import React, { useState, useEffect, useRef } from 'react';
import Droppable from 'droppable';

const AttachmentFileModal = ({ id, setFiles, files }) => {
    const [state, setState] = useState({
        totalFiles: 0,
        uploadingFiles: 0,
        percentage: 0,
        isLoading: false,
        message: ''
    });
    const droppableRef = useRef(null);

    const simulateUploadProgress = () => {
        let percentage = 0;
        const interval = setInterval(() => {
            percentage += 10;
            if (percentage >= 100) {
                clearInterval(interval);
                setState(prevState => ({
                    ...prevState,
                    percentage: 100,
                    message: 'File successfully uploaded'
                }));
                setTimeout(() => {
                    setState({
                        totalFiles: 0,
                        uploadingFiles: 0,
                        percentage: 0,
                        isLoading: false,
                        message: ''
                    });
                }, 3000); 
            } else {
                setState(prevState => ({
                    ...prevState,
                    percentage
                }));
            }
        }, 500); 
    };

    const uploadFiles = (files) => {
        setState({
            ...state,
            isLoading: true,
            totalFiles: files.length,
            uploadingFiles: 1,
            percentage: 0
        });
        setFiles(files)
        simulateUploadProgress();
    };

    useEffect(() => {
        if (!droppableRef.current) {
            droppableRef.current = new Droppable({
                element: document.querySelector('#' + (id || 'file_uploader_canvas'))
            });
            droppableRef.current.onFilesDropped((files) => {
                uploadFiles(files);
            });
        }
    }, [id]);

    const displayLoader = () => {
        if (!state.isLoading && !state.message) {
            return null;
        }
        const percentageUpload = state.percentage;
        return (
            <div className="uploader_loader">
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={percentageUpload} aria-valuemin="0" aria-valuemax="100" style={{ width: `${percentageUpload}%`, backgroundColor: 'orange' }}>
                        {Math.round(percentageUpload)}%
                    </div>
                </div>
                <p className='upload_st_label' style={{ display: 'none' }}>Uploading {state.uploadingFiles} / {state.totalFiles}</p>
            </div>
        );
    };

    return (
        <>
            <div className='file_uploader'>
                {displayLoader()}
                <div className='file_uploader_canvas' id={id || 'file_uploader_canvas'}>
                    <img src="/images/icons/file-upload.svg" className='file_uploader_icon' alt="Upload File" />
                    <div className='uploader_help_text'>
                        <h3>Select a file or drag and drop here</h3>
                        <p>JPG, PNG or PDF, file size no more than 10MB</p>
                    </div>
                </div>
            </div>
            {state.message && <div className="upload-success-message">{state.message}</div>}
            {files?.map((item)=>(
                <p>File name: {item?.name}</p>
            ))}
        </>
    );
};

export default AttachmentFileModal;
