import React from 'react'

const CommonInnerCircle = ({ totalTasks }) => {
  return (
    <div
     className="inner-text-circle"
    >
      <h4 className="fw-bold">{totalTasks}</h4>
      <p style={{ marginBottom: '0', fontSize: '12px', color: '#A0AEC0' }}>
        Total Task
      </p>
    </div>
  )
}

export default CommonInnerCircle
