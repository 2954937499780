import React, { Fragment, useCallback } from "react"; 
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings"; 
import { 
  getPopupMessageBasedOnStatus,
} from "../../../inc/Validation";
import NavigationHeder from "../../Navigations/NavigationHeder"; 
import Alert from "../../inc/Alert";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import Api from "../../../inc/Api";
import AgGridNew from "../../grid/ag/ag-grid-new";

let gridApi = null;
let AddBtn;
let transaction;
const Roles = (props) => {
  const handleBtn = (value, transactionData) => {
    AddBtn = value;
    transaction = transactionData;
  };

  const onRowValueChanged = (event) => {
    if (event?.data?.role_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  const updateData = async (event) => {
    try {
      let payload = {
        name: event?.data?.name,
        description: event?.data?.description
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiSystemUrl + `/role/${event?.data?.role_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const addNewData = async (event) => {
    try {
      let payload = {
        name: event?.data?.name,
        description: event?.data?.description,
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiSystemUrl + "/role", payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delRoles_ID = element?.data?.role_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiSystemUrl + `/role/${delRoles_ID}`)
      const rowNode = gridApi?.getRowNode(delRoles_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
   
  const gridColumnHeaders = [
    {
      field: "name",
      editable:true,
      headerName: Helper.getLabel(props.language, "name_sr_1", "Name"),
    },
    {
      field: "description",
      editable:true,
      headerName: Helper.getLabel(
        props.language,
        "description_sr_2",
        "Description"
      ),
    },
  ];

  if (!props?.user?.admin_user) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <NavigationHeder hideMoreBtn={true} title="Security Roles"></NavigationHeder>
      <AgGridNew
        apiUrl={Settings.apiSystemUrl + "/role"}
        pagination={false}
        columnDefs={gridColumnHeaders}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={DeleteHandler}
        height={500}
        onGridReady={(params) => (gridApi = params?.api)}
        handleAddButton={handleBtn}
        uniqueField={`role_id`}
        gridId={"user-roles-grid"}
      />  
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MasterComponentWraper(Roles)); 