import React, {useState, useEffect, Fragment} from 'react';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import DropDownInput from '../../../Forms/Dropdown'; 
import TechEvaluationConsolidated from './TechEvaluationConsolidated';
import { connect } from 'react-redux'; 
import CreateTechnicalEvaluation from './CreateTechnicalEvaluation';
import SimpleLoading from '../../../Loading/SimpleLoading';
import { checkIfUserExistInScorings } from '../../../../inc/Validation';

/*
RFQ Technical Evaluation - LYNKAZ - SYSTEM

required params :-

rfq_id: extract from url then send it here,
employee_id: extract from either the token API, or from dropdown of employee inside RFQ,
evaluation_type: either Financial or Technical (its ids),
executeAPIForSubmitBtn (accepts a function as of argument): allows to trigger the child level function to the upper/parent level, 
executeAPIForRetenderBtn (accepts a function as of argument): allows to trigger the child level function to the upper/parent level, 
rfqHeader: for blue bar,
*/

const TechnicalEvaluationWithConsolidatedView = (
    { 
        rfq_id, 
        auth, 
        rfqHeader,   
        executeAPIForSubmitBtn, 
        executeAPIForRetenderBtn,
        orderer_id,  
        componentTypeRendered,
        evaluation_type,
        readOnlyForm,
        setRFQScoresState,
        RFQScoresState,
        setEvaluatorData,
        setTechnicalScoreSummary,
        screenHeight,
        hideSidebarState,
        showPreviewBtn=true
    }
) => { 
    const [toggleComponentType, setToggleComponentType] = useState({
        type: null,
        id: null
    });
    const [userScoringData, setUserScoringData] = useState([]);  
    const [loading, setLoading] = useState(true);
    const [loadingView, setLoadingView] = useState(false);
 
    const [scrollablePaddings, setScrollablePaddings] = useState(0);
    
    let userScoringDataCalled = false;
    let RFQScoresCalled = false;

    useEffect(() => { 
        getRFQScores()
        getUsersForScoring() 
    }, []);

    useEffect(() => {
        if(window?.innerHeight > 1105){
            setScrollablePaddings(100);
        } else if (window?.innerHeight < 923) {
            setScrollablePaddings(0);
        } else {
            setScrollablePaddings(30);
        }
    }, [window?.innerHeight])
 
    const getRFQScores = () => {
        if (!rfq_id && RFQScoresCalled) return;

        RFQScoresCalled = true;

        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseRequisitionUrl + `/get_rfq_scores/${rfq_id}?evaluation_type=0`, {})
        .then(function (res) {
            if (res.data.status === "success") {
                setRFQScoresState(res?.data?.data[0]);
            }
        })
        .catch((res) => {
            setRFQScoresState({});
        });
    };
    
    const getUsersForScoring = () => {
        if(userScoringDataCalled) return;
        
        setLoading(true);
        let api = Api;
        userScoringDataCalled = true;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseRequisitionUrl + `/get_users_for_scoring/${rfq_id}?users_for=${evaluation_type}`, {})
        .then(function (res) {
            if (res.data.status === "success") {
                const userScoringResponse = res?.data?.data?.map(item => ({
                    value: item.employee_id,
                    label: item.employee_name,
                    user_id: item.user_id
                }))?.concat({
                    value: Date.now(),
                    label: "Consolidated View"
                });

                if(componentTypeRendered) { componentTypeRendered({}) }; 
                setUserScoringData(userScoringResponse);
                setLoading(false);
            } 
        })
        .catch((error) => { 
            setLoading(false)
            console.log(error); 
        });
    };
    
    const ComponentViewToggleMethod = () => {
        return (
            <Fragment>
                {auth?.user?.is_buyer 
                    ? 
                        <Fragment>
                            <div className='technical_evaluation_filtering'>
                                <DropDownInput className='user_for_scoring_dropdown' label={"User for scorings"} options={userScoringData} id='user_scoring_technical_evaluation' onChange={userScoringHandler} /> 
                                {showPreviewBtn && 
                                    <a href={`/request-for-quotation/rfqdetailform?rfq_view_id=${rfq_id}&technical_evaluation=true&return=rfqheaderlist`} target="_blank" rel="noopener noreferrer">
                                        <div className='technical_evaluation_preview_tab_wrapper'>
                                            <img src='/images/icons/tab-preview.jpg' alt="Preview" />
                                        </div> 
                                    </a>
                                }
                            </div>
                            {loadingView ? 
                                (
                                    <SimpleLoading />
                                ) : (
                                toggleComponentType.type !== null ? (
                                    <Fragment>
                                        {toggleComponentType.type === "Consolidated View" && (
                                            <TechEvaluationConsolidated  
                                                screenHeight={screenHeight}
                                                employee_id={auth?.user?.employee_id}
                                                evaluation_type={evaluation_type} 
                                                rfq_id={rfq_id} 
                                                rfqHeader={rfqHeader}
                                                hideSidebarState={hideSidebarState}
                                                rfq_scores_data={RFQScoresState}
                                            />
                                        )}
                                        {toggleComponentType.type !== "Consolidated View" && (
                                            <CreateTechnicalEvaluation
                                                setEvaluatorData={setEvaluatorData}
                                                setTechnicalScoreSummary={setTechnicalScoreSummary}
                                                rfq_id={rfq_id}
                                                rfq_scores_data={RFQScoresState}
                                                readOnlyForm={readOnlyForm}
                                                employee_id={toggleComponentType.id}
                                                evaluation_type={evaluation_type} 
                                                rfqHeader={rfqHeader}
                                                executeAPIForSubmitBtn={executeAPIForSubmitBtn}
                                                executeAPIForRetenderBtn={executeAPIForRetenderBtn}
                                            />
                                        )}
                                    </Fragment>
                                ) : (
                                    <Fragment></Fragment>
                                )
                            )}
                        </Fragment> 
                    :
                        (!checkIfUserExistInScorings(auth?.user?.user_id, userScoringData)) ? (
                            <TechEvaluationConsolidated  
                                screenHeight={screenHeight}
                                employee_id={auth?.user?.employee_id}
                                evaluation_type={evaluation_type} 
                                rfq_id={rfq_id} 
                                rfqHeader={rfqHeader}
                                hideSidebarState={hideSidebarState}
                                rfq_scores_data={RFQScoresState}
                            />
                        ) :
                        (
                            <CreateTechnicalEvaluation
                                setEvaluatorData={setEvaluatorData}
                                setTechnicalScoreSummary={setTechnicalScoreSummary}
                                rfq_id={rfq_id}
                                employee_id={auth.user.employee_id}
                                evaluation_type={evaluation_type} 
                                readOnlyForm={readOnlyForm}
                                rfq_scores_data={RFQScoresState}
                                rfqHeader={rfqHeader}
                                executeAPIForSubmitBtn={executeAPIForSubmitBtn}
                                executeAPIForRetenderBtn={executeAPIForRetenderBtn}
                            /> 
                        )
                }
            </Fragment> 
        )
    }

    const userScoringHandler = (evt) => {
        let targetVal = evt.target.value;
        if(!targetVal) return;

        setLoadingView(true);
        let componentType = userScoringData?.find(val => val.value == targetVal);
        
        setToggleComponentType((prevState) => {
            return {
                ...prevState,
                id: componentType.value,
                type: componentType.label
            }
        });
        if(componentTypeRendered) {
            componentTypeRendered((prevState) => {
                return {
                    ...prevState,
                    label: componentType?.label, 
                    value: componentType?.value
                }
            })
        }
        setTimeout(() => {
            setLoadingView(false);
        }, 200); 
    }
    return (<div style={{paddingBottom: `${scrollablePaddings}px`}} className='technical_evaluation_with_consolidated_view_wrapper'> { loading ? <SimpleLoading /> : ComponentViewToggleMethod() } </div>);
};

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
};

export default connect(mapStateToProps) ((TechnicalEvaluationWithConsolidatedView));