import Settings from "../inc/Settings";
import ActionTypes from "../inc/ActionsTypes";
import { replaceMmWithMM } from "../inc/Validation";

const defaultState = {
    formate:Settings.defaultDateFormate
}
const DateFormateReducer = (state = defaultState, actions) =>{ 
    console.log(replaceMmWithMM(actions.payload), 'rd')
    if(actions.type === ActionTypes.SET_DATE_FORMATE && actions.payload){
        state = {
            ...state,
            formate:replaceMmWithMM(actions.payload)
        }
    }
    return state;
}
export default DateFormateReducer;