import React, {useEffect, useState} from 'react'
import Helper from '../../../inc/Helper'
import { connect } from 'react-redux'
import AgGrid from '../../grid/ag/ag-grid'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import SimpleLoading from '../../Loading/SimpleLoading'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'

const POLinesHistoryGrid = ({language, id, units, category, products, barcodes, items}) => {
  const [POLinesHistory, setPOLinesHistory] = useState([])
  const [loadingLines, setLoadingLines] = useState(false)
 
  // API Reduction Calls --
  let POLinesHistoryCalled = false 
  // 

  // API Renderer calls --

  useEffect(() => {
    POLinesHistoryAPI()
  }, [])

  // API Calls --

  const POLinesHistoryAPI = () => {
    if(POLinesHistoryCalled || !id ){
      return;
    }
    setLoadingLines(true)
    let api = Api
    POLinesHistoryCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/po_line_history/${id}`,{}).then(function(res){
      if(res.data.status==="success"){  
        setPOLinesHistory(res.data.data)
        setLoadingLines(false)
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoadingLines(false)
    })
  }

  // Grid Things --

  const Columns_Headings = [
    {field:'lines_type', headerName:Helper.getLabel(language,'type',"Type"), minWidth: 300, editable: false},
    {field:'budget_check', headerName:Helper.getLabel(language,'budget_check',"Budget Check"), minWidth: 300, editable: false},
    {field:'line_number', editable: false, headerName:Helper.getLabel(language,'line_no',"Line No."), minWidth: 300,  editable: false},
    {field:'item_id', headerName:Helper.getLabel(language,'item_id',"Item"), minWidth: 300, options:items, inputType: "dropdown", editable: false,   },
    {field:'barcode', headerName:Helper.getLabel(language,'barcode',"Barcode"), minWidth: 300,  options:barcodes, inputType: "dropdown",  editable: false,    },
    {field:'product_id', headerName:Helper.getLabel(language,'product_name',"Product Name"), minWidth: 300, options:products, inputType: "dropdown", editable: false,   },
    {field:'category_id', headerName:Helper.getLabel(language,'category',"Category"), minWidth: 350, options:category, inputType: "dropdown", editable: false,   },
    {field:'comments', headerName:Helper.getLabel(language,'comments',"Comments") , minWidth: 350, editable: false, },
    {field:'ordered_qty', headerName:Helper.getLabel(language,'quantity',"Quantity"), minWidth: 300, editable: false,   },
    {field:'unit', headerName:Helper.getLabel(language,'unit',"Unit"), options: units, minWidth: 300, inputType: "dropdown", editable: false,   },
    {field:'unit_price', editable: false , headerName:Helper.getLabel(language,'unitprice',"Unit Price"), minWidth: 300,},
    {field:'discount_amount', editable: false,  minWidth: 300, headerName:Helper.getLabel(language,'discount_amount',"Discount Amount") },
    {field:'discount_percentage', editable: false, minWidth: 300, headerName:Helper.getLabel(language,'discount_percentage',"Discount Percentage"),},
    {field:'line_amount', minWidth: 300, headerName:Helper.getLabel(language,'lineamount',"Line Amount"), editable: false},
  ]

  const Callbuttons = () => {
    return ( 
      <h2 className='grid_label_rfq me-3'>Lines</h2>  
    )
  }

  // --

  return (
    <div className='container-fluid purchase_orders_header_all_history_grid'>
      {loadingLines ? <SimpleLoading /> : <AgGrid data={POLinesHistory} beforeAddButton={Callbuttons} id="rs_purchase_orders_header_all_history_grid_view" allBtnHide={true} header={Columns_Headings} />}
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
  }
}

export default connect(mapStateToProps) (POLinesHistoryGrid);