import React from 'react';
import POArchive from '../../components/Purchase Orders Internal/Archive PO/POArchive';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';

const ContractOrderArchivePage = (props) => {
    return (
        <POArchive 
            title={Helper.getLabel(null, 'contract_orders_all', 'Contract Orders - All')}
            createFormTitle={Helper.getLabel(null, 'create_contract_order', 'Create Contract Order')}
            editUrl="/contract-order/edit"
            type={"1"}
            security={props?.security}
            source_id={Settings.source_id.contract}
        />
    );
};
 
const SecurityOptions = {
  gui_id: Gui_id_list.procure.contract_orders.contract_orders_main
};

export default (((ApplySecurityRoles(ContractOrderArchivePage, SecurityOptions)))); 