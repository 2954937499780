import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Helper from '../../inc/Helper'

const WorkFlowDetails = ({name, date, stage, from, mainHeading, changeLabelOrder = false, task_assignee, initiated_date, due_date, language}) => {
    const defaultLabelsOrder = () => {
        return (
            <div>
                <div className='workflow_details'>
                    <h3>{Helper.getLabel(language,'workflow_name','Workflow Name')}: <span>{name}</span></h3> 
                    <h3>{Helper.getLabel(language,'stage_name','Stage')}: <span>{stage}</span></h3>
                    <h3>{Helper.getLabel(language,'workflow_date','Initiate Date')}: <span>{date && date.split(", ").shift()}</span></h3>
                    <h3>{Helper.getLabel(language,'from','From')}: <span>{from}</span></h3> 
                </div>
                <div className='work_extra_details'>
                    <h2>{mainHeading}</h2>
                </div>
            </div>
        )
    }

    const changedLabelsOrder = () => {
        return (
            <div>
                <div className='work_extra_details_chng'>
                    <h2>{mainHeading}</h2>
                </div>
                <div className='workflow_details'>
                    <h3>{Helper.getLabel(language,'workflow_name','Workflow Name')}: <span>{name}</span></h3> 
                    <h3>{Helper.getLabel(language,'task_assignee','Task Assignee')}: <span>{task_assignee}</span></h3>
                    <h3>{Helper.getLabel(language,'stage_name','Stage')}: <span>{stage}</span></h3>
                    <h3>{Helper.getLabel(language,'initiated_date','Initiated Date')}: <span>{initiated_date}</span></h3>
                    <h3>{Helper.getLabel(language,'due_date','Due Date')}: <span>{due_date} </span></h3> 
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {changeLabelOrder ? changedLabelsOrder() : defaultLabelsOrder()} 
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language
    }
}

export default connect(mapStateToProps) (WorkFlowDetails);