import React from 'react'

import AddProfileIcon from '../../../assets/vendor req.svg'

const ContractInformationCard = ({
  title,
  information,
  mainCardClass,
  value
}) => {
  //Common Card for Employee Information.
  return (
    <div className={`${mainCardClass} info-card-common`}>
      <div className="icon-style">
        <img src={AddProfileIcon} alt="" width="40px" height="40px" />
      </div>
      <div>
        <h6 className="info-title">{title}</h6>
        <h3 className="info-style">{information}</h3>
        <p className="info-title mb-0">{value}</p>
      </div>
    </div>
  )
}

export default ContractInformationCard
