import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../components/inc/Button'
import Input from '../../components/Forms/Input'
import Helper from '../../inc/Helper'
import FormValidator from '../../inc/FormValidator'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'

const ForgotPasswordPopup = ({ forgotPasswordPopup, handleClose, language }) => {
  const [userEmail,setUserEmail]=useState('')

  let validator 

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'user_name', displayName:Helper.getLabel(language,'email_address','Email address'),types:['Required','Email'], max: 80},
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,language)
    }
  }

  validationConfigure()

  const forgotPasswordClick = async () => {
    let validData = {
        user_name:userEmail,
    }
    if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
    } 
    try {
        let api = Api;
        api.setUserToken();
        const res = await api.axios().post(Settings.apiUrl + '/forget_password', validData);
        if (res.data.status === "success") {
            Helper.alert(res?.data?.message);
            handleClose()
        }
    } catch (error) {
        getPopupMessageBasedOnStatus(error);
    } 
  }

  return (
    <Modal show={forgotPasswordPopup} onHide={handleClose} className='modal-forgot-password'>
      <Modal.Body>
        <h3 className='forgot-password-title'>Reset Password</h3>
        <p className='fw-bold text-center'>Provide your registered email address</p>
        <Input placeholder={'Enter your email'} name={Helper.getLabel(language,'user_name',"user_name")} label = {Helper.getLabel(language,'email_address',"Email address")} value={userEmail} onChange={(e)=>{setUserEmail(e.target.value)}}/>
        <div className='d-flex gap-2 justify-content-end forgot-password-btns'>
          <Button title={Helper.getLabel(null, 'reset_password', 'Reset Password')} isActive={true} onClick={forgotPasswordClick}></Button>
        </div>
        <img src='/images/icons/cross.svg' alt="cross" className='forgot-password-cross' onClick={handleClose} />
      </Modal.Body>
    </Modal>
  )
}

export default ForgotPasswordPopup
