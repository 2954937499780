import React, { Component } from 'react';
import HistoryPO from '../../components/Purchase Orders Internal/History & Versions/HistoryPO';

class PoHistoryPage extends Component {
    render() {
        return (
            <div>History Page</div>
        );
    }
}

export default PoHistoryPage;