import React, { useState, useEffect} from "react";
import Popup from "../../../Popup/Popup";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import $ from "jquery";
import SimpleLoading from "../../../Loading/SimpleLoading";
import LineViewComponent from "../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Helper from "../../../../inc/Helper";
import TotalWidget from "../../../Widgets/TotalWidget/TotalWidget";
import CustomCheckboxCellRenderer from "../../../grid/ag/cellRender/CustomCheckboxCellRenderer";
import { getNewValue } from "../../../grid/ag/commonFunctions/GridCommonFunctions";
import Button from "../../../inc/Button";

/*

Lines Recommendation Popup Component:

required @params:

scorereply_id,
setLinesDetails: State function for popup opening/closing view,

*/


let gridObj = null

const LinesRecommendationPopup = ({
  setLinesDetails,
  language,
  scorereplyheader_id,
  vendorreply_id,
  getSummary,
  rfqHeader,
  hideDeleteBtn=false,
  user,
  disabled
}) => {
  const [loading, setLoading] = useState(false);
  const [vendorReplyLines, setVendorReplyLines] = useState();
  const [scoreReplyLines, setScoreReplyLines] = useState();
  const [linesData, setLinesData] = useState();
  const [userDetails, setUserDetails] = useState();
  const [dataLoaded, setDataLoaded] = useState(false)
  
  let scoringReplyHeaderCalled = false;
  let vendorReplyHeaderCalled = false;

  useEffect(() => {
    async function fetchData() {
      await getUserDetails();
      await getVendorReplyLines();
      await getScoreReplyLines();
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (scoreReplyLines && vendorReplyLines && userDetails) {
      mapReplyLines()
    }
  }, [scoreReplyLines, vendorReplyLines, userDetails])

  const getUserDetails = async () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + `/user_details`)
      .then(function (res) {
        if (res.data.Status === "Success") {
          setUserDetails(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const getVendorReplyLines = async () => {
    if (!vendorreply_id && vendorReplyHeaderCalled) return;

    let api = Api;
    vendorReplyHeaderCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/lines/${vendorreply_id}/${Settings?.source_id?.RfqVendorReply}`
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setVendorReplyLines(res.data.data)
        }
      })
      .catch((res) => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  const getScoreReplyLines = async () => {
    if (!scorereplyheader_id && scoringReplyHeaderCalled) return;
    let api = Api;
    scoringReplyHeaderCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/get_line_recommendations/${scorereplyheader_id}`
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setScoreReplyLines(res.data.data)
        }
      })
      .catch((res) => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  const mapReplyLines = () => {
    if (!scoreReplyLines || !vendorReplyLines || !userDetails) return

    const filteredVendorReplyLines = vendorReplyLines?.filter((vendorLine) =>
      scoreReplyLines?.some(
        (scoreLine) => scoreLine.vendorreplyline_id === vendorLine.line_id
      )
    );

    const linesDataWithRecommended = filteredVendorReplyLines?.map(
      (vendorLine) => {
        const matchingScoreLine = scoreReplyLines?.find(
          (scoreLine) => scoreLine.vendorreplyline_id === vendorLine.line_id
        );
        return {
          ...vendorLine,
          recommended: matchingScoreLine?.recommended || null,
          recommendation_id:matchingScoreLine?.replylinerecommendation_id || null
        };
      }
    );
  
    setLinesData(linesDataWithRecommended);
    setDataLoaded(true);
  };

  const CloseHandler = () => {
    setDataLoaded(false);
    $(".lines_recommendation_popup_wrapper .rs_popup_close").trigger("click");
    setLinesDetails((prevState) => {
      return {
        ...prevState,
        openPopup: false,
        id: null,
        rfq_vendorreply_id: null,
      };
    });
  };

  const onCheckboxChanged = (value,params) => {
    const filteredScore = scoreReplyLines?.find(
      (score) =>
        score.vendorreplyline_id === params.data.line_id 
    );

    let lineRecommendationId =
      filteredScore?.replylinerecommendation_id;
    if (!lineRecommendationId) return;
  

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/line_recommendation/${lineRecommendationId}`,
        {
          recommended: value,
          preferred: null,
          award_to: null,
        }
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setLinesData((prevLinesData) => {
            const updatedLinesData = prevLinesData?.map((line) =>
              line.replylinerecommendation_id === lineRecommendationId
                ? { ...line, recommended: value}
                : line
            );
            return updatedLinesData;
          });
          params.node.setDataValue('recommended',value)
          Helper.alert('Line recommendation updated successfully.', 'success')
          getSummary()        
        }
      })
      .catch((res) => {
        Helper.alert(res.response.data.message,"error")
      });
  };

  const taxTransactionViewCell = (params) => {
    if(params.data.line_id){
        return <div className="rs_inactive_label">View tax</div>;
    }
    return <></>;
  }

  let dataSource = {
    getRows: async function (params) {
      if(linesData?.length!==0){
        gridObj?.api?.hideOverlay()
      }
      params?.success({
        rowData: linesData?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: linesData?.length
      })
    },
  };

  const handleLineViewGridReady = (params) => {
    gridObj = params
    if(params){ 
      setTimeout(()=>{ 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200)
    };
  };

  let Columns_Header = [
    {
      field:"line_number",
      headerName:Helper.getLabel(language,'line_number',"Line number"), 
      editable:false,
      minWidth:160,
    },
    {
      field: 'recommended',
      minWidth: 190,
      headerName: Helper.getLabel(
        language,
        'recommended',
        'Recommended'
      ),
      cellClass:'rfq-col-additional',
      cellRenderer: CustomCheckboxCellRenderer,
      cellRendererParams:{
         onChange:onCheckboxChanged,
         disabled: disabled || rfqHeader?.rfq_status !==5 || rfqHeader?.onhold || rfqHeader?.rfq_status===2
      },
      editable: false,
    }, 
    {
      field:"item_name",
      headerName:Helper.getLabel(language,'item_name',"Item name"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"item_code",
      headerName:Helper.getLabel(language,'item_code',"Item code"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"category_name",
      headerName:Helper.getLabel(language,'category',"Category"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    }, 
    {
      field:"comments",
      headerName:Helper.getLabel(language,'comments',"Comments"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"ordered_qty",
      headerName:Helper.getLabel(language,'quantity',"Quantity"), 
      editable:false,
      minWidth:180,
    },
    {
      field:"required_qty",
      headerName:Helper.getLabel(language,'purposed_quantity',"Purposed quantity"), 
      editable:false,
      minWidth:180,
    },
    {
      field:"unit_name",
      headerName:Helper.getLabel(language,'Unit Name',"Unit name"), 
      editable:false,
      minWidth:180,
    },
  ]

  let commercialValues = [{
    field:"unit_price",
    headerName:Helper.getLabel(language,'unit_price',"Unit price"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"discount_amount",
    headerName:Helper.getLabel(language,'discount_amount',"Discount amount"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"discount_percentage",
    headerName:Helper.getLabel(language,'discount_percentage',"Discount percentage"), 
    editable:false,
    minWidth:200,
  },
  {
    field:"line_amount",
    headerName:Helper.getLabel(language,'line_amount',"Line amount"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"itemgroup_name",
    headerName:Helper.getLabel(language,'item_group',"Item group"), 
    editable:false,
    minWidth:220,
  },
  {
    field:'view_tax_transaction', 
    minWidth: 150, 
    headerName:Helper.getLabel(language,'tax',"Tax"), 
    editable: false,
    cellRenderer:taxTransactionViewCell
  }
]

  if(user?.is_buyer || (rfqHeader?.allow_view_commericals_in_technical_evaluation && !user?.is_buyer)){
   Columns_Header =  Columns_Header.concat(commercialValues)
  } 

  const hideTabs = () => {
    if(rfqHeader?.target_document === 1 && user?.is_buyer){
      return false
    }else if(rfqHeader?.target_document === 1 && !user?.is_buyer && rfqHeader?.allow_view_commericals_in_technical_evaluation){
      return false
    }else{
      return true
    }
  }
   return (
     <div className='lines_recommendation_popup_wrapper'>
       <Popup className='lines_recommendation_popup' autoOpen={true} width='1200px' onClose={CloseHandler}>
         <div className='lines_details mb-3 d-flex justify-content-between align-items-center'>
           <h5 className='create_rfq_modal__title'>Select lines recommended to vendor</h5>
           <div className='d-flex gap-2'>
             <Button title={Helper.getLabel(language, 'save_and_close', 'Save & Close')} isActive={false} className='blue-btn-style' onClick={CloseHandler} />
             <div className='technical-total'>
               <TotalWidget source_id={Settings.source_id.RfqVendorReply} integrator={vendorreply_id} isDisable={!rfqHeader?.allow_view_commericals_in_technical_evaluation && !user?.is_buyer} />
             </div>
           </div>
         </div>
         {loading || !dataLoaded ? (
           <SimpleLoading />
         ) : (
           <div className='rfq-line-view line_scoring_module'>
             <LineViewComponent
               source_id={Settings?.source_id?.RfqVendorReply}
               line_source={null}
               header_id={null}
               lineGridTitle={'Request Lines'}
               header={Columns_Header}
               defaultValues={{
                 requester_id: null,
                 warehouse_id: null,
                 delivery_date: null,
                 taxgroupitem_id: null,
                 taxgroup_id: null,
                 entity_id: null,
                 site_id: null,
                 orderer_id: null,
               }}
               isEditable={false}
               purchase_status={null}
               document_status={null}
               delivery_date={null}
               // master_save_button_id='btn_master_save_lines_recommendated'
               onbehalf_request={false}
               vendor_id={null}
               data={linesData}
               vendorsite_id={null}
               hideDistribution={false}
               hideSetup={true}
               hideDeleteBtn={hideDeleteBtn}
               hideGeneral={true}
               hideBillingRule={hideTabs()}
               lineRecommended={true}
               hideExtraColumn={true}
               onLineGridReady={handleLineViewGridReady}
             />
           </div>
         )}
       </Popup>
     </div>
   )
};

export default LinesRecommendationPopup;
