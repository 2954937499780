import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Helper from "../../../../inc/Helper"

const RFQBiddingRequirement = (props) => {
  return (
    <Fragment>
        <div className='scope_of_work ps-3 pe-3'>
            <h2 className='border-bottom-h2'>{Helper.getLabel(props.language, 'scope_of_work', 'Scope of work')}</h2>
            <div className='request_for_quotation_content w-100 mt-2'>
              <textarea className="request_for_quotation_content rfq-textarea w-100" disabled={props.type==="rfq"?props.disable:true} onChange={props.type==="rfq"?props.onRequirementChange:''} value={props.scopeOfWork} defaultValue={props.scopeOfWork} onBlur={props.type==="rfq"?props.onRequirementBlur:''} name="scope_of_work" maxLength={4000}></textarea>
            </div>
        </div>
        <div className='material_not_inc ps-3 pe-3 mt-4'>
            <h2 className='border-bottom-h2'>{Helper.getLabel(props.language, 'materials_and_services_not_included', 'Materials and services not included')}</h2>
            <div className='request_for_quotation_content w-100 mt-2'>
               <textarea className="request_for_quotation_content rfq-textarea w-100"  disabled={props.type==="rfq"?props.disable:true} defaultValue={props.materialsAndServicesNotIncluded} value={props.materialsAndServicesNotIncluded} onChange={props.type==="rfq"?props.onRequirementChange:''} name="material_service_not_included"  onBlur={props.type==="rfq"?props.onRequirementBlur:''} maxLength={4000}></textarea>
            </div>
        </div>
        <div className='agreement_terms ps-3 pe-3 mt-4 mb-4'>
            <h2 className='border-bottom-h2'>{Helper.getLabel(props.language, 'agreement_terms', 'Agreement terms')}</h2>
            <div className='request_for_quotation_content w-100 mt-2'>
               <textarea className="request_for_quotation_content rfq-textarea w-100" disabled={props.type==="rfq"?props.disable:true} onChange={props.type==="rfq"?props.onRequirementChange:''} name="requirement_description" defaultValue={props.agreementTerms} value={props.agreementTerms}  onBlur={props.type==="rfq"?props.onRequirementBlur:''} maxLength={4000}></textarea>
            </div>
        </div>
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
  

export default connect(mapStateToProps) (RFQBiddingRequirement) 