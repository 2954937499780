import $ from 'jquery';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import Helper from '../../../../inc/Helper';
import MasterCellEditor from './MasterCellEditor';
class AjaxBarcodeSearch extends MasterCellEditor{

    init(params) {
        this.params = params;
        this.skey = null;
        this.ppp = 10;
        this.page = 1;
        this.value = params.value ? params.value : '';
        this.input = document.createElement('input');
        this.addClass(this.input,'form-control')
        this.input.id = 'ajax_search_input';
        this.input.type = 'text';
        this.input.value = this.value;
        this.isInputChanged = false;
        this.input.addEventListener('input', (event) => {
            this.value = event.target.value;
            this.isInputChanged = true;
        });
        
        this.wraperSection = document.createElement('div');
        this.addClass(this.wraperSection,'rs_barcodesearch');
        this.wraperSection.appendChild(this.input)
        let that = this;
        //this.searchBrcode();
        this.input.addEventListener('keydown', (event) => {
            if( ( that.isInputChanged ) && Helper.getNullableValue(event.target.value)){
                if(event.key === "Tab"  && !event.shiftKey){
                    event.preventDefault();
                    event.stopPropagation();
                    that.searchBrcode();
                }
            }
            
        });
        
    }
    updateValue(newValue){
        this.value = newValue;
        this.input.value = newValue;
    }
    showNoItemFoundMessage(){
        Helper.alert(Helper.getLabel(null,'no_item_found','No item found!'),'error');
    }
    searchBrcode(){
        let api = Api;
        api.setUserToken()
        let that = this;
        //this.params.api.showLoadingOverlay()
        api.axios().post(Settings.apiItemUrl+`/get_barcode_item`,{barcode:this.getValue()}).then(function(res){
            if(res.data.status =='success'){
                let item = res.data.data;
                let newData = {
                    ...that.params.data,
                    'item_name': item.name,
                    'item_code': item.code,
                    'item_id': item.item_id,
                    'barcode': item.barcode,
                }
                var cellEditorInstances = that.params.api.getCellEditorInstances();
                cellEditorInstances.forEach(cellEditorIns => {
                    if(cellEditorIns.params.colDef.field == 'item_code'){
                        cellEditorIns.updateValue(item.code)
                    }
                    if(cellEditorIns.params.colDef.field == 'item_name'){
                        cellEditorIns.updateValue(item.name)
                    }
                    if(cellEditorIns.params.colDef.field == 'barcode'){
                        cellEditorIns.updateValue(item.barcode);
                    }
                });
            
                that.params.node.setData(newData)
                that.params.api.tabToNextCell();
            }
            
        }).catch((error) => { 
            that.showNoItemFoundMessage()
        })
    }
    

    /* Component Editor Lifecycle methods */
    // gets called once when grid ready to insert the element
    getGui() {
        return this.wraperSection;
    }

    // the final value to send to the grid, on completion of editing
    getValue() {
        return this.value;
    }

    // after this component has been created and inserted into the grid
    afterGuiAttached() {
        //this.input.focus();
    }
    focusIn() {
        this.isInputChanged = false;
        this.input.focus();
    }
 }
 export default AjaxBarcodeSearch;