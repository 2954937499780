import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import LoadTaxSettlementPeriods from '../../../actions/EnumsActions';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';  
import ButtonNew from '../../inc/ButtonNew'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { useNavigate } from 'react-router-dom';
import { getDisplayNamesFromArray, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SettlementPeriodsModal from './SettlementPeriodsModal';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';

let gridApi = null;

const SettlementPeriods = ({loadPeriods, TaxSmtPeriod, security}) => { 
    const [showCreateModal, setShowCreateModal] = useState(false);

    const [taxAuthorityList, setTaxAuthorityList] = useState([]); 
    const language = useSelector(state => state.language);

    const navigate = useNavigate(); 

    let taxAuthorityCalled = false;

    useEffect(() => { 
        getTaxAuthority();
        (loadPeriods(TaxSmtPeriod));
    }, []);

    const getTaxAuthority = () => {
        if(taxAuthorityCalled) return;
        taxAuthorityCalled = true
    
        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/taxauthority`,{}).then(function(res){
            if(res.data.status==='success'){
                setTaxAuthorityList(res?.data?.data?.map(item => {
                    return {
                        value: item.taxauthority_id,
                        label: item.name + '-' + item.code
                    }
                }))
            }
        }).catch((res) => {  
            console.log(res)
        })
    }
  
    const onCellClicked = (event) => {
        if (event.colDef.field === 'code') {
            navigate('/tax/edit-settlement-period/' + event?.data?.settlementperiodheader_id);
        }
    };

    let period_intervals = TaxSmtPeriod.periods.map(item => {
        return {
            label: item.name,
            value: item.id
        };
    });

    const deleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delSettlementPeriods_ID = element?.data?.settlementperiodheader_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/settlementheader/${delSettlementPeriods_ID}`)
            const rowNode = gridApi?.getRowNode(delSettlementPeriods_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200);

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    /* Grid Column Headers */

    const gridColumnHeaders = [
        { field: 'code', filter: 'agTextColumnFilter', cellRenderer: (params) => gridLinkValueFormat(params), headerName: Helper.getLabel(language, "code", 'Code') },
        { field: 'taxauthority_id', filterParams: { values: (params) => getOptions(params, taxAuthorityList), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', cellRenderer: (params) => getDisplayNamesFromArray(params, taxAuthorityList, 'value', 'label'), headerName: Helper.getLabel(language, "authority", 'Authority') },
        { field: 'description', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, "description", 'Description') },
        { field: 'periodinterval', filterParams: { values: (params) => getOptions(params, period_intervals), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName: Helper.getLabel(language, "period_interval", 'Period interval'),  cellRenderer: (params) => getDisplayNamesFromArray(params, period_intervals, 'value', 'label') },
        { field: 'periodinterval_days', filter: 'agNumberColumnFilter', headerName: Helper.getLabel(language, "period_interval_days", 'Period interval days') },
    ];

    if(!security.canView(Gui_id_list.setupForms.tax_settlement_periods.tax_settlement_periods_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment> 
            <div className='container-fluid settlement_periods'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'settlement_periods', 'Tax Settlement')}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.tax_settlement_periods.tax_settlement_periods_main_create_button)} onClick={() => setShowCreateModal(true)} />
                </NavigationHeder>

                <AgGridNew
                    apiUrl={Settings.apiUrl + '/settlementheader'}  
                    pagination={false}
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.tax_settlement_periods.tax_settlement_periods_main_delete_button)}
                    columnDefs={gridColumnHeaders} 
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'settlementperiodheader_id'}
                    onCellClicked={onCellClicked}
                    handleDeleteSelectedRows={deleteHandler}
                    onGridReady={(params) => gridApi = params?.api} 
                    gridId={"tax_settlement_periods"}
                />
            </div>
            {showCreateModal && <SettlementPeriodsModal setShowCreateModal={setShowCreateModal} />}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        TaxSmtPeriod:state.TaxSmtPeriod,
        language:state.language
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadPeriods: (TaxSmtPeriod) => LoadTaxSettlementPeriods(dispatch, TaxSmtPeriod)
});
 
const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.tax_settlement_periods.tax_settlement_periods_main
};

export default connect(mapStateToProps, mapDispatchToProps) ((( ApplySecurityRoles( SettlementPeriods, SecurityOptions)))); 