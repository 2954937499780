import React from 'react';
// This is the Skeleton component for when there's a delay in fetching data...
const ButtonSkeletonComponent = () => {
  return (
    <>
      <button className="button-skeleton"></button>
      <button className="button-skeleton"></button>
      <button className="button-skeleton"></button>
    </>
  );
}

const TextSkeletonComponent = () => {
  return (
    <>
      <button className="text-skeleton"></button>
    </>
  );
}

export { ButtonSkeletonComponent, TextSkeletonComponent };