import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import Settings from "../../../inc/Settings";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Button from "../../inc/Button";
import Popup from "../../Popup/Popup";


const RetenderModal = ({
  language,
  handleCloseModal,
  setRefreshAfterPublish,
}) => {
  const location = useLocation();

  const [actions, setActions] = useState([]);

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  useEffect(() => {
    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(`${Settings.loginUrl}/enum/RFvendorreply_action`)
      .then((res) => {
        setActions(res?.data.data);
      })
      .catch((error) => {
        console.log("Error in getting actions: ", error);
      });
  }, []);

  const onSubmit = () => {
    const action = actions.filter((el) => el.name === "Re-tender")[0].id;

    const payload = {
      rfq_id: parseInt(getSearchParam("rfq_view_id")),
      action: action,
    };

    const api = Api;
    api.setUserToken();
    api
      .axios()
      .post(
        `${Settings.apiPurchaseRequisitionUrl}/vendoraction_on_vendorreply`,
        payload
      )
      .then((res) => {
        setRefreshAfterPublish(true);
        Helper.alert(res.data?.message, "success");
        setTimeout(() => {
          setRefreshAfterPublish(false);
        }, 100);
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  return (
    <Popup
      autoOpen={true}
      width="600px"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        handleCloseModal();
      }}
    >
      <>
        <div className="d-flex justify-content-between">
        <h5 className="create_rfq_modal__title">{Helper.getLabel(language, "re-tender", "Re-tender")}</h5>
        <div
          className="addDocumentNumber-body-buttons"
          style={{ justifyContent: "flex-end", gap: "16px" }}
        >
          <Button
            isActive={false}
            title={Helper.getLabel(language, "no", "No")}
            onClick={() => {
              $("html").removeClass("rs_popup_open");
              handleCloseModal();
            }}
          />
          <Button
            className="solid_btn publish_rfq_blue_btn"
            isActive={true}
            title={Helper.getLabel(language, "yes", "Yes")}
            onClick={() => {
              onSubmit();
              $("html").removeClass("rs_popup_open");
              handleCloseModal();
            }}
          />
        </div>
        </div>

        <h6 className="inviteModalSubHeading">
          {Helper.getLabel(
            language,
            "you_want_to_re-tender",
            "Are you sure you want to re-tender this Request for Quotation? This action will revert the RFQ to draft state, allowing you to modify the scope, add new purchase requisitions, update vendor information, and make other necessary changes. Please note that all subsequent operations such as technical evaluation, commercial evaluation, and awarding will need to be re-done."
          )}
        </h6>
      </>
    </Popup>
  );
};

export default RetenderModal;
