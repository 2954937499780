import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper'; 
import SimpleLoading from '../Loading/SimpleLoading';
import DropDownInput from '../Forms/Dropdown';
import Input from '../Forms/Input';
import Button from '../inc/Button';

class ThemeSettings extends Component {
    constructor(props){
        super(props);
        this.isTenantSettingLoaded = false;
        let language = this.props.language;
        this.state = {
            isLoading:false,
            settings:this.props.defaultThemeSettings
        }
        this.textBoxInputSettings = [
            {label:Helper.getLabel(language,'primary_color',"Primary color"), name:'primary_color'},
            {label:Helper.getLabel(language,'secondary_color',"Secondary color"), name:'secondary_color'},
            {label:Helper.getLabel(language,'header_color',"Header color"), name:'header_color'},
            {label:Helper.getLabel(language,'header_background_color',"Header background color"), name:'header_bg_color'},
            {label:Helper.getLabel(language,'sidebar_color',"Sidebar color"), name:'sidebar_color'},
            {label:Helper.getLabel(language,'sidebar_background_color',"Sidebar background color"), name:'sidebar_bg_color'}
        ]
        this.themes = [
            {label:'Default', value:'default'},
            {label:'Theme 1', value:'theme_1'},
            {label:'Custom', value:'custom'}
        ]
    }
    onInputChangeHandler(e){
        let settings = this.state.settings;
        this.setState({
            settings:{
                ...settings,
                [e.target.name]:e.target.value
            }
        })
    }
    displayCustomTheme(){
        let settings = this.state.settings;
        if(settings.theme !=='custom'){
            return;
        }
        return(
            <div className='row'>
                { this.textBoxInputSettings.map( (item,key) => { 
                    return (
                        <div className={item.className ? item.className : 'col-2'} key={key}><Input inputType="color" name={item.name} label={item.label} onChange={this.onInputChangeHandler.bind(this)} value={settings[item.name]} /></div>
                    )
                 }) }
            </div>
        )
    }
    saveHandler(e){
        let that = this;
        this.setState({
            isLoading:true
        });
        let api = Api;
        api.setUserToken();
        let newData = {
            theme_setting:this.state.settings
        }
        this.props.executeAPI(this.state.settings) 
    }
    render() {
        let language = this.props.language;
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        let settings = this.state.settings;
        return (
            <div className='theme_settings_page'>
                <DropDownInput options={this.themes} name="theme" label={Helper.getLabel(language,'theme','Theme')} value={settings.theme} onChange={this.onInputChangeHandler.bind(this)} />
                { this.displayCustomTheme()}
                <Button className='theme_settings_btn' title={Helper.getLabel(language,'save','Save')} onClick={this.saveHandler.bind(this)}/> 
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme:state.theme,
        language:state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTheme: (themeName,settings) => { dispatch({ type:ActionTypes.SET_THEME_SETTINGS,settings:settings,theme: themeName})}
    }
}
export default connect (mapStateToProps,mapDispatchToProps) (  ThemeSettings );