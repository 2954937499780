import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import FormValidator from '../../inc/FormValidator';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import AgGrid from '../grid/ag/ag-grid';
import Button from '../inc/Button';
import Collapse from '../inc/Collapse';
import LinkBtn from '../inc/LinkBtn';
import RsWithRouter from '../inc/RsWithRouter';
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder';
import SegmentsRange from './popups/SegmentsRange';
import $ from 'jquery';
import SegmentsParents from './popups/SegmentsParents';
import TextTranslations from '../Popup/TextTranslation/TextTranslations';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';
class Segments extends Component {
    constructor(props){
        super(props);
        this.isSegmentsLoaded = false;
        this.saveButtonTimeout = null;
        this.gridObj = null;
        this.sourceId = 51;
        this.state = {
            isOpenSegmentsRange:false,
            isOpenSegmentParentPopup:false,
            isLoading:false,
            selectedSegment: null,
            gridData:[]
        }
        this.validationConfigure();
    }
    componentDidMount(){
        this.loadSegments();
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    loadSegments(forceLoad = false){
        if(this.isSegmentsLoaded && !forceLoad){
            return;
        }
        this.isSegmentsLoaded = true;
        let api = Api;
        let that = this;
        that.setState({
            isLoading:true,
            gridData:[]
        })
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment').then(res => {
            let data = res.data.data.reverse();
            that.setState({
                isLoading:false,
                gridData:data
            })
        }).catch( error => {
            that.setState({
                isLoading:false
            })
        })
    }
    onSaveHandler(e){
        if(!this.gridObj){
            return;
        }
        let validData = {}
        if(this.gridObj.componentObj.hasErrors()){
            return;
        }
        if(!this.validator.isValid(validData,[this.gridObj])){
            this.validator.displayMessage(this);
            return;
        }
        clearTimeout(this.saveButtonTimeout); // clear before assign new value inside the timmer
        this.saveButtonTimeout = setTimeout(function(){ // asign timeout
            $('.segments_page .rs_grid_btn_save').trigger('click');
        },100)

    }
    onGridSave(data){
        let createData = [], updteData = [];
        data.forEach( dataItem => {
            dataItem.segment_id ?  updteData.push(dataItem) :  createData.push(dataItem)
        })
        if(createData.length >=1){
            this.createNewSegment(createData)
        }
        if(updteData.length >=1){
            this.updateSegments(updteData)
        }
    }
    createNewSegment(data){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let itemsCounts = data.length;
        data.forEach( dataItem => {
            let newData = {
                sequence:dataItem.sequence,
                name:dataItem.name,
                display_name:dataItem.display_name,
                external_code:dataItem.external_code,
                segment_value_character:dataItem.segmentValue_character,
                isactive:dataItem.isactive == 'yes' || dataItem.isactive === true ? true : false,
                is_mainaccount:dataItem.is_mainaccount == 'yes' || dataItem.is_mainaccount === true ? true : false,
                isfollowinghierarchy:dataItem.isfollowinghierarchy == 'yes' || dataItem.isfollowinghierarchy === true ? true : false,
                translationsource_id:null
            }
            api.axios().post(Settings.apiUrls.segments +'/segment' ,newData ).then(res => {
                itemsCounts--;
                if(itemsCounts <=0){
                    Helper.alert(res.data.message);
                    that.setState({
                        isLoading:false
                    })
                    that.loadSegments(true)
                }
            }).catch(error => {
                Helper.alert(error.response.data.message)
                that.loadSegments(true);
            })
        })
    }
    updateSegments(data){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let itemsCounts = data.length;
        data.forEach( dataItem => {
            let newData = {
                sequence:dataItem.sequence,
                name:dataItem.name,
                display_name:dataItem.display_name,
                external_code:dataItem.external_code,
                segment_value_character:dataItem.segmentValue_character,
                isactive:dataItem.isactive == 'yes' || dataItem.isactive === true ? true : false,
                is_mainaccount:dataItem.is_mainaccount == 'yes' || dataItem.is_mainaccount === true ? true : false,
                isfollowinghierarchy:dataItem.isfollowinghierarchy == 'yes' || dataItem.isfollowinghierarchy === true ? true : false,
                //translationsource_id:null
            }
            api.axios().put(Settings.apiUrls.segments +'/segment/'+dataItem.segment_id ,newData ).then(res => {
                itemsCounts--;
                if(itemsCounts <=0){
                    Helper.alert(res.data.message);
                    that.setState({
                        isLoading:false
                    })
                    that.loadSegments(true)
                }
            }).catch(error => {
                Helper.alert(error.response.data.message)
                that.loadSegments(true);
            })
        })
    }
    setValueOnClick(e){
        if(!this.gridObj){
            return;
        }
        const language = this.props.language;
        let selectedItems = this.gridObj.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert(Helper.getLabel(language,'"msg_select_segment"','"Please select a segment"'),'error')
            return;
        }
        this.props.rs_router.navigate('/segments/segments-value/'+selectedItems[0].segment_id)
    }
    rollupGroupBtnClick(e){
        if(!this.gridObj){
            return;
        }
        const language = this.props.language;
        let selectedItems = this.gridObj.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert(Helper.getLabel(language,'"msg_select_segment"','"Please select a segment"'),'error')
            return;
        }
        this.props.rs_router.navigate('/segments/set-rollup-groups/'+selectedItems[0].segment_id)
    }
    setParentOnClick(e){
        if(!this.gridObj){
            return;
        }
        const language = this.props.language;
        let selectedItems = this.gridObj.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert(Helper.getLabel(language,'"msg_select_segment"','"Please select a segment"'),'error')
            return;
        }
        this.setState({
            selectedSegment:selectedItems[0],
            isOpenSegmentParentPopup:true
        })
        //this.props.rs_router.navigate('/segments/segment_parent/'+selectedItems[0].segment_id)
    }
    onGridReady(grid){
        this.gridObj = grid;
    }
    onSelectionChanged(event){
        let selectedRows = event.api.getSelectedRows();
        if(selectedRows.length <=0){
            $('.rs_setvalue_btn').addClass('disable');
            $('.visible_on_select_item').addClass('disable');
        }else{
            $('.rs_setvalue_btn').removeClass('disable');
            $('.visible_on_select_item').removeClass('disable');
        }

    }
    popupCloseHander(){
        this.setState({
            isOpenSegmentsRange:false,
            isOpenSegmentParentPopup:false,
        })
    }
    onDeleteHandler(data){
        let api = Api;
        let that = this;
        api.setUserToken();
        data.forEach(dataItem => {
            if(dataItem.segment_id){
                api.axios().delete(Settings.apiUrls.segments +'/segment/'+dataItem.segment_id).then(res => {
                    Helper.alert(res.data.message);
                }).catch( error => {
                    that.gridObj.componentObj.undorDelete()
                    Helper.alert(error.response.data.message,'error');
                })
            }
            
        })
    }
    render() {
        const language = this.props.language;
        
        let gridHeader= [
            {field:'name', headerName: Helper.getLabel(language,"name",'Name *'),validationRule: {types:['Required'], min:1, max:80} },
            {field:'display_name', headerName: Helper.getLabel(language,"display_name",'Display name *'),validationRule: {types:['Required'], min:1, max:80} },
            {field:'external_code', headerName: Helper.getLabel(language,"external_code",'External code')},
            {field:'segmentValue_character', headerName: Helper.getLabel(language,"characters",'Characters *'),validationRule: {types:['Required','Number'], min:1, max:2}},
            {field:'sequence', headerName: Helper.getLabel(language,"sequence",'Sequence *'),validationRule: {types:['Required','Number'], min:1, max:10} },
            {field:'isfollowinghierarchy', headerName: Helper.getLabel(language,"following_hierarchy",'Following hierarchy'), inputType:'checkbox', cellDataType:false},
            {field:'isactive', headerName: Helper.getLabel(language,"active",'Active'), inputType:'checkbox',cellDataType:false},
            {field:'is_mainaccount', headerName: Helper.getLabel(language,"active",'Main account'), inputType:'checkbox', cellDataType:false},
        ];
        let selectedSegment = this.state.selectedSegment;

        if(!this.props?.security.canView(Gui_id_list.setupForms.segments_master.segments_main)){ 
            return <Fragment>
              <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </Fragment>
        }
        return (
            <div className='segments_page'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language,'segments','Segments')}>
                    <TextTranslations isDisable={ !selectedSegment }  source_id={this.sourceId} source_integrator = {2} translationsource_id={1} original_text={1}  product_number={1}/>
                    <LinkBtn to="/setup" isActive={false} className="black-btn-style" title={Helper.getLabel(language,'Close','Close')}/>
                    <Button isDisable={!this.props?.security.canCreate(Gui_id_list.setupForms.segments_master.segments_main_create_button)} onClick={ this.setValueOnClick.bind(this)} className="blue disable rs_setvalue_btn" title={Helper.getLabel(language,'Value','Value')}/>
                   {this.state.isSaving ? <div style={{width:"150px"}}><SimpleLoading/></div> :  <Button isDisable={!this.props?.security.canCreate(Gui_id_list.setupForms.segments_master.segments_main_create_button)} onClick = { e => this.onSaveHandler(e) }  isActive={false} className="rfq_save_btn" title={Helper.getLabel(language,'save','Save')}/> }
                </NavigationHeder>
                { this.state.isLoading ? <SimpleLoading/> : ''}
                <div className='container-fluid grid_save_btn_hide'>
                    <Collapse title={Helper.getLabel(language,'segments','Segments')} open={true} className="mb-4 mt-4">
                        <AgGrid hideDelete={!this.props?.security.canDelete(Gui_id_list.setupForms.segments_master.segments_main_delete_button)} addBtnHide={ !this.props?.security.canCreate(Gui_id_list.setupForms.segments_master.segments_main_create_button) } settings={{singleClickEdit:false}} onSelectionChanged={this.onSelectionChanged.bind(this)} id="segments_grid" onGridReady={ this.onGridReady.bind(this)} onDelete={this.onDeleteHandler.bind(this)} singleRowSelect ={true} onSave={ this.onGridSave.bind(this)}  header={gridHeader} data={this.state.gridData}/>
                    </Collapse>
                </div>
                { this.state.isOpenSegmentsRange ? <SegmentsRange language={language}/> : ''}
                { this.state.isOpenSegmentParentPopup ? <SegmentsParents onPopupClose={ this.popupCloseHander.bind(this) } segment={this.state.selectedSegment} language={language}/> : ''}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}

const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.segments_master.segments_main
};

export default connect(mapStateToProps) ((RsWithRouter(ApplySecurityRoles(Segments, SecurityOptions))));