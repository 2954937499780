class ColorPicker {
    constructor() {
        this.eGui = document.createElement('div');
        this.eGui.classList.add('color-picker-editor');
    
        this.colorPicker = document.createElement('input');
        this.colorPicker.type = 'color';
        this.colorPicker.addEventListener('input', this.onColorChange.bind(this));
    
        this.eGui.appendChild(this.colorPicker);
    }
    
    init(params) {
        this.params = params;
        this.colorPicker.value = params.value || '';
    }
    
    getGui() {
        return this.eGui;
    }
    
    afterGuiAttached() {
        this.colorPicker.focus();
    }
    
    getValue() {
        return this.colorPicker.value;
    }
    
    onColorChange(event) {
        // Implement logic to handle the color change
        this.params.data[this.params.colDef.field] = event.target.value;
        this.params.api.stopEditing();
    }
}

export default ColorPicker;