import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import { checkNullProperties } from '../../../inc/Validation';
import AgGrid from '../../grid/ag/ag-grid';
import Collapse from '../../inc/Collapse';

const PriceDiscountVersion = ({language, data, payment}) => {
  console.log(checkNullProperties(data), data)
  // Grid Things -

  const Columns_Headings = [ 
    {field:'priceinclude_tax', headerName:Helper.getLabel(language,'priceinclude_tax',"Price Include Tax"),inputType: "checkbox", minWidth: 250, editable: false},
    {field:'paymentterm_id', headerName:Helper.getLabel(language,'paymentterm_id',"Payment Term "), inputType: "dropdown", options: payment, minWidth: 250, editable: false}, 
    {field:'discount_percentage', headerName:Helper.getLabel(language,'discount_percentage',"Discount Percentage"), minWidth: 250, editable: false},
    {field:'discount_amount', headerName:Helper.getLabel(language,'discount_amount',"Discount Amount"), minWidth: 250, editable: false},
  ]

  // ---

  return (
    <Collapse className='price_discount_po_version' open={true} title={Helper.getLabel(language,'price_discount',"Price & Discount")}>
      <div className='container-fluid price_discount_po_version'>
        <AgGrid data={checkNullProperties(data) ? [] : data} id="rs_price_discount_po_version" allBtnHide={true} header={Columns_Headings} />
      </div>
    </Collapse>
  )
}

 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
  
export default connect(mapStateToProps) (PriceDiscountVersion) 