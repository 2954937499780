import React from 'react'
import DashboardInfo from '../Dashboard Information/DashboardInfo'
import DashboardNav from '../Dashboard Navigation/DashboardNav' 
import { connect } from 'react-redux' 

const DashboardContainer = (props) => {
  return ( 
    <div className={`${props?.auth?.user?.usertype === 0 && 'ps-5 pe-5'} container-fluid`}>
      <DashboardNav />
    </div> 
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
} 
  
export default connect(mapStateToProps) (((DashboardContainer)))