import React from 'react';
import Button from '../../inc/Button';
import BusinessClassEdit from '../../Vendors/Vendors Edit/BusinessClassEdit';

let gridObj = null;

const BusinessClassificationDetails = (props) => {
    let total = 0;

    const onGridReady = (event) => {
        gridObj = event;
    }

    const getTotalItems = () => {
        if (gridObj) {
            gridObj.api.forEachNode(item => {
                total++;
            });
        }
        return total;
    }

    const goStep = (stepNumber) => {
        let newTotal = getTotalItems();
        if (newTotal > 0) {
            props.updateStep(3, true);
        } else {
            props.updateStep(3, false);
        }
        props.goStep(stepNumber);
    }

    const businessClassificationGet = (response) => {
        if (response?.length > 0) {
            props.updateStep(3, true);
        } else {
            props.updateStep(3, false);
        }
    }

    let activeStep = props.activeStep;
    let data = props.data;
    let disableCondition = data?.document_status!=="Draft";
    return (
        <div className='vendor_regis_contact_page'>
            <BusinessClassEdit disableFields={disableCondition} id={data?.vendor_id} onGridReady={onGridReady} businessClassificationGet={businessClassificationGet} getBusinessClassifications={props.getBusinessClassifications}/>
            <div className='tabs_changer'>
                {activeStep >= 2 ? <Button className="light_gray btn_back" title="Back" onClick={e => goStep(activeStep - 1)}/> : '' }
                <Button className="blue" title="Next" onClick={e => goStep(activeStep + 1)}/>
            </div>
        </div>
    );
}

export default BusinessClassificationDetails;