import React from 'react'
import Collapse from '../../inc/Collapse'
import Helper from '../../../inc/Helper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import CurrencyRenderer from '../../grid/ag/cellRender/CurrencyRenderer'

const AwardingLinesGrid = ({ language, apiUrl, additionalParams}) => {
  const Columns_Headings = [
    {
      field: 'vendor_name',
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
      minWidth: 180,
    },
    {
      field: 'item_name',
      headerName: Helper.getLabel(
        language,
        'item_name',
        'Item name'
      ),
      editable: false,
      minWidth: 180,
      valueFormatter: (params) => params.value || '-',
    },
    {
      field: 'ordered_qty',
      headerName: Helper.getLabel(language, 'quantity', 'Quantity'),
      editable: false,
      minWidth: 150,
    },
    {
      field: 'unit_name',
      headerName: Helper.getLabel(language, 'unit', 'Unit'),
      editable: false,
      minWidth: 150,
      valueFormatter: (params) => params.value || '-',
    },
    {
      field: 'unit_price',
      headerName: Helper.getLabel(language, 'unit_price', 'Unit price'),
      editable: false,
      cellRenderer:CurrencyRenderer,
      minWidth: 150,
    },
    {
      field: 'discount_amount',
      headerName: Helper.getLabel(
        language,
        'discount_amount',
        'Discount amount'
      ),
      cellRenderer:CurrencyRenderer,
      editable: false,
      minWidth: 150,
    },
    {
      field: 'discount_percentage',
      headerName: Helper.getLabel(
        language,
        'discount_percentage',
        'Discount percentage'
      ),
      editable: false,
      cellRenderer:CurrencyRenderer,
      minWidth: 180,
    },
    {
      field: 'line_amount',
      headerName: Helper.getLabel(language, 'line_amount', 'Line amount'),
      editable: false,
      cellRenderer:CurrencyRenderer,
      minWidth: 150,
    },
  ]
  return (
    <Collapse
      className='mb-4'
      open={true}
      title={Helper.getLabel(language, 'awarded_lines', 'Awarded Lines')}
    >
      <AgGridNew 
        gridId={'rfq_header_awarding_table_1'}
        hideDeleteBtn={true}
        hideAddBtn={true}
        columnDefs={Columns_Headings}
        additionalParams={additionalParams}
        // hidePaginationFooter={true}
        apiUrl={apiUrl}
        pagination={true}
      />
    </Collapse>
  )
}

export default AwardingLinesGrid
