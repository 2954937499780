import React, {useState, Fragment, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls';
import { checkIfArrayIsEmpty, getLocalTimeAndDate } from '../../../inc/Validation';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import TaskActionDropdown from '../../Dashboard/Dashboard Navigation Components/TaskActionDropdown';
import SimpleLoading from '../../Loading/SimpleLoading';

// default_clr for actionTaken will show the blue color
// pending_clr for actionTaken will show the orange-type color

const ActionsHistory = ({ historyArray, actionHistoryHeight=0, actionHistoryLoadingState, setActionHistoryState, showActionHistoryDropdown=false, showCanvas, openCurrentAssignees }) => { 
    const [actionStatus, setActionStatus] = useState([]);
    const [sources, setSources] = useState([]);

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null); 
    const [openKPIViolateIndex, setOpenKPIViolateIndex] = useState(null);  

    // Closing Modal Popups Refs
    const KPIViolateModalPopup = useRef(null);
    const DropdownDateModalPopup = useRef(null);

    let actionHistoryStatusCalled = false;
    let sourcesByParentCalled = false;

    useEffect(() => { 
        getActionHistoryInstanceStatus();
        getSourcesByParent();
    }, []); 
 
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (KPIViolateModalPopup.current && !KPIViolateModalPopup.current.contains(event.target)) OpenKPIViolate(-1); 
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openKPIViolateIndex]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (DropdownDateModalPopup.current && !DropdownDateModalPopup.current.contains(event.target)) OpenDropdown(-1); 
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openDropdownIndex]);

    const getSourcesByParent = () => {
        if (sourcesByParentCalled || !showActionHistoryDropdown) return;
        sourcesByParentCalled = true;

        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiUrl + `/source?parent_id=${Settings.source_id.request_for_quotation}`, {}).then(function (res) {
            if (res.data.status === "success") { 
                setSources(
                    res?.data?.data
                        ?.filter((element) => element.source_id !== 61 && element.source_id !== 67)
                        .map((element) => {
                            return {
                                value: element.source_id,
                                label: element.source_name
                            };
                        })
                );
            }
        })
    }

    const getActionHistoryInstanceStatus = async () => {
        if(actionHistoryStatusCalled){
            return;
        }
        actionHistoryStatusCalled = true 

        const workflowHistoryCalls = new WorkFlowCommonHeaderAPICalls()
        const statuses = await workflowHistoryCalls.getWorkFlowInstanceStatus()
        setActionStatus(statuses)
    }
  
    const OpenDropdown = (index) => { setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index)); };

    const OpenKPIViolate = (index) => { setOpenKPIViolateIndex((prevIndex) => (prevIndex === index ? null : index)); }

    const displayCurrentState = (actionState) => {
        let actionPerformed = actionStatus && actionState && actionStatus.find(val => val.id === actionState)?.name;
        switch(actionPerformed){
            case 'Completed':
                return <h2 className='completed'><span className='action-label'>Current State:</span> <span className='action-performed'>{actionPerformed}</span></h2>;
            case 'Rejected':
                return <h2 className='rejected'><span className='action-label'>Current State:</span> <span className='action-performed'>{actionPerformed}</span></h2>;
            case 'In Progress':
                return <h2 className='in-process'><span className='action-label'>Current State:</span> <span className='action-performed'>{actionPerformed}</span></h2>;
            case 'Failed': 
                return <h2 className='cancelled'><span className='action-label'>Current State:</span> <span className='action-performed'>{actionPerformed}</span></h2>;
            case 'Recalled': 
                return <h2 className='recalled'><span className='action-label'>Current State:</span> <span className='action-performed'>{actionPerformed}</span></h2>;
            default:
                break;
        }
    }

    const processUserProfileImage = (image) => { 
        const imageData = image?.slice(2,-1)
        const imageUrl = `data:image/${image};base64,${imageData}`;

        return image ? imageUrl : '/images/blank-profile.jpg';
    };

    const onSourceDropdownChange = (value) => { setActionHistoryState(value) };

    return (
        <Fragment> 
            { showActionHistoryDropdown 
                ?
                    <TaskActionDropdown applyCustomStyles={true} defaultPlaceholderLabel={'Source'} APIData={sources} onChangeHandler={onSourceDropdownChange} />
                : 
                    null
            }

            { actionHistoryLoadingState ? <SimpleLoading /> :
                ( 
                    <div className='action-history-comp'> 
                        <div className='action_history' style={{height: `${actionHistoryHeight}px`}}> 
                            <div className='actions_taken'>
                                <div className='action_status mb-3'>
                                    <div className='d-flex justify-content-evenly align-items-start'>
                                    <div className='current_state_wrapper'>{historyArray !== undefined && displayCurrentState(historyArray?.currentState)}</div>  
                                        {(showActionHistoryDropdown && historyArray?.history?.length > 0) || !showActionHistoryDropdown ? (
                                            <div className='current_assignees_history_wrapper'>
                                                <button onClick={() => openCurrentAssignees(true)}>View current assignees</button>
                                                <button onClick={() => showCanvas(true)}>View history</button>
                                            </div>
                                        ) : null}

                                        {/* {(showActionHistoryDropdown && historyArray?.history?.length > 0) || !showActionHistoryDropdown ? (
                                            <div className='excel_export_wrapper'>
                                                <img src='/images/icons/excel-logo.svg' className='img-fluid' />
                                            </div>
                                        ) : null }  */}
                                    </div>
                                </div>
                                <div className='container-fluid'>
                                    { 
                                        historyArray !== undefined && !checkIfArrayIsEmpty(historyArray?.history) ? historyArray?.history?.map((element, index) => {  
                                            return (
                                                <Fragment>
                                                    <div className={`row action_history_info_wrapper`}> 
                                                        <div className='col-2'>
                                                            <div className='user-logo-container'>
                                                                <div className='user-logo' style={{ backgroundImage: `url("${processUserProfileImage(element.logo)}")`, border: !element.logo ? '1px solid #16084C' : 'none' }}></div>
                                                            </div>
                                                        </div>

                                                        <div className='col-10 m-0 p-0 history-short-details'> 
                                                            <div className='row' style={{position: 'relative'}}>
                                                                <div className='col-10 justify-content-start d-flex align-items-start'>
                                                                    <h2 style={{ whiteSpace: 'pre-line' }}>
                                                                        {element.action_taken === "Submitted by"
                                                                            ? 'Submitter'
                                                                            : element.action_taken === "Recall by"
                                                                            ? "Recalled by Submitter"
                                                                            : element.stageName
                                                                        }
                                                                    </h2>
                                                                </div>
                                                                <div className='col-10 flex-column justify-content-start d-flex align-items-start'> 
                                                                {element.action_taken !== "Submitted by" &&
                                                                        <Fragment>
                                                                            <div className='mb-1 d-flex'> 
                                                                                <h4><span style={{fontWeight: 'bold', color:'#000'}}>Task assigned:</span> {element?.assigned_date?DatepickerFunctions.convertDateTimeFromDataBase(element?.assigned_date):'-'}</h4>
                                                                                { element.kpi_violate && <div className='ms-2 kpi-flag' onMouseEnter={() => OpenKPIViolate(index)}> <img className='img-fluid' src='/images/icons/kpi.svg' /> </div> }
                                                                                {openKPIViolateIndex === index && (
                                                                                    <div className='dropdown-menu-kpi' ref={KPIViolateModalPopup}> 
                                                                                        <h5><span>KPI Violated:</span> Have not complete task within assigned time.</h5>
                                                                                    </div>
                                                                                )} 
                                                                            </div>
                                                                            <div className='mb-1'> <h4><span style={{fontWeight: 'bold', color:'#000'}}>Due at:</span> {element?.due_date?DatepickerFunctions.convertDateTimeFromDataBase(element?.due_date):'-'}</h4></div> 
                                                                            <div className='mb-1'> <h4><span style={{fontWeight: 'bold', color:'#000'}}>KPI:</span> {element.duration} {element.duration_type}</h4></div>
                                                                            <div className='mb-1'> <h4><span style={{fontWeight: 'bold', color:'#000'}}>Actioned on:</span> {element?.action_taken_at?DatepickerFunctions.convertDateTimeFromDataBase(element?.action_taken_at):'-'}</h4></div>
                                                                        </Fragment>
                                                                    }
                                                                    <h3><span className={element.action_taken == 'Pending by' ? 'pending_clr' : 'default_clr'}>{element.action_taken}:</span> {element.name}</h3>
                                                                </div>
                                                                <div className='col-2 list-actions-btn' onClick={() => OpenDropdown(index)}>
                                                                    <img className='img-fluid' src='/images/icons/list-actions.svg' /> 
                                                                </div>
                                                            </div>
                
                                                            {openDropdownIndex === index && (
                                                                <div className='dropdown-menu-task' ref={DropdownDateModalPopup}>
                                                                    <h5 className='mb-2'>Task assigned: {DatepickerFunctions.convertDateTimeFromDataBase(element?.assigned_date)}</h5>
                                                                    <h5>KPI: {element.duration} {element.duration_type ? element.duration_type+'(s)' : ''}</h5>
                                                                </div>
                                                            )}
                                
                                                            <div className={`row ${element.comment ? 'mb-3' : 'mb-2 d-none' }`}>
                                                                <div className='col-11'>
                                                                    <div className='action_history_user_comms'>
                                                                        <h3>{element.comment}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </Fragment>
                                            )
                                        })
                                    : 
                                        <div className='text-center'>No action history found</div>
                                    }
                                </div>
                            </div> 
                        </div>
                    </div> 
                )
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

export default connect(mapStateToProps) (ActionsHistory);