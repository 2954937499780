import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../../Purchase Order/ExtPO.css'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import AgGrid from '../../../grid/ag/ag-grid'
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import ButtonNew from '../../../inc/ButtonNew'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'

const CreditNotesCreatedByMe = (props) => {
  const navigate = useNavigate()

  const invoiceApiCalls = new WorkConfirmationApiCalls()

  const [loading, setLoading] = useState(false)
  const [documentStatus, setDocumentStatus] = useState([])
  const [invoiceData, setInvoiceData] = useState([])

  const gridLinkValueFormat = (params) => {
    const value = params.value === null ? '-' : params.value
    return `<div class="rs_grid_simple_link" style="margin-left:6px;">${value}</div>`
  }

  const getDocumentStatus = (params) => {
    const statusName = documentStatus?.find(
      (option) => option.id === params.value,
    )
    return statusName
      ? `<div><div class="rs_ag_status_label ${Helper.getClassFromString(
          statusName?.name,
        )}">${statusName?.name}</div></div>`
      : null
  }

  const CellHandler = (event) => {
    if (event.colDef.field === 'reference_number') {
      navigate(`/invoice-details?invoice_id=${event.data.invoice_id}`)
    }
  }

  const getAsyncData = async () => {
    setLoading(true)
    const documentStatus = await invoiceApiCalls.getAllShDocumentStatus()
    if (documentStatus) setDocumentStatus(documentStatus)
    if (documentStatus) setLoading(false)
  }

  const get_invoice_data = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/invoice_header?invoice_type=1&createdby=${props.auth.user.user_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setInvoiceData(res.data?.data)
          setLoading(false)
        }
      })
      .catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  useEffect(() => {
    get_invoice_data()
    getAsyncData()
  }, [])

  const Columns_Headings = [
    {
      field: 'reference_number',
      headerName: Helper.getLabel(props.language, 'invoice_no', 'Invoice no.'),
      minWidth: 220,
      editable: false,
      valueFormatter: gridLinkValueFormat,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'description',
      minWidth: 180,
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      editable: false,
    },
    {
      field: 'document_number',
      headerName: Helper.getLabel(
        props.language,
        'source_document_no',
        'Source document number',
      ),
      minWidth: 200,
      editable: false,
      valueFormatter: gridLinkValueFormat,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'vendor_code',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'vendor_code', 'Vendor code'),
      editable: false,
    },
    {
      field: 'vendor_name',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'name', 'Name'),
      editable: false,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(props.language, 'site', 'Site'),
      editable: false,
    },
    {
      field: 'document_status',
      minWidth: 200,
      headerName: Helper.getLabel(
        props.language,
        'document_status',
        'Document status',
      ),
      editable: false,
      valueFormatter: getDocumentStatus,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'accounting_date',
      minWidth: 200,
      inputType: 'datepicker',
      headerName: Helper.getLabel(
        props.language,
        'accounting_date',
        'Accounting date',
      ),
      editable: false,
    },
    {
      field: 'document_date',
      minWidth: 200,
      inputType: 'datepicker',
      headerName: Helper.getLabel(
        props.language,
        'document_date',
        'Document date',
      ),
      editable: false,
    },
  ]

  const handleNewButtonClick = () => {
    navigate('/invoice/new')
    localStorage.removeItem('savedInvoiceSteps')
  }

  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'work-confirmation-internal-screen'
          : 'purchase_orders_listing_archive'
      }
    >
      <div className="container-fluid">
        <NavigationHeder backUrl="/finance" title={`Credit Note - Created by Me`}>
          <ButtonNew
            onClick={handleNewButtonClick}
            title={Helper.getLabel(props.language, 'new', 'New')}
          />
        </NavigationHeder>
        {loading ? (
          <SimpleLoading />
        ) : (
          <AgGrid
            id="rs_invoice_listing"
            hideDelete={true}
            onCellClicked={CellHandler}
            header={Columns_Headings}
            data={invoiceData}
            addBtnHide={true}
            hideSave={true}
            viewOnly={true}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(MasterComponentWraper(CreditNotesCreatedByMe));