import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";

class DropSelect extends Component {
  render() {
    const { options, prefix, handleChange } = this.props;
    return (
      <div className="dropSelect">
        <span>{prefix}</span>&nbsp;
        <select
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          value={this.props.value}
        >
          {options.map((option, index) => (
            <option value={option.option} key={index + option.key}>
              {Helper.getLabel(this.props.language, option.key, option.option)}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(DropSelect);
