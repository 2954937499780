import React from 'react'; 
import PurchaseRequisitionList from '../../components/PurchaseRequisition/PurchaseRequisitionList';
import Gui_id_list from '../../inc/Gui_id_list';
import { connect } from 'react-redux';
import MasterComponentWraper from '../../components/Backend/MasterComponentWraper';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Alert from '../../components/inc/Alert';
import { checkForRolesPermission } from '../../inc/Validation';

const PurchaseRequisitionScreen = ({security, user}) => {
  if ( user?.usertype===0 && !security?.canView(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main) ) {
    return ( 
      <Alert
        message="You do not have any access to the following screen, please contact your administrator!" 
        type="danger" 
      />  
    );
  };

  return ( 
    <PurchaseRequisitionList 
      isDisableDeleteBtn={(user?.usertype===0 && !security?.canDelete(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_delete_button)) || checkForRolesPermission(user?.roles, 'Purchase Requisition')}
      isDisableCreateBtn={user?.usertype===0 && !security?.canCreate(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_create_button)} 
    />
  );
};
 
const mapStateToProps = (state) => {
  return { 
    user:state.auth.user
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.request.purchase_requisitions.purchase_requisitions_main
}

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(PurchaseRequisitionScreen, SecurityOptions))))