import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import NavigationHeder from "../../Navigations/NavigationHeder";
import Helper from "../../../inc/Helper";
import ButtonNew from "../../inc/ButtonNew";
import SimpleLoading from "../../Loading/SimpleLoading";
import AgGrid from "../../grid/ag/ag-grid";
import CustomHtmlCell from "../../grid/ag/cellRender/CustomHtmlCell";
import RFQCommonApiCalls from "../../RequestForQuotation/RFQCommonApiCalls"; 
import CreateRFQModal from "../../RequestForQuotation/RFQDetailForm/RFQHeaderList/CreateRFQModal"; 
import Settings from "../../../inc/Settings";
import Api from "../../../inc/Api";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import Gui_id_list from "../../../inc/Gui_id_list";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Alert from "../../inc/Alert";

const RFI = (props) => {
  const urlParams = new URLSearchParams(useLocation().search);
  const current_user = urlParams?.get('user_id');
 
  const commonApiCalls = new RFQCommonApiCalls();
  const navigate = useNavigate();
  const [rfqHeaders, setRfqHeaders] = useState([]);
  const [rfqStatusList, setRfqStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateRFQModal, setShowCreateRFQModal] = useState(false);
  // START:: Modal Data
  const [employee, setEmployee] = useState([]);
  const [entity, setEntity] = useState([]);
  const [sites, setSites] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [securityLevel, setSecurityLevel] = useState([]);
  const [quotationType, setQuotationType] = useState([]);
  // END:: Modal Data
  //Pagination States
  const [pageNumber,setPageNumber]=useState(1)
  const [totalRecords,setTotalRecords]=useState(1)
  const [pageSize,setPageSize]=useState(10)
  const api = Api;

  const getQuotationType = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/enum/RQ_type")
      .then((res) => {
        setQuotationType(res.data.data);
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };
  
  const getAPIUrlBasedOnType = () => {
    let api_url = `${Settings.apiPurchaseRequisitionUrl}/rfq_header?rfq_type=1`
    if(current_user) api_url+= `&createdby=${current_user}`; 
    return api_url;
  };

  const getAllEmployee = () => {
    api
      .axios()
      .get(`${Settings.apiOrgUrl}/employee`)
      .then((res) => {
        if (res.data.status === "success") {
          setEmployee(res.data?.data);
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const getAllEntity = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/get_entities", {
        code: null,
        name: null,
      })
      .then((res) => {
        if (res.data.status === "success") {
          setEntity(res.data?.data);
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const getSites = async () => {
    const res = await commonApiCalls.getAllSites();
    setSites(res);
  };

  const getWarehouse = async () => {
    const res = await commonApiCalls.getAllWarehouses();
    setWarehouse(res);
  };

  const getSecurityLevel = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/enum/RQ_securitylevel")
      .then((res) => {
        setSecurityLevel(res.data.data);
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const initModalData = () => {
    api.setUserToken();
    getSites();
    getWarehouse();
    getSecurityLevel();
    getQuotationType();
    getAllEntity();
    getAllEmployee();
  };

  const cellHandler = (event, navigate) => {
    if (event?.value && event?.colDef?.field === "document_number") {
      navigate(
        "/request-for-quotation/rfqdetailform?rfq_view_id=" +
          event?.data?.RFQ_id +
          "&type=RFI&return=rfqheaderlist"
      );
    }
  };

  const getAsyncData = async () => {
    try {
      const headerData = await commonApiCalls.getAllRFIHeaders(pageNumber,pageSize,current_user);
      const rfqStatusData = await commonApiCalls.getAllRfqStatus();
      if (headerData) {
        setRfqHeaders(headerData?.data?.map((item) => {
          return {
            ...item,
            expiry_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.expiry_datetime) ,
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date) ,
            publish_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.publish_datetime) 
          }
        }));
        setTotalRecords(headerData?.total_records);
        setIsLoading(false);
      }
      if (rfqStatusData) setRfqStatusList(rfqStatusData);
    } catch (error) {
      Helper.alert(
        error?.response?.status === 500
          ? Helper.getLabel(
              this.props.language,
              "something_went_wrong",
              "Something went wrong"
            )
          : error?.response?.data?.message,
        "error"
      );
      setIsLoading(false)
    }
  };

  const getStatusNameById = (id) => {
    const status = rfqStatusList.find((option) => option.id === id);
    return status ? status.name : null;
  };

  const Columns_Headings = [
    {
      field: "document_number",
      headerName: Helper.getLabel(
        props.language,
        "document_number",
        "Document Number"
      ),
      cellRenderer: CustomHtmlCell,
      valueFormatter: (params) =>
        params.value
          ? `<div class="rs_grid_simple_link">${params.value}</div>`
          : "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "rfq_status",
      headerName: Helper.getLabel(props.language, "rfq_status", "RFQ Status"),
      valueFormatter: (params) => getStatusNameById(params.value),
      editable: false,
      minWidth: 150,
    },
    {
      field: "publish_datetime",
      headerName: Helper.getLabel(
        props.language,
        "publish_date_time",
        "Publish Date Time"
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "delivery_date",
      headerName: Helper.getLabel(
        props.language,
        "delivery_date",
        "Delivery Date"
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "expiry_datetime",
      headerName: Helper.getLabel(
        props.language,
        "expiry_datetime",
        "Expiry Date Time"
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
  ];

  const handlePageNumber = (pageNumberNew) => {
    setPageNumber(pageNumberNew)
  }

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize)
    setPageNumber(1)
  }

  const createNewRFQ = () => {
    setShowCreateRFQModal(true);
  };

  useEffect(() => {
    initModalData();
  }, []);

  useEffect(()=>{
    getAsyncData();
  },[pageNumber,pageSize])

  let security = props?.security;
  
  if (props.auth?.user?.usertype===0 && !security.canView(Gui_id_list.request.request_for_informations.request_for_informations_main)) {
    return (
      <Fragment> 
        <Alert 
          message="You do not have any access to the following screen, please contact your administrator!" 
          type="danger" 
        /> 
      </Fragment>
    );
  }

  return (
    <div className="items_archive"> 
      <div className="container-fluid">
        <NavigationHeder
          backUrl="/request"
          hideMoreBtn={true}
          title={Helper.getLabel(
            props.language,
            "requestion_for_informations",
            `Requestion for Informations - ${current_user ? 'Created by me' : 'All'}`
          )}
        >
          <ButtonNew 
            isDisable={props.auth?.user?.usertype===0 && !security.canCreate(Gui_id_list.request.request_for_informations.request_for_informations_main_create_button)} 
            onClick={createNewRFQ} 
          />
        </NavigationHeder>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <AgGrid
            id="rs_items_list"
            onCellClicked={(event) => {
              return cellHandler(event, navigate);
            }}
            hideDelete={true}
            dissableCheckBox={true}
            header={Columns_Headings}
            data={rfqHeaders}
            addBtnHide={true}
            hideSave={true}
            viewOnly={true}
            apiParamsForSearch={ getAPIUrlBasedOnType() }
            pagination={true}
            handlePageChange={handlePageNumber}
            totalRecords={totalRecords||1}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
          />
        )}
      </div>

      {showCreateRFQModal && (
        <CreateRFQModal
          employee={employee}
          entity={entity}
          sites={sites}
          warehouse={warehouse}
          securityLevel={securityLevel}
          quotationType={quotationType}
          language={props.language}
          setShowCreateRFQModal={setShowCreateRFQModal}
          type="RFI"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.request.request_for_informations.request_for_informations_main
}

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(RFI, SecurityOptions))))