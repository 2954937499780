let currentLocation = window.location
let currentOrigin = currentLocation?.origin

const location = origin;
const parsedUrl = new URL(location);
const hostnameParts = parsedUrl.hostname.split('.');
const subdomain = hostnameParts[0];

let Settings = {
    apiBaseUrl:'https://prod.system.lynkaz.com', // assets
    apiSystemUrl: `https://prod.system.lynkaz.com`,
    loginUrl:`https://${subdomain}.prod.common.lynkaz.com`,
    apiUrl:`https://${subdomain}.prod.common.lynkaz.com`,
    apiOrgUrl: `https://${subdomain}.prod.org.lynkaz.com` ,
    apiVendorUrl: `https://${subdomain}.prod.vendor.lynkaz.com`,
    apiItemUrl:  `https://${subdomain}.prod.item.lynkaz.com`,
    apiPurchaseOrderUrl: `https://${subdomain}.prod.po.lynkaz.com`,
    apiProcurementSettingsUrl: `https://${subdomain}.prod.config.lynkaz.com`,
    apiPurchaseRequisitionUrl: `https://${subdomain}.prod.pr.lynkaz.com`,
    apiWorkflowUrl: 'https://fn-prod-lynkaz-workflow.azurewebsites.net/api',
    apiUrls: {
        segments: `https://${subdomain}.prod.financials.lynkaz.com`
    },
    lynkaz_middleware_api: 'https://ba-fn-middleware.azurewebsites.net/api/migrate/v1/data_queue/',
    defaultLang:'en',
    userTokenKey:'lynkaq_user_token', 
    enumStatus: [ // this will be removed when api arrives
        {value: '0', label: 'Active'},
        {value: '1', label: 'Non active'},
        {value: '2', label: 'No status'},
    ],
    apiIntegrationUrl: 'https://ba-fn-middleware.azurewebsites.net/api/apps', 
    dateFormateKey:'lynkaq_dateformate',
    tenantSubdomain:'tenant_subdomain',
    languageKey:'app_lang',
    userTokenExpireDate:1,
    redirectUrlAfterLogin:'dashboard',
    redirectAdminAfterLogin:'lynkaz-administration',
    loginLink:'login',
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    debug:true,
    defaultDateFormate:'mm/dd/yyyy',
    source_id:{
        // follow the respective format for lowercase & if space in between add '_' separator,
        tenant:1,employee:18,item:24,warehouse:4,site:3,entity:2,address:5,vendor:6,vendor_registration:7,bank:8,purchase_order:12,PoLoLineSiteSegmentDistribution:58,
        PurchaseOrderlines:12,ShipmentGoodReceivingWorkConfirmation:54,release_order:13,contract:14,invoice:16,RfqVendorReply:63,request_for_quotation:10,
        credit_notes:81,return_order:54,invoiceLineDetails:64,purchase_requisition:9,rfq_technical_evaluation:77,rfq_awarding: 79,rfq_financial_evaluation: 78, purchase_agreement:15, vendor_contact_person:85, goods_receiving: 86, work_confirmation: 87, return_order: 88
    },
    document_types: {
        request_for_quotation: 'RFQ',
        purchase_order: 'Purchase Order',
        purchase_agreement: 'Purchase Agreement',
        release_order: 'Release Order',
        contract: 'Contract'
    },
    document_types_tnc: {
        request_for_quotation: 0,
        purchase_order: 2,
        purchase_agreement: 3, 
        contract: 1
    },
    sh_type:{
       goods_receiving:0,work_confirmation:1,return_order:2,
    },
    seedDataStatus: [
        { label: 'Active', value: true },
        { label: 'In-Active', value: false }
    ],
    invoice_type:{
        invoice:0,credit_note:1,
    },

    po_purchase_status:{
        open:{id:0,bgColor:'#EBFFF5',textColor:'#00AA55'},
        confirmed:{id:1,bgColor:'#EBF4FF',textColor:'#007BFF'},
        close:{id:2,bgColor:'#EBEBEB',textColor:'#808080'},
        reopen:{id:3,bgColor:'#EBFFF5',textColor:'#008F47'},
        freeze:{id:4,bgColor:'#F0F5FA',textColor:'#336699'},
        hold:{id:5,bgColor:'#FFEBEB',textColor:'#FF0000'},
        withdrawFreeze:{id:6,bgColor:'#EBFFF5',textColor:'#006633'},
        canceled:{id:7,bgColor:'#FFFFFF',textColor:'#007BFF'},
        invoiced:{id:9,bgColor:'#FFF0EB',textColor:'#FC5E24'},
        amendment_in_process:{id:10,bgColor:'#FFFFFF',textColor:'#000000'}
    },
    lineTypesId:{comment:"2", item:"0", category:"1"},
    defaultZeroAfterDot:2,
    validator:{
        max:{
            code:10,
            name:80
        }
    },
    shType:{
        returnOrder:2,
        po:0
    },
    dropdownPageSize:10
}
export default Settings;
