import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import AgGrid from '../../grid/ag/ag-grid'
import SimpleLoading from '../../Loading/SimpleLoading'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'

const POHeaderVersions = ({language, id}) => {
  const [POHeaderVersion, setPOHeaderVersion] = useState([])
  const [loadingVersions, setLoadingVersions] = useState(true)

  let POHistoryVersionsCalled = false

  useEffect(() => {
    POHeaderVersionsAPI()
  }, [])

  // Grid Things --

  const POHeaderVersionsAPI = () => {
    if(POHistoryVersionsCalled || !id){
      return;
    }
    setLoadingVersions(true)
    let api = Api
    POHistoryVersionsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/po_header_version/${id}`,{}).then(function(res){
      if(res.data.status==="success"){  
        let Updated_STATE = []
        res.data.data.forEach((element) => {
          Updated_STATE.push({ 
            version: element.version,
            document_status: element.document_status,
            modifieddatetime: element.modifieddatetime ? Helper.formateDate(element.modifieddatetime) : null
          })
        })
        setPOHeaderVersion(Updated_STATE)
        setLoadingVersions(false)
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoadingVersions(false)
    })
  }

  const Columns_Headings = [ 
    {field:'version', headerName:Helper.getLabel(language,'versions',"Versions"), minWidth: 150, editable: false},
    {field:'modifieddatetime', headerName:Helper.getLabel(language,'change_date',"Change Date"), minWidth: 150, editable: false}, 
    {field:'document_status', headerName:Helper.getLabel(language,'status',"Status"), minWidth: 150, editable: false},
  ]

  const Callbuttons = () => {
    return ( 
      <h2 className='grid_label_rfq me-3'>Versions</h2>  
    )
  }

    // --
 
  return (
    <div className='container-fluid purchase_orders_header_all_versions_grid'>
       {loadingVersions ? <SimpleLoading /> : <AgGrid hideFooter={true} data={POHeaderVersion} beforeAddButton={Callbuttons} id="rs_purchase_orders_header_all_versions_grid_view" allBtnHide={true} header={Columns_Headings} />}
    </div>
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}
    
  export default connect(mapStateToProps) (POHeaderVersions) 