import React, { useState } from 'react'
import Helper from '../../../inc/Helper'
import Popup from '../../Popup/Popup'
import { Form } from 'react-bootstrap'
import Settings from '../../../inc/Settings'
import AgGridNew from '../../grid/ag/ag-grid-new'
import SimpleLoading from '../../Loading/SimpleLoading'
import { getEvaluationHeaderInnerGridColumns, getEvaluationMainGridColumns } from './HeaderView/ColumnsHeader/ColumnsHeader'

let gridObj = null

const EvaluationOperations = ({ handleCloseModal,language,rfq_id }) => {
  const [selectedEvaluationType,setSelectedEvaluationType] = useState(null)
  const [loading,setLoading]=useState(false)

  const handleEvaluationTypeChange = (value) => {
     setSelectedEvaluationType(value)
     setLoading(true)
     setTimeout(()=>{
      setLoading(false)
     },1500)
     gridObj?.api?.refreshServerSide({purge:true})
  }

  const Columns_Headings = getEvaluationMainGridColumns({
    language:language
  })

  const detailColumnDefs = getEvaluationHeaderInnerGridColumns({
    language:language
  })

  const handleGridReady = (params) => {
     gridObj = params
  }

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: detailColumnDefs,},

        getDetailRowData: function (params) {
            params.successCallback(params?.data?.evaluators);
        }
    },
};


  return (
    <Popup autoOpen={true} width='1100px' onClose={handleCloseModal}>
      <>
        <h5 className='create_rfq_modal__title'>
          {Helper.getLabel(
            language,
            'publish_history',
            'Evaluation Operations'
          ) }
        </h5>
        <div>
          <h6 className='mt-3'>Please choose option</h6>
          <div className='d-flex mt-3 gap-3'>
            <Form.Check
              type='radio'
              label="Technical Evaluation"
              value={selectedEvaluationType}
              checked={selectedEvaluationType===0}
              onChange={()=>{handleEvaluationTypeChange(0)}}
            />
             <Form.Check
              type='radio'
              label="Commercial Evaluation"
              value={selectedEvaluationType}
              checked={selectedEvaluationType === 1}
              onChange={()=>{handleEvaluationTypeChange(1)}}
            />
             <Form.Check
              type='radio'
              label="Awarding"
              value={selectedEvaluationType}
              checked={selectedEvaluationType === 2}
              onChange={()=>{handleEvaluationTypeChange(2)}}
            />
          </div>
        </div>
        <div className='mt-4'>
          {loading ? <SimpleLoading /> : selectedEvaluationType !== null && <AgGridNew
            gridId="evaluation_operations_history"
            apiUrl={Settings.apiPurchaseRequisitionUrl + `/evaluation_history/${rfq_id}/${selectedEvaluationType}`}
            columnDefs={Columns_Headings}
            masterSettings={masterSettings}
            hideAddBtn={true}
            height={500}
            hideDeleteBtn={true}
            rowType="single"
            onGridReady={handleGridReady}
          
          />}
        </div>
      </>
    </Popup>
  )
}

export default EvaluationOperations
