import React, { Fragment, useState } from 'react'
import { checkIfArrayIsEmpty, isEmptyObject } from '../../../../inc/Validation';

let emptyRowDivId = 1;
const CompliancesSections = ({compliances, RFQVendors, complainceName}) => {
    const [openDropdownIndex, setOpenDropdownIndex] = useState(-1); 

    const renderComplianceType = (compliance, color) => {
        const maxCharacters = 10;
      
        if (!compliance) {
          return <div style={{ color: color || '#000', fontWeight: 600, overflowY: 'auto', whiteSpace: 'pre-wrap' }}>-</div>;
        }
       
        const formattedCompliance = compliance.replace(new RegExp(`.{1,${maxCharacters}}`, 'g'), (match, index) => {
          return index === 0 ? match : `<br/>${match}`;
        });
      
        return (
            <div
                style={{
                    color: color || '#000',
                    fontSize: '16px',
                    fontWeight: 600,
                    overflowY: 'auto',
                    whiteSpace: 'pre-wrap'
                }}
                dangerouslySetInnerHTML={{ __html: formattedCompliance }}
            />
        );
    };

    const OpenPopupBox = (complianceIndex) => { setOpenDropdownIndex(complianceIndex) };

    const closeCommentsPopupBox = () => { setOpenDropdownIndex(-1) };

    const commentPopupBox = (LinesObject={}, RFQObject={}) => {
        // comments will be the entire object, extract the respective props from it
        if(!isEmptyObject(LinesObject) && !isEmptyObject(RFQObject)) { 
            return (
                <div className='comments_box'>
                    <div className='row'>
                        <div className='col-6 d-flex justify-content-start align-items-center'>
                            <div className='compliance_vendor'>{RFQObject.vendor ? RFQObject.vendor : '-'}</div>  
                        </div>
                        <div className='col-6 d-flex justify-content-end align-items-center p-0 m-0'>
                            <div className='compliance_applicable'>Applicable: <span> {LinesObject.compliance_type ? LinesObject.compliance_type : '-'}</span></div>
                        </div>
                    </div>

                    <div className='compliance_comments'>
                        {LinesObject.comments ? LinesObject.comments : '-'}
                    </div>
                </div>
            )
        } else {
            return (
                <div className='comments_box'>
                    <div className='row'>
                        <div className='col-6 d-flex justify-content-start align-items-center'>
                            <div className='compliance_vendor'>
                                No Record Found!
                            </div>  
                        </div> 
                    </div> 
                </div>
            )
        }
    }

    const displayEmptyRowDiv = () => { return ( <div className="column empty-div"></div> ) }
    
    const displayEmptyRFQVendorDiv = () => {   
        return (
            <div className="column section-content" key={emptyRowDivId}>
                <div className="compliance_type">-</div>
                <div className="compliance_comments">
                    <div className="comments_icon_wrapper" onClick={() => OpenPopupBox(emptyRowDivId)}>
                        <img className="img-fluid" src="/images/icons/comments.svg" alt="comments" />
                    </div>
                    {openDropdownIndex === emptyRowDivId && (
                        <div className="popup-box-menu-comments">
                            {commentPopupBox()}
                            <div className="cross_close_wrapper" onClick={closeCommentsPopupBox}>
                                <img src="/images/icons/cross-close.svg" className="img-fluid" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="column section-head">
                <h1 style={{maxHeight:'80px', overflowY: 'auto'}}>
                    {complainceName}
                </h1> 
            </div>

            {!checkIfArrayIsEmpty(RFQVendors) ? (
                RFQVendors.map((vendor) => {
                    const matchingCompliance = compliances.find((item) => item.rfq_vendorreply_id === vendor.vendorreply_id);
                    if (matchingCompliance) {
                    return (
                        <div className="column section-content" key={vendor.vendorreply_id}>
                            <div className="compliance_type">{renderComplianceType(matchingCompliance.compliance_type, matchingCompliance.color)}</div>
                            <div className="compliance_comments">
                                <div className="comments_icon_wrapper" onClick={() => OpenPopupBox(vendor.vendorreply_id)}>
                                    <img className="img-fluid" src="/images/icons/comments.svg" alt="comments" />
                                </div>
                                {openDropdownIndex === vendor.vendorreply_id && (
                                    <div className="popup-box-menu-comments">
                                        {commentPopupBox(matchingCompliance, vendor)}
                                        <div className="cross_close_wrapper" onClick={closeCommentsPopupBox}>
                                            <img src="/images/icons/cross-close.svg" className="img-fluid" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                    } else { return displayEmptyRFQVendorDiv(vendor) };
                })
                ) : (
                <Fragment>{displayEmptyRowDiv()}</Fragment>
            )}
        </div>
    );
};

export default CompliancesSections;