import React, {useState, useEffect, Fragment, useRef} from 'react'  
import Button from '../../inc/Button'
import '../Numbers.css'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Helper from '../../../inc/Helper'
import LinkBtn from '../../inc/LinkBtn'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'  
import Collapse from '../../inc/Collapse' 
import Input from '../../Forms/Input'
import FormValidator from '../../../inc/FormValidator'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'
import Checkbox from '../../Forms/Checkbox'
import { areObjectsDifferent, checkIfArrayIsEmpty, excludeProperty, getPopupMessageBasedOnStatus, isGreater, isGreaterThan32Bits, isWithinRange } from '../../../inc/Validation'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import AgGridNew from '../../grid/ag/ag-grid-new'
import SimpleLoading from '../../Loading/SimpleLoading'

 
let numberSequenceObject = null
 
const EditNumber = (props) => {
  const {id} = useParams() 
 
  const [manualChecked, setManualChecked] = useState(null)
  const [continuousChecked, setContinuousChecked] = useState(null)
 
  const [segments, setSegments] = useState({});
  const [segmentsGrid, setSegmentsGrid] = useState([]); 
  
  const [loading, setLoading] = useState(true) 

  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);

  let validator;  

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'code', displayName:Helper.getLabel(props.language,'code','Code'),types:['Required'] },
      {name:'name', displayName:Helper.getLabel(props.language,'name','Name'),types:['Required'] },   
      {name:'smallest_number', displayName:Helper.getLabel(props.language, 'smallest_number', 'Smallest Number'), types:['Required', 'Number']},
      {name:'largest_number', displayName:Helper.getLabel(props.language, 'largest_number', 'Largest Number'), types:['Required', 'Number']},
      {name:'next_number', displayName:Helper.getLabel(props.language, 'next_number', 'Next Number'), types:['Required', 'Number']}, 
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure() 

  let getNumberCalled = false  

  useEffect(() => { 
    getNumberSequenceData()
  }, [id])

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);
  
  const getNumberSequenceData = () => { 
    if(getNumberCalled || !id){
      return;
    }
    setLoading(true)
    let api = Api
    getNumberCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/numbersequence/${id}`,{}).then(function(res){
    if(res.data.status==="success"){   
      numberSequenceObject = res.data.data
      let UPDATED_GRID = {}
      UPDATED_GRID =  {
        name: res.data.data.name !== "" ? res.data.data.name : null,
        code: res.data.data.code !== "" ? res.data.data.code : null, 
        continuous: res.data.data.format.continuous == null ? false : res.data.data.format.continuous,
        manual: res.data.data.format.manual == null ? false : res.data.data.format.manual, 
        sequencenumber_id: res.data.data.sequencenumber_id, 
        segments: res?.data?.data?.segments || [],
        in_use: res.data.data.format.in_use, 
        format: res.data.data.format.format ? res.data.data.format.format : '-',
        largest_number: res.data.data.format.largest_number,
        smallest_number: res.data.data.format.smallest_number,
        next_number: res.data.data.format.next_number
      }
      setContinuousChecked(res.data.data.format.continuous == null ? false : res.data.data.format.continuous)
      setManualChecked(res.data.data.format.manual == null ? false : res.data.data.format.manual)
      numberSequenceObject = UPDATED_GRID 
      setSegmentsGrid(res?.data?.data?.segments || []);
      setSegments(UPDATED_GRID);
      setLoading(false);
    }
    }).catch((res) => { 
      setLoading(false)
      getPopupMessageBasedOnStatus(res) 
    }) 
  }
 
  // Values - Handlers ----

  const CodeChangeHandler = (event) => {
    let Code = event.target.value
      setSegments((prevState) => {
        return {...prevState, code: Code}
      })
  }

  const NameChangeHandler = (event) => {
    let Name = event.target.value
      setSegments((prevState) => {
        return {...prevState, name: Name}
    })
  }
 
  const InUseChangeHandler = (name, value) => { 
    if(value == "on"){
      setSegments((prevState) => {
        return {...prevState, in_use: true}
      })
    }else {
      setSegments((prevState) => {
        return {...prevState, in_use: false}
      })
    } 
  }

  const ContinousChangeHandler = (event) => { 
    setSegments((prevState) => {
      return {
        ...prevState, 
        continuous: true
      }
    })
    setContinuousChecked(true);
    setManualChecked(false);
  }

  const ManualChangeHandler = (event) => {
    setSegments((prevState) => {
      return {
        ...prevState, 
        continuous: false,
        manual: true
      }
    })
    setManualChecked(true);
    setContinuousChecked(false);
  }

  const NumberNextHandler = (event) => {
    let Next = event.target.value
    setSegments((prevState) => {
      return {...prevState, next_number: Next}
    })
  }

  const NumberSmallestHandler = (event) => {
    let Small = event.target.value
    setSegments((prevState) => {
      return {...prevState, smallest_number: Small}
    })
    setSegments((prevState) => {
      return {...prevState, next_number: Small}
    })
  }

  const LargestChangeHandler = (event) => {
    let Largest = event.target.value
    setSegments((prevState) => {
      return {...prevState, largest_number: Largest}
    })
  }
 
  const NumberingSequenceModule = () => {
    return (
      <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
        {IdentificationModule()} 
        {SegmentsModule()}
        {GeneralModule()}
      </div>
    )
  }

  const IdentificationModule = () => {
    return ( 
      <Collapse open={true} title={Helper.getLabel(props.language,'number_seq-001','Identification')}>
        <div className='container-fluid'> 
          <div className='row'>
            <div className='col-12'>
              <div className='row gy-0 mt-2 mb-2'>
                <div className='col-lg-2 col-md-2'> 
                  <Input maxLength={10} label={Helper.getLabel(props.language,'code_NS_ADMIN','Code')} type='text' onChange={CodeChangeHandler} value={segments.code} required={true} />
                </div>

                <div className='col-lg-4 col-md-4'> 
                  <Input maxLength={50} type='text' label={Helper.getLabel(props.language,'name_NS_ADMIN','Name')} onChange={NameChangeHandler} value={segments.name} required={true}  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse> 
    )
  }

  const SegmentsModule = () => {
    let dataSource = {
      getRows: async function (params) {
        params?.success({
          rowData: segmentsGrid?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}`
          })),
          rowCount: segmentsGrid?.length
        })
      },
    };
  
    const onSegmentsNumberSequenceReady = (params) => {
      if(params){  
        setTimeout(()=>{ 
          params?.api?.setGridOption('serverSideDatasource', dataSource);
        }, 200)
      };
    };

    return (
      <Collapse open={true} title={Helper.getLabel(props.language,'number_seq-003','Segments')}>
        <div className='rs_new_number_sequence'>
          <AgGridNew 
            pagination={false}  
            key={loading?'render':'rerender'}
            onGridReady={onSegmentsNumberSequenceReady}
            columnDefs={gridColumnHeaders}
            allBtnHide={true}
            height={500}  
            isDisabledCheckbox={true}
            gridId={'rs_number_sequence_edit'}
          />
        </div>
      </Collapse>
    )
  }

  const GeneralModule = () => {
    return (
      <Collapse open={true} title={Helper.getLabel(props.language,11217,'General')}>
        <div className='container-fluid'>

          <div className='row mb-4'>
            <div className='col-lg-3 col-md-3'> 
              <Input label={Helper.getLabel(props.language,'format_NS_ADMIN','Format')} type='text' className='format' disable={true} value={segments.format} /> 
            </div>  
          </div>

          <div className='row p-0 mb-4'>
            <div className='col-lg-2 col-md-2'>
              <Checkbox label={Helper.getLabel(props.language,'in-use_NS_ADMIN','In Use')} type='checkbox' onChange={InUseChangeHandler} isChecked={segments.in_use} />
            </div>
            <div className='col-lg-2 col-md-2'>
              <Checkbox label={Helper.getLabel(props.language,'continuous_NS_ADMIN','Continuous')} type='checkbox' onChange={ContinousChangeHandler} isChecked={continuousChecked} />
            </div>
            <div className='col-lg-2 col-md-2'>
              <Checkbox label= {Helper.getLabel(props.language,'manual_NS_ADMIN','Manual')} type='checkbox' onChange={ManualChangeHandler} isChecked={manualChecked} />
            </div>
          </div> 
 
          <div className='row p-0 mb-4'> 
            <div className='col-lg-3 col-md-3'> 
              <Input type='number' label={Helper.getLabel(props.language,'smallest_NS_ADMIN','Smallest')} required={true} onChange={NumberSmallestHandler} value={segments.smallest_number} placeholder='-99' />
            </div>
            <div className='col-lg-3 col-md-3'> 
              <Input label= {Helper.getLabel(props.language,'largest_NS_ADMIN','Largest')} type='text' onChange={LargestChangeHandler} required={true}  value={segments.largest_number} placeholder='1000' />
            </div>   
            <div className='col-lg-3 col-md-3'> 
              <Input type='number' label= {Helper.getLabel(props.language,'next_NS_ADMIN','Next')} required={true} onChange={NumberNextHandler} value={segments.next_number} placeholder='1'/>
            </div> 
          </div>
        </div>
      </Collapse>
    )
  }

  // ----

  // API Executions for Update --

  const SaveHandler = () => { 
    // Making a comparison, with object's value's, if different, it will make a API call there n then only..
    if(!areObjectsDifferent(excludeProperty(numberSequenceObject, 'segments'), excludeProperty(segments, 'segments'))){
      return;
    }

    let newData = {
      name: segments.name, 
      code: segments.code,
      largest_number: segments.largest_number,
      smallest_number: segments.smallest_number,
      next_number: segments.next_number
    }

    let validData = {
      ...newData
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }
  
    if(parseInt(segments.smallest_number) > parseInt(segments.largest_number)){
      Helper.alert('Smallest Number cant be greater than Largest Number!', 'error')
      return;
    } 

    if(parseInt(segments.next_number) < parseInt(segments.smallest_number)){
      Helper.alert('Next Number cant be smaller than Smallest Number!', 'error')
      return;
    } 

    if(parseInt(segments.next_number) > parseInt(segments.largest_number)){
      Helper.alert('Next Number cant be greater than Largest Number!', 'error')
      return;
    } 

    if(segments.in_use) { 
      if(!continuousChecked && !manualChecked) {
        Helper.alert('Please provide either manual or continuous!', 'error')
        return;
      }
    }

    if(isGreaterThan32Bits(segments.largest_number)){
      Helper.alert('Enter Digits within the 32 bits range!', 'error')
      return;
    }

    if(isGreaterThan32Bits(segments.smallest_number)){
      Helper.alert('Enter Digits within the 32 bits range!', 'error')
      return;
    }

    if(isGreaterThan32Bits(segments.next_number)){
      Helper.alert('Enter Digits within the 32 bits range!', 'error')
      return;
    }
 
    setLoading(true);
    let api = Api
    api.setUserToken() 
    api.axios().put(Settings.apiUrl+`/numbersequence/${id}`, {
      name: segments.name !== "" ? segments.name : null, 
      number_sequence_code: segments.code !== "" ? segments.code : null,
      continues: continuousChecked,
      in_use: segments.in_use === null ? false : segments.in_use,
      largest: segments.largest_number,
      manual: manualChecked,
      smallest: segments.smallest_number,
      next_number: segments.next_number
    }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        getNumberSequenceData()
      }
    }).catch((res) => {  
      getPopupMessageBasedOnStatus(res) 
      getNumberSequenceData()
    })  
  }
 
  const gridColumnHeaders = [
    {field: 'segment_type', filter: false, sortable: false, headerName:'Segment', editable: false},
    {field: 'segment_value', filter: false, sortable: false, headerName:'Value', editable: false},
    {field: 'segment_length', filter: false, sortable: false, headerName:'Length', editable: false}
  ]

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.number_sequence.number_sequence_edit_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader isPopupInTab={true} /> : null }
      <div className = { 'container-fluid' }>
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} title={ `<span style="color:#000;">${Helper.getLabel(props.language,'number_seq_000_heading',"Edit Number Sequence")} #</span>  ${segments.code}`}>
            <LinkBtn isActive= {false} to="/number-sequence" title='Close' className='black-btn-style' />
            <Button idDisable={!security.canEdit(frontendIds.setupForms.number_sequence.number_sequence_edit_screen_save_button)} isActive= {false} title='Save' onClick={SaveHandler} className="rfq_save_btn" />
          </NavigationHeder> 
        </div>

        { NumberingSequenceModule() }
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.number_sequence.number_sequence_edit_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditNumber, SecurityOptions))))