import axios from "axios";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";

export default class CommonAPICalls {
    api;

    constructor() {
      this.api = Api;
      this.api.setUserToken();
    }

    async getSystemEntities() { 
        const res = await this.api.axios().get(Settings.apiUrl + `/get_entities`);
        if (res.data.status === "success") return res.data.data;
        return res;
    }

    async getSystemSites() { 
        const res = await this.api.axios().get(Settings.apiUrl + `/get_site`);
        if (res.data.status === "success") return res.data.data;
        return res;
    }
    
    async getSystemWarehouse() { 
        const res = await this.api.axios().get(Settings.apiUrl + `/get_warehouse`);
        if (res.data.status === "success") return res.data.data;
        return res;
    }
}