import React, { useState } from 'react'
import Popup from '../../Popup/Popup'
import Button from '../../inc/Button'
import Helper from '../../../inc/Helper'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import { getMatchProductColumns1, getMatchProdutSiteColumns } from './ColumnsHeadings'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions'


let gridApi1 = null
let gridApi2= null

const InvoiceProductsDetails = ({
  onProductDetailPopupClose,
  language,
  invoice_id,
  invoice_header,
  userType
}) => {
  const [lineSitesData,setLineSitesData]=useState({})

  const getQtyToMatchEditable = (params) => {
    if(invoice_header?.purchaseorder_type===1){
      return true
    }else if(invoice_header?.purchaseorder_type!==1 && params?.data?.matched_qty===null){
      return true
    }else{
      return false
    }
  }

  const Column_Header = getMatchProductColumns1({
    language:language,
  })

  const Columns_SiteTable = getMatchProdutSiteColumns({
    language:language,
    invoice_header:invoice_header,
    getQtyToMatchEditable:getQtyToMatchEditable,
  })

  const onSelectionChanged = (event) => {
    let selectedRows = event?.api?.getSelectedRows(); 
    if(selectedRows?.length>0){
    setLineSitesData(selectedRows[0])
     setTimeout(()=>{
      gridApi2?.api?.setGridOption('serverSideDatasource', dataSource)
     },200)
    }
    else{
      setLineSitesData({})
    }
  }

  const handleGridFirstReady = (gridApi) => {
    gridApi1 = gridApi
  }

  const handleGridSecondReady = (gridApi) => {
    gridApi2 = gridApi
  }

  const onRowValueChanged = (event) => {
    let getSelectedRows = gridApi1?.api?.getSelectedRows()
    let api = Api
    api.setUserToken()
    const putRequestData = {
      invoice_id: getSelectedRows[0]?.invoice_id,
      invoiceline_id: getSelectedRows[0]?.line_id,
      shipment_id: event.data?.shipment_id,
      shipmentline_id: event.data?.line_id,
      qty_to_match: parseInt(event?.data?.matched_qty),
    }
    const grnPutRequestData={
      ...putRequestData,
      shipmentlinesite_id:event.data?.linesite_id
    }
    let payload = invoice_header?.purchaseorder_type!==1 ? grnPutRequestData : putRequestData
    if(parseInt(payload?.qty_to_match)>parseInt(event?.data?.remaining_qty_for_invoice)){
      Helper.alert("Value cannot be greater than Invoice Qty.","error")
      return
    }
    api
    .axios()
    .put(
      Settings.apiPurchaseOrderUrl +
        `/match_product_qty`,
      payload,
    )
    .then(function (res) {
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success')
        gridApi2?.api?.refreshServerSide({purge:false})
      }
    })
    .catch((res) => {
      getPopupMessageBasedOnStatus(res) 
    })
  }

  let dataSource = {
    getRows: async function (params) {
      let getTotalSelectedRows = gridApi1?.api?.getSelectedRows()
      params.success({
        rowData: getTotalSelectedRows[0]?.line_details?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`,
        })),
        rowCount: getTotalSelectedRows[0]?.line_details?.length,
      })
    },
  }

  let Columns_First_Table = Column_Header.filter((item)=>(item.field!=="item_name" && item.field !== "unit_name" && item.field!=="unit_price"))
  if(invoice_header?.purchaseorder_type===1){
    Columns_First_Table?.splice(1,0,{field:"billingrule.description", headerName: Helper.getLabel(language, 'billing_rule_line', 'Billing Rule Line'),
    minWidth:180,
    editable:false})
  }
 
  return (
    <div className={`${userType === 0?'':'work_completion_line_grid'}`}>
      <Popup autoOpen={true} width='1100px' onClose={onProductDetailPopupClose}>
        <div>
          <div>
            <div className='d-flex justify-content-between align-items-center mb-4'>
              <h5 style={{ fontWeight: '600', marginBottom: '0' }}>Match Product List to Invoice</h5>
              <div style={{ float: 'right' }}>
                <Button className='black-btn-style' title={Helper.getLabel(language, 'close', 'Close')} onClick={onProductDetailPopupClose}></Button>
              </div>
            </div>
            <AgGridNew
              columnDefs={invoice_header?.purchaseorder_type===1?Columns_First_Table:Column_Header}
              apiUrl={Settings.apiPurchaseOrderUrl + `/match_product_line_detail/${invoice_id}`}
              hideAddBtn={true}
              id="first_invoice__productgrid"
              hideDeleteBtn={true}
              isDisabledCheckbox={true}
              onSelectionChanged={onSelectionChanged}
              onGridReady={handleGridFirstReady}
              rowType="single"
            />
            <div className='mt-3'>
              <AgGridNew
                columnDefs={Columns_SiteTable}
                data={lineSitesData?.line_details || []}
                isDisabledCheckbox={true}
                hideAddBtn={true}
                hideDeleteBtn={true}
                onGridReady={handleGridSecondReady}
                id="second_invoice_grid"
                rowType="single"
                onRowValueChanged={onRowValueChanged}
              />
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
}

export default InvoiceProductsDetails
