import React from 'react'
import { useNavigate } from 'react-router-dom'

const Notifications = () => {
  const navigate = useNavigate()
  return (
    <button className='notification_controller border-0 bg-transparent p-0' onClick={()=>{navigate("/notifications")}}>
      <img src='/images/icons/notification.svg' alt='Notifications' />
      <span></span>
    </button>
  )
}

export default Notifications
