// import React, { Component } from 'react';
// import Helper from '../../../../inc/Helper';
// import Input from '../../../Forms/Input';
// import Collapse from '../../../inc/Collapse';
// import DropDownInput from '../../../Forms/Dropdown';
// import Checkbox from '../../../Forms/Checkbox';
// import { faL } from '@fortawesome/free-solid-svg-icons';
// import SimpleLoading from '../../../Loading/SimpleLoading';
// class SetupTabDetails extends Component {
//     constructor(props){
//         super(props);
//         this.setTimeOut = null;
//         this.activePOLine = this.props.activePOLine? this.props.activePOLine:{};
//         this.state = {
//             isLoading:false,
//             rowData: this.props.activePOLine ? this.props.activePOLine.data:{}
//         }
//     }
//     componentDidMount(){
//         this.activePOLine = this.props?.activePOLine;
//         this.setState({
//             rowData: this.props?.activePOLine ? this.props?.activePOLine?.data:{}
//         })
//     }
//     onChangeHandler(e) {
//         let oldRowData = this.state.rowData;
//         let activePO = this.props.activePOLine
//         if(Object.keys(oldRowData).length <=0){
//             Helper.alert("Please select Purchase Order Lines",'error')
//             return;
//         }
//         let that = this;
//         this.setState({
//             rowData:{
//                 ...activePO?.data,
//                 [e.target.name]: e.target.value
//             }
//         })
//         clearTimeout(this.setTimeOut);
//         this.setTimeOut = setTimeout(() => {
//             let isChanged = that.activePOLine.setDataValue(e.target.name,e.target.value);
//             if(isChanged){
//                 that.props.updateData(that.state.rowData,false,false)
//                 that.props.grid.componentObj.setGridValueChanged(that.activePOLine.id, that.state.rowData);
//             }
//         }, 200);
//     }
//     onCheckboxChangeHandler(checkboxName,cValue) {
//         let oldRowData = this.state.rowData;
//         let activePO = this.props.activePOLine
//         if(Object.keys(oldRowData).length <=0){
//             Helper.alert("Please select Purchase Order Lines",'error')
//             return;
//         }
//         let that = this;
//         this.setState({
//             rowData:{
//                 ...activePO?.data,
//                 [checkboxName]: cValue == 'on' ? 1 : 0
//             }
//         })
//         clearTimeout(this.setTimeOut);
//         this.setTimeOut = setTimeout(() => {
//             let isChanged = that.activePOLine.setDataValue(checkboxName,cValue == 'on' ? 1 : 0);
//             if(isChanged){
//                 that.props.updateData(that.state.rowData,false,false)
//                 that.props.grid?.componentObj?.setGridValueChanged(that.activePOLine?.id, that.state.rowData);
//             }
//         }, 200);
//     }
//     componentDidUpdate(prevProps, prevState, snapshot){
//         if(prevProps.activePOLine != this.props.activePOLine){
//             this.activePOLine = this.props.activePOLine;
//             let that = this;
//             this.setState({
//                 isLoading:true,
//                 rowData: this.props.activePOLine?.data
//             },()=> {
//                 that.setState({
//                     isLoading:false
//                 })
//             })
//         }
//     }
//     isDisable(){
//         if(this.props.viewType == 'amendment' && this.props.activePOLine?.data?.line_id && !this.props.activePOLine?.data?.is_amendmentline){
//             return true;
//         }
//         return !this.props.isEditable;
//     }
//     render() {
//         let language = null;
//         let data = this.state.rowData;
//         let props = this.props;
//         //console.log(data)
//         if(this.state.isLoading){
//             return <SimpleLoading/>
//         }
//         return (
//             <Collapse open={true} title={Helper.getLabel(language,'Setup',"Setup")}>
//                 <div className='row'>
//                     <div className='col-xs-12 col-sm-4'><DropDownInput label="Tax group" name="taxgroup_id" id="taxgroup_id" onChange={e => this.onChangeHandler(e)}  options={props.taxGroup} value={data?.taxgroup_id ? data?.taxgroup_id : '' }  disable={this.isDisable()}  /></div>
//                     <div className='col-xs-12 col-sm-4'><DropDownInput label="Tax group item" name="taxgroupitem_id" id="taxgroupitem_id" onChange={e => this.onChangeHandler(e)}  options={props.taxGroupItems} value={data?.taxgroupitem_id ? data?.taxgroupitem_id : ''}  disable={this.isDisable()}  /></div>
//                     <div className='col-xs-12 col-sm-4'><Checkbox label="Advance Payment" name="advance_payment" onChange={ this.onCheckboxChangeHandler.bind(this) } isChecked={ data?.advance_payment == '1' || data?.advance_payment == 'yes'  ? true : false }  disable={this.isDisable()}  /></div>
//                     <div className='col-xs-12 col-sm-4'><Input label="Advance Amount" name="advance"  onChange={e => this.onChangeHandler(e)} value={data?.advance}  disable={this.isDisable()}  /></div>
//                     <div className='col-xs-12 col-sm-4'><Input label="Recoupment Rate" name="recoupment_rate" value={data?.recoupment_rate} disable={true}   /></div>
//                     <div className='col-xs-12 col-sm-4'><Input label="Retainage Rate" name="retainage_rate" value={data?.retainage_rate}  disable={true}  /></div>
//                     <div className='col-xs-12 col-sm-4'><Input label="Maximum Retainage Amount" name="max_retainage_amount" value={data?.max_retainage_amount}  disable={true} /></div>
//                 </div>
//             </Collapse>
//         );
//     }
// }

// export default SetupTabDetails;


import React, { useState, useEffect, useCallback } from 'react';
import Helper from '../../../../inc/Helper';
import Input from '../../../Forms/Input';
import Collapse from '../../../inc/Collapse';
import DropDownInput from '../../../Forms/Dropdown';
import Checkbox from '../../../Forms/Checkbox';
import SimpleLoading from '../../../Loading/SimpleLoading';
import NewInputDatePicker from '../../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../../inc/DatepickerHelper';

const SetupTabDetails = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [rowData, setRowData] = useState(props.activePOLine ? props.activePOLine.data : {});

    let setTimeOut = null
    let language = null;

    useEffect(() => {
        if (props.activePOLine !== undefined) {
            setIsLoading(true)
            setTimeout(()=>{
                setIsLoading(false)
            },200)
            setRowData(props?.activePOLine?.data);
        }
    }, [props.activePOLine]);

    // const onChangeHandler = (e) => {
    //     if (Object.keys(rowData).length <= 0) {
    //         Helper.alert("Please select Purchase Order Lines", 'error');
    //         return;
    //     }

    //     setRowData({
    //         ...props.activePOLine.data,
    //         [e.target.name]: e.target.value
    //     });

    //     clearTimeout(setTimeOut);
    //     const setTimeOut = setTimeout(() => {
    //         const isChanged = props.activePOLine.setDataValue(e.target.name, e.target.value);
    //         if (isChanged) {
    //             props.updateData(rowData, false, false);
    //             props.grid.componentObj.setGridValueChanged(props.activePOLine.id, rowData);
    //         }
    //     }, 200);
    // };

    const onChangeHandler = useCallback((e) => {
        // if (Object.keys(rowData).length <= 0) {
        //     Helper.alert("Please select Purchase Order Lines", 'error');
        //     return;
        // }

        setRowData({
            ...props.activePOLine?.data,
            [e.target.name]: e.target.value
        });

        const updatedData = {
            ...props.updatedData,
            [e.target.name]: e.target.value
        };
        props.setUpdatedData(updatedData);

        clearTimeout(setTimeOut);
        setTimeOut = setTimeout(() => {
            let isChanged = props.activePOLine.setDataValue(e.target.name, e.target.value, 'RowValueChangedEvent');
            if (isChanged) {
                if(e.target.name==="commitment_level"){
                    updatedData.commitment_level = parseInt(updatedData?.commitment_level)
                }
                props.updateData(updatedData, rowData?.line_id, false);
                props.grid.componentObj.setGridValueChanged(props.activePOLine.id, rowData);
            }
        }, 500);
    }, [props.updatedData, setRowData, props.setUpdatedData, props.activePOLine, props.updateData, rowData]);

    useEffect(() => {
        if (props.activePOLine?.data?.line_id) {
            setRowData(props.activePOLine.data);
        }
    }, [props.activePOLine?.data?.line_id]);

    // const onCheckboxChangeHandler = (checkboxName, cValue) => {
    //     if (Object.keys(rowData).length <= 0) {
    //         Helper.alert("Please select Purchase Order Lines", 'error');
    //         return;
    //     }

    //     setRowData({
    //         ...props.activePOLine.data,
    //         [checkboxName]: cValue == 'on' ? 1 : 0
    //     });

    //     clearTimeout(setTimeOut);
    //     const setTimeOut = setTimeout(() => {
    //         const isChanged = props.activePOLine.setDataValue(checkboxName, cValue == 'on' ? 1 : 0);
    //         if (isChanged) {
    //             props.updateData(rowData, false, false);
    //             props.grid?.componentObj?.setGridValueChanged(props.activePOLine?.id, rowData);
    //         }
    //     }, 200);
    // };

    const onCheckboxChangeHandler = useCallback((checkboxName, cValue) => {
        // if (Object.keys(rowData).length <= 0) {
        //     Helper.alert("Please select Purchase Order Lines", 'error');
        //     return;
        // }

        setRowData({
            ...props.activePOLine?.data,
            [checkboxName]: cValue == 'on' ? true : false
        });

        const updatedData = {
            ...props.updatedData,
            [checkboxName]: cValue == 'on' ? true : false
        };
        props.setUpdatedData(updatedData);

        clearTimeout(setTimeOut);
        setTimeOut = setTimeout(() => {
            const isChanged = props.activePOLine.setDataValue(checkboxName, cValue == 'on' ? true : false);
            if (isChanged) {
                props.updateData(updatedData, rowData?.line_id, false);
                props.grid?.componentObj?.setGridValueChanged(props.activePOLine?.id, rowData);
            }
        }, 200);
  }, [props.updatedData, setRowData, props.setUpdatedData, props.activePOLine, props.updateData, rowData]);

  const onDateChangeHandler = useCallback((date,name) => {
    // if (Object.keys(rowData).length <= 0) {
    //     Helper.alert("Please select Purchase Order Lines", 'error');
    //     return;
    // }

    setRowData({
        ...props.activePOLine?.data,
        [name]: date
    });

    const updatedData = {
        ...props.updatedData,
        [name]: date
    };
    props.setUpdatedData(updatedData);

    clearTimeout(setTimeOut);
    setTimeOut = setTimeout(() => {
        let isChanged = props.activePOLine.setDataValue(name, date, 'RowValueChangedEvent');
        if (isChanged) {
            if(name==='start_date'){
                updatedData.start_date = updatedData?.start_date === null || updatedData?.start_date===undefined ? '' : DatepickerFunctions?.convertDateForDataBase(DatepickerFunctions?.convertDateFromDataBase(updatedData?.start_date))
                updatedData.end_date = DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(rowData?.end_date))
            }
            if(name==='end_date'){
                updatedData.end_date= updatedData?.end_date === null || updatedData?.end_date===undefined? '' :DatepickerFunctions.convertDateForDataBase(DatepickerFunctions?.convertDateFromDataBase(updatedData?.end_date))
                updatedData.start_date = DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(rowData?.start_date))
            }
            props.updateData(updatedData, rowData?.line_id, false);
            props.grid.componentObj.setGridValueChanged(props.activePOLine.id, rowData);
        }
    }, 500);
}, [props.updatedData, setRowData, props.setUpdatedData, props.activePOLine, props.updateData, rowData]);

    const isDisable = () => {
        if (props.viewType == 'amendment' && props.activePOLine?.data?.line_id && !props.activePOLine?.data?.is_amendmentline) {
            return true;
        }
        return !props.isEditable;
    };

    if (isLoading) {
        return <SimpleLoading />;
    }

    return (
        <Collapse open={true} title={Helper.getLabel(language, 'Setup', "Setup")} className='mb-3'>
            <div className='row'>
                <div className='col-xs-12 col-sm-4'><DropDownInput label="Tax group" name="taxgroup_id" id="taxgroup_id" onChange={e => onChangeHandler(e)} options={props.taxGroup} value={rowData?.taxgroup_id ? rowData?.taxgroup_id : ''} disable={isDisable()}   placeHolder={`${Helper.getLabel(language, 'select_tax_group', 'Select tax group')}`} /></div>
                <div className='col-xs-12 col-sm-4'><DropDownInput label="Tax group item" name="taxgroupitem_id" id="taxgroupitem_id" onChange={e => onChangeHandler(e)} options={props.taxGroupItems} value={rowData?.taxgroupitem_id ? rowData?.taxgroupitem_id : ''} disable={isDisable()} placeHolder={`${Helper.getLabel(language, 'Select_tax_group_item', 'Select tax group item')}`}/></div>
                <div className='col-xs-12 col-sm-4'><Checkbox label="Advance payment" name="advance_payment" onChange={(name,value) => onCheckboxChangeHandler(name, value)} isChecked={rowData?.advance_payment == '1' || rowData?.advance_payment == 'yes' ? true : false} disableWithoutIcon={isDisable()} /></div>
                <div className='col-xs-12 col-sm-4'><Input label="Advance amount" name="advance" onChange={e => onChangeHandler(e)} value={rowData?.advance} disable={rowData?.advance_payment === false || rowData?.advance_payment === null ? true : isDisable()} /></div>
                <div className='col-xs-12 col-sm-4'><Input label="Recoupment rate" name="recoupment_rate" value={rowData?.recoupment_rate} disable={true} /></div>
                <div className='col-xs-12 col-sm-4'><Input label="Retainage rate" name="retainage_rate" value={rowData?.retainage_rate} disable={true} /></div>
                <div className='col-xs-12 col-sm-4'><Input label="Maximum retainage amount" name="max_retainage_amount" value={rowData?.max_retainage_amount} disable={true} /></div>
                {props?.formType==="purchase agreement" && <>
                  <div className='col-xs-12 col-sm-4'><DropDownInput label="Commitment level" name="commitment_level" id="commitment_level" onChange={e => onChangeHandler(e)} options={props?.commitmentLevelList} value={rowData?.commitment_level ? rowData?.commitment_level : ''} disable={isDisable()}   placeHolder={`${Helper.getLabel(language, 'select_commitment_level', 'Select commitment level')}`} reRenderRequired={true} /></div>
                  <div className='col-xs-12 col-sm-4'><NewInputDatePicker disable={isDisable()} label={Helper.getLabel(props.language, 'start_date', "Start date")} value={rowData?.start_date} onChange={onDateChangeHandler} id='start_date' name="start_date" empty={true} /></div>
                  <div className='col-xs-12 col-sm-4'><NewInputDatePicker disable={isDisable()} label={Helper.getLabel(props.language, 'end_date', "End date")} value={rowData?.end_date} onChange={onDateChangeHandler} id='end_date' name="end_date" empty={true} /></div>
                  <div className='col-xs-12 col-sm-4'><Input inputType="number" label="Minimum release amount" name="min_release_amount" onChange={e => onChangeHandler(e)} value={rowData?.min_release_amount} disable={isDisable()} /></div>
                  <div className='col-xs-12 col-sm-4'><Input inputType="number" label="Maximum release amount" name="max_release_amount" onChange={e => onChangeHandler(e)} value={rowData?.max_release_amount} disable={isDisable()} /></div>   
                  <div className='col-xs-12 col-sm-4'><Checkbox label="Maximum release enforce" name="max_is_enforced" onChange={(name,value) => onCheckboxChangeHandler(name, value)} isChecked={rowData?.max_is_enforced == 'yes' || rowData?.max_is_enforced === true  ? true : false} disableWithoutIcon={isDisable()} /></div>
                  <div className='col-xs-12 col-sm-4'><Checkbox label="Enforced same category for item release" name="enforce_same_category_item_release" onChange={(name,value) => onCheckboxChangeHandler(name, value)} isChecked={rowData?.enforce_same_category_item_release == 'yes' || rowData?.enforce_same_category_item_release === true ? true : false} disableWithoutIcon={isDisable()} /></div>          
                </>}
            </div>
        </Collapse>
    );
};

export default SetupTabDetails;
