import ActionTypes from "../inc/ActionsTypes";


const DefaultState = {
    prCartInfo:null,
}
const PRCartReducer = (state = DefaultState, actions) =>{
    if(actions.type ===  ActionTypes.SET_PR_ID ){
        state = {
            ...state,
            prCartInfo:actions.payload
        }
    }
    if(actions.type ===  ActionTypes.RESET_PR_ID ){
        state = {
            ...state,
            prCartInfo:[]
        }
    }
    return state;
}
export default PRCartReducer;