import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import {
  getPopupMessageBasedOnStatus,
  objectIsEmpty,
} from '../../inc/Validation'
import Helper from '../../inc/Helper'

const CrudServerSide = () => {
  const gridApi = useRef(null)

  function convertObjectsToFilters(objects) {
    const filters = []

    for (const key in objects) {
      const { operator, conditions } = objects[key]
      const objFilters = []
      if (conditions && conditions.length > 0) {
        const conditionsFilters = conditions.map((condition) => ({
          left: key,
          right: condition.filter,
          operation: condition.type,
        }))

        objFilters.push(...conditionsFilters)
        filters.push({
          conditions:
            operator.charAt(0).toUpperCase() +
            operator?.toLowerCase()?.slice(1),
          filters: objFilters,
        })
      } else {
        const { type, filter } = objects[key]
        objFilters.push({
          left: key,
          right: filter,
          operation: type,
        })
        filters.push({
          conditions: operator || 'And',
          filters: objFilters,
        })
      }
    }

    return filters
  }

  let dataSource = {
    getRows: async function (params) {
      const filterObject = convertObjectsToFilters(params?.request?.filterModel)
      const queryString = `adv_search=${JSON.stringify(filterObject)}`
      const pageSize = params?.api?.paginationProxy?.pageSize
      const pageNumber = Math.floor(params.request.startRow / pageSize) + 1
      const url = objectIsEmpty(params?.request?.filterModel)
        ? `${Settings.apiUrl}/get_site?page_number=${pageNumber}&page_size=${pageSize}&is_deleted=0`
        : `${Settings.apiUrl}/get_site?${queryString}`
      try {
        let api = Api
        api.setUserToken()
        const response = await api.axios().get(url)

        setTimeout(() => {
          if (response.status === 200) {
            params.success({
              rowData: response?.data?.data,
              rowCount: response?.data?.total_records,
            })
          } else {
            params.fail()
          }
        }, 500)
      } catch (error) {
        getPopupMessageBasedOnStatus(error)
        params.fail()
      }
    },
  }

  const handleGridReady = (params) => {
    gridApi.current = params.api
    params.api.setGridOption('serverSideDatasource', dataSource)
  }

  const createRow = () => {
    return {
      gridId: `${Date.now()}_${getNewValue()}`,
    }
  }

  const addEmptyRow = useCallback(() => {
    const rowIndex = 0
    const transaction = {
      addIndex: rowIndex != null ? rowIndex : undefined,
      add: [createRow()],
    }
    gridApi.current.applyServerSideTransaction(transaction)
  }, [])

  const getNewValue = () => {
    return Math.floor(Math.random() * 100000) + 100
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return params?.data?.gridId
        ? `${params?.data?.gridId}`
        : `${params?.data?.site_id}`
    }
  }, [])

  const columnDefs = [
    {
      headerName: 'Site Code',
      field: 'code',
      editable:true,
    },
    {
      headerName: 'Site Name',
      field: 'name',
      editable:true,
    },
    {
      headerName: 'Entity Name',
      field: 'entity_name',
      editable:true,
    },
    {
      headerName: 'Created by',
      field: 'createdby',
      editable:false,
    },
    {
      headerName: 'modifiedby',
      field: 'modifiedby',
      editable:false,
    },  
  ]

  if (columnDefs.length >= 1) {
    columnDefs[0].headerCheckboxSelection = true
    columnDefs[0].checkboxSelection = true
  }


  const onRowValueChanged = (event) => {
    if (event?.data?.site_id) {
      updateSite(event)
    } else {
      addSite(event)
    }
  }

  const addSite = async (event) => {
    let payload = {
      code: event?.data?.code,
      name: event?.data?.name,
      entity_id: 115,
    }
    try {
      let api = Api
      api.setUserToken()
      const siteTypeData = await api
        .axios()
        .post(`${Settings.apiUrl}/site`, payload)
      setTimeout(() => {
        gridApi?.current?.refreshServerSide()
      }, 200)
      Helper.alert(siteTypeData?.data?.message)
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const updateSite = async (event) => {
    let payload = {
      code: event?.data?.code,
      name: event?.data?.name,
      entity_id: 115,
    }
    try {
      let api = Api
      api.setUserToken()
      const siteTypeData = await api
        .axios()
        .put(`${Settings.apiUrl}/site/${event?.data?.site_id}`, payload)
      setTimeout(() => {
        gridApi?.current?.refreshServerSide()
      }, 200)
      Helper.alert(siteTypeData?.data?.message)
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const handleDeleteSelectedRows = useCallback(async () => {
    const selectedRows = gridApi.current.getSelectedNodes()
    let api = Api
    for (const element of selectedRows) {
      if (!element?.data?.site_id) {
        const transaction = { remove: [element.data] }
        gridApi.current.applyServerSideTransaction(transaction)
      } else {
        try {
          const res = await api
            .axios()
            .delete(`${Settings.apiUrl}/site/${element?.data?.site_id}`)
          const rowNode = gridApi?.current?.getRowNode(element.site_id)
          if (rowNode) {
            rowNode.setSelected(false)
          }
          setTimeout(() => {
            const transaction = { remove: [element.data] }
            gridApi.current.applyServerSideTransaction(transaction)
          }, 200)
          Helper.alert(res?.data?.message, 'success')
        } catch (err) {
          getPopupMessageBasedOnStatus(err)
        }
      }
    }
    gridApi.current.deselectAll()
  }, [])

  const handlePageSizeChange = (params) => {
    params.api.setGridOption('cacheBlockSize',params?.api?.paginationProxy?.pageSize)
  }

  return (
    <div>
      <div className='d-flex mt-4 mb-2 gap-3'>
        <button onClick={addEmptyRow}>Add Empty Row</button>
        <button onClick={handleDeleteSelectedRows}>Delete</button>
      </div>
      <div style={{ width: '100%', height: '400px', marginTop: '1rem' }}>
        <div
          className='ag-theme-alpine'
          style={{ height: '100%', width: '100%' }}
          role='grid'
          tabIndex='0'
          onClick={(e) => {
            e.stopPropagation()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
            }
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{
              filter:'agTextColumnFilter'
            }}
            gridOptions={{
              rowModelType: 'serverSide',
              serverSideStoreType: 'partial',
              checkboxSelection: true,
              rowSelection: 'multiple',
              rowMultiSelectWithClick: false,
              suppressRowClickSelection: true,
              onRowValueChanged: onRowValueChanged,
              singleClickEdit: true,
              cacheBlockSize:10,
              onPaginationChanged:handlePageSizeChange,
              maxBlocksInCache:10,
            }}
            onGridReady={handleGridReady}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10,20,50,100]}
            getRowId={getRowId}
            editType={'fullRow'}
          />
        </div>
      </div>
    </div>
  )
}

export default CrudServerSide
