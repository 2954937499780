import React, { Component } from 'react';
import Input from "./Input"
import $ from 'jquery';
import '../../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
import datetimepicker from 'jquery-datetimepicker';
import Helper from '../../inc/Helper';

class InputTimePicker extends Component {
  constructor(props){
    super(props);
    this.datePickerObj = null;
    this.selectorName = this.props.inputClassName ? this.props.inputClassName : 'rs_timepicker';
    if(this.props.selectorName){
      this.selectorName = this.props.selectorName;
    }
  }

  componentDidMount(){
    this.initTimePicker();
  }

  componentDidUpdate(){
    this.initTimePicker();
  }

  initTimePicker(){
    let that = this;
    let selectorName = this.selectorName;
    let options = {
      datepicker: false, // disable datepicker
      format: 'H:i', // Only Time format
      onChangeDateTime:(e,$input) => {
        that.props.onChange(that.props.name,$input.val())
      }
    }
    this.datePickerObj = $('.' + selectorName).datetimepicker(options);
  }

  render() {
    let props = this.props;
    return(
      <Input {...props} inputClassName={ this.selectorName } />
    )
  }
}

export default InputTimePicker;