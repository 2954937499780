import React from 'react';
import { connect } from 'react-redux'; 
import DashboardContainer from '../../components/Dashboard/Dashboard Container/DashboardContainer';
import ExtHome from '../../components/External Screens/User Home Page/ExtHome';
import SimpleLoading from '../../components/Loading/SimpleLoading'; 
import MasterComonent from '../../components/Backend/MasterComonent';
import DashboardNav from '../../components/Dashboard/Dashboard Navigation/DashboardNav';

const DashboardScreen = (props) => {
  const renderDashboardBasedOnUserType = (auth) => {
    if(!auth?.isLogin) return <SimpleLoading />;
    if(auth?.user?.usertype === 1) return <ExtHome />
    if(auth?.user?.usertype === 0) return <DashboardNav />;

    return "No User Found!"
  };
  return ( 
    <MasterComonent masterClassName='no_padding_class'> 
      {renderDashboardBasedOnUserType(props?.auth)}
    </MasterComonent> 
  );
}

const mapStateToProps = (state) => {
  return { 
    auth: state.auth,
    theme: state.theme
  };
};

export default connect(mapStateToProps) ((DashboardScreen));