import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import MasterComonent from "../Backend/MasterComonent";
import Button from "../inc/Button";
import NavigationHeder from "../Navigations/NavigationHeder";
import NavCartWishlist from "./common/NavCartWishlist";
import EditOldCartModal from "./modals/EditOldCartModal";

class ShopLayout extends Component {
  componentDidMount() {
    this.getWishlistCount();
  }


  getWishlistCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/wishlist")
      .then(function (res) {
        that.props.changeWishlistCount(res.data.data?.length);
        that.props.updateWishlistIds([...res.data.data]);
      });
  }

  render() {
    return (
      <div style={{position:'relative'}}>
      <MasterComonent>
        <div className="pr-wrapper">
          <NavigationHeder
            backUrl={this.props.backUrl}
            title={this.props.title}
            hideMoreBtn={this.props.hideMoreBtn}
          >
            {this.props.hideMoreBtn ? (
              <NavCartWishlist hideNextBtn={this.props.hideNextBtn} handleBackBtn={this.props.handleBackBtn} nextBtnHandler={this.props.nextBtnHandler} isCartVisible={this.props.isCartVisible} isContinueShopping={this.props.isContinueShopping} prId={this.props.prId} currentDocumentStatus={this.props.currentDocumentStatus} />
            ) : (
              <>
                <Button isActive={false} title="Back" />
                <Button isActive={true} title="Save" />
              </>
            )}
          </NavigationHeder>
          {this.props.children}
        </div>
      </MasterComonent>
      {this.props.showEditOldCartModal ? <EditOldCartModal viewOnly={this.props.viewOnly} prId={this.props.prId}/> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showEditNewCartModal: state.prShop.showEditNewCartModal,
    showEditOldCartModal: state.prShop.showEditOldCartModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
    updateWishlistIds: (payload) =>
      dispatch({ type: "UPDATE_WISHLIST_IDS", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopLayout);
