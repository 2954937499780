import React, { useEffect, useRef } from 'react'

import Api from "../../../inc/Api"
import Settings from "../../../inc/Settings"
import Helper from '../../../inc/Helper'
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation'


const AttachmentTile = ({attachment,onDownloadClick}) => {
  return (
    <div key={attachment?.attachment_id} className='rfq-attachment-files dotted-outer-div'>
    <div style={{ display: 'flex', gap: '10px' }}>
      <img src='/images/icons/document-gray.svg' />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className='rfq-subtitle rfq-attachment-document-name'>
          {attachment?.filename}
        </span>
        <span className='rfq-attachment-value'>
          {attachment?.filesize} MB
        </span>
      </div>
    </div>
    <div className='d-flex gap-2 align-items-center'>
      <div
        onClick={() => onDownloadClick(attachment.attachment_id)}
        style={{ cursor: 'pointer' }}
      >
        <img src='/images/icons/print.svg' alt='print-icon' />
      </div>
    </div>
  </div>
  )
}


const FeedPopUp = (props) => {
  const modalRef = useRef(null)
   
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.closeModal()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [props.closeModal])

  const onDownloadClick = (id) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachment/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          const fileUrl = res.data.data.file_url
          const link = document.createElement('a')
          link.href = fileUrl
          link.download = 'filename'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  return (
    <div ref={modalRef} className='rfq-attachment-popup' style={props.style}>
      <div
        style={{ display: 'flex', marginLeft: '-5px', marginBottom: '18px' }}
      >
        <img src='/images/icons/attach.svg' alt='attachment-logo' />
        <span className='rfq-attachment-title'>
          {Helper.getLabel(props.language, 'attachments', 'Attachments')}
        </span>
      </div>
      <p className='rfq-subtitle rfq-attachment-documents'>
        Uploaded Documents
      </p>
      <div className='d-flex mb-2 flex-column gap-2'>
        {objectIsEmpty(props.attachment) ? (
          <p>No Attachment Found</p>
        ) : (
         
           <AttachmentTile key={props.attachment?.attachment_id} attachment={props.attachment} onDownloadClick={onDownloadClick}/>
        
        )}
      </div>
    </div>
  )
}

export default FeedPopUp
