import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import AgGrid from '../../grid/ag/ag-grid';
import Collapse from '../../inc/Collapse';

const GeneralVersion = ({language, data, currency, vendor, entities, itemGroup, ordered, requester, sites, taxGroup, taxCode}) => {

  // Grid Things -

  const Columns_Headings = [ 
    {field:'document_number', headerName:Helper.getLabel(language,'document_number',"Document Number"), minWidth: 250, editable: false},
    {field:'version', headerName:Helper.getLabel(language,'version',"Version"), minWidth: 250, editable: false}, 
    {field:'description', headerName:Helper.getLabel(language,'description',"Description"), minWidth: 250, editable: false},
    {field:'attention_information', headerName:Helper.getLabel(language,'attention_information',"Attention Information"), minWidth: 250, editable: false},
    {field:'purchase_status', headerName:Helper.getLabel(language,'purchase_status',"Purchase Status"), minWidth: 250, editable: false}, 
    {field:'vendor_id', headerName:Helper.getLabel(language,'vendor_id',"Vendor"), inputType: "dropdown", options: vendor, minWidth: 250, editable: false},
    {field:'vendorsite_id', headerName:Helper.getLabel(language,'vendorsite_id',"Vendor Site"),inputType: "dropdown", options: [], minWidth: 250, editable: false},
    {field:'warehouse_id', headerName:Helper.getLabel(language,'warehouse_id',"Warehouse"), minWidth: 250, editable: false}, 
    {field:'document_date', headerName:Helper.getLabel(language,'document_date',"Document Date"), inputType: "datepicker", minWidth: 250, editable: false},
    {field:'accounting_date', headerName:Helper.getLabel(language,'accounting_date',"Accounting Date"), inputType: "datepicker", minWidth: 250, editable: false},
    {field:'taxgroup_id', headerName:Helper.getLabel(language,'taxgroup_id',"Tax Group"), inputType: "dropdown", options: taxGroup, minWidth: 250, editable: false},
    {field:'taxcodes_id', headerName:Helper.getLabel(language,'taxcodes_id',"Taxcodes"), inputType: "dropdown", options: taxCode, minWidth: 250, editable: false}, 
    {field:'buyergroup_id', headerName:Helper.getLabel(language,'buyergroup_id',"Buyer Group"), inputType: "dropdown", options: itemGroup, minWidth: 250, editable: false},
    {field:'requester_id', headerName:Helper.getLabel(language,'requester_id',"Requester"),inputType: "dropdown", options: requester, minWidth: 250, editable: false},
    {field:'orderer_id', headerName:Helper.getLabel(language,'orderer_id',"Orderer"),inputType: "dropdown", options: ordered, minWidth: 250, editable: false}, 
    {field:'currency_id', headerName:Helper.getLabel(language,'currency_id',"Currency"), inputType: "dropdown", options: currency, minWidth: 250, editable: false},
    {field:'allowaccesstobuyergroup', headerName:Helper.getLabel(language,'allow_access_only_to_buyer_group',"Allow Access Only To Buyer Group"),inputType: "checkbox", options: [], minWidth: 250, editable: false},
    {field:'emailaddress', headerName:Helper.getLabel(language,'emailaddress',"Email Address"), minWidth: 250, editable: false}, 
    {field:'entity_id', headerName:Helper.getLabel(language,'entity_id',"Entity"), inputType: "dropdown", options: entities, minWidth: 250, editable: false},
    {field:'site_id', headerName:Helper.getLabel(language,'site_id',"Site"), inputType: "dropdown", options: sites, minWidth: 250, editable: false},
    {field:'blindreceivingofqty', headerName:Helper.getLabel(language,'blindreceivingofqty',"Blind Receiving Of Qty"), inputType: "checkbox", minWidth: 250, editable: false},
    {field:'externalreference', headerName:Helper.getLabel(language,'externalreference',"External Reference"), minWidth: 250, editable: false},
  ]

  // --
  
  return (
    <Collapse className='general_po_version' open={true} title={Helper.getLabel(language,'general',"General")}> 
      <div className='container-fluid general_po_version'>
        <AgGrid data={data} id="rs_general_po_version" allBtnHide={true} header={Columns_Headings} />
      </div>
    </Collapse>
  )
}

 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
  
export default connect(mapStateToProps) (GeneralVersion) 