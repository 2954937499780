import Helper from '../../../inc/Helper'

const interestedTypeMessage = (res, type) => {
  if (type === 'interested') {
    return Helper.alert('Interested in Bid', 'success')
  } else if (type === 'not interested') {
    return Helper.alert('Not Interested in Bid', 'success')
  } else if (type === 'save') {
    return Helper.alert(res.data.message, 'success')
  } else {
    return null
  }
}


const dateTimeFormat = (date) => {
  const parsedDate = new Date(date)

  const year = parsedDate.getFullYear()
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
  const day = String(parsedDate.getDate()).padStart(2, '0')

  const hours = String(parsedDate.getHours()).padStart(2, '0')
  const minutes = String(parsedDate.getMinutes()).padStart(2, '0')
  const seconds = String(parsedDate.getSeconds()).padStart(2, '0')

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return formattedDateTime
}

const getBGStatusColor = (status) => {
  switch (status) {
    case 0:
      return "#30303014";
    case 1:
      return "#2D74E014";
    case 2:
      return "#32C47214";
    case 3:
      return "#2D74E014";
    case 4:
      return "#FC5E2414";
    case 5:
      return "#FC5E2414";
    case 6:
      return "#32C47214";
    case 7:
      return "#32C47214";
    case 8:
      return "#FC5E2414";
    case 9:
      return "#FC5E2414";
    case 10:
      return "#FC5E2414";
    default:
      return "#30303014";
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 0:
      return "#303030";
    case 1:
      return "#2D74E0";
    case 2:
      return "#3CBA54";
    case 3:
      return "#2D74E0";
    case 4:
      return "#FC5E24";
    case 5:
      return "#FC5E24";
    case 6:
      return "#3CBA54";
    case 7:
      return "#3CBA54";
    case 8:
      return "#FC5E24";
    case 9:
      return "#FC5E24";
    case 10:
      return "#FC5E24";
    default:
      return "#303030";
  }
};

const getSearchParam = (name,location) => {
  const params = new URLSearchParams(location.search);
  return params.get(name);
};


export { dateTimeFormat, getBGStatusColor, getStatusColor, getSearchParam, interestedTypeMessage }
