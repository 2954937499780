import React from 'react'
import CommonChartCard from './common/CommonChartCard'
import { Doughnut } from 'react-chartjs-2'
import CommonInnerCircle from './common/CommonInnerCircle'
import {Chart,ArcElement} from 'chart.js';

Chart.register(ArcElement)

//data for chart
export const data = {
  labels: ['PR', 'RFQ', 'Contracts', 'PO', 'RFI'],
  datasets: [
    {
      data: [20, 40, 30, 20, 90],
      backgroundColor: [
        '#00B8D9',
        '#2F78EE',
        '#FF5630',
        '#FFAB00',
        '#00AB55',
      ].reverse(),
      borderColor: 'white',
      hoverBorderColor: 'white',
      borderWidth: 4,
    },
  ],
}

const EngagementProcessChart = () => {
  const totalTasks = data.datasets[0].data.reduce((acc, num) => acc + num, 0)

  //options for chart
  const options = {
    cutout: '85%',
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        direction: 'column',
        fullWidth: false,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: 'circle',
          pointRadius: 3,
          padding: 20,
        },
        align: 'center',
      },
     
    },
  }

  

  return (
    <div style={{position:"relative"}}>
      <CommonChartCard title="Engagement by Process">
        <Doughnut data={data} options={options} />
        <CommonInnerCircle totalTasks={totalTasks} />
      </CommonChartCard>
    </div>
  )
}

export default EngagementProcessChart
