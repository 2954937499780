import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings'; 
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import AgGridNew from '../grid/ag/ag-grid-new';
import SimpleDropdown from '../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown } from '../grid/ag/commonFunctions/GridCommonFunctions'; 

let gridApi = null;
let AddBtn; 
let transaction;
let totalDropdownPage = 1

const CategoryVendors = ({master_category_id, language}) => { 
    const [vendors, setVendors] = useState([]);     
    const [loading, setLoading] = useState(false);

    let vendorCalled = false;

    useEffect(() => { 
        getAllVendorsList();
    }, []);

    useEffect(() => {
        if(!master_category_id) setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300)  
    }, [master_category_id])

    const getAllVendorsList = () => {
        if(vendorCalled) return;
        vendorCalled = true;

        Api.setUserToken();
        Api.axios().get(Settings.apiVendorUrl + "/vendor").then(res => { 
            setVendors(res.data.data?.map(item => {
                return {
                    value: item?.vendor_id,
                    label: `${item?.code} - ${item?.name}`
                }
            })); 
        });
    }
    
    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const onRowValueChanged = (event) => {
        if (!event?.data?.categoryvendor_id) {
            onAddNewVendor(event)
        }
    }
    
    const onAddNewVendor = async (event) => {
        try {
            let payload = {
                vendor_id: event?.data?.vendor_id,
                category_id: master_category_id
            };
         
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(`${Settings.apiVendorUrl}/vendorcategory`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);
                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }  
    };

    const deleteVendorCategoryHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        
        let delete_category_vendor = element?.data?.categoryvendor_id;

        try {
            const res = await api
                .axios()
                .delete(`${Settings.apiVendorUrl}/categoryvendor/${delete_category_vendor}`)
            const rowNode = gridApi?.getRowNode(delete_category_vendor)

            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element?.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200) 

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
 
    let select2Settings = {
        ajax: {
            url: (params) =>{
                totalDropdownPage = params.page || 1;
                return Settings.apiVendorUrl + '/get_active_vendors';
            },
            processResults: function (data) {
                let isMorePageExist = ( data.total_records - ( totalDropdownPage * Settings.dropdownPageSize )) >0 ? true : false ; 
                let result = data.data.map( item => {
                    return {
                        id: item.vendor_id,
                        text: item.code+' - '+item.name
                    }
                })
                return {
                    results:result,
                    pagination: {
                        more: isMorePageExist
                    }
                };
            }
        }
    }
    
    const gridColumnHeaders = [
        {
            field: 'vendor_id',
            headerName: Helper.getLabel(language, "vendor", 'Vendor'),
            editable: (item) => !item.data.vendor_id,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth: 180,
            select2Settings:select2Settings,
            cellEditorParams: { values: vendors },
            cellDataType:false,
            valueGetter: (params) => {
                if(!params?.data?.code || !params?.data?.name){
                    return ''
                }
                else {
                    return params?.data?.code + ' - ' + params?.data?.name
                }
            },
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agTextColumnFilter'
        }
    ];

    return (
        <div className='category_vendors grid_save_btn_hide'> 
            <AgGridNew
                apiUrl={master_category_id?Settings.apiVendorUrl+`/vendorcategory/${master_category_id}`:null}
                pagination={false}
                columnDefs={gridColumnHeaders}
                key={loading ? 'render' : 'no render'}
                onRowValueChanged={onRowValueChanged}
                handleDeleteSelectedRows={deleteVendorCategoryHandler}
                height={500}
                onGridReady={(params) => gridApi = params?.api}
                handleAddButton={handleBtn}
                uniqueField={`categoryvendor_id`}
                gridId={'vendorcategory_list'}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    language: state.language
});

export default connect(mapStateToProps)(CategoryVendors); 