import React from 'react'
import CommonChartCard from './CommonChartCard'
import { Doughnut } from 'react-chartjs-2'
// import CommonInnerCircle from './CommonInnerCircle'

import {Chart,ArcElement} from 'chart.js';
import CommonInnerCircle from '../../Employee Dashboard/EmployeeDasboardScreen/EmployeeInfoCharts/EmployeeTasksInfo/common/CommonInnerCircle';

Chart.register(ArcElement)


//data for chart
export const data = {
  labels: ['Active', 'On-hold', 'Freeze','In-Amendment','In-Renewal','In-Early-Settlement'],
  datasets: [
    {
      data: [154, 5, 4, 37, 6, 3],
      backgroundColor: [ '#00B8D9', '#2F78EE', '#FF5630', '#FFAB00', '#00AB55', '#FF69B4'],
      borderColor: 'white',
      hoverBorderColor: 'white',
      borderWidth: 8,
      borderJoinStyle: 'round',
      borderRadius: 8,
    },
  ],
}

const ContractByStatusChart = () => {
  //options for chart
  const options = {
    cutout: "75%",
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        direction: 'column',
        fullWidth: false,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: 'circle',
          pointRadius: 3,
          padding: 20,
        },
        align: 'start',
      },
      tooltip:{
        boxPadding:5,
      },
    },
  }
  const totalTasks = data.datasets[0].data.reduce((acc, num) => acc + num, 0)
  return (
    <div style={{ position: 'relative' }}>
      <CommonChartCard title="Contract count by status">
        <Doughnut data={data} options={options}></Doughnut>
        <CommonInnerCircle totalTasks={totalTasks} />
      </CommonChartCard>
    </div>
  )
}

export default ContractByStatusChart
