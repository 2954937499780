import React,{useRef,useState,useEffect} from 'react'
import Collapse from '../../../inc/Collapse'
import Settings from '../../../../inc/Settings'
import FixedNavigationHeder from '../../../Navigations/FixedNavigationHeder'
import TabContentWraper from '../../../Widgets/Tabs/TabContentWraper'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import BlueCallToAction from '../../../Widgets/BlueCallToAction/BlueCallToAction'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import SegmentsDistribution from '../../../Segments/SegmentsDistribution'
import Helper from '../../../../inc/Helper'
import { useLocation, useNavigate, useParams } from 'react-router'
import SimpleLoading from '../../../Loading/SimpleLoading'
import HeaderViewDelivery from '../../../Purchase Orders Internal/Edit PO/HeaderViewDelivery'
import AddressList from '../../../Tenant/Address/AddressList'
import ContactAddress from '../../../Tenant/Address/ContactAddress'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import Button from '../../../inc/Button'
import { connect } from 'react-redux'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import InvoiceForm from '../InvoiceForm'
import Api from '../../../../inc/Api'
import InvoiceLineGrid from '../InvoiceLineGrid'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import WorkFlowCommonHeaderAPICalls from '../../../Header/WorkflowCommonHeaderAPICalls'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import WorkFlowCommonHeader from '../../../Header/WorkFlowCommonHeader'
import WorkFlowOffCanvas from '../../../Header/WorkFlowOffCanvas'

let contactGridObj = null

const InvoiceDetailsInternal = (props) => {
const [invoiceData,setInvoiceData]=useState({})
const [loading,setLoading]=useState(false)
const [invoiceDate,setInvoiceDate]=useState(new Date())
const [documentDate,setDocumentDate]=useState(new Date())
const [invoiceNumber,setInvoiceNumber]=useState('')
const [invoiceDescription,setInvoiceDescription]=useState('')
const [documentStatus,setDocumentStatus]=useState('')
const [documentStatuses,setDocumentStatuses]=useState([])
const [linesData,setLinesData]=useState([])
const [lineIds, setLineIds]=useState([])
const [selectedSupNav, setSelectedSupNav] = useState('Header View')
const [scrollDitection, setScrollDitection] = useState('down')
const [height, setHeight] = useState(0)

const [workflowActionHistory, setWorkflowActionHistory] = useState([])
const [workflowEligible, setWorkflowEligible] = useState(null)
const [workflowExist, setWorkflowExist] = useState({})
const [showCanvas, setShowCanvas] = useState(false)
const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)

const myDivRef = useRef(null);

let sectionName = 'invoice-edit'

const invoiceApiCalls = new WorkConfirmationApiCalls()

const {id} = useParams()
const {workflow_instance_id} = useParams();
const navigate = useNavigate()
const location = useLocation()

useEffect(() => {
  Helper.resizeWindowHandle(myDivRef,setHeight,window)
},[myDivRef?.current?.clientHeight,scrollDitection]);

let superNav = [
    { title: Helper.getLabel(props.language, 'header_view', 'Header View') },
    { title: Helper.getLabel(props.language, 'line_view', 'Line View') },
  ]
  let navs = [
    { title: Helper.getLabel(props.language, 'general', 'General'), data_section: "one", active_status: 'active_tab' },
    { title: Helper.getLabel(props.language, 'delivery', 'Delivery'), data_section: "two", active_status: '' },
    // { title: Helper.getLabel(props.language, 'price_discount', 'Price & Discount'), data_section: "three", active_status: '' },
    { title: Helper.getLabel(props.language, 'segment', 'Segment'), data_section: "four", active_status: '' },
    { title: Helper.getLabel(props.language, 'address', 'Address'), data_section: "five", active_status: '' },
    { title: Helper.getLabel(props.language, 'contact', 'Contact'), data_section: "six", active_status: '' },
  ];

const addComponentTop = () => { 
    const editPOReferenceNumber = invoiceData?.reference_number || "-";
    const documentDate = DatepickerFunctions.convertDateFromDataBase(invoiceData?.document_date) || '-';
    const vendorCode = invoiceData?.vendor_code || "-";
    const vendorName = invoiceData?.vendor_name || "-";
    let items = [
      { title:Helper.getLabel(props.language, 'invoice_number', "Invoice No."), subtitle:editPOReferenceNumber },
      { title: Helper.getLabel(props.language, 'vendor_id', "Vendor Code"), subtitle:vendorCode },
      { title: Helper.getLabel(props.language, 'vendorname', "Vendor Name"), subtitle:vendorName },
      { title: Helper.getLabel(props.language, 'document_date', "Document Date"), subtitle:documentDate},
    ]
    let tagTitle = documentStatus || "-";
    let tags = [
      { title: tagTitle || null },
    ]

    return (
      <div className='rfq-internal-blue'>
        <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
      </div>
    )
  }
  
  const addComponentRight = () => {
    return ( 
        <div className='parent_sidebar_cn'>
        <MasterSidebar>
          <div style={{ background: '#fff' }}>
            <NoteAttachmentHistory  
              openCanvas={setShowCanvas}
              openCurrentAssignees={setShowCurrentAssignees}
              scrollDitection={scrollDitection}
              height={height}
              hideHistory={props.auth?.user?.usertype==1?true: !workflowActionHistory?.history?.length}
              historyArray={workflowActionHistory}
              notesource_integrator={id}
              attachmentsource_integrator={id}
              source_id={Settings.source_id.invoice}
              condition={invoiceData?.document_status===2}
            />
          </div>
        </MasterSidebar>

        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={Settings.source_id.invoice}
            source_item_id={id}
          />
        }

        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={Settings.source_id.invoice}
            source_item_id={id}
          />
        }
      </div> 
    )
  }

  const getWorkFlowCalls = async () => {
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();
    const trigger = await workflowCalls.getWorkFlowTriggers();
    const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name == "Update")?.id;
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.invoice, props?.auth?.user?.tenant_id, mode);
    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props?.auth?.user?.tenant_id, Settings.source_id.invoice, id);
 
    setWorkflowExist(workflowExist);
    setWorkflowEligible(eligibleWorkflow);

    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.invoice, id);
    setWorkflowActionHistory(workflowActionHistory);
  }

  const HeaderViewSegment = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language, 'segment', 'Segment')}>
        <SegmentsDistribution isEditable={false} id="invoice_header_segments" disableFinancial={true} distribution_source_id={invoiceData?.distributionsource_id} integrator={id} source_id={Settings?.source_id?.invoice} totalAmount={100} />
      </Collapse>
    )
  }

  const handleEditButton = () => {
    navigate(`/invoice/edit/${id}`,{state:{prevPath:"/invoice-details"}})
  }
 
  const getAllShDocumentStatus =  () => { 
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/enum/invoice_header_documentstatus`)
      .then(function (res) {
        if (res.data.status === 'success') {
           setDocumentStatuses(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
   }

  const getInvoiceDetails = () => {
    if (!id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/invoice_header/${id}`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          setInvoiceData(res.data.data) 
          if (res.data.data.purchaseorder_id) {
            invoiceApiCalls
              .getAllHeaderLines(
                res.data.data.purchaseorder_id,
                Settings.source_id.purchase_order,
              )
              .then((lineId) => {
                if (lineId) setLineIds(lineId)
              })
          } 
          setLoading(false) 
        }
      })
      .catch((error) => {
        Helper.alert(error.response.data.message, 'error')
        setLoading(false)
      })
  }

  const getAllLines = () => {
    if (!id || !invoiceData?.source_id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/lines/${id}/${invoiceData?.source_id}`,
        {},
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          let updatedData = []
          for (let obj of res.data.data) {
            getLineItemDetails(obj.line_id)
              .then((data) => {
                obj.items = data
                updatedData.push(obj)
              })
              .catch((error) => {
                obj.items = []
                updatedData.push(obj)
              })
          }
          setTimeout(() => {
            setLinesData(updatedData)
            setLoading(false)
          }, 300)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const getLineItemDetails = (line_id) => {
    return new Promise((resolve, reject) => {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .get(Settings.apiPurchaseOrderUrl + `/lines/${line_id}/${Settings?.source_id?.invoiceLineDetails}`, {})
        .then((res) => {
          if (res.data.status === 'success') {
            resolve(res.data.data)
          } else {
            reject(new Error('API call failed'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }


  useEffect(()=>{
    getInvoiceDetails()
    getAllShDocumentStatus()
  },[])

  useEffect(() => {
    if (invoiceData?.document_status !== undefined) {
      const document_status = documentStatuses?.find(
        (option) => option.id === invoiceData?.document_status
      );
      setDocumentStatus(document_status?.name);
    }
  }, [invoiceData, documentStatus]);

  useEffect(() => {
    if (!id || !invoiceData) return
    getAllLines()
  }, [id, invoiceData])

  useEffect(() => { 
    if(!props?.auth?.user) return;
    getWorkFlowCalls()
  }, [props?.auth?.user])

  return (
    <div
      className={
        'container-fluid vn_edit_screen invoice-on-account-screen scrollBarBlinkControl document-main-screen'
      }
    >
      <div ref={myDivRef}>
        <NavigationHeder
          backUrl={location?.state?.prevPath==="/work-confirmation/edit"?"/invoice-list":-1}
          hideMoreBtn={true}
          title={`<span style="color:#757575;">${Helper.getLabel(
            props.language,
            'invoice_listing',
            'Invoice List'
          )} > </span> Invoice Details : ${invoiceData?.document_number}`}
        >
          <Button
            onClick={() => {
              navigate(-1)
            }}
            className='black-btn-style'
            title={Helper.getLabel(props.language, 'close', 'Close')}
          />
          {invoiceData?.document_status === 0 && (
            <Button
              onClick={handleEditButton}
              className="blue-btn-style"
              title={Helper.getLabel(props.language, 'edit', 'Edit')}
            />
          )}
          <TotalWidget
            source_id={Settings?.source_id?.invoice}
            integrator={invoiceData?.invoice_id}
          />
        </NavigationHeder>

        {workflowExist?.instance_exist && workflowEligible && documentStatus != "Approved" && !loading &&
          <div className='invoice_workflow'>
            <WorkFlowCommonHeader
              instance_task_id={workflow_instance_id}
              workflowInstanceDetails={workflowExist?.instance_details}
              module_id={!workflow_instance_id ? Settings.source_id.invoice : null}
              source_id={Settings.source_id.invoice}
              source_item_id={id}
              SubmitWorkflowObjDetails={workflowExist?.workflow_name}
              module_item_id={!workflow_instance_id ? id : null}
              workflowInstanceExist={workflowExist?.instance_exist}
              workflowEligible={workflowEligible}
              workflowName={'Invoice Workflow'}
              reloadFunction={() => {
                getWorkFlowCalls();
                getInvoiceDetails();
              }}
            />
          </div>
        }

        <FixedNavigationHeder
          addComponentTop={addComponentTop}
          sectionName={sectionName}
          navs={navs}
          scrollDitection={scrollDitection}
          selectedSupNav={selectedSupNav}
          setSelectedSupNav={setSelectedSupNav}
          superNav={superNav}
        />
      </div>

      {loading ? (
        <SimpleLoading />
      ) : (
        <TabContentWraper
          height={height}
          setScrollDitection={setScrollDitection}
          leftSideContent={
            <>
              {selectedSupNav == 'Header View' && (
                <>
                  <div
                    className='lists_contant one'
                    id={`${sectionName}General`}
                    style={{ position: 'relative' }}
                  >
                    <Collapse
                      title={Helper.getLabel(
                        null,
                        'general_information',
                        'General Information'
                      )}
                      open={true}
                    >
                      <InvoiceForm
                        invoiceHeader={invoiceData}
                        setInvoiceHeader={setInvoiceData}
                        invoiceDate={invoiceData?.invoice_date}
                        setInvoiceDate={setInvoiceDate}
                        documentDate={invoiceData?.document_date}
                        setDocumentDate={setDocumentDate}
                        setInvoiceNumber={setInvoiceNumber}
                        setInvoiceDescription={setInvoiceDescription}
                        type='internal'
                        userType={props.auth?.user?.usertype}
                      />
                    </Collapse>
                  </div>
                  <div
                    className='lists_contant two mt-4'
                    id={`${sectionName}Delivery`}
                    style={{ position: 'relative' }}
                  >
                    <HeaderViewDelivery
                      isEditable={false}
                      state={invoiceData}
                      setState={setInvoiceData}
                    />
                  </div>
                  {/* <div
                    className='lists_contant three'
                    id={`${sectionName}Price & Discount`}
                    style={{ position: 'relative' }}
                  >
                    <HeaderViewPriceDiscount
                      isEditable={false}
                      state={invoiceData}
                      setState={setInvoiceData}
                    />
                  </div> */}
                  <div
                    className='lists_contant four'
                    id={`${sectionName}Segment`}
                    style={{ position: 'relative' }}
                  >
                    {HeaderViewSegment()}
                  </div>
                  <div
                    className='lists_contant five'
                    id={`${sectionName}Address`}
                    style={{ position: 'relative' }}
                  >
                    <AddressList
                      canEdit={false}
                      addressSourc_id={invoiceData.addresssource_id}
                      address_source_integrator={invoiceData?.invoice_id}
                      source_id={Settings.source_id.invoice}
                      allBtnHide={true}
                    />
                  </div>
                  <div
                    className='lists_contant six'
                    id={`${sectionName}Contact`}
                    style={{ position: 'relative' }}
                  >
                    <Collapse
                      title={Helper.getLabel(null, 'contact', 'Contact')}
                      open={true}
                      className='mt-4'
                    >
                      <ContactAddress
                        onGridReady={(ctGrid) => {
                          contactGridObj = ctGrid
                        }}
                        canEdit={false}
                        contactsource_id={invoiceData?.contactsource_id}
                        source_id={Settings.source_id.invoice}
                        address_id={null}
                        contactsource_integrator={invoiceData?.invoice_id}
                      />
                    </Collapse>
                  </div>
                </>
              )}
              {selectedSupNav === 'Line View' && (
                <div style={{ position: 'relative' }}>
                  <InvoiceLineGrid
                    lineData={linesData}
                    fetchAllLines={getAllLines}
                    lineIdOptions={lineIds}
                    gridType='summary'
                    userType={props.auth?.user?.usertype}
                    invoiceHeader={invoiceData}
                    currentStep={4}
                  />
                </div>
              )}
            </>
          }
          rightSideContent={addComponentRight}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions: state.options,
      auth: state.auth,
      language: state.language,
    }
  }

export default connect(mapStateToProps)(MasterComponentWraper(InvoiceDetailsInternal))
  