import React, { Fragment, useCallback, useState } from "react"; 
import MasterComponentWraper from "../../../../Backend/MasterComponentWraper"; 
import NavigationHeder from "../../../../Navigations/NavigationHeder";
import ButtonNew from "../../../../inc/ButtonNew"; 
import { useNavigate } from "react-router-dom"; 
import Api from "../../../../../inc/Api";
import Settings from "../../../../../inc/Settings";
import Helper from "../../../../../inc/Helper"; 
import { connect } from "react-redux";
import { getPopupMessageBasedOnStatus } from "../../../../../inc/Validation";
import AgGridNew from "../../../../grid/ag/ag-grid-new";
import { gridLinkValueFormat } from "../../../../grid/ag/commonFunctions/GridCommonFunctions";
import CreateResponsesModal from "./CreateResponsesModal";
import ApplySecurityRoles from "../../../../SecurityRoles/ApplySecurityRoles";
import Gui_id_list from "../../../../../inc/Gui_id_list";
import Alert from "../../../../inc/Alert";

let gridApi = null;

const ListViewResponses = (props) => { 
    const [showResponsesModal, setShowResponsesModal] = useState(false);

    const navigate = useNavigate();
  
    const CellHandler = (event) => {
        if(event.colDef.field === 'compliance_name') { 
            navigate(`/compliances-responses/edit/${event?.data?.complianceheader_id}`)
        }
    }

    const DeleteComplianceResponse = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delCompliance_ID = element?.data?.complianceheader_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl+`/complianceresponseheader/${delCompliance_ID}`)
            const rowNode = gridApi?.getRowNode(delCompliance_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
   
    const gridColHeaders = [
        {field:'compliance_name', filter:'agTextColumnFilter', editable: false, headerName: Helper.getLabel(props.language,'name','Name'), cellRenderer: (params) => gridLinkValueFormat(params)}, 
    ];

    if(!props?.security.canView(Gui_id_list.procure.request_for_quotation.control_forms.compliances_responses.compliances_responses)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return ( 
        <div className='compliance_responses_list_view'>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'compliance_response','Compliance Responses')}>
                    <ButtonNew
                        isDisable={!props?.security.canCreate(Gui_id_list.procure.request_for_quotation.control_forms.compliances_responses.compliances_responses_main_create_button)} 
                        onClick={() => setShowResponsesModal(true)} title={Helper.getLabel(props.language,'new','New')} 
                    />
                </NavigationHeder>

                <AgGridNew
                    apiUrl={Settings.apiPurchaseRequisitionUrl+'/complianceresponseheader'} 
                    pagination={true}
                    hideDeleteBtn={!props?.security.canCreate(Gui_id_list.procure.request_for_quotation.control_forms.compliances_responses.compliances_responses_main_delete_button)}
                    columnDefs={gridColHeaders} 
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'complianceheader_id'}
                    onCellClicked={CellHandler}
                    handleDeleteSelectedRows={DeleteComplianceResponse}
                    onGridReady={(params) => gridApi = params?.api} 
                    gridId={"rs_compliances_response_list"}
                /> 
            </div>

            {showResponsesModal && <CreateResponsesModal setShowCreateResponsesModal={setShowResponsesModal} />}
        </div>  
    );
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};
  
const SecurityOptions = {
    gui_id:Gui_id_list.procure.request_for_quotation.control_forms.compliances_responses.compliances_responses
};
 
export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(ListViewResponses, SecurityOptions))));  