import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import $ from 'jquery'
import Settings from '../../../inc/Settings'
import Helper from '../../../inc/Helper'
import axios from 'axios'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button'
import NavigationHeder from '../../Navigations/NavigationHeder'
import SimpleLoading from '../../Loading/SimpleLoading'
import AgGrid from '../../grid/ag/ag-grid' 
import Api from '../../../inc/Api'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'

const AccessLevels = (props) => {
    const [accessLevelsGrid, setAccessLevelsGrid] = useState([]) 
    const [loading, setLoading] = useState(true)

    let accessLevelsCalled = false

    useEffect(() => {
        get_access_levels_data()
    }, [])

    const get_access_levels_data = () => {
        if(accessLevelsCalled){
            return;
        }
        accessLevelsCalled = true 
        setLoading(true)
        let api = Api
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/access_levels',{}).then(function(res){
        if(res.data.status==="success"){ 
            setAccessLevelsGrid(res.data.data.reverse())
            setLoading(false)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            setLoading(false)
        })
    }
 
    const onSaveClick = (rowItem) => { 
        let updatedData = []
        let newData=[];
        rowItem.forEach(item => {
            if( !item.teamaccess_level_id || item.teamaccess_level_id <=0){
            newData.push(item);
            }else{
            updatedData.push(item);
            }
        });
        if(newData.length>=1){
            addNewData(newData);
        }
        if(updatedData.length>=1){ 
            updateData(updatedData);
        }
    } 

    const updateData = (data) =>{ 
        let api = Api
        api.setUserToken()
        data.forEach(element => {
        api.axios().put(Settings.apiUrl+`/access_level/${element.teamaccess_level_id}`,{
            "accesslevel_description": element.description,
            "comments":element.comments
        }).then(function(res){
            if(res.data.status==="success"){
                Helper.alert(res.data.message)
                get_access_levels_data()
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            get_access_levels_data()
        })
        })
    }
 
    const addNewData = (data) => {
        let api = Api
        api.setUserToken()
        data.forEach(element => {
        api.axios().post(Settings.apiUrl+'/access_levels',{
            "accesslevel_description": element.description,
            "comments": element.comments
        }).then(function(res){
            if(res.data.status==="success"){
                get_access_levels_data()
                Helper.alert(res.data.message)
            }
            }).catch((res) => { 
                get_access_levels_data()
                getPopupMessageBasedOnStatus(res) 
            })
        }) 
    }

    const DeleteHandler = (allRows) => { 
        let api = Api
        api.setUserToken()
        allRows.forEach(element => {
            api.axios().delete(Settings.apiUrl+`/access_level/${element.teamaccess_level_id}`,{}).then(function(res){
                if(res.data.status==="success"){
                    Helper.alert(res.data.message)
                    get_access_levels_data()
                }
            }).catch((res) => { 
                getPopupMessageBasedOnStatus(res) 
                get_access_levels_data()
            })
        })
    }

    const SaveHandler = () => {
        $('.rs_access_levels_grid .rs_grid_btn_save').trigger('click'); 
    }

    let Columns_Headings = [
        {field:'description', headerName:Helper.getLabel(props.language,'description','Description')},
        {field:'comments', headerName:Helper.getLabel(props.language,'comments','Comments')} 
    ]

    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.access_levels.access_levels)){
        return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

  return (
    <Fragment> 
        <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'access_levels','Access Levels')}>
            <Button isDisable={!security.canEdit(frontendIds.procure.purchase_orders.control_forms.access_levels.access_levels_save_button)} isActive= {true} title='Save' onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder>

        {loading ? <SimpleLoading /> : (
            <div className="rs_access_levels_grid grid_save_btn_hide">
                <AgGrid addBtnHide={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.access_levels.access_levels_create_button)} hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.access_levels.access_levels_delete_button)} data={accessLevelsGrid} id="access-levels-grid" header={Columns_Headings} onDelete={DeleteHandler} onSave={onSaveClick} />
            </div>
        )}
    </Fragment> 
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.control_forms.access_levels.access_levels
}

export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(AccessLevels, SecurityOptions)))