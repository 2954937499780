import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Checkbox from '../../Forms/Checkbox';
import DropDownInput from '../../Forms/Dropdown';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import Popup from '../../Popup/Popup';
import $ from 'jquery';
import RsWithRouter from '../../inc/RsWithRouter';
import Dropdown from '../../Forms/Dropdown';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import Collapse from '../../inc/Collapse';

class NewWarehouse extends Component {
    constructor(props){
        super(props);
        this.state = {
            entity:[],
            entityId:null,
            siteList:[],
            isLoading:false,
            needRedirect:false, 
            currentSearchParam:'', 
            entityAllData: createRef() 
        }
    }
    loadSitesByEntityId (entityId){
        let that = this;
        
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/get_site_entity/'+entityId).then(function(res){
            if(res.data.status ==='success'){
                let dataList = res.data.data.reverse();
                that.setState({
                    siteList:dataList
                })
            }
        })
    }
    onSaveHander(e){
        let formData = new FormData(document.getElementById('new_form'));
        let newData = {
            "entity_id": Number.parseInt(formData.get('entity_id')),
            "site_id": Number.parseInt(formData.get('site_id')),
            "name":formData.get('name'),
            "code":formData.get('code'),
            "is_active": formData.get('is_active') ==='on' ? true : false,
            "translationsource_id":null,
            "address":null,
            "contact":null,
        }
        if(!newData?.entity_id){
            Helper.alert("Entity field is required",'error')
            return;
        }
        if(!newData?.site_id){
            Helper.alert("Site field is required",'error')
            return;
        }
        if(newData?.code === ""){
            Helper.alert("Code field is required",'error')
            return;
        }
        if(newData?.name === ""){
            Helper.alert("Name field is required",'error')
            return;
        }
        let api = Api;
        let that =  this;
        this.setState({
            isLoading:true
        })
        api.setUserToken();
        api.axios().post(Settings.apiUrl+'/warehouse',newData).then(function(res){
            if(res.data.status ==='success'){
                that.onPopupClose();
                that.props.rs_router.navigate('/warehouse/edit/'+res.data.warehouse_id);
            }else{
                that.setState({
                    isLoading:false
                })
                Helper.alert(res.data.message,'error')
            }
        }).catch( function(error){
            getPopupMessageBasedOnStatus(error)
            console.log(error)
            that.setState({
                isLoading:false
            })
        })
    }
    mapEntityValue(entity){
        return { text:  entity.name + ' ('+entity.code+")", id: entity.entity_id }
    } 
    onPopupClose(){
        this.props.onPopupClose()
        $('html').removeClass('rs_popup_open');
    }
    onEntityChangeHandler(event){
        let that = this; 
        this.setState({
            entityId:event.target.value
        },function(){
            that.loadSitesByEntityId(event.target.value)
        })
    }
    getSiteList(){
        if(this.state.entityId){
            return this.state.siteList;
        }
        return this.props.siteList; 
    }
    render() {
        
        let language = this.props.language;
        let entity_list = this.props.entityList.map( entity => { return { label:  entity.name + ' ('+entity.code+")", value: entity.entity_id }});
        let siteList = this.getSiteList().map( site => { return { label:  site.name, value: site.site_id }} );
        return (
            <Fragment>
                { this.state.isLoading ? <OverlayLoader /> : null }  
                <Popup autoOpen={true} width="900px" onClose={ this.onPopupClose.bind(this)}>
                    <>
                        <h5 className='create_rfq_modal__title'>{Helper.getLabel(language,'create_warehouse','Create Warehouse')}</h5>
                        <Collapse
                            className="mt-4 mb-4" open={true}
                            title={Helper.getLabel(
                                language,
                                "general_info",
                                "General Information"
                            )}
                        > 
                            <form id="new_form" className='rs_form_container' encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <Dropdown
                                            id="entity_id"
                                            name="entity_id"
                                            reRenderRequired={true}
                                            label={ Helper.getLabel(language,'entity','Entity') }
                                            options={entity_list}
                                            apiUrl={Settings.apiUrl + '/get_entities'}
                                            currentSearchTerm={this.state.currentSearchParam}
                                            mapFunction={this.mapEntityValue}
                                            allDataRef={this.state.entityAllData} 
                                            pagination={true}  
                                            required={true}
                                            placeHolder={`${Helper.getLabel(
                                                language,
                                                    'please_select',
                                                    'Select entity',
                                                )}`
                                            }  
                                            onChange={this.onEntityChangeHandler.bind(this)}
                                        /> 
                                    </div>
                                    <div className='col-lg-4'>
                                        <DropDownInput placeHolder={`${Helper.getLabel( language, 'please_select', 'Select site' )}` } id="site_id" name="site_id" label={ Helper.getLabel(language,'site','Site') } options={siteList ? siteList : []} reRenderRequired={true} required = {true}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <Input name="code" label={ Helper.getLabel(language,'code','Code') } required = {true}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <Input name="name" label={ Helper.getLabel(language,'name','Name') } required = {true} />
                                    </div>
                                    <div className='col-lg-4'>
                                        <Checkbox name="is_active" isChecked={true} label={ Helper.getLabel(language,'active','Active') } />
                                    </div>
                                </div>
                            </form>
                        </Collapse>
                    </>
                    <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                        <Button
                            isActive={false}  
                            className='black-btn-style'
                            title={Helper.getLabel(language, "close", "Close")}
                            onClick={this.onPopupClose.bind(this)}
                        />

                        <Button 
                            className={ "solid_btn" }  
                            isActive={true} 
                            title={ Helper.getLabel(language, "create", "Create") }
                            onClick={e => this.onSaveHander(e)}
                        />
                    </div>
                </Popup>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
export default   connect (mapStateToProps) ( RsWithRouter(NewWarehouse));