import React, { Fragment, useCallback, useState } from 'react' 
import NavigationHeder from '../../Navigations/NavigationHeder' 
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation' 
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux' 
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import SetupBanksModal from './SetupBanksModal'

// Banks Creation SYSTEM - LYNKAZ --

let gridApi = null;

const Banks = (props) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate()
 
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delBanks_ID = element?.data?.bank_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiOrgUrl+`/bank/${delBanks_ID}`)
      const rowNode = gridApi?.getRowNode(delBanks_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'code') { 
      navigate(`/edit-bank/${event.data.bank_id}`)
    }
  }
 
  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.banks.bank)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  const gridColHeaders = [
    {field:'code', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'3007007','Code'), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
    {field:'name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'3008008','Name'), editable:false } 
  ];

  return ( 
    <div className='banks_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'3006006','Banks')}>
          <ButtonNew isDisable={!security.canCreate(frontendIds.setupForms.banks.bank_main_create_button)} onClick={() => setShowCreateModal(true)} title={Helper.getLabel(props.language,'000002','New')} />
        </NavigationHeder>
        
        <AgGridNew
          apiUrl={Settings.apiOrgUrl+'/bank'} 
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.banks.bank_main_delete_button)}
          pagination={false}
          columnDefs={gridColHeaders} 
          hideAddBtn={true}
          height={500}
          uniqueField={'bank_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api} 
          gridId={"rs_banks_grid"}
        /> 
      </div>
      {showCreateModal && <SetupBanksModal setShowCreateModal={setShowCreateModal} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.banks.bank
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(Banks, SecurityOptions))))