import React from 'react';
import Helper from '../../inc/Helper';
import POArchive from '../../components/Purchase Orders Internal/Archive PO/POArchive';
import Settings from '../../inc/Settings';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';

const ReleaseOrdersArchivePage = (props) => {
    return (
        <POArchive 
            title={Helper.getLabel(null, 'release_orders_all', 'Release Orders - All')}
            createFormTitle={Helper.getLabel(null, 'create_release_order', 'Create Release Order')}
            editUrl="/release-order/edit"
            type={"2"}
            backUrl={-1}
            security={props?.security}
            source_id={Settings.source_id.release_order}
        />
    );
};
 
const SecurityOptions = {
  gui_id: Gui_id_list.procure.release_orders.release_orders_main
};

export default (((ApplySecurityRoles(ReleaseOrdersArchivePage, SecurityOptions)))); 