import React, { useState } from 'react'
import LoginRegisterSidebar from './LoginRegisterSidebar'
import { Link } from 'react-router-dom'
import AuthCheck from '../../components/Auth/AuthCheck'
import Settings from '../../inc/Settings'
import Input from '../../components/Forms/Input'
import FormValidator from '../../inc/FormValidator'
import Helper from '../../inc/Helper'

const CompanyDomain = (props) => {
  const [domainName,setDomainName]=useState('')
  const [isFormSubmitted,setIsFormSubmitted]=useState(false)
  let logo = '/images/login-reg-screen-logo.png'

  let validator

  const validationConfigure = () => {
    const language = props.language;
    let fieldConfig = [
        {name:'company_domain',displayName:Helper.getLabel(language,'company_domain','Company Domain'),types:['Required'],max:80 },
    ]
    validator = new FormValidator(fieldConfig,language);
  }

  validationConfigure()

  const handleGetStartedBtn = () => {
    setIsFormSubmitted(true);

    // Validation
    const validData = {
      company_domain: domainName, // Update this according to your form fields
    };

    if (!validator.isValid(validData)) {
      validator.displayMessage();
      setIsFormSubmitted(false);
    }else{
      const prefixedDomain = domainName.startsWith('https://') || domainName.startsWith('https://')
      ? domainName
      : `https://${domainName}`;
    setTimeout(()=>{
        setIsFormSubmitted(false)
        window.location.href = window.location.hostname==='localhost'?`http://${domainName}.localhost:3000/login`:`${prefixedDomain}.lynkaz.com/login`;
    },200)
    }
  }

  const handleInputChange = (e) => {
     setDomainName(e.target.value)
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleGetStartedBtn();
    }
  };

  return (
    <div className='login_register_page'>
      <LoginRegisterSidebar />
      <div className='loginreg_contents'>
        <div className='login_regiser_box'>
          <AuthCheck noAccessForLogin={'/' + Settings.redirectUrlAfterLogin} />
          <Link to='/' className='login_logo'>
            <img src={logo} alt='Lynkaz' />
          </Link>
          <div className='lr_box_inner'>
            <div className='login_form_group'>
              <div className='lf_title_subtitle'>
                <h2>Sign in to your account</h2>
              </div>
              <Input label="Company Domain" placeholder="company domain" name="company_domain" value={domainName} onKeyDown={handleInputKeyDown} onChange={handleInputChange} placeHolder = "Ex: abc" />
              <button className='btn btn_block btn_active sb_btn' onClick={handleGetStartedBtn}>Get Started <img src="/images/icons/arrow-right-white.svg" alt="arrow login" /> {isFormSubmitted ? <output className="spinner-border spinner-border-sm" aria-hidden="true"></output> : ''} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyDomain
