import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import RsWithRouter from '../../inc/RsWithRouter';
import SimpleLoading from '../../Loading/SimpleLoading';
import Popup from '../../Popup/Popup';
import $ from 'jquery';
import FormValidator from '../../../inc/FormValidator';
import Collapse from '../../inc/Collapse';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
class NewForm extends Component {
    constructor(props){
        super(props);
        this.validationConfigure()
        this.state = {
            isLoading:false,
            entity:null,
            logoData:null
        }
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'code',displayName:Helper.getLabel(language,'code','Code'),types:['Required'],max:10 },
            {name:'name',displayName:Helper.getLabel(language,'name','Name'),max:100,types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    saveEntity(){
        let formData = new FormData(document.getElementById('new_form'));
        let newEntityData = {
            "code":formData.get('code'),
            "name":formData.get('name'),
            "logo": this.state.logoData,
            "translationsource_id":null,
            "addresssource_id":null,
            "contactsource_id":null
        }
        if(!this.validator.isValid({...newEntityData})){
            this.validator.displayMessage(this);
            return;
        }

        let api = Api;
        let that =  this;
        this.setState({
            isLoading:true
        })
        api.setUserToken();
        api.axios().post(Settings.apiUrl+'/add_entity',newEntityData).then(function(res){
            if(res.data.status ==='success'){
                that.setState({
                    entity: {
                        entity_id: res.data.entity_id,
                        ...newEntityData
                    },
                    isLoading: false
                })
                
                Helper.alert(res?.data?.message)
            }
        }).catch((res) => getPopupMessageBasedOnStatus(res))
    }
    logoChange(e,fileData){
        let that = this;
        that.setState({
            logoData: fileData?.fileData
        })
    }
    onPopupClose(){
        this.props.onPopupClose()
        $('html').removeClass('rs_popup_open');
    }
    render() {
        let language = this.props.language;
        let entity = this.state.entity;
        if(entity){
            this.onPopupClose();
            this.props.rs_router.navigate('/entity/edit-entity/'+entity?.entity_id)
        }
        return (
            <Fragment>
                { this.state.isLoading ? <OverlayLoader /> : null } 
                <Popup autoOpen={true} width="900px" onClose={ this.onPopupClose.bind(this)}>
                    <>
                        <h5 className='create_rfq_modal__title'>{Helper.getLabel(language,'create_entity','Create Entity')}</h5>
                        <Collapse
                            className="mt-4 mb-4" open={true}
                            title={Helper.getLabel(
                                language,
                                "general_info",
                                "General Information"
                            )}
                        > 
                            <form id="new_form" className='rs_form_container' encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <Input name="code"  hasError={this.validator.hasError('code')} label={Helper.getLabel(language,'code','Code')} placeholder={Helper.getLabel(language,'type_here','Type here')} required = {true}/>
                                    </div>
                                    <div className='col-lg-4'>
                                        <Input name="name"  hasError={this.validator.hasError('name')} label={Helper.getLabel(language,'name','Name')} placeholder={Helper.getLabel(language,'type_here','Type here')}  required = {true} />
                                    </div>
                                    <div className='col-lg-4'>
                                        <Input name="logo" label={Helper.getLabel(language,'logo','Logo')} placeholder={Helper.getLabel(language,'type_here','Type here')}  onChange= { this.logoChange.bind(this) }   inputType="file" />
                                    </div>
                                    <div className='entity-logo-wrapper'>
                                        {
                                            this.state.logoData ?  
                                                <div style={{
                                                    width: '100%',
                                                    height: '100px',
                                                    background: '#fff', 
                                                    boxSizing: 'border-box', 
                                                    position: 'relative',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center center',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundImage: `url(${this.state.logoData})`
                                                }}>
                                                </div> 
                                            : 
                                                null
                                        }
                                    </div>
                                </div>
                            </form>
                        </Collapse> 
                    </>
                    <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                        <Button
                            isActive={false}  
                            className='black-btn-style'
                            title={Helper.getLabel(language, "close", "Close")}
                            onClick={this.onPopupClose.bind(this)}
                        />

                        <Button 
                            className={ "solid_btn" }  
                            isActive={true} 
                            title={ Helper.getLabel(language, "create", "Create") }
                            onClick={this.saveEntity.bind(this)}
                        />
                    </div>
                </Popup>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
export default   connect(mapStateToProps) ( RsWithRouter(NewForm));