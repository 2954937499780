import React, { useState, useEffect, Fragment, useCallback } from 'react'
import NavigationHeder from '../../Navigations/NavigationHeder'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import { connect } from 'react-redux'; 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation' 
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'

/* Validations -> from unit & to unit cannot be lesser than zero n string */

let gridApi = null;
let AddBtn;
let transaction;

const Conversion = (props) => {   
  const [unitLists, setUnitLists] = useState([])
 
  let unitCalled = false
  
  useEffect(() => { get_unit_list() }, [])
   
  const get_unit_list = () => {
    if(unitCalled) return;

    let api = Api;
    unitCalled = true;
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/unit', {}).then(function(res){
      if(res.data.status==="success"){
        setUnitLists(res?.data?.data?.map((item) => {
          return {
            value: parseInt(item.unit_id),
            label: item.description,
            roundings: item.rounding,
            code: item.code
          }
        }))
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res)
    })
  }

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.UOM_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  const updateData = async (event) => {
    try {
      let payload = {
        fromunit: event?.data?.From_Unit,
        tounit: event?.data?.To_Unit,
        fromunit_id: event?.data?.From_Unit_id,
        tounit_id: event?.data?.To_Unit_id
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl+`/unit_detail/${event?.data?.UOM_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
 
  const addNewData = async (event) => {
    try {
      let payload = {
        from_unit_id: event?.data?.From_Unit_id,
        from_unit: event?.data?.From_Unit,
        to_unit_id: event?.data?.To_Unit_id,
        to_unit:event?.data?.To_Unit
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl+'/unitdetails', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }
   
  const onDeleteSystemConversion = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_conversion_id = element?.data?.UOM_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/unit_detail/${delete_conversion_id}`)
      const rowNode = gridApi?.getRowNode(delete_conversion_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.formModules.unit_conversion.unit_conversion_main)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  /* Grid Column Headers */

  const gridColHeader = [
    {
      field: 'From_Unit_id',
      headerName: Helper.getLabel(props.language, '2800101', 'From description'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: { values: unitLists },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, unitLists),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      },
    }, 
    {
      field: 'To_Unit_id',
      headerName: Helper.getLabel(props.language, '2800201', 'To description'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: { values: unitLists },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, unitLists),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
    { field:'From_Unit', editable: true, filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language, '2800301', 'From unit') },
    { field:'To_Unit', editable: true, filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language, '2800401', 'To unit') },
    { field:'factor',
      filter: false,
      sortable: false,
      valueGetter: (params) => {
        if(!params?.data?.To_Unit && !params?.data?.From_Unit){
          return ''
        }
        else {
          return params?.data?.To_Unit * params?.data?.From_Unit
        }
      }, headerName:Helper.getLabel(props.language, '2800501', 'Factor'), editable: false 
    }
  ];

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language, 'unit_conversion', 'Unit Conversion')} />

      <AgGridNew
        apiUrl={Settings.apiUrl+'/unitdetails'}
        pagination={false}
        columnDefs={gridColHeader}
        onRowValueChanged={onRowValueChanged}
        hideDeleteBtn={!security.canDelete(frontendIds.formModules.unit_conversion.unit_conversion_main_delete_button)}
        hideAddBtn={!security.canCreate(frontendIds.formModules.unit_conversion.unit_conversion_main_create_button)}
        handleDeleteSelectedRows={onDeleteSystemConversion}
        height={500}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        uniqueField={`UOM_id`}
        gridId={'unit-conversion-setup-forms'}
      /> 
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.formModules.unit_conversion.unit_conversion_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Conversion, SecurityOptions)))