import React, { Fragment, useCallback, useState} from 'react';   
import Settings from '../../../inc/Settings';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper'; 
import Alert from '../../inc/Alert';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import ButtonNew from '../../inc/ButtonNew';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat, statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import PaymentTermsModal from './PaymentTermsModal';

let gridApi = null;

const PaymentArchive = (props) => {
  const [paymentTermId, setPaymentTermId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const CellHandler = (event) => {   
    if(event.colDef.field === 'code') { 
      setPaymentTermId(event?.data?.paymentterm_id);
      setShowCreateModal(true);
    }
  };

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delPayment_ID = element?.data?.paymentterm_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/payment_term/${delPayment_ID}`)
      const rowNode = gridApi?.getRowNode(delPayment_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.refreshServerSide({ purge: true });
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const reloadGrid = () => {
    gridApi?.refreshServerSide({ purge: true });
    gridApi?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setPaymentTermId(null);
  };

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.payment_terms.payment_terms)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };

  const gridColHeaders = [
    {field:'code', filter: 'agTextColumnFilter', cellRenderer: (params) => gridLinkValueFormat(params), headerName:Helper.getLabel(props.language,'code','Code'), editable: false },
    {field:'name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'name','Name'), editable: false},
    {field:'days', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'days','Days'), editable: false},
    {field:'is_active', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, cellRenderer: (params) => statusLabelValueFormat(params), headerName:Helper.getLabel(props.language,'status','Status'), editable: false } 
  ];

  return ( 
    <div className='payment_terms_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title='Payment Terms'>
          <ButtonNew isDisable={!security.canCreate(frontendIds.setupForms.payment_terms.payment_terms_main_create_button)} onClick={onClickNewButton} title='New' />
        </NavigationHeder> 
  
        <AgGridNew
          apiUrl={Settings.apiVendorUrl+'/paymentterm'}
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.payment_terms.payment_terms_main_delete_button)}
          pagination={true}
          columnDefs={gridColHeaders}
          hideAddBtn={true}
          height={500}
          uniqueField={'paymentterm_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params?.api} 
          gridId={"rs_payment_terms_list"}
        /> 
      </div>
      { showCreateModal && <PaymentTermsModal reloadParentGrid={reloadGrid} payment_term_id={paymentTermId} setShowCreateModal={setShowCreateModal} /> }
    </div> 
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.payment_terms.payment_terms
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(PaymentArchive, SecurityOptions))))