import React, {useState} from 'react';
import './Toggle.css';

export const TitlePosition = {
  left: 1,
  right: 2
}

const ToggleSwitch = ({onChange, title, margin, titlePosition, value,isDisable}) => {
    const [checked, setChecked] = useState(false);

    const handleToggle = () => { 
        onChange(!checked) 
        setChecked(!checked)
    }
  return (
    <div className={`toggle-container ${margin}`}>
      {titlePosition === TitlePosition.left ? <span>{title}</span> : null}
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={value ?? checked}
          onChange={handleToggle}
          disabled={isDisable}
        />
        <span className="toggle-slider"></span>
      </label>
      {titlePosition === TitlePosition.right ? <span>{title}</span> : null}
    </div> 
  )
}

export default ToggleSwitch;