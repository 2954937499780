import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { useLocation } from 'react-router-dom'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Button from '../../inc/Button'
import Popup from '../../Popup/Popup'
import { Form } from 'react-bootstrap'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import AgGridMemo from '../../grid/ag/AgGridMemo'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

let defaultGridObj = null
let forceRender = 1

const ExtensionModal = ({ language, handleCloseModal, rfqHeader, vendors, updatePageUI, setUpdatePageUI }) => {
  const location = useLocation()

  const [date, setDate] = useState(new Date())
  const [reason, setReason] = useState('')
  const [actions, setActions] = useState([])
  const [publishedVendors, setPublishedVendors] = useState()
  const [selectedNodes,setSelectedNodes] = useState([])
  const [isLoading,setIsLoading]=useState(false)

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  useEffect(() => {
    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(`${Settings.loginUrl}/enum/RFvendorreply_action`)
      .then((res) => {
        setActions(res?.data.data)
        forceRender++
      })
      .catch((error) => {
        console.log('Error in getting actions: ', error)
      })
    getPublishedVendors()
  }, [])

  const getPublishedVendors = () => {
    const publishedVendorsData = vendors.filter((vendor) => vendor.vendorreply_id !== null)
    setPublishedVendors(publishedVendorsData)
    forceRender++
  }

  const onSubmit = () => {
    if (getSearchParam('rfq_view_id')) {
      const rows = defaultGridObj?.api?.getSelectedRows()

      for (let i = 0; i < rows.length; i++) {
        const action = actions.filter((el) => el.name === 'Extension')[0].id

        const payload = {
          rfq_id: parseInt(rfqHeader?.RFQ_id),
          vendorreply_id: rows[i].vendorreply_id,
          action: action,
          newexpirydatetime: DatepickerFunctions.convertDateTimeForDataBase(date===null?new Date():date),
          reason: reason,
        }

        const api = Api
        api.setUserToken()
        setIsLoading(true)
        api
          .axios()
          .post(`${Settings.apiPurchaseRequisitionUrl}/vendoraction_on_vendorreply`, payload)
          .then((res) => {
            setIsLoading(false)
            if (i === 0) Helper.alert(res.data?.message, 'success')
            if (i === 0) setUpdatePageUI(!updatePageUI)
            if(i===0){
              $('html').removeClass('rs_popup_open')
              handleCloseModal()
            }
          })
          .catch((error) => {
            if (i === 0) Helper.alert(error?.response?.data?.message, 'error')
              setIsLoading(false)
          })
      }
    }
  }

  const Columns_Headings = [
    {
      field: 'vendor_name',
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendors Name'),
      editable: false,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(language, 'vendor_site', 'Vendors Site'),
      editable: false,
    },
    {
      field: 'vendor_id',
      hide: true,
    },
    {
      field: 'vendorsite_id',
      hide: true,
    },
  ]

  const handleSelectionChanged = (event) => {
     setSelectedNodes(event?.api?.getSelectedRows())
  }

  return (
    <>
    <Popup
      autoOpen={true}
      width='900px'
      onClose={() => {
        $('html').removeClass('rs_popup_open')
        handleCloseModal()
      }}
    >
      <>
        <div className='d-flex justify-content-between align-items-start'>
          <div>
            <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'expiry_control', 'Expiry control')}</h5>
            <h6 className='inviteModalSubHeading'>{Helper.getLabel(language, 'enter_the_details_below', 'Enter the details below')}</h6>
          </div>
          <div className='addDocumentNumber-body-buttons' style={{ justifyContent: 'flex-end', gap: '16px' }}>
            <Button
              isActive={false}
              className='black-btn-style'
              title={Helper.getLabel(language, 'close', 'Close')}
              onClick={() => {
                $('html').removeClass('rs_popup_open')
                handleCloseModal()
              }}
            />
            <Button
              className='solid_btn publish_rfq_blue_btn'
              isActive={true}
              title={Helper.getLabel(language, 'submit', 'Submit')}
              onClick={() => {
                onSubmit()
              }}
              isDisable={selectedNodes?.length === 0}
            />
          </div>
        </div>

        <div title={Helper.getLabel(language, 'fill_up_the_form', 'Fill up the form')} open={true}>
          <Form>
            <div className='addVendorModalForm' style={{ marginBottom: '20px' }}>
              <NewInputDatePicker
                label={`${Helper.getLabel(language, 'select_expiry_date', 'Expiry date')}`}
                id='expiry_datetime'
                name='expiry_datetime'
                onChange={(date) => {
                  setDate(date)
                }}
                value={date}
                timePicker={true}
                required
                minDate={new Date()}
              />
            </div>

            <label htmlFor='inviteModal__message'>{Helper.getLabel(language, 'reason', 'Reason')}</label>

            <textarea name='inviteModal__message' id='inviteModal__message' rows='5' value={reason} className='mb-3' onChange={(e) => setReason(e.target.value)}></textarea>

            {getSearchParam('rfq_view_id') && publishedVendors && (
              <AgGridMemo forceRender={forceRender} id='retender_grid' header={Columns_Headings} data={publishedVendors} hideSave={true} allBtnHide={true} onGridReady={(event) => (defaultGridObj = event)} onSelectionChanged={handleSelectionChanged} />
            )}
          </Form>
        </div>
      </>
    </Popup>
    {isLoading ? <OverlayLoader /> : null}
    </>
  )
}

export default ExtensionModal
