const SimpleLoading = (props) => {
    const height = props.width ? props.width : '30px';
    const style = {
        height: height, 
        width: props.height ? props.height : height,
        margin:'0 auto',
        display:'block'
    };
    if(props.title){
        return(
            <div className="text-center">
                <span>{props.title}</span>
                <div className="spinner-border spinner-border-sm" style={style} role="status">
                        <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            
        ) 
    }
    return(
        <div className="spinner-border spinner-border-sm" style={style} role="status">
                <span className="visually-hidden">Loading...</span>
        </div>
    )
}
export default SimpleLoading;