import React, { Fragment, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import Api from '../../../../inc/Api'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import CommonApiCalls from '../../Purchase Order Screen/CommonApiCalls/CommonApiCalls'
import SimpleLoading from '../../../Loading/SimpleLoading'
import Collapse from '../../../inc/Collapse'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import PriceDiscountTab from '../../Purchase Order Screen/PurchaseOrderDetails/PriceDiscountTab'
import AddressList from '../../../Tenant/Address/AddressList'
import DeliveryTab from '../../../Purchase Orders Internal/Edit PO/DeliveryTab'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'

//for render tabs data
const PurchaseAgreementData = (props) => {
  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const innerTabs = [
    {
      title: Helper.getLabel(props.language, 'general_information', 'General Information'),
      isActive: true,
    },
    {
      title: Helper.getLabel(props.language, 'additional_information', 'Additional Information'),
    },
    {
      title: Helper.getLabel(props.language, 'price_and_discount', 'Price & Discount'),
    },
    {
      title: Helper.getLabel(props.language, 'delivery', 'Delivery'),
    },
    {
      title: Helper.getLabel(props.language, 'address', 'Address'),
    },
  ]

  let status = props.status?.toLowerCase()

  const generalData = [
    {
      id: 1,
      title: 'Document number',
      subtitle: props.purchaseAgreementData?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle: props.status,
      className: status === 'confirmed' ? `status-style success-style` : `status-style draft-style`,
    },
    {
      id: 3,
      title: 'Description',
      subtitle: props.purchaseAgreementData?.description,
    },
    {
      id: 4,
      title: 'Vendor code',
      subtitle: props.purchaseAgreementData?.vendor_code,
    },
    {
      id: 5,
      title: 'Vendor name',
      subtitle: props.purchaseAgreementData?.vendor_name,
    },
    {
      id: 6,
      title: 'Vendor site',
      subtitle: props.purchaseAgreementData?.vendorsite_name,
    },
    {
      id: 7,
      title: 'Classification',
      subtitle: props.purchaseAgreementData?.pa_classification_name,
    },
    {
      id: 8,
      title: 'Document date',
      subtitle: Helper.formateDate(props.purchaseAgreementData?.document_date),
    },
    {
      id: 9,
      title: 'Start date',
      subtitle: Helper.formateDate(props.purchaseAgreementData?.start_date),
    },
    {
      id: 10,
      title: 'End date',
      subtitle: Helper.formateDate(props.purchaseAgreementData?.expiry_date),
    },
  ]

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <div style={{ background: '#fff' }}>
          <NoteAttachmentHistory
            notesource_integrator={props.purchaseAgreementData?.agreement_id}
            attachmentsource_integrator={props.purchaseAgreementData?.agreement_id}
            source_id={Settings.source_id.purchase_agreement}
            hideHistory={true}
            condition={props?.purchaseAgreementData?.document_status===2}
          />
        </div>
      </MasterSidebar>
    )
  }

  return (
    <Tabs navs={defaultNav} showAll={true} scrollOffset='300' disableSticky={true} addComponentRight={addComponentRight}>
      <Fragment>
        <div className='bidding_tab mt-3'>
          <div className='mt-1 header-tab-rfq'>
            <Tabs navs={innerTabs} disableSticky={true} scrollOffset='300' showAll={false}>
              <div className='mt-3 ps-3 pe-3'>
                <div className='external-general-information'>
                  <div className='purchase-order-inner-details general-details-responsive'>
                    {generalData.map((item) => (
                      <div className='row justify-content-between' key={item.id}>
                        <div className='col-sm-6'>
                          <span className='purchase-order-external-title'>{item.title} :</span>
                        </div>
                        <div className='col-sm-6'>
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.purchaseAgreementData} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
              </div>
              <div className='ps-3 pe-3'>
                <Collapse className='mt-3' title={Helper.getLabel(props.language, 'price_and_discount', 'Price & Discount')} open={true}>
                  <PriceDiscountTab isEditable={false} state={props.purchaseAgreementData} setState={props.setPurchaseAgreementData} name='price_include_tax' paymentTermName='payment_term_id' />
                </Collapse>
              </div>
              <div className='bidding_commercial_proposal ps-3 pe-3 '>
                <Collapse className='mt-3 mb-3' title={Helper.getLabel(props.language, 'delivery', 'Delivery')} open={true}>
                  <DeliveryTab isEditable={false} setState={props.setPurchaseAgreementData} state={props.purchaseAgreementData} />
                </Collapse>
              </div>
              <div className='bidding_commercial_proposal ps-3 pe-3 '>
                <div className='mt-3 mb-3 address-grid'>
                  <AddressList
                    disableRecords={true}
                    canEdit={false}
                    address_source_integrator={props.purchaseAgreementData?.agreement_id}
                    addressSourc_id={props?.purchaseAgreementData?.addresssource_id}
                    source_id={props?.purchaseAgreementData?.source_id}
                  />
                </div>
              </div>
            </Tabs>
          </div>
        </div>
        <div className='mt-3 external-read-only-grid line-grid-btns-common'>
          <LineViewComponent
            defaultValues={{ requester_id: null }}
            source_id={Settings.source_id.purchase_agreement}
            line_source={Settings.source_id.purchase_agreement}
            isEditable={false}
            header_id={props.purchaseAgreementData?.agreement_id}
            formType={'purchase agreement'}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={true}
            hideGeneral={false}
            hideSetup={false}
            hideBillingRule={true}
            alternate_line={false}
            lineGridTitle={Helper.getLabel(props.language, 'lines', 'Lines')}
            hideDistributionInnerTabs={true}
            hideMoreBtns={true}
            hideAddBtn={true}
            hideDeleteBtn={true}
          />
        </div>
      </Fragment>
    </Tabs>
  )
}

const PurchaseAgreementDetails = (props) => {
  const contractOrderApiCalls = new CommonApiCalls()
  const location = useLocation()

  //state for contract order data
  const [purchaseAgreementData, setPurchaseAgreementData] = useState({})
  const [loading, setLoading] = useState(false)
  const [purchaseAgreementDocumentStatus, setPurchaseAgreementDocumentStatus] = useState([])

  const navigate = useNavigate()

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const agreement_id = getSearchParam('agreement_id')

  const get_purchase_agreement_details = async () => {
    let api = Api
    api.setUserToken()
    setLoading(true)  
    if (agreement_id === null || agreement_id === undefined) return 
    try {
      const res = await api.axios().get(Settings.apiPurchaseOrderUrl + `/PA_header/${agreement_id}`); 
      if (res.data.status === 'success') {
        setPurchaseAgreementData(res.data?.data);
      }
    }catch (err) {
      getPopupMessageBasedOnStatus(err);
    } finally {
      setLoading(false);
    }
  };
  

  //function to fetch status
  const getAsyncData = async () => {
    const document_statuses = await contractOrderApiCalls.getAllDocumentStatus()
    if (document_statuses);
    setPurchaseAgreementDocumentStatus(document_statuses)
  }

  useEffect(() => {
    get_purchase_agreement_details()
    getAsyncData()
  }, [])

  const onInvoiceCreate = () => {
    navigate(`/invoice-list?purchase_agreement_id=${agreement_id}`)
  }

  const document_status = purchaseAgreementDocumentStatus?.find((option) => option.id === parseInt(purchaseAgreementData?.document_status))

  return (
    <div className='request_for_quotation_detail_screen external-detail-screens external-theme responsive-screen-style'>
      <MasterComonent>
        <NavigationHeder
          title={`<span style="color:#757575">Purchase Agreements List > </span><span style="color:#313638;">${Helper.getLabel(props.language, 'Purchase Agreement Details', 'Purchase Agreement Details')} # </span> ${purchaseAgreementData?.document_number}`}
          hideMoreBtn={true}
        >
           <Button
            title={Helper.getLabel(props.language, 'close', 'Close')}
            onClick={() => {navigate(-1)}}
            className='black-btn-style'
          />
          <TotalWidget source_id={purchaseAgreementData?.source_id} integrator={purchaseAgreementData?.agreement_id}></TotalWidget>
          <Button title={Helper.getLabel(props.language, 'invoices', 'Invoices')} onClick={onInvoiceCreate} className='brown-btn-style'/>
        </NavigationHeder>

        <div className='tabs_default'>
          {loading ? (
            <SimpleLoading />
          ) : (
            <PurchaseAgreementData language={props.language} purchaseAgreementData={purchaseAgreementData} setPurchaseAgreementData={setPurchaseAgreementData} status={document_status?.name} />
          )}
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(PurchaseAgreementDetails)
