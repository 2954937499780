import React, { useEffect, useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import AgGrid from '../grid/ag/ag-grid'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomHtmlCell from '../grid/ag/cellRender/CustomHtmlCell'

const QuestionGroup = (props) => {
  const [questionGroupData, setQuestionGroupData] = useState([])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('questionnaire_id')
  const navigate = useNavigate()

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link">${params.value ? params.value : "-"}</div>`;
  };

  const getQuestionnaireGroupData = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/getquestiongroupsbyid/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionGroupData(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onQuestionnaireGroupDelete = (allRows) => {
    let api = Api
    api.setUserToken()
    allRows.forEach((element) => {
      api
        .axios()
        .delete(Settings.apiUrl + `/questiongroup/${element.questiongroup_id}`)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            getQuestionnaireGroupData()
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res)
        })
    })
  }

  const onRowValueChanged = (event) => {
    let payload = {
      description: event?.data?.description,
      weight: event?.data?.weight,
    }
    let addPayload = {
      ...payload,
      questionnaire_id:id,
    }
    if (event?.data?.questiongroup_id) {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .put(Settings.apiUrl + `/questiongroup/${event?.data?.questiongroup_id}`, payload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    } else {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .post(Settings.apiUrl + '/questiongroup', addPayload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            getQuestionnaireGroupData()
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    }
  }

  const onCellClicked = (event) => {
    if(event?.colDef.field==='description'){
       navigate(`/question-details?questiongroup_id=${event?.data?.questiongroup_id}`)
    }
  }

  useEffect(() => {
    getQuestionnaireGroupData()
  }, [])

  const Columns_Headings = [
    {
      field: 'description',
      editable:true,
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      valueFormatter:gridLinkValueFormat,
      cellRenderer:CustomHtmlCell
    },
    {
      field: 'weight',
      editable:true,
      headerName: Helper.getLabel(props.language, 'weight', 'Weight'),
    },
  ]

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'question_group', 'Questions group')}`} hideMoreBtn={true}></NavigationHeder>
        <div>
          <div>
            <AgGrid id='rfq-questionnaire-group' header={Columns_Headings} data={questionGroupData} onRowValueChanged={onRowValueChanged} hideSave={true} viewOnly={true} onDelete={onQuestionnaireGroupDelete} onCellClicked={onCellClicked} />
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

export default QuestionGroup
