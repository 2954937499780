import React, { Fragment, useCallback } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';  
import Alert from '../../inc/Alert'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';

let gridApi = null;
const Size = (props) => {
  let AddBtn;
  let transaction; 

  const updateSizes = async (event) => {
    try {
      let payload = {
        name: event?.data?.name
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiItemUrl+`/itemsize/${event?.data?.itemsize_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
 
  const addNewSizes = async (event) => {
    try {
      let payload = {
        name: event?.data?.name
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiItemUrl+'/item_size', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delSize_ID = element?.data?.itemsize_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/itemsize/${delSize_ID}`)
      const rowNode = gridApi?.getRowNode(delSize_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  const sizeValidator = (params) => {
    if( params.newValue.length > 255 ){
      Helper.alert('Name limit exceeded!', 'error') 
      return false
    }
    params.data.name = params.newValue
    return true
  }

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.itemsize_id) {
      updateSizes(event)
    } else {
      addNewSizes(event)
    }
  }

  let Columns_Headings = [ 
    {field:'name', editable: true, valueSetter: sizeValidator, headerName: Helper.getLabel(props.language, 'name', 'Name') } 
  ]

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView( Gui_id_list.masterData.item_master_modules.size.size_main)){
    return <Fragment> 
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title='Size' /> 
      <div className='rs_size_grid'>
        <AgGridNew
          apiUrl={Settings.apiItemUrl+'/item_size'}
          pagination={false}
          columnDefs={Columns_Headings}
          onRowValueChanged={onRowValueChanged}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.item_master_modules.size.size_main_delete_button)}
          hideAddBtn={!security.canCreate(frontendIds.masterData.item_master_modules.size.size_main_create_button)}
          handleDeleteSelectedRows={DeleteHandler}
          height={500}
          onGridReady={(params) => gridApi = params?.api}
          handleAddButton={handleBtn}
          uniqueField={`itemsize_id`}
          gridId={'sizes-master-data'}
        />
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.masterData.item_master_modules.size.size_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Size, SecurityOptions)))