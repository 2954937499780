import notifyjs from "notifyjs-browser";
import $ from "jquery";
import Settings from "./Settings";
import Api from "./Api";
import store from "../store";
import Cookies from "js-cookie";
import Sticky from "./Sticky";
import moment from "moment/moment";
import axios from "axios";
import CryptoJS from 'crypto-js';
//require('./Sticky.js');
let Helper = {
  refreshAppScript() {
    //$(window).trigger('resize');
    //window.dispatchEvent(new Event('resize'));
  },
  getNullableValue(value, isAcceptZero = false) {
    if (isAcceptZero && (value == "0" || value === 0)) {
      return value;
    }
    if (!value) {
      return null;
    }
    if (value.length <= 0) {
      return null;
    }
    if (value.length == "") {
      return null;
    }
    return value;
  },
  makeSticky(selector, options = {}) {
    options = {
      ...options,
      contained: true,
      class_name: "sticky-active",
    };
    //let sticky = $(selector).sticky({topSpacing:0});
    //let sticky = new $.Zebra_Pin($(selector), options);
    let sticky = {
      update: () => {},
    };
    let customSticky = new Sticky(selector, options);
    return customSticky;
  },
  getClassFromString(str) {
    if (!str) {
      str = "";
    }
    let output = str.replace(" ", "_").toLowerCase();
    return output;
  },
  scroll2id(id, offset = 0) {
    let scrollToId = document.getElementById(id);
    Helper.refreshAppScript();
    window.scrollTo(0, scrollToId?.offsetTop - offset,{behavior:"smooth"});
  },
  isRtl() {
    var language = document
      .getElementsByTagName("html")[0]
      .getAttribute("lang");
    return language === "ar";
  },
  getLabel(LanguageProps, frontend_Id, defaultValue = null, postFix = null) {
    if (!LanguageProps) {
      return defaultValue;
    }
    let output = defaultValue;
    if (
      LanguageProps &&
      LanguageProps.allLabels &&
      Array.isArray(LanguageProps.allLabels)
    ) {
      LanguageProps.allLabels.forEach((label) => {
        if (label.frontend_Id === frontend_Id) {
          if (label.TranslatedText) {
            output = label.TranslatedText;
          } else {
            output = label.originalText;
          }
        }
      });
    }
    return output;
  },
  setCookie(cname, cvalue, exdays) {
    Cookies.set(cname, cvalue, { expires: exdays, path: "/" });
    /*
        var d = new Date();
        let expireTime = (exdays*24*60*60);
        d.setTime(expireTime);
        var expires = "; expires="+ d.toUTCString();
        var maxAge = "; max-age="+expireTime;
        document.cookie = cname + "=" + cvalue + expires + maxAge+"; path=/; SameSite=None";
        */

    //document.cookie = cname + "=" + cvalue + expires + maxAge+"; path=/; SameSite=None; Secure";
  },
  formatDateForWorkflow(date_string) {
    const commaIndex = date_string.indexOf(",");

    let extracted_date = date_string;
    if (commaIndex !== -1) {
      extracted_date = date_string.substring(0, commaIndex);
      extracted_date = extracted_date.trim();
    }

    return extracted_date;
  },
  getCookie(cname) {
    return Cookies.get(cname);
    /*
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split('; ');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
        */
  },
  removeCookie(cname) {
    Cookies.remove(cname, { path: "/" });
    //document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //document.cookie = cname+ "=; maxAge=0; expires=" + new Date(0).toUTCString();
  },
  /**
   *
   * @param {string} message
   * @param {string} 'success, info, warn, error'
   */
  alert(message, messageType = "success") {
    if (!Helper.getNullableValue(message)) {
      return;
    }
    $.notify(message, {
      className: messageType,
      globalPosition: "top center",
      autoHideDelay:5000
    });
  },
  onEditClickAlert(message, messageType = "success") {
    if (!Helper.getNullableValue(message)) {
      return;
    }
    $.notify(message, {
      className: 'edit_message_class',
      globalPosition: "top center",
    });
  },
  confirmMe(
    confirmFunction,
    bodyMessage,
    cancelFunction = null,
    title = Helper.getLabel(null, "do_delete", "Do you want to delete?"),
    minWidth
  ) {
    $("<div>" + bodyMessage + "</div>").dialog({
      modal: true,
      zIndex: 30005,
      title: title,
      minWidth:minWidth,
      buttons: {
        Yes: function () {
          confirmFunction();
          $(this).dialog("close");
        },
        Cancel: function () {
          if (cancelFunction) {
            cancelFunction();
          }
          $(this).dialog("close");
        },
      },
    });
  },
  getTodayDateForDb(withTime = false) {
    let dateObject = new Date();
    if (withTime) {
      return `${dateObject.getFullYear()}-${
        dateObject.getMonth() + 1
      }-${dateObject.getDate()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()} `;
    } else {
      return `${dateObject.getFullYear()}-${
        dateObject.getMonth() + 1
      }-${dateObject.getDate()}`;
    }
  },
  /*
    getDateForDb(dateString,isMMddYYYY = false , seperatorNeed = '-'){
        if(dateString =='' || !dateString){
            console.log("getDateForDb received empty");
            return null;
        }
        if(dateString.includes("_")){
            return null;
        }
        let dateSeperator = dateString.includes('-') ? '-' : '/';
        var dateStArray =dateString.split(dateSeperator);
        let userFormateOfDate = store.getState().dateFormate.formate;
        let dateObject = new Date(); 
        if(userFormateOfDate == 'dd-mm-yyyy' || userFormateOfDate ==  'dd/mm/yyyy'){
            dateObject = new Date(dateStArray[2]+'-'+dateStArray[1]+'-'+dateStArray[0]); 
        }else if(userFormateOfDate == 'yyyy-mm-dd' || userFormateOfDate == 'yyyy/mm/dd'){
            dateObject = new Date(dateStArray[0]+'-'+dateStArray[1]+'-'+dateStArray[1]); 
        }else if(userFormateOfDate == 'mm-dd-yyyy' || userFormateOfDate == 'mm/dd/yyyy'){
            dateObject = new Date(dateStArray[2]+'-'+dateStArray[0]+'-'+dateStArray[1]); 
        }
        if(!dateObject.getFullYear()){
            console.log("getDateForDb can't create date object ",dateString);
            dateObject = new Date(dateString);
            return null;
        }

        let year = dateObject.getFullYear();
        let month = dateObject.getMonth();
        let monthOfDate = dateObject.getDate();
        if(isMMddYYYY){
            return `${ ( month + 1 ) }${seperatorNeed}${ ( monthOfDate ) }${seperatorNeed}${year}`;
        }
        return `${ year }${seperatorNeed}${ ( month + 1 ) }${seperatorNeed}${monthOfDate}`;
    },
    */
  getDateForDb(dateString, isMMddYYYY = false, seperatorNeed = "-") {
    if (dateString == "" || !dateString) {
      return null;
    }
    if (dateString.includes("_")) {
      return null;
    }
    let userFormateOfDate = store.getState().dateFormate.formate.toUpperCase();
    let momentObect = moment();
    if (Helper.isDateFormated(dateString)) {
      momentObect = moment(dateString, userFormateOfDate);
    } else {
      momentObect = moment(dateString);
    }
    if (isMMddYYYY) {
      return momentObect.format(`MM${seperatorNeed}DD${seperatorNeed}YYYY`);
    }
    return momentObect.format(`YYYY${seperatorNeed}MM${seperatorNeed}DD`);
  },

  getDateForDbWithTime(date, isMMddYYYY = false, seperatorNeed = "-") {
    const parsedDate = new Date(date);

    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");

    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
  },

  getDatePickerFormate(isTimeNeed = false) {
    let userFormateOfDate = store.getState().dateFormate.formate.toLocaleLowerCase();
    let dateSeperator = userFormateOfDate.includes("-") ? "-" : "/";
    if (
      userFormateOfDate == "dd-mm-yyyy" ||
      userFormateOfDate == "dd/mm/yyyy"
    ) {
      return `d${dateSeperator}m${dateSeperator}Y`+ ( isTimeNeed ? Helper.getTimeFormat() : '');
    } else if (
      userFormateOfDate == "yyyy-mm-dd" ||
      userFormateOfDate == "yyyy/mm/dd"
    ) {
      return `Y${dateSeperator}m${dateSeperator}d`+ ( isTimeNeed ? Helper.getTimeFormat() : '');
    } else if (
      userFormateOfDate == "mm-dd-yyyy" ||
      userFormateOfDate == "mm/dd/yyyy"
    ) {
      return `m${dateSeperator}d${dateSeperator}Y`+ ( isTimeNeed ? Helper.getTimeFormat() : '');
    }
    return `d${dateSeperator}m${dateSeperator}Y`+ ( isTimeNeed ? Helper.getTimeFormat() : '');
  },
  /*
    convertDateFromDb(DbDateString){
        let dateObj = new Date(DbDateString);
        return Helper.formateDate(dateObj.getFullYear()+'-'+( dateObj.getMonth() + 1)+'-'+dateObj.getDate());
    },
    */
  convertDateFromDb(DbDateString, isReturnObj = false) {
    let userFormateOfDate = store.getState().dateFormate.formate.toUpperCase();
    let momentObect = moment(DbDateString);
    if (isReturnObj) {
      return momentObect;
    }
    return momentObect.format(userFormateOfDate);
  },
  isFrontendFormatedDate(string) {
    if (Helper.isDateFormated(string)) {
      return true;
    }
    return false;
  },
  createDebouncedAPIFunction(funcArray, setDisabledBtnState = () => {}, cancelTokenSource, isClassComp=false, delayTime) { 
    
    /*
      This function takes an array of functions, loops through the functions, 
      gets the repsonse, and it allocates the total time of 10 seconds to the api functions, 
      if responsed in that time, if not then it cancels the api request.

      funcArray: ARRAY of Functions with API Calls - Try/Catch Method Mandatory,
      setDisabledBtnState: Set State Function for toggling Save button state for disabling,
      cancelTokenSource: object formed from axios packages, it works for cancelling API requests after API calls exceeds the 10 seconds time.
    */

    const debounce = (delay) => {
      let timeoutId;
      let resolvePromise;

      return (...args) => {
        clearTimeout(timeoutId);

        return new Promise((resolve, reject) => {
          resolvePromise = resolve;

          timeoutId = setTimeout(() => {
            cancelAPIRequest();
            reject(new Error('API Call function cancelled due to 10 seconds timed out!'));
          }, delay);

          const promises = funcArray.map((func) => func(...args));

          Promise.race([Promise.all(promises), timeoutPromise(delay)])
            .then((results) => {
              clearTimeout(timeoutId);
              resolvePromise(results);
            })
            .catch((error) => {
              clearTimeout(timeoutId);
              reject(error);
            })
            .finally(() => { 
              if(isClassComp) {
                setDisabledBtnState({
                  saveBtnApi: false
                })
              }else {
                setDisabledBtnState(false)
              } 
            });
        });
      };
    };

    const timeoutPromise = (delay) => { 
      return new Promise((_, reject) => setTimeout(() => reject(new Error('Function promise timed out!')), delay));
    };

    const cancelAPIRequest = () => {
      if (cancelTokenSource && cancelTokenSource.cancel) {
        cancelTokenSource.cancel('Function request cancelled due to time exceeded!');
        resetCancelToken();
      }
    };
  
    const resetCancelToken = () => {
      if (cancelTokenSource) {
        cancelTokenSource = axios.CancelToken.source();
        if (cancelTokenSource.token) {
          cancelTokenSource.token.reason = null;
        }
      }
    };

    return async (...args) => {
      try {
        if(isClassComp) {
          setDisabledBtnState({
            saveBtnApi: true
          })
        }else {
          setDisabledBtnState(true)
        } 
        const debouncedFunctions = funcArray.map((func) => debounce(delayTime?delayTime:15000)(func, ...args));
        const results = await Promise.all(debouncedFunctions); 
        return results;
      } catch (error) {
        console.log('Function call error:', error);
      } finally {
        if(isClassComp) {
          setDisabledBtnState({
            saveBtnApi: false
          })
        }else {
          setDisabledBtnState(false)
        } 
      }
    };
  },
  getTimeFormat(){
    return ' h:mm:ss';
  },
  /**
   *
   * @param {String} dateString
   * @param {String} formate
   * @returns {String}
   */
  formateDate(dateString, options = {}) {
    if (dateString == "" || dateString == null) {
      return dateString;
    }
    let userFormateStrOfDate = store
      .getState()
      .dateFormate.formate.toUpperCase();
    if(options.time){
      userFormateStrOfDate += ' '+Helper.getTimeFormat()
    }
    let momentObect = moment();
    if (Helper.isDateFormated(dateString)) {
      momentObect = moment(dateString, userFormateStrOfDate);
    } else {
      momentObect = moment(dateString);
    }
    if (options.returnObj) {
      return momentObect;
    }
    return momentObect.format(userFormateStrOfDate);
    /*

        const dateObject = new Date(dateString);
        if(!dateObject.getDate()){
            return dateString;
        }
        let userFormateOfDate = dateOptions.dateFormat ?? store.getState().dateFormate.formate;
        let dateSeperator = userFormateOfDate.includes('-') ? '-' : dateOptions.seperator;
        let day =  `${dateObject.getDate()}`.padStart(2,"0");
        let month = `${dateObject.getMonth() +1}`.padStart(2,"0");
        let year =  `${dateObject.getFullYear()}`.padStart(4,"0");
        let finalDate = `${day + dateSeperator + month + dateSeperator + year}`;
        if(userFormateOfDate == 'dd-mm-yyyy' || userFormateOfDate ==  'dd/mm/yyyy'){
            finalDate = `${day + dateSeperator + month + dateSeperator + year}`;
        }else if(userFormateOfDate == 'yyyy-mm-dd' || userFormateOfDate == 'yyyy/mm/dd'){
            finalDate = `${year + dateSeperator + month + dateSeperator + day}`;
        }else if(userFormateOfDate == 'mm-dd-yyyy' || userFormateOfDate == 'mm/dd/yyyy'){
            finalDate = `${month + dateSeperator + day + dateSeperator +  year}`;
        }
        return finalDate;
        */
  },
  isDateFormated(dateStr) {
    const formatsReg = [
      { format: "yyyy/mm/dd", regex: /^\d{4}\/\d{2}\/\d{2}$/ },
      { format: "yyyy-mm-dd", regex: /^\d{4}-\d{2}-\d{2}$/ },
      { format: "mm-dd-yyyy", regex: /^\d{2}-\d{2}-\d{4}$/ },
      { format: "mm/dd/yyyy", regex: /^\d{2}\/\d{2}\/\d{4}$/ },
      { format: "dd/mm/yyyy", regex: /^\d{2}\/\d{2}\/\d{4}$/ },
      { format: "dd-mm-yyyy", regex: /^\d{2}-\d{2}-\d{4}$/ },
    ];
    let isFormated = false;
    for (const { format, regex } of formatsReg) {
      if (regex.test(dateStr)) {
        isFormated = true;
      }
    }
    return isFormated;
  },
  isValidDate(dateString) {
    const [day, month, year] = dateString.split("/");
    const isoDateString = `${year}-${month}-${day}`;
    const date = new Date(isoDateString);
    return date instanceof Date && !isNaN(date);
  },
  getCurrentDate() {
    const currentDate = new Date();
    const year = `${currentDate.getFullYear()}`.padStart(4, "0");
    const month = `${currentDate.getMonth() + 1}`.padStart(2, "0");
    const day = `${currentDate.getDate()}`.padStart(2, "0");
    return day + "/" + month + "/" + year;
  },

  /**
   *
   * @param {Object} dataObjcet
   */
  gridDateView(dataObjcet) {
    let output = dataObjcet.value;
    return Helper.formateDate(output);
  },
  getCountryFromName(allCountry, CountryName) {
    let country = {};
    allCountry.forEach((item) => {
      if (item.country_short_name === CountryName) {
        country = item;
      }
    });
    return country;
  },
  getStateFromName(allState, StateName) {
    let state = {};
    allState.forEach((item) => {
      if (item.name === StateName) {
        state = item;
      }
    });
    return state;
  },
  getCityFromName(allCity, CityName) {
    let city = {
      city_id: "",
    };
    allCity.forEach((item) => {
      if (item.city_name === CityName) {
        city = item;
      }
    });
    return city;
  },
  getRandom(max = 1000) {
    return Math.floor(Math.random() * max) + 1;
  },
  /* this format for address grid */
  getContactsFromGrid(gridObj) {
    let gridData = Helper.getDataFromGrid(gridObj);
    let contactsList = gridData.map((contactItem) => {
      contactItem.isprimary = contactItem.is_primary == "yes" ? true : false;
      contactItem.isactive = contactItem.is_active =
        contactItem.is_active == "yes" ? true : false;
      contactItem.mobile = contactItem.mobile == "yes" ? true : false;
      contactItem.is_primary = contactItem.isprimary;
      contactItem.is_active = contactItem.isactive;
      return contactItem;
    });
    return contactsList;
  },
  /* this format for contact grid */
  getAddressFromGrid(gridObj) {
    let gridData = Helper.getDataFromGrid(gridObj);
    let addressData = gridData.map((addressItem) => {
      addressItem.isprimary = addressItem.is_primary == "yes" ? true : false;
      addressItem.isprivate = addressItem.is_private == "yes" ? true : false;
      addressItem.is_primary = addressItem.isprimary;
      addressItem.is_private = addressItem.isprivate;
      addressItem.contacts = addressItem.contacts.map((contactItem) => {
        contactItem.isprimary = contactItem.is_primary =
          contactItem.is_primary == "yes" ? true : false;
        contactItem.isactive = contactItem.is_active =
          contactItem.is_active == "yes" ? true : false;
        contactItem.mobile = contactItem.mobile === "yes" ? true : false;
        return contactItem;
      });
      addressItem.contact = {
        contacts: addressItem.contacts,
      };
      return addressItem;
    });
    return addressData;
  },
  getDataFromGrid(gridObj) {
    let gridData = [];
    gridObj.api.forEachNode((gridItem) => {
      gridData.push(gridItem.data);
    });
    return gridData;
  }, 
  createNoteIfExist(note, sourceId, integrator, afterCreate) {
    /*
      note: is the inputField value of the notes textarea,
      sourceId: the document source_id, for respective documents, check /sources - GET api,
      integrator: is the unique document id (which received when a document gets created), 
    */

    if (note.length <= 0) {
      afterCreate({
        type: "success",
      });
      return;
    }

    let payloadData = {
      note: note,
      note_type: 0,
      notesource_integrator: integrator,
      source_id: sourceId, 
    };
  
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .post(Settings.apiUrl + "/note", payloadData)
      .then((res) => {
        afterCreate({
          type: "success",
          data: res.data,
        });
      })
      .catch((error) => {
        afterCreate({
          type: "fail",
          data: error,
        });
      });
  },
  isNumeric(value) {
    return true;
  },
  getOnlyInt(value, nullIfZero = false, emtyStrIfZero = false) {
    let out = parseInt(value);
    if (!out) {
      if (nullIfZero) {
        return null;
      }
      if (emtyStrIfZero) {
        return "";
      }
      return 0;
    }
    if (nullIfZero && out === 0) {
      return null;
    }
    return out;
  },
  getOnlyNumber(value, digitAfterDot = null) {
    let output = parseFloat(value);
    if (!output) {
      return 0;
    }
    if (digitAfterDot) {
      return output.toFixed(
        digitAfterDot === true ? Settings.defaultZeroAfterDot : digitAfterDot
      );
    }
    return output;
  },
  getPositiveNumber(value) {
    let output = Helper.getOnlyNumber(value);
    if (output < 0) {
      return 0;
    }
    return output;
  },
  getCurrencyForDb(amount) {
    if (!amount) {
      return 0;
    }
    let currency = amount;
    let output = Number(currency.replace(/[^0-9.-]+/g, ""));
    if (!output) {
      return 0;
    }
    return output;
  },
  formateCurrency(amount) {
    if (!amount) {
      return 0;
    }
    let currency = amount;
    let output = Number(currency);
    if (!output) {
      return 0;
    }
    let finalOutput = output; //parseFloat(output.toFixed(2))
    const stringOptions = {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    return finalOutput.toLocaleString("en-US", stringOptions);
  },
  addThousandSeparator(number) {
    if (!number) return number;
    let numString = number.toString();
    let [integerPart, decimalPart] = numString.split(".");
    let integerArray = integerPart.split("");
    let integerLength = integerArray.length;
    let integerSeparators = Math.floor((integerLength - 1) / 3);
    for (let i = 1; i <= integerSeparators; i++) {
      let separatorIndex = integerLength - 3 * i;
      integerArray.splice(separatorIndex, 0, ",");
    }
    if (decimalPart) {
      return integerArray.join("") + "." + decimalPart;
    } else {
      return integerArray.join("");
    }
  },
  getLocalTimeFromTimestamp(timestamp) {
    const userTime = new Date(timestamp).toLocaleString([], {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return userTime;
  },

  calculateHeight(myDivRef,setHeight,window){
    if (myDivRef?.current) {
      const newHeight = window.innerHeight - myDivRef.current.clientHeight - 80.8;
      setHeight(newHeight);
    }
  },
  resizeWindowHandle(myDivRef,setHeight,window){
    Helper.calculateHeight(myDivRef,setHeight,window)
    const handleResize = () => {
      Helper.calculateHeight(myDivRef,setHeight,window);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },
  encryptPassword(password){
    const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;    
    const derived_key = CryptoJS.enc.Base64.parse(secretKey);    
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);  
    const encryptionOptions = {
        iv: iv,
        mode: CryptoJS.mode.CBC
    };  
    const encryptedPassword = CryptoJS.AES.encrypt(password, derived_key, encryptionOptions).toString();    
    return encryptedPassword;
  }
};
export default Helper;
