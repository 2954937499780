import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import SimpleLoading from "../../Loading/SimpleLoading";

class OrderSummary extends Component {

  render() {
    const {
      title,
      itemCount,
      subtotal,
      discount,
      tax,
      buttonText,
      onButtonClick,
      isLoading,
      total
    } = this.props;

    return (
      <div className="widget_order_summary">
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-title">{title}</span>
          {itemCount ? (
            <span className="widget_order_summary-row-label">
              {itemCount} {itemCount > 1 ? "items" : "item"}
            </span>
          ) : null}
        </div>
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-label">
            {Helper.getLabel(this.props.language, "subtotal", "Subtotal")}
          </span>
          <span className="widget_order_summary-row-value">
            {subtotal
              ? Helper.addThousandSeparator(parseFloat(subtotal).toFixed(2))
              : "0.00"}
          </span>
        </div>
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-label">
            {Helper.getLabel(this.props.language, "discount", "Discount")}
          </span>
          <span className="widget_order_summary-row-value">
            {discount
              ? Helper.addThousandSeparator(parseFloat(discount).toFixed(2))
              : "0.00"}
          </span>
        </div>
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-label">Tax</span>
          <span className="widget_order_summary-row-value">
            {tax
              ? Helper.addThousandSeparator(parseFloat(tax).toFixed(2))
              : "0.00"}
          </span>
        </div>
        <hr />
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-total">Grand Total</span>
          <span
            className="widget_order_summary-row-value"
            style={{ colo: "white" }}
          >
            {Helper.addThousandSeparator(total?.toFixed(2))}
          </span>
        </div>
        <hr />
        {buttonText ? (
          <button
            className={itemCount === 0 ? "widget_order_summary-disabled" : ""}
            disabled={itemCount === 0 || isLoading}
            onClick={onButtonClick}
          >
            {isLoading ? <SimpleLoading /> : buttonText}
          </button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(OrderSummary);
