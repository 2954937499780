import React, { useState, useEffect } from 'react';
import Helper from '../../../inc/Helper';
import Collapse from '../../inc/Collapse';
import FormValidator from '../../../inc/FormValidator';
import Input from '../../Forms/Input';
import Settings from '../../../inc/Settings';
import LineViewComponent from '../Edit PO/ListView/LineViewComponent';
import Button from '../../inc/Button';
import AmendDatesButtonPopup from './AmendDatesButtonPopup';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import NavigationHeder from '../../Navigations/NavigationHeder';
import PoTopBlueShortDetails from '../Widget/PoTopBlueShortDetails';
import Api from '../../../inc/Api';
import SimpleLoading from '../../Loading/SimpleLoading';
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useLocation, useParams } from 'react-router-dom';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';

const AmendmentRequest = (props) => {
  const [amendmentLoading, setAmendmentLoading] = useState(false);
  const [amendment, setAmendment] = useState(null);
  const [activeSelectedLine, setActiveSelectedLine] = useState(null);
  const [updateingAmendment, setUpdateingAmendment] = useState(false);
  const [po, setPo] = useState(null);
  const [amendmentDurations, setAmendmentDurations] = useState([]);
  const [isLoadedAmendment, setIsLoadedAmendment] = useState(false);
  let isAmentEvnetRegistered = false;

  const {id} = useParams()
  const location = useLocation()

  const validationConfigure = () => {
    const language = null;
    let fieldConfig = [
      { name: 'start_date', displayName: Helper.getLabel(language, 'start_date', 'Start date required'), types: ['Required'] },
      { name: 'end_date', displayName: Helper.getLabel(language, 'end_date', 'End date required'), types: ['Required'] },
    ];
    return new FormValidator(fieldConfig, language);
  };

  validationConfigure()



  const loadAmendmentDurations = () => {
    if (!id) {
      return;
    }
    let api = Api
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + '/amendmentdurations/' + id)
      .then((res) => {
        setAmendmentDurations(res.data.data);
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error);
      });
  };

  const getAmendmentDuration = (amendmentline_type, integrator) => {
    let output = null;
    amendmentDurations.forEach((duration) => {
      if (duration.amendmentline_type == amendmentline_type && duration.integrator_id == integrator) {
        output = duration;
      }
    });
    return output;
  };

  const loadAmendment = () => {
    if (isLoadedAmendment || !id) {
      return;
    }
    let api = Api
    api.setUserToken();
    setAmendmentLoading(true);
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + '/amendment/' + id)
      .then((res) => {
        setAmendmentLoading(false);
        setAmendment(res.data.data);
        if (res.data.status === 'success') {
          loadPurchasesOrder(res.data.data.purchaseorder_id);
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error);
        window.location.href = '/';
      });
  };

  const loadPurchasesOrder = (poId) => {
    if (!poId) {
      return;
    }
    let api = Api;
    api.setUserToken();
    setAmendmentLoading(true);
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/PO_header/${poId}`)
      .then((res) => {
        setAmendmentLoading(false);
        setPo(res.data.data);
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error);
        setPo(null);
        setAmendmentLoading(false);
      });
  };

  const onAmendmentChangeHandler = (event) => {
    let amendMentData = amendment;
    setAmendment({
      ...amendMentData,
      [event.target.name]: event.target.value,
    });
  };

  const afterCreetOrUpdateAmendmentDuration = (event) => {
    loadAmendmentDurations();
  };

  const amendmentSaveHandler = (event) => {
    let amendMentData = amendment;
    let newData = {
      amendment_description: amendMentData.description,
      amendment_status: null,
    };
    let api = Api
    api.setUserToken();
    setUpdateingAmendment(true);
    api
      .axios()
      .put(Settings.apiPurchaseOrderUrl + '/amendment/' + amendMentData.amendment_id, newData)
      .then((res) => {
        Helper.alert(res.data.message);
        setUpdateingAmendment(false);
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error);
        setUpdateingAmendment(false);
      });
  };

  const onPostingHandler = () => {
    let amendMentData = amendment;
    let api = Api;
    setAmendmentLoading(true);
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + '/amendmentsubmit/' + amendMentData.amendment_id)
      .then((res) => {
        setAmendmentLoading(false);
        Helper.alert(res.data.message);
        loadAmendment();
        loadAmendmentDurations();
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error);
        setAmendmentLoading(false);
        loadAmendment();
        loadAmendmentDurations();
      });
  };

  const getLineGridStyle = (params) => {
    if (params?.data?.line_id && !params?.data?.is_amendmentline) {
      return {
        backgroundColor: '#f1f1f1',
      };
    }
    return null;
  };

  const getActiveLineIntegrator = () => {
    let id = null;
    if (activeSelectedLine) {
      id = activeSelectedLine.data.line_id;
    }
    return id;
  };

  const getActiveLineData = () => {
    let data = null;
    if (activeSelectedLine) {
      data = activeSelectedLine.data;
    }
    return data;
  };

  const afterDeleteButtonOfLines = (params) => {
    let amendMentData = amendment;
    return (
      <p className='rs_h_controller rs_amendment_controller'>
        <AmendDatesButtonPopup
          getExistingDates={getActiveLineExistingDate}
          getAmendmentDuration={getAmendmentDuration}
          afterCreteOrUpdate={afterCreetOrUpdateAmendmentDuration}
          amendmentline_type={1}
          integratorMessage={Helper.getLabel(null, 'please_select_a_line', 'PLease select a line')}
          integrator_id={getActiveLineIntegrator}
          amendment_id={amendMentData.amendment_id}
        />
      </p>
    );
  };

  const getAmendmentExistingDate = () => {
    let amendMentData = amendment;
    return {
      start_date: amendMentData?.start_date,
      end_date: amendMentData?.end_date,
    };
  };

  const getActiveLineExistingDate = () => {
    let activeLine = getActiveLineData();
    return {
      start_date: activeLine?.start_date,
      end_date: activeLine?.end_date,
    };
  };

  const onLineSelectionChanged = (event) => {
    let activeSelectedLine = event.api.getSelectedNodes();
    if (activeSelectedLine.length <= 0) {
      setActiveSelectedLine(null);
    } else {
      setActiveSelectedLine(activeSelectedLine[0]);
    }
  };

  useEffect(()=>{
    loadAmendment()
    loadAmendmentDurations()
    if(!isAmentEvnetRegistered){
        isAmentEvnetRegistered = true;
        document.addEventListener('rs_amendment_created',(event)=> {
            setAmendmentDurations(true)
            setTimeout(()=>{
                setIsLoadedAmendment(false)
                setAmendmentDurations(false)
                loadAmendment()
                loadAmendmentDurations()
            },100)
            
        })
    }
  },[location])

  if (amendmentLoading) {
    return <SimpleLoading />;
  }

  let amendMentData = amendment;
  let poData = po;
  let lng = null;
  let lineGridSettings = {
    getRowStyle: getLineGridStyle,
  };

  if (!poData || !amendMentData) {
    return <SimpleLoading />;
  }

  let headerDuration = getAmendmentDuration(0, poData.purchase_order_id);
  let startDate = poData?.start_date;
  let endDate = poData?.end_date;
  if (headerDuration) {
    startDate = headerDuration?.start_date;
    endDate = headerDuration?.end_date;
  }

  return (
    <div className='amandment_request_single_page'>
      <NavigationHeder
        //commented for future
        // backUrl={props.backUrl ? props.backUrl + '/' + poData.purchase_order_id : ''}
        title={Helper.getLabel(lng, 'Amendment_request', 'Amendment request')}
        hideMoreBtn={true}
      >
        <TotalWidget source_id={65} integrator={amendMentData.amendment_id} />
        <Button
          id='btn_master_save'
          className='rfq_save_btn'
          isDisable={updateingAmendment}
          isActive={false}
          title={Helper.getLabel(null, 'save', 'Save')}
          onClick={amendmentSaveHandler}
        />
        <Button title={Helper.getLabel(null, 'post', 'Post')} className='blue' isActive={true} onClick={onPostingHandler} />
      </NavigationHeder>
      <PoTopBlueShortDetails poid={poData.purchase_order_id} />
      {/* <AmendmentLogs/> */}
      <Collapse className='mt-3 mb-3' open={true} title={Helper.getLabel(null, 'general_information', 'General Information')}>
        <div className='d-flex justify-content-between'>
          <div>
            {/* <Checkbox name = "on_behalf" label={Helper.getLabel(null,'on_behalf','On behalf')} onChange={onCheckboxChangeHandler} value={poData}/> */}
          </div>

          <div>
            <AmendDatesButtonPopup
              getExistingDates={getAmendmentExistingDate}
              getAmendmentDuration={getAmendmentDuration}
              afterCreteOrUpdate={afterCreetOrUpdateAmendmentDuration}
              amendment={amendMentData}
              amendmentline_type={0}
              integrator_id={poData.purchase_order_id}
              amendment_id={amendMentData.amendment_id}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-4'>
            <Input label={Helper.getLabel(null, 'description', 'Description')} name='description' value={amendMentData.description} onChange={onAmendmentChangeHandler} />
          </div>
          <div className='col-xs-12 col-md-4'>
            <NewInputDatePicker
              disable={true}
              value={startDate}
              id='start_date'
              name='start_date'
              label={Helper.getLabel(null, 'start_date', 'Start date')}
            //   onChange={onDateChangeHanlder}
            />
          </div>
          <div className='col-xs-12 col-md-4'>
            <NewInputDatePicker
              disable={true}
              value={endDate}
              id='end_date'
              name='end_date'
              label={Helper.getLabel(null, 'end_date', 'End date')}
            //   onChange={onDateChangeHanlder}
            />
          </div>
          <div className='col-xs-12 col-md-4'></div>
        </div>
      </Collapse>
      <LineViewComponent
        lineGridId='amendment_line_grid'
        distributionGridId='amendment_line_distribution'
        defaultValues={{
          requester_id: poData.requester_id,
          warehouse_id: poData.warehouse_id,
          delivery_date: poData.delivery_date,
          taxgroupitem_id: poData.taxgroupitem_id,
          taxgroup_id: poData.taxgroup_id,
          entity_id: poData.entity_id,
          site_id: poData.site_id,
          orderer_id: poData.orderer_id,
        }}
        source_id={Settings.source_id.purchase_order}
        line_source={Settings.source_id.purchase_order}
        isEditable={true}
        lineGridTitle={Helper.getLabel(null, 'contract_order_lines', 'Contract Order Lines')}
        header_id={poData.purchase_order_id}
        purchase_status={poData.purchase_status}
        document_status={poData.document_status}
        delivery_date={poData.delivery_date}
        master_save_button_id='btn_master_save'
        onbehalf_request={false}
        vendor_id={null}
        vendorsite_id={null}
        lineGridSettings={lineGridSettings}
        viewType='amendment'
        distributionTabCols={['remaining_qty', 'received_qty']}
        amendment_id={amendMentData.amendment_id}
        afterDeleteButtonOfLines={afterDeleteButtonOfLines}
        onLineSelectionChanged={onLineSelectionChanged}
      />
    </div>
  );
};

export default MasterComponentWraper(AmendmentRequest);
