import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import Api from '../../inc/Api';
import User from '../../inc/Auth/User';
import Settings from '../../inc/Settings';
import Alert from '../inc/Alert';
import SimpleLoading from '../Loading/SimpleLoading';
const ApplySecurityRoles = (SecureComponent,componentOptions = {}) =>{
    class UserSecurityCheck extends Component {
        
        constructor(props){
            super(props);
            this.isLoadedAccessLevel = false;
            this.user = new User(this.props.auth)
            this.state ={
                isLoading:false,
                accessLevels:[],
                resourceGUI_Id: componentOptions.gui_id ?  componentOptions.gui_id : null
            }
        }
        getAccess(){
            if(this.state.resourceGUI_Id){
                if(this.props.securityRoles.accessLevels[this.state.resourceGUI_Id]){
                    this.isLoadedAccessLevel = true;
                    return this.props.securityRoles.accessLevels[this.state.resourceGUI_Id];
                }
            }
            return [];
        }
        componentDidMount(){
            this.loadAccessLevels();
        }
        loadAccessLevels(){
            let accessLevels = this.getAccess();
            if(!this.state.resourceGUI_Id){
                return;
            }
            if(accessLevels.length>=1){
                return;
            }
            if(this.isLoadedAccessLevel){
                return;
            }
            let api = Api;
            api.setUserToken();
            let that  = this;
            this.setState({
                isLoading:true
            })
            this.isLoadedAccessLevel = true;
            
            if(that?.props?.user?.admin_user) {
                that.setState({ isLoading:false });
                return;
            }

            api.axios().get( Settings.apiBaseUrl + '/get_form/'+ this.state.resourceGUI_Id).then(function(res){
                if(res.data.status =='success'){
                    that.setState({
                        //accessLevels:res.data.data,
                        isLoading:false
                    },function(){
                        that.props.setAccess(res.data.data)
                    })
                }else{
                    console.log('ApplySecurityRoels.js',res)
                }
                
            }).catch((error) =>{
                that.setState({
                    isLoading:false
                })
            } )
        }
        /**
         * 
         * @param String gui_id 
         */
        addResource(gui_id){
            this.setState({
                resourceGUI_Id:gui_id
            })
        }
        /**
         * 
         * @param {String} accessType 
         */
        can(accessType = 'full' , resourceId = null){
            let accessList = this.getAccess();
            let resourceGUI_Id = resourceId;
            if(accessList.length<=0){
                return false;
            }
            let accessLevel = {}
            accessList.forEach(accessLevelItem => {
                if(accessLevelItem.GUI_ID == resourceGUI_Id){
                    accessLevel = accessLevelItem;
                }
            });
            if( (accessLevel.access && accessLevel.access == 1) && accessType ==='full' ){
                return true;
            }
            if( (accessLevel.access && accessLevel.access == 2) && accessType === 'readonly' ){
                return true;
            }
            return false;
        }
        canFullAccess(ResourceId = null){
            if(this.user.isSupperAdmin()){
                return true;
            }
            return this.can('full',ResourceId);
        }
        
        canRead(ResourceId = null){
            if(this.canFullAccess(ResourceId)){
                return true;
            }
            return this.can('readonly',ResourceId);
        }
        canEdit(ResourceId = null){
            return this.canFullAccess(ResourceId);
        }
        canCreate(ResourceId = null){
            return this.canFullAccess(ResourceId);
        }
        canDelete(ResourceId = null){
            return this.canFullAccess(ResourceId)
        }
        render() {
            if(this.state.isLoading){
                return <SimpleLoading />
            }
            let securityProps = {
                can:this.can.bind(this),
                canRead: this.canRead.bind(this),
                canDelete: this.canDelete.bind(this),
                canView: this.canRead.bind(this),
                canEdit: this.canEdit.bind(this),
                canCreate: this.canCreate.bind(this),
                canFullAccess: this.canFullAccess.bind(this),
                resourceRegister: this.addResource.bind(this),
                accessLevels:  this.getAccess.bind(this)
            }
            return <SecureComponent security={securityProps}   {...this.props}/>
        }
    }
    const mapStateToProps = (state) => {
        return {
            securityRoles:state.securityRoles,
            auth:state.auth,
            user:state.auth.user
        }
    }
    const mapDispatchToProps = (dispatch) => {
        return {
            setAccess: (accessLavels) => { dispatch({payload: accessLavels,gui_id:componentOptions.gui_id,type: ActionTypes.SET_ACCESS_LEVELS })}
        }
    }
    return connect(mapStateToProps,mapDispatchToProps) (UserSecurityCheck);
}

export default ApplySecurityRoles;