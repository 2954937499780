import React from 'react'
import EditPO from '../../../Purchase Orders Internal/Edit PO/EditPO'
import Helper from '../../../../inc/Helper'
import Gui_id_list from '../../../../inc/Gui_id_list'
import MasterComonent from '../../../Backend/MasterComonent'
import Alert from '../../../inc/Alert'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

const EditInvoiceOnAccount = ({security}) => {
  if (!security.canView(Gui_id_list.finance.invoices.invoices_from_po_edit_screen)) {
    return (
      <MasterComonent>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </MasterComonent>
    )
  }
  return (
    <EditPO
      title={Helper.getLabel(
        null,
        'edit_invoice',
        'Edit Invoice'
      )}
      lineTitle={Helper.getLabel(null, 'invoice_lines',"Invoice Lines")}
      backUrl={-1}
      security={security}
      hideStartDate={false}
      hideEndDate={false}
      hideBillingRule={false}
      source_id={16}
      visibleEndDate={true}
      visibleStartDate={true}
      type={"invoice on account"}
    />
  )
}

const SecurityOptions = {
  gui_id: Gui_id_list.finance.invoices.invoices_from_po_edit_screen
};

export default ApplySecurityRoles(EditInvoiceOnAccount, SecurityOptions);  