import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import { ValidateArray, ValidateData, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import MasterComonent from '../../Backend/MasterComonent'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button' 
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import CreatePOPopup from '../Create PO/CreatePOPopup'
import LoadPurchaseStatus from '../../../actions/LoadPurchaseStatus'

const ReleaseOrderFreeze = (props) => {
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [openCreateModePO, setOpenCreateModePO] = useState(false)
  const [vendors, setVendors] = useState([])
  const [vendorsName, setVendorsName] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  let POList = false
  let vendorCalled = false

  const LoadPurchaseStatus = () => {
    props.LoadPurchaseStatus(props.purchaseStatus)
  }

  useEffect(() => {
    get_purchase_orders_data()
    get_vendors_data()
    LoadPurchaseStatus()
  }, [])

  const CellHandler = (event) => {  
    if(event.colDef.field === 'document_number') { 
      navigate(`/edit-purchase-order/${event.data.purchase_order_id}`)
    }
    if(event.colDef.field === 'vendor_code') { 
      navigate(`/edit-vendor/${event.data.vendor_id}`)
    }
  }

  const get_purchase_orders_data = () => {
    if(POList){
      return;
    }
    let api = Api
    POList = true
    api.setUserToken()
    let data = {
      page_number:1,
      page_size:100
    }
    let url = Settings.apiPurchaseOrderUrl+`/PO_header?page_number=${data.page_number}&page_size=${data.page_size}&purchase_status=4&type=2`;
    api.axios().get(url,data).then(function(res){
      if(res.data.status==="success"){  
        let UPDATED_DATA = []
        res.data.data.forEach(element => {
          UPDATED_DATA.push({
            "document_number": element.document_number,
            "vendor_code": element.vendor_id,
            "vendor_name": element.vendor_id,
            "purchase_status": element.purchase_status,
            "document_date": Helper.formateDate(element.document_date),
            "creationtime": Helper.formateDate(element.creationtime),
            "vendor_id": element.vendor_id,
            "purchase_order_id": element.purchase_order_id,
            "description": element.description
          })
        });
        setPurchaseOrders(UPDATED_DATA.reverse())
        setLoading(false)
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoading(false)
    })
  }

  const get_vendors_data = () => {
    if(vendorCalled){
      return;
    }
    let api = Api
    vendorCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
      if(res.data.status==="success"){  
        let UPDATED_STATE = [] 
        let UPDATED_STATE_NAME = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
          value: element.vendor_id,
          label: element.code
        })
        })    
        res.data.data.forEach((element) => {
          UPDATED_STATE_NAME.push({
          value: element.vendor_id,
          label: element.name
        }) 
        })
        setVendors(UPDATED_STATE) 
        setVendorsName(UPDATED_STATE_NAME) 
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res)  
    })
  }

  const DeletePOHandler = (allRows) => { 
    let api = Api;
    api.setUserToken();
    allRows.forEach(element => {
      let delPO_ID = element.purchase_order_id;
      api.axios().delete(Settings.apiPurchaseOrderUrl+'/PO_header/'+delPO_ID).then(res=>{
        Helper.alert(res.data.message);
        get_purchase_orders_data()
      }).catch(error => {
        getPopupMessageBasedOnStatus(error)
        get_purchase_orders_data()
      })

    }) 
  }

  const gridLinkValueFormat = (params) => {
    return `<a onclick="return false" href="/edit-purchase-order/${params.data.purchase_order_id}"><div class="rs_grid_simple_link">${params.value ? params.value : '-'}</div></a>`;
  }

  const gridLinkValueCodeFormat = (params) => { 
    if(vendors.find(id => id.value == params.value) == undefined){
      return `<div class="rs_grid_simple_link">${params.value}</div>`
    }else {
      let label = !ValidateData(vendors)? vendors.find(id => id.value == params.value).label : ''
      return `<div class="rs_grid_simple_link">${label}</div>`
    } 
  }
  
  const statusValueFormat = (params) => {
    let currentpurchaseStatus = props.purchaseStatus.allStatus.find( item => {
      return item.id == params.data.purchase_status ? item : false
    })
    if(currentpurchaseStatus){
      let color = getPoColorCode(currentpurchaseStatus.id)
      return `<span class="rs_active_label" style="background-color:${color.bgColor}; color:${color.textColor}">${currentpurchaseStatus.name}</span>`;
    }
    return ''
  }
  const getPoColorCode = (statusId) => {
    let output = {
      bgColor:'#fff',
      textColor:'#000'
    }
    let poStatusWithColor = Settings.po_purchase_status;
    for (const key in poStatusWithColor) {
      if (Object.hasOwnProperty.call(poStatusWithColor, key)) {
        const poSt = poStatusWithColor[key];
        if(statusId == poSt.id){
          output = poSt;
        }
      }
    }
    return output;
  }  

  const billingstatusValueFormat = (params) => {
    if(params.value === true){
      return `<span class="rs_active_billing">Billed</span>`;
    }
    return `<span class="rs_inactive_billing">Non Billed</span>`;
  }  
  
  const CreateModePurchaseOrder = () => { 
    setOpenCreateModePO(true)
  }
     
  const Columns_Headings = [
    {field:'document_number', headerName:Helper.getLabel(props.language,'document_number',"Document Number"), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat },
    {field:'vendor_code', headerName:Helper.getLabel(props.language,'vendor_code',"Vendor Code"), editable: false, options: vendors, inputType: "dropdown",  cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueCodeFormat},
    {field:'vendor_id', headerName:Helper.getLabel(props.language,'vendor_name',"Vendor Name"), editable: false, options: vendorsName, inputType: "dropdown"},
    {field:'purchase_status', headerName:Helper.getLabel(props.language,'purchase_status',"Purchase Status"), editable: false, options:[true,false], valueFormatter: statusValueFormat,  cellRenderer: CustomHtmlCell}, 
    {field:'description', headerName:Helper.getLabel(props.language,'description', "Description"), },
    {field:'document_date', headerName:Helper.getLabel(props.language,'document_date', "Document Date"), },
    {field:'creationtime', headerName:Helper.getLabel(props.language,'creationdatetime',"Creation Date Time"), editable: false},
    {field:'accounting_date', editable:false, hide:true},
    {field:'attention_information', editable:false, hide:true},
    {field:'delivery_date', editable:false, hide:true},
    {field:'delivery_name', editable:false, hide:true},
    {field:'discount_amount', editable:false, hide:true},
    {field:'discount_percentage', editable:false, hide:true},
    {field:'total_amount', editable:false, hide:true}
  ]
  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.purchase_orders)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }
 
  return (
    <div className='purchase_orders_archive'>
    {openCreateModePO ? <CreatePOPopup headerType={"Release Order"} ClosePopup={setOpenCreateModePO} /> : ''}
      <div className='container-fluid'>
        <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'release_orders_freeze',"Release Orders - Freeze")}>
          <Button onClick={CreateModePurchaseOrder} isActive={true} icon='/images/icons/plus-white.svg' title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders.purchase_orders_main_create_button)} /> 
        </NavigationHeder> 

      {loading ? <SimpleLoading /> : null}
        <AgGrid id="rs_purchase_orders_list" onDelete={DeletePOHandler} hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.purchase_orders_main_delete_button)} onCellClicked={CellHandler} header={Columns_Headings} data={purchaseOrders} addBtnHide={true} hideSave={true} viewOnly={true}/>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      purchaseStatus:state.purchaseStatus,
      language:state.language
    }
  }

  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders
}
const setDispatchToProps = (dispatch) => {
  return {
    LoadPurchaseStatus: (oldData) => { LoadPurchaseStatus(dispatch,oldData)}
  }
}

export default connect(mapStateToProps,setDispatchToProps) (MasterComponentWraper((ApplySecurityRoles(ReleaseOrderFreeze, SecurityOptions))))