import React, { Fragment, useEffect, useState } from "react";
import NavigationHeder from "../../../Navigations/NavigationHeder";
import AgGridNew from "../../../grid/ag/ag-grid-new";
import Helper from "../../../../inc/Helper";
import { connect } from "react-redux";
import Settings from "../../../../inc/Settings";
import MasterComponentWraper from "../../../Backend/MasterComponentWraper";
import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import { customDateComparator } from "../../../../inc/Validation";
import Api from "../../../../inc/Api";
import SimpleDropdown from "../../../grid/ag/cellEditor/SimpleDropdown";
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from "../../../grid/ag/commonFunctions/GridCommonFunctions";

const PRRequestPolicyHistory = ({language}) => {
  const [enumDetails, setEnumDetails] = useState([]);

  let enumApiCalled = false;

  useEffect(() => { getPRExpressRequestDate() }, []);

  const getPRExpressRequestDate = () => {
    if (enumApiCalled) return;
    enumApiCalled = true;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl+`/enum/express_request_date_to_picked`, {})
      .then(function (res) {
        if (res.data.status === "success") { 
          setEnumDetails(res?.data?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.name
            }
          })); 
        }
      })
      .catch((e) => { 
        setEnumDetails([]);
      });
  };

  const gridColHeaders = [
    { field:'name', headerName:Helper.getLabel(language,'name',"Name"), minWidth: 250 },
    {
      field: 'which_date_to_be_picked_if_both_provided',
      headerName: 'Which date to pick if both provided',
      editable: false,
      cellEditor: SimpleDropdown,
      minWidth: 250,
      isAllowZero:true,
      cellEditorParams: { values: enumDetails },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, enumDetails),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    },
    { field:'no_of_days_from_creation_and_deliverydate', headerName:Helper.getLabel(language,'no_of_days_from_creation_and_deliverydate',"No. of days from creation and delivery date"), minWidth: 250},
    { field:'no_of_days_from_creation_and_startdate', headerName:Helper.getLabel(language,'no_of_days_from_creation_and_startdate',"No. of days from creation and start date"), minWidth: 250},
    { field:'revision', filter: 'agTextColumnFilter', editable:false, headerName:'Revision', minWidth: 250 },
    { field:'createdby_name', filter: 'agTextColumnFilter', editable:false, headerName:'Created by', minWidth: 250 },
    {
      field: 'createddatetime',
      headerName: "Created date time",
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value)
          : '-'
      },
      editable: false, 
      comparator: customDateComparator,
      minWidth: 250
    },
    { field:'modifiedby_name', filter: 'agTextColumnFilter', editable:false, headerName:'Modified by', minWidth: 250 },
    {
      field: 'modifieddatetime',
      headerName: "Modified date time",
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value)
          : '-'
      },
      editable: false, 
      comparator: customDateComparator,
      minWidth: 250
    }
  ];
  return (
    <Fragment>
      <div className="container-fluid">
        <NavigationHeder
          hideMoreBtn={true}
          backUrl="/pr-request-policies"
          title={Helper.getLabel(
            language,
            "pr_request_policy_history",
            "PR Request Policy History"
          )}
        />

        <AgGridNew
          apiUrl={Settings.apiPurchaseRequisitionUrl+'/express_request'}
          pagination={false}
          columnDefs={gridColHeaders}
          hideAddBtn={true}
          hideDeleteBtn={true}
          height={500}
          gridId={"rs_pr_request_policy_history_list"}
        />
      </div>
    </Fragment>
  );
};
 
const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps) (MasterComponentWraper(PRRequestPolicyHistory));