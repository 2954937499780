import React, { useState } from 'react'
import Popup from '../../Popup/Popup'
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import Helper from '../../../inc/Helper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import Settings from '../../../inc/Settings'
import { getExistingInvoicesColumns } from './ColumnsHeadings'

let gridApi = null

const InvoiceListPopup = ({
  language,
  documentNumber,
  onInvoiceListClose,
  selectedDocument,
  setInvoiceHeader,
}) => {
  const [selectedInvoice, setSelectedInvoice] = useState({})
  
  const Column_Header = getExistingInvoicesColumns({
    language:language
  })

  const onSelectionChanged = (event) => {
    let selectedRows = event.api.getSelectedRows()
    setSelectedInvoice(selectedRows[0])
  }

  const handleGridReady = (gridObj) => {
    gridApi = gridObj
  }

  const onSelectBtnClick = () => {
    setInvoiceHeader(selectedInvoice)
    onInvoiceListClose()
  }

  return (
    <div>
      <Popup autoOpen={true} width='1000px' onClose={onInvoiceListClose}>
        <div>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <h5 style={{ fontWeight: '600' }}>
              List of existing invoices related to{' '}
              <span style={{ color: '#FC5E24' }}>{documentNumber}</span>{' '}
            </h5>
            <Button
              title={Helper.getLabel(language, 'select', 'Select')}
              className='rfq_save_btn'
              onClick={onSelectBtnClick}
            />
          </div>
          <Collapse
            open={true}
            title={Helper.getLabel(language, 'invoice_list', 'Invoice List')}
          >
            <AgGridNew
              columnDefs={Column_Header}
              hideDeleteBtn={true}
              hideAddBtn={true}
              apiUrl={Settings.apiPurchaseOrderUrl + `/get_invoices_by_PO/${selectedDocument?.purchase_order_id}?status=0`}
              rowType="single"
              onGridReady={handleGridReady}
              onSelectionChanged={onSelectionChanged}
            />
          </Collapse>
        </div>
      </Popup>
    </div>
  )
}

export default InvoiceListPopup
