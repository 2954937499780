import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    nature:[]
}
const AmendmentNatureReducer = ( state = defaultState, actions) =>{
    if(ActionTypes.SET_AMENDMENT_NATURE === actions.type){
        state = {
            ...state,
            isLoaded:true,
            nature:actions.payload
        }
    }
    return state;
}
export default AmendmentNatureReducer;