import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../Popup/Popup';
import $ from 'jquery';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import CommonAPICalls from '../../Common Calls/CommonAPICalls';
import Dropdown from '../../Forms/Dropdown';

let cancelTokenSource = axios.CancelToken.source();
const CreateEmployeeModal = ({setShowCreateModal, language}) => {  
    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const [numberSequence, setNumberSequence] = useState({});
    const [title, setTitle] = useState([])
    const [positions, setPositions] = useState([])
    const [entityList, setEntityList] = useState([])
    const [siteList, setSiteList] = useState([])
    const [warehouseList, setWarehouseList] = useState([]) 
    const [gender, setGender] = useState([]) 
    const [supervisor, setSupervisor] = useState([])

    const supervisorDataRef = useRef([])
    const siteDataRef = useRef([])
    const entityDataRef = useRef([])
    const warehouseDataRef = useRef([])

    const [selectedEntityOptions, setSelectedEntityOptions] = useState(null);
    const [selectedSiteOptions, setSelectedSiteOptions] = useState(null);
    const [selectedWarehouseOptions, setSelectedWarehouseOptions] = useState(null);

    const [siteAdditionalParams, setSiteAdditionalParams] = useState({});
    const [warehouseAdditionalParams, setWarehouseAdditionalParams] = useState({});

    const navigate = useNavigate();

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [ 
            {name:'title', displayName:Helper.getLabel(language,'title','Title'),types:['Required']}, 
            {name:'first_name', displayName:Helper.getLabel(language,'first_name','First name'),types:['Required']},  
            {name:'last_name', displayName:Helper.getLabel(language,'last_name','Last name'),types:['Required'],}, 
            {name:'display_name', displayName:Helper.getLabel(language,'display_name','Display name'),types:['Required'] }
        ];
      
        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let employeeTitleCalled = false; 
    let genderCalled = false; 
    let supervisionCalled = false;
    let positionCalled = false;
    let commonSystemCalled = false;
    let nsApiCalled = false;

    useEffect(() => { 
        getNumberSequenceData();
        getCommonSystemCalls();
        getEmployeeTitles();  
        getGender(); 
        getSupervisors();
        getPositions();
    }, []);
 
    const getNumberSequenceData = async () => {
        if(nsApiCalled) return;
        nsApiCalled = true;

        try {
            let api = Api
            api.setUserToken();
            const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/None/${Settings.source_id.employee}`)
            if (numberSequenceRecord?.status===200) { 
                setNumberSequence(numberSequenceRecord?.data)
            }
        } catch(error){
            setNumberSequence({})
        }
    };

    const getCommonSystemCalls = async () => {
        if(commonSystemCalled) return;
    
        commonSystemCalled = true;
    
        const commonAPICalls = new CommonAPICalls()
        const entites = await commonAPICalls.getSystemEntities()
        const sites = await commonAPICalls.getSystemSites()
        const warehouses = await commonAPICalls.getSystemWarehouse()

        let updated_entities = [];
        let updated_sites = [];
        let updated_warehouses = [];
        entites?.forEach((item) => { updated_entities.push({value: item.entity_id, label: `${item.code} - ${item.name}`}) })
        sites?.forEach((item) => { updated_sites.push({value: item.site_id, label: `${item.code} - ${item.name}`}) })
        warehouses?.forEach((item) => { updated_warehouses.push({value: item.warehouse_id, label: `${item.code} - ${item.name}`}) })
            setEntityList(updated_entities)
            setSiteList(updated_sites)
            setWarehouseList(updated_warehouses) 
    };

    const getEmployeeTitles = () => {
        if(employeeTitleCalled) return;

        let api = Api;
        employeeTitleCalled = true;
        api.setUserToken();
        api.axios().get(Settings.apiUrl+`/employeetitle`,{}).then(function(res){
            if(res.data.status==="success"){  
                setTitle(res?.data?.data?.map((element) => {
                    return {
                        value: element.id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const getGender = () => {
      if (genderCalled) {
        return;
      }
      let api = Api;
      genderCalled = true;
      api.setUserToken();
      api
        .axios()
        .get(Settings.apiUrl + `/employeegender`, {})
        .then(function (res) {
            if (res.data.status === "success") { 
                setGender(res?.data?.data?.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                }));
            }
        });
    };
 
    const getSupervisors = () => {
        if (supervisionCalled) {
            return;
        }
        let api = Api;
        supervisionCalled = true;
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiOrgUrl + `/employee_dropdown`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    setSupervisor(res?.data?.data?.map((element) => {
                        return {
                            value: element.employee_id,
                            label: element.displayname,
                        }
                    }));
                }
            });
    };

    const getPositions = () => {
        if (positionCalled) {
            return;
        }
        let api = Api;
        positionCalled = true;
        api.setUserToken();
        api
        .axios()
        .get(Settings.apiOrgUrl + `/get_active_postion`, {})
        .then(function (res) {
            if (res.data.status === "success") { 
                setPositions(res?.data?.data?.map((element) => {
                    return {
                        value: element.position_id,
                        label: element.name,
                    }
                }));
            }
        });
    };
 
    const mapSupervisorData = (item) => {
        return {
            text: `${item.displayname}`,
            id: item.employee_id
        };
    };

    const mapEntityData = (item) => {
        return {
            text: `${item.code} - ${item.name}`,
            id: item.entity_id
        };
    };

    const mapSiteData = (item) => {
        return {
            text: `${item.code} - ${item.name}`,
            id: item.site_id
        };
    };

    const mapWarehouseData = (item) => {
        return {
            text: `${item.code} - ${item.name}`,
            id: item.warehouse_id
        };
    };

    const handleEntityChange = (entity_id) => {  
        setSelectedEntityOptions(entity_id); 
        if (entity_id) {
            setSiteAdditionalParams({});
            setWarehouseAdditionalParams({});
            setSelectedSiteOptions(null);
            setSelectedWarehouseOptions(null);
            setSiteAdditionalParams({
                entity_id: entity_id
            }); 
            setWarehouseAdditionalParams({
                entity_id: entity_id
            });
            setFormData((prevState) => {
                return {
                    ...prevState, 
                    entity_id
                }
            });
        } else {
            setSelectedSiteOptions(null);
            setSelectedWarehouseOptions(null);
            setFormData((prevState) => {
                return {
                    ...prevState,
                    site_id: null,
                    entity_id,
                    warehouse_id: null
                }
            });
            setSiteAdditionalParams({});
            setWarehouseAdditionalParams({});
        }
    };

    const handleSiteChange = (site_id) => { 
        setSelectedSiteOptions(site_id); 
        if (site_id) {
            const currentSiteData = siteDataRef?.current?.find(
                (item) => item?.site_id === parseInt(site_id)
            );
            setWarehouseAdditionalParams({
                site_id: site_id,
            });
            setSelectedEntityOptions(currentSiteData?.entity_id);
            setSelectedWarehouseOptions(currentSiteData?.warehouse_id);
            setFormData((prevState) => {
                return {
                    ...prevState,
                    site_id,
                    entity_id: currentSiteData?.entity_id,
                    warehouse_id: currentSiteData?.warehouse_id
                }
            });
        } else {
            let resSite = siteList?.filter(
                (item) => item?.entity_id == selectedEntityOptions
            );
            let resWerehouse = warehouseList?.filter(
                (item) => item?.entity_id == selectedEntityOptions
            );
            setSiteList(
                resSite?.map((item) => {
                    return {
                        value: item?.site_id,
                        label: item?.name
                    };
                })
            );
            setWarehouseList(
                resWerehouse?.map((item) => {
                    return {
                        value: item?.warehouse_id,
                        label: item?.name
                    };
                })
            );
            setSelectedWarehouseOptions(null);
            setFormData((prevState) => {
                return {
                    ...prevState,
                    site_id,
                    warehouse_id: null
                }
            });
        }
    };

    const handleWarehouseChange = (warehouse_id) => { 
        setSelectedWarehouseOptions(warehouse_id); 
        if (warehouse_id) {
            setSiteAdditionalParams({});
            setWarehouseAdditionalParams({});
            const CurrentWarehouseData = warehouseDataRef?.current?.find(
                (item) => item?.warehouse_id === parseInt(warehouse_id)
            ); 
            setSelectedEntityOptions(CurrentWarehouseData?.entity_id);
            setFormData((prevState) => {
                return {
                    ...prevState,
                    entity_id: CurrentWarehouseData?.entity_id, 
                    warehouse_id
                }
            });
        } else {
            setSelectedWarehouseOptions(null);
            setFormData((prevState) => {
                return {
                    ...prevState, 
                    warehouse_id: null
                }
            });
        }
    };

    const fieldsArrayPersonalInfo = [
        {className:'col-lg-4', maxLength: 20, required: !numberSequence?.ns_type == "continuous", disable: numberSequence?.ns_type == "continuous" || apiCallBtn, label: Helper.getLabel(language,'code',"Code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', placeHolder: 'Select title', disable: apiCallBtn, required: true, inputType: "dropdown", label: Helper.getLabel(language,'title',"Title"), options: title, id:'title', name:'title'},  
        {className:'col-lg-4', maxLength: 80, disable: apiCallBtn, label: Helper.getLabel(language,'first_name',"First name"), required:true, inputType: "text", id:'first_name', name:'first_name'},
        {className:'col-lg-4', maxLength: 80, disable: apiCallBtn, label: Helper.getLabel(language,'middlename',"Middle name"), inputType: "text", id:'middlename', name:'middlename'},
        {className:'col-lg-4', maxLength: 80, disable: apiCallBtn, label: Helper.getLabel(language,'last_name',"Last name"), required:true, inputType: "text", id:'last_name', name:'last_name'},
        {className:'col-lg-4', maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'display_name',"Display name"), required:true, inputType: "text", id:'display_name', name:'display_name'}
    ];

    const fieldsArrayAdditionalInfo = [
        {className:'col-lg-4', required: true, options: Settings.seedDataStatus, placeHolder: 'Select status', disable: apiCallBtn, label: Helper.getLabel(language,'status',"Status"), inputType: "dropdown", id:'status', name:'status'},
        {className:'col-lg-4', maxLength: 40, disable: apiCallBtn, label: Helper.getLabel(language,'national_identifier_EM_SETUP',"National identifier"), inputType: "text", id:'national_identifier', name:'national_identifier'},
        {className:'col-lg-4', emptyDate: true, disable: apiCallBtn, label: Helper.getLabel(language,'dob_EM_SETUP',"Date of birth"), inputType: "datepicker_new", id:'date_of_birth', name:'date_of_birth'},
        {className:'col-lg-4', maxLength: 20, disable: apiCallBtn, label: Helper.getLabel(language,'ext_code_EM_SETUP',"External code"), inputType: "text", id:'external_code', name:'external_code'},
        {className:'col-lg-4', placeHolder: 'Select gender', disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'gender',"Gender"), options: gender, id:'gender', name:'gender'}, 
        {className:'col-lg-4', pagination: true, apiUrl: Settings?.apiOrgUrl + `/employee_dropdown`, allDataRef: supervisorDataRef, mapFunction: mapSupervisorData, placeHolder: 'Select supervisor', disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'supervisor_EM_SETUP',"Supervisor"), options: supervisor, id:'supervisor', name:'supervisor'},
        {className:'col-lg-4', placeHolder: 'Select position', disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'position_EM_SETUP',"Position"), options: positions, id:'position_id', name:'position_id'}
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiOrgUrl + '/employee', {
                ...formData, 
                status: formData?.status == 'true', 
                gender: formData?.gender !== "" ? formData?.gender : null,
                position_id: formData?.position_id !== "" ? formData?.position_id : null,
                supervisor: formData?.supervisor !== "" ? formData?.supervisor : null,
                date_of_birth: formData?.date_of_birth ? DatepickerFunctions.convertDateForDataBase(formData?.date_of_birth) : null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res.data.message); 
                onPopupClose();
                navigate(`/edit-employee/${res?.data?.Employee_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const mapOtherDetailsColumn = () => {
        return (
            <Fragment>
                <div className='col-lg-4'>
                    <Dropdown
                        label={`${Helper.getLabel(
                            language,
                            "entity",
                            "Entity"
                        )}`}
                        options={entityList || []}
                        apiUrl={Settings.loginUrl + "/get_entities"} 
                        pagination={true} 
                        mapFunction={mapEntityData} 
                        allDataRef={entityDataRef}  
                        placeHolder={`${Helper.getLabel(
                            language,
                            "select_entity",
                            "Select entity"
                        )}`}
                        id="select_entity_id"
                        reRenderRequired={true}
                        onChange={(e) => handleEntityChange(e?.target?.value)}
                        value={selectedEntityOptions}
                        required={true}
                    />
                </div>
                <div className='col-lg-4'>
                    <Dropdown
                        label={`${Helper.getLabel(language, "site", "Site")}`}
                        options={siteList ? siteList : []}
                        placeHolder={`${Helper.getLabel(
                            language,
                            "select_site",
                            "Select site"
                        )}`}
                        onChange={(e) => handleSiteChange(e?.target?.value)}
                        value={selectedSiteOptions}
                        id="site"
                        reRenderRequired={true}
                        apiUrl={Settings.loginUrl + "/get_site"} 
                        pagination={true} 
                        mapFunction={mapSiteData} 
                        allDataRef={siteDataRef}  
                        additionalParams={siteAdditionalParams}
                        required={true}
                    /> 
                </div>
                <div className='col-lg-4'>
                    <Dropdown
                        label={`${Helper.getLabel(language, "warehouse", "Warehouse")}`}
                        options={warehouseList ? warehouseList : []}
                        placeHolder={`${Helper.getLabel(
                            language,
                            "select_warehouse",
                            "Select warehouse"
                        )}`}
                        apiUrl={Settings.loginUrl + "/get_warehouse"} 
                        pagination={true} 
                        mapFunction={mapWarehouseData} 
                        allDataRef={warehouseDataRef}  
                        reRenderRequired={true}
                        onChange={(e) => handleWarehouseChange(e?.target?.value)}
                        additionalParams={warehouseAdditionalParams}
                        value={selectedWarehouseOptions}
                        id="warehouse"
                        required={true}
                    />
                </div>
            </Fragment>
        )
    };

    const handleSubmit = () => {
        if(!formValidatory.isValid({...formData}) || !formData?.status || !formData?.entity_id || !formData?.site_id || !formData?.warehouse_id){
            if(!formData?.status) {
                Helper.alert('Status field is required',  'error');
            };
            if(!formData?.entity_id) {
                Helper.alert('Entity field is required',  'error');
            }
            if(!formData?.site_id) {
                Helper.alert('Site field is required',  'error');
            }
            if(!formData?.warehouse_id) {
                Helper.alert('Warehouse field is required',  'error');
            }
            formValidatory.displayMessage();
            formValidatory.reset(); 
            return;
        };

        if(!numberSequence?.ns_type == "continuous" && (!formData?.code || formData?.code === "")) {
            Helper.alert('Code field is required', 'error');
            return;
        };

        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };
    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Employee</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "personal_info",
                            "Personal Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayPersonalInfo} />
                            </div>
                        </Form> 
                    </Collapse>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "additional_info",
                            "Additional Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer mapOtherColumnItems={mapOtherDetailsColumn} updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayAdditionalInfo} /> 
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (CreateEmployeeModal);  