import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    segments:null,
    isSegmentLoaded:false
}
const SegmentsReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_SEGMENTS){
        state = {
            ...state,
            isSegmentLoaded:true,
            segments:actions.payload
        }
    }
    return state;
}
export default SegmentsReducer;