import React, { Fragment, useEffect, useState } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import Gui_id_list from '../../../inc/Gui_id_list' 
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import Helper from '../../../inc/Helper'
import AgGrid from '../../grid/ag/ag-grid' 
import SimpleLoading from '../../Loading/SimpleLoading'
import Alert from '../../inc/Alert' 
import NavigationHeder from '../../Navigations/NavigationHeder'
import Button from '../../inc/Button' 
import Collapse from '../../inc/Collapse'
import Tabs from '../../Widgets/Tabs/Tabs'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation'

// Edit Mode Team Header Lines Access - Levels / Granted - SYSTEM

const TeamHeaderAccessLevelsEdit = (props) => {
  const {id} = useParams()
  const [teamLinesAccessLevels, setTeamLinesAccessLevels] = useState([])
  const [teamLinesAccessLevelsDropdown, setTeamLinesAccessLevelsDropdown] = useState([])
  const [teamLinesAccessLevelSource, setTeamLinesAccessLevelSource] = useState([])
  const [teamHeaderAccessLevels, setTeamHeaderAccessLevels] = useState([]) 
  const [accessLevels, setAccessLevels] = useState([])
  const [loading, setLoading] = useState(true)

  //Reduction Calls 

  let LinesAcessLevelidsCalled = false
  let LinesAcessLevelSourceidsCalled = false
  let headerAccessLevels= false
  let AccessLevelsCalled = false

  // ---

  // Tabs - 

  const DefaultNavs = [ 
    {title:Helper.getLabel(props.language,'teamlines_access','Team Lines Access Levels'), isActive: true, },
    {title:Helper.getLabel(props.language,'teamlines_access_source','Team Lines Access Levels (Source)')},  
  ]

  //--

  // API Calls / Render Load Functions --

  useEffect(() => {
    getTeamLinesAccessLevelIdsData()
    getTeamHeaderAccessLevels()
    AccessLevels()
  }, [])

  // ---

  // API Calls For Dropdown --

  const getTeamLinesAccessLevelIdsData = () => {
    if(LinesAcessLevelidsCalled){
     return;
    }
    let api = Api
    LinesAcessLevelidsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/accesslevels/${id}`,{}).then(function(res){
      if(res.data.status==='success'){
        let Updated_State = []
        res.data.data.forEach((element) => {
          Updated_State.push({
            label: element.teamlineaccesslevel_id,
            value: element.teamlineaccesslevel_id
          })
        })
        setTeamLinesAccessLevelsDropdown(Updated_State)
        setTeamLinesAccessLevels(res.data.data)
        setLoading(false)
      }
    }).catch((res) => { 
      setLoading(false)
      getPopupMessageBasedOnStatus(res)   
    })
  }

  const getTeamLinesAccessLevelSourceIdsData = () => {
    if(LinesAcessLevelSourceidsCalled){
     return;
    }
    let api = Api
    LinesAcessLevelSourceidsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/lineaccesslevel_source/${id}`,{}).then(function(res){
      if(res.data.status==='success'){
        setLoading(false)
        setTeamLinesAccessLevelSource(res.data.data) 
      }
    }).catch((res) => { 
      setLoading(false)
      getPopupMessageBasedOnStatus(res)   
    })
  }

   // -

  const getTeamHeaderAccessLevels = () => {
    if(headerAccessLevels){
     return;
    }
    let api = Api
    headerAccessLevels = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/access_levels`,{}).then(function(res){
     if(res.data.status==='success'){ 
      let Updated_State = []
      res.data.data.forEach(element => {
        Updated_State.push({
          label: element.description,
          value: element.teamaccess_level_id
        })
      });
      setTeamHeaderAccessLevels(Updated_State) 
     } 
    }).catch((res) => { 
      setLoading(false)
      getPopupMessageBasedOnStatus(res)   
    })
  }

  const AccessLevels = () => {
    if(AccessLevelsCalled){
     return;
    }
    let api = Api
    AccessLevelsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/lineaccess_level`,{}).then(function(res){
     if(res.data.status==='success'){ 
      let Updated_State = []
      res.data.data.forEach(element => {
        Updated_State.push({
          label: element.name,
          value: element.id
        })
      });
      setAccessLevels(Updated_State) 
     } 
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)   
    })
  }

  //---

  // Grid Things - Access Levels --
  
  const GridSaveHandler = (rowItem) => {
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.teamlineaccesslevel_id || item.teamlineaccesslevel_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){ 
      addNewData(newData);
    }
    if(updatedData.length>=1){ 
      updateData(updatedData);
    }
  }

  const updateData = (data) =>{  
    setLoading(true)
    let api = Api
    api.setUserToken()
    data.forEach(element => {  
      api.axios().put(Settings.apiUrl+`/teamlineaccess/${element.teamlineaccesslevel_id}`,{
        "teamline_id": id,
        "teamaccesslevel_id": parseInt(element.teamaccesslevel_id),
        "access_level": parseInt(element.access_level)
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          setLoading(false)
          getTeamLinesAccessLevelIdsData()
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
        getTeamLinesAccessLevelIdsData()
      })
    })
  }

  const addNewData = (data) => {   
    setLoading(true)
    let api = Api
    api.setUserToken()
    data.forEach(element => {
      api.axios().post(Settings.apiUrl+'/teamlineaccesslevel',{
        "teamline_id": id,
        "teamaccesslevel_id": parseInt(element.teamaccesslevel_id),
        "access_level": parseInt(element.access_level)
    }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getTeamLinesAccessLevelIdsData()
          setLoading(false)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        getTeamLinesAccessLevelIdsData()
        setLoading(false)
      })
    })
  }

  const DeleteTeamHeaderAccessLevel = (allRows) => {
    setLoading(true)
    let api = Api
    api.setUserToken()
    allRows.forEach(element => {
      api.axios().delete(Settings.apiUrl+`/teamlineaccess/${element.teamlineaccesslevel_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getTeamLinesAccessLevelIdsData()
          setLoading(false)
        }
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        getTeamLinesAccessLevelIdsData()
        setLoading(false)
      })
    })
  }

  const Columns_Headings = [
    {field:'teamaccesslevel_id', inputType: 'dropdown', options: teamHeaderAccessLevels, headerName: Helper.getLabel(props.language,'teamaccesslevel_id','Team Access Level'), }, 
    {field:'access_level', inputType: 'dropdown', options: accessLevels, headerName: Helper.getLabel(props.language,'access_level','Access Level')},
  ]

  const Columns_Headings_Source = [
    {field:'teamlineaccesslevel_id', inputType: 'dropdown', options: teamLinesAccessLevelsDropdown, headerName: Helper.getLabel(props.language,'teamaccesslevel_id','Team Line Access Level'), }, 
    {field:'access_level', inputType: 'dropdown', options: accessLevels, headerName: Helper.getLabel(props.language,'access_level','Access Level')},
  ]

  // ---

  // Grid Things - Access Levels Source 

  const GridSourceSaveHandler = (rowItem) => {
    if(checkIfArrayIsEmpty(teamLinesAccessLevels)){
      Helper.alert('Please Add Team Lines Access Levels then try creating source for it!', 'error')
      return;
    }
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.rec_id || item.rec_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){ 
      addNewDataSourceAccessLevel(newData);
    }
    if(updatedData.length>=1){ 
      updateDataSourceAccessLevel(updatedData);
    }
  }

  const addNewDataSourceAccessLevel = (data) => {
    setLoading(true)
    let api = Api
    api.setUserToken()
    data.forEach(element => {
      api.axios().post(Settings.apiUrl+'/teamlineaccess_source',[
        {
          "teamline_id": parseInt(id),
          "teamlineaccesslevel_id": parseInt(element.teamlineaccesslevel_id),
          "access_level": parseInt(element.access_level),
          "source_id": 12,
          "sourceintegrator_id": parseInt(id)
        }
      ]).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getTeamLinesAccessLevelSourceIdsData()
          setLoading(false)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        getTeamLinesAccessLevelSourceIdsData()
        setLoading(false)
      })
    })
  }

  const updateDataSourceAccessLevel = (data) => {
    setLoading(true)
    let api = Api
    api.setUserToken()
    data.forEach(element => {  
      api.axios().put(Settings.apiUrl+`/teamlineaccess_source/${element.rec_id}`,{
        "teamline_id": parseInt(id),
        "teamlineaccesslevel_id": parseInt(element.teamlineaccesslevel_id),
        "access_level": parseInt(element.access_level),
        "source_id": 12,
        "sourceintegrator_id": parseInt(id)
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          setLoading(false)
          getTeamLinesAccessLevelSourceIdsData()
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
        getTeamLinesAccessLevelSourceIdsData()
      })
    })
  }

  const DeleteTeamHeaderAccessLevelSource = (allRows) => {
    setLoading(true)
    let api = Api
    api.setUserToken()
    allRows.forEach(element => {
      api.axios().delete(Settings.apiUrl+`/teamlineaccess_source/${element.rec_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getTeamLinesAccessLevelSourceIdsData()
          setLoading(false)
        }
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        getTeamLinesAccessLevelSourceIdsData()
        setLoading(false)
      })
    })
  }

  // ---

  // Grid Module Team Lines Levels --

  const TeamLinesLevelsModule = () => {
    return (
      <div className='lines_access_levels_edit grid_save_btn_hide'>
        <AgGrid id="rs_team_lines_access_levels" onSave={GridSaveHandler} hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_edit_screen_delete_button)} onDelete={DeleteTeamHeaderAccessLevel} header={Columns_Headings} data={teamLinesAccessLevels}/>
      </div> 
    )
  }

  const TeamLinesLevelsSourceModule = () => {
    return (
      <div className='lines_access_levels_source_edit grid_save_btn_hide'>
        <AgGrid id="rs_team_lines_access_levels_source" onSave={GridSourceSaveHandler} hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_edit_screen_delete_button)} onDelete={DeleteTeamHeaderAccessLevelSource} header={Columns_Headings_Source} data={teamLinesAccessLevelSource}/>
      </div> 
    )
  }

  //

  // Execute Grid Savew Handler Through Class Name jQuery
  const SaveHandler = () => {
    $('.lines_access_levels_edit .rs_grid_btn_save').trigger('click'); 
    $('.lines_access_levels_source_edit .rs_grid_btn_save').trigger('click'); 
  }
  // ----

  //Security System - Accessibility Screen -

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_edit_screen)){
  return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  </Fragment>
  }

  return (
    <div className='team_lines_access_levels_edit_screen'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/teamlines-access-levels' title={ `<span style="color:#000;">${Helper.getLabel(props.language,'team_header_line_access_level_edit',"Team Header Line Access Level Edit")} #</span>  ${id}` }>
          <Button onClick={SaveHandler} isActive={true} icon='/images/icons/save-white.svg' isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_edit_screen_save_button)} title={Helper.getLabel(props.language,'save','Save')} />
        </NavigationHeder>

        {loading ? <SimpleLoading /> : (
          <Collapse className='mb-4 default_params_tabs' open={true} title={Helper.getLabel(props.language,'team_lines_accessibility',"Team Lines Accessibility")}>
            <Tabs disableSticky={true} navs={DefaultNavs} showAll={false} scrollOffset= "500">
              <Fragment>{TeamLinesLevelsModule()}</Fragment>
              <Fragment>{TeamLinesLevelsSourceModule()}</Fragment>
            </Tabs>
          </Collapse>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_edit_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(TeamHeaderAccessLevelsEdit, SecurityOptions))))