// import React, { Component } from 'react';
// import Api from '../../../../inc/Api';
// import Helper from '../../../../inc/Helper';
// import Settings from '../../../../inc/Settings';
// import DropDownInput from '../../../Forms/Dropdown';
// import Input from '../../../Forms/Input';
// import Collapse from '../../../inc/Collapse';
// import SimpleLoading from '../../../Loading/SimpleLoading';

// class GeneralLineDetails extends Component {
//     constructor(props){
//         super(props);
//         this.setTimeOut = null;
//         this.activePOLine = this.props.activePOLine;
//         this.state = {
//             isLoading:false,
//             refLineObj:{},
//             itemGroup:[],
//             rowData: this.props.activePOLine ? this.props.activePOLine.data:{}
//         }
//     }
//     componentDidMount(){
//         this.activePOLine = this.props.activePOLine;
//         this.setState({
//             rowData: this.props.activePOLine ? this.props.activePOLine.data:{}
//         })
//         this.loadItemGroup();

//     }

//     loadLineById(lineId){
//         let api = Api, that = this;
//         api.setUserToken();
//         api.axios().get(Settings.apiPurchaseOrderUrl+`/line/${lineId}/${this.props.line_source}`).then(res => {
//             that.setState({
//                 refLineObj:res.data.data
//             })
//         })
//     }
//     onChangeHandler(e) {
//         let oldRowData = this.state.rowData;
//         if(Object.keys(oldRowData).length <=0){
//             Helper.alert("Please select Purchase Order Lines",'error')
//             return;
//         }
//         let that = this;
//         this.setState({
//             rowData:{
//                 ...oldRowData,
//                 [e.target.name]: e.target.value
//             }
//         })
//         clearTimeout(this.setTimeOut);
//         this.setTimeOut = setTimeout(() => {
//             let isChanged = that.activePOLine.setDataValue(e.target.name,e.target.value,'RowValueChangedEvent');
//             if(isChanged){
//                 that.props.updateData(that.state.rowData,false,false);
//                 that.props.grid.componentObj.setGridValueChanged(that.activePOLine.id, that.state.rowData);
//             }

//         }, 200);
//     }
//     componentDidUpdate(prevProps, prevState, snapshot){
//         if(prevProps.activePOLine && this.props.activePOLine && prevProps.activePOLine.rowIndex != this.props.activePOLine.rowIndex){
//             this.activePOLine = this.props.activePOLine;
//             if(this.props.activePOLine && this.props.activePOLine.data.is_amendmentline){
//             }
//             let that = this;
//             this.setState({
//                 isLoading:true,
//                 rowData: this.props.activePOLine.data
//             },function(){
//                 that.setState({
//                     isLoading:false
//                 })
//             })
//         }
//     }
//     loadItemGroup(){
//         let api =  Api;
//         api.setUserToken();
//         let that = this;
//         api.axios().get(Settings.apiItemUrl+'/item_group').then(res =>{
//             that.setState({
//                 itemGroup:res.data.data
//             })
//         })
//     }
//     displayAmentmentReference(){
//         if(this.props.viewType != 'amendment'){
//             return <></>
//         }
//         return(
//             <div className='col-xs-12 col-sm-4'><Input label={Helper.getLabel(null,'reference_line_no','Reference line no')} name="reference_line_no"  value={this.props.activePOLine.data.reference_line_number}  disable={true} /></div>
//         )
//     }
//     isDisable(){
//         if(this.props.viewType == 'amendment' && this.props.activePOLine.data.line_id && !this.props.activePOLine.data.is_amendmentline){
//             return true;
//         }
//         return !this.props.isEditable;
//     }
//     render() {
//         let language = null;
//         let data = this.state.rowData;
//         let itemGroupOptions = this.state.itemGroup.map( item => {
//             return {
//                 value:item.itemgroup_id,
//                 label:item.name
//             }
//         })
//         if(this.state.isLoading){
//             return <SimpleLoading/>
//         }
//         return (
//             <Collapse open={true} title={Helper.getLabel(language,'line_details',"Line Details")}>
//                 <div className='row'>
//                     <div className='col-xs-12 col-sm-4'>
//                         <Input
//                             label={Helper.getLabel(language, 'description', 'Description')}
//                             name="description"
//                             onChange={this.onChangeHandler.bind(this)}
//                             value={data.description}
//                             disable={this.isDisable()}
//                         />
//                     </div>
//                     <div className='col-xs-12 col-sm-4'><Input label={Helper.getLabel(language,'comments','Comments')} name="comments" onChange={this.onChangeHandler.bind(this)} value={data.comments}  disable={this.isDisable()} /></div>
//                     <div className='col-xs-12 col-sm-4'><DropDownInput label={Helper.getLabel(language,'item_group','Item Group')} name="itemgroup_id" onChange={this.onChangeHandler.bind(this)} id="itemgroup_id"  value={data.itemgroup_id} options={itemGroupOptions}  disable={this.isDisable()} /></div>
//                     <div className='col-xs-12 col-sm-4'><Input label={Helper.getLabel(language,'external_reference','External Reference')} name="external_reference" onChange={this.onChangeHandler.bind(this)} value={data.external_reference}  disable={this.isDisable()} /></div>
//                     <div className='col-xs-12 col-sm-4'><DropDownInput label={Helper.getLabel(language,'orderer','Orderer')}  onChange={this.onChangeHandler.bind(this)}   options = {this.props.ordered} id = 'ordered_id' name = 'orderer_id'  value={data.orderer_id ? data.orderer_id : ''}  disable={this.isDisable()} /></div>
//                     <div className='col-xs-12 col-sm-4'><DropDownInput label={Helper.getLabel(language,'requester','Requester')} name="requester_id" onChange={this.onChangeHandler.bind(this)} id="requester_id"  options={this.props.requester} value={data.requester_id ? data.requester_id : ''}  disable={this.isDisable()}  /></div>
//                    {/* here we add 3 new field, Requirement Description, Scope of Work and Material Service not Included */}
//                    {this.props.source_id===10 || this .props.source_id === 63 && <>
//                     <div className='col-xs-12 col-sm-4'>
//                         <Input
//                             label={`${Helper.getLabel(language, "requirement_description", "Requirement Description")}`}
//                             name="requirement_description"
//                             onChange={this.onChangeHandler.bind(this)}
//                             value={data?.requirement_description}
//                             disable={this.isDisable()}
//                         />
//                     </div>
//                     <div className='col-xs-12 col-sm-4'>
//                         <Input
//                             label={`${Helper.getLabel(language, "scope_of_work", "Scope of Work")}`}
//                             name="scope_of_work"
//                             onChange={this.onChangeHandler.bind(this)}
//                             value={data?.scope_of_work}
//                             disable={this.isDisable()}
//                         />
//                     </div>
//                     <div className='col-xs-12 col-sm-4'>
//                         <Input
//                             label={`${Helper.getLabel(language, "material_service_not_included", "Material Service not Included")}`}
//                             name="material_service_not_included"
//                             onChange={this.onChangeHandler.bind(this)}
//                             value={data?.material_service_not_included}
//                             disable={this.isDisable()}
//                         />
//                     </div>
//                     </>}
//                     {  this.displayAmentmentReference() }
//                 </div>
//             </Collapse>
//         );
//     }
// }

// export default GeneralLineDetails;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import Api from '../../../../inc/Api';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import DropDownInput from '../../../Forms/Dropdown';
import Input from '../../../Forms/Input';
import Collapse from '../../../inc/Collapse';
import SimpleLoading from '../../../Loading/SimpleLoading';
import { Link } from 'react-router-dom';

const GeneralLineDetails = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [refLineObj, setRefLineObj] = useState({});
    const [itemGroup, setItemGroup] = useState([]);
    const [rowData, setRowData] = useState(props.activePOLine ? props.activePOLine.data : {});

    let setTimeOut = null

    useEffect(() => {
        loadItemGroup();
    }, []);

    const loadLineById = (lineId) => {
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseOrderUrl + `/line/${lineId}/${props.line_source}`).then(res => {
            setRefLineObj(res.data.data);
        });
    };

    // const onChangeHandler = (e) => {
    //     let oldRowData = rowData;
    //     if (Object.keys(oldRowData).length <= 0) {
    //         Helper.alert("Please select Purchase Order Lines", 'error');
    //         return;
    //     }

    //     setRowData({
    //         ...oldRowData,
    //         [e.target.name]: e.target.value
    //     });
        

    //     props.setUpdatedData({
    //         ...props.updatedData,
    //         [e.target.name]: e.target.value
    //     })
       

    //     clearTimeout(setTimeOut);
    //     setTimeOut = setTimeout(() => {
    //         let isChanged = props.activePOLine.setDataValue(e.target.name, e.target.value, 'RowValueChangedEvent');
    //         if (isChanged) {
    //             console.log("in changed")
    //             props.updateData(props.updatedData, rowData?.line_id, false);
    //             // props.grid.componentObj.setGridValueChanged(props.activePOLine.id, rowData);
    //         }
    //     }, 200);
    // };

    const onChangeHandler = useCallback((e) => {
        if (Object.keys(rowData).length <= 0) {
            Helper.alert("Please select Purchase Order Lines", 'error');
            return;
        }       
        setRowData(prevRowData => ({
            ...prevRowData,
            [e.target.name]: e.target.value
        }));

        const updatedData = {
            ...props.updatedData,
            [e.target.name]: e.target.value
        };
        props.setUpdatedData(updatedData);

        clearTimeout(setTimeOut);
        setTimeOut = setTimeout(() => {
            let isChanged = props.activePOLine.setDataValue(e.target.name, e.target.value, 'RowValueChangedEvent');
            if (isChanged) {
                props.updateData(updatedData, rowData?.line_id, false);
                props.grid.componentObj.setGridValueChanged(props.activePOLine.id, rowData);
            }
        }, 500);
    }, [props.updatedData, setRowData, props.setUpdatedData, props.activePOLine, props.updateData, rowData]);

    useEffect(() => {
        if (props.activePOLine && props.activePOLine?.data?.is_amendmentline) {
            setIsLoading(true);
            setRowData(props.activePOLine.data);
            setIsLoading(false);
        }
    }, [props.activePOLine]);

    useEffect(() => {
        if (props.activePOLine?.data?.line_id) {
            setRowData(props.activePOLine.data);
        }
    }, [props.activePOLine?.data?.line_id]);

    const loadItemGroup = () => {
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiItemUrl + '/item_group').then(res => {
            setItemGroup(res.data.data);
        });
    };

    const displayAmentmentReference = () => {
        if (props.viewType !== 'amendment') {
            return null;
        }
        return (
            <div className='col-xs-12 col-sm-4'>
                <Input
                    label={Helper.getLabel(null, 'reference_line_no', 'Reference line no')}
                    name="reference_line_no"
                    value={props.activePOLine.data.reference_line_number}
                    disable={true}
                />
            </div>
        );
    };

    const isDisable = () => {
        if (props.viewType === 'amendment' && props.activePOLine.data.line_id && !props.activePOLine.data.is_amendmentline) {
            return true;
        }
        return !props.isEditable;
    };

    let language = null;
    let data = rowData;
    let itemGroupOptions = itemGroup.map(item => {
        return {
            value: item.itemgroup_id,
            label: item.name
        };
    });
    const employeesData = useRef([])
    const mapEmployeesData = (item) => {
      return {
        id: item.employee_id,
        text: item.displayname,
      };
    }

    if (isLoading) {
        return <SimpleLoading />;
    }

    return (
        <Collapse open={true} title={Helper.getLabel(language, 'line_details', "Line Details")} className='mb-3'>
            <div className='row' >
                {(props?.isPRLinkNeeded || props?.isRFQLinkNeeded) && <div className='col-xs-12 col-sm-4'>
                  <p className='mb-1'>{Helper.getLabel(language, 'Source document number', 'Source document number')}</p>
                  {props?.isPRLinkNeeded && ((data?.pr_document_number=== null || data?.pr_document_number===undefined) ? " ":<Link to={`/purchase-requisition/summary?pr_view_id=${data?.purchase_requisition_id}`} state={{isPrevPath:true}}>{data?.pr_document_number}</Link>)}
                  {props?.isRFQLinkNeeded && ((data?.rfq_document_number === null || data?.rfq_document_number===undefined) ? " ":<Link to={`/request-for-quotation/rfqdetailform?rfq_view_id=${data?.rfq_id}&return=rfqheaderlist`} state={{isPrevPath:true}}>{data?.rfq_document_number}</Link>)}
                </div>}
                <div className='col-xs-12 col-sm-4'>
                    <Input
                        label={Helper.getLabel(language, 'description', 'Description')}
                        name="description"
                        onChange={onChangeHandler}
                        value={data?.description}
                        disable={isDisable()}
                    />
                </div>
                <div className='col-xs-12 col-sm-4'>
                    <Input
                        label={Helper.getLabel(language, 'comments', 'Comments')}
                        name="comments"
                        onChange={onChangeHandler}
                        value={data?.comments}
                        disable={props?.activePOLine?.data?.line_type !== 2 ? true : isDisable()}
                    />
                </div>
                <div className='col-xs-12 col-sm-4'>
                    <DropDownInput
                        label={Helper.getLabel(language, 'item_group', 'Item group')}
                        name="itemgroup_id"
                        onChange={onChangeHandler}
                        id="itemgroup_id"
                        value={data?.itemgroup_id}
                        options={itemGroupOptions}
                        reRenderRequired={true}
                        disable={isDisable()}
                        placeHolder={`${Helper.getLabel(language, 'select_item_group', 'Select item group')}`}
                    />
                </div>
                <div className='col-xs-12 col-sm-4'>
                    <Input
                        label={Helper.getLabel(language, 'external_reference', 'External reference')}
                        name="external_reference"
                        onChange={onChangeHandler}
                        value={data?.external_reference}
                        disable={isDisable()}
                    />
                </div>
                <div className='col-xs-12 col-sm-4'>
                    <DropDownInput
                        label={Helper.getLabel(language, 'orderer', 'Orderer')}
                        onChange={onChangeHandler}
                        options={ data?.orderer_id
                            ? [{
                                value: data.orderer_id,
                                label: data.orderer_name ?? employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(data.orderer_id))?.displayname,
                              }]
                            :props.ordered}
                        id='ordered_id'
                        name='orderer_id'
                        value={data?.orderer_id ? data?.orderer_id : ''}
                        reRenderRequired={true}
                        placeHolder={`${Helper.getLabel(language, 'select_orderer', 'Select orderer')}`}
                        disable={isDisable()}
                        pagination={true} 
                        apiUrl={Settings?.apiOrgUrl + `/employee_dropdown`} 
                        allDataRef={employeesData}
                        mapFunction={mapEmployeesData}
                    />
                </div>
                <div className='col-xs-12 col-sm-4'>
                    <DropDownInput
                        label={Helper.getLabel(language, 'requester', 'Requester')}
                        name="requester_id"
                        onChange={onChangeHandler}
                        id="requester_id"
                        placeHolder={`${Helper.getLabel(language, 'select_requester', 'Select requester')}`}
                        options={ data?.requester_id
                            ? [{
                                value: data.requester_id,
                                label: data.requester_name ?? employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(data.requester_id))?.displayname,
                              }]
                            : props.requester}
                        reRenderRequired={true}
                        value={data?.requester_id ? data?.requester_id : ''}
                        disable={isDisable()}
                        pagination={true} 
                        apiUrl={Settings?.apiOrgUrl + `/employee_dropdown`} 
                        allDataRef={employeesData}
                        mapFunction={mapEmployeesData}
                    />
                </div>
                {/* here we add 3 new field, Requirement Description, Scope of Work and Material Service not Included */}
                {(props.source_id === 10 || props.source_id === 63) && (
                    <>
                        <div className='col-xs-12 col-sm-4'>
                            <Input
                                label={`${Helper.getLabel(language, "requirement_description", "Requirement description")}`}
                                name="requirement_description"
                                onChange={onChangeHandler}
                                value={data?.requirement_description}
                                disable={isDisable()}
                            />
                        </div>
                        <div className='col-xs-12 col-sm-4'>
                            <Input
                                label={`${Helper.getLabel(language, "scope_of_work", "Scope of work")}`}
                                name="scope_of_work"
                                onChange={onChangeHandler}
                                value={data?.scope_of_work}
                                disable={isDisable()}
                            />
                        </div>
                        <div className='col-xs-12 col-sm-4'>
                            <Input
                                label={`${Helper.getLabel(language, "material_service_not_included", "Material service not included")}`}
                                name="material_service_not_included"
                                onChange={onChangeHandler}
                                value={data?.material_service_not_included}
                                disable={isDisable()}
                            />
                        </div>
                    </>
                )}
                {displayAmentmentReference()}
            </div>
        </Collapse>
    );
};

export default GeneralLineDetails;
