import React, { useState, useEffect, useCallback } from 'react'
import Helper from '../../../inc/Helper'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ButtonNew from '../../inc/ButtonNew'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import RsWithRouter from '../../inc/RsWithRouter'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { getStatusNameById, gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { useNavigate } from 'react-router-dom'

let gridObj = null

const ReturnOrders = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatingSh, setIsCreatingSh] = useState(false)
  const [allReturnOrders, setAllReturnOrders] = useState([])
  const [shDocumentStatus, setShDocumentStatus] = useState([])
  const navigate = useNavigate()
  const { poid } = props.rs_router.params
  const lng = null

  const onGridReady = (params) => {
    gridObj = params
  }

  const loadShDocumentStatus = useCallback(() => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/enum/SH_DocumentStatus')
      .then((res) => {
        setShDocumentStatus(res.data.data)
      })
  }, [])

  const loadPoReturnOrders = useCallback(() => {
    if (!poid) return
    let api = Api
    api.setUserToken()
    setIsLoading(true)
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + '/get_sh_header_PO/' + poid + '/' + Settings.shType.returnOrder)
      .then((res) => {
        setAllReturnOrders(res.data.data)
        setIsLoading(false)
      })
  }, [poid])

  useEffect(() => {
    loadPoReturnOrders()
    loadShDocumentStatus()
  }, [])

  const oncellClickHandler = (event) => {
    if (event?.colDef?.field === 'receiving_reference_number') {
      const href = (props.editUrl ? `${props.editUrl}/` : '/return-order/edit/') + event.data.shipment_id
      navigate(href)
    }
    if (event?.colDef?.field === 'document_number') {
      const href = (props.backUrl ? `${props.backUrl}/` : '/purchase-order/edit/') + event.data.purchaseorder_id
      navigate(href)
    }
  }

  const createShFromPoId = () => {
    if (!poid) return
    let api = Api
    setIsCreatingSh(true)
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/PO_header/${poid}`)
      .then(function (res) {
        let newData = {
          ...res.data.data,
          accounting_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(new Date())),
          document_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(new Date())),
        }
        api
          .axios()
          .post(Settings.apiPurchaseOrderUrl + '/sh_headers/' + Settings.shType.returnOrder, newData)
          .then((resSh) => {
            Helper.alert(resSh.data.message)
            props.rs_router.navigate('/return-order/edit/' + resSh.data.shipment_id)
            setIsCreatingSh(false)
          })
          .catch((error) => {
            getPopupMessageBasedOnStatus(error)
            setIsCreatingSh(false)
          })
      })
  }

  const deleteHanlder = useCallback(async (element) => {
    let api = Api
    api.setUserToken()

    let delete_shipment_id = element?.data?.shipment_id

    try {
      const res = await api.axios().delete(`${Settings.apiPurchaseOrderUrl}/sh_header/${delete_shipment_id}`)
      const rowNode = gridObj?.api?.getRowNode(delete_shipment_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api?.deselectAll()
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const gridHeaders = [
    {
      field: 'receiving_reference_number',
      headerName: Helper.getLabel(lng, 'return_order_number', 'Return order number'),
      minWidth: '220',
      cellRenderer: (params) => {
        return gridLinkValueFormat(params)
      },
    },
    { field: 'description', headerName: Helper.getLabel(lng, 'description', 'Description'), minWidth: '260' },
    {
      field: 'document_number',
      headerName: Helper.getLabel(lng, 'purchase_order_number', 'Purchase order number'),
      cellRenderer: (params) => {
        return gridLinkValueFormat(params)
      },
      minWidth: '220',
    },
    { field: 'vendor_code', headerName: Helper.getLabel(lng, 'vendor_code', 'Vendor code'), minWidth: '160' },
    { field: 'vendor_name', headerName: Helper.getLabel(lng, 'vendor_name', 'Vendor name'), minWidth: '180' },
    { field: 'vendorsite_name', headerName: Helper.getLabel(lng, 'vendor_site', 'Vendor site'), minWidth: '180' },
    {
      field: 'accounting_date',
      headerName: Helper.getLabel(lng, 'accounting_date', 'Accounting date'),
      minWidth: '200',
      valueFormatter: (params) => {
        return DatepickerFunctions.convertDateFromDataBase(params.value)
      },
    },
    {
      field: 'document_date',
      headerName: Helper.getLabel(lng, 'document_date', 'Document date'),
      minWidth: '200',
      valueFormatter: (params) => {
        return DatepickerFunctions.convertDateFromDataBase(params.value)
      },
    },
    {
      field: 'document_status',
      headerName: Helper.getLabel(lng, 'status', 'Status'),
      minWidth: '180',
      cellRenderer: (params) => {
        return getStatusNameById(params.value, shDocumentStatus)
      },
    },
  ]

  return (
    <div className='page_return_order'>
      <div className='container-fluid'>
        <NavigationHeder backUrl={props.backUrl ? `${props.backUrl}/${poid}` : null} title={props.title || Helper.getLabel(lng, 'return_order', 'Return Orders')} hideMoreBtn={true}>
          {props?.rs_router?.location?.pathname?.includes('/purchase-agreement/return-order/all') ? null : (
            <ButtonNew id='create_note' isDisable={isCreatingSh} onClick={createShFromPoId} isActive={true} title={Helper.getLabel(lng, 'new', 'New')} />
          )}
        </NavigationHeder>
        {isLoading || isCreatingSh ? <OverlayLoader isLogoCenter={true} /> : ''}
        <AgGridNew
          gridId={'return_order_list'}
          uniqueField={'shipment_id'}
          handleDeleteSelectedRows={deleteHanlder}
          onGridReady={onGridReady}
          height={500}
          apiUrl={props?.rs_router?.location?.pathname?.includes('/purchase-agreement/return-order/all')?Settings.apiPurchaseOrderUrl+`/get_receivings`:Settings.apiPurchaseOrderUrl + '/get_sh_header_PO/' + poid + '/' + Settings.shType.returnOrder}
          additionalParams={props?.rs_router?.location?.pathname?.includes('/purchase-agreement/return-order/all') && `&type=2&agreement_id=${poid}`}
          columnDefs={gridHeaders}
          returnApiCondition={!poid}
          hideAddBtn={true}
          pagination={true}
          onCellClicked={oncellClickHandler}
        />
        {/* <AgGrid id="good_receiving_list" onDelete={deleteHandler} header={gridHeaders} data={allReturnOrders} viewOnly={true} hideSave={true} addBtnHide={true} /> */}
      </div>
    </div>
  )
}
export default MasterComponentWraper(RsWithRouter(ReturnOrders))
