import Helper from '../../../../inc/Helper';
import MasterRenderer from './MasterRenderer';

class CurrencyRenderer extends MasterRenderer {
    init(params) {
        this.eGui = document.createElement('div');
        this.addClass(this.eGui,'rs_ag_currency_renderer')
        this.eGui.innerHTML = this.getValueToDisplay(params);
    }
    getGui() {
        return this.eGui;
    }
    getValueToDisplay(params) {
       return params.valueFormatted ? params.valueFormatted : params?.value !== null && params?.value !== undefined ? params.value: params?.value?.includes("*") && !params?.data?.line_id ? params.value :  Helper.formateCurrency( params.value);
    }
}

export default CurrencyRenderer;