import React from 'react'
import { connect } from "react-redux"
import Helper from '../../../../inc/Helper'
import LineViewComponent from "../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Settings from '../../../../inc/Settings';


let gridObj = null
const AlternateLines = (props) => {  
  const handleVersionAlternateLines = (obj) => {
    gridObj = obj
  }
  return (
    <div className="mt-5 external-read-only-grid">
         <LineViewComponent
            defaultValues={{ requester_id: null }}
            source_id={Settings?.source_id?.RfqVendorReply}
            viewType="alternateline version"
            line_source={Settings?.source_id?.RfqVendorReply}
            isEditable={false}
            header_id={props.header_id}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            formType='rfq'
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={false}
            hideGeneral={false}
            hideSetup={false}
            hideBillingRule={false}
            alternate_line={true}
            lineGridTitle={Helper.getLabel(props.language, 'alternate_lines', 'Alternate Lines')}
            onLineGridReady={handleVersionAlternateLines}
            returnApiCondition={props.version === null || !props.header_id}
            version={props.version}
            hideAddBtn={true}
            hideDeleteBtn={true}
            hideMoreBtns={true}
         />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(AlternateLines)
