import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import DeliveryTab from './DeliveryTab'

const HeaderViewDelivery = (props) => {
  return (
    <Collapse className='mb-5 delivery' open={true} title={Helper.getLabel(props.language,'delivery','Delivery')}> 
      <div className='container-fluid delivery_tabs'>
        <DeliveryTab isEditable={props.isEditable} setState={props.setState} state={props.state} type={props.type} reRenderRequired={props.reRenderRequired}/>
      </div> 
    </Collapse> 
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (HeaderViewDelivery) 