import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import MasterComonent from '../../components/Backend/MasterComonent';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import axios from 'axios';
import Helper from '../../inc/Helper';
import Button from '../../components/inc/Button';
import CrudExample from './CrudExample'; 
import CrudServerSide from './CrudServerSide';

const RFQTstScreen = (props) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  // const [apiResponse, setApiResponse] = useState(null);
  // const [loading, setLoading] = useState(false);
  // let cancelTokenSource = axios.CancelToken.source();

  // // Debounce function with promise and timeout
  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   let resolvePromise;

  //   return (...args) => {
  //     clearTimeout(timeoutId);

  //     return new Promise((resolve, reject) => {
  //       resolvePromise = resolve;

  //       timeoutId = setTimeout(() => {
  //         cancelAPIRequest();
  //         reject(new Error('API call timed out'));
  //       }, delay);

  //       Promise.race([func(...args), timeoutPromise(delay)])
  //         .then((result) => {
  //           clearTimeout(timeoutId);
  //           resolvePromise(result);
  //         })
  //         .catch((error) => {
  //           clearTimeout(timeoutId);
  //           reject(error);
  //         });
  //     });
  //   };
  // };

  // const timeoutPromise = (delay) =>
  //   new Promise((_, reject) => setTimeout(() => reject(new Error('API call timed out')), delay));

  // const cancelAPIRequest = () => {
  //   cancelTokenSource.cancel('API request cancelled due to time exceeded!');
  //   cancelTokenSource = axios.CancelToken.source();
  //   cancelTokenSource.token.reason = null;
  // };

  const apiCall = () => {
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiOrgUrl + `/organization`, {}).then(function (res) {
      if (res.data.status === "success") {
        Helper.alert(res.data.message);
      }
    }).catch((res) => {
      console.log(res);
    });
  };

  const apiCall2 = async () => {
    try {  
      let api = Api;
      api.setUserToken();

      const res = await api.axios().get(Settings.apiVendorUrl + '/vendor', {});
      if (res.data.status === 'success') {
        console.log(res.data.data);
      }  
    } catch (error) {
      Helper.alert(error, 'error');
    }  
  };
  
  const apiCall3 = async () => {
    try {  
      let api = Api;
      api.setUserToken();

      const res = await api.axios().get(Settings.apiVendorUrl + '/vendorreason', {});
      if (res.data.status === 'success') {
        console.log(res.data.data);
      }  
    } catch (error) {
      console.log(error)
    }  
  };

  // const debouncedApiCall = useCallback(debounce(apiCall, 1000), []);

  // const handleButtonClick = async () => {
  //   if (!loading) {
  //     try {
  //       await debouncedApiCall();
  //     } catch (error) {
  //       console.error('API call error:', error);
  //     }
  //   }
  // };

  const handleButtonClick = () => {
    Helper.createDebouncedAPIFunction([apiCall2], setLoadingBtn)(); 
  }

  return (
    <MasterComonent>
   
      {/* <Button isActive= {true} isDisable={loadingBtn} title='Save' onClick={handleButtonClick} icon={'/images/icons/save-white.svg'} /> */}
      {/* <button disabled={loadingBtn} onClick={handleButtonClick}>Execute API</button> */}

      <CrudExample />
      <CrudServerSide /> 
    
    </MasterComonent>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(RFQTstScreen); 