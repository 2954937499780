import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import ButtonNew from '../../inc/ButtonNew';
import Collapse from '../../inc/Collapse'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { useNavigate } from 'react-router-dom';
import TaxCodesModal from './TaxCodesModal';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';

let gridApi = null;

const Taxcodes = ({security}) => { 
    const [showCreateModal, setShowCreateModal] = useState(false);
    
    const language = useSelector(state => state.language); 
    const navigate = useNavigate();
  
    const onCellClicked = (event) => {
        if (event.colDef.field === 'code') {
            navigate('/tax/edit-taxcode/' + event?.data?.taxcodes_id);
        }
    };

    const deleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delTaxCode_ID = element?.data?.taxcodes_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/taxcodes/${delTaxCode_ID}`)
            const rowNode = gridApi?.getRowNode(delTaxCode_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200);

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    /* Grid Column Headers */

    const gridColumnHeaders = [
        { field: 'code', filter: 'agTextColumnFilter', cellRenderer: (params) => gridLinkValueFormat(params), headerName: Helper.getLabel(language, "code", 'Code') },
        { field: 'name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, "name", 'Name') }
    ];

    
    if(!security.canView(Gui_id_list.setupForms.tax_codes.tax_codes_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment>
            <div className='tax_codes_page'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'tax_codes', 'Tax Codes')}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.tax_codes.tax_codes_main_create_button)} onClick={() => setShowCreateModal(true)} />
                </NavigationHeder>

                <Collapse open={true} title={Helper.getLabel(language, 'tax_codes', 'Tax Codes')}> 
                    <AgGridNew
                        apiUrl={Settings.apiUrl + '/taxcodes'}  
                        pagination={false}
                        hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.tax_codes.tax_codes_main_delete_button)}
                        columnDefs={gridColumnHeaders} 
                        hideAddBtn={true}
                        height={500}
                        uniqueField={'taxcodes_id'}
                        onCellClicked={onCellClicked}
                        handleDeleteSelectedRows={deleteHandler}
                        onGridReady={(params) => gridApi = params?.api} 
                        gridId={"tax_codes_grid"}
                    />
                </Collapse>
            </div>
            {showCreateModal && <TaxCodesModal setShowCreateModal={setShowCreateModal} />}
        </Fragment>
    );
};
  
const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.tax_codes.tax_codes_main
};

export default ((( ApplySecurityRoles( Taxcodes, SecurityOptions)))); 