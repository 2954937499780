import React, { useState, useEffect, useRef, Fragment } from 'react'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { TextSkeletonComponent } from '../../Skeleton/SkeletonComponent'
import Button from '../../inc/Button'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import SimpleLoading from '../../Loading/SimpleLoading'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import Settings from '../../../inc/Settings'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import LineViewComponent from '../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { useParams } from 'react-router-dom'
import { Budget_Data } from './BudgetData'
import Input from '../../Forms/Input'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import Dropdown from '../../Forms/Dropdown'
import Api from '../../../inc/Api'

const budgetEntryStatuses = [
  { id: 1, name: 'Draft' },
  { id: 2, name: 'Re-call' },
  { id: 3, name: 'In-review' },
  { id: 4, name: 'Approved' },
]

const BudgetEntryEdit = (props) => {
  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [scrollDitection, setScrollDitection] = useState('down')
  const [height, setHeight] = useState(0)

  const [loading, setLoading] = useState(false)
  const [budgetEntryData, setBudgetEntryData] = useState({})
  const [departments,setDepartments]=useState([])

  const myDivRef = useRef(null)
  let sectionName = 'budget-entry-edit'

  const { id } = useParams()

  const fetchBudgetData = () => {
    const budgetEntry = Budget_Data.find((entry) => entry.id === parseInt(id))
    if (budgetEntry) {
      setBudgetEntryData(budgetEntry)
    }
  }

  const fetchDepartments = () => {
    let api = Api
    api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
        let departmentData = [];
        if(res.data.status === 'success'){
            let responseData = res.data.data;
            departmentData = responseData.filter( item => { 
                return item.isactive===true 
            }).map((item)=>{
                return {
                    value:item.segmentvalue_id,
                    label:item.name
                }
            })
            setDepartments(departmentData)
        }
    }).catch(error =>{
    })
  }

  useEffect(() => {
    fetchBudgetData()
    fetchDepartments()
  }, [])

  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, 'document_number', 'Document Number'),
        subtitle: !budgetEntryData ? <TextSkeletonComponent /> : budgetEntryData?.document_number ?? budgetEntryData?.RFQ_vendorreply_id,
      },
      {
        title: Helper.getLabel(props.language, 'document_type', 'Document Type'),
        subtitle: !budgetEntryData ? <TextSkeletonComponent /> : 'Budget Entry' || '-',
      },
      {
        title: Helper.getLabel(props.language, 'title', 'Title'),
        subtitle: !budgetEntryData ? <TextSkeletonComponent /> : budgetEntryData?.title?.length > 30 ? budgetEntryData?.title?.substring(0, 30) + '...' || '-' : budgetEntryData?.title || '-',
      },
      {
        title: Helper.getLabel(props.language, 'submitter', 'Submitter'),
        subtitle: !budgetEntryData ? <TextSkeletonComponent /> : budgetEntryData?.owner ?? '-',
      },
    ]

    let budgetEntryDocumentStatus = budgetEntryStatuses?.find((item) => item.id === budgetEntryData?.status_id)

    let tagTitle = budgetEntryDocumentStatus?.name || '-'
    let tags = [{ title: tagTitle.replace(/_/g, ' ') || null }]

    return (
      <div className={`rfq_detail_form`}>
        <div className='rfq-internal-blue'>
          <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
        </div>
      </div>
    )
  }

  const handleOnChange = (e) => {
    let value = e.target.value
    setBudgetEntryData((prev) => {
      const updatedBudgetEntryHeader = { ...prev, [e.target.name]: value }
      return updatedBudgetEntryHeader
    })
  }

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef, setHeight, window)
  }, [myDivRef.current?.clientHeight, scrollDitection])

  let navs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      data_section: 'one',
      active_status: 'active_tab',
    },
  ]

  let superNav = [{ title: Helper.getLabel(props.language, 'header_view', 'Header View') }, { title: Helper.getLabel(props.language, 'line_view', 'Line View') }]

  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <NoteAttachmentHistory scrollDitection={scrollDitection} height={height} hideHistory={true} source_id={90} notesource_integrator={id} attachmentsource_integrator={id} hideAttachments={false} hideVendorPortal={true} />
      </MasterSidebar>
    )
  }

  return (
    <div className='scrollBarBlinkControl'>
      <div className={`rfq-internal-screens`}>
        <div ref={myDivRef}>
          <NavigationHeder removeMargin={true} title={'Edit Budget Entry'} backUrl={-1} hideMoreBtn={true}>
            <Button isActive={false} className='rfq_vendor_reply_publish_btn' title={Helper.getLabel(props.language, 'submit', 'Submit')} isDisable={false} />
            <TotalWidget source_id={Settings.source_id?.RfqVendorReply} integrator={id} />
          </NavigationHeder>

          <FixedNavigationHeder
            addComponentTop={addComponentTop}
            sectionName={sectionName}
            navs={navs}
            scrollDitection={scrollDitection}
            selectedSupNav={selectedSupNav}
            setSelectedSupNav={setSelectedSupNav}
            superNav={superNav}
          />
        </div>
        {loading ? (
          <OverlayLoader />
        ) : (
          <TabContentWraper
            height={height}
            setScrollDitection={setScrollDitection}
            leftSideContent={
              <>
                {selectedSupNav == 'Header View' && (
                  <>
                    <div className='lists_contant one' id={`${sectionName}General`} style={{ position: 'relative' }}>
                      <Collapse title={Helper.getLabel(props.language, 'general_information', 'General Information')} open={true} className='mb-5'>
                        <div className='generalInformation-basic'>
                          <div className='generalInformation-basic-grid'>
                            <Input
                              label={`${Helper.getLabel(props.language, 'document_number', 'Document number')}`}
                              name='document_number'
                              value={budgetEntryData?.document_number}
                              onChange={handleOnChange}
                              disable={true}
                            />
                            <Input label={`${Helper.getLabel(props.language, 'title', 'Title')}`} name='title' value={budgetEntryData?.title} onChange={handleOnChange} disable={budgetEntryData?.status_id !== 1} />
                            <Input
                              label={`${Helper.getLabel(props.language, 'description', 'Description')}`}
                              name='description'
                              value={budgetEntryData?.description}
                              onChange={handleOnChange}
                              disable={budgetEntryData?.status_id !== 1}
                            />
                            <NewInputDatePicker
                              label={`${Helper.getLabel(props.language, 'initiate_date', 'Initiate date')}`}
                              id='initiate_date'
                              name='initiate_date'
                              onChange={(date) => {
                                setBudgetEntryData({
                                  ...budgetEntryData,
                                  initiate_date: date,
                                })
                              }}
                              value={budgetEntryData.initiate_date}
                              disable={true}
                            />
                            <Dropdown
                              label={`${Helper.getLabel(props.language, 'department', 'Department')}`}
                              options={departments}
                              placeHolder={`${Helper.getLabel(props.language, 'select_department', 'Select department')}`}
                              id='budget_entry_department'
                              name='segmentvalue_id'
                              onChange={handleOnChange}
                              value={budgetEntryData?.segmentvalue_id}
                              isDisable={budgetEntryData?.status_id !== 1}
                            />
                            <Input
                              label={`${Helper.getLabel(props.language, 'project', 'Project')}`}
                              name='project'
                              value={budgetEntryData?.project}
                              onChange={handleOnChange}
                              disable={budgetEntryData?.status_id !== 1}
                            />
                            <Input label={`${Helper.getLabel(props.language, 'owner', 'Owner')}`} name='owner' value={budgetEntryData?.owner} onChange={handleOnChange} disable={budgetEntryData?.status_id !== 1} />
                            <Input
                              label={`${Helper.getLabel(props.language, 'total_budget_estimate', 'Total budget estimate')}`}
                              name='total_budget_estimated'
                              value={budgetEntryData?.total_budget_estimated}
                              onChange={handleOnChange}
                              disable={budgetEntryData?.status_id !== 1}
                            />
                            <Input
                              label={`${Helper.getLabel(props.language, 'key_objectives', 'Key Objectives')}`}
                              name='key_objectives'
                              value={budgetEntryData?.key_objectives}
                              onChange={handleOnChange}
                              disable={budgetEntryData?.status_id !== 1}
                            />
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </>
                )}
                {selectedSupNav === 'Line View' && (
                  <>
                    {loading ? (
                      <SimpleLoading />
                    ) : (
                      <div className='rfq-line-view line_scoring_module mb-4'>
                        <LineViewComponent
                          defaultValues={{
                            requester_id: budgetEntryData?.requester_id,
                          }}
                          lineGridId='rfq-internal-lines'
                          source_id={null}
                          apiUrl={null}
                          data={[]}
                          line_source={null}
                          isEditable={false}
                          lineGridTitle={Helper.getLabel(props.language, 'budget_entry_lines', 'Budget Entry Lines')}
                          header_id={null}
                          purchase_status={null}
                          document_status={null}
                          delivery_date={null}
                          onbehalf_request={false}
                          vendor_id={null}
                          vendorsite_id={null}
                          requester_id={budgetEntryData?.requester_id}
                          orderer_id={budgetEntryData?.order_id}
                          setOrderStatusProps={null}
                          warehouse_id={null}
                          taxgroup_item={null}
                          taxgroup_id={null}
                          rfqExternal={true}
                          hideBillingRule={true}
                          billingrulegrid_id='normal'
                          distributionGridId='distribution-normal'
                          alternate_line={false}
                          disableAllBtn={true}
                          // onLineGridReady={onGridReady}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            }
            rightSideContent={addComponentRight}
          />
        )}
      </div>
    </div>
  )
}

export default MasterComponentWraper(BudgetEntryEdit)
