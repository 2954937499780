import React, { Component } from 'react';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Treeview from '../Treeview/Treeview';
class MasterCategoryList extends Component {
    constructor(props){
        super(props);
        this.isMasterCategoryLoaded = false;
        this.state= {
            isLoading:false,
            masterCategoryList:[]
        }
    }
    componentDidMount(){
        this.loadMasterCategory();
        Helper.makeSticky('master_category_list_child', {topSpace:150,containerId:'lynkaz_tab_contents',zIndex:10,upateWithClick:'.rs_treeview_controller'})
    }
    loadMasterCategory(isForseLoad = false){
        if(this.isMasterCategoryLoaded && !isForseLoad){
            return;
        }
        this.isMasterCategoryLoaded = true;
        let api = Api;
        let that = this;
        that.setState({
            isLoading:true
        })
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/mastercategory').then(function(res){
            that.setSetTreeDatea(res?.data?.data);
            that.setState({
                isLoading:false
            })
        })
    }
    getCategoriChildres(categories,masterCategory){
        let output = [];
        categories.forEach(categoryItem => {
            let newItem = {
                title:categoryItem.name,
                category:categoryItem,
                isMaster:false,
                masterCategory:masterCategory,
                items:[]
            }
            if(categoryItem.sub_categories && Array.isArray(categoryItem?.sub_categories)){
                newItem.items = this.getCategoriChildres(categoryItem?.sub_categories,masterCategory);
            }
            output.push(newItem);
        });
        return output;
    }
    setSetTreeDatea(data){
        let items = [];
        data.forEach(dataItem => {
            items.push({
                title:dataItem.name,
                showChild:false,
                isMaster:true,
                category:null,
                masterCategory:dataItem,
                items: dataItem.has_childern ? [{}] : []
                //items: this.getCategoriChildres(dataItem.categories,dataItem)
            },)
        });
        this.setState({
            masterCategoryList:items
        })
    }
    onItemClick(event){
        if(this.props.onItemClick && typeof this.props.onItemClick === 'function'){
            this.props.onItemClick(event)
        }
        Helper.refreshAppScript();
    }
    genMasterCatDetailsFromCategory(category){
        return {
            hierarchy_id:category.hierarchy_id
        }
    }
    onIconClickHandler(item,options){
        if(!options.isExpanded){
            return;
        }
        let that = this;
        
        let api = Api;
        api.setUserToken();
        if(item.isMaster){
            if(!item.masterCategory.has_childern){
                options.setData([])
                return;
            }
            api.axios().get(Settings.apiUrl + '/get_childern_mastercategory/'+item.masterCategory.hierarchy_id).then(resp => {
                let items = [];
                resp.data.data.forEach(dataItem => {
                    items.push({
                        title:dataItem.name,
                        showChild:false,
                        isMaster:false,
                        category:dataItem,
                        masterCategory:that.genMasterCatDetailsFromCategory(dataItem),
                        items: dataItem.has_childern ? [{}] : []
                        //items: this.getCategoriChildres(dataItem.categories,dataItem)
                    },)
                });
                options.setData(items)
            })
        }else{
            if(!item.category.has_childern){
                options.setData([])
                return;
            }
            api.axios().get(Settings.apiUrl + '/subcategoryhierarchy/'+item.category.category_id).then(resp => {
                let items = [];
                
                resp.data.data.forEach(dataItem => {
                    items.push({
                        title:dataItem.name,
                        showChild:false,
                        isMaster:false,
                        category:dataItem,
                        masterCategory:that.genMasterCatDetailsFromCategory(dataItem),
                        items: dataItem.has_childern ? [{}] : []
                        //items: this.getCategoriChildres(dataItem.categories,dataItem)
                    },)
                });
                options.setData(items)
            })
        }
    }
    render() {
        
        return (
            <div className='master_category_list'>
                <div className='master_category_list_child' id="master_category_list_child">
                    <Treeview isAjax={true} items = {this.state.masterCategoryList} onClick = { e => this.onItemClick(e)} onIconClick = {this.onIconClickHandler.bind(this)} />
                </div>
            </div>
        );
    }
}

export default MasterCategoryList;