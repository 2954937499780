import Constant_GUI from "./GuiConstants";

const LabelsGUI_ID = {
    tenant:{
        id:`11`,
        code:`12`,
        name:`13`,
        status:`14`,
        modifiedby:`15`,
        start_date:`16`,
        end_date:`17`,
        created_by:`18`,
    },
    sidepanel:{
        command_center:'201',
        request:'202',
        source:'203',
        receive:'204',
        master_data:'205',
        finance:'206',
        budget:'207',
        approval:'208',
        reports:'209',
        setup:'2010',
        administration:'2011',
        purchase_order:'2012', 
        invoice:'2013', 
        vendor_profile:'2014', 
        request_for_quotation:'2015', 
        request_for_information:'2016', 
        contract:'2017', 
        purchase_agreement:'2018', 
        release_order:'2019', 
        goods_receiving:'2020', 
        work_confirmation:'2021', 
        workflow:'2022', 
        questionnaire:'2023',
        auction:'2024'
    }
}
export default LabelsGUI_ID;
