import Helper from "../../../../inc/Helper";
import DistributionSettings from "../../../../inc/PropsSettings/DistributionSettings";
import Settings from "../../../../inc/Settings";

class SettingsPoDistribution extends DistributionSettings{
    constructor(defaultValues = {}){
        super();
        this.defaultValues =defaultValues;
        this.setApiUrl(
            Settings.apiPurchaseOrderUrl + '/linesite',
            Settings.apiPurchaseOrderUrl + '/linesite',
            Settings.apiPurchaseOrderUrl + '/linesite',
            Settings.apiPurchaseOrderUrl+'/linesites'
        )
        this.setIdKey('linesite_id');
    }
    filterPostData(data){
        let modifiedData =  this.dataForDb(data);
        if(this.defaultValues.requester_id){
            modifiedData.requester_id = this.defaultValues.requester_id;
        }
        return modifiedData;
    }
    
    filterPutData(data){
        let filtredData= this.dataForDb(data)
        return filtredData
    }


    dataForDb(data){
        return {
            linestatus:data?.line_status ?? 1,
            line_status:data?.line_status ?? 1,
            ordered_qty: Helper.getOnlyNumber(data.ordered_qty),
            returned_qty: Helper.getOnlyNumber(data.returned_qty),
            received_qty: Helper.getOnlyNumber(data.received_qty),
            canceled_qty: Helper.getOnlyNumber(data.canceled_qty),
            accepted_qty: Helper.getOnlyNumber(data.accepted_qty),
            defected_qty: Helper.getOnlyNumber(data.defected_qty),
            under_delivery_tolerance:Helper.getNullableValue(data.under_delivery_tolerance) == true ? 1 : 0,
            over_delivery_tolerance:Helper.getNullableValue(data.over_delivery_tolearance) == true ? 1 : 0,
            delivery_date: Helper.getDateForDb(data.delivery_date),
            start_date:Helper.getDateForDb(data.start_date),
            end_date:Helper.getDateForDb(data.end_date),
            distributionsource_id:Helper.getNullableValue(data.distributionsource_id),
            external_reference:Helper.getNullableValue(data.external_reference),
            requisition_id:Helper.getNullableValue(data.requisition_id),
            requester_id:Helper.getNullableValue(data.requester_id),
            entity_id:Helper.getNullableValue(data.entity_id),
            site_id:Helper.getNullableValue(data.site_id),
            warehouse_id:Helper.getNullableValue(data.warehouse_id),
            overreceipt_action:Helper.getNullableValue(data.overreceipt_action) == true ? 1 : 0
        }
    }


}
export default SettingsPoDistribution;