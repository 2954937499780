import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../inc/Helper';
import Collapse from '../inc/Collapse';
import LinkBtn from '../inc/LinkBtn';
import RsWithRouter from '../inc/RsWithRouter';
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder';
import BlueCallToAction from '../Widgets/BlueCallToAction/BlueCallToAction';
import Tabs from '../Widgets/Tabs/Tabs';
import Button from '../inc/Button';
import AgGrid from '../grid/ag/ag-grid';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import $ from 'jquery';
import SegmentGroupRange from './popups/SegmentGroupRange';
import FormValidator from '../../inc/FormValidator';
import AgGridMemo from '../grid/ag/AgGridMemo';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
class RollupGroup extends Component {
    constructor(props){
        super(props);
        this.isGroupLoaded = false;
        this.gridObj = null;
        this.forceRender = 1;
        this.saveButtonTimeout = null;
        this.validationConfigure();
        this.state = {
            showRangePopup:false,
            isLoading:false,
            selectedGroup:{},
            segment:{},
            parents:[],
            gridData:[]
        }
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = []
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        
        this.loadSegment();

    }
    loadSegment(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let segment_id = this.props.rs_router.params.id
        api.axios().get(Settings.apiUrls.segments + '/segment/'+segment_id).then(res=> {
            that.forceRender = that.forceRender + 1;
            that.setState(
                {
                    isLoading:false,
                    segment:res.data.data
                },
                function(){
                    that.loadParent()
                    that.loadSegmentGroups()
                }
            )
        })
    }
    loadParent(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment_parent').then(res=> {
            
            let segmentParents = [];
            let segment_id = that.state.segment.segment_id;
            if(res.data.status=='success'){
                res.data.data.forEach(parentItem => {
                    if(segment_id == parentItem.segment_id){
                        segmentParents.push(parentItem);
                    }
                });
            }
            that.forceRender = that.forceRender + 1;
            that.setState({
                parents:segmentParents,
                isLoading:false
            })
        })
    }
    loadSegmentGroups(){
        
        this.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiUrls.segments + '/segment_group').then(res => {
            let segmentGroup = res.data.data.filter( item => {
                return item.segment_id === that.state.segment.segment_id ? item : false;
            })
            that.forceRender = that.forceRender + 1;
            that.setState({
                gridData:segmentGroup,
                isLoading:false
            })
        }).catch(error => {
            that.forceRender = that.forceRender + 1;
            that.setState({
                isLoading:false
            })
        })
    }
    onSaveHandler(e){
        clearTimeout(this.saveButtonTimeout); // clear before assign new value inside the timmer
        this.saveButtonTimeout = setTimeout(function(){ // asign timeout
            $('.segment_group_page .rs_grid_btn_save').trigger('click');
        },100)
    }
    setRangeOnClick(e){
        if(!this.gridObj){
            return;
        }
        const language = this.props.language;
        let selectedItems = this.gridObj.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert(Helper.getLabel(language,'"msg_select_segment"','"Please select a group"'),'error')
            return;
        }
        this.setState({
            selectedGroup:selectedItems[0],
            showRangePopup:true
        })
    }
    
    onGridSave(items){
        if(!this.validator.isValid({},[this.gridObj])){
            this.validator.displayMessage(this);
            return;
        }
        let newItems = [];
        let oldItems = [];
        items.forEach( item => {
            if(item.segmentvaluegroup_id){
                oldItems.push(item);
            }else{
                newItems.push(item)
            }
        })
        this.createNewGroup(newItems)
        this.updateSegmentValueGroup(oldItems)
    }
    createNewGroup(items){
        let api = Api;
        api.setUserToken();
        let totalItems = items.length;
        let segment_id = this.state.segment.segment_id;
        let that = this;
        items.forEach(item => {
            let newData = {
                segment_id:segment_id,
                segmentvalueparent_id: item.segmentvalueparent_id,
                code: item.code,
                name:item.name,
                description:item.description,
                translationsource_id:null
            }
            api.axios().post(Settings.apiUrls.segments + '/segment_group',newData).then(res=>{
                totalItems--;
                if(totalItems<=0){
                    that.setState({
                        isLoading:false
                    })
                    that.loadSegmentGroups();
                    Helper.alert(res.data.message);
                }
            }).catch(res => {
                totalItems--;
                if(totalItems<=0){
                    that.setState({
                        isLoading:false
                    })
                    that.loadSegmentGroups();
                    getPopupMessageBasedOnStatus(res);
                }
            })
        })
    }
    updateSegmentValueGroup(items){
        let api = Api;
        api.setUserToken();
        let totalItems = items.length;
        let segment_id = this.state.segment.segment_id;
        let that = this;
        items.forEach(item => {
            let newData = {
                segment_id:segment_id,
                segmentvalueparent_id: item.segmentvalueparent_id,
                code: item.code,
                name:item.name,
                description:item.description,
                translationsource_id:null
            }
            api.axios().put(Settings.apiUrls.segments + '/segment_group/'+item.segmentvaluegroup_id,newData).then(res=>{
                totalItems--;
                if(totalItems<=0){
                    that.setState({
                        isLoading:false
                    })
                    that.loadSegmentGroups();
                    Helper.alert(res.data.message);
                }
            }).catch(res => {
                totalItems--;
                if(totalItems<=0){
                    that.setState({
                        isLoading:false
                    })
                    that.loadSegmentGroups();
                    getPopupMessageBasedOnStatus(res);
                }
            })
        })
    }
    addComponentTop(){
        let language = this.props.language;
        let segment = this.state.segment;
        let items = [
            {title:Helper.getLabel(language,'segment_name','Segment name'),subtitle:segment.name},
            {title:Helper.getLabel(language,'segment_display_name','Segment display name'),subtitle:segment.display_name}
        ];
        return(
            <div className='rfq-internal-blue'>
              <BlueCallToAction items={items}/>
            </div>
        )
    }
    onGridReady(grid){
        this.gridObj = grid;
    }
    onSelectionChanged(event){
        let selectedRows = event.api.getSelectedRows();
        if(selectedRows.length <=0){
            $('.segment_group_page .visible_on_select_item').addClass('disable');
        }else{
            $('.segment_group_page .visible_on_select_item').removeClass('disable');
        }
    }
    onPopupClose(e){
        this.setState({
            showRangePopup:false
        })
    }
     onDeleteHandler(data){
        let api = Api;
        let that = this;
        api.setUserToken();
        data.forEach(dataItem => {
            if(dataItem.segmentvaluegroup_id){
                api.axios().delete(Settings.apiUrls.segments+'/segment_group/' +dataItem.segmentvaluegroup_id).then(res => {
                    Helper.alert(res.data.message);
                }).catch( error => {
                    that.gridObj.componentObj.undorDelete()
                    getPopupMessageBasedOnStatus(error);
                })
            }
            
        })
    }
    render() {
        const language = this.props.language;
        let navs = [
            {title:Helper.getLabel(language,'general',"General"), isActive: true},
        ];
        let parantsList = this.state.parents.map( parent => {
            return {
                label:parent.name + '- '+ parent.code,
                value:parent.segmentvalue_id
            }
        })
        let gridHeader= [
            {field:'code', headerName: Helper.getLabel(language,"code",'Code *') , validationRule:{types:['Required'],max:10}},
            {field:'name', headerName: Helper.getLabel(language,"name",'Name *') , validationRule:{types:['Required']}},
            {field:'segmentvalueparent_id', headerName: Helper.getLabel(language,"parent",'Parent *'), inputType:'dropdown', options : parantsList, validationRule:{types:['Required']}},
            {field:'description', headerName: Helper.getLabel(language,"description",'Description')},
        ];
        let segment = this.state.segment;
        return (
            <div className='segments_page segment_group_page grid_save_btn_hide'>
                <NavigationHeder backUrl={'/segments/segments-value/'+segment.segment_id} title={Helper.getLabel(language,'group','Group')} hideMoreBtn={true}>
                    <LinkBtn to={'/segments/segments-value/'+segment.segment_id} isActive={false} className="black-btn-style" title={Helper.getLabel(language,'Close','Close')}/>
                    <Button onClick={ this.setRangeOnClick.bind(this)} className="blue disable visible_on_select_item" title={Helper.getLabel(language,'range','Range')}/>
                    {this.state.isLoading ? <div style={{width:"150px"}}><SimpleLoading/></div> :  <Button onClick = { this.onSaveHandler.bind(this)  }  isActive={false} className="rfq_save_btn" title={Helper.getLabel(language,'save','Save')}/> }
                </NavigationHeder>
                <Tabs navs={navs} isHideNav={true} showAll={true} scrollOffset="300" addComponentTop = { this.addComponentTop.bind(this)} >
                    <Collapse  title={Helper.getLabel(language,'rollup-groups',"Rollup groups")} className="mb-5" open={true}>
                        <AgGridMemo settings={{singleClickEdit:false}} onDelete={this.onDeleteHandler.bind(this)} forceRender={this.forceRender} onSelectionChanged={this.onSelectionChanged.bind(this)} id="segments_group_grid" onGridReady={ this.onGridReady.bind(this)} singleRowSelect ={true} onSave={ this.onGridSave.bind(this)}   header={gridHeader} data={this.state.gridData}/>
                    </Collapse>
                </Tabs>
                { this.state.showRangePopup ? <SegmentGroupRange segment={this.state.segment} selectedGroup ={ this.state.selectedGroup } onPopupClose = { this.onPopupClose.bind(this)} /> :''}
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
    })
}
export default  connect (mapStateToProps,mapDispatchToProps)  ( RsWithRouter(RollupGroup));