import React,{useEffect,useState} from 'react';

import TimeLapse from "../../../Widgets/Time lapse/TimeLapse";

import { CalculateDateDelta } from "./CalculateDateDelta";

const Timer = (props) => {
 const [dateDelta, setDateDelta] = useState(null)
 let endDateStr = props.rfqData?.expiry_datetime
  useEffect(() => {
    const interval = setInterval(() => {
      const newDateDelta = CalculateDateDelta(endDateStr)

      //for check bid is expiry or not
      const dateExpiryCheck =
        newDateDelta &&
        newDateDelta.days === 0 &&
        newDateDelta.hours === 0 &&
        newDateDelta.minutes === 0 &&
        newDateDelta.seconds === 0

      props.setAllFieldsAreZero(dateExpiryCheck)
      setDateDelta(newDateDelta)
    }, 1000)
    return () => clearInterval(interval)
  }, [endDateStr])
  return (
    <div>
      <TimeLapse
          days={dateDelta?.days}
          minutes={dateDelta?.minutes}
          hours={dateDelta?.hours}
          seconds={dateDelta?.seconds}
        />
    </div>
  );
}

export default Timer;
