import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import RsWithRouter from "../../inc/RsWithRouter";
import { SearchStringType } from "../ShopResults";
import Dropdown from "../../Forms/Dropdown";

export const searchType = {
  shop: 0,
  wishlist: 1,
};

class ShopSearchbar extends Component {
  handleSearchChange(e) {
    if (this.props.searchType === searchType.shop)
      this.props.updateSearchString(e.target.value?.trimStart());
    if (this.props.searchType === searchType.wishlist)
      this.props.updateWishlistSearchString(e.target.value?.trimStart());
    if (this.props.searchString !== e.target.value && e.target.value === "") {
      this.props.handleSearch(1, "");
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.props.handleSearch(1);
    }
  }

  render() {
    const { searchString, handleSearch } = this.props;

    let searchTypeOptions = Object.keys(SearchStringType)?.map((key) => {
      return {
        value:SearchStringType[key].key_name,
        label:SearchStringType[key].title
      }
    })

    return (
      <div className="pr-searchbar pr-searchbar-wrapper">
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <div className="pr-searchbar-form">
            <input
              className={`pr-searchbar-input ${
                Helper.isRtl() ? "pr-searchbar-input-rtl" : ""
              }`}
              placeholder={Helper.getLabel(
                this.props.language,
                "search_item",
                "Search Item"
              )}
              onChange={(e) => this.handleSearchChange(e)}
              value={searchString}
            />
            <div
              className={`pr-searchbar-custom-select ${
                Helper.isRtl() ? "pr-searchbar-custom-select-rtl" : ""
              }`}
            >
              {/* <select
                value={this.props.SearchStringType}
                onChange={(e) =>
                  this.props.setSearchStringType({ type: e.target.value })
                }
              >
                {Object.keys(SearchStringType)?.map((key) => {
                  return (
                    <option value={SearchStringType[key].key_name}>
                      {SearchStringType[key].title}
                    </option>
                  );
                })}
              </select> */}
               <div className='rs_ppp_select pr-search-select'>
                <Dropdown 
                  disableClear={true} 
                  id={`search-type-1-${this.props.id}`} 
                  value={this.props.searchStringType}
                  onChange={(e) =>
                    this.props.setSearchStringType({ type: e.target.value })
                  }
                  options={searchTypeOptions}
                />
             </div>
            </div>
          </div>
          <button className="pr-searchbar-btn" onClick={() => handleSearch(1)} disabled={!this.props.searchString}>
            {Helper.getLabel(this.props.language, "search", "Search")}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(RsWithRouter(ShopSearchbar));
