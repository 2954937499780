import React, { useState, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import Helper from '../../inc/Helper'; 
import NavigationHeder from '../Navigations/NavigationHeder';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { useNavigate } from 'react-router-dom';
import ButtonNew from '../inc/ButtonNew';
import NewForm from './Entity Form/NewForm';
import AgGridNew from '../grid/ag/ag-grid-new';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import { gridLinkValueFormat } from '../grid/ag/commonFunctions/GridCommonFunctions';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Alert from '../inc/Alert';

let gridApi = null;

const Entity = ({ language, security }) => {
    const [inCreatingEntity, setInCreatingEntity] = useState(false); 

    const navigate = useNavigate();
   
    const onCellClicked = event => {
        if (event.colDef.field === 'code') {
            navigate('/entity/edit-entity/' + event?.data?.entity_id);
        }
    };

    const showEntityForm = e => {
        setInCreatingEntity(true);
    };

    const onNewEntityPopupClose = e => {
        setInCreatingEntity(false);
    };

    const onDeleteEntityHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delEntity_ID = element?.data?.entity_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl+`/delete_entity/${delEntity_ID}`)
            const rowNode = gridApi?.getRowNode(delEntity_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
 
    const gridColHeaders = [
        { field: 'code', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, "code", 'Code'), width: 150, cellRenderer: (params) => gridLinkValueFormat(params)},
        { field: 'name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, "name", 'Name'), width: 150 }
    ];
 
    if(!security.canView(Gui_id_list.setupForms.entity.entity_main)){ 
      return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </Fragment>
    }

    return (
        <div className='rs_entity_page'>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'all_entity', 'All Entity')}>
                    <ButtonNew
                        isDisable={!security.canCreate(Gui_id_list.setupForms.entity.entity_main_create_button)}
                        onClick={showEntityForm} 
                    />
                </NavigationHeder>

                {inCreatingEntity ? <NewForm onPopupClose={onNewEntityPopupClose} /> : ''}
 
                <AgGridNew
                    apiUrl={Settings.apiUrl + '/get_entities'}  
                    pagination={true}
                    columnDefs={gridColHeaders} 
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.entity.entity_main_delete_button)}
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'entity_id'}
                    onCellClicked={onCellClicked}
                    handleDeleteSelectedRows={onDeleteEntityHandler}
                    onGridReady={(params) => gridApi = params.api} 
                    gridId={"enttiy_list"}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language
    };
};

const SecurityOptions = {
    gui_id:Gui_id_list.setupForms.entity.entity_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(Entity, SecurityOptions))));   