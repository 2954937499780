import React, { useEffect, useState } from 'react'
import Popup from '../../Popup/Popup'
import Dropdown from '../../Forms/Dropdown'
import Helper from '../../../inc/Helper'
import RFQCommonApiCalls from '../RFQCommonApiCalls'
import Button from '../../inc/Button'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import Settings from '../../../inc/Settings'

const TemplateModal = ({ handleTemplateModalClose, language, rfq_id }) => {
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const rfqCommonCalls = new RFQCommonApiCalls()

  const handleConvertTemplate = async () => {
    let payload = {
      rec_id: rfq_id,
      source_id: Settings?.source_id?.request_for_quotation,
      template_id: selectedTemplate,
    }
    try {
      const convertRes = await rfqCommonCalls.convertToTemplate(payload)
      if (convertRes?.data) {
        const blob = new Blob([convertRes.data], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        window.open(url);
      } 
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const getAllTemplates = async () => {
    try {
      const templatesData = await rfqCommonCalls.getTemplates()
      let templatesList = []
      templatesData.forEach((item) => {
        templatesList?.push({
          value: item.rec_id,
          label: item.template_name,
        })
      })
      setTemplates(templatesList)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllTemplates()
  }, [])

  const onTemplateChange = (event) => {
    setSelectedTemplate(event.target.value)
  }

  return (
    <Popup autoOpen={true} width='900px' onClose={handleTemplateModalClose}>
      <h5 className='create_rfq_modal__title mb-4'>
        Preview Document
      </h5>
      <div>
        <Dropdown
          id='templates-rfq'
          label={Helper.getLabel(language, 'templates', 'Templates')}
          options={templates}
          name='template_id'
          value={selectedTemplate}
          onChange={onTemplateChange}
        />
      </div>
      <div className='w-100 d-flex justify-content-end'>
        <Button title='Preview' className='rfq_save_btn' onClick={handleConvertTemplate} />
      </div>
    </Popup>
  )
}

export default TemplateModal
