import React, { Fragment, useCallback } from 'react'; 
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api'; 
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux'; 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import Alert from '../../inc/Alert';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';

/* 50 limit to rounding, 10 to code, rounding cannot be lesser than 0 or not equal to 0 */

let gridApi = null;
let AddBtn;
let transaction;

const Unit = (props) => {

  const updateData = async (event) => {  
    try {
      let payload = {
        code: event?.data?.code,
        description: event?.data?.description,
        rounding: event?.data?.rounding
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl+`/unit/${event?.data?.unit_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  } 

  const addNewData = async (event) => { 
    try {
      let payload = {
        code: event?.data?.code,
        description: event?.data?.description,
        rounding: event?.data?.rounding
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl+'/unit', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }
  
  const onDeleteSystemUnit = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_unit_id = element?.data?.unit_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/unit/${delete_unit_id}`)
      const rowNode = gridApi?.getRowNode(delete_unit_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }
 
  const onRowValueChanged = (event) => {
    if (event?.data?.unit_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.formModules.unit.unit_main)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  } 

  /* Grid Header Columns */

  const gridColHeader = [
    {field:'code', editable: true, filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language, '2700101', 'Code')},
    {field:'description', editable: true, filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language, '2700201', 'Description')},
    {field:'rounding', editable: true, filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language, '2700301', 'Rounding')}
  ];

  return (
    <Fragment>
      <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language, 'unit', 'Unit')} />
 
      <AgGridNew
        apiUrl={Settings.apiUrl+'/unit'}
        pagination={false}
        columnDefs={gridColHeader}
        onRowValueChanged={onRowValueChanged}
        hideDeleteBtn={!security.canDelete(frontendIds.formModules.unit.unit_main_delete_button)}
        hideAddBtn={!security.canCreate(frontendIds.formModules.unit.unit_main_create_button)}
        handleDeleteSelectedRows={onDeleteSystemUnit}
        height={500}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        uniqueField={`unit_id`}
        gridId={'unit-setup-data'}
      /> 
    </Fragment>
  )
}
  
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.formModules.unit.unit_main
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(Unit, SecurityOptions))))