import React, { Component } from "react";

import Helper from "../../../inc/Helper";
import ChevronLeft from "../../../assets/chevron-left-single.svg";
import ChevronLeftDouble from "../../../assets/chevron-left-double.svg";
import ChevronRight from "../../../assets/chevron-right-single.svg";
import ChevronRightDouble from "../../../assets/chevron-right-double.svg";

// PROPS
// totalPages: number
// pageSize: number
// currentPage: number
// onPageChange: callback fn

export default class ModernPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumbers: [],
    };
  }

  componentDidMount() {
    this.setPageNumbers(this.props.totalPages, this.props.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.totalPages !== this.props.totalPages) {
      this.setPageNumbers(this.props.totalPages, this.props.pageSize);
    }
  }

  setPageNumbers = (totalPages, pageSize) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPages / pageSize); i++) {
      pageNumbers.push(i);
    }
    this.setState({ pageNumbers });
  };

  handleMoveToFirst = () => {
    this.props.onPageChange(1);
  };

  handleMoveToLast = () => {
    this.props.onPageChange(this.state.pageNumbers?.length);
  };

  handlePrevious = () => {
    const { currentPage } = this.props;
    if (currentPage > 1) {
      this.props.onPageChange(currentPage - 1);
    }
  };

  handleNext = () => {
    const { pageNumbers } = this.state;
    const { currentPage } = this.props;
    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      this.props.onPageChange(currentPage + 1);
    }
  };

  getVisiblePageNumbers = () => {
    const { pageNumbers } = this.state;
    const { currentPage } = this.props;
    const visiblePageNumbers = [];
  
    let startIndex = Math.max(1, currentPage - 2);
    let endIndex = Math.min(pageNumbers.length, startIndex + 4);
  
    if (endIndex - startIndex < 4) {
      // If there are not enough numbers to show 5, adjust the range to center the current page
      const diff = 4 - (endIndex - startIndex);
      if (startIndex === 1) {
        endIndex = Math.min(pageNumbers.length, endIndex + diff);
      } else if (endIndex === pageNumbers.length) {
        startIndex = Math.max(1, startIndex - diff);
      } else {
        const leftDiff = Math.floor(diff / 2);
        const rightDiff = diff - leftDiff;
        startIndex = Math.max(1, startIndex - leftDiff);
        endIndex = Math.min(pageNumbers.length, endIndex + rightDiff);
      }
    }
  
    for (let i = startIndex; i <= endIndex; i++) {
      visiblePageNumbers.push(pageNumbers[i - 1]);
    }
  
    return visiblePageNumbers;
  };
  

  render() {
    const { currentPage } = this.props;
    const visiblePageNumbers = this.getVisiblePageNumbers();

    return (
      <div className="modern-pagination">
        <button
          className={`arrow-button arrow-button-leftmost ${
            currentPage === 1 ? "disabled" : ""
          }`}
          onClick={this.handleMoveToFirst}
        >
          <img
            src={Helper.isRtl() ? ChevronRightDouble : ChevronLeftDouble}
            alt="move to first"
          />
        </button>
        <button className={`arrow-button`} onClick={this.handlePrevious}>
          <img
            src={Helper.isRtl() ? ChevronRight : ChevronLeft}
            alt="previous"
          />
        </button>
        {visiblePageNumbers.map((pageNumber) => {
          let className = "page-number";
          if (pageNumber === currentPage) {
            className += " active";
          }
          return (
            <button
              key={pageNumber}
              className={className}
              onClick={() => this.props.onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
        <button className={`arrow-button`} onClick={this.handleNext}>
          <img src={Helper.isRtl() ? ChevronLeft : ChevronRight} alt="next" />
        </button>
        <button
          className={`arrow-button arrow-button-rightmost  ${
            currentPage === this.props.totalPages ? "disabled" : ""
          }`}
          onClick={this.handleMoveToLast}
        >
          <img
            src={Helper.isRtl() ? ChevronLeftDouble : ChevronRightDouble}
            alt="move to last"
          />
        </button>
      </div>
    );
  }
}
