import ActionTypes from "../inc/ActionsTypes";
import Api from "../inc/Api";
import Settings from "../inc/Settings";

const LoadPurchaseStatus = (dispatch,oldData) => {
    if(oldData.isLoaded){
        return;
    }
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiUrl + '/enum/POHeaderPurchaseStatus').then(res =>{
        dispatch({
            type:ActionTypes.SET_PURCHASE_STATUS,
            payload:res.data.data
        });
    })
}
export default LoadPurchaseStatus;