import React, { useEffect, useRef } from 'react'
import Dropdown from '../../../../Forms/Dropdown'
import Helper from '../../../../../inc/Helper'
import Input from '../../../../Forms/Input'
import SimpleLoading from '../../../../Loading/SimpleLoading'
import { Form } from 'react-bootstrap'
import $ from 'jquery'
import Settings from '../../../../../inc/Settings'
import NewInputDatePicker from '../../../../Forms/NewInputDatePicker'

const ReadableInput = ({
  value1,
  value2,
  languageLabel,
  label,
  shipmentHeader,
  language,
}) => {
  return (
    <Input
      label={`${Helper.getLabel(language, languageLabel, label)}`}
      value={shipmentHeader ? value1 : value2}
      disable={true}
      onChange={(e) => {}}
    />
  )
}

const CommonInputDatePicker = ({
  language,
  label,
  shipmentHeader,
  purchaseorder_id,
  setState,
  state,
  name,
}) => {
  return (
    <NewInputDatePicker
      label={`${Helper.getLabel(language, name, label)}`}
      id={name}
      name={name}
      value={state}
      disable={shipmentHeader?.document_status === 1 && purchaseorder_id===null}
      onChange={(date) => {
        setState(date)
      }}
    />
  )
}

const WorkConfirmationForm = ({
  language,
  isLoading,
  selectedDocument,
  setSelectedDocument,
  workConfirmationNumber,
  setWorkingConfirmationNumber,
  workConfirmationDescription,
  setWorkConfirmationDescription,
  shipmentHeader,
  accountingDate,
  setAccountingDate,
  documentDate,
  setDocumentDate,
  userType,
  purchaseorder_id,
}) => {

  useEffect(() => {
    if (shipmentHeader) {
      setAccountingDate(shipmentHeader.accounting_date)
      setDocumentDate(shipmentHeader.document_date)
      setWorkingConfirmationNumber(shipmentHeader.receiving_reference_number)
      setWorkConfirmationDescription(shipmentHeader.description)
    }
  }, [shipmentHeader])

  const mapApiItemToOption = (item) => {
    return {
        id: item.document_number || 'null',
        text: item.document_number + ' - ' + item.description?.substring(0,40),
    };
 };

  let currentSearchTerm = ''
  let pageCounter = 1
  const allData = useRef([])

  const handleDropdownOpen = () => {
    pageCounter = 1
    allData.current = []
  }

  $(`#select-work-confirmation`).on('select2:opening', handleDropdownOpen)


  let documentOptions = purchaseorder_id ? [
    {
      value: selectedDocument?.document_number,
      label: selectedDocument?.document_number 
    }, 
  ]:[]

  return (
    <div className="generalInformation">
      {isLoading ? (
        <SimpleLoading />
      ) : (
          <div className="generalInformation-basic">
            <div>
              {/* {userType === 0 && (
                <div
                  className="mb-3 work-confirmation-checkbox"
                  style={{ marginLeft: '-5px' }}
                >
                  <Form.Check
                    type={'checkbox'}
                    label="Submission on behalf"
                    id="work-confirmation-onbehalf-request"
                  />
                </div>
              )} */}
              <div className="work-confirmation-grid work-confirmation-row-1">
                <div className="generalInformation-basic-grid">
                  <Dropdown
                   id="select-work-confirmation"
                    label={`${Helper.getLabel(
                      language,
                      'source_document_no',
                      'Source document number',
                    )}`}
                    options={
                      shipmentHeader &&
                      (shipmentHeader.document_number !== null ||
                        shipmentHeader.document_status === 1)
                        ? [
                            {
                              value: shipmentHeader?.document_number,
                              label: shipmentHeader?.document_number 
                            },
                          ]
                        : documentOptions
                    }
                    placeHolder={`${Helper.getLabel(
                      language,
                      'select_document_no.',
                      'Select document number',
                    )}`}
                    required={true}
                    value={
                      shipmentHeader
                        ? shipmentHeader?.document_number
                        : selectedDocument?.document_number || ''
                    }
                    isDisable={
                      shipmentHeader &&
                      (shipmentHeader.document_number !== null ||
                        shipmentHeader.document_status === 1)
                    }
                    pagination={true}
                    reRenderRequired={true}
                    apiUrl={Settings.apiPurchaseOrderUrl + '/PO_header?type=1'}
                    currentSearchTerm={currentSearchTerm}
                    mapFunction={mapApiItemToOption}
                    allDataRef={allData} 
                    additionalParams={{document_status:1}}
                    onChange={(e) => {
                      if (e.target.value.includes('null')) {
                        Helper.alert(
                          'Document number cannot be null. Please select other option.',
                          'error',
                        )
                      } else {
                        const selected = allData?.current?.find(
                          (doc) => doc.document_number === e.target.value,
                        )
                        setSelectedDocument(selected || {})
                      }
                    }}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="source_document_description"
                    label="Source document description"
                    shipmentHeader={shipmentHeader}
                    value1={shipmentHeader?.po_description?.substring(0,80)}
                    value2={selectedDocument?.description}
                  />
                </div>
              </div>
              <div className="work-confirmation-grid">
                <h6>Vendor</h6>
                <div className="generalInformation-basic-grid">
                  <ReadableInput
                    language={language}
                    languageLabel="vendor_code"
                    label="Vendor code"
                    shipmentHeader={shipmentHeader}
                    value1={shipmentHeader?.vendor_code}
                    value2={selectedDocument?.vendor_code}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="vendor_name"
                    label="Vendor name"
                    shipmentHeader={shipmentHeader}
                    value1={shipmentHeader?.vendor_name}
                    value2={selectedDocument?.vendor_name}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="vendor_site"
                    label="Vendor site"
                    shipmentHeader={shipmentHeader}
                    value1={shipmentHeader?.vendorsite_name}
                    value2={selectedDocument?.vendorsite_name}
                  />
                </div>
              </div>
              <div className="work-confirmation-grid">
                <h6>Work confirmation detail</h6>
                <div className="generalInformation-basic-grid">
                  <Input
                    label={`${Helper.getLabel(
                      language,
                      'work_confirmation_no',
                      'Work confirmation no.',
                    )}`}
                    value={workConfirmationNumber}
                    required={true}
                    maxLength={20}
                    disable={
                      shipmentHeader?.document_status === 1 && purchaseorder_id===null
                    }
                    onChange={(e) => {
                      setWorkingConfirmationNumber(e.target.value)
                    }}
                  />
                   <Input
                    label={`${Helper.getLabel(
                      language,
                      'description',
                      'Description',
                    )}`}
                    value={workConfirmationDescription}
                    required={true}
                    disable={
                      shipmentHeader?.document_status === 1 && purchaseorder_id===null
                    }
                    maxLength={255}
                    onChange={(e) => {
                      setWorkConfirmationDescription(e.target.value)
                    }}
                  />
                  {userType === 0 && <CommonInputDatePicker
                    name={'accounting_date'}
                    language={language}
                    label={'Accounting date'}
                    shipmentHeader={shipmentHeader}
                    setState={setAccountingDate}
                    state={accountingDate}
                    editValue={shipmentHeader?.accounting_date}
                    purchaseorder_id={purchaseorder_id}
                  />}
                  <CommonInputDatePicker
                    name={'document_date'}
                    language={language}
                    label={'Document date'}
                    shipmentHeader={shipmentHeader}
                    setState={setDocumentDate}
                    state={documentDate}
                    editValue={shipmentHeader?.document_date}
                    purchaseorder_id={purchaseorder_id}
                  />
                </div>
              </div>
            </div>
          </div>
      )}
    </div>
  )
}

export default WorkConfirmationForm
