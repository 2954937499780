import React from 'react'
import Helper from '../../../../inc/Helper'
import Dropdown from '../../../Forms/Dropdown'
import Input from '../../../Forms/Input'
import NewInputDatePicker from '../../../Forms/NewInputDatePicker'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import Checkbox from '../../../Forms/Checkbox'

const GeneralAuction = ({ rfqHeader, setRfqHeader, setUpdateRFQ, updateRFQ, handleOnChange, language, rfqHeaderKeyPressData, handleInputChange, isDisabled }) => {
  const onInputCheckboxChangeHandler = (name, value) => {
    setRfqHeader((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
    setUpdateRFQ((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  let biddingParticipationOptions = [
    {
      value: 1,
      label: 'Invited Only',
    },
    {
      value: 2,
      label: 'Registered Only',
    },
    {
      value: 3,
      label: 'All',
    },
  ]

  return (
    <div className='generalInformation-advanced'>
      <div className='generalInformation-basic-grid'>
        <Input
          label={`${Helper.getLabel(language, 'auction_bid_from_range', 'Auction bid from range')}`}
          value={rfqHeaderKeyPressData?.auction_bid_from_range ? rfqHeaderKeyPressData?.auction_bid_from_range : rfqHeader?.auction_bid_from_range}
          name='auction_bid_from_range'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={isDisabled}
          inputType={'number'}
        />
        <Input
          label={`${Helper.getLabel(language, 'auction_bid_to_range', 'Auction bid to range')}`}
          value={rfqHeaderKeyPressData?.auction_bid_to_range ? rfqHeaderKeyPressData?.auction_bid_to_range : rfqHeader?.auction_bid_to_range}
          name='auction_bid_to_range'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={isDisabled}
          inputType={'number'}
        />
        <NewInputDatePicker
          label={`${Helper.getLabel(language, 'auction_start_date_time', 'Auction start date time')}`}
          id='auction_start_date_time'
          name='auction_start_date_time'
          timePicker={true}
          onChange={(date) => {
            setUpdateRFQ({
              ...updateRFQ,
              auction_start_date_time: DatepickerFunctions.convertDateForDataBase(date),
            })
            setRfqHeader({
              ...rfqHeader,
              auction_start_date_time: date,
            })
          }}
          value={rfqHeader?.auction_start_date_time}
          minDate={new Date()}
          disable={isDisabled}
          empty={true}
        />
        <NewInputDatePicker
          label={`${Helper.getLabel(language, 'auction_end_date_time', 'Auction end date time')}`}
          id='auction_end_date_time'
          name='auction_end_date_time'
          timePicker={true}
          onChange={(date) => {
            setUpdateRFQ({
              ...updateRFQ,
              auction_end_date_time: DatepickerFunctions.convertDateForDataBase(date),
            })
            setRfqHeader({
              ...rfqHeader,
              auction_end_date_time: date,
            })
          }}
          value={rfqHeader?.auction_end_date_time}
          minDate={new Date()}
          disable={isDisabled}
          empty={true}
        />
        <Input
          label={`${Helper.getLabel(language, 'minimum_bid_decrement_percentage', 'Minimum bid decrement percentage')}`}
          value={rfqHeaderKeyPressData?.minimum_bid_decrement_percentage ? rfqHeaderKeyPressData?.minimum_bid_decrement_percentage : rfqHeader?.minimum_bid_decrement_percentage}
          name='minimum_bid_decrement_percentage'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={isDisabled}
          inputType={'number'}
        />
        <Input
          label={`${Helper.getLabel(language, 'minimum_bid_decrement_amount', 'Minimum bid decrement amount')}`}
          value={rfqHeaderKeyPressData?.minimum_bid_decrement_amount ? rfqHeaderKeyPressData?.minimum_bid_decrement_amount : rfqHeader?.minimum_bid_decrement_amount}
          name='minimum_bid_decrement_amount'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={isDisabled}
          inputType={'number'}
        />
        <NewInputDatePicker
          label={`${Helper.getLabel(language, 'auction_time_extensions', 'Automatic time extensions')}`}
          id='automatic_time_extenstions'
          name='automatic_time_extenstions'
          timePicker={true}
          timeOnly={true}
          onChange={(date) => {
            setUpdateRFQ({
              ...updateRFQ,
              automatic_time_extenstions: DatepickerFunctions.convertDateForDataBase(date),
            })
            setRfqHeader({
              ...rfqHeader,
              automatic_time_extenstions: date,
            })
          }}
          value={rfqHeader?.automatic_time_extenstions}
          minDate={new Date()}
          disable={isDisabled}
          empty={true}
        />
        <Dropdown
          label={`${Helper.getLabel(language, 'currency', 'Bidding participation')}`}
          options={biddingParticipationOptions}
          placeHolder={`${Helper.getLabel(language, 'select_bidding_participation', 'Select bidding participation')}`}
          id='rfq_bidding_participation'
          name='bidding_participation'
          onChange={handleOnChange}
          value={rfqHeader?.bidding_participation}
          reRenderRequired={true}
          isDisable={isDisabled}
        />
        <Checkbox
          id='competitor_name_visible'
          type='checkbox'
          name='competitor_name_visible'
          disableWithoutIcon={isDisabled}
          label='Competitor name visible'
          isChecked={rfqHeader?.competitor_name_visible}
          onChange={onInputCheckboxChangeHandler}
        />
        <Checkbox
          id='bid_ranking_visibility'
          type='checkbox'
          name='bid_ranking_visibilit'
          disableWithoutIcon={isDisabled}
          label='Bid ranking visibility'
          isChecked={rfqHeader?.bid_ranking_visibility}
          onChange={onInputCheckboxChangeHandler}
        />
      </div>
    </div>
  )
}

export default GeneralAuction
