import Constant_GUI from "./GuiConstants";

const Form_gui_ids = {
    warehouse_edit:'warehouse_1',//warehouse_1
    appMasterSidebar:{
        main_component_id:'2000450',//,'app_main_sidebar',
        command_center:'20004501',//'app_main_sidebar-command_center',
        request:'20004502',//'app_main_sidebar-request',
        procure:'20004503',//'app_main_sidebar-procure',
        receive:'20004504',//'app_main_sidebar-receive',
        master_data:'20004505',//'app_main_sidebar-master_data',
        finance:'20004506',//'app_main_sidebar-finance',
        budget:'20004507',//'app_main_sidebar-budget',
        approval:'20004508',//'app_main_sidebar-approval',
        reports:'20004509',//'app_main_sidebar-reports',
        setup:'200045010',//'app_main_sidebar-setup',
        administration:'200045011',//'app_main_sidebar-administration',
        purchase_order:'2000445012',//'app_main_sidebar-purchase-order',
        invoice:'2000445013',//'app_main_sidebar-invoice',
        vendor_profile:'2000445014',//'app_main_sidebar-vendor-profile',
        request_for_quotation:'2000445015',//'app_main_sidebar-request-for-quotation', 
        request_for_information:'2000445016',//'app_main_sidebar-request-for-information',
        contract:'2000445017',//'app_main_sidebar-contract',
        purchase_agreement:'2000445018',//'app_main_sidebar-blanked-agreements',
        release_order:'2000445019',//'app_main_sidebar-release-order',
        goods_receiving:'2000445020',//'app_main_sidebar-goods-receiving',
        work_confirmation:'2000445021',//'app_main_sidebar-work-confirmation',
        workflow:'2000445022',//'app_main_sidebar-workflow',
        questionnaire:'2000445023',
        auction:'2000445024'
    },
    tenant:{
        main_id:"10045011",
        create:"10045012",
        tenant_main_form:"10045013",
        tenant_edit_button:"10045014",
        tenant_address_edit:"10045016",
        tenant_contact_edit:"10045017",
        tenant_contact_delete:"10045018",
        tenant_contacts_add:"10045019",
        tenant_address_delete:"10045020",
        tenant_address_add:"10045021",
        tenant_address:"10045022",
        tenant_contacts:"10045023",
        newForm:{
            page:`1${Constant_GUI.createMode}1`,
            createAddress:`1${Constant_GUI.createMode}2`,
            createContact:`1${Constant_GUI.createMode}3`
        },
        editForm:{
            btn_save:`1${Constant_GUI.editMode}1`,//10021
            btn_delete:`1${Constant_GUI.editMode}2`,
            btn_cancel:`1${Constant_GUI.editMode}3`,
            btn_translation:`1${Constant_GUI.editMode}4`,
            tab_address:`1${Constant_GUI.editMode}5`,
            tab_address_add:`1${Constant_GUI.editMode}6`,
            tab_address_delete:`1${Constant_GUI.editMode}7`,
            tab_address_view:`1${Constant_GUI.editMode}8`,
            tab_contact:`1${Constant_GUI.editMode}9`,
            tab_contact_add:`1${Constant_GUI.editMode}10`,
            tab_contact_delete:`1${Constant_GUI.editMode}11`,
            tab_genarel:`1${Constant_GUI.editMode}12`,
            code:`1${Constant_GUI.editMode}13`,
            name:`1${Constant_GUI.editMode}14`,
            f_name:`1${Constant_GUI.editMode}15`,
            m_name:`1${Constant_GUI.editMode}16`,
            l_name:`1${Constant_GUI.editMode}17`,
            email:`1${Constant_GUI.editMode}18`,
            sid:`1${Constant_GUI.editMode}19`,
            start_date:`1${Constant_GUI.editMode}20`,
            end_date:`1${Constant_GUI.editMode}21`,
            status:`1${Constant_GUI.editMode}22`,
        }
    },
    setup:{
        page_id:"300045013",
        create:""
    }
}
export default Form_gui_ids;
