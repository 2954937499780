import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper' 
import Collapse from '../../inc/Collapse'
import Tabs from '../../Widgets/Tabs/Tabs' 
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer'

const GeneralCreate = ({language, disableFields=false, vendorType, createVendor, websiteUrl, setCreateVendor}) => {

    //Tabs

    let general_navs = [
        {title:Helper.getLabel(language,'identification','Identification'), isActive: true},
        {title:Helper.getLabel(language,'other_information','Other Information')},
        {title:Helper.getLabel(language,'online_presence','Online Presence')}
    ]

    // ------ 

    // Fields Array@s

    const fieldsArrayIdentification = [
        {className:'col-lg-4', disable: true, label: Helper.getLabel(language,'vendor_code',"Vendor Code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'name',"Name"), required:true, inputType: "text", id:'name', name:'name'},
        {className:'col-lg-4', disable: disableFields, options: vendorType, label: Helper.getLabel(language,'type',"Type"), required:true, inputType: "dropdown", id:'vendor_type', name:'vendor_type'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'one-time-vendor',"One Time Vendor"), inputType: "checkbox", id:'onetime', name:'onetime'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'alternate_name',"Alternate Name"), inputType: "text", id:'alternate_name', name:'alternate_name'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'legacy_code',"Legacy Code"), inputType: "text", id:'legacy_code', name:'legacy_code'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'external_code',"External Code"), inputType: "text", id:'externalcode', name:'externalcode'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'duns_number',"DUNS Number"), inputType: "number", id:'dunsnumber', name:'dunsnumber'}
    ]

    const fieldsArrayOtherInfo = [
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language, 'year_established', "Year established"), emptyDate:true, inputType: "datepicker_new", id:'yearestablished', name:'yearestablished'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'incorporated_year',"Incorporated Year"), emptyDate:true, inputType: "datepicker_new", id:'incorporationyear', name:'incorporationyear'}, 
    ]

    const fieldsArrayOnlinePresence = [
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'website',"Website"), inputType: "text", id:'website_url', name: !websiteUrl ? 'website_url' : 'websiteurl'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'linked-in',"LinkedIn"), inputType: "text", id:'linkedin', name:'linkedin'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'facebook',"Facebook"), inputType: "text", id:'facebook', name:'facebook'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'twitter',"Twitter"), inputType: "text", id:'twitter', name:'twitter'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'youtube',"Youtube"), inputType: "text", id:'youtube', name:'youtube'},
        {className:'col-lg-4', disable: disableFields, label: Helper.getLabel(language,'instagram',"Instagram"), inputType: "text", id:'instagram', name:'instagram'}
    ]

    // ----

    return (
        <Collapse title={Helper.getLabel(language,'general','General')} className="mb-4 general_tabs" open={true}>
            <Tabs disableSticky={true} navs={general_navs} showAll={false} scrollOffset="500">
                <div className='container-fluid identification'>
                    <FieldsRenderer updateState={setCreateVendor} currentState={createVendor} fieldsArray={fieldsArrayIdentification} />
                </div>
                <div className='container-fluid other-information'>
                    <FieldsRenderer fieldsArray={fieldsArrayOtherInfo} updateState={setCreateVendor} currentState={createVendor} /> 
                </div>
                <div className='container-fluid online-presence'>
                    <FieldsRenderer updateState={setCreateVendor} fieldsArray={fieldsArrayOnlinePresence} currentState={createVendor} />
                </div>
            </Tabs> 
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

export default connect(mapStateToProps) (GeneralCreate)