import React, { Component } from 'react';
import Input from "./Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class PasswordInput extends Component {
    constructor(props){
        super(props);
        this.state={
            isShowText:false
        }
    }
    textHideShow(){
        let textState = this.state.isShowText
        this.setState({
            isShowText: !textState
        })
    }
    render() {
        let props = this.props;
        let isTextVisible = this.state.isShowText
        return (
            <div style={{position:"relative"}}>
             <Input {...props} inputType={isTextVisible?"text":"password"} /> 
             <FontAwesomeIcon icon={isTextVisible?faEyeSlash:faEye} style={{position:"absolute",top:"50%",right:"5px",cursor:"pointer"}} onClick={this.textHideShow.bind(this)}/>
            </div>
        )
    }
}

export default PasswordInput;