import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import MasterComonent from '../../Backend/MasterComonent'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button'
import ButtonNew from '../../inc/ButtonNew'
import LinkBtn from '../../inc/LinkBtn'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles' 
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import OrderTotal from '../../Widgets/Totalling/OrderTotal'
import AttachmentsLines from './AttachmentsLines'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import './ExtPO.css'
import PurchaseLines from './PurchaseLines'

const PurchaseOrderDetailView = (props) => {

  
  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders_listing.purchase_orders_detail)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className='purchase_orders_detail_view'> 
      <div className='container-fluid'>
        <NavigationHeder backUrl='/purchase-orders-listing' title={ `<span style="color:#757575;">${Helper.getLabel(props.language,'po_listing',"PO Listing")} > </span> Purchase Order Detail ` }>
          <ButtonNew title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders_listing.purchase_orders_detail_create_button)} /> 
        </NavigationHeder>

        <div className='purchase_order_section'>
          <div className='archive_header_section app_navigation'>
            <div className='app_navigation_left'>
              <h3>Purchase Order Number: <span>PO15-0553</span></h3>
            </div>

            <div className='app_navigation_right'>
              <Button isDisable={!security.canCreate(frontendIds.procure.purchase_orders_listing.purchase_orders_detail_view_invoices_button)} title={Helper.getLabel(props.language,'view_invoices',"View Invoices")}  />
              <Button isDisable={!security.canCreate(frontendIds.procure.purchase_orders_listing.purchase_orders_detail_view_credit_note_button)} title={Helper.getLabel(props.language,'view_credit_note',"View Credit Note")}  />
              <Button isDisable={!security.canCreate(frontendIds.procure.purchase_orders_listing.purchase_orders_detail_receiving_button)} title={Helper.getLabel(props.language,'receiving',"Receiving")}  />
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row p-0 border-bottom border-2 pb-3'>
              <div className='col-8'>
                <div className='purchase_order_status'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 mb-3'>
                        <h2>
                        PO Status
                          <span className='ms-5'>
                            <button disabled={!security.canCreate(frontendIds.procure.purchase_orders_listing.purchase_orders_detail_open_button)}>Open Order</button>
                          </span>
                        </h2>
                      </div>
                      <div className='col-12 mb-3'>
                        <h2>
                        Document Title
                          <span className='ms-5'>
                          Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et
                          </span>
                        </h2>
                      </div>
                      <div className='col-12 mb-3'>
                        <h2>
                        Created Date & Time
                          <span className='ms-5'>
                          11/24/2015   11:20:52AM
                          </span>
                        </h2>
                      </div>
                      <div className='col-12 mb-3'>
                        <h2>
                        Currency
                          <span className='ms-5'>
                           USD
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4 d-flex justify-content-end'>
                <OrderTotal />
              </div>
            </div>

            <div className='purchasing_items mt-2'>
              <div className='row mb-3'>
                <h2>Lines</h2>
              </div>
              <PurchaseLines />
            </div>
          </div>
        </div>
        
        <div className='row mt-3'>
          <div className='col-6'>
            <div className='attachments_po'>
              <div className='row'>
                <div className='col-12'>
                  <h3>Attachments</h3>
                </div>  
                <div className='col-12'>
                  <AttachmentsLines />
                </div>  
              </div>
            </div>
          </div>
          <div className='col-6'>
           <div className='notes_attachments'>
            <div className='row'>
              <div className='col-12'>
                <h3>Invoice Notes/Comments</h3>
              </div>  
              <div className='col-12'>
                 <NoteAttachmentHistory />
              </div>  
          </div>
           </div> 
          </div>
        </div>
      </div>    
    </div>    
  )
}
 

const mapStateToProps = (state) => {
  return { 
      appOptions:state.options,
      auth:state.auth,
      language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders_listing.purchase_orders_detail
}

export default connect(mapStateToProps) (MasterComponentWraper(( ApplySecurityRoles( PurchaseOrderDetailView, SecurityOptions))))