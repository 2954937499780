function CustomButtonCellRenderer() {}

CustomButtonCellRenderer.prototype.init = function (params) {
  this.eGui = document.createElement('div');
  this.eGui.innerHTML = params.usertype===0?`
    <div style="display:flex;align-items:center;height:41px;gap:15px;">
      <button style="height:30px;line-height:0;background-color:#3CBA54;color:#fff;border:none;border-radius:4px;">Accept</button>
      <button style="height:30px;line-height:0;background-color:#cc0000;color:#fff;border:none;border-radius:4px;">Decline</button>
    </div>
  `:`<div style="display:flex;align-items:center;height:41px;gap:15px;">
      <button style="height:30px;line-height:0;background-color:#3CBA54;color:#fff;border:none;border-radius:4px;">Recall</button>
    </div>`;

  if(params.usertype===0){
  this.eGui.querySelector('button:nth-child(1)').addEventListener('click', () => {
    params.onClickAccept(params);
  });

  this.eGui.querySelector('button:nth-child(2)').addEventListener('click', () => {
    params.onClickReject(params);
  });
 }

 if(params.usertype===1){
  this.eGui.querySelector('button:nth-child(1)').addEventListener('click', () => {
     params.onClickRecalled(params) 
  });
 }

};

CustomButtonCellRenderer.prototype.getGui = function () {
  return this.eGui;
};

export default CustomButtonCellRenderer;
