import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import CommonChartCard from "./CommonChartCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ContractByStatusLineChart = () => {
    const options = {
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        barPercentage: 0.4,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        // maintainAspectRatio: true,
      }

  const data = {
    labels: ['Active', 'On-hold', 'Freeze','In-Amendment','In-Renewal','In-Early-Settlement'],
    datasets: [
      {
        data: [15000000,1000000,1000000,5500000,1500000,1800000],
        backgroundColor: [
            "#FFD700",  
            "#8C62FF"
        ],
      },
    ],
  };
  return (
      <CommonChartCard title='Contract count by status'>
        <Bar options={options} data={data} height={300} />
      </CommonChartCard>
  )
};

export default ContractByStatusLineChart;
