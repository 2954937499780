import React from 'react' 
import MasterComonent from '../../components/Backend/MasterComonent';
import EditSite from '../../components/Sites/forms/EditSite';
import Sites from '../../components/Sites/Sites';

const SiteScreen = (props) => {
  return (

    <MasterComonent>
      {props.pageName && props.pageName ==='edit' ? <EditSite /> : <Sites />}
    </MasterComonent>
  )
}

export default SiteScreen;
