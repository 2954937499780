import React, { Component } from 'react';
import ReturnOrders from '../../components/Purchase Orders Internal/ReturnOrder/ReturnOrders';

class ContractReturnOrderPage extends Component {
    render() {
        return (
            <ReturnOrders 
                backUrl = '/contract-order/edit'
                editUrl = '/contract-order/return-order/edit'
            />
        );
    }
}

export default ContractReturnOrderPage;