import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import $ from 'jquery';
import Settings from '../../../inc/Settings';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

const VendorCategory = ({ Vendorcategories, readOnlyMode=false, currentCategoryArray=[], setVendorCategories, editMode, reloadGrid, setShowCategories, vendor_id, language }) => {
  const [vendorCategorySearch, setVendorCategorySearch] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);

  let categoryCalled = false;

  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => { 
    const filtered = categoryList?.filter(item =>
      item.label?.toLowerCase()?.includes(vendorCategorySearch?.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [vendorCategorySearch, categoryList]);

  const getCategoriesList = () => {
    if (categoryCalled) {
      return;
    }
    let api = Api;
    setCategoryLoading(true);
    categoryCalled = true;
    api.setUserToken();
    api.axios().get(Settings.apiUrl + '/categories', {}).then(function (res) {
      if (res.data.status === 'success') {
        let UPDATED_STATE = []; 
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.category_id,
            label: `${element.code} - ${element.name}`
          });
        }); 

        setCategoryLoading(false);
        setCategoryList(UPDATED_STATE?.filter((item) => !currentCategoryArray.includes(item.value)));
      }
    }).catch((err) => {
      setCategoryLoading(false);
    });
  };
  
  const CategoryHandler = (value) => {
    if (Vendorcategories.includes(value)) {
      setVendorCategories(Vendorcategories.filter((item) => item !== value));
    } else {
      setVendorCategories([...Vendorcategories, value]);
    }
  };

  const SelectCategories = () => {
    let api = Api;
    api.setUserToken();
    Vendorcategories.forEach((element) => [
      api.axios().post(Settings.apiVendorUrl + '/vendorcategory', {
        "category_id": element,
        "vendor_id": vendor_id,
        "processinstance_id": null,
        "relevance": null
      }).then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          reloadGrid();
          setShowCategories(false);
          setVendorCategories([]);
          $('.vn_categories_popup_close .rs_popup_close').trigger('click');
        }
      }).catch((res) => {
        getPopupMessageBasedOnStatus(res); 
      })
    ]);
  }; 
  return (
    <div className='vendors_categories'>
      <div className='action_button_vn_category mb-3 border-bottom pb-2'> 
        <div className='action_button_search_category'>
          <input
            name='search_category'
            placeholder='Search'
            value={vendorCategorySearch}
            onChange={(e) => setVendorCategorySearch(e.target.value)}
          /> 
        </div>
        {editMode && (<Button className="rfq_save_btn" onClick={SelectCategories} title={Helper.getLabel(language, 'vn_category_select', 'Select Category')} isActive={false} />)}
      </div>

      {categoryLoading ? <SimpleLoading /> : (
        <div className="chips-container-vn-category">
          {filteredCategories.map((item) => (
            <div
              key={item.value}
              style={{ pointerEvents: readOnlyMode ? 'none' : 'auto', userSelect: 'none' }}
              className={`chip-vn-category ${Vendorcategories.includes(item.value)  || Vendorcategories.some(s => s.vendorcategory_id == item.value) ? 'selected' : ''}`}
              onClick={() => CategoryHandler(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language
  };
};

export default connect(mapStateToProps)(VendorCategory); 