import React, { Component } from "react";
import { Alert, Button } from "react-bootstrap";
import { connect } from "react-redux";

import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import ModernPagination from "../common/ModernPagination";
import ShoppingItemCard from "../common/ShoppingItemCard";
import AddCategoryFormModal from "../modals/AddCategoryFormModal.js";
import SearchFilters from "./SearchFilters";
import ShopSearchbar, { searchType } from "../common/ShopSearchbar";
import { isValidNumber } from "../../../inc/Validation";
import Dropdown from "../../Forms/Dropdown.js";
import RsWithRouter from "../../inc/RsWithRouter.js";

// temp data
const sortOptions = [
  {
    key: "featured",
    option: "Featured",
  },
  {
    key: "price_low_to_high",
    option: "Price: Low to High",
  },
  {
    key: "price_high_to_low",
    option: "Price: High to Low",
  },
  {
    key: "average_customer_reviews",
    option: "Average Customer Reviews",
  },
];

export const pageSizeOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];
const priceOptions = [
  {
    key: 1,
    option: "Under AED 1,000",
    min: 0,
    max: 1000,
  },
  {
    key: 2,
    option: "AED 1,000 - AED 5,000",
    min: 1000,
    max: 5000,
  },
  {
    key: 3,
    option: "UAED 5,000 - AED 10,000",
    min: 5000,
    max: 10000,
  },
  {
    key: 4,
    option: "UAED 10,000 - AED 20,000",
    min: 10000,
    max: 20000,
  },
  {
    key: 5,
    option: "Over AED 20,000",
    min: 20000,
    max: null,
  },
];

export const SearchStringType = {
  name: {
    id: 1,
    key_name: "name",
    title: "Name",
    value: 0,
  },
  itemCode: {
    id: 2,
    key_name: "item_code",
    title: "Item Code",
    value: 0,
  },
  barCode: {
    id: 3,
    key_name: "bar_code",
    title: "Bar Code",
    value: 0,
  },
};

class ShopResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isBrandsLoading: false,
      isSizesLoading: false,
      isColorsLoading: false,
      isStyleLoading:false,
      shopItems: [],
      brandOptions: [],
      selectedBrands: [],
      sizeOptions: [],
      selectedSizes: [],
      colorOptions: [],
      selectedColors: [],
      styleOptions:[],
      selectedStyles:[],
      resultCount: 0,
      pageSize: 10,
      pageNumber: 1,
      showAddCategoryForm: false,
      startPrice: 0,
      endPrice: null,
      selectedPriceBracket: 0,
      lineTypesList: [],
      searchStringType: SearchStringType.name.key_name,
      searchString: "",
      lastSearch: "",
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.incrementCartCount = this.incrementCartCount.bind(this);
    this.decrementCartCount = this.decrementCartCount.bind(this);
    this.incrementWishlistCount = this.incrementWishlistCount.bind(this);
    this.decrementWishlistCount = this.decrementWishlistCount.bind(this);
    this.handleShowAddCategoryForm = this.handleShowAddCategoryForm.bind(this);
    this.handleCloseAddCategoryForm =
      this.handleCloseAddCategoryForm.bind(this);
    this.handleBrandSelection = this.handleBrandSelection.bind(this);
    this.handleSizeSelection = this.handleSizeSelection.bind(this);
    this.handleColorSelection = this.handleColorSelection.bind(this);
    this.handleStyleSelection = this.handleStyleSelection.bind(this);
    this.handlePriceBracketChange = this.handlePriceBracketChange.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleMinPriceChange = this.handleMinPriceChange.bind(this);
    this.handleMaxPriceChange = this.handleMaxPriceChange.bind(this);
    this.resetPriceSelection = this.resetPriceSelection.bind(this);
    this.setSearchStringType = this.setSearchStringType.bind(this);
    this.updateSearchString = this.updateSearchString.bind(this);
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  componentDidMount() {
    this.getSearchResults();
    this.getBrandOptions();
    this.getSizeOptions();
    this.getColorOptions();
    this.getStyleOptions();
    this.getLineTypesList();
  }

  componentDidUpdate(prevProps, prevState) {
    // search if page size value changes
    if (prevState.pageSize && prevState.pageSize !== this.state.pageSize) {
      this.getSearchResults(1);
      return;
    }
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  getSearchResults(page, search,filters) {
    if (page) {
      this.setState({ pageNumber: page });
    }
    let searchString = search ?? this.state.searchString;
    let data = {
      filters: {
        // price: priceFilter,
        brand: this.state.selectedBrands,
        sizes: this.state.selectedSizes,
        colors: this.state.selectedColors,
        styles: this.state.selectedStyles,
        name:
          searchString?.length > 0 &&
          this.state.searchStringType === SearchStringType.name.key_name
            ? searchString
            : null,
        item_code:
          searchString?.length > 0 &&
          this.state.searchStringType === SearchStringType.itemCode.key_name
            ? searchString
            : null,
        bar_code:
          searchString?.length > 0 &&
          this.state.searchStringType === SearchStringType.barCode.key_name
            ? searchString
            : null,
      },
    };
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isLoading: true });
    api
      .axios()
      .post(
        Settings.apiItemUrl +
          `/get_items_ecommerce/${page ?? this.state.pageNumber}/${
            this.state.pageSize
          }`,
       filters?filters:data
      )
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isLoading: false,
            shopItems: [...res.data.data],
            resultCount: res.data?.total_records,
            lastSearch: searchString ?? "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getBrandOptions() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isBrandsLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + `/item_brand`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            isBrandsLoading: false,
            brandOptions: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
        this.setState({ isBrandsLoading: false });
      });
  }

  getSizeOptions() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isSizesLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + `/item_size`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            isSizesLoading: false,
            sizeOptions: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
        this.setState({ isSizesLoading: false });
      });
  }

  getColorOptions() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isColorsLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + `/item_color`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            isColorsLoading: false,
            colorOptions: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
        this.setState({ isColorsLoading: false });
      });
  }

  getStyleOptions() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isStyleLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + `/item_style`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            isStyleLoading: false,
            styleOptions: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
        this.setState({ isStyleLoading: false });
      });
  }

  getLineTypesList() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            lineTypesList: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  handlePageChange(pageNumber) {
    this.setState({ pageNumber });
    this.getSearchResults(pageNumber);
  }

  handlePageSizeChange(event) {
    this.setState({ pageSize: parseInt(event?.target?.value) });
    this.setState({ pageNumber: 1 });
  }

  handleCloseAddCategoryForm() {
    this.setState({ showAddCategoryForm: false });
  }

  handleShowAddCategoryForm() {
    this.setState({ showAddCategoryForm: true });
  }

  incrementCartCount() {
    this.props.changeCartCount(this.props.cartCount + 1);
  }

  decrementCartCount() {
    this.props.changeCartCount(
      this.props.cartCount >= 1
        ? this.props.cartCount - 1
        : this.props.cartCount
    );
  }

  updateSearchString(val) {
    this.setState({ searchString: val });
  }

  incrementWishlistCount() {
    this.props.changeWishlistCount(this.props.wishlistCount + 1);
  }

  decrementWishlistCount() {
    this.props.changeWishlistCount(
      this.props.wishlistCount >= 1
        ? this.props.wishlistCount - 1
        : this.props.wishlistCount
    );
  }

  handleBrandSelection(id) {
    const selectedBrands = [...this.state.selectedBrands];
    const existingIndex = selectedBrands.indexOf(id);
    if (existingIndex === -1) selectedBrands.push(id);
    else selectedBrands.splice(existingIndex, 1);
    this.setState({ selectedBrands });
  }

  handleSizeSelection(id) {
    const selectedSizes = [...this.state.selectedSizes];
    const existingIndex = selectedSizes.indexOf(id);
    if (existingIndex === -1) selectedSizes.push(id);
    else selectedSizes.splice(existingIndex, 1);
    this.setState({ selectedSizes });
  }

  handleColorSelection(id) {
    const selectedColors = [...this.state.selectedColors];
    const existingIndex = selectedColors.indexOf(id);
    if (existingIndex === -1) selectedColors.push(id);
    else selectedColors.splice(existingIndex, 1);
    this.setState({ selectedColors });
  }

  handleStyleSelection(id) {
    const selectedStyles = [...this.state.selectedStyles];
    const existingIndex = selectedStyles.indexOf(id);
    if (existingIndex === -1) selectedStyles.push(id);
    else selectedStyles.splice(existingIndex, 1);
    this.setState({ selectedStyles });
  }

  handlePriceBracketChange(min, max, optionNumber) {
    this.setState({
      startPrice: min,
      endPrice: max,
      selectedPriceBracket: optionNumber ?? 0,
    });
  }

  handleMinPriceChange(e) {
    if (e.target.value < 0) return;
    if (!isValidNumber(e.target.value)) return;
    this.setState({ startPrice: e.target.value });
  }

  handleMaxPriceChange(e) {
    if (e.target.value < 0) return;
    if (!isValidNumber(e.target.value)) return;
    this.setState({ endPrice: e.target.value });
  }

  resetPriceSelection(e) {
    this.setState({ selectedPriceBracket: 0 });
  }

  isFilterActive() {
    return (
      this.state.selectedBrands.length > 0 ||
      this.state.selectedSizes.length > 0 ||
      this.state.selectedColors.length > 0 ||
      this.state.selectedPriceBracket !== 0 ||
      this.state.selectedStyles.length > 0
    );    
  }

  clearFilters() {
    this.setState({
      selectedBrands: [],
      selectedSizes: [],
      selectedColors: [],
      selectedStyles:[],
      selectedPriceBracket: 0,
      // startPrice: 0,
      // endPrice: null,
    });
    let data = {
      filters:{
        "brand": [],
        "sizes": [],
        "colors": [],
        "name": null,
        "item_code": null,
        "bar_code": null
      }
    }
    this.getSearchResults(1,null,data)
  }

  setSearchStringType(val) {
    this.setState({ searchStringType: val.type });
  }

  render() {
    return (
      <>
        <ShopSearchbar
          handleSearch={this.getSearchResults}
          searchString={this.state.searchString}
          updateSearchString={this.updateSearchString}
          searchType={searchType.shop}
          searchStringType={this.state.searchStringType}
          setSearchStringType={this.setSearchStringType}
        />
        <div className="pr-search-additional">
          <div className="pr-search-additional-count">
            {Helper.addThousandSeparator(
              (this.state.pageNumber - 1) * this.state.pageSize + 1
            )}
            -
            {Helper.addThousandSeparator(
              this.state.pageNumber * this.state.pageSize <
                this.state.resultCount
                ? this.state.pageNumber * this.state.pageSize
                : this.state.resultCount
            )}{" "}
            of over {Helper.addThousandSeparator(this.state.resultCount)}{" "}
            results
          </div>
          <div className="d-flex align-items-center gap-3">
             <p className="fw-bold mb-0">Page size</p>
             <div className='rs_ppp_select pagination-select'>
                <Dropdown 
                  disableClear={true} 
                  id={`pagination-dropdown-${this.props.id}`} 
                  value={this.state.pageSize}
                  onChange={this.handlePageSizeChange} 
                  options={pageSizeOptions}
                />
             </div>
          </div>
        </div>
        <div className="pr-shop">
          <div className="pr-filters">
            {this.state.isBrandsLoading ||
            this.state.isColorsLoading ||
            this.state.isSizesLoading || this.state.isStyleLoading ? (
              <SimpleLoading />
            ) : (
              <>
                <div className="pr-filters-actions">
                  {this.isFilterActive() ? (
                    <button
                      className="pr-filters-actions-outlined"
                      onClick={this.clearFilters}
                    >
                      {Helper.getLabel(
                        this.props.language,
                        "clear_all",
                        "Clear all"
                      )}
                    </button>
                  ) : (
                    <span></span>
                  )}
                  <button
                    className="pr-filters-actions-solid"
                    onClick={() => this.getSearchResults(1)}
                    disabled={!this.isFilterActive() }
                  >
                    {Helper.getLabel(
                      this.props.language,
                      "apply_filters",
                      "Apply filters"
                    )}
                  </button>
                </div>
                <SearchFilters
                  filters={{
                    label: Helper.getLabel(
                      this.props.language,
                      "brands",
                      "Brands"
                    ),
                    options: this.state.brandOptions,
                  }}
                  addCheckboxes={true}
                  handleSelection={this.handleBrandSelection}
                  selectedOptions={this.state.selectedBrands}
                />
                <SearchFilters
                  filters={{
                    label: Helper.getLabel(
                      this.props.language,
                      "sizes",
                      "Sizes"
                    ),
                    options: this.state.sizeOptions,
                  }}
                  addCheckboxes={true}
                  handleSelection={this.handleSizeSelection}
                  selectedOptions={this.state.selectedSizes}
                />
                <SearchFilters
                  filters={{
                    label: Helper.getLabel(
                      this.props.language,
                      "colors",
                      "Colors"
                    ),
                    options: this.state.colorOptions,
                  }}
                  addCheckboxes={true}
                  handleSelection={this.handleColorSelection}
                  selectedOptions={this.state.selectedColors}
                /> 
               <SearchFilters
                  filters={{
                  label: Helper.getLabel(
                    this.props.language,
                    "styles",
                    "Styles"
                   ),
                   options: this.state.styleOptions,
                }}
                addCheckboxes={true}
                handleSelection={this.handleStyleSelection}
                selectedOptions={this.state.selectedStyles}
              />

                {/* <SearchFilters
                  filters={{
                    label: Helper.getLabel(
                      this.props.language,
                      "price",
                      "Price"
                    ),
                    options: priceOptions,
                  }}
                  returnPriceFilters={true}
                  handleSelection={this.handlePriceBracketChange}
                  selectedOptions={this.state.selectedPriceBracket}
                  minPrice={this.state.startPrice}
                  maxPrice={this.state.endPrice}
                  handleMinPriceChange={this.handleMinPriceChange}
                  handleMaxPriceChange={this.handleMaxPriceChange}
                  handleSearch={this.getSearchResults}
                  resetPriceSelection={this.resetPriceSelection}
                /> */}
              </>
            )}
          </div>
          <div className="pr-shop-main">
            <Alert className="pr-add-category-alert">
              {Helper.getLabel(
                this.props.language,
                "want_to_add_category_as_pr",
                "Want to add category as part of purchase requisition?"
              )}
              <Button
                variant="outline-primary"
                onClick={this.handleShowAddCategoryForm}
              >
                Add
              </Button>
            </Alert>
            {this.state.isLoading ? (
              <SimpleLoading />
            ) : (
              <>
               {this?.state?.shopItems?.length > 0 ? <div className="pr-shop-main-itemgrid">
                  {this.state.shopItems?.map((item) => (
                    <ShoppingItemCard
                      key={item.item_id}
                      item={item}
                      showWishlistIcon={true}
                      wishlistIds={this.props.wishlistIds}
                      lineTypes={this.state.lineTypesList}
                      incrementCartCount={this.incrementCartCount}
                      incrementWishlistCount={this.incrementWishlistCount}
                      decrementWishlistCount={this.decrementWishlistCount}
                    />
                  ))}
                </div> : <p className="fw-bold text-center w-100">No product found.</p>}
                <div className="modern-pagination-wrapper">
                  {this.state.resultCount > this.state.pageSize ? (
                    <ModernPagination
                      currentPage={this.state.pageNumber}
                      totalPages={this.state.resultCount}
                      pageSize={this.state.pageSize}
                      onPageChange={this.handlePageChange}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>

        <AddCategoryFormModal
          incrementCartCount={this.incrementCartCount}
          visibility={this.state.showAddCategoryForm}
          hide={this.handleCloseAddCategoryForm}
          prId={this.getSearchParam('pr_view_id')}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RsWithRouter(ShopResults));
