import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import $ from "jquery";
import Popup from "../Popup/Popup";
import Helper from "../../inc/Helper";
import Input from "../Forms/Input";
import Collapse from "../inc/Collapse";
import Dropdown from "../Forms/Dropdown";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import InputDatePicker from "../Forms/InputDatePicker";
import Button from "../inc/Button";
import RFQCommonApiCalls from "../RequestForQuotation/RFQCommonApiCalls";
import moment from "moment";

const CreateRFIModal = ({
  language,
  setShowCreateRFIModal,
  employee,
  entity,
  sites,
  warehouse,
  securityLevel,
  quotationType,
}) => {
  const [selectedEntity, setSelectedEntity] = useState();
  const [rfqHeader, setRfqHeader] = useState({});
  const [unsealed, setUnsealed] = useState(0);
  const [site_id, setSite_id] = useState(null);
  const [warehouse_id, setWarehouse_id] = useState(null);
  const [delivery_date, setDelivery_date] = useState(null);
  const [unsealed_datetime, setUnsealed_datetime] = useState(null);
  const [expiry_date, setExpiry_date] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [entityOptions, setEntityOptions] = useState(
    entity.map((item) => {
      return {
        value: item?.entity_id,
        label: item?.name,
      };
    }) ?? []
  );
  const [siteOptions, setSiteOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [description, setDescription] = useState("");
  const [isEntityFirst, setIsEntityFirst] = useState();
  const [isWarehouseFirst, setIsWarehouseFirst] = useState(false);
  const [rfqHeaderType, setRfqHeaderType] = useState();
  const navigate = useNavigate();
  const rfqCommonApiCalls = new RFQCommonApiCalls();
  const api = Api;

  const getUserDetails = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/user_details")
      .then((res) => {
        setSelectedEntity(res.data.data.Employee.Entity_id);
        setSite_id(res.data.data.Employee.Site_id);
        setWarehouse_id(res.data.data.Employee.Warehouse_id);
        setDelivery_date($(".delivery_date").val());
        setUnsealed_datetime($(".unsealed_date_time").val());
        setExpiry_date($(".expiry_date_time").val());
        handleEntityChange(null, res.data.data.Employee.Entity_id);

        // setRfqHeader({
        //   ...rfqHeader,
        //   site_id: res.data.data.Employee.Site_id,
        //   warehouse_id: res.data.data.Employee.Warehouse_id,
        //   delivery_date: Helper.getDateForDb($(".delivery_date").val()),
        //   unsealed_datetime: Helper.getDateForDb(
        //     $(".unsealed_date_time").val()
        //   ),
        // });
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const initData = () => {
    api.setUserToken();
    getUserDetails();
  };

  useEffect(() => {
    initData();
  }, []);

  const handleEntityChange = (e, entity) => {
    let entityValue;

    if (e) {
      entityValue = e.target.value;
      setIsEntityFirst(true);
    } else {
      entityValue = entity;
    }

    const selectedEntitySites = sites.filter(
      (site) => site.entity_name === e?.params?.data?.text
    );
    const selectedEntityWarehouses = warehouse.filter(
      (warehouse) => warehouse.entity_name === e?.params?.data?.text
    );

    setSiteOptions(
      selectedEntitySites.map((site) => {
        return {
          value: site.site_id,
          label: site.name,
        };
      })
    );
    if (isWarehouseFirst === false) {
      setWarehouseOptions(
        selectedEntityWarehouses.map((warehouse) => {
          return {
            value: warehouse.warehouse_id,
            label: warehouse.name,
          };
        })
      );
    }
    // setSelectedWarehouse(null);
    // setSelectedSite(null);

    api.setUserToken();
    api
      .axios()
      .get(
        `${Settings.apiProcurementSettingsUrl}/procurementsetting_by_entity/${entityValue}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          const data = res?.data.data;

          setRfqHeader({
            ...rfqHeader,
            entity_id: entityValue,
            expiry_datetime: getExpiryDate(data?.default_quoation_expiry_days),
            security_level:
              data?.default_quoation_security_level === 0
                ? "0"
                : data?.default_quoation_security_level,
            quotation_type:
              data?.default_quoation_type === 0
                ? "0"
                : data?.default_quoation_type,
          });
        }
      })
      .catch((error) => {
        // Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const handleSiteChange = (e, sites) => {
    const find = sites.find((site) => site.site_id == e.target.value);

    setSelectedSite(e.target.value);
    setWarehouse_id(null);

    setEntityOptions([
      {
        value: find.entity_id,
        label: find.entity_name,
      },
    ]);
  };

  const handleWarehouseChange = (e, warehouse) => {
    if (isEntityFirst) {
      setIsEntityFirst(false);
    } else {
      setIsWarehouseFirst(true);
      const find = warehouse.find(
        (warehouse) => warehouse.warehouse_id == e.target.value
      );

      setSelectedWarehouse(e.target.value);
      setSelectedSite(find.site_name);

      setEntityOptions([
        {
          value: find.entity_id,
          label: find.entity_name,
        },
      ]);

      setSiteOptions([
        {
          value: find.site_id,
          label: find.site_name,
        },
      ]);
    }
  };

  const getExpiryDate = (numberOfDaysToAdd) => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + numberOfDaysToAdd));
  };

  //   const getRfqHeaderType = () => {
  //     Api.axios()
  //       .get(Settings.loginUrl + "/enum/RFQ_HeaderType")
  //       .then((res) => {
  //         setRfqHeaderType(res.data?.data);
  //       })
  //       .catch((error) => {
  //         Helper.alert(error?.response?.data?.message, "error");
  //       });
  //   };

  const handleSubmit = () => {
    const payLoad = {
      ...rfqHeader,
      entity_id: selectedEntity,
      quotation_type: parseInt(rfqHeader.quotation_type),
      unsealed_by: parseInt(unsealed),
      expiry_datetime: Helper.getDateForDb(expiry_date),
      delivery_date: Helper.getDateForDb(delivery_date),
      unsealed_datetime: Helper.getDateForDb(unsealed_datetime),
      site_id: selectedSite,
      warehouse_id: selectedWarehouse,
      title: description,
      rfq_type: 1, // 1 - RFI, 0 - RFQ
    };

    const api = Api;
    api.setUserToken();
    api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/rfq_header", payLoad)
      .then((res) => {
        if (res.data.status === "success") {
          Helper.alert(res?.data?.message, "success");

          setShowCreateRFIModal(false);
          navigate(
            `/request-for-quotation/rfqdetailform?rfq_view_id=${res?.data?.header_id}`
          );
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Popup
      autoOpen={true}
      width="900px"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setShowCreateRFIModal(false);
      }}
    >
      <>
        <h5 className="create_rfq_modal__title">
          {Helper.getLabel(
            language,
            "create_request_for_information",
            "Create Request For Information"
          )}
        </h5>

        <Collapse
          className="mt-4 mb-4"
          open={true}
          title={Helper.getLabel(
            language,
            "enter_the_following_information",
            "Enter the following information"
          )}
        >
          <Form className="addVendorModalForm" style={{ gap: "20px" }}>
            <Dropdown
              label={`${Helper.getLabel(
                language,
                "select_entity_id",
                "Select Entity id"
              )}`}
              options={
                entityOptions
                  ? entityOptions
                  : entity.map((item) => {
                      return {
                        value: item?.entity_id,
                        label: item?.name,
                      };
                    }) ?? []
              }
              placeHolder={`${Helper.getLabel(
                language,
                "please_select",
                "Please Select"
              )}`}
              id="select_entity_id"
              onChange={(e) => {
                setSelectedEntity(e.target.value);
                isWarehouseFirst ? <></> : handleEntityChange(e);
              }}
              value={selectedEntity}
            />

            <Input
              label={`${Helper.getLabel(
                language,
                "description",
                "Description"
              )}`}
              onChange={handleDescriptionChange}
              inputType="textarea"
              value={description}
            />

            <InputDatePicker
              label={`${Helper.getLabel(
                language,
                "delivery_date",
                "Delivery Date"
              )}`}
              inputClassName="delivery_date"
              name="delivery_date"
              onChange={() => {
                setDelivery_date($(".delivery_date").val());
                // setRfqHeader({
                //   ...rfqHeader,
                //   delivery_date: Helper.getDateForDb(
                //     $(".delivery_date").val()
                //   ),
                // });
              }}
              value={delivery_date}
            />

            <InputDatePicker
              label={`${Helper.getLabel(
                language,
                "expiry_date_time",
                "Expiry Date Time"
              )}`}
              inputClassName="expiry_date_time"
              name="expiry_date_time"
              onChange={() => {
                // const date = $(".expiry_date_time").val();
                // const datenew = new Date(date);
                // setRfqHeader({
                //   ...rfqHeader,
                //   expiry_datetime: Helper.getDateForDb(
                //     $(".expiry_date_time").val()
                //   ),
                // });
                setExpiry_date($(".expiry_date_time").val());
              }}
              value={expiry_date || rfqHeader?.expiry_date}
            />

            <InputDatePicker
              label={`${Helper.getLabel(
                language,
                "unsealed_date_time",
                "Unsealed Date Time"
              )}`}
              inputClassName="unsealed_date_time"
              name="unsealed_date_time"
              onChange={() => {
                const date = $(".unsealed_date_time").val();
                const datenew = new Date(date);
                // let dateObj = moment.utc(datenew);
                // // Format the date
                // let dateHttp = dateObj.format(
                //   "ddd, DD MMM YYYY HH:mm:ss [GMT]"
                // );
                // // console.log(dateHttp); // Output: "Wed, 26 Jul 2023 07:56:09 GMT"

                // console.log(dateHttp, "date");

                setUnsealed_datetime($(".unsealed_date_time").val());
                // setRfqHeader({
                //   ...rfqHeader,
                //   unsealed_datetime: Helper.getDateForDb(
                //     $(".unsealed_date_time").val()
                //   ),
                // });
              }}
              value={unsealed_datetime}
            />

            <Dropdown
              label={`${Helper.getLabel(
                language,
                "unsealed_by",
                "Unsealed By"
              )}`}
              options={employee.map((item) => {
                return {
                  value: item?.employee_id,
                  label: item?.display_name,
                };
              })}
              placeHolder={`${Helper.getLabel(
                language,
                "please_select",
                "Please Select"
              )}`}
              onChange={(e) => {
                // setRfqHeader({
                //   ...rfqHeader,
                //   unsealed_by: e.target.value,
                // });
                setUnsealed(e.target.value);
              }}
              value={unsealed}
              id="unsealed_by"
            />

            <Dropdown
              label={`${Helper.getLabel(
                language,
                "security_level",
                "Security Level"
              )}`}
              options={securityLevel.map((item) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              })}
              placeHolder={`${Helper.getLabel(
                language,
                "please_select",
                "Please Select"
              )}`}
              onChange={(e) => {
                setRfqHeader({
                  ...rfqHeader,
                  security_level: e.target.value,
                });
              }}
              value={rfqHeader?.security_level}
              id="security_level"
            />

            {quotationType.length !== 0 && rfqHeader?.quotation_type && (
              <Dropdown
                label={`${Helper.getLabel(
                  language,
                  "quotation_type",
                  "Quotation Type"
                )}`}
                options={quotationType.map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name,
                  };
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  "please_select",
                  "Please Select"
                )}`}
                onChange={(e) => {
                  setRfqHeader({
                    ...rfqHeader,
                    quotation_type: e.target.value,
                  });
                }}
                value={rfqHeader?.quotation_type}
                id="quotation_type"
              />
            )}

            <Dropdown
              label={`${Helper.getLabel(language, "site", "Site")}`}
              options={
                siteOptions.length >= 1
                  ? siteOptions
                  : sites.map((item) => {
                      return {
                        value: item?.site_id,
                        label: item?.name,
                      };
                    }) ?? []
              }
              placeHolder={`${Helper.getLabel(
                language,
                "please_select",
                "Please Select"
              )}`}
              onChange={(e) => {
                setSelectedSite(e.target.value);
                setSite_id(e.target.value);
                handleSiteChange(e, sites);
              }}
              value={selectedSite}
              id="site"
            />

            <Dropdown
              label={`${Helper.getLabel(language, "warehouse", "Warehouse")}`}
              options={
                warehouseOptions.length >= 1
                  ? warehouseOptions
                  : warehouse.map((item) => {
                      return {
                        value: item?.warehouse_id,
                        label: item?.name,
                      };
                    })
              }
              placeHolder={`${Helper.getLabel(
                language,
                "please_select",
                "Please Select"
              )}`}
              onChange={(e) => {
                // setRfqHeader({
                //   ...rfqHeader,
                //   warehouse_id: e.target.value,
                // });
                setSelectedWarehouse(e.target.value);
                setWarehouse_id(e.target.value);

                handleWarehouseChange(e, warehouse);
              }}
              value={selectedWarehouse}
              id="warehouse"
            />
          </Form>

          <div
            className="addDocumentNumber-body-buttons"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              isActive={false}
              className="black-btn-style"
              title={Helper.getLabel(language, "Close", "Close")}
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                setShowCreateRFIModal(false);
              }}
            />
            <Button
              className="solid_btn"
              isActive={true}
              title={Helper.getLabel(language, "create", "Create")}
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                handleSubmit();
              }}
            />
          </div>
        </Collapse>
      </>
    </Popup>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(CreateRFIModal);
