import InputPlaceholder from "./InputPlaceholder";
import React, { Component } from 'react';
import Helper from "../../inc/Helper";

class Input extends Component {
    constructor(props){
        super(props);
        this.state = {
            isError: false,
            value:'',
            fileData:null,
            isRequired: this.props.required ? true : false,
            cssClass: 'form-group '+ ( this.props.className ? this.props.className  : '' )
        }
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.inputType === 'number' && this.inputRef?.current) {
            this.inputRef.current.addEventListener('wheel', this.handleWheel, { passive: false });
        }
    }

    componentWillUnmount() {
        if (this.props.inputType === 'number' && this.inputRef?.current) {
            this.inputRef.current.removeEventListener('wheel', this.handleWheel);
        }
    }

    handleWheel = (e) => {
        e.preventDefault();
    }

    errorHandler(value){
        if( this.state.isRequired && (value === "" || value === null)){
            this.setState({
                isError:true
            })
        }else{
            this.setState({
                isError:false
            })
        }
    }
    onChangeHandler(e){
        this.errorHandler(e.target.value)
        if(this.props?.inputType == 'number' && !Helper.isNumeric(e.target.value) ){
            return;
        }
        if (this.props?.inputType === 'number' && e.target.value?.length > this.props?.maxLength) {
            return;
        }
        if(this.props.onChange && typeof this.props.onChange == 'function'){
            if(this.props.inputType ==='file'){
                this.fileChangeHandler(e,this.props.onChange)
            }else{
                this.props.onChange(e,this.state.isError)
            }
        }else{
            this.setState({
                value:e.target.value
            })
        }
    }
    fileChangeHandler(e, callbackFunction){
        if(!e.target.files[0]){
            return;
        }
        let that = this;
        var reader = new FileReader();
        const file = e.target.files[0];
        if (file) {
            reader.readAsDataURL(file); 
    
            reader.onload = function () {
                that.setState({
                    fileData:reader.result
                })
                callbackFunction(e,{
                    fileData:reader.result,
                    fileName: file.name 
                });
            };
    
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }
    focusOutHandler(e){
        this.errorHandler(e.target.value);
        if(this.props.onBlur && typeof this.props.onBlur ==='function'){
            this.props.onBlur(e)
        }
    }
    focusHandler(e){
        this.errorHandler(e.target.value);
        if(this.props.onFocus && typeof this.props.onFocus ==='function'){
            this.props.onFocus(e)
        }
    }
    onKeyDown(e){
        this.errorHandler(e.target.value);
        if(this.props.inputType == 'number' && this.props.label !== Helper.getLabel(null,'rounding_amount','Rounding amount')){
            if (e.key === '+' || e.key === '-' || e.key === 'e') {
                e.preventDefault();
            }
        }
        if(this.props.onKeyDown && typeof this.props.onKeyDown ==='function'){
            this.props.onKeyDown(e)
        }
    }
    getInputBox = () => {
        let props = this.props;
        const {inputType} = props;
        let inputValue = props.value ? props.value : this.state.value;
        let inputProps = {};
        if(this.props.onKeyPress){
            inputProps.onKeyPress = this.props.onKeyPress;
        }

        if(this.props.inputId){
            inputProps.id = this.props.inputId;
        }

        if(props.disable){
            let value = props.value;
            return <InputPlaceholder label= {value }/>
        }

        if(inputType ==='textarea'){
            return (
                <textarea autoComplete="off" onBlur  = { e => this.focusOutHandler(e)} type={inputType ? inputType : 'text'} name={props.name} value={inputValue ? inputValue : ''} onChange={ e => this.onChangeHandler(e) } className={'form-control textAreaHeightControl ' +  (props.inputClassName ? props.inputClassName : '' )}  placeholder={props.placeHolder ? props.placeHolder : props.placeholder} maxLength={this.props.maxLength}/>
            )
        }

        return (
            <input {...inputProps} ref={this.inputRef} autoComplete="off" onBlur  = { e => this.focusOutHandler(e)} onFocus={ e => this.focusHandler(e)} onKeyDown={e => this.onKeyDown(e)} type={inputType ? inputType : 'text'} name={props.name} value={inputValue ? inputValue : ''} onChange={ e => this.onChangeHandler(e) } className={'form-control ' +  (props.inputClassName ? props.inputClassName : '' )}  placeholder={props.placeHolder ? props.placeHolder : props.placeholder} maxLength={props.maxLength}/>
        )
    }
    hasError(){
        if(this.state.isError){
            return true;
        }
        if(this.props.hasError){
            return true;
        }
        return false;
    }
    render() {
        let props = this.props;
        let cssClass = this.state.cssClass;
        if(props.labelAlign && props.labelAlign ==='left'){
            cssClass = cssClass+' lebel_align_left';
        }
        return (
            <div className={  cssClass + ( this.hasError() ? ' invalid' : '' ) }>
                {props.label ? <label>{props.label} {this.state.isRequired || this.props.required ? <span>*</span> : ''}</label> : '' }
                <div className="rs_input_box_wraper">{ this.getInputBox() }</div>
                { props.helpText ? <small className={ ( props.helpTextClass ? props.helpTextClass : '' ) + " form-text text-muted" }>{props.helpText}</small> : ''}
            </div>
        )
    }
}

export default Input;