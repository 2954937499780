import React from 'react';

/* 
    props: 
    Wrap this component with Sections to have scrollable functionalities - 
*/

const TeamSectionsWrapper = ({ children, sectionRef, numberOfElements=0, onScroll, compliance = false }) => {
 
    const renderComplianceRow = () => {
        return (
            <div className='complainces_row' style={{height: numberOfElements===0 ? '46px' : '100%'}}>
                <div className="compliance_column fixed_compliance_col">
                    Description
                </div>
               
                {Array.from({ length: numberOfElements }, (el, index) => (
                    <div key={index} className="compliance_column compliance_content">
                        <div className='compliance'>Compliance</div>
                        <div className='comment'>Comments</div>
                    </div>
                ))}
            </div>
        )
    };

    return (
        <div className={`${compliance ? 'compliances_sections_cn' : 'sections_cn'}`} onScroll={onScroll} ref={sectionRef}>
            <div className="container-fluid"> 
                {compliance && renderComplianceRow()}
                {children}
            </div>
        </div>
    );
}

export default TeamSectionsWrapper;