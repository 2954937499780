import React from "react";

const FixedNavigationHeader = (props) => {
  // there are two type of super nav one is dynamin other is fixed, so here besically we store what ever super nav comes
  const dynamicSuperNav = props.dynamic
    ? props?.superNav?.props?.headerList
    : props?.superNav;

  // javascript code for controling tab active status when user scroll on tab content section. start...
  const sections = document.querySelectorAll(".lists_contant");
  
  let activeSectionIndicator = "";
  let sectionName = props?.sectionName;
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const listActiveTab = document.querySelector(".lists.active_tab");
          if(listActiveTab) {
            listActiveTab.classList.remove("active_tab"); 
          }
          
          activeSectionIndicator = entry.target.classList[1];
          const newActiveTabElement = document.querySelector(`.lists[data-section="${activeSectionIndicator}"]`);

          if (newActiveTabElement) {
            newActiveTabElement.classList.add("active_tab");
          }
        }
      });
    },
    {
      root: document.querySelector(
        "#parentDiv"
      ) /* <-- This is necessary for an iframe to properly target itself as the root document instead of the parent window. https://github.com/w3c/IntersectionObserver/issues/372 */,
      rootMargin: "-10% 0px -90% 0px",
    }
  );
  sections.forEach((section) => {
    observer.observe(section);
  });
  

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();

    const section = document.getElementById(sectionId); 
    const parentDiv = document.getElementById("parentDiv");  
  
    if (section && parentDiv) {
      parentDiv.scroll({
        top: section.offsetTop - parentDiv.offsetTop,
        behavior: "smooth"
      });
    }
  };

  return (
    <div
      style={{
        marginTop:
          props.scrollDitection == "down"
            ? "24px"
            : props.superNav
            ? "0px"
            : "11px",
      }}
    >
      {props?.addComponentTop ? props?.addComponentTop() : null}

      {/* tab navigation header */}
      <div
        className={
          "tab_header_container " +
          (props?.scrollDitection == "down" ? "" : "scrollUp")
        }
      >
        <div className="tab_header_wraper">
          <div
            className={
              "tab_header " +
              (props?.scrollDitection == "down" ? "scrollUp_content" : "")
            }
          >
            <div className="d-flex gap-4">
              {props?.superNav
                ? dynamicSuperNav?.map((item, index) => {
                    return (
                      <div
                        onClick={() =>
                          props.setSelectedSupNav(
                            props.dynamic ? item : item.title
                          )
                        }
                        key={props.dynamic ? item : item.title}
                        className={`lists link_text_container ${
                          props.dynamic
                            ? item == props.selectedSupNav
                              ? "super_nav_active_tab "
                              : ""
                            : item.title == props.selectedSupNav
                            ? "super_nav_active_tab "
                            : ""
                        }`}
                      >
                        <a>
                          <p className="link_text">
                            {props.dynamic ? item : item.title}
                          </p>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
            
            {props?.selectedSupNav === "Header View" && (
              <div className="d-flex gap-4 header_view_tabs">
                {props?.navs?.map((item, index) => (
                  <div
                    key={item.title}
                    className={
                      "lists " + "link_text_container " + item.active_status
                    }
                    data-section={item.data_section}
                  >
                    <a
                      onClick={(e) =>
                        scrollToSection(
                          e,
                          sectionName + item.title 
                        )
                      }
                    >
                      <p className="link_text">{item.title}</p>
                    </a>
                  </div>
                ))}
              </div>
            )}  
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedNavigationHeader; 