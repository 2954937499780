import React, { useState, useEffect, Fragment, useRef } from 'react'
import WorkFlowDetails from './WorkFlowDetails'; 
import DatepickerFunctions from '../../inc/DatepickerHelper';
import DropDownInput from '../Forms/Dropdown';
import Dropdown from "../Forms/Dropdown";
import Input from '../Forms/Input';
import Helper from '../../inc/Helper'; 
import axios from 'axios';
import $ from 'jquery';
import Settings from '../../inc/Settings'; 
import WorkFlowCommonHeaderAPICalls from './WorkflowCommonHeaderAPICalls'; 
import { connect } from 'react-redux';
import { checkIfArrayIsEmpty, isEmptyObject, getPopupMessageBasedOnStatus } from '../../inc/Validation'; 
import SimpleLoading from '../Loading/SimpleLoading';
import OverlayLoader from '../PurchaseRequisition/common/OverlayLoader';
import Api from '../../inc/Api';
import Button from '../inc/Button';

/*

Workflow System - LYNKAZ-

actions triggering view,
actions List / types listings:
    "Approve" 
    "Reject"
    "Request more information"
    "Delegate"
    "Recall"
    "View current assignment" 
    "Task Re-assign" 
    "Action History" 
    "Submit"
    "Sendback"
    "Answer"
*/

let cancelTokenSource = axios.CancelToken.source();
let ActionHistoryAllLogs = null;
let totalDropdownPageUser = 1;
let totalDropdownPageTeam = 1;

const WorkFlowOffCanvas = ({
    closeSidebar, 
    isOpen, 
    type,
    workFlowActionObject,
    submitWorkflowAction,
    reloadFunction,
    source_id,
    source_item_id,
    executeApiOfTaskAction,
    setEnableFields, 
    auth,
    workFlowFunction
}) => {

    const [workflowActionDetails, setWorkflowActionDetails] = useState({
        description: null,
        duration_type: null,
        user_id: null,
        duration: null,
        teams: null,
        positions: null,
        role_id: null,
        new_assignee_type: null,
        completion_policy: null, 
        percentage_of_approver: null
    })
    const [isTransitioning, setIsTransitioning] = useState(false); 
    const [isApiCallMade, setIsApiCallMade] = useState(false);

    // Dropdown States & Refs
    const [positions, setPositions] = useState([]); 
    const [teams, setTeams] = useState([]); 
    const [users, setUsers] = useState([]); 
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [openKPIViolateIndex, setOpenKPIViolateIndex] = useState(null); 
    const [employeeUser, setEmployeeUser] = useState([]);
    const [request, setRequests] = useState([]);
    const [duration, setDuration] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [sources, setSources] = useState([]); 

    // All Data from API for filtering records,  
    const [logs, setLogs] = useState([])
    const workflowActionHistoryRef = useRef([])

    // Toggle Dropdowns Based On Request Types
    const [showPolicy, setShowPolicy] = useState(false) 
    const [showPositions, setShowPositions] = useState(false)
    const [showTeams, setShowTeams] = useState(false)
    const [showUsers, setShowUsers] = useState(false)
    const [showPercentageApprover, setShowPercentageApprover] = useState(false) 

    //Loading States
    const [loadingAssignees, setLoadingAssignees] = useState(true) 

    // Closing Modal Popups & Div Refs
    const KPIViolateModalPopup = useRef(null)
    const DropdownDateModalPopup = useRef(null) 
 
    // API Calls -
    useEffect(() => {   
        $('html').addClass('rs_popup_open'); // triggering the class to remove the scrollbar,

        fetchDataForSystemSources();
        fetchDataFromAPIForDropdowns();
        fetchDataForCurrentAssignee();
        // fetchDataForLogs();
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (KPIViolateModalPopup.current && !KPIViolateModalPopup.current.contains(event.target)) OpenKPIViolate(-1); 
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openKPIViolateIndex]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (DropdownDateModalPopup.current && !DropdownDateModalPopup.current.contains(event.target)) OpenDropdown(-1); 
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openDropdownIndex])

    const fetchDataFromAPIForDropdowns = async () => {
        const apiCalls = new WorkFlowCommonHeaderAPICalls();
    
        try { 
            if (type.label === "Approve" || type.label === "Reject") { // remove this code/condition, when sibte adds task_assignee_name in the api, use that thing then
                const users = await apiCalls.getWorkFlowUsers(1, 10);
    
                const usersDropdownData = users?.map(item => ({
                    value: item.user_id,
                    label: item.employee_display_name
                }));
    
                setUsers(usersDropdownData);
                return;  
            }

            if (["Request more information", "Delegate", "Task Re-assign"].includes(type.label)) {
                const [positions, teams, users, requestTypes, durationType, completionPolicy] = await Promise.all([
                    apiCalls.getWorkFlowPositions(),
                    apiCalls.getWorkFlowTeams(1, 10),
                    apiCalls.getWorkFlowUsers(1, 10),
                    apiCalls.getWorkFlowRequestTypes(),
                    apiCalls.getWorkFlowDurationTypes(),
                    type.label === "Request more information" ? Promise.resolve([]) : apiCalls.getWorkFlowCompletionPolicy()
                ]);
    
                const mapToDropdownData = (items, valueKey, labelKey) => {
                    return items?.map(item => ({
                        value: item[valueKey],
                        label: item[labelKey]
                    }));
                };
     
                setPositions(mapToDropdownData(positions, 'position_id', 'name'));
                setTeams(mapToDropdownData(teams, 'teamheader_id', 'name'));
                setUsers(mapToDropdownData(users, 'user_id', 'employee_display_name'));
                setRequests(mapToDropdownData(requestTypes, 'id', 'name'));
                setDuration(mapToDropdownData(durationType, 'id', 'name'));
                setPolicy(mapToDropdownData(completionPolicy, 'id', 'name'));
            }
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
            setLoadingAssignees(false);
        }
    };
     
    const fetchDataForCurrentAssignee = async () => {
        if(type.label != "View current assignment") return;

        if(!source_id && !source_item_id) return; 

        const apiCalls = new WorkFlowCommonHeaderAPICalls();
        try {
          const currentAssignee = await apiCalls.getWorkFlowCurrentAssignee(auth.user.tenant_id, source_id, source_item_id);
          if (currentAssignee) setEmployeeUser(currentAssignee);
        } catch (error) { console.log(error) } finally { setLoadingAssignees(false) }
    }

    const fetchDataForSystemSources = async () => {
        if(type.label == "View current assignment" || type.label == "Action History" || type.label == "Request more information" || type.label == "Delegate" || type.label == "Task Re-assign") return;

        if(!source_id) return;

        const apiCalls = new WorkFlowCommonHeaderAPICalls()
        const sources = await apiCalls.getAllSystemSources() 

        const sourcesDropdownData = sources.map(item => ({
            value: item.source_id,
            label: item.source_name,
        }))

        setSources(sourcesDropdownData)
    }

    // - Select 2 Settings for POST API calls

    let select2SettingsUser = {
        ajax: {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: (args) => {
                let pageNumber = args.page ? args.page : 1;
                totalDropdownPageUser = pageNumber;
                let pageSize = Settings.dropdownPageSize;
                return `${Settings.apiUrl}/workflow/get_users/${pageNumber}/${pageSize}`;
            },
            data: (dataParams) => JSON.stringify({
                name: dataParams.term ? dataParams.term : null
            }),
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${Api?.getUserToken()}`);
            },
            processResults: (data) => {
                let isMorePageExist = (data?.total_records - (totalDropdownPageUser * Settings.dropdownPageSize)) > 0;
                let result = data?.data?.map(item => ({
                    id: item.user_id,
                    text: item.employee_display_name
                }));
                return {
                    results: result,
                    pagination: {
                        more: isMorePageExist
                    }
                };
            }
        }
    };

    let select2SettingsTeam = {
        ajax: {
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            url: (args) => {
                let pageNumber = args.page ? args.page : 1;
                totalDropdownPageTeam = pageNumber;
                let pageSize = Settings.dropdownPageSize;
                return `${Settings.apiUrl}/workflow/get_teams/${pageNumber}/${pageSize}`;
            },
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${Api?.getUserToken()}`);
            },
            data: function () { 
                return {};
            },
            processResults: (data) => {
                let isMorePageExist = (data?.total_records - (totalDropdownPageTeam * Settings.dropdownPageSize)) > 0;
                let result = data?.data?.map(item => ({
                    id: item.teamheader_id,
                    text: item.name
                }));
                return {
                    results: result,
                    pagination: {
                        more: isMorePageExist
                    }
                };
            }
        }
    };

    // -- Dropdown / Click Handlers

    const handleTransitionEnd = () => {
        if (isOpen) { 
            setIsTransitioning(false);
        }
    };

    const OpenDropdown = (index) => { setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index)); };

    const OpenKPIViolate = (index) => { setOpenKPIViolateIndex((prevIndex) => (prevIndex === index ? null : index)); }

    const handleCloseClick = () => {
        if(isApiCallMade) return;

        $('html').removeClass('rs_popup_open'); // triggering the class to add the scrollbar,

        setIsTransitioning(true);
        closeSidebar(false);
    };
  
    const DescriptionHandler = (event) => {
        let description = event.target.value
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                description: description,
            }
        })
    }

    const getDataFromPaginationDropdown = (apiData) => { 
        ActionHistoryAllLogs = (apiData?.map(element => (
            {
                instance_id: element.instance_id,
                initiated_date: element.initiated_date ? DatepickerFunctions.convertDateTimeFromDataBase(element.initiated_date) : '-',
                logs: element.logs.map((item) => ({
                    name: item.name,
                    due_date: item.due_date ? item.due_date : null,
                    assigned_date: item.created_at ? item.created_at : null,
                    action_taken_at: item.action_taken_at ? item.action_taken_at : null,
                    action_taken: item.action_taken ? `${Object.values(item.action_taken)[0]} by` : null,
                    comment: item.comment,
                    stageName: item.stage ? item.stage : '-',
                    kpi_violate: item.kpi_violate,
                    logo: item.image,
                    duration: item.duration,
                    duration_type: item.duration_type ? item.duration_type[Object.keys(item.duration_type)[0]] : null
                }))
            }
        )))
    }

    const ActionHistoryFiltering = (event) => {  
        let logs = ActionHistoryAllLogs?.find(id => id.instance_id === parseInt(event.target.value));
        if(!isEmptyObject(logs)){
            setLogs(logs.logs)
            return;
        }
        setLogs([]);
    }

    const InputOnChangeHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                duration: evt.target.value
            }
        })
    }

    const RequestTypeHandler = (evt) => {
        const ReqType = request?.find(id => id.value == evt.target.value)?.label;

        switch (ReqType) {
            case "User":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            case "Hierarchy":
                // Handle API call for hierarchy, workflow Team Req
                setShowUsers(false);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(true);
                break;
            case "Team":
                setShowTeams(true);
                setShowPositions(false);
                setShowUsers(false);
                setShowPolicy(true);
                break;
            case "Position":
                setShowPositions(true);
                setShowTeams(false);
                setShowUsers(false);
                setShowPolicy(true);
                break;
            case "Dynamic Team":
                setShowTeams(true);
                setShowPositions(false);
                setShowUsers(false);
                setShowPolicy(true);
                break;
            case "SME":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            case "Requester":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            case "Requester Supervisor's":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            default:
                setShowUsers(false);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
        }
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                new_assignee_type: evt.target.value
            }
        })
    }

    const DurationTypeHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                duration_type: evt.target.value
            }
        })
    }
    
    const CompletionPolicyHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                completion_policy: evt.target.value
            }
        })
        if(evt.target.value == 3) setShowPercentageApprover(true); 
        else setShowPercentageApprover(false);
    }

    const PercentageApproverHandler = (evt) => {
        let value = evt.target.value
        if(value >= 1 && value <= 100) { 
            setWorkflowActionDetails((prevState) => {
                return {
                    ...prevState,
                    percentage_of_approver: value
                }
            })
        }else {
            Helper.alert('Invalid Percentage!', 'error')
        }
    }

    const UsersHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                user_id: evt.target.value
            }
        })
    }
    
    const TeamsHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                teams: evt.target.value
            }
        })
    }

    const PositionsHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                positions: evt.target.value
            }
        })
    }
     
    // ---

    // Modules based on task action types, -
    const ApproveModule = () => { 
        return (
            <div className='approve-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-${type?.label==="Complete"?'complete':'approve'}`}
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'} 
                    from={!checkIfArrayIsEmpty(users) && workFlowActionObject.task_assignee ? users.find(id => id.value === workFlowActionObject.task_assignee)?.label : '-'} 
                /> 
                <div className='input_details'>
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' onChange={DescriptionHandler} maxLength={255} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const RejectModule = () => {
        return (
            <div className='reject-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-reject`}
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    from={!checkIfArrayIsEmpty(users) && workFlowActionObject.task_assignee ? users.find(id => id.value === workFlowActionObject.task_assignee)?.label : '-'} 
                /> 
                <div className='input_details'>
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const RFIModule = () => { 
        return (
            <div className='rfi-module'>
                <WorkFlowDetails 
                    mainHeading={'Request more information'} 
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'} 
                    from={!checkIfArrayIsEmpty(users) && workFlowActionObject.task_assignee ? users.find(id => id.value === workFlowActionObject.task_assignee)?.label : '-'} 
                /> 
                <div className='input_details rfi_module_wrapper'> 
                    <DropDownInput required={true} onChange={RequestTypeHandler} value={workflowActionDetails.new_assignee_type} name='new_assignee_type' options={request} label={'Type'} placeHolder='Select type' id='request_type_id' />
                    {showUsers && 
                        <Dropdown
                            label={'User'} 
                            select2Settings={select2SettingsUser}
                            // pagination={true}
                            // apiUrl={`${Settings.apiUrl}/workflow/get_users`}
                            // allDataRef={usersDropdownDataRef}
                            // mapFunction={(item) => { return {
                            //     text: item.employee_display_name,
                            //     id: item.user_id
                            // } }}
                            id='user_id'
                            placeHolder='Select user'
                            // apiHTTPMethod='POST'
                            // additionalParams={{"name":null}} 
                            reRenderRequired={true}
                            options={users}
                            name='user_id'
                            value={workflowActionDetails.user_id}
                            onChange={UsersHandler}
                        />
                    }
                    {showTeams && 
                        <Dropdown
                            label={'Team'}
                            select2Settings={select2SettingsTeam}
                            placeHolder='Select team'
                            // pagination={true}
                            // apiUrl={`${Settings.apiUrl}/workflow/get_teams`}
                            // allDataRef={teamsDropdownDataRef}
                            // mapFunction={(item) => { return {
                            //     text: item.name,
                            //     id: item.teamheader_id
                            // } }}
                            id='teams_id'
                            reRenderRequired={true}
                            options={teams}
                            name='teams'
                            value={workflowActionDetails.teams}
                            onChange={TeamsHandler}
                        />
                    }
                    {showPositions && 
                        <Dropdown
                            label={'Position'} 
                            id='position_id'
                            reRenderRequired={true}
                            placeHolder='Select position'
                            options={positions}
                            name='positions'
                            value={workflowActionDetails.positions}
                            onChange={PositionsHandler}
                        /> 
                    }
                    <h3 className='time-limit-heading'>Time Limit</h3>
                    <div className='row'>
                        <div className='col-6'>
                            <DropDownInput onChange={DurationTypeHandler} name='duration_type' value={workflowActionDetails.duration_type} options={duration} placeHolder='Select type' label={'Type'} id='type_id' />
                        </div>
                        <div className='col-6'>
                            <Input onChange={InputOnChangeHandler} value={workflowActionDetails.duration} label={'Response within'} id='response_within' />
                        </div> 
                    </div>
                    <label className='mb-2'>Description <span className='asterik_sign'>*</span></label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const DelegateModule = () => {
        return (
            <div className='delegate-module'>
                <WorkFlowDetails 
                    mainHeading={'Delegate To'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'} 
                    from={!checkIfArrayIsEmpty(users) && workFlowActionObject.task_assignee ? users.find(id => id.value === workFlowActionObject.task_assignee)?.label : '-'} 
                /> 
                <div className='input_details delegate_module_wrapper'> 
                    <DropDownInput required={true} onChange={RequestTypeHandler} value={workflowActionDetails.new_assignee_type} name='new_assignee_type' options={request} placeHolder='Select type' label={'Type'} id='request_type_id' />
                    {showUsers && 
                        <Dropdown
                            label={'User'}
                            select2Settings={select2SettingsUser}
                            // pagination={true}
                            // apiUrl={`${Settings.apiUrl}/workflow/get_users`}
                            // allDataRef={usersDropdownDataRef}
                            // mapFunction={(item) => { return {
                            //     text: item.employee_display_name,
                            //     id: item.user_id
                            // } }}
                            id='user_id'
                            placeHolder='Select user'
                            // apiHTTPMethod='POST'
                            // additionalParams={{"name":null}} 
                            reRenderRequired={true}
                            options={users}
                            name='user_id'
                            value={workflowActionDetails.user_id}
                            onChange={UsersHandler}
                        />
                    }
                    {showTeams && 
                        <Dropdown
                            label={'Team'}
                            select2Settings={select2SettingsTeam}
                            // pagination={true}
                            // apiUrl={`${Settings.apiUrl}/workflow/get_teams`}
                            // allDataRef={teamsDropdownDataRef}
                            // mapFunction={(item) => { return {
                            //     text: item.name,
                            //     id: item.teamheader_id
                            // } }}
                            id='teams_id'
                            placeHolder='Select team'
                            reRenderRequired={true}
                            options={teams}
                            name='teams'
                            value={workflowActionDetails.teams}
                            onChange={TeamsHandler}
                        />
                    }
                    {showPositions && 
                        <Dropdown
                            label={'Position'} 
                            id='position_id'
                            placeHolder='Select position'
                            reRenderRequired={true}
                            options={positions}
                            name='positions'
                            value={workflowActionDetails.positions}
                            onChange={PositionsHandler}
                        /> 
                    }
                    {showPolicy && 
                        <Fragment>
                            <h2>Completion policy</h2>
                            <div className='row'>
                                <div className='col-12'>
                                    <DropDownInput onChange={CompletionPolicyHandler} value={workflowActionDetails.completion_policy} name='completion_policy' options={policy} label={'Completion policy'} placeHolder='Select completion policy' id='completion_policy_id' />
                                    {showPercentageApprover && <Input onChange={PercentageApproverHandler} value={workflowActionDetails.percentage_of_approver} name='percentage_of_approver' label={'Percentage Of Approver %'} id='percentage_of_approver_id' />}
                                </div>
                            </div>
                        </Fragment>
                    }
                    <h3 className='time-limit-heading'>Time Limit</h3>
                    <div className='row'>
                        <div className='col-6'>
                            <DropDownInput onChange={DurationTypeHandler} name='duration_type' value={workflowActionDetails.duration_type} options={duration} placeHolder='Select type' label={'Type'} id='type_id' />
                        </div>
                        <div className='col-6'>
                            <Input onChange={InputOnChangeHandler} value={workflowActionDetails.duration} label={'Response within'} id='response_within' />
                        </div> 
                    </div>
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }
    
    const ReCallModule = () => {  
        return (
            <div className='recall-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-recall`}  
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'} 
                    from={!checkIfArrayIsEmpty(users) && workFlowActionObject?.task_assignee ? users.find(id => id.value === workFlowActionObject?.task_assignee)?.label : '-'} 
                /> 
                <div className='input_details'> 
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const CurrentAssignmentModule = () => { 
        const mapWorkflowDetails = () => {
            return (
                !checkIfArrayIsEmpty(employeeUser) &&
                    employeeUser.map((element) =>  (
                        <WorkFlowDetails
                            key={element.id}
                            name={element.workflow_name ? element.workflow_name : '-'}
                            changeLabelOrder={true}
                            stage={element.stage ? element.stage : '-'}
                            task_assignee={element.employee ? element.employee : '-'}
                            initiated_date={element.initiated_date ? DatepickerFunctions.convertDateTimeFromDataBase(element.initiated_date) : '-'}
                            due_date={element.due_date ? DatepickerFunctions.convertDateFromDataBase(element.due_date) : '-'}
                        />
                    )
                )
            );
        };

        return ( 
            <div className='current-assignment-module'>
                <h2 style={{color: '#2D74E0', fontSize: '18px', fontWeight: 600}}>View current assignee</h2>
                {loadingAssignees ? <SimpleLoading /> : ( checkIfArrayIsEmpty(employeeUser) ? <div>No Current Assignee Found</div> : mapWorkflowDetails())}
            </div> 
        )
    }

    const TaskReAssignModule = () => {
        return (
            <div className='task-reassign-module'>
                <WorkFlowDetails 
                    mainHeading={'Re-assignment to'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'} 
                    from={!checkIfArrayIsEmpty(users) && workFlowActionObject.task_assignee ? users.find(id => id.value === workFlowActionObject.task_assignee)?.label : '-'} 
                /> 
                <div className='input_details task_reassign_module_wrapper'> 
                    <DropDownInput required={true} onChange={RequestTypeHandler} value={workflowActionDetails.new_assignee_type} name='new_assignee_type' options={request} placeHolder='Select re-assignment type' label={'Re-assignment type'} id='task_re_assign_id' />
                    {showUsers && 
                        <Dropdown
                            label={'User'}
                            // pagination={true}
                            // apiUrl={`${Settings.apiUrl}/workflow/get_users`}
                            // allDataRef={usersDropdownDataRef}
                            // mapFunction={(item) => { return {
                            //     text: item.employee_display_name,
                            //     id: item.user_id
                            // } }}
                            id='user_id'
                            select2Settings={select2SettingsUser}
                            placeHolder='Select user'
                            // apiHTTPMethod='POST'
                            // additionalParams={{"name":null}} 
                            reRenderRequired={true}
                            options={users}
                            name='user_id'
                            value={workflowActionDetails.user_id}
                            onChange={UsersHandler}
                        />
                    }
                    {showTeams && 
                        <Dropdown
                            label={'Team'}
                            placeHolder='Select team'
                            select2Settings={select2SettingsTeam}
                            // pagination={true}
                            // apiUrl={`${Settings.apiUrl}/workflow/get_teams`}
                            // allDataRef={teamsDropdownDataRef}
                            // mapFunction={(item) => { return {
                            //     text: item.name,
                            //     id: item.teamheader_id
                            // } }}
                            id='teams_id'
                            reRenderRequired={true}
                            options={teams}
                            name='teams'
                            value={workflowActionDetails.teams}
                            onChange={TeamsHandler}
                        />
                    }
                    {showPositions && 
                        <Dropdown
                            label={'Position'} 
                            id='position_id'
                            placeHolder='Select position'
                            reRenderRequired={true}
                            options={positions}
                            name='positions'
                            value={workflowActionDetails.positions}
                            onChange={PositionsHandler}
                        />
                    }
                    {showPolicy && 
                        <Fragment>
                            <h2>Completion policy</h2>
                            <div className='row'>
                                <div className='col-12'>
                                    <DropDownInput onChange={CompletionPolicyHandler} value={workflowActionDetails.completion_policy} name='completion_policy' options={policy} label={'Completion policy'} placeHolder='Select completion policy' id='completion_policy_id' />
                                    {showPercentageApprover && <Input onChange={PercentageApproverHandler} value={workflowActionDetails.percentage_of_approver} name='percentage_of_approver' label={'Percentage Of Approver %'} id='percentage_of_approver_id' />}
                                </div>
                            </div>
                        </Fragment>
                    }
                    <h3 className='time-limit-heading'>Time Limit</h3>
                    <div className='row'>
                        <div className='col-6'>
                            <DropDownInput onChange={DurationTypeHandler} name='duration_type' value={workflowActionDetails.duration_type} options={duration} placeHolder='Select type' label={'Type'} id='type_id' />
                        </div>
                        <div className='col-6'>
                            <Input onChange={InputOnChangeHandler} value={workflowActionDetails.duration} label={'Response within'} id='response_within' />
                        </div> 
                    </div>
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const processUserProfileImage = (image) => { 
        const imageData = image?.slice(2,-1)
        const imageUrl = `data:image/${image};base64,${imageData}`;

        return image ? imageUrl : '/images/blank-profile.jpg';
    };

    const ActionHistoryLogs = () => {
        const mapActionHistoryData = () => {
            return ( 
                <Fragment>
                    <div className='action_history'> 
                        <div className='actions_taken'>
                            <div className='container-fluid'>
                                {!checkIfArrayIsEmpty(logs) && logs.map((element, index) => {
                                    return (
                                        <Fragment>
                                            <div className={`row action_history_info_wrapper ${!element.comment ? 'pb-3' : ''}`}>  
                                                <div className="user-logo-col">
                                                    <div className='user-logo-container'>
                                                        <div className='user-logo' style={{ backgroundImage: `url("${processUserProfileImage(element.logo)}")`, border: !element.logo ? '1px solid #16084C' : 'none' }}></div> 
                                                    </div>
                                                </div> 

                                                <div className="col-10 history-short-details"> 
                                                    <div className='row' style={{position: 'relative'}}> 
                                                        <div className="col-12 justify-content-start d-flex align-items-start"> 
                                                            <h2 style={{ whiteSpace: 'pre-line' }}>
                                                                {element.action_taken === "Submitted by"
                                                                    ? 'Submitter'
                                                                    : element.action_taken === "Recall by"
                                                                    ? "Recalled by Submitter"
                                                                    : element.stageName
                                                                }
                                                            </h2>
                                                        </div>
                                                        <div className="col-10 justify-content-start flex-column d-flex align-items-start"> 
                                                            {element.action_taken !== "Submitted by" && 
                                                                <Fragment> 
                                                                    <div className='mb-1 d-flex'> 
                                                                        <h4 ><span style={{fontWeight: 'bold', color:'#000'}}>Task assigned:</span> {element?.assigned_date?DatepickerFunctions.convertDateTimeFromDataBase(element?.assigned_date):'-'}</h4>
                                                                        { element.kpi_violate && <div className='ms-2 kpi-flag' onMouseEnter={() => OpenKPIViolate(index)}> <img className='img-fluid' src='/images/icons/kpi.svg' /> </div> } 
                                                                        {openKPIViolateIndex === index && (
                                                                            <div className='dropdown-menu-kpi' ref={KPIViolateModalPopup}> 
                                                                                <h5><span>KPI Violated:</span> Have not complete task within assigned time.</h5>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className='mb-1'> <h4><span style={{fontWeight: 'bold', color:'#000'}}>Due at:</span> {element?.due_date?DatepickerFunctions.convertDateTimeFromDataBase(element?.due_date):'-'}</h4></div> 
                                                                    <div className='mb-1'> <h4><span style={{fontWeight: 'bold', color:'#000'}}>KPI:</span> {element.duration}  {element.duration_type}</h4></div>
                                                                    <div className='mb-1'> <h4><span style={{fontWeight: 'bold', color:'#000'}}>Actioned on:</span> {element?.action_taken_at?DatepickerFunctions.convertDateTimeFromDataBase(element?.action_taken_at):'-'}</h4></div>
                                                                </Fragment>
                                                            }
                                                            <h3><span className={element.action_taken == 'Pending' ? 'pending_clr' : 'default_clr'}>{element.action_taken}:</span> {element.name}</h3>
                                                        </div>
                                                        <div className="col-2 list-actions-btn justify-content-center d-flex align-items-center" onClick={() => OpenDropdown(index)}>
                                                            <img className='img-fluid' src='/images/icons/list-actions.svg' /> 
                                                        </div>
                                                    </div>
 
                                                    {openDropdownIndex === index && (
                                                        <div className='dropdown-menu-task' ref={DropdownDateModalPopup}>
                                                            <h5 className='mb-2'>Task assigned: {DatepickerFunctions.convertDateTimeFromDataBase(element?.assigned_date)}</h5>
                                                            <h5>KPI: {element.duration}  {element.duration_type ? element.duration_type+'(s)' : ''}</h5>
                                                        </div>
                                                    )}
                         
                                                    <div className={`user-comment-row ${element.comment ? 'mb-3' : 'mb-2 d-none' }`}>
                                                        <div className="action-history-user-comment-inputarea">
                                                            <div className='action_history_user_comms'> <h3>{element.comment}</h3> </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div> 
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div> 
                    </div>
                </Fragment>
            )
        }
        return (
          <div className="workflow-action-history-module">
            { (
                <Fragment>
                    <h2 className="workflow_action_history_txt">
                        Workflow Action History
                    </h2>
                    <div className='workflow_action_history_wrapper'>
                        <div className='action_history_instance_dropdown_wrapper'>
                            <Dropdown
                                pagination={true}
                                apiHTTPMethod='POST'
                                additionalParams={{
                                    "source_id": source_id,
                                    "sourceitem_id": source_item_id,
                                    "messages_level": "User"
                                }}
                                apiUrl={`${Settings.apiUrl}/workflow/get_actions_history`}
                                allDataRef={workflowActionHistoryRef}
                                reRenderRequired={true}
                                currentDropdownData={getDataFromPaginationDropdown}
                                mapFunction={(element) => {
                                    return {
                                        id: element.instance_id,
                                        text: element.initiated_date ? DatepickerFunctions.convertDateTimeFromDataBase(element.initiated_date) : '-',
                                    } 
                                }}
                                onChange={ActionHistoryFiltering}
                                name="instance"
                                value={null}
                                options={[]}
                                placeHolder='Select instance'
                                label={"Instance"}
                                id="instance_id"
                            />
                        </div> 
                        {/* <div className='action_history_excel_records_export'>
                            <img src='/images/icons/excel-logo.svg' className='img-fluid' />
                        </div> */}
                    </div>
                    {mapActionHistoryData()}
                </Fragment>
            )}
          </div>
        );
    }

    const SubmitModule = () => {
        let currentDate = new Date().toLocaleDateString();
        return (
            <div className='submit-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-submit`}
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={DatepickerFunctions.convertDateFromDataBase(currentDate)}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    from={'-'} 
                /> 
                <div className='input_details'> 
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const SendBackModule = () => { 
        return (
            <div className='send-back-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-send-back`}
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    from={'-'} 
                /> 
                <div className='input_details'> 
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    const AnswerModule = () => {  
        return (
            <div className='answer-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-answer`}
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? workFlowActionObject.initiated_date : '-'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    from={'-'} 
                /> 
                <div className='input_details'> 
                    <label className='mb-2'>Description <span className='asterik_sign'>*</span></label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        ) 
    }

    // ----

    const executeInstanceTaskActionApi = async () => { 
        if (submitWorkflowAction && type.label == "Submit") {  
            submitWorkflowAction(workflowActionDetails.description ? workflowActionDetails.description : 'execute_api');
            setIsApiCallMade(false);
            handleCloseClick(); 
            return;
        }
        
        const conditions = [showUsers && workflowActionDetails.user_id, showTeams && workflowActionDetails.teams, showPositions && workflowActionDetails.positions];
        const resultArray = conditions.filter(Boolean).map(value => parseInt(value));
    
        try {
            const response = await axios.post(
                `${Settings.apiWorkflowUrl}/instance-task/task-action`,
                {
                    "user_id": auth?.user?.user_id,
                    "instance_task_id": workFlowActionObject.instance_task_id,
                    "task_action": parseInt(type.value),
                    "new_assignee_type": workflowActionDetails.new_assignee_type ? parseInt(workflowActionDetails.new_assignee_type) : null,
                    "new_assignee": resultArray,
                    "completion_policy": !showUsers ? (workflowActionDetails.completion_policy ? parseInt(workflowActionDetails.completion_policy) : null) : null,
                    "percentage_of_approver": !showUsers ? (showPercentageApprover ? workflowActionDetails.percentage_of_approver : null) : null,
                    "duration_type": workflowActionDetails.duration_type ? parseInt(workflowActionDetails.duration_type) : null,
                    "duration": workflowActionDetails.duration,
                    "comment": workflowActionDetails.description
                },
                { cancelToken: cancelTokenSource?.token }
            );
    
            if (response?.status === 201) { 
                if (setEnableFields && typeof setEnableFields === 'function') setEnableFields(false);
                if (reloadFunction) reloadFunction();  
                handleCloseClick();
                Helper.alert(response?.data?.message);
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error);
        }
    } 
    
    const taskActionWorkflowActionSubmitApi = () => { 
        Helper.createDebouncedAPIFunction(
            [executeInstanceTaskActionApi], 
            setIsApiCallMade,
            cancelTokenSource
        )();  
    }

    // Execute API call for Submit Button Handler -
    const SubmitAPICall = () => { 
        if (type.label == "Delegate" || type.label == "View current assignment" || type.label == "Request more information") {
            if(!workflowActionDetails.new_assignee_type) {
                Helper.alert("Provide Type!", 'error')
                return;
            }
        }

        if(type.label == "Request more information" || type.label == "Answer"){
            if(!workflowActionDetails.description) {
                Helper.alert("Provide Description!", 'error')
                return;
            }
        }

        if(typeof workFlowFunction === "function"){
            setIsApiCallMade(true); 
            workFlowFunction(type);
            setIsApiCallMade(false); 
        } else { 
            taskActionWorkflowActionSubmitApi();
        }
    }

    {
        typeof executeApiOfTaskAction === 'function' && executeApiOfTaskAction(taskActionWorkflowActionSubmitApi)
    } // when workFlowFunction is being sended, this executeApiOfTaskAction should be sended too, { triggering the take action function from this child component to parent component }

    return (  
        <div className='workflow_offcanvas_header'>  
            <div className={`offcanvas-overlay ${isOpen ? 'show' : ''}`} onClick={handleCloseClick}></div>

            <div className={`offcanvas-container ${isOpen ? 'show' : ''} ${isTransitioning ? 'transitioning' : ''}`} onTransitionEnd={handleTransitionEnd}>
                <div className="offcanvas-content"> 
                    {(type.label == "Approve" || type.label == "Complete") && ApproveModule()}
                    {type.label == "Reject" && RejectModule()}
                    {type.label == "Request more information" && RFIModule()}
                    {type.label == "Delegate" && DelegateModule()}
                    {type.label == "Recall" && ReCallModule()}
                    {type.label == "View current assignment" && CurrentAssignmentModule()}
                    {type.label == "Task Re-assign" && TaskReAssignModule()}
                    {type.label == "Action History" && ActionHistoryLogs()}
                    {type.label == "Submit" && SubmitModule()}
                    {(type.label == "Sendback" || type.label == "Send back") && SendBackModule()}
                    {type.label == "Answer" && AnswerModule()}

                    { isApiCallMade ? <OverlayLoader isPopupInTab={true} isLogoCenter={true} /> : null }

                    <div className='action_btns'>
                        {
                            (type.label !== "Action History") && 
                            (
                                <Button
                                    isDisable={isApiCallMade}
                                    onClick={handleCloseClick}
                                    title={'Close'}
                                    className="black-btn-style"
                                />
                            )
                        }
                        {(type.label !== 'View current assignment' && type.label !== "Action History") ? (
                            <Button
                                isDisable={isApiCallMade}
                                onClick={SubmitAPICall}
                                title={'Submit'}
                                className="blue-btn-style ms-2"
                            />
                        ) : null } 
                    </div>
                    
                    { type.label == "Action History" && 
                        <div className="action-history-close-action-handler">
                            <button onClick={handleCloseClick}><img src="/images/icons/chevron-btn.svg" className="img-fluid" /></button>
                        </div> 
                    }
                </div>
            </div> 
        </div>   
    )
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth, 
        language:state.language
    }
}

export default  connect (mapStateToProps) (WorkFlowOffCanvas); 