import React, { useState, Fragment, useCallback } from 'react';  
import CurrenyRounding from './CurrenyRounding'; 
import Api from '../../../inc/Api'; 
import Settings from '../../../inc/Settings'; 
import Helper from '../../../inc/Helper'; 
import { connect } from 'react-redux'
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';

/* Validations: currency_code (no more than 3), currency_name (no more than 80), currency_symbol (no more than 5), * all 3 required */

let gridApi = null;
let AddBtn;
let transaction;

const Currency = (props) => { 
  const [currencyId, setCurrencyId] = useState(null)
  const [showRoundings, setShowRoundings] = useState(false)

  const CellHandler = (event) => {
    if(event.colDef.field === 'roundings' && event.data.currency_id) { 
      setCurrencyId(event.data.currency_id)
      setShowRoundings(true) 
    }
  }

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.currency_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  const updateData = async (event) => {  
    try {
      let payload = {
        currency_code: event?.data?.currency_code,
        currency_name: event?.data?.currency_name,
        currency_symbol: event?.data?.currency_symbol
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl+`/currency/${event?.data?.currency_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }

  const addNewData = async (event) => {
    try {
      let payload = {
        currency_code: event?.data?.currency_code,
        currency_name: event?.data?.currency_name,
        currency_symbol:event?.data?.currency_symbol
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl+'/currency', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_currency_id = element?.data?.currency_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl + `/currency/${delete_currency_id}`)
      const rowNode = gridApi?.getRowNode(delete_currency_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
    
  /* Grid Column Headers */

  const gridColHeaders = [
    {
      field:'currency_code', 
      headerName:Helper.getLabel(props.language, '3600101', 'Code*'),
      editable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field:'currency_name', 
      headerName:Helper.getLabel(props.language, '3600201', 'Name*'),
      editable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field:'currency_symbol', 
      headerName:Helper.getLabel(props.language, '3600301', 'Symbol*'),
      editable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field:'roundings', 
      cellRenderer: () => gridLinkValueFormat(null, 'Roundings'), 
      headerName:Helper.getLabel(props.language, '3600401', 'Roundings'), 
      editable: false,
      filter: false,
      sortable: false
    }
  ];

  return (
    <Fragment>    
      <NavigationHeder hideMoreBtn={true} backUrl='/lynkaz-administration' title={Helper.getLabel(props.language, '3600501', 'Currency')} />

      <AgGridNew
        apiUrl={Settings.apiUrl+'/currency'}
        pagination={false} 
        columnDefs={gridColHeaders}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={DeleteHandler}
        onCellClicked={CellHandler}
        height={500}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        uniqueField={`currency_id`}
        gridId={'rs_currency_grid'}
      />

      {showRoundings && <CurrenyRounding gridCurrenyApi={gridApi} setCurrency={setCurrencyId} closeGrid={setShowRoundings} currencyId={currencyId} /> } 
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
  
export default connect(mapStateToProps) (MasterComponentWraper((Currency)))