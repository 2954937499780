import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    taxcodes:[]
}
const TaxcodesReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_TAXCODES){
        state = {
            ...state,
            isLoaded:true,
            taxcodes:actions.payload
        }
    }
    return state;
}
export default TaxcodesReducer;