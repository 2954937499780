import React, { Fragment, useCallback} from 'react'
import Api from '../../../inc/Api' 
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { gridActions } from '../../../store/data-grid' 
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import ButtonNew from '../../inc/ButtonNew'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'

let gridApi = null;

const NumberArchive = ( props ) => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
   
  const CellHandler = (event) => {
    if(event.node.data.code !== ''){ 
      if(event.colDef.field === 'code') { 
        navigate(`/number-sequence/edit/${event?.data?.sequencenumber_id}`)
        dispatch(gridActions.NumberSequenceObjectHandler({payload: event?.data}))
      }
    } else {
      Helper.alert('Code Doesnt Exists!', 'error')
    }
  }

  const onDeleteNumber = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    let delNS_ID = element?.data?.sequencenumber_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/numbersequence/${delNS_ID}`)
      const rowNode = gridApi?.getRowNode(delNS_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.number_sequence.number_sequence)){
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }
 
  /* Grid Column Headers  */

  const gridColHeaders = [
    {field:'code', filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language,'260020101','Code') , editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
    {field:'name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'260030101','Name'), editable: false},
    {field:'format.smallest_number', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'260040101','Smallest'), editable: false},
    {field:'format.largest_number', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'260050101','Largest'), editable: false},
    {field:'format.next_number', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'260060101','Next'), editable: false},
    {field:'format.format', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'260070101','Format'), editable: false},
  ];

  return ( 
    <div className='numbers_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'260010101','Number Sequence')}>
          <ButtonNew isDisable={!security.canCreate(frontendIds.setupForms.number_sequence.number_sequence)} to="/new-number" title={Helper.getLabel(props.language,'new','New')} />
        </NavigationHeder> 
 
        <AgGridNew
          apiUrl={Settings.apiUrl+'/numbersequence'} 
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.number_sequence.number_sequence_main_delete_button)}
          pagination={true}
          columnDefs={gridColHeaders} 
          hideAddBtn={true}
          height={500}
          uniqueField={'sequencenumber_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={onDeleteNumber}
          onGridReady={(params) => gridApi = params.api} 
          gridId={"rs_numbers_list"}
        />
      </div>
    </div>  
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.number_sequence.number_sequence
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(NumberArchive, SecurityOptions))))