import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import AgGrid from '../../grid/ag/ag-grid'

const RFQMiscellaneousCharges = (props) => {  
    const GridColumnsMiscellaneousCharges = [
        {field:'miscellaneouscharges', headerName:Helper.getLabel(props.language,'miscellaneouscharges',"Miscellaneous Charges")},
        {field:'amount', headerName:Helper.getLabel(props.language,'amount',"Amount")}
    ]

    const LaborLabels = () => {
      return ( 
        <h2 className='grid_label_rfq me-3'>Miscellaneous Charges</h2>  
      )
    }
 
  return (
    <AgGrid id='rfq_miscellaneous_grid' labels={{btnDelete:  "Remove", btnAdd: "Add Charges", }} header={GridColumnsMiscellaneousCharges} beforeAddButton={LaborLabels} data={[]} hideSave={true} addBtnHide={false} hideDelete={false} />
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}
  
export default connect(mapStateToProps) (RFQMiscellaneousCharges) 