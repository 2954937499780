import React from "react"

const OverlayLoader = ({height,isHeightPercentage,isPopupInTab=false,isLogoCenter}) => {
  return (
    <div className={isPopupInTab ? 'overlay_loader_tab' : 'overlay_loader'} onClick={(event) => event.stopPropagation()}>
     <div className={`d-flex justify-content-center align-items-center ${isLogoCenter?'overlay-fixed':''}`} style={{height:isHeightPercentage?"100%":"100vh"}}>
      <div className='overlay-logo d-flex align-items-center justify-content-center flex-column gap-2'>
        <img src='/images/icons/lynkaz-logo-sign.svg' alt='icon-svg' height={height ?? 60} />
        <p className="user-select-none">Please wait. We're processing your request.</p>
      </div>
     </div>
    </div>
  )
}

export default OverlayLoader;