import React from "react";
import MasterComonent from "../../components/Backend/MasterComonent";
import RequestForInformation from "../../components/RequestForInformation";

const RequestForInformationScreen = () => {
  return (
    <MasterComonent>
      <RequestForInformation />
    </MasterComonent>
  );
};

export default RequestForInformationScreen;
