import React, { Fragment, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import Collapse from '../../../inc/Collapse'
import PriceDiscountTab from './PriceDiscountTab'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import CommonApiCalls from '../CommonApiCalls/CommonApiCalls'
import SimpleLoading from '../../../Loading/SimpleLoading'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import AddressList from '../../../Tenant/Address/AddressList'
import DeliveryTab from '../../../Purchase Orders Internal/Edit PO/DeliveryTab'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'

//for render tabs data
const PurchaseOrderData = (props) => {
  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  //tabs data
  const navs = [
    {
      title: Helper.getLabel(props.language, 'general_information', 'General Information'),
      isActive: true,
    },
    {
      title: Helper.getLabel(props.language, 'additional_information', 'Additional Information'),
    },
    {
      title: Helper.getLabel(
        props.language,
        'price_and_discount',
        'Price & Discount',
      ),
    },
    {
      title: Helper.getLabel(props.language, 'delivery', 'Delivery'),
    },
    {
      title: Helper.getLabel(props.language, 'address', 'Address'),
    },
  ]

  let status = props.status?.toLowerCase()

  //data for map to show purchase order details
  const generalData = [
    {
      id: 1,
      title: 'Document number',
      subtitle: props.purchaseOrderData?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle: props.status,
      className:status === 'confirmed' ?`status-style success-style`:`status-style draft-style`
    },
    {
      id: 3,
      title: 'Name',
      subtitle: props.purchaseOrderData?.vendor_name,
    },
    {
      id: 4,
      title: 'Site',
      subtitle: props.site_name,
    },
    {
      id: 5,
      title: 'Currency',
      subtitle: props.currency_name,
    },
    {
      id: 6,
      title: 'Orderer',
      subtitle: props.purchaseOrderData?.orderer_id||"-",
    },
  ]

  //value formatter for show purpose in ag grid
  const getPurposeById = (params) => {
    const purposeName = props.allPurposes?.find(
      (option) => option.id === params.value,
    )
    return purposeName ? `<div>${purposeName.name}</div>` : null
  }

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <div style={{ background: '#fff' }}>
          <NoteAttachmentHistory
            notesource_integrator={props.purchase_order_id}
            attachmentsource_integrator={props.purchase_order_id}
            source_id={Settings.source_id.purchase_order}
            hideHistory={true}
            condition={props?.purchaseOrderData?.document_status===2}
          />
        </div>
      </MasterSidebar>
    )
  }

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset="300"
      disableSticky={true}
      addComponentRight={addComponentRight}
    >
      <Fragment>
        <div className="bidding_tab mt-3">
         <div className="mt-1 header-tab-rfq">
          <Tabs navs={navs} showAll={false} className="me-5" scrollOffset="300" disableSticky={true}>
            <div className="bidding_proposal_line ps-3 pe-3">
              <Fragment>
                <div className="external-general-information mt-3">
                  <div className="purchase-order-inner-details">
                    {generalData.map((item) => (
                      <div
                        className="row justify-content-between"
                        key={item.id}
                      >
                        <div className="col-sm-6">
                          <span className="purchase-order-external-title">
                            {item.title} :
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Fragment>
            </div>
            <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.purchaseOrderData} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
            </div>
            <div className="bidding_requirement">
              <Collapse
                className="mt-3 mb-3 ms-3 me-3"
                title={Helper.getLabel(
                  props.language,
                  'price_and_discount',
                  'Price & Discount',
                )}
                open={true}
              >
                <PriceDiscountTab
                  isEditable={false}
                  state={props.purchaseOrderData}
                  setState={props.setPurchaseOrderData}
                  name="priceinclude_tax"
                  paymentTermName="paymentterm_id"
                />
              </Collapse>
            </div>
            <div className="bidding_commercial_proposal ps-3 pe-3 ">
              <Collapse
                className="mt-3 mb-3"
                title={Helper.getLabel(props.language, 'delivery', 'Delivery')}
                open={true}
              >
                <DeliveryTab
                  isEditable={false}
                  setState={props.setPurchaseOrderData}
                  state={props.purchaseOrderData}
                />
              </Collapse>
            </div>
            <div className='bidding_commercial_proposal ps-3 pe-3 '>
              <div className='mt-3 mb-3 address-grid'>
                <AddressList
                  disableRecords={true}
                  canEdit={false}
                  address_source_integrator={
                    props.purchaseOrderData?.purchase_order_id
                  }
                  addressSourc_id={props?.purchaseOrderData?.addresssource_id}
                  source_id={props?.purchaseOrderData?.source_id}
                />
              </div>
            </div>
          </Tabs>
          </div>
        </div>
        <div className="mt-3 external-read-only-grid">
          <LineViewComponent
            defaultValues={{ requester_id: null }}
            source_id={props?.purchaseOrderData?.source_id || 12}
            line_source={props?.purchaseOrderData?.source_id || 12}
            isEditable={false}
            userType={props?.usertype}
            header_id={props.purchase_order_id}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={false}
            hideGeneral={false}
            hideSetup={true}
            hideBillingRule={true}
            alternate_line={false}
            lineGridTitle={Helper.getLabel(props.language, 'lines', 'Lines')}
            hideDistributionInnerTabs={true}
            hideAddBtn={true}
            hideDeleteBtn={true}
            hideMoreBtns={true}
          />
        </div>
      </Fragment>
    </Tabs>
  )
}

const PurchaseOrderDetails = (props) => {
  const purchaseOrderCommonApiCalls = new CommonApiCalls()
  const location = useLocation()
  const navigate = useNavigate()
  const [purchaseOrderData, setPurchaseOrderData] = useState({})
  const [loading, setLoading] = useState(false)
  const [allSites, setAllSites] = useState([])
  const [allVendors, setAllVendors] = useState([])
  const [currencies, setCurrency] = useState([])
  const [purchaseStatus, setPurchaseStatus] = useState([])

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const purchase_order_id = getSearchParam('purchase_order_id')
  const goods_receiving_no = getSearchParam('goods_receiving_no')
  const shipment_id = getSearchParam('shipment_id')

  const get_purchase_order_details = () => {
    let api = Api
    api.setUserToken()
    if (purchase_order_id === null || purchase_order_id === undefined) return
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/PO_header/${purchase_order_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setPurchaseOrderData(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  useEffect(() => {
    get_purchase_order_details()
    const getAsyncData = async () => {
      setLoading(true)
      const sites = await purchaseOrderCommonApiCalls.getAllSites()
      const vendors = await purchaseOrderCommonApiCalls.getAllVendors()
      const currencies = await purchaseOrderCommonApiCalls.getAllCurrencies()
      const purchase_statuses =
        await purchaseOrderCommonApiCalls.getAllDocumentStatus()
      if (sites && vendors && currencies && purchase_statuses)
        setLoading(false)
      setAllSites(sites)
      setAllVendors(vendors)
      setCurrency(currencies)
      setPurchaseStatus(purchase_statuses)
    }
    getAsyncData()
  }, [])

  //for sites and vendor and currency details
  const site = allSites?.find(
    (option) => option.site_id === purchaseOrderData?.site_id,
  )
  const vendor = allVendors?.find(
    (option) => option.vendor_id === purchaseOrderData?.vendor_id,
  )
  const currency = currencies?.find(
    (option) => option.currency_id === purchaseOrderData?.currency_id,
  )
  const purchase_status = purchaseStatus?.find(
    (option) => option.id === purchaseOrderData?.document_status,
  )

  return (
    <div className='request_for_quotation_detail_screen external-screens external-detail-screens external-theme responsive-screen-style'>
      <MasterComonent>
        <NavigationHeder
          // backUrl="/purchase-order-list"
          title={`<span style="color:#757575">Purchase Orders List > </span><span style="color:#313638;">${Helper.getLabel(
            props.language,
            'po_listing',
            'Purchase Order Details #',
          )}</span> ${purchaseOrderData?.document_number}`}
          hideMoreBtn={true}
        >
          <Button
            title={Helper.getLabel(props.language, 'close', 'Close')}
            className='black-btn-style'
            onClick={() => {
              navigate(
                -1
              )
            }}
          />         
          <TotalWidget
            source_id={purchaseOrderData?.source_id}
            integrator={purchaseOrderData?.purchase_order_id}
          ></TotalWidget>

          {!goods_receiving_no && !shipment_id && (
            <Button
              title={Helper.getLabel(props.language, 'receiving', 'Receiving')}
              onClick={() => {
                navigate(
                  `/goods-receiving-list?&purchaseorder_id=${purchaseOrderData?.purchase_order_id}`,
                  {state:{document_number:purchaseOrderData?.document_number}}
                )
              }}
            />
          )}

          {/* <Button
            title={Helper.getLabel(
              props.language,
              'credit_note',
              'Credit Note',
            )}
          /> */}
          <Button
            title={Helper.getLabel(
              props.language,
              'invoices',
              'Invoices',
            )}
            onClick={()=>navigate(`/invoice-list?purchase_order_id=${purchase_order_id}`)}
            className='brown-btn-style'
          />
        </NavigationHeder>

        <div className='tabs_default'>
          {loading ? (
            <SimpleLoading />
          ) : (
            <PurchaseOrderData
              props={props}
              purchaseOrderData={purchaseOrderData}
              setPurchaseOrderData={setPurchaseOrderData}
              purchase_order_id={purchase_order_id}
              site_name={site?.name}
              vendor_name={vendor?.name}
              currency_name={currency?.currency_name}
              status={purchase_status?.name}
              usertype={props?.auth?.user?.usertype}
            />
          )}
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(PurchaseOrderDetails)
