import React from 'react'
import { Line } from 'react-chartjs-2'
import CommonChartCard from './common/CommonChartCard'

//data for Line chart
export const data = {
  labels: ['0-3', '3-7', '7-10', '10-13'],
  datasets: [
    {
      data: [30, 20, 40, 10],
      borderColor: '#FFAB00',
      backgroundColor: '#FFAB00',
      borderWidth: 3,
      fill: false,
      pointRadius: 0,
      tension: 0.4,
    },
    {
      data: [20, 10, 45, 20],
      borderColor: '#00AB55',
      backgroundColor: '#00AB55',
      borderWidth: 3,
      fill: false,
      pointRadius: 0,
      tension: 0.4,
    },
  ],
}

const TaskCompletionChart = () => {
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
  }

  return (
    <CommonChartCard title="Task completion aging">
        <Line data={data} options={options} height={300}></Line>
    </CommonChartCard>
  )
}

export default TaskCompletionChart
