import React from 'react';
import Command from '../../../assets/cmd center.svg';
import FileDownload from '../../../assets/file download.svg';
import './DashboardInfo.css';

const DashboardInfo = ({scrollDitection}) => {
  return (
    <div className={
      "dashboard_container_wrapper " 
      // (scrollDitection == "down" ? "" : "dash_nav_position_style_1")
    }>
      <div className='row align-items-center'>
        <div className='col-6 d-flex justify-content-start mt-2 mb-2'>
          <span><img src={Command} className='img-fluid command-center-icon mb-1 me-1' /></span>
          <span className='command-center-text'>Command Center</span>
        </div>
        <div className='col-6 d-flex justify-content-end mt-2 mb-2'>
          <img src={FileDownload} className='img-fluid file-download-icon' />
        </div>
      </div>
    </div>
  )
}

export default DashboardInfo;