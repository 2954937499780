import React, { useState } from 'react'
import Button from '../inc/Button'
import Helper from '../../inc/Helper'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const QuestionnaireForm = (props) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const handleNext = () => {
    if (currentQuestionIndex < props?.QuestionData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
  }

  return (
    <div
      style={{ position: 'fixed', right: '60px', bottom: '60px', width: '600px', height: '650px', background: 'white', boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.25)', borderRadius: '10px', zIndex: 100002, padding: '24px' }}
    >
      <div className='d-flex justify-content-between align-items-center'>
        <div>
           <h5 style={{ fontWeight: 600, margin:"0px" }}>{`General Information ${currentQuestionIndex + 1}/${props?.QuestionData?.length}`}</h5>
        </div>
        <button className='border-0 p-0 bg-transparent' onClick={props.onQuestionnaireClose}>
          <img src="/images/icons/questionclose.svg" alt="cross icon" />
        </button>
      </div>
      <div className='d-flex gap-2 mt-4 mb-4'>
        {props?.QuestionData?.map((item, index) => {
          return <div key={item} style={{ width: '12px', height: '12px', borderRadius: '50%', background: index <= currentQuestionIndex ? '#2D74E0' : '#ECECEC' }}></div>
        })}
      </div>
      <div style={{ border: '1px solid #DEE8F4', minHeight: '400px', borderRadius: '10px' }}>
        <div style={{ padding: '16px', borderBottom: '1px solid #DEE8F4' }}>
          <h5 style={{ color: '#2D74E0', marginBottom: '0' }}>{props?.QuestionData[currentQuestionIndex].question}</h5>
        </div>
        <div style={{ padding: '16px' }}>
          {props?.QuestionData[currentQuestionIndex].inputType === 'multiple choice' && (
            <div className='d-flex flex-column gap-2'>
              {props?.QuestionData[currentQuestionIndex].options.map((option, index) => (
                <div key={option} className='d-flex'>
                  <input type='radio' id={option} name='answer' value={option} />
                  <label htmlFor={option} style={{ marginLeft: '8px' }}>
                    {option}
                  </label>
                </div>
              ))}
            </div>
          )}
          {props?.QuestionData[currentQuestionIndex].inputType === 'checkbox' && (
            <div className='d-flex flex-column gap-2'>
              {props?.QuestionData[currentQuestionIndex].options.map((option, index) => (
                <div key={option} className='d-flex'>
                  <input type='checkbox' id={option} name='answer' value={option} />
                  <label htmlFor={option} style={{ marginLeft: '8px' }}>
                    {option}
                  </label>
                </div>
              ))}
            </div>
          )}
          {props?.QuestionData[currentQuestionIndex].inputType === 'rating' && (
            <div className='d-flex flex-column gap-2'>
              {props?.QuestionData[currentQuestionIndex].options.map((option, index) => (
                <div key={option} className='d-flex'>
                  <input type='radio' id={option} name='answer' value={option} />
                  <label htmlFor={option} style={{ marginLeft: '8px' }}>
                    {option}
                  </label>
                </div>
              ))}
            </div>
          )}
          {props?.QuestionData[currentQuestionIndex].inputType === 'text' && (
            <div>
              <textarea label={Helper.getLabel(null, '')} className='w-100' style={{ borderRadius: '10px', minHeight: '200px' }} />
            </div>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-between w-100 mt-5'>
        <div className='d-flex gap-3 justify-content-center align-items-center'>
          <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip style={{zIndex:100003}}>{props?.info ? props?.info:'This information is about finance.'}</Tooltip>}>
            <div className='d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', background: '#2D74E014', borderRadius: '50%', cursor: 'pointer' }}>
              <img src='/images/icons/overviewicon.svg' alt='overview' />
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip style={{zIndex:100003}}>Please read your filled information properly before submit.</Tooltip>}>
            <div className='d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px', background: '#2D74E014', borderRadius: '50%', cursor: 'pointer' }}>
              <img src='/images/icons/questionnotes.svg' alt='overview' />
            </div>
          </OverlayTrigger>
        </div>
        <div className='d-flex gap-2'>
          <Button className='orange-btn-style' onClick={handlePrevious} title={Helper.getLabel(null, 'previous', 'Previous')} />
          {currentQuestionIndex < props?.QuestionData.length - 1 ? (
            <Button className='orange-btn-style' onClick={handleNext} title={Helper.getLabel(null, 'next', 'Next')} />
          ) : (
            <Button className='orange-btn-style' onClick={props.handleSubmit} title={Helper.getLabel(null, 'submit', 'Submit')} />
          )}
        </div>
      </div>
    </div>
  )
}

export default QuestionnaireForm
