import React, { useEffect, useRef, useState } from "react";
import {
  apiHasParams,
  checkIfArrayIsEmpty,
  filterVisibleColumns,
} from "../../../../inc/Validation";
import Dropdown from "../../../Forms/Dropdown";
import Helper from "../../../../inc/Helper";
import ListingCommonCalls from "./ListingCommonCalls"; 
import NewInputDatePicker from "../../../Forms/NewInputDatePicker";
import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import { APIUrlsOnDropdown, ColumnsToExclude, FieldsForDatePickers } from "./APIUrlsBasedOnDropdown"; 

/*

Advance Search Filter: Common Search Functionality for Ag-Grid

(Input Types Allowed)
Normal Input Field,
Datepicker Field ( Normal Dates - Dates With Time ) { Ensure, that the actual prop name with its respective type (date or datetime), do exists inside the seed data file },
Dropdown ( Ensure, that the actual prop name & corresponding Api Url do exist inside the APIUrls seed data file ),

*Core Function/Functionalities?
- its includes the searching based on different types of fields,
- if user adds a new column using Floating Filters, the user can perform respective search on that field too,
- its ensures that if the dropdown is selected, the data loaded inside the dropdown is coming according to the same respective field selected by the user,
- it also includes the functionality where there's only search input field no operations & column field available for searches,

*Props: 
apiUrlForSearch : ( example: 'domain/vendors' ) - it will use this params api to perform search queries entered by the user,
searchOnDocument : ( example: 'invoices' ) - differentiator because columns ( field names ) inside the file was manipulated, hence, the data returning from search query should be according to that props,
searchWithoutFieldKey : ( Boolean, default:false ) - it will render a search field only with any operations & field dropdown

*/

const AdvanceSearchFilterDropdown = ({
  gridOptionsAPI,
  gridData = [],
  pageSize = 10, 
  apiUrlForSearch = "",
  searchOnDocument = "",
  searchWithoutFieldKey = false
}) => {
    const [searchKey, setSearchKey] = useState({
        field: null,
        operation: null,
        key: null,
    });

    const [apiObj, setAPIObject] = useState({
        apiUrl: null,
        valueProp: null,
        labelProp: null
    });

    const [inputType, setInputType] = useState("field");
    const [dateType, setDateType] = useState("");

    const [fields, setFields] = useState([]);
    const [operations, setOperations] = useState([]); 
    const [dropdownData, setDropdownData] = useState([]);

    const dropdownDataRef = useRef([]); 
    const popupModalRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const gridOptions = gridOptionsAPI(); 
    const allGridColums = gridOptions?.api?.columnModel?.displayedColumns;

    const advanceFilterApiCalls = new ListingCommonCalls();

    useEffect(() => { getOperationsEnum() }, []);
    useEffect(() => { extractFormFieldsFromColumnHeaders() }, [gridOptions, allGridColums]);
    useEffect(() => { getDropdownData() }, [apiObj]); 
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupModalRef.current && !popupModalRef.current.contains(event.target)) setIsOpen(false); 
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [isOpen]);
     
    const getOperationsEnum = async () => {  
        const enumFilterOptionsData = await advanceFilterApiCalls.getFilterOptionsEnum();
        setOperations(enumFilterOptionsData); 
    };

    const getDropdownData = async () => { 
        const dropdownData = await advanceFilterApiCalls.getDropdownsData(
            apiObj.apiUrl, 
            apiObj.valueProp, 
            apiObj.labelProp
        );
        setDropdownData(dropdownData);
    };

    const extractFormFieldsFromColumnHeaders = () => { 
        const columnsToUse = allGridColums?.map(item => ({
            field: item?.colDef?.field,
            headerName: item?.colDef?.headerName
        }));

        const columnsToInclude = columnsToUse?.filter(item => !ColumnsToExclude?.includes(item?.field));
        const filteredCols = filterVisibleColumns(columnsToInclude);
     
        const updatedData = filteredCols?.map(item => ({
            value: item.field,
            label: item.headerName,
        }));

        setFields(updatedData);
    };

    const onResetBtnClick = () => { gridOptions.api.setRowData(gridData) };

    const OutputDifferentFieldForSearch = () => {
        if(searchKey.field == 'sh_document_status' || searchKey.field == 'inv_document_status' || searchKey.field == 'po_document_status' || searchKey.field == 'workflow_status' || searchKey.field == 'co_document_status') return 'document_status'; 
        if(searchKey.field == 'requester_name') return 'requester_id';
        if(searchKey.field == 'status_name') return 'status';
        if(searchKey.field == 'vendor_name' || searchKey.field == 'vendor_code') return 'vendor_id'; 
        if(searchKey.field == 'vendorsite_name' || searchKey.field == 'site_id') return 'vendorsite_id';

        else return searchKey.field; 
    };

    const OutputDifferentTypeForSearch = () => {
        if(inputType == 'datepicker' && dateType?.includes('time')) return DatepickerFunctions.convertDateTimeForDataBase(searchKey.key);
        if(inputType == 'datepicker' && !dateType?.includes('time')) return DatepickerFunctions.convertDateForDataBase(searchKey.key);
        if(inputType == 'dropdown') return parseInt(searchKey.key);

        else return searchKey.key;
    };

    const onSearchBtnClick = async () => {
        try {
            if (!apiUrlForSearch || !gridOptions) return;
    
            if (!searchWithoutFieldKey && (!searchKey.field || !searchKey.key || !searchKey.operation)) {
                Helper.alert("Please provide all 3 fields to perform search!", "error");
                return;
            }
    
            if (searchWithoutFieldKey && !searchKey.key) {
                Helper.alert("Please provide key to perform search!", "error");
                return;
            }
    
            let apiUrlWithParams = "";
            let querySearchString = "";
    
            const leftFieldForSearch = OutputDifferentFieldForSearch();
            const rightFieldForSearch = inputType === 'dropdown'
                ? OutputDifferentTypeForSearch()
                : `"${OutputDifferentTypeForSearch()}"`;
    
            if (!searchWithoutFieldKey) {
                querySearchString = `adv_search=[{"left":"${leftFieldForSearch}","right":${rightFieldForSearch},"operation":"${searchKey.operation}"}]&page_size=${pageSize}`;
            } else {
                querySearchString = `search=${searchKey.key}&page_size=${pageSize}`;
            }
      
            if (apiHasParams(apiUrlForSearch)) {
                apiUrlWithParams = `${apiUrlForSearch}&${querySearchString}`;
            } else {
                apiUrlWithParams = `${apiUrlForSearch}?${querySearchString}`;
            }
        
            let searchResult = await advanceFilterApiCalls.getDataFromSearchParams(
                apiUrlWithParams,
                searchOnDocument
            );
 
            gridOptions.api.setRowData([]);  // resetting the grid, so, that when new data places in, it doesnt overlap with the previous ones.

            if (checkIfArrayIsEmpty(searchResult)) {  
                gridOptions.api.setRowData(gridData);
                gridOptions.api.refreshCells();

                return;
            };

            gridOptions.api.setRowData(searchResult);
            gridOptions.api.refreshCells(); 
        } catch (error) {
            Helper.alert('Aplogies, something went wrong from our side!', 'error');
        }
    };

    const handleToggleClick = () => { setIsOpen(!isOpen) };
    
    const determineInputType = (inputField = '') => {
        const apiObject = APIUrlsOnDropdown?.find(val => val.prop === inputField);
        const datesObject = FieldsForDatePickers?.find(val => val.prop === inputField);
    
        if (apiObject) {
            setAPIObject((prevState) => ({
                ...prevState,
                apiUrl: apiObject?.apiUrl,
                valueProp: apiObject?.value,
                labelProp: apiObject?.label
            }));
    
            setSearchKey((prevState) => ({ ...prevState, key: null }));
            setInputType('dropdown');
            return;
        }
    
        if (datesObject) { 
            setSearchKey((prevState) => ({ ...prevState, key: new Date() }));
    
            setDateType(datesObject?.type === 'date' ? 'date' : 'datetime');
            setInputType('datepicker');
            return;
        }
    
        setSearchKey((prevState) => ({ ...prevState, key: '' }));
        setInputType('field');
    };
    

    const updateSearchKey = (field, value) => {  
        setSearchKey((prevState) => ({ ...prevState, [field]: value }));
    };

    const onFieldChange = (e) => { 
        let { name, value } = e.target;  
        determineInputType(value);
        updateSearchKey(name, value);
    };

    const onOperationValueFieldChange = (e) => {
        let { name, value } = e.target;
        updateSearchKey(name, value);
    };

    const onDatePickerValueChange = (value, name) => { updateSearchKey(name, value) }; 
    return (
        <div 
            ref={popupModalRef}
            className="ag-grid-advance-filter"
        >
            <button
                className={`dropdown-toggle ${
                    isOpen ? "dropdown-active-cls" : "active-cls"
                }`}
                type="button"
                onClick={handleToggleClick}
            >
                Advance Search
                <img
                    className="img-fluid ms-2 mb-1"
                    src={
                        isOpen
                        ? "/images/icons/chevron-up-filled.svg"
                        : "/images/icons/chevron-down-filled.svg"
                    }
                    alt="Chevron"
                />
            </button>

            {isOpen && (
                <div 
                    className="search-dropdown-menu"   
                    style={{
                        minWidth: searchWithoutFieldKey ? '330px' : '625px',
                        left: !searchWithoutFieldKey ? '-458px' : '-163px',
                    }}
                >
                    <div className="row d-flex justify-content-center align-items-center">
                        {!searchWithoutFieldKey && 
                            <div className="col-3">
                                <Dropdown
                                    placeHolder="Select"
                                    disableClear={true}
                                    required={true}
                                    id="select_field_searchbox_grid"
                                    label={"Field"}
                                    options={fields}
                                    reRenderRequired={true}
                                    name="field"
                                    onChange={onFieldChange}
                                    value={searchKey.field}
                                />
                            </div>
                        } 
                        {!searchWithoutFieldKey && 
                            <div className="col-3">
                                <Dropdown
                                    placeHolder="Select"
                                    disableClear={true}
                                    required={true}
                                    id="select_operation_searchbox_grid"
                                    label={"Operation"}
                                    options={operations}
                                    name="operation"
                                    onChange={onOperationValueFieldChange}
                                    value={searchKey.operation}
                                />
                            </div>
                        }
                        <div className={`${searchWithoutFieldKey ? 'col-12 mb-4 mt-2' : 'col-6'}`}> 
                            { inputType == "field" && (
                                <div className="rs_gh_search_box">
                                    <input
                                        placeholder="Search"
                                        name="key"
                                        value={searchKey.key}
                                        onChange={onOperationValueFieldChange}
                                        className="form-control"
                                    />
                                </div>
                            )}
                            { inputType == "dropdown" && (
                                <Dropdown
                                    label={"Select Value"}
                                    pagination={true}
                                    apiUrl={apiObj?.apiUrl}
                                    allDataRef={dropdownDataRef}
                                    mapFunction={(item) => { return {
                                        text: item[apiObj?.labelProp],
                                        id: item[apiObj?.valueProp]
                                    } }}
                                    id={`${searchKey.field}_id`}
                                    disableClear={true}
                                    required={true}
                                    reRenderRequired={true}
                                    options={dropdownData}
                                    name={'key'}
                                    value={searchKey.key}
                                    onChange={onOperationValueFieldChange}
                                />
                            )}
                            { inputType == "datepicker" && (
                                <NewInputDatePicker
                                    timePicker={dateType?.includes('time')}
                                    label={"Select Date"}
                                    value={searchKey.key}
                                    onChange={onDatePickerValueChange}
                                    id={`${searchKey.field}_id`}
                                    required={true}
                                    name={'key'} 
                                />
                            )} 
                        </div>
                    </div>
                    <div className="search-key-btn">
                        <button className="search-cls me-2" onClick={onSearchBtnClick}>
                            Search
                        </button>
                        <button className="default-cls" onClick={onResetBtnClick}>
                            Reset
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdvanceSearchFilterDropdown; 