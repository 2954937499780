export const BudgetTransfer_Data = [
    {
      id: 1,
      document_number: 'BT-001',
      requester_name: 'John Doe',
      department_division: 'Head Office', // from code "HOF"
      segmentvalue_id: 66, // from segmentvalue_id of "HOF"
      from_budget: 'IT Projects',
      to_budget: 'Infrastructure Upgrade',
      transfer_reason: 'Budget reallocation for team expansion',
      total_transfer_amount: '50,000',
      approval_status: 'In-review',
      status_id: 3,
      approvers: 'Jane Smith',
      approver_id: 1,
      approval_date: new Date('2024-10-01').toString(),
      comments: 'Pending manager approval',
      title: 'HR Budget Reallocation',
      description: 'Reallocation of HR budget for team growth and expansion',
      priority_id: 2,
      priority_name: 'High',
    },
    {
      id: 2,
      document_number: 'BT-002',
      requester_name: 'Emily Clark',
      department_division: 'Store Management', // from code "STMT"
      segmentvalue_id: 68, // from segmentvalue_id of "STMT"
      from_budget: 'Training Budget',
      to_budget: 'Employee Development',
      transfer_reason: 'HR team development',
      total_transfer_amount: '30,000',
      approval_status: 'Re-call',
      status_id: 2,
      approvers: 'Michael Scott',
      approver_id: 2,
      approval_date: new Date('2024-09-30').toString(),
      comments: 'Recalled for revision',
      title: 'Admin Budget Reallocation',
      description: 'Reallocation of admin budget for HR team development',
      priority_id: 1,
      priority_name: 'Low',
    },
    {
      id: 3,
      document_number: 'BT-003',
      requester_name: 'Michael Adams',
      department_division: 'Recruitement', // from code "RCRT"
      segmentvalue_id: 69, // from segmentvalue_id of "RCRT"
      from_budget: 'Sales Tools',
      to_budget: 'CRM Migration',
      transfer_reason: 'Procurement project expansion',
      total_transfer_amount: '100,000',
      approval_status: 'Draft',
      status_id: 1,
      approvers: 'Sara Lee',
      approver_id: 3,
      approval_date: new Date('2024-09-15').toString(),
      comments: 'Awaiting initial review',
      title: 'Procurement Budget Reallocation',
      description: 'Reallocation of procurement budget for project expansion',
      priority_id: 2,
      priority_name: 'High',
    },
    {
      id: 4,
      document_number: 'BT-004',
      requester_name: 'David Johnson',
      department_division: 'Head Office', // from code "HOF"
      segmentvalue_id: 66, // from segmentvalue_id of "HOF"
      from_budget: 'Financial Systems',
      to_budget: 'Audit Tools',
      transfer_reason: 'Project cost management',
      total_transfer_amount: '75,000',
      approval_status: 'Approved',
      status_id: 4,
      approvers: 'Lisa Williams',
      approver_id: 4,
      approval_date: new Date('2024-10-05').toString(),
      comments: 'Transfer finalized',
      title: 'Finance Budget Reallocation',
      description: 'Reallocation of finance budget for procurement project cost management',
      priority_id: 1,
      priority_name: 'Low',
    },
    {
      id: 5,
      document_number: 'BT-005',
      requester_name: 'Sophia Turner',
      department_division: 'HR Technology', // from code "HRTC"
      segmentvalue_id: 73, // from segmentvalue_id of "HRTC"
      from_budget: 'Vendor Relations',
      to_budget: 'Supplier Database Upgrade',
      transfer_reason: 'Staff welfare management',
      total_transfer_amount: '45,000',
      approval_status: 'In-review',
      status_id: 3,
      approvers: 'Brian Lewis',
      approver_id: 5,
      approval_date: new Date('2024-10-02').toString(),
      comments: 'Awaiting final approval',
      title: 'HR to Finance Budget Reallocation',
      description: 'Reallocation of HR budget for staff welfare management under finance',
      priority_id: 2,
      priority_name: 'High',
    },
    {
      id: 6,
      document_number: 'BT-006',
      requester_name: 'Jack Brown',
      department_division: 'Store', // from code "STR"
      segmentvalue_id: 67, // from segmentvalue_id of "STR"
      from_budget: 'Admin Budget 2024',
      to_budget: 'Procurement Budget 2024',
      transfer_reason: 'Procurement assistance for new project',
      total_transfer_amount: '60,000',
      approval_status: 'Approved',
      status_id: 4,
      approvers: 'Katherine Moore',
      approver_id: 6,
      approval_date: new Date('2024-09-28').toString(),
      comments: 'Approved by management',
      title: 'Admin to Procurement Budget Reallocation',
      description: 'Reallocation of admin budget to support procurement for a new project',
      priority_id: 1,
      priority_name: 'Low',
    },
  ]
  