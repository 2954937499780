const QuestionBidSubmissionData = [
    {
        question: 'Can you outline your experience with similar solutions, such as test solutions or automated vision inspection systems?',
        inputType: 'multiple choice',
        options: [
          'No prior experience',
          'Limited experience (1-2 similar projects)',
          'Moderate experience (3-5 similar projects)',
          'Extensive experience (more than 5 similar projects)'
        ],
      },
      {
        question: 'What is your expected timeline for delivery, considering our requirements for systems like turret tests or laser marking?',
        inputType: 'multiple choice',
        options: [
          'Less than 3 months',
          '3-6 months',
          '6-9 months',
          'More than 9 months'
        ],
      },
      {
        question: 'How do you ensure quality standards in manufacturing processes, especially for complex systems like MEMS sensors or power modules?',
        inputType: 'multiple choice',
        options: [
          'Basic quality checks at the end of production',
          'Regular quality checks at multiple stages',
          'ISO-certified quality assurance process',
          'Industry-leading quality management practices with continuous monitoring'
        ],
      },
      {
        question: 'Do you have certifications or compliance documentation that align with industry standards for our required services?',
        inputType: 'multiple choice',
        options: [
          'No certifications',
          'Basic compliance documentation',
          'ISO certification or equivalent',
          'Multiple industry-standard certifications (ISO, CE, etc.)'
        ],
      },
      {
        question: 'Can you provide a cost breakdown for each phase of the project?',
        inputType: 'multiple choice',
        options: [
          'Not possible to provide a detailed breakdown',
          'Basic cost estimate only',
          'Detailed cost breakdown by major phases',
          'Comprehensive cost breakdown with phase-wise and task-level details'
        ],
      },
  ]
  
  export default QuestionBidSubmissionData
  