import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
  cartCount: 0,
  wishlistCount: 0,
  wishlistIds: [],
  checkoutLineIds: [],
  showEditNewCartModal: false,
  showEditOldCartModal: false,
  activeCartItem: null,
  didCartUpdate: false,
  totalPRAmount: 0,
  lastChangedVendor: null,
  showApplyVendorModal: false,
};
const PRShopReducers = (state = DefaultState, actions) => {
  if (actions.type === ActionTypes.CHANGE_CART_COUNT) {
    state = {
      ...state,
      cartCount: actions.payload,
    };
  }
  if (actions.type === ActionTypes.CHANGE_WISHLIST_COUNT) {
    state = {
      ...state,
      wishlistCount: actions.payload,
    };
  }
  if (actions.type === ActionTypes.UPDATE_WISHLIST_IDS) {
    state = {
      ...state,
      wishlistIds: [...actions.payload],
    };
  }
  if (actions.type === ActionTypes.UPDATE_CHECKOUT_LINE_IDS) {
    state = {
      ...state,
      checkoutLineIds: [...actions.payload],
    };
  }
  if (actions.type === ActionTypes.SHOW_EDITNEWCART_MODAL) {
    state = {
      ...state,
      showEditNewCartModal: true,
    };
  }
  if (actions.type === ActionTypes.CLOSE_EDITNEWCART_MODAL) {
    state = {
      ...state,
      showEditNewCartModal: false,
    };
  }
  if (actions.type === ActionTypes.SHOW_EDITOLDCART_MODAL) {
    state = {
      ...state,
      showEditOldCartModal: true,
      activeCartItem: { ...actions.payload },
    };
  }
  if (actions.type === ActionTypes.CLOSE_EDITOLDCART_MODAL) {
    state = {
      ...state,
      showEditOldCartModal: false,
      activeLineId: null,
      isDistributionClose:true,
    };
  }
  if (actions.type === ActionTypes.RESET_EDITOLDCART_MODAL) {
    state = {
      ...state,
      isDistributionClose:false,
    };
  }
  if (actions.type === ActionTypes.UPDATE_ACTIVRCARTITEM) {
    state = {
      ...state,
      activeCartItem: { ...actions.payload },
    };
  }
  if (actions.type === ActionTypes.TOGGLE_DIDCARTUPDATE) {
    state = {
      ...state,
      didCartUpdate: !state.didCartUpdate,
    };
  }
  if (actions.type === ActionTypes.UPDATE_TOTAL_PR_AMOUNT) {
    state = {
      ...state,
      totalPRAmount: actions.payload,
    };
  }
  if (actions.type === ActionTypes.UPDATE_VENDOR_CHANGED) {
    state = {
      ...state,
      lastChangedVendor: actions.payload,
    };
  }
  if (actions.type === ActionTypes.SHOW_APPLY_VENDOR_MODAL) {
    state = {
      ...state,
      showApplyVendorModal: true,
    };
  }
  if (actions.type === ActionTypes.CLOSE_APPLY_VENDOR_MODAL) {
    state = {
      ...state,
      showApplyVendorModal: false,
    };
  }
  return state;
};
export default PRShopReducers;
