import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import DropDownInput  from '../Forms/Dropdown';
import { Link } from 'react-router-dom';

const SearchBox = ({ language, auth, user }) => {
    const [sKey, setSKey] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedFilter,setSelectedFilter]=useState(null)
    const [filterOptions,setFilterOptions]=useState([])

    const onSearchValueChange = (event) => {
        setSKey(event.target.value);
        if (event.target.value) {
            setIsSearchActive(true);
            searchByText(event.target.value);
        } else {
            setIsSearchActive(false);
            setSearchResult([]);
        }
    };

    const searchByText = (searchText,index) => {
        if (searchText === '') {
            setSearchResult([]);
            return;
        }
        const api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiOrgUrl + '/elastic', { query: searchText, index: selectedFilter || null })
            .then((res) => {
                setSearchResult(res.data);
            });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.target.value !== '') {
            setIsSearchActive(true);
            searchByText(event.target.value);
        }
    };

    const getFilterOptions = () => {
        if(user?.admin_user) return;

        let api = Api
        Api.setUserToken()
        let url = auth?.user?.usertype===1 ?'/enum/elastic_search_external' :'/enum/elastic_search_internal'
        api.axios().get(Settings.apiUrl+url).then(function(res){
            if(res.data.status==="success"){
                let filterUpdatedData = []
                res?.data?.data?.map((item) => { filterUpdatedData.push({value: item.id, label: item.name}) })
                setFilterOptions(filterUpdatedData)
            }
        }).catch((res) => {

        })
    }

    useEffect(()=>{
        getFilterOptions()
    },[])

    const handleDropdownChange = (e) => {
        setSelectedFilter(parseInt(e.target.value))
        searchByText(sKey,parseInt(e.target.value))
    }

    const getInternalLinkUrl = (type, id) => {
        let url = '';
        switch(type){
            case 'Item':
                url += '/edit-item/'+id;
                break;
            case 'Vendor':
                url += '/edit-vendor/'+id;
                break;
            case 'Purchase Order':
                url += '/purchase-order/edit/'+id;
                break;
            case 'Contract':
                url += '/contract-order/edit/'+id;
                break;
            case 'Request For Quotation':
                url += '/request-for-quotation/rfqdetailform?rfq_view_id='+id+'&return=rfqheaderlist';
                break;
            case 'Invoice':
                url += '/invoice-details/'+id;
                break;
            case 'Work Confirmation':
                url += '/work-confirmation-details?shipment_id='+id;
                break;
            case 'Goods Receiving':
                url += '/goods-receiving-details?shipment_id='+id;
                break;
            case 'Purchase Requisition':
                url += '/purchase-requisition/summary?pr_view_id='+id+'&return=pr';
                break;
            case 'Request For Quotation VR':
                url += '/request-for-quotation-reply/'+id;
                break;
            default:
                url += '/'
                break;
        }
        return url;
    }

    const getVendorPortalLinkUrl = (type, id) => {
        let url = '';
        switch(type){
            case 'Purchase Order':
                url += '/purchase-order-details?purchase_order_id='+id;
                break;
            case 'Contract':
                url += '/contract-order-details?&purchase_order_id='+id;
                break;
            case 'Invoice':
                url += '/invoice-details?invoice_id='+id;
                break;
            case 'Work Confirmation':
                url += '/work-confirmation-details?shipment_id='+id;
                break;
            case 'Goods Receiving':
                url += '/goods-receiving-details?shipment_id='+id;
                break;
            case 'Request For Quotation VR':
                url += '/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id='+id;
                break;
            default:
                url += '/'
                break;
        }
        return url;
    }

    const displaySearchResult = () => {
        if(searchResult.length <= 0 || !isSearchActive) {
            return <></>
        }
        const sIconUrl ='/images/icons/search-light.svg';
        return (
            <div className="search_result_dropdown">
                <ul>
                    {searchResult.map((result) => {
                        const resultData = result._source;
                        return (
                            <li key={result?._id}>
                                <Link
                                    to={auth?.user?.usertype === 1
                                        ? getVendorPortalLinkUrl(resultData?.type, result._id)
                                        : getInternalLinkUrl(resultData?.type, result._id)}
                                    style={{ backgroundImage: 'url(' + sIconUrl + ')' }}
                                    onMouseDown={(e) => e.preventDefault()} 
                                >
                                    <span className='sr_text'>{resultData.name}</span>
                                    <span className='sr_code'> - {resultData.code}</span>
                                    <span className='sr_type'>{resultData.type}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    return (
        <div className='search_box_section' onMouseEnter={() => sKey !== '' && setIsSearchActive(true)} onMouseLeave={() => setTimeout(() => setIsSearchActive(false), 200)}>
            <div className={'search_box_section_inner ' + (isSearchActive ? 'active' : '') + (searchResult.length >= 1 ? ' has_result' : '')}>
                <form className='s_form' onSubmit={handleFormSubmit}>
                    <div className='icon'><img src="/images/icons/search.svg" alt=""/></div>
                    <div className='input_box'>
                        <input type="text" value={sKey} onChange={onSearchValueChange} onFocus={() => sKey !== '' && setIsSearchActive(true)} onBlur={() => setTimeout(() => setIsSearchActive(false), 200)} placeholder={Helper.getLabel(language, 'search', 'Search')} onKeyDown={handleKeyDown} />
                    </div>
                    <div className='options_box'>
                        <DropDownInput inputType="dropdown" options={filterOptions} placeholder={Helper.getLabel(language,'all','All')} onChange={handleDropdownChange} />
                    </div>
                </form>
                {displaySearchResult()}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language,
        user:state.auth.user
    }
}
export default connect(mapStateToProps)(SearchBox);