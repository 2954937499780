import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    all:[]
}
const EmployeeReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_EMPLOYEE_LIST){
        state = {
            ...state,
            isLoaded:true,
            all:actions.payload
        }
    }
    return state;
}
export default EmployeeReducer;