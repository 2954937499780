import React from 'react';
import Popup from '../../../Popup/Popup';  
import Helper from '../../../../inc/Helper';
import { connect } from 'react-redux';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
 
let gridApi = null

const MasterDetails = ({setMasterDetails, gridData, language, masterscoring_id, reloadGrid}) => {
    const CloseHandler = () => {  
        setMasterDetails((prevState) => {
            return {
                ...prevState,
                showPopup: false
            }
        })
        reloadGrid();
    };

    const onRowValueChanged = (params) => {
        if(masterscoring_id) {
            ScoringDistributionValidations(params) 
        }
    };

    const ScoringDistributionValidations = (params) => {
        let technicalWeight = params.data.technical_score_weight;
        let financialWeight = params.data.financial_score_weight;
        let totalWeight = parseFloat(technicalWeight) + parseFloat(financialWeight);

        if (totalWeight !== 100) {
            Helper.alert('Technical & Financial weights must be distributed about 100%', 'error') 
            return;
        };
        GridSaveHandlerMasterTemplate(params);
    };

    const GridSaveHandlerMasterTemplate = async (event) => {
        try {
            let payload = {
                name: event?.data?.name,
                technical_score_weight: parseFloat(event?.data?.technical_score_weight),
                auto_disqualification_technical_score_prct: parseFloat(event?.data?.auto_disqualification_technical_score_prct),
                financial_score_weight: parseFloat(event?.data?.financial_score_weight),
                auto_disqualification_financial_score_prct: parseFloat(event?.data?.auto_disqualification_financial_score_prct),
                show_evaluation_criteria_to_bidder: event?.data?.show_evaluation_criteria_to_bidder
            };

            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/scoringMaster/${masterscoring_id}?for_rfq=True`, payload);
            if (res.data.status === "success") {
                setTimeout(() => { 
                    gridApi?.deselectAll();
                }, 100);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        } 
    };
 
    let dataSource = {
        getRows: async function (params) {
            params?.success({
                rowData: gridData?.map((item) => ({
                    ...item,
                    gridId: `${Date.now()}_${getNewValue()}`
                })),
                rowCount: gridData?.length
            })
        }
    };

    const onGridReady = (params) => {
        if(params) {
            gridApi = params?.api
            setTimeout(()=>{ 
                params?.api?.setGridOption('serverSideDatasource', dataSource);
            }, 200)
        }
    };

    const columnDefs = [
        {field:'name', editable: true, filter: 'agTextColumnFilter', headerName:Helper.getLabel(language,'name',"Name*"),},
        {field:'financial_score_weight', editable: true, filter: 'agNumberColumnFilter', headerName:Helper.getLabel(language,'financial_score_weight',"Financial score weight*")},
        {field:'technical_score_weight', editable: true, filter: 'agNumberColumnFilter', headerName:Helper.getLabel(language,'technical_score_weight',"Technical score weight*")},
    ];

    return (
        <div className='common_responsive_class_popup_component'> 
            <Popup className="master_template_popup" autoOpen={true} onClose={CloseHandler}>
                <div className='grid_master_templates'>
                    <AgGridNew
                        hideAddBtn={true}  
                        hideDeleteBtn={true}    
                        gridId={'rfq_master_templates_grid'}
                        columnDefs={columnDefs}
                        apiUrl={null}
                        onRowValueChanged={onRowValueChanged}
                        onGridReady={onGridReady}
                        height={500}
                        pagination={false}  
                    />
                </div>
            </Popup>
        </div>
    )
};
 
const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
};

export default connect(mapStateToProps) (MasterDetails);