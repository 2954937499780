import React, { useState } from 'react'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import ItemDetails from '../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/ItemDetails'
import LineDetailsPopUp from '../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/LineDetailsPopUp'
import LineViewComponent from "../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Popup from '../../Popup/Popup'
import Button from '../../inc/Button'
import $ from 'jquery'
import { Tab, Tabs } from 'react-bootstrap'
import DistributionTab from '../../Purchase Orders Internal/Edit PO/ListView/DistributionTab'
import SettingsPoDistribution from '../../Purchase Orders Internal/Edit PO/ListView/SettingsPoDistribution'
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getInvoiceLineGridColumns } from './ColumnsHeadings'

let forceRender = 1
let gridApi = null

const InvoiceLineGrid = (props) => {
  const [popUpData, setPopUpData] = useState({})
  const [detailsPopUp, setDetailsPopUp] = useState(false)
  const [messagePopUp,setMessagePopUp] = useState(false)
  const [rowData,setRowData]=useState({})
  const [selectedRow,setSelectedRow] = useState({})

  
  //function to show button when item details required
  const addOREditButton = (params) => {
    return params.data?.item_detail_required ||
      params.data?.billingrule?.item_detail_required
      ? <p className="rs_grid_simple_link">line details</p>
      : <p></p>
  }

  const onCellClicked = (event) => {
    if (
      (event?.colDef?.field === 'line-details' &&
        event.data?.item_detail_required === true) ||
      (event?.colDef?.field === 'line-details' &&
        event.data?.billingrule?.item_detail_required === true)
    ) {
      setPopUpData(event.data)
      setDetailsPopUp(true)
    }
  }

  const GridColumnsHeadingLines = getInvoiceLineGridColumns({
    language:props.language,
    lineIdOptions:props.lineIdOptions,
    lineData:props.lineData,
    shipmentHeader:props.shipmentHeader,
    gridType:props.gridType
  })

  if (props.gridType !== 'summary') {
    GridColumnsHeadingLines.push({
      field: 'line-details',
      headerName: '',
      minWidth: 160,
      cellRenderer: addOREditButton,
      editable: false,
    })
  }

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,
    isRowMaster: function (dataItem) {
      return (
        dataItem.billingrule?.item_detail_required ||
        dataItem?.item_detail_required
      )
    },
    detailCellRenderer: ItemDetails,
    detailCellRendererParams: {
      items: props.linesData?.items,
    },
  }

  const onRowValueChanged = (event) => {
     const remaining_qty = parseFloat(event.data?.remaining_qty)
     const received_qty = parseFloat(event.data?.received_qty)
     if (received_qty > remaining_qty) {
      Helper.alert(
        `Invoice Prcnt / Qty cannot be greater than ${remaining_qty}`,
        'error',
      )
    }else{
     setMessagePopUp(true)
     setRowData(event.data)
    }
  }

  const onOKBtnClicked = (rowData) => {
    const invoice_id = props.invoiceHeader?.invoice_id
    const remaining_qty = parseFloat(rowData?.remaining_qty)
    const received_qty = parseFloat(rowData?.received_qty)

    if (received_qty > remaining_qty) {
      Helper.alert(
        `Invoice Prcnt / Qty cannot be greater than ${remaining_qty}`,
        'error',
      )
      return
    }
    const putRequestData = {
      received_qty: parseFloat(rowData?.received_qty),
    }
    let api = Api
    api.setUserToken()
    if (!invoice_id) return
    api
      .axios()
      .put(
        Settings.apiPurchaseOrderUrl +
          `/line/${rowData?.line_id}/${props.invoiceHeader?.source_id}`,
        putRequestData,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          setMessagePopUp(false)
          $('html').removeClass('rs_popup_open');
          gridApi?.api?.refreshServerSide({purge:false})
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }


   const onMessagePopClose = () => {
    setMessagePopUp(false)
    gridApi?.api?.refreshServerSide({purge:false})
    $('html').removeClass('rs_popup_open');
   }

   const onSelectionChanged = (event) => {
     let selectedRows = event.api.getSelectedRows()
     setSelectedRow(selectedRows[0])
   }

   const handleGridReady = (gridObj) => {
      gridApi = gridObj
   }

  let distributionSettings = new SettingsPoDistribution({requester_id:selectedRow?.requester_id}); 

  distributionSettings.setDistributionHeader([
    {field:'entity_name', minWidth: 180, headerName:Helper.getLabel(props.language,'entity',"Entity"),editable:false},
    {
        field:'site_name',minWidth: 180, headerName:Helper.getLabel(props.language,'site',"Site"),editable:false, 
    },
    {
        field:'warehouse_name',minWidth: 180,headerName:Helper.getLabel(props.language,'warehouse',"Warehouse"),editable:false,
    },
    {
      field:'line_amount',minWidth: 180,headerName:Helper.getLabel(props.language,'amount',"Amount"),editable:false,
    },
 ]);

  return (
    <div
      className={
        props.userType === 0
          ? 'work_completion_line_grid_responsive line-grid-btns-common line-tabs'
          : 'work_completion_line_grid work_completion_line_grid_responsive line-grid-btns-common line-tabs' 
      }
    >
      {(props.invoiceHeader?.purchaseorder_type!==parseInt(1))  ? (
        <LineViewComponent
          defaultValues={{}}
          lineGridId="good_receiving_line_grid"
          viewType="invoice"
          canShipmentEdit={props.invoiceHeader?.document_status !== 1 && props.gridType !== 'summary'}
          shipmentType="invoice"
          userType={props.userType}
          source_id={props.invoiceHeader?.source_id}
          line_source={props.invoiceHeader?.source_id}
          isEditable={false}
          lineGridTitle={Helper.getLabel(null, 'lines', 'Lines')}
          header_id={props.invoiceHeader?.invoice_id}
          purchase_status={0}
          document_status={0}
          delivery_date={null}
          master_save_button_id="btn_master_save"
          onbehalf_request={false}
          vendor_id={null}
          vendorsite_id={null}
          hideGeneral={true}
          hideBillingRule={true}
          hideSetup={true}
          hideDistributionInnerTabs={props.userType===1}
          hideAddBtn={true}
          hideDeleteBtn={true}
          hideMoreBtns={true}
        />
      ) : (
        <div>
        <AgGridNew
          id="invoice_line_grid"
          columnDefs={GridColumnsHeadingLines}
          apiUrl={Settings.apiPurchaseOrderUrl + `/lines/${props?.invoiceHeader?.invoice_id}/${props?.invoiceHeader?.source_id}`}
          hideDeleteBtn={true}
          hideAddBtn={true}
          isDisabledCheckbox={true}
          onCellClicked={onCellClicked}
          masterSettings={masterSettings}
          onRowValueChanged={onRowValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={handleGridReady}
          rowType="single"
        />
        {props.userType===0 && <div className='line-view-tabs'>
        <Tabs defaultActiveKey="distribution">
          <Tab eventKey="distribution" title="Distribution" disabled={objectIsEmpty(selectedRow)}>
          {!objectIsEmpty(selectedRow) && <DistributionTab 
             settings = {distributionSettings}
             gridId={"invoice_line_distribution_grid"}
             isEditable={true}
             segment_distribution_source_id={Settings.source_id.PoLoLineSiteSegmentDistribution}
             source_id= {props.invoiceHeader?.source_id}
             distributeKey = {"received_qty"}
             remaining_qty = {selectedRow?.remaining_qty}
             distributeValue = {selectedRow?.received_qty}
             lineAmount = {parseInt(selectedRow?.line_amount)}
             po_line_id={selectedRow?.line_id}
             version = { null}
             line_source = {64}
             viewType = { 'default' }
             amendment_id = { null }
             shipmentType="invoice"
             gridType="contract"
             hideDistributionInnerTabs={props.userType===1}           
             />}
          </Tab>
        </Tabs>
        </div>}
        </div>
      )}
      {detailsPopUp && (
        <LineDetailsPopUp
          setShowPopup={setDetailsPopUp}
          popUpData={popUpData}
          gridApi={gridApi}
          shipmentHeader={props.invoiceHeader}
          type="invoice"
        />
      )}
      {messagePopUp && <Popup autoOpen={true} width="800px" onClose={onMessagePopClose}>
       <div>
        <p>Note: The invoice update quantity is not according to the quantity of matched product receipts. Matched Product Receipt Quantity will be invoiced and won't be consistant with the quantity in the invoice. Use "Matched Qty" field in the "Match Product Receipts" to adjust the quantity that will be invoiced.</p>
        <div className='d-flex align-items-end gap-3 justify-content-end'>
          <Button title={Helper.getLabel(props.language, 'ok', 'Ok')} onClick={()=>onOKBtnClicked(rowData)}></Button>
          <Button title={Helper.getLabel(props.language, 'close', 'Close')} onClick={onMessagePopClose} className="black-btn-style"></Button>
        </div>
       </div>  
      </Popup>}
    </div>
  )
}

export default InvoiceLineGrid
