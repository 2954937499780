import React, { useState } from 'react';
import MasterComonent from '../../Backend/MasterComonent';
import NavigationHeder from '../../Navigations/NavigationHeder';
import ButtonNew from '../../inc/ButtonNew';
import Helper from '../../../inc/Helper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue, gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { BudgetTransfer_Data } from './BudgetTransferData';
import { useNavigate } from 'react-router-dom';
import BudgetTransferPopup from './BudgetTransferPopup';
import DatepickerFunctions from '../../../inc/DatepickerHelper';

const BudgetTransferList = () => {
    const [budgetTransferPopup,setBudgetTransferPopup] = useState(false)

    const navigate = useNavigate()

    const onNewBtnClick = () => {
        setBudgetTransferPopup(true)
    }

    const onPopupClose = () => {
        setBudgetTransferPopup(false)
    }

    const onCellClicked = (event) => {
        if(event?.colDef.field==='document_number'){
           navigate(`/edit-budget-transfer/${event?.data?.id}`)
        }
     }
   
     let dataSource = {
       getRows: async function (params) {
         params?.success({
           rowData: BudgetTransfer_Data?.map((item) => ({
             ...item,
             gridId: `${Date.now()}_${getNewValue()}`,
           })),
           rowCount: BudgetTransfer_Data?.length,
         })
       },
     }
   
     const onGridReady = (params) => {
       if (params) {
         setTimeout(() => {
           params?.api?.setGridOption('serverSideDatasource', dataSource)
         }, 200)
       }
     }

  const Columns_Headings = [
    {
        field:'document_number',
        headerName:Helper.getLabel(null,'document_number','Document number'),
        cellRenderer:(params)=>{return gridLinkValueFormat(params)},
        editable:false,
        minWidth:180,
    },
    {
        field:'title',
        headerName:Helper.getLabel(null,'title','Title'),
        editable:false,
        minWidth:200,
    },
    {
        field:'requester_name',
        headerName:Helper.getLabel(null,'description','Description'),
        editable:false,
        minWidth:220,
    },
    {
        field:'requester_name',
        headerName:Helper.getLabel(null,'requester_name','Requester name'),
        editable:false,
        minWidth:180,
    },  
    {
        field:'department_division',
        headerName:Helper.getLabel(null,'department/divison','Department/Divison'),
        editable:false,
        minWidth:200
    },
    {
        field:'from_budget',
        headerName:Helper.getLabel(null,'from_budget','From budget'),
        editable:false,
        minWidth:180,
    }, 
    {
        field:'to_budget',
        headerName:Helper.getLabel(null,'to_budget','To budget'),
        editable:false,
        minWidth:180,
    },
    {
        field:'transfer_reason',
        headerName:Helper.getLabel(null,'transfer_reason','Transfer reason'),
        editable:false,
        minWidth:220,
    },
    {
        field:'total_transfer_amount',
        headerName:Helper.getLabel(null,'total_transfer_amount','Total transfer amount'),
        editable:false,
        minWidth:200
    },
    {
        field:'approval_status',
        headerName:Helper.getLabel(null,'approval_status','Approval status'),
        editable:false,
        minWidth:180,
    },
    {
        field:'approvers',
        headerName:Helper.getLabel(null,'approvers','Approvers'),
        editable:false,
        minWidth:180,
    },
    {
        field:'approval_date',
        headerName:Helper.getLabel(null,'approval_date','Approval date'),
        editable:false,
        minWidth:180,
        valueFormatter:(params)=>{return DatepickerFunctions?.convertDateFromDataBase(params.value)}
    },
    {
        field:'priority_name',
        headerName:Helper.getLabel(null,'priority','Priority'),
        editable:false,
        minWidth:180,
    },
    {
        field:'comments',
        headerName:Helper.getLabel(null,'comments','Comments'),
        editable:false,
        minWidth:180,
    },
  ]

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title="Budget Transfer" hideMoreBtn={true}>
            <ButtonNew title={Helper.getLabel(null,'new','New')} onClick={onNewBtnClick}></ButtonNew>
        </NavigationHeder>
        <div>
            <AgGridNew 
              gridId={'budget-transfer-grid'}
              columnDefs={Columns_Headings}
              height={500}
              hideAddBtn={true}
              isDeleteBtnDisable={true}
              isDataSourceRequire={true}
              onGridReady={onGridReady}
              onCellClicked={onCellClicked}
            />
        </div>
      </MasterComonent>
      {budgetTransferPopup && <BudgetTransferPopup ClosePopup={onPopupClose} />}
    </div>
  );
}

export default BudgetTransferList;
