import { Link } from "react-router-dom"
import Button from "./Button";

const LinkBtn = (props) => {
    const getClass = ( ) => {
        let className = 'btn btn-default rs_btn ';
        if(props.isActive){
            className += ' btn_active';
        }
        if(props.className){
            className += ' '+props.className;
        }
        return className;
    }
    if(props.isDisable){
        return <Button {...props} />
    }
    return (
        <Link to={props.to} state={props.state} className={ getClass() }>
            {props.icon ? <img className="icon" src={props.icon} alt={props.title} /> : ''}
            {props.title}
        </Link>
    )
}
export default LinkBtn;