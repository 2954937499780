import React from 'react'
import ContractEmployeeChart from './ContractEmployeeChart'
import ContractByStatusChart from './ContractByStatusChart'
import ContractByStatusLineChart from './ContractByStatusLineChart'

const ContractSecondRow = () => {
  return (
    <div className='row align-items-stretch task-chart-row mt-4'>
      <div className='col-sm-6 col-lg-4 h-100'>
        <ContractEmployeeChart />
      </div>
      <div className='col-sm-6 col-lg-4 h-100'>
        <ContractByStatusChart />
      </div>
      <div className='col-sm-6 col-lg-4 h-100'>
        <ContractByStatusLineChart />
      </div>
    </div>
  )
}

export default ContractSecondRow
