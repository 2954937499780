import React, {Fragment, useEffect, useState} from 'react' 
import AgGrid from '../../grid/ag/ag-grid'
import Helper from '../../../inc/Helper'
import $ from 'jquery'
import axios from 'axios'
import { connect } from "react-redux";
import Settings from '../../../inc/Settings'
import NavigationHeder from '../../Navigations/NavigationHeder'
import Button from '../../inc/Button'
import Popup from '../../Popup/Popup'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, moveLastToTop } from '../../../inc/Validation'
import GridPrevilegesDetails from '../../grid/ag/cellRender/GridPrevilegesDetails'
import SimpleLoading from '../../Loading/SimpleLoading'
import Api from '../../../inc/Api'
 
let selectedPrevileges = [] 

const AssignPrev = () => { 
  const [privilegeassignGrid, setPrivilegeAssignGrid] = useState([]) 
  const [loadingRolesPrevs, setLoadingRolesPrevs] = useState(true)
  const [loadingPrevs, setLoadingPrevs] = useState(true)
  const [previlegesList, setPrevilegesList] = useState([])
  const [enableList, setEnableList] = useState(false)
  const [roleId, setRoleId] = useState(null)

  let gridObject ;
  let unselectedPrevileges = []
  let assignPrevCalled = false
  let prevCalled = false 

  useEffect(() => {
    get_roles_data()
    get_previlegs_data()
  }, [])

  const get_roles_data = () => {
    if(assignPrevCalled){
      return;
    }
    setLoadingRolesPrevs(true)
    assignPrevCalled = true
    const api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiSystemUrl+'/role_privileges',{}).then(function(res){
      if(res.data.status==="success"){ 
        setLoadingRolesPrevs(false) 
        setPrivilegeAssignGrid( res.data.data.reverse() )
      } 
    }).catch((err) => {
      setLoadingRolesPrevs(false)
    })
  }

  const get_previlegs_data = () => {
    if(prevCalled){
      return
    }
    setLoadingPrevs(true)
    prevCalled = true
    const api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiSystemUrl+'/privileges',{}).then(function(res){
      if(res.data.status==="success"){ 
        setLoadingPrevs(false)
        setPrevilegesList( res.data.data )
      } 
    }).catch((err) => { 
      setLoadingPrevs(false)
    })
  }
  
  // Grid API Executions -

  const onSaveClick = (rowItem) => {  
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
      if(!item.role_id || item.role_id <=0){
          newData.push(item);
      }else{
        updatedData.push(item);
      }
    });
    if(newData.length>=1){
      addNewData(newData);
    } 
  } 
   
  const addNewData = (data) => {   
    let NEW_DATA_ADDED = []
    data.forEach(element => {
      NEW_DATA_ADDED.push({
        "role_id": element.role_id,
        "privilege_id": element.privilege_id
      })
    }) 
    const api = Api;
    api.setUserToken();
     api.axios().post(Settings.apiSystemUrl+'/assign_privilege', NEW_DATA_ADDED).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        get_roles_data()
      }else {
        get_roles_data()
        Helper.alert(res.data.message, 'error')
      }
    })
  }
  
  const DeleteHandler = (allRows) => {
    const api = Api;
    api.setUserToken();
    allRows.forEach(element => {
      api.axios().delete(Settings.apiSystemUrl+'',{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_roles_data()
        }else {
          Helper.alert(res.data.message, 'error')
        }
      })
    })
  }

  // --
  
  const CloseHandler = () => {
    setEnableList(false)
  }

  // Grid Calls buttons actions  --
  const Callbuttons = () => {
    return (
      <div style={{ display: 'flex', gap: '20px' }}>
        <Button onClick={AssignPrevileges} title='Select Previleges To Assign' />
        <Button onClick={DeleteAssignedPrevileges} title='Delete Assigned Previleges' />
      </div>
    );
  }

  // Grid onReadyAPI executions -
   
  const GridAPI = (gridObj) => {
    let prevliegeArr = privilegeassignGrid.find(obj => obj.role_id == roleId).privileges
    console.log(prevliegeArr)
  
    gridObj.api.forEachNode((node) => {
      // check if the row's data is included in prevliegeArr
      let isSelected = prevliegeArr.some(item => item.id === node.data.id)
      if(isSelected) { 
        selectedPrevileges.push(node.data)
        node.setSelected(isSelected)
      }
    })
  
    gridObject = gridObj
  }

  const rowSelectionChange = (params) => {
    if (params.source === 'api') {
      return;
    } 
  
    let selectedRows = gridObject.api.getSelectedRows();
    let prevliegeArr = privilegeassignGrid.find(obj => obj.role_id === roleId).privileges; 
  
    // Loop through the prevliegeArr array to find which rows are unselected
    prevliegeArr.forEach(prevliege => {
      let isSelected = selectedRows.some(selectedRow => selectedRow.id === prevliege.id);
      if (!isSelected) { // if it's not selected, add it to unselectedPrevileges array
        unselectedPrevileges.push({
          "role_id": roleId,
          "privilege_id": prevliege.id
        })
      }
    }); 
  }

  // prevlieges assignments -
  
  const AssignPrevileges = () => { 
    let UPDATED_ROLES_PREVILEGES = [] 
    let selectedRows = gridObject.api.getSelectedRows()
    let prevliegeArr = privilegeassignGrid.find(obj => obj.role_id == roleId).privileges  
  
    // loop through the selected rows
    selectedRows.forEach(row => {
      // check if the row's data is included in prevliegeArr
      let isSelected = prevliegeArr.some(item => item.id === row.id)
      if (!isSelected) { // if it's not included in prevliegeArr, add it to UPDATED_ROLES_PREVILEGES array
        UPDATED_ROLES_PREVILEGES.push({
          "role_id": roleId,
          "privilege_id": row.id
        })
      }
    })
  
    if (checkIfArrayIsEmpty(UPDATED_ROLES_PREVILEGES)) {
      return;
    }
  
    const api = Api;
    api.setUserToken();
    api.axios().post(Settings.apiSystemUrl+'/assign_privilege', UPDATED_ROLES_PREVILEGES).then(function(res){
      if(res.data.status==="success"){ 
        $('.close-popup .rs_popup_close').trigger('click');
        Helper.alert(res.data.message)
        get_roles_data()
        setEnableList(false)
      } 
    }).catch((res) => { 
      $('.close-popup .rs_popup_close').trigger('click');
      get_roles_data()
      getPopupMessageBasedOnStatus(res) 
    }) 
  }

  const DeleteAssignedPrevileges = () => { 
    if(checkIfArrayIsEmpty(unselectedPrevileges)){
      return;
    } 
    const api = Api;
    api.setUserToken();
    unselectedPrevileges.forEach((item) => { 
      api.axios().delete(Settings.apiSystemUrl + `/unassign_privilege/${item.role_id}/${item.privilege_id}`, { }).then(function(res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message)
          get_previlegs_data()
          get_roles_data()
          $('.close-popup .rs_popup_close').trigger('click');
          // // Remove the object from unselectedPrevileges after successful API call
          unselectedPrevileges = unselectedPrevileges.filter((previlege) => previlege.id !== item.id);
        }
      }).catch((res) => {
        // Helper.alert(res.response.data.message)
        get_previlegs_data()
        get_roles_data()
      })
    })
  }

  // Grid Columns Defs

  const Columns_Headings = [
    {field:'name', cellRenderer: 'agGroupCellRenderer' , headerName:'Role Name', editable:false},
    {field:'description', headerName:'Role Description', editable:false} 
  ]

  const Columns_Headings_Previleges = [
    {field:'name', headerName:'Previleges Name', editable:false},
    {field:'description', headerName:'Previleges Description', editable:false} 
  ]

  // Grid Master Settings, enabling 2D Grid Functionality -

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,  
    detailCellRenderer: GridPrevilegesDetails, 
    detailCellRendererParams: { 
      togglePopupState: setEnableList,
      assignRoleId: setRoleId
    }
  }

  return (
    <Fragment>
     <NavigationHeder title='Previleges Assignment' hideMoreBtn={true} />
      <div className='previleges_assignment_save'>
        {loadingRolesPrevs ? <SimpleLoading /> : <AgGrid settings={masterSettings} data={privilegeassignGrid} id="previleges-assignment-grid" header={Columns_Headings} viewOnly={true} dissableCheckBox= {true} dissableSelect ={true} singleRowSelect={false} allBtnHide={true} onDelete={DeleteHandler} onSave={onSaveClick} />}
      </div> 
 
      {enableList && 
        <div className='close-popup'>
          <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
            <div className='rs_create_users_grid'>
              {loadingPrevs ? <SimpleLoading /> : <AgGrid dissableRowSelect={true} onSelectionChanged={rowSelectionChange} id="rs_popup_open_users_roles" afterDeleteButtons={Callbuttons} allBtnHide={true} viewOnly={true} onGridReady={GridAPI} header={Columns_Headings_Previleges} data={previlegesList} />}
            </div>
          </Popup> 
        </div>
      }
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    language:state.language,
    user:state.auth.user
  }
}

export default connect (mapStateToProps) (MasterComponentWraper(AssignPrev))