import React, { Fragment, useEffect, useState } from 'react'; 
import Collapse from '../../inc/Collapse';
import Checkbox from '../../Forms/Checkbox';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import Helper from '../../../inc/Helper';

const TermsConditionsVendor = ({masterVendor, setVendor, vendor_id=null, disableFields=false}) => {
    const [termsText, setTermsText] = useState('');
    const [loading, setLoading] = useState(false);

    let termsCalled = false;

    useEffect(() => {
        loadTermsConditionsContent()
    }, []);

    const loadTermsConditionsContent = () => {
        if(termsCalled) return;
        termsCalled = true;

        setLoading(true); 
        let api = Api;
        api.setUserToken(); 
        api.axios().get(Settings.apiUrl+'/termandcondition')
            .then(res => {
                setTermsText(res.data.data)
                setLoading(false) 
            })
            .catch(error => { 
                setLoading(false)
            });
    };

    const executeVendorUpdateAPI = (terms_conditions_val) => {
        if(!vendor_id) return;
        
        let api = Api;
        api.setUserToken();
    
        api.axios().put(Settings.apiVendorUrl + `/vendor/${vendor_id}`, {
            agree_on_terms_condition: terms_conditions_val
        }).then(function (res) {
            if (res.data.status === "success") {
                Helper.alert('Terms & Conditions Value Updated on Vendors!');
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res); 
            setVendor((prevState) => {
                return {
                    ...prevState,
                    agree_on_terms_condition: !terms_conditions_val
                }
            });
        })
    };

    const onChange = (name, value) => {
        let checkboxVal = value == 'on';

        setVendor((prevState) => {
            return {
                ...prevState,
                agree_on_terms_condition: checkboxVal
            }
        });

        executeVendorUpdateAPI(checkboxVal);
    }
    
    return ( 
        <Fragment> 
            { loading ? <SimpleLoading /> : 
                <Collapse title="Terms & Condition" className='mb-5' open={true}>
                    {!termsText ? (
                        <div className="error-message">
                            There is no terms and conditions.
                        </div>
                    ) : (
                        <div className='vendor_terms_conditions_wrapper'> <div dangerouslySetInnerHTML={{ __html: termsText}}></div> </div> 
                    )}
                    <div className='checkbox_terms_conditions_wrapper'>   
                        <div className='mt-2'> 
                            <Checkbox
                                disableWithoutIcon={disableFields}
                                onChange={onChange}
                                isChecked={masterVendor?.agree_on_terms_condition}
                                type="checkbox"
                                name="tnc" 
                                label="I agree to comply with these terms and conditions."
                            />
                        </div> 
                    </div> 
                </Collapse>
            }
        </Fragment>
    )
};

export default TermsConditionsVendor;