import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import LinkBtn from '../../inc/LinkBtn';
import RsWithRouter from '../../inc/RsWithRouter'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import AddressList from '../../Tenant/Address/AddressList';
import ContactAddress from '../../Tenant/Address/ContactAddress'; 
import ActionTypes from '../../../inc/ActionsTypes'; 
import Collapse from '../../inc/Collapse';
import Dropdown from '../../Forms/Dropdown';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { useParams } from 'react-router-dom';

const EditSite = (props) => {
    const { id } = useParams();
    const wrapperDiv = useRef(null);  
    const [height, setHeight] = useState(0);  

    const [isLoading, setIsLoading] = useState(false);
    const [site, setSite] = useState(null);
    const [currentSearchParam, setCurrentSearchParam] = useState('');
    const entityAllData = useRef(null);

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    let siteApiCalled = false; 

    useEffect(() => {
        loadSite();
    }, []);

    const loadSite = () => { 
        if(siteApiCalled || !id) return;
        siteApiCalled = true;

        setIsLoading(true);
        let api = Api;
        api.setUserToken();
        api.axios().get(`${Settings.apiUrl}/site/${id}`).then(res => {
            if (res.data.status === 'success') {
                let siteFromServer = res.data.data;
                if (siteFromServer?.contact?.contactsource_id) {
                    siteFromServer.contactsource_id = siteFromServer.contact.contactsource_id;
                }
                if (siteFromServer?.address?.addresssource_id) {
                    siteFromServer.addresssource_id = siteFromServer.address.addresssource_id;
                }
                setSite(siteFromServer);
                setIsLoading(false);
            }
        }).catch((res) => {
            setIsLoading(false);
            getPopupMessageBasedOnStatus(res) 
        });
    };

    const saveSite = () => {
        const siteData = {
            "entity_id": site?.entity_id,
            "code": site?.code,
            "name": site?.name
        };

        if (siteData?.code === "" || siteData?.name === "") {
            Helper.alert("Code and Name fields are required", 'error');
            return;
        }

        let api = Api;
        setIsLoading(true);
        api.setUserToken(); 
        api.axios().put(`${Settings.apiUrl}/site/${id}`, siteData).then(res => { 
            if (res.data.status === 'success') {
                Helper.alert('Site updated successfully');
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
            getPopupMessageBasedOnStatus(error);
        });
    };

    const onInputChangeHandler = (e) => {
        setSite(prevSite => ({
            ...prevSite,
            [e.target.name]: e.target.value
        }));
    };

    const mapEntityValue = (entity) => {
        return { text: entity?.name, id: entity?.entity_id };
    };
 
    const onContactCreate = (response) => {
        if (site && !site.contactsource_id) {
            setSite(prevSite => ({
                ...prevSite,
                contactsource_id: response?.contact_source_id
            }));
        }
    };

    const onAddressCreate = (response) => {
        if (site && !site.addresssource_id) {
            setSite(prevSite => ({
                ...prevSite,
                addresssource_id: response?.address_source_id
            }));
        }
    };

    let language = props?.language;  
    let sourceId = Settings.source_id.site;

    return (
        <Fragment>
            {isLoading ? <OverlayLoader isPopupInTab={true} /> : null}
            <div className="container-fluid">
                <div ref={wrapperDiv}>
                    <NavigationHeder
                        hideMoreBtn={true}
                        backUrl="/sites"
                        title={Helper.getLabel(language, "edit_site", "Edit Site")}
                    >
                        <LinkBtn
                            to="/sites"
                            isActive={false}
                            className='black-btn-style'
                            title={Helper.getLabel(language, "close", "Close")}
                        />
                        <Button
                            className="rfq_save_btn"
                            onClick={saveSite}
                            isActive={false}
                            title={Helper.getLabel(language, "save", "Save")}
                        />
                    </NavigationHeder>
                </div>

                <div
                    className="common_scrollbar_styles"
                    style={{ height: `${height - 30}px`, overflowY: "auto" }}
                >
                    <Collapse
                    title={Helper.getLabel(language, "general", "General")}
                    open={true}
                    >
                    <form
                        id="new_form"
                        className="rs_form_container"
                        encType="multipart/form-data"
                    >
                        <div className="row">
                        <div className="col-xs-12 col-sm-3">
                            <Dropdown
                            id="entity_id"
                            name="entity_id"
                            options={[
                                { value: site?.entity_id, label: site?.entity_name }
                            ]}
                            label={Helper.getLabel(language, "entity", "Entity")} 
                            apiUrl={`${Settings.apiUrl}/get_entities`}
                            currentSearchTerm={currentSearchParam}
                            mapFunction={mapEntityValue}
                            allDataRef={entityAllData}
                            value={site?.entity_id}
                            disableClear={true}
                            pagination={true}
                            reRenderRequired={true}
                            required={true}
                            onChange={onInputChangeHandler}
                            placeHolder={`${Helper.getLabel(
                                language,
                                "please_select",
                                "Select entity"
                            )}`}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <Input
                            name="code"
                            readOnly={true}
                            label="Code"
                            onChange={onInputChangeHandler}
                            value={site?.code}
                            required={true}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <Input
                            name="name"
                            label="Name"
                            onChange={onInputChangeHandler}
                            value={site?.name}
                            required={true}
                            />
                        </div>
                        </div>
                    </form>
                    </Collapse>
                    {isLoading ? null : ( 
                        <AddressList
                            id='site_address'
                            onAddressCreate={onAddressCreate}
                            addressSourc_id={site?.addresssource_id}
                            source_id={sourceId}
                            address_source_integrator={id}
                        />
                    )}
                    {isLoading ? null : (
                    <Collapse title={"Contact"} open={true} className="mt-4">
                        <div className="entity_contacts"> 
                            <ContactAddress
                                id='site_contact'
                                contactsource_integrator={id}
                                onContactCreate={onContactCreate}
                                contactsource_id={site?.contactsource_id}
                                source_id={sourceId}
                                address_id={null}
                            />
                        </div>
                    </Collapse>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    language: state.language,
    entity: state.entity
});

const mapDispatchToProps = (dispatch) => ({
    setEntity: (entity) => { dispatch({ type: ActionTypes.SET_ENTITY, payload: entity }) }
});

export default connect(mapStateToProps, mapDispatchToProps)(RsWithRouter(EditSite)); 