import React from "react";
import $ from "jquery";
import Helper from "../../../../inc/Helper";
import Popup from "../../../Popup/Popup";
import Collapse from "../../../inc/Collapse";
import { useLocation } from "react-router-dom";
import Settings from "../../../../inc/Settings";
import { getVendorInviteModelColumns } from "./ColumnsHeader/ColumnsHeader";
import AgGridNew from "../../../grid/ag/ag-grid-new";

let gridObj = null

const InvitedVendorModal = ({ language, handleCloseModal }) => {
  const location = useLocation();
  
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const Columns_Headings =  getVendorInviteModelColumns({
    language:language
  })

  const handleGridReady = (params) => {
    gridObj = params
  }

  return (
    <Popup
      autoOpen={true}
      width="1100px"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        handleCloseModal();
      }}
    >
      <>
        <h5 className="create_rfq_modal__title mb-4">
          {Helper.getLabel(
            language,
            "invited_vendor_history",
            "Invited Vendor History"
          )}
        </h5>

          <Collapse
            title={Helper.getLabel(language, "vendors", "Vendors")}
            open={true}
          >
            <AgGridNew 
              gridId="rfq_pending_vendors_invite"
              hideAddBtn={true}
              hideDeleteBtn={true}
              columnDefs={Columns_Headings}
              apiUrl={ `${Settings.apiPurchaseRequisitionUrl}/get_invite_vendors/${getSearchParam("rfq_view_id")}`}
              onGridReady={handleGridReady}
            />
          </Collapse>
      </>
    </Popup>
  );
};

export default InvitedVendorModal;
