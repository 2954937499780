import Api from '../../../../../inc/Api'
import Settings from '../../../../../inc/Settings'

export default class WorkConfirmationApiCalls {
  api
  constructor() {
    this.api = Api
    this.api.setUserToken()
  }

  async getAllPurchaseOrderStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + '/enum/POHeaderPurchaseStatus')
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllShType() {
    const res = await this.api.axios().get(Settings.loginUrl + '/enum/SH_type')
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllShDocumentStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + '/enum/SH_DocumentStatus')
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllInvoiceDocumentStatus() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + '/enum/invoice_header_documentstatus')
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllShipmentLines(shipment_id) {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/get_sh_lines/${shipment_id}`)
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllShipmentHeaderById(shipment_id) {
    const res = await this.api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/sh_header/${shipment_id}`)
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllPOLInes(purchase_order_id,forInvoice) {
    let url =  Settings.apiPurchaseOrderUrl +
    `/get_all_billingrule_po/${purchase_order_id}`
    if(forInvoice){
      url += "?for_invoice=true"
    }
    const res = await this.api
      .axios()
      .get(url)
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllHeaderLines(purchase_order_id, source_id) {
    const res = await this.api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/lines/${purchase_order_id}/${source_id}`,
      )
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllSHHeader(shType,pageNumber,pageSize,purchase_order_id, status,createdBy,dashboard) {
    let url = Settings.apiPurchaseOrderUrl + `/sh_headers/${shType}?page_number=${pageNumber}&page_size=${pageSize}`
    if(status){
      url += `&status=${status}`
    }
    if (purchase_order_id) {
      url += `&purchaseorder_id=${purchase_order_id}`
    }
    if(createdBy){
      url += `&createdby=${createdBy}`
    }
    if(dashboard) {
      url += `&dashboard=true`
    }
    const res = await this.api.axios().get(url)
    if (res.data.status === 'success') return { responseData: res.data.data, fullResponse: res.data }
    return res
  }

  async getAllOpenSHHeader(shType, purchase_order_id) {
    let url = Settings.apiPurchaseOrderUrl + `/sh_headers/${shType}?status=0`
    if (purchase_order_id) {
      url += `&purchaseorder_id=${purchase_order_id}`
    }
    const res = await this.api.axios().get(url)
    if (res.data.status === 'success') return res.data.data
    return res
  }

  async getAllCreatedBySHHeader(shType, createdBy, purchase_order_id) {
    let url = Settings.apiPurchaseOrderUrl + `/sh_headers/${shType}?createby=${createdBy}`
    if (purchase_order_id) {
      url += `&purchaseorder_id=${purchase_order_id}`
    }
    const res = await this.api.axios().get(url)
    if (res.data.status === 'success') return res.data.data
    return res
  }
  
  async getAllPOHeaderType() {
    const res = await this.api
      .axios()
      .get(Settings.loginUrl + `/enum/POHeaderType`)
    if (res.data.status === 'success') return res.data.data
    return res
  }
}
