import { createSlice } from '@reduxjs/toolkit';

const gridID = createSlice({
    name:'Grid Id',
    initialState: {
        currency_id: undefined,
        showpopup: false,
        usersListShow: false,
        calendar_id: undefined,
        description: '',
        year_id: undefined,
        year_description: '',
        year_end_date: '',
        year_start_date: '',
        users_id: undefined,
        rolesListShow: false,
        roles_id: undefined,
        selected_role_id:undefined,
        selected_role_id_assign: false,
        vendorSiteId: undefined,
        selectedChip: undefined,
        prevOpen: false
    },
    reducers: {
        CurrencyIdChange (state, action) { 
            state.currency_id = action.payload 
        }, 
        PopupGridShow (state, action) { 
            state.showpopup = action.payload.payload
        }, 
        usersGridShow (state, action) { 
            state.usersListShow = action.payload.payload
        },
        rolesGridShow (state, action) { 
            state.rolesListShow = action.payload.payload
        },
        CalendarIdChange (state, action) { 
            state.calendar_id = action.payload.payload
        }, 
        DescriptionCalendarChange (state, action) { 
            state.description = action.payload.payload
        },
        yearIdChange (state, action) { 
            state.year_id = action.payload.payload 
        }, 
        DescriptionPeriodChange (state, action) { 
            state.year_description = action.payload.payload
        },
        yearEndDateChange (state, action) { 
            state.year_end_date = action.payload.payload 
        },
        yearStartDateChange (state, action) { 
            state.year_start_date = action.payload.payload 
        }, 
        UsersIdChange (state, action) { 
            state.users_id = action.payload.payload
        },
        roleIdChange (state, action) { 
            state.roles_id = action.payload.payload 
        },
        selectedRoleIdChange (state, action) { 
            state.selected_role_id = action.payload.payload 
        },
        RoleAssigned (state, action) { 
            state.selected_role_id_assign = action.payload.payload 
        },
        VendorID (state, action) { 
            state.vendorSiteId = action.payload.payload 
        },
        ChipId (state, action) { 
            state.selectedChip = action.payload.payload 
        },
        togglePopupPrevilegesList (state, action) {
            state.prevOpen = action.payload.payload
        } 
    }

});

export const gridIdActions = gridID.actions;
export default gridID;

