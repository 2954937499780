import React, { Fragment, useCallback, useState } from 'react'
import '../BankAccounts.css' 
import MasterComponentWraper from '../../Backend/MasterComponentWraper'; 
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'; 
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import ButtonNew from '../../inc/ButtonNew';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import BankAccountModal from './BankAccountModal';

let gridApi = null;

const BankArchive = (props) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'bank_code') { 
      navigate(`/edit-bank-account/${event.data.account_id}`)
    }
  };
 
  const onDeleteBankAccounts = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delBackAcc_ID = element?.data?.account_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiOrgUrl+`/bankaccount/${delBackAcc_ID}`)
      const rowNode = gridApi?.getRowNode(delBackAcc_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
      
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.bank_accounts.bank_accounts)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };

  const gridColHeaders = [
    {field:'bank_code', filter:'agTextColumnFilter', cellRenderer: (params) => gridLinkValueFormat(params), headerName:Helper.getLabel(props.language,'code','Code'), editable: false },
    {field:'bank_name',  filter:'agTextColumnFilter', headerName:Helper.getLabel(props.language,'name','Name'), editable: false},
    {field:'code', filter:'agTextColumnFilter', headerName:Helper.getLabel(props.language,'account_code','Account Code'), editable: false},
    {field:'name', filter:'agTextColumnFilter', headerName:Helper.getLabel(props.language,'account_name','Account Name'), editable: false},
    {field:'account_number', filter:'agTextColumnFilter', headerName:Helper.getLabel(props.language,'account_number','Account Number'), editable: false}, 
  ];

  return ( 
    <div className='bank_accounts_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'bank_accounts','Bank Accounts')}>
          <ButtonNew isDisable={!security.canCreate(frontendIds.setupForms.bank_accounts.bank_accounts_main_create_button)} onClick={() => setShowCreateModal(true)} title={Helper.getLabel(props.language,'new','New')} />
        </NavigationHeder> 

        <AgGridNew
          apiUrl={Settings.apiOrgUrl+'/bankaccount'} 
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.bank_accounts.bank_accounts_main_delete_button)}
          pagination={true}
          columnDefs={gridColHeaders} 
          hideAddBtn={true}
          height={500}
          uniqueField={'account_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={onDeleteBankAccounts}
          onGridReady={(params) => gridApi = params.api} 
          gridId={"rs_bank_accounts_list"}
        /> 
      </div>
      { showCreateModal && <BankAccountModal setShowCreateModal={setShowCreateModal} /> }
    </div> 
  )
}

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.bank_accounts.bank_accounts
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(BankArchive, SecurityOptions))))