import React from 'react'
import Helper from '../../../../inc/Helper'

const AdditionalInformation = ({
  language,
  data,
  title,
  isNeedNotes
}) => {
  return (
    <div className='bidding_requirement rfq-external-additional-info'>
    <div className='scope_of_work mb-3'>
      <h2 className='border-bottom-h2'>
        {title?title:Helper.getLabel(language, 'description', 'RFQ description')}
      </h2>
      <div className='request_for_quotation_content w-100 mt-2'>
        <textarea
          className='request_for_quotation_content rfq-textarea w-100'
          disabled={true}
          name='description'
          defaultValue={data?.description}
          value={data?.description}
        >
        </textarea>
      </div>
    </div>
    {isNeedNotes?<div className='scope_of_work'>
      <h2 className='border-bottom-h2'>
        {Helper.getLabel(language, 'notes_to_vendor', 'Notes to vendor')}
      </h2>
      <div className='request_for_quotation_content w-100 mt-2'>
        <textarea
          className='request_for_quotation_content rfq-textarea w-100'
          disabled={true}
          name='note_to_vendor'
          defaultValue={data?.note_to_supplier}
          value={data?.note_to_supplier}
        >
        </textarea>
      </div>
    </div>:<></>}
  </div>
  )
}

export default AdditionalInformation
