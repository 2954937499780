import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Gui_id_list from '../../../../inc/Gui_id_list'
import { connect } from 'react-redux'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../../inc/Alert'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ButtonNew from '../../../inc/ButtonNew' 
import Helper from '../../../../inc/Helper' 
import Settings from '../../../../inc/Settings'
import Api from '../../../../inc/Api'
import RecallBidding from './RecallBidding'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getDisplayNamesFromArray, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions'

let gridApi = null;

const RecallBiddingListing = (props) => { 
    const [questionnaire, setQuestionnaire] = useState([]) 
    const [allowedRecall, setAllowRecall] = useState([]) 

    const [biddingRecall, setBiddingRecall] = useState({
        showPopup: false,
        id: null
    })
  
    useEffect(() => { 
        getQuestionnaire()
        getAllowRecallFor()
    }, [])
 
    let questionnaireCalled = false 
    let allowedRecallFor = false
 
    const getQuestionnaire = () => {
        if(questionnaireCalled) return;
 
        let api = Api
        questionnaireCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/questionnaire?is_active=True',{}).then(function(res){
            if(res.data.status==="success"){
                setQuestionnaire(res?.data?.data?.map(item => {
                    return {
                        value: item.questionaire_id,
                        label: item.description
                    }
                }))
            }
        }).catch((res) => {  
            console.log(res.response.data.message, 'error')
        })
    }

    const getAllowRecallFor = () => {
        if(allowedRecallFor) return;

        let api = Api
        allowedRecallFor = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/AllowedRecallFor',{}).then(function(res){
            if(res.data.status==="success"){
                setAllowRecall(res?.data?.data?.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }))
            }
        }).catch((res) => {  
            console.log(res.response.data.message, 'error')
        })
    }

    const DeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delRecallBid_ID = element?.data?.rfq_recall_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl+`/RFQRecallPolicy/${delRecallBid_ID}`)
            const rowNode = gridApi?.getRowNode(delRecallBid_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true });
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
      
    const CellHandler = (event) => {   
        if(event.colDef.field == 'rfq_recall_id') { 
            setBiddingRecall((prevState) => {
                return {
                    ...prevState,
                    showPopup: true,
                    id: event?.data?.rfq_recall_id
                }
            })
        }
    }
 
    const OpenPopup = () => {
        setBiddingRecall((prevState) => {
            return {
                ...prevState,
                showPopup: true,
                id: null
            }
        })
    }

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.bidding_count_recall.bidding_count_recall)){
      return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </Fragment>
    }

    const gridColHeaders = [
        {field:'rfq_recall_id', filter: false, sortable: false, headerName:Helper.getLabel(props.language,'rfq_recall_id',"RFQ Recall"), cellRenderer: (params) => gridLinkValueFormat(params)},
        {field:'allowedrecall_for', cellRenderer: (params) => getDisplayNamesFromArray(params, allowedRecall, 'value', 'label'), filterParams: { values: (params) => getOptions(params, allowedRecall), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName:Helper.getLabel(props.language,'allowedrecall_for',"Allowed Recall For")},
        {field:'questionnaire_id', cellRenderer: (params) => getDisplayNamesFromArray(params, questionnaire, 'value', 'label'), filterParams: { values: (params) => getOptions(params, questionnaire), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName:Helper.getLabel(props.language,'questionnaire_id',"Questionnaire")},  
    ];

    return (
        <div className='bidding_count_recall_listings'>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'bid_count_policy_recall',"Bid Recall Policy")}>
                    <ButtonNew onClick={OpenPopup} title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.bidding_count_recall.bidding_count_recall_main_create_button)} /> 
                </NavigationHeder> 
 
                <AgGridNew
                    apiUrl={Settings.apiUrl+'/RFQRecallPolicy'} 
                    hideDeleteBtn={!security.canDelete(frontendIds.procure.request_for_quotation.control_forms.bidding_count_recall.bidding_count_recall_main_delete_button)}
                    pagination={false}
                    columnDefs={gridColHeaders} 
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'rfq_recall_id'}
                    onCellClicked={CellHandler}
                    handleDeleteSelectedRows={DeleteHandler}
                    onGridReady={(params) => gridApi = params.api} 
                    gridId={"rs_bidding_count_recall_list"}
                />
                {biddingRecall.showPopup && <RecallBidding id={biddingRecall.id} gridApi={gridApi} closePopup={setBiddingRecall} />}
            </div> 
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.procure.request_for_quotation.control_forms.bidding_count_recall.bidding_count_recall
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(RecallBiddingListing, SecurityOptions))))