import React from 'react';
import './Conversion.css'; 

const Conversion = ({children}) => {
  return ( 
    <div className='currency-container ms-4 mt-2 mb-2'>
      {children}
    </div> 
  )
}

export default Conversion;
