import React from 'react'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles' 
import Alert from '../../inc/Alert';
import { connect } from 'react-redux';
import PurchaseRequisitionList from './index';
import { checkForRolesPermission } from '../../../inc/Validation';  
import MasterComponentWraper from '../../Backend/MasterComponentWraper';

const AllPRListingsCreatedByMe = ({security, user}) => {
    if ( user?.usertype===0 && !security?.canView(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_created_by_user) ) {
        return ( 
            <Alert 
                message="You do not have any access to the following screen, please contact your administrator!" 
                type="danger" 
            />  
        );
    };
    return (
        <PurchaseRequisitionList 
            isDisableDeleteBtn={(user?.usertype===0 && !security?.canDelete(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_created_by_me_delete_button)) || checkForRolesPermission(user?.roles, 'Purchase Requisition')}
            isDisableCreateBtn={user?.usertype===0 && !security?.canCreate(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_created_by_me_create_button)} 
        />
    )
}

const mapStateToProps = (state) => {
    return { 
        user:state.auth.user
    };
};

const SecurityOptions = {
    gui_id: Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_created_by_user
}

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(AllPRListingsCreatedByMe, SecurityOptions))))