import React, { Fragment, useState } from 'react'
import Gui_id_list from '../../../inc/Gui_id_list'
import MasterComonent from '../../Backend/MasterComonent'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import { connect } from 'react-redux'
import NavigationHeder from '../../Navigations/NavigationHeder' 
import Helper from '../../../inc/Helper'
import Button from '../../inc/Button'
import Tabs from '../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import ExtHeaderLine from '../../Header/ExtHeaderLine'
import Collapse from '../../inc/Collapse'
import Input from '../../Forms/Input'
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import ToggleSwitch from '../../Forms/ToggleSwitch'
import RFQCommercialProposal from './RFQCommercialProposal'
import RFQLabor from './RFQLabor'
import RFQMiscellaneousCharges from './RFQMiscellaneousCharges'
import OrderStatus from '../../Widgets/OrderStatus/OrderStatus'
import TimeLapse from '../../Widgets/Time lapse/TimeLapse'
import RFQBiddingRequirement from './RFQBiddingRequirement'
import RFQBiddingProposal from './RFQBiddingProposal'   
 
const RFQDetails = (props) => {
  const [RFQ, setRFQ] = useState({})
  
  const addComponentRight = () => {
    return (
      <MasterSidebar>   
        <TimeLapse days={10} minutes={43} hours={3} seconds={43} />
        <OrderStatus />
        <NoteAttachmentHistory  hideHistory={true} notesource_integrator= {null}  attachmentsource_integrator ={null} source_id={10} />
      </MasterSidebar>
    )
  }

  const defaultNav = [
    {title:Helper.getLabel(props.language,'hide_tab','hide_tab'), isActive: true}
  ]

  const BiddingInputBoxes = [
    {label:Helper.getLabel(props.language,'rfq_id',"RFQ ID"), className:'col-lg-4', inputType: "text", name:'rfq_id'},
    {label:Helper.getLabel(props.language,'rfq_title',"RFQ Title"),className:'col-lg-4', inputType: "text", name:'rfq_title'},
    {label:Helper.getLabel(props.language,'expiration_date_&_time',"Expiration Date & Time"),className:'col-lg-4', inputType: "datepicker" },
    {label:Helper.getLabel(props.language,'bid_currency',"Bid Currency"), inputType: "dropdown",className:'col-lg-4', options: [], id: 'bid_currency', name:'bid_currency'},
    {label:Helper.getLabel(props.language,'note',"Note"), inputType: "text", className:'col-lg-4', name:'note'}, 
  ]
    
  const navs = [
    {title:Helper.getLabel(props.language,'proposal_line','Proporsal Line'), isActive: true, }, 
    {title:Helper.getLabel(props.language,'requirement','Requirement')}, 
    {title:Helper.getLabel(props.language,'commercial_proposals','Commercial Proposals')}, 
    {title:Helper.getLabel(props.language,'labor','Labor')}, 
    {title:Helper.getLabel(props.language,'miscellaneous_charges','Miscellaneous charges')}, 
  ]
 
  const RFQBiddingModule = () => {
    // <div className='w-100'> 
    // <CompliancesConsolidatedView />
    // </div>
    return (
    <Tabs navs={defaultNav} showAll={true} scrollOffset="300" addComponentRight={addComponentRight}>
      <Fragment>
        <ExtHeaderLine title={Helper.getLabel(props.language,'general',"General Information")} /> 
        <Collapse className='mt-3' title={Helper.getLabel(props.language,'bidding',"Bidding")} open={true}> 
          <div className='request_for_quotation_bidding'> 
            <FieldsRenderer fieldsArray={BiddingInputBoxes} updateState={setRFQ} currentState={RFQ} />
          </div>
        </Collapse>
        <div className='bidding_tab mt-3'>
          <Tabs navs={navs} showAll={false} className='me-5' scrollOffset="300">
            <div className='bidding_proposal_line ps-3 pe-3'>  
              <RFQBiddingProposal />
            </div>
            <div className='bidding_requirement'>
              <RFQBiddingRequirement />
            </div>
            <div className='bidding_commercial_proposal ps-3 pe-3 '>
              <RFQCommercialProposal />
            </div>
            <div className='bidding_labor ps-3 pe-3 '>
              <RFQLabor />
            </div>
            <div className='bidding_miscellaneous_charges ps-3 pe-3'>
              <RFQMiscellaneousCharges />
            </div>
          </Tabs> 
        </div>
      </Fragment>  
    </Tabs>
    )
  }

  const SaveHandler = () => { }

  return (
    <div className='request_for_quotation_detail_screen'>
      <MasterComonent>
        <NavigationHeder title={`<span style="color:#FC5E24;">${Helper.getLabel(props.language,'rfq',"Request For Quotation")} </span>` }> 
          <Button isActive= {false} className='rfq_document_btn' onClick={SaveHandler} icon={'/images/icons/document.svg'} />
          <Button isActive= {false} className='rfq_targets_btn' onClick={SaveHandler} icon={'/images/icons/target.svg'} />
          <Button isActive= {false} className='rfq_print_btn' title={Helper.getLabel(props.language,'print',"Print")} icon={'/images/icons/print.svg'} />
          <Button isActive= {false} title={Helper.getLabel(props.language,'document_builder',"Document Builder")} />
          <Button isActive= {true} title={Helper.getLabel(props.language,'publish',"Publish")} onClick={SaveHandler}/>
        </NavigationHeder>

        <div className='tabs_default'>
          {RFQBiddingModule()}
        </div>
      </MasterComonent>
    </div>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.request_for_quotation.request_for_quotation_create_screen
}

export default connect(mapStateToProps)  ((ApplySecurityRoles(RFQDetails, SecurityOptions)))