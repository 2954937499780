import React, { Component } from 'react';
import ReturnOrders from '../../components/Purchase Orders Internal/ReturnOrder/ReturnOrders';

class RoReturnOrderPage extends Component {
    render() {
        return (
            <ReturnOrders 
                backUrl = '/release-order/edit'
                editUrl = '/release-order/return-order/edit'
            />
        );
    }
}

export default RoReturnOrderPage;