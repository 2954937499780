import React, {useState, useEffect, Fragment, useCallback} from 'react';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper'; 
import {connect} from 'react-redux'; 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import Alert from '../../inc/Alert';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import Api from '../../../inc/Api';
import AgGridNew from '../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';

let gridApi = null;
let AddBtn;
let transaction;
const Form = (props) => { 
  const [moduleList, setModuleList] = useState([]);
  
  let getModuleCalled = false; 
  
  useEffect(() => { getAllModules() }, []);

  const handleBtn = (value, transactionData) => {
    AddBtn = value;
    transaction = transactionData;
  };

  const onRowValueChanged = (event) => {
    if (event?.data?.form_Id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  };
  
  const getAllModules = () => {
    if(getModuleCalled) return; 
    getModuleCalled = true
    
    const api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiSystemUrl+'/module',{}).then(function(res){
      if(res.data.status==="success"){
        setModuleList(res?.data?.data?.map((item) => {
          return {
            value: item.module_id,
            label: item.modulename
          }
        }));
      };
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    });
  }
  
  const updateData = async (event) => { 
    try {
      let payload = { 
        form_name:event?.data?.formname,
        GUI_Id: event?.data?.GUI_ID,
        path: event?.data?.path,
        description: event?.data?.description
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiSystemUrl+`/form/${event?.data?.form_Id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  }

  const addNewData = async (event) => {   
    try {
      let payload = {
        module_id: event?.data?.module,
        form_name: event?.data?.formname,
        GUI_Id: event?.data?.GUI_ID,
        path: event?.data?.path,
        description: event?.data?.description
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiSystemUrl+'/forms', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);

        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delForms_ID = element?.data?.form_Id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiSystemUrl+`/form/${delForms_ID}`)
      const rowNode = gridApi?.getRowNode(delForms_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  const gridColumnHeaders = [
    {
      field: 'module',
      headerName: 'Module',
      editable: (params) => params?.data?.isNew,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth: 180,  
      cellEditorParams: {
        values: moduleList
      },
      cellDataType:false,
      valueFormatter: (params) => getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, moduleList),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    }, 
    {field:'formname', headerName:'Form Name', editable: true },
    {field:'GUI_ID', headerName:'GUI ID', editable: true },
    {field:'path', headerName:'Path', editable: true },
    {field:'description', headerName:'Description', editable: true } 
  ];

  if(!props?.user?.admin_user){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return ( 
    <Fragment>
      <NavigationHeder hideMoreBtn={true} title='Security Roles Form' />

      <AgGridNew
        apiUrl={Settings.apiSystemUrl+'/forms'}
        pagination={false}
        columnDefs={gridColumnHeaders}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={DeleteHandler}
        height={500}
        onGridReady={(params) => gridApi = params?.api }
        handleAddButton={handleBtn}
        uniqueField={`form_Id`}
        gridId={"form-grid"}
      />
    </Fragment>
  )
}
   
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language,
    user:state.auth.user
  }
}

export default connect(mapStateToProps) (MasterComponentWraper((Form)))