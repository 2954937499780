import React, { useState } from 'react'
import MasterComonent from '../../Backend/MasterComonent'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ButtonNew from '../../inc/ButtonNew'
import Helper from '../../../inc/Helper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import BudgetEntryPopup from './BudgetEntryPopup'
import { getNewValue, gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import { useNavigate } from 'react-router-dom'
import { Budget_Data } from './BudgetData'

const BudgeEntryList = () => {
  const [budgetEntryPopup, setBudgetEntryPopup] = useState(false)

  const navigate = useNavigate()

  const onNewBtnClick = () => {
    setBudgetEntryPopup(true)
  }

  const onPopupClose = () => {
    setBudgetEntryPopup(false)
  }

  const onCellClicked = (event) => {
     if(event?.colDef.field==='title'){
        navigate(`/edit-budget-entry/${event?.data?.id}`)
     }
  }

  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: Budget_Data?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`,
        })),
        rowCount: Budget_Data?.length,
      })
    },
  }

  const onGridReady = (params) => {
    if (params) {
      setTimeout(() => {
        params?.api?.setGridOption('serverSideDatasource', dataSource)
      }, 200)
    }
  }

  const Columns_Headings = [
    {
      field: 'title',
      headerName: Helper.getLabel(null, 'title', 'Title'),
      editable: false,
      minWidth:180,
      cellRenderer:(params)=>{return gridLinkValueFormat(params)}
    },
    {
      field: 'year',
      headerName: Helper.getLabel(null, 'year', 'Year'),
      editable: false,
      minWidth:160,
    },
    {
      field: 'initiate_date',
      headerName: Helper.getLabel(null, 'initiate_date', 'Initiate date'),
      editable: false,
      minWidth:180,
      cellRenderer:(params)=>{return DatepickerFunctions.convertDateFromDataBase(params.value)}
    },
    {
      field: 'department',
      headerName: Helper.getLabel(null, 'department', 'Department'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'project',
      headerName: Helper.getLabel(null, 'project', 'Project'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'owner',
      headerName: Helper.getLabel(null, 'owner', 'Owner'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'description',
      headerName: Helper.getLabel(null, 'description', 'Description'),
      editable: false,
      minWidth: 200,
    },
    {
      field: 'total_budget_estimated',
      headerName: Helper.getLabel(null, 'total_budget_estimated', 'Total budget estimated'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'key_objectives',
      headerName: Helper.getLabel(null, 'key_objectives', 'Key objectives'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'status',
      headerName: Helper.getLabel(null, 'status', 'Status'),
      editable: false,
      minWidth:180,
    },
  ]

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title='Budget Entry' hideMoreBtn={true}>
          <ButtonNew title={Helper.getLabel(null, 'new', 'New')} onClick={onNewBtnClick}></ButtonNew>
        </NavigationHeder>
        <div>
          <AgGridNew gridId={'budget-entry-grid'} isDataSourceRequire={true} columnDefs={Columns_Headings} height={500} hideAddBtn={true} isDeleteBtnDisable={true} onGridReady={onGridReady} onCellClicked={onCellClicked} />
        </div>
      </MasterComonent>
      {budgetEntryPopup && <BudgetEntryPopup ClosePopup={onPopupClose} />}
    </div>
  )
}

export default BudgeEntryList
