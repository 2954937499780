import React, { Fragment, useCallback, useState } from 'react';
import NonCompliancePolicyPopup from './NonCompliancePolicyPopup';
import { connect } from 'react-redux';
import ButtonNew from '../../../inc/ButtonNew'; 
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import Helper from '../../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import { gridLinkValueFormat } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import Gui_id_list from '../../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../../inc/Alert';

let gridApi = null;

const NonCompliancePolicyListings = ({language,security}) => { 
    const [popupDetails, setPopupDetails] = useState({
        showPopup: false,
        id: null
    });
    
    const DeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delNonCompliancePolicy_ID = element?.data?.non_compliancepolicy_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl+`/non_compliance_policy/${delNonCompliancePolicy_ID}`)
            const rowNode = gridApi?.getRowNode(delNonCompliancePolicy_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200)
            gridApi?.refreshServerSide({ purge: true });
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const CellHandler = (event) => {   
        if(event.colDef.field == 'name') { 
            setPopupDetails((prevState) => {
                return {
                    ...prevState,
                    showPopup: true,
                    id: event?.data?.non_compliancepolicy_id
                }
            })
        }
    }
 
    const OpenPopup = () => {
        setPopupDetails((prevState) => {
            return {
                ...prevState,
                showPopup: true,
                id: null
            }
        })
    }

    const gridColHeaders = [
        {   field:'name', headerName:Helper.getLabel(language,'name',"Name"), cellRenderer: (params) => gridLinkValueFormat(params)   },
        {   
            field:'delivery_date_is_past_date', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: false, headerName: 'Delivery date is past date', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.delivery_date_is_past_date || !params?.data){
                    return false
                }
                else {
                    return params.data?.delivery_date_is_past_date
                }
            }
        },
        {   
            field:'delivery_date_is_pr_creation_date', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: false, headerName: 'Delivery date is PR creation date', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.delivery_date_is_pr_creation_date || !params?.data){
                    return false
                }
                else {
                    return params.data?.delivery_date_is_pr_creation_date
                }
            }
        }, 
        {   
            field:'start_date_is_past_date', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: false, headerName: 'Start date is past date', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.start_date_is_past_date || !params?.data){
                    return false
                }
                else {
                    return params.data?.start_date_is_past_date
                }
            }
        },
        {   
            field:'start_date_is_pr_creation_date', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: false, headerName: 'Start date is PR creation date', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.start_date_is_pr_creation_date || !params?.data){
                    return false
                }
                else {
                    return params.data?.start_date_is_pr_creation_date
                }
            }
        },
        {
            field:'non_compliance_request_allowed_to_be_converted_into_rfq', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: false, headerName: 'Non compliance request allowed to be converted into RFQ', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.non_compliance_request_allowed_to_be_converted_into_rfq || !params?.data){
                    return false
                }
                else {
                    return params.data?.non_compliance_request_allowed_to_be_converted_into_rfq
                }
            }
        },
        {
            field:'active', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: false, headerName: 'Active', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if(!params?.data?.active || !params?.data){
                    return false
                }
                else {
                    return params.data?.active
                }
            }
        }
    ];

    const reloadParentGrid = () => {
        gridApi?.refreshServerSide({ purge: true });
        gridApi?.deselectAll();
    };

    if(!security.canView(Gui_id_list.setupForms.non_compliance.non_compliance_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(language,'non_compliance_policy',"Non Compliance Policy")}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.non_compliance.non_compliance_main_create_button)} onClick={OpenPopup} title={Helper.getLabel(language,'new',"New")} /> 
                </NavigationHeder> 
 
                <AgGridNew
                    apiUrl={Settings.apiUrl+'/non_compliance_policy'}  
                    pagination={false}
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.non_compliance.non_compliance_main_delete_button)}
                    columnDefs={gridColHeaders} 
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'non_compliancepolicy_id'}
                    onCellClicked={CellHandler}
                    handleDeleteSelectedRows={DeleteHandler}
                    onGridReady={(params) => gridApi = params?.api} 
                    gridId={"rs_non_compliance_policy_list"}
                />
                {popupDetails?.showPopup && <NonCompliancePolicyPopup policy_id={popupDetails?.id} reloadParentGrid={reloadParentGrid} setShowCreateModal={setPopupDetails} />}
            </div> 
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { 
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.setupForms.non_compliance.non_compliance_main
};

export default connect(mapStateToProps) (MasterComponentWraper(( ApplySecurityRoles( NonCompliancePolicyListings, SecurityOptions))));