import React, { useState } from 'react';
import HierarchyItem from './HierarchyItem';
import { connect } from 'react-redux';

const SitesHierarchy = (props) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (itemId) => {
    setActiveItem(itemId); 
  };

  const Items = props.items.data ? props.items.data : [];

  return (
    <div className='treeview_vendor'>
      <ul>
        <li className="treeview_vendor_heading">
          <h3 style={{ backgroundColor: '#FFF' }}>{props.items.mainTitle}</h3>
          <ul>
            {Items.map((item, key) => (
              <HierarchyItem
                key={key}
                item={item}
                onClick={handleItemClick}
                isActive={activeItem === item.id}
              />
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SitesHierarchy); 