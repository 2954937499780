class GridPrevilegesDetails {
    constructor() {
        this.addBtnId = 'add_api';
    }

    init(params) {
        this.params = params;
        this.togglePopupState = params.togglePopupState 
        this.assignRoleId = params.assignRoleId
        this.eGui = document.createElement('div');
        this.eGui.classList.add('detailed_grid_view_renderer');
        this.privileges = this.params.data.privileges; 
        this.eGui.innerHTML = 'Loading..';
        this.setupDetailGrid(); 
        this.addAPIEventListener = this.onAddClick(); 
        this.eAddButton = this.eGui.querySelector('#add_api'); 
        this.eAddButton.addEventListener('click', this.addAPIEventListener); 
    } 

    setupDetailGrid() {
        this.eGui.innerHTML = this.getInnerHtml(this.params.data.privileges);
    }

    onAddClick() {
        return () => {
            this.togglePopupState(true); 
            this.assignRoleId(this.params.data.role_id)
        };
    }
  
    getInnerHtml(detailsArray) {  
        let arrayData = '';
        detailsArray.forEach((item) => {
            arrayData += `<tr class="rs_item_row"><td>${item.name}</td><td>${item.description}</td></tr>`;
        });
        return (
            `<div class="rs_previleges_buttons">
                <button id="${this.addBtnId}"><img src="/images/icons/add-new.svg" /> Add</button>
            </div>
            <table class="table rs_prevlieges_details_table">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    ${arrayData}
                </tbody> 
            </table>`
        );
    }

    getGui() {
        return this.eGui;
    }
    
    refresh(params) {
        return false;
    }
}
  
export default GridPrevilegesDetails;