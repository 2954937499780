import React, { useEffect, useState } from "react";
import Button from "../../inc/Button";
import BanksEdit from "../../Vendors/Vendors Edit/BanksEdit"

const BankDetails = (props) => {
  const [vendorBanks,setVendorBanks]= useState([])
  let disableCondition = props?.data?.document_status!=="Draft";

  useEffect(()=>{
    banksGetFunction()
  },[vendorBanks?.length])

  const goStep = (stepNumber) => {
    banksGetFunction()
    props.goStep(stepNumber);
  };

  let currency = props.currencies.map((item) => {
    return { label:  `${item.currency_code} - ${item.currency_name}`, value: item.currency_id };
  });

  const banksGetFunction = () => {
    if (vendorBanks?.length > 0) {
      props.updateStep(4, true);
    } else {
      props.updateStep(4, false);
    }
  }

  return (
    <div>
      {/* {banksLoading ? (
        <SimpleLoading />
      ) : ( */}
        <BanksEdit
        disableFields={disableCondition}
        currency={currency}
        id={props.data.vendorsite_id}
        type="vendor registration"
        setVendorBanks={setVendorBanks}
      />
      {/* )} */}
      <div className="tabs_changer">
        {props.activeStep >= 2 ? (
          <Button
            className="light_gray btn_back"
            title="Back"
            onClick={(e) => goStep(props.activeStep - 1)}
          />
        ) : (
          ""
        )}
        <Button
          className="blue"
          title="Next"
          onClick={(e) => goStep(props.activeStep + 1)}
        />
      </div>
    </div>
  );
};

export default BankDetails;
