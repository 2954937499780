import React from "react";
import { connect } from "react-redux";
import AgGrid from "../../../grid/ag/ag-grid";
import Helper from "../../../../inc/Helper";
import CustomHtmlCell from "../../../grid/ag/cellRender/CustomHtmlCell";




const LeftVersionsSideBar = (props) => {

  const RenderGridVersionsLabel = () => {
    return (
      <h2 className="grid_label_rfq">
        {props.title?props.title:Helper.getLabel(props.language, "versions", "Versions")}
      </h2>
    );
  }; 


  //function for link style
  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link">${params.value}</div>`;
  };
   
  //columns heading/title data
  const GridColumnsHeadingsVersions = [
    {
      field: "version",
      headerName: Helper.getLabel(props.language, "version", "Version"),
      minWidth: 105,
      cellRenderer: CustomHtmlCell,
      editable:false,
      valueFormatter: gridLinkValueFormat,
    },
    {
      field: "modifieddatetime",
      headerName: Helper.getLabel(props.language, "change_date", "Change date"),
      minWidth: 125,
      editable:false,
      inputType: "datepicker",
    },
    {
      field: "vendor_replystatus",
      headerName: Helper.getLabel(props.language, "status", "Status"),
      minWidth: 120,
      editable:false,
      cellRenderer: CustomHtmlCell,
      valueFormatter: (params) => `<div>${params.value.replace("_"," ")}</div>`,
    },
  ];

  return (
    <div className="rfq-versions-left-sidebar">
      <div className="left-agGrid">
        <AgGrid
          header={props.header?props.header:GridColumnsHeadingsVersions}
          data={props.versions}
          afterDeleteButtons={RenderGridVersionsLabel}
          hideSave={true}
          addBtnHide={true}
          hideDelete={true}
          dissableCheckBox={true}
          hideFooter={true}
          onCellClicked={props.CellHandler}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(LeftVersionsSideBar);

