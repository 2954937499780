import Helper from '../../../../inc/Helper';
import moment from 'moment';
import DatepickerFunctions from '../../../../inc/DatepickerHelper';
import store from '../../../../store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import $ from 'jquery'
// import 'jquery-ui/ui/widgets/datepicker';
// import '../../../../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
 
class NewDatePicker {
    init(params) {
        this.eInput = document.createElement('input');
        this.userDateFormate =  store.getState().dateFormate.formate;
        if(params.colDef.inputId){
            this.eInput.id = params.colDef.inputId;
        }
        if(params.value){
            console.log(params.value, this.eInput.value)
            if(Helper.isFrontendFormatedDate(params.value)){
                this.eInput.value = params.value;
            }else{
                this.eInput.value = DatepickerFunctions.convertDateFromDataBase(params.value);
            }
        }else{
            this.eInput.value = null;
        }
        this.eInput.classList.add('ag-date-picker-input');
        this.eInput.style.height = '100%';
        this.eInput.style.width = '100%';
        console.log(this.userDateFormate, Helper.getDatePickerFormate(), 'date format')
        let datePickerOptions = {
            timepicker: false,
            format: Helper.getDatePickerFormate(),
            mask:false,
        }
        datePickerOptions.onShow =function( ct ){
            if(params.colDef.minDateInputId){
                let minDate = document.getElementById(params.colDef.minDateInputId); 
                if(minDate){
                    this.setOptions({
                        minDate:minDate.value
                    })
                }
            }
            if(params.colDef.maxDateInputId){ 
                let maxDate = document.getElementById(params.colDef.maxDateInputId); 
                if(maxDate){
                    this.setOptions({
                        maxDate:maxDate.value
                    })
                }
            }
            
        } 
        console.log(this.eInput,'input')
        $(this.eInput).datetimepicker(datePickerOptions);
    }

    // gets called once when grid ready to insert the element
    getGui() {
      return this.eInput;
    }
  
    // focus and select can be done after the gui is attached
    afterGuiAttached() {
      //this.eInput.focus();
      //this.eInput.select();
    }
    getValue() {
      if(this.eInput.value === 'undefined'){
        return null;
      }
      return this.eInput.value;
    }
    destroy() {

    }
    isPopup() {
      // and we could leave this method out also, false is the default
      return false;
    }
    focusIn(){
      this.eInput.focus();
    }
  
}
export default NewDatePicker;