import { Component } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import Button from "../../inc/Button";
import SimpleLoading from "../../Loading/SimpleLoading";

class ConfirmationModal extends Component {
  render() {
    const {
      show,
      hide,
      isLoading,
      itemInAction,
      callback,
      content,
      primaryBtnText,
      secondaryBtnText,
    } = this.props;

    return (
      <Modal className="addDocumentNumber" show={show} onHide={hide}>
        <ModalBody>
          <div className="addDocumentNumber-body p-2">
            <div className="addDocumentNumber-body-content" style={{justifyContent:"start"}}>
              <label>{content}</label>
            </div>
            <div className="d-flex justify-content-end gap-2">
              {isLoading ? (
                <SimpleLoading />
              ) : (
                <>
                  <Button
                    isActive={false}
                    title={secondaryBtnText}
                    onClick={() => {
                      this.props.hide();
                    }}
                  />
                  <Button
                    isActive={true}
                    title={primaryBtnText}
                    onClick={() => {
                      callback(itemInAction);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ConfirmationModal;
