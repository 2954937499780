import React from 'react'
import Helper from '../../../../inc/Helper'
import EditPO from '../../../Purchase Orders Internal/Edit PO/EditPO'
import Gui_id_list from '../../../../inc/Gui_id_list'
import MasterComonent from '../../../Backend/MasterComonent'
import Alert from '../../../inc/Alert'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

const EditCreditNote = ({security}) => {
  if (!security.canView(Gui_id_list.finance.credit_notes.credit_notes_from_po_edit_screen)) {
    return (
      <MasterComonent>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </MasterComonent>
    )
  }
  return (
    <EditPO
      title={Helper.getLabel(
        null,
        'edit_credit_note',
        'Edit Credit Note'
      )}
      lineTitle={Helper.getLabel(null, 'credit_notes_lines',"Credit Notes Lines")}
      backUrl={-1}
      security={security}
      hideStartDate={false}
      hideEndDate={false}
      hideBillingRule={false}
      source_id={81}
      visibleEndDate={true}
      visibleStartDate={true}
      type="credit notes"
    />
  )
} 

const SecurityOptions = {
  gui_id: Gui_id_list.finance.credit_notes.credit_notes_from_po_edit_screen
};

export default ApplySecurityRoles(EditCreditNote, SecurityOptions);  