import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../../Purchase Order/ExtPO.css'
import Helper from '../../../../inc/Helper'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import { useLocation, useNavigate } from 'react-router-dom'
import Settings from '../../../../inc/Settings'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import MasterComonent from "../../../Backend/MasterComonent";
import CommonApiCalls from '../../Purchase Order Screen/CommonApiCalls/CommonApiCalls'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import getReleaseOrderColumns from './ReleaseOrderColumnsHeadings'

let gridApi = null

const ReleaseOrdersList = (props) => {
  const location= useLocation();
  const [loading, setLoading] = useState(false)
  const [documentType, setDocumentType] = useState('')
  const [purchaseStatuses,setPurchaseStatuses]=useState([])
  const [documentStatuses,setDocumentStatuses]=useState([])

  const contractApiCalls = new WorkConfirmationApiCalls()
  const contractStatusCalls = new CommonApiCalls()

  const navigate = useNavigate()

  const handleGridReady= (params) => {
    gridApi = params.api
  }

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  
  const status = getSearchParam('status')
  
  //function to fetch data
  const getAsyncData = async () => {
    setLoading(true)
    const poAllHeaderTypes = await contractApiCalls.getAllPOHeaderType()
    const purchase_statuses = await contractStatusCalls.getAllPurchaseOrderStatus()
    const document_statuses = await contractStatusCalls.getAllDocumentStatus()
    const poAllHeaderType = poAllHeaderTypes?.find(
      (item) => item.name === 'Release Order',
    )
    if (poAllHeaderType && purchase_statuses && documentStatuses) {
      setPurchaseStatuses(purchase_statuses)
      setDocumentStatuses(document_statuses)
      setDocumentType(poAllHeaderType?.id)
      setLoading(false)
    }
  }


  //function for on cell click
  const cellHandler = (event) => { 
    if (event.colDef.field === 'document_number') {
      navigate(
        `/release-order-details?&purchase_order_id=${event.data?.purchase_order_id}`,
      )
    }
    if (event.colDef.field === 'vendor_code') {
      navigate(
        `/edit-vendor/${event?.data?.vendor_id}` 
      )
    }
  }

  const typeTitle = () => {
    if(parseInt(status)===0){
      return "Draft"
    }else if(parseInt(status)===1){
      return "Confirmed"
    }
    else if(parseInt(status)===2){
      return "Under Review"
    } else {
      return "All"
    }
  }

  const Columns_Headings = getReleaseOrderColumns({
    language:props.language,
    purchaseStatuses:purchaseStatuses,
    documentStatuses:documentStatuses
  })

  useEffect(() => {
    getAsyncData()
  }, [status])

  return (
    <div className="purchase_orders_listing_archive external-theme">
      <MasterComonent>
      <div className="container-fluid">
        <NavigationHeder title={`Release Orders - ${typeTitle()}`}             
          backUrl={
            location.search === '' ? props.auth?.user?.usertype===1 && `/dashboard` : -1
          }
          hideMoreBtn={true}
        ></NavigationHeder>
        {loading ? (
          <SimpleLoading />
        ) : (
          <div> 
            <ExtHeaderLine
              title={Helper.getLabel(
                props.language,
                'release_orders',
                `Release Orders - ${typeTitle()}`,
              )}
            />
            <AgGridNew
              apiUrl={Settings.apiPurchaseOrderUrl + `/PO_header`}
              additionalParams={`&type=${documentType?.id || 2} ${status?`&document_status=${status}`:''}`}
              filterAdditionalParams={`&type=${documentType?.id || 2} ${status?`&document_status=${status}`:''}`}     
              pagination={true}
              columnDefs={Columns_Headings}
              onGridReady={handleGridReady}
              isDisabledCheckbox={true}
              onCellClicked={cellHandler}
              uniqueField={'purchase_order_id'}
              hideAddBtn={true}
              height={500}
              hideDeleteBtn={true}
              gridId={`release-order-external-listing-page`}
            />
          </div>
        )}
      </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(ReleaseOrdersList)
