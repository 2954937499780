import Helper from "../../../../inc/Helper";
import Settings from "../../../../inc/Settings";
import {
  getPopupMessageBasedOnStatus,
  removeDuplicates,
} from "../../../../inc/Validation";
import Api from "../../../../inc/Api";

class FormResourcesDetails {
  constructor() {
    this.addBtnId = "add_api";
    this.deleteBtnId = "delete_api";
    this.deleteRowsId = [];
  }

  init(params) {
    this.params = params;
    this.onAddBtnClick = params.onAddBtnClick;
    this.reloadGrid = params.reloadGrid;
    this.togglePopupState = params.togglePopupState;

    this.eGui = document.createElement("div");
    this.eGui.classList.add("detailed_grid_view_renderer");
    this.eGui.innerHTML = "Loading...";

    this.setupDetailGrid(this.params);

    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  async setupDetailGrid(params) {
    const response = await params.getDetailRowData(params);
    this.eGui.innerHTML = this.getInnerHtml(
      params?.data?.access,
      response?.previlegesList,
      response?.accesses
    );

    this.attachEventListeners();
  }

  attachEventListeners() {
    this.eAddButton = this.eGui.querySelector(`#${this.addBtnId}`);
    this.eDeleteButton = this.eGui.querySelector(`#${this.deleteBtnId}`);

    if (this.eAddButton) {
      this.eAddButton.addEventListener("click", this.onAddClick);
    }

    if (this.eDeleteButton) {
      this.eDeleteButton.addEventListener("click", this.onDeleteClick);
    }

    const checkboxes = this.eGui.querySelectorAll(".rs_item_checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("click", (event) =>
        this.onCheckboxClick(event, checkbox)
      );
    });
  }

  onCheckboxClick(event, checkbox) {
    const id = event.target.getAttribute("data-id");
    const row = checkbox.closest(".rs_item_row");
    if (event.target.checked) {
      this.deleteRowsId.push(id);
      row.classList.add("rs_item_selected");
    } else {
      const index = this.deleteRowsId.indexOf(id);
      if (index > -1) {
        this.deleteRowsId.splice(index, 1);
      }
      row.classList.remove("rs_item_selected");
    }
  }

  onAddClick() {
    this.togglePopupState(true);
    this.onAddBtnClick(this.params.data);
  }

  onDeleteClick() {
    if (this.deleteRowsId?.length === 0) {
      return;
    }

    const ids = removeDuplicates(this.deleteRowsId);

    let api = Api;
    api.setUserToken();

    ids.forEach((element) => {
      api
        .axios()
        .delete(`${Settings.apiSystemUrl}/form_resource_access/${element}`, {})
        .then((res) => {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
            this.reloadGrid();
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res);
        });
    });
  }

  getInnerHtml(detailsArray, previlegesList, accesses) {
    let arrayData = "";
    if (detailsArray && detailsArray.length > 0) {
      detailsArray.forEach((item) => {
        const accessLevels =
          accesses?.find((a) => a.value === item.access_level)?.label || "-";
        const previlegesLabel =
          previlegesList?.find((p) => p.value === item.privilege_id)?.label ||
          "-";
        arrayData += `
          <tr class="rs_item_row">
            <td>
              <input type="checkbox" class="rs_item_checkbox" data-id="${item.formresource_access_id}">
            </td>
            <td class="rs_item_previleges">${previlegesLabel}</td>
            <td>${accessLevels}</td>
          </tr>`;
      });
    } else {
      arrayData =
        '<tr><td colspan="3" class="no-records">No Records To Show</td></tr>';
    }

    return `
      <div class="rs_previleges_buttons">
        <button id="${this.addBtnId}"><img src="/images/icons/add-new.svg" /> Add</button>
        <button id="${this.deleteBtnId}"><img src="/images/icons/delete.svg" /> Delete</button>
      </div>
      <table class="table rs_form_resources_details_table">
        <thead>
          <tr>
            <th scope="col"><input type="checkbox" class="rs_item_checkbox" disabled></th>
            <th scope="col">Previleges</th>
            <th scope="col">Access Level</th>
          </tr>
        </thead>
        <tbody>
          ${arrayData}
        </tbody>
      </table>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh() {
    return false;
  }
}

export default FormResourcesDetails;