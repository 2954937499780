import React from "react";
import MasterComonent from "../../components/Backend/MasterComonent";
import Entity from "../../components/Entity/Entity";
import EditForm from "../../components/Entity/Entity Form/EditForm";
import NewForm from "../../components/Entity/Entity Form/NewForm";

const EntityScreen = (props) => {
  const displayComponent = (props) =>{
    switch(props.pageName){
      case 'new':
        return <NewForm/>
      case 'edit':
        return <EditForm/>

      default:
        return <Entity/>
    }
  }
  return (
    <MasterComonent>
      {
        displayComponent(props)
      }
    </MasterComonent>
  );
};

export default EntityScreen;
