import DatepickerFunctions from "../../../../inc/DatepickerHelper"
import Helper from "../../../../inc/Helper"
import { getOptions, getSiteNameById, getStatusNameById, gridLinkValueFormat, optionsKeyCreator, purchaseStatusValueFormat } from "../../../grid/ag/commonFunctions/GridCommonFunctions"

const getPurchaseOrderColumns = ({language,allSites,purchaseStatuses,documentStatuses}) => {
  return [
    {
      field: 'document_number',
      headerName: Helper.getLabel(
        language,
        'purchase_order',
        'Purchase order no.',
      ),
      minWidth: 160,
      cellRenderer:(params)=>gridLinkValueFormat(params)
    },
    {
      field: 'description',
      headerName: Helper.getLabel(language, 'description', 'Description'),
      editable: false,
      minWidth: 200,
    },
    {
      field: 'vendor_code',
      headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'),
      editable: false,
      minWidth: 200,
      cellRenderer:(params)=> gridLinkValueFormat(params),

    },
    {
      field: 'vendor_name',
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
      minWidth: 180,
    },
    {
      field: 'site_id',
      headerName: Helper.getLabel(language, 'site', 'Site name'),
      minWidth: 200,
      cellRenderer:(params)=>getSiteNameById(params,allSites),
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, allSites),
        keyCreator: (params) => optionsKeyCreator(params, 'site_id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: 'purchase_status',
      headerName: Helper.getLabel(language, 'purchase_status', 'Purchase status'),
      minWidth: 200,
      cellRenderer: (params)=> purchaseStatusValueFormat(params,purchaseStatuses),
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, purchaseStatuses),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: 'document_status',
      minWidth: 200,
      headerName: Helper.getLabel(
        language,
        'document_status',
        'Document status',
      ),
      editable: false,
      cellRenderer: (params)=>getStatusNameById(params.value,documentStatuses),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, documentStatuses),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: 'accounting_date',
      minWidth: 200,
      filter:'agDateColumnFilter',
      headerName: Helper.getLabel(
        language,
        'accounting_date',
        'Accounting date',
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) , 
      editable: false,
      hide:true
    },
    {
      field: 'document_date',
      minWidth: 200,
      filter:'agDateColumnFilter',
      headerName: Helper.getLabel(
        language,
        'document_date',
        'Document date',
      ),
      valueFormatter: (params) =>  DatepickerFunctions.convertDateFromDataBase(params.value) , 
      editable: false,
      hide:true
    },
    {
      field:'delivery_date',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'delivery_date',
        'Delivery date',
      ),
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>   DatepickerFunctions.convertDateFromDataBase(params.value) , 
      editable:false
    },
    {
      field:'delivery_name',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'delivery_name',
        'Delivery name',
      ),
      editable:false
    },
    {
      field:'discount_amount',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'discount_amount',
        'Discount amount',
      ),
      filter:'agNumberColumnFilter',
      editable:false
    },
    {
      field:'discount_percentage',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'discount_percentage',
        'Discount percentage',
      ),
      filter:'agNumberColumnFilter',
      editable:false
    },
    {
      field:'total_amount',
      minWidth:200,
      hide:true,
      filter:'agNumberColumnFilter',
      headerName: Helper.getLabel(
        language,
        'total_amount',
        'Total amount',
      ),
      editable:false
    },
  ]
}

export default getPurchaseOrderColumns