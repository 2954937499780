import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import DropdownIcon from "../../../assets/dropdown-grey.svg";
import StarFilled from "../../../assets/review-star-filled.svg";
import StarEmpty from "../../../assets/review-star-empty.svg";

// this component returns a particular section of filters with certain customisable options that can be added via props. business logic is to be handled via callback functions.
// by default standard filter options will show which can be nested.
//
// PROPS
//
// 1. filters: nested object consisting of only 3 options 'label: string (default text to show)', 'key: string (key to match from translations)' & 'options: array of objects', can be 1 level deep , looks like this:
//    {
//      label: "Department",
//      key: "department"
//      options: [
//        {
//          id: number,
//          name: string
//          options: [{ id: number }, { name: string }],
//        },
//      ],
//    };
// 2. returnReviewFilters: boolean (returns only rating based review filter option)
//    No filters prop is needed in case of reviewFilters
// 3. addSearchbar: boolean (adds search bar below main label to search through filter options)
// 4. addCheckboxes: boolean  (adds checkboxes before filter options)

class SearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeMore: true,
      search: "",
      filtersToShow: {},
    };
    this.toggleSeeMore = this.toggleSeeMore.bind(this);
    this.getLabelId = this.getLabelId.bind(this)
  }

  getLabelId(label, obj) {
    switch (label) {
      case "Brands":
        return obj.itembrand_id;
      case "Sizes":
        return obj.itemsize_id;
      case "Styles":
        return obj.itemstyle_id;
      default:
        return obj.itemcolor_id;
    }
  }

  componentDidMount() {
    const label = this.props.filters?.label;
    const updatedFilters = {
      label,
      options: this.props.filters?.options.map((obj) => {
        return {
          id:this.getLabelId(label,obj),
          name: obj.name,
        };
      }),
    };

    if (updatedFilters) this.setState({ filtersToShow: updatedFilters });
  }

  filledStar() {
    return (
      <img
        className="search-filters-ratings-star"
        src={StarFilled}
        alt="filled star"
      />
    );
  }

  emptyStar() {
    return (
      <img
        className="search-filters-ratings-star"
        src={StarEmpty}
        alt="empty star"
      />
    );
  }

  toggleSeeMore() {
    this.setState({ seeMore: !this.state.seeMore });
  }

  handleSearchChange(event) {
    this.setState({ search: event.target.value });
    this.setState({
      filtersToShow: {
        ...this.props.filters,
        options: this.props.filters.options.filter(
          (item) => item.label.toLowerCase().indexOf(event.target.value) >= 0
        ),
      },
    });
  }

  render() {
    const {
      filters,
      returnReviewFilters,
      returnPriceFilters,
      addSearchbar,
      addCheckboxes,
      handleSelection,
      selectedOptions,
      minPrice,
      maxPrice,
      handleMinPriceChange,
      handleMaxPriceChange,
      handleSearch,
      resetPriceSelection,
    } = this.props;

    if (returnReviewFilters)
      return (
        <div className="search-filters">
          <div className="search-filters-label">
            {Helper.getLabel(this.props.language, "reviews", "Reviews")}
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.filledStar()}
            {this.filledStar()}
            {this.filledStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.filledStar()}
            {this.filledStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.filledStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
        </div>
      );

    if (returnPriceFilters)
      return (
        <div className="search-filters">
          <div className="search-filters-label">
            {Helper.getLabel(this.props.language, "price", "Price")}
          </div>
          <div className="search-filters-options">
            {filters.options?.map((option, index) => {
              return (
                <div
                  className={`search-filters-option search-filters-selectableoption ${
                    selectedOptions === index + 1
                      ? "search-filters-highlighted"
                      : ""
                  }`}
                  key={"price" + option.key}
                  onClick={() =>
                    handleSelection(option.min, option.max, index + 1)
                  }
                >
                  {option.option}
                </div>
              );
            })}
            <div className="search-filters-priceform">
              <div className="search-filters-priceform-field">
                <span>AED</span>
                <input
                  type="text"
                  placeholder="Min"
                  value={minPrice === 0 ? "" : minPrice}
                  onChange={(e) => handleMinPriceChange(e)}
                />
              </div>
              <div className="search-filters-priceform-field">
                <span>AED</span>
                <input
                  type="text"
                  placeholder="Max"
                  value={maxPrice ?? ""}
                  onChange={(e) => handleMaxPriceChange(e)}
                />
              </div>
              <button
                onClick={() => {
                  resetPriceSelection();
                  handleSearch(1);
                }}
              >
                Go
              </button>
            </div>
          </div>
        </div>
      );

    return filters ? (
      <div className="search-filters">
        <div className="search-filters-label">
          {this.state.filtersToShow.label}
        </div>
        {addSearchbar ? (
          <input
            className="search-filters-searchbar"
            type="text"
            placeholder={Helper.getLabel(
              this.props.language,
              "search",
              "Search"
            )}
            onChange={(e) => this.handleSearchChange(e)}
          />
        ) : null}
        <div className="search-filters-options">
          {this.state.filtersToShow.options?.map((option, index) =>
            this.state.seeMore && index >= 5 ? null : (
              <div key={option?.id}>
                <div
                  className={`search-filters-option ${
                    !addCheckboxes
                      ? "search-filters-selectableoption"
                      : "search-filters-checkboxoption"
                  }`}
                >
                  {addCheckboxes ? (
                    <input
                      className="search-filters-checkbox"
                      type="checkbox"
                      value={option.id}
                      checked={selectedOptions.indexOf(option.id) !== -1}
                      onChange={(e) => {
                        handleSelection(parseInt(e.target.value));
                      }}
                    />
                  ) : null}
                  <span>{option.name}</span>
                </div>
                {option.options?.map((nestedOption, index) => (
                  <div
                    className={`search-filters-option search-filters-nestedoption ${
                      !addCheckboxes
                        ? "search-filters-selectableoption"
                        : "search-filters-checkboxoption"
                    }`}
                    key={index + nestedOption?.label}
                  >
                    {addCheckboxes ? (
                      <input
                        className="search-filters-checkbox"
                        type="checkbox"
                      />
                    ) : null}
                    <span>
                      {Helper.getLabel(
                        this.props.language,
                        option.key,
                        option.label
                      )}
                    </span>
                  </div>
                ))}
              </div>
            )
          )}
          {this.state.filtersToShow.options?.length > 5 &&
          this.state.seeMore ? (
            <div
              className="search-filters-seemore"
              onClick={this.toggleSeeMore}
            >
            <img src={DropdownIcon} alt="dropdown" />
              See more
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(SearchFilters);
