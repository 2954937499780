import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import DropDownInput from '../../Forms/Dropdown';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Collapse from '../../inc/Collapse';

const Attributes = (props) => { 
  const [brand, setBrand] = useState([])
  const [color, setColor] = useState([])
  const [style, setStyle] = useState([])
  const [size, setSize] = useState([])
  const [dimension, setDimension] = useState([])
  const [configuration, setConfiguration] = useState([])

  useEffect(() => {
    get_item_brand_list()
    get_item_style_list()
    get_item_color_list()
    get_item_size_list()
    get_item_dimensions_list()
    get_item_configuration_list()
  }, [])

  
  let itemBrandListCalled = false
  let itemStyleListCalled = false
  let itemColorListCalled = false
  let itemSizeListCalled = false
  let itemConfigurationListCalled = false
  let dimensionsListCalled = false

    const BrandHandler = (event) => {
    let brand = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        item_brand_id: parseInt(brand)
      }
    })
    }
    
    const StyleHandler = (event) => {
      let style = event.target.value
      props.setEditItem((prevState) => {
        return {
          ...prevState,
          ItemStyle_id: style
        }
      })
    }
    
    const ColorHandler = (event) => {
      let color = event.target.value
      props.setEditItem((prevState) => {
        return {
          ...prevState,
          item_color_id: parseInt(color)
        }
      })
    }
    
    const SizeHandler = (event) => {
      let size = event.target.value
      props.setEditItem((prevState) => {
        return {
          ...prevState,
          item_size_id: parseInt(size)
        }
      })
    }
    
    const ConfigurationHandler = (event) => {
      let config = event.target.value
      props.setEditItem((prevState) => {
        return {
          ...prevState,
          item_configuration_id: parseInt(config)
        }
      })
    }
    
    const DimensionHandler = (event) => {
      let dimension = event.target.value
      props.setEditItem((prevState) => {
        return {
          ...prevState,
          item_dimension_id: parseInt(dimension)
        }
      })
    }

    
   const get_item_brand_list = () => {
    if(itemBrandListCalled){
      return
    }
    let api = Api
    itemBrandListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_brand`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itembrand_id,
          label: element.name
        })
      })
      setBrand(UPDATED_STATE)
      }
    })
   }

   const get_item_style_list = () => {
    if(itemStyleListCalled){
      return;
    }
    let api = Api
    itemStyleListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_style`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itemstyle_id,
          label: element.name
        })
      })
      setStyle(UPDATED_STATE)
      }
    })
   }

   const get_item_color_list = () => {
    if(itemColorListCalled){
      return;
    }
    let api = Api
    itemColorListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_color`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itemcolor_id,
          label: element.name
        })
      })
      setColor(UPDATED_STATE)
      }
    })
   }

   const get_item_size_list = () => {
    if(itemSizeListCalled){
      return
    }
    let api = Api
    itemSizeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_size`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itemsize_id,
          label: element.name
        })
      })
      setSize(UPDATED_STATE)
      }
    })
   }

   const get_item_configuration_list = () => {
    if(itemConfigurationListCalled){
      return;
    }
    let api = Api
    itemConfigurationListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_configuration`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itemconfiguration_id,
          label: element.name
        })
      })
      setConfiguration(UPDATED_STATE)
      }
    })
   }

   const get_item_dimensions_list = () => {
    if(dimensionsListCalled){
      return;
    }
    let api = Api
    dimensionsListCalled = false
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_dimension`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itemdimension_id,
          label: element.name
        })
      })
      setDimension(UPDATED_STATE)
      }
    })
  }
  
  return (
    <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'attributes',"Attributes")}>
  <div className='container-fluid attributes'>
    <div className='row'>

      <div className='col-lg-4'>
        <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select brand'} reRenderRequired={true} onChange={BrandHandler} value={props.editItem.item_brand_id} options={brand} label={Helper.getLabel(props.language,'brand',"Brand")} id='brand_id' />
      </div>

      <div className='col-lg-4'>
        <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select style'} reRenderRequired={true} onChange={StyleHandler} value={props.editItem.ItemStyle_id} options={style} label={Helper.getLabel(props.language,'style',"Style")} id='style_id' />
      </div>

      <div className='col-lg-4'>
        <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select color'} reRenderRequired={true} onChange={ColorHandler} value={props.editItem.item_color_id} options={color} label={Helper.getLabel(props.language,'color',"Color")} id='color_id' />
      </div>

      <div className='col-lg-4'>
        <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select size'} reRenderRequired={true} onChange={SizeHandler} value={props.editItem.item_size_id} options={size} label={Helper.getLabel(props.language,'size',"Size")} id='size_id' />
      </div>
      
      <div className='col-lg-4'>
        <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select configuration'} reRenderRequired={true} onChange={ConfigurationHandler} value={props.editItem.item_configuration_id} options={configuration} label={Helper.getLabel(props.language,'configuration',"Configuration")} id='configuration_id' />
      </div>
      
      <div className='col-lg-4'>
        <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select dimension'} reRenderRequired={true} onChange={DimensionHandler} value={props.editItem.item_dimension_id} options={dimension} label={Helper.getLabel(props.language,'dimension',"Dimension")} id='dimension_id' />
      </div> 
    </div>
  </div>
</Collapse>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }

export default connect(mapStateToProps) (Attributes)