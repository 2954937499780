import React, { Component } from 'react';
import MasterComonent from "./MasterComonent";
import SimpleLoading from '../Loading/SimpleLoading';
const MasterComponentWraper = (InnerComponent,options) =>{
    class MasterComponentWraperClass extends Component {
        render() {
            return  ( <MasterComonent {...options}  {...this.props}>{this.props.isMasterContentLoader ? <SimpleLoading/> : <InnerComponent {...this.props} /> }</MasterComonent>);
        }
    }
    return MasterComponentWraperClass;
}
export default MasterComponentWraper;
