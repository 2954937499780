import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Popup from '../../../Popup/Popup'
import Collapse from '../../../inc/Collapse'
import { Form } from 'react-bootstrap'
import JoditEditor from 'jodit-react'
import Input from '../../../Forms/Input'
import { useLocation } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import Dropdown from '../../../Forms/Dropdown'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'

let defaultEmailBody = null
const InviteModal = ({ language, handleCloseModal, gridApi, InvitationForMasterLevel = false }) => {
  const location = useLocation()
  const editor = useRef(null)

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [countries, setCountries] = useState([])

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const getAllCommonEvents = () => {
    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/events')
      .then((res) => {
        let vendorInvitationEvent = res.data?.data?.find((val) => val.event_name == 'Vendor Invitation')?.event_id
        if (vendorInvitationEvent) {
          getDefaultEmailTempalteByEvent(vendorInvitationEvent)
        }
      })
      .catch((error) => console.log('Error in getting events: ', error))
  }

  const getDefaultEmailTempalteByEvent = (event_id) => {
    if (!event_id) return

    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/get_email_template_by_events/${event_id}`)
      .then((res) => {
        defaultEmailBody = res.data?.data?.mail_body
        setEmailBody(res.data?.data?.mail_body)
      })
      .catch((error) => console.log('Error in getting email template: ', error))
  }

  const getAllCountries = () => {
    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/country')
      .then((res) => {
        setCountries(res.data.Message)
      })
      .catch((error) => console.log('Error in getting invites: ', error))
  }

  const handleSubmit = () => {
    if (!defaultEmailBody && !emailBody) {
      Helper.alert('Cannot send invitation to Vendor, since, email template is missing!', 'error')
      return
    }

    const payload = {
      RFQ_id: parseInt(getSearchParam('rfq_view_id')),
      vendor_id: null,
      email: email,
      country_id: parseInt(selectedCountry),
      phone_number: parseInt(phone),
      emailBody: emailBody,
    }

    const apiURLForRequest = InvitationForMasterLevel ? `${Settings.apiVendorUrl}/vendorinvitation` : `${Settings.apiPurchaseRequisitionUrl}/invite_vendor`

    const api = Api
    api.setUserToken()

    api
      .axios()
      .post(apiURLForRequest, {
        email: payload?.email,
        country_id: payload?.country_id,
        phone_number: payload?.phone_number,
        vendor_id: InvitationForMasterLevel ? null : payload?.vendor_id,
        email_body: payload.emailBody || payload.emailBody !== '' ? payload.emailBody : null,
        RFQ_id: InvitationForMasterLevel ? null : payload?.RFQ_id,
      })
      .then((res) => {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          handleCloseModal()
          if (InvitationForMasterLevel) {
            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll()
          }
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const handleDropdownValueChange = (e) => {
    setSelectedCountry(e.target.value)
  }

  useEffect(() => {
    getAllCommonEvents()
    getAllCountries()
  }, [])

  let countryOptions = countries?.map((item) => {
    return {
      value: item.country_id,
      label: item.country_long_name,
    }
  })

  return (
    <Popup
      autoOpen={true}
      width='900px'
      onClose={() => {
        $('html').removeClass('rs_popup_open')
        handleCloseModal()
      }}
    >
      <>
        <div className='d-flex justify-content-between align-items-start'>
          <div>
            <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'add_un-registered_vendors', 'Add Un-registered vendors')}</h5>
            <h6 className='inviteModalSubHeading'>{Helper.getLabel(language, 'enter_requirement_details_and_select_template', 'Enter requirement details and select template')}</h6>
          </div>
          <div className='addDocumentNumber-body-buttons' style={{ justifyContent: 'flex-end', gap: '16px' }}>
            <Button
              isActive={false}
              className="black-btn-style"
              title={Helper.getLabel(language, 'Close', 'Close')}
              onClick={() => {
                $('html').removeClass('rs_popup_open')
                handleCloseModal()
              }}
            />
            <Button
              className='solid_btn publish_rfq_blue_btn'
              isActive={true}
              title={Helper.getLabel(language, 'send_invite', 'Send Invite')}
              onClick={() => {
                handleSubmit()
                $('html').removeClass('rs_popup_open')
              }}
            />
          </div>
        </div>
        <Collapse title={Helper.getLabel(language, 'general_information', 'General Information')} open={true}>
          <Form>
            <div className='addVendorModalForm' style={{ marginBottom: '40px' }}>
              <Input label={Helper.getLabel(language, 'email_address', 'Email Address')} value={email} onChange={(e) => setEmail(e.target.value)} />
              <Input label={Helper.getLabel(language, 'phone_number', 'Phone number')} value={phone} onChange={(e) => setPhone(e.target.value)} />
              <Dropdown label={Helper.getLabel(language, 'country', 'Country')} options={countryOptions} name='country_id' value={selectedCountry} onChange={handleDropdownValueChange} />
            </div>
          </Form>
        </Collapse>

        <Collapse title={Helper.getLabel(language, 'mail_body', 'Email Template Body')} open={true}>
          <JoditEditor
            ref={editor}
            value={emailBody}
            tabIndex={1}
            onChange={(newContent) => {
              setEmailBody(newContent)
            }}
          />
        </Collapse>
      </>
    </Popup>
  )
}

export default InviteModal
