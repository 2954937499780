import React from 'react'

const CommonTabContent = ({children,displayRequest,totalTitle,totalCount}) => {
  return (
    <div>
       <div className='feed_tabs_invoice'>
              {displayRequest && 
                <div className='invoice_info_header'>
                  <div className='row'>
                    <div className='col-12'>
                      <h2 className='p-2'>{totalTitle} : {totalCount}</h2>
                    </div>
                  </div>
                </div>
              }

              <div className='flex-row d-flex align-items-center external-dashboard-tabs-main-row'>
                  {children} 
              </div>
       </div>
    </div>
  )
}

export default CommonTabContent
