import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';  
import Collapse from '../inc/Collapse';  
import NavigationHeder from '../Navigations/NavigationHeder'; 
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import AgGridNew from '../grid/ag/ag-grid-new';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';

/* Validations: -> code(10), name (80), both required */

let gridApi = null;
let AddBtn;
let transaction;

const TaxPostingGroup = ({ language, security }) => { 

    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const onRowValueChanged = (event) => {  
        if (event?.data?.taxpostinggroup_id) {
            updateTaxPostingGroup(event)
        } else {
            createTaxPostingGroup(event)
        }
    }

    const createTaxPostingGroup = async (event) => {
        try {
            let payload = { 
                code: event?.data?.code,
                name: event?.data?.name,
                taxpayable: event?.data?.taxpayable,
                taxreceivable: event?.data?.taxreceivable,
                taxsettlement: event?.data?.taxsettlement
            };
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiUrl + '/taxpostinggroup', payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);
                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        } 
    };

    const updateTaxPostingGroup = async (event) => {
        try {
            let payload = { 
                code: event?.data?.code,
                name: event?.data?.name,
                taxpayable: event?.data?.taxpayable,
                taxreceivable: event?.data?.taxreceivable,
                taxsettlement: event?.data?.taxsettlement
            };
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiUrl + '/taxgroupposting/' + event?.data?.taxpostinggroup_id, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };

    const onDeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
    
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + '/taxgroupposting/' + element?.data?.taxpostinggroup_id)
            const rowNode = gridApi?.getRowNode(element?.data?.taxpostinggroup_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }
        
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200);
        
            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
        
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const gridColumnHeaders = [
        { field: 'code', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(language, 'code', 'Code') },
        { field: 'name', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(language, 'name', 'Name') },
        { field: 'taxsettlement', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(language, 'settlement', 'Settlement') },
        { field: 'taxpayable',filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(language, 'payable', 'Payable') },
        { field: 'taxreceivable',filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(language, 'receivable', 'Receivable') },
    ];

    if(!security.canView(Gui_id_list.setupForms.tax_posting_groups.tax_posting_groups_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <div className='tax_posting_group_page'>
            <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(language, 'tax_posting_group', 'Posting Group')} />

            <Collapse open={true} title={Helper.getLabel(language, 'tax_posting_group', 'Tax Posting Group')}>
                <AgGridNew
                    apiUrl={Settings.apiUrl + '/taxpostinggroup'}
                    pagination={false}
                    hideAddBtn={!security.canCreate(Gui_id_list.setupForms.tax_posting_groups.tax_posting_groups_main_create_button)}
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.tax_posting_groups.tax_posting_groups_main_delete_button)}
                    columnDefs={gridColumnHeaders}
                    onRowValueChanged={onRowValueChanged} 
                    handleDeleteSelectedRows={onDeleteHandler}
                    height={500}
                    onGridReady={(params) => gridApi = params?.api}
                    handleAddButton={handleBtn}
                    uniqueField={`taxpostinggroup_id`}
                    gridId={'tax_posting_group'}
                />
            </Collapse>
        </div>
    ); 
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        language: state.language,
    };
};
 
const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.tax_posting_groups.tax_posting_groups_main
};

export default connect(mapStateToProps) ((( ApplySecurityRoles( TaxPostingGroup, SecurityOptions)))); 