import React, { Component } from 'react';
import InputPlaceholder from './InputPlaceholder';
import $ from 'jquery';
import Helper from '../../inc/Helper';
import { connect } from 'react-redux';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
/**
 * disableClear : false
 */
class DropDownInput extends Component {
    constructor(props){
        super(props);
        this.trigerTime = 0;
        this.renderDropdown = this.props.reRenderRequired ? true : false; // this prop let you to re-render the dropdown component, if you are updating the dropdown's value dynamically
        //use this for page Counter
        this.pageCounter = 1;
        this.id =  this.props.id ? this.props.id : (this.props.name ? this.props.name : new Date().getTime());
        this.state ={
            isRequired: this.props.required ? true : false,
            isError:false
        }
    }
    componentDidMount(){
        if(this.props.disable) {
            return;
        }
        let that = this;
        setTimeout(function(){ 
            that.initChoosen();
        },100) 
    }
    componentWillUnmount(){
        let inputSelector = $('#'+this.id);
        //inputSelector.chosen('destroy');
    }
    initChoosen(){
        let language = this.props.language;
        let that = this;
        let inputSelector = $('#'+this.id);
       
        let select2Settings = this.props.select2Settings ? this.props.select2Settings : {};
        //comment out for a time being need confirmation

        // if(this.props.select2Settings){
        //     if(select2Settings.ajax){
        //         select2Settings.ajax.delay = 500;
        //         select2Settings.ajax.transport =  (params, success, failure) =>{
        //             var $request = $.ajax({
        //                 ...params,
        //                 headers:{
        //                     ...params.headers,
        //                     Authorization: 'Bearer '+Api.getUserToken()
        //                 }
        //             });
                
        //             $request.then(success);
        //             $request.fail(failure);
                
        //             return $request;
        //           }
        //         /*
        //         select2Settings.ajax.headers = {
        //             Authorization: 'Bearer '+Api.getUserToken()
        //         }
        //         */
        //         if(!select2Settings.ajax.data){
        //             select2Settings.ajax.data =  (dataParams)  =>{
        //                 var query = {
        //                   search: dataParams.term,
        //                   page_number: dataParams.page ? dataParams.page  : 1,
        //                   page_size:Settings.dropdownPageSize
        //                 }
        //                 return query;
        //             }
        //         }
        //     }
        // }

        //calling of common function
        if (this.props.pagination) {
            select2Settings.ajax = this.createAjaxConfig(
                this.props.apiUrl,
                this.props.mapFunction,
                this.props.currentDropdownData
            )
        }
        let s2Options = {
            placeholder: this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select'),
            allowClear: this.props.disableClear ? false : true,
            width: '100%',
            openOnEnter: true,
            ...select2Settings
        }

        //inputSelector.select2(s2Options)
        if(this.props.disable || this.props.isDisable){
            inputSelector.select2({...s2Options,disabled:true});
        }else{
            inputSelector.select2({...s2Options,disabled:false});
        }
        if (this.props.value && this.props.select2Settings?.multiple) {
            inputSelector.val(this.props.value).trigger('change');
        }
        
        inputSelector.on('select2:select', function (event) {
            if(event.timeStamp !==that.trigerTime){
                if(that.props.onChange && typeof that.props.onChange === 'function'){
                    that.trigerTime =event.timeStamp;
                    that.props.onChange(event)
                }
            }
        });

        inputSelector.on('select2:unselect', function (event) {
            if(event.timeStamp !==that.trigerTime){
                if(that.props.onChange && typeof that.props.onChange === 'function'){
                    that.trigerTime =event.timeStamp; 
                    that.props.onChange(event)
                }
            }
        });
        
        /*
        if(inputSelector){
            inputSelector.chosen({
                disable_search_threshold:0,
                placeholder_text_single:this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select'),
                placeholder_text_multiple :this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select'),
                rtl:Helper.isRtl()
            }).change(function(event){
                if(event.timeStamp !==that.trigerTime){
                    if(that.props.onChange && typeof that.props.onChange === 'function'){
                        that.trigerTime =event.timeStamp;
                        that.props.onChange(event)
                    }
                }
            });
        } 
        */
    
    }

    //made common function for pagination
    createAjaxConfig = () => {
        const { apiUrl, mapFunction, currentDropdownData, allDataRef, additionalParams, apiHTTPMethod } = this.props;

        let pageCounter = 1; // Initialize the page counter
        let currentSearchTerm ='';

        const requestHeaders = {
            Authorization: 'Bearer ' + Api.getUserToken(),
        };
    
        if (apiHTTPMethod) {
            requestHeaders['Content-Type'] = 'application/json';
        }    

        return {
            url: (params) => {
                pageCounter = params.page || 1;
                return apiUrl
            },
            type: apiHTTPMethod || 'GET',
            delay: 250,
            dataType: 'json',
            data: (params, data) => { 
                const isNewSearch = params.term !== currentSearchTerm;
                currentSearchTerm = params.term;

                if (isNewSearch) {
                    pageCounter = 1;
                    allDataRef.current = [];
                }

                const query = {
                    page_number: pageCounter,
                    page_size: 10,
                    search: params.term,
                    ...additionalParams
                };
                return !apiHTTPMethod ? query : JSON.stringify(query);
            },
            processResults: (data) => {
                if (data.data.length > 0) {
                    allDataRef.current = [...allDataRef.current, ...data.data];
                    if ((pageCounter - 1) * 10 < data.total_records) {
                        pageCounter++;
                    }
                }

                const transformedResults = data.data.map(mapFunction);

                if(currentDropdownData) currentDropdownData(data?.data);

                return {
                    results: transformedResults,
                    pagination: {
                        more: (pageCounter - 1) * 10 < data.total_records,
                    },
                };
            },
            headers: requestHeaders,
            cache:true,
        };
    }
  
    componentDidUpdate(prevProps) {   
        if(this.renderDropdown){  
            if(this.props.disable) return;

            let that = this;
            setTimeout(function(){ 
                that.initChoosen();
            }, 100)
        }else if (
            this.props.pagination &&
            this.props.additionalParams &&
            this.props.additionalParams !== prevProps.additionalParams &&
            this.areAdditionalParamsDifferent(this.props.additionalParams, prevProps.additionalParams)
        ) {
            this.initChoosen();
        }
        /*
        let inputSelector = $('#'+this.id );
        if(inputSelector){
            inputSelector.trigger('chosen:updated');
        }
        */
    }

    areAdditionalParamsDifferent(newParams, oldParams) {
        for (const key in newParams) {
            if (newParams.hasOwnProperty(key) && newParams[key] !== oldParams[key]) {
                return true;
            }
        }
        return false;
    }

   
    getInputBox = () => {
        let language = this.props.language;
        let props = this.props;
        let options = this.props.options ? this.props.options : [];
        if(props.disable){
            return <InputPlaceholder label= {options ? options.find(id => id.value == props.value)?.label : props.value}/>
        }
        let that = {
            props:props
        }
        
        return (
            <select data-placeholder={this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select')}  id ={this.id} name={props.name} className="form-control rs_chosen_dropdown" onChange={ props.onChange ? e => props.onChange(e) : e => { } }>
                {this.props.select2Settings?.multiple?null:<option value=""></option>}
                {
                    options.map( (item,key) => {
                        if(that.props.value == item.value){
                            return(<option key={key} value={item.value} selected={true}>{item.label}</option>)
                        }
                        return(<option key={key} value={item.value}>{item.label}</option>)
                    })
                }
            </select>
        )
    }
    hasError(){
        if(this.state.isError){
            return true;
        }
        if(this.props.hasError){
            return true;
        }
        return false;
    }
    render() {
        let props = this.props;
        let cssClass = props.className ? 'form-group '+ props.className : `form-group ${this.props.disable || this.props.isDisable && 'disabled-dropdown-class'}`;
        if(props.labelAlign && props.labelAlign ==='left'){
            cssClass = cssClass+' lebel_align_left';
        }
        
        return(
            <div className={  cssClass + ( this.hasError() ? ' invalid' : '' ) }>
                {props.label ? <label>{props.label} {this.state.isRequired || this.props.required ? <span>*</span> : ''}</label> : '' }
                { this.getInputBox() }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        language:state.language
    }
}

export default  connect (mapStateToProps)  ( DropDownInput );