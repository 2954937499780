import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MasterComonent from "../../components/Backend/MasterComonent";
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import Helper from "../../inc/Helper";
import Gui_id_list from "../../inc/Gui_id_list";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";
import Alert from "../../components/inc/Alert";

const RequestScreen = ({ language, security, auth }) => {
  if (!security?.canView(Gui_id_list.screens.request_screen.main_request_screen_view)) { 
    return ( 
      <MasterComonent>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </MasterComonent>
    );
  }
 
  const canViewRFQAll = security?.canView(Gui_id_list.screens.request_screen.main_request_screen_purchase_req_all);
  const canViewRFQOpen = security?.canView(Gui_id_list.screens.request_screen.main_request_screen_purchase_req_open);
  const canViewRFQCreatedByMe = security?.canView(Gui_id_list.screens.request_screen.main_request_screen_purchase_req_created_by_me);
 
  const canViewRFIMain = security?.canView(Gui_id_list.screens.request_screen.main_request_screen_rfi_main);
  const canViewRFICreatedByMe = security?.canView(Gui_id_list.screens.request_screen.main_request_screen_rfi_created_by_me);

  return (
    <div className="modules_screen_lynkaz">
      <MasterComonent>
        <InnerScreen innerTitle="Request">
          {(canViewRFQAll || canViewRFQOpen || canViewRFQCreatedByMe) && (
            <div className="col-lg-3 col-md-4 col-6 gy-4">
              <div className="inner-page-header border-bottom">
                <h2>
                  {Helper.getLabel(
                    language,
                    "purchase_requistition",
                    "Purchase Requisitions"
                  )}
                </h2>
              </div>
              
              <div className="inner-page-routes">
                {canViewRFQAll && (
                  <Link to="/purchase-requisition">
                    {Helper.getLabel(
                      language,
                      "purchase_requistition",
                      "Purchase Requisitions - All"
                    )}
                  </Link>
                )}
                {canViewRFQOpen && (
                  <Link to='/request-for-quotation/allrequisitions'>
                    {Helper.getLabel(
                      language,
                      'purchase_requisition_open',
                      'Purchase Requisitions - Open'
                    )}
                  </Link>
                )}
                {canViewRFQCreatedByMe && (
                  <Link to={`/purchase-requisition-created-by-me?user_id=${auth?.user?.user_id}`}>
                    {Helper.getLabel(
                      language,
                      'purchase_requisition_created_by_me',
                      'Purchase Requisitions - Created by me'
                    )}
                  </Link>
                )}
              </div>
            </div>
          )}
 
          {/* {(canViewRFIMain || canViewRFICreatedByMe) && (
            <div className='col-lg-3 col-md-4 col-6 gy-4'>
              <div className='inner-page-header border-bottom'>
                <h2>
                  {Helper.getLabel(
                    language,
                    'requestion_for_informations',
                    'Requestion for Informations'
                  )}
                </h2>
              </div>
              <div className='inner-page-routes'>
                {canViewRFIMain && (
                  <Link to="/request-for-informations">
                    {Helper.getLabel(
                      language,
                      "request_for_informations",
                      "Requestion for Informations (RFI)"
                    )}
                  </Link>
                )}
                {canViewRFICreatedByMe && (
                  <Link to={`/request-for-informations?user_id=${auth?.user?.user_id}`}>
                    {Helper.getLabel(
                      language,
                      'request_for_informations_created_by_me',
                      'Requestion for Informations (RFI) - Created by me'
                    )}
                  </Link>
                )}
              </div>
            </div>
          )} */}
        </InnerScreen>
      </MasterComonent>
    </div>
  );
};
 
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.screens.request_screen.main_request_screen_view
};

export default connect(mapStateToProps) ((( ApplySecurityRoles( RequestScreen, SecurityOptions))));  