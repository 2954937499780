import React, { useState } from 'react';
import $ from 'jquery';
import Popup from '../../../../Popup/Popup';
import InputColorPicker from '../../../../Forms/InputColorPicker';
import Button from '../../../../inc/Button';
import Helper from '../../../../../inc/Helper';

const ColorPickerPopup = ({color, closePopupView, onCellClickObject, gridApi}) => {
    const [colorVal, setColorVal] = useState(color);
    
    const onPopupCloseClickHandler = () => {
        $('.common_compliance_response_line_popup .rs_popup_close').trigger('click');
        closePopupView((prevState) => {
            return {
                ...prevState,
                openPopup: false
            }
        })
    };

    const onColorChange = (name, value) => { setColorVal(value) };

    const onSaveButton = () => { 
        if(!onCellClickObject.node || !onCellClickObject.api) return;

        if(!colorVal) {
            Helper.alert('Please select a color, then select!', 'error');
            return;
        };
        
        onCellClickObject?.node?.setDataValue('color', colorVal);
        onCellClickObject?.api?.refreshCells({ rowNodes: [onCellClickObject?.node], columns: ['color'] }); 
        
        // gridApi?.refreshServerSide({ purge: true })
        // gridApi?.deselectAll();
        
        onPopupCloseClickHandler();
    }

    return (
        <Popup className="common_compliance_response_line_popup" autoOpen={true} width={'275px'} onClose={onPopupCloseClickHandler}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <InputColorPicker id={'input_colorpicker_compliance_response_line'} value={colorVal} onChange={onColorChange} label={'Color'} />
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12 pe-4 d-flex justify-content-end align-items-center'>
                        <Button onClick={onSaveButton} title={'Select'} />
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default ColorPickerPopup;