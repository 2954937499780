import React, { Component } from 'react';
import Helper from '../../inc/Helper';
import Button from '../inc/Button';
import RsWithRouter from '../inc/RsWithRouter';
import $ from 'jquery';
/**
 * leftItems: Functions
 * moreOptions: function
 */
class NavigationHeder extends Component {
    constructor(props) {
        super(props);
        this.moreOptionsRef = React.createRef();
    }

    componentDidMount(){
        let mainHeaderHeight = $('.main_header').outerHeight();
        Helper.makeSticky('app_navigation', { topSpace: mainHeaderHeight, zIndex: '1001' });

        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick = (event) => {
        if (this.moreOptionsRef.current && !this.moreOptionsRef.current.contains(event.target)) {
            $(this.moreOptionsRef.current).removeClass('show_menu');
        }
    };

    onBackClickHandler = (e) => {
       //this.props.history.goBack()
        if(this.props.backUrlFunction && !this.props.backUrl){
            this.props.backUrlFunction()
        }
        if(this.props.backUrl && !this.props.backUrlFunction){
            this.props.rs_router.navigate(this.props.backUrl)
        }
        if(!this.props.backUrlFunction && !this.props.backUrl){
            this.props.rs_router.navigate(-1)
        }
    }

    moreButtonClickHandler = (event) => {
        if(this.props.onMoreClick && typeof this.props.onMoreClick === 'function'){
            this.props.onMoreClick(event)
        }
        $(this.moreOptionsRef.current).toggleClass('show_menu');
    };

    render() {
        const arrowIcon = '/images/icons/arrow-left.svg';
        return (
            <div className='archive_header_section app_navigation' style={{marginBottom: this.props.removeMargin && '0px'}} id="app_navigation">
                <div className='app_navigation_left'>
                    {!this.props.hideBackButton ? (
                        <div className='app_navigation_back'>
                            <Button icon={arrowIcon} onClick={this.onBackClickHandler} />
                        </div>
                    ) : null}
                    {this.props.title ? (
                        <div className='app_nav_title' dangerouslySetInnerHTML={{ __html: this.props.title }} />
                    ) : null}
                    {this.props.leftItems ? this.props.leftItems() : null}
                </div>
                <div className='app_navigation_right'>
                    {this.props.children}
                    {!this.props.hideMoreBtn ? (
                        <div className='rs_nav_more_options' ref={this.moreOptionsRef}>
                            <Button
                                icon="/images/icons/more-vertical.svg"
                                className="btn_more"
                                onClick={this.moreButtonClickHandler}
                            />
                            {this.props.moreOptions && typeof this.props.moreOptions === 'function' ? (
                                <div className='btn_more_dropdown'>{this.props.moreOptions()}</div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default RsWithRouter(NavigationHeder);