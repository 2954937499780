import React from 'react'

const TimeLapse = ({days, hours, minutes, seconds}) => {
  return (
    <div className='timelapse'>
        <div className='time_left'>
            <h2>Remaining Time</h2>
        </div>
        <div className='time_countdown row d-flex justify-content-between ps-3 pe-3'>
            <div className='col-lg-3 timecount_box'>
                <div className='timecount_box_value'>{days}</div>
                <div className='timecount_box_period'>Days</div>
            </div>

            <div className='col-lg-3 timecount_box'>
                <div className='timecount_box_value'>{hours}</div>
                <div className='timecount_box_period'>Hours</div>
            </div>

            <div className='col-lg-3 timecount_box'>
                <div className='timecount_box_value'>{minutes}</div>
                <div className='timecount_box_period'>Minutes</div>
            </div>

            <div className='col-lg-3 timecount_box'>
                <div className='timecount_box_value'>{seconds}</div>
                <div className='timecount_box_period'>Seconds</div>
            </div>
        </div>
    </div>
  )
}

export default TimeLapse;