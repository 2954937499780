import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Helper from '../../../../inc/Helper'
import { checkIfArrayIsEmpty } from '../../../../inc/Validation'

const RFQAuctionPopup = (props) => {
  const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.closeModal()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [props.closeModal])

  return (
    <div ref={modalRef} className='rfq-attachment-popup p-4' style={props.style}>
      <div style={{ display: 'flex', marginLeft: '-5px', marginBottom: '18px' }}>
        {/* <img src='/images/icons/attach.svg' alt='attachment-logo' /> */}
        <span className='rfq-attachment-title'>{Helper.getLabel(props.language, 'vendor_details', 'Vendor bidding details')}</span>
      </div>
      <div>
        {checkIfArrayIsEmpty(props.data) ? (
          <p>No record found.</p>
        ) : (
          <div>
            <table className='w-100'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Bid</th>
                  <th>Rank</th>
                </tr>
              </thead>
              <tbody>
                {props.data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.bid}</td>
                    <td>{item.rank}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQAuctionPopup)
