import React from "react";
import {
  Chart as ChartJS,
  PointElement,
  LineElement
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
 PointElement,LineElement
);

const VendorResponseReportLineChart = ({ vendorsData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        offset: true
      },
      y: {
        beginAtZero: true, 
      },
    },
  };

  const data = {
    labels: vendorsData.map((vendor) => {
      return vendor.vendor_name;
    }),
    datasets: [
      {
        label: "Vendor response report",
        data: vendorsData.map((vendor) => {
          return vendor.score;
        }),
        borderColor: "#3B86BC",
        pointRadius: 5,
        tension: 0.3,
        fill: true,
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default VendorResponseReportLineChart;
