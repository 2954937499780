import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import AgGrid from '../../grid/ag/ag-grid';
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import Popup from '../../Popup/Popup';
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction';
import $ from 'jquery';
import Api from '../../../inc/Api';
import FormValidator from '../../../inc/FormValidator';
import SimpleLoading from '../../Loading/SimpleLoading';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
class SegmentGroupRange extends Component {
    constructor(props){
        super(props);
        this.gridObj = null;
        this.validationConfigure();
        this.state = {
            isLoading:false,
            segment:this.props.segment,
            selectedGroup:this.props.selectedGroup,
            gridData:[]
        }
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = []
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        this.loadAllRanges();
    }
    onGridReady(grid){
        this.gridObj = grid;
    }
    onPopupCloseHandler(e){
        if(this.props.onPopupClose && typeof this.props.onPopupClose ==='function'){
            this.props.onPopupClose();
        }
    }
    loadAllRanges(){
        let api = Api;
        api.setUserToken();
        let that = this;
        let segmentvalue_group_id = this.state.selectedGroup.segmentvaluegroup_id;
        this.setState({
            isLoading:true
        })
        console.log('Loading all ranges')
        api.axios().get(Settings.apiUrls.segments + '/segmentvaluegrouprange/'+segmentvalue_group_id).then( res=>{
            that.setState({
                isLoading:false,
                gridData:res.data.data.reverse()
            })
        }).catch(error => {
            that.setState({
                isLoading:false
            })
        })
    }
    onGridSave(items){
        if(!this.validator.isValid({},[this.gridObj])){
            this.validator.displayMessage(this);
            return;
        }
        let newValues = [],oldValues = [];
        items.forEach( item => {
            if(item.segmentvaluegrouprange_id){
                oldValues.push(item);
            }else{
                newValues.push(item)
            }
        })
        this.createNewRange(newValues)
        this.updateGroupRange(oldValues)
    }
    createNewRange(items){
        let api = Api;
        api.setUserToken();
        let that = this, totalApiRequest = items.length;
        let segmentvalue_group_id = this.state.selectedGroup.segmentvaluegroup_id;
        this.setState({
            isLoading:true
        })
        items.forEach(item => {
            let newItem ={
                segmentvalue_group_id:segmentvalue_group_id,
                fromsegmentcode:item.fromsegment,
                tosegmentcode:item.tosegment
            }
            api.axios().post(Settings.apiUrls.segments + '/segmentvaluegrouprange',newItem).then(res=>{
                totalApiRequest--;
                if(totalApiRequest<=0){
                    Helper.alert(res.data.message);
                    that.setState({
                        isLoading:false
                    },function(){
                        that.loadAllRanges();
                    })
                }
            }).catch(error => {
                totalApiRequest--;
                getPopupMessageBasedOnStatus(error);
                if(totalApiRequest<=0){
                    that.setState({
                        isLoading:false
                    },function(){
                        that.loadAllRanges();
                    })
                }
            })
        });

    }
    updateGroupRange(items){
        let api = Api;
        api.setUserToken();
        let that = this, totalApiRequest = items.length;
        this.setState({
            isLoading:true
        })
        items.forEach(item => {
            let newItem ={
                fromsegmentcode: parseInt( item.fromsegment),
                tosegmentcode: parseInt(item.tosegment)
            }
            api.axios().put(Settings.apiUrls.segments + '/segmentvaluegrouprange/'+item.segmentvaluegrouprange_id,newItem).then(res=>{
                totalApiRequest--;
                if(totalApiRequest<=0){
                    Helper.alert(res.data.message);
                    that.setState({
                        isLoading:false
                    },function(){
                        that.loadAllRanges();
                    })
                }
            }).catch(error => {
                totalApiRequest--;
                getPopupMessageBasedOnStatus(error);
                if(totalApiRequest<=0){
                    that.setState({
                        isLoading:false
                    },function(){
                        that.loadAllRanges();
                    })
                }
            })
        });

    }
    onSaveClickHandler(e){
        $('.sgrg_wraper .rs_grid_btn_save').trigger('click');
    }
    onCloseBtnClick(e){
        $('.rs_popup_close').trigger('click');
    }
    render() {
        let language = this.props.language;
        let segment = this.state.segment;
        let gridHeader= [
            {field:'fromsegment', headerName: Helper.getLabel(language,"from",'From *'),validationRule:{types:['Required','Number']}},
            {field:'tosegment', headerName: Helper.getLabel(language,"to",'To *'),validationRule:{types:['Required','Number']}}
        ];
        let blueSectionItems = [
            {title:Helper.getLabel(language,'segment_name','Segment Name'),subtitle:segment.display_name},
            {title:Helper.getLabel(language,'group_name','Group Name'),subtitle: this.state.selectedGroup.name}
        ];
        return (
            <Popup className="seg_popup " autoOpen={true} width="750px" onClose={ this.onPopupCloseHandler.bind(this)}>
                <h2 className='seg_p_title'>{Helper.getLabel(language,'range','Range')}</h2>
                <div className='rfq-internal-blue'>
                 <BlueCallToAction items={blueSectionItems}/>
                </div>
                <div className='sgrg_wraper grid_save_btn_hide'>
                    {this.state.isLoading ? <SimpleLoading /> : <AgGrid  id="segments_group_range_grid" height="200px" deleteConfig={{that:this,apiUrl: Settings.apiUrls.segments + '/segmentvaluegrouprange', idName:'segmentvaluegrouprange_id',reloadFuntion:'loadAllRanges'}} onGridReady={ this.onGridReady.bind(this)} onSave={ this.onGridSave.bind(this)}  header={gridHeader} data={this.state.gridData}/>  }
                </div>
                <div className='seg_p_footer'>
                    <Button className="black-btn-style mr-2" onClick ={ this.onCloseBtnClick.bind(this)} title={Helper.getLabel(language,'Close','Close')} />
                    <Button className="blue btn_md" title={Helper.getLabel(language,'save','Save')} onClick={ this.onSaveClickHandler.bind(this) } />
                </div>
            </Popup>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
    })
}
export default  connect (mapStateToProps,mapDispatchToProps)  ( SegmentGroupRange );