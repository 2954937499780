import React, { useState, useEffect, Fragment, useCallback } from "react";
import axios from "axios";
import "./LabelGrid.css";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import Helper from "../../../inc/Helper";
import DropDownInput from "../../Forms/Dropdown";
import { connect } from "react-redux"; 
import { 
  getPopupMessageBasedOnStatus,
} from "../../../inc/Validation"; 
import NavigationHeder from "../../Navigations/NavigationHeder"; 
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Gui_id_list from "../../../inc/Gui_id_list"; 
import Alert from "../../inc/Alert"; 
import AgGridNew from "../../grid/ag/ag-grid-new";
import SimpleLoading from "../../Loading/SimpleLoading";
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from "../../grid/ag/commonFunctions/GridCommonFunctions";
import SimpleDropdown from "../../grid/ag/cellEditor/SimpleDropdown";

let gridApi = null;
let AddBtn;
let transaction;

const LabelGrid = (props) => { 
  const [formData, setFormData] = useState({ language_id: null, source_id: null });
  const [labelsGridData, setLabelsGridData] = useState([]);

  const [elements, setElements] = useState([]);
  const [sources, setSources] = useState([]); 
  const [languages, setLanguages] = useState([]);

  const [loading, setLoading] = useState(true);

  let getLabelsCalled = false;
  let getSourcesCalled = false;
  let getLanguagesCalled = false; 

  useEffect(() => {
    fetchDataFromAPI()
  }, [formData?.language_id, formData?.source_id])
 
  useEffect(() => {
    get_label_element();
    get_sources();
    get_languages();
  }, []);

  const get_label_element = () => {
    if (getLabelsCalled) {
      return;
    }
    getLabelsCalled = true;
    axios
      .get(Settings.apiSystemUrl + "/label_element", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let Elements_List_API = [];
          res.data.data.forEach((item) => {
            Elements_List_API.push({
              value: item.element_id,
              label: item.element_name,
            });
          });
          setElements(Elements_List_API);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const get_sources = () => {
    if (getSourcesCalled) {
      return;
    }
    getSourcesCalled = true;
    axios.get(Settings.apiSystemUrl + `/source`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = [];
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.source_id,
            label: element.sourcename,
          });
        });
        setSources(UPDATED_STATE);
      }
    });
  };

  const get_languages = () => {
    if (getLanguagesCalled) {
      return;
    }
    getLanguagesCalled = true;
    axios.get(Settings.apiSystemUrl + `/language`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = [];
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.languange_Id,
            label: element.language,
          });
        });
        setLanguages(UPDATED_STATE);
      }
    });
  };

  const fetchDataFromAPI = () => {
    if(formData.language_id === null || formData.source_id === null) {
      setLoading(false);
      return;
    };

    setLoading(true);

    let api = Api; 
    api.setJsonHeader();
    axios
      .post(Settings.apiSystemUrl + "/get_label_for_grid", {
        language_id: formData?.language_id,
        source_id: formData?.source_id,
      })
      .then(function (res) {
        if (res.data.status === "success") {
          setLabelsGridData(res?.data?.data)
          setLoading(false);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
        setLoading(false);
      });
  };

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {  
    if (event?.data?.label_Id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  const updateData = async (element) => {
    try {
      let payload = {
        label_id: element?.data?.label_Id,
        path: element?.data?.path,
        description: element?.data?.description,
        originaltext: element?.data?.originalText,
        translation_text: element?.data?.TranslatedText,
        translation_language_id: formData?.language_id,
      };
 
      const res = await axios.put(Settings.apiSystemUrl + "/update_label", payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          fetchDataFromAPI();
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const addNewData = async (element) => { 
    try {
      let payload = {
        source_id: formData?.source_id,
        language_id: formData?.language_id,
        path: element?.data?.path,
        element_id: element?.data?.element.element_name,
        description: element?.data?.description,
        frontend_id: element?.data?.frontend_Id,
        originaltext: element?.data?.originalText,
        translatedtext: element?.data?.TranslatedText
      };

      const res = await axios.post(Settings.apiSystemUrl + "/label", payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          fetchDataFromAPI();
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  };

  const DeleteHandler = useCallback(async (element) => {  
    try {
      const res = await 
        axios
        .delete(Settings.apiSystemUrl + `/label/${element?.data?.label_Id}`)
      const rowNode = gridApi?.getRowNode(element?.data?.label_Id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200);

      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: labelsGridData?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: labelsGridData?.length
      })
    },
  };

  const onLabelsGridReady = (params) => {
    if(params){
      gridApi = params?.api
      params?.api?.setGridOption('serverSideDatasource', dataSource)
    };
  }

  const sourceHandler = (event) => {
    let source = event.target.value;
    setFormData((prevState) => {
      return { ...prevState, source_id: source };
    });
  };

  const languageHandler = (event) => {
    let language = event.target.value;
    setFormData((prevState) => {
      return { ...prevState, language_id: language };
    });
  };
 
  if (!props?.user?.admin_user) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  /* Grid Column Headers */

  const gridColumnHeaders = [
    {
      field: "source.sourcename",
      filter: false,
      sortable: false,
      headerName: Helper.getLabel(
        props.language,
        "source_name_labels_ADMIN",
        "Source Name"
      ),
      width: 500,
      editable: false,
    },
    {
      field: 'element.element_name',
      headerName: Helper.getLabel(
        props.language,
        "element_name_labels_ADMIN",
        "Element Name"
      ),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180, 
      inputId: 'elements_drp',
      cellEditorParams: { values: elements },
      cellDataType: false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, elements),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label 
      }
    }, 
    {
      field: "label_Id",
      headerName: Helper.getLabel(
        props.language,
        "element_name_labels_ADMIN",
        "Label ID"
      ),
      width: 250,
      editable: false
    },
    {
      field: "frontend_Id",
      headerName: Helper.getLabel(
        props.language,
        "frontend_id_labels_ADMIN",
        "Frontend ID"
      ),
      width: 250,
      editable: true
    },
    {
      field: "description",
      headerName: Helper.getLabel(
        props.language,
        "description_labels_ADMIN",
        "Description"
      ),
      width: 450,
      editable: true
    },
    {
      field: "path",
      headerName: Helper.getLabel(props.language, "path_labels_ADMIN", "Path"),
      width: 350,
      editable: true
    },
    {
      field: "originalText",
      headerName: Helper.getLabel(
        props.language,
        "original_text_labels_ADMIN",
        "Original Text"
      ),
      width: 400,
      editable: true
    },
    {
      field: "TranslatedText",
      headerName: Helper.getLabel(
        props.language,
        "translated_text_labels_ADMIN",
        "Translated Text"
      ),
      width: 400,
      editable: true
    },
  ];
 
  return (
    <>
      <div className="container-fluid">
        <NavigationHeder 
          title={Helper.getLabel(props.language, "labels_form", "Labels Form")}
        />
        <div className="row label-form">
          <div className="col-lg-3 col-md-3">
            <label>
              {Helper.getLabel(props.language, "source_labels_ADMIN", "Source")}
            </label>
            <DropDownInput
              value={formData.source_id}
              options={sources}
              onChange={sourceHandler}
              id="source_label_id"
            />
          </div>
          <div className="col-lg-3 col-md-3">
            <label>
              {Helper.getLabel(
                props.language,
                "to_language_labels_ADMIN",
                "To Language"
              )}
            </label>
            <DropDownInput
              value={formData.language_id}
              onChange={languageHandler}
              options={languages}
              id="language_label_id"
            />
          </div>
        </div>

        <div className="rs_label_forms_grid">
          { loading ? <SimpleLoading /> : 
            <AgGridNew 
              hideAddBtn={
                !props?.user?.admin_user
              }
              hideDeleteBtn={
                !props?.user?.admin_user
              }
              pagination={false}
              columnDefs={gridColumnHeaders}
              height={500}
              uniqueField={'label_Id'} 
              handleAddButton={handleBtn}
              onRowValueChanged={onRowValueChanged}
              handleDeleteSelectedRows={DeleteHandler}
              onGridReady={onLabelsGridReady} 
              gridId={"labels-grid-admin"}
            />
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user:state.auth.user
  };
};
 
export default connect(mapStateToProps)(
  MasterComponentWraper((LabelGrid))
);