import React from 'react';

class CustomCellRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopupOpen: false,
    };
  }

  handleCellClick = () => {
    this.setState({ isPopupOpen: true });
  };

  render() {
    const { value } = this.props;
    const { isPopupOpen } = this.state;
    return (
      React.createElement('div', null,
        React.createElement('div', { className: 'cell-content', onClick: this.handleCellClick }, value),
        isPopupOpen && React.createElement(<div>Test Custom Component</div>, null)
      )
    );
  }
}

export default CustomCellRenderer;