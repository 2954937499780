import React, { useState } from "react";
import $ from "jquery";
import Popup from "../../../Popup/Popup";
import Collapse from "../../../inc/Collapse";
import Helper from "../../../../inc/Helper";
import SuggestedVendorCellRender from "./SuggestedVendorCellRender";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import { useLocation } from "react-router-dom";
import Button from "../../../inc/Button";
import AgGridNew from "../../../grid/ag/ag-grid-new";
import { getNewValue } from "../../../grid/ag/commonFunctions/GridCommonFunctions";

let gridObj = null

const SuggestedVendorModal = ({ language, rfq_vendors, getVendors, rfq_id, handleCloseModal, gridVendorObj }) => {
  const [totalVendors,setTotalVendors]=useState(0)
  const [filteredNodes,setFilteredNodes]=useState([])
  const location = useLocation();

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };


  const Columns_Headings = [
    {
      field: "vendor_name",
      cellRenderer: "agGroupCellRenderer",
      headerName: Helper.getLabel(language, "vendors", "Vendors"),
      editable: false,
    },
    {
      field: "add",
      headerName: Helper.getLabel(language, "add", "Add"),
      cellRenderer:'agCheckboxCellRenderer',
      cellRendererParams:{disabled:true},
      cellEditor: 'agCheckboxCellEditor',
      valueGetter:(params)=> {
        return params.data.add ? params.data?.add : false
      },
      editable: true,
    },
  ];

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,
    detailCellRenderer: SuggestedVendorCellRender,
  };

  const handleGridReady = (params) => {
    gridObj = params
  }

  const handleFetchData = (response) => {
    let data = response?.data?.data?.filter(newItem => !rfq_vendors.some(existingItem => existingItem.vendor_id === newItem.vendor_id))
    setTotalVendors(data?.length)
    if(response?.data?.status==='fail'){
      setTotalVendors(0)
      return {
        rowData:[]?.map((item) => ({
          ...item,
          add:false,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: 0,
      }
    }
    return {
      rowData:data?.map((item) => ({
        ...item,
        add:false,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: data?.length,
    }
  }

  const handleCellValueChanged = (event) => {
    const nodeExists = filteredNodes?.some(node => node.vendor_id === event.data.vendor_id);
    if(event?.data?.add === true){
      setFilteredNodes((prevState)=>[...prevState,event.data])
    }else{
      setFilteredNodes((prevState)=>prevState.filter(node => node.vendor_id !== event.data.vendor_id))
    }
  }

  const onAddVendors = () => {
    let successMessageShown = false
    filteredNodes?.forEach((item) => {
      const api = Api;
      api.setUserToken();
      api
        .axios()
        .post(Settings.apiPurchaseRequisitionUrl + "/RFVendor", {
          RFQ_id: rfq_id,
          vendor_id: item.vendor_id,
          vendorsite_id: item.vendorsite_id,
        })
        .then((res) => {
          if (res?.data?.status === "success" && !successMessageShown) {
            successMessageShown=true
            handleCloseModal()
            getVendors()
            Helper.alert(res?.data?.message, "success");
          }
        })
        .catch((error) => {
          Helper.alert(error?.response?.data?.message, "error");
        });
    })
    if(filteredNodes?.length !== 0){
      setTimeout(()=>{
        gridVendorObj.api?.refreshServerSide({purge:true})
        setFilteredNodes([])
      },300)
    }
  }


  const handleVendorModalClose = () => {
    $("html").removeClass("rs_popup_open")
    handleCloseModal()
  }


  return (
    <Popup autoOpen={true} width="900px" onClose={handleVendorModalClose}>
      <>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h5 className="create_rfq_modal__title">{Helper.getLabel(language, "suggested_vendors", "Suggested Vendors")}</h5>
            <p className="inviteModalSubHeading">Add a line item or category in the line view to see the suggested vendors.</p>
          </div>
          <div className="d-flex align-items-end justify-content-end">
            <Button title={Helper.getLabel(language, "add", "Add")} className="rfq_save_btn" onClick={onAddVendors} isDisable={filteredNodes?.length===0} />
          </div>
        </div>
        <>
          <Collapse title={Helper.getLabel(language, "vendors", "Vendors") + `(${totalVendors})`} open={true}>
            <AgGridNew
              gridId="rfq_suggested_vendors"
              height={500}
              apiUrl={`${Settings.loginUrl}/suggested_vendors?rfq_id=${getSearchParam("rfq_view_id" )}`}
              hideAddBtn={true}
              hideDeleteBtn={true}
              masterSettings={masterSettings}
              columnDefs={Columns_Headings}
              onGridReady={handleGridReady}
              onCellValueChanged={handleCellValueChanged}
              fetchData={handleFetchData}
            />
          </Collapse>
        </>
      </>
    </Popup>
  );
};

export default SuggestedVendorModal;
