import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import FormValidator from '../../../inc/FormValidator';
import DropDownInput from '../../Forms/Dropdown'; 
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker";
import Input from '../../Forms/Input'; 
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import LinkBtn from '../../inc/LinkBtn';
import NavigationHeder from '../../Navigations/NavigationHeder';
import SimpleLoading from '../../Loading/SimpleLoading';
import RsWithRouter from '../../inc/RsWithRouter';
import LoadTaxSettlementPeriods from '../../../actions/EnumsActions'; 
import { customDateComparator, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { useParams } from 'react-router-dom';
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

/* From Date, To Date validations here */

let gridApi = null;
let AddBtn;
let transaction;

const EditSettlementPeriods = ({loadPeriods, TaxSmtPeriod}) => { 
    const { id } = useParams(); 

    const wrapperDiv = useRef();
    const [height, setHeight] = useState(0);
 
    const [settlement, setSettlement] = useState({}); 
    const [taxAuthorityList, setTaxAuthorityList] = useState([]); 
    const [isLoading, setIsLoading] = useState(true);
    const language = useSelector(state => state.language);   

    let isSettlementLoaded = false;
    let taxAuthorithyLoaded = false; 

    let validator; 
    
    const validationConfigure = () => {
        const fieldConfig = [
            { name: 'code', displayName: Helper.getLabel(language, 'code', 'Code'), types: ['Required'] },
            { name: 'description', displayName: Helper.getLabel(language, 'description', 'Description'), types: ['Required'] },
            { name: 'taxauthority_id', displayName: Helper.getLabel(language, 'tax_authority', 'Tax authority'), types: ['Required'] }, 
            { name: 'periodinterval', displayName: Helper.getLabel(language, 'periodinterval', 'Period interval'), types: ['Required'] },
            { name: 'periodinterval_days', displayName: Helper.getLabel(language, 'period_interval_days', 'Period interval days'), types: ['Required', 'Number'] },
        ];

        if(!validator) {
            validator = new FormValidator(fieldConfig, language);
        };
    };

    validationConfigure();

    useEffect(() => { 
        loadSettlement(); 
        (loadPeriods(TaxSmtPeriod));
        loadTaxAuthority();
    }, []);

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    const loadSettlement = () => {
        if (isSettlementLoaded) return;
        isSettlementLoaded = true;
 
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/settlementheader/' + id).then(res => { 
            setSettlement(res?.data?.data);  
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        }) 
    };

    const loadTaxAuthority = () => {
        if(taxAuthorithyLoaded) return;
        taxAuthorithyLoaded = true;

        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/taxauthority').then(res => { 
            setTaxAuthorityList(res?.data?.data); 
        });
    };
 
    const onInputHander = (e) => {
        const updatedSettlement = { ...settlement, [e.target.name]: e.target.value };
        setSettlement(updatedSettlement); 
    };

    const onDropDownChange = (event) => {
        const updatedSettlement = { ...settlement, [event.target.name]: event.target.value };
        setSettlement(updatedSettlement); 
    };

    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const onRowValueChanged = (event) => {
        if (event?.data?.settlementperiods_id) {
            updateTimePeriod(event)
        } else {
            createTimePeriod(event)
        }
    }

    const onSaveHandler = (e) => {
        const validData = { ...settlement, periodinterval: settlement?.periodinterval+'' };
        if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        };

        const formData = {
            taxauthority_id: document.getElementById('taxauthority_id')?.value,
            code: settlement.code,
            description: settlement.description,
            period_interval: settlement.periodinterval,
            period_interval_days: settlement.periodinterval_days,
        };
        Api.setUserToken();
        setIsLoading(true);
        Api.axios().put(Settings.apiUrl + '/settlementheader/' + settlement.settlementperiodheader_id, formData).then(res => {
            if (res.data.status === 'success') {
                Helper.alert(res.data.message); 
                loadSettlement();
            } else {
                Helper.alert(res.data.message, 'error');
            }
            setIsLoading(false);
        }).catch(error => {
            getPopupMessageBasedOnStatus(error);
            setIsLoading(false);
        });
    };
  
    const createTimePeriod = async (event) => {
        try {
            let payload = {
                periodsheader_id: parseInt(id),
                fromdate: event?.data?.from_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.from_date) : null,
                to_date: event?.data?.to_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.to_date) : null
            };

            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiUrl + '/settlementperiods', payload);
            if (res.data.status === "success") {
                
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);

                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }  
    };

    const updateTimePeriod = async (event) => {
        try {
            let payload = {
                periodsheader_id: parseInt(id),
                fromdate: event?.data?.from_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.from_date) : null,
                to_date: event?.data?.to_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.to_date) : null
            };
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiUrl + `/settlementperiods/${event?.data?.settlementperiods_id}`, payload);
            if (res.data.status === "success") {
                
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);

                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        } 
    };

    const timeperiodDeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delete_time_period_id = element?.data?.settlementperiods_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/settlementperiods/${delete_time_period_id}`)
            const rowNode = gridApi?.getRowNode(delete_time_period_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200)

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
  
    const taxAuthoritySelector = taxAuthorityList.map(authority => ({
        label: authority.name,
        value: authority.taxauthority_id
    }));

    const periodIntervalOptions = TaxSmtPeriod.periods.map(item => ({
        label: item.name,
        value: item.id
    }));

    const handleFetchData = (response) => {
        return {
            rowData: response?.data?.data?.settlementPeriods?.map((item) => ({
                ...item, 
                gridId: `${Date.now()}_${getNewValue()}`
            })),
            rowCount: response?.data?.data?.settlementPeriods?.length 
        }
    };

    const gridColHeader = [
        {
            field: 'from_date',
            headerName: Helper.getLabel(language, "start_date", 'Start date'),
            filter: 'agDateColumnFilter', 
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: true,
            cellEditor: DateEditor,
            minWidth: 220,
            comparator: customDateComparator
        },
        {
            field: 'to_date',
            headerName: Helper.getLabel(language, "end_date", 'End date'),
            filter: 'agDateColumnFilter', 
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: true,
            cellEditor: DateEditor,
            minWidth: 220,
            comparator: customDateComparator
        } 
    ];
 
    return (
        <Fragment> 
            { isLoading ? <OverlayLoader /> : null }
            <div className='container-fluid edit_settlement_form grid_save_btn_hide'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} backUrl="/tax/settlement-periods" title={Helper.getLabel(language, 'edit_settlement_period', 'Edit Settlement Period')}>
                        <LinkBtn to="/tax/settlement-periods" className='black-btn-style' isActive={false} title={Helper.getLabel(language, 'close', 'Close')} />
                        <Button onClick={e => onSaveHandler(e)} isActive={false} className="rfq_save_btn" title={Helper.getLabel(language, 'save', 'Save')} /> 
                    </NavigationHeder>
                </div>

                { isLoading ? null :
                    <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
                        <Collapse title={Helper.getLabel(language, 'general', 'General')} open={true}>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Input maxLength={18} name="code" value={settlement.code} label={Helper.getLabel(language, 'code', 'Code')} required={true} onChange={onInputHander} />
                                    </div>
                                    <div className='col-sm-4'>
                                        <Input name="description" value={settlement.description} label={Helper.getLabel(language, 'description', 'Description')} required={true} onChange={onInputHander} />
                                    </div>
                                    <div className='col-sm-4'>
                                        <DropDownInput name="taxauthority_id" value={settlement.taxauthority_id} id="taxauthority_id" options={taxAuthoritySelector} label={Helper.getLabel(language, 'tax_authority', 'Tax authority')} required={true} onChange={onDropDownChange} />
                                    </div>
                                    <div className='col-sm-4'>
                                        <DropDownInput name="periodinterval" value={settlement.periodinterval} id="periodinterval" options={periodIntervalOptions} label={Helper.getLabel(language, 'periodinterval', 'Period interval')} required={true} onChange={onDropDownChange} />
                                    </div>
                                    <div className='col-sm-4'>
                                        <Input inputType="number" name="periodinterval_days" value={settlement.periodinterval_days} label={Helper.getLabel(language, 'period_interval_days', 'Period interval days')} required={true} onChange={onInputHander} />
                                    </div>
                                </div>
                            </div>
                        </Collapse>

                        <Collapse title={Helper.getLabel(language, 'time_periods', 'Time Periods')} open={true}>
                            <AgGridNew
                                apiUrl={Settings.apiUrl + '/settlementheader/' + id}
                                pagination={false}
                                fetchData={handleFetchData}
                                columnDefs={gridColHeader}
                                onRowValueChanged={onRowValueChanged} 
                                handleDeleteSelectedRows={timeperiodDeleteHandler}
                                height={500}
                                onGridReady={(params) => gridApi = params?.api}
                                handleAddButton={handleBtn}
                                uniqueField={`settlementperiods_id`}
                                gridId={'tax_settlement_time_periods_edit'}
                            />
                        </Collapse>   
                    </div>
                }
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        TaxSmtPeriod:state.TaxSmtPeriod,
        language:state.language
    }
}
 
const mapDispatchToProps = (dispatch) => ({
    loadPeriods: (TaxSmtPeriod) => LoadTaxSettlementPeriods(dispatch, TaxSmtPeriod)
});

export default connect(mapStateToProps, mapDispatchToProps) (RsWithRouter(EditSettlementPeriods));