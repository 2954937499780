import React, { Fragment, useEffect, useRef, useState } from "react";
import Helper from "../../../../inc/Helper";
import SimpleLoading from "../../../Loading/SimpleLoading";
import { connect } from "react-redux";
import TechnicalEvaluationMasterWraper from "./TechnicalEvaluationMasterWraper";
import EvaluationCriteriaHeader from "./EvaluationCriteriaHeader";
import CriteriaSectionsCollapseWrapper from "./CriteriaSectionsCollapseWrapper";
import TeamSectionsWrapper from "./TeamSectionsWrapper";
import Sections from "./Sections";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import {
  ArrayInitializerOfObjects,
  ArrayToArrayTransformation,
  calculateTotalScoringPercentages,
  checkIfArrayIsEmpty,
  checkTextAreasHavingValues,
  extractCriteriaArray,
  findMatchingDisqualifiedObjects,
  getCalculatedDataFromFormData,
  getPopupMessageBasedOnStatus,
  isEmptyObject,
  transformSplitArrayData,
} from "../../../../inc/Validation";
import TechnicalSelectedLinesModal from "../../../RequestForQuotation/RFQDetailForm/TechnicalEvaluation/TechnicalSelectedLinesModal";
import LinesRecommendationPopup from "./LinesRecommendationPopup";
import CommonScoringSummaryTable from "./CommonScoringSummaryTable";
import useDynamicRefs from "./useDynamicRefs";
import VendorReasonSelection from "./VendorReasonSelection";

// evaluator details states,
let defaultVendorReasonSelection = null;   
let evaluatorDetailsObj = {};
let scoreDisqualificationArray = [];

const CreateTechnicalEvaluation = (props) => {
  // component-wide loading state,
  const [loading, setLoading] = useState(false);

  // component vendors/scoring/sections states, 
  const [RFQVendors, setRFQVendors] = useState([]); 
  const [RFQScoringMasterSections, setRFQScoringMasterSections] = useState([]); 
  const [scoreDisqualification, setScoreDisqualification] = useState([]) 

  // Summary grid 2D Data state,
  const [RFQVendorSummary, setRFQVendorSummary] = useState({
    summaryRowData: [],
    summaryColsData: [],
  });
   
  // evaluator details states, 
  const [vendor, setVendor] = useState({});

  // lines view states, for popup - opening/closing,
  const [linesDetails, setLinesDetails] = useState({ openPopup: false, disableLinesRecommendation: false, vendorreply_id: null, id: null });
  const [showSelectedLinesModal, setShowSelectedLinesModal] = useState(false);
  
  // refs for controlling scroll effects for vendors header + sections, 
  const vendorRef = useRef(0);
  const sectionRefs = useDynamicRefs(RFQScoringMasterSections.length)

  // API Calls Renderer, 
  useEffect(() => {
    if (!RFQVendorCalled && props?.rfq_scores_data) { 
      getRFQVendors();
      getScoringTemplateForRFQ();
      RFQVendorCalled = true; 
    }
  }, [props?.rfq_scores_data, props?.readOnlyForm]);

  // useEffect for handling the scrolling effects,
  useEffect(() => {
    if (vendorRef.current) {
      vendorRef.current.addEventListener('scroll', handleVendorRefs);
    }

    return () => {
      if (vendorRef.current) {
        vendorRef.current.removeEventListener('scroll', handleVendorRefs);
      }
    };
  }, [vendorRef.current]);
  
  // apis reduction flags,
  let RFQVendorCalled = false;
  let scoringMasterCalled = false;
  let scoringSummaryCalled = false;
  let evaluatorCalled = false;

  // API Calls / Functions,
  const getRFQVendors = async () => {
    if (RFQVendorCalled && !props.rfq_id) return;
    setLoading(true);
  
    try {  
      RFQVendorCalled = true;
      let api = Api;
      api.setUserToken();
  
      const res = await api.axios().get(
        Settings.apiPurchaseRequisitionUrl +
        `/get_vendors_of_RFQ/${props.rfq_id}`,
        {}
      );
  
      if (res.data.status === "success") {
        let UPDATED_DATA = [];
        const promises = res.data?.data?.filter(item => item.vendor_replystatus === 2 || item.vendor_replystatus === 6 || item.vendor_replystatus === 7 || item.vendor_replystatus === 3).map(async (item) => {
          if (!item.vendorreply_id) return;
  
          let scoringData = await getRFQVendorScoring(props.rfq_id, props?.rfq_scores_data?.scorerfq_id);
          let evaluator_details = await getRFQEvaluatorDetails(props?.rfq_scores_data?.scorerfq_id);
  
          let extractedArray =
            scoringData[item.vendorreply_id?.toString()] || [];
  
          UPDATED_DATA.push({
            id: item.vendor_id,
            vendor: item.vendor_name,
            reply_id: item.vendorreply_id,
            vendorScoring: extractedArray,
          });
  
          if (!scoringData || scoringData.length === 0) {
            UPDATED_DATA[UPDATED_DATA.length - 1].vendorScoring = [];
          }
        });
  
        await Promise.all(promises);
  
        getRFQVendorsSummary(UPDATED_DATA, props?.rfq_scores_data?.scorerfq_id);
        setRFQVendors(UPDATED_DATA);
        setLoading(false); 
      }
    } catch (error) {
      setLoading(false); 
    } finally {
      setLoading(false); 
    }
  };

  const getRFQVendorScoring = async (id, scorerfq_id) => {
    if (!id || !scorerfq_id) return Promise.resolve();

    let api = Api;
    api.setUserToken();
    return api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + `/user_vendorscoring/${id}?employee_id=${props?.employee_id}&scoreRFQ_id=${scorerfq_id}`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          return res.data.data;
        }
      })
      .catch((res) => {
        return {
          comments: null,
          score: null,
          scorecriterialine_id: null,
        };  
      });
  };

  const getRFQVendorsSummary = (RFQVendors, scorerfq_id) => {
    if ((
      scoringSummaryCalled &&
      !props?.rfq_id &&
      !props?.employee_id &&
      !props?.evaluation_type
    ) || !scorerfq_id)
      return;

    let api = Api;
    scoringSummaryCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/score_summary/${props?.rfq_id}?employee_id=${props?.employee_id}&evaluation_type=${props?.evaluation_type}&scoreRFQ_id=${scorerfq_id}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          const { RowData, ColumnHeadersData } = transformSplitArrayData(res.data.data, RFQVendors); 
          props?.setTechnicalScoreSummary(res?.data?.data);

          // calculateTotalScoringPercentages(RFQVendors);  // setting pre-values calculated from scores for summary table
          setRFQVendorSummary({
            summaryRowData: RowData,
            summaryColsData: ColumnHeadersData,
          });
        }
      })
      .catch((res) => {
        if(checkIfArrayIsEmpty(RFQVendors)) return;
        props?.setTechnicalScoreSummary([]);

        let RowData = ArrayInitializerOfObjects(RFQVendors); 
        let ColumnData = ArrayToArrayTransformation(RFQVendors);  
        setRFQVendorSummary({
          summaryRowData: RowData,
          summaryColsData: ColumnData,
        });
      });
  };
  
  const getScoringTemplateForRFQ = () => {
    if (scoringMasterCalled && !props.rfq_id) return;

    let api = Api;
    scoringMasterCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/scoringMaster_RFQ/${props.rfq_id}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          if (!res.data.data.scoringmaster_rfq_id) return;
          getScoringSectionsCriterias(res.data.data.scoringmaster_rfq_id);
        }
      })
      .catch((res) => {
        console.log(res.response.data.message);
      });
  };

  const getScoringSectionsCriterias = (id) => {
    if (!id) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/scoringsections/${id}?for_rfq=True`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          let updated_sections = [];
          res.data.data.forEach((item) => {
            updated_sections.push({
              name: item.name,
              weight: item.weight,
              type: item.section_type == 0 ? "Technical" : "Financial",
              criteria: item.criteria,
            });
          });
          setRFQScoringMasterSections(updated_sections);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      });
  };

  const getRFQEvaluatorDetails = (scorerfq_id) => {
    if ((evaluatorCalled && !props?.rfq_id && props?.employee_id) || !scorerfq_id) return Promise.resolve();

    let api = Api;
    evaluatorCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/evaluator/${props?.rfq_id}?employee_id=${props?.employee_id}&scoreRFQ_id=${scorerfq_id}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          defaultVendorReasonSelection = res.data.data.reason_for_selection;  
          evaluatorDetailsObj = res.data.data; 
          
          props?.setEvaluatorData(res?.data?.data)
          return res.data.data
        }
      })
      .catch((res) => {
        console.log('No Evaluator Reason found for this RFQ'); 
        props?.setEvaluatorData({});
        
        return {}
      });
  };

  // Scroll handling Effects functions,

  const handleVendorRefs = () => {
    sectionRefs.forEach((ref) => {
      if (ref.current) { 
        ref.current.scrollLeft = vendorRef.current.scrollLeft;
      }
    });
  };

  const handleSectionRefs = (index) => { 
    if (vendorRef.current && sectionRefs[index].current) { 
      vendorRef.current.scrollLeft = sectionRefs[index].current.scrollLeft;
    }
  }; 
  
  // Updating the variables for scoring disqualifications

  const getUpdatedScoringDisqualificationScores = (params) => {
    const updatedArray = [...params];
    scoreDisqualificationArray = updatedArray;
  }
  // Modules --
  const TechnicalEvaluationModule = () => { 
    return (
      <div className="technical_evaluation_module">
        {props?.employee_id !== props?.auth?.user?.employee_id && <h2 className="rfq_head_line_txt">Following evaluation is of a different user's employee, hence, you cannot edit it</h2>}
        <div className="technical_evaluation_section">
          <TechnicalEvaluationMasterWraper>
            <EvaluationCriteriaHeader
              criteriaType="Evaluation Criteria"
              vendorsList={RFQVendors}
              vendorRef={vendorRef} 
              setShowSelectedLinesModal={setShowSelectedLinesModal}
              setVendor={setVendor}
            />

            <form id="creation_technical_evaluations">
              {!checkIfArrayIsEmpty(RFQScoringMasterSections) &&
                RFQScoringMasterSections.map((item, index) => { 
                  return (
                    <CriteriaSectionsCollapseWrapper
                      title={item.name}
                      type={item.type}
                      weight={item.weight}
                      className={"evaluation-section"}
                      id={"Section"}
                      open={true}
                    >
                      <TeamSectionsWrapper
                        onScroll={() => handleSectionRefs(index)}
                        sectionRef={sectionRefs[index]}
                      >
                        {!checkIfArrayIsEmpty(item.criteria) &&
                          item.criteria.map((element) => { 
                            return (
                              <Sections
                                RFQVendorsList={RFQVendors}
                                ArrayRFQMasterScoring={RFQScoringMasterSections}
                                sectionMandatory={element.comments_mandatory}
                                sectionName={element.name} 
                                criteriaDescription={element.description}
                                checkboxVal={
                                  RFQVendors[0]?.vendorScoring?.find(
                                    (val) =>
                                      val.scorecriteriadetail_RFQ_id ===
                                      element.scoringcriterialdetailrfq_id
                                  )?.not_applicable
                                }
                                disableToggle={
                                  props?.readOnlyForm || props?.employee_id !==
                                  props?.auth?.user?.employee_id
                                } 
                                detailRFQId={
                                  element.scoringcriterialdetailrfq_id
                                }
                                vendors={RFQVendors.map((el) => {
                                  const vendorScoring = el.vendorScoring || [];
                                  const matchingScore = vendorScoring?.find(
                                    (val) =>
                                      val.scorecriteriadetail_RFQ_id ===
                                      element.scoringcriterialdetailrfq_id
                                  );
                                  
                                  if(matchingScore) {
                                    return {
                                      score_id: `name_${Date.now().toString(
                                        36
                                      )}_${Math.random()
                                        .toString(36)
                                        .substr(2, 9)}`,
                                      score: checkIfArrayIsEmpty(vendorScoring)
                                        ? 0
                                        : matchingScore?.score || 0,
                                      comment: checkIfArrayIsEmpty(vendorScoring)
                                        ? ""
                                        : matchingScore?.comments || "",
                                      commentsRequired:
                                        element.comments_mandatory,
                                      id: element.scoringcriterialdetailrfq_id,
                                      reply_id: el.reply_id, 
                                      allVendorsList: RFQVendors, 
                                      disableFields:
                                        matchingScore?.not_applicable || props?.readOnlyForm ||
                                        props?.employee_id !==
                                          props?.auth?.user?.employee_id
                                          ? true
                                          : false, 
                                      scorecriterialine_id: matchingScore?.scorecriterialine_id || null 
                                    };
                                  }
                                })}
                              />
                            );
                          })}
                      </TeamSectionsWrapper>
                    </CriteriaSectionsCollapseWrapper>
                  );
                })}
            </form>
          </TechnicalEvaluationMasterWraper>
        </div>

        <div className="vn_selection_reason mt-4">
          <div className="summary mb-4"> 
            <CommonScoringSummaryTable
              disableCheckbox = {
                props?.readOnlyForm || props?.employee_id !== props?.auth?.user?.employee_id
              }
              out_of_total_score={((extractCriteriaArray(RFQScoringMasterSections)?.length) * 10)}
              executeScoreVendorReplyApi={ScoreVendorReplyUpdate}
              RFQVendors={RFQVendors}
              RowData={RFQVendorSummary.summaryRowData}
              ColumnHeadersData={RFQVendorSummary.summaryColsData}
              scoreDisqualification={scoreDisqualification}
              getUpdatedScoreDisqualificationFromSummaryTable={getUpdatedScoringDisqualificationScores}
              setScoreDisqualification={setScoreDisqualification}
              setOpenPopupView={setLinesDetails}
            />
          </div>
          <VendorReasonSelection
            executeRFQEvaluatorApi={updateRFQEvaluator}  
            disableField={
              props?.readOnlyForm || props?.employee_id !== props?.auth?.user?.employee_id 
            }
            vendorReasonDefaultVal={evaluatorDetailsObj.reason_for_selection}
          />
        </div>
        
        {showSelectedLinesModal && (
          <TechnicalSelectedLinesModal
            rfqHeader={props?.rfqHeader}
            language={props.language}
            hideDeleteBtn={true}
            setShowSelectedLinesModal={setShowSelectedLinesModal}
            vendor={vendor}
            setVendor={setVendor}
            user={props?.auth?.user}
          />
        )}
      </div>
    );
  };

  // Execution Of API

  const updateRFQEvaluator = (submitted=false, applyValidations=false, reasonText='') => { 
    if(applyValidations) { 
      if (!reasonText || reasonText === "") {
        Helper.alert('Please provide vendor reason for selection', 'error');
        return;
      };  
    }
 
    let apiPayload = applyValidations ? {
      reason_for_selection: reasonText
    } : {
      submitted: submitted,
      requested_retender: !submitted
    }

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/update_evaluator/${evaluatorDetailsObj.score_rfq_evaluator_id}`, apiPayload
      )
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert("Evaluator updated successfully");
          getRFQEvaluatorDetails(props?.rfq_scores_data?.scorerfq_id);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      });
  };

  const ScoreVendorReplyUpdate = (scorereplyheader_id, disqualified) => {
    if(!scorereplyheader_id) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/RFScoreReplyHeader/${scorereplyheader_id}`,
        { 
          "disqualified": disqualified
        }
      )
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert("Score reply header updated successfully");
          getRFQVendorsSummary(
            RFQVendors,
            props?.rfq_scores_data?.scorerfq_id
          );
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      });
  }

  const APIforSubmitBtn = () => {
    if(props?.employee_id !== props?.auth?.user?.employee_id || props?.readOnlyForm) return;
    
    let vendorReasonInputField = document.getElementById('reason_for_vendor_selection')?.value;
    const requiredTextareasfilled = checkTextAreasHavingValues();
    
    if (!vendorReasonInputField || vendorReasonInputField === "") {
      Helper.alert('Please provide vendor reason for selection', 'error');
      return;
    };  

    if(!requiredTextareasfilled) {
      Helper.alert('Please fill out the required comment fields', 'error');
      return;
    };

    updateRFQEvaluator(true); 
  };

  const APIforRequestedRetenderBtn = () => {
    if(props?.employee_id !== props?.auth?.user?.employee_id || props?.readOnlyForm) return;

    let vendorReasonInputField = document.getElementById('reason_for_vendor_selection')?.value;
    const requiredTextareasfilled = checkTextAreasHavingValues();
    
    if (!vendorReasonInputField || vendorReasonInputField === "") {
      Helper.alert('Please provide vendor reason for selection', 'error');
      return;
    };  

    if(!requiredTextareasfilled) {
      Helper.alert('Please fill out the required comment fields', 'error');
      return;
    };

    updateRFQEvaluator(false);  
  };

  {
    typeof props?.executeAPIForSubmitBtn === 'function' && props?.executeAPIForSubmitBtn(APIforSubmitBtn);
    typeof props?.executeAPIForRetenderBtn === 'function' && props?.executeAPIForRetenderBtn(APIforRequestedRetenderBtn);
  }; // allowing btn functions to be triggered inside parent component
  
  return (
    <Fragment>
      {linesDetails.openPopup && (
        <LinesRecommendationPopup
          rfq_id={props.rfq_id}
          setLinesDetails={setLinesDetails}
          vendorreply_id={linesDetails?.vendorreply_id}
          scorereplyheader_id={linesDetails?.id}
          language={props?.language}
          hideDeleteBtn={true}
          getSummary={() => {
            return getRFQVendorsSummary(
              RFQVendors,
              props?.rfq_scores_data?.scorerfq_id
            );
          }}
          rfqHeader={props?.rfqHeader}
          user={props?.auth?.user}
          disabled={props?.readOnlyForm || linesDetails?.disableLinesRecommendation}
        />
      )}
      {loading ? (
        <SimpleLoading />
      ) : !props?.rfq_scores_data ? (
        <div className="text-center">Techincal Evaluation not initiated!</div>
      ) : checkIfArrayIsEmpty(RFQVendors) && !loading ? (
        <div>Technical Evaluation not Found!</div>
      ) : (
        TechnicalEvaluationModule()
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps) (CreateTechnicalEvaluation);