import React, { useEffect, useState } from 'react' 
import Api from '../../../inc/Api'
import Popup from '../../Popup/Popup' 
import { connect } from 'react-redux'
import $ from 'jquery';
import Helper from '../../../inc/Helper' 
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation' 
import Button from '../../inc/Button'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'

/* Validations: -> Roundings to be not lesser than 0 and it should be an integer */

let gridApi = null;
let AddBtn;
let transaction;

const CurrenyRounding = ({ language, setCurrency, closeGrid, currencyId, gridCurrenyApi }) => {  
  const [roundingMethod, setRoundingMethod] = useState([]);

  let roundingsMethodCalled = false;
  
  useEffect(() => { getSystemRoundingsMethod() }, []);

  const getSystemRoundingsMethod = () => {
    if(roundingsMethodCalled) return;
    roundingsMethodCalled = true

    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/roundingmethod`,{}).then(function(res){
      if(res.data.status==='success'){
        setRoundingMethod(res?.data?.data?.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        }))
      }
    }).catch((res) => {  
      console.log(res)
    })
  }

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.createdby) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }
 
  const updateData = async (event) => {   
    if(!currencyId) return;

    try {
      let payload = {
        rounding: (event?.data?.rounding),
        rounding_method: (event?.data?.rounding_method)
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl+`/currencyrounding/${currencyId}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridCurrenyApi?.refreshServerSide({ purge: true });

          gridApi?.deselectAll(); 
          gridCurrenyApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  }

  const addNewData = async (event) => {
    if(!currencyId) return;

    try {
      let payload = {
        currency_id: currencyId,
        rounding: (event?.data?.rounding),
        rounding_method: (event?.data?.rounding_method)
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl+`/currencyrounding`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridCurrenyApi?.refreshServerSide({ purge: true });

          gridApi?.deselectAll(); 
          gridCurrenyApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  } 
 
  const onClosePopup = () => {
    $("html").removeClass("rs_popup_open");
    closeGrid(false);
    setCurrency({});
  }

  const ViewHistory = (event) => { event?.preventDefault() }

  const handleFetchData = (response) => {
    return {
      rowData: [response?.data?.data]?.map((item) => ({
        ...item, 
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: [response?.data?.data]?.length,
    }
  }

  /* Grid Column Headers */

  const gridColHeaders = [
    {
      field:'rounding', 
      headerName: Helper.getLabel(language, '3600102', 'Rounding'),
      editable: true,
      filter: 'agNumberColumnFilter'
    },
    {
      field: 'rounding_method', 
      headerName: Helper.getLabel(language, '3600102', 'Rounding Methods'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180, 
      cellEditorParams: { values: roundingMethod },
      cellDataType: false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, roundingMethod),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    }
  ];

  return (
    <Popup autoOpen={true} width={"70%"} onClose={onClosePopup}> 
      <div className='container-fluid p-0 mb-3'>
        <div className='currency_action_btns'> 
          <Button title={Helper.getLabel(language, '3600402', 'View History')} onClick={ViewHistory} /> 
        </div>
      </div>

      <AgGridNew
        apiUrl={currencyId ? Settings.apiUrl+`/currencyrounding/${currencyId}` : null}
        fetchData={handleFetchData}
        pagination={false}
        hideDeleteBtn={true}
        columnDefs={gridColHeaders}
        onRowValueChanged={onRowValueChanged} 
        height={500}
        handleAddButton={handleBtn}
        uniqueField={`createdby`}
        gridId={'currency_rounding_grid'}
      />
    </Popup>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (CurrenyRounding);