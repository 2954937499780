import axios from "axios";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import Helper from "../../../../inc/Helper";

/*

Common Technical Evaluation API calls file,

*/
export default class TechnicalEvaluationCommonCalls {
  api;

  constructor() {
    this.api = Api;
    this.api.setUserToken();
  }

  async updateVendorScoringOfTechnicalEvaluation(
    scorecriterialine_id,
    apiPayload
  ) {
    try {
      const res = await this.api
        .axios()
        .put(
          Settings.apiPurchaseRequisitionUrl +
            `/vendorscoring/${scorecriterialine_id}`,
          apiPayload
        ); 

      if (res.data.status === "success") {
        console.log("API succeeded!");
      }
    } catch (error) {
      console.log(error?.response?.data?.message); 
    }
  }
}