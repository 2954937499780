import React, { Component } from 'react';
import AmendmentRequest from '../../components/Purchase Orders Internal/AmendmentRequest/AmendmentRequest';

class RoAmendmentEditPage extends Component {
    render() {
        return (
            <AmendmentRequest
                backUrl = '/release-order/amendment-request/all'
            />
        );
    }
}

export default RoAmendmentEditPage;