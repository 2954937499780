import React, { useState, useEffect, useCallback } from 'react'; 
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Api from '../../inc/Api'; 
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import { getLabelFromDropdown } from '../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../grid/ag/cellEditor/SimpleDropdown';
import AgGridNew from '../grid/ag/ag-grid-new';

let gridApi = null;
let AddBtn; 
let transaction;
let totalDropdownPage = 1;

const CategoryItems = ({ category_id }) => { 
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    let itemApiCalled = false;

    useEffect(() => {
        loadItems(); 
    }, []);

    useEffect(() => {
        if(!category_id) setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300)  
    }, [category_id])

    const loadItems = () => {
        if(itemApiCalled) return;
        itemApiCalled = true;

        Api.setUserToken();
        Api.axios().get(`${Settings.apiItemUrl}/item`,{}).then(res => {
            setItems(res?.data?.data?.map(item => {
                return {
                    value: item?.itemmaster_id,
                    label: `${item?.code} - ${item?.display_name}`
                }
            }));
        });
    };
 
    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const onRowValueChanged = async (event) => {  
        if (event?.data?.isNew) {
            onAddNewItemCategory(event)
        }
    };

    const onAddNewItemCategory = async (event) => {
        try {
            let payload = {
                category_id: Helper.getOnlyInt(category_id, true),
                itemmaster_id: Helper.getOnlyInt(event?.data?.item_master_id, true)
            };
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(`${Settings.apiUrl}/categoryitem`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);
                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }  
    }

    const onDeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let category_id_int = Helper.getOnlyInt(category_id, true);
        let item_master_id = Helper.getOnlyInt(element?.data?.item_master_id, true);
  
        try {
            const res = await api
                .axios()
                .delete(`${Settings.apiUrl}/categoryitem/${category_id_int}/${item_master_id}`)
            const rowNode = gridApi?.getRowNode(item_master_id)

            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element?.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200) 

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, [category_id]);

    let select2Settings = {
        ajax: {
            url: (params) =>{
                totalDropdownPage = params.page || 1;
                return `${Settings.apiItemUrl}/item`;
            },
            processResults: function (data) {
                let isMorePageExist = ( data.total_records - ( totalDropdownPage * Settings.dropdownPageSize )) >0 ? true : false ; 
                let result = data.data.map( item => {
                    return {
                        id: item.itemmaster_id,
                        text: item.code+' - '+item.display_name
                    }
                })
                return {
                    results:result,
                    pagination: {
                        more: isMorePageExist
                    }
                };
            }
        }
    };

    const gridColHeaders = [
        {
            field: 'item_master_id',
            valueGetter: (params) => {
                if(!params?.data?.item_code || !params?.data?.item_name){
                    return ''
                }
                else {
                    return params?.data?.item_code + ' - ' + params?.data?.item_name
                }
            },
            select2Settings:select2Settings,
            headerName: Helper.getLabel(null, 'item', 'Item'),
            editable: (node) => !node.data.item_master_id,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth:180, 
            cellEditorParams: { values: items },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agTextColumnFilter'
        },
        { field: 'item_id', headerName: Helper.getLabel(null, 'item_id', 'item_id'), editable: false, hide: true }
    ];

    return (
        <div className='category_items'>
            <AgGridNew
                apiUrl={category_id?`${Settings.apiUrl}/categoryitem/${category_id}?filter_by=category`:null}
                pagination={false}
                columnDefs={gridColHeaders}
                onRowValueChanged={onRowValueChanged}
                handleDeleteSelectedRows={onDeleteHandler}
                key={loading ? 'render' : 'no render'}
                height={500}
                onGridReady={(params) => gridApi = params?.api}
                handleAddButton={handleBtn}
                uniqueField={`item_master_id`}
                gridId={'category_items_master'}
            />
        </div>
    );
};

export default CategoryItems;