import React from 'react';
import Collapse from '../../inc/Collapse';
import Tabs from '../../Widgets/Tabs/Tabs'; 
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer'; 

const GeneralEdit = ({language, vendorType, numberSequence, editVendor, setEditVendor, disableFields, auth }) => {

    //Tabs

    let general_navs = [
        {title:Helper.getLabel(language,'identification','Identification'), isActive: true},
        {title:Helper.getLabel(language,'other_information','Other information')},
        {title:Helper.getLabel(language,'online_presence','Online presence')}
    ]

    /// ------

    // Fields Array@s

    const fieldsArrayIdentification = [
        {className:'col-lg-4', maxLength: 15, required: !numberSequence?.ns_type == "continuous", disable: (numberSequence?.ns_type == "continuous") || disableFields, label: Helper.getLabel(language,'vendor_code',"Vendor code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', maxLength: 255, disable:disableFields || auth?.user?.usertype===1, label: Helper.getLabel(language,'supplier_trade_name',"Supplier trade name (EN)"), required:true, inputType: "text", id:'name', name:'name'},
        {className:'col-lg-4', reRenderRequired:true, placeHolder: 'Select type', disable:disableFields || (auth?.user?.usertype===1) || editVendor?.version > 0, options: vendorType, label: Helper.getLabel(language,'type',"Type"), required:true, inputType: "dropdown", id:'vendor_type', name:'vendor_type'}, 
        {className:'col-lg-4', maxLength: 100, disable:disableFields, label: Helper.getLabel(language,'supplier_trade_name',"Supplier trade name (AR)"), required:true, inputType: "text", id:'alternate_name', name:'alternate_name'},
        // {className:'col-lg-4', maxLength: 40, disable:disableFields, label: Helper.getLabel(language,'legacy_code',"Legacy code"), inputType: "text", id:'legacy_code', name:'legacy_code'},
        {className:'col-lg-4', maxLength: 20, disable:disableFields, label: Helper.getLabel(language,'license_number',"License number"), inputType: "text", id:'externalcode', name:'externalcode'},
        // {className:'col-lg-4', maxLength: 18, disable:disableFields, label: Helper.getLabel(language,'duns_number',"DUNS number"), inputType: "number", id:'dunsnumber', name:'dunsnumber'},
        {className:'col-lg-4', disable:true, label: Helper.getLabel(language,'workflow_status',"Workflow status"), inputType: "text", id:'document_status', name:'document_status'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'one-time-vendor',"One time vendor"), inputType: "checkbox", id:'onetime', name:'onetime'},
    ]

    const fieldsArrayOtherInfo = [
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language, 'established_date', "Establishment date"), emptyDate: true, inputType: "datepicker_new", id:'yearestablished', name:'yearestablished'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'license_issue_date',"License issue date"), emptyDate: true, inputType: "datepicker_new", id:'incorporationyear', name:'incorporationyear'}, 
    ]

    const fieldsArrayOnlinePresence = [
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'website',"Website"), inputType: "text", id:'websiteurl', name:'websiteurl'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'linked-in',"LinkedIn"), inputType: "text", id:'linkedin', name:'linkedin'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'facebook',"Facebook"), inputType: "text", id:'facebook', name:'facebook'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'x',"X"), inputType: "text", id:'twitter', name:'twitter'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'youtube',"Youtube"), inputType: "text", id:'youtube', name:'youtube'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'instagram',"Instagram"), inputType: "text", id:'instagram', name:'instagram'},
        {className:'col-lg-4', disable:disableFields, label: Helper.getLabel(language,'tiktok',"Tiktok"), inputType: "text", id:'tiktok', name:'tiktok'}
    ]

    // ----

    return (
        <Collapse title={Helper.getLabel(language,'general','General')} className="mb-4 general_tabs" open={true}>
            <Tabs disableSticky={true} navs={general_navs} showAll={false} scrollOffset="500">
                <div className='container-fluid identification'>
                    <FieldsRenderer currentState={editVendor} updateState={setEditVendor} fieldsArray={fieldsArrayIdentification} />
                </div>
                <div className='container-fluid other-information'>
                    <FieldsRenderer currentState={editVendor} updateState={setEditVendor} fieldsArray={fieldsArrayOtherInfo} />
                </div>
                <div className='container-fluid online-presence'> 
                    <FieldsRenderer updateState={setEditVendor} fieldsArray={fieldsArrayOnlinePresence} currentState={editVendor} /> 
                </div>
            </Tabs>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) (GeneralEdit);