import React, { Component, createRef } from "react";

import Helper from "../../../inc/Helper";
import Tabs from "../../Widgets/Tabs/Tabs";
import Input from "../../Forms/Input";
import NewInputDatePicker from "../../Forms/NewInputDatePicker";

import EditGrey from "../../../assets/edit-grey.svg";
import Dropdown from "../../Forms/Dropdown";
import ToggleSwitch, { TitlePosition } from "../../Forms/ToggleSwitch";
import Button from "../../inc/Button";
import Settings from "../../../inc/Settings";
import Checkbox from "../../Forms/Checkbox";

const generalNavs = [
  { title: "Basic", isActive: true },
  { title: "Additional Information" },
];

export default class HeaderForm extends Component {
  constructor(props){
    super(props);
    this.mapEntityItems = this.mapEntityItems.bind(this)
    this.mapSiteItems = this.mapSiteItems.bind(this)
    this.mapWarehouseItems = this.mapWarehouseItems.bind(this)
    this.mapEmployeesData = this.mapEmployeesData.bind(this)
    this.employeesData = createRef()
  }

  mapEntityItems(item){
    return {
        id:item.entity_id,
        text:item.name
    }
  }

  mapEmployeesData(item){
    return {
        id:item.employee_id,
        text:item.displayname
    }
  }

  mapSiteItems(item){
    return {
        id:item.site_id,
        text:item.name
    }
  }

  mapWarehouseItems(item){
    return {
        id:item.warehouse_id,
        text:item.name
    }
  }

  getEmployeeName(id) {
    for (let i = 0; i < this.props.employeeList?.length; i++) {
      if (this.props.employeeList[i].employee_id === id) {
        const name = this.props.employeeList[i].display_name;
        return name;
      }
    }
  }

  getCurrencyName(id) {
    for (let i = 0; i < this.props.currencyList?.length; i++) {
      if (this.props.currencyList[i].currency_id === id) {
        const name = this.props.currencyList[i].currency_name;
        return name;
      }
    }
  }

  getTeamHeader(id) {
    for (let i = 0; i < this.props.teams?.length; i++) {
      if (this.props.teams[i].teamheader_id === id) {
        const name = this.props.teams[i].name;
        return name;
      }
    }
  }

  getSMETeamsOptions(){
    if(this.props.isSMEExist){
      return  this.props.teams?.map((item) => {
        return {
          value: item.teamheader_id,
          label: item.name,
        }
      }) ?? []
    }else{
      return this.props.employeeList?.map((item) => {
        return {
          value: item.employee_id,
          label: item.display_name,
        }
      }) ?? []
    }
  }

  render() {
    return (
      <>
        {!this.props.editMode &&
        typeof this.props.editMode !== "undefined" &&
        !this.props.viewOnly ? (
          <div className="generalInformation-edit">
            <div onClick={() => this.props.setEditMode(true)}>
              <img src={EditGrey} alt="edit" />
              <span>{`${Helper.getLabel(
                this.props.language,
                "edit",
                "Edit"
              )}`}</span>
            </div>
          </div>
        ) : null}
        <div className="generalInformation">
          {this.props.editMode || typeof this.props.editMode === "undefined" ? (
            <>
              <Tabs navs={generalNavs} showAll={false} disableSticky={true}>
                <div className="generalInformation-basic">
                  <div className="generalInformation-basic-grid">
                   <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "document number",
                        "Document number"
                      )}`}
                      value={this.props.documentNumber}
                      name='document_number'
                      disable={true}
                    />
                    <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "description",
                        "Description"
                      )}`}
                      value={this.props.description}
                      name='description'
                      onChange={(e) =>
                        this.props.setDescription(e.target.value.trimStart())
                      }
                      required={true}
                      disable={this.props.isDisableAllField}
                      maxLength={255}
                    />
                    <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "attention_information",
                        "Attention information"
                      )}`}
                      name='attention_information'
                      value={this.props.attentionInformation}
                      onChange={(e) =>
                        this.props.setAttentionInfo(e.target.value.trimStart())
                      }
                      disable={this.props.isDisableAllField}
                      maxLength={255}
                    />
                  </div>
                  <div className="generalInformation-title">
                    {Helper.getLabel(this.props.language, "dates", "Dates")}
                  </div>
                  <div className="generalInformation-basic-grid">
                    <NewInputDatePicker
                      label={`${Helper.getLabel(
                        this.props.language,
                        "document_date",
                        "Document date"
                      )}`}
                      inputClassName="document_date"
                      name="document_date"
                      disable={this.props.isDisableAllField}
                      onChange={(date) => {
                        this.props.setDocumentDate(date);
                      }}
                      value={this.props.documentDate}
                      
                    />
                    <NewInputDatePicker
                      label={`${Helper.getLabel(
                        this.props.language,
                        "accounting_date",
                        "Accounting date"
                      )}`}
                      inputClassName="accounting_date"
                      name="accounting_date"
                      disable={this.props.isDisableAllField}
                      onChange={(date) => {
                        this.props.setAccountingDate(
                          date
                        );
                      }}
                      value={this.props.accountingDate}
                      required={true}
                    />
                    <NewInputDatePicker
                      label={`${Helper.getLabel(
                        this.props.language,
                        "delivery_date",
                        "Delivery date"
                      )}`}
                      name="delivery_date"
                      disable={this.props.isDisableAllField}
                      onChange={(date) => {
                        this.props.setDeliveryDate(date);
                      }}
                      value={this.props.deliveryDate}
                    />
                  </div>
                </div>
                <div className="generalInformation-advanced">
                  <div className="generalInformation-basic-grid">
                    <Dropdown
                      label={`${Helper.getLabel(
                        this.props.language,
                        "requester",
                        "Requester"
                      )}`}
                      isDisable={this.props.isDisableAllField}
                      options={
                        (this.props.onBehalfRequest
                          ? this.props.requester ? [{value:this.props.requester,label:this.props.requester_name}] : this.props.employeeList?.map((item) => {
                              return {
                                value: item.employee_id,
                                label: item.displayname,
                              };
                            })
                          : this.props.requester ? [{value:this.props.requester,label:this.props.requester_name}] : this.props.requesterList?.map((item) => {
                              return {
                                value: item.employee_id,
                                label: item.displayname,
                              };
                            })) ?? []
                      }
                      key={this.props.onBehalfRequest ? 'onBehalf' : 'regular'}
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "select_requester",
                        "Select requester"
                      )}`}
                      id="requester_id"
                      name='requester_id'
                      onChange={(e) => {
                        this.props.setRequester(e.target.value);
                        const requesterName = this?.employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(e.target.value))?.displayname;
                        this.props.setRequesterName(requesterName)
                      }}
                      value={this.props.requester}
                      {...(this.props.onBehalfRequest === true
                        ? {
                          pagination:this.props.onBehalfRequest,
                          apiUrl:Settings?.apiOrgUrl + `/employee_dropdown`,
                          allDataRef:this.employeesData,
                          mapFunction:this.mapEmployeesData
                          
                          }
                        : {})}
                      required={true}
                      reRenderRequired={true}
                    />
                    <ToggleSwitch
                      title={`${Helper.getLabel(
                        this.props.language,
                        "on_behalf_request",
                        "On behalf request"
                      )}`}
                      onChange={() => {
                        this.props.setOnBehalfRequest();
                      }}
                      name='on_behalf_request'
                      margin={"mb-4"}
                      isDisable={this.props.isDisableAllField}
                      titlePosition={TitlePosition.left}
                      value={this.props.onBehalfRequest}
                    />
                    {/* <Dropdown
                      label={`${Helper.getLabel(
                        this.props.language,
                        "submitter",
                        "Submitter"
                      )}`}
                      options={
                        this.props.submitterList?.map((item) => {
                          return {
                            value: item.employee_id,
                            label: item.display_name,
                          };
                        }) ?? []
                      }
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "submitter",
                        "Submitter"
                      )}`}
                      id="submitter_id"
                      name='submitter_id'
                      onChange={(e) => {
                        this.props.setSubmitter(e.target.value);
                      }}
                      value={this.props.submitter}
                      required={true}
                      disable={true}
                    /> */}
                    <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "submitter",
                        "Submitter"
                      )}`}
                      required={true}
                      value={this.props.submitter}
                      name='submitter_id'
                      disable={true}
                    />
                    <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "delivery",
                        "Delivery"
                      )}`}
                      value={this.props.delivery}
                      onChange={(e) =>
                        this.props.setDelivery(e.target.value.trimStart())
                      }
                      name='delivery_name'
                      disable={this.props.isDisableAllField}
                      maxLength={255}
                    />
                    <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "email",
                        "Email"
                      )}`}
                      value={this.props.email}
                      name='email_address'
                      onChange={(e) => {
                        this.props.setEmail(e.target.value.trim());
                      }}
                      disable={this.props.isDisableAllField}
                      maxLength={80}
                    />
                    <Input
                      label={`${Helper.getLabel(
                        this.props.language,
                        "external_reference",
                        "External reference"
                      )}`}
                      name='external_reference'
                      value={this.props.externalReference}
                      onChange={(e) => {
                        this.props.setExternalRef(e.target.value.trimStart());
                      }}
                      disable={this.props.isDisableAllField}
                      maxLength={80}
                    />
                    <Dropdown
                      label={`${Helper.getLabel(
                        this.props.language,
                        "entity",
                        "Entity"
                      )}`}
                      options={
                        this.props.entitylist?.map((item) => {
                          return {
                            value: item.entity_id,
                            label: item.name,
                          };
                        }) ?? []
                      }
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "select_entity",
                        "Select entity"
                      )}`}
                      id="entity_id"
                      name='entity_id'
                      isDisable={this.props.isDisableAllField}
                      onChange={(e) => {
                        this.props.setEntity(e.target.value);
                      }}
                      value={this.props.entity}
                      required={true}
                      reRenderRequired={true}
                      apiUrl={Settings.loginUrl + '/get_entities'}
                      pagination={true}
                      mapFunction={this.mapEntityItems}
                      allDataRef={this.props.entitiesDataRef}
                    />
                    <Dropdown
                      label={`${Helper.getLabel(
                        this.props.language,
                        "site",
                        "Site"
                      )}`}
                      options={
                        this.props.siteList?.map((item) => {
                          return {
                            value: item.site_id,
                            label: item.name,
                          };
                        }) ?? []
                      }
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "select_site",
                        "Select site"
                      )}`}
                      id="site_id"
                      name='site_id'
                      onChange={(e) => {
                        this.props.setSite(e.target.value);
                      }}
                      reRenderRequired={true}
                      value={this.props.site}
                      required={true}
                      isDisable={this.props.isDisableAllField}
                      apiUrl={Settings.loginUrl + '/get_site'}
                      pagination={true}
                      mapFunction={this.mapSiteItems}
                      allDataRef={this.props.sitesDataRef}
                      additionalParams={this.props.siteAdditionalParams}
                    />
                    <Dropdown
                      label={`${Helper.getLabel(
                        this.props.language,
                        "warehouse",
                        "Warehouse"
                      )}`}
                      name='warehouse_id'
                      options={
                        this.props.warehouseList?.map((item) => {
                          return {
                            value: item.warehouse_id,
                            label: item.name,
                          };
                        }) ?? []
                      }
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "select_warehouse",
                        "Select warehouse"
                      )}`}
                      id="warehouse_id"
                      onChange={(e) => {
                        this.props.setWarehouse(e.target.value);
                      }}
                      value={this.props.warehouse}
                      required={true}
                      reRenderRequired={true}
                      isDisable={this.props.isDisableAllField}
                      apiUrl={Settings.loginUrl + '/get_warehouse'}
                      pagination={true}
                      mapFunction={this.mapWarehouseItems}
                      allDataRef={this.props.warehouseDataRef}
                      additionalParams={this.props.warehouseAdditionalParams}
                    />
                     <Dropdown
                      label={`${Helper.getLabel(
                        this.props.language,
                        "currency",
                        "Currency"
                      )}`}
                      name='currency_id'
                      options={
                        this.props.currencyList?.map((item) => {
                          return {
                            value: item.currency_id,
                            label: `${item.currency_code} - ${item.currency_name}`,
                          };
                        }) ?? []
                      }
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "select_currency",
                        "Select currency"
                      )}`}
                      id="currency_id"
                      onChange={(e) => {
                        this.props.setCurrency(e.target.value);
                      }}
                      value={this.props.currency}
                      isDisable={this.props.isDisableAllField}
                      required={true}
                    />
                     <Dropdown
                      label={this.props.isSMEExist ? `${Helper.getLabel(this.props.language,"sme_team","Subject matter expert team")}`:`${Helper.getLabel(this.props.language,"sme","Subject matter expert")}`}
                      name={this.props.isSMEExist ? 'sme_team' : 'sme'}
                      options={this.getSMETeamsOptions()}
                      placeHolder={`${Helper.getLabel(
                        this.props.language,
                        "select subject matter expert",
                        "Select subject matter expert"
                      )}`}
                      id="sme_team_id"
                      onChange={(e) => {
                        this.props.setSMEExpert(e.target.value);
                      }}
                      value={this.props.sme}
                      reRenderRequired={true}
                      isDisable={this.props.isDisableAllField}
                    />
                  </div>
                </div>
              </Tabs>
              {typeof this.props.editMode !== "undefined" ? (
                <div className="generalInformation-action">
                  <Button
                    isActive={false}
                    className="black-btn-style"
                    title={Helper.getLabel(
                      this.props.language,
                      "Close",
                      "Close"
                    )}
                    onClick={() => this.props.setEditMode(false)}
                  />
                  <Button
                    isActive={true}
                    title={Helper.getLabel(this.props.language, "save", "Save")}
                    onClick={() => this.props.handleHeaderSubmission()}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <div>
              <div className="generalInformation-static">
                <div className="generalInformation-static-block">
                <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "document_number",
                        "Document number"
                      )}
                    </label>
                    <span className="highlightedText">
                      {this.props.documentNumber ?? "-"}
                    </span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "Description",
                        "Description"
                      )}
                    </label>
                    <span>
                      {this.props.description ?? "-"}
                    </span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "delivery",
                        "Delivery"
                      )}
                    </label>
                    <span>{this.props.delivery ?? "-"}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "attention_information",
                        "Attention information"
                      )}
                    </label>
                    <span>{this.props.attentionInformation ?? "-"}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "requester",
                        "Requester"
                      )}
                    </label>
                    <span>{this.props.requesterName}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "submitter",
                        "Submitter"
                      )}
                    </label>
                    <span>{this.props.submitter}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(this.props.language, "email", "Email")}
                    </label>
                    <span>{this.props.email ?? "-"}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "external_reference",
                        "External reference"
                      )}
                    </label>
                    <span>
                      {this.props.externalReference &&
                      this.props.externalReference?.length > 0
                        ? this.props.externalReference
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="generalInformation-static-block">
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "currency",
                        "Currency"
                      )}
                    </label>
                    <span>{this.getCurrencyName(this.props.currency)}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(this.props.language, "entity", "Entity")}
                    </label>
                    <span>{this.props.entity_name}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(this.props.language, "site", "Site")}
                    </label>
                    <span>{this.props.site_name}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "warehouse",
                        "Warehouse"
                      )}
                    </label>
                    <span>{this.props.warehouse_name}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "document_date",
                        "Document date"
                      )}
                    </label>
                    <span>{this.props.documentDate}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "accounting_date",
                        "Accounting date"
                      )}
                    </label>
                    <span>{this.props.accountingDate}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "delivery_date",
                        "Delivery date"
                      )}
                    </label>
                    <span>{this.props.deliveryDate}</span>
                  </div>
                  <div>
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "subject matter expert",
                        "Subject matter expert"
                      )}
                    </label>
                    <span>{this.props.isSMEExist?this.getTeamHeader(this.props.sme) || "-":this.getEmployeeName(this.props.sme) || "-"} </span>
                  </div>
                </div>
              </div>
              <div className="work-confirmation-grid">
                <h6>Operational controls</h6>
                <div className="row">
                <div className="col-sm-3">
                <Checkbox
                  id='purchase_requisition_non_compliance'
                  type='checkbox'
                  name='purchase_requisition_non_compliance'
                  disableWithoutIcon={true}
                  label='Purchase requisition non compliance'
                  isChecked={this.props?.purchase_requisition_non_compliance}
                />
              </div>
              <div className="col-sm-3">
                <Checkbox
                  id='is_pr_qualify_for_express_request'
                  type='checkbox'
                  name='is_pr_qualify_for_express_request'
                  disableWithoutIcon={true}
                  label='Is PR qualify for express request'
                  isChecked={this.props?.is_pr_qualify_for_express_request}
                />
              </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
