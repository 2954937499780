import React from "react";
import MasterComonent from "../../components/Backend/MasterComonent";
import RFQDetailForm from "../../components/RequestForQuotation/RFQDetailForm";

const RFQDetailFormScreen = () => {
  return (
      <RFQDetailForm />
  );
};

export default RFQDetailFormScreen;
