import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper'; 
import Collapse from '../../inc/Collapse'; 
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings'; 
import { customDateComparator, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new'; 
import DatepickerFunctions from '../../../inc/DatepickerHelper'; 
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker";

/* Validations: positive number for percentages */

let gridApi = null;
let AddBtn;
let transaction;

const TaxcodeValues = ({ taxcode_id, language }) => {  
    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }
    
    const onRowValueChanged = (event) => {
        let apiPayload = {
            from_date: event?.data?.from_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.from_date) : null,
            to_date: event?.data?.to_date ? DatepickerFunctions.gridDateForDatabase(event?.data?.to_date) : null,
            tax_code_id: taxcode_id ? parseInt(taxcode_id) : null,
            percentage_exempt_from_tax: (event?.data?.percentage_exempt_from_tax) ? parseInt(event?.data?.percentage_exempt_from_tax) : null,
            valuepercentage: (event?.data?.value_percentage) ? parseInt(event?.data?.value_percentage) : null
        };

        if (event?.data?.taxcodesvalue_id) {
            updateValues(event, apiPayload)
        } else {
            createNew(event, apiPayload)
        }
    }
 
    const createNew = async (event, payload) => {
        try {
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiUrl + '/taxcodevalues', payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);
                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };

    const updateValues = async (event, payload) => {
        try { 
            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiUrl + '/taxcodevalues/' + event?.data?.taxcodesvalue_id, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.refreshServerSide({ purge: true });
                    gridApi?.deselectAll();
                }, 100);

                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };
 
    const onDeleteTaxCodesValues = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delete_tax_codes_values_id = element?.data?.taxcodesvalue_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/taxcodevalues/${delete_tax_codes_values_id}`)
            const rowNode = gridApi?.getRowNode(delete_tax_codes_values_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200) 
 
            gridApi?.refreshServerSide({ purge: true });
            gridApi?.deselectAll();
            
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const handleFetchData = (response) => {
        return {
            rowData: response?.data?.data?.codes_values?.map((item) => ({
                ...item, 
                gridId: `${Date.now()}_${getNewValue()}`
            })),
            rowCount: response?.data?.data?.codes_values?.length 
        }
    };
     
    const gridColHeaders = [
        {
            field: 'from_date',
            headerName: Helper.getLabel(language, "from_date", 'From date'),
            filter: 'agDateColumnFilter', 
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: true,
            cellEditor: DateEditor,
            minWidth: 220,
            comparator: customDateComparator
        },
        {
            field: 'to_date',
            headerName: Helper.getLabel(language, "to_date", 'To date'),
            filter: 'agDateColumnFilter', 
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateFromDataBase(params.value)
                : '-'
            },
            editable: true,
            cellEditor: DateEditor,
            minWidth: 220,
            comparator: customDateComparator
        },
        { field: 'value_percentage', editable: true, filter: 'agNumberColumnFilter', headerName: Helper.getLabel(language, "value_percentage", 'Value percentage') },
        { field: 'percentage_exempt_from_tax', editable: true, filter: 'agNumberColumnFilter', headerName: Helper.getLabel(language, "percentage_exempt_from_tax", 'Percentage exempt from tax') }
    ];
    
    return (
        <Collapse title={Helper.getLabel(language, 'codevalues', 'Code values')} open={true} className="mb-4 mt-4">
            <AgGridNew
                apiUrl={taxcode_id ? Settings.apiUrl + '/taxcodes/' + taxcode_id : null}
                pagination={false}
                columnDefs={gridColHeaders}
                fetchData={handleFetchData}
                onRowValueChanged={onRowValueChanged}
                handleDeleteSelectedRows={onDeleteTaxCodesValues}
                height={500}
                onGridReady={(params) => gridApi = params?.api}
                handleAddButton={handleBtn}
                uniqueField={`taxcodesvalue_id`}
                gridId={'taxcode-values-grid'}
            /> 
        </Collapse>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language
    };
};

export default connect(mapStateToProps)(TaxcodeValues);