import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect } from 'react-redux'
import '../../Purchase Order/ExtPO.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ButtonNew from '../../../inc/ButtonNew'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import CreatePOPopup from '../../../Purchase Orders Internal/Create PO/CreatePOPopup'
import MasterComonent from '../../../Backend/MasterComonent'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import { checkForRolesPermission, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import getInvoiceColumns from './InvoiceColumnsHeader'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Alert from '../../../inc/Alert'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

let gridApi = null

const InvoiceList = (props) => {
  const invoiceApiCalls = new WorkConfirmationApiCalls()

  const [loading, setLoading] = useState(true)
  const [documentStatus, setDocumentStatus] = useState([])
  const [openCreateModeInvoice,setOpenCreateModeInvoice]=useState(false)


  const navigate = useNavigate()

  const location = useLocation()

  //for fetch params from route
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const status = getSearchParam('status')
  const purchase_order_id = getSearchParam('purchase_order_id')
  const receiving_id = getSearchParam('receiving_id')
  const document_status = getSearchParam('document_status')
  const createdBy = getSearchParam('createby')
  const onAccount = getSearchParam('on_account')
  const purchase_agreement_id = getSearchParam('purchase_agreement_id')

  const getEditUrl = (event) => {
    if(onAccount && location.pathname !== "/credit-notes"){
      return `/invoices-on-account/edit/${event.data?.invoice_id}`
    }else if(location?.pathname==="/credit-notes"){
      return `/credit-notes/edit/${event.data?.invoice_id}`
    }else{
      return props.user?.usertype===0?`/invoice-details/${event.data.invoice_id}`:`/invoice-details?invoice_id=${event.data.invoice_id}`
    }
  }

  const cellHandler = (event) => {
    if (event.colDef.field === 'reference_number') {
      navigate(getEditUrl(event))
    }
    if (event.colDef.field === 'vendor_code') {
      navigate(
        `/edit-vendor/${event?.data?.vendor_id}`
      )
    }
    if(event.colDef.field === 'vendor_code') { 
      navigate(`/edit-vendor/${event.data.vendor_id}`)
    }
  }

  const getAsyncData = async () => {
    setLoading(true)
    const documentStatus = await invoiceApiCalls.getAllInvoiceDocumentStatus()
    if (documentStatus) setDocumentStatus(documentStatus)
    if (documentStatus) setLoading(false)
  }

  const handleGridReady= (params) => {
    gridApi = params.api
  }


  const getApiUrl = () => {
    let url = Settings.apiPurchaseOrderUrl + `/invoice_header`;  
 
   if (purchase_order_id){
      url = Settings.apiPurchaseOrderUrl + `/get_invoices_by_PO/${purchase_order_id}`
    } else if (receiving_id){
      url =  Settings.apiPurchaseOrderUrl + `/get_invoices_by_receiving/${receiving_id}`
    } else if(purchase_agreement_id){
      url = Settings.apiPurchaseOrderUrl + `/get_invoices_by_PA/${purchase_agreement_id}`
    }
    return url
  }

  const getAdditionalParams = () => {
    let addParams = location?.pathname==="/credit-notes" ? `&invoice_type=${Settings?.invoice_type?.credit_note}` : ''

    if(status || (parseInt(document_status)===1)){
      addParams += `&document_status=${status || document_status}`
    } else if(createdBy){
      addParams += `&createdby=${createdBy}`
    } else if (onAccount){
      addParams += `&on_account=true`
    }
    return addParams
  }

  useEffect(() => {
    getAsyncData()
  }, [])

  const Columns_Headings = getInvoiceColumns({
    language:props.language,
    location:location,
    invoiceStatuses:documentStatus,
  })


  const handleNewButtonNavigate = () => {
    if(receiving_id){
      navigate(`/invoice/new?receiving_id=${receiving_id}`)
    }else if(purchase_order_id){
      navigate(`/invoice/new?purchase_order_id=${purchase_order_id}`)
    }else if(onAccount || location?.pathname==="/credit-notes"){
      setOpenCreateModeInvoice(true)
    }
    else{
      navigate('/invoice/new')
    }
  }

  const isNewInvoiceAllowed = () => {
    if (!location?.state) {
      return true; 
    }

    const purchaseStatus = location.state.purchase_status?.name?.toLowerCase();
    const documentStatus = location.state.document_status?.toLowerCase();
  
    if (purchaseStatus === 'open' && documentStatus === 'draft' || purchaseStatus === 'reopen' && documentStatus==='draft' || purchaseStatus === 'freeze' && documentStatus==='draft' || purchaseStatus === 'withdraw freeze' && documentStatus==='draft') {
      Helper.alert(`New Invoice creation is not allowed because your ${location.state.document_name} document is in draft state.`, "error");
      return false; 
    } else if (purchaseStatus === 'close' || purchaseStatus === 'hold' || purchaseStatus === 'canceled' || purchaseStatus === 'amendment in process') {
      Helper.alert(`New Invoice creation is not allowed because your ${location.state.document_name} document is ${purchaseStatus.replace("/_/g","_")}.`, "error");
      return false;
    }
    return true; 
  };
  
  const handleNewButtonClick = () => {
    if (isNewInvoiceAllowed()) {
      handleNewButtonNavigate();
      localStorage.removeItem('savedInvoiceSteps');
    }
  };
  

  const getStatus = ()=>{
    if(parseInt(status)===0){
      return "Draft"
    }
    else if(parseInt(status) === 1){
      return "Posted"
    }else if(document_status){
      return "Posted"
    }else if(createdBy){
      return "Created by Me"
    }else if(onAccount){
      return "On Account"
    } else{
      return "All"
    }
  }

  const onInvoiceHeaderDelete  = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete( Settings.apiPurchaseOrderUrl +
          `/invoice_header/${element?.data?.invoice_id}`,)
      const rowNode = gridApi?.getRowNode(element.data?.invoice_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      if (res && index === lastIndex) {
        gridApi?.refreshServerSide({ purge: true });
        gridApi?.deselectAll();
      }
      // Helper.alert(res?.data?.message, 'success')
      Helper.alert(location.pathname==="/credit-notes"?"Credit Note Deleted Successfully":res.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const External_Columns_Headers = Columns_Headings.filter((item)=>item.field!=="accounting_date")

  let url = getApiUrl()
  let additionalParams = getAdditionalParams()

  const getDeleteBtnAccessFromSecurity = () => {
    if(props?.user?.usertype===0 && location?.pathname === '/invoice-list' && checkForRolesPermission(props?.user?.roles, 'Invoice')) return true;
    return false;
  };

  const renderGuiList = () => { 
    if(props?.user?.usertype === 0 && location?.pathname === '/invoice-list'){
      return {  
        screen: Gui_id_list.finance.invoices.invoices_main,
        addBtn: Gui_id_list.finance.invoices.invoices_main_create_button,
        deleteBtn: Gui_id_list.finance.invoices.invoices_main_delete_button
      }
    }

    if(props?.user?.usertype === 0 && location?.pathname !== '/invoice-list') {
      return {  
        screen: Gui_id_list.finance.credit_notes.credit_notes_main,
        addBtn: Gui_id_list.finance.credit_notes.credit_notes_main_create_button,
        deleteBtn: Gui_id_list.finance.credit_notes.credit_notes_main_delete_button
      }
    }

    return '';
  };
 
  let security = props?.security;
  
  if (renderGuiList() !== "" && !security.canView(renderGuiList()?.screen)) {
    return (
      <Fragment>
        <MasterComonent>
          <Alert 
            message="You do not have any access to the following screen, please contact your administrator!" 
            type="danger" 
          />
        </MasterComonent>
      </Fragment>
    );
  }
  
  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'work-confirmation-internal-screen'
          : 'purchase_orders_listing_archive external-theme'
      }
    >
     <MasterComonent>
      <div className="container-fluid"> 
        <NavigationHeder backUrl={props.auth?.user?.usertype===0&&location.search===""?"/finance":props.auth?.user?.usertype===1&&location.search === ''?"/dashboard":-1} title={location?.pathname==="/credit-notes"?`Credit Notes - ${getStatus()}`:`Invoices - ${getStatus()}`} hideMoreBtn={true}>
          {purchase_agreement_id ? null : <ButtonNew
            onClick={handleNewButtonClick}
            isDisable={renderGuiList() !== "" && !security.canCreate(renderGuiList()?.addBtn)} 
            title={Helper.getLabel(props.language, 'new', 'New')}
          />}
        </NavigationHeder>
        {loading ? (
          <SimpleLoading />
        ) : (
          <div>
           {props.auth?.user?.usertype===1 && 
            <ExtHeaderLine
                title={Helper.getLabel(
                  props.language,
                  'invoice_list',
                  'Invoices - All',
                )}
              />
            }
              <AgGridNew
                apiUrl={url}
                additionalParams={additionalParams}
                filterAdditionalParams={additionalParams}
                pagination={true}
                columnDefs={props.auth?.user?.usertype===1?External_Columns_Headers:Columns_Headings}
                onGridReady={handleGridReady}
                handleDeleteSelectedRows={onInvoiceHeaderDelete}
                onCellClicked={cellHandler}
                uniqueField={'invoice_id'}
                hideDeleteBtn={(renderGuiList() !== "" && !security.canDelete(renderGuiList()?.deleteBtn)) || getDeleteBtnAccessFromSecurity()}
                hideAddBtn={true}
                isDeleteBtnDisable={purchase_agreement_id}
                height={500}
                gridId={`invoice-listing-page`}
              />
          </div>
        )}
         {openCreateModeInvoice ? <CreatePOPopup headerType={props.headerType?props.headerType:"Invoice"} type={props.type?props.type:"invoice on account"} ClosePopup={setOpenCreateModeInvoice} editUrl={props.editUrl?props.editUrl:"/invoices-on-account/edit"} /> : ''}
      </div>
     </MasterComonent> 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    user:state.auth.user,
    language: state.language,
    user: state.auth.user,
  }
} 

export default connect(mapStateToProps) ((((InvoiceList))))