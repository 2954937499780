import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Helper from '../../../inc/Helper'
import NavigationHeder from '../../Navigations/NavigationHeder'
import MasterComonent from '../../Backend/MasterComonent'
import Collapse from '../../inc/Collapse'
import Dropdown from '../../Forms/Dropdown'
import Button from '../../inc/Button'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import $ from 'jquery' 
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../inc/Alert'

const LogsSearch = (props) => {

  const navigate = useNavigate()

  const [logsFactor,setLogsFactor]=useState({
    selectedDocument:{},
    viewType:'',
    document_number:'',
    line_id:0,
    header_id:'',
    lineViewType:'',
    line_type_id:0,
  })

  const [sources,setSources]=useState([])
  const [sourceId,setSourceId]=useState('')
  const [lines,setLines]=useState([])
  const [lineViewTypeData,setLineViewTypeData]=useState([])
  const [shType,setShType]=useState(null)
  const [onDropdownValueChange, setOnDropdownValueChange] = useState(false);

  const allCurrentData = useRef()

  let currentSearchTerm = ''
 
 
  const viewOptions = [
    {
       value:1,
       label:"Header View"
    },
    {
       value:2,
       label:"Line View"
    }
  ]

  const vendorViewOptions = [
    {
       value:1,
       label:"General Information"
    },
    {
       value:2,
       label:"Sites Information"
    }
  ]

  const listViewOptions = [
    {
      value:1,
      label:"Distribution"
    },
    {
      value:2,
      label:"Billing Rule"
    }
  ]

  const shipmentOptions = [
    {
      value:0,
      label:"Goods Receivings"
    },
    {
      value:1,
      label:"Work Confirmation"
    }
  ]


  const lineLevelOptions = listViewOptions.filter((item)=>item.value===1)

  //get_sources
  const get_sources= () => {  
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/source`).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        const filteredData = res.data.data.filter((element) => {
          return element.source_name === "Purchase Order" || element.source_name === "Contract" || element.source_name==="Request for Quotation" || element.source_name==="Shipment, Good receiving, Work confirmation" ||  element.source_name==="Release Order" || element.source_name==="Purchase Agreement" || element.source_name === "Vendor"
          ;
        });
        filteredData?.forEach((element) => {
         UPDATED_STATE.push({
          value: element.source_id,
          label: element.source_name
        })
      }) 
      setSources(UPDATED_STATE)
      }
    }) 
  }

  const getApiUrl = () => { 
    const baseApiUrl = Settings.apiPurchaseOrderUrl + `/get_documents/${sourceId}`; 
    return shType && parseInt(sourceId) === Settings?.source_id?.ShipmentGoodReceivingWorkConfirmation
      ? `${baseApiUrl}?shipment_type=${shType}`
      : baseApiUrl;
  };

  const getLinesApiUrl = () => {
    let linesApiUrl
    if(parseInt(sourceId)===Settings?.source_id?.ShipmentGoodReceivingWorkConfirmation){
      linesApiUrl = Settings?.apiPurchaseOrderUrl + `/get_sh_lines/${logsFactor?.header_id}`
    }else if(parseInt(sourceId)===Settings.source_id?.vendor){
      linesApiUrl = Settings.apiVendorUrl + `/vendorsites/${logsFactor?.header_id}`
    }else{
      linesApiUrl = Settings.apiPurchaseOrderUrl + `/lines/${logsFactor?.header_id}/${sourceId}` 
    }
    return linesApiUrl
  }


  const getDocumentsLines = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(getLinesApiUrl()).then(function(res){
      if(res.data.status==="success"){
        let UPDATED_STATE = []
        res.data?.data?.forEach((element) => {
         UPDATED_STATE.push({
          value: parseInt(sourceId)===Settings?.source_id?.vendor?element.vendorsite_id:element.line_id,
          label: parseInt(sourceId)===Settings?.source_id?.vendor?element.name:element.line_number || "-"
        })
      }) 
       setLines(UPDATED_STATE.reverse())
      }
    }).catch((res) => { 
      setLines([]) 
    })
  }

  const getLineViewTypeData = (type) => {
    let lineViewTypeUrl 
    lineViewTypeUrl = parseInt(type)===2 ? Settings.apiPurchaseOrderUrl + `/get_billingrule/${logsFactor?.line_id}/${sourceId}` : Settings.apiPurchaseOrderUrl + `/linesites/${logsFactor?.line_id}/${sourceId}`
    let api = Api
    api.setUserToken()
    api.axios().get(lineViewTypeUrl).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data?.data?.forEach((element) => {
         UPDATED_STATE.push({
          value: parseInt(type)===2?element.billingrule_id:element.linesite_id,
          label: parseInt(type)===2?element.description || "-":element.linesite_id
        })
      }) 
       setLineViewTypeData(UPDATED_STATE.reverse())
      }
    }) 
  }

  const mapDocumentsData = (item) => {
    return {
      id:item.header_id,
      text:item.document_number
    }

  }


  //onChangeFunction

  const onSourceIdChangeHandler = (e) => {
    setOnDropdownValueChange(true);
    let source_id = e.target.value
    setSourceId(source_id)
    $("#history_document_no").val(null).trigger("change");
    setLogsFactor({...logsFactor,selectedDocument:{},
      viewType:'',
      document_number:'',
      line_id:0,
      header_id:'',
      lineViewType:''})
    setShType(null)
    setTimeout(() => {
      setOnDropdownValueChange(false);
    }, 200) 
  }

  const onShipmentTypeChangeHandler = (e) => {
    setOnDropdownValueChange(true);
    setShType(e.target.value)
    $("#history_document_no").val(null).trigger("change");
    setLogsFactor({...logsFactor,viewType:'',header_id:'',selectedDocument:'',line_id:'',lineViewType:'',line_type_id:''})
    setTimeout(() => {
      setOnDropdownValueChange(false);
    }, 200) 
  }

  const onDocumentNumberChangeHandler = (e) => {
    const selected = allCurrentData?.current?.find(
      (doc) => doc.header_id===parseInt(e.target.value),
    )  
    setLogsFactor({...logsFactor,document_number:e.target.value,header_id:e.target.value,selectedDocument:selected,viewType:'',lineViewType:'',line_id:'',line_type_id:''})
  }

  const onViewTypeChangeHandler = (e) => {
    if(parseInt(e.target.value)===1){
      setLogsFactor({...logsFactor,viewType:e.target.value,header_id:logsFactor?.header_id,line_id:'',lineViewType:'',line_type_id:''})
    }else if(parseInt(e.target.value)===2){
      setLogsFactor({...logsFactor,viewType:e.target.value,lineViewType:'',line_type_id:'',line_id:''})
      getDocumentsLines()
    }else{
      setLogsFactor({...logsFactor,viewType:'',lineViewType:'',line_type_id:''})
    }
  }

  const onLineIdChangeHandler = (e) => {
    setLogsFactor({...logsFactor,line_id:e.target.value,lineViewType:''})
  }

  const onLineViewTypeChangeHandler = (e) => {
    setLogsFactor({...logsFactor,lineViewType:e.target.value,line_type_id:''})
    getLineViewTypeData(e.target.value)
  }

  const onLineTypeChangeHandler = (e) => {
    setLogsFactor({...logsFactor,line_type_id:e.target.value})
  }

  const onSearchClickHandler = () => {
    if (!logsFactor.header_id || !sourceId || !logsFactor.viewType) {
      Helper.alert("Please select any document, sourceId, and viewType", "error");
      return;
    }else if (logsFactor?.viewType === "1") {
        if (!logsFactor.header_id || !sourceId) {
          Helper.alert("Please select any document, sourceId, and header id for ", "error");
          return;
        }
      } else if (logsFactor?.viewType === "2") {
        if (!logsFactor.line_id) {
          Helper.alert("Please provide a line_id", "error");
          return;
        }
        if (logsFactor.lineViewType !== "") {
          if (!logsFactor.line_type_id) {
            Helper.alert("If lineViewType is not empty, line_type_id is required for viewType 2", "error");
            return;
          }
        }
      }
    
      navigate("/logs-details", { state: { logsFactor: logsFactor, sourceId: sourceId } })  
  }

  useEffect(()=>{
    get_sources()
  },[])

  if(!props?.security.canView(Gui_id_list.administrationForms.history_logs.history_logs_main)){ 
    return <Fragment>
      <MasterComonent>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </MasterComonent>
    </Fragment>
  }

  return (
    <div className="logs-search-screen">
      <MasterComonent>
        <div className="container-fluid">
          <NavigationHeder
            backUrl={-1}
            hideMoreBtn={true}
            hideBackButton={false}
            title={`Logs`}
          >
          </NavigationHeder>
          <Collapse title={Helper.getLabel(props.language,"search_logs","Search Logs")} open={true}>
            <div className='row align-items-center logs-search-row'>
              <div className='col-md-3'>
                <Dropdown label={Helper.getLabel(props.language,"select_document","Select document")} id="history_source_document" placeHolder={Helper.getLabel(props.language,"select_type_of_document","Select type of document")} options={sources} onChange={onSourceIdChangeHandler}></Dropdown>
              </div>
              {
                (parseInt(sourceId)===Settings?.source_id?.ShipmentGoodReceivingWorkConfirmation) && <div className='col-md-3'>
                <Dropdown label={Helper.getLabel(props.language,"select_shipment_type","Select shipment type")} placeHolder={Helper.getLabel(props.language,"select_shipment_type","Select shipment type")} onChange={onShipmentTypeChangeHandler} value={shType} id="history_shipment_id" options={shipmentOptions}></Dropdown>
                </div>
              }
              <div className='col-md-3'> 
                <Dropdown key={onDropdownValueChange ? 'render' : 'rerender'} label={Helper.getLabel(props.language,"select_document_no","Select document number")} id="history_document_no" placeHolder={Helper.getLabel(props.language,"select_document_number","Select document number")} value={logsFactor?.document_number} onChange={onDocumentNumberChangeHandler} apiUrl={getApiUrl()} pagination={true} allDataRef={allCurrentData} mapFunction={mapDocumentsData} currentSearchTerm={currentSearchTerm} isDisable={(shType==='' || shType===null) && parseInt(sourceId)===Settings?.source_id?.ShipmentGoodReceivingWorkConfirmation}></Dropdown>
              </div>
               <div className='col-md-3'>
                <Dropdown label={Helper.getLabel(props.language,"select_view_type","Select view type")} value={logsFactor?.viewType} placeHolder={Helper.getLabel(props.language,"select_view_type","Select view type")}  id="history_view_type" options={parseInt(sourceId)===Settings?.source_id?.vendor?vendorViewOptions:viewOptions} onChange={onViewTypeChangeHandler}></Dropdown>
              </div> 
              {
                parseInt(logsFactor?.viewType)===2 &&  <><div className='col-md-3'>
                <Dropdown label={parseInt(sourceId)===Settings?.source_id?.vendor?Helper.getLabel(props.language,"select_site_id","Select site id"):Helper.getLabel(props.language,"select_line_id","Select line id")} onChange={onLineIdChangeHandler} value={logsFactor?.line_id} id="history_line_id" options={lines}></Dropdown>
              </div>
              {(parseInt(shType) !== 1 && parseInt(sourceId) !== 6) && <div className='col-md-3'>
                <Dropdown label={Helper.getLabel(props.language,"select_line_view_type","Select line view type")} onChange={onLineViewTypeChangeHandler} value={logsFactor?.lineViewType} id="history_line_view_type" options={(parseInt(sourceId)===Settings.source_id?.request_for_quotation||parseInt(sourceId)===Settings?.source_id?.contract)?listViewOptions:lineLevelOptions}></Dropdown>
              </div>}</>
              }
              {
                (parseInt(logsFactor?.lineViewType) === 1 || parseInt(logsFactor?.lineViewType) === 2) && <div className='col-md-3'>
                <Dropdown label={parseInt(logsFactor?.lineViewType) === 1?Helper.getLabel(props.language,"select_distribution","Select distribution"):Helper.getLabel(props.language,"select_billing_rule","Select billing rule")} onChange={onLineTypeChangeHandler} value={logsFactor?.line_type_id} id="history_line_view_type_id" options={lineViewTypeData}></Dropdown>
              </div>
              }
              <div className='col-md-3'>
                <Button isDisable={!props?.security.canCreate(Gui_id_list.administrationForms.history_logs.history_logs_main_button)} title={Helper.getLabel(props.language,"search","Search")} isActive={true} onClick={onSearchClickHandler}></Button>
              </div>
            </div>
          </Collapse>
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.administrationForms.history_logs.history_logs_main
};

export default connect(mapStateToProps) ((( ApplySecurityRoles( LogsSearch, SecurityOptions)))); 