import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import MasterComonent from '../components/Backend/MasterComonent';
import RsDataLoader from '../components/inc/RsDataLoader';
import RegisterVendor from '../components/Registrations/Vendor/RegisterVendor';
import Helper from '../inc/Helper';

class Join extends Component {
    onSavBtnClick(e){

    }
    render() {
        let language = this.props.dataLoaderProps.language;
        let icons = {
            basic:{default:"/images/icons/user-blue.svg",active:"/images/icons/user-blue.svg"},
            security:{default:"/images/icons/security-gray.svg",active:"/images/icons/security-gray.svg"},
            deligation:{default:"/images/icons/users-gray.svg",active:"/images/icons/users-gray.svg"},
        }
        return (
            <MasterComonent>
                <div className='join_pages'>
                    <div className='container-fluid'>
                        <Routes>
                            <Route path='/vendor' element={ <RegisterVendor {...this.props}/>} />
                        </Routes>
                    </div>
                </div>
            </MasterComonent>
            
        );
    }
}

export default RsDataLoader(Join);