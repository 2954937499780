import React from 'react';

const CompliancesHeader = ({CompliancesVendorList, vendorRef, onScroll}) => {
    return (
        <div className='compliances_consolidated_header'>
            <div className="fixed_column">
                <p>Compliance</p>
            </div>
            <div className="scrollable_column" onScroll={onScroll} ref={vendorRef}>
                {CompliancesVendorList.map((item, index) => {
                    return (
                        <div className="inner_vendor_col" key={index}> {item.vendor} </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CompliancesHeader;