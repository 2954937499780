import React, { Component } from 'react';
import GoodsReceivingNotes from '../../components/GoodsReceiving/GoodsReceivingNotes';
import Helper from '../../inc/Helper';

class ContractOrderGoodReceivingPage extends Component {
    render() {
        return (
            <GoodsReceivingNotes
                title={Helper.getLabel(null,'contract_order_good_receiving_note','Contract order good receiving note')}
                backUrl='/contract-order/edit'
                editUrl='/contract-order/good-receiving/edit'
            />
        );
    }
}

export default ContractOrderGoodReceivingPage;