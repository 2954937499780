import React from "react";
import CommonScoringSections from "../../../External Screens/Request For Quotation/Scoring Module/CommonScoringSections";

const ScoringCriteria = ({ language, RFQ_id, rfqHeader }) => {
  return (
    <CommonScoringSections
      language={language}
      RFQ_id={RFQ_id}
      collapseTitle="Scoring Criteria"
      isFieldsEditable={(rfqHeader?.rfq_status === 2 || rfqHeader?.rfq_status === 3 || rfqHeader?.rfq_status === 5 || rfqHeader?.rfq_status === 6 || rfqHeader?.rfq_status === 7)} 
    />
  );
};

export default ScoringCriteria;