import React, {useEffect, useState } from "react";
import $ from "jquery";
import Popup from "../../../Popup/Popup";
import SimpleLoading from "../../../Loading/SimpleLoading";
import Helper from "../../../../inc/Helper";
import LineViewComponent from "../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import { getPopupMessageBasedOnStatus, objectIsEmpty } from "../../../../inc/Validation";
import TotalWidget from "../../../Widgets/TotalWidget/TotalWidget";
import CustomCheckboxCellRenderer from "../../../grid/ag/cellRender/CustomCheckboxCellRenderer";
import { getNewValue } from "../../../grid/ag/commonFunctions/GridCommonFunctions";
import Button from "../../../inc/Button";

let gridObj = null

const TechnicalSelectedLinesModalAwardToColumn = ({
  rfqHeader,
  language,
  setShowSelectedLinesModal,
  vendorreplyId,
  scoreReplyHeaderId,
  getScoreSummary,
  evaluationData
}) => {
  const [linesData, setLinesData] = useState([]);
  const [vendorReplyLines, setVendorReplyLines] = useState();
  const [scoreReplyLines, setScoreReplyLines] = useState();
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await getUserDetails();
      await getVendorReplyLines();
      await getScoreReplyLines();
    }

    fetchData();
  }, []);

  useEffect(() => {
    mapReplyLines();
  }, [scoreReplyLines && userDetails]);

  const getUserDetails = async () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + `/user_details`)
      .then(function (res) {
        if (res.data.Status === "Success") {
          setUserDetails(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const getVendorReplyLines = async () => {
    if (!vendorreplyId) {
      setLoading(false);
      setDataLoaded(true);
      return;
    }

    let api = Api;
    api.setUserToken();
    setLoading(true)
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/lines/${vendorreplyId}/${Settings.source_id.RfqVendorReply}`)
      .then(function (res) {
        if (res.data.status === "success") {
          setVendorReplyLines(res.data.data);
          setLoading(false);
        }
      })
      .catch((res) => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  const getScoreReplyLines = async () => {
    if (!scoreReplyHeaderId) {
      setLoading(false);
      setDataLoaded(true);
      return;
    }

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/get_line_recommendations/${scoreReplyHeaderId}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setScoreReplyLines(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  const mapReplyLines = () => {
    if (!vendorReplyLines || !scoreReplyLines || !userDetails) return;


    const filteredVendorReplyLines = vendorReplyLines?.filter((vendorLine) =>
      scoreReplyLines?.some(
        (scoreLine) => scoreLine.vendorreplyline_id === vendorLine.line_id
      )
    );

    const linesDataWithAwardTo = filteredVendorReplyLines?.map(
      (vendorLine) => {
        const matchingScoreLine = scoreReplyLines?.find(
          (scoreLine) => scoreLine.vendorreplyline_id === vendorLine.line_id
        );
        return {
          ...vendorLine,
          award_to: matchingScoreLine?.award_to || null,
          recommendation_id:matchingScoreLine?.replylinerecommendation_id || null
        };
      }
    );
  
    setLinesData(linesDataWithAwardTo);
    setDataLoaded(true);
  };

  const onCheckboxChanged = (value,params) => {
    const filteredScore = scoreReplyLines?.find(
      (score) =>
        score.vendorreplyline_id === params.data.line_id
    );

    let lineRecommendationId =
      filteredScore?.replylinerecommendation_id;

    if (!lineRecommendationId) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/line_recommendation/${lineRecommendationId}`,
        {
          recommended: null,
          preferred: null,
          award_to: value,
        }
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setLinesData((prevLinesData) => {
            const updatedLinesData = prevLinesData?.map((line) =>
              line.recommendation_id === lineRecommendationId
                ? { ...line, award_to: value}
                : line
            );
            return updatedLinesData;
          });
          params.node.setDataValue('award_to',value)
          Helper.alert("Line recommendation updated successfully.", "success");
          getScoreSummary()
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      });
  };

  const taxTransactionViewCell = (params) => {
    if(params.data.line_id){
        return <div className="rs_inactive_label">View tax</div>;
    }
    return <></>;
  }


  let dataSource = {
    getRows: async function (params) {
      if(linesData?.length !== 0){
        gridObj?.api?.hideOverlay()
      }
      params?.success({
        rowData: linesData?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: linesData?.length
      })
    },
  };
  
  const handleLineViewGridReady = (params) => {
    gridObj = params
    if(params){ 
      setTimeout(()=>{ 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200)
    };
  };

  const onPopupCloseHandler = () => {
    $("html").removeClass("rs_popup_open");
    setShowSelectedLinesModal(false);
  }
  

  let Columns_Header = [
    {
      field:"line_number",
      headerName:Helper.getLabel(language,'line_number',"Line number"), 
      editable:false,
      minWidth:160,
    },
    {
      field: "award_to",
      minWidth: 160,
      headerName: Helper.getLabel(language, "award_to", "Award to"),
      cellClass:'rfq-col-additional',
      cellRenderer: CustomCheckboxCellRenderer,
      cellRendererParams:{
        onChange:onCheckboxChanged,
        disabled:(objectIsEmpty(evaluationData) || evaluationData?.submitted) ? true : rfqHeader?.rfq_status !== 6 || rfqHeader?.onhold || rfqHeader?.rfq_status===2
      },
      editable: false,
    },
    {
      field:"item_name",
      headerName:Helper.getLabel(language,'item_name',"Item name"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"item_code",
      headerName:Helper.getLabel(language,'item_code',"Item code"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"category_name",
      headerName:Helper.getLabel(language,'category',"Category"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    }, 
    {
      field:"comments",
      headerName:Helper.getLabel(language,'comemnts',"Comments"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"ordered_qty",
      headerName:Helper.getLabel(language,'quantity',"Quantity"), 
      editable:false,
      minWidth:180,
    },
    {
      field:"required_qty",
      headerName:Helper.getLabel(language,'purposed_quantity',"Purposed quantity"), 
      editable:false,
      minWidth:180,
    },
    {
      field:"unit_name",
      headerName:Helper.getLabel(language,'Unit Name',"Unit name"), 
      editable:false,
      minWidth:180,
    },
  ]

  let commercialValues = [{
    field:"unit_price",
    headerName:Helper.getLabel(language,'unit_price',"Unit price"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"discount_amount",
    headerName:Helper.getLabel(language,'discount_amount',"Discount amount"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"discount_percentage",
    headerName:Helper.getLabel(language,'discount_percentage',"Discount percentage"), 
    editable:false,
    minWidth:200,
  },
  {
    field:"line_amount",
    headerName:Helper.getLabel(language,'line_amount',"Line amount"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"itemgroup_name",
    headerName:Helper.getLabel(language,'item_group',"Item group"), 
    editable:false,
    minWidth:180,
  },
  {
    field:'view_tax_transaction', 
    minWidth: 150, 
    headerName:Helper.getLabel(language,'tax',"Tax"), 
    editable: false,
    cellRenderer:taxTransactionViewCell
  }
]

  Columns_Header = Columns_Header.concat(commercialValues)



  return (
    <Popup
      autoOpen={true}
      width='1200px'
      onClose={onPopupCloseHandler}
    >
      <>
        <div className='d-flex justify-content-between mb-3'>
          <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'select_lines_awarded_to_vendor', 'Select Lines Awarded to Vendor')}</h5>
          <div className='d-flex gap-2'>
            <Button title={Helper.getLabel(language, 'save_and_close', 'Save & Close')} className='blue-btn-style' onClick={onPopupCloseHandler} />
            <div className='technical-total'>
              <TotalWidget source_id={Settings.source_id.RfqVendorReply} integrator={vendorreplyId} isDisable={false} />
            </div>
          </div>
        </div>
        {loading || !dataLoaded ? (
          <SimpleLoading />
        ) : (
          <div className='rfq-line-view line_scoring_module'>
            <LineViewComponent
              defaultValues={{ requester_id: rfqHeader?.requester_id }}
              source_id={Settings?.source_id?.RfqVendorReply}
              line_source={null}
              isEditable={false}
              header_id={null}
              header={Columns_Header}
              purchase_status={null}
              document_status={rfqHeader?.rfq_status}
              delivery_date={null}
              onbehalf_request={false}
              vendor_id={null}
              vendorsite_id={null}
              data={linesData}
              requester_id={rfqHeader?.requester_id}
              orderer_id={rfqHeader?.order_id}
              setOrderStatusProps={null}
              warehouse_id={rfqHeader?.warehouse_id}
              taxgroup_item={null}
              taxgroup_id={null}
              entity_id={rfqHeader?.entity_id}
              site_id={rfqHeader?.site_id}
              lineRecommended={true}
              hideDistribution={false}
              hideBillingRule={rfqHeader?.target_document !== 1}
              hideSetup={true}
              hideGeneral={true}
              hideExtraColumn={true}
              onLineGridReady={handleLineViewGridReady}
            />
          </div>
        )}
      </>
    </Popup>
  )
};

export default TechnicalSelectedLinesModalAwardToColumn;
