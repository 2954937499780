import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Dropdown from '../Forms/Dropdown';
class TanentSwitcher extends Component {
    constructor(props){
        super(props);
        this.isUserLoaded = false;
        this.state = {
            isLoading:false,
            users:[],
        }
    }
    componentDidMount(){
        this.getUsers();
    }
    getUsers(){
        if(this.isUserLoaded){
            return;
        }
        this.isUserLoaded = true;
        this.setState({
            isLoading:true
        })
        let user_id = this.props.auth.user.user_id;

        if(this.props?.auth?.user?.admin_user) return;

        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiUrl + '/get_users/'+user_id).then( res => {
            let users = [];
            if(res.data.status==='success'){
                users = res.data.data;
            }
            that.setState({
                users:users
            })
        })
    }
    onChangeHanlder(event){
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/switch_user/'+event.target.value).then(res=>{
            Helper.setCookie(Settings.userTokenKey,res.data.new_token,Settings.userTokenExpireDate);
            setTimeout(function(){
               window.location.reload();
            },200)
        })
    }
    render() {
        let userList = this.state.users.map( user => {
            return {
                label: `${user.tenant_name}`,
                value: user.tenant_id
            }
        })
        return (
            <div className='tenant_switcher'> 
                <Dropdown
                    id="users_id"
                    name="users" 
                    options={userList}
                    onChange={ this.onChangeHanlder.bind(this) }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
    })
}
export default  connect (mapStateToProps,mapDispatchToProps)  ( TanentSwitcher );