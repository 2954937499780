

class ColorPickerRender {
    constructor() {
        this.eGui = document.createElement('div');
        this.eGui.classList.add('color-picker-renderer');
    
        this.colorPicker = document.createElement('input');
        this.colorPicker.type = 'color';
        this.colorPicker.addEventListener('input', this.onColorChange.bind(this));
    
        this.eGui.appendChild(this.colorPicker);
    }
  
    init(params) {
        this.params = params;
        this.colorPicker.value = params.value || '';
    }
  
    getGui() {
        return this.eGui;
    }
  
    onColorChange(event) {
        const newColor = event.target.value;
        this.params.data[this.params.colDef.field] = newColor;
    }
}

export default ColorPickerRender;