import React from 'react';
import ContractInformationCard from './ContractInformationCard';
import Helper from '../../../inc/Helper';

const ContractFirstRow = (language) => {
  return (
    <div className='contract-info-cards d-flex gap-2 justify-content-between'>
      <ContractInformationCard title={Helper.getLabel(language,"total_active_contracts","Total Active Contracts")} mainCardClass="pr-card-bg" information={"73.81%"} value="124" />
      <ContractInformationCard title={Helper.getLabel(language,"contracts_on_hold","Contracts on Hold")} mainCardClass="contracts-card-bg" information={"4.17%"} value="7" /> 
      <ContractInformationCard title={Helper.getLabel(language,"contract_expiring","Contracts Expiring")} mainCardClass="ro-card-bg" information={"12.50%"} value="21" /> 
      <ContractInformationCard title={Helper.getLabel(language,"contract_in_amendment","Contracts in Amendment")} mainCardClass="light-green-card" information={"5.36%"} value="9" />
      <ContractInformationCard title={Helper.getLabel(language,"contract_in_renewal","Contracts in Renewal")} mainCardClass="light-purple-card" information={"2.38%"} value="4" />
      <ContractInformationCard title={Helper.getLabel(language,"cancelled_contracts","Cancelled Contracts")} mainCardClass="light-yellow-card" information={"1.19%"} value="2" />
      <ContractInformationCard title={Helper.getLabel(language,"contract_in_early_settlement","Contracts in Early Settlement")} mainCardClass="light-rose" information={"0.60%"} value="1" />
   </div>
  );
}

export default ContractFirstRow;
