import React, { Fragment, useState } from 'react'; 
import { connect } from 'react-redux'; 
import Settings from '../../../inc/Settings'; 
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import Alert from '../../inc/Alert';
import MasterComponentWraper from '../../Backend/MasterComponentWraper'; 
import Popup from '../../Popup/Popup';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'; 
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'; 
import FormResourcesDetails from '../../grid/ag/cellRender/FormResourcesDetails';

let gridApi = null;
let gridApiChild = null;
let AddBtn;
let transaction;

const ResourceAccess = (props) => {
  const [previleges, setPrevileges] = useState([]);
  const [accessLevels, setAccessLevels] = useState([]);

  const [formResourceAccesses, setFormResourcesAccesses] = useState([]);
  const [addResources, setAddResources] = useState({});
  
  const [openPopup, setOpenPopup] = useState(false) 
    
  const closeResourcesPopup = () => {
    reloadParentGrid()
    setOpenPopup(false)
  };

  const handleBtn = (value, transactionData) => {
    AddBtn = value;
    transaction = transactionData;
  };

  const onRowValueChanged = (event) => { 
    if (event?.data?.formresource_access_id) {
      updateData(event);
    } else {
      addNewData(event);
    }
  }
  
  const updateData = async (event) => {
    if(!event?.data?.formresource_access_id) return;

    try {
      let payload = {
        formresource_id: event?.data?.formresource_access_id,
        privilege_id: event?.data?.privilege_id,
        access_level: event?.data?.access_level
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiSystemUrl+`/form_resource_access/${event?.data?.formresource_access_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiChild?.refreshServerSide({ purge: true });
          gridApiChild?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const addNewData = async (event) => {  
    if(!addResources?.form_resource_id) return;

    try {
      let payload = {
        formresource_id: addResources?.form_resource_id,
        privilege_id: event?.data?.privilege_id,
        access_level: ""+event?.data?.access_level
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiSystemUrl+'/form_resource_access', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiChild?.refreshServerSide({ purge: true });
          gridApiChild?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApiChild?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        closeResourcesPopup();
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
 
  const gridColumnHeadersParent = [
    {field: 'name', cellRenderer: 'agGroupCellRenderer', editable: false, headerName:Helper.getLabel(props.language,'form_resource_id_sr_1','Form Resources Name')},
    {field: 'description', editable: false, headerName:'Description'},
    {field: 'path', editable: false, headerName:'Path'}, 
  ];

  const gridColumnHeadersChild = [  
    { 
      field: 'privilege_id', 
      headerName: Helper.getLabel(props?.language, "previleges", 'Previleges'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth: 180, 
      cellEditorParams: { values: previleges },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, previleges),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label 
      }
    },
    { 
      field: 'access_level', 
      headerName: Helper.getLabel(props?.language, "access_level", 'Access Level'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth: 180, 
      cellEditorParams: { values: accessLevels },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, previleges),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    }
  ];
    
  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: formResourceAccesses ? formResourceAccesses?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })) : []?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: formResourceAccesses ? formResourceAccesses?.length : 0
      })
    },
  };

  const handleFetchData = (response) => {  
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}` 
      })) ,
      rowCount: response?.data?.data?.length  
    }
  };

  const reloadParentGrid = () => {
    setTimeout(() => {
      gridApi?.refreshServerSide({ purge: true });
      gridApi?.deselectAll();
    }, 100);
  };

  const onGridChildReady = (params) => {
    if(params) {
      gridApiChild = params?.api;
      setTimeout(()=> { 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200);
    };
  }
  
  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true, 
    detailCellRenderer: FormResourcesDetails,
    detailCellRendererParams: {
      togglePopupState: setOpenPopup, 
      reloadGrid: reloadParentGrid,
      onAddBtnClick: setAddResources,
      getDetailRowData: async function (params) {  
        try {
          const api = Api;
          api.setUserToken();
  
          const [privilegesResponse, accessLevelsResponse] = await Promise.all([
            api.axios().get(`${Settings.apiSystemUrl}/privileges`),
            api.axios().get(`${Settings.apiUrl}/enum/formresourceaccess`)
          ]);
  
          const updatedPrevileges = privilegesResponse?.data?.status === 'success'
            ? privilegesResponse?.data?.data?.map((item) => ({
                value: item.id,
                label: item.name
              }))
            : [];
  
          const updatedAccessLevels = accessLevelsResponse?.data?.status === 'success'
            ? accessLevelsResponse?.data?.data?.map((item) => ({
                value: item.id,
                label: item.name
              }))
            : [];

          setFormResourcesAccesses(params?.data?.access);
          setPrevileges(updatedPrevileges);
          setAccessLevels(updatedAccessLevels);
          return ({ previlegesList: updatedPrevileges, accesses: updatedAccessLevels });
        } catch (error) { 
          return ({ previlegesList: [], accesses: [] }); 
        }
      } 
    },
  };

  if(!props?.user?.admin_user){
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} title='Resources access' />

      <AgGridNew
        apiUrl={Settings.apiSystemUrl + "/formresources"} 
        payload={{ form_id: null }}
        apiMethod={"post"} 
        fetchData={handleFetchData}
        pagination={false} 
        onGridReady={(params) => gridApi = params?.api}
        columnDefs={gridColumnHeadersParent}
        allBtnHide={true}
        masterSettings={masterSettings}
        height={500}   
        gridId={"resources-access-grid"}
      />
 
      {openPopup && (
        <div className='close-popup'>
          <Popup autoOpen={true} width={"70%"} onClose={closeResourcesPopup}>
            <div className='rs_create_users_grid'>
              <AgGridNew
                apiUrl={null} 
                hideDeleteBtn={true}
                pagination={false} 
                rowType={'single'}
                columnDefs={gridColumnHeadersChild} 
                onRowValueChanged={onRowValueChanged} 
                height={500}
                onGridReady={onGridChildReady}
                handleAddButton={handleBtn} 
                uniqueField={'formresource_access_id'}
                gridId={"rs_popup_open_resources_access"}
              />
            </div>
          </Popup> 
        </div>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language,
    user:state.auth.user
  }
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ResourceAccess)))