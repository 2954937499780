import React, { Component } from 'react';
import AmendmentRequestList from '../../components/Purchase Orders Internal/AmendmentRequest/AmendmentRequestList';
import Helper from '../../inc/Helper';

class RoAmendmentPage extends Component {
    render() {
        return (
            <AmendmentRequestList
                title = {Helper.getLabel(null,'release_order_amendment_request','Release order amendment request')}
                backUrl = "/release-order/edit"
                editUrl = "/release-order/amendment-request/edit"
            />
        );
    }
}

export default RoAmendmentPage;