import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Alert from '../../inc/Alert'  
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import LinkBtn from '../../inc/LinkBtn'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'  
import { useParams } from 'react-router-dom'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'  
import FormValidator from '../../../inc/FormValidator'
import SimpleLoading from '../../Loading/SimpleLoading' 
import {areObjectsDifferent, checkIfArrayIsEmpty, getObjectsWithNullImageId, getPopupMessageBasedOnStatus, ValidateData} from '../../../inc/Validation'
import Attributes from './Attributes'
import PriceDiscount from './PriceDiscount'
import EditGeneral from './EditGeneral'
import ProductImg from '../../Widgets/ProductImageUploader/ProductImg'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

let itemDiscountObj = null 
let itemPricingArray = null
let default_item_Obj = null 
let gridApiVendor = null
let gridApiEntity = null
let AddBtn;
let transaction; 
let totalDropdownPage = 1;
let totalDropdownPageEntityItem = 1;

const EditItem = (props) => {

  // Components Level Data get APIS

  const {id} = useParams() 
  const [editItem, setEditItem] = useState({})
  const [loading, setLoading] = useState(true) 
  const [loadingDiscount, setLoadingDiscount] = useState(false)
  const [editItemDiscount, setEditItemDiscount] = useState({})
  const [editItemPricing, setEditItemPricing] = useState({}) 
  const [numberSequence, setNumberSequence] = useState({});
  const [itemBarcode, setItemBarcode] = useState([]) 

  // Tabs Related logics
  const wrapperDivRef = useRef(null)
  const [scrollDitection, setScrollDitection] = useState('down');
  const [height, setHeight] = useState(0);
  const [selectedSupNav, setSelectedSupNav] = useState('Header View'); 

  // Item Image / Loader State --
  const [itemImage, setItemImage] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)
  // --

  //Dropdowns -- States

  const [entityReason, setEntityReason] = useState([])
  const [purchaseUnit, setPurchaseUnit] = useState([])
  const [entityCode, setEntityCode] = useState([])
  const [entityName, setEntityName] = useState([]) 
  const [vendorName, setVendorName] = useState([])

  let item_source_id = 24
  let sectionName = 'item-master-edit'

  // -----
  
  let entityCodeNameListCalled = false
  let purchaseUnitListCalled = false
  let vendorsCodeNameListCalled = false
  let itemEntityReasonListCalled = false
  let itemObjCalled = false 
  let itemDiscountObjCalled = false 
  let itemImageObjCalled = false
  let nsApiCalled = false;
 
  let security = props?.security; 

  let isItemFormVisible = security?.canView(Gui_id_list.masterData.item_master_modules.item.item_edit_screen);
  let isBtnDisableFromSecurity = !security?.canEdit(Gui_id_list.masterData.item_master_modules.item.item_edit_screen_save_button);

  //Component-Rendered Functions:
  useEffect(() => {
    Helper.resizeWindowHandle(wrapperDivRef,setHeight,window)
  },[wrapperDivRef?.current?.clientHeight,scrollDitection]);

  useEffect(() => { 
    getNumberSequenceData();
    get_entity_code_name_list()
    get_vendors_code_name_list()
    getItemObject()  
    getItemDiscountObject() 
    get_item_entity_reason_list()  
    get_purchase_unit_list()
    getItemImageObj()
  }, [])
  
  // Validations

  let validator;
  let itemDiscountValidator;

  const validationConfigure = () => {
    let fieldConfig = [
      {name:'item_name', max: 255, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
      {name:'display_name', max: 255, displayName:Helper.getLabel(props.language,'display_name','Display Name'),types:['Required']}, 
      {name:'purchaseUoM', displayName:Helper.getLabel(props.language,'purchaseUoM','Purchase UoM'),types:['Required']}, 
      {name:'item_group_id', displayName:Helper.getLabel(props.language,'item_group','Item Group'),types:['Required']},  
      {name:'itemmaster_type', displayName:Helper.getLabel(props.language,'item_master','Item Master Type'),types:['Required']}, 
    ] 

    let fieldConfigItemDiscount = [
      {name:'name', max: 255, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
      {name:'item_discounttype_id', displayName:Helper.getLabel(props.language,'discount_type','Discount Type'),types:['Required']}, 
      {name:'discount_unit', displayName:Helper.getLabel(props.language,'discount_unit','Discount Unit'), types:['Required']},
      {name:'discount', displayName:Helper.getLabel(props.language,'discount','Discount'), types:['Required']}
    ]

    if(!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }

    if(!itemDiscountValidator){
      itemDiscountValidator = new FormValidator(fieldConfigItemDiscount, props.language)
    }
  }

  validationConfigure()

  let navs = [
    {title:Helper.getLabel(props.language,'general','General'), data_section: "one", active_status: 'active_tab' },
    {title:Helper.getLabel(props.language,'price_&_discount','Price & Discount'), data_section: "two", active_status: ''}, 
    {title:Helper.getLabel(props.language,'attributes','Attributes'), data_section: "three", active_status: ''},
    {title:Helper.getLabel(props.language,'entity','Entity'), data_section: "four", active_status: ''},
    {title:Helper.getLabel(props.language,'vendor','Vendor'), data_section: "five", active_status: ''}, 
  ];

  const addComponentTop = () => {
    let items = [
      {title:Helper.getLabel(props.language,'item_code',"Item Code"),subtitle: editItem.code ? editItem.code : ''}, 
      {title:Helper.getLabel(props.language,'name',"Name"),subtitle: editItem.item_name ? editItem.item_name : ''},
      {title:Helper.getLabel(props.language,'display_name',"Display Name"),subtitle: editItem.display_name ? editItem.display_name : ''},
      {title:Helper.getLabel(props.language,'purchase_unit_of_measurement',"Purchase Unit Of Measurement"),subtitle: editItem.purchaseUoM ? getPurchaseTitleLabel() : ''}
    ]
    let tags = [
      {title:Helper.getLabel(props.language,'confirm','Confirmed')},
    ];
    return(
      <div className='rfq-internal-blue'>
        <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags}/>
      </div>
    )
  }

  const addComponentRight = () => { 
    return ( 
      <MasterSidebar> 
        <ProductImg disbleProductImageArea={isBtnDisableFromSecurity} loadImages={loadingImages} editMode={true} reloadComponent={getItemImageObj} imageFiles={(itemImage)} setImageFiles={setItemImage} />  
        <NoteAttachmentHistory disableAttachments={isBtnDisableFromSecurity} scrollDitection={scrollDitection} height={height} hideHistory={true} notesource_integrator= {id}  attachmentsource_integrator ={id} source_id ={item_source_id} /> 
      </MasterSidebar> 
    )
  }

  const getPurchaseTitleLabel = () => {
    if(!document.getElementById('purchase_unit_id')) {
      return ''
    }
    let Result = ''
    let innerid = document.getElementById('purchase_unit_id').value
    purchaseUnit.forEach(element => {
      if (element.value == innerid){
        Result = element.label
      }
    }) 
    return Result
  }

  //get objects data
 
  const getItemObject = () => {
    if(id){  
      if(itemObjCalled){
        return
      }  
      let api = Api
      itemObjCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/item/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){
          let UPDATE_OBJ = {}
          UPDATE_OBJ = { 
            "item_name": res.data.data.name,
            "display_name": res.data.data.display_name,
            "description":res.data.data.description,
            "lastpurchaseprice":res.data.data.lastpurchaseprice,
            "purchaseUoM": res.data.data.purchaseUOM,
            "translationsource_id":res.data.data.translationsource_id,
            "ItemStyle_id": res.data.data.style_id,
            "item_configuration_id": res.data.data.configuration_id,
            "item_color_id": res.data.data.color_id,
            "item_size_id":res.data.data.size_id,
            "item_dimension_id": res.data.data.dimension_id,
            "notesource_id": res.data.data.notesource_id,
            "attachmentsource_id": res.data.data.attachmentsource_id,
            "item_group_id": res.data.data.itemgroup_id,
            "segment_combination_id":null,
            "price_include_tax": res.data.data.price_include_tax,
            "itemmaster_id": res.data.data.itemmaster_id,
            "taxgroupitem_id": res.data.data.taxgroupitem_id,
            "item_buyergroup_id":res.data.data.itembuyergroup_id,
            "item_brand_id": res.data.data.itembrand_id,
            "itemmaster_type": res.data.data.itemmaster_type ,
            "code": res.data.data.code,
            "prices": res.data.data?.prices?.map(item => {
              return {
                ...item,
                price: item.price ? parseFloat(item.price)?.toFixed(2) : 0
              }
            })
          }
          default_item_Obj = {
            "item_name": res.data.data.name,
            "display_name": res.data.data.display_name,
            "description":res.data.data.description,
            "lastpurchaseprice":res.data.data.lastpurchaseprice,
            "purchaseUoM": res.data.data.purchaseUOM,
            "translationsource_id":res.data.data.translationsource_id,
            "ItemStyle_id": res.data.data.style_id,
            "item_configuration_id": res.data.data.configuration_id,
            "item_color_id": res.data.data.color_id,
            "item_size_id":res.data.data.size_id,
            "item_dimension_id": res.data.data.dimension_id,
            "price_include_tax": res.data.data.price_include_tax,
            "notesource_id": res.data.data.notesource_id,
            "attachmentsource_id": res.data.data.attachmentsource_id,
            "item_group_id": res.data.data.itemgroup_id,
            "segment_combination_id":null,
            "itemmaster_id": res.data.data.itemmaster_id,
            "taxgroupitem_id": res.data.data.taxgroupitem_id,
            "item_buyergroup_id":res.data.data.itembuyergroup_id,
            "item_brand_id": res.data.data.itembrand_id,
            "itemmaster_type": res.data.data.itemmaster_type ,
            "code": res.data.data.code
          }
          itemPricingArray = res.data.data?.prices?.map((item) => { 
            return {
              itempricing_id: item.itempricing_id,
              price: item.price ? parseFloat(item.price)?.toFixed(2) : 0,
              pricing_type: JSON.stringify(item.pricing_type)
            }
          })
          setLoading(false) 
          setEditItem(UPDATE_OBJ)
          Helper.alert(res.data.message)    
        }else {
          setLoading(false)
        }
      }).catch((res) => { 
        setLoading(false)
      })
    }
  }

  const getItemImageObj = () => {
    if(id){  
      if(itemImageObjCalled){
        return;
      }
      setLoadingImages(true)
      let api = Api
      itemImageObjCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/itemimage/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){    
          setItemImage(res.data.data)   
          setLoadingImages(false)
        }
      }).catch((res) => {  
        setLoadingImages(false)
        // Helper.alert(res.response.data.message, 'error')  
      }) 
    }
  }
  
  const getItemDiscountObject = () => {
    if(id){  
      if(itemDiscountObjCalled){
        return;
      }
      setLoadingDiscount(true)
      let api = Api
      itemDiscountObjCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/getitem_discount/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){  
          setLoadingDiscount(false)
          if(!checkIfArrayIsEmpty(res.data.data)){
            res.data.data.forEach((element) => { 
              setEditItemDiscount({
                ...element,
                discount_unit: element.discount_unit
                  ? parseFloat(element.discount_unit)?.toFixed(2)
                  : 0,
                discount: element.discount
                  ? parseFloat(element.discount)?.toFixed(2)
                  : 0,
              });
              itemDiscountObj = {
                ...element,
                discount_unit: element.discount_unit
                  ? parseFloat(element.discount_unit)?.toFixed(2)
                  : 0,
                discount: element.discount
                  ? parseFloat(element.discount)?.toFixed(2)
                  : 0,
              }
            })
          }
          if(checkIfArrayIsEmpty(res.data.data)){
            setEditItemDiscount({})
            itemDiscountObj = null
          }
        }
      }).catch((error) => {
        setLoadingDiscount(false)
      })
    }
  }

  const get_item_entity_reason_list = () => {
    if(itemEntityReasonListCalled){
      return;
    }
    let api = Api
    itemEntityReasonListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/vendorreason`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.reason_id,
          label: element.description
        })
      })
      setEntityReason(UPDATED_STATE)
      }
    })
   }

   const get_purchase_unit_list = () => {
    if(purchaseUnitListCalled){
      return;
    }
    let api = Api
    purchaseUnitListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.unit_id,
          label: `${element.description} - ${element.code}`
        })
      })
      setPurchaseUnit(UPDATED_STATE)
      }
    })
   }

   const getNumberSequenceData = async () => {
    if(nsApiCalled) return;
    nsApiCalled = true;

    try {
      let api = Api;
      api.setUserToken();
      const numberSequenceRecord = await api
        .axios()
        .get(Settings.apiUrl + `/ns_status/None/${Settings.source_id.item}`);
      if (numberSequenceRecord?.status === 200) {
        setNumberSequence(numberSequenceRecord?.data);
      }
    } catch (error) {
      setNumberSequence({});
    }
  };

   const get_entity_code_name_list = () => {
    if(entityCodeNameListCalled){
      return
    }
    let api = Api
    entityCodeNameListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/get_entities`).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE_CODE = []
        let UPDATED_STATE_NAME = []
        res.data.data.forEach((element) => {
          UPDATED_STATE_CODE.push({
          value: element.entity_id,
          label: element.code
        })
      })
      res.data.data.forEach((element) => {
        UPDATED_STATE_NAME.push({
        value: element.entity_id,
        label: element.name
      })
    })
      setEntityCode(UPDATED_STATE_CODE)
      setEntityName(UPDATED_STATE_NAME)
      }
    })
   }

   const get_vendors_code_name_list = () => {
    if(vendorsCodeNameListCalled){
      return
    }
    let api = Api
    vendorsCodeNameListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/get_active_vendors`,{}).then(function(res){
      if(res.data.status==="success"){  
        let UPDATED_STATE_NAME = []
       
        res.data.data.forEach((element) => {
            UPDATED_STATE_NAME.push({
              value: element.vendor_id,
              label: element.name
            })
        })
      setVendorName(UPDATED_STATE_NAME)
      }
    })
   }


  //end

  // Update Item & Item Modules

  const ItemUpdateAPI = () => {
    if(!areObjectsDifferent(default_item_Obj, editItem)){
      return;
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiItemUrl+`/item/${id}`, {
      "item_name": editItem.item_name !== "" ? editItem.item_name : "",
      "display_name": editItem.display_name !== "" ? editItem.display_name : "",
      "description": editItem.description !== "" ? editItem.description : "",
      "lastpurchaseprice": editItem.lastpurchaseprice !== "" ? editItem.lastpurchaseprice : null,
      "purchaseUoM": editItem?.purchaseUoM || null,
      "translationsource_id": editItem.translationsource_id,
      "ItemStyle_id": editItem?.ItemStyle_id || "",
      "item_configuration_id": editItem?.item_configuration_id || "",
      "item_color_id": editItem?.item_color_id || "",
      "item_size_id": editItem?.item_size_id || "",
      "item_dimension_id": editItem?.item_dimension_id || "",
      "notesource_id": editItem.notesource_id,
      "attachmentsource_id": editItem.attachmentsource_id,
      "item_group_id": editItem?.item_group_id || null,
      "segment_combination_id":null,
      "taxgroupitem_id": editItem?.taxgroupitem_id || "",
      "item_buyergroup_id": editItem?.item_buyergroup_id || "",
      "item_brand_id": editItem?.item_brand_id || "",
      "itemmaster_type": editItem?.itemmaster_type,
      "price_include_tax": editItem.price_include_tax
    }).then(function(res){
      if(res.data.status ==="success"){
        Helper.alert(res.data.message)
        getItemObject()
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      getItemObject()
    }) 
  }

  const ItemImageAPI = () => {
    if (checkIfArrayIsEmpty(itemImage) || !id) {
      return;
    }
    
    let nullImageIdObjs = getObjectsWithNullImageId(itemImage);
    if (!nullImageIdObjs) {
      return;
    }
    
    setLoading(true)
    let promises = nullImageIdObjs.map((element) => {
      let api = Api;
      api.setUserToken();
      return api.axios().post(Settings.apiItemUrl+`/itemimage`, {
        "item_id": id,
        "image_name": element.image_name,
        "image":element.image,
        "image_type": element.image_type
      });
    });
    
    Promise.all(promises).then((responses) => {
      let success = responses.some(res => res.data.status === "success");
      if (success) {
        Helper.alert("Item Images Uploaded!"); 
        getItemImageObj();
        setLoading(false)
      } else { 
        setLoading(false)
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res) 
      getItemImageObj();
      setLoading(false)
    });
  };

  const ItemDiscountUpdateAPI = () => { 
      if(itemDiscountObj !== null){
        if(!areObjectsDifferent(itemDiscountObj, editItemDiscount)){
          return;
        }
        
        let validData = {
          ...editItemDiscount
        }
    
        if(!itemDiscountValidator.isValid(validData)){
          itemDiscountValidator.displayMessage();
          itemDiscountValidator.reset() 
          return;
        }

        setLoading(true)
        let api = Api
        api.setUserToken()
        api.axios().put(Settings.apiItemUrl+`/itemdiscount/${editItemDiscount.item_discount_id}`, {
          "itemdiscounttype_id": editItemDiscount.item_discounttype_id ? parseInt(editItemDiscount.item_discounttype_id) : null,
          "name": editItemDiscount.name !== "" ? editItemDiscount.name : null,
          "discount_unit": editItemDiscount.discount_unit ? (editItemDiscount.discount_unit) : 0,
          "discount": editItemDiscount.discount ? (editItemDiscount.discount) : 0,
          "effective_date": DatepickerFunctions.convertDateForDataBase(editItemDiscount.effective_date),
          "expire_date": DatepickerFunctions.convertDateForDataBase(editItemDiscount.expire_date)
        }).then(function(res){
          if(res.data.status ==="success"){
            Helper.alert(res.data.message)
            getItemDiscountObject()
            setLoading(false)
          }
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
          getItemDiscountObject()
          setLoading(false)
        })
      }
      if(itemDiscountObj === null){
        if(ValidateData(editItemDiscount)){
          return;  
        }
        
        let validData = {
          ...editItemDiscount
        }

        if(!itemDiscountValidator.isValid(validData)){
          itemDiscountValidator.displayMessage();
          itemDiscountValidator.reset() 
          return;
        }

        let api = Api
        api.setUserToken()
        api.axios().post(Settings.apiItemUrl+'/item_discount', {
          "item_id": parseInt(id),
          "itemdiscounttype_id": editItemDiscount.item_discounttype_id ? parseInt(editItemDiscount.item_discounttype_id) : null,
          "name": editItemDiscount.name !== "" ? editItemDiscount.name: null,
          "discount_unit": editItemDiscount.discount_unit,
          "discount": editItemDiscount.discount,
          "effective_date": DatepickerFunctions.convertDateForDataBase(editItemDiscount.effective_date),
          "expire_date": DatepickerFunctions.convertDateForDataBase(editItemDiscount.expire_date)
        }).then(function(res){
          if(res.data.status ==="success"){
            Helper.alert(res.data.message)
            getItemDiscountObject() 
          }
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
          getItemDiscountObject()
        }) 
      }
    }

    const ItemPricingUpdateAPI = () => {
      if(editItemPricing.pricing_type == null) { 
        return;
      }
      let priceObj = itemPricingArray.find(price => price.pricing_type == editItemPricing.pricing_type)

      let api = Api
      api.setUserToken()
 
      if(editItemPricing.itempricing_id) {
        if(!areObjectsDifferent(priceObj, editItemPricing)){
          return;
        }
        setLoading(true)
        api.axios().put(Settings.apiItemUrl+`/itemprice/${editItemPricing.itempricing_id}`, {
          "pricing_type": editItemPricing.pricing_type ? parseInt(editItemPricing.pricing_type) : null,
          "price_unit": '1',
          "price": editItemPricing.price ? editItemPricing.price : 0
        }).then(function(res){
          if(res.data.status ==="success"){
            Helper.alert(res.data.message)  
            setEditItemPricing({})
            getItemObject()
            setLoading(false)
          }
        }).catch((res) => {  
          getPopupMessageBasedOnStatus(res) 
          setEditItemPricing({})
          getItemObject()
          setLoading(false)
        })  
      }

      if(editItemPricing.itempricing_id == null) {
        if(!editItemPricing.price || editItemPricing.price === "" || editItemPricing.price === null) return;

        setLoading(true)
        api.axios().post(Settings.apiItemUrl+'/item_pricing',{
          "item_id": parseInt(id),
          "pricing_type": editItemPricing.pricing_type ? parseInt(editItemPricing.pricing_type) : null,
          "price_unit": '1',
          "price": editItemPricing.price
        }).then(function(res){
        if(res.data.status ==="success"){
          Helper.alert(res.data.message)   
          setEditItemPricing({})
          getItemObject()
          setLoading(false)
        }
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res)   
          setEditItemPricing({})
          getItemObject()
          setLoading(false)
        })     
      }
    }
 
  const DeleteVendorOfItemMaster = useCallback(async (element) => {
    let api = Api
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/itemvendor/${id}/${element?.data?.vendor_id}`)
      const rowNode = gridApiVendor?.getRowNode(element?.data?.vendor_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApiVendor.applyServerSideTransaction(transaction)
      }, 200)
      gridApiVendor?.refreshServerSide({ purge: true });
      gridApiVendor?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  const addVendorData = async (event) => {
    if(!id) return;

    let payload = {
      vendor_id: parseInt(event?.data?.vendor_id),
      ispreferred: event.data.is_preferred ? true : false,
      item_id: parseInt(id)
    }

    try {
      let api = Api
      api.setUserToken()
      const vendorResponseData = await api
        .axios()
        .post(Settings.apiItemUrl+`/itemvendor`, [payload])
      setTimeout(() => {
        gridApiVendor?.refreshServerSide({ purge: true });
        gridApiVendor?.deselectAll();
      }, 100)
      setTimeout(()=> {
        if(AddBtn){
          gridApiVendor.applyServerSideTransaction(transaction)
        }
      },1000)
      Helper.alert(vendorResponseData?.data?.message)
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const updateVendorData = async (event) => {
    if(!id) return;

    let payload = {
      vendor_id: parseInt(event?.data?.vendor_id),
      ispreferred: event?.data?.is_preferred,
      item_id: parseInt(id)
    };

    try {
      let api = Api
      api.setUserToken()
      const vendorItemResponse = await api
        .axios()
        .put(Settings.apiItemUrl+`/itemvendor/${id}/${event?.data?.vendor_id}`, payload)
      setTimeout(() => {
        gridApiVendor?.refreshServerSide({ purge: true })
        gridApiVendor?.deselectAll();
      }, 100)
      Helper.alert(vendorItemResponse?.data?.message)
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const addItemEntityData = async (event) => { 
    try {
      let payload = {
        entity_id: event?.data?.entity_id,
        itemmaster_id: editItem?.itemmaster_id,
        IsActive: event?.data?.is_active ? true : false,
        Reason_Id: event?.data?.reason_id ? parseInt(event?.data?.reason_id) : "",
        comments: !event?.data?.is_active && !event?.data?.comments ? null : event?.data?.comments
      };

      let api = Api;
      api.setUserToken();
      
      const res = await api.axios().post(Settings.apiItemUrl+`/itementity`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiEntity?.refreshServerSide({ purge: true });  
          gridApiEntity?.deselectAll();
        }, 100);

        setTimeout(() => {
          if (AddBtn) {
            gridApiEntity?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const updateItemData = async (event) => {
    if(!id) return;

    let payload = {
      IsActive: event?.data?.is_active,
      Reason_Id: event?.data?.reason_id ? parseInt(event?.data?.reason_id) : "",
      comments: !event?.data?.is_active && !event?.data?.comments ? null : event?.data?.comments
    };

    try {  
      let api = Api
      api.setUserToken()
      const entitytemResponse = await api
        .axios()
        .put(Settings.apiItemUrl+`/itementity/${event?.data?.itemmaster}/${event?.data?.entity_id}`, payload)
      setTimeout(() => {
        gridApiEntity?.refreshServerSide({ purge: true })
        gridApiEntity?.deselectAll();
      }, 100)
      Helper.alert(entitytemResponse?.data?.message)
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const onRowValueChanged = (event) => { 
    if (!event?.data?.vendor_code) { 
      addVendorData(event)
    } else {
      updateVendorData(event) 
    }
  };

  const onRowValueChangedEntity = (event) => {
    if(event?.data?.entity_id && event?.data?.itemmaster) {
      updateItemData(event)
    } else {
      addItemEntityData(event)
    }
  };
  
  const handleBtn = (value,transactionData) => {
    AddBtn = value
    transaction = transactionData
  };
 
  const Editable = (event) => {
    if(!event.data.vendor_id && !isBtnDisableFromSecurity) {
      return true
    }
    return false
  }

  let select2SettingsEntity = {
    ajax: {
      url: (params) =>{
        totalDropdownPageEntityItem = params.page || 1;
        return `${Settings.apiUrl}/get_entities`;
      },
      processResults: function (data) {
        let isMorePageExist = ( data.total_records - ( totalDropdownPageEntityItem * Settings.dropdownPageSize )) > 0 ? true : false ; 
        let result = data?.data?.map( item => {
          return {
            id: item.entity_id,
            text: item.name
          }
        })
        return {
          results: result,
          pagination: {
            more: isMorePageExist
          }
        };
      }
    }
  };

  const gridColsHeaderItem = [
    {
      field: 'entity_id',
      headerName: 'Entity',
      valueGetter: (params) => {
        if(!params?.data?.entity_name || !params?.data){
          return '-'
        }
        else {
          return params?.data?.entity_name
        }
      },
      editable: (event) => !(event?.data?.entity_id && event?.data?.itemmaster),
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      select2Settings:select2SettingsEntity,
      minWidth:200,
      cellEditorParams: {
        values: entityName
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agTextColumnFilter'
    },
    { field:'entity_code', filter: 'agTextColumnFilter', headerName:'Entity code', editable: false },
    {
      field:'is_active', headerName: 'Active', 
      filter: 'agSetColumnFilter', filterParams: 
      { 
        values: [true, false],
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'true' : 'false' 
      },
      editable: !isBtnDisableFromSecurity,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      valueGetter:(params)=> { 
        if(!params?.data?.is_active || !params?.data){
          return false
        }
        else {
          return params.data?.is_active
        }
      }
    },
    {
      field: 'reason_id',
      headerName: 'Reason',
      editable: !isBtnDisableFromSecurity,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:200,
      cellEditorParams: {
        values: entityReason
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agTextColumnFilter'
    },
    { field:'comments', filter: 'agTextColumnFilter', headerName: 'Comments', editable: !isBtnDisableFromSecurity }
  ];
  
  const paginatedDropdownSettings = {
    ajax: {
      url: (params) =>{
        totalDropdownPage = params.page || 1;
        return Settings.apiVendorUrl + '/get_active_vendors';
      },
      processResults: function (data) {
        let isMorePageExist = ( data.total_records - ( totalDropdownPage * Settings.dropdownPageSize )) >0 ? true : false ; 
        let result = data?.data?.map( item => {
          return {
            id: item.vendor_id,
            text: item.name
          }
        })
        return {
          results:result,
          pagination: {
            more: isMorePageExist
          }
        };
      }
    }
  };

  const getColsHeaderVendors = [
    {
      field: 'vendor_id',
      headerName: 'Vendor',
      editable: Editable,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      valueGetter: (params) => { 
        if(!params?.data?.vendor_name || !params?.data){
          return '-'
        }
        else {
          return params.data?.vendor_name
        }
      },
      minWidth:200,
      select2Settings:paginatedDropdownSettings,
      cellEditorParams: {
        values: vendorName
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agTextColumnFilter'
    }, 
    { field:'vendor_code', filter: 'agTextColumnFilter', headerName:'Vendor code', editable:false },
    {
      field:'is_preferred', headerName: 'Preferred', 
      editable: !isBtnDisableFromSecurity,
      filter: 'agSetColumnFilter', filterParams: { 
        values: [true, false],
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'true' : 'false' 
      },
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      valueGetter:(params)=> { 
        if(!params?.data?.is_preferred || !params?.data){
          return false
        }
        else {
          return params.data?.is_preferred
        }
      }
    }
  ];

  const VendorsModule = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'vendor',"Vendor")}>
        <AgGridNew
          apiUrl={Settings.apiItemUrl+`/itemvendor/${id}`}
          pagination={false}
          isAddBtnDisable={isBtnDisableFromSecurity}
          isDeleteBtnDisable={isBtnDisableFromSecurity}
          columnDefs={getColsHeaderVendors}
          onRowValueChanged={onRowValueChanged}
          handleDeleteSelectedRows={DeleteVendorOfItemMaster}  
          onGridReady={(params) => gridApiVendor = params.api}
          handleAddButton={handleBtn}
          uniqueField={`vendor_code`}
          gridId={'item-master-vendors-grid'}
        />
      </Collapse> 
    )
  }

  const EntityModule = () => { 
    return(
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'entity',"Entity")}>
        <AgGridNew
          apiUrl={ editItem?.itemmaster_id ? Settings.apiItemUrl+`/itementity/${editItem?.itemmaster_id}` : null }
          pagination={false} 
          key={loading}
          columnDefs={gridColsHeaderItem}
          hideDeleteBtn={true}
          returnApiCondition={!editItem?.itemmaster_id}
          onRowValueChanged={onRowValueChangedEntity}  
          onGridReady={(params) => gridApiEntity = params.api} 
          isAddBtnDisable={isBtnDisableFromSecurity}
          uniqueField={`entity_id`}
          gridId={'item-master-entity-grid'}
        />
      </Collapse>
    )
  }
  
  const SaveHandler = () => {
    let editItemValid = { 
      "item_name": editItem.item_name,
      "display_name": editItem.display_name,
      "purchaseUoM": editItem.purchaseUoM,
      "item_group_id": editItem.item_group_id,
      "itemmaster_type": editItem.itemmaster_type+''
    }

    let validData = {
      ...editItemValid
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }
       
    if(!numberSequence?.ns_type == "continuous" && (!editItem?.code || editItem?.code === "")) {
      Helper.alert('Item code field is required', 'error');
      return;
    }

    ItemImageAPI() 
    ItemUpdateAPI()
    ItemDiscountUpdateAPI()
    ItemPricingUpdateAPI() 
  }

  if(!isItemFormVisible){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader /> : null }
      <div className='container-fluid scrollBarBlinkControl'>
        <div ref={wrapperDivRef}>
          <NavigationHeder hideMoreBtn={true} title={ `<span style="color:#000;">${Helper.getLabel(props.language,'Edit Employee',"Edit Item")} #</span> ${editItem.display_name}` }>
            <LinkBtn className='black-btn-style' isActive= {false} to="/items" title={Helper.getLabel(props.language,'close',"Close")} />
            <Button isDisable={isBtnDisableFromSecurity} isActive= {false} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} className="rfq_save_btn" />
          </NavigationHeder>
  
          <FixedNavigationHeder 
            addComponentTop={addComponentTop} 
            sectionName={sectionName} 
            navs={navs} 
            selectedSupNav={selectedSupNav} 
            scrollDitection={scrollDitection} 
          /> 
        </div>

        <TabContentWraper
          height={height}
          setScrollDitection={setScrollDitection}
          leftSideContent = {
            <>
              <div className="lists_contant one" id={`${sectionName}General`} style={{ position: "relative" }}>
                <EditGeneral disableComponent={isBtnDisableFromSecurity} numberSequence={numberSequence} purchaseUnit={purchaseUnit} id={id} itemBarcode={itemBarcode} setItemBarcode={setItemBarcode} editItem={editItem} setEditItem={setEditItem} />
              </div>
              <div className="lists_contant two" id={`${sectionName}Price & Discount`} style={{ position: "relative" }}>
                <PriceDiscount disableComponent={isBtnDisableFromSecurity} loadingDiscount={loadingDiscount} editItemDiscount={editItemDiscount} setEditItemDiscount={setEditItemDiscount} editItem={editItem} setEditItemPricing={setEditItemPricing} editItemPricing={editItemPricing} />
              </div>
              <div className="lists_contant three" id={`${sectionName}Attributes`} style={{ position: "relative" }}>
                <Attributes disableComponent={isBtnDisableFromSecurity} editItem={editItem} setEditItem={setEditItem} />
              </div>
              <div className="lists_contant four" id={`${sectionName}Entity`} style={{ position: "relative" }}>
                {EntityModule()}
              </div>
              <div className="lists_contant five" id={`${sectionName}Vendor`} style={{ position: "relative" }}>
                {VendorsModule()}
              </div>
            </>
          }
          rightSideContent={addComponentRight}
        /> 
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.item_master_modules.item.item_edit_screen
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditItem, SecurityOptions))))