import Api from "../../../../inc/Api";
import MasterCellEditor from "./MasterCellEditor";
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Helper from "../../../../inc/Helper";
import Settings from "../../../../inc/Settings";
/**
disableOptions = []
inputId = inputtag id
select2Settings = {}
dropdownDisableAllowClear = true | false
*/

class SimpleDropdown extends MasterCellEditor{
    init(params) {
        this.params = params;
        if( params.colDef.disableOptions && typeof  params.colDef.disableOptions === 'function'){
            this.disableOptions =  params.colDef.disableOptions(params);
        }else{
            this.disableOptions =params.colDef.disableOptions ? params.colDef.disableOptions : []
        }
        this.dropdownId = params.colDef.inputId ? params.colDef.inputId : params.colDef.field;
        this.uiWraperId = this.dropdownId+'_wrapper';
        this.value = params.value ? params.value : '' ;
        this.uiWraper = document.createElement("div");
        this.uiWraper.id = this.uiWraperId;
        this.addClass(this.uiWraper,'rs_ag_adv_dropdown rs_simple_dropdown_select2')
        
        this.input = document.createElement("select");
        this.input.id = this.dropdownId;
        
        this.uiWraper.appendChild(this.input);

        let dataValues = typeof params.values == 'function' ? params.values(params) : params.values;

        let values = dataValues;
        if(params.colDef.optionsFilter){
            values = params.colDef.optionsFilter(dataValues,params);
        }
        let placeholderOption = document.createElement("option");
        placeholderOption.label = "";
        placeholderOption.value = "";
        this.input.appendChild(placeholderOption);
        
        for (var i = 0; i < values.length; i++) {
            var option = document.createElement("option");
            option.value = values[i].value;
            option.text = values[i].label;
            if(this.disableOptions.includes(values[i].value)){
                this.addAttribute(option,'disabled','')
            }
            if(params.value && values[i].value == params.value){
                option.selected = 'selected';
            }
            this.input.appendChild(option);
        }
        this.input.addEventListener('input', (event) => {
            this.value = event.target.value;
        });
        
    }
    
    afterGuiAttached(){
        let that = this;
        that.initSelect2();
        setTimeout(function(){
            $('#'+this.dropdownId).select2("destroy");
            that.initSelect2();
        },1)
    }
    initSelect2(){
        let that = this;
        let dropdownSelctor =  $('#'+this.dropdownId);
        let select2Settings = this.params.colDef.select2Settings ? this.params.colDef.select2Settings: {}
        if(select2Settings.ajax){
            select2Settings.ajax.delay = 500;
            select2Settings.ajax.transport =  (params, success, failure) =>{
                var $request = $.ajax({
                    ...params,
                    headers:{
                        ...params.headers,
                        Authorization: 'Bearer '+Api.getUserToken()
                    }
                });
            
                $request.then(success);
                $request.fail(failure);
            
                return $request;
              }
            /*
            select2Settings.ajax.headers = {
                Authorization: 'Bearer '+Api.getUserToken()
            }
            */
            if(!select2Settings.ajax.data){
                select2Settings.ajax.data =  (dataParams)  =>{
                    var query = {
                      search: dataParams.term,
                      page_number: dataParams.page ? (dataParams.page) : 1,
                      page_size:Settings.dropdownPageSize
                    }
                    return query;
                }
            }
        }
        dropdownSelctor.select2({
            placeholder: this.params.colDef.placeHolder ? this.params.colDef.placeHolder : Helper.getLabel(null,'please_select','Please Select'),
            allowClear: this.params.colDef.dropdownDisableAllowClear === true ? false : true,
            width: '100%',
            //theme: 'classic',
            //selectOnClose: true,
            //dropdownParent: $("#"+this.uiWraperId).parent(),
            openOnEnter: true,
            ...select2Settings
        })
        $("#"+this.uiWraperId).closest('.ag-cell-value').css({
            overflow:'visible'
        })
        //$(dropdownSelctor).select2("val", this.value)
        
        dropdownSelctor.on('select2:select', function (event) {
            that.value = event.target.value;
            that.input.value = that.value;
            if(that.params.colDef.onChange){
                that.params.colDef.onChange(event,that.params);
            }
        });
        
        dropdownSelctor.on('select2:unselect', function (event) {
            that.value = "";
            that.input.value = that.value;
            if(that.params.colDef.onChange){
                that.params.colDef.onChange(event,that.params);
            }
        });
        
        
    }
    
    
    getGui() {
        return this.uiWraper;
    }
     getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
    }
    updateValue(value){
        this.value = value;
        this.input.value = value;
        $('#'+this.dropdownId).trigger('change');
    }
    destroy(){
        $("#"+this.uiWraperId).closest('.ag-cell-value').css({
            overflow:'hidden'
        })
        //$("#"+this.uiWraperId).select2('destroy');
    }
    
    focusIn(){
        let dropdownSelctor =  $('#'+this.dropdownId);
        dropdownSelctor.select2('focus');
    }
}

export default SimpleDropdown;