const TabsNavItem = (props) => {
    let item = props.item;
    if(item.isDisable){
        return(
            <div className={ 'lynkaz_tab_nav_item disable' }><p>{item.title}</p></div>
        ) 
    }
    return(
        <div id={'nav_'+item.key} data-ps2id-offset={ (props.offset ) } rel="scrolltoid" className={ 'lynkaz_tab_nav_item ' + ( props.disableSticky ? ' sticky_disable ' : '' ) + props.getActiveClass({...item,key:item.key})} onClick={ e => { props.onClickHandler(item) } }><p dangerouslySetInnerHTML={{__html:item.title}} /></div>
    )
}

export default TabsNavItem;