import Helper from "../../../../inc/Helper";
import MasterCellEditor from "./MasterCellEditor";
/**
 * positive = true|false
 * maxValue = function
 */
class NumberCellEditor extends MasterCellEditor {
    init(params) {
        this.value = params.value;
        this.params = params;
        this.uiWraper = document.createElement('div');
        this.addClass(this.uiWraper,'rs_number_cell_editor')
        this.input = document.createElement('input');
        this.uiWraper.appendChild(this.input)
        this.input.id = params.colDef.id ? params.colDef.id : params.colDef.field;
        this.input.type = 'number';
        // if (params.colDef.defaultValue !== undefined) {
        //     console.log("in")
        //     this.input.value = params.colDef.defaultValue;
        // } else {
        //     this.input.value = params.value !== undefined ? params.value : '';
        // }
        if(params.colDef.maxValue){
            if(typeof  params.colDef.maxValue =='function'){
                this.input.max = params.colDef.maxValue(this.params.value,this.params,this.input,null);
            }else{
                this.input.max = params.colDef.maxValue;
            }
        }
        if(params.colDef.defaultValue && params?.data?.isNew){
            if(typeof  params.colDef.defaultValue =='function'){
                this.value = params.colDef.defaultValue(this.params.value,this.params,this.input,null);
            }else{
                this.value = params.colDef.defaultValue;
            }
        }
        this.input.value = this.value;
        this.addClass(this.input,'form-control rs_number_input')
        if(!params.colDef.specialCharAllowed){
            this.input.addEventListener('keydown', (event) => {
                if (event.key === '+' || event.key === '-') {
                    event.preventDefault();
                }
            });
        }
        this.input.addEventListener('input', (event) => {
            if(this.params.colDef.positive){
                if(Helper.getOnlyNumber(event.target.value) <0){
                    this.value = 0;
                    this.input.value = 0;
                    return;
                }
            }
            if(this.params.colDef.maxValue){
                let maxValueCalculated = typeof  this.params.colDef.maxValue =='function' ?  this.params.colDef.maxValue(event.target.value,this.params,this.input,event) : this.params.colDef.maxValue;
                if(Helper.getOnlyNumber(maxValueCalculated) <= Helper.getOnlyNumber(event.target.value) ){
                    this.value = maxValueCalculated;
                    this.input.value = maxValueCalculated;
                }else{
                    this.value = event.target.value;
                }
            }else{
                this.value = event.target.value;
            }
            if(this.params.colDef.minValue){
                let minVlaueCalculate = typeof  this.params.colDef.minValue =='function' ?  this.params.colDef.minValue(event.target.value,this.params,this.input,event) : this.params.colDef.minValue;
                if(Helper.getOnlyNumber(minVlaueCalculate) >= Helper.getOnlyNumber(event.target.value) ){
                    this.value = minVlaueCalculate;
                    this.input.value = minVlaueCalculate;
                }
            }
            if(this.params.colDef.onChange){
                this.params.colDef.onChange(event,this.params);
            }
        });
    }
    updateValue(value){
        this.value = value;
        this.input.value = value;
    }
    addClass(selector,className){
        const att = document.createAttribute("class");
        att.value = className;
        selector.setAttributeNode(att);
    }
 
    /* Component Editor Lifecycle methods */
    // gets called once when grid ready to insert the element
    getGui() {
        return this.uiWraper;
    }
 
    // the final value to send to the grid, on completion of editing
    getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
    }
 
 
    // after this component has been created and inserted into the grid
    afterGuiAttached() {
        if(this.params.colDef.autoFocus){
            this.input.focus();
        }
    }
    focusIn(){
        this.input.focus();
        this.input.select()
    }
 }
 export default NumberCellEditor;