import React,{Fragment, useState} from 'react'
import { connect } from 'react-redux'
import NavigationHeder from '../../Navigations/NavigationHeder'
import MasterComonent from '../../Backend/MasterComonent'
import EmployeeHeader from '../EmployeeHeader'
import EmployeeInformation from './EmployeeInformation/EmployeeInformation'
import DashboardLastRowCharts from './EmployeeInfoCharts/DashboardLastRow/DashboardLastRowCharts'
import EmployeeTasksInfo from './EmployeeInfoCharts/EmployeeTasksInfo/EmployeeTasksInfo'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'

const EmployeeDashboardScreen = (props) => {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  let security = props?.security;

  if (!security.canView(Gui_id_list.reports.employee_task_performance.employee_task_performance_main)) {
    return (
      <Fragment>
        <MasterComonent>
          <Alert 
            message="You do not have any access to the following screen, please contact your administrator!" 
            type="danger" 
          />
        </MasterComonent>
      </Fragment>
    );
  }

  return (
    <div className="employee-dashboard-screen">
      <MasterComonent>
        <div className="container-fluid">
          <NavigationHeder title={`Employee Task Performance`}></NavigationHeder>
          <EmployeeHeader
            profilePhoto=""
            language={props.language}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
          <EmployeeInformation />
          <EmployeeTasksInfo />
          <DashboardLastRowCharts />
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}
 
const SecurityOptions = {
  gui_id:Gui_id_list.reports.employee_task_performance.employee_task_performance_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(EmployeeDashboardScreen, SecurityOptions))));