import React, { Component } from "react";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import Button from "../../inc/Button";
import Collapse from "../../inc/Collapse";
import MasterCategoryList from "../../CategoryMaster/MasterCategoryList";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";

class ProcurementCategory extends Component {
  constructor(props) {
    super(props);
    this.state= {
      isLoading:false,
      isMaster: false,
      data: {},
      selectedItems: [],
      postedItems:[]
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
    if(this.props.data.category.length===0){
      this.props.updateStep(5,false)
    }   
    Helper.makeSticky('master_category_list_child',{topSpace:150,containerId:'lynkaz_tab_contents',zIndex:10,upateWithClick:'.rs_treeview_controller'})
  }
 
  loadCategoryById(categoryId) {
    let api = Api;
    api.setUserToken();
    let that = this;
    that.setState({
      category: {},
    });
    api
      .axios()
      .get(Settings.apiUrl + "/categoryhierarchy/" + categoryId)
      .then((res) => {
        that.setState({
          isCategoryLoading: false,
          category: res.data.data,
        });
      });
  }

  CategoryHandler(vendor_id, vendorcategory_id) {
    let api = Api;
    api.setUserToken();
    if (vendorcategory_id) {
      api.axios()
        .delete(Settings.apiVendorUrl + `/vendor_category/${vendorcategory_id}`, {})
        .then((res) => {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message);
            this.props.loadVendorDetails()    
          }
        })
        .catch((err) => {
          getPopupMessageBasedOnStatus(err);
        });
    } else {
      this.setState(prevState => {
        const updatedSelectedItems = prevState.selectedItems.filter(item => item.category_id !== vendor_id);
        return { selectedItems: updatedSelectedItems };
      });      
    }
  }
  
  
  onTreeItemClick(itemData) {
    const { selectedItems } = this.state;
    let data = this.props.data
    if(data?.document_status!=="Draft"){
      Helper.alert("Due to your profile being under review, the addition of new categories are unavailable.","error")
      return
    }
    let newStateDate = {
      selectedTreeItem: itemData,
      isNewForm: false,
      selectedCategory: itemData.category,
      isMaster: itemData.isMaster,
      masterCategory: itemData.masterCategory,
    };
  
    if (itemData.category) {
      this.loadCategoryById(itemData.category.category_id);
    } else {
      newStateDate.category = {
        ...itemData.masterCategory,
        is_active: itemData.masterCategory.isactive,
      };
    }
    let newItem = {
      category_id: null,
      vendor_id: this.state.data.vendor_id,
      title: null,
      vendorcategory_id:null,
    };

    if (itemData.category) {
      newItem.category_id = itemData.category.category_id;
      newItem.title = itemData.category.name;
      newItem.vendorcategory_id=itemData?.vendorcategory_id || null
    
    const itemExists = selectedItems.find(
      (item) =>
        (item.category_id && item.category_id === newItem.category_id) ||
        (!item.category_id && item.title === newItem.title)
    );

    if (!itemExists) {
      const updatedSelectedItems = [...selectedItems, newItem];
      this.setState(
        {
          selectedItems: updatedSelectedItems,
          ...newStateDate,
        },
        () => {
          Helper.refreshAppScript();
            if (updatedSelectedItems.length !== 0) {
              this.saveSelectedCategories();
            }
        }
      );
    } 
  }
  }
  async saveSelectedCategories(stepNumber) {
    let api = Api;
    api.setUserToken();
    let existingVendorCategoryIds = this.props.data.category.map(item => item.vendorcategory_id);
    let that = this
    let selectedItemsCopy = [...that.state.selectedItems];
    let postedItemsCopy = [...that.state.postedItems];
  
    for (let element of selectedItemsCopy) {
      if (element.category_id) {
        if (existingVendorCategoryIds.includes(element.category_id)) {
          continue;
        }
  
        let requestData = {
          category_id: element.category_id,
          vendor_id: element.vendor_id,
          processinstance_id: null,
          relevance: null
        };
  
        try {
          const response = await api.axios().post(Settings.apiVendorUrl + "/vendorcategory", requestData);
          const responseData = response.data;
          const vendorcategoryId = responseData.id;
  
          const postedItemIndex = selectedItemsCopy.findIndex(item => item.category_id === element.category_id);
          if (postedItemIndex !== -1) {
            selectedItemsCopy.splice(postedItemIndex, 1);
          }
  
          postedItemsCopy.push({
            ...element,
            vendorcategory_id: vendorcategoryId
          });
  
          Helper.alert(response.data.message, "success");
          let dataNew = {
            ...that.state.data,
            category: [...that.state.data.category, ...postedItemsCopy]
          }
          that.props.setVendorState(dataNew)
          that.props.updateStep(5, true);
        } catch (error) {
          getPopupMessageBasedOnStatus(error);
          that.props.loadVendorDetails();
        }
      }
    }
  
    that.setState({
      selectedItems: selectedItemsCopy,
      postedItems: postedItemsCopy,
    });
  }
  
  goStep(stepNumber) {
    if(this.props.data?.category?.length>0){
      this.props.updateStep(5,true)
    }else{
      this.props.updateStep(5,false)
    }
    this.props.goStep(stepNumber);
  }

  render() {
    let activeStep = this.props.activeStep;
    let combinedItems = [
      ...this.props?.data?.category || [],
      // ...this.state.postedItems
  ];

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <Collapse title="Procurement Categories" open={true} scrollOffset={500}>
              <div className="master_category_selector">
                <MasterCategoryList
                  onItemClick={this.onTreeItemClick.bind(this)}
                />
              </div>
            </Collapse>
          </div>
          <div className="col-md-4">
            <Collapse title="Selected Categories" open={true}>
              <div className="selected_master_category">
              <div className='display_vendor_categories mt-3'>  
            {combinedItems.map((item) => { 
              return (
                <div key={item.category_id} className="chips-list-page mb-1 mt-1 ms-2">
                  <span
                     className={`mt-5`}>{item.name || item.title}</span>
                  {<button className='ms-2'
                  onClick={() => this.CategoryHandler(item?.category_id || null,item?.vendorcategory_id || null)}

                  >x</button> }
                </div>
              )
            })}
          </div>
                
              </div>
            </Collapse>
          </div>
        </div>
        <div className="tabs_changer">
          {activeStep >= 2 ? (
            <Button
              className="light_gray btn_back"
              title="Back"
              onClick={(e) => this.goStep(activeStep - 1)}
            />
          ) : (
            ""
          )}
          <Button
            className="blue"
            title="Next"
            onClick={(e) => this.goStep(activeStep + 1)}
          />
        </div>
      </div>
    );
  }
}

export default ProcurementCategory;