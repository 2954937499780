import React, { Component } from 'react';

class Pagination extends Component {
    constructor(props){
        super(props);
        this.state = {
            agGrid: this.getCurrentAgObject(),
            totalPages: 1,
            currentPage: 1,
        }
    }
  componentDidMount() {
    this.props.pagination === true
      ? this.updatePaginationData()
      : this.setState({
          agGrid: this.getCurrentAgObject(),
        })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getAgObject !== this.props.getAgObject) {
      this.updatePaginationData()
    }
    if (prevProps.pageSize !== this.props.pageSize) {
      this.setState({ currentPage: 1 })
    }
  }

  afterChangeHander() {
    this.setState({
      agGrid: this.getCurrentAgObject(),
    })
  }
  //     // goFirstPage(e){
  //     //     this.state.agGrid.api.paginationGoToFirstPage();
  //     //     this.afterChangeHander();
  //     // }
  //     // goLastPage(e){
  //     //     this.state.agGrid.api.paginationGoToLastPage();
  //     //     this.afterChangeHander();
  //     // }
  goNextPage(e){
    this.state.agGrid.api.paginationGoToNextPage()
    this.afterChangeHander();
  }
  goPrevPage(e) {
    this.state.agGrid.api.paginationGoToPreviousPage()
    this.afterChangeHander()
  }
  goPageByNumber(e, pageNumber) {
    this.state.agGrid.api.paginationGoToPage(--pageNumber)
    this.afterChangeHander()
  }
  goNextGroup(e) {
    const { currentPage, totalPages } = this.state
    const groupSize = 5
    const currentGroup = Math.ceil(currentPage / groupSize)
    const nextPage = currentGroup * groupSize + 1
    if (nextPage <= totalPages) {
      this.goToPage(nextPage)
    }
  }

  goPrevGroup(e) {
    const { currentPage } = this.state
    const groupSize = 5
    const currentGroup = Math.ceil(currentPage / groupSize)
    const prevPage = (currentGroup - 2) * groupSize + 1
    if (prevPage >= 1) {
      this.goToPage(prevPage)
    }
  }

  goToPage(pageNumber) {
    this.setState(
      this.props.pagination === true && { currentPage: pageNumber },
      () => {
        this.state.agGrid.api.paginationGoToPage(pageNumber - 1)
        this.props.pagination && this.props.onPageChange(pageNumber)
      }
    )
  }

  getCurrentAgObject() {
    let agGrid = this.props.getAgObject()
    return agGrid
  }

  displayPaginationNumbers(totalPages) {
    let agGrid = this.state.agGrid
    let tempAr = []
    let currentPage = agGrid.api.paginationGetCurrentPage() + 1
    for(let i = 1; i <= totalPages; i++) {
      tempAr.push(
        <li key={i} className={currentPage === i ? 'page-item active' : 'page-item'} onClick={(e) => this.goPageByNumber(e, i)}> <span className='page-link'>{i}</span> </li>
      )
    }
    return tempAr
  }

  updatePaginationData() {
    const agGrid = this.props.getAgObject()
    if (agGrid?.api) {
      const totalRecords = this.props.totalRecords
      const pageSize = this.props.pagination
        ? agGrid.paginationPageSize
        : agGrid.api.paginationGetPageSize()
      const totalPages = Math.ceil(totalRecords / pageSize)
      this.setState({
        agGrid,
        totalPages,
      })
    }
  }

  renderPaginationNumbers(totalPages, currentPage) {
    const pageNumbers = []
    const groupSize = 5
    const currentGroup = Math.ceil(currentPage / groupSize) 

    let startPage = totalPages===0?1:(currentGroup - 1) * groupSize + 1
    const endPage = totalPages===0?1:Math.min(totalPages, currentGroup * groupSize)

    if (startPage > totalPages) {
      startPage = totalPages - groupSize + 1
    }

    if(startPage<=0){
        startPage=1
    }

    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={currentPage === i ? 'page-item active' : 'page-item'} onClick={() => this.goToPage(i)}> <span className='page-link'>{i}</span> </li>  )
    }
    return pageNumbers
  }

  render() {
    let iconArrowLeft = '/images/icons/angle-left.svg'
    let iconArrowRight = '/images/icons/angle-right.svg'
    let agGrid = this.state.agGrid
    if (!agGrid?.api) {
      return <></>
    }
    const totalPages = this.props.pagination === Boolean(true) ? this.state.totalPages : agGrid.api.paginationGetTotalPages() || 1
    const { currentPage } = this.state

    return (
      <nav className='d-flex align-items-center rs_grid_pagination'>
        <p className='rs_grid_page_status'>Showing {this.props.pagination === true ? totalPages === 0 ? 0 : currentPage : agGrid.api.paginationGetCurrentPage() + 1} to {totalPages} of {this.props.pagination ? this.props.totalRecords : this.props.data.length} entries</p>
        <ul className='pagination'>
          {this.props.pagination ? (
            <li className='page-item' onClick={() => this.goPrevGroup()}><span className='page-link nex_prev'><img src={iconArrowLeft} alt='First' /><img src={iconArrowLeft} style={{marginLeft:"-10px"}} alt='First' /></span> </li> ) : null}
            <li className='page-item' onClick={() =>this.props.pagination ? this.goToPage(currentPage === 1 ? 1 : currentPage - 1) : this.goPrevPage()}><span className='page-link nex_prev'><img src={iconArrowLeft} alt='First' /></span></li>
          {this.props.pagination ? this.renderPaginationNumbers(totalPages, currentPage): this.displayPaginationNumbers(totalPages)}
          <li className='page-item' onClick={() =>  this.props.pagination ? this.goToPage(currentPage === totalPages ? totalPages : currentPage + 1 ) : this.goNextPage() } > <span className='page-link nex_prev'><img src={iconArrowRight} alt='Last' /></span></li>
          {this.props.pagination ? (<li className='page-item' onClick={() => this.goNextGroup()}><span className='page-link nex_prev'><img src={iconArrowRight} alt='Last' /><img src={iconArrowRight} style={{marginLeft:"-10px"}} alt='Last' /></span> </li>) : null}
        </ul>
      </nav>
    )
  }
}

export default Pagination
