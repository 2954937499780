import React from 'react'
import { Bar } from 'react-chartjs-2'

//data for Bar chart
export const data = {
  labels: ['PR', 'RFQ', 'Contract', 'PO', 'RFI'],
  datasets: [
    {
      data: [40, 60, 40, 80, 80],
      borderColor: '#00AB55',
      backgroundColor: '#00AB55',
      borderWidth: 1,
      borderRadius: {
        topLeft: 0,
        topRight: 5,
        bottomLeft: 0,
        bottomRight: 5,
      },
    },
  ],
}

const TaskTurnAroundChart = () => {
  //options for Bar Chart
  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    barPercentage: 0.2,
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  }
  return (
    <div className="chart-outer-div">
      <h5 className="fw-bold">Task turn around time (avg).</h5>
        <Bar data={data} options={options} />
    </div>
  )
}

export default TaskTurnAroundChart
