import React from 'react';
import RFQList from '../../components/External Screens/RFQ Vendor Reply/RFQList/RFQList';

const AuctionScreen = () => {
  return (
    <RFQList type="auction" />
  );
}

export default AuctionScreen;
