import React, {useState, useEffect, Fragment, useRef} from 'react'
import { connect } from 'react-redux';
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { useParams } from 'react-router-dom';
import Button from '../../inc/Button';
import $ from 'jquery'
import LinkBtn from '../../inc/LinkBtn';
import NavigationHeder from '../../Navigations/NavigationHeder';
import '../BankAccounts.css'
import Helper from '../../../inc/Helper';
import ContactAddress from '../../Tenant/Address/ContactAddress';
import Api from '../../../inc/Api';
import Collapse from '../../inc/Collapse';
import Settings from '../../../inc/Settings';
import AddressList from '../../Tenant/Address/AddressList';
import SimpleLoading from '../../Loading/SimpleLoading'; 
import FormValidator from '../../../inc/FormValidator';
import { Alert } from 'react-bootstrap';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import { areObjectsDifferent, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';


// Banks Accounts (EDIT) - SYSTEM - LYNKAZ
let defaultBankAccObj = null

const EditBanksAccounts = (props) => {
  const [editAccount, setEditAccount] = useState({})
  const [bankList, setBankList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [loading, setLoading] = useState(true)
  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);
  const {id} = useParams()
 
  let banksListCalled = false
  let currencyListCalled = false
  let banksAccCalled = false; 
 
  useEffect(() => {
    getBankAccountByID()
    get_banks_dropdown_list()
    get_currency_dropdown_list()
  }, [id])

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  let validator; 

  const validationConfigure = () => {
    let fieldConfig = [  
      { name:'bank_id', displayName:Helper.getLabel(props.language,'bank','Bank'), types:['Required'] },  
      { name:'name', displayName:Helper.getLabel(props.language,'name','Name'), types:['Required'] },
      { name:'account_number', displayName:Helper.getLabel(props.language,'account number','Account Number'), types:['Required'] },
      { name:'currency_id', displayName:Helper.getLabel(props.language,'currency','Currency'), types:['Required'] },
      { name:'IBAN', displayName:Helper.getLabel(props.language,'iban','IBAN'), types:['Required'] }
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }
  validationConfigure()

  const getBankAccountByID = () => {
    if( banksAccCalled || !id){
      return;
    } 
    banksAccCalled = true;
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+`/bankaccount/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
        defaultBankAccObj = {
        "bank_id": res.data.data.bank_id,
        "account_code": res.data.data.code,
        "name": res.data.data.name,
        "account_number": res.data.data.account_number,
        "SWIFTno": res.data.data.SWIFTNO,
        "IBAN": res.data.data.IBAN,
        "phone": res.data.data.phone,
        "email": res.data.data.email,
        "URL": res.data.data.url,
        "status":true,
        "currency_id":res.data.data.currency_id,
        "contactsource_id":res.data.data.contactsource_id,
        "addresssource_id":res.data.data.addresssource_id,
      }
      setEditAccount({
        "bank_id": res.data.data.bank_id,
        "account_code": res.data.data.code,
        "name": res.data.data.name,
        "account_number": res.data.data.account_number,
        "SWIFTno": res.data.data.SWIFTNO,
        "IBAN": res.data.data.IBAN,
        "phone": res.data.data.phone,
        "email": res.data.data.email,
        "URL": res.data.data.url,
        "status":true,
        "currency_id":res.data.data.currency_id,
        "contactsource_id":res.data.data.contactsource_id,
        "addresssource_id":res.data.data.addresssource_id,
      })
      setLoading(false)
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoading(false)
    })
  }

  const get_banks_dropdown_list = () => {
    if(banksListCalled){
      return;
    }
    let api = Api
    banksListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+`/bank`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.bank_id,
          label: element.name
        })
      })
        setBankList(UPDATED_STATE) 
      }
    })
  }

  const get_currency_dropdown_list = () => {
    if(currencyListCalled){
      return;
    }
    let api = Api
    currencyListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/currency`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.currency_id,
          label: `${element.currency_code} - ${element.currency_name}`
        })
      })
      setCurrencyList(UPDATED_STATE) 
      }
    })
  }
 
  const ContactCreate = (data) => { 
    if(editAccount.contactsource_id !== null){
      return;
    }
    setEditAccount({
      ...editAccount,
      contactsource_id:data.contact_source_id,
    })
  }
 
  const AddressCreate = (data) => {
    if(editAccount.addresssource_id !== null){
      return;
    }
    setEditAccount({
      ...editAccount,
      addresssource_id:data.address_source_id
    })
  }
 
  const BankAccountsModule = () => {
    let textBoxInputSettings = [ 
      { label: Helper.getLabel(props.language,'account_code',"Account code"), maxLength: 20, className: 'col-lg-4', inputType: "text", name: 'account_code', id: 'account_code' },
      { label: Helper.getLabel(props.language,'bank',"Bank"), reRenderRequired: true, placeHolder: 'Select bank', className: 'col-lg-4', inputType: "dropdown", options: bankList, required: true , name:'bank_id', id: 'bank_id' }, 
      { label: Helper.getLabel(props.language,'name',"Name"), inputType: "text", maxLength: 80, className: 'col-lg-4', name:'name', id: 'name', required:true },
      { label: Helper.getLabel(props.language,'account_number',"Account number"), maxLength: 35, className: 'col-lg-4', inputType: "number", name:'account_number', required:true }, 
      { label: Helper.getLabel(props.language,'currency',"Currency"), reRenderRequired: true, placeHolder: 'Select currency', className: 'col-lg-4', inputType: "dropdown", options: currencyList, id: 'currency_id', name:'currency_id', required: true }, 
      { label: Helper.getLabel(props.language,'iban',"IBAN"), required: true, maxLength: 35, inputType: "text", className: 'col-lg-4', id: 'IBAN', name:'IBAN' },  
      { label: Helper.getLabel(props.language,'phone',"Phone"), maxLength: 20, inputType: "text", className: 'col-lg-4', id: 'phone', name:'phone'  },  
      { label: Helper.getLabel(props.language,'SWFTno',"Swift no"), inputType: "text", className: 'col-lg-4', id: 'SWIFTno', name:'SWIFTno' },
      { label: Helper.getLabel(props.language,'email',"Email"), inputType: "text", className: 'col-lg-4', id: 'email', name:'email' }, 
      { label: Helper.getLabel(props.language,'url',"URL"),  inputType: "text", className: 'col-lg-4', id: 'URL', name:'URL' }
    ];

    return (
      <Collapse open={true} title={Helper.getLabel(props.language,'general',"General")}>
        <div className='row mb-3'>
          <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={setEditAccount} currentState={editAccount} />
        </div>
      </Collapse>
    )
  }

  const ContactListModule = () => {
    return (
      <Collapse open={true} title='Contact'> 
        <div className='edit-bank-account-grid grid_save_btn_hide'>
          <ContactAddress key={loading ? 'render' : 'rerender'} onContactCreate={ContactCreate} contactsource_integrator={id} address_id = {null}  contactsource_id ={editAccount.contactsource_id} source_id = {30}  />
        </div>
      </Collapse>
    )
  }

  const SaveHandler = () => {   
    if(!areObjectsDifferent(defaultBankAccObj, editAccount)){
      return;
    }
 
    if(!validator.isValid({...editAccount})){
      validator.displayMessage();
      validator.reset() 
      return;
    }

    setLoading(true);

    let api = Api
    api.setUserToken() 
    api.axios().put(Settings.apiOrgUrl+`/bankaccount/${id}`, {
      bank_id: editAccount.bank_id ? parseInt(editAccount.bank_id) : null,
      account_code: editAccount.account_code !== "" ? editAccount.account_code : null,
      name: editAccount.name !== "" ? editAccount.name : null,
      account_number: editAccount.account_number !== "" ? editAccount.account_number : null,
      SWIFTno: editAccount.SWIFTno !== "" ? editAccount.SWIFTno : null,
      IBAN: editAccount.IBAN !== "" ? editAccount.IBAN : null,
      phone: editAccount.phone !== "" ? editAccount.phone : null,
      email: editAccount.email !== "" ? editAccount.email : null,
      URL: editAccount.URL !== "" ? editAccount.URL : null,
      status: editAccount.status,
      addresssource_id: editAccount?.addresssource_id,
      contactsource_id: editAccount?.contactsource_id,
      currency_id:editAccount.currency_id ? parseInt(editAccount.currency_id) : null
    }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)  
        getBankAccountByID()
      }
    }).catch((res) => {  
      getPopupMessageBasedOnStatus(res)  
    })  
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.bank_accounts.bank_accounts_edit_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader isPopupInTab={true} /> : null }
      <div className={'container-fluid'}>
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} title={ `<span style="color:#000;">${Helper.getLabel(props.language,'Edit Bank Account',"Edit Bank Account")} #</span>  ${editAccount?.name}` }>
            <LinkBtn isActive= {false} to="/banks-accounts" className='black-btn-style' title={Helper.getLabel(props.language,'close',"Close")} />
            <Button isActive= {false} isDisable={!security.canEdit(frontendIds.setupForms.bank_accounts.bank_accounts_edit_screen_save_button)} onClick ={SaveHandler} title={Helper.getLabel(props.language,'save',"Save")} className="rfq_save_btn" />
          </NavigationHeder>
        </div>
 
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
          {BankAccountsModule()} 
          <AddressList key={loading ? 'render' : 'rerender'} onAddressCreate={AddressCreate} address_source_integrator={id} addressSourc_id = {editAccount?.addresssource_id} source_id ={30}/>
          {ContactListModule()}  
        </div> 
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.bank_accounts.bank_accounts_edit_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditBanksAccounts, SecurityOptions))))