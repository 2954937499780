import React from "react";
import { Bar } from "react-chartjs-2";

const TopVendorsByRankBarGraph = ({ vendorsData }) => {
  const data = {
    labels: vendorsData.map((vendor) => {
      return vendor.vendor_name;
    }),
    datasets: [
      {
        label: "Top vendors by Rank",
        backgroundColor: "#5B93FF",
        borderColor: "#5B93FF",
        data: vendorsData.map((score) => {
          return score.rank;
        }),
      },
    ],
  };

  const options = {
    responsive: true,
    borderRadius: 30,
    barPercentage: 0.3,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax:10, 
        ticks: {
          stepSize: 1, 
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default TopVendorsByRankBarGraph;
