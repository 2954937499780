import React from 'react'
import Dropdown from '../../../../../Forms/Dropdown'

const CommonChartCard = ({ title, children }) => {
  return (
    <div className="chart-outer-div">
      <div className="row" style={{marginBottom:"1.5rem"}}>
        <div className="col-sm-6 col-lg-8">
          <h5 className="fw-bold">{title}</h5>
        </div>
        <div className="col-sm-6 col-lg-4">
          {/* Dropdown Component (Empty Options for now) */}
          <Dropdown options={[]} />
        </div>
      </div>
      {children}
    </div>
  )
}

export default CommonChartCard
