import React from 'react'
import CommonChartCard from './common/CommonChartCard'
import { Doughnut } from 'react-chartjs-2'
import CommonInnerCircle from './common/CommonInnerCircle'

import {Chart,ArcElement} from 'chart.js';

Chart.register(ArcElement)


//data for chart
export const data = {
  labels: ['On Time', 'Escalated', 'KPI Violates'],
  datasets: [
    {
      data: [42, 8, 7],
      backgroundColor: ['#8C62FF', '#2DD4BF', '#FE964A'],
      borderColor: 'white',
      hoverBorderColor: 'white',
      borderWidth: 8,
      borderJoinStyle: 'round',
      borderRadius: 8,
    },
  ],
}

const TaskChart = () => {
  const totalTasks = data.datasets[0].data.reduce((acc, num) => acc + num, 0)

  //options for chart
  const options = {
    cutout: "75%",
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        direction: 'column',
        fullWidth: false,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: 'circle',
          pointRadius: 3,
          padding: 20,
        },
        align: 'start',
      },
    },
  }

  return (
    <div style={{ position: 'relative' }}>
      <CommonChartCard title="My tasks">
        <Doughnut data={data} options={options}></Doughnut>
        <CommonInnerCircle totalTasks={totalTasks} />
      </CommonChartCard>
    </div>
  )
}

export default TaskChart
