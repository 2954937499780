import React, { Component } from "react";
import ProductDetail from "../../components/PurchaseRequisition/ProductDetail";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import RsWithRouter from "../../components/inc/RsWithRouter";

class ProductDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.getBackUrl = this.getBackUrl.bind(this);
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  getSearchParam(name) {
    const params = new URLSearchParams(this.props.rs_router.location.search);
    return params.get(name);
  }

  getBackUrl() { 
    const origin = this.getSearchParam("return");
    if (origin === "rfq") {
      return "/request-for-quotation/allrequisitions";
    } else {
      return "/purchase-requisition/shop";
    }
  }
// this.getBackUrl()
  render() {
    return (
      <ShopLayout
        backUrl={-1}
        title="Shop > Product detail page"
        hideMoreBtn={true}
        prId={this.getSearchParam('pr_view_id')}
        hideNextBtn={true}
        hideBackBtn={true}
      >
        <ProductDetail />
      </ShopLayout>
    );
  }
}

export default RsWithRouter(ProductDetailScreen);
