import React, { Component } from 'react';
import Collapse from '../components/inc/Collapse';
import SegmentsDistribution from '../components/Segments/SegmentsDistribution';
import BlueCallToAction from '../components/Widgets/BlueCallToAction/BlueCallToAction';
import Tabs from '../components/Widgets/Tabs/Tabs';

class DemoPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            view:'header'
        }
    }
    headerView(){
        return(
            <>
                <div>Addional Information</div>
                <div>Segment distributions</div>
            </>
        )
    }
    listView(){
        return(
            <div>
                List View
            </div>
        )
    }
    switchView(view){
        this.setState({
            view:view
        })
    }
    tabTopsComponents(){
        return(
            <>
            <BlueCallToAction>dfadfdsf</BlueCallToAction>
                <div onClick={ e => { this.switchView('header') }} >Heder</div>
                <div onClick={ e => { this.switchView('list') }}>List View</div>
            </>
        )
        
    }
    render() {
        let view = this.state.view;
        let navs = [
            {title:'Addional Information', isActive: true},
            {title:'Segment distributions'},
        ];
        return (
            <div className={ 'container '+ ( view ==='header' ? 'header_view' : 'list_view') }>
                <Tabs addComponentTop={ this.tabTopsComponents.bind(this)}  navs={navs} showAll={true}>
                    {view=="header" ? <div>Tab 1 header view</div> :<div>List view</div>}
                    {view=="header" ? <div>Tab 1 header view</div> :<div></div>}
                </Tabs>
                
            </div>
        );
    }
}

export default DemoPage;