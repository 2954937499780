import React from 'react' 
import '../../Purchase Order/ExtPO.css'
import InvoiceList from './InvoiceList'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../../inc/Gui_id_list'

const InvoiceListWrapper = (props) => { 
  return ( <InvoiceList security={props?.security} /> )
}

const SecurityOptions = {
    gui_id: Gui_id_list.finance.invoices.invoices_main
};
 
export default (((ApplySecurityRoles(InvoiceListWrapper, SecurityOptions))));