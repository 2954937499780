import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles' 
import Gui_id_list from '../../inc/Gui_id_list'
import Alert from '../../components/inc/Alert'

const SetupScreen = ({ security }) => {

    if(!security?.canView(Gui_id_list.screens.setup_screen.main_setup_screen_view)){ 
        return ( 
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        )
    };

    const canViewLegalEntites = security?.canView(Gui_id_list.screens.setup_screen.main_setup_screen_entites);
    const canViewOrganizationTypes = security?.canView(Gui_id_list.screens.setup_screen.main_setup_screen_org_type);
    const canViewOrganizationUnit = security?.canView(Gui_id_list.screens.setup_screen.main_setup_screen_org_unit); 
   
    const canViewCurrencyConversion = security?.canView(Gui_id_list.screens.setup_screen.main_setup_screen_currency_conversion); 
   
    const canViewSites = security?.canView(Gui_id_list.screens.setup_screen.main_setup_sites);
    const canViewWarehouses = security?.canView(Gui_id_list.screens.setup_screen.main_setup_warehouse); 
   
    const canViewSegments = security?.canView(Gui_id_list.screens.setup_screen.main_setup_segments); 
   
    const canViewUnits = security?.canView(Gui_id_list.screens.setup_screen.main_setup_unit);
    const canViewUnitsConversion = security?.canView(Gui_id_list.screens.setup_screen.main_setup_unit_conversion); 
  
    const canViewFiscalCalendars = security?.canView(Gui_id_list.screens.setup_screen.main_setup_fiscal_calendars); 

    const canViewNumberSequence = security?.canView(Gui_id_list.screens.setup_screen.main_setup_number_sequence); 

    const canViewCategories = security?.canView(Gui_id_list.screens.setup_screen.main_setup_categories); 

    const canViewProcurementSettings = security?.canView(Gui_id_list.screens.setup_screen.main_setup_procurement_settings); 
   
    const canViewBidCount = security?.canView(Gui_id_list.screens.setup_screen.main_setup_bid_policy_count);
    const canViewBidRecall = security?.canView(Gui_id_list.screens.setup_screen.main_setup_bid_policy_recall);
    const canViewNonCompliancePolicy = security?.canView(Gui_id_list.screens.setup_screen.main_setup_compliance_policy); 
    const canViewPRRequestPolicy = security?.canView(Gui_id_list.screens.setup_screen.main_setup_pr_request_policy); 
   
    const canViewItemsGroup = security?.canView(Gui_id_list.screens.setup_screen.main_setup_item_group);
    const canViewItemsGroupAccount = security?.canView(Gui_id_list.screens.setup_screen.main_setup_item_group_account);
    const canViewItemsGroupAccountType = security?.canView(Gui_id_list.screens.setup_screen.main_setup_item_group_account_type); 
    const canViewItemsDiscountType = security?.canView(Gui_id_list.screens.setup_screen.main_setup_item_discount_type); 
    const canViewItemsBuyerGroup = security?.canView(Gui_id_list.screens.setup_screen.main_setup_item_buyer_group); 

    const canViewAttachmentTypes = security?.canView(Gui_id_list.screens.setup_screen.main_setup_attachment_types); 
   
    const canViewEmailTemplates = security?.canView(Gui_id_list.screens.setup_screen.main_setup_email_templates);
    const canViewEmailNotifications = security?.canView(Gui_id_list.screens.setup_screen.main_setup_email_notifications); 

    const canViewPaymentTerms = security?.canView(Gui_id_list.screens.setup_screen.main_setup_payment_terms);
   
    const canViewBanks = security?.canView(Gui_id_list.screens.setup_screen.main_setup_banks);
    const canViewBankAccounts = security?.canView(Gui_id_list.screens.setup_screen.main_setup_banks_accounts);

    const canViewUserAll = security?.canView(Gui_id_list.screens.setup_screen.main_setup_users_all);
    const canViewUserExternal = security?.canView(Gui_id_list.screens.setup_screen.main_setup_users_external);
   
    const canViewTaxAuthority = security?.canView(Gui_id_list.screens.setup_screen.main_setup_tax_authority);
    const canViewTaxSettlement = security?.canView(Gui_id_list.screens.setup_screen.main_setup_tax_settlement);
    const canViewTaxPostingGroup = security?.canView(Gui_id_list.screens.setup_screen.main_setup_tax_posting_groups); 
    const canViewTaxCodes = security?.canView(Gui_id_list.screens.setup_screen.main_setup_tax_codes); 
    const canViewTaxGroup = security?.canView(Gui_id_list.screens.setup_screen.main_setup_tax_group); 
    const canViewTaxItemGroup = security?.canView(Gui_id_list.screens.setup_screen.main_setup_tax_item_group); 

    const canViewComplianceResponses = security?.canView(Gui_id_list.screens.setup_screen.main_setup_compliance_responses);
   
    const canViewDeliveryTerms = security?.canView(Gui_id_list.screens.setup_screen.main_setup_delivery_terms);
    const canViewModesOfDelivery = security?.canView(Gui_id_list.screens.setup_screen.main_setup_modes_of_delivery);

    const canViewPAClassification = security?.canView(Gui_id_list.screens.setup_screen.main_setup_purchase_agreement_classification);

    return (
        <div className='modules_screen_lynkaz'>
            <MasterComonent>
                <InnerScreen innerTitle='Setup'>
                    {(canViewLegalEntites || canViewOrganizationTypes || canViewOrganizationUnit) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Organization</h2>
                            </div>
                            <div className='inner-page-routes'> 
                                {canViewLegalEntites && <Link to='/entity'>Legal Entities</Link>}
                                {canViewOrganizationTypes && <Link to='/organisation-type'>Organizations Type</Link>}
                                {canViewOrganizationUnit && <Link to='/organisation-unit'>Organizations Unit</Link>}
                            </div>
                        </div>
                    )}
                    {canViewCurrencyConversion && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Currency</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/currency-conversion'>Currency Conversion</Link>
                            </div>
                        </div>
                    )}
                    {(canViewSites || canViewWarehouses) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'> 
                            <div className='inner-page-header border-bottom'>
                                <h2>Inventory</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewSites && <Link to='/sites'>Sites</Link>}
                                {canViewWarehouses && <Link to='/warehouse'>Warehouses</Link>} 
                            </div>
                        </div>
                    )}
                    {canViewSegments && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Cost Segments</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/segments'>Segments</Link> 
                            </div>
                        </div>
                    )}
                    {(canViewUnits || canViewUnitsConversion) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Unit of Measurement</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewUnits && <Link to='/unit'>Unit</Link>}
                                {canViewUnitsConversion && <Link to='/unit-conversion'>Unit Conversion</Link>}
                            </div>
                        </div>
                    )}
                    {canViewFiscalCalendars && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Fiscal Calendars</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/fiscal-year'>Fiscal Calendars</Link> 
                            </div>
                        </div>
                    )}
                    {canViewNumberSequence && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Number Sequence</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/number-sequence'>Number Sequence</Link>
                            </div>
                        </div>
                    )}
                    {canViewCategories && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Categories</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/category'>Categories</Link> 
                            </div>
                        </div>
                    )}
                    {canViewProcurementSettings && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Procurement Settings</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/procurement-settings'>Procurement Settings</Link>  
                            </div>
                        </div>
                    )}
                    {(canViewBidCount || canViewBidRecall || canViewNonCompliancePolicy || canViewPRRequestPolicy) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Request for Quotations</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewBidCount && <Link to='/bidding-count-listings'>Bid Count Policy</Link>}
                                {canViewBidRecall && <Link to='/bidding-count-recall-listings'>Bid Recall Policy</Link>} 
                                {canViewNonCompliancePolicy && <Link to='/non-compliance-policies'>Non-Compliance Policy</Link>}
                                {canViewPRRequestPolicy && <Link to='/pr-request-policies'>PR Request Policy</Link>}
                            </div>
                        </div>
                    )} 
                    {(canViewItemsGroup || canViewItemsGroupAccount || canViewItemsGroupAccountType || canViewItemsDiscountType || canViewItemsBuyerGroup) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Item</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewItemsGroup && <Link to='/item-group'>Item Group</Link> }
                                {canViewItemsGroupAccount && <Link to='/item-group-account'>Item Group - Account</Link>} 
                                {canViewItemsGroupAccountType && <Link to='/item-group-account-type'>Item Group - Account Type</Link>} 
                                {canViewItemsDiscountType && <Link to='/item-discount-type'>Item - Discount Type</Link>} 
                                {canViewItemsBuyerGroup && <Link to='/item-buyer-group'>Item - Buyer Group</Link>}
                            </div>
                        </div>
                    )}
                    {(canViewAttachmentTypes) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Attachment</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewAttachmentTypes && <Link to='/attachment-types'>Attachment Types</Link>}
                            </div>
                        </div>
                    )}
                    {(canViewEmailTemplates || canViewEmailNotifications) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Email</h2>
                            </div>
                            <div className='inner-page-routes'> 
                                {canViewEmailTemplates && <Link to='/email-templates'>Email Templates</Link>} 
                                {canViewEmailNotifications && <Link to='/notification-events'>Notifications</Link>}
                            </div>
                        </div>
                    )}
                    {canViewPaymentTerms && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Payment Terms</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/payment-terms'>Payment Terms</Link> 
                            </div>
                        </div>
                    )}
                    {(canViewBanks || canViewBankAccounts) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Banks</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewBanks && <Link to='/banks'>Banks</Link>} 
                                {canViewBankAccounts && <Link to='/banks-accounts'>Bank Accounts</Link>}
                            </div>
                        </div>
                    )}
                    {(canViewUserAll || canViewUserExternal) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Users</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewUserAll && <Link to='/users'>All Users</Link>} 
                                {canViewUserExternal && <Link to='/users?user_type=1'>External Users</Link>}
                            </div>
                        </div>
                    )}
                    {(canViewTaxAuthority || canViewTaxSettlement || canViewTaxPostingGroup || canViewTaxCodes || canViewTaxGroup || canViewTaxItemGroup) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Tax</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewTaxAuthority && <Link to='/tax/authority'>Tax Authority</Link> }
                                {canViewTaxSettlement && <Link to='/tax/settlement-periods'>Tax Settlement</Link>} 
                                {canViewTaxPostingGroup && <Link to='/tax/posting-group'>Tax Posting Groups</Link>} 
                                {canViewTaxCodes && <Link to='/tax/codes'>Tax Codes</Link>} 
                                {canViewTaxGroup && <Link to='/tax/group'>Tax Group</Link>} 
                                {canViewTaxItemGroup && <Link to='/tax/tax-group-with-items'>Tax Item Group</Link>}
                            </div>
                        </div>
                    )}
                    {canViewComplianceResponses && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Compliance Responses</h2>
                            </div>
                            <div className='inner-page-routes'>
                                <Link to='/compliances-responses/listings'>Compliance Responses</Link> 
                            </div>
                        </div>
                    )}
                    {(canViewDeliveryTerms || canViewModesOfDelivery) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Delivery</h2>
                            </div>
                            <div className='inner-page-routes'>
                                {canViewDeliveryTerms && <Link to='/delivery-terms'>Delivery Terms</Link>} 
                                {canViewModesOfDelivery && <Link to='/modes-of-delivery'>Modes of Delivery</Link>}
                            </div>
                        </div>
                    )}
                    {(canViewPAClassification) && (
                        <div className='col-lg-3 col-md-4 col-6 gy-4'>
                            <div className='inner-page-header border-bottom'>
                                <h2>Purchase Agreement Classifications</h2>
                            </div>
                            <div className='inner-page-routes'> 
                                <Link to='/purchase-agreement-classifications'>Purchase Agreement Classification</Link>
                            </div>
                        </div>
                    )}
                </InnerScreen>
            </MasterComonent>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
};

const SecurityOptions = {
    gui_id: Gui_id_list.screens.setup_screen.main_setup_screen_view
};
   
export default connect (mapStateToProps) ( ApplySecurityRoles( SetupScreen, SecurityOptions));