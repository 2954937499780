import React, { useRef } from 'react'

const TabContentWraper = (props) => {
    const ref = useRef(null); 

    const handleScroll = (e) => {
        const scrollTop = ref.current.scrollTop;  
        
        if (scrollTop > 0) {
            props.setScrollDitection('up')
       }
        else {
            props.setScrollDitection('down')
        }
    }; 

    return (
        <div  className="tabs_wrapper_t mt-3 d-flex justify-content-between" style={{ gap: props?.rightSideContent ? "40px 20px" : "0px" }}>
            {/* left side content */}
            <div
                id="parentDiv"
                className='left_side_detals_section' 
                style={{
                    maxHeight: `${props?.height}px`,
                    paddingRight:"6px"
                }}
                ref={ref}
                onScroll={handleScroll}
            >
                {props?.leftSideContent?.props?.children?.map((item) => {
                    return (
                        item
                    )
                })}
            </div>

            {/* right side content */} 
            <div className='right_side_detals_section'>
                {props?.rightSideContent ? props.rightSideContent() : null}
            </div> 
        </div>
    )
}

export default TabContentWraper