// import React, { Component } from 'react';
// import Collapse from '../../inc/Collapse';
// import Helper from '../../../inc/Helper';
// import AgGrid from '../../grid/ag/ag-grid';
// import Settings from '../../../inc/Settings';
// import Api from '../../../inc/Api';
// import RsDataLoader from '../../inc/RsDataLoader';
// import Button from '../../inc/Button';
// import TeamPopup from './TeamPopup';
// import DatepickerFunctions from '../../../inc/DatepickerHelper';
// /**
//  * source_id
//  * integrator
//  */
// class Team extends Component {
//     constructor(props){
//         super(props);
//         this.teamGrid = null;
//         this.state = {
//             teamList:[],
//             isLoading:false,
//             openTeamPopup:false,
//             gridData:[]
//         }
//     }
//     componentDidMount(){
//         this.loadTeamsData();
//         this.loadTeamList();
//     }
//     loadTeamList(){
//         this.setState({
//             isLoading:true
//         })
//         let api = Api, that = this;
//         api.setUserToken();
//         api.axios().get(Settings.apiUrl + '/teamheader').then(res => {
//             that.setState({
//                 isLoading:false,
//                 teamList: res.data.data
//             })
//         })
//     }
//     loadTeamsData(){
//         let that = this;
//         let api = Api;
//         api.setUserToken();
//         this.setState({
//             isLoading:true
//         })
//         api.axios().get(Settings.apiUrl + '/get_teamsourcelines/'+this.props.source_id+'/'+this.props.integrator).then(res => {
//             that.setState({
//                 isLoading:false,
//                 gridData:res.data.data.reverse()
//             })
//         }).catch(error => {
//             that.setState({
//                 isLoading:false
//             })
//         })
//     }

//     validationCheck(perams){
//         let data = perams.data;
//         let output = true;
//         if( !Helper.getNullableValue(data.employee_id) ){
//             perams.api.startEditingCell({
//                 rowIndex: perams.rowIndex ? perams.rowIndex : 0,
//                 colKey: "employee_id"
//             });
//             Helper.alert(Helper.getLabel(null,'name_field_are_required','Name field are required'),'error')
//             output = false;
//         }
//         else if(  !Helper.getNullableValue(data.from_date) ) {
//             perams.api.startEditingCell({
//                 rowIndex: perams.rowIndex ? perams.rowIndex : 0,
//                 colKey: "from_date"
//             });
//             Helper.alert(Helper.getLabel(null,'start_date_field_are_required','Start date field are required'),'error')
//             output = false;
//         }else if( !Helper.getNullableValue(data.access_level) ){
//             perams.api.startEditingCell({
//                 rowIndex: perams.rowIndex ? perams.rowIndex : 0,
//                 colKey: "access_level"
//             });
//             Helper.alert(Helper.getLabel(null,'access_field_are_required','Access field are required'),'error')
//             output = false;
//         }
//         return output;
//     }
//     onRowValueChanged(perams){
//         let data = perams.data;
//         if(!this.validationCheck(perams)){
//             return;
//         }
//         if(data.teamsourceline_id){
//             this.updateData(data.teamsourceline_id,data)
//         }else{
//             this.createNew([data]);
//         }
//     }
//     createNew(dataArray,afterCreate=null){
//         if(this.teamGrid){
//             this.teamGrid.api.showLoadingOverlay();
//         }
//         let that = this;
//         let dataForDatabse = [];
//         dataArray.forEach(data => {
//             dataForDatabse.push({
//                 teamheader_id: data.teamheader_id ? data.teamheader_id : null,
//                 employee_id: data.employee_id,
//                 from_date: DatepickerFunctions.convertDateForDataBase(data.from_date),
//                 to_date: DatepickerFunctions.convertDateForDataBase(data.to_date),
//                 description: data.description ? data.description : null,
//                 PR_view_access: true,
//                 RFP_view_access: null,
//                 allowed_to_unsealed_technical_proposal: null,
//                 allowed_to_unsealed_commercial_proposal: true,
//                 perform_RFQ_technical_evaluation: null,
//                 perform_RFQ_financial_evaluation: null,
//                 source_id: this.props.source_id,
//                 sourceintegrator_id: this.props.integrator,
//                 access_level: data.access_level
//             })
//         });
//         let api = Api;
//         api.setUserToken();
//         api.axios().post(Settings.apiUrl+'/teamsourcelines',dataForDatabse).then(res => {
//             that.loadTeamsData()
//             that.teamGrid.api.hideOverlay();
//             if(afterCreate){
//                 afterCreate(res)
//             }
//         }).catch(error => {
//             that.teamGrid.api.hideOverlay();
//             if(afterCreate){
//                 afterCreate(error.response)
//             }
//         })
//     }
//     updateData(teamsourceline_id, data){
//         if(this.teamGrid){
//             this.teamGrid.api.showLoadingOverlay();
//         }
//         let that = this;
//         let dataForDatabse = {
//             from_date:DatepickerFunctions.convertDateForDataBase(data.from_date),
//             to_date: DatepickerFunctions.convertDateForDataBase(data.to_date),
//             description: data.description ? data.description : null,
//             PR_view_access: null,
//             RFP_view_access: null,
//             allowed_to_unsealed_technical_proposal: null,
//             allowed_to_unsealed_commercial_proposal: true,
//             perform_RFQ_technical_evaluation: null,
//             perform_RFQ_financial_evaluation: null,
//             access_level: data.access_level
//         }
//         let api = Api;
//         api.setUserToken();
//         api.axios().put(Settings.apiUrl+'/teamsourcelines/'+teamsourceline_id,dataForDatabse).then(res => {
//             that.loadTeamsData()
//             that.teamGrid.api.hideOverlay();
//         }).catch(error => {
//             that.teamGrid.api.hideOverlay();
//         })
//     }
//     onDeleteHandler(data){
//         let totalApiCalls = data.length;
//         this.teamGrid.api.showLoadingOverlay();
//         let api = Api,that = this;
//         api.setUserToken();
//         data.forEach(delItem => {
//             api.axios().delete(Settings.apiUrl+'/teamsourcelines/'+delItem.teamsourceline_id).then(res => {

//                 totalApiCalls--;
//                 if(totalApiCalls<=0){
//                     that.loadTeamsData()
//                     that.teamGrid.api.hideOverlay();
//                 }
//             }).catch(error => {
//                 totalApiCalls--;
//                 if(totalApiCalls<=0){
//                     that.loadTeamsData()
//                     that.teamGrid.api.hideOverlay();
//                 }
//             })
//         });

//     }
//     afterDeleteButtons(){
//         if(this.props.isEditable != true){
//             return <></>
//         }
//         return (
//             <div class="list_view_action_btns_grid">
//                 <button onClick={ e => { this.setState({openTeamPopup:true})}}>
//                     <span class="me-2">
//                         <img src="/images/icons/add-new.svg" class="img-fluid" alt="invite a vendor"/>
//                     </span>
//                     {
//                         Helper.getLabel(null,'add_team','Add team')
//                     }
//                 </button>
//             </div>
//         )
//     }
//     onPopupClose(data){
//         this.setState({
//             openTeamPopup:false
//         })
//     }
//     render() {
//         let language = null;
//         let teams = this.state.teamList.map( item => {
//             return {
//                 value: item.teamheader_id,
//                 label:item.name
//             }
//         })
//         let employeesList =  this.props.dataLoaderProps.employee.all;
//         let accessOptions = this.props.dataLoaderProps.lineAccessLevel.lineAccessLevel.map( item => {
//             return {
//                 label: item.name,
//                 value: item.id+''
//             }
//         })
//         let header = [
//             {
//                 field: "teamheader_id",
//                 headerName: Helper.getLabel(language, "team", "Team"),
//                 editable: false,
//                 options: teams,
//                 minWidth: 200,
//                 inputType: "dropdown",
//               },
//               {
//                 field: "employee_id",
//                 headerName: Helper.getLabel(language, "name", "Name *"),
//                 editable: (prarams) => { return prarams.data.teamsourceline_id ? false : true },
//                 options: employeesList.map((item) => {
//                   return {
//                     value: item.employee_id,
//                     label: item.display_name,
//                   };
//                 }),
//                 minWidth: 200,
//                 inputType: "dropdown",
//               },
//               {
//                 field: "access_level",
//                 isAllowZero:true,
//                 headerName: Helper.getLabel(language, "access", "Access *"),
//                 //editable: true,
//                 options:accessOptions,
//                 minWidth: 200,
//                 inputType: "dropdown",
//               },
//               { field: "from_date", headerName: Helper.getLabel(language, "start_date", "Start Date *"), inputType:'datepicker'},
//               { field: "to_date", headerName: Helper.getLabel(language, "end_date", "End Date"), inputType:'datepicker'},
//               { field: "description", headerName: Helper.getLabel(language, "description", "Description"), },

//         ]
//         return (
//             <div className='team_page' >
//                 { this.state.openTeamPopup ? <TeamPopup createNew = {this.createNew.bind(this)} teamList={this.state.teamList} header = {header} onClose={ this.onPopupClose.bind(this)}/> : '' }
//                 <Collapse className="mt-4" title={Helper.getLabel(null, 'collaboration_team','Collaboration Team')} open={true}>
//                     <AgGrid addBtnDissable={!this.props.isEditable}  readOnlyGrid={this.props.isEditable === true ? false : true } afterDeleteButtons={ this.afterDeleteButtons.bind(this)} onDelete={ this.onDeleteHandler.bind(this)} id="team_grid" hideSave={true} onGridReady={ gridOBj => this.teamGrid = gridOBj } onRowValueChanged = { this.onRowValueChanged.bind(this)} header={ header } data={this.state.gridData} />
//                 </Collapse>
//             </div>
//         );
//     }
// }

// export default RsDataLoader(Team,{lineaccess_level:true,employee:true});

import React, { useState, useEffect, useCallback } from 'react'
import Collapse from '../../inc/Collapse'
import Helper from '../../../inc/Helper'
import AgGrid from '../../grid/ag/ag-grid'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import RsDataLoader from '../../inc/RsDataLoader'
import Button from '../../inc/Button'
import TeamPopup from './TeamPopup'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'
import { getLabelFromDropdown } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import DateEditor from '../../grid/ag/cellEditor/CustomDatePicker'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'

let teamGrid = null

const Team = (props) => {
  const [teamList, setTeamList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [openTeamPopup, setOpenTeamPopup] = useState(false)
  const [gridData, setGridData] = useState([])

  let teams = teamList.map((item) => {
    return {
      value: item.teamheader_id,
      label: item.name,
    }
  })
  let employeesList = props.dataLoaderProps.employee.all
  let accessOptions = props.dataLoaderProps.lineAccessLevel.lineAccessLevel.map((item) => {
    return {
      label: item.name,
      value: item.id + '',
    }
  })

  useEffect(() => {
    loadTeamsData()
    loadTeamList()
  }, [])

  const loadTeamList = () => {
    setIsLoading(true)
    Api.setUserToken()
    Api.axios()
      .get(Settings.apiUrl + '/teamheader')
      .then((res) => {
        setIsLoading(false)
        setTeamList(res.data.data)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const loadTeamsData = () => {
    setIsLoading(true)
    Api.setUserToken()
    Api.axios()
      .get(Settings.apiUrl + '/get_teamsourcelines/' + props.source_id + '/' + props.integrator)
      .then((res) => {
        setIsLoading(false)
        setGridData(res.data.data.reverse())
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const validationCheck = (params) => {
    let data = params.data
    let output = true
    if (!Helper.getNullableValue(data.employee_id)) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex ? params.rowIndex : 0,
        colKey: 'employee_id',
      })
      Helper.alert(Helper.getLabel(null, 'name_field_are_required', 'Name field are required'), 'error')
      output = false
    } else if (!Helper.getNullableValue(data.from_date)) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex ? params.rowIndex : 0,
        colKey: 'from_date',
      })
      Helper.alert(Helper.getLabel(null, 'start_date_field_are_required', 'Start date field are required'), 'error')
      output = false
    } else if (!Helper.getNullableValue(data.access_level)) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex ? params.rowIndex : 0,
        colKey: 'access_level',
      })
      Helper.alert(Helper.getLabel(null, 'access_field_are_required', 'Access field are required'), 'error')
      output = false
    }
    return output
  }

  const onRowValueChanged = (params) => {
    let data = params.data
    if (!validationCheck(params)) {
      return
    }
    if (data.teamsourceline_id) {
      updateData(data.teamsourceline_id, data)
    } else {
      createNew([data])
    }
  }

  const createNew = (dataArray, afterCreate = null) => {
    let dataForDatabase = []
    dataArray.forEach((data) => {
      dataForDatabase.push({
        teamheader_id: data.teamheader_id ? data.teamheader_id : null,
        employee_id: data.employee_id,
        from_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(data.from_date)),
        to_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(data.to_date)),
        description: data.description ? data.description : null,
        PR_view_access: true,
        RFP_view_access: null,
        allowed_to_unsealed_technical_proposal: null,
        allowed_to_unsealed_commercial_proposal: true,
        perform_RFQ_technical_evaluation: null,
        perform_RFQ_financial_evaluation: null,
        source_id: props.source_id,
        sourceintegrator_id: props.integrator,
        access_level: data.access_level,
      })
    })
    let api = Api
    api.setUserToken()
    api
      .axios()
      .post(Settings.apiUrl + '/teamsourcelines', dataForDatabase)
      .then((res) => {
        Helper.alert(res.data.message,"success")
        setTimeout(()=>{
            teamGrid?.api?.refreshServerSide({purge:true})
        },100)
        if(afterCreate){
            afterCreate(res)
        }
        teamGrid?.api?.deselectAll()
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
        if(afterCreate){
          afterCreate(error.response)
        }
      })
  }

  const updateData = (teamsourceline_id, data) => {
    let dataForDatabase = {
      from_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(data.from_date)),
      to_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(data.to_date)),
      description: data.description ? data.description : null,
      PR_view_access: null,
      RFP_view_access: null,
      allowed_to_unsealed_technical_proposal: null,
      allowed_to_unsealed_commercial_proposal: true,
      perform_RFQ_technical_evaluation: null,
      perform_RFQ_financial_evaluation: null,
      access_level: data.access_level,
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(Settings.apiUrl + '/teamsourcelines/' + teamsourceline_id, dataForDatabase)
      .then((res) => {
        Helper.alert(res.data.message,"success")
        setTimeout(()=>{
           teamGrid?.api?.refreshServerSide({purge:true})
         },200)
        teamGrid?.api?.deselectAll()
      })
      .catch((error) => {
       getPopupMessageBasedOnStatus(error)
      })
  }

  const onTeamDeleteHandler = useCallback(async (element) => {
    let api = Api
    api.setUserToken()
    try {
      const res = await api.axios().delete(Settings.apiUrl + '/teamsourcelines/' + element?.data?.teamsourceline_id)
      const rowNode = teamGrid?.api?.getRowNode(element?.data?.teamsourceline_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        teamGrid?.api?.applyServerSideTransaction(transaction)
      }, 200)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const AfterDeleteButtons = () => {
    if (props.isEditable !== true) {
      return <></>
    }
    return (
      <div className='list_view_action_btns_grid'>
        <button
          onClick={(e) => {
            setOpenTeamPopup(true)
          }}
        >
          <span className='me-2'>
            <img src='/images/icons/add-new.svg' className='img-fluid' alt='invite a vendor' />
          </span>
          {Helper.getLabel(null, 'add_team', 'Add team')}
        </button>
      </div>
    )
  }

  const onPopupClose = () => {
    setOpenTeamPopup(false)
  }

  const header = [
    {
      field: 'teamheader_id',
      headerName: Helper.getLabel(null, 'team', 'Team'),
      editable: false,
      cellEditor: SimpleDropdown,
      cellEditorParams: {
        values: teamList.map((item) => {
          return {
            value: item.teamheader_id,
            label: item.name,
          }
        }),
      },
      minWidth: 200,
      valueFormatter: (params) => getLabelFromDropdown(params),
    },
    {
      field: 'employee_id',
      headerName: Helper.getLabel(null, 'name', 'Name'),
      editable: (params) => {
        return !params.data.teamsourceline_id
      },
      cellEditor: SimpleDropdown,
      cellEditorParams: {
        values: employeesList.map((item) => {
          return {
            value: item.employee_id,
            label: item.display_name,
          }
        }),
      },
      minWidth: 200,
      valueFormatter: (params) => getLabelFromDropdown(params),
    },
    {
      field: 'access_level',
      isAllowZero: true,
      editable: props.isEditable,
      headerName: Helper.getLabel(null, 'access', 'Access'),
      cellEditor: SimpleDropdown,
      cellEditorParams: {
        values: accessOptions,
      },
      minWidth: 200,
      valueFormatter: (params) => getLabelFromDropdown(params),
    },
    {
      field: 'from_date',
      editable: props.isEditable,
      headerName: Helper.getLabel(null, 'start_date', 'Start date'),
      cellEditor: DateEditor,
      valueFormatter: (params) => (params.value ? DatepickerFunctions.convertDateFromDataBase(params.value) : '-'),
    },
    { field: 'to_date', cellEditor: DateEditor,headerName: Helper.getLabel(null, 'end_date', 'End date'),valueFormatter: (params) => (params.value ? DatepickerFunctions.convertDateFromDataBase(params.value) : '-'), editable:props.isEditable },
    { field: 'description', headerName: Helper.getLabel(null, 'description', 'Description'), editable: props.isEditable },
  ]

  return (
    <div className='team_page'>
      {openTeamPopup ? <TeamPopup createNew={createNew} teamList={teamList} header={header} onClose={onPopupClose} /> : ''}
      <Collapse className='mt-5' title={Helper.getLabel(null, 'collaboration_team', 'Collaboration Team')} open={true}>
        {/* <AgGrid addBtnDissable={!props.isEditable} readOnlyGrid={props.isEditable === true ? false : true} afterDeleteButtons={afterDeleteButtons} onDelete={onDeleteHandler} id="team_grid" hideSave={true} onGridReady={gridOBj => teamGrid = gridOBj} onRowValueChanged={onRowValueChanged} header={header} data={gridData} /> */}
        <AgGridNew
          gridId={'team_grid'}
          apiUrl={Settings.apiUrl + '/get_teamsourcelines/' + props.source_id + '/' + props.integrator}
          isAddBtnDisable={!props.isEditable}
          isDeleteBtnDisable={!props.isEditable}
          onGridReady={(gridOBj) => (teamGrid = gridOBj)}
          onRowValueChanged={onRowValueChanged}
          handleDeleteSelectedRows={onTeamDeleteHandler}
          columnDefs={header}
          afterDeleteBtns={<AfterDeleteButtons />}
          uniqueField={'teamsourceline_id'}
        />
      </Collapse>
    </div>
  )
}

export default RsDataLoader(Team, { lineaccess_level: true, employee: true })
