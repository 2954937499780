import React, { useEffect, useRef } from 'react'; 
/*
    custom Hook, comprising for useRefs functionality for multiple instances of Sections, 
*/

const useDynamicRefs = (length) => {
    const refsArray = useRef([]);

    useEffect(() => {
        refsArray.current = Array.from({ length }).map(() => ({ ref: React.createRef() }));
    }, [length]);

    return refsArray.current;
};

export default useDynamicRefs;