import React, { Component } from 'react';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import SimpleLoading from '../Loading/SimpleLoading';
import RsWithRouter from '../inc/RsWithRouter';
import Alert from '../inc/Alert';
import Helper from '../../inc/Helper';
const PoTeamAccess = (SecureComponent) =>{
    class UserSecurityCheck extends Component {
        
        constructor(props){
            super(props);
            this.state ={
                isLoaded:false,
                poAccessForUser:{
                }
            }
        }
        componentDidMount(){
            this.loadAccessLevels();
        }
        getSourceId(){
            let extractedData
            const text = window.location?.pathname
            const parts = text.split('/')
            extractedData = `/${parts[1]}/${parts[2]}`
            if(extractedData==="/contract-order/edit"){
                return Settings.source_id.contract
            }
            else if(extractedData==="/invoices-on-account/edit"){
                return Settings.source_id.invoice
            }else if(extractedData==="/credit-notes/edit"){
                return Settings.source_id.credit_notes
            }else{
                return Settings.source_id.purchase_order
            }
        }
        loadAccessLevels(){
            let id = this.props.rs_router.params.id;
            let api = Api;
            api.setUserToken();
            if (
                (Object.values(this.props?.auth?.user?.roles).includes("Super Admin") ||
                   Object.values(this.props?.auth?.user?.roles).includes("Tenant Admin"))
            ) {
                this.setState({
                    isLoaded:true,
                })
            return;
            }
            let that = this;
            api.axios().get(Settings.apiUrl + '/get_current_user_sourceline_access/'+this.getSourceId()+'/'+id).then(res => {
                that.setState({
                    isLoaded:true,
                    poAccessForUser:res.data.data
                })
            }).catch(errro => {
                that.setState({
                    isLoaded:true,
                    poAccessForUser:{access_level:0}
                })
            })
        }
       
        render() {
            if(!this.state.isLoaded){
                return <SimpleLoading />
            }
            let poAccessForTeam = this.state.poAccessForUser;
            if(poAccessForTeam?.access_level == 0){
                return(<div className='mt-4'><Alert type="danger" message={Helper.getLabel(null,'no_access',"No Access")}/></div>)
            }
            
            return <SecureComponent poAccessForTeam={poAccessForTeam}   {...this.props}/>
        }
    }
    return RsWithRouter(UserSecurityCheck);
}

export default PoTeamAccess;