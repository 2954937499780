import React, { Component } from 'react';
import MasterComonent from '../components/Backend/MasterComonent';
import RsWithRouter from '../components/inc/RsWithRouter';
import RollupGroup from '../components/Segments/RollupGroup';
import Segments from '../components/Segments/Segments';
import SegmentValues from '../components/Segments/SegmentValues';
class SegmentsScreen extends Component {
    getPage(){
        let {page,id} = this.props.rs_router.params;
        switch(page ){
            case 'set-rollup-groups':
                return <RollupGroup/>
            case 'segments-value':
                return <SegmentValues/>
            default:
                return <Segments/>
        }
    }
    render() {
        return (
            <MasterComonent>
                { this.getPage()}
            </MasterComonent>
        );
    }
}

export default  RsWithRouter(SegmentsScreen);