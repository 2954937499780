import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    lineAccessLevel:[]
}
const LineAccessLevelReducer = (state = defaultState, actions ) => {
    if(actions.type === ActionTypes.SET_LINE_ACCESS_LEVEL){
        state = {
            ...state,
            isLoaded:true,
            lineAccessLevel:actions.payload
        }
    }
    return state;
}
export default LineAccessLevelReducer;
