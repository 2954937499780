import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import {Line } from "react-chartjs-2";
import CommonChartCard from "../ContractSecondRow/CommonChartCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ContractByMonth = () => {
    const options = {
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        barPercentage: 0.4,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      }

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        data: [1578960, 45000, 364000, 1579000, 1257900, 300000, 1600000, 1887900, 459000, 450000, 1670000, 1900000],
        backgroundColor: [
          "#156082"
        ],
      },
    ],
  };
  return (
      <CommonChartCard title='Contract value month wise'>
        <Line options={options} data={data} />
      </CommonChartCard>
  )
};

export default ContractByMonth;
