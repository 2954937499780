import React from 'react'

import Step from "./Step"

const StepperSection = ({currentStep,savedSteps,shipmentHeader,type,userType}) => {
  return (
    <div className="steps">
      <Step
        number="1"
        name="General Information"
        isCurrentStep={currentStep === 0}
        isSaved={shipmentHeader !== null || savedSteps[0]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      <Step
        number="2"
        name={type==="invoice"?"Invoice Lines":"Work Confirmation Lines"}
        isCurrentStep={currentStep === 1}
        isSaved={savedSteps[1]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      {/* {tabsCondition && 
      <><Step
        number="3"
        name="Price & Discount"
        isCurrentStep={currentStep === 2}
        isSaved={savedSteps[2]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      </>} */}
      <Step
        number={"3"}
        name="Documents"
        isCurrentStep={currentStep=== 2}
        isSaved={savedSteps[2]}
      />
      <div className="arrow">
        <img src="/images/icons/angle-right.svg" alt="arrow-right-icon" />
      </div>
      <Step
        number={"4"}
        name="Summary"
        isCurrentStep={currentStep===3}
        isSaved={savedSteps[3]}
      />
    </div>
  )
}

export default StepperSection
