import React, { Component } from 'react';
import EditGoodsReceivingNote from '../../components/GoodsReceiving/CreateNote/EditGoodsReceivingNote';
import Helper from '../../inc/Helper';

class PoGoodReceivingEditPage extends Component {
    render() {
        return (
            <EditGoodsReceivingNote
                title = { Helper.getLabel(null,'Edit good receiving note','Edit good receiving note')}
                backUrl = '/purchase-order/good-receiving/notes'
            />
        );
    }
}

export default PoGoodReceivingEditPage;