import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import ButtonNew from "../../inc/ButtonNew";
import SimpleLoading from "../../Loading/SimpleLoading";
import NavigationHeder from "../../Navigations/NavigationHeder";
import RsWithRouter from "../../inc/RsWithRouter";
import { useLocation } from "react-router-dom";
import { getPRColumnsHeader } from "../CommonColumnsHeader";
import AgGridNew from "../../grid/ag/ag-grid-new";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import CreatePRModal from "../modals/CreatePRModal";

let gridObj = null;

const PurchaseRequisitionList = (props) => {
  const urlParams = new URLSearchParams(useLocation().search);
  const current_user = urlParams?.get('user_id');

  const [isLoading, setIsLoading] = useState(true);
  const [documentStatusList, setDocumentStatusList] = useState([]);
  const [purchaseStatusList, setPurchaseStatusList] = useState([]);
  const [createPRPopup,setCreatePRPopup]=useState(false)

  useEffect(() => {
    getDocumentStatusList();
    getPurchaseStatusList()
  }, [])

  const getDocumentStatusList = useCallback(() => {
    setIsLoading(true);
    Api.setUserToken();
    Api.axios()
      .get(Settings.loginUrl + "/enum/pr_document_status")
      .then(function (res) {
        if (res.data.status === "success") {
          setDocumentStatusList(res.data?.data);
          setIsLoading(false)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  }, []);

  const getPurchaseStatusList = useCallback(() => {
    setIsLoading(true);
    Api.setUserToken();
    Api.axios()
      .get(Settings.loginUrl + "/enum/pr_requisition_status")
      .then(function (res) {
        if (res.data.status === "success") {
          setPurchaseStatusList(res.data?.data);
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  }, []);

  const onPurchaseRequisitionListDelete  = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete( Settings.apiPurchaseRequisitionUrl +
          `/pr_header/${element?.data?.purchaserequistion_id}`)
      const rowNode = gridObj?.api?.getRowNode(element.data?.purchaserequistion_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const CellHandler = useCallback((event) => {
    if (event.colDef.field === "document_number") {
      if(event?.data?.document_status===0){
        props.rs_router.navigate(
          "/purchase-requisition/addrequisitiondetails?pr_view_id=" +
            event.data.purchaserequistion_id +
            "&return=pr",{state:{isCurrentUser:current_user}}
        )
      }else{
        props.rs_router.navigate("/purchase-requisition/summary?pr_view_id=" + event.data.purchaserequistion_id + "&return=pr",{state:{isEditBtn:'true', isPrevPath:true}});
      }
    }
  }, [props.rs_router]);

  const handleNewPRCreate = () => {
    setCreatePRPopup(true)
  }

  const handleGridReady = (params) => {
    gridObj = params
  }

  const Columns_Headings = getPRColumnsHeader({
    language:props.language,
    documentStatusList:documentStatusList,
    purchaseStatusList:purchaseStatusList,
  })

  return (
    <div className="items_archive">  
      <div className="container-fluid">
        <NavigationHeder
          backUrl="/request"
          title={Helper.getLabel(
            props.language,
            "purchase_requisitions",
            `Purchase Requisitions - ${current_user ? 'Created by Me' : 'All'}`
          )}
          hideMoreBtn={true}
        >
          <ButtonNew
            title={Helper.getLabel(props?.language, "new", "New")}
            isDisable={props?.isDisableCreateBtn} 
            onClick={handleNewPRCreate}
          />
        </NavigationHeder>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <AgGridNew 
            gridId="rs_items_list"
            columnDefs={Columns_Headings}
            height={500}
            apiUrl={Settings.apiPurchaseRequisitionUrl + '/pr_header' }
            additionalParams={current_user ? `&createdby=${current_user}` : ''}
            onCellClicked={CellHandler}
            hideAddBtn={true}
            pagination={true}
            hideDeleteBtn={props?.isDisableDeleteBtn}
            handleDeleteSelectedRows={onPurchaseRequisitionListDelete}
            uniqueField={'purchaserequistion_id'}
            onGridReady={handleGridReady}
          />
        )}
      </div>
      {createPRPopup && <CreatePRModal setShowCreatePRModal={setCreatePRPopup} current_user={current_user} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user:state.auth.user,
    language: state.language,
  };
};  

export default connect(mapStateToProps) ((RsWithRouter((PurchaseRequisitionList))))