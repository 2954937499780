import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../External Screens/Purchase Order/ExtPO.css'
import { useNavigate, useLocation } from 'react-router-dom'
import Helper from '../../inc/Helper'
import AgGrid from '../grid/ag/ag-grid'
import CustomHtmlCell from '../grid/ag/cellRender/CustomHtmlCell'
import SimpleLoading from '../Loading/SimpleLoading'
import NavigationHeder from '../Navigations/NavigationHeder'
import MasterComponentWraper from '../Backend/MasterComponentWraper'
import ButtonNew from '../inc/ButtonNew' 
import WorkConfirmationApiCalls from '../External Screens/Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'

const GoodsReceivingAll = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const workConfirmationApiCalls = new WorkConfirmationApiCalls()

  const [loading, setLoading] = useState(false)
  const [workConfirmationList, setWorkConfirmationList] = useState([])
  const [documentStatus, setDocumentStatus] = useState([])

   const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const purchaseorder_id = getSearchParam('purchaseorder_id')

  const getAsyncData = async () => {
    setLoading(true)
    const shtypes = await workConfirmationApiCalls.getAllShType()
    const shtype = shtypes?.find((item) => item.name === 'Goods Receiving')
    const documentStatus = await workConfirmationApiCalls.getAllShDocumentStatus()
    let workConfirmationListData
    if (purchaseorder_id) {
      workConfirmationListData = await workConfirmationApiCalls.getAllSHHeader(
        shtype?.id,
        purchaseorder_id,
      )
    } else {
      workConfirmationListData = await workConfirmationApiCalls.getAllSHHeader(
        shtype?.id,
      )
    }
    if (workConfirmationListData)
      setWorkConfirmationList(workConfirmationListData)
    if (documentStatus) setDocumentStatus(documentStatus)
    if (workConfirmationListData && documentStatus) setLoading(false)
  }

  useEffect(() => {
    getAsyncData()
  }, [])

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link" style="margin-left:6px;">${params.value ? params.value : '-'}</div>`
  }

  const CellHandler = (event) => {
    if (event.colDef.field === 'receiving_reference_number') {
      navigate(
        `/goods-receiving-details?shipment_id=${event.data?.shipment_id}`,
      )
    }
    if (event.colDef.field === 'document_number') {
      navigate(
        `/purchase-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&shipment_id=${event.data?.shipment_id}`,
      )
    }
  }

  const getDocumentStatus = (params) => {
    const statusName = documentStatus?.find(
      (option) => option.id === params.value,
    )
    return statusName
      ? `<div><div class="rs_ag_status_label ${Helper.getClassFromString(
          statusName?.name,
        )}">${statusName?.name}</div></div>`
      : null
  }

  const Columns_Headings = [
    {
      field: 'receiving_reference_number',
      headerName: Helper.getLabel(
        props.language,
        'receiving_no',
        'Goods Receiving no.',
      ),
      minWidth: 220,
      editable: false,
      valueFormatter: gridLinkValueFormat,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'description',
      minWidth: 180,
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      editable: false,
    },
    {
      field: 'document_number',
      headerName: Helper.getLabel(
        props.language,
        'source_document_no',
        'Source document number',
      ),
      minWidth: 200,
      editable: false,
      valueFormatter: gridLinkValueFormat,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'vendor_code',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'vendor_code', 'Vendor code'),
      editable: false,
    },
    {
      field: 'vendor_name',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'name', 'Name'),
      editable: false,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(props.language, 'site', 'Site'),
      editable: false,
    },
    {
      field: 'document_status',
      minWidth: 200,
      headerName: Helper.getLabel(
        props.language,
        'document_status',
        'Document status',
      ),
      editable: false,
      valueFormatter: getDocumentStatus,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'accounting_date',
      minWidth: 200,
      inputType: 'datepicker',
      headerName: Helper.getLabel(
        props.language,
        'accounting_date',
        'Accounting date',
      ),
      editable: false,
    },
    {
      field: 'document_date',
      minWidth: 200,
      inputType: 'datepicker',
      headerName: Helper.getLabel(
        props.language,
        'document_date',
        'Document date',
      ),
      editable: false,
    },
  ]
 
  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'work-confirmation-internal-screen'
          : 'purchase_orders_listing_archive'
      }
    > 
      <div className="container-fluid">  
        <NavigationHeder
          backUrl="/receive"
          title={`Goods Receiving - All`}
        > 
        </NavigationHeder>
        {loading ? (
          <SimpleLoading />
        ) : (
          <AgGrid
            id="rs_work_confirmation_listing"
            hideDelete={true}
            onCellClicked={CellHandler}
            header={Columns_Headings}
            data={workConfirmationList}
            addBtnHide={true}
            hideSave={true}
            viewOnly={true}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(
  MasterComponentWraper(GoodsReceivingAll),
)
