import Helper from "../../../../inc/Helper";

class DatePickerViewer {
    init(params) {
      this.eGui = document.createElement('div');
      this.eGui.innerHTML = this.getValueToDisplay(params);
    }
  
    removeSpaces(str) {
      return str ? str.replace(/\s/g, '') : str;
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh() {
      return false;
    }

    getValueToDisplay(params) {
        let date = Helper.formateDate( params.value);

        /*
        if(typeof date === 'string' && date.length === 0){

        }
        else{
          if(Helper.isFrontendFormatedDate(params.value)){
              let stingAr = params.value;
              stingAr = stingAr.split('-');
              date = Helper.formateDate(stingAr[2]+'-'+stingAr[1]+'-'+stingAr[0]);
          }else{
              
              date = Helper.convertDateFromDb(params.value);
          }
        }
        */
        let output =  params.valueFormatted ? params.valueFormatted : date;
        if(typeof output == 'undefined'){
          return '';
        }
        return output;
    }
}
export default DatePickerViewer;