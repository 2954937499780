import axios from 'axios';
import { get } from 'jquery';
import React, { Component } from 'react';
import {connect} from 'react-redux';
import LogoutBtn from '../../components/Auth/LogoutBtn';
import MasterComonent from '../../components/Backend/MasterComonent';
import AgGrid from '../../components/grid/ag/ag-grid';
import Button from '../../components/inc/Button';
import Collapse from '../../components/inc/Collapse';
import LanguageSwitch from '../../components/Language Switcher/LanguageSwitch';
import NavigationHeder from '../../components/Navigations/NavigationHeder';
import TextTranslations from '../../components/Popup/TextTranslation/TextTranslations';
import MasterSidebar from '../../components/Sidebars/MasterSidebar';
import AddressList from '../../components/Tenant/Address/AddressList';
import ContactAddress from '../../components/Tenant/Address/ContactAddress';
import BlueCallToAction from '../../components/Widgets/BlueCallToAction/BlueCallToAction';
import NoteAttachmentHistory from '../../components/Widgets/NoteAttachmentHistory';
import OrderStatus from '../../components/Widgets/OrderStatus/OrderStatus';
import Tabs from '../../components/Widgets/Tabs/Tabs';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import DropDownInput from '../../components/Forms/Dropdown.js';
import TanentSwitcher from '../../components/Auth/TanentSwitcher';
import SegmentsDistribution from '../../components/Segments/SegmentsDistribution';
import UploaderWithList from '../../components/Widgets/FileUpload/UploaderWithList';
import { Grid} from 'ag-grid-enterprise';
import LineViewComponent from '../../components/Purchase Orders Internal/Edit PO/ListView/LineViewComponent';
import TabsNavItem from '../../components/Widgets/Tabs/TabsNavItem';
import Dropdown from '../../components/Forms/Dropdown.js';
import CustomCellRenderer from './CustomCellRenderer';
import AmendmentRequestDateUpdate from '../../components/grid/ag/cellEditor/AmendmentRequestDateUpdate';
import InputDatePicker from '../../components/Forms/InputDatePicker';
class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            date:null
        }
    }
    componentDidMount(){

    }
    dateChnageHandlder(event,date,datePickerDate){
        console.log('datePickerDate',datePickerDate);
        /*
        this.setState({
            date:datePickerDate
        })
        */
    }
    render() {
        console.log('this.state.date',this.state.date)
        return (
            <MasterComonent>
                <InputDatePicker value={this.state.date} name="test_date" id="test_date" label="Test Date" timepicker={false} onChange={ this.dateChnageHandlder.bind(this)} />
               
            </MasterComonent>
            
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}

export default connect (mapStateToProps) (  Dashboard);