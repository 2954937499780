import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import AgGrid from '../../grid/ag/ag-grid'

const RFQLabor = (props) => {
     
    const GridColumnsLabor = [
        {field:'labor', headerName:Helper.getLabel(props.language,'labor',"Labor"), minWidth: 160, },
        {field:'hours', headerName:Helper.getLabel(props.language,'hours',"Hours"), minWidth: 210,},
        {field:'rate', headerName:Helper.getLabel(props.language,'rate',"Rate"), minWidth: 260, },
        {field:'amount', headerName:Helper.getLabel(props.language,'amount',"Amount"), minWidth: 240,},
    ]

    const LaborLabels = () => {
      return ( 
        <h2 className='grid_label_rfq me-3'>Labor</h2>  
      )
    }

  return (
    <AgGrid id='rfq_labor_grid' labels={{btnDelete:  "Remove", btnAdd: "Add Labor", }} header={GridColumnsLabor} beforeAddButton={LaborLabels} data={[]} hideSave={true} addBtnHide={false} hideDelete={false} />
  )
}
 

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}
  
export default connect(mapStateToProps) (RFQLabor) 