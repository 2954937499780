import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { ValidateData, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGrid from '../../grid/ag/ag-grid';
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell';
import Collapse from '../../inc/Collapse';
import SimpleLoading from '../../Loading/SimpleLoading';

let gridObject = {} 
let gridAPIObject = {} 
let data = null

const ListViewGrid = (props) => {
    const navigate = useNavigate()   
    const gridApi = useRef()
  
    const [items, setItems] = useState([])
    const [barcode, setBarcodes] = useState([])
    const [products, setProducts] = useState([])
    const [category, setCategory] = useState([]) 

    // API Renderer

    useEffect(() => { 
        getCategoryData()
        getProductsData()  
        getItemsData()
    }, [])
 
  
    //Default Values Functions Executions

    useEffect(() => {
        updateRequesterProp()
    }, [props.purchaseOrderObj.requester_id])

    useEffect(() => {
        updateOrdererProp()
    }, [props.purchaseOrderObj.orderer_id])

    useEffect(() => {
        updateTaxGroupVendorProp()
    }, [props.purchaseOrderObj.taxgroup_id])

    useEffect(() => {
        updateTaxCodeVendorProp()
    }, [props.purchaseOrderObj.taxcodes_id])

    // End
  
    //Default Values For Lines Handling

    const updateRequesterProp = () => {
        let array = props.state.map((obj) => { 
                obj.requester_id = obj.requester_id === null ? null : +props.purchaseOrderObj.requester_id 
            return obj;
        })
        props.setState(array)
    } 

    const updateOrdererProp = () => {
        let array = props.state.map((obj) => { 
                obj.orderer_id = obj.orderer_id === null ? null :  +props.purchaseOrderObj.orderer_id 
            return obj;
        })
        props.setState(array)
    }

    const updateTaxGroupVendorProp = () => {
        let array = props.state.map((obj) => {
                obj.taxgroup_id =  obj.taxgroup_id === null ? null : +props.purchaseOrderObj.taxgroup_id
            return obj;
        })
        props.setState(array)
    }

    const updateTaxCodeVendorProp = () => {
        let array = props.state.map((obj) => { 
                obj.taxcodes_id = obj.taxcodes_id === null ? null : +props.purchaseOrderObj.taxcodes_id 
            return obj;
        })
        props.setState(array)
    }

    //End

    //API Reduction Calls
     let itemsCalled= false
     let barcodesCalled= false
     let productsCalled= false
     let categoryCalled= false 
    //End

    //API Calls
    const getItemsData = () => {
        if(itemsCalled){
            return;
        } 
        let api = Api
        itemsCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/item`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.item_id,
                    label: element.name
                })
            })
            setItems(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }
    
    const getBarcodesData = (id) => {
        if(barcodesCalled){
            return;
        } 
        let api = Api
        barcodesCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/get_barcodes/${id}`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.item_id,
                    label: element.barcode
                })
            })
            setBarcodes(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }

    const getProductsData = () => {
        if(productsCalled){
            return;
        } 
        let api = Api
        productsCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/item`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.item_id,
                    label: element.name
                })
            })
            setProducts(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }

    const getCategoryData = () => {
        if(categoryCalled){
            return;
        } 
        let api = Api
        categoryCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/categories`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.category_id,
                    label: `${element.code} - ${element.name}`
                })
            })
            setCategory(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }
 
    //End

    //Grid Functions
    const onGridReady = (params) =>{  
        data = params.api.data
        gridApi.current = params.api  
    }

    const OnAddClickFunction = () => {
    //    props.setState([{
    //     gridRowId: Date.now()
    //    },...props.state])
    }

    const getLineSiteIdFromRowClick = (event) => {   
        if( !ValidateData(gridObject) ){ 
            if(gridObject.node.rowIndex !== undefined) {  
                if(event.node.rowIndex == gridObject.node.rowIndex){ 
                    return;
                } 
            }
        } 
        props.updateLineId(event.data.line_id)
        props.updateListViewTabs(event.data)
        props.setGridObject(event)  
        gridObject = event 
        gridAPIObject = event.api 
  
    }
  
    const getPurchaseOrderLinesDetails = (event) => { 
        let data = event.data
        props.updateListViewTabs((prevState) => {
            return {
                ...prevState,
                data
            }
        }) 
    }

    const CellClickHandler = (event) => {
       if(event.colDef.field === 'item_id'){
            if(event.data.item_id !== null) {
                // navigate(`/edit-item/${event.data.item_id}`)
            }
        }
       if(event.colDef.field === 'barcode'){
            if(event.data.barcode !== null) {
                // navigate(`/edit-item/${event.data.barcode}`)
            }
        }
        if(event.colDef.field === 'category_id'){
            if(event.data.category_id !== null) {
                // navigate(`/category}`)
            }
        }
        if(event.colDef.field === 'product_id'){
            if(event.data.product_id !== null) {
                // navigate(`/edit-item/${event.data.product_id}`)
            }
       } 
    } 

    const enableRowEditForCategory = () => {  
        gridAPIObject.startEditingCell({ 
            colKey: 'category_id',
            rowIndex: gridObject.node.rowIndex
        })  
    };

    const enableRowEditForComments = () => {
        gridAPIObject.startEditingCell({ 
            colKey: 'comments',
            rowIndex: gridObject.node.rowIndex
        })
    }
  
    const Callbuttons = () => {
        return (
            <div className='list_view_action_btns_grid'>
                <button onClick={enableRowEditForCategory}>
                    <span className='me-2'><img src='/images/icons/category.svg' className='img-fluid' /></span>
                    Add Category
                </button>
                <button onClick={enableRowEditForComments} className='ms-3'>
                    <span className='me-2'><img src='/images/icons/comment.svg' className='img-fluid' /></span>
                    Add Comments
                </button> 
            </div>
        )
    }

    const gridLinkItemValueFormat = (params) => {
        if(items.find(id => id.value == params.value) == undefined){
            return `<div class="rs_grid_simple_link">${params.value}</div>`
        }else {
            let label = !ValidateData(items)? items.find(id => id.value == params.value).label : ''
            return `<div class="rs_grid_simple_link">${label}</div>`
        } 
    }

    const gridLinkBarcodeValueFormat = (params) => {
        if(barcode.find(id => id.value == params.value) == undefined){
            return `<div class="rs_grid_simple_link">${params.value}</div>`
        }else {
            let label = !ValidateData(barcode)? barcode.find(id => id.value == params.value).label : ''
            return `<div class="rs_grid_simple_link">${label}</div>`
        } 
    }

    const gridLinkProductValueFormat = (params) => {
        if(products.find(id => id.value == params.value) == undefined){
            return `<div class="rs_grid_simple_link">${params.value}</div>`
        }else {
            let label = !ValidateData(products)? products.find(id => id.value == params.value).label : ''
            return `<div class="rs_grid_simple_link">${label}</div>`
        } 
    }

    const gridLinkCategoryValueFormat = (params) => {
        if(category.find(id => id.value == params.value) == undefined){
            return `<div class="rs_grid_simple_link">${params.value}</div>`
        }else {
            let label = !ValidateData(category)? category.find(id => id.value == params.value).label : ''
            return `<div class="rs_grid_simple_link">${label}</div>`
        } 
    }

    //End

    //API calls

    const SaveHandler = (rowItem) => {
        let updatedData = []
        let newData=[];

        rowItem.forEach(item => {
          if( !item.line_id || item.line_id <=0){
            newData.push(item);
          }else{
            updatedData.push(item);
          }
        })

        if(newData.length>=1){ 
            addNewData(newData);
        }
        if(updatedData.length>=1){ 
            updateData(updatedData);
        }
    }
    //  

    function compareAndUpdateArrays( newArr) {
        let upatedData = props.DefStateData  
        let array = []
        newArr.forEach(element => { 
            upatedData[element.line_id] = element
        })
        for( let keyItem in upatedData){
            array.push(upatedData[keyItem])
        } 
        props.setState(array)  
    }

    const updateData = (data) => {
        console.log(data, 'data going into state')
        data.forEach((element) => {
            props.datafromGrid.push(element)
        })
        // compareAndUpdateArrays(data)
    }
  
    const addNewData = (data) => {   
        console.log(data, 'data going into state')
        data.forEach((element) => {
            props.datafromGrid.push(element)
        })
        // data.forEach(element => {
        //     props.setState([...props.state, element]);
        // })
    }

    const DeleteHandler = (allRows) => { 
        let api = Api
        api.setUserToken() 
        allRows.forEach(element => {
          api.axios().delete(Settings.apiPurchaseOrderUrl+`/`,{}).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            props.reloadAPI()
          } 
          }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            props.reloadAPI()
          })
      })
    }

    //end
 
    // Grid Validations 

    const gridLevelValidations = { 
        line_amount: {name:'line_amount', displayName:Helper.getLabel(props.language,"line_amount","Line Amount Invalid"),types:['Number']},
    }
  
    const gridValueUnitPriceValidationSetter = (params) => {
        if(Number.parseFloat(params.newValue) < 0 || isNaN(params.newValue)){
            Helper.alert('Unit price Invalid', 'error')
            // params.data.unit_price = params.newValue 
            return false
        } 
        params.data.unit_price = params.newValue
        return true
    }

    const gridValueDiscountValidationSetter = (params) => {
        if(Number.parseFloat(params.newValue) < 0 || isNaN(params.newValue)){
            Helper.alert('Discount Amount Invalid', 'error')
            // params.data.discount_amount = params.newValue 
            return false
        } 
        params.data.discount_amount = params.newValue
        return true
    }

    const gridValueQuantityValidationSetter = (params) => {
        if( isNaN(params.newValue) ){
            Helper.alert('Quantity Invalid', 'error')
            // params.data.unit_price = params.newValue 
            return false
        } 
        params.data.ordered_qty = params.newValue
        return true
    }

    const gridValueDiscountPercentageValidationSetter = (params) => {
        if(Number.parseFloat(params.newValue) < 0  || isNaN(params.newValue)){
            Helper.alert('Discount Percentage Invalid', 'error')
            // params.data.unit_price = params.newValue 
            return false
        } 
        params.data.discount_percentage = params.newValue
        return true
    }
  
    //End

    const Columns_Headings = [
        {field:'lines_type', headerName:Helper.getLabel(props.language,'type',"Type"), minWidth: 150, editable: false},
        {field:'budget_check', headerName:Helper.getLabel(props.language,'budget_check',"Budget Check"), minWidth: 160, editable: false},
        {field:'line_number', editable: false, headerName:Helper.getLabel(props.language,'line_no',"Line number"), minWidth: 150, validationRule: gridLevelValidations},
        {field:'item_id', headerName:Helper.getLabel(props.language,'item_id',"Item"), minWidth: 275, options:items, inputType: "dropdown", cellRenderer: CustomHtmlCell, valueFormatter: gridLinkItemValueFormat },
        {field:'barcode', headerName:Helper.getLabel(props.language,'barcode',"Barcode"), minWidth: 240,  options:barcode, inputType: "dropdown", cellRenderer: CustomHtmlCell, valueFormatter: gridLinkBarcodeValueFormat },
        {field:'product_id', headerName:Helper.getLabel(props.language,'product_name',"Product Name"), minWidth: 290, options:products, inputType: "dropdown", cellRenderer: CustomHtmlCell, valueFormatter: gridLinkProductValueFormat },
        {field:'category_id', headerName:Helper.getLabel(props.language,'category',"Category"), minWidth: 350, options:category, inputType: "dropdown", cellRenderer: CustomHtmlCell, valueFormatter: gridLinkCategoryValueFormat },
        {field:'comments', headerName:Helper.getLabel(props.language,'comments',"Comments") , minWidth: 350,},
        {field:'ordered_qty', valueSetter: gridValueQuantityValidationSetter , headerName:Helper.getLabel(props.language,'quantity',"Quantity"), minWidth: 200,},
        {field:'unit', headerName:Helper.getLabel(props.language,'unit',"Unit"), options: props.units, minWidth: 150, inputType: "dropdown"},
        {field:'unit_price', valueSetter: gridValueUnitPriceValidationSetter, headerName:Helper.getLabel(props.language,'unitprice',"Unit Price"), minWidth: 200,},
        {field:'discount_amount', valueSetter: gridValueDiscountValidationSetter, minWidth: 200, headerName:Helper.getLabel(props.language,'discount_amount',"Discount Amount") },
        {field:'discount_percentage', valueSetter: gridValueDiscountPercentageValidationSetter, minWidth: 200, headerName:Helper.getLabel(props.language,'discount_percentage',"Discount Percentage"),},
        {field:'line_amount', validationRule: gridLevelValidations, minWidth: 200, headerName:Helper.getLabel(props.language,'lineamount',"Line Amount"), editable: false},
    ]
    return ( 
    <Collapse className='mb-4 purchase_order_lines' open={true} title={Helper.getLabel(props.language,'purchase_order_lines',"Purchase Order Lines")}>
        {props.loadingState ? <SimpleLoading/> :
        <div className='container-fluid purchase_orders_lines_list_view_grid'>
            <AgGrid singleRowSelect={true} onGridReady={onGridReady} data={props.state} onCellClicked={CellClickHandler} onSave={SaveHandler} onRowValueChanged={getPurchaseOrderLinesDetails} onRowClick={getLineSiteIdFromRowClick} afterDeleteButtons={Callbuttons} id="rs_purchase_orders_lines_list_view" header={Columns_Headings} />
        </div>
        }
    </Collapse> 
    )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 
 
export default connect(mapStateToProps) (ListViewGrid) 