import React, { Fragment, useEffect, useRef, useState } from 'react'
import NavigationHeder from '../../../Navigations/NavigationHeder';
import Button from '../../../inc/Button';
import { useParams } from 'react-router-dom';
import Gui_id_list from '../../../../inc/Gui_id_list';
import { connect } from 'react-redux';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../../inc/Alert';
import Helper from '../../../../inc/Helper';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import Tabs from '../../../Widgets/Tabs/Tabs';
import Collapse from '../../../inc/Collapse';
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import SimpleLoading from '../../../Loading/SimpleLoading'; 
import { convertObjectValuesToInteger, customDateComparator, getPopupMessageBasedOnStatus, isEmptyObject } from '../../../../inc/Validation';
import DatepickerFunctions from '../../../../inc/DatepickerHelper';
import AgGridNew from '../../../grid/ag/ag-grid-new'; 
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';

const EditBiddingCount = (props) => {
  const {id} = useParams()

  const wrapperDiv = useRef();
  const [height, setHeight] = useState(0);

  const [editBiddingCount, setEditBiddingCount] = useState({})

  const [questionaireAPI, setQuestionaireAPI] = useState([])
  const [allActionReq, setAllActionReq] = useState([])

  const [loading, setLoading] = useState(false) 

  // api reduction flag
  let biddingDateAPICalled = false
  let biddingCountAPICalled = false
  let allActionsCalled = false

  // api trigger functions
  useEffect(() => {
    getBiddingCountPolicy()
    getBiddingDatesGrid() 
    getAllActionsRequired()
  }, [])

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  // api calls functions,

  const getBiddingCountPolicy = () => {
    if(biddingCountAPICalled) return;

    setLoading(true)
    let api = Api
    biddingCountAPICalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/RFQBidCountPolicy/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
        let updated_obj = {
          ...res.data.data,
          questionniare_id: res.data.data.questionnaire_id
        }
        setEditBiddingCount(updated_obj)
        setLoading(false)
      }
    }).catch((res) => {  
      console.log(res.response.data.message, 'error') 
      setLoading(false)
    })
  }

  const getBiddingDatesGrid = () => {
    if(biddingDateAPICalled) return;

    let api = Api
    biddingDateAPICalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/questionnaire?is_active=True',{}).then(function(res){
      if(res.data.status==="success"){
        let updated_dropdown_data = [] 
        res.data.data.forEach(element => {
          updated_dropdown_data.push({
            value: element.questionaire_id,
            label: element.description
          })
        });
        setQuestionaireAPI(updated_dropdown_data)  
      }
    }).catch((res) => {  
      console.log(res.response.data.message, 'error')  
    })
  }

  const getAllActionsRequired = () => {
    if(allActionsCalled) return;

    let api = Api
    allActionsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/ActionRequiredNumberBidNotMet',{}).then(function(res){
      if(res.data.status==="success"){   
        let updated_dropdown_data = []
        res.data.data.forEach(element => {
          updated_dropdown_data.push({
            value: element.id,
            label: element.name
          })
        });
        setAllActionReq(updated_dropdown_data)  
      }
    }).catch((res) => {  
      console.log(res.response.data.message, 'error')  
    })
  }
 
  // -----

  const BidCounting = () => { 
    const gridColHeaders = [
      {
        field: 'createddatetime',
        headerName: Helper.getLabel(props?.language,'dates',"Dates"),
        filter: 'agDateColumnFilter', 
        valueFormatter: (params) => { 
         return params.value
            ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value)
            : '-'
        },
        editable: false,
        minWidth: 220,
        comparator: customDateComparator
      }
    ];

    const defaultNavs = [
      {title:Helper.getLabel(props.language,'general','General'), isActive: true } 
    ];

    return (
      <div className='row'>
        <div className='col-4'>
          <div className='bidding_count_grid'>
            <AgGridNew
              apiUrl={Settings.apiUrl+'/questionnaire'} 
              additionalParams={`&is_active=True`}
              pagination={true}
              key={loading?'render':'rerender'}
              columnDefs={gridColHeaders}
              hidePaginationFooter={true}
              hideDeleteBtn={true}
              hideAddBtn={true}
              height={500}
              gridId={'rs_bidding_dates_grid'}
            />
          </div>
        </div>
        <div className='col-8'>
          <Tabs navs={defaultNavs} tabContantsId='bidding-count-tabs' showAll={false} scrollOffset="300">
            <Fragment>{BidCountingGeneralModule()}</Fragment> 
          </Tabs>
        </div>
      </div>
    )
  }

  const BidCountingGeneralModule = () => {
    const textBoxInputSettingsGeneral = [
      {label:Helper.getLabel(props.language,'from_numberofbid',"Bid count from"), className: 'col-lg-4', inputType: "text", name:'from_numberofbid', id:'from_numberofbid'},
      {label:Helper.getLabel(props.language,'to_numberofbid',"Bid count to"), className: 'col-lg-4', inputType: "text", name:'to_numberofbid', id:'to_numberofbid'},
      {label:Helper.getLabel(props.language,'minimumrequired_numberofbid',"Minimum required number of bids"), className: 'col-lg-4', inputType: "text", name:'minimumrequired_numberofbid', id:'minimumrequired_numberofbid'},
      {label:Helper.getLabel(props.language,'actionrequired_numberbidnotmet',"Action required number bid not met"), className: 'col-lg-4', inputType: "dropdown", options: allActionReq, name:'actionrequired_numberbidnotmet', id:'actionrequired_numberbidnotmet'},
      {label:Helper.getLabel(props.language,'questionniare_id',"Questionnaire"), className: 'col-lg-4', inputType: "dropdown", options: questionaireAPI, name:'questionniare_id', id:'questionniare_id'}
    ]
    return (
      <Collapse open={true} title={Helper.getLabel(props.language,'general',"General")}>
        <FieldsRenderer updateState={setEditBiddingCount} currentState={editBiddingCount} fieldsArray={textBoxInputSettingsGeneral} />
      </Collapse>
    )
  }

  const executeUpdateAPIForBiddingCount = () => {
    if(!id) return;

    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiUrl+`/RFQBidCountPolicy/${id}`, convertObjectValuesToInteger(editBiddingCount)).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)  
        getBiddingCountPolicy() 
      }
    }).catch((res) => {  
      getPopupMessageBasedOnStatus(res) 
    })
  }
 
  const SaveHandler = () => {   
    if(isEmptyObject(editBiddingCount)) return;
  
    if(!/^\d+$/.test(String(editBiddingCount.from_numberofbid).trim())){
      Helper.alert('Invalid integer entered!', 'error')
      return;
    }
    if(!/^\d+$/.test(String(editBiddingCount.to_numberofbid).trim())){
      Helper.alert('Invalid integer entered!', 'error')
      return;
    }
    if(!/^\d+$/.test(String(editBiddingCount.minimumrequired_numberofbid).trim())){
      Helper.alert('Invalid integer entered!', 'error')
      return;
    }

    executeUpdateAPIForBiddingCount() 
  }

  // Screen Accessibility -
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.bidding_count.bidding_count_edit_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return ( 
    <Fragment> 
      { loading ? <OverlayLoader /> : null }
      <div className='container-fluid'>
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} backUrl='/bidding-count-listings' title={ `<span style="color:#000;">${Helper.getLabel(props.language,'edit_bidding_count_policy', "Edit Bidding Count Policy")} #</span> ${id}` } >
            <Button className="rfq_save_btn" isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.bidding_count.bidding_count_edit_screen_save_button)} isActive= {false} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} />
          </NavigationHeder>
        </div>
        
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowX: 'hidden', overflowY: 'auto'}}> 
          { BidCounting()}
        </div>
      </div>
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.request_for_quotation.control_forms.bidding_count.bidding_count_edit_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditBiddingCount, SecurityOptions))))