import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import LinkBtn from '../../inc/LinkBtn';
import RsWithRouter from '../../inc/RsWithRouter'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import AddressList from '../../Tenant/Address/AddressList';
import ContactAddress from '../../Tenant/Address/ContactAddress'; 
import ActionTypes from '../../../inc/ActionsTypes'; 
import Collapse from '../../inc/Collapse';
import Checkbox from '../../Forms/Checkbox';
import Dropdown from '../../Forms/Dropdown';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useParams } from 'react-router-dom';

const EditWarehouse = (props) => {
    const { id } = useParams();

    const wrapperDiv = useRef(null);  
    const [height, setHeight] = useState(0);  

    const [isLoading, setIsLoading] = useState(false);

    const [warehouse, setWarehouse] = useState({}); 

    const [currentSearchParam, setCurrentSearchParam] = useState('');
    const [siteAdditionalParams, setSiteAdditionalParams] = useState({});

    const entityAllData = useRef(null);
    const siteDataRef = useRef(null);

    let warehouseApiCalled = false; 

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    useEffect(() => {
        loadWarehouse(); 
    }, []);

    const loadWarehouse = () => { 
        if(warehouseApiCalled) return;
        warehouseApiCalled = true;

        setIsLoading(true);
        Api.setUserToken();
        Api.axios().get(`${Settings.apiUrl}/warehouse/${id}`).then((res) => {
            if (res.data.status === 'success') {
                let warehouseFromServer = res?.data?.data;
                if (warehouseFromServer?.contact?.contactsource_id) {
                    warehouseFromServer.contactsource_id = warehouseFromServer.contact.contactsource_id;
                }
                if (warehouseFromServer?.address?.addresssource_id) {
                    warehouseFromServer.addresssource_id = warehouseFromServer.address.addresssource_id;
                } 
                setWarehouse(warehouseFromServer); 
                setIsLoading(false);
            }
        }).catch((res) => {
            setIsLoading(false);
            getPopupMessageBasedOnStatus(res) 
        });
    };

    const saveWarehouse = () => { 
        const newWarehouseData = {
            entity_id: warehouse?.entity_id,
            site_id: warehouse?.site_id,
            code: warehouse?.code,
            name: warehouse?.name,
            addresssource_id: warehouse?.addresssource_id,
            contactsource_id: warehouse?.contactsource_id,
            is_active: warehouse?.is_active,
            translationsource_id: null
        };

        if (!newWarehouseData?.entity_id || !newWarehouseData?.site_id || !newWarehouseData?.code || !newWarehouseData?.name) {
            Helper.alert("All fields are required", 'error');
            return;
        }

        setIsLoading(true);
        Api.setUserToken(); 
        Api.axios().put(`${Settings.apiUrl}/warehouse/${id}`, newWarehouseData).then((res) => {
            setIsLoading(false);
            if (res.data.status === 'success') {
                Helper.alert('Warehouse updated successfully');
                setIsLoading(false);
            }
        }).catch((res) => {
            setIsLoading(false);
            getPopupMessageBasedOnStatus(res) 
        });
    };

    const onInputChangeHandler = (e) => {
        setWarehouse((prevWarehouse) => ({
            ...prevWarehouse,
            [e.target.name]: e?.target?.value,
        }));
    };
  
    const onContactCreate = (response) => {
        if (warehouse && !warehouse?.contactsource_id) {
            setWarehouse((prevWarehouse) => ({
                ...prevWarehouse,
                contactsource_id: response?.contact_source_id,
            }));
        }
    };

    const onAddressCreate = (response) => {
        if (warehouse && !warehouse?.addresssource_id) {
            setWarehouse((prevWarehouse) => ({
                ...prevWarehouse,
                addresssource_id: response?.address_source_id,
            }));
        }
    };
  
    const mapEntityValue = (entity) => {
        return { text: `${entity?.name}`, id: entity?.entity_id };
    };

    const mapSiteData = (item) => {
        return {
            text: `${item?.name}`,
            id: item?.site_id
        };
    };

    const onIsActiveChangeHandler = (name, value) => {
        setWarehouse((prevWarehouse) => ({
            ...prevWarehouse,
            [name]: value == 'on',
        }));
    };

    const onEntityChangeHandler = (event) => {
        setWarehouse((prevWarehouse) => ({
            ...prevWarehouse,
            site_id: '',
            entity_id: event?.target?.value,
        }));
        setSiteAdditionalParams({
            entity_id: event?.target?.value
        });
    };

    const onSiteChangeHandler = (event) => {
        setWarehouse((prevWarehouse) => ({
            ...prevWarehouse,
            site_id: event?.target?.value
        }));
    };

    const language = props?.language;
    const source_id = Settings.source_id.warehouse; 
  
    return (
      <Fragment>
        {isLoading ? <OverlayLoader isPopupInTab={true} /> : null}
        <div className="container-fluid">
          <div ref={wrapperDiv}>
            <NavigationHeder
              hideMoreBtn={true}
              backUrl="/warehouse"
              title={Helper.getLabel(
                language,
                "edit_warehouse",
                "Edit Warehouse"
              )}
            >
              <LinkBtn
                to="/warehouse"
                isActive={false}
                className='black-btn-style'
                title={Helper.getLabel(language, "close", "Close")}
              />
              <Button
                onClick={saveWarehouse}
                className="rfq_save_btn"
                isActive={false}
                title={Helper.getLabel(language, "save", "Save")}
              />
            </NavigationHeder>
          </div>

          <div
            className="common_scrollbar_styles"
            style={{ height: `${height - 30}px`, overflowY: "auto" }}
          >
            <Collapse
              title={Helper.getLabel(language, "general", "General")}
              open={true}
            >
              <form
                id="new_form"
                className="rs_form_container"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-xs-12 col-sm-3">
                    <Input
                      name="code"
                      readonly={true}
                      label="Code"
                      onChange={onInputChangeHandler}
                      value={warehouse?.code}
                      required={true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <Input
                      name="name"
                      label="Name"
                      onChange={onInputChangeHandler}
                      value={warehouse?.name}
                      required={true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-3"> 
                    <Dropdown
                        id="entity_id"
                        name="entity_id" 
                        label={Helper.getLabel(language, "entity", "Entity")}
                        options={[
                          { value: warehouse?.entity_id, label: warehouse?.entity_name }
                        ]}
                        apiUrl={`${Settings.apiUrl}/get_entities`}
                        currentSearchTerm={currentSearchParam}
                        mapFunction={mapEntityValue}
                        allDataRef={entityAllData}
                        value={warehouse?.entity_id}
                        disableClear={true}
                        pagination={true}
                        reRenderRequired={true}
                        required={true}
                        onChange={onEntityChangeHandler}
                        placeHolder={`${Helper.getLabel(
                            language,
                            "please_select",
                            "Select entity"
                        )}`}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <Dropdown
                        label={`${Helper.getLabel(language, "site", "Site")}`}
                        options={[
                            { value: warehouse?.site_id, label: warehouse?.site_name }
                        ]}
                        placeHolder={`${Helper.getLabel(
                            language,
                            "select_site",
                            "Select site"
                        )}`}
                        onChange={onSiteChangeHandler} 
                        value={warehouse?.site_id}
                        id="site"
                        reRenderRequired={true}
                        apiUrl={Settings.loginUrl + "/get_site"}
                        pagination={true}
                        mapFunction={mapSiteData}
                        allDataRef={siteDataRef}
                        additionalParams={siteAdditionalParams}
                        required={true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <Checkbox
                      name="is_active" 
                      onChange={onIsActiveChangeHandler}
                      isChecked={warehouse?.isactive}
                      label={Helper.getLabel(language, "status", "Status")}
                    />
                  </div>
                </div>
              </form>
            </Collapse>
            {isLoading ? null : ( 
                <AddressList
                    id='warehouse_address'
                    onAddressCreate={onAddressCreate}
                    addressSourc_id={warehouse?.addresssource_id}
                    source_id={source_id}
                    address_source_integrator={id}
                />
            )}
            {isLoading ? null : (
                <Collapse open={true} title={Helper.getLabel(language, "contact", "Contact")}> 
                    <ContactAddress
                        id='warehouse_contact'
                        contactsource_integrator={id}
                        onContactCreate={onContactCreate}
                        contactsource_id={warehouse?.contactsource_id}
                        source_id={source_id}
                        address_id={null}
                    />
                </Collapse>
            )}
          </div>
        </div>
      </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth:state.auth,
    language: state.language,
    entity: state.entity,
});

const mapDispatchToProps = (dispatch) => ({
    setEntity: (entity) => dispatch({ type: ActionTypes.SET_ENTITY, entity }),
});

export default RsWithRouter(connect(mapStateToProps, mapDispatchToProps)(EditWarehouse)); 