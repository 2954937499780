import React, { Component } from 'react';
import EditReturnOrder from '../../components/Purchase Orders Internal/ReturnOrder/Edit/EditReturnOrder';

class PoReturnOrderEditPage extends Component {
    render() {
        return (
            <EditReturnOrder
                backUrl = "/purchase-order/return-order/all"
            />
        );
    }
}

export default PoReturnOrderEditPage;