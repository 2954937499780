import React, { useEffect, useState } from 'react'
import Popup from '../../Popup/Popup'
import $ from 'jquery'
import Input from '../../Forms/Input'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import Dropdown from '../../Forms/Dropdown'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import Button from '../../inc/Button'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'

const BudgetEntryPopup = (props) => {
  const [createBudgetEntry, setCreateBudgetEntry] = useState({
    initiate_date: new Date(),
  })
  const [departments,setDepartments]=useState([])

  useEffect(()=>{
    fetchDepartments()
  },[])

  const fetchDepartments = () => {
    let api = Api
    api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
        let departmentData = [];
        if(res.data.status === 'success'){
            let responseData = res.data.data;
            departmentData = responseData.filter( item => { 
                return item.isactive===true
            }).map((item)=>{
                return {
                    value:item.segment_id,
                    label:item.name
                }
            })
            setDepartments(departmentData)
        }
    }).catch(error =>{
    })
  }

  const InputChangeHandler = (e) => {
    setCreateBudgetEntry((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const DatePickerHandler = (date, name) => {
    setCreateBudgetEntry((prevState) => {
      return {
        ...prevState,
        initiate_date: date,
      }
    })
  }

  const onPopupClose = () => {
    $('html').removeClass('rs_popup_open')
    props.ClosePopup(false)
  }

  let projects = [
    {
        value:101,
        label:'Data Center Migration'
    },
    {
        value:102,
        label:'Website Support Services'
    },
    {
        value:103,
        label:'Office Upgrade'
    },
    {
        value:104,
        label:'HR Tech Implementation'
    },
  ]

  return (
    <div>
      <div className='close-popup_po'>
        <Popup onClose={onPopupClose} autoOpen={true} width='752px'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h2 className='mb-4'>{`Create Budget Entry`}</h2>
              </div>
            </div>
          </div>

          <Collapse className='mb-4 info_po_tabs' open={true} title={Helper.getLabel(props.language, 'etfi', 'Enter the following information')}>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-lg-6'>
                  <Input label={Helper.getLabel(props.language, 'document_no', 'Document number')} name='document_number' value={createBudgetEntry?.document_number} disable={false} onChange={InputChangeHandler} />
                </div>
                <div className='col-lg-6'>
                  <Input label={Helper.getLabel(props.language, 'title', 'Title')} value={createBudgetEntry.title} onChange={InputChangeHandler} name='title' maxLength={255} />
                </div>
                <div className='col-lg-6'>
                  <Input label={Helper.getLabel(props.language, 'year', 'Year')} value={createBudgetEntry.year} onChange={InputChangeHandler} name='year' maxLength={255} />
                </div>
                <div className='col-lg-6'>
                  <NewInputDatePicker
                    label={Helper.getLabel(props.language, 'initiation_date', 'Initiation date')}
                    name='initiation_date'
                    id='initiation_date'
                    value={createBudgetEntry.initiation_date}
                    onChange={DatePickerHandler}
                  />
                </div>
                <div className='col-lg-6'>
                  <Dropdown
                    id='department_id'
                    label={Helper.getLabel(props.language, 'department', 'Department')}
                    placeHolder={Helper.getLabel(props.language, 'select department', 'Select department')}
                    options={departments}
                    value={createBudgetEntry.department_id}
                    name='department_id'
                    onChange={InputChangeHandler}
                    reRenderRequired={true}
                  />
                </div>
                <div className='col-lg-6'>
                  <Dropdown
                    id='project_id'
                    label={Helper.getLabel(props.language, 'project', 'Project')}
                    placeHolder={Helper.getLabel(props.language, 'select project', 'Select project')}
                    options={projects}
                    value={createBudgetEntry.project_id}
                    name='project_id'
                    onChange={InputChangeHandler}
                  />
                </div>
              </div>
            </div>
          </Collapse>
          <div className='container-fluid'>
            <div className='row mt-3 mb-3'>
              <div className='col-12'>
                <div className='addDocumentNumber-body-buttons justify-content-end'>
                  <Button className='black-btn-style' onClick={onPopupClose} title={Helper.getLabel(null, 'close', 'Close')} />
                  <Button isDisable={false} isActive={true} onClick={createBudgetEntry} title={Helper.getLabel(null, 'create', 'Create')} />
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  )
}

export default BudgetEntryPopup
