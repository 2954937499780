import React from 'react';

/*

This is our wraper component (main) - For Technical / Financial Evaluation , 
Example:- Structure To Acheive Design -

  <TechnicalEvaluationMasterWraper> 

    <EvaluationCriteriaHeader {...props required} />

    <CriteriaSectionsCollapseWrapper {...props required} >
      <TeamSectionsWrapper>
        <Sections {...props required} /> // this section is usually having the criteria's sections, with comments , & range slider for scoring
      </TeamSectionsWrapper> 
    </CriteriaSectionsCollapseWrapper>

  </TechnicalEvaluationMasterWraper>
  
*/

const TechnicalEvaluationMasterWraper = ({children}) => {
  return (
    <div className='tech_evaluation_master_wrapper'>
      <div className='container-fluid p-0 m-0'>  
        {children}
      </div>
    </div>
  )
};

export default TechnicalEvaluationMasterWraper;