import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'; 
import Alert from '../../inc/Alert'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';

/* Validations -> itemgroup_id/itemgroupaccounttype_id/account ( confirmation when implementing the validations from Sibte ) -> required */

let gridApi = null;
let AddBtn;
let transaction;

const ItemGroupAccount = (props) => {
  const [itemGroups, setItemGroups] = useState([])
  const [itemGroupAccounts, setItemGroupAccounts] = useState([])
 
  let itemGroupCalled = false
  let groupAccListCalled = false

  useEffect(() => { 
    itemGroupsList()
    itemGroupsAccountsList()
  }, [])

  const itemGroupsList = () => {
    if(itemGroupCalled){
      return;
    }
    let api = Api
    itemGroupCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+'/item_group',{}).then(function(res){
      if(res.data.status==="success"){
        setItemGroups(res?.data?.data?.map(item => {
          return {
            value: item.itemgroup_id,
            label: item.name
          }
        }))
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const itemGroupsAccountsList = () => {
    if(groupAccListCalled) {
      return;
    }
    let api = Api
    groupAccListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+'/item_groupaccount_type',{}).then(function(res){
      if(res.data.status==="success"){
        setItemGroupAccounts(res?.data?.data?.map(item => {
          return {
            value: item.itemgroupaccounttype_id,
            label: item.name
          }
        }))
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {  
    if (!event?.data?.isNew) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }
 
  const updateData = async (event) => { 
    try {
      let payload = {
        groupaccounttype_id: event?.data?.itemgroupaccounttype_id ? parseInt(event?.data?.itemgroupaccounttype_id) : null,
        itemgroup_id: event?.data?.itemgroup_id ? parseInt(event?.data?.itemgroup_id) : null,
        account: event?.data?.account ? event?.data?.account : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiItemUrl+`/item_groupaccount/${event?.data?.itemgroup_id}/${event?.data?.itemgroupaccounttype_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
 
  const addNewData = async (event) => {
    try {
      let payload = {
        groupaccounttype_id: event?.data?.itemgroupaccounttype_id ? parseInt(event?.data?.itemgroupaccounttype_id) : null,
        itemgroup_id: event?.data?.itemgroup_id ? parseInt(event?.data?.itemgroup_id) : null,
        account: event?.data?.account ? event?.data?.account : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiItemUrl+'/item_groupaccount', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  }

  const onDeleteItemGroupAccount = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/item_groupaccount/${element?.data?.itemgroup_id}/${element?.data?.itemgroupaccounttype_id}`)
      const rowNode = gridApi?.getRowNode(element?.data?.itemgroup_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200);

      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item_group_account.item_group_account_main)){
    return <Fragment> 
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  /* Grid Column Headers */
   
  const gridColHeader = [ 
    {
      field: 'itemgroup_id',
      headerName: Helper.getLabel(props.language, 'item_group', 'Item Group'),
      editable: (event) => event?.data?.isNew,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: {
        values: itemGroups
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, itemGroups),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      },
    },
    {
      field: 'itemgroupaccounttype_id',
      headerName: Helper.getLabel(props.language, 'item_group_account_type', 'Item Group Account Type'),
      editable: (event) => event?.data?.isNew,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180,
      cellEditorParams: {
        values: itemGroupAccounts
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, itemGroupAccounts),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      },
    },
    { field:'account', editable: true, filter: 'agNumberColumnFilter', headerName: Helper.getLabel(props.language, 'account', 'Account') } 
  ];

  return (
    <Fragment> 
      <NavigationHeder backUrl='/setup' hideMoreBtn={true} title='Items Group - Account' /> 
    
      <AgGridNew
        apiUrl={Settings.apiItemUrl+'/item_groupaccount'}
        pagination={false}
        columnDefs={gridColHeader}
        onRowValueChanged={onRowValueChanged}
        hideDeleteBtn={!security.canDelete(frontendIds.masterData.item_master_modules.item_group_account.item_group_account_main_delete_button)}
        hideAddBtn={!security.canCreate(frontendIds.masterData.item_master_modules.item_group_account.item_group_account_main_create_button)}
        handleDeleteSelectedRows={onDeleteItemGroupAccount}
        height={500}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        uniqueField={`itemgroup_id`}
        gridId={'item-group-account-setup-forms'}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.masterData.item_master_modules.item_group_account.item_group_account_main
} 

export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(ItemGroupAccount, SecurityOptions)))