import React, { Fragment, useEffect, useRef, useState  } from 'react';
import { connect } from 'react-redux'; 
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper'; 
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';  
import Alert from '../../../inc/Alert';
import Button from '../../../inc/Button'; 
import LinkBtn from '../../../inc/LinkBtn'; 
import NavigationHeder from '../../../Navigations/NavigationHeder'; 
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles' ; 
import AddTemplatePopup from './AddTemplatePopup';
 
let functionCalled = null

const CreateScoringTemplate = (props) => {
  const wrapperDiv = useRef(null);
 
  const functionCall = (params) => {
    functionCalled = params
  }

  let saveButtonTimeout = null;
  const SaveHandler = () => {   
    clearTimeout(saveButtonTimeout);
    saveButtonTimeout = setTimeout(function(){ 
      functionCalled() 
    }, 200) 
  }
 
  // Screen Accessibility ---
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.scoring_template.scoring_template_create_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }
  
  return (
    <div className='create_scoring_template'>
      <div className='container-fluid scrollBarBlinkControl'>
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} backUrl='/scoring-template' title={Helper.getLabel(props.language,'scoring_templates',"Create Scoring Template")}>
            <LinkBtn isActive= {false} to="/scoring-template" title={Helper.getLabel(props.language,'close',"Close")} className='black-btn-style' />
            <Button isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.scoring_template.scoring_template_create_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
          </NavigationHeder>
        </div>

        <AddTemplatePopup masterComponentWrapperDiv={wrapperDiv} callBackAPIExecution={functionCall} RFQModuleReq={false} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.request_for_quotation.control_forms.scoring_template.scoring_template_create_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(CreateScoringTemplate, SecurityOptions))))