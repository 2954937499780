import Settings from "../../../../inc/Settings";

export const APIUrlsOnDropdown = [
    {
        prop: 'vendor_name',
        apiUrl: `${Settings.apiVendorUrl}/vendor`,
        value: 'vendor_id',
        label: 'name'
    },
    {
        prop: 'vendor_code',
        apiUrl: `${Settings.apiVendorUrl}/vendor`,
        value: 'vendor_id',
        label: 'code'
    },
    {
        prop: 'rfq_status',
        apiUrl: `${Settings.loginUrl}/enum/RFQ_status`,
        value: 'id',
        label: 'name'
    }, 
    {
        prop: 'purchase_status',
        apiUrl: `${Settings.loginUrl}/enum/POHeaderPurchaseStatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'document_status',
        apiUrl: `${Settings.loginUrl}/enum/pr_document_status`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'sh_document_status',
        apiUrl: `${Settings.loginUrl}/enum/SH_DocumentStatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'vendor_type',
        apiUrl: `${Settings.apiUrl}/enum/vendortype`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'usertype',
        apiUrl: `${Settings.apiUrl}/enum/usertype`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'inv_document_status',
        apiUrl: `${Settings.apiUrl}/enum/invoice_header_documentstatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'category',
        apiUrl: `${Settings.apiUrl}/categories`,
        value: 'category_id',
        label: 'name'
    },
    {
        prop: 'requester_name',
        apiUrl: `${Settings.apiOrgUrl}/employee`,
        value: 'employee_id',
        label: 'display_name'
    },
    {
        prop: 'po_document_status',
        apiUrl: `${Settings.loginUrl}/enum/POHeaderDocumentStatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'co_document_status',
        apiUrl: `${Settings.loginUrl}/enum/POHeaderDocumentStatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'status_name',
        apiUrl: `${Settings.loginUrl}/enum/vendorstatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'workflow_status',
        apiUrl: `${Settings.loginUrl}/enum/POHeaderDocumentStatus`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'quotation_type',
        apiUrl: `${Settings.loginUrl}/enum/RQ_type`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'vendor_replystatus',
        apiUrl: `${Settings.loginUrl}/enum/RFvendorreplyheaderstatus_external`,
        value: 'id',
        label: 'name'
    },
    {
        prop: 'site_id',
        apiUrl: `${Settings.apiVendorUrl}/get_active_vendor_sites`,
        value: 'vendorsite_id',
        label: 'name'
    },
    {
        prop: 'vendorsite_name',
        apiUrl: `${Settings.apiVendorUrl}/get_active_vendor_sites`,
        value: 'vendorsite_id',
        label: 'name'
    }
];

export const ColumnsToExclude = [
    'entities',
    'image',
    'category',
    'enable', 
    'interested_to_participate',
    'recall_bid',
    'attached'
];

export const FieldsForDatePickers = [
    {
        prop: 'publish_datetime',
        type: 'datetime'
    },
    {
        prop: 'delivery_date',
        type: 'date'
    },
    {
        prop: 'expiry_datetime',
        type: 'datetime'
    },
    {
        prop: 'document_date',
        type: 'date'
    },
    {
        prop: 'createddatetime',
        type: 'datetime'
    },
    {
        prop: 'accounting_date',
        type: 'date'
    },
    {
        prop: 'invoice_date',
        type: 'date'
    }
];