import React, { Component } from "react";
import MasterComonent from "../../components/Backend/MasterComonent";
import AllRequisitions from "../../components/RequestForQuotation/AllRequisitions";

export default class AllRequisitionsScreen extends Component {
  render() {
    return (
      <MasterComonent>
        <AllRequisitions />
      </MasterComonent>
    );
  }
}