import React from 'react'
import { connect } from 'react-redux'
import MasterComonent from '../../../Backend/MasterComonent'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'

const RFQHelpVideos = (props) => {
  return (
    <div>
      <MasterComonent>
        <NavigationHeder
          title={`${Helper.getLabel(
            props.language,
            'watch_video',
            'Watch Video',
          )}`}
        ></NavigationHeder>
        <div className="rfq-help-videos-screen">
          <div className="left-video-player">
            <video width="100%" controls style={{borderRadius:"16px"}}>
              <source
                src="https://file-examples.com/storage/fe396452246495b989f22f7/2017/04/file_example_MP4_480_1_5MG.mp4"
                type="video/mp4"
              ></source>
            </video>
            <h5 className="mt-4 mx-2 played-video-title">How to re-call the eid?</h5>
          </div>
          <div>
            <h5 className="related-videos-title">Related Videos</h5>
            <div className="related-videos-container">
              {/* dummy videos */}
              {[0, 1, 2, 3].map((item) => (
                <div className="video-card">
                  <div className="video-preview">
                    <img className="preview-image" />
                    <img
                      src="/images/icons/pause-icon.svg"
                      alt="pause-icon"
                      className="pause-icon"
                    />
                  </div>
                  <div className="video-card-details">
                    <h6 className="video-card-title">
                      How to re-call the bid?
                    </h6>
                    <p className="video-duration">5:00</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQHelpVideos)
