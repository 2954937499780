import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import { checkNullProperties } from '../../../inc/Validation';
import AgGrid from '../../grid/ag/ag-grid';
import Collapse from '../../inc/Collapse';

const DeliveryVersion = ({data, language, deliveryTerm, modeOfDelivery}) => {
  
  // Grid Things -

  const Columns_Headings = [ 
    {field:'delivery_term_id', headerName:Helper.getLabel(language,'delivery_term_id',"Delivery Term"),inputType: "dropdown", options: deliveryTerm, minWidth: 250, editable: false},
    {field:'mode_of_delivery_id', headerName:Helper.getLabel(language,'mode_of_delivery_id',"Mode Of Delivery"), inputType: "dropdown", options: modeOfDelivery, minWidth: 250, editable: false}, 
    {field:'deliveryaddress_id', headerName:Helper.getLabel(language,'deliveryaddress_id',"Delivery Address"), inputType: "dropdown", options: [], minWidth: 250, editable: false},
    {field:'delivery_date', headerName:Helper.getLabel(language,'delivery_date',"Delivery Date"), inputType: "datepicker", minWidth: 250, editable: false},
  ]

  // --

  return (
    <Collapse className='delivery_po_version' open={true} title={Helper.getLabel(language,'delivery',"Delivery")}>
      <div className='container-fluid delivery_po_version'>
        <AgGrid data={checkNullProperties(data) ? []: data} id="rs_delivery_po_version" allBtnHide={true} header={Columns_Headings} />
      </div>
    </Collapse>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}
    
export default connect(mapStateToProps) (DeliveryVersion) 