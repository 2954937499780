import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import MasterComonent from '../../../Backend/MasterComonent'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Tabs from '../../../Widgets/Tabs/Tabs'
import SwitchViewDynamic from '../../../../inc/SwitchViewDynamic'
import Collapse from '../../../inc/Collapse'
import BlueCallToAction from '../../../Widgets/BlueCallToAction/BlueCallToAction'
import LeftVersionsSideBar from './LeftVersionsSideBar'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import ProposalLineGrid from './ProposalLineGrid'
import BiddingForm from '../RFQBiddingDetails/BiddingForm'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import SimpleLoading from '../../../Loading/SimpleLoading'
import PriceDiscountTab from '../../Purchase Order Screen/PurchaseOrderDetails/PriceDiscountTab'

let gridObj = null

const RFQVersions = (props) => {
  const ViewHeaderList = [
    Helper.getLabel(props.language, 'header_view', 'Header View'),
    Helper.getLabel(props.language, 'line_view', 'Line View'),
  ]

  const [viewComponent, setViewComponent] = useState(ViewHeaderList[0])
  const [versionsData, setVersionsData] = useState([])
  const [vendorData, setVendorData] = useState({})
  const [sources, setSources] = useState([])
  const [sourceId, setSourceId] = useState(null)
  const [version, setVersion] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currencyList,setCurrencyList]=useState([])

  let currencyListCalled = false

  //function to get params
  const location = useLocation()
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  const rfq_vendor_id = getSearchParam('RFQ_vendorreply_id')

  //Api Call for Source Id
  const get_rfq_source_id = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + '/source')
      .then(function (res) {
        if (res.data.status === 'success') {
          setSources(res.data.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getSourceId = (name) => {
    for (let i = 0; i < sources?.length; i++) {
      if (sources[i]?.source_name === name) {
        return sources[i]?.source_id
      }
    }
    return null
  }

  //Api call to fetch versions data
  const get_rfq_versions_data = () => {
    let api = Api
    api.setUserToken()
    setLoading(true)
    if (!sourceId) return
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/header_version/${rfq_vendor_id}/${sourceId}`,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          setVersionsData(res.data.data)
          setLoading(false)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const get_currency_dropdown_list = () => {
    if (currencyListCalled) {
      return
    }
    let api = Api
    currencyListCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/currency`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          setCurrencyList(res.data.data)
        }
      })
  }

  useEffect(() => {
    get_rfq_source_id()
    get_currency_dropdown_list()
  }, [])

  useEffect(() => {
    const sourceId1 = getSourceId('RFQ-VendorReply') || ''
    setSourceId(sourceId1)
  }, [sources])

  useEffect(() => {
     get_rfq_versions_data()
  }, [sourceId])

  useEffect(() => {
      setTimeout(()=>{
        gridObj?.api?.refreshServerSide({purge:true})
      },500)
  }, [version])

  //function to get headerline of particular version
  const CellHandler = (event) => {
    if (event?.colDef?.field === 'version') {
      setVersion(event.value)
      const selectedVendorData = versionsData.find(
        (data) => data.version === event.value,
      )
      if (selectedVendorData) {
        setVendorData(selectedVendorData)
      }
      setLoading(true)
      setTimeout(()=>{
        setLoading(false)
      },300)
    }
    let versionNewData = versionsData?.find((item)=>item.version===event.value)
    setVendorData(versionNewData)
  }

  //data for header level tabs
  let navs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      isActive: true,
    },
    {
      title: Helper.getLabel(props.language, 'price_and_discount', 'Price & Discount'),
    },
  ]

  //data for lines tab
  let navs2 = [
    {
      title: Helper.getLabel(props.language, 'lines', 'Lines'),
      isActive:true,
    },
  ]

  //blue top section
  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, 'document_no', 'Document Number'),
        subtitle: location?.state?.document_number,
      },
      {
        title: Helper.getLabel(props.language, 'title', 'Title'),
        subtitle:location?.state?.title?.length > 30
        ? location?.state?.title?.substring(0, 30) + "..." || "-"
        : location?.state?.title || "-",
      },
    ]
  
    return (
      <div className="rfq_detail_form blue-top-section">
        <div className={props.auth?.user?.usertype===0?'rfq-internal-blue':'rfq_details_external_action misc-header-blue rfq-internal-blue'}>
          <BlueCallToAction items={items} tags={null} />
        </div>
        <div className="mb-4" />
      </div>
    )
  }

  return (
    <div className={props.auth?.user?.usertype===0?'':'request_for_quotation_detail_screen external-theme'}>
      <MasterComonent>
        <NavigationHeder
          // backUrl={`/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id=${rfq_vendor_id}`}
          backUrl={-1}
          title={`${Helper.getLabel(props.language, 'versions', 'Versions')}`}
          hideMoreBtn={true}
        ></NavigationHeder>
        <div>
          {addComponentTop()}
          <div className="rfq-versions-container">
            <LeftVersionsSideBar
              versions={versionsData}
              CellHandler={CellHandler}
            />
            <div style={{ width: '100%' }}>
              <SwitchViewDynamic
                viewComponent={viewComponent}
                setViewComponent={setViewComponent}
                headerList={ViewHeaderList}
              />
              {viewComponent === 'Header View' && (
                <Tabs navs={navs} showAll={true} scrollOffset="300">
                  {loading ? <SimpleLoading /> : <div id="rfq-version-general-info">
                  <Collapse
                    title={Helper.getLabel(
                      props.language,
                      'general',
                      'General',
                    )}
                    open={true}
                    className="mb-4"
                  >
                    <div className="request_for_quotation_bidding">
                      <BiddingForm
                        rfqHeader={vendorData}
                        setRfqHeader={setVendorData}
                        currencyList={currencyList}
                        language={props.language}
                        type="versions"
                      />
                    </div>
                  </Collapse>
                  </div>}
                  {loading ? null : <div id="rfq-version-price-discount">
                    <Collapse
                    className='mb-4'
                    title={Helper.getLabel(
                      props.language,
                      'price_and_discount',
                      'Price & Discount'
                    )}
                    open={true}
                  >
                    <PriceDiscountTab
                      isEditable={false}
                      state={vendorData}
                      setState={setVendorData}
                      name="price_include_tax"
                      paymentTermName="paymentterm_id"
                    />
                  </Collapse>
                  </div>}
                </Tabs>
              )}

              {viewComponent === 'Line View' && (
                <div>
                  <Tabs navs={navs2} showAll={true} scrollOffset="300">
                      {loading ? <SimpleLoading /> : <ProposalLineGrid rfq_vendor_id={rfq_vendor_id} version={version} gridObj={gridObj} />}
                  </Tabs>
                </div>
              )}
            </div>
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQVersions)
