// import React, { Component } from 'react';
// import Popup from '../../Popup/Popup';
// import $ from 'jquery';
// import Helper from '../../../inc/Helper';
// import AgGrid from '../../grid/ag/ag-grid';
// import Api from '../../../inc/Api';
// import Settings from '../../../inc/Settings';
// import DropDownInput from '../../Forms/Dropdown';
// import Button from '../../inc/Button';
// import PopupFooter from '../../Popup/PopupFooter';
// class TeamPopup extends Component {
//     constructor(props){
//         super(props);
//         this.teamGrid = null;
//         this.state = {
//             gridData:[],
//             isLoading:false
//         }
//     }
//     componentDidMount(){

//     }
//     onClose(){
//         let data = [];
//         if(this.props.onClose){
//             this.props.onClose(data)
//         }
//         $('html').removeClass('rs_popup_open');
//     }
    
//     loadTeamLines(teamheader_id){
//         this.teamGrid.api.showLoadingOverlay();
//         let api = Api, that = this;
//         api.setUserToken();
//         that.setState({
//             gridData:[]
//         })
//         api.axios().get(Settings.apiUrl + '/get_valid_members/'+teamheader_id).then(res => {
            
//             that.setState({
//                 gridData: res.data.data
//             })
//             that.teamGrid.api.hideOverlay();
//         }).catch(error => {
//             that.teamGrid.api.hideOverlay();
//         })
//     }
//     teamOnchangeHandler(event){
//         $('#btn_select_team_members').addClass('disable')
//         this.loadTeamLines(event.target.value);
//     }
//     onSelectionChanged(params){
//         let selectedData = params.api.getSelectedRows();
//         if(selectedData.length <1){
//             $('#btn_select_team_members').addClass('disable')
//         }else{
//             $('#btn_select_team_members').removeClass('disable')
//         }
//     }
//     onSelectButtonClickHander(){
//         let selectedData = this.teamGrid.api.getSelectedRows();
//         let that = this;
//         if(selectedData.length){
//             this.props.createNew(selectedData,(res)=> {
//                 if(res.data.status == 'fail'){
//                     Helper.alert(res.data.message,'error')
//                 }else{
//                     that.onClose()
//                 }
//             })
//         }
//     }
//     render() {
//         let teams = this.props.teamList.map( item => {
//             return {
//                 value: item.teamheader_id,
//                 label:item.name
//             }
//         })
//         let header = this.props.header.map( item => {
//             return {
//                 ...item,
//                 editable:false
//             }
//         });
        
//         return (
//             <Popup autoOpen={true} onClose= {this.onClose.bind(this)} width="900px">
//                 <h5>{Helper.getLabel(null,'pick_a_team','Pick a team')}</h5>
//                 <div className='row'>
//                     <div className='col-xs-12 col-md-4'>
//                         <DropDownInput onChange={this.teamOnchangeHandler.bind(this)} name="team_header_select" id="team_header_select" label="Teams" options={teams} />
//                     </div>
//                 </div>
//                 <AgGrid  id="team_grid_popup" onSelectionChanged ={ this.onSelectionChanged.bind(this)} readOnlyGrid={true} allBtnHide={true} hideSave={true} hideDelete={true} onGridReady={ gridOBj => this.teamGrid = gridOBj } header={ header } data={this.state.gridData} />
//                 <PopupFooter>
//                     <Button onClick={this.onClose.bind(this)} className="btn_md light_gray" title={Helper.getLabel(null,'close','Close')} />
//                     <Button id="btn_select_team_members" onClick={this.onSelectButtonClickHander.bind(this)} className="blue btn_md disable" title={Helper.getLabel(null,'select','Select')} />
//                 </PopupFooter>
//             </Popup>
//         );
//     }
// }

// export default TeamPopup;

import React, { useState, useEffect, useRef } from 'react';
import Popup from '../../Popup/Popup';
import $ from 'jquery';
import Helper from '../../../inc/Helper';
import AgGrid from '../../grid/ag/ag-grid';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import DropDownInput from '../../Forms/Dropdown';
import Button from '../../inc/Button';
import PopupFooter from '../../Popup/PopupFooter';
import AgGridNew from '../../grid/ag/ag-grid-new';
import SimpleLoading from '../../Loading/SimpleLoading';

const TeamPopup = (props) => {
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const teamGrid = useRef(null);
    const teamheader_id = useRef(null)
    const [rerenderGrid, setRerenderGrid] = useState(false);

    const onClose = () => {
        let data = [];
        if (props.onClose) {
            props.onClose(data);
            teamheader_id.current = null
        }
        $('html').removeClass('rs_popup_open');
    };

    const teamOnchangeHandler = (event) => {
        $('#btn_select_team_members').addClass('disable');
        teamheader_id.current = event.target.value
        setRerenderGrid(prevState => !prevState);
    };

    const onSelectionChanged = (params) => {
        let selectedData = params.api.getSelectedRows();
        if (selectedData.length < 1) {
            $('#btn_select_team_members').addClass('disable');
        } else {
            $('#btn_select_team_members').removeClass('disable');
        }
    };

    const onSelectButtonClickHander = () => {
        let selectedData = teamGrid.current.api.getSelectedRows();
        if (selectedData.length) {
            props.createNew(selectedData, (res) => {
                if (res.data.status === 'fail') {
                    Helper.alert(res.data.message, 'error');
                    teamheader_id.current = null
                } else {
                    onClose();
                }
            });
        }
    };

    const teams = props.teamList.map(item => ({
        value: item.teamheader_id,
        label: item.name
    }));

    const header = props.header.map(item => ({
        ...item,
        editable: false
    }));

    return (
        <Popup autoOpen={true} onClose={onClose} width="900px">
            <h5>{Helper.getLabel(null, 'pick_a_team', 'Pick a team')}</h5>
            <div className='row'>
                <div className='col-xs-12 col-md-4'>
                    <DropDownInput onChange={teamOnchangeHandler} name="team_header_select" id="team_header_select" label="Teams" options={teams} />
                </div>
            </div>
            {/* <AgGrid id="team_grid_popup" onSelectionChanged={onSelectionChanged} readOnlyGrid={true} allBtnHide={true} hideSave={true} hideDelete={true} onGridReady={gridOBj => teamGrid.current = gridOBj} header={header} data={gridData} /> */}
            <AgGridNew  key={rerenderGrid ? 'rerender' : 'no-rerender'}  gridId={"team_grid_popup"} columnDefs={header} hideAddBtn={true} apiUrl={Settings.apiUrl + '/get_valid_members/' + teamheader_id?.current} returnApiCondition={!teamheader_id?.current} hideDeleteBtn={true} onSelectionChanged={onSelectionChanged} onGridReady={gridOBj => teamGrid.current = gridOBj}/>
            <PopupFooter>
                <Button onClick={onClose} className="black-btn-style" title={Helper.getLabel(null, 'close', 'Close')} />
                <Button id="btn_select_team_members" onClick={onSelectButtonClickHander} className="blue btn_md disable" title={Helper.getLabel(null, 'select', 'Select')} />
            </PopupFooter>
        </Popup>
    );
};

export default TeamPopup;
