import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import Helper from '../../../../inc/Helper'
import { getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator, purchaseStatusValueFormat } from '../../../grid/ag/commonFunctions/GridCommonFunctions'

const getPurchaseAgreementColumns = ({ language, purchaseStatuses, documentStatuses }) => {
  return [
    {
      field: 'document_number',
      headerName: Helper.getLabel(language, 'purchase_agreement_no', 'Purchase Agreement no.'),
      minWidth: 220,
      cellRenderer: (params) => gridLinkValueFormat(params),
    },
    {
      field: 'description',
      minWidth: 180,
      headerName: Helper.getLabel(language, 'description', 'Description'),
      editable: false,
    },
    {
      field: 'vendor_code',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'),
      editable: false,
      cellRenderer: gridLinkValueFormat,
    },
    {
      field: 'vendor_name',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(language, 'site', 'Site name'),
      editable: false,
      minWidth: 200,
    },
    {
      field: 'purchase_status',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'purchase_status', 'Purchase status'),
      editable: false,
      cellRenderer: (params) => purchaseStatusValueFormat(params, purchaseStatuses),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, purchaseStatuses),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: 'document_status',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'document_status', 'Document status'),
      editable: false,
      cellRenderer: (params) => getStatusNameById(params.value, documentStatuses),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, documentStatuses),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: 'document_date',
      minWidth: 200,
      filter: 'agDateColumnFilter',
      headerName: Helper.getLabel(language, 'document_date', 'Document date'),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value),
      editable: false,
    },
    {
      field: 'start_date',
      minWidth: 200,
      filter: 'agDateColumnFilter',
      headerName: Helper.getLabel(language, 'start_date', 'Start date'),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value),
      editable: false,
    },
    {
      field: 'expiry_date',
      minWidth: 200,
      filter: 'agDateColumnFilter',
      headerName: Helper.getLabel(language, 'end_date', 'End date'),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value),
      editable: false,
    },
    {
      field: 'accounting_date',
      minWidth: 200,
      filter: 'agDateColumnFilter',
      headerName: Helper.getLabel(language, 'accounting_date', 'Accounting date'),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value),
      editable: false,
      hide: true,
    },
    {
      field: 'delivery_date',
      minWidth: 200,
      hide: true,
      filter: 'agDateColumnFilter',
      headerName: Helper.getLabel(language, 'delivery_date', 'Delivery date'),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value),
      editable: false,
    },
    {
      field: 'delivery_name',
      minWidth: 200,
      hide: true,
      headerName: Helper.getLabel(language, 'delivery_name', 'Delivery name'),
      editable: false,
    },
    {
      field: 'discount_amount',
      minWidth: 200,
      hide: true,
      filter: 'agNumberColumnFilter',
      headerName: Helper.getLabel(language, 'discount_amount', 'Discount amount'),
      editable: false,
    },
    {
      field: 'discount_percentage',
      minWidth: 200,
      hide: true,
      filter: 'agNumberColumnFilter',
      headerName: Helper.getLabel(language, 'discount_percentage', 'Discount percentage'),
      editable: false,
    },
  ]
}

export default getPurchaseAgreementColumns
