import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import $ from 'jquery'
import { ValidateArray, getPopupMessageBasedOnStatus } from '../../../inc/Validation';  
import Alert from '../../inc/Alert'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';

/* Validations -> name cannot be greater than 255 */

let gridApi = null;
let AddBtn;
let transaction;

const ItemGroupAccountType = (props) => {

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.itemgroupaccounttype_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }
  
  const updateData = async (event) => {  
    try {
      let payload = {
        name: event?.data?.name
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiItemUrl+`/item_groupaccount_type/${event?.data?.itemgroupaccounttype_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
  
  const addNewData = async (event) => {
    try {
      let payload = {
        name: event?.data?.name
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiItemUrl+'/item_groupaccount_type', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }

  const onDeleteItemGroupAccountType = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_item_group_account_type_id = element?.data?.itemgroupaccounttype_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/item_groupaccount_type/${delete_item_group_account_type_id}`)
      const rowNode = gridApi?.getRowNode(delete_item_group_account_type_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item_group_account_type.item_group_account_type_main)){
    return <Fragment> 
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  /* Grid Column Headers */

  const gridColumnHeaders = [ 
    {field:'name', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(props.language, 'name', 'Name') } 
  ];

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} backUrl='/setup' title='Item Group - Account Type' />
   
      <AgGridNew
        apiUrl={Settings.apiItemUrl+'/item_groupaccount_type'}
        pagination={false}
        columnDefs={gridColumnHeaders}
        onRowValueChanged={onRowValueChanged}
        hideDeleteBtn={!security.canDelete(frontendIds.masterData.item_master_modules.item_group_account_type.item_group_account_type_main_delete_button)}
        hideAddBtn={!security.canCreate(frontendIds.masterData.item_master_modules.item_group_account_type.item_group_account_type_main_create_button)}
        handleDeleteSelectedRows={onDeleteItemGroupAccountType}
        height={500}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        uniqueField={`itemgroupaccounttype_id`}
        gridId={'item-group-account-type-setup-forms'}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.masterData.item_master_modules.item_group_account_type.item_group_account_type_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(ItemGroupAccountType, SecurityOptions)))