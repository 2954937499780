import React, { Fragment, useCallback } from 'react'; 
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api'; 
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux'; 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import Alert from '../../inc/Alert';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';

let gridApi = null;
let AddBtn;
let transaction;

const PurchaseAgreementClassifications = (props) => {

  const updateData = async (event) => {  
    try {
      let payload = {
        name: event?.data?.name,
        description: event?.data?.description,
        required_direct_invoicing: event?.data?.required_direct_invoicing,
        release_order_mandatory:event?.data?.release_order_mandatory
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiPurchaseOrderUrl+`/PA_classification/${event?.data?.pa_classification_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  } 

  const addNewData = async (event) => { 
    try {
      let payload = {
        name: event?.data?.name,
        description: event?.data?.description,
        required_direct_invoicing: event?.data?.required_direct_invoicing,
        release_order_mandatory:event?.data?.release_order_mandatory
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiPurchaseOrderUrl+'/PA_classification', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }
  
  const onDeleteSystemClassification = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_classification_id = element?.data?.pa_classification_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl+`/PA_classification/${delete_classification_id}`)
      const rowNode = gridApi?.getRowNode(delete_classification_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }
 
  const onRowValueChanged = (event) => {
    if (event?.data?.pa_classification_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.formModules.purchase_agreement_classification.purchase_agreement_classification_main)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  } 

  /* Grid Header Columns */

  const gridColHeader = [
    {field:'name', editable: true, headerName:Helper.getLabel(props.language, 'name', 'Name')},
    {field:'description', editable: true, headerName:Helper.getLabel(props.language, 'description', 'Description')},
    {field:'required_direct_invoicing', editable: true, headerName:Helper.getLabel(props.language, 'required_direct_invoicing', 'Required direct invoicing'),cellRenderer:'agCheckboxCellRenderer',cellEditor:'agCheckboxCellEditor'},
    {field:'release_order_mandatory', editable: true, headerName:Helper.getLabel(props.language, 'mandatory_for_release_order', 'Mandatory for release order'),cellRenderer:'agCheckboxCellRenderer',cellEditor:'agCheckboxCellEditor'}
  ];

  return (
    <Fragment>
      <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language, 'purchase_agreement_classifications', 'Purchase Agreement Classifications')} />
 
      <AgGridNew
        apiUrl={Settings.apiPurchaseOrderUrl+'/PA_classification'}
        pagination={false}
        columnDefs={gridColHeader}
        onRowValueChanged={onRowValueChanged}
        hideDeleteBtn={!security.canDelete(frontendIds.formModules.purchase_agreement_classification.purchase_agreement_classification_main_delete_button)}
        hideAddBtn={!security.canCreate(frontendIds.formModules.purchase_agreement_classification.purchase_agreement_classification_main_create_button)}
        handleDeleteSelectedRows={onDeleteSystemClassification}
        height={500}
        newAddItems={{required_direct_invoicing:false,release_order_mandatory:false}}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        uniqueField={`pa_classification_id`}
        gridId={'purchase-agreement-setup-data'}
      /> 
    </Fragment>
  )
}
  
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.formModules.purchase_agreement_classification.purchase_agreement_classification_main
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(PurchaseAgreementClassifications, SecurityOptions))))