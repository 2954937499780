import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import ButtonNew from '../../inc/ButtonNew';
import Collapse from '../../inc/Collapse'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { useNavigate } from 'react-router-dom';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import TaxGroupItemModal from './TaxGroupItemModal';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../inc/Alert';

let gridApi = null;

const TaxGroupWIthItems = ({security}) => { 
    const [showCreateModal, setShowCreateModal] = useState(false);

    const language = useSelector(state => state.language); 
    const navigate = useNavigate();
 
    const onCellClicked = (event) => {
        navigate('/tax/edit-tax-group-with-items/' + event?.data?.taxgroupitem_id)
    };

    const deleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delTaxGroupItem_ID = element?.data?.taxgroupitem_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/taxgroupitem/${delTaxGroupItem_ID}`)
            const rowNode = gridApi?.getRowNode(delTaxGroupItem_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200);

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    /* Grid Column Headers */

    const gridColumnHeaders = [
        { field: 'name', cellRenderer: (params) => gridLinkValueFormat(params), editable: false, filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, "name", 'Group name') } 
    ];

    if(!security.canView(Gui_id_list.setupForms.tax_item_groups.tax_item_groups_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment> 
            <div className='container-fluid taxgroup_with_items'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'tax_item_group', 'Tax Item Group')}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.tax_item_groups.tax_item_groups_main_create_button)} onClick={() => setShowCreateModal(true)} />
                </NavigationHeder>

                <Collapse open={true} title={Helper.getLabel(language, 'groups', 'Groups')}>
                    <AgGridNew
                        apiUrl={Settings.apiUrl + '/taxgroupitem'}  
                        pagination={false}
                        hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.tax_item_groups.tax_item_groups_main_delete_button)}
                        columnDefs={gridColumnHeaders} 
                        hideAddBtn={true}
                        height={500}
                        uniqueField={'taxgroupitem_id'}
                        onCellClicked={onCellClicked}
                        handleDeleteSelectedRows={deleteHandler}
                        onGridReady={(params) => gridApi = params?.api} 
                        gridId={"tax_group_item_grid"}
                    />
                </Collapse>
            </div>
            {showCreateModal && <TaxGroupItemModal setShowCreateModal={setShowCreateModal} />}
        </Fragment>
    );
};
 
const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.tax_item_groups.tax_item_groups_main
};

export default ((( ApplySecurityRoles( TaxGroupWIthItems, SecurityOptions)))); 