import React, { useState } from 'react';
import { TextSkeletonComponent } from '../../Skeleton/SkeletonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown , faChevronUp } from '@fortawesome/free-solid-svg-icons';

const BlueCallToAction = (props) => {
    const [isExpanded,setIsExpanded]=useState(false)
    const displayTags = () => {
        const tags = props.tags ? props.tags : [];
        if (tags.length <= 0) {
            return <></>;
        }
        return (
            <div className='actions_tags'>
                {tags.map((tag, index) => {
                    const styleTag = tag.style ? tag.style : {};
                    return <span style={styleTag} className={tag.type ? tag.type : 'success'} key={index}>{tag.title}</span>;
                })}
            </div>
        );
    };

    const items = props.items ? props.items : [];
    const scrollDitections = props.scrollDitection;

    return (
        <div className={'rs_calltoaction_section ' +  (scrollDitections !== 'up' && !isExpanded ? '' : 'nav_position_style_1')}>
            {props.title ? <h2 className='rs_blue_section_title'>{props.title}</h2> : ''}
            <div className={'rs_calltoaction_items ' + (scrollDitections !== 'up' && !isExpanded ? '' : 'rs_calltoaction_items_width')}>
                {items.map((item, key) => (
                    <div className='rs_calltoaction_item action_item_header' key={key}>
                        <h3>
                           {item.title}
                        </h3>
                        <p className='rfq-break-line'>
                            {typeof item.subtitle === 'string' || typeof item.subtitle === 'number' ? (
                                item.title === 'Expiry Date and Time' || item.title === 'Publish Date and Time' ? (
                                    <>
                                        <span>{`${item.subtitle}`.split(' ')[0]}</span>
                                        <span>{`${item.subtitle}`.split(' ')[1]} {`${item.subtitle}`.split(' ')[2]}</span>
                                    </>
                                ) : (
                                    <>
                                        {`${item.subtitle}`.split(' ').map((word, index) => (
                                            <span key={index}>{word}</span>
                                        ))}
                                    </>
                                )
                            ) : (
                                <TextSkeletonComponent />
                            )}
                        </p>
                    </div>
                ))}
            </div>
            {displayTags()}
            <button className='border-0 bg-transparent p-0 expand-blue-btn' onClick={()=>{scrollDitections!=="up"&&setIsExpanded(!isExpanded)}}><FontAwesomeIcon icon={isExpanded?faChevronDown:faChevronUp} size='1x' /></button>
        </div>
    );
};

export default BlueCallToAction;