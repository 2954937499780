import React, { Component } from 'react';
import LineViewComponent from '../Edit PO/ListView/LineViewComponent';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper';
import Alert from '../../inc/Alert';

class LineViewVersion extends Component {

    render() {
        if(this.props.version === null || this.props.version===undefined){
            return <Alert message={"version props required"}/>
        }
        return (
            <div className='po_line_view_history'>
                <LineViewComponent 
                    viewType="version"
                    defaultValues ={{}}
                    source_id={this.props.source_id?this.props.source_id:Settings.source_id.purchase_order} 
                    line_source={Settings.source_id.purchase_order} 
                    isEditable={false} 
                    lineGridTitle = {Helper.getLabel(null,'purchase_order_lines',"Purchase Order Lines")}
                    header_id={this.props.purchaseorder_id}
                    purchase_status={0}
                    document_status={0}
                    delivery_date={''}
                    master_save_button_id = "btn_master_save"
                    onbehalf_request =  {false}
                    vendor_id = {null}
                    vendorsite_id = {null}
                    version={this.props.version}
                    hideBillingRule={this.props.hideBillingRule}
                    //setOrderStatusProps={}
                />
            </div>
        );
    }
}

export default LineViewVersion;