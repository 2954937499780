import React, { useEffect, useState } from 'react';
import Popup from '../../../Popup/Popup';
import $ from 'jquery';
import Helper from '../../../../inc/Helper';
import { connect } from 'react-redux';
import DropDownInput from '../../../Forms/Dropdown'
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings'; 
import Collapse from '../../../inc/Collapse';
import SimpleLoading from '../../../Loading/SimpleLoading';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { getDisplayNamesFromArray, getNewValue, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import Button from '../../../inc/Button';

// keeping variables presistent irrespectives of component state renders 
let allTemplates = []

const ImportTemplatePopup = ({closePopup, reloadScoringGrid, rfq_id, language}) => {
  // dropdown options state:
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [template, setTemplates] = useState([])
  const [sections, setSections] = useState([])
  const [typeDropdown, setTypeDropdown] = useState([])

  // sections/component-wide loading states:
  const [loading, setLoading] = useState(true)
  const [loadingSections, setLoadingSections] = useState(false)
 
  // API Reduction Calls  
  let scoringMasterCalled = false
  let typeCalled = false
  // API renderer functions 

  useEffect(() => {
    scoringMasterAPI();
    TypeDropdownAPI();
  }, []);

  // API Calls / Modules: 
  const scoringMasterAPI = () => {
    if(scoringMasterCalled) return;

    let api = Api
    scoringMasterCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+`/scoringMaster`,{}).then(function(res){
      if(res.data.status==="success"){    
        let UPDATED_DATA = []
        res.data.data.forEach(element => {
          UPDATED_DATA.push({
            value: element.scoringmaster_id,
            label: element.name
          })
        })
        setTemplates(UPDATED_DATA) 
        allTemplates=res.data.data
        setLoading(false)
      }
    }).catch((error) => {  
      console.log(error) 
      setLoading(false)
    })
  }

  const getSectionsOfScoringMaster = (id) => {
    if(!id) return;

    setLoadingSections(true)
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+`/scoringsections/${id}`,{}).then(function(res){
      if(res.data.status==="success"){    
        setSections(res.data.data) 
        setLoadingSections(false)
        let currentObject = !checkIfArrayIsEmpty(allTemplates) && allTemplates.find(val => val.scoringmaster_id == id)
        setSelectedTemplate(currentObject)
      }
    }).catch((error) => {  
      console.log(error)
      setLoadingSections(false)
      setSelectedTemplate({})
    })
  }

  const TypeDropdownAPI = () => {
    if(typeCalled) return;  

    let api = Api
    typeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/RF_ScoringSection_type`,{}).then(function(res){
      if(res.data.status==="success"){    
        let UPDATED_DATA = []
        res.data.data.forEach(element => {
          UPDATED_DATA.push({
            value: element.id,
            label: element.name
          })
        })
        setTypeDropdown(UPDATED_DATA) 
      }
    }).catch((error) => {  
      console.log(error)
    })
  }

  // filtering master scoring: 
  const TemplateChangeHandler = (evt) => {
    if(!evt.target.value) return; 
    getSectionsOfScoringMaster(evt.target.value) // fetching corresponding sections of scoring master got from templates dropdown,
  }

  // import templates modules : 
  const filterByMasterScoring = () => {
    return (
      <DropDownInput label={Helper.getLabel(language,'templates',"Templates")} options={template} id='template_id' required={true} onChange={TemplateChangeHandler} value={null} />  
    )
  }

  const SectionsGrid = () => {
    const GridColumnSections = [
      {field:'name', filter: 'agTextColumnFilter', cellRenderer: 'agGroupCellRenderer' , headerName:Helper.getLabel(language,'name',"Name*"), editable:false},
      {field:'sequence', filter: 'agNumberColumnFilter', editable:false, headerName:Helper.getLabel(language,'sequence',"Sequence*")},
      {field:'weight', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(language, 'weight', "Weight"), editable:false},
      {
        field:'section_type', 
        cellRenderer: (params) => getDisplayNamesFromArray(params, typeDropdown, 'value', 'label'),
        headerName:Helper.getLabel(language,'type',"Type"), 
        editable:false,
        filterParams: { 
          values: (params) => getOptions(params, typeDropdown), 
          keyCreator: (params) => optionsKeyCreator(params, 'value'), 
          valueFormatter: params => params.value.label 
        }, 
        filter: 'agSetColumnFilter'
      }
    ];

    let masterSettings = {
      detailRowAutoHeight: true,
      masterDetail: true,  
      detailCellRendererParams: {
        detailGridOptions: {
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          enableRangeSelection: true, 
          columnDefs: [
            {field: 'sequence', filter: 'agNumberColumnFilter', editable: false, headerName:'Sequence*', checkboxSelection: true},
            {field: 'name', filter: 'agTextColumnFilter', editable: false, headerName:'Name*'},
            {field: 'description', filter: 'agTextColumnFilter', editable: false, headerName:'Description'},
            {field: 'comments_mandatory', filter: 'agSetColumnFilter', cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', cellRendererParams: { disabled: true }, editable: false, headerName:'Comments mandatory',
              filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
              } 
            }
          ],
          defaultColDef: {
            sortable: true,
            flex: 1,
          },  
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.criteria); 
        },
        template:
        '<div style="background-color: #FDF4E7; padding: 0px; box-sizing: border-box;">' +
        '  <div style="color:#2D74E0; padding-top: 10px; margin-left: 15px; padding-bottom: 10px; font-size: 18px; font-weight: 600;">Criteria</div>' +
        '  <div ref="eDetailGrid" style=" "></div>' +
        '</div>'
      },
    } 

    let dataSource = {
      getRows: async function (params) {
        params?.success({
          rowData: sections?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}`
          })),
          rowCount: sections?.length
        })
      }
    };

    const onGridReady = (params) => { 
      if(params) { 
        setTimeout(()=>{ 
          params?.api?.setGridOption('serverSideDatasource', dataSource);
        }, 200)
      } 
    };

    return (
      <Collapse open={true} title={Helper.getLabel(language,'sections',"Sections")}>
        <div className='grid_save_btn_hide'>
          <AgGridNew 
            hideAddBtn={true}  
            height={500}
            hideDeleteBtn={true}
            isDisabledCheckbox={true}
            gridId={'import_template_rfq_sections'}
            columnDefs={GridColumnSections}
            apiUrl={null} 
            masterSettings={masterSettings} 
            onGridReady={onGridReady}
            pagination={false}
          />
      </div>
      </Collapse>
    )
  }

  const actionBtns = () => {
    return (
      <div className='d-flex justify-content-end align-items-center'>
        <Button 
          title={Helper.getLabel(language, "import", "Import")}
          onClick={SaveHandler}
          className='blue-btn-style'
        /> 
      </div>
    )
  }

  // ---

  // Popup close handler --
  const onPopupCloseHandler = () => {
    closePopup((prevState) => {
      return {
        ...prevState,
        importTemplatePopup: false
      }
    })
    $('html').removeClass('rs_popup_open');
  }

  // ---

  // API Executions Handler:  
  const executeAPIForMasterScoringCreation = () => {
    if(!rfq_id) return;

    let api = Api 
    api.setUserToken()
    api.axios().post(Settings.apiPurchaseRequisitionUrl+`/scoringMaster?for_rfq=True`, {
      ...selectedTemplate,
      "RFQ_id": rfq_id
    }).then(function(res){
      if(res.data.status==="success"){     
        if(!res.data.scoringmaster_RFQ_id) return;
        executeAPIForMasterScoringSectionsCreation(res.data.scoringmaster_RFQ_id)
      }
    }).catch((res) => {   
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const executeAPIForMasterScoringSectionsCreation = (id) => {
    let api = Api 
    api.setUserToken()
    api.axios().post(Settings.apiPurchaseRequisitionUrl+`/scoringSection?for_rfq=True`, {
      scoringmaster_id: id,
      sections: sections
    }).then(function(res){
      if(res.data.status==="success"){  
        Helper.alert('Sections Imported Successfully!')  
        onPopupCloseHandler()
        reloadScoringGrid()
      }
    }).catch((res) => {   
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const SaveHandler = () => { 
    if(checkIfArrayIsEmpty(sections)){
      Helper.alert('No Sections to import, please find any other template', 'error')
      return;
    }
    
    executeAPIForMasterScoringCreation()
  }

  return (
    <div className='common_responsive_class_popup_component'> 
      <Popup onClose={onPopupCloseHandler} autoOpen={true}> 
        {loading ? <SimpleLoading /> : 
          <div className='container-fluid'>
            { actionBtns() }
            { filterByMasterScoring() }
            { loadingSections ? <SimpleLoading /> : SectionsGrid() } 
          </div>
        } 
      </Popup>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (ImportTemplatePopup); 