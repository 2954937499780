import React, {useState, useEffect, Fragment, useRef} from 'react'
import NavigationHeder from '../Navigations/NavigationHeder';
import LinkBtn from '../inc/LinkBtn';
import Button from '../inc/Button';
import Helper from '../../inc/Helper';
import { connect } from 'react-redux';
import MasterComponentWraper from '../Backend/MasterComponentWraper';
import SimpleLoading from '../Loading/SimpleLoading';
import DropDownInput from '../Forms/Dropdown';
import FormValidator from '../../inc/FormValidator';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../inc/Validation';
import { areObjectsDifferent } from '../../inc/Validation';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../inc/Alert';
import Dropdown from '../Forms/Dropdown';

let defaultORConfigObj = null // default object setting for comparisons

const ORConfiguration = (props) => {
    const [orgConfig, setOrgConfig] = useState({}) // organisation configuration - data/actual object/ configurations
    const [entityList, setEntityList] = useState([]) // entity data --dropdown
    const [ficalCalendar, setFicalCalendar] = useState([]) // fiscal calendar data --dropdown
    const [currency, setCurrency] = useState([]) // currenct data --dropdown
    const [vendorGroup, setVendorGroup] = useState([]) //VNGroup data --dropdown
    const [vendorGroupPrePay, setVendorGroupPrePay] = useState([]) // VNGroup pre-payment data --dropdown
    const entityDataRef = useRef([])

    const [loading, setLoading] = useState(false) // loading state -

    // API Calls -- 
    useEffect(() => {
      if(!props.auth.user.Employee.Entity_id) return;
      getOrganisationConfigurations(props.auth.user.Employee.Entity_id)
    }, [props.auth?.user?.Employee?.Entity_id])

    useEffect(() => {  
      EntityAPI()
      FiscalCalendarAPI()
      CurrencyAPI()
      VNGroupAPI()
      VNGroupPrePaymentAPI()
    }, [])

    // ---

    // Validating Fields --
    let validator; 
    const validationConfigure = () => {
      let fieldConfig = [ 
        {name:'fiscalcalendar_id', displayName:Helper.getLabel(props.language,'fiscalcalendar_id','Fiscal Calendar'),types:['Required'] },
        {name:'vendorgroup_id', displayName:Helper.getLabel(props.language,'vendorgroup_id','Vendor Group'),types:['Required'] }, 
        {name:'currency_id', displayName:Helper.getLabel(props.language,'currency_id','Currency'),types:['Required']},
        {name:'vendorgroup_prepayment_id', displayName:Helper.getLabel(props.language,'vendorgroup_prepayment_id','Vendor Group Pre-Payment'),types:['Required']}
      ]
      if(!validator) {
        validator = new FormValidator(fieldConfig, props.language)
      }
    }
    validationConfigure()
    // ---

    // API Reduction Calls / Flags --
    let entityCalled = false
    let fiscalCalendarCalled = false
    let currencyCalled = false
    let vendorGroupCalled = false
    let vendorGroupPrePaymentCalled = false
    /// --

    // get ORConfigurations Data --
  
    const getOrganisationConfigurations = (entity) => {  
      if(!entity){ // getting the entity from the user details api, which is for authentication purposes 
        return;
      }
      setLoading(true)
      let api = Api 
      api.setUserToken()
      api.axios().get(Settings.apiUrl+`/entity_configuration/${entity}`,{}).then(function(res){
        if(res.data.status === "success"){
          setLoading(false) 
          setOrgConfig(res.data.data)
          defaultORConfigObj = res.data.data
        } 
      }).catch((res) => { 
        setLoading(false)
        getPopupMessageBasedOnStatus(res) 
      })
    }

    // ----

    // Dropdown APIs Functions --
    
    const EntityAPI = () => {
      if(entityCalled){
        return;
      }
      let api = Api
      entityCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/get_entities').then(function(res){
        if(res.data.status==="success"){
          let EntityARR = []
          res.data.data.forEach(item => {  
          EntityARR.push({
            value: item.entity_id,
            label: `${item.name}`
          })
          }) 
          setEntityList(EntityARR)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const FiscalCalendarAPI = () => {
      if(fiscalCalendarCalled){
        return;
      }
      let api = Api
      fiscalCalendarCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/fiscalcalendar',{}).then(function(res){
        if(res.data.status === "success"){
          let FiscalArr = []
          res.data.data.forEach(item => {
          FiscalArr.push({
            value: item.fiscalcalendar_id,
            label: item.description
          })
          })
          setFicalCalendar(FiscalArr)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    } 

    const CurrencyAPI = () => {
      if(currencyCalled){
        return;
      }
      let api = Api
      currencyCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
        if(res.data.status==="success"){
          let CurrencyArr = []
          res.data.data.forEach(item => {  
          CurrencyArr.push({
            value: item.currency_id,
            label: `${item.currency_code} - ${item.currency_name}`
          })
          })
          setCurrency(CurrencyArr)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const VNGroupAPI = () => {
      if(vendorGroupCalled){
        return;
      }
      let api = Api
      vendorGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{}).then(function(res){
        if(res.data.status==="success"){
          let VendorGroup = []
          res.data.data.forEach(item => {  
          VendorGroup.push({
            value: item.vendorgroup_id,
            label: item.name 
          })
          }) 
          setVendorGroup(VendorGroup)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const VNGroupPrePaymentAPI = () => {
      if(vendorGroupPrePaymentCalled){
        return;
      }
      let api = Api
      vendorGroupPrePaymentCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{ }).then(function(res){
        if(res.data.status==="success"){
          let vendorGroupPrePayment = []
          res.data.data.forEach(item => {  
          vendorGroupPrePayment.push({
            value: item.vendorgroup_id,  
            label: item.name  
          })
          }) 
          setVendorGroupPrePay(vendorGroupPrePayment)
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    } 

    // ---

    // Dropdown Handlers -- 

    const mapEntityData = (item) => {
      return {
        text: `${item.name}`,
        id: item.entity_id
      };
    };

    const EntityHandler = (event) => { 
      getOrganisationConfigurations(event?.target?.value)
      setOrgConfig((prevState) => {
        return {
          ...prevState,
          entity_id: event.target.value
        }
      })
    }

    const FiscalHandler = (event) => {
      setOrgConfig((prevState) => {
        return {
          ...prevState,
          fiscalcalendar_id: event.target.value
        }
      })
    }

    const VendorGroupHandler = (event) => {
      setOrgConfig((prevState) => {
        return {
          ...prevState,
          vendorgroup_id: event.target.value
        }
      })
    }

    const CurrencyHandler = (event) => {
      setOrgConfig((prevState) => {
        return {
          ...prevState,
          currency_id: event.target.value
        }
      })
    }

    const VendorGroupPrePayHandler = (event) => {
      setOrgConfig((prevState) => {
        return {
          ...prevState,
          vendorgroup_prepayment_id: event.target.value
        }
      })
    }

    // --

    // Module --
    const OrgConfiguration = () => {
      return (
        <div className='row'>
          <div className='col-lg-4'>  
            <Dropdown
              label={`${Helper.getLabel(
                props.language,
                "entity",
                "Entity"
              )}`}
              options={ orgConfig?.entity_id
                ? [{
                    value: orgConfig?.entity_id,
                    label: orgConfig?.entity_name ?? entityDataRef?.current?.find(item => parseInt(item.entity_id) === parseInt(orgConfig?.entity_id))?.name,
                  }]
                : entityList
              }
              apiUrl={Settings.loginUrl + "/get_entities"} 
              pagination={true}
              mapFunction={mapEntityData} 
              allDataRef={entityDataRef}  
              placeHolder={`${Helper.getLabel( props.language, "select_entity", "Select entity" )}`}
              id="select_entity_id"
              reRenderRequired={true}
              onChange={EntityHandler}
              value={orgConfig?.entity_id}
            /> 
          </div>
          <div className='col-lg-4'>
            <DropDownInput placeHolder={`${Helper.getLabel( props.language, "select_fiscal_calendar", "Select fiscal calendar" )}`} id='fiscalcalendar_id' value={orgConfig.fiscalcalendar_id} onChange={FiscalHandler} label={Helper.getLabel(props.language,'fiscal_calendar','Fiscal calendar')} required={true} options={ficalCalendar} />
          </div>
          <div className='col-lg-4'>
            <DropDownInput placeHolder={`${Helper.getLabel( props.language, "select_vendor_group", "Select vendor group" )}`} id='vendorgroup_id' value={orgConfig.vendorgroup_id} onChange={VendorGroupHandler} label={Helper.getLabel(props.language,'vendor_group','Vendor group')} required={true} options={vendorGroup} />
          </div>
          <div className='col-lg-4'>
            <DropDownInput placeHolder={`${Helper.getLabel( props.language, "select_currency", "Select currency" )}`} id='currency_id' value={orgConfig.currency_id} onChange={CurrencyHandler} label={Helper.getLabel(props.language,'currency_id','Currency')} required={true} options={currency} />
          </div>
          <div className='col-lg-4'>
            <DropDownInput placeHolder={`${Helper.getLabel( props.language, "select_vendorgroup_prepayment", "Select vendor group pre-payment" )}`} id='vendorgroup_prepayment_id' value={orgConfig.vendorgroup_prepayment_id} onChange={VendorGroupPrePayHandler} label={Helper.getLabel(props.language,'vendorgroup_prepayment_id','Vendor group pre payment')} required={true} options={vendorGroupPrePay} />
          </div>
        </div>
      )
    }
    // ---
    
    // API Execution --

    const SaveORConfiguration = () => { 
      let api = Api 
      api.setUserToken() 
      if(objectIsEmpty(defaultORConfigObj)){ 
        api.axios().post(Settings.apiUrl+'/entity_configuration',{
          "entity_id": orgConfig.entity_id ? parseInt(orgConfig.entity_id) : null,
          "fiscalCalendar_id": parseInt(orgConfig.fiscalcalendar_id),
          "vendorgroup_id":parseInt(orgConfig.vendorgroup_id),
          "currency_id":parseInt(orgConfig.currency_id),
          "vendorgroup_prepayment_id": parseInt(orgConfig.vendorgroup_prepayment_id)
        }).then(function(res){
          if(res.data.status==="success"){ 
            Helper.alert(res.data.message)
          } 
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
        })
      }

      if(!objectIsEmpty(defaultORConfigObj)){ 
        api.axios().put(Settings.apiUrl+`/entity_configuration/${orgConfig.rec_id}`,{ 
          "fiscalCalendar_id": parseInt(orgConfig.fiscalcalendar_id),
          "vendorgroup_id":parseInt(orgConfig.vendorgroup_id),
          "currency_id":parseInt(orgConfig.currency_id),
          "vendorgroup_prepayment_id": parseInt(orgConfig.vendorgroup_prepayment_id)
        }).then(function(res){
          if(res.data.status==="success"){ 
            Helper.alert(res.data.message)
          } 
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
        })
      }
    }

    // ---

    // Calling API Functions --
    const SaveHandler = () => {
      if(!areObjectsDifferent(defaultORConfigObj, orgConfig) && !objectIsEmpty(defaultORConfigObj)){
        return;
      }
      let newData = { 
        fiscalcalendar_id: orgConfig.fiscalcalendar_id,
        vendorgroup_id: orgConfig.vendorgroup_id, 
        currency_id: orgConfig.currency_id,
        vendorgroup_prepayment_id: orgConfig.vendorgroup_prepayment_id
      }
      let validData = {
        ...newData
      }
      if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
      }

      SaveORConfiguration()
    }
    // --

    if(!props?.security.canView(Gui_id_list.administrationForms.or_configuration.or_configuration_main)){ 
      return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </Fragment>
    }
  return (
    <div className='org_configuration'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(props.language,'orgainsation_config',"Orgainsation Configuration")}>
          <LinkBtn className='black-btn-style' isActive= {false} to="/administration" title={Helper.getLabel(props.language,'close',"Close")} />
          <Button isDisable={!props?.security.canCreate(Gui_id_list.administrationForms.or_configuration.or_configuration_main_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler}  />
        </NavigationHeder>

        {loading ? <SimpleLoading /> : OrgConfiguration()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

const SecurityOptions = {
  gui_id:Gui_id_list.administrationForms.or_configuration.or_configuration_main
};
 
export default connect(mapStateToProps) (MasterComponentWraper(( ApplySecurityRoles( ORConfiguration, SecurityOptions)))); 