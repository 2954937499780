import React, { useEffect, useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import AgGrid from '../grid/ag/ag-grid'
import Api from '../../inc/Api'
import { useNavigate } from 'react-router-dom'
import CustomHtmlCell from '../grid/ag/cellRender/CustomHtmlCell'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'

const AnswerGroup = (props) => {
  const [answerGroupData, setAnswerGroupData] = useState([])

  const navigate = useNavigate()

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link">${params.value ? params.value : '-'}</div>`
  }

  const getAnswerGroupData = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/answergroup`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setAnswerGroupData(res.data?.data)
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const onAnswerGroupDelete = (allRows) => {
    let api = Api
    api.setUserToken()
    allRows.forEach((element) => {
      api
        .axios()
        .delete(Settings.apiUrl + `/answergroup/${element.answergroup_id}`)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            getAnswerGroupData()
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res)
        })
    })
  }

  const onRowValueChanged = (event) => {
    let payload = {
      description: event?.data?.description,
      answer_group_for_vendor_performance: event?.data?.answer_group_for_vendor_performance === "yes" ? true : false,
    }
    if (event?.data?.answergroup_id) {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .put(Settings.apiUrl + `/answergroup/${event?.data?.answergroup_id}`, payload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    } else {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .post(Settings.apiUrl + '/answergroup', payload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            getAnswerGroupData()
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    }
  }

  const onCellClicked = (event) => {
    if (event?.colDef.field === 'description') {
      navigate(`/answer-details?answergroup_id=${event?.data?.answergroup_id}`)
    }
  }

  useEffect(() => {
    getAnswerGroupData()
  }, [])

  const Columns_Headings = [
    {
      field: 'description',
      editable: true,
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      valueFormatter: gridLinkValueFormat,
      cellRenderer: CustomHtmlCell,
    },
    {
      field: 'answer_group_for_vendor_performance',
      editable: true,
      headerName: Helper.getLabel(props.language, 'allow_group_for_vendor_performance', 'Answer group for vendor performance'),
      inputType:'checkbox',
    },
  ]
  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'answer_groups  ', 'Answer groups')}`} hideMoreBtn={true}>
        </NavigationHeder>
        <div>
          <div>
            <AgGrid id='rfq-questionnaire-group' header={Columns_Headings} data={answerGroupData} onDelete={onAnswerGroupDelete} onRowValueChanged={onRowValueChanged} hideSave={true} onCellClicked={onCellClicked} />
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

export default AnswerGroup
