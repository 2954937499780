import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import $ from 'jquery';
class ItemSearchComponent {
    init(params) {
        this.params= params;
        this.skey = params.api.rsPrevParams ? params.api.rsPrevParams.sKey : null;
        this.ppp = 10;
        this.page = 1;
        let that = this;
        this.eGui = document.createElement('div');
        
        this.closeButton = document.createElement("button");
        const buttonType = document.createAttribute("type");
        buttonType.value = 'button';
        this.addClass(this.closeButton,'btn_close')
        this.closeButton.innerHTML = 'x';
        this.closeButton.setAttributeNode(buttonType);
        this.eGui.appendChild(this.closeButton);
        this.closeButton.addEventListener('click',() => {
            that.params.api.hideOverlay()
        })
        this.closeButton.focus()

        this.addClass(this.eGui,'ag-itembarcode-serch');
        this.popupContainer = document.createElement('div');
        this.addClass(this.popupContainer,'rs_item_serch_container')
        this.table = document.createElement('table');
        this.addClass(this.table,'table')
        this.tHeader = document.createElement('thead');
        this.tHeaderRow = document.createElement('tr');
        this.tHeaderRow.innerHTML = `<tr><th>Item Code</th> <th>Item Name</th></tr>`;
        this.tHeader.appendChild(this.tHeaderRow)
        this.tHeader.scrollIntoView()
        this.tbody = document.createElement('tbody');
        

        this.table.appendChild(this.tHeader)
        this.table.appendChild( this.tbody )
        this.popupContainer.appendChild(this.table)
        this.eGui.appendChild( this.popupContainer )
        
        this.apiCalledInterval = null;
        this.eGui.addEventListener('scroll',() => {
            clearTimeout(that.apiCalledInterval)
            that.apiCalledInterval = setTimeout(function () { 
                that.search()
            }, 500);
            
        })
        // create ui
        this.search();
        /*
        this.tHeader.addEventListener('click',() => {
            
            params.api.hideOverlay()
        })
        */
        
        /*
        $('body').on('.rs_sb_header','click',function(){
            alert("done")
            params.api.hideOverlay()
        })
        */
    }
    addClass(selector,className){
        const att = document.createAttribute("class");
        att.value = className;
        selector.setAttributeNode(att);
    }
    search(){
        if(this.skey === null){
            return;
        }
        let rsPrevParams = this.params.api.rsPrevParams;

        let that = this;
        let api = Api;
        api.setUserToken();
        let filterData = {item_name:null, item_code:null}
        if(rsPrevParams.fieldName && this.skey){
            filterData[rsPrevParams.fieldName] = rsPrevParams.sKey
        }
        api.axios().post(Settings.apiItemUrl+`/get_item_dropdown/${this.page}/${this.ppp}`,filterData).then(res => {
            res.data.data.forEach( (item,key) => {
                let resultRow = document.createElement('tr');
                resultRow.addEventListener('click',() => {
                    let newData = {
                        ...rsPrevParams.params.data,
                        'item_name': item.name,
                        'item_code': item.code,
                        'item_id': item.item_id,
                    }
                    if(newData.line_type == Settings.lineTypesId.item){
                        item.prices.forEach( priceItem => {
                            if(priceItem.pricing_type ===0){
                                newData.unit_price = priceItem.price;
                            }
                        })
                        newData.unit = item.purchaseUOM;
                    }
                    rsPrevParams.params.node.setData(newData)
                    
                    
                    rsPrevParams.params.api.startEditingCell({
                        rowIndex:rsPrevParams.params.rowIndex,
                        colKey:'barcode'
                    })
                    that.params.api.hideOverlay()
                })
                
                resultRow.innerHTML =  `<tr>
                                            <td><label tabindex="${key + 1 }">${item.code ? item.code : ''}</label></td>
                                            <td>${item.name ? item.name : ''}</td>
                                        </tr/>`;
                /*
                itemDropdown.addEventListener('click',function(){
                    that.sboxDropdwon.innerHTML = "";
                    that.value = item.name
                    that.input.value = item.name
                    that.page = 1;

                })
                */
               that.tbody.appendChild(resultRow)
            })
            that.page = parseInt(that.page ) + 1;
        })
        //this.value = event.target.value;
        //that.sboxDropdwon.innerHTML = event.target.value;
        //$(that.sboxDropdwon).html(event.target.value)
    }
 
    getGui() {
        return this.eGui;
    }
 }
 export default ItemSearchComponent;