import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';  
import Collapse from '../../inc/Collapse'; 
import BanksPopup from '../Master Bank Popup/BanksPopup';
import { getLabelFromDropdown, getNewValue, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';

// setMasterBank, banksGetObject, gridData, ( same for when version is true ) -> was being used for setting the vendor banks after getting api response

let gridApi = null;
const BanksEdit = ({language, version=false, gridData, id, currency, disableFields, banksGetObject=() => {},type, setVendorBanks}) => {
  const [masterBank, setMasterBank] = useState({
    banksPopup: false, 
    selectedBanksDetails: null,
    gridReadyObject: null,
    vendorBanks: [],
    accountIds: [],
    editModeGridData: {},
    editMode: false
  });
  const [banksData, setBanksData] = useState({});
  const [banks, setBanks] = useState([]);

  let banksListCalled = false;

  useEffect(() => { BanksList() }, []);

  const BanksList = () => {
    if(banksListCalled) return;
 
    let api = Api
    banksListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+`/bank`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.bank_id,
            label: element.name
          })
        })
        setBanks(UPDATED_STATE) 
      }
    })
  }
 
  const onCellClicked = (event) => { 
    if(event.colDef.field === 'account.name'){
      if(disableFields) return;
      
      setBanksData({
        vendorsite_id: event?.data?.account?.vendorsite_id,
        bank_id: event?.data?.account?.bank_id,
        name: event?.data?.account?.name,
        account_number: event?.data?.account?.account_number,
        SWIFTno: event?.data?.account?.SWIFTNO,
        IBAN: event?.data?.account?.IBAN,
        status: event?.data?.is_default === true ? "yes" : "no",
        account_code: event?.data?.account?.code,
        currency_id: event?.data?.account?.currency_id, 
        bank_name: event?.data?.account?.bank_name,
        account_id: event?.data?.account_id
      });

      setMasterBank((prevState) => {
        return {
          ...prevState,
          editMode: true,
          banksPopup: true
        }
      });
    }
  };

  const ShowBanksPopup = () => {
    setMasterBank((prevState) => {
      return {
        ...prevState,
        banksPopup: true,
      }
    });
    setBanksData({});
  };

  const onBanksPopupCloseHandler = () => {
    setMasterBank((prevState) => {
      return {
        ...prevState,
        editModeGridData: {},
        editMode: false,
        banksPopup:false
      }
    })
  };
     
  const onBanksPopupClose = () => {
    setMasterBank((prevState) => {
        return {
        ...prevState,
        banksPopup: false
      }
    })
  };

  const DeleteBanksOfVendor = useCallback(async (element) => { 
    let api = Api
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/vendorbank/${id}/${element?.data?.account_id}`,{})
      const rowNode = gridApi?.api?.getRowNode(element?.data?.account_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.api?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.api?.refreshServerSide({ purge: true })
      gridApi?.api?.deselectAll();
      Helper.alert('Vendor Bank Deleted Successfully!')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const handleFetchData = (response) => {
    if(typeof setVendorBanks === 'function'){
      setVendorBanks(response?.data?.data)
    }
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item, 
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: response?.data?.data?.length 
    }
  };

  /* Grid Column Headers */

  const gridColsHeader = [
    {field:'account.name', filter: 'agTextColumnFilter', editable:false, minWidth: 350, headerName:'Account name', cellRenderer: gridLinkValueFormat },
    {
      field: 'account.bank_id',
      headerName: 'Bank',
      editable: false,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth: 300,
      cellEditorParams: {
        values: banks
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, banks),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
    {
      field: 'account.currency_id',
      headerName: 'Currency',
      editable: false,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth: 300,
      cellEditorParams: {
        values: currency
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, currency),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
    {field:'account.account_number', filter: 'agNumberColumnFilter', editable:false, minWidth: 380, headerName:'Account number'},
    {field:'account.code', filter: 'agTextColumnFilter', editable:false, minWidth: 320, headerName:'Account code'},
    {field:'account.SWIFTNO',  filter: 'agTextColumnFilter', editable:false, minWidth: 320, headerName:'Swift number'},
    {field:'account.IBAN', filter: 'agTextColumnFilter', editable:false, minWidth: 390, headerName:'IBAN'}, 
    {
      field:'account.status', editable: false, headerName: 'Default bank', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      filter: 'agSetColumnFilter', filterParams: { values: [true, false] },
      minWidth: 300, 
      valueGetter:(params)=>{
        if(!params?.data?.account?.status || !params?.data?.account){
          return false
        }
        else {
          return params.data?.account?.status
        }
      }
    }
  ];

  return (
    <Fragment> 
      <Collapse title={Helper.getLabel(language,'banks','Banks')} className="mb-4" open={true}>
        <div className='container-fluid banks'>
          <AgGridNew
            apiUrl={id ? Settings.apiVendorUrl + `/vendorbank/${id}` : null}
            pagination={false}
            hideAddBtn={version}
            isAddBtnDisable={disableFields}
            hideDeleteBtn={disableFields}
            fetchData={handleFetchData}
            onCellClicked={onCellClicked}
            onAddClick={ShowBanksPopup}
            columnDefs={gridColsHeader}
            handleDeleteSelectedRows={DeleteBanksOfVendor}
            onGridReady={(params) => gridApi = params} 
            uniqueField={`account_id`}
            gridId={'vendor-banks-common'}
          /> 
        </div> 
      </Collapse> 
      {masterBank.banksPopup ? <BanksPopup reloadBanksAfterAccountCreation={BanksList} gridApi={gridApi} vendorsite_id={id} dataExportable={false} editable={masterBank.editMode} editData={banksData} setBanksGridData ={setBanksData} setVendorsGrid={setMasterBank} onPopupClose = {onBanksPopupClose} closeBtnClick = {onBanksPopupCloseHandler} type={type}/> : ""}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (BanksEdit);