import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
    types: [],
    isLoaded:false
}
const PoHeaderTypesReducer = (state = DefaultState , actions) => {
    if(actions.type === ActionTypes.SET_PO_HEADER_TYPES){
        state = {
            ...state,
            isLoaded:true,
            types:actions.payload
        }
    }
    return state;
}
export default PoHeaderTypesReducer;