import React, { Component } from 'react';
import MasterComonent from '../components/Backend/MasterComonent';
import RsWithRouter from '../components/inc/RsWithRouter';
import EditSettlementPeriods from '../components/Tax/SettlementPeriods/EditSettlementPeriods'; 
import SettlementPeriods from '../components/Tax/SettlementPeriods/SettlementPeriods';
import EditTaxAuthority from '../components/Tax/TaxAuthority/EditTaxAuthority'; 
import TaxAuthority from '../components/Tax/TaxAuthority/TaxAuthority';
import EditTaxCode from '../components/Tax/Taxcodes/EditTaxCode';
import Taxcodes from '../components/Tax/Taxcodes/Taxcodes';
import EditTaxGroup from '../components/Tax/TaxGroup/EditTaxGroup'; 
import TaxGroup from '../components/Tax/TaxGroup/TaxGroup';
import EditTaxGroupWithItem from '../components/Tax/TaxGroupWithItems/EditTaxGroupWithItem'; 
import TaxGroupWIthItems from '../components/Tax/TaxGroupWithItems/TaxGroupWIthItems';
import TaxPostingGroup from '../components/Tax/TaxPostingGroup';

class TaxScreen extends Component {
    getPage(){
        let {page,id} = this.props.rs_router.params;

        switch(page ){
            case 'authority':
                return <TaxAuthority/>
            case 'edit-tax-authority':
                return <EditTaxAuthority { ...this.props}/>
            case 'settlement-periods':
                return <SettlementPeriods/> 
            case 'edit-settlement-period':
                return <EditSettlementPeriods/>
            case 'posting-group':
                return <TaxPostingGroup/>
            case 'codes':
                return <Taxcodes/>
            case 'edit-taxcode':
                return <EditTaxCode/> 
            case 'group':
                return <TaxGroup/> 
            case 'edit-tax-group':
                return <EditTaxGroup/>
            case 'tax-group-with-items':
                return <TaxGroupWIthItems/> 
            case 'edit-tax-group-with-items':
                return <EditTaxGroupWithItem/>
            default:
                return "Page not found"
        }
    }
    render() {
        return (
            <MasterComonent>
                { this.getPage()}
            </MasterComonent>
        );
    }
}

export default  RsWithRouter(TaxScreen);