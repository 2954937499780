import React from 'react'
import Dropdown from '../Forms/Dropdown'
import Helper from '../../inc/Helper'
import InputDatePicker from '../Forms/InputDatePicker'
import $ from 'jquery'

const EmployeeHeader = ({
  language,
  profilePhoto,
  fromDate,
  setFromDate,
  setToDate,
  toDate,
}) => {
  return (
    <div className="employee-header">
      <div className="row align-items-center">
        <div className="left col-sm-6">
          <div className="profile-widget">
            <img src={profilePhoto} alt="" />
          </div>
          <div className="d-flex flex-column">
            {/*dummy values for now*/}
            <h4>Hii, Sophia</h4>
            <p className="subtitle-style">#2501-86462</p>
          </div>
        </div>
        <div className="right col-sm-6">
          <div className="row">
            <div className="col-sm-4">
              {/*dummy options for now*/}
              <Dropdown
                label={Helper.getLabel(
                  language,
                  'employee_name',
                  'Employee name',
                )}
                placeholder={Helper.getLabel(
                  language,
                  'please_select',
                  'Please select',
                )}
                options={[
                  {
                    value: 'tom wailing',
                    label: 'Tom wailing',
                  },
                  { value: 'tom cruise', label: 'tom cruise' },
                ].map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  }
                })}
              />
            </div>
            <div className="col-sm-4">
              <InputDatePicker
                label={Helper.getLabel(language, 'from_date', 'From Date')}
                inputClass="from-date"
                name="from-date"
                value={fromDate}
                onChange={(e) => {
                  setFromDate($(`.from-date`).val())
                }}
              />
            </div>
            <div className="col-sm-4">
              <InputDatePicker
                label={Helper.getLabel(language, 'to_date', 'To Date')}
                inputClass="to-date"
                name="to-date"
                value={toDate}
                onChange={(e) => {
                  setToDate($(`.to-date`).val())
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeHeader
