import React from 'react'
import '../../Purchase Order/ExtPO.css'
import InvoiceList from './InvoiceList'
import Gui_id_list from '../../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

const CreditNotes = (props) => { 
  return ( <InvoiceList security={props?.security} type="credit notes" headerType="Credit Note" editUrl="/credit-notes/edit" /> )
}

const SecurityOptions = {
  gui_id: Gui_id_list.finance.credit_notes.credit_notes_main
};

export default (((ApplySecurityRoles(CreditNotes, SecurityOptions))));