import React, {useState, useEffect} from 'react';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import Collapse from '../../inc/Collapse';
import DropDownInput from '../../Forms/Dropdown';
import Tabs from '../../Widgets/Tabs/Tabs';
import $ from 'jquery'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import { checkIfArrayIsEmpty } from '../../../inc/Validation';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';

const PriceDiscount = (props) => {
  const [priceType, setPriceType] = useState([]);
  const [discountType, setDiscountType] = useState([]);

  let PriceDiscountNavs = [
    {title:Helper.getLabel(props.language,'price','Price'), isActive: true, forceLoadContents: true },
    {title:Helper.getLabel(props.language,'discount','Discount'), forceLoadContents: true},  
  ];

  useEffect(() => { 
    get_price_type_list()
    get_discount_type_list()
  }, []);

  useEffect(() => {
    let priceObj = !checkIfArrayIsEmpty(props?.editItem?.prices) && props?.editItem?.prices?.find(price => price.pricing_type == '0') 
    props.setEditItemPricing((prevState) => {
      return {
        ...prevState,
        itempricing_id: priceObj?.itempricing_id,
        pricing_type: '0', 
        price: priceObj?.price
      }
    })
  }, [props?.editItem]);

  let priceTypeListCalled = false
  let discountTypeListCalled = false

  const get_price_type_list = () => {
    if(priceTypeListCalled){
      return;
    }
    let api = Api
    priceTypeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/itempricingtype`,{}).then(function(res){
      if(res.data.status==="success") {  
        setPriceType(res?.data?.data?.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        }));
      }
    })
  }
  
  const get_discount_type_list = () => {
    if(discountTypeListCalled){
      return
    }
    let api = Api
    discountTypeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_discounttype`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
          value: element.itemdiscounttype_id,
          label: element.name
        })
      })
      setDiscountType(UPDATED_STATE)
      }
    })
  }
  
  const PriceTypeHandler = (event) => { 
    let price_type = event.target.value 
    let priceObj = props.editItem.prices.find(price => price.pricing_type == price_type) 
    if(priceObj) {
      props.setEditItemPricing((prevState) => {
        return {
          ...prevState,
          itempricing_id: priceObj.itempricing_id,
          pricing_type: price_type, 
          price: priceObj.price,
        }
      })
    }
    if(priceObj == undefined) { 
      props.setEditItemPricing((prevState) => {
        return {
          ...prevState,
          itempricing_id: null,
          pricing_type: price_type, 
          price: null,
        }
      })
    }
  }
    
  const PriceUnitHandler = (event) => {
    let priceunit = event.target.value
    if (/\D/.test(priceunit)) { // Check for non-numeric characters
      Helper.alert('Please provide Numeric values!', 'error')
      return;
    }
    props.setEditItemPricing((prevState) => {
      return{
        ...prevState,
        pricing_unit: parseInt(priceunit)
      }
    })
  }

  const PriceHandler = (event) => {
    let pricediscount = event.target.value
    if (!/^\d*\.?\d{0,2}$/.test(pricediscount)) { 
      Helper.alert('Please provide Numeric values with up to 2 decimal places!', 'error');
      return;
    }
    props.setEditItemPricing((prevState) => {
        return{
        ...prevState,
        price: (pricediscount)
        }
    })
  }

  const DiscountNameHandler = (event) => {
    let discountname = event.target.value
    props.setEditItemDiscount((prevState) => {
      return {
        ...prevState,
        name: discountname
      }
    }) 
  }
  
  const DiscountTypeHandler = (event) => {
    let discounttype = event.target.value 
    props.setEditItemDiscount((prevState) => {
      return {
        ...prevState,
        item_discounttype_id: parseInt(discounttype) 
      }
    }) 
  }
  
  const DiscountUnitHandler = (event) => {
    let discountunit = event.target.value
    if (!/^\d*\.?\d{0,2}$/.test(discountunit)) { 
      Helper.alert('Please provide Numeric values with up to 2 decimal places!', 'error');
      return;
    } 
    props.setEditItemDiscount((prevState) => {
      return {
        ...prevState,
        discount_unit: (discountunit)
      }
    }) 
  }
  
  const DiscountHandler = (event) => {
    let discountname = event.target.value
    if (!/^\d*\.?\d{0,2}$/.test(discountname)) { 
      Helper.alert('Please provide Numeric values with up to 2 decimal places!', 'error');
      return;
    }
    props.setEditItemDiscount((prevState) => {
      return {
        ...prevState,
        discount: (discountname)
      }
    }) 
  }
  
  const EffectiveDateHandler = (date) => {  
    props.setEditItemDiscount((prevState) => {
      return {
        ...prevState,
        effective_date: date
      }
    }) 
  }
  
  const ExpiryDateHandler = (date) => { 
    props.setEditItemDiscount((prevState) => {
      return {
        ...prevState,
        expire_date: date
      }
    }) 
  }
        
  return (
    <Collapse className='mb-4 price_discount' open={true} title={Helper.getLabel(props.language,'price_&_discount',"Price & Discount")}>
      {props.loadingDiscount ? <SimpleLoading /> : (
        <Tabs navs={PriceDiscountNavs} showAll={false} scrollOffset= "500">
          <div className='container-fluid price'>
            <div className='row'>
              <div className='col-lg-4'>
                <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select type'} reRenderRequired={true} required={true} onChange={PriceTypeHandler} value={props.editItemPricing.pricing_type} options={priceType} label={Helper.getLabel(props.language,'type',"Type")}  id='price_type_id' />
              </div>
              <div className='col-lg-4'> 
                <Input disable={true} onChange={PriceUnitHandler} value={'1'} label={Helper.getLabel(props.language,'price_unit_quantity',"Price unit quantity")} type='number'  />
              </div>
              <div className='col-lg-4'> 
                <Input disable={props?.disableComponent} onChange={PriceHandler} value={props.editItemPricing.price} label={Helper.getLabel(props.language,'price',"Price")} type='number' />
              </div>
            </div>
          </div>

          <div className='container-fluid discount'>
            <div className='row'>
              <div className='col-lg-4'>
                <Input disable={props?.disableComponent} required={true} onChange={DiscountNameHandler} value={props.editItemDiscount.name} label={Helper.getLabel(props.language,'name',"Name")} type='text' />
              </div>
              <div className='col-lg-4'> 
                <DropDownInput isDisable={props?.disableComponent} placeHolder={'Select type'} reRenderRequired={true} required={true} onChange={DiscountTypeHandler} value={props.editItemDiscount.item_discounttype_id} options={discountType} label={Helper.getLabel(props.language,'type',"Type")} id='item_discounttype_id' />
              </div>
              <div className='col-lg-4'> 
                <Input disable={props?.disableComponent} required={true} onChange={DiscountUnitHandler} value={props.editItemDiscount.discount_unit} label={Helper.getLabel(props.language,'discount_unit_quantity',"Discount unit quantity")} type='number'  />
              </div>
              <div className='col-lg-4'> 
                <Input disable={props?.disableComponent} required={true} onChange={DiscountHandler} value={props.editItemDiscount.discount} label={Helper.getLabel(props.language,'discount',"Discount %")} type='number'   />
              </div>
              <div className='col-lg-4'> 
                <NewInputDatePicker disable={props?.disableComponent} empty={true} id='effective_date_id' onChange={EffectiveDateHandler} value={props.editItemDiscount.effective_date} label={Helper.getLabel(props.language,'effective_date',"Effective date")} name='effective_date' />
              </div>
              <div className='col-lg-4'> 
                <NewInputDatePicker disable={props?.disableComponent} empty={true} id='expiry_date_id' onChange={ExpiryDateHandler} value={props.editItemDiscount.expire_date } label={Helper.getLabel(props.language,'expiry_date',"Expiry date")} name='expiry_date' />
              </div>
            </div>
          </div>
        </Tabs>
      )}
    </Collapse>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }

export default connect(mapStateToProps) (PriceDiscount);