import React from "react";  
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import { Link } from "react-router-dom"; 
import MasterComonent from "../../components/Backend/MasterComonent";
import {connect} from "react-redux"
import Gui_id_list from "../../inc/Gui_id_list";
import Alert from "../../components/inc/Alert";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";

const Finance = (props) => { 
    
    if (!props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_view)) { 
        return ( 
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        );
    }
 
    const canViewInvoicesAll = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_invoices_all);
    const canViewInvoicesOpen = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_invoices_open);
    const canViewInvoicesCreatedByMe = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_invoices_created_by_me);
    const canViewInvoicesPosted = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_invoices_posted);
    const canViewInvoicesOnAccount = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_invoices_on_account);
 
    const canViewCreditNotesAll = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_credit_notes_all);
    const canViewCreditNotesOpen = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_credit_notes_open);
    const canViewCreditNotesCreatedByMe = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_credit_notes_created_by_me);
    const canViewCreditNotesPosted = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_credit_notes_posted);
    const canViewCreditNotesOnAccount = props.security?.canView(Gui_id_list.screens.finance_screen.main_finance_screen_credit_notes_on_account);
    
    return (
        <div className="modules_screen_lynkaz">  
            <MasterComonent> 
                <InnerScreen innerTitle="Finance">  
                    {(canViewInvoicesAll || canViewInvoicesOpen || canViewInvoicesCreatedByMe || canViewInvoicesPosted || canViewInvoicesOnAccount) && (
                        <div className="col-lg-3 col-md-4 col-6 gy-4">
                            <div className="inner-page-header border-bottom">
                                <h2>Invoices</h2>
                            </div>
                            <div className="inner-page-routes">
                                {canViewInvoicesAll && <Link to="/invoice-list">Invoices - All</Link>}
                                {canViewInvoicesOpen && <Link to="/invoice-list?status=0">Invoices - Open</Link>}
                                {canViewInvoicesCreatedByMe && <Link to={`/invoice-list?createby=${props.auth?.user?.user_id}`}>Invoices - Created by Me</Link>}
                                {canViewInvoicesPosted && <Link to="/invoice-list?document_status=1">Invoices - Posted</Link>}
                                {canViewInvoicesOnAccount && <Link to="/invoice-list?on_account=true">Invoices - On Account</Link>}
                            </div>
                        </div>
                    )}

                    {(canViewCreditNotesAll || canViewCreditNotesOpen || canViewCreditNotesCreatedByMe || canViewCreditNotesPosted || canViewCreditNotesOnAccount) && (
                        <div className="col-lg-3 col-md-4 col-6 gy-4">
                            <div className="inner-page-header border-bottom">
                                <h2>Credit Notes</h2>
                            </div> 
                            <div className="inner-page-routes">
                                {canViewCreditNotesAll && <Link to="/credit-notes">Credit Notes - All</Link>}
                                {canViewCreditNotesOpen && <Link to="/credit-notes?status=0">Credit Notes - Open</Link>}
                                {canViewCreditNotesCreatedByMe && <Link to={`/credit-notes?createby=${props.auth?.user?.user_id}`}>Credit Notes - Created by Me</Link>}
                                {canViewCreditNotesPosted && <Link to="/credit-notes?document_status=1">Credit Notes - Posted</Link>}
                                {canViewCreditNotesOnAccount && <Link to="/credit-notes?on_account=true">Credit Notes - On Account</Link>}
                            </div>
                        </div>
                    )}

                    <div className="col-lg-3 col-md-4 col-6 gy-4">
                        <div className="inner-page-header border-bottom">
                            <h2>Budget</h2>
                        </div>
                        <div className="inner-page-routes">
                            <Link to="/budget-entry-list">Budget Entry</Link>
                            <Link to="/budget-transfer-list">Budget Transfer</Link>
                        </div>
                    </div> 
                </InnerScreen> 
            </MasterComonent>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
    };
};
    
const SecurityOptions = {
    gui_id: Gui_id_list.screens.finance_screen.main_finance_screen_view
};
  
export default connect(mapStateToProps) ((( ApplySecurityRoles( Finance, SecurityOptions))));