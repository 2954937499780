import React, { Component } from 'react';
import Checkbox from '../../Forms/Checkbox';
import Collapse from '../../inc/Collapse';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

class TermsCondition extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAccept:false,
            termsText:"",
            isLoading:false,
            isError: false,
            data:this.props.data,
        }
    }

    componentDidMount(){
        this.loadTearmsText();
        if(this.state.data?.agree_on_terms_condition){
            this.props.updateStep(7,true)
        }
        else{
            this.props.updateStep(7,false)
        }
    }

    onChange(name,value){
        let oldData = this.state.data
        let that = this
        
        oldData={
          ...oldData,
          agree_on_terms_condition:value =='on'
        }

        let payload = {
          agree_on_terms_condition:value =='on'
        }
    
        let api = Api
        api.axios().put(Settings.apiVendorUrl + '/vendor/'+oldData.vendor_id,payload).then(res=>{
            that.setState({
                isLoading:false
            })
            if(res.data.status=='success'){
                Helper.alert(res.data?.message,"success")
                that.setState({
                    data:oldData
                })
                that.props.setVendorState(oldData)
                if(oldData?.agree_on_terms_condition){
                    this.props.updateStep(7,true)
                }else{
                   this.props.updateStep(7,false)
                }
            }
        }).catch(error =>{
            getPopupMessageBasedOnStatus(error)
            that.setState({
                isLoading:false
            })
        })
    }

    loadTearmsText(){
        this.setState({
            isLoading:true,
            isError: false 
        });
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiUrl+'/termandcondition')
            .then(res => {
                that.setState({
                    termsText:res.data.data,
                    isLoading:false,
                    isError:false
                });
            })
            .catch(error => {
                that.setState({
                    termsText: "There is no  terms and conditions.",
                    isLoading: false,
                    isError: true 
                });
            });
    }

    goStep(stepNumber,goBack =false){
        if(goBack===true){
            this.props.goStep(stepNumber)
            return
        }
        if(!this.state.data?.agree_on_terms_condition && !goBack){
            Helper.alert("The acceptance of terms and conditions is mandatory.",'error');
            return
        }
        else{
            if(this.state.data?.agree_on_terms_condition){
                this.props.updateVendorData()
                this.props.updateStep(7,true)
            }
            else{
                this.props.updateStep(7,false)
            }
        }
        //commented for future
        // this.props.goStep(stepNumber)
    }

    render() {
        let activeStep = this.props.activeStep;
        let data = this.props.data
        let disableCondition = data?.document_status!=="Draft"

        if(this.state.isLoading ){
            return <SimpleLoading/>
        }

        return (
            <div>
                <Collapse title="Terms & Condition" open={true}>
                    {this.state.isError ? (
                        <div className="error-message">
                            There is no terms and conditions.
                        </div>
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html:this.state.termsText}}></div>
                    )}
                </Collapse>
                <div className='mt-4'></div>
                <Checkbox
                    onChange={this.onChange.bind(this)}
                    isChecked={this.state.data?.agree_on_terms_condition}
                    type="checkbox"
                    name="tnc"
                    label="I agree to comply with these terms and conditions."
                    disableWithoutIcon={disableCondition}
                />
                <div className='tabs_changer'>
                    {activeStep >= 2 ? <Button className="light_gray btn_back" title="Back" onClick={e => this.goStep( activeStep - 1 ,true)}/> : '' }
                    <Button className="blue" title="Submit" isActive={true} onClick={e => this.goStep( activeStep + 1 )}/>
                </div>
            </div>
        );
    }
}

export default TermsCondition;
