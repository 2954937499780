import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    entity:[]
}
const EntityReducter = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_ENTITY){
        state = {
            ...state,
            isLoaded:true,
            entity:actions.payload
        }
    }
    return state;
}
export default EntityReducter;