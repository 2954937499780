import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import DropDownInput from '../../Forms/Dropdown'
import Button from '../../inc/Button'
import LinkBtn from '../../inc/LinkBtn'
import Helper from '../../../inc/Helper'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { connect } from 'react-redux'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import ContactAddress from '../../Tenant/Address/ContactAddress'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import $ from 'jquery'
import AddressList from '../../Tenant/Address/AddressList'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import SimpleLoading from '../../Loading/SimpleLoading'
import Collapse from '../../inc/Collapse'
import Input from '../../Forms/Input'
import FormValidator from '../../../inc/FormValidator'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../inc/Alert'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction' 
import ImageUpload from '../../Widgets/ImageUploader/ImageUpload'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { areObjectsDifferent, checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import WorkFlowCommonHeader from '../../Header/WorkFlowCommonHeader'
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls'
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas'
import CommonAPICalls from '../../Common Calls/CommonAPICalls'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import Dropdown from '../../Forms/Dropdown'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import AgGridNew from '../../grid/ag/ag-grid-new'

// Employee Creation SYSTEM - LYNKAZ -- EDIT MODE

// setting default variable for keeping presistence -
let image_path = null
let defaultEmployeeObj = null
let enableSubmitBtn = false
let employeeOrganizationGridApi = null
let AddBtn;
let transaction;
let defaultWarehouseArray = []

const EditEmployee = (props) => {
  const { id, instance_id } = useParams()

  const mounted = useRef();

  const [editEmployee, setEditEmployee] = useState({})
  const [title, setTitle] = useState([])
  const [positions, setPositions] = useState([])

  const [entityList, setEntityList] = useState([])
  const [siteList, setSiteList] = useState([])
  const [warehouseList, setWarehouseList] = useState([]) 

  const [gender, setGender] = useState([])
  const [loading, setLoading] = useState(true)
  const [organizationType, setOrganizationType] = useState([])
  const [organization, setOrganization] = useState([])
  const [supervisor, setSupervisor] = useState([])

  const supervisorData = useRef([])
  const siteDataRef = useRef([])
  const entityDataRef = useRef([])
  const warehouseDataRef = useRef([])
 
  const [selectedEntityOptions, setSelectedEntityOptions] = useState(null);
  const [selectedSiteOptions, setSelectedSiteOptions] = useState(null);
  const [selectedWarehouseOptions, setSelectedWarehouseOptions] = useState(null);

  const [siteAdditionalParams, setSiteAdditionalParams] = useState({});
  const [warehouseAdditionalParams, setWarehouseAdditionalParams] = useState({});

  // Workflow States
  const [disableFields, setDisableFields] = useState(null)
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)
  const [workflowActionHistory, setWorkflowActionHistory] = useState([])
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({})
  const [numberSequence, setNumberSequence] = useState({});

  const [scrollDitection, setScrollDitection] = useState('down');
  const [height, setHeight] = useState(0);
  const [selectedSupNav, setSelectedSupNav] = useState('Header View');
  const myDivRef = useRef(null);
  let sectionName = 'employee-edit'

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef?.current?.clientHeight,scrollDitection]);

  //API Reduction Calls

  let positionCalled = false
  let genderCalled = false
  let orgCalled = false 
  let orgTypeCalled = false
  let supervisionCalled = false
  let employeeDetailsCalled = false
  let found = false
  let workflowCalled = false
  let commonSystemCalled = false
  let nsApiCalled = false

  //End

  useEffect(() => {
    if (!mounted.current) {
      getWorkFlowCalls()
      getNumberSequenceData()
      get_position_dropdown_list()
      get_gender_dropdown_list()
      get_org_dropdown_list()
      getOrgsType()
      get_supervisor_dropdown_list()
      mounted.current = true;
    }
  });

  useEffect(() => {
    if (!found) {
      get_title_dropdown_list()
    }
  }, [])

  let validator;

  const validationConfigure = () => {
    let fieldConfig = [
      { name: 'title', displayName: Helper.getLabel(props.language, 'title', 'Title'), types: ['Required'] },
      { name: 'first_name', displayName: Helper.getLabel(props.language, 'first_name', 'First Name'),  types: ['Required'] },
      { name: 'last_name', displayName: Helper.getLabel(props.language, 'last_name', 'Last Name'), types: ['Required']  }, 
      { name: 'display_name', displayName: Helper.getLabel(props.language, 'display_name', 'Display Name'), types: ['Required'] }, 
      { name: 'entity_id', displayName: Helper.getLabel(props.language, 'entity_id', 'Entity'), types: ['Required'] },
      { name: 'site_id', displayName:Helper.getLabel(props.language,'site_id','Site'),types:['Required'] },
      { name: 'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse_id','Warehouse'),types:['Required'] },
    ]
    if (!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }
  validationConfigure()

  let navs = [
    { title: Helper.getLabel(props.language, 'personal_information', 'Personal Information'), data_section: "one", active_status: 'active_tab' },
    { title: Helper.getLabel(props.language, 'additional_information', 'Additional Information'), data_section: "two", active_status: '' },
    { title: Helper.getLabel(props.language, 'organization', 'Organization'), data_section: "three", active_status: '' },
    { title: Helper.getLabel(props.language, 'address', 'Address'), data_section: "four", active_status: '' },
    { title: Helper.getLabel(props.language, 'contact', 'Contact'), data_section: "five", active_status: '' },
  ];

  const addComponentTop = () => {
    let items = [
      { title: Helper.getLabel(props.language, 'title_EM_SETUP', "Title"), subtitle: getTitleLabel() },
      { title: Helper.getLabel(props.language, 'code_EM_SETUP', "Code"), subtitle: editEmployee.code },
      { title: Helper.getLabel(props.language, 'first_name_EM_SETUP', "First Name"), subtitle: editEmployee.first_name },
      { title: Helper.getLabel(props.language, 'last_name_EM_SETUP', "Last Name"), subtitle: editEmployee.last_name }
    ]
    let tags = [
      { title: editEmployee.status ? Helper.getLabel(props.language, 'active', 'Active') : Helper.getLabel(props.language, 'in_active', 'In-Active') },
    ];
    return (
      <div className='rfq-internal-blue'>
        <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
      </div>
    )
  }

  const processUserProfileImage = (image) => { 
    if (image && !image.startsWith('data:image')) {
      const imageData = image.slice(2, -1);
      return `data:image/${image};base64,${imageData}`;
    }
    return image ? image : null;
  };

  const addComponentRight = () => { 
    return (
      <div className='parent-sidebar-cn'>
        <MasterSidebar heights={height}>
          <ImageUpload file={processUserProfileImage(editEmployee.image)} setImageFile={setEditEmployee} />
          <NoteAttachmentHistory
            openCanvas={setShowCanvas}
            openCurrentAssignees={setShowCurrentAssignees}
            hideHistory={!workflowActionHistory?.history?.length} 
            historyArray={workflowActionHistory}
            source_id={18}
            notesource_integrator={id}
            attachmentsource_integrator={id}
            scrollDitection={scrollDitection}
            height={height}
          />
        </MasterSidebar>

        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={18}
            source_item_id={id}
          />
        }

        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={18}
            source_item_id={id}
          />
        }
      </div>
    )
  }

  const getWorkFlowCalls = async () => {
    if (workflowCalled) {
      return;
    }
    workflowCalled = true

    getCommonSystemCalls();
    
    const workflowCalls = new WorkFlowCommonHeaderAPICalls()
    const trigger = await workflowCalls.getWorkFlowTriggers()
    const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name == "Update").id
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(18, props.auth.user.tenant_id, mode)
    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props.auth.user.tenant_id, 18, id)

    if (workflowExist?.instance_exist) setDisableFields(true)
    setWorkflowExist(workflowExist)
    setWorkflowEligible(eligibleWorkflow)

    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(18, id)

    setWorkflowActionHistory(workflowActionHistory) 
  }

  const getCommonSystemCalls = async () => {
    if (commonSystemCalled) return;

    commonSystemCalled = true;

    const commonAPICalls = new CommonAPICalls()
    const entites = await commonAPICalls.getSystemEntities()
    const sites = await commonAPICalls.getSystemSites()
    const warehouses = await commonAPICalls.getSystemWarehouse()

    let updated_entities = [];
    let updated_sites = [];
    let updated_warehouses = [];
    entites?.forEach((item) => { updated_entities.push({ value: item.entity_id, label: `${item.name}` }) })
    sites?.forEach((item) => { updated_sites.push({ value: item.site_id, label: `${item.name}` }) })
    warehouses?.forEach((item) => { updated_warehouses.push({value: item.warehouse_id, label: `${item.name}`}) })
    setEntityList(updated_entities)
    setSiteList(updated_sites) 
    setWarehouseList(updated_warehouses)
    defaultWarehouseArray = warehouses
    get_employee_details()
  }

  const getNumberSequenceData = async () => {
    if(nsApiCalled) return;
    nsApiCalled = true;

    try {
      let api = Api
      api.setUserToken();
      const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/None/${Settings.source_id.employee}`)
      if (numberSequenceRecord?.status===200) { 
        setNumberSequence(numberSequenceRecord?.data)
      }
    } catch(error){
      setNumberSequence({})
    }
  };

  const get_employee_details = () => {
    let employeeID = id ? id : null
    if (employeeDetailsCalled || !employeeID) {
      return;
    }
    
    let api = Api
    employeeDetailsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + `/employee/${id}`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_DATA = {}
        UPDATED_DATA = {
          ...res.data.data,
          // date_of_birth: res.data.data.date_of_birth ? DatepickerFunctions.convertDateFromDataBase(date_of_birth) : null,
          image: res.data.data.image === null ? '/images/blank-profile.jpg' : res.data.data.image
        }
        setEditEmployee(UPDATED_DATA)
        defaultEmployeeObj = UPDATED_DATA
        image_path = res.data.data.image
        setSelectedEntityOptions(res?.data?.data?.entity_id)
        setSelectedSiteOptions(res?.data?.data?.site_id)
        setSelectedWarehouseOptions(res?.data?.data?.warehouse_id) 
        setLoading(false)
      }
    }).catch((res) => {
      setLoading(false)
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const get_title_dropdown_list = () => {
    found = true
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl + `/employeetitle`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.id,
            label: element.name
          })
        })
        setTitle(UPDATED_STATE)
      }
    })
  }

  const get_gender_dropdown_list = () => {
    if (genderCalled) {
      return;
    }
    let api = Api
    genderCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + `/employeegender`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.id,
            label: element.name
          })
        })
        setGender(UPDATED_STATE)
      }
    })
  }

  const getOrgsType = () => {
    if (orgTypeCalled) return;

    let api = Api;
    orgTypeCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + `/organizationType`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          setOrganizationType(
            res?.data?.data?.map((element) => {
              return {
                value: element.organizationtype_id,
                label: element.name,
              };
            })
          );
        }
      });
  };

  const get_org_dropdown_list = () => {
    if (orgCalled) {
      return
    }
    let api = Api
    orgCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + `/organization`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.organization_id,
            label: element.name
          })
        })
        setOrganization(UPDATED_STATE)
      }
    })
  }

  const get_supervisor_dropdown_list = () => {
    if (supervisionCalled) {
      return;
    }
    let api = Api
    supervisionCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + `/employee_dropdown`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.employee_id,
            label: element.displayname,
          })
        })
        setSupervisor(!checkIfArrayIsEmpty(UPDATED_STATE) && UPDATED_STATE.filter(item => item.value !== parseInt(id)));
      }
    })
  }

  const get_position_dropdown_list = () => {
    if (positionCalled) {
      return;
    }
    let api = Api
    positionCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + `/get_active_postion`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.position_id,
            label: element.name
          })
        })
        setPositions(UPDATED_STATE)
      }
    })
  }

  const mapSupervisorData = (item) => {
    return {
      text: `${item.displayname}`,
      id: item.employee_id
    };
  } 
  const mapEntityData = (item) => {
    return {
      text: `${item.name}`,
      id: item.entity_id
    };
  };

  const mapSiteData = (item) => {
    return {
      text: `${item.name}`,
      id: item.site_id
    };
  };

  const mapWarehouseData = (item) => {
    return {
      text: `${item.name}`,
      id: item.warehouse_id
    };
  };

  const TitleChangeHandler = (event) => {
    let Title = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, title: Title }
    })
  }
  const CodeChangeHandler = (event) => {
    let Code = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, code: Code }
    })
  }
  const FirstNameChangeHandler = (event) => {
    let Firstname = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, first_name: Firstname }
    })
  }
  const MiddleNameChangeHandler = (event) => {
    let Middlename = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, middle_name: Middlename }
    })
  }
  const LastNameChangeHandler = (event) => {
    let Lastname = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, last_name: Lastname }
    })
  }
  const DisplayNameChangeHandler = (event) => {
    let Displayname = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, display_name: Displayname }
    })
  }
  const IdChangeHandler = (event) => {
    let Id = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, national_identifier: Id }
    })
  }
  const StatusChangeHandler = (event) => {
    let Status = event.target.value
    if (Status == 'true') {
      setEditEmployee((prevState) => {
        return { ...prevState, status: true }
      })
    } else {
      setEditEmployee((prevState) => {
        return { ...prevState, status: false }
      })
    }
  }
  const DOBChangeHandler = (date) => {  
    setEditEmployee((prevState) => {
      return { ...prevState, date_of_birth: date }
    })
  }
  const ExtCodeChangeHandler = (event) => {
    let ExtCode = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, external_code: ExtCode }
    })
  }
  const GenderChangeHandler = (event) => {
    let Gender = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, gender: Gender }
    })
  }
  const SupervisorChangeHandler = (event) => {
    let Supervisor = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, supervisor: Supervisor }
    })
  }
  const PositionChangeHandler = (event) => {
    let Position = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, position_id: Position }
    })
  }
 
  const OrgChangeHandler = (event) => {
    let Org = event.target.value
    setEditEmployee((prevState) => {
      return { ...prevState, organization_id: Org }
    })
  }

  const handleEntityChange = (entity_id) => {
    setSelectedEntityOptions(entity_id);
    setEditEmployee((prevState) => {
      return {
        ...prevState,
        entity_id
      }
    });
    if (entity_id) {
      setSiteAdditionalParams({});
      setWarehouseAdditionalParams({});
      setSelectedSiteOptions(null);
      setSelectedWarehouseOptions(null);
      setSiteAdditionalParams({
        entity_id: entity_id
      }); 
      setWarehouseAdditionalParams({
        entity_id: entity_id
      });
    } else {
      setSiteAdditionalParams({});
      setWarehouseAdditionalParams({});
    }
  };

  const handleSiteChange = (site_id) => {
    setSelectedSiteOptions(site_id);
    setEditEmployee((prevState) => {
      return {
        ...prevState,
        site_id
      }
    });
    if (site_id) {
      const currentSiteData = siteDataRef?.current?.find(
        (item) => item?.site_id === parseInt(site_id)
      );
      setWarehouseAdditionalParams({
        site_id: site_id,
      });
      setSelectedEntityOptions(currentSiteData?.entity_id);
      setSelectedWarehouseOptions(currentSiteData?.warehouse_id);
    } else {
      let resSite = siteList?.filter(
        (item) => item?.entity_id == selectedEntityOptions
      );
      let resWerehouse = warehouseList?.filter(
        (item) => item?.entity_id == selectedEntityOptions
      );
      setSiteList(
        resSite?.map((item) => {
          return {
            value: item?.site_id,
            label: item?.name
          };
        })
      );
      setWarehouseList(
        resWerehouse?.map((item) => {
          return {
            value: item?.warehouse_id,
            label: item?.name
          };
        })
      );
      setSelectedWarehouseOptions(null);
    }
  };

  const handleWarehouseChange = (warehouse_id) => {
    setSelectedWarehouseOptions(warehouse_id);
    setEditEmployee((prevState) => {
      return {
        ...prevState,
        warehouse_id
      }
    });
    if (warehouse_id) {
      setSiteAdditionalParams({});
      setWarehouseAdditionalParams({});
      const CurrentWarehouseData = warehouseDataRef?.current?.find(
        (item) => item?.warehouse_id === parseInt(warehouse_id)
      );
      setSelectedSiteOptions(CurrentWarehouseData?.site_id);
      setSelectedEntityOptions(CurrentWarehouseData?.entity_id);
    }
  };

  const getTitleLabel = () => {
    if (!document.getElementById('title')) {
      return ''
    }
    let Result = ''
    let innerid = document.getElementById('title').value
    title.forEach(element => {
      if (element.value == innerid) {
        Result = element.label
      }
    })
    return Result
  }

  const ContactCreate = (data) => {
    if (editEmployee.contactsource_id !== null) {
      return;
    }
    setEditEmployee({
      ...editEmployee,
      contactsource_id:data.contact_source_id,
    })
  }

  const AddressCreate = (data) => {
    if (editEmployee.address_source_id !== undefined) {
      return;
    } 
    setEditEmployee({
      ...editEmployee,
      addresssource_id:data.address_source_id
    })
  }

  const PersonalInformationModule = () => {
    return (
      <Collapse className='mb-4' title={Helper.getLabel(props.language, 'personal_info', "Personal Information")} open={true}>
        <div className='row mb-3'>

          <div className='col-lg-4'>
            <DropDownInput reRenderRequired={true} placeHolder={'Select title'} isDisable={disableFields} label={Helper.getLabel(props.language, 'title_EM_SETUP', "Title")} options={title} id='title' required={true} onChange={TitleChangeHandler} value={editEmployee.title} />
          </div>

          <div className='col-lg-4'>
            <Input maxLength={20} disable={numberSequence?.ns_type == "continuous"} required={!numberSequence?.ns_type == "continuous"} label={Helper.getLabel(props.language, 'code_EM_SETUP', "Code")} type='text' onChange={CodeChangeHandler} value={editEmployee.code} />
          </div>

          <div className='col-lg-4'>
            <Input  maxLength={80} disable={disableFields} label={Helper.getLabel(props.language, 'first_name_EM_SETUP', "First name")} type='text' onChange={FirstNameChangeHandler} required={true} value={editEmployee.first_name} />
          </div>

          <div className='col-lg-4'>
            <Input maxLength={80} disable={disableFields} type='text' label={Helper.getLabel(props.language, 'middle_name_EM_SETUP', "Middle name")} onChange={MiddleNameChangeHandler} value={editEmployee.middle_name} />
          </div>

          <div className='col-lg-4'>
            <Input disable={disableFields} maxLength={80} label={Helper.getLabel(props.language, 'last_name_EM_SETUP', "Last name")} type='text' onChange={LastNameChangeHandler} required={true} value={editEmployee.last_name} />
          </div>

          <div className='col-lg-4'>
            <Input  disable={disableFields} maxLength={255} label={Helper.getLabel(props.language, 'display_name_EM_SETUP', "Display name")} required={true} type='text' onChange={DisplayNameChangeHandler} value={editEmployee.display_name} />
          </div>

        </div>
      </Collapse>
    )
  }

  const AdditionalInformationModule = () => {
    return (
      <Collapse className='mb-4' title={Helper.getLabel(props.language, 'additional_info', "Additional Information")} open={true}>
        <div className='row mb-3'>
          <div className='col-lg-4'>
            <DropDownInput reRenderRequired={true}  placeHolder={'Select status'}  isDisable={disableFields} required={true} label={Helper.getLabel(props.language, 'status_EM_SETUP', "Status")} options={Settings.seedDataStatus} id='status' onChange={StatusChangeHandler} value={editEmployee.status} />
          </div>

          <div className='col-lg-4'>
            <Input maxLength={40}  disable={disableFields} type='text' label={Helper.getLabel(props.language, 'national_identifier_EM_SETUP', "National identifier")} onChange={IdChangeHandler} value={editEmployee.national_identifier} />
          </div>

          <div className='col-lg-4 dob'>
            <NewInputDatePicker disable={disableFields} label={Helper.getLabel(props.language, 'dob_EM_SETUP', "Date of birth")} value={editEmployee.date_of_birth} onChange={DOBChangeHandler} id='dob_date_id' name="dob_date" />
          </div>

          <div className='col-lg-4'>
            <Input maxLength={20} disable={disableFields} type='text' label={Helper.getLabel(props.language, 'ext_code_EM_SETUP', "External code")} onChange={ExtCodeChangeHandler} value={editEmployee.external_code} />
          </div>

          <div className='col-lg-4'>
            <DropDownInput reRenderRequired={true} placeHolder={'Select gender'}  isDisable={disableFields} options={gender} id='gender' label={Helper.getLabel(props.language, 'gender_EM_SETUP', "Gender")} onChange={GenderChangeHandler} value={editEmployee.gender} />
          </div>
 
          <div className='col-lg-4'>
            <DropDownInput options = { editEmployee?.supervisor ? [{ value: editEmployee?.supervisor, label: editEmployee?.supervisor_name ?? supervisorData?.current?.find(item => parseInt(item.employee_id) === parseInt(editEmployee?.supervisor))?.displayname }] : supervisor} pagination={true} apiUrl={Settings?.apiOrgUrl + `/employee_dropdown`} allDataRef={supervisorData} mapFunction={mapSupervisorData} reRenderRequired={true} placeHolder={'Select supervisor'} disable={disableFields} label={Helper.getLabel(props.language, 'supervisor_EM_SETUP', "Supervisor")} id='supervisor' onChange={SupervisorChangeHandler} value={editEmployee?.supervisor} />
          </div>

          <div className='col-lg-4'>
            <DropDownInput reRenderRequired={true} placeHolder={'Select position'} isDisable={disableFields} label={Helper.getLabel(props.language, 'position_EM_SETUP', "Position")} options={positions} id='position' onChange={PositionChangeHandler} value={editEmployee.position_id} />
          </div>

          <div className='col-lg-4'>
            <Dropdown
              label={`${Helper.getLabel(
                props.language,
                "entity",
                "Entity"
              )}`}
              options={ editEmployee?.entity_id
                ? [{
                    value: editEmployee?.entity_id,
                    label: editEmployee?.entity_name ?? entityDataRef?.current?.find(item => parseInt(item.entity_id) === parseInt(editEmployee?.entity_id))?.name,
                  }]
                : entityList
              }
              apiUrl={Settings.loginUrl + "/get_entities"} 
              pagination={true}
              mapFunction={mapEntityData} 
              allDataRef={entityDataRef}  
              placeHolder={`${Helper.getLabel(
                props.language,
                "select_entity",
                "Select entity"
              )}`}
              id="select_entity_id"
              reRenderRequired={true}
              onChange={(e) => handleEntityChange(e?.target?.value)}
              value={selectedEntityOptions}
              required={true}
            />
          </div>
          <div className='col-lg-4'>
            <Dropdown
              label={`${Helper.getLabel(props.language, "site", "Site")}`}
              options={ editEmployee?.site_id
                ? [{
                    value: editEmployee?.site_id,
                    label: editEmployee?.site_name ?? siteDataRef?.current?.find(item => parseInt(item.site_id) === parseInt(editEmployee?.site_id))?.name,
                  }]
                : siteList
              }
              placeHolder={`${Helper.getLabel(
                props.language,
                "select_site",
                "Select site"
              )}`}
              onChange={(e) => handleSiteChange(e?.target?.value)}
              value={selectedSiteOptions}
              id="site"
              reRenderRequired={true}
              apiUrl={Settings.loginUrl + "/get_site"} 
              pagination={true} 
              mapFunction={mapSiteData} 
              allDataRef={siteDataRef}  
              additionalParams={siteAdditionalParams}
              required={true}
            /> 
          </div>
          <div className='col-lg-4'>
            <Dropdown
              label={`${Helper.getLabel(props.language, "warehouse", "Warehouse")}`}
              options={ 
                editEmployee?.warehouse_id
                ? [{
                    value: editEmployee?.warehouse_id,
                    label: editEmployee?.warehouse_name ?? warehouseDataRef?.current?.find(item => parseInt(item.warehouse_id) === parseInt(editEmployee?.warehouse_id))?.name,
                  }]
                : warehouseList
              }
              placeHolder={`${Helper.getLabel(
                props.language,
                "select_warehouse",
                "Select warehouse"
              )}`}
              apiUrl={Settings.loginUrl + "/get_warehouse"} 
              pagination={true} 
              mapFunction={mapWarehouseData} 
              allDataRef={warehouseDataRef}  
              reRenderRequired={true}
              onChange={(e) => handleWarehouseChange(e?.target?.value)}
              additionalParams={warehouseAdditionalParams}
              value={selectedWarehouseOptions}
              id="warehouse"
              required={true}
            />
          </div>
        </div>
      </Collapse>
    )
  }

  const ContactModule = () => {
    return (
      <Collapse className='mt-4' title={Helper.getLabel(props.language, 'contact', "Contact")} open={true}>
        <div className='edit_employee_grid_contact grid_save_btn_hide'>
          <ContactAddress disableRecords={disableFields} onContactCreate={ContactCreate} contactsource_id={editEmployee.contactsource_id} contactsource_integrator={id} source_id={18} address_id={null} />
        </div>
      </Collapse>
    )
  }

  let dataSource = {
    getRows: async function (params) { 
      params?.success({
        rowData: editEmployee?.organizations ? editEmployee?.organizations?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })) : []?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: editEmployee?.organizations ? editEmployee?.organizations?.length : 0
      })
    }
  };

  const onEmployeeOrgnizationGridReady = (params) => {
    if(params){
      employeeOrganizationGridApi = params?.api
      setTimeout(()=> { 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200)
    };
  };

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  };

  const onRowValueChanged = (event) => {  
    if (event?.data?.isNew) {
      addNewData(event)
    }
  };

  const addNewData = async (event) => {
    try {
      if(!id || !event?.data?.organization_id) return;

      let payload = {
        employee_id: parseInt(id),
        organization_id: event?.data?.organization_id ? parseInt(event?.data?.organization_id) : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiOrgUrl+'/employee_organization', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          employeeOrganizationGridApi?.refreshServerSide({ purge: true });
          employeeOrganizationGridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            employeeOrganizationGridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  }

  const onDeleteEmployeeOrganization = useCallback(async (element) => {  
    let api = Api;
    api.setUserToken();
    
    let del_employee_org_id = element?.data?.organization_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiOrgUrl+`/employee_organization/${del_employee_org_id}/${id}`)
      const rowNode = employeeOrganizationGridApi?.getRowNode(del_employee_org_id)

      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        employeeOrganizationGridApi?.applyServerSideTransaction(transaction)
      }, 200);

      employeeOrganizationGridApi?.refreshServerSide({ purge: true })
      employeeOrganizationGridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const EmployeeOrganization = () => {
    const employeeOrganizationColHeaders = [
      {
        field: "organization_id",
        headerName: "Organization", 
        cellEditor: SimpleDropdown,
        isAllowZero: true,
        minWidth: 180,
        valueGetter: (params) => {
          if(!params?.data?.organization_name || !params?.data){
            return ''
          }
          else {
            return params.data?.organization_name
          }
        },
        editable: (event) => event?.data?.isNew,
        cellEditorParams: {
          values: organization,
        },
        cellDataType: false,
        valueFormatter: (params) => getLabelFromDropdown(params),
        filter: "agSetColumnFilter",
        filterParams: {
          values: (params) => getOptions(params, organization),
          keyCreator: (params) => optionsKeyCreator(params, "value"),
          valueFormatter: (params) => params.value.label,
        },
      }
    ]; 

    const handleFetchData = (response) => {
      return {
        rowData: response?.data?.data?.organizations?.map((item) => ({
          ...item, 
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: response?.data?.data?.organizations?.length 
      }
    };
    return (
      <Collapse title={Helper.getLabel(props?.language,'organization','Organization')} className="mb-4" open={true}>
        <div className='container-fluid employee_organizations'> 
          <AgGridNew
            apiUrl={id ? Settings.apiOrgUrl + '/employee/' + id : null}
            pagination={false}  
            fetchData={handleFetchData}
            isAddBtnDisable={disableFields}
            hideDeleteBtn={disableFields}
            columnDefs={employeeOrganizationColHeaders}
            onRowValueChanged={onRowValueChanged} 
            handleDeleteSelectedRows={onDeleteEmployeeOrganization}
            onGridReady={(params) => employeeOrganizationGridApi = params?.api}
            handleAddButton={handleBtn}
            uniqueField={`organization_id`}
            gridId={'employee-organization-listings'}
          />
        </div>
      </Collapse>
    )
  }
 
  const executeAPIForEmployee = (comment) => {
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiOrgUrl + `/employee/${id}`, {
      title: editEmployee.title ? parseInt(editEmployee.title) : null,
      code: editEmployee.code !== "" ? editEmployee.code : null,
      first_name: editEmployee.first_name !== "" ? editEmployee.first_name : null,
      middlename: editEmployee.middle_name !== "" ? editEmployee.middle_name : null,
      last_name: editEmployee.last_name !== "" ? editEmployee.last_name : null,
      display_name: editEmployee.display_name !== "" ? editEmployee.display_name : null,
      status: editEmployee?.status,
      national_identifier: editEmployee.national_identifier !== "" ? editEmployee.national_identifier : null,
      date_of_birth: DatepickerFunctions.convertDateForDataBase(editEmployee.date_of_birth),
      external_code: editEmployee.external_code !== "" ? editEmployee.external_code : null,
      image: editEmployee.image !== null ? (editEmployee.image == '/images/blank-profile.jpg' || editEmployee.image === image_path ? null : editEmployee.image) : null,
      image_type: null,
      gender: editEmployee.gender ? parseInt(editEmployee.gender) : "",
      supervisor: editEmployee.supervisor ? parseInt(editEmployee.supervisor) : "", 
      position_id: editEmployee.position_id ? parseInt(editEmployee.position_id) : "",
      // notesource_id: editEmployee.notesource_id,
      addresssource_id: editEmployee.addresssource_id,
      contactsource_id: editEmployee.contactsource_id,
      entity_id: editEmployee.entity_id ? parseInt(editEmployee.entity_id) : null,
      site_id: editEmployee.site_id ? parseInt(editEmployee.site_id) : null,
      warehouse_id: editEmployee.warehouse_id ? parseInt(editEmployee.warehouse_id) : null,
      comment: comment == '' || comment == 'execute_api' ? null : comment,
      workflow_initiated: !enableSubmitBtn ? true : false,
    }).then(function (res) {
      if (res.data.status === "success") {
        Helper.alert(res.data.message)
        getWorkFlowCalls()
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res) 
      getWorkFlowCalls()
      setLoading(false)
    })
  }

  // execute api for Workflow execution -
  const SubmitWorkflow = (comment) => {
    enableSubmitBtn = false
  
    if (!areObjectsDifferent(defaultEmployeeObj, editEmployee)) {
      return;
    }

    let newData = {
      title: (document.getElementById('title').value), 
      first_name: editEmployee.first_name,
      last_name: editEmployee.last_name,
      display_name: editEmployee.display_name,
      entity_id: editEmployee.entity_id,
      site_id: editEmployee.site_id,
      warehouse_id: editEmployee.warehouse_id 
    }

    let validData = {
      ...newData
    }

    if (!validator.isValid(validData) || (editEmployee.status===null)) {
      if(editEmployee.status===null) {
        Helper.alert('Status field missing!', 'error');
      };
      validator.displayMessage();
      validator.reset()
      return;
    }

    if(!numberSequence?.ns_type == "continuous" && (!editEmployee?.code || editEmployee?.code === "")) {
      Helper.alert('Code field is required', 'error');
      return;
    };

    executeAPIForEmployee(comment)
  }

  const SaveHandler = () => {
    enableSubmitBtn = true
  
    if (!areObjectsDifferent(defaultEmployeeObj, editEmployee)) {
      return;
    }

    let newData = {
      title: (document.getElementById('title').value), 
      first_name: editEmployee.first_name,
      last_name: editEmployee.last_name,
      display_name: editEmployee.display_name,
      entity_id: editEmployee.entity_id,
      site_id: editEmployee.site_id,
      warehouse_id: editEmployee.warehouse_id 
    }

    let validData = {
      ...newData
    }

    if (!validator.isValid(validData) || (editEmployee.status===null)) {
      if(editEmployee.status===null) {
        Helper.alert('Status field missing!', 'error');
      };
      validator.displayMessage();
      validator.reset(); 
      return;
    }
  
    if(!numberSequence?.ns_type == "continuous" && (!editEmployee?.code || editEmployee?.code === "")) {
      Helper.alert('Code field is required', 'error');
      return;
    };

    setLoading(true)
    executeAPIForEmployee()
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if (!security.canView(Gui_id_list.setupForms.employee.employee_edit_screen)) {
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader /> : null }
      <div className='container-fluid scrollBarBlinkControl'>
        <div ref={myDivRef}> 
          <NavigationHeder hideMoreBtn={true} title={`<span style="color:#000;">${Helper.getLabel(props.language, 'Edit employee', "Edit Employee")} #</span>  ${editEmployee?.code}`}>
            <LinkBtn isActive={false} to="/employee" className='black-btn-style' title={Helper.getLabel(props.language, 'close', "Close")} />
            <Button isDisable={!security.canEdit(frontendIds.setupForms.employee.employee_edit_screen_save_button)} isActive={false} className="rfq_save_btn" title={Helper.getLabel(props.language, 'save', "Save")} onClick={SaveHandler} />
          </NavigationHeder>

          <div style={{ display: scrollDitection == 'down' ? "block" : "none" }}> 
            <WorkFlowCommonHeader
              setEnableFields={setDisableFields}
              enableFields={disableFields}
              workflowInstanceDetails={workflowExist?.instance_details}
              submitWorkflowAction={!workflowExist.instance_exist && workflowEligible && SubmitWorkflow}
              instance_task_id={instance_id}
              module_id={!instance_id ? 18 : null}
              source_id={18}
              source_item_id={id}
              SubmitWorkflowObjDetails={workflowExist.workflow_name}
              module_item_id={!instance_id ? id : null}
              workflowInstanceExist={workflowExist.instance_exist}
              workflowEligible={workflowEligible}
              workflowName={'Employee Workflow'}
              reloadFunction={getWorkFlowCalls}
            />
          </div>

          <FixedNavigationHeder 
            addComponentTop={addComponentTop} 
            sectionName={sectionName} 
            navs={navs} 
            selectedSupNav={selectedSupNav} 
            scrollDitection={scrollDitection} 
          /> 
        </div> 
 
        <TabContentWraper
          height={height}
          setScrollDitection={setScrollDitection}
          leftSideContent={
            <>
              <div className="lists_contant one" id={`${sectionName}Personal Information`} style={{ position: "relative" }}>
                {PersonalInformationModule()}
              </div>
              <div className="lists_contant two" id={`${sectionName}Additional Information`} style={{ position: "relative" }}>
                {AdditionalInformationModule()}
              </div>
              <div className="lists_contant three" id={`${sectionName}Organization`} style={{ position: "relative" }}>
                {EmployeeOrganization()}
              </div>
              <div className="lists_contant four" id={`${sectionName}Address`} style={{ position: "relative" }}>
                { loading ? null : <AddressList disableRecords={disableFields} onAddressCreate={AddressCreate} addressSourc_id={editEmployee?.addresssource_id} address_source_integrator={id} source_id={18} /> }
              </div>
              <div className="lists_contant five" id={`${sectionName}Contact`} style={{ position: "relative" }}>
                { loading ? null : ContactModule() }
              </div>
            </>
          }
          rightSideContent={addComponentRight}
        /> 
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language
  }
}
const SecurityOptions = {
  gui_id: Gui_id_list.setupForms.employee.employee_edit_screen
}


export default connect(mapStateToProps)(MasterComponentWraper((ApplySecurityRoles(EditEmployee, SecurityOptions))))