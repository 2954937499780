import React, { useState, useCallback, useEffect } from 'react';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Helper from '../../../inc/Helper';
import Tabs from '../../Widgets/Tabs/Tabs';
import SimpleLoading from '../../Loading/SimpleLoading';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import GeneralTab from './GeneralTab';
import CurrencyRenderer from '../../grid/ag/cellRender/CurrencyRenderer';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';

const TaxTransaction = (props) => {
    const [selectedNode, setSelectedNode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { source_id, integrator, line_id } = props;

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const onSelectionChanged = useCallback((event) => {
        let selectedNodes = event.api.getSelectedNodes();
        if (selectedNodes.length <= 0) {
            setSelectedNode(null);
            return;
        }
        setSelectedNode(selectedNodes[0]);
    }, []);

    const onCellValueChanged = useCallback((params) => {
        if (params.colDef.field === 'transaction_amount_adjusted') {
            let api = Api;
            api.setUserToken();
            let newData = {
                taxcode_id: null,
                transaction_amount_origin: null,
                transaction_amount_adjusted: Helper.getOnlyNumber(params.value),
                override: null
            };
            api.axios().put(Settings.apiUrl + '/taxtransactiondetails/' + params.data.taxtransaction_detail_id, newData).then(res => {
                Helper.alert(res.data.message);
            }).catch(errors => {
                getPopupMessageBasedOnStatus(errors);
            });
        }
    }, []);

    let url = Settings.apiUrl + '/get_taxtransaction_detail/' + source_id + '/' + integrator;
    if (line_id) {
        url = Settings.apiUrl + '/taxtransaction_detail/' + source_id + '/' + integrator + '/' + line_id;
    }

    let tabNavs = [
        { title: Helper.getLabel(null, 'overview', 'Overview'), isActive: true, forceLoadContents: true },
        { title: Helper.getLabel(null, 'general', 'General'), isDisable: selectedNode === null }
    ];

    let gridHeader = [
        { field: 'taxcode', headerName: Helper.getLabel(null, 'sale_tax_code', 'Sale tax code'), editable: false, minWidth:200, },
        { field: 'sourceintegrator_line_qty', headerName: Helper.getLabel(null, 'quantity', 'Quanity'), editable: false , minWidth:160},
        { field: 'percentage', headerName: Helper.getLabel(null, 'percentage', 'Percentage'), editable: false, minWidth:150 },
        { field: 'transaction_amount_origin', headerName: Helper.getLabel(null, 'amount_origin', 'Amount origin'), editable: false, cellRenderer: CurrencyRenderer, minWidth:180 },
        { field: 'transaction_amount_adjusted', headerName: Helper.getLabel(null, 'amount_adjusted', 'Amount adjusted'), inputType: 'number', autoFocus: true, minWidth:220 },
        { field: 'transaction_calculated_taxamount_after_adjustment', headerName: Helper.getLabel(null, 'calculated_taxamount_after_adjustment', 'Tax amount after adjustment'), editable: false, cellRenderer: CurrencyRenderer, minWidth:220 },
        { field: 'total_tax', headerName: Helper.getLabel(null, 'total_tax', 'Total tax'), editable: false, cellRenderer: CurrencyRenderer, minWidth:200 },
    ];

    if (isLoading) {
        return <SimpleLoading />;
    }

    return (
        <div className='taxtransaction_by_line'>
            <Tabs navs={tabNavs} tabContantsId="tabs_cnt_taxtrns_all" disableSticky={true} >
                <div>
                     <AgGridNew
                        onCellValueChanged={onCellValueChanged}
                        onSelectionChanged={onSelectionChanged}
                        gridId="transactions_view_grid"
                        viewOnly={false}
                        apiUrl={url}
                        columnDefs={gridHeader}
                        height={500}
                        hideAddBtn={true}
                        hideDeleteBtn={true}
                        returnApiCondition={!source_id || !integrator}
                    />
                </div>
                <div>
                    <GeneralTab selectedNode={selectedNode} />
                </div>
            </Tabs>
        </div>
    );
};

const securityOptions = {
    gui_id: '1525'
};
export default ApplySecurityRoles(TaxTransaction, securityOptions);
