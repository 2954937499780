import React, { Component } from 'react';
import Input from '../../Forms/Input';
import Helper from '../../../inc/Helper';

class GeneralTab extends Component {
    onInputChangeHandler(e){

    }
    render() {
        let language = null;
        let data = this.props.selectedNode ? this.props.selectedNode.data : {};
        return (
            <div className='txtr_general_tab' style={{minHeight:"680px"}}>
                <div className='row'>
                    <div className='col-xs-12 col-sm-4'><Input name="taxcode" value={data.taxcode} label={Helper.getLabel(language,'tax_code','Tax code')} id="taxcode" onChange={this.onInputChangeHandler.bind(this)}  disable={true}  /></div>
                    <div className='col-xs-12 col-sm-4'><Input name="taxgroup_name" value={data.taxgroup_name} label={Helper.getLabel(language,'tax_group_name','Tax group name')} id="taxgroup_name" onChange={this.onInputChangeHandler.bind(this)}  disable={true}  /></div>
                    <div className='col-xs-12 col-sm-4'><Input name="taxgroupitem_name" value={data.taxgroupitem_name} label={Helper.getLabel(language,'taxgroupitem_name','Tax group item name')} id="taxgroupitem_name" onChange={this.onInputChangeHandler.bind(this)}  disable={true}  /></div>
                    <div className='col-xs-12 col-sm-4'><Input name="sourceintegrator_line_qty" value={data.sourceintegrator_line_qty} label={Helper.getLabel(language,'line_qty','Line quantity')} id="sourceintegrator_line_qty" onChange={this.onInputChangeHandler.bind(this)}  disable={true}  /></div>
                    <div className='col-xs-12 col-sm-4'><Input name="percentage" value={data.percentage} label={Helper.getLabel(language,'percentage','Percentage')} id="percentage" onChange={this.onInputChangeHandler.bind(this)}  disable={true}  /></div>
                </div>
            </div>
        );
    }
}

export default GeneralTab;