import axios from 'axios';
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import $ from 'jquery';
import Collapse from '../../inc/Collapse';
import Popup from '../../Popup/Popup';
import { connect } from "react-redux";
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

let cancelTokenSource = axios.CancelToken.source();
const SetupBanksModal = ({language, setShowCreateModal}) => {
    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);
 
    const navigate = useNavigate();

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [
            {name:'name', displayName:Helper.getLabel(language,'Name','Name'),types:['Required']}
        ];
      
        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();
    
    const fieldsArraySetup = [  
        {className:'col-lg-4', maxLength: 20, disable: apiCallBtn, label: Helper.getLabel(language,'Code',"Code"),  inputType: "text", id:'code', name:'code'},
        {className:'col-lg-6', maxLength: 80, disable: apiCallBtn, label: Helper.getLabel(language,'Name',"Name"), required:true, inputType: "text", id:'name', name:'name'} 
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiOrgUrl+`/bank`, {
                ...formData,
                address: null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/edit-bank/${res?.data?.bank_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){ 
            formValidatory.displayMessage();
            formValidatory.reset(); 
            return;
        };
 
        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };
    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Bank</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArraySetup} />
                            </div>
                        </Form> 
                    </Collapse> 
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" 
                        isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (SetupBanksModal);