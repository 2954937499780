class CustomCheckboxCellRenderer {
    init(params) {
      this.eGui = document.createElement('div');
      this.checkbox = document.createElement('input');
      this.checkbox.type = 'checkbox';
      this.eGui.style.textAlign = 'center';
      this.checkbox.checked = !!params.value;
      this.checkbox.disabled = params.disabled;
      this.checkbox.addEventListener('change', (event) => {
        params.onChange(event.target.checked, params);
      });
  
      this.eGui.appendChild(this.checkbox);
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params) {
      this.checkbox.checked = !!params.value;
      return true;
    }
  }
  
  export default CustomCheckboxCellRenderer;
  