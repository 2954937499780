import React, { useCallback, useEffect, useState } from "react";

import Helper from "../../../../inc/Helper";


import RFQCommonApiCalls from "../../RFQCommonApiCalls";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import InviteModal from "./InviteModal";
import { useLocation, useNavigate } from "react-router-dom";
import InvitedVendorModal from "./InvitedVendorModal";
import SuggestedVendorModal from "./SuggestedVendorModal";
import { getPopupMessageBasedOnStatus } from "../../../../inc/Validation";
import AgGridNew from "../../../grid/ag/ag-grid-new";
import { getVendorColumns } from "./ColumnsHeader/ColumnsHeader";
import VendorsButtons from "./VendorsButtons";


let forceRender = 1
let gridObj = null

const VendorsGrid = ({
  language,
  rfqHeader,
  vendorGridData,
  setVendorGridData,
  isDisabled
}) => {
  const [vendorList, setVendorList] = useState([]);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openPendingInviteModal, setOpenPendingInviteModal] = useState(false);
  const [showSuggestedVendor, setShowSuggestedVendor] = useState(false);
  const [vendorReplyHeaderStatus, setVendorReplyHeaderStatus] = useState([]); 
  const [vendorSites,setVendorSites]=useState([])
  const navigate = useNavigate();
  const location = useLocation();

  let AddBtn;
  let transaction;
  let totalDropdownPage = 1

  const commonApiCalls = new RFQCommonApiCalls()
  useEffect(() => {
    initData();
  }, []);

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  }

  const id =  getSearchParam("rfq_view_id")

  

  const initData = () => {
    const getAllVendors = () => {
      const api = Api;
      api.setUserToken();
      api
        .axios()
        .get(Settings.apiVendorUrl + "/get_active_vendors")
        .then((res) => {
          if (res.data.status === "success") {
            setVendorList(res.data?.data);
            forceRender++
          }
        })
        .catch((error) => {
          Helper.alert(error?.response?.data?.message, "error");
        });
    };

    getAllVendors();

    const getVendorReplyHeaderStatus = () => {
      let api = Api;
      api.setUserToken();
      api
        .axios()
        .get(`${Settings.loginUrl}/enum/RFvendorreplyheaderstatus`)
        .then(function (res) {
          if (res.data.status === "success") {
            setVendorReplyHeaderStatus(res.data?.data);
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        });
    };

    getVendorReplyHeaderStatus();
  };

  const handleInviteVendor = () => {
    setOpenInviteModal(true);
  };

  const handlePendingInvite = () => {
    setOpenPendingInviteModal(true);
  };

 
  const getVenderNameByVendorId = (id) => {
    const vendor = vendorList.filter((v) => v.vendor_id === id);
    return vendor;
  };

  const cellHandler = (event) => {
    if (event?.value && event?.colDef?.field === "reply_id") {
      window.scrollTo(0, 0);
      navigate(
        `/request-for-quotation-reply/${event.data.vendorreply_id}`
      );
    }
  };

  const getSites = async ()  => {
    let api = Api
    try{
      api.setUserToken()
      let siteOptions = []
      const res = await api.axios().get(Settings.apiVendorUrl + "/get_active_vendor_sites")
      if (res.data.status === "success") {
       res.data.data.forEach(element => {
        siteOptions.push({
          label:element.name,
          value:element.vendorsite_id
        })
      });
        setVendorSites(siteOptions)
       
      }
    }
    catch(err){
    }
  }


  async function handleCodeDropDownChange(event, params) {
    const id = parseInt(event.target.value);
    const vendorName = getVenderNameByVendorId(id);
    const vendorSiteName = vendorList.find(
      (item) => item.vendor_id === id
    )?.defaultsite_id; 

    params.node.setDataValue("vendor_name", vendorName[0]?.name);
    params.node.setDataValue("expiry_datetime", null);
    params.node.setDataValue(
      "vendorsite_id",
      vendorSiteName || ""
    );
    const filteredVendor = vendorGridData?.filter(
      (vendor) => vendor.vendor_id === id
    );

    params.node.setDataValue(
      "contact_number",
      filteredVendor[0]?.contact_number || "-"
    );
    params.node.setDataValue("email", filteredVendor[0]?.email || "-");
  }

  const handleEditable = (params) => {
    return !params.data?.RFQvendor_id 
  }

  const handleGridReady = (obj) => {
    gridObj = obj
  }

  let select2SettingsVendor = {
    ajax: {
      url: (params) =>{
        totalDropdownPage = params.page || 1;
        return Settings.apiVendorUrl + '/get_active_vendors';
      },
      processResults: function (data) {
        let isMorePageExist = ( data.total_records - ( totalDropdownPage * Settings.dropdownPageSize )) >0 ? true : false ; 
        let result = data.data.map( item => {
          return {
            id: item.vendor_id,
            text: item.code+' | '+item.name
          }
        })
        return {
          results:result,
          pagination: {
            more: isMorePageExist
          }
        };
      }
    }
  }

  const Columns_Headings = getVendorColumns({
    vendorList:vendorList,
    vendorStatues:vendorReplyHeaderStatus,
    language:language,
    handleEditable:handleEditable,
    handleCodeDropDownChange:handleCodeDropDownChange,
    vendorSites:vendorSites,
    select2Settings:select2SettingsVendor
  })


  const onVendorDelete  = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl +
          `/RF_vendor/${element?.data?.RFQvendor_id}`)
      const rowNode = gridObj?.api?.getRowNode(element.data?.RFQvendor_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      if (res && index === lastIndex) {
        gridObj?.api?.refreshServerSide({ purge: true });
        gridObj?.api?.deselectAll();
      }
      Helper.alert(res?.data?.message, 'success')
      getVendorGridData()
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
      console.log(err)
    }
  }, [])


  const handleSuggestedVendor = () => {
    setShowSuggestedVendor(true);
  };

  const getVendorGridData = async () => {
    if (getSearchParam("rfq_view_id")) {
      const res = await commonApiCalls.getAllRfqVendors(
        getSearchParam("rfq_view_id")
      );
      res.map(async (el) => {
        let api = Api;
        api.setUserToken();
        const siteOptionsAPI = await api
          .axios()
          .get(
            Settings.apiVendorUrl + "/get_active_vendor_sites/" + el.vendor_id
          )
          .then(function (res) {
            if (res.data.status === "success") {
              res.data.data.map((el1) => {
                el1.label = el1.name;
                el1.value = el1.name;
              });
              forceRender++
              return res.data.data;
            }
          })
          .catch((error) => {
            Helper.alert(error?.response?.data?.message, "error");
          });
        el.siteOptions = siteOptionsAPI;
      });
      setVendorGridData(res);
    }
  };

  const handleAddBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

 

  const handleOnRowValueChanged = async (event) => {
    let payload = {
      RFQ_id: getSearchParam("rfq_view_id"),
      vendor_id: parseInt(event?.data?.vendor_code),
      vendorsite_id: parseInt(event?.data?.vendorsite_id),
      expiry:null,
      rfqvendorinvitation_id: null,
    }
    let api 
    api = Api
    if (!event?.data?.RFQvendor_id) {
          api.setUserToken();
          api
            .axios()
            .post(
              Settings.apiPurchaseRequisitionUrl + "/RFVendor",
              payload
            )
            .then((res) => {
              if (res?.data?.status === "success") {
                Helper.alert(res?.data?.message, 'success')
                gridObj?.api?.refreshServerSide({purge:true})
                setTimeout(() => {
                  if (AddBtn) {
                    gridObj?.api?.applyServerSideTransaction(transaction);
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              getPopupMessageBasedOnStatus(error)
            });
            gridObj?.api?.deselectAll()
    } else {
      try {
        api.setUserToken();
        const response = await api.axios().put(`${Settings.apiPurchaseRequisitionUrl}/RF_vendor/${event?.data?.RFQvendor_id}`, {
          "vendor_id": event?.data?.vendor_id,
          "vendorsite_id": event?.data?.vendorsite_id,
          'expiry_datetime': event?.data?.expiry_datetime,
        });
        if (response.data.status === "success") {
          Helper.alert(response.data.message, "success");
        } else {
          Helper.alert("Failed to update vendor info", "error");
        }
      } catch (error) {
        Helper.alert("Error updating vendor info", "error");
      }
    };
  };

  useEffect(()=>{
    getVendorGridData()
    getSites()
  },[])
   
  return (
    <>
     <div className="rfq-line-view">
      <AgGridNew
      columnDefs={Columns_Headings}
      apiUrl={Settings.apiPurchaseRequisitionUrl + `/get_vendors_of_RFQ/${id}`}
      pagination={false}
      afterDeleteBtns={<VendorsButtons language={language} rfqHeader={rfqHeader} handleInviteVendor={handleInviteVendor} handlePendingInvite={handlePendingInvite} handleSuggestedVendor={handleSuggestedVendor} isDisabled={isDisabled} />}
      onGridReady={handleGridReady}
      onCellClicked={cellHandler}
      onRowValueChanged={handleOnRowValueChanged}
      handleAddButton={handleAddBtn}
      handleDeleteSelectedRows={onVendorDelete}
      isAddBtnDisable={isDisabled}
      isDeleteBtnDisable={isDisabled}
      uniqueField='RFQvendor_id'
      />
      </div>
      {openInviteModal && (
        <InviteModal
          language={language}
          handleCloseModal={() => setOpenInviteModal(false)}
        />
      )}

      {openPendingInviteModal && (
        <InvitedVendorModal
          language={language}
          handleCloseModal={() => setOpenPendingInviteModal(false)}
        />
      )}

      {showSuggestedVendor && (
        <SuggestedVendorModal
          language={language}
          rfq_vendors={vendorGridData}
          rfq_id={getSearchParam('rfq_view_id')}
          getVendors={getVendorGridData}
          handleCloseModal={() => setShowSuggestedVendor(false)}
          gridVendorObj={gridObj}
        />
      )}
    </>
  );
};

export default VendorsGrid;
