import React, { useEffect, useState, useRef } from 'react'
import { Line } from 'react-chartjs-2'

//data for Curve Chart
export const data = {
  labels: ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12'],
  datasets: [
    {
      data: [20, 10, 60, 50, 80, 60],
      borderColor: '#00AB55',
      backgroundColor: '#00AB55',
      borderWidth: 3,
      pointBackgroundColor: '#ffffff',
      pointBorderColor: '#00AB55',
      pointRadius: 5,
      tension: 0.3,
      fill: true,
    },
  ],
}

const ResponseChart = () => {
  const chartRef = useRef(null)
  const [chartData, setChartData] = useState({ datasets: [] })

  //function to create gradient for lines
  function createLineGradient(ctx, area) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    gradient.addColorStop(0, 'rgba(255, 200, 55)')
    gradient.addColorStop(1, 'rgba(12, 175, 96)')

    return gradient
  }

  //function to create gradient for fill
  function createFillGradient(ctx, area) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    gradient.addColorStop(0, 'rgba(255, 200, 55, 0.04)')
    gradient.addColorStop(1, 'rgba(12, 175, 96, 0.04)') // Green with opacity
    return gradient
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart?.ctx || !chart?.chartArea) {
      return
    }

    const lineGradient = createLineGradient(chart.ctx, chart.chartArea)
    const fillGradient = createFillGradient(chart.ctx, chart.chartArea)

    //update data to set gradient colors
    const updatedData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        borderColor: lineGradient,
        backgroundColor: fillGradient,
      })),
    }

    setChartData(updatedData)
  }, [])

  //options for Curve Chart
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
  }

  return (
    <div className="chart-outer-div">
      <h5 className="fw-bold">My response to request more information</h5>
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  )
}

export default ResponseChart
