import React, { useEffect, useState } from "react";
import { Form, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import RFQCommonApiCalls from "../../RFQCommonApiCalls";
import Helper from "../../../../inc/Helper";
import BlueInfo from "../../../../assets/BlueInfo.svg";
import { getPopupMessageBasedOnStatus } from "../../../../inc/Validation";

const renderTooltip = (props,data) => {
  return ( 
    <Tooltip {...props}> 
     {data?.description}
    </Tooltip> 
  )
}

const ControlsData = [
  {
    id: 1,
    label: "Display scoring criteria to vendor",
    value: "display_scoring_criteria_to_vendor",
    description: "When checked, this control allows invited vendors to view the scoring criteria used for their evaluation in the RFQ process, provided that the criteria are available within the RFQ"
  },
  {
    id: 2,
    label: "Allow vendor to request extension",
    value: "allow_vendor_to_request_extension",
    description: "When enabled, this control displays an 'extend request' button on the bidding form for vendors, allowing them to request an extension if the RFQ is not yet expired. However, the decision to extend the RFQ remains at the discretion of the buyer."
  },
  {
    id: 3,
    label: "Allow vendor to select lines to respond",
    value: "allow_vendor_to_select_lines_to_respond",
    description: "When enabled, this control empowers vendors to selectively choose which RFQ lines they wish to respond to, giving them flexibility in their responses. If selected, vendors must still submit responses for all lines added in the RFQ."
  },
  {
    id: 4,
    label: "Require full quantity",
    value: "require_full_quantity",
    description: "When activated, this control ensures that vendors cannot modify the quantities specified in RFQ lines, obliging them to provide unit prices for the quantities as originally stated."
  },
  {
    id: 5,
    label: "Allow quote withdrawal",
    value: "allow_quote_withdrawal",
    description: "When activated, this option permits vendors to withdraw their submitted bids as long as the RFQ has not yet expired."
  },
  {
    id: 6,
    label: "Allow manual override of expiry date",
    value: "allow_manual_override_of_expiry_date",
    description: "When enabled, this control grants the buyer the ability to manually extend the RFQ's expiry date while the bid is still open. If not selected, changing the RFQ expiry date requires initiating a workflow approval process"
  },
  {
    id: 7,
    label: "Allow alternate lines on vendor reply",
    value: "allow_alternate_lines_on_vendor_response",
    description: "When activated, this control permits vendors to propose alternative items in their RFQ responses, deviating from the originally requested items. Note that for this feature to work, 'Allow vendor to select lines to respond' must also be enabled."
  },
  {
    id: 8,
    label: "Allow partial awarding",
    value: "allow_partial_awarding",
    description: "When selected, this function enables the buyer to select individual line items from different vendors in the RFQ process for awarding. If not selected, the buyer can only award the entire RFQ to a single vendor."
  },
  {
    id: 9,
    label: "Allow view commericals values in technical evaluation",
    value: "allow_view_commericals_in_technical_evaluation",
    description: "When selected, this function enables the inclusion of commercial values in the technical evaluation process. This means that during the technical evaluation phase, evaluators will have visibility into the commercial aspects alongside technical considerations. If not selected, commercial values will be hidden during the technical evaluation, ensuring a focused assessment on non-commercial aspects."
  },
];

const Controls = ({ rfqHeader , setRfqHeader, updateRfqHeader , setUpdateRfqHeader, isDisabled}) => {
  const [checkedBoxValues, setCheckedBoxValues] = useState([]);
  const commonApiCalls = new RFQCommonApiCalls();

  useEffect(() => {
    const newStateValues = ControlsData?.map((data) => {
      if (rfqHeader?.[data?.value]) {
        return { ...data, isChecked: true };
      }
      return { ...data, isChecked: false };
    });

    setCheckedBoxValues(newStateValues);
  }, [rfqHeader]);

  const onHandleChange = async (label) => {
    let payload = null;
    let updatedCurrentState = checkedBoxValues.map((checkBox) => {
      if (checkBox.label === label) {
        payload = { ...updateRfqHeader, [checkBox.value]: !checkBox.isChecked };
        setRfqHeader({...rfqHeader, [checkBox.value]: !checkBox.isChecked})
        setUpdateRfqHeader({...updateRfqHeader, [checkBox.value]: !checkBox.isChecked})

        return { ...checkBox, isChecked: !checkBox.isChecked };
      }
      return checkBox;
    });

    try {
      const updateRfqRes = await commonApiCalls.updateRfqHeader(
        rfqHeader?.RFQ_id,
        payload
      );
      if (updateRfqRes) {
        Helper.alert(updateRfqRes?.message, "success");
        setCheckedBoxValues(updatedCurrentState);
        setUpdateRfqHeader({})
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
      setUpdateRfqHeader({})
    }
  };

  return (
    <div className="RfqHeaderControls">
      {checkedBoxValues.map((data) => (
        <div className="RfqHeaderControls-item" key={data.id} >
          <Form.Check
            type={"checkbox"}
            id={data.id}
            onChange={(e) => onHandleChange(data.label)}
            checked={data.isChecked}
            className="control-check checkBoxControl"
            disabled={isDisabled}
          />
          <span />
          <label className="control-label" htmlFor={data.id}>{data.label}</label>
          {/* this is a Tooltip...  */}
          <OverlayTrigger 
        overlay={(props) => renderTooltip(props,data)} 
        placement="top"
      >
         <Image
            src={BlueInfo}
            alt=""
            style={{ cursor: "pointer" }}
            height={17}
            width={17}
          />
      </OverlayTrigger>
        </div>
      ))}
    </div>
  );
};

export default Controls;
