import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'

const RFQCommercialProposal = (props) => {

    const gridLinkValueFormat = (params) => {
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }
     
    const GridColumnsCommercialProporsals = [
        {field:'line', headerName:Helper.getLabel(props.language,'line',"Line"), inputType: "dropdown", options: [], cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat, minWidth: 160, },
        {field:'type', headerName:Helper.getLabel(props.language,'type',"Type"), inputType: "dropdown", options: [], minWidth: 210,},
        {field:'description', headerName:Helper.getLabel(props.language,'description',"Description"), inputType: "dropdown", options: [], minWidth: 260, },
        {field:'qty', headerName:Helper.getLabel(props.language,'qty',"Qty"), inputType: "dropdown", options: [], minWidth: 240,},
        {field:'unit', headerName:Helper.getLabel(props.language,'unit',"Unit"), inputType: "dropdown", options: [], minWidth: 280,},
        {field:'value', headerName:Helper.getLabel(props.language,'value',"Value"),minWidth: 200,},
        {field:'price', headerName:Helper.getLabel(props.language,'price',"Price"),minWidth: 200, },
        {field:'amount', headerName:Helper.getLabel(props.language,'amount',"Amount"),minWidth: 200, },
        {field:'date', headerName:Helper.getLabel(props.language,'date',"Need by date"), inputType: "datepicker",minWidth: 200, },
    ]

    const CommercialLabels = () => {
        return (
            <Fragment>
                <h2 className='grid_label_rfq'>Billing Rule</h2>
                <h2 className='grid_label_rfq_commercial ms-3'>Status : <span>Incomplete</span></h2>
                <h2 className='grid_label_rfq_commercial ms-2 me-3'>Total : <span>9,000 USD</span></h2>
            </Fragment>
        )
    }

  return (
    <AgGrid labels={{btnDelete:"Remove", btnAdd: "Add Item", }} id='rfq_commercial_proposal_grid' header={GridColumnsCommercialProporsals} beforeAddButton={CommercialLabels} data={[]} hideSave={true} addBtnHide={false} hideDelete={false} />
  )
}


const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}


export default connect(mapStateToProps) (RFQCommercialProposal) 