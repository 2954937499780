import React, { useState, useEffect, Fragment, useCallback } from "react";
import Settings from "../../../inc/Settings";
import { connect } from "react-redux"; 
import DropDownInput from "../../Forms/Dropdown";
import Helper from "../../../inc/Helper";
import { 
  getPopupMessageBasedOnStatus,
} from "../../../inc/Validation";
import NavigationHeder from "../../Navigations/NavigationHeder";
import Alert from "../../inc/Alert";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import Api from "../../../inc/Api";
import AgGridNew from "../../grid/ag/ag-grid-new";
import SimpleDropdown from "../../grid/ag/cellEditor/SimpleDropdown";
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from "../../grid/ag/commonFunctions/GridCommonFunctions";

let gridApi = null;
let AddBtn;
let transaction;
const FormResources = (props) => { 
  const [formDropdown, setFormDropdown] = useState("");
  const [gridKey, setGridKey] = useState(null);

  const [Forms, setForms] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
    
  let resourcesListCalled = false;
  let formDataCalled = false;

  useEffect(() => {
    getSystemResources();
    getSystemForms();
  }, []);

  useEffect(() => {
    if (gridKey) {
      const timeout = setTimeout(() => {
        gridApi?.refreshServerSide({ purge: true });
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [gridKey]);
 
  const getSystemForms = () => {
    if (formDataCalled) return;
    formDataCalled = true;

    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiSystemUrl + "/forms", {})
      .then(function (res) {
        if (res.data.status === "success") {
          setForms(res?.data?.data?.map((item) => {
            return {
              value: item.form_Id,
              label: item.formname
            }
          }));
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const getSystemResources = () => {
    if (resourcesListCalled) return;
    resourcesListCalled = true;

    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiSystemUrl + "/resource", {})
      .then(function (res) {
        if (res.data.status === "success") {
          setResourcesList(res?.data?.data?.map((item) => {
            return {
              value: item.resource_id,
              label: item.resource_type
            }
          }));
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const FormsHandler = (event) => { 
    setFormDropdown(event.target.value);
    setGridKey(Date.now());
  };

  const handleBtn = (value, transactionData) => {
    AddBtn = value;
    transaction = transactionData;
  };

  const onRowValueChanged = (event) => {
    if (event?.data?.form_resource_id) {
      updateData(event);
    } else {
      addNewData(event);
    }
  };
   
  const updateData = async (event) => {
    try {
      let payload = {
        form_id: parseInt(formDropdown),
        resource_id: parseInt(event?.data?.resource_id),
        resourceGUI_id: (event?.data?.GUI_ID),
        resource_path: event?.data?.path,
        resource_name: event?.data?.name,
        resource_description: event?.data?.description
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiSystemUrl + `/form_resource/${event?.data?.form_resource_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const addNewData = async (event) => {
    try {
      let payload = [{
        form_id: parseInt(formDropdown),
        resource_id: parseInt(event?.data?.resource_id),
        resourceGUI_id: (event?.data?.GUI_ID),
        resource_path: event?.data?.path,
        resource_name: event?.data?.name,
        resource_description: event?.data?.description
      }];

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiSystemUrl + '/add_formsresoucres', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delFormResource_ID = element?.data?.form_resource_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiSystemUrl+`/form_resource/${delFormResource_ID}`)
      const rowNode = gridApi?.getRowNode(delFormResource_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  const gridColumnHeaders = [
    {
      field: "GUI_ID",
      headerName: Helper.getLabel(
        props.language,
        "gui_id_sr_fr_rsc_1",
        "Gui id"
      ),
      editable: true
    }, 
    {
      field: "path",
      headerName: Helper.getLabel(props.language, "path_sr_fr_rsc_3", "Path"),
      editable: true
    },
    {
      field: "name",
      headerName: Helper.getLabel(
        props.language,
        "name_sr_fr_rsc_4",
        "Resource name"
      ),
      editable: true
    },
    { 
      field: 'resource_id', 
      headerName: Helper.getLabel(
        props.language,
        "resource_id_sr_fr_rsc_5",
        "Resource"
      ),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth: 180, 
      cellEditorParams: { values: resourcesList },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, resourcesList),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    }, 
    {
      field: "description",
      headerName: Helper.getLabel(
        props.language,
        "description_sr_fr_rsc_6",
        "Resource description"
      ),
      editable: true
    },
  ];

  if (!props?.user?.admin_user) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <NavigationHeder hideMoreBtn={true} title="Form Resources" />

      <div className="container-fluid">
        <div className="row gy-0">
          <div className="col-3">
            <DropDownInput
              label={Helper.getLabel(props.language, "security_form", "Form")}
              options={Forms}
              id={'form_id'}
              name={'form_id'}
              value={formDropdown}
              onChange={FormsHandler}
            />
          </div>
        </div>
      </div>
      
      <AgGridNew
        apiUrl={Settings.apiSystemUrl + "/formresources"}
        apiMethod={"post"}
        returnApiCondition={!formDropdown}
        payload={{ form_id: parseInt(formDropdown) }}
        pagination={false}
        key={gridKey}
        columnDefs={gridColumnHeaders}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={DeleteHandler}
        height={500}
        onGridReady={(params) => gridApi = params?.api }
        handleAddButton={handleBtn}
        uniqueField={`form_resource_id`}
        gridId={"rs_form_resources_grid"}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MasterComponentWraper(FormResources)); 