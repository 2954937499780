export const htmlContent2 = `<!doctype html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Lynkaz | Oops!</title>
        <link rel="shortcut icon" href="https://dfydac381qi3t.cloudfront.net/static/public/images/favicon.ico">
        <style>
            @import url('https://fonts.googleapis.com/css?family=Overpass:400,600&display=swap'); </style>
        <style>
            body, html {
                font-family: 'Overpass', sans-serif;
                background: #414141;
                color: #fff;
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
            }

            a {
                color: #fff;
            }

            .container {
                display: flex;
                height: 100%;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .btn {
                font-size: 11px;
                text-decoration: none;
                position: relative;
                display: inline-block;
                min-width: 10em;
                padding: 1.09091em 2.18182em;
                margin-bottom: 0;
                font-family: Open Sans,Arial;
                font-style: normal;
                font-weight: 600;
                line-height: 1.09091em;
                text-align: center;
                text-transform: uppercase;
                white-space: normal;
                vertical-align: middle;
                cursor: pointer;
                border: 1px solid #e3e3e3;
                -webkit-border-radius: .27273em;
                -moz-border-radius: .27273em;
                -ms-border-radius: .27273em;
                -o-border-radius: .27273em;
                border-radius: .27273em;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-box-shadow: 0 0.166em 0.333em 0 rgba(0,0,0,.2);
                -moz-box-shadow: 0 .166em .333em 0 rgba(0,0,0,.2);
                box-shadow: 0 0.166em 0.333em 0 rgba(0,0,0,.2);
                background-color: #03ba5b;
                border: 1px solid #03ba5b;
            }

            h1 {
                margin-top: 0;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="info-text">
                <div class="bg-circles">
                    <div class="astronaut"></div>
                    <div class="info-text">
                        <h1>
                            Lynkaz,<br/>WE HAVE A PROBLEM...
                        </h1>
                        <h2>the page you're looking for is lost in space</h2>
                        <a class="btn btn--green btn--large mt40" href="https://dev.lynkaz.com/login">RETURN TO DASHBOARD</a>
                    </div>
                </div>
            </div>
        </div>
    </body>
</html>
`
