import React from 'react'

import AddProfileIcon from '../../../../assets/vendor req.svg'

const EmployeeInformationCard = ({
  title,
  information,
  mainCardClass,
  totalValue,
  completedValue,
  progressValue,
}) => {
  //Common Card for Employee Information.
  return (
    <div className={`${mainCardClass} info-card-common`}>
      <div className="icon-style">
        <img src={AddProfileIcon} alt="" width="40px" height="40px" />
      </div>
      <div>
        <h6 className="info-title">{title}</h6>
        <h3 className="info-style">{information}</h3>
      </div>
      <div className="row">
        <div className="col-sm-4 d-flex flex-column">
          <p className="subtitle-style">Total</p>
          <h6>{totalValue}</h6>
        </div>
        <div className="col-sm-4 d-flex flex-column">
          <p className="subtitle-style">Completed</p>
          <h6>{completedValue}</h6>
        </div>
        <div className="col-sm-4 d-flex flex-column">
          <p className="subtitle-style">In Progress</p>
          <h6>{progressValue}</h6>
        </div>
      </div>
    </div>
  )
}

export default EmployeeInformationCard
