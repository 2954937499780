import React from "react";
import Select from '../../assets/select.svg';
import './FiscalYear.css'

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
  ref={ref}
  onClick={e => {
    e.preventDefault();
    onClick(e);
  }}> 
    {children}
    <img src={Select} className='icon ms-2' />
  </button>
  ));
 