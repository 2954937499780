import React, { Fragment, useEffect, useState } from 'react';
import $ from 'jquery'; 
import { connect } from "react-redux";
import axios from 'axios';    
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap'; 
import Popup from '../../Popup/Popup';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Collapse from '../../inc/Collapse';
import FormValidator from '../../../inc/FormValidator';
import Button from '../../inc/Button';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

let cancelTokenSource = axios.CancelToken.source();
const CreateItemModal = ({setShowCreateItemModal, language}) => {
    const navigate = useNavigate();

    const [itemData, setItemData] = useState({});
 
    const [apiCallBtn, setApiCallBtn] = useState(false);
 
    const [numberSequence, setNumberSequence] = useState({});
    const [purchaseUnit, setPurchaseUnit] = useState([])
    const [taxGroup, setTaxGroup] = useState([]);
    const [itemGroup, setItemGroup] = useState([]);
    const [itemMaster, setItemMaster] = useState([]);
    const [itemBuyerGroup, setItemBuyerGroup] = useState([]);

    let itemMasterValidator;   

    const validationConfigure = () => {
        let itemMasterFieldConfig = [
            {name:'item_name', displayName:Helper.getLabel(language,'name','Name'), types:['Required']},  
            {name:'display_name', displayName:Helper.getLabel(language,'display_name','Display name'), types:['Required']},  
            {name:'purchaseUoM', displayName:Helper.getLabel(language,'purchaseUoM','Purchase unit'), types:['Required']}, 
            {name:'item_group_id', displayName:Helper.getLabel(language,'item_group','Item group'), types:['Required']}, 
            {name:'itemmaster_type', displayName:Helper.getLabel(language,'item_master','Item master type'), types:['Required']},  
        ];

        if(!itemMasterValidator) {
            itemMasterValidator = new FormValidator(itemMasterFieldConfig, language)
        }; 
    };

    validationConfigure();

    let itemGroupListCalled = false; 
    let taxGroupListCalled = false;
    let purchaseUnitListCalled = false;
    let itemMasterListCalled = false;
    let buyerGroupListCalled = false;
    let nsApiCalled = false;

    useEffect(() => {  
        getTaxItemGroups();
        getTaxGroups();
        getPurchaseUnits();
        getItemMaster();
        getBuyerGroup();
        getNumberSequenceData();
    }, []);

    const getNumberSequenceData = async () => {
        if(nsApiCalled) return;
        nsApiCalled = true;

        try {
            let api = Api
            api.setUserToken();
            const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/None/${Settings.source_id.item}`)
            if (numberSequenceRecord?.status===200) { 
                setNumberSequence(numberSequenceRecord?.data)
            }
        } catch(error){
            setNumberSequence({})
        }
    };

    const getTaxGroups = () => {
        if(itemGroupListCalled) return;

        let api = Api
        itemGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/item_group`,{}).then(function(res){
            if(res.data.status==="success"){
                setTaxGroup(res?.data?.data?.map((element) => {
                    return {
                        value: element.itemgroup_id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const getTaxItemGroups = () => {
        if(taxGroupListCalled) return;

        let api = Api
        taxGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/taxgroupitem`,{}).then(function(res){
            if(res.data.status==="success"){  
                setItemGroup(res?.data?.data?.map((element) => {
                    return {
                        value: element.taxgroupitem_id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const getPurchaseUnits = () => {
        if(purchaseUnitListCalled) return;

        let api = Api
        purchaseUnitListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
            if(res.data.status==="success"){
                setPurchaseUnit(res?.data?.data?.map((element) => {
                    return {
                        value: element.unit_id,
                        label: `${element.description} - ${element.code}`
                    }
                }))
            }
        })
    };

    const getItemMaster = () => {
        if(itemMasterListCalled) return;

        let api = Api
        itemMasterListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/enum/itemmastertype`,{}).then(function(res){
            if(res.data.status==="success"){ 
                setItemMaster(res?.data?.data?.map((element) => {
                    return {
                        value: element.id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const getBuyerGroup = () => {
        if(buyerGroupListCalled) return;

        let api = Api
        buyerGroupListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/item_buyergroup`,{}).then(function(res){
            if(res.data.status==="success"){  
                setItemBuyerGroup(res?.data?.data?.map((element) => {
                    return {
                        value: element.itembuyergroup_id,
                        label: element.name
                    }
                }))
            }
        })
    };

    const fieldsArrayGeneral = [
        {className:'col-lg-4', maxLength: 20, required: !numberSequence?.ns_type == "continuous", disable: numberSequence?.ns_type == "continuous" || apiCallBtn, label: Helper.getLabel(language,'item_code',"Item code"), inputType: "text", id:'code', name:'code'},
        {className:'col-lg-4', maxLength: 255, disable: apiCallBtn, required: true, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'item_name', name:'item_name'},
        {className:'col-lg-4', maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'display_name',"Display name"), required:true, inputType: "text", id:'display_name', name:'display_name'}, 
        {className:'col-lg-4', placeHolder: 'Select purchase unit', disable: apiCallBtn, required: true, options: purchaseUnit, label: Helper.getLabel(language,'purchase_unit',"Purchase unit"), inputType: "dropdown", id:'purchaseUoM', name:'purchaseUoM'},
        {className:'col-lg-4', placeHolder: 'Select item group', disable: apiCallBtn, required: true, options: taxGroup, label: Helper.getLabel(language,'item_group_id',"Item group"), inputType: "dropdown", id:'item_group_id', name:'item_group_id'}, 
        {className:'col-lg-4', placeHolder: 'Select item master type', disable: apiCallBtn, required: true, options: itemMaster, label: Helper.getLabel(language,'itemmaster_type',"Item master type"), inputType: "dropdown", id:'itemmaster_type', name:'itemmaster_type'},
        {className:'col-lg-4', placeHolder: 'Select tax item group', disable: apiCallBtn, options: itemGroup, label: Helper.getLabel(language,'taxgroupitem_id',"Tax item group"), inputType: "dropdown", id:'taxgroupitem_id', name:'taxgroupitem_id'},
        {className:'col-lg-4', placeHolder: 'Select buyer group', disable: apiCallBtn, options: itemBuyerGroup, label: Helper.getLabel(language,'item_buyergroup_id',"Buyer group"), inputType: "dropdown", id:'item_buyergroup_id', name:'item_buyergroup_id'},
        {className:'col-lg-4', disable: apiCallBtn, label: Helper.getLabel(language,'price_include_tax',"Price include tax"), inputType: "checkbox", id:'price_include_tax', name:'price_include_tax'}
    ];

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateItemModal(false);
    }

    const executePOSTItemAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiItemUrl + '/item', {
                ...itemData,
                price_include_tax: itemData.price_include_tax == "on",
                item_buyergroup_id: itemData.item_buyergroup_id !== "" ? itemData.item_buyergroup_id : null,
                taxgroupitem_id: itemData.taxgroupitem_id !== "" ? itemData.taxgroupitem_id : null
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/edit-item/${res?.data?.item_id}`); 
            };
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        };
    };

    const handleSubmit = () => { 
        if(!itemMasterValidator.isValid({...itemData})){
            itemMasterValidator.displayMessage();
            itemMasterValidator.reset();
            return;
        };
 
        if(!numberSequence?.ns_type == "continuous" && (!itemData?.code || itemData?.code === "")) {
            Helper.alert('Item code field is required', 'error');
            return;
        }

        Helper.createDebouncedAPIFunction( 
            [executePOSTItemAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} 
                width="900px"
                onClose={onPopupClose}
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Item</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setItemData} currentState={itemData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse> 
                </> 

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        isDisable={apiCallBtn}
                        onClick={onPopupClose}
                    />
                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" 
                        isActive={true}
                        title={Helper.getLabel(language, "create", "Create")} 
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps)(CreateItemModal); 