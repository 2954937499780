import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import Tabs from "../../Widgets/Tabs/Tabs";
import LineDistribution from "./LineDistribution";
import RFQBiddingRequirement from "../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQBiddingRequirement";


class DistributionTabs extends Component {
  constructor(props) {
    super(props);
    this.settings = this.props.settings ? this.props.settings : null;
    this.uniqueIdForTable = this.settings
      ? this.settings.getIdKey()
      : "linesite_id";
    this.state = {
      additionalInfo: [],
      gridAPIObj: [],
      activePrLineSite: null,
      gridObj: null,
    };

    this.updateGridObj = this.updateGridObj.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.getSegmentDistributionAmmount =
      this.getSegmentDistributionAmmount.bind(this);
  }

  updateGridObj(data) {
    this.setState({ gridObj: data });
  }

  hasActivePrCartlineSite() {
    let selectedNodes = this?.state?.gridObj?.api.getSelectedNodes();
    return selectedNodes?.length === 0 ? false :Boolean(this.state?.activePrLineSite?.data?.[this.uniqueIdForTable]);
  }

  onSelectionChanged(event) {
    let selectedNodes = event.api.getSelectedNodes();
    if (selectedNodes.length <= 0) {
      this.setState({
        activePrLineSite: null,
      });
    } else {
      this.setState({
        activePrLineSite: selectedNodes[0],
      });
    }
  }

  getSegmentDistributionAmmount() {
    let selectedNodes = this.state.gridObj?.api?.getSelectedNodes();
    let output = 0;
    if (selectedNodes?.length >= 1) {
      output = parseFloat(selectedNodes[0].data.line_amount);
    }
    return output;
  }

  render() {
    let distributionNavs = [
      {
        title: Helper.getLabel(
          this.props.language,
          "distribution",
          "Distribution"
        ),
        isActive: true,
      },
      {
        title: Helper.getLabel(this.props.language, "segments", "Segments"),
        isDisable: !this.hasActivePrCartlineSite(),
      },
      {
        title: Helper.getLabel(this.props.language, "requirements", "Requirements"),
      },
    ];


    let seg_distribution_source_id = null;
    let segment_integrator = null;
    if (this.hasActivePrCartlineSite()) {
      segment_integrator = this.state.activePrLineSite.data.linesite_id;
      seg_distribution_source_id =
        this.state.activePrLineSite.data.distributionsource_id;
    }

    

    return (
      <Tabs
        id='list_view_parent_tab'
        tabContantsId='list_view_parent_tab_contents'
        navs={distributionNavs}
        className='purchase-orders-list-view-tabs'
        showAll={false}
        disableSticky={true}
        scrollOffset='300'
      >
        <div className='container-fluid'>
          <LineDistribution
            gridId={this.props.gridId}
            cartItem={this.props.activeCartItem}
            source_id={this.props.source_id}
            viewOnly={this.props.viewOnly}
            sites={this.props.sites}
            updateSitesData={this.props.updateSitesData}
            updateExistingSiteIds={this.props.updateExistingSiteIds}
            onSelectionChanged={this.onSelectionChanged}
            updateGridObj={(e) => this.updateGridObj(e)}
            currentDocumentStatus={this.props.currentDocumentStatus}
            type={this.props.type}
          />
        </div>
        <div>
          {this.hasActivePrCartlineSite() ? (
            <SegmentsDistribution
              id={`prcartline_segment_distribution_${Date.now()}`}
              onCreateFirstDistribution={(distribution_source_id) =>
                this.props.onCreateFirstDistribution(
                  distribution_source_id,
                  segment_integrator
                )
              }
              distribution_source_id={seg_distribution_source_id}
              integrator={segment_integrator}
              source_id={this.props.source_id}
              totalAmount={this.getSegmentDistributionAmmount()}
              isEditable={!this.props.viewOnly}
              accountingDate={this.props.accountingDate}
            />
          ) : (
            ''
          )}
        </div>
        <div className='rfq-internal-requirements bidding_requirement'>
          <RFQBiddingRequirement
            type='rfq'
            scopeOfWork={this.props?.scope_of_work}
            materialsAndServicesNotIncluded={
              this.props?.material_service_not_included
            }
            agreementTerms={this.props?.requirement_description}
            disable={this.props.isDisable}
            onRequirementChange={this.props.handleInputChange}
            onRequirementBlur={this.props.handleInputChange}
          />
        </div>
      </Tabs>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeCartItem: state.prShop.activeCartItem,
  };
};

export default connect(mapStateToProps)(DistributionTabs);
