import React from 'react';
import POArchive from '../../components/Purchase Orders Internal/Archive PO/POArchive';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';

const PurchaseAgreementArchivePage = (props) => {
    return (
        <POArchive 
            title={Helper.getLabel(null, 'purchase_agreement', 'Purchase Agreement - All')}
            createFormTitle={Helper.getLabel(null, 'create_purchase_agreement', 'Create Purchase Agreement')}
            editUrl="/purchase-agreement/edit"
            type={"purchase agreement"}
            security={props?.security}
            source_id={Settings.source_id.purchase_agreement}
            formType={"purchase agreement"}
        />
    );
};

const SecurityOptions = {
  gui_id: Gui_id_list.procure.purchase_agreement.purchase_agreement_main
};

export default (((ApplySecurityRoles(PurchaseAgreementArchivePage, SecurityOptions)))); 