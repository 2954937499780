import React, { useCallback, useState} from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';  
import NavigationHeder from '../../Navigations/NavigationHeder';
import Settings from '../../../inc/Settings';  
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../inc/Alert';
import Helper from '../../../inc/Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonNew from '../../inc/ButtonNew'; 
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import CreateTeamHeaderModal from './CreateTeamHeaderModal';

let gridApi = null;
const TeamHeader = (props) => {   
  const [showTeamHeaderModal, setShowTeamHeaderModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const CellHandler = (event) => {
    if(event.node.data.teamheader_id !== ''){ 
    if(event.colDef.field === 'name') { 
      navigate(`/team-header-edit/${event.data.teamheader_id}`) 
    }
    }else {
      Helper.alert('Team Header Doesnt Exists!', 'error')
    }
  }
 
  const DeleteParamters = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delTeamHeader_ID = element?.data?.teamheader_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/teamheader/${delTeamHeader_ID}`)
      const rowNode = gridApi?.getRowNode(delTeamHeader_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert('Team header deleted successfully', 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const Columns_Headings = [
    {field:'name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language,'name','Name') , editable: false, cellRenderer: (params) => gridLinkValueFormat(params) }, 
  ]

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  }

  return ( 
    <div className='team_header_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={location?.search === '?dynamic_team_header=true'?Helper.getLabel(props.language,'team-dynamic','Dynamic - Teams'):Helper.getLabel(props.language,'team','Teams')}>
          <ButtonNew isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_main_create_button)} onClick={() => setShowTeamHeaderModal(true)} state={location?.search === '?dynamic_team_header=true' &&{dynamic_team:true}}title={Helper.getLabel(props.language,'new','New')} />
        </NavigationHeder>  

        <AgGridNew
          apiUrl={Settings.apiUrl+'/teamheader'}
          hideDeleteBtn={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_main_delete_button)}
          additionalParams={location?.search === '?dynamic_team_header=true' ? '?dynamic_team_header=true':'?dynamic_team_header=false'}
          uniqueField={'teamheader_id'}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteParamters}
          onGridReady={(params) => gridApi = params.api}
          gridId={"rs_team_header_list"}
        /> 
      </div>
      {showTeamHeaderModal && <CreateTeamHeaderModal isDynamic={location?.search === '?dynamic_team_header=true'} setShowCreateTeamHeaderModal={setShowTeamHeaderModal} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header
}
  
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(TeamHeader, SecurityOptions))))