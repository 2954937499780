class DistributionSettings {
    constructor(){
        this.apiUrl = {
            create:null,
            update:null,
            delete:null,
            get:null
        }
        this.idKey = null;
        this.distributionHeader = [];
        this.additionalFields = [];
    }
    setAdditionalFields(fields){
        this.additionalFields = fields;
    }
    getAdditionalFields(){
        if(!Array.isArray(this.additionalFields)){
            return []
        }
        return this.additionalFields;
    }
    /**
     * 
     * @param {*} data 
     * @returns 
     */
    filterPostData(data){
        return data;
    }
    /**
     * 
     * @param {*} data 
     * @returns 
     */
    filterPutData(data){
        return data;
    }
    /**
     * 
     * @param {*} headerDetails 
     */
    setDistributionHeader(headerDetails = []){
        this.distributionHeader = headerDetails;
    }
    /**
     * 
     * @param {*} IdKey 
     */
    setIdKey(IdKey){
        this.idKey = IdKey;
    }
    getIdKey(){
        return this.idKey;
    }
    /**
     * 
     * @param {*} createApiUrl 
     * @param {*} updateApiUrl 
     * @param {*} deleteApiUrl 
     * @param {*} getApiUrl 
     */
    setApiUrl(createApiUrl,updateApiUrl,deleteApiUrl,getApiUrl){
        this.apiUrl = {
            create:createApiUrl,
            update:updateApiUrl,
            delete:deleteApiUrl,
            get:getApiUrl
        }
    }
    getApiUrl(){
        return this.apiUrl;
    }
    getDistributionHeader(){
        return this.distributionHeader;
    }

    
}
export default DistributionSettings;