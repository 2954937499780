import React from 'react'
import AgGrid from '../grid/ag/ag-grid'
import Popup from '../Popup/Popup'
import Helper from '../../inc/Helper'


const ParticipantsPopup = ({onPopupCloseClickHandler,language}) => {
  const Columns_Headings = [
    {
      field: 'first_name',
      minWidth: 280,
      headerName: Helper.getLabel(language, 'first_name', 'First Name'),
      editable: false,
    },
    {
      field: 'last_name',
      minWidth: 180,
      headerName: Helper.getLabel(language, 'last_name', 'Last Name'),
    },
    {
      field: 'email',
      minWidth: 180,
      headerName: Helper.getLabel(language, 'email_address', 'Email Address'),
    },
    {
      field: 'initiate_date',
      minWidth: 180,
      headerName: Helper.getLabel(language, 'Initiate Date', 'Initiate Date'),
      editable: false,
    },
    {
      field: 'complete_date',
      minWidth: 160,
      headerName: Helper.getLabel(language, 'complete_date', 'Complete Date'),
      editable: false,
    },
    {
      field: 'status',
      minWidth: 180,
      headerName: Helper.getLabel(language, 'status', 'Status'),
    },
  ]

  return (
    <div>
     <Popup autoOpen={true} onClose={onPopupCloseClickHandler} width={'1100px'}>
       <AgGrid header={Columns_Headings} hideSave={true} data={[]} addBtnHide={true} viewOnly={true} />
     </Popup>
    </div>
  )
}

export default ParticipantsPopup
