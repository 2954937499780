import React, { useState, useEffect, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../inc/Validation'
import Helper from '../../inc/Helper'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SimpleDropdown from '../../components/grid/ag/cellEditor/SimpleDropdown'

const CrudExample = () => {
  const [rowData, setRowData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [sourcesDetails,setSourcesDetails] = useState([])
  const [isAddBtnClicked,setIsAddBtnClicked]=useState(false)
  const [isRowModified, setIsRowModified] = useState(true);

  const gridApi = useRef(null);
  

  const sourcesDetails2 = sourcesDetails.map((item)=>{
    return {
      value: item?.source_id,
      label: item?.source_name
    }
  })

  const getLabelFromDropdown = (params) => {
          let output = params.value;
          if( Array.isArray(params.colDef.cellEditorParams?.values)){
            params.colDef?.cellEditorParams?.values?.forEach(item => {
                  if(parseInt(params.value) === parseInt(item.value)){
                      output = item.label;
                  }
              })
          }
          if(!params.colDef.isAllowZero){
              if(output == 0 ){
                  return '';
              }
          }
          return output;
  }
  
  const columnDefs = [
    {
      headerName: 'Source',
      field: 'source_id',
      editable:true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      cellEditorParams: {
          values: sourcesDetails2,
      },
      cellDataType:false,
      valueFormatter: (params)=>{return getLabelFromDropdown(params)}
    },
    {
      headerName: 'Name',
      field: 'name',
      editable:true,
    },
    {
      headerName: 'File Server Path',
      field: 'fileserverpath',
      editable:true,
    },
    {
      headerName: 'Repository',
      field: 'repository',
      editable:true,
    },
    {
      headerName: 'Mandatory',
      field: 'mandatory',
      editable:true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueGetter:(params)=>{
         if(params?.data === null || params?.data === undefined || objectIsEmpty(params?.data)){
          return false
        }
        else{
          return params.data?.mandatory
        }
      }
    }, 
    {
      headerName: 'Allowed to external',
      field: 'external_allowed',
      editable:true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueGetter:(params)=>{
        if(params?.data === null || params?.data === undefined || objectIsEmpty(params?.data)){
         return false
       }
       else{
         return params.data?.external_allowed
       }
     }
    }
  ]

  const getAllAttachments = async () => {
    let api = Api
    api.setUserToken()
    try {
      const attachmentTypeData = await api.axios().get(Settings.apiUrl + '/attachmenttype')
      setRowData(attachmentTypeData?.data?.data)
    } catch (err) {}
  }

  const getAllSources = async () => {
    let api = Api
    api.setUserToken()
    try {
      const sourcesData = await api.axios().get(Settings.apiUrl + '/source')
      setSourcesDetails(sourcesData?.data?.data)
    } catch (err) {}
  }

  const onRowValueChangedHandler = async (event) => {
    const { attachmenttype_id, ...payload } = event.data
    try {
      if (attachmenttype_id) {
        await updateAttachmentType(attachmenttype_id, payload)
         setIsRowModified(true)
      } else {
        const newAttachmentTypeId = await addAttachmentType(payload)
        updateRowDataWithNewId(newAttachmentTypeId,payload)
        setIsRowModified(true)
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
      setIsRowModified(false)
    }
  }

  const updateAttachmentType = async (attachmentTypeId, payload) => {
    let api = Api
    api.setUserToken()
    const attachmentData = await api
      .axios()
      .put(`${Settings.apiUrl}/attachmenttype/${attachmentTypeId}`, payload)
    Helper.alert(attachmentData?.data?.message)
  }

  const addAttachmentType = async (payload) => {
    let api = Api
    api.setUserToken()
    const attachmentTypeData = await api
      .axios()
      .post(`${Settings.apiUrl}/attachmenttype`, payload)
    Helper.alert(attachmentTypeData?.data?.message)
    return attachmentTypeData?.data?.attachment_type_id
  }

  const updateRowDataWithNewId = (newAttachmentTypeId, payload) => {
    let data = {...payload,attachmenttype_id:newAttachmentTypeId}
    setRowData(prevRowData => {
      return [...prevRowData, data];
    });
    setTimeout(()=>{
      setIsAddBtnClicked(prevIsAddBtnClicked => {
        if (prevIsAddBtnClicked) {
          const newRow = {};
          gridApi.current.applyTransaction({ add: [newRow], addIndex:0 });
        }
        return !prevIsAddBtnClicked;
      });
    },300)
  }

  const onSelectionChanged = (event) => {
    let selectedRows
    selectedRows = event?.api?.getSelectedRows()
    setSelectedRows(selectedRows)
  }

  const handleDeleteSelectedRows = async () => {
    let api = Api
    try {
      for (const element of selectedRows) {
        const res = await api
          .axios()
          .delete(`${Settings.apiUrl}/attachmenttype/${element?.attachmenttype_id}`)
        setRowData((prevRows) =>
          prevRows.filter((row) => row.attachmenttype_id !== element.attachmenttype_id)
        )
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter(
            (row) => row.attachmenttype_id !== element.attachmenttype_id
          )
        )
        Helper.alert(res?.data?.message, 'success')
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
    }
  }

  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      resizable: true,
    },
    rowSelection: 'multiple',
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    checkboxSelection: true,
    rowMultiSelectWithClick: false,
    singleClickEdit:true,
    onRowValueChanged: onRowValueChangedHandler,
    onSelectionChanged: onSelectionChanged,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  if(columnDefs.length >= 1){
    columnDefs[0].headerCheckboxSelection = true;
    columnDefs[0].checkboxSelection = true;
  }

  const onCellValueChanged = useCallback(async (event) => {
    const { data, column, newValue } = event;
    if(!data?.attachmenttype_id){
      return
    }
    if(column?.colDef?.field==="mandatory" || column?.colDef?.field==="external_allowed"){
    try {
      data[column.colDef.field] = newValue;
      await onRowValueChangedHandler({ data });
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
    }
  }, [onRowValueChangedHandler]);

  useEffect(() => {
   getAllAttachments()
   getAllSources()
  }, [])

  const addEmptyRow = () => {
    gridApi.current.stopEditing();
    if(!isRowModified){
      return
    }else{
    const newRow = {};
    gridApi.current.applyTransaction({ add: [newRow], addIndex:0 });
    setIsAddBtnClicked(true)
    setTimeout(()=>{
      setIsAddBtnClicked(false)
    },4000)
    }
  }

  return (
    <div>
      <div className='d-flex mt-3 mb-3 gap-3'>
        <button onClick={addEmptyRow}>Add Empty Row</button>
        <button onClick={handleDeleteSelectedRows}>Delete</button>
      </div>
      <div style={{ width: '90vw', height: '400px' }}>
        <div
          className='ag-theme-alpine'
          style={{ height: '100%', width: '100%' }}
          role='grid'
          tabIndex='0'
          onClick={(e) => {
            e.stopPropagation()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
            }
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            editType={'fullRow'}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            checkboxSelection={true}
            gridOptions={gridOptions}
          />
        </div>
      </div>
    </div>
  )
}

export default CrudExample
