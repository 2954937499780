import React, { Component } from 'react';
import RenewOrderEdit from '../../components/Purchase Orders Internal/RenewOrder/RenewOrderEdit';

class RenewOrderEditPage extends Component {
    render() {
        return (
            <RenewOrderEdit/>
        );
    }
}

export default RenewOrderEditPage;