import React, { Component } from "react";
import MasterComonent from "../../components/Backend/MasterComonent";
import RFQVendorReplyGraph from "../../components/RequestForQuotation/RFQVendorReplyGraph";

export default class RFQVendorReplyGraphScreen extends Component {
  render() {
    return (
      <MasterComonent>
        <RFQVendorReplyGraph />
      </MasterComonent>
    );
  }
}