import React, { Component } from 'react';
import ReturnOrders from '../../components/Purchase Orders Internal/ReturnOrder/ReturnOrders';

class PoReturnOrderPage extends Component {
    render() {
        return (
            <ReturnOrders 
                backUrl = '/purchase-order/edit'
                editUrl = '/purchase-order/return-order/edit'
            />
        );
    }
}

export default PoReturnOrderPage;