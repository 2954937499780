import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import FormValidator from '../../../inc/FormValidator';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input';
import DropDownInput from '../../Forms/Dropdown';
import Checkbox from '../../Forms/Checkbox';
import RsWithRouter from '../../inc/RsWithRouter';
class RegisterForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            isSucess:false,
            isLoading:false,
            isFormSubmited:false,
            message:null,
            messageType:'',
            vendorTypes:[],
            aggree_tc:false,
            data:{
                first_name:"",
                last_name:"",
                middle_name:"",
                user_name:"",
                enable:true,
                SID:"",
                network_domain:"",
                network_alias:"",
                language_id:1,
                vendor_type:null,
                company_name:""
            }
        }
        this.validationConfigure();
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'first_name',displayName:Helper.getLabel(language,'first_name','First Name'),types:['Required'],max:80 },
            {name:'email',displayName:Helper.getLabel(language,'email','Email'),types:['Required','Email'],max:80 },
            //{name:'isactive',displayName:Helper.getLabel(language,'active','Active'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        this.loadVendorTypes();
    }
    loadVendorTypes(){
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl+'/enum/vendortype').then(res => {
            that.setState({
                vendorTypes:res.data.data
            })
        })
    }
    onChangeHandeler(e){
        let data = this.state.data;
        this.setState({
            data:{
                ...data,
                [e.target.name]:e.target.value
            }
        })
    }
    onTcChangeHandler(name,value){
        this.setState({
            aggree_tc: ( value == 'on' )
        })
    }
    //changes to get tenant id from url
    getSearchParam = (name) => {
        const params = new URLSearchParams(this.props.rs_router.location?.search)
        return params.get(name)
    }
    registerHandler(e){
        if(this.state.isLoading){
            return;
        }
        if(!this.state.aggree_tc){
            Helper.alert(Helper.getLabel(null,'please_accept_terms_condition',"Terms of use and Privacy Policy are required"),'error')
            return;
        }
        let validData = this.state.data;
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage(this);
            return;
        }
        let api = Api;
        api.setUserToken();
        let data = this.state.data;
        data.user_name = data.email;
        if(!Helper.getNullableValue(data.company_name) && data.vendor_type  == 2){
            data.company_name = data.first_name + ' ' +data.last_name ;
        }
        if(data.invitation_code===""){
            data.invitation_code=null
        }
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().post(Settings.apiUrl+'/register_vendor',data).then(res => {
            that.setState({
                message:res.data.message,
                messageType:res.data.status,
                isLoading:false
            })
            setTimeout(()=>{
                this.props.rs_router.navigate("/login")
            },1000)
        }).catch(error=>{
            let customMessage = error.response.data.message;
            if (customMessage.includes('already exists')) {
              customMessage = `The username with email id ${customMessage.split('=')[1]}`;
            }
            that.setState({
                message:customMessage,
                messageType:'error',
                isLoading:false
            })
        })
    }
    onKeyPreshHander(event){
        if(event.key =='Enter'){
            this.registerHandler(event);
        }
    }
    render() {
        let vendor_type = this.state.vendorTypes.map(item=>{
            return{label:item.name,value:item.id}
        })
        let data = this.state.data;

        let invitation_code = this.getSearchParam('invitation_code')
        
        return (
            <div className='login_form_group registration_page'>
                <div className='lf_title_subtitle'>
                    <h2>Join with us</h2>
                    <p>Enter your details below</p>
                </div>
                <div className='row mb-2'>
                    <div className='col-xs-12 col-md-6'><Input label="First Name" hasError={this.validator.hasError('first_name')}  name="first_name" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.first_name} onChange= { e => this.onChangeHandeler(e) } required={true}/></div>
                    <div className='col-xs-12 col-md-6'><Input label="Last Name" hasError={this.validator.hasError('last_name')}  name="last_name" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.last_name} onChange= { e => this.onChangeHandeler(e) } /></div>
                </div>
                <DropDownInput name="vendor_type" value={data.vendor_type} options={vendor_type} onChange={this.onChangeHandeler.bind(this)}  label={Helper.getLabel(null,"vendor_type","Vendor type")} />
                {data.vendor_type == 1 ? <Input label="Organization name" hasError={this.validator.hasError('company_name')}  name="company_name" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.company_name} onChange= { e => this.onChangeHandeler(e) } /> : ''}
                <Input label="Email" hasError={this.validator.hasError('email')}  inputType="email" name="email" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.email} onChange= { e => this.onChangeHandeler(e) } required={true}/>

                <Input label="Invitation Code" name="invitation_code" onKeyPress={ this.onKeyPreshHander.bind(this)} value={invitation_code ?? data.invitation_code} onChange= { e => this.onChangeHandeler(e) } />
                <p className='lr_accept_tc_checkbox'>
                    <Checkbox name="aggree_tc" onChange={this.onTcChangeHandler.bind(this)} />
                    By clicking "Submit" you agree to our <a href='/'>Terms of use</a> and <a href='/'>Privacy Policy</a>
                </p>
                { this.state.message ? <p className={this.state.messageType ==='success' ? 'alert alert-success' : 'alert alert-danger' }>{this.state.message}</p> : ''}
                <button className='btn btn_block btn_blue sb_btn' onClick={ e => this.registerHandler(e)}>Join now <img src="/images/icons/arrow-right-white.svg" alt="Join now" /> {this.state.isLoading ? <output className="spinner-border spinner-border-sm" aria-hidden="true"></output> : ''}</button>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        language:state.language,
        auth:state.auth
    }
}
export default connect(mapStateToProps) (RsWithRouter(RegisterForm));