import React, { Component } from 'react';
import Popup from '../../../Popup/Popup';
import Helper from '../../../../inc/Helper';
import $ from "jquery";
import Collapse from '../../../inc/Collapse';
import AgGrid from '../../../grid/ag/ag-grid';
import Settings from '../../../../inc/Settings';
import RsDataLoader from '../../../inc/RsDataLoader';
import Dropdown from '../../../Forms/Dropdown';
import Api from '../../../../inc/Api';
import AgGridMemo from '../../../grid/ag/AgGridMemo';
import AmendmentDatePopupButton from './AmendmentDatePopupButton';
import Button from '../../../inc/Button';
import PopupHeader from '../../../Popup/PopupHeader';
import BlueCallToAction from '../../../Widgets/BlueCallToAction/BlueCallToAction';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
/**
 * closePopup = function
 * amendment_id
 */
class AmendmentBillingRulePopup extends Component {
    constructor(props){
        super(props);
        this.forceRender = 1;
        this.grid = null;
        this.saveTimeout = null;
        this.state = {
            isLoading:false,
            lineObj:{},
            selectedNode:null,
            unitsLists:[],
            billingRuleTypes:[],
            billingRoles:[]
        }

    }
    componentDidMount(){
        this.loadBillingRoles();
        this.loadUnits();
        this.loadBillingRuleType();
        this.loadLineById(this.getLineId());
    }
    loadBillingRuleType(){
        let that = this;
        let api = Api;
        api.setUserToken();
        let url = Settings.apiUrl + '/enum/billingrule_type';
        api.axios().get(url).then(res=>{
            that.forceRender++;
            that.setState({
                billingRuleTypes:res.data.data
            })
        })
    }
    loadUnits = () => {
        let that = this;
        let api = Api
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
            that.forceRender++;
            that.setState({
                unitsLists:res.data.data
            })
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }
    loadBillingRoles(){
        if(!this.getLineId()){
            return;
        }
        this.setState({
            billingRoles:[],
            isLoading:true
        })
        let that = this;
        let api = Api;
        api.setUserToken();
        let url = Settings.apiPurchaseOrderUrl + '/get_billingrule/'+this.getLineId()+'/'+this.props.source_id;
        
        api.axios().get(url).then(res=>{
            that.forceRender++;
            that.setState({
                isLoading:false,
                billingRoles:res.data.data
            })
        }).catch(error => {
            that.forceRender++;
            that.setState({
                isLoading:false
            })
        })
    }
    loadLineById(lineId){
        let api = Api, that = this;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseOrderUrl+`/line/${lineId}/${this.props.line_source}`).then(res => {
            that.setState({
                lineObj:res.data.data
            })
        })
    }
    onClose(){
        if(this.props.onClose){
            this.props.onClose();
        }
        $('html').removeClass('rs_popup_open');
    }
    canEditReceivedQty(prarams){
        return true;
    }
    getLineId(){
        return this.props.po_line_id ? this.props.po_line_id : this.props.line_id;
    }
    
    onAmendantDateSaveClick(data){
        let selectedNode = this.state.selectedNode;
        if(!selectedNode){
            Helper.alert(Helper.getLabel(null,'please_select_row','Please select row'),'error')
            return;
        }
        selectedNode.setDataValue('new_start_date', data?.new_start_date);
        selectedNode.setDataValue('new_end_date',data?.new_end_date);
        this.grid.componentObj.setGridValueChanged(selectedNode.id,selectedNode.data)
    }
    getIntegratorForDuration(){
        if(!this.state.selectedNode){
            return null;
        }
        return this.state.selectedNode.data.billingrule_id;
    }
    getExistingDates(){
        if(!this.state.selectedNode){
            return null;
        }
        return {
            start_date:this.state.selectedNode.data.start_date,
            end_date:this.state.selectedNode.data.end_date,
        }
    }
    addButtonsAfterDeleteButon(){
        return(
            <>
                <AmendmentDatePopupButton getExistingDates={this.getExistingDates.bind(this)} amendmentline_type = {3} integrator_id={this.getIntegratorForDuration.bind(this)} amendment_id={this.props.amendment_id} onClickSave={this.onAmendantDateSaveClick.bind(this)}/>
            </>
        )
    }
    onSaveBtnHanlder(event){
        if(this.grid){
            clearTimeout(this.saveTimeout)
            let that = this;
            this.saveTimeout = setTimeout(function(){
                that.grid.componentObj.saveBtnClickHandler(event);
            },100)
        }
    }
    onSelectionChanged(event){
        let selectedNodes = event.api.getSelectedNodes();
        if(selectedNodes.length <=0){
            this.setState({
                selectedNode:null
            })
            
        }else{
            this.setState({
                selectedNode:selectedNodes[0]
            })
        }
    }
    getFilterdDataForApiBilling(gridRowData){
        return(
            {
                "amount": gridRowData.amount,
                "billingrule_id":gridRowData.billingrule_id,
                "billingrulecategory_id":gridRowData.billingrulecategory_id,
                "description":gridRowData.description,
                "end_date":gridRowData.end_date,
                "invoice_qty":gridRowData.invoice_qty,
                "invoice_qty_calculated":gridRowData.invoice_qty_calculated,
                "item_detail_required":gridRowData.item_detail_required,
                "line_number":gridRowData.line_number,
                "needby_date":gridRowData.needby_date,
                "over_delivery_allowed_percentage":gridRowData.over_delivery_allowed_percentage,
                "owner":gridRowData.owner,
                "po_line_description":gridRowData.po_line_description,
                "line_id":gridRowData.po_line_id,
                "po_line_number":gridRowData.po_line_number,
                "remaining_amount":gridRowData.remaining_amount,
                "remaining_percentage_or_qty":gridRowData.remaining_percentage_or_qty,
                "start_date":gridRowData.start_date,
                "status":gridRowData.status,
                "status_name":gridRowData.status_name,
                "type":gridRowData.type,
                "type_name":gridRowData.type_name,
                "unit":gridRowData.unit,
                "unit_price":gridRowData.unit_price,
            }
        )
    }
    onGridSaveHandler(data){
        let api = Api;
        let that = this;
        let amendment_id = this.props.amendment_id;
        if(!amendment_id){
            return;
        }
        api.setUserToken();
        let billingrules = data.map( item => {
            return {
                ...this.getFilterdDataForApiBilling(item),
                //amendment_id: amendment_id,
                //amendment_nature: 1, // Decrease ammendment nature,
                end_date: Helper.getDateForDb(item.end_date),
                //delivery_date: Helper.getDateForDb(item.delivery_date),
                needby_date: Helper.getDateForDb(item.needby_date),
                start_date: Helper.getDateForDb(item.start_date),
            }
        })
        let newData = {
            "amendment_id":amendment_id,
            "amendment_nature":1,
            "billingrules":billingrules
        }
        api.axios().post(Settings.apiPurchaseOrderUrl + '/amendmentbillingrule',newData).then(res =>{
            Helper.alert(res.data.message);
            var event = new CustomEvent("rs_amendment_created", { "api_response": res });
            document.dispatchEvent(event);
            $('.rs_popup_close').trigger('click');

        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })
    }
    render() {
        let billingRoles =this.state.billingRoles;
        let language = null;
        let units = this.state.unitsLists.map( itemObj => {
            return {
                value: itemObj.unit_id,
                label:`${itemObj.code} - ${itemObj.description}`
            }
        });
        let types = this.state.billingRuleTypes.map( item => {
            return {
                label:item.name,
                value:item.id
            }
        })
        let header =[
            {field:'line_number', headerName:Helper.getLabel(language,'line_no',"Line number"),minWidth: 150, editable: false ,inputType:'number'},
            {field:'type',editable: false , disableOptions:[], headerName:Helper.getLabel(language,'type',"Type"), options:types,defaultValue:'0',minWidth: 150, inputType: "dropdown",inputId:'billingRuleTypeInput'},
            {field:'owner', headerName:Helper.getLabel(language,'owner',"Owner"), editable:false, options:this.props.employeeList, inputType: "dropdown",minWidth: 200},
            {field:'description',editable: false , headerName:Helper.getLabel(language,'description',"Description"),minWidth: 200, },
            {field:'amount', inputType:'number', headerName:Helper.getLabel(language,'amount',"Amount"),minValue:'0', editable:true,minWidth: 150, },
            //{field:'remaining_percentage_or_qty', inputType:'number', headerName:Helper.getLabel(language,'remaining_percentage_or_qty',"Remaining percentage/quantity"), editable:false,minWidth: 300, defaultValue:"0" },
            //{field:'quantity', inputType:'number', editable:false, headerName:Helper.getLabel(language,'quantity',"Quantity"),minWidth: 200, defaultValue: this.getDefaltQuantityValue.bind(this) , maxValue: this.getQuanityEditorMaxValue.bind(this), onChange:this.onQuntityChangeHandler.bind(this) },
            {field:'unit', headerName:Helper.getLabel(language,'unit',"Unit"), editable:false, options: units, inputType: "dropdown",minWidth: 200 },
            {field:'unit_price', inputType:'number', headerName:Helper.getLabel(language,'unit_price',"Unit Price"), editable:false,minWidth: 200, defaultValue:this.props.defaultUnitPrice },
            //{field:'percentage', inputType:'number',onlyPositive:true, headerName:Helper.getLabel(language,'percentage',"Percentage"), editable:false,minWidth: 200,  defaultValue: 0 , maxValue: this.percentageMaxValue.bind(this) , onChange:this.onPercentageChangeHandler.bind(this) },
            {field:'percentage_or_quantity', inputType:'number',onlyPositive:true, headerName:Helper.getLabel(language,'percentage_or_quantity',"Percentage or quantity"), editable:false,minWidth: 250 },
            //{field:'decrease_quantity',autoFocus:true,minWidth:300,inputType:'number',maxValue:(value,param)=> { return param.data.percentage_or_quantity }, headerName:Helper.getLabel(language,'decrease_percentage_or_quantity',"Decrease Percentage or quantity")},
            {field:'needby_date', headerName:Helper.getLabel(language,'needbydate',"Need By Date"), editable:false, inputType: "datepicker",minWidth: 250, },
            {field:'start_date', headerName:Helper.getLabel(language,'start_date',"Start Date"), editable:false, inputType: "datepicker",minWidth: 250,},
            {field:'end_date', headerName:Helper.getLabel(language,'end_date',"End Date"), editable:false, inputType: "datepicker",minWidth: 250,},
            {field:'remaining_amount', inputType:'number', headerName:Helper.getLabel(language,'remaining_amount',"Remaining amount"), editable:false,minWidth: 200, defaultValue:"0" },
            //{field:'remaining_percentage', inputType:'number', headerName:Helper.getLabel(language,'remaining_percentage',"Remaining percentage"), editable:false,minWidth: 200, defaultValue:"0" },
            {field:'over_delivery_allowed_percentage', inputType:'number', headerName:Helper.getLabel(language,'over_delivery_allowed_percentage',"Over delivery allowed percentage"), editable:false,minWidth: 300, defaultValue:"0" },
            {field:'item_detail_required', inputType:'checkbox', headerName:Helper.getLabel(language,'item_detail_required',"Item detail required"), editable:false,minWidth: 250 },
            {field:'status',editable:false, headerName:Helper.getLabel(language,'status',"Status"),  options:[], inputType: "dropdown",minWidth: 150,defaultValue:'0' },
            {field:'new_start_date',editable:false,headerName:Helper.getLabel(language,'new_start_date',"New start date"),hide:true, minWidth:200},
            {field:'new_end_date',editable:false,headerName:Helper.getLabel(language,'new_end_date',"New end date"),hide:true, minWidth:200}
          ]
        let lineObj = this.state.lineObj;
        let blugBoxInfo = [
            {title:Helper.getLabel(null,'line_no','Line no'), subtitle: lineObj.line_number },
            {title:Helper.getLabel(null,'quantity','Quantity'), subtitle: Helper.formateCurrency(lineObj.ordered_qty) },
            {title:Helper.getLabel(null,'unit_price','Unit Price'), subtitle: Helper.formateCurrency(lineObj.unit_price) },
            {title:Helper.getLabel(null,'line_amount','Line Amount'), subtitle: Helper.formateCurrency(lineObj.line_amount) },
        ]
        return (
            <Popup autoOpen={true} onClose={this.onClose.bind(this)} width="1100px">
                <PopupHeader>
                    <div>
                        {Helper.getLabel(null,'amendment_line_site',"Amendment line site")}
                    </div>
                    <div>

                        <Button title={Helper.getLabel(null,"save","Save")} className="blue" icon="/images/icons/save-white.svg"  onClick={ this.onSaveBtnHanlder.bind(this)}/>
                    </div>
                </PopupHeader>
                <div className='mb-3 rfq-internal-blue'>
                    <BlueCallToAction items = {blugBoxInfo}/>
                </div>
                <Collapse open={true} title={Helper.getLabel(null,'amendment','Amendment')}>
                    <AgGridMemo
                        afterDeleteButtons = { this.addButtonsAfterDeleteButon.bind(this)}
                        addBtnHide={true} 
                        hideSave={true} 
                        hideDelete={true}  
                        forceRender = { this.forceRender } 
                        data={billingRoles} 
                        header={header}
                        settings = {
                            {
                                singleClickEdit:false
                            }
                        }
                        singleRowSelect = {true}
                        onGridReady={(grid) => { this.grid = grid}}
                        onSave={this.onGridSaveHandler.bind(this)}
                        onSelectionChanged = { this.onSelectionChanged.bind(this)} 
                    />
                </Collapse>
            </Popup>
        );
    }
}

export default RsDataLoader(AmendmentBillingRulePopup,{entity:true,sites:true,warehouse:true,lineSiteStatus:true});