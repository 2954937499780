import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'; 
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import MasterComonent from '../Backend/MasterComonent'; 
import NavigationHeder from '../Navigations/NavigationHeder';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import AgGridNew from '../grid/ag/ag-grid-new';
import SimpleDropdown from '../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../grid/ag/commonFunctions/GridCommonFunctions';
import AttachmentTypesModal from './AttachmentTypesModal';
import ButtonNew from '../inc/ButtonNew';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Alert from '../inc/Alert';

let gridApi = null;

const AttachmentTypes = ({ language, security }) => {
    const [attachmentTypeId, setAttachmentTypeId] = useState(null);

    const [showCreateModal, setShowCreateModal] = useState(false);

    const [countries,setCountries]=useState([])

    const [vendorTypes,setVendorTypes]=useState([])

    const [sources, setSources] = useState([]);

    let sourceApiCalled = false; 

    useEffect(()=>{
        loadSource()
        getAllCountries()
        getAllVendorType()
    },[])
 
    const loadSource = () => {
        if(sourceApiCalled) return;
        sourceApiCalled = true;
        
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/source')
            .then(res => {
                if (res.data.status === 'success') {
                    setSources(res.data.data?.map(item => {
                        return {
                            label: item.source_name,
                            value: item.source_id
                        }
                    }));
                }
            });
    };

    const getAllCountries = () => {
        const api = Api
        api.setUserToken()
        api
          .axios()
          .get(Settings.apiUrl + '/country')
          .then((res) => {
            let UPDATED_STATE = []
            res.data.Message.forEach((element) => {
              UPDATED_STATE.push({
                value: element.country_id,
                label: element.country_short_name
              })
            })
            setCountries(UPDATED_STATE)
          })
          .catch((error) => console.log('Error in getting invites: ', error))
      }

      const getAllVendorType = () => {
        const api = Api
        api.setUserToken()
        api
          .axios()
          .get(Settings.apiUrl + '/enum/vendortype')
          .then((res) => {
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
              UPDATED_STATE.push({
                value: element.id,
                label: element.name
              })
            })
            setVendorTypes(UPDATED_STATE)
          })
          .catch((error) => console.log('Error in getting invites: ', error))
    }

    const onDownloadClick = (id) => {
        if(!id){
            return;
        }
        let api = Api
        api.setUserToken()
        api
          .axios()
          .get(Settings.loginUrl + `/get_attachmenttype_doc/${id}`,{ responseType: 'blob' })
          .then(async function (res) {
            if (res.status === 200) {
              const response = res
              const contentDisposition = response.headers['content-disposition']
              const fileName =
                contentDisposition?.split('filename=')[1] || 'dummy.jpg'
              const fileBlob = new Blob([response.data], {
                type: response.headers['content-type'],
              })
              const downloadLink = document.createElement('a')
              downloadLink.href = URL.createObjectURL(fileBlob)
              downloadLink.download = fileName
    
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          })
          .catch((res) => {
            getPopupMessageBasedOnStatus(res) 
          })
    }
 
    const onNameCellClicked = (event) => { 
        if(event.colDef.field === 'name') { 
            setAttachmentTypeId(event?.data?.attachmenttype_id);
            setShowCreateModal(true); 
        }
        if(event.colDef.field === 'download_template' && event?.data?.is_template_based_attachment){
            onDownloadClick(event?.data?.attachmenttype_id)
        }
    };
 
    const onClickNewButton = () => {
        setShowCreateModal(true);
        setAttachmentTypeId(null);
    };
  
    const onDeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        
        let delete_attachment_types_id = element?.data?.attachmenttype_id;
    
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/attachmenttype/${delete_attachment_types_id}`)
            const rowNode = gridApi?.getRowNode(delete_attachment_types_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200) 
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    /* Grid Column Headers */
    const reloadGrid = () => {
        gridApi?.refreshServerSide({ purge: true });
        gridApi?.deselectAll();
    };

    const downloadTemplateRenderer = (params) => {
        return <div className='d-flex justify-content-center align-items-center h-100'>{params?.data?.is_template_based_attachment?<img src="/images/icons/print.svg" alt="download-icon" height="20px" />:"-"}</div>
    }

    const gridColHeaders = [
        { field: 'name', cellRenderer: (params) => gridLinkValueFormat(params), editable: false, filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, "name", 'Name') },
        { 
            field: 'source_id', 
            headerName: Helper.getLabel(language, "source", 'Source'),
            editable: false,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth: 180, 
            cellEditorParams: { values: sources },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, sources),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label,
            }
        }, 
        {
            field: 'mandatory',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: false, 
            minWidth: 160,
            valueGetter: (params) => {
                if(!params?.data?.mandatory || !params?.data){
                    return false
                }
                else {
                    return params.data?.mandatory
                }
            },
            headerName: Helper.getLabel(language, "mandatory", 'Mandatory')
        },
        { 
            field: 'external_allowed',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: false,
            minWidth: 180,
            valueGetter: (params) => {
                if(!params?.data?.external_allowed || !params?.data){
                    return false
                }
                else {
                    return params.data?.external_allowed
                }
            },
            headerName: Helper.getLabel(language, "allowed_to_external", 'Allowed to external')
        },
        { 
            field: 'is_technical',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: false,
            minWidth: 160,
            valueGetter: (params) => {
                if(!params?.data?.is_technical || !params?.data){
                    return false
                }
                else {
                    return params.data?.is_technical
                }
            },
            headerName: Helper.getLabel(language, "is_technical", 'Is technical')
        },
        { 
            field: 'is_commercial',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: false,
            minWidth: 160,
            valueGetter: (params) => {
                if(!params?.data?.is_commercial || !params?.data){
                    return false
                }
                else {
                    return params.data?.is_commercial
                }
            },
            headerName: Helper.getLabel(language, "is_commercial", 'Is commercial')
        },
        { 
            field: 'vendortype',
            headerName: Helper.getLabel(language, "vendor_type", 'Vendor type'),
            cellEditor: SimpleDropdown, 
            cellEditorParams:{values:vendorTypes},
            valueFormatter:(params)=>{return getLabelFromDropdown(params) || "-"},
            editable: false,
            minWidth: 160,
        },
        { 
            field: 'country_id',
            headerName: Helper.getLabel(language, "country", 'Country'),
            cellEditor: SimpleDropdown, 
            cellEditorParams:{values:countries},
            valueFormatter:(params)=>{return getLabelFromDropdown(params) || "-"},
            editable: false,
            minWidth: 160,
        },
        { 
            field: 'is_template_based_attachment',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: false,
            minWidth: 260,
            headerName: Helper.getLabel(language, "is_template_based_attachment", 'Is template based attachment')
        },
        { 
            field: 'download_template',
            filter: false,
            cellRenderer: downloadTemplateRenderer, 
            editable: false,
            minWidth: 180,
            headerName: Helper.getLabel(language, "download_template", 'Download template')
        }
    ];

    if(!security.canView(Gui_id_list.setupForms.attachment_types.attachment_types_main)){ 
        return <Fragment>
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        </Fragment>
    };

    return (
        <MasterComonent>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(language, 'attachments_types', 'Attachment Types')} backUrl="/setup">
                    <ButtonNew 
                        isDisable={!security.canCreate(Gui_id_list.setupForms.attachment_types.attachment_types_main_create_button)}
                        onClick={onClickNewButton} isActive= {true} title={Helper.getLabel(language,'000002',"New")} 
                    />
                </NavigationHeder>

                <AgGridNew
                    apiUrl={Settings.apiUrl + '/attachmenttype'}
                    pagination={true}
                    columnDefs={gridColHeaders}
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.attachment_types.attachment_types_main_delete_button)}
                    onCellClicked={onNameCellClicked} 
                    handleDeleteSelectedRows={onDeleteHandler}
                    height={500}
                    onGridReady={(params) => gridApi = params?.api} 
                    hideAddBtn={true}
                    newAddItems={{is_template_based_attachment:false}}
                    uniqueField={`attachmenttype_id`}
                    gridId={'attachment_types'}
                />
            </div>
            { showCreateModal && <AttachmentTypesModal reloadParentGrid={reloadGrid} attachment_type_id={attachmentTypeId} setShowCreateModal={setShowCreateModal} countries={countries} vendorTypes={vendorTypes}/> }
        </MasterComonent>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    language: state.language,
    sources: state.allSources,
});

const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.attachment_types.attachment_types_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(AttachmentTypes, SecurityOptions))));