import Helper from '../../../../../inc/Helper'
class ItemDetails {
  init(params) {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = 'Loading..';
    this.items = this.params.data.items;
    this.loadDistributionDetails(params);
  }

  loadDistributionDetails(params) {
    const response = params.getDetailRowData(params)
    this.eGui.innerHTML = this.getInnerHtml(response);
  }

  getInnerHtml(detailsArray) {
    let detailsBody = '';
    detailsArray?.forEach((item) => {
      detailsBody += `<tr class="rs_item_row"><td>${item.item_name || "-"}</td><td>${item.received_qty || "-"}</td><td>${item.unit_name || "-"}</td><td>${item.unit_price || "-"}</td><td>${item.discount_amount|| "-"}</td><td>${item.discount_percentage || "-"}</td><td>${item.line_amount || "-"}</td></tr>`;
    });
    return (
      `<div style="overflow-x:auto;">
        <table class="table rs_distribution_details_table rfq_custom_table">
          <thead>
            <tr>
              <th scope="col">Item Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Unit</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Discount</th>
              <th scope="col">Discount Percentage</th>
              <th scope="col">Line Amount</th>
            </tr>
          </thead>
          <tbody>
            ${detailsBody}
          </tbody>
        </table>
      </div>`
    );
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

export default ItemDetails;
