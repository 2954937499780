import React from "react";
import "./DashboardReq.css";
import VendorIcon from "../../../assets/vendor req.svg";
import { connect } from "react-redux";
import { checkForRolesPermission, checkIfArrayIsEmpty } from "../../../inc/Validation";
import ContractInformationCard from "../../ContractReports/ContractFirstRow/ContractInformationCard";
import SimpleLoading from "../../Loading/SimpleLoading";

const RequestCard = ({ item, lengthOfObjects = 0, groupingColor=false }) => (
  <div className="source-request-col border rounded p-2" style={{maxWidth: lengthOfObjects === 1 ? 'calc(25% - 15px)' : '100%' }} key={item.id}>
    <div className={`row request-row align-items-center ps-2 pt-3 ${item.perct_request && item.request_type ? '' : 'pb-2'}`}>
      <h3 className="mb-0">{item.module}</h3>
      <div className="col-6 d-flex justify-content-start mt-0">
        <span className={`request-row-badge ${groupingColor ? 'grouping_clr' : ''}`}>
          {item.total_requests === "" || item.total_requests === undefined ? (
            <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output>
          ) : (
            <>{item.total_requests}</>
          )}
        </span>
      </div>
      <div className="col-6 d-flex justify-content-end mt-0">
        <span className="request-row-icon pe-2">
          <a>
            <img src={VendorIcon} className="img-fluid" alt="icon" />
          </a>
        </span>
      </div>
    </div> 
  </div>
);

const DashboardReq = ({ user, sources, scrollDitection, loadingSourceView, buyerUserRequests, financeUserRequests, commonUserRequests, sourcesViews }) => {
  const getRequestsForUser = () => {
    if (user?.is_buyer) {
      return {
        buyerGroupView: buyerUserRequests,
        commonView: commonUserRequests,
        sourceView: sourcesViews
      };
    }
    if (checkForRolesPermission(user?.roles, 'Accounts Payable', null) && !user?.is_buyer){
      return { 
        commonView: financeUserRequests,
        sourceView: sourcesViews
      }
    }
    if (!checkForRolesPermission(user?.roles, 'Accounts Payable', null) && !user?.is_buyer){
      return {
        commonView: commonUserRequests,
        sourceView: sourcesViews
      }
    } 
  };

  const userRequests = getRequestsForUser();

  const getClassNameByLabel = (moduleName) => {
    if (typeof moduleName !== 'string') {
      return '-';
    };

    if(moduleName){ 
      switch (moduleName?.toLowerCase()) {
        case 'vendor':
          return 'crd-vendor-bg'
        case 'purchase requisition':
          return 'crd-light-blue-bg'
        case 'purchase order':
          return 'crd-light-blue-bg'
        case 'contract':
          return 'contracts-card-bg'
        case 'direct order':
          return 'crd-light-blue-bg'
        case 'awarding':
          return 'crd-awarding-bg'
        case 'request for quotation':
          return 'crd-light-blue-bg'
        case 'rfq technical evaluation':
          return 'crd-light-blue-bg'
        case 'rfq financial evaluation':
          return 'crd-light-blue-bg'
        case 'rfq commercial evaluation':
          return 'crd-light-blue-bg'
        case 'rfq awarding':
          return 'crd-awarding-bg'
        case 'request for information':
          return 'crd-light-blue-bg'
        case 'invoice':
          return 'crd-invoice-bg'
        case 'work confirmation':
          return 'crd-work-confirmation-bg'
        default:
          return 'crd-light-blue-bg' 
      }   
    };
  };

  return (
    <div className={`dashboard_container_wrapper ${scrollDitection === "down" ? "" : "dash_nav_position_style_1"}`}>
      <div className="dashboard_container_wrapper">
        <div className="dashboard-request-section">
          {userRequests?.buyerGroupView?.map(item => <RequestCard key={item?.id} item={item} groupingColor={true} />)}
          {userRequests?.commonView?.map(item => <RequestCard lengthOfObjects={userRequests?.commonView?.length} key={item?.id} item={item} />)} 
        </div>
      </div>

      <div className="contract-dashboard-screen mt-4"> 
        <div className='employee-dashboard-screen rs_tree_view_section'> 
          <div className='contract-info-cards d-flex gap-3'>
            { loadingSourceView ? <SimpleLoading /> : userRequests?.sourceView?.map(item => {
                let sourceLabel = !checkIfArrayIsEmpty(sources) ? (sources?.find((id) => id.value === item?.title) !== undefined ? sources?.find((id) => id.value === item?.title)?.label : '') : item?.title; 
                let getClassName = getClassNameByLabel(sourceLabel);
                return (
                  <ContractInformationCard mainCardClass={getClassName} title={ item?.title === 54 ? sourceLabel?.split(',')[2].trim() : (sourceLabel || '')} information={item?.numberOfRequestsPercentage+'%'} value={item?.numberOfRequests} />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appOptions: state.options,
  user: state.auth.user,
  language: state.language,
});

export default connect(mapStateToProps)(DashboardReq); 