import React, { useEffect, useState } from 'react'
import Popup from '../../Popup/Popup'
import Button from '../../inc/Button'
import Helper from '../../../inc/Helper'
import AgGridMemo from '../../grid/ag/AgGridMemo'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import { getBGStatusColor, getStatusColor } from './RfqFunctions'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import axios from 'axios'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

let forceRender = null
let cancelTokenSource = axios.CancelToken.source();

const BAFOModal = ({
  handleBafoModalClose,
  language,
  rfq_id,
  vendors,
  commonApiCalls,
  type,
  setRefreshAfterPublish,
  onReturnBid
}) => {
  const [vendorReplyHeaderStatus, setVendorReplyHeaderStatus] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [newExpiryDate,setNewExpiryDate]=useState('')
  const [vendorsData,setVendorsData]=useState(vendors)
  const [saveBtn,setSaveBtn]=useState(false)

  const initData = async () => {
    const getVendorReplyHeaderStatus = async () => {
      try {
        const statuses = await commonApiCalls.getAllRfqVendorReplyStatus()
        setVendorReplyHeaderStatus(statuses)
        const formattedData = formatVendorsData()
        setVendorsData(formattedData)
        forceRender++
      } catch (err) {
        getPopupMessageBasedOnStatus(err)
      }
    }
    getVendorReplyHeaderStatus()
  }

  useEffect(() => {
    initData()
  }, [])

  const getStatusName = (status) => {
    const statusText = vendorReplyHeaderStatus.filter(
      (el) => el.id === status
    )[0]

    if (!statusText || statusText.length === 0) return 'Draft'

    let statusName = ''
    const splitted = statusText?.name.split('_')

    splitted.forEach((el) => {
      statusName += el[0].toUpperCase() + el.slice(1) + ' '
    })

    return statusName
  }

  const Columns_Headings = [
    {
      field: 'vendor_code',
      headerName: Helper.getLabel(language, 'code', 'Code'),
      editable: false,
      minWidth: 180,
      inputType: 'dropdown',
    },
    {
      field: 'vendor_name',
      headerName: Helper.getLabel(language, 'name', 'Name'),
      minWidth: 150,
      editable: false,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(language, 'site', 'Site'),
      editable: false,
      minWidth: 150,
    },
    {
      field: 'expiry_datetime',
      headerName: Helper.getLabel(language, 'expiration_date', 'Expiry date'),
      editable: true,
      minWidth: 225,
      inputType: 'datepicker',
      valueFormatter: (params) => {
        return params.value === null || params.value === undefined
          ? null
          : DatepickerFunctions.convertDateFromDataBase(params?.value)
      },
    },
    {
      field: 'expiry_time',
      headerName: Helper.getLabel(language, 'expiry_time', 'Expiry time'),
      editable: true,
      minWidth: 225,
    },
    {
      field: 'vendor_replystatus',
      headerName: Helper.getLabel(language, 'reply_status', 'Reply status'),
      cellRenderer: CustomHtmlCell,
      valueFormatter: (params) => {
        return `<div style="display: flex;
            justify-content: center;
            height: 40px;
            align-items: center;
          ">
          <div style="color: white;
            color: ${getStatusColor(params.value)};
            background-color: ${getBGStatusColor(params.value)}; 
            border-radius: 80px;
            width: fit-content;
            padding: 8px 15px;
            height: auto;
            display: inline-block;
            text-align: center;
            margin: 0 auto;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 18px
          ">
            ${getStatusName(params.value)}
          </div>
      </div>`
      },
      editable: false,
      minWidth: 180,
    },
    {
      field: 'contact_number',
      headerName: Helper.getLabel(
        language,
        'primary_contact_person',
        'Primary contact person'
      ),
      minWidth: 225,
      editable: false,
    },
    {
      field: 'email',
      headerName: Helper.getLabel(language, 'email', 'Email'),
      minWidth: 200,
      editable: false,
    },
    {
      field: 'rank',
      headerName: Helper.getLabel(language, 'rank', 'Rank'),
      minWidth: 120,
      editable: false,
    },
  ]

  const handleSelectionChanged = (event) => {
    let rows = event?.api?.getSelectedRows()
    setSelectedRows(rows)
  }

  const handleNewExpiryDate = (date) => {
    setNewExpiryDate(date)
    const datetime = DatepickerFunctions.convertDateTimeFromDataBase(date)
    const formattedTime = datetime?.split(' ');
    const updatedRows = vendorsData?.map(row => ({
      ...row,
      expiry_datetime: DatepickerFunctions.convertDateFromDataBase(date),
      expiry_time:`${formattedTime[1]} ${formattedTime[2]}`
    }));
    setVendorsData(updatedRows)
    forceRender++
  }

  const formatVendorsData = () => {
    let vendorGridData = vendorsData?.filter(
      (vendor) =>
        vendor?.vendor_replystatus === 2 || vendor?.vendor_replystatus === 3
   )
    return vendorGridData?.map(vendor => {
      const date = DatepickerFunctions.convertDateFromDataBase(vendor.expiry_datetime)
      const datetime = DatepickerFunctions.convertDateTimeFromDataBase(vendor.expiry_datetime);
      const formattedTime = datetime?.split(' ');

      return {
        ...vendor,
        expiry_datetime:date,
        expiry_time:`${formattedTime[1]} ${formattedTime[2]}`,
      };
    });
  };

  const handleInitiateBAFOSendApiCall = async () => {
    if(selectedRows?.length===0){
      Helper.alert("Please select atleast one row.","error")
      return;
    }
    const isTimeFormatValid = selectedRows.every(row => {
      const [hoursStr, minutesStr] = row.expiry_time.split(':');
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      return (
        (hours >= 1 && hours <= 12 && minutes >= 0 && minutes <= 59) 
        // !(hours === 12 && minutes !== 0) 
      );
    });
  
    if (!isTimeFormatValid) {
      Helper.alert("Expiry time should be in AM/PM format and not exceed 12:59 (e.g., '1:30 PM')",'error');
      return;
    }
   
    const currentDate = new Date()
    const isExpiryValid = selectedRows.every(row => {
      let currentConvertedDate = DatepickerFunctions.convertDateForDataBase(row.expiry_datetime) 
      let expiryDateTime = DatepickerFunctions.convertPayloadDateTimeForDataBase(`${currentConvertedDate} ${row.expiry_time}`)
      const expiryDate = new Date(expiryDateTime);
      return expiryDate > currentDate;
    });

    if(newExpiryDate === "" || newExpiryDate === null){
      Helper.alert("Please select new expiry date.","error")
      return;
    }

    if (!isExpiryValid) {
        Helper.alert("Expiry datetime should be greater than the current datetime.", 'error');
        return;
      }
      const payload = {
        action: type==="bafo"?6:7,
        vendorreplies: selectedRows.map(row =>{
          let currentDate = DatepickerFunctions.convertDateForDataBase(row.expiry_datetime) 
          return ({
          vendorreply_id: row.vendorreply_id,
          expiry_datetime: new Date(DatepickerFunctions.convertPayloadDateTimeForDataBase(`${currentDate} ${row.expiry_time}`)),
        })})
      };

    try{
       const resBafo = await commonApiCalls?.initiateBAFO(payload,{ cancelToken: cancelTokenSource.token })
       Helper.alert(resBafo?.message)
       handleBafoModalClose()
       setRefreshAfterPublish(true)
       setTimeout(() => {
         setRefreshAfterPublish(false);
       }, 100);
       setSelectedRows([])
       type==="vendor" && onReturnBid()
    }
    catch(err){
      getPopupMessageBasedOnStatus(err)
    }
  }

  const handleInitiateBAFOSend = () => {
    Helper.createDebouncedAPIFunction([async ()=>{return await handleInitiateBAFOSendApiCall()}], setSaveBtn, cancelTokenSource, false, 300000)();
  }

  
  
  return (
    <>
    <Popup autoOpen={true} width='1000px' onClose={handleBafoModalClose}>
      <div className='w-100 d-flex justify-content-end'>
        <Button
          onClick={handleInitiateBAFOSend}
          title={Helper.getLabel(language, 'send', 'Send')}
          className='rfq_save_btn'
        />
      </div>
      <h5 className='create_rfq_modal__title mb-4'>{type==="vendor"?'Return bid':'Vendors'}</h5>
      <div className='col-md-4'>
        <NewInputDatePicker
          label={`${Helper.getLabel(language, 'new_expiry_date_time', 'New expiry date and time')}`}
          id='expiry_datetime'
          name='expiry_datetime'
          onChange={handleNewExpiryDate}
          value={newExpiryDate}
          timePicker={true}
          minDate={new Date()}
          disable={false}
          required={true}
          empty={true}
        />
      </div>
      <div className="hide-ag-grid-search">
        <AgGridMemo
          id='rfq-vendor-bafo'
          data={vendorsData || []}
          header={Columns_Headings}
          allBtnHide={true}
          onSelectionChanged={handleSelectionChanged}
          forceRender={forceRender}
        />
      </div>
    </Popup>
    {saveBtn ? <OverlayLoader isLogoCenter={true} />:<></>}
    </>
  )
}

export default BAFOModal
