import React from 'react'
import TaskChart from './TaskChart'
import EngagementProcessChart from './EngagementProcessChart'
import TaskCompletionChart from './TaskCompletionChart'

const EmployeeTasksInfo = () => {
  return (
    <div className="mt-4">
      <div className="row align-items-stretch task-chart-row">
        <div className="col-sm-6 col-lg-4 h-100">
          <TaskChart />
        </div>
        <div className="col-sm-6 col-lg-4 h-100">
          <EngagementProcessChart />
        </div>
        <div className="col-sm-6 col-lg-4 h-100">
          <TaskCompletionChart />
        </div>
      </div>
    </div>
  )
}

export default EmployeeTasksInfo
