import React from 'react';
import ContractByItemGroup from './ContractByItemGroup';
import ContractByMonth from './ContractByMonth';

const ContractLastRow = () => {
  return (
    <div className='row align-items-stretch task-chart-row mt-4 mb-4'>
        <div className='col-sm-6 col-lg-6 h-100'>
            <ContractByItemGroup />
        </div>
        <div className='col-sm-6 col-lg-6 h-100'>
            <ContractByMonth />
        </div>
    </div>
  );
}

export default ContractLastRow;
