import Helper from "../../../../inc/Helper";
import MasterCellEditor from "../cellEditor/MasterCellEditor";

class NumberCellRenderer extends MasterCellEditor{
    init(params) {
        this.eGui = document.createElement('div');
        this.addClass(this.eGui,'ag_grid_number_cell_render')
        this.eGui.innerHTML = this.getValueToDisplay(params);
      }
    
      getGui() {
        return this.eGui;
      }
    
      refresh() {
        return false;
      }
      getValueToDisplay(params) {
          let output = params.valueFormatted ? params.valueFormatted : params.value;
          output = Helper.addThousandSeparator(Helper.getOnlyNumber(output))
          return output;
      }
}

export default NumberCellRenderer;