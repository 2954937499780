//import { Grid} from 'ag-grid-community';
import { Grid} from 'ag-grid-enterprise';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../../inc/ActionsTypes';
import Api from '../../../inc/Api';
// import Helper from '../../../inc/Helper';
import AddIcon from '../../../assets/add.svg';
import DeleteIcon from '../../../assets/delete.svg';
import Settings from '../../../inc/Settings';
import DatePicker from './cellEditor/DatePicker';
import SimpleDropdownSelector from './cellEditor/SimpleDropdownSelector';
import './style.css';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/dialog';
import Helper from '../../../inc/Helper';
import DateFilters from './filters/DateFilter';
import Pagination from './Pagination';
import GridCheckbox from './cellEditor/GridCheckbox';
import CheckboxRender from './cellRender/CheckboxRender';
import RsWithRouter from '../../inc/RsWithRouter';
import DatePickerViewer from './cellRender/DatePickerViewer';
import GridValidator from './GridValidator';
import NumberCellEditor from './cellEditor/NumberCellEditor';
import SimpleDropdown from './cellEditor/SimpleDropdown';
import SimpleLoading from '../../Loading/SimpleLoading';
import NumberCellRenderer from './cellRender/NumberCellRenderer';
import NewDatePicker from './cellEditor/NewDatePicker';
import Dropdown from '../../Forms/Dropdown';
import ColorPickerRender from './cellRender/ColorPickerRender';
import ColorPicker from './cellEditor/ColorPicker';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AdvanceSearchFilterDropdown from './filters/AdvanceSearchFilterDropdown';
/*
 props {
    hideHeaderRightContents : boolean // Default : false,
    hideFooter : boolean // Default : false,
    addBtnHide : boolean // Default : false,
    searchWithoutFieldKey : boolean // Default : false,
    hideAdvanceFilter: boolean // Default : false,
    addBtnDissable : boolean // Default : false,
    dissableRowSelect : boolean // Default : false, 
    singleRowSelect : boolean // Default : false, 
    hideSave : boolean // Default : false,
    hideDelete : boolean // Default : false,
    readOnlyGrid:false
    viewOnly : boolean // Default : false,
    dissableSelect : boolean // Default : false,
    dissableCheckBox : boolean // Default : false,
    allBtnHide: boolean // false
    showFilterBox : boolean // Default : false,
    noValidationOnRowEditingStop:false,
    onDelete: function (object) 
    onSave: function (object)
    onAddClick: function()
    afterDeleteButtons: function()
    beforeDeleteButton: function()
    beforeAddButton: function()
    beforeSaveButton: function()
    deleteConfig: { apiUrl: , idName, that: , reloadFuntion:fn}
    data:[],
    header:[{
        isAllowZero:false/// true|false
        validationRule: as like Validator
        defaultValue:///
        inputType: dropdown | datepicker | checkbox | number
        options: options dropdown
        disableOptions:[]
        field:"",
        headerName: ''
    }]
    onAddClickCheck: function() - to run some code before adding new row on add click, return true to continue to add row
    afterDownloadButton: fn ,actionHook
    beforeDownloadButton: fn ,actionHook
    addNewDefaultValue: {}
    onBeforeRowAdd: fn
    onAfterRowAdd: fn
 }
*/
class AgGrid extends Component {
    constructor(props){
        super(props);
        this.hasError = false;
        this.reloadUserSettingsTimeOut=null;
        this.deletedItemsList = [];
        this.deletedRowsHistory = [];
        this.isDataChanged = false;
        this.isFirstRenderDone = false;
        this.state = {
            oldGridData:null,
            needRefresh:false,
        }
        this.searchWithoutFieldKey = this.props.searchWithoutFieldKey ? true : false
        this.searchOnDocument = this.props.searchOnDocument !== "" ? this.props.searchOnDocument : null;
        this.apiParamsURL = this.props.apiParamsForSearch ? this.props.apiParamsForSearch : null
        this.validationMessages = {};
        this.gridInitOptions = {}
        this.updatedRowData = {};
        this.agGrid = null;
        this.ppp = this.props.ppp ? this.props.ppp : 10;
        this.gridOptions = null;
        this.columnDefs = [];
        this.gridId = this.props.id ? this.props.id : 'ag_grid_'+Date.now();
        this.apiSourceKey = 'country';// country for testing
        this.defaultColDef = {}
    }
    
    componentDidMount(){
        //this.loadSource();
        this.updatedRowData  = {};
        // { field: 'date',headerName:'Date',inputType: 'select|datepicker'}
        this.columnDefs = this.props.header ? this.props.header : [];
        /*
        this.columnDefs.actions = { colId:"actions", field: 'actions',headerName:'Actions'};
        */
        let gridAdvSettings = this.props.settings ? this.props.settings : {};
        this.defaultColDef = {
            editable: this.props.readOnlyGrid ? false : (this.props.viewOnly  ? false : true) ,
            sortable: true,
            filter: 'agTextColumnFilter',
            resizable: true,
            flex: 1,
            cellClass: (prarams) => {  
                //return prarams.colDef.field+'_'+prarams.rowIndex 
                return 'rs_cell_' + prarams.node.id + '_' + prarams.column.colId;
            },
            minWidth: 100,
        }
        const gridOptions = {
            defaultColDef: this.defaultColDef,
            singleClickEdit: true, 
            enableCellTextSelection:true,
            //enableGroupEdit: true,
            stopEditingWhenCellsLoseFocus:false,
            rowSelection: this.props.singleRowSelect ? 'single' : 'multiple',
            overlayLoadingTemplate: // default template for showing the loader overlay
            '<div class="ag-overlay-loading-center">' +
              '<div class="ag-overlay-loading-template" aria-label="loading">' +
                '<div class="spinner"></div>' +
              '</div>' +
            '</div>',
            rowMultiSelectWithClick : false,
            pagination: this.props.hidePagination?false:true,
            paginationPageSize: this.props.pagination?10:this.ppp,
            animateRows: false,
            columnDefs: this.getColumnDefs(),
            rowData: null,
            editType: 'fullRow',
            suppressPaginationPanel:true, // Hide Default pagination
            getRowId: (prarams) => {
                if(prarams.data.gridRowId){
                    return prarams.data.gridRowId;
                }else if(prarams.data.setRowDataGridRowId){
                    return prarams.data.setRowDataGridRowId;
                }
                return 0;
            },
            ...gridAdvSettings,
            onSelectionChanged: this.selectionChangedHandler.bind(this),
            onRowValueChanged: this.onRowValueChanged.bind(this),
            onRowClicked: this.onRowClicked.bind(this),
            onRowGroupOpened:this.onRowGroupOpened.bind(this),
            onRowSelected: this.onRowSelected.bind(this),
            onCellClicked: this.onCellClicked.bind(this),
            onColumnEverythingChanged: this.onColumnEverythingChanged.bind(this),
            onRowEditingStopped : this.onRowEditingStopped.bind(this),
            onRowEditingStarted : this.onRowEditingStarted.bind(this),
            onCellMouseOut: this.onCellMouseOut.bind(this),
            onCellValueChanged: this.onCellValueChanged.bind(this),
            onCellEditingStopped: this.onCellEditingStopped.bind(this),
            onVirtualColumnsChanged: this.onVirtualColumnsChanged.bind(this),
            onFirstDataRendered : this.onFirstDataRendered.bind(this),
            onGridReady : this.onGridReady.bind(this),
            onCellFocused : this.onCellFocused.bind(this),
            onColumnMoved : this.onColumnMoved.bind(this),
            onGridColumnsChanged : this.onGridColumnsChanged.bind(this)
        };
        
        if(this.props.dissableRowSelect){
            gridOptions.suppressRowClickSelection = true;
        }
        if(this.props.dissableSelect){
            //gridOptions.rowSelection = "single";
            gridOptions.suppressRowClickSelection = true;
        }
        if(!this.agGrid){
            this.gridInitOptions = gridOptions;
            this.intGrid(this.gridInitOptions ,this.getModifiedSetRowData())
        }

        $(`#pagination-dropdown-${this.props.id}`).on('select2:open', function () {
            $('.select2-search').hide(); // Hide the search input when the dropdown opens
        });
        
    }
    hasErrors(){
        return this.checkValidationError();
    }
    checkValidationError(){
        let headerData = this.getColumnDefs();
        //event.api.this.gridOptions.api.setColumnDefs(this.getColumnDefs())
        //this.gridOptions.api.setColumnDefs(this.getColumnDefs())
        $( '#'+ this.gridId + ' .ag-cell-value' ).removeClass('data_invalid');
        this.hasError = false;
        let errorMessages = {};
        this.gridOptions.api.forEachNode( gridNode => {
            let rowIndex = gridNode.rowIndex;
            let data = gridNode.data;
            headerData.forEach( headerItem => {
                let cell = $( '#'+ this.gridId +' .rs_cell_' + gridNode.id + '_' +headerItem.field);
                //let cell = $( '#'+ this.gridId + ' .'+headerItem.field+'_'+rowIndex);
                if(headerItem.validationRule){

                    let validationRule  = {
                        name:headerItem.field,
                        displayName:headerItem.headerName,
                        ... ( typeof headerItem.validationRule =='function' ? headerItem.validationRule(gridNode) : headerItem.validationRule)
                    }

                    let validator = new GridValidator(validationRule);
                    let dataNeedToValid = {
                        [headerItem.field]:data[headerItem.field]
                    }
                    if(!validator.isValid(dataNeedToValid)){
                        let messages = validator.getMessage();
                        messages = messages.reverse();
                        messages.forEach(msg => {
                            errorMessages[msg.name] = msg.message;
                        })
                        //validator.displayMessage();
                        cell.addClass('data_invalid');
                        this.hasError = true;
                    }else{
                        cell.removeClass('data_invalid');
                    }
                }
            })
        })
        if(this.hasError){
            this.validationMessages = errorMessages;
            for (const messageKey in errorMessages) {
                if (Object.hasOwnProperty.call(errorMessages, messageKey)) {
                    Helper.alert(errorMessages[messageKey],'error');
                }
            }
        }else{
            this.validationMessages = {};
        }
        return this.hasError;
    }
    getFirstValidationMessage(){
        for (const messageKey in this.validationMessages) {
            if (Object.hasOwnProperty.call(this.validationMessages, messageKey)) {
                return {
                    key:messageKey,
                    message:this.validationMessages[messageKey]
                }
            }
        }
        return {};
    }
    intGrid(gridOptions,defaultData){
        var eGridDiv = document.getElementById(this.gridId);
        this.agGrid = new Grid(eGridDiv, gridOptions);
        gridOptions.api.setRowData(defaultData);
        this.gridOptions = gridOptions;
        if(this.props.getGrid && typeof this.props.getGrid ==='function'){
            this.props.getGrid(this.gridOptions);
        }
        $('.ag-header-icon').find('.ag-icon').removeClass('ag-icon-menu').addClass('ag-icon-filter');
    }
    onRowEditingStopped(event){
        $('.ag-cell').removeClass('ag-cell-focus');
        this.gridOptions.api.deselectAll();
        if(!this.props.noValidationOnRowEditingStop){
            this.checkValidationError();
        }
        if(this.props.onRowEditingStopped && typeof this.props.onRowEditingStopped ==='function' ){
            this.props.onRowEditingStopped(event)
        }
    }
    onRowEditingStarted(event){
        if(this.props.onRowEditingStarted && typeof this.props.onRowEditingStarted ==='function' ){
            this.props.onRowEditingStarted(event)
        }
    }
    onColumnEverythingChanged(event){
        if(this.props.onColumnEverythingChanged && typeof this.props.onColumnEverythingChanged ==='function' ){
            this.props.onColumnEverythingChanged(event)
        }
    }
    onCellMouseOut(event){
        if(this.props.onCellMouseOut && typeof this.props.onCellMouseOut ==='function' ){
            this.props.onCellMouseOut(event)
        }
    }
    onCellEditingStopped(event){
        if(this.props.onCellEditingStopped && typeof this.props.onCellEditingStopped ==='function' ){
            this.props.onCellEditingStopped(event)
        }
    }
    onVirtualColumnsChanged(event){
        if(this.props.onVirtualColumnsChanged && typeof this.props.onVirtualColumnsChanged ==='function' ){
            this.props.onVirtualColumnsChanged(event)
        }
    }
    onCellValueChanged(event){
        if(this.props.onCellValueChanged && typeof this.props.onCellValueChanged ==='function' ){
            this.props.onCellValueChanged(event)
        }
    }
    onCellFocused(event) {
        //tabToNextCell
        //console.log('d'+event.column.isCellEditable());
        /*
        if(!event.column.colDef.editable){
            event.api.tabToNextCell();
        }
        */
        if(this.props.onCellFocused && typeof this.props.onCellFocused ==='function' ){
            this.props.onCellFocused({...event,componentObj:this})
        }
    }
    onColumnMoved(event) {
        var columnState = JSON.stringify(event.columnApi.getColumnState());
        localStorage.setItem(this.gridId+'_grid_col_state', columnState);
        if(this.props.onColumnMoved && typeof this.props.onColumnMoved ==='function' ){
            this.props.onColumnMoved({...event,componentObj:this})
        }
    }
    onGridColumnsChanged(event) {
        if(this.props.onGridColumnsChanged && typeof this.props.onGridColumnsChanged ==='function' ){
            this.props.onGridColumnsChanged({...event,componentObj:this})
        }
    }
    reloadUserSettings(){
        var columnState = JSON.parse(localStorage.getItem(this.gridId+'_grid_col_state'));
        if (columnState) {
            this.gridOptions.columnApi.applyColumnState({
                state: columnState,
                applyOrder: true,
            });
        }
    }
    onGridReady(event){
        let that = this;
        var gridElement =document.getElementById(this.gridId); // Replace 'myGrid' with the ID of your grid element

        document.addEventListener('mousedown', function(e) {
            var targetElement = e.target;
            if (targetElement && gridElement && !gridElement.contains(targetElement)) {
                event.api.stopEditing();
            }
        });
        clearTimeout(this.reloadUserSettingsTimeOut);
        //that.reloadUserSettings()
        this.reloadUserSettingsTimeOut = setTimeout(function () {
            that.reloadUserSettings()
        },500)
        if(this.props.onGridReady && typeof this.props.onGridReady ==='function' ){
            this.props.onGridReady({...event,componentObj:this})
        }
    }
    onFirstDataRendered(event){
        if(this.props.onFirstDataRendered && typeof this.props.onFirstDataRendered ==='function' ){
            this.props.onFirstDataRendered({...event,componentObj:this})
        }
    }
    onSearchHandler(e){
        this.gridOptions.api.setQuickFilter(e.target.value);
    }
    currentAgGrid(){
        return this.gridOptions;
    }
    onRowClicked(event){
        if(this.props.onRowClick && typeof this.props.onRowClick === 'function'){
            this.props.onRowClick(event)
        }
    }
    onRowGroupOpened(event){
        if(this.props.onRowGroupOpened && typeof this.props.onRowGroupOpened === 'function'){
            this.props.onRowGroupOpened(event)
        }
    }
    onRowSelected(event){
        if(this.props.onRowSelected && typeof this.props.onRowSelected === 'function'){
            this.props.onRowSelected(event)
        }
    }
    
    onCellClicked(event){
        if(this.props.onCellClicked && typeof this.props.onCellClicked === 'function'){
            this.props.onCellClicked(event)
        }
    }
    getSnapshotBeforeUpdate(prevProps, prevState){
        this.columnDefs = this.props.header ? this.props.header : [];
        this.gridOptions.api.setColumnDefs(this.getColumnDefs())
        this.gridOptions.enableRtl = Helper.isRtl();
        this.gridOptions.api.refreshHeader();
        this.gridOptions.api.setRowData(this.getModifiedSetRowData());
        return null;
    }
    
    
   
    getColumnDefs(){
        let formatedColumnDefs = [];
        let header = this.columnDefs;
        header.forEach(  (headerItem)  => {
            if(headerItem.inputType && headerItem.inputType ==='datepicker'){
                headerItem = {
                    cellEditor: DatePicker,
                    ...headerItem,
                    cellRenderer: DatePickerViewer, 
                    //cellEditorPopup: true,
                    //filter: 'agDateColumnFilter',
                    //filterParams: DateFilters,
                }
            }
            else if( headerItem.inputType && headerItem.inputType === 'new_datepicker'){
                headerItem = {
                    ...headerItem,
                    cellEditor: NewDatePicker,
                    cellRenderer: DatePickerViewer
                }
            }
            else if( headerItem.inputType && headerItem.inputType === 'checkbox'){
                headerItem = {
                    ...headerItem,
                    cellEditor: GridCheckbox,
                    cellRenderer: CheckboxRender
                }
            }
            else if( headerItem.inputType && headerItem.inputType === 'colorpicker'){
                headerItem = {
                    ...headerItem,
                    cellEditor: ColorPicker,
                    cellRenderer: ColorPickerRender
                }
            }
            else if( headerItem.inputType && headerItem.inputType === 'select'){
                /*
                headerItem = {
                    ...headerItem,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: headerItem.options,
                    },
                    cellEditorPopup: false
                }
                */
               let options = headerItem.options.map( item => { return { label:item,value:item}})
                headerItem = {
                    
                    valueFormatter:(params) => {
                        return this.getLabelFromAdvanceDropDown(params);//params.label;
                    },
                    ...headerItem,
                    cellEditor: SimpleDropdown,
                    cellEditorParams: {
                        values: options,
                    },
                    cellEditorPopup: false
                }
            }
            else if( headerItem.inputType && headerItem.inputType === 'dropdown'){
                headerItem = {
                    valueFormatter:(params) => {
                        return this.getLabelFromAdvanceDropDown(params);//params.label;
                    },
                    cellEditor: SimpleDropdown,
                    ...headerItem,
                    cellEditorParams: {
                        values: typeof headerItem.options == 'function' ? (paramsData) =>{return headerItem.options(paramsData)}  : headerItem.options,
                    },
                    cellEditorPopup: false
                }
            }
            else if( headerItem.inputType && headerItem.inputType === 'number'){
                headerItem = {
                    /*
                    valueFormatter:(params) => {
                        return this.getLabelFromAdvanceDropDown(params);//params.label;
                    },
                    */
                    cellEditor: NumberCellEditor,
                    cellRenderer:NumberCellRenderer,
                    ...headerItem,
                    cellEditorPopup: false
                }
            }
            else{
            }
            if(this.props.showFilterBox){
                
                headerItem.floatingFilter = true;
            }
            formatedColumnDefs.push(headerItem)
        })
        if(formatedColumnDefs.length >= 1 && !this.props.dissableCheckBox){
            formatedColumnDefs[0].headerCheckboxSelection = true;
            formatedColumnDefs[0].checkboxSelection = true;
        }
        return formatedColumnDefs;
    }
    getLabelFromAdvanceDropDown(params){
        let output = params.value;
        if( Array.isArray(params.colDef.options)){
            params.colDef.options.forEach(item => {
                if(params.value == item.value){
                    output = item.label;
                }
            })
        }
        if(!params.colDef.isAllowZero){
            if(output ==0 ){
                return '';
            }
        }
        return output;
    } 
    onRowValueChanged(event) {
        if(this.props.onRowValueChanged && typeof this.props.onRowValueChanged === 'function'){
            this.props.onRowValueChanged(event)
        }
        this.setGridValueChanged(event.node.id,event.data)
    }
    setGridValueChanged(nodeId,nodeData){
        if(!this.isDataChanged){
            this.isDataChanged = true;
        }
        $('#rs_grid_wrapper_'+this.gridId).find('.rs_grid_btn_save').removeClass('ctl-dissable');
        this.updatedRowData['row_number_'+nodeId] = nodeData;
    }
    exportBtnClickHandler(e) {
        // Check if there is data in the grid
        if (this.gridOptions.api.getModel().getRowCount() > 0) {
            var params = {};
            if (params.columnSeparator) {
                alert(
                    'NOTE: you are downloading a file with non-standard separators - it may not render correctly in Excel.'
                );
            }
            this.gridOptions.api.exportDataAsExcel(params);
        } else {
            alert('No data found to export.');
        }
    }
     
    reloadBtnHandler(e){
        if(this.agGrid){
            this.gridOptions.api.setRowData(this.getModifiedSetRowData())
            //this.gridOptions.api.refreshServerSide({ route: Settings.apiBaseUrl+'/get_labels' , purge: true });
        }
    }
    
    saveBtnClickHandler(e){
        if(this.checkValidationError()){
            return;
        }
        if(!this.isDataChanged)
            return;
        let formatedData = [];
        for (const dataItemKey in this.updatedRowData) {
            let itemToPush = this.updatedRowData[dataItemKey];
            if(itemToPush.gridRowId){
                if(!this.deletedItemsList.includes(itemToPush.gridRowId)){
                    formatedData.push(itemToPush)
                }else{
                    // item deleted
                    console.log('item delted')
                }
            }else{
                formatedData.push(itemToPush)
            }
        }
        if(this.props.onSave && this.props.onSave instanceof Function ){
            this.props.onSave(formatedData);
        }else{
            console.log('Please pass a function props as onSave')
        }
        this.isDataChanged = false;
        this.updatedRowData = {};
    }
    handlePostPageHandler(value){
        this.props.handlePageSize(value)
        this.gridOptions.api.paginationSetPageSize(value)
    }
    postPerPageChangeHandler(e){
        this.setState({
            needRefresh: !this.state.needRefresh
        })
        const ppp = e.target.value;
        this.props.pagination?this.handlePostPageHandler(e.target.value):this.gridOptions.api.paginationSetPageSize(ppp);
    }
    makeEptyObject(source) {
        var newObject = Array.isArray(source) ? [] : {};
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                var t = typeof source[key];
                newObject[key] = t === 'object' ? this.makeEptyObject(source[key]) : { string: '', number: 0, boolean: false }[t];
                if(t === 'object' && Object.keys(newObject[key]).length === 0){
                    newObject[key] = "";
                }
            }
        }
        return newObject;
    }
    startEditFirstRow(key) {
        let columnDefs = this.getColumnDefs();
        this.gridOptions.api.setFocusedCell(0, columnDefs[0].field);
        this.gridOptions.api.startEditingCell({
            rowIndex: 0,
            colKey: columnDefs[0].field,
            key: key
        });
    }
    getGridCurrentData(){
        let gridData = [];
        this.gridOptions.api.forEachNode( gridItem => {
            gridData.push(gridItem.data);
        })
        return gridData;
    }
    addNewButtonHandler(e,defaultDataNewData = this.props.addNewDefaultValue ? this.props.addNewDefaultValue : {} ){
        if(this.props.onAddClick){
            this.props.onAddClick();
            return;
        }
        if(this.props.onBeforeRowAdd){
            this.props.onBeforeRowAdd(this.gridOptions);
        }
        if(this.props.onAddClickCheck) {
            const check = this.props.onAddClickCheck();
            if(!check) return;
        }
        this.gridOptions.api.deselectAll();
        let headerData = this.getColumnDefs();
        let newData = this.props.data;//this.getGridCurrentData(); //this.gridOptions.api.getF //this.props.data; //this.getModifiedSetRowData();
        let structure = this.makeEptyObject(newData[0]);
        structure.gridRowId = Date.now();
        headerData.forEach(headerItem => {
            if(headerItem.defaultValue){
                structure[headerItem.field] = typeof headerItem.defaultValue ==='function' ? headerItem.defaultValue(this.gridOptions) : headerItem.defaultValue;
            }
        })
        //newData.splice(0,0,structure)
        //this.gridOptions.api.setRowData(newData);
        structure = {
            ...structure,
            ...defaultDataNewData
        }
        this.gridOptions.api.applyTransaction({
            add:[structure],
            addIndex:0
        })
        let that = this;
        that.startEditFirstRow(undefined)

        if(this.props.onAfterRowAdd){
            this.props.onAfterRowAdd(this.gridOptions);
        }
        /*
        this.gridOptions.api.applyTransaction({
            add: [{}],
            addIndex:0
        });
        */
    }

    delteBtnHandler(e){
        let that = this;
        let itemsNeedToDelte = this.gridOptions.api.getSelectedRows();
        let getSelectedNodes = this.gridOptions.api.getSelectedNodes();
        const totalItems = itemsNeedToDelte.length;
        if(totalItems <=0){
            return;
        }
        let bodyMessage = totalItems + (totalItems === 1 ?  " item " : ' items ' )+' will be deleted';
        $('<div>'+bodyMessage+'</div>').dialog({
             modal: true,
             zIndex: 30005,
             title:'Do you want to delete?',
             buttons: {
                "Yes": function() {
                    $( this ).dialog( "close" );

                    getSelectedNodes.forEach(nodeItem => {
                        if(nodeItem.id){
                            that.deletedItemsList.push(nodeItem.id)
                        }
                    })

                    that.gridOptions.api.applyTransaction({
                        remove:itemsNeedToDelte,
                    });
                    that.deletedRowsHistory = itemsNeedToDelte;
                    if(that.props.onDelete && that.props.onDelete instanceof Function ){
                        that.props.onDelete(itemsNeedToDelte);
                    } else if(that.props.deleteConfig){
                        that.deleteUsingApi(that.props.deleteConfig,itemsNeedToDelte)
                    } else{
                        console.log('Please pass a function props as onDelete')
                    }
                },
                Cancel: function() {
                  $( this ).dialog( "close" );
                }
            }
        });
        
    }
    undorDelete(onlyLastOne = false){
        if (this.deletedRowsHistory.length > 0) {
            var lastDeletedRowData = this.deletedRowsHistory.pop();
            this.gridOptions.api.applyTransaction({ add: [lastDeletedRowData] });
            this.gridOptions.api.refreshCells();
        }
    }
    deleteUsingApi(options = {},items){
        let that = options.that;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let delItems = 0;
        let isApiCalled = false;
        items.forEach(item => {
            if(item[options.idName]){
                isApiCalled = true;
                api.axios().delete(options.apiUrl + '/' +item[options.idName]).then( response => {
                    delItems++;
                    if(delItems >= items.length){
                        that.setState({
                            isLoading:false,
                        })
                        if(response.data.status ==='success'){
                            Helper.alert(response.data.message)
                        }
                        if(options.reloadFuntion){
                            options.reloadFuntion(true)
                        }
                    }
                    
                }).catch(error=>{
                    delItems++;
                    if(error.response){
                        getPopupMessageBasedOnStatus(error)
                    }
                    if(delItems >= items.length){
                        that.setState({
                            isLoading:false
                        })
                        if(options.reloadFuntion){
                            options.reloadFuntion(true)
                        }
                    }
                    
                })
            }else{
                delItems++;
            }
        })

        if(!isApiCalled){
            that.setState({
                isLoading:false
            })
        }
        
    }
    selectionChangedHandler(event){
        if(!this.props.readOnlyGrid){
            if(event.api.getSelectedRows().length >= 1){
                $('#'+this.gridId).closest('.rs_ag_grid_panel').find('.btn_delete').css({opacity:'1'});
            }else{
                $('#'+this.gridId).closest('.rs_ag_grid_panel').find('.btn_delete').css({opacity:'0.5'});
            }
        }
        
        if(this.props.onSelectionChanged && typeof this.props.onSelectionChanged === 'function'){
            this.props.onSelectionChanged(event)
        }
    }
    displayAddBtn(){
        if(this.props.addBtnHide || this.props.allBtnHide){
            return <></>
        }
        let btnTitle = (this.props.labels && this.props.labels.btnAdd) ? this.props.labels.btnAdd : Helper.getLabel(this.props.language,11221,'Add');
        if(this.props.addBtnDissable || this.props.readOnlyGrid){
            return <p className='rs_h_controller ctl-dissable rs_header_controller'  ><img src="/images/icons/add-new.svg"  alt="Add New"/><span>{btnTitle}</span></p>
        }
        return <p className='rs_h_controller rs_header_controller'  onClick={ e => this.addNewButtonHandler(e)} ><img src="/images/icons/add-new.svg"  alt="Add New"/><span>{btnTitle}</span></p>
    }
    displayRefreshBtn(){
        if(this.props.allBtnHide){
            return <></>
        }
        return <p className='rs_h_controller rs_header_controller'  onClick={ e => this.reloadBtnHandler(e)} ><img src="/images/icons/refresh-cw.svg"  alt="Refresh"/><span>{Helper.getLabel(this.props.language,11270,'Refresh')}</span></p>
    }
    displayDownloadBtn(){
        if(this.props.allBtnHide){
            return <></>
        }
        return <p className='rs_h_controller rs_header_controller'  onClick={ e => this.exportBtnClickHandler(e)}><img src="/images/icons/arrow-down.svg" alt="Download"/><span>{Helper.getLabel(this.props.language,11224,'Export')}</span></p>
    }
    getModifiedSetRowData(data=null){
        let setRowData = data ? data : this.props.data;
        // add setRowDataGridRowId 
        setRowData = setRowData.map( (dataItem,key) => {
            if(dataItem.gridRowId || dataItem.setRowDataGridRowId){
                return dataItem;
            }else{
                return {
                    ...dataItem,
                    setRowDataGridRowId: key +'' + Date.now() 
                }
            }
        })
        let that = this;
        // deleted Data filter
        let filterdData =  setRowData.filter(item => {
            if(item.gridRowId){
                return !that.deletedItemsList.includes(item.gridRowId)
            }
            return item;
        })

        return filterdData;
        
    }
    gridFooter(){
        if(this.props.hideFooter){
            return<></>
        }
        const options = [{
            value:10,
            label:10,
        },
        {
            value:20,
            label:20,
        },
        {
            value:50,
            label:50,
        },
        {
            value:100,
            label:100,
        },
       ]
        return(
            <div className='ag_grid_footer'>
                <div className='d-flex justify-content-between'>
                    <div className='rs_ppp_select pagination-select'>
                        <span>Show</span>
                        {/* comment for once */}
                        {/* <select defaultValue={this.ppp} onChange={ e=> this.postPerPageChangeHandler(e)}>
                            <option>10</option>
                            <option>20</option>
                            <option>50</option>
                            <option>100</option>
                        </select> */}
                        <Dropdown disableClear={true} id={`pagination-dropdown-${this.props.id}`} value={this.ppp} onChange={e=> this.postPerPageChangeHandler(e)} options={options}/>
                        <span>entries</span>
                    </div>
                    <div className='ag_footer_right'>
                        <Pagination agGrid={this.gridOptions} getAgObject = { this.currentAgGrid.bind(this) } data={this.getModifiedSetRowData()} pagination={this.props.pagination} onPageChange={this.props.handlePageChange} totalRecords={this.props.totalRecords} pageSize={this.props.pageSize}/>
                    </div>
                </div>
                
            </div>
        )
    }
    headerRightContents(){
        if(this.props.hideHeaderRightContents){
            return <></>
        };
        
        return(
            <div className='d-flex justify-content-between right'>
                <div className='d-flex justify-content-between rs_h_controllers'> 
                    {
                        this.props.beforeDownloadButton ? this.props.beforeDownloadButton() : ''
                    }
                    { this.displayDownloadBtn() }
                    {
                        this.props.afterDownloadButton ? this.props.afterDownloadButton() : ''
                    }
                </div>
               
                { !this.props.hideAdvanceFilter 
                ?
                    (
                        !this.props.pagination ? 
                            <div className='rs_gh_search_box'> 
                                <input placeholder='Search' onChange={ e => this.onSearchHandler(e) }  className='form-control' />
                            </div>
                        : 
                            <AdvanceSearchFilterDropdown 
                                searchOnDocument={ this.searchOnDocument }
                                searchWithoutFieldKey={ this.searchWithoutFieldKey }
                                apiUrlForSearch={ this.apiParamsURL }
                                pageSize={ this.props.pageSize } 
                                gridData={ this.getModifiedSetRowData() } 
                                gridOptionsAPI={ this.currentAgGrid.bind(this) } 
                            /> 
                    )
                     
                :
                    ''
                }
            </div>
        )
    }
    componentDidUpdate(prevProps){
        if( (prevProps.readOnlyGrid != this.props.readOnlyGrid || this.props.viewOnly != prevProps.viewOnly) && this.agGrid){
            let newOptions = {
                ...this.defaultColDef,
                editable:this.props.readOnlyGrid ? false : (this.props.viewOnly  ? false : true)
            }
            this.agGrid.gridOptions.api.setDefaultColDef(newOptions)
        }
        /*
        this.defaultColDef = {
            editable: this.props.readOnlyGrid ? false : (this.props.viewOnly  ? false : true) ,
            sortable: true,
            filter: 'agTextColumnFilter',
            resizable: true,
            flex: 1,
            cellClass: (prarams) => {  return prarams.colDef.field+'_'+prarams.rowIndex },
            minWidth: 100,
        }
        */
        /*
        if(this.state.oldGridData && this.state.oldGridData.length >=1){
            this.agGrid.gridOptions.api.setRowData(this.state.oldGridData)
        }
        */
        //this.reloadBtnHandler(null);
    }
    /*
    shouldComponentUpdate(nextProps, nextState){
        //return true;
        if(this.agGrid.gridOptions.api){
            let gridData = [];
            this.agGrid.gridOptions.api.forEachNode( gridItem => {
                gridData.push(gridItem.data);
            })
            if(JSON.stringify(this.state.oldGridData) != JSON.stringify(gridData)){
                this.setState({
                    oldGridData:gridData
                })
            }
            
        }
        return true;
    }
    */
   displaySaveButton(){
    let saveBtnTitle = this.props.saveBtnTitle ?  this.props.saveBtnTitle : Helper.getLabel(this.props.language,'save','Save');
    if(this.props.readOnlyGrid){
        return <p className="save_btn rs_h_controller ctl-dissablers_header_controller" ><img src="/images/icons/save.svg" alt={saveBtnTitle}/><span>{saveBtnTitle}</span></p>
    }
    return !this.props.hideSave && !this.props.allBtnHide ? <p className={this.isDataChanged ? 'rs_h_controller rs_header_controller rs_grid_btn_save' : 'rs_h_controller rs_header_controller rs_grid_btn_save ctl-dissable' }  onClick={ e => this.saveBtnClickHandler(e)}><img src="/images/icons/save.svg" alt={saveBtnTitle}/><span>{saveBtnTitle}</span></p> : ''

   }
   displayDeleteButton(){
    let deleteBtnLabel = (this.props.labels && this.props.labels.btnDelete) ? this.props.labels.btnDelete : Helper.getLabel(this.props.language,11222,'Delete');
    if(this.props.readOnlyGrid){
        return <p className='rs_h_controller btn_delete ctl-dissable rs_header_controller' ><img src="/images/icons/delete.svg"  alt={deleteBtnLabel}/><span>{ deleteBtnLabel }</span></p>
    }
    return  !this.props.hideDelete  && !this.props.allBtnHide ? <p className='d-flex rs_h_controller btn_delete ctl-dissable rs_header_controller'  onClick={ e => this.delteBtnHandler(e)} ><img src="/images/icons/delete.svg"  alt={deleteBtnLabel}/><span>{ deleteBtnLabel }</span></p> : '';
   }

    render() {
        //console.log(this.props.addBtnDissable);
        return (
            <div id={'rs_grid_wrapper_'+this.gridId} className= { 'rs_ag_grid_panel' + ( this.props.showLoader ? ' rs_ag_custom_loader_active' : '') }>
                {
                    this.props.showLoader ? <div className='grid_custom_overloader'><SimpleLoading/></div> : ''
                }
                <div className='d-flex justify-content-between align-items-center rs_ag_header'>
                    <div className='left'>
                        <div className='d-flex justify-content-between align-items-center rs_h_controllers rs_h_actions'>
                            { this.props.beforeAddButton  ? this.props.beforeAddButton() : ''}
                            { this.displayAddBtn() }
                            { this.props.beforeSaveButton  ? this.props.beforeSaveButton() : ''}
                            { this.displaySaveButton() }
                            { this.props.beforeDeleteButton  ? this.props.beforeDeleteButton() : ''}
                            { this.displayDeleteButton()}
                            { this.props.afterDeleteButtons  ? this.props.afterDeleteButtons() : ''}
                        </div>
                        
                    </div>
                    {
                        this.headerRightContents()
                    }
                    
                </div>
                <div className='ag_grid_wrapper'>
                    <div id={this.gridId} className="ag-theme-alpine" style={{height: this.props.height ? this.props.height : '500px'}}></div>
                    {this.gridFooter()}
                </div>
                
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        allSources:state.allSources,
        language:state.language,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAllSource:(sources) => { dispatch({type:ActionTypes.SET_ALL_SOURCE , payload:sources}) },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (RsWithRouter(AgGrid));