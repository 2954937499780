import React, { useState, useEffect, useRef, Fragment } from 'react'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { TextSkeletonComponent } from '../../Skeleton/SkeletonComponent'
import Button from '../../inc/Button'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import SimpleLoading from '../../Loading/SimpleLoading'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import Settings from '../../../inc/Settings'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import LineViewComponent from '../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { useParams } from 'react-router-dom'
import { BudgetTransfer_Data } from './BudgetTransferData'
import Input from '../../Forms/Input'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import Dropdown from '../../Forms/Dropdown'
import Api from '../../../inc/Api'

const budgetTransferStatuses = [
  { id: 1, name: 'Draft' },
  { id: 2, name: 'Re-call' },
  { id: 3, name: 'In-review' },
  { id: 4, name: 'Approved' },
]

const approverList = [
    { value: 1, label: 'Jane Smith' },
    { value: 2, label: 'Michael Scott' },
    { value: 3, label: 'Sara Lee' },
    { value: 4, label: 'Lisa Williams' },
    { value: 5, label: 'Brian Lewis' },
    { value: 6, label: 'Katherine Moore' }
  ];

  const fromBudgetList = [
    { value: 'IT Projects', label: 'IT Projects' },
    { value: 'Training Budget', label: 'Training Budget' },
    { value: 'Sales Tools', label: 'Sales Tools' },
    { value: 'Financial Systems', label: 'Financial Systems' },
    { value: 'Vendor Relations', label: 'Vendor Relations' },
    { value: 'Admin Budget 2024', label: 'Admin Budget 2024' },
  ];

  const toBudgetList = [
    { value: 'Infrastructure Upgrade', label: 'Infrastructure Upgrade' },
    { value: 'Employee Development', label: 'Employee Development' },
    { value: 'CRM Migration', label: 'CRM Migration' },
    { value: 'Audit Tools', label: 'Audit Tools' },
    { value: 'Supplier Database Upgrade', label: 'Supplier Database Upgrade' },
    { value: 'Procurement Budget 2024', label: 'Procurement Budget 2024' },
  ];
  
  
  

const BudgetTransferEdit = (props) => {
  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [scrollDitection, setScrollDitection] = useState('down')
  const [height, setHeight] = useState(0)

  const [loading, setLoading] = useState(false)
  const [budgetTransferData, setBudgetTransferData] = useState({})
  const [departments,setDepartments]=useState([])

  const myDivRef = useRef(null)
  let sectionName = 'budget-transfer-edit'

  const { id } = useParams()

  const fetchBudgetData = () => {
    const budgetTransfer = BudgetTransfer_Data?.find((entry) => entry.id === parseInt(id))
    if (budgetTransfer) {
      setBudgetTransferData(budgetTransfer)
    }
  }

  const fetchDepartments = () => {
    let api = Api
    api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
        let departmentData = [];
        if(res.data.status === 'success'){
            let responseData = res.data.data;
            departmentData = responseData.filter( item => { 
                return item.isactive===true 
            }).map((item)=>{
                return {
                    value:item.segmentvalue_id,
                    label:item.name
                }
            })
            setDepartments(departmentData)
        }
    }).catch(error =>{
    })
  }

  useEffect(() => {
    fetchBudgetData()
    fetchDepartments()
  }, [])

  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, 'document_number', 'Document Number'),
        subtitle: !budgetTransferData ? <TextSkeletonComponent /> : budgetTransferData?.document_number ?? budgetTransferData?.RFQ_vendorreply_id,
      },
      {
        title: Helper.getLabel(props.language, 'document_type', 'Document Type'),
        subtitle: !budgetTransferData ? <TextSkeletonComponent /> : 'Budget Transfer' || '-',
      },
      {
        title: Helper.getLabel(props.language, 'title', 'Title'),
        subtitle: !budgetTransferData ? <TextSkeletonComponent /> : budgetTransferData?.title?.length > 30 ? budgetTransferData?.title?.substring(0, 30) + '...' || '-' : budgetTransferData?.title || '-',
      },
      {
        title: Helper.getLabel(props.language, 'submitter', 'Submitter'),
        subtitle: !budgetTransferData ? <TextSkeletonComponent /> : budgetTransferData?.requester_name ?? '-',
      },
    ]

    let budgetTransferDocumentStatus = budgetTransferStatuses?.find((item) => item.id === budgetTransferData?.status_id)

    let tagTitle = budgetTransferDocumentStatus?.name || '-'
    let tags = [{ title: tagTitle.replace(/_/g, ' ') || null }]

    return (
        <div className='rfq-internal-blue'>
          <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
        </div>
    )
  }

  const handleOnChange = (e) => {
    let value = e.target.value
    setBudgetTransferData((prev) => {
      const updatedBudgetTransferHeader = { ...prev, [e.target.name]: value }
      return updatedBudgetTransferHeader
    })
  }

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef, setHeight, window)
  }, [myDivRef.current?.clientHeight, scrollDitection])

  let navs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      data_section: 'one',
      active_status: 'active_tab',
    },
  ]

  let superNav = [{ title: Helper.getLabel(props.language, 'header_view', 'Header View') }, { title: Helper.getLabel(props.language, 'line_view', 'Line View') }]

  let statusList = budgetTransferStatuses?.map((item)=>{return{value:item.id,label:item.name}})

  let priorityList = [{value:1,label:'Low'},{value:2,label:'High'}]

  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <NoteAttachmentHistory scrollDitection={scrollDitection} height={height} hideHistory={true} source_id={90} notesource_integrator={id} attachmentsource_integrator={id} hideAttachments={false} hideVendorPortal={true} />
      </MasterSidebar>
    )
  }

  return (
    <div className='scrollBarBlinkControl'>
      <div className={`rfq-internal-screens`}>
        <div ref={myDivRef}>
          <NavigationHeder removeMargin={true} title={'Edit Budget Transfer'} backUrl={-1} hideMoreBtn={true}>
            <Button isActive={false} className='rfq_vendor_reply_publish_btn' title={Helper.getLabel(props.language, 'submit', 'Submit')} isDisable={false} />
            <TotalWidget source_id={Settings.source_id?.RfqVendorReply} integrator={id} />
          </NavigationHeder>

          <FixedNavigationHeder
            addComponentTop={addComponentTop}
            sectionName={sectionName}
            navs={navs}
            scrollDitection={scrollDitection}
            selectedSupNav={selectedSupNav}
            setSelectedSupNav={setSelectedSupNav}
            superNav={superNav}
          />
        </div>
        {loading ? (
          <OverlayLoader />
        ) : (
          <TabContentWraper
            height={height}
            setScrollDitection={setScrollDitection}
            leftSideContent={
              <>
                {selectedSupNav == 'Header View' && (
                  <>
                    <div className='lists_contant one' id={`${sectionName}General`} style={{ position: 'relative' }}>
                      <Collapse title={Helper.getLabel(props.language, 'general_information', 'General Information')} open={true} className='mb-5'>
                        <div className='generalInformation-basic'>
                          <div className='generalInformation-basic-grid'>
                            <Input
                              label={`${Helper.getLabel(props.language, 'document_number', 'Document number')}`}
                              name='document_number'
                              value={budgetTransferData?.document_number}
                              onChange={handleOnChange}
                              disable={true}
                            />
                            <Input label={`${Helper.getLabel(props.language, 'title', 'Title')}`} name='title' value={budgetTransferData?.title} onChange={handleOnChange} disable={budgetTransferData?.status_id !== 1} />
                            <Input
                              label={`${Helper.getLabel(props.language, 'description', 'Description')}`}
                              name='description'
                              value={budgetTransferData?.description}
                              onChange={handleOnChange}
                              disable={budgetTransferData?.status_id !== 1}
                            />
                            <Input
                              label={`${Helper.getLabel(props.language, 'requester_name', 'Requester name')}`}
                              name='requester_name'
                              value={budgetTransferData?.requester_name}
                              onChange={handleOnChange}
                              disable={budgetTransferData?.status_id !== 1}
                            />   
                             <Dropdown
                              label={`${Helper.getLabel(props.language, 'department', 'Department')}`}
                              options={departments}
                              placeHolder={`${Helper.getLabel(props.language, 'select_department', 'Select department')}`}
                              id='budget_transfer_department'
                              name='segmentvalue_id'
                              onChange={handleOnChange}
                              value={budgetTransferData?.segmentvalue_id}
                              isDisable={budgetTransferData?.status_id !== 1}
                            /> 
                            <Dropdown
                              label={`${Helper.getLabel(props.language, 'from_budget', 'From budget')}`}
                              options={fromBudgetList}
                              placeHolder={`${Helper.getLabel(props.language, 'select_from_budget', 'Select from budget')}`}
                              id='budget_from'
                              name='from_budget'
                              onChange={handleOnChange}
                              value={budgetTransferData?.from_budget}
                              isDisable={budgetTransferData?.status_id !== 1}
                            /> 
                            <Dropdown
                              label={`${Helper.getLabel(props.language, 'to_budget', 'To Budget')}`}
                              options={toBudgetList}
                              placeHolder={`${Helper.getLabel(props.language, 'select_to_budget', 'Select to budget')}`}
                              id='budget_transfer_to_budget'
                              name='to_budget'
                              onChange={handleOnChange}
                              value={budgetTransferData?.to_budget}
                              isDisable={budgetTransferData?.status_id !== 1}
                            /> 
                            <Input
                              label={`${Helper.getLabel(props.language, 'transfer_reason', 'Transfer reason')}`}
                              name='transfer_reason'
                              value={budgetTransferData?.transfer_reason}
                              onChange={handleOnChange}
                              disable={budgetTransferData?.status_id !== 1}
                            />  
                            <Input
                              label={`${Helper.getLabel(props.language, 'totak_transfer_amount', 'Total transfer amount')}`}
                              name='total_transfer_amount'
                              value={budgetTransferData?.total_transfer_amount}
                              onChange={handleOnChange}
                              disable={budgetTransferData?.status_id !== 1}
                            />          
                            <Dropdown
                              label={`${Helper.getLabel(props.language, 'approval_status', 'Approval status')}`}
                              options={statusList}
                              placeHolder={`${Helper.getLabel(props.language, 'select_approval_status', 'Select approval status')}`}
                              id='budget_transfer_status'
                              name='status_id'
                              onChange={handleOnChange}
                              value={budgetTransferData?.status_id}
                              isDisable={budgetTransferData?.status_id !== 1}
                            />   
                            <Dropdown
                              label={`${Helper.getLabel(props.language, 'approvers', 'Approver')}`}
                              options={approverList}
                              placeHolder={`${Helper.getLabel(props.language, 'select_approver', 'Select approver')}`}
                              id='budget_transfer_approver'
                              name='approver_id'
                              onChange={handleOnChange}
                              value={budgetTransferData?.approver_id}
                              isDisable={budgetTransferData?.status_id !== 1}
                            />       
                            <NewInputDatePicker
                              label={`${Helper.getLabel(props.language, 'approval_date', 'Approval date')}`}
                              id='approal_date'
                              name='approval_date'
                              onChange={(date) => {
                                setBudgetTransferData({
                                  ...budgetTransferData,
                                  approval_date: date,
                                })
                              }}
                              value={budgetTransferData.approval_date}
                              disable={budgetTransferData?.status_id !== 1}
                            />
                            <Dropdown
                              label={`${Helper.getLabel(props.language, 'priority', 'Priority')}`}
                              options={priorityList}
                              placeHolder={`${Helper.getLabel(props.language, 'select_priority', 'Select priority')}`}
                              id='budget_priority'
                              name='priority_id'
                              onChange={handleOnChange}
                              value={budgetTransferData?.priority_id}
                              isDisable={budgetTransferData?.status_id !== 1}
                            />  
                            <Input
                              label={`${Helper.getLabel(props.language, 'comments', 'Comments')}`}
                              name='comments'
                              value={budgetTransferData?.comments}
                              onChange={handleOnChange}
                              disable={budgetTransferData?.status_id !== 1}
                            />
                          </div>
                        </div>
                      </Collapse>
                      <div style={{height:'100px'}}></div>
                    </div>
                  </>
                )}
                {selectedSupNav === 'Line View' && (
                  <>
                    {loading ? (
                      <SimpleLoading />
                    ) : (
                      <div className='rfq-line-view line_scoring_module mb-4'>
                        <LineViewComponent
                          defaultValues={{
                            requester_id: budgetTransferData?.requester_id,
                          }}
                          lineGridId='rfq-internal-lines'
                          source_id={null}
                          apiUrl={null}
                          data={[]}
                          line_source={null}
                          isEditable={false}
                          lineGridTitle={Helper.getLabel(props.language, 'budget_transfer_lines', 'Budget Transfer Lines')}
                          header_id={null}
                          purchase_status={null}
                          document_status={null}
                          delivery_date={null}
                          onbehalf_request={false}
                          vendor_id={null}
                          vendorsite_id={null}
                          requester_id={budgetTransferData?.requester_id}
                          orderer_id={budgetTransferData?.order_id}
                          setOrderStatusProps={null}
                          warehouse_id={null}
                          taxgroup_item={null}
                          taxgroup_id={null}
                          rfqExternal={true}
                          hideBillingRule={true}
                          billingrulegrid_id='normal'
                          distributionGridId='distribution-normal'
                          alternate_line={false}
                          disableAllBtn={true}
                          // onLineGridReady={onGridReady}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            }
            rightSideContent={addComponentRight}
          />
        )}
      </div>
    </div>
  )
}

export default MasterComponentWraper(BudgetTransferEdit)
