import React, { Component } from 'react';
import $ from 'jquery';
import Helper from '../../inc/Helper';

class CollapseWithButton extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.id ? this.props.id : Date.now();
        this.buttonLabel = this.props.btnLabel ? this.props.btnLabel : '';
        this.isEventApply = false;
        this.requestClickHandler = this.props.requestBtnClick || (() => {});
        this.state = {
            isCollapsed: !this.props.open,
            className: this.props.className ? this.props.className : '',
        };
    }

    componentDidMount() {
        let selector = '#' + this.id + ' .rs_collaps_title';
        if (!this.isEventApply) {
            $(selector).on('click', function (event) {
                event.preventDefault();
                if ($(event.target).is('.request_btn')) { return; }
                $(this)
                .next('.rs_collapse_contents')
                .slideToggle('fast', 'linear', function () {
                    $(this).closest('.rs_collapse').toggleClass('collapsed');
                    Helper.refreshAppScript();
                });
                return false;
            });
            this.isEventApply = true;
        }
    }

    render() {
        let className = 'rs_collapse ' + this.state.className;
        if (this.state.isCollapsed) { className += ' collapsed'; }

        return (
            <div id={this.id} className={className}>
                <div className="rs_collaps_title" open={true}>
                    <p>{this.props.title}</p>
                {/* <button onClick={(e) => this.requestClickHandler(e)} className="request_btn">
                        <img className="img-fluid" src="/images/icons/plus-white.svg" /> {this.buttonLabel}
                    </button> */}
                </div>
                <div className="rs_collapse_contents">{this.props.children}</div>
            </div>
        );
    }
}
  
export default CollapseWithButton;