class SuggestedVendorCellRender {
  init(params) {
    this.params = params;
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = "Loading..";
    this.category = this.params.data.category;
    this.loadDistributionDetails();
  }

  loadDistributionDetails() {
    this.eGui.innerHTML = this.getInnerHtml(this.category);
  }

  getInnerHtml(flexData) {
    let detailsBody = "";

    flexData.forEach((el) => {
      detailsBody += `<div class="SuggestedVendorCellRender__flex_item">${el.category_name}</div>`;
    });
    return `<div class="SuggestedVendorCellRender__flex_gap_10">${detailsBody}</div>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

export default SuggestedVendorCellRender;
