import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper'; 
import Collapse from '../../inc/Collapse';
import FieldsRenderer from './FieldsRenderer';

const AdditionalInfoDistributionTab = (props) => {

  let textBoxInputSettings = [ 
    {label:Helper.getLabel(props.language,'source document',"Source document"), inputType: "text", disable: true, name:'requisition_id', id:'purchase_requisition_number'},
    {label:Helper.getLabel(props.language,'requester',"Requester"), options: props.requester, inputType: "dropdown", name:'requester_id', id:'requester_id'},
    {label:Helper.getLabel(props.language,'under_delivery_tolerence',"Under delivery tolerence"), inputType: "checkbox", name:'under_delivery_tolerance', id:'under_delivery_tolerence_id'},
    {label:Helper.getLabel(props.language,'over_delivery_tolerence',"Over delivery tolerence"),inputType: "checkbox", name:'over_delivery_tolearance', id:'over_delivery_tolerence'},
    {label:Helper.getLabel(props.language,'over_receipt_action',"Over receipt action"),inputType: "checkbox", name:'overreceipt_action', id:'over_receipt_action_id'},
    {label:Helper.getLabel(props.language,'received_qty',"Received quantity"), inputType: "text", name:'received_qty', disable:true, id: 'received_qty_id' },
    {label:Helper.getLabel(props.language,'return_qty',"Return quantity"), inputType: "text", name:'returned_qty', disable:true, id: 'return_qty'},
    {label:Helper.getLabel(props.language,'canceled_qty',"Canceled quantity"), inputType: "text", name:'canceled_qty', disable:true, id: 'canceled_qty_id' },
    {label:Helper.getLabel(props.language,'accepted_qty',"Accepted quantity"), inputType: "text", name:'accepted_qty', disable:true, id: 'accepted_qty_id'}, 
  ]
  
  return (
    <Collapse className='mb-4 additional_info_list_view_lines' open={true} title={Helper.getLabel(props.language,'additional_info',"Additional Information")}>
      <div className='container-fluid'>
        <FieldsRenderer updateGridState={props.updateGridDataState} updateState={props.setState} currentState={props.state} fieldsArray={textBoxInputSettings} />
      </div>  
    </Collapse>
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}



export default connect(mapStateToProps) (AdditionalInfoDistributionTab) 