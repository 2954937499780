import React, { Fragment, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../inc/Api";
import Gui_id_list from "../../inc/Gui_id_list";
import Helper from "../../inc/Helper";
import Settings from "../../inc/Settings";
import MasterComponentWraper from "../Backend/MasterComponentWraper"; 
import Alert from "../inc/Alert";
import ButtonNew from "../inc/ButtonNew"; 
import NavigationHeder from "../Navigations/NavigationHeder";
import ApplySecurityRoles from "../SecurityRoles/ApplySecurityRoles";
import { getPopupMessageBasedOnStatus } from "../../inc/Validation";
import AgGridNew from "../grid/ag/ag-grid-new";
import { gridLinkValueFormat, gridValueFormatChip } from "../grid/ag/commonFunctions/GridCommonFunctions";
import CreateProcurementModal from "./CreateProcurementModal";

let gridApi = null;

const ParametersArchive = (props) => {
  const [showProcurementPopup, setShowProcurementPopup] = useState(false);
  const navigate = useNavigate();

  const CellHandler = (event) => {
    if (event.node.data.procurement_setting_id !== "") {
      if (event.colDef.field === "name") {
        navigate(
          `/edit-procurement-settings/${event?.data?.procurement_setting_id}`
        );
      }
    } else {
      Helper.alert("Setting Id Doesnt Exists!", "error");
    }
  };
 
  const DeleteParamters = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delProcurement_ID = element?.data?.procurement_setting_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiProcurementSettingsUrl+`/procurementsetting/${delProcurement_ID}`)
      const rowNode = gridApi?.getRowNode(delProcurement_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  let security = props.security;
  let frontendIds = Gui_id_list;
  if (
    !security.canView(
      Gui_id_list.procure.procurement_settings.procurement_settings
    )
  ) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  /* Grid Column Headers */

  const gridColumnHeaders = [
    {
      field: "name",
      headerName: Helper.getLabel(props.language, "name", "Name"), 
      cellRenderer: (params) => gridLinkValueFormat(params),
      filter: 'agTextColumnFilter'
    },
    {
      field: "entities",
      headerName: Helper.getLabel(props.language, "entities", "Entities"), 
      cellRenderer: (params) => gridValueFormatChip(params, true, 'entity_name'),
      filter: 'agTextColumnFilter'
    }
  ];

  return (
    <div className="parameters_settings_procure_archive">
      <div className="container-fluid">
        <NavigationHeder
          backUrl="/setup"
          hideMoreBtn={true}
          title={Helper.getLabel(
            props.language,
            "procurement_settings",
            "Procurement Settings"
          )}
        >
          <ButtonNew
            isDisable={
              !security.canCreate(
                frontendIds.procure.procurement_settings
                  .procurement_settings_main_create_button
              )
            }
            onClick={() => setShowProcurementPopup(true)}
            title={Helper.getLabel(props.language, "new", "New")}
          />
        </NavigationHeder>

        <div className="rs_parameters_edit"> 
          <AgGridNew
            apiUrl={Settings.apiProcurementSettingsUrl + `/procurementsetting`} 
            hideDeleteBtn={
              !security.canDelete(
                frontendIds.procure.procurement_settings
                  .procurement_settings_main_delete_button
              )
            }
            pagination={true}
            columnDefs={gridColumnHeaders} 
            hideAddBtn={true}
            height={500}
            uniqueField={'procurement_setting_id'}
            onCellClicked={CellHandler}
            handleDeleteSelectedRows={DeleteParamters}
            onGridReady={(params) => gridApi = params?.api} 
            gridId={"rs_procurement_settings_list"}
          />
        </div>
      </div>
      {showProcurementPopup && <CreateProcurementModal setShowCreateProcureModal={setShowProcurementPopup} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.procure.procurement_settings.procurement_settings,
};

export default connect(mapStateToProps)(
  MasterComponentWraper(ApplySecurityRoles(ParametersArchive, SecurityOptions))
); 