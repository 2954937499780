import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings';
import { checkNullProperties, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import SimpleLoading from '../../Loading/SimpleLoading';
import Tabs from '../../Widgets/Tabs/Tabs';
import BillingRuleVersion from './BillingRuleVersion';
import DeliveryVersion from './DeliveryVersion';
import GeneralVersion from './GeneralVersion';
import PriceDiscountVersion from './PriceDiscountVersion';
import SegmentsVersion from './SegmentsVersion';

const POHeaderTabs = ({language, currency, vendor, entities, payment, modeOfDelivery, deliveryTerm, itemGroup, ordered, requester, sites, taxGroup, taxCode, viewComponent, id}) => {
  const [POHistoryGeneral, setPOHistoryGeneral] = useState([])
  const [POHistoryDelivery, setPOHistoryDelivery] = useState([])
  const [POHistoryPriceDiscount, setPOHistoryPriceDiscount] = useState([])
  const [loadingTabs, setLoadingTabs] = useState(false)

  // API Reduction Calls --
  let POHistoryCalled = false 
  // 

  // API Renderer calls --

  useEffect(() => {
    POHeaderAPI()
  }, [])

  // API Calls --

  const POHeaderAPI = () => {
    if(POHistoryCalled || !id ){
      return;
    }
    setLoadingTabs(true)
    let api = Api
    POHistoryCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/po_header_h/${id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE_General = []
        let UPDATED_STATE_Delivery = []
        let UPDATED_STATE_Price_Discount = []
        res.data.data.forEach(element => {
          UPDATED_STATE_General.push({
            ...element, 
            accounting_date: element.accounting_date ? Helper.formateDate(element.accounting_date) : null,
            document_date: element.document_date ? Helper.formateDate(element.document_date) : null, 
            allowaccesstobuyergroup: element.allowaccesstobuyergroup == true ? "yes" : null,
            blindreceivingofqty: element.blindreceivingofqty == true ? "yes" : null
          })
          UPDATED_STATE_Delivery.push({
            delivery_term_id: element.delivery_term_id,
            mode_of_delivery_id: element.mode_of_delivery_id,
            deliveryaddress_id: element.deliveryaddress_id,
            delivery_date: element.delivery_date ? Helper.formateDate(element.delivery_date) : null,
          })
          UPDATED_STATE_Price_Discount.push({ 
            priceinclude_tax: element.priceinclude_tax == true ? "yes" : null,
            paymentterm_id: element.paymentterm_id,
            discount_percentage: element.discount_percentage,
            discount_amount: element.discount_amount
          })
        });
        setPOHistoryGeneral(UPDATED_STATE_General)
        setPOHistoryDelivery(UPDATED_STATE_Delivery)
        setPOHistoryPriceDiscount(UPDATED_STATE_Price_Discount)
        setLoadingTabs(false)
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoadingTabs(false)
    })
  }
 
  // Tabs Declarations -

  let navs = [
    {title:Helper.getLabel(language,'general','General'), isActive: true, },
    {title:Helper.getLabel(language,'delivery','Delivery')}, 
    {title:Helper.getLabel(language,'price_discount','Price & Discount')},
  ];
 
  return (
    <div className={viewComponent === 'Header View' ? 'header_view_class' : 'list_view_class'}>
      {loadingTabs ? <SimpleLoading /> : (
        <Tabs navs={navs} tabContantsId='purchase-orders-header-view-history-tabs-list' showAll={false} scrollOffset="300">
          <Fragment>
            <GeneralVersion data={POHistoryGeneral} 
              currency={currency} 
              vendor={vendor} 
              entities={entities} 
              itemGroup={itemGroup} 
              ordered={ordered} 
              requester={requester} 
              sites={sites} 
              taxGroup={taxGroup} 
              taxCode={taxCode}
            />
          </Fragment>
          <Fragment><DeliveryVersion deliveryTerm={deliveryTerm} modeOfDelivery={modeOfDelivery} data={POHistoryDelivery} /></Fragment>
          <Fragment><PriceDiscountVersion payment={payment} data={POHistoryPriceDiscount} /></Fragment>
        </Tabs>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
    
export default connect(mapStateToProps) (POHeaderTabs) 