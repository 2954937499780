import React, { useEffect, useState } from 'react'
import Button from '../inc/Button'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import Collapse from '../inc/Collapse'
import Input from '../Forms/Input'
import Form from 'react-bootstrap/Form'
import NewInputDatePicker from '../Forms/NewInputDatePicker'
import Api from '../../inc/Api'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Settings from '../../inc/Settings'
import ButtonNew from "../inc/ButtonNew"
import { useNavigate, useParams } from 'react-router-dom'
import DatepickerFunctions from '../../inc/DatepickerHelper'

const NewQuestionnaire = (props) => {
  const [questionnaireData, setQuestionnaireData] = useState({
    description: '',
    is_active: true,
    total_time: null,
    allow_back: false,
    allow_next_without_complete_question: true,
    overview_enabled: true,
    note_enabled: false,
    note: '',
    overview: '',
  })
  const navigate = useNavigate()
  const {id} = useParams()
  const handleQuestionnaireData = (event,type) => {
    let value = type === 'checkbox' ? event?.target?.checked : event?.target?.value
    setQuestionnaireData(
      {
        ...questionnaireData,
        [event.target.name]:value
      }
    )
  }
  const handleDateChange = (date) => {
    setQuestionnaireData({
      ...questionnaireData,
      total_time:date
    })
  }
  const onSaveClickHandler = () => {
    let payload = {
      ...questionnaireData,
      total_time:DatepickerFunctions.convertTimeFromDate(questionnaireData?.total_time)
    }
    if(id){
      let api = Api;
      api.setUserToken();
      api.axios().put( Settings.apiUrl + `/questionnaire/${id}`,payload).then(function(res){
          if(res.data.status ==='success'){
              Helper.alert(res.data.message)
          }else{
              Helper.alert(res.data.message,'error')
          }
      }).catch(error => {
          getPopupMessageBasedOnStatus(error)
      })
    }else{
    let api = Api;
    api.setUserToken();
    api.axios().post( Settings.apiUrl + '/questionnaire',payload).then(function(res){
        if(res.data.status ==='success'){
            Helper.alert(res.data.message)
            setTimeout(()=>{
              navigate("/questionnaire-list")
            },200)
        }else{
            Helper.alert(res.data.message,'error')
        }
    }).catch(error => {
        getPopupMessageBasedOnStatus(error)
    })
   }
  }

  const getQuestionnaireData = () => {
    if(!id){
      return 
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/questionnaire/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionnaireData({
            ...res.data.data,
            total_time:DatepickerFunctions.combineDateAndTime(res.data?.data?.total_time)
          })
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
    })
  }

  useEffect(()=>{
    getQuestionnaireData()
  },[])

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language,id? 'edit_questionnaire' : 'new_questionnaire',id?'Edit Questionnaire' : 'New Questionnaire')}`} hideMoreBtn={true}>
          {id && <ButtonNew title={Helper.getLabel(props.language, 'question group', 'Question Group')} onClick={()=>{navigate(`/question-group?questionnaire_id=${id}`)}} />}
          <Button title={Helper.getLabel(props.language, 'save', 'Save')} icon='/images/icons/save-white.svg' isActive={true} onClick={onSaveClickHandler}/>
        </NavigationHeder>
        <div>
          <div>
            <Collapse open={true} title={Helper.getLabel(props.language, 'questionnaire', 'Questionnaire')}>
              <div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <Input label={`${Helper.getLabel(props.language, 'description', 'Description')}`} name='description' value={questionnaireData?.description} onChange={(event) => handleQuestionnaireData(event)   } />
                  </div>
                  <div className='col-sm-6'>
                    <NewInputDatePicker
                      label={`${Helper.getLabel(props.language, 'total_time_to_complete_the_questionnaire', 'Total time to complete the questionnaire')}`}
                      name='total_time'
                      value={questionnaireData?.total_time}
                      onChange={handleDateChange}
                      timePicker={true}
                      timeOnly={true}
                      empty={true}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6 mb-4'>
                    <div className='rfq-questionnaire-controls-div'>
                      <Form.Check
                        label={
                          <div className='d-flex flex-column mx-2'>
                            <span>Active</span>
                            <span className='rfq-questionnaire-control-subtitle'>Request managers to approve orders above a minimum amount</span>
                          </div>
                        }
                        name='is_active'
                        value={questionnaireData?.is_active}
                        checked={questionnaireData?.is_active}
                        onChange={(event) => handleQuestionnaireData(event,'checkbox')   }
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 mb-4'>
                    <div className='rfq-questionnaire-controls-div'>
                      <Form.Check
                        label={
                          <div className='d-flex flex-column mx-2'>
                            <span>Allow Back</span>
                            <span className='rfq-questionnaire-control-subtitle'>Request managers to approve orders above a minimum amount</span>
                          </div>
                        }
                        name='allow_back'
                        value={questionnaireData?.allow_back}
                        checked={questionnaireData?.allow_back}
                        onChange={(event) => handleQuestionnaireData(event,'checkbox')   }
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 mb-4'>
                    <div className='rfq-questionnaire-controls-div'>
                      <Form.Check
                        label={
                          <div className='d-flex flex-column mx-2'>
                            <span>Allow next without completing the question</span>
                            <span className='rfq-questionnaire-control-subtitle'>Request managers to approve orders above a minimum amount</span>
                          </div>
                        }
                        name='allow_next_without_complete_question'
                        value={questionnaireData?.allow_next_without_complete_question}
                        checked={questionnaireData?.allow_next_without_complete_question}
                        onChange={(event) => handleQuestionnaireData(event,'checkbox')   }
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 mb-4'>
                    <div className='rfq-questionnaire-controls-div'>
                      <Form.Check
                        label={
                          <div className='d-flex flex-column mx-2'>
                            <span>Overview visible</span>
                            <span className='rfq-questionnaire-control-subtitle'>Request managers to approve orders above a minimum amount</span>
                          </div>
                        }
                        name='overview_enabled'
                        value={questionnaireData?.overview_enabled}
                        checked={questionnaireData?.overview_enabled}
                        onChange={(event) => handleQuestionnaireData(event,'checkbox')   }
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 mb-4'>
                    <div className='rfq-questionnaire-controls-div'>
                      <Form.Check
                        label={
                          <div className='d-flex flex-column mx-2'>
                            <span>Notes visible</span>
                            <span className='rfq-questionnaire-control-subtitle'>Request managers to approve orders above a minimum amount</span>
                          </div>
                        }
                        name='note_enabled'
                        value={questionnaireData?.note_enabled}
                        checked={questionnaireData?.note_enabled}
                        onChange={(event) => handleQuestionnaireData(event,'checkbox')   }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <Input label={`${Helper.getLabel(props.language, 'overview', 'Overview')}`} name='overview' value={questionnaireData?.overview}  onChange={(event) => handleQuestionnaireData(event)   }/>
                  </div>
                  <div className='col-sm-6'>
                    <Input label={`${Helper.getLabel(props.language, 'note', 'Note')}`} name='note' value={questionnaireData?.note}  onChange={(event) => handleQuestionnaireData(event)   } />
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

export default NewQuestionnaire
