import React, { useState } from 'react'
import Helper from '../../../inc/Helper'
import Popup from '../../Popup/Popup'
import { Form } from 'react-bootstrap'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import AgGridMemo from '../../grid/ag/AgGridMemo'
import Button from '../../inc/Button'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader"
import axios from 'axios'

let forceRender = 1
let cancelTokenSource = axios.CancelToken.source();

const UnsealedBidModal = ({ handleCloseModal,language,vendors, rfqId }) => {
  const [selectedSealedTypes,setSelectedSealedTypes] = useState([]);
  const [selectedVendors,setSelectedVendors]=useState([])
  const [saveBtnApi,setSaveBtnApi]=useState(false)

  const handleSealedTypeChange = (value) => {
    if (selectedSealedTypes.includes(value)) {
      setSelectedSealedTypes(selectedSealedTypes.filter((type) => type !== value));
    } else {
      setSelectedSealedTypes([...selectedSealedTypes, value]);
    }
  };
  
  const onSelectionChanged = (event) => {
    let selectedRows = event?.api?.getSelectedRows()
    setSelectedVendors(selectedRows)
  }

  const unSealedBidPost = async () => {
     let api = Api
     api.setUserToken()
     let vendors = []
     selectedVendors?.map((item) => {
      vendors.push(item.vendorreply_id);
     });
     let payload = {
      vendorreply_id:vendors,
      evaluation_type:selectedSealedTypes,
     }
     try{
      const unsealedRes = await api.axios().post(Settings?.apiPurchaseRequisitionUrl + `/initiate_unsealed/${rfqId}`,payload, { cancelToken: cancelTokenSource.token })
      if(unsealedRes){
       Helper.alert(unsealedRes?.data?.message)
       handleCloseModal()
      }
     }catch(error){
       getPopupMessageBasedOnStatus(error)
     }
  }

  const unSealedHandler = () => {
    Helper.createDebouncedAPIFunction([
      async () => await unSealedBidPost()
    ], setSaveBtnApi, cancelTokenSource)();
  }

  const Columns_Headings = [
    {
      field: 'vendor_code',
      headerName: Helper.getLabel(language, 'code', 'Code'),
      editable: false,
      minWidth: 180,
    },
    {
      field: 'vendor_name',
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
      minWidth: 200,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(language, 'vendor_site_name', 'Vendor site name'),
      editable: false,
      minWidth: 225,
    },
    {
      field: 'expiry_datetime',
      headerName: Helper.getLabel(
        language,
        'expiration_date_and_time',
        'Expiry date and time'
      ),
      editable: false,
      minWidth: 225,
      valueFormatter: (params) =>
        params.value === null || params.value === undefined
          ? null
          : DatepickerFunctions.convertDateTimeFromDataBase(params?.value),
    },
    {
      field: 'contact_number',
      headerName: Helper.getLabel(
        language,
        'primary_contact_person',
        'Primary contact person'
      ),
      minWidth: 225,
      editable: false,
      valueFormatter: (params) => params.value || '-',
    },
    {
      field: 'email',
      headerName: Helper.getLabel(language, 'email', 'Email'),
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => params.value || '-',
    },
  ];

  return (
    <>
    <Popup autoOpen={true} width='1100px' onClose={handleCloseModal}>
      <>
        <h5 className='create_rfq_modal__title'>
          {Helper.getLabel(language, 'unsealed', 'Unsealed')}
        </h5>
        <div>
          <h6 className='mt-3'>Please choose option</h6>
          <div className='d-flex mt-3 gap-3'>
            <Form.Check
              type='checkbox'
              label='Technical Evaluation'
              checked={selectedSealedTypes.includes(0)}
              onChange={() => {
                handleSealedTypeChange(0);
              }}
            />
            <Form.Check
              type='checkbox'
              label='Commercial Evaluation'
              checked={selectedSealedTypes.includes(1)}
              onChange={() => {
                handleSealedTypeChange(1);
              }}
            />
          </div>
        </div>
        <div className='mt-4'>
          <AgGridMemo
            id='evaluation_operations_history'
            header={Columns_Headings}
            allBtnHide={true}
            forceRender={forceRender}
            onSelectionChanged={onSelectionChanged}
            data={vendors}
          />
        </div>
        <div className='mt-4 d-flex align-items-end w-100 justify-content-end'>
          <Button title={Helper.getLabel(language,'unseal','Unseal')} className='rfq_save_btn' onClick={unSealedHandler} isDisable={saveBtnApi} />
        </div>
      </>
    </Popup>
    {saveBtnApi ? <OverlayLoader /> : null }
    </>
  )
}

export default UnsealedBidModal
