import React, { memo, useRef } from 'react';
import NewInputDatePicker from '../../../Forms/NewInputDatePicker';

export default memo(( {value, onValueChange, stopEditing, empty,portalId="root"} ) => {
    const refContainer = useRef(null);
    const onChange = (date) => {
        onValueChange(date);
        // stopEditing();
    };
    return (
        <div ref={refContainer}
            tabIndex={1} 
        >
            <div>
             <NewInputDatePicker empty={empty} onChange={(date)=>{
               return onChange( date ? date?.toString() : '')}} value={value} timePicker={false} portalId={portalId}/>
            </div>
        </div>
    );
});
