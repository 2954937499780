import React, { Component } from 'react';

export const buttonIconPosition = {
    left: 0,
    right: 1
}

class Button extends Component {
    getClass = ( ) => {
        let props = this.props;
        let className = 'btn btn-default rs_btn ';
        if(props.isActive){
            className += ' btn_active';
        }
        if(props.isDisable){
            className += ' disable';
        }
        if(props.className){
            className += ' '+props.className;
        }
        return className;
    }
    onClickHandler(e){
        if(this.props.isDisable){
            return;
        }
        if(this.props.onClick && typeof this.props.onClick ==='function'){
            this.props.onClick(e)
        }
    }
    render() {
        let props = this.props;
        return (
            <button
            id = {this.props.id ? this.props.id : Math.random()}
            className={this.getClass()}
            onClick={(e) => this.onClickHandler(e)}
          >
            {props.icon && props.iconPosition !== buttonIconPosition.right ? (
              <img className="icon" src={props.icon} alt={props.title} />
            ) : (
              ""
            )}
            {props.title}
            {props.icon && props.iconPosition === buttonIconPosition.right ? (
              <img className="icon" src={props.icon} alt={props.title} />
            ) : (
              ""
            )}
          </button>
        );
    }
}

export default Button;