import React, { Component } from 'react';
import {  NavLink, Route, Routes } from 'react-router-dom';
import MasterComonent from '../components/Backend/MasterComonent';
import Button from '../components/inc/Button';
import RsDataLoader from '../components/inc/RsDataLoader';
import AccountBasicInformation from '../components/MyAccount/AccountBasicInformation';
import AccountSecurity from '../components/MyAccount/AccountSecurity';
import NavigationHeder from '../components/Navigations/NavigationHeder';
import Helper from '../inc/Helper';
import RsWithRouter from '../components/inc/RsWithRouter';

class MyAccount extends Component {
    constructor(props){
        super(props);
        this.state = {
           hoveredTab: null 
         };
    }
     
    onSavBtnClick(e){ 
        if(this.formComponentObj){
            this.formComponentObj.onSaveHandler()
        }
    }
    setFormComponentObj(frmObj){
        this.formComponentObj = frmObj;
    }
    handleMouseEnter(tabName) {
        this.setState({ hoveredTab: tabName });
    }

    handleMouseLeave() {
        this.setState({ hoveredTab: null });
    }
    renderContent(){
        let language = this.props.dataLoaderProps.language;
        let icons = {
            basic:{default:"/images/icons/user-gray.svg",active:"/images/icons/user-blue.svg"},
            security:{default:"/images/icons/security-gray.svg",active:"/images/icons/security-blue.svg"},
            deligation:{default:"/images/icons/users-gray.svg",active:"/images/icons/users-gray.svg"},
        }
        let location = this.props.rs_router?.location
        let { hoveredTab } = this.state;
        return (
                <div className='my_account_page'>
                    <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(language,'profile','Profile')}>
                        <Button className='rfq_save_btn' isActive={true} title={ Helper.getLabel(language,'save','Save')} onClick={ this.onSavBtnClick.bind(this)} />
                    </NavigationHeder>
                    <div className='rs_vertical_tabs my_account_tab'>
                        <div className='rs_vertical_nav rs_vertical_tab_menu'>
                            <NavLink to="/my-account" onMouseEnter={() => this.handleMouseEnter('basic')} onMouseLeave={() => this.handleMouseLeave()} end><img src={location?.pathname==="/my-account" || hoveredTab === 'basic'?icons.basic.active:icons.basic.default} alt="" /> <span className='tb_label'>Basic Information</span><img className='icon_ang_right' src={'/images/icons/angle-right-blue.svg'} alt="" /></NavLink>
                            <NavLink to="/my-account/security" onMouseEnter={() => this.handleMouseEnter('security')} onMouseLeave={() => this.handleMouseLeave()} end><img src={location?.pathname==="/my-account/security" || hoveredTab === 'security'?icons.security.active:icons.security.default} alt="" /><span className='tb_label'>Password & Security</span><img  className='icon_ang_right' src={'/images/icons/angle-right-blue.svg'} alt="" /></NavLink>
                            {/* <NavLink to="/my-account/deligation" end><img src={icons.deligation.default} alt="" /><span className='tb_label'>Deligation</span><img  className='icon_ang_right' src={'/images/icons/angle-right-blue.svg'} alt="" /></NavLink> */}
                        </div>
                        <div className='rs_vertical_tab_contents'>
                            <Routes>
                                <Route path='/security' element={ <AccountSecurity setFormObj = {this.setFormComponentObj.bind(this)}/>} />
                                {/* <Route path='/deligation' element={ <AccountDeligation  setFormObj = {this.setFormComponentObj.bind(this)}/>} /> */}
                                <Route path='/' element={ <AccountBasicInformation  setFormObj = {this.setFormComponentObj.bind(this)}/>} />
                            </Routes>
                        </div>
                    </div>
                </div> 
        );
    }
    render() {
        return this?.props?.isMasterComponenNotNeeded ? (
            <div className='join_to_vendor container-fluid external-theme my-account-external'>
             {this.renderContent()}
            </div>
        ) : (
         <MasterComonent>
            {this.renderContent()}
         </MasterComonent>     
        );
    }
}

export default RsWithRouter(RsDataLoader(MyAccount));