import Api from "../../../../inc/Api";
import Helper from "../../../../inc/Helper";
import Settings from "../../../../inc/Settings";

class WarehouseCellRenderer {
    init(params) {
        this.params = params;
        this.value = params.value;
        this.eGui = document.createElement('div');
        if(this.value){
            this.loadWharehouse()
        }
    }
    loadWharehouse(){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.eGui.innerHTML = Helper.getLabel(null,'loading','Loading');
        api.axios().get(Settings.apiUrl+'/warehouse/'+this.value).then(res=>{
            if(res.data.status=='success'){
                that.eGui.innerHTML = res.data.data.name;
            }else{
                that.eGui.innerHTML = ""
            }
        }).catch(error =>{
            that.eGui.innerHTML = ""
        })
    }
    getGui() {
        return this.eGui;
    }
}
export default WarehouseCellRenderer;