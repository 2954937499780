import React from 'react';
import ContractReports from '../../components/ContractReports';

const ContractReportScreen = () => {
  return (
    <ContractReports />
  );
}


export default ContractReportScreen;
