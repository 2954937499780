import Api from "../../../../inc/Api";
import MasterCellEditor from "./MasterCellEditor";
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Helper from "../../../../inc/Helper";
/**
cellEditor:AdvancedDropdown,
cellEditorParams:{
    lynSettings: {
        dependentSelector:['site_id'],
        resetDependentSelector:['entity_id'],
        apiUrl:Settings.apiUrl +  '/get_warehouse_site',
        apiRequestMethod:'get',
        options: (apiResponse) => {
            let output = apiResponse.data.data.map( item => {
                return {
                    label: item.name,
                    value: item.warehouse_id
                }
            });
            return output;
        }
    }
}
 */

class AdvancedDropdown extends MasterCellEditor{
    init(params) {
        this.defaultSite = params?.colDef?.defaultSite
        this.vc = params.colDef;
        this.params = params;
        this.settings = params.lynSettings ? params.lynSettings : null;
        this.dropdownId = params.colDef.inputId ? params.colDef.inputId : params.colDef.field;
        this.uiWraperId = this.dropdownId+'_wrapper';
        this.value = params.value;
        this.uiWraper = document.createElement("div");
        this.uiWraper.id = this.uiWraperId;
        this.addClass(this.uiWraper,'rs_ag_adv_dropdown')
        this.loader = document.createElement("div");
        this.addClass(this.loader,'spinner-border spinner-border-sm rs_spinner')
        this.uiWraper.appendChild(this.loader)
        this.input = document.createElement("select");
        this.input.id = this.dropdownId;
        this.uiWraper.appendChild(this.input);
        this.apiCalledParams = {}
        if(!this.settings){
            this.input = document.createElement("div");
            this.input.innerHTML = "Settings error"
        }else{
            let that = this; 
            this.generateApiCallParams()
            this.loadApiData(function(res){
                //that.input.value = that.value;
                //$(that.input).trigger('change')
                $(that.input).trigger('select2:select')
                //select2:select
            });
            this.input.addEventListener('input', (event) => {
                this.value = event.target.value;
            });
            //reset dropdown
            if(this.settings.resetDependentSelector){
                this.settings.resetDependentSelector.forEach( selectorId => {
                    $('#'+selectorId).off('change');
                    $('body').on('change','#'+selectorId,function(event){
                        that.resetDropdown()
                    })

                })
            }
            // apply change event
            this.settings.dependentSelector.forEach( selectorId => { 
                $('#'+selectorId).off('change');
                const selectElement = document.getElementById(selectorId);
                const changeListeners = selectElement?._listeners || [];

                changeListeners.forEach(listener => {
                    selectElement?.removeEventListener("change", listener);
                })
                if(selectElement){
                selectElement._listeners = [];
                }
                $('#'+selectorId).on('select2:select',function(){
                    that.generateApiCallParams()
                    that.loadApiData()
                })
            })
        }

        
    }

    afterGuiAttached(){
        let that = this;
        setTimeout(function(){
            that.initSelect2();
        },50)
    }
    initSelect2(){
        let that = this;
        let dropdownSelctor =  $('#'+this.dropdownId);
        let dropdownSelctorCode = $('#vendor_code');
        let newValue = 'newVendorCodeValue'; // Replace this with the desired value
    // if(dropdownSelctorCode &&  this.defaultSite){ 
    //     dropdownSelctor.val(newValue).trigger('change');
    //     dropdownSelctor.select2({
    //         placeholder: this.params.data.vendor_code ? this.params.data.vendor_code : Helper.getLabel(null, 'please_select', 'Please Select'),

    //         allowClear: true,
    //         width: '100%',
    //         openOnEnter: true
    //     })
    // }else {
        dropdownSelctor.select2({
            placeholder: this.settings.placeHolder ? this.settings.placeHolder : Helper.getLabel(null,'please_select','Please Select'),
            allowClear: true,
            width: '100%',
            openOnEnter: true
        })
    // }
        $("#"+this.uiWraperId).closest('.ag-cell-value').css({
            overflow:'visible'
        })

        dropdownSelctor.on('select2:select', function (event) {
            that.value = event.target.value;
            that.input.value = that.value;
            if(that.settings.onChange){
                that.settings.onChange(event);
            }
        });

        dropdownSelctor.on('select2:unselect', function (event) {
            that.value = "";
            that.input.value = that.value;
            if(that.settings.onChange){
                that.settings.onChange(event);
            }
        });

    }
    generateApiCallParams(){
        let that = this;  
        this.settings.dependentSelector.forEach(selectorId => {  
            that.apiCalledParams[selectorId] = document?.getElementById(selectorId)?.value;
        })
    }
    resetDropdown(){
        this.input.innerHTML = "";
        var placeHolderOption = document.createElement("option");
        placeHolderOption.value = "";
        placeHolderOption.text = "Select";
        placeHolderOption.setAttribute("disabled", "");
        placeHolderOption.setAttribute("selected", "");
        placeHolderOption.setAttribute("hidden", "");
        this.input.appendChild(placeHolderOption);
    }
    loadApiData(afterLoadData = null){
        let that = this;
        // if (this.params.data.gridId && this.defaultSite) {
        //     let that = this;
        //     const defaultSiteSelect = this.params?.data?.siteOptions?.filter((item) => item.is_default == true);
        //     defaultSiteSelect?.forEach(item => {
        //         var option = document.createElement("option");
        //         option.value = item.value;
        //         option.text = item.label;
        //         that.input.appendChild(option);
        //     });
        // }
        // else {
        //Create and append the options
        let getUrlParams = '';
        this.settings.dependentSelector.forEach(selectorId => {
            getUrlParams += '/'+that.apiCalledParams[selectorId]
        })
        this.resetDropdown();
        that.addClass(this.uiWraper,'loading')
        // Load api data
        let api = Api;
        api.setUserToken();
        let httpRequestMethod = this.settings.apiRequestMethod ? this.settings.apiRequestMethod : 'get';
        api.axios()[httpRequestMethod](this.settings.apiUrl + ( httpRequestMethod === 'get'  ? getUrlParams : '' ) ,this.apiCalledParams).then(res=>{
         
            let dataList = that.settings.options(res)
            // this is for default Site Select based on vendor code...
            if (this.defaultSite) {
                const defaultSiteSelect = dataList.filter((item) => item.isdefault == true)[0];
                this.input.innerHTML = "";
                var placeHolderOption = document.createElement("option");
                placeHolderOption.value = defaultSiteSelect ? defaultSiteSelect?.value : '';
                placeHolderOption.text = defaultSiteSelect ? defaultSiteSelect?.value : '';
                this.value=defaultSiteSelect?.value
                placeHolderOption.setAttribute("selected", "");
                placeHolderOption.setAttribute("hidden", "");
            }

            
            dataList.forEach(item => {
                var option = document.createElement("option");
                option.value = item.value;
                option.text = item.label;
                option.isdefault = item.isdefault;
                if(that.value == item.value){
                    that.addAttribute(option,'selected','')
                }
                that.input.appendChild(option);
            });
            that.removeClass(this.uiWraper,'loading')
            if(afterLoadData && typeof afterLoadData == 'function'){
                afterLoadData(res)
            }
        }).catch(error => {
            that.removeClass(this.uiWraper,'loading')
            that.resetDropdown();
        })
    // }
    }
    getGui() {
        return this.uiWraper;
    }
     getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
    }
    destroy(){
        $("#"+this.uiWraperId).closest('.ag-cell-value').css({
            overflow:'hidden'
        })
    }

    focusIn(){
        let dropdownSelctor =  $('#'+this.dropdownId);
        dropdownSelctor.select2('focus');
    }
    /*
    afterGuiAttached() {
        this.input.focus();
    }
    */
}

export default AdvancedDropdown;