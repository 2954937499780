import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import "./Banks.css";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import Settings from "../../../inc/Settings";
import Button from "../../inc/Button";
import LinkBtn from "../../inc/LinkBtn";
import NavigationHeder from "../../Navigations/NavigationHeder";
import {
  areObjectsDifferent,
  getPopupMessageBasedOnStatus,
} from "../../../inc/Validation";
import AddressList from "../../Tenant/Address/AddressList";
import Input from "../../Forms/Input";
import SimpleLoading from "../../Loading/SimpleLoading";
import Collapse from "../../inc/Collapse";
import FormValidator from "../../../inc/FormValidator";
import Gui_id_list from "../../../inc/Gui_id_list";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Alert from "../../inc/Alert";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

// Bank Creation - EDIT MODE - SYSTEM - LYNKAZ
let defaultBankObj = null;

const EditBanks = (props) => {
  const [editBank, setEditBank] = useState({});
  const [loading, setLoading] = useState(false);

  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);

  const { id } = useParams();

  let isFirstCalled = false; 
 
  useEffect(() => { getSystemBanks() }, []);

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  let validator;

  const validationConfigure = () => {
    let fieldConfig = [
      {
        name: "name",
        displayName: Helper.getLabel(props.language, "3004004", "Name"),
        types: ["Required"],
      },
    ];
    if (!validator) {
      validator = new FormValidator(fieldConfig, props.language);
    }
  };

  validationConfigure();

  // Get API Calls --

  const getSystemBanks = () => {
    if (isFirstCalled) {
      return;
    }
    isFirstCalled = true;
    setLoading(true);
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + `/bank/${id}`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          defaultBankObj = res.data.data;
          setEditBank(res.data.data);
          setLoading(false);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
        setLoading(false);
      });
  };

  const NameChangeHandler = (event) => {
    let Name = event.target.value;
    setEditBank((prevState) => {
      return { ...prevState, name: Name };
    });
  };
  const CodeChangeHandler = (event) => {
    let Code = event.target.value;
    setEditBank((prevState) => {
      return { ...prevState, code: Code };
    });
  };

  const BankModule = () => {
    return (
      <Collapse
        title={Helper.getLabel(props.language, "3002002", "General")}
        open={true}
      >
        <div className="row mb-3">
          <div className="col-lg-2">
            <Input
              maxLength={20}
              label={Helper.getLabel(props.language, "3003003", "Code")}
              hasError={validator.hasError("code")}
              placeholder="Enter Code"
              name="text"
              value={editBank.code}
              onChange={CodeChangeHandler}
            />
          </div>

          <div className="col-lg-3">
            <Input
              maxLength={80}
              label={Helper.getLabel(props.language, "3004004", "Name")}
              required={true}
              hasError={validator.hasError("name")}
              placeholder="Enter Bank Name"
              name="text"
              value={editBank.name}
              onChange={NameChangeHandler}
            />
          </div>
        </div>
      </Collapse>
    );
  };

  const AddressCreate = (data) => {
    if (editBank.addresssource_id !== null) {
      return;
    }
    setEditBank({
      ...editBank,
      addresssource_id: data?.address_source_id
    });
  };

  const SaveHandler = () => {
    if (!areObjectsDifferent(defaultBankObj, editBank)) {
      return;
    }
    let newData = {
      name: editBank.name, 
    };
    let validData = {
      ...newData,
    };
    if (!validator.isValid(validData)) {
      validator.displayMessage();
      validator.reset();
      return;
    }

    setLoading(true);

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(Settings.apiOrgUrl + `/bank/${id}`, {
        bank_id: id,
        code: editBank.code !== "" ? editBank.code : null,
        addresssource_id: editBank?.addresssource_id,
        name: editBank.name !== "" ? editBank.name : null,
      })
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          getSystemBanks();
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
        setLoading(false)
      });
  };

  let security = props.security;
  let frontendIds = Gui_id_list;
  if (!security.canView(Gui_id_list.setupForms.banks.bank_edit_screen)) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader isPopupInTab={true} /> : null }
      <div className={loading ? 'scrollBarBlinkControl' : 'container-fluid'}>
        <div ref={wrapperDiv}>
          <NavigationHeder
            hideMoreBtn={true}
            title={`<span style="color:#000;">${Helper.getLabel(
              props.language,
              "3009009",
              "Edit Bank"
            )} #</span>  ${editBank?.name}`}
          >
            <LinkBtn
              isActive={false}
              to="/banks"
              className='black-btn-style'
              title={Helper.getLabel(props.language, "000001", "Close")}
            />
            <Button
              isDisable={
                !security.canEdit(
                  frontendIds.setupForms.banks.bank_edit_screen_save_button
                )
              }
              isActive={false}
              onClick={SaveHandler}
              title={Helper.getLabel(props.language, "000003", "Save")}
              className="rfq_save_btn"
            />
          </NavigationHeder>
        </div>

        { loading ? null : 
          <div className='edit-banks-section common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
            {BankModule()} 
            <AddressList
              onAddressCreate={AddressCreate} 
              addressSourc_id={ editBank?.addresssource_id } 
              address_source_integrator={id} 
              source_id={8} 
            />  
          </div> 
        }
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.setupForms.banks.bank_edit_screen,
};

export default connect(mapStateToProps)(
  MasterComponentWraper(ApplySecurityRoles(EditBanks, SecurityOptions))
);