import React, { Component } from 'react';
import ActionsHistory from './ActionsHistory/ActionsHistory';
import FileUploaderList from './FileUpload/FileUploaderList';
import UploaderWithList from './FileUpload/UploaderWithList';
import Notes from './Notes/Notes';
import $ from 'jquery';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { connect } from 'react-redux';
/**
 * source_id ={18} 
 * notesource_integrator= {59}  
 * attachmentsource_integrator ={59}
 */
class NoteAttachmentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeComponent: this.props.hideNotes ? 'attachment' : 'notes',
            isNewInternalAttachments: false,
            isNewExternalAttachments:false,
            isNewInternalNotes:false,
            isNewExternalNotes:false
        };
    }

    componentDidMount() {
        this.fixWidgetHeight();
        this.loadFiles("isNewInternalAttachments",0)
        this.loadFiles("isNewExternalAttachments",1)
        this.loadNotes("isNewInternalNotes",0)
        this.loadNotes("isNewExternalNotes",1)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.historyArray !== this.props.historyArray) {
            this.fixWidgetHeight();
        }
    }

    fixWidgetHeight() {
        let minHeight = $('.verticle_items').outerWidth();
        $('.verticle_items').css({ translate: ' 0 ' + minHeight + 'px' });
        $('.note_attachment_history_widget').css({
            'min-height': minHeight + 30 + 'px',
        });
    }

    loadFiles(attachmentState,attachmentFor){
        const api = Api;
        api.setUserToken();
        const { attachmentsource_integrator, source_id} = this.props;
        if(attachmentFor===0 && this?.props?.auth?.user?.usertype===1) return
        if(!attachmentsource_integrator) return

        let apiUrl

        if (attachmentsource_integrator && source_id) {
            apiUrl = Settings.apiUrl + `/get_attachments/${source_id}/${attachmentsource_integrator}?attachment_for=${attachmentFor}`;
        }

        api.axios()
            .get(apiUrl)
            .then((res) => {
                if (res.data.status === 'success') {
                    const fetchedData = res.data;
                    this.setState({
                        [attachmentState]:fetchedData?.new_attachment_added
                    })
                }
            })
            .catch(() => {
            });
    };

    loadNotes(noteState,notesFor){
        const api = Api;
        api.setUserToken();
        const { attachmentsource_integrator, source_id} = this.props;
        if(notesFor===0 && this?.props?.auth?.user?.usertype===1) return
        if(!attachmentsource_integrator) return

        let apiUrl

        if (attachmentsource_integrator && source_id) {
            apiUrl = Settings.apiUrl + `/note/${source_id}/${attachmentsource_integrator}?note_type=${notesFor}`;
        }

        api.axios()
            .get(apiUrl)
            .then((res) => {
                if (res.data.status === 'success') {
                    const fetchedData = res.data.data;
                    this.setState({
                        [noteState]:fetchedData?.new_note_added
                    })
                }
            })
            .catch(() => {
            });
    };

    setActiveComponent(e, activeComponent) {
        this.setState({
            activeComponent: activeComponent,
        });
    }

    setNewAttachments(isNewAttachmentState,isNewAttachments) {
        this.setState({
            [isNewAttachmentState]: isNewAttachments,
        });
    }

    setNewNotes(isNewNotesState,isNewNotes) {
        this.setState({
            [isNewNotesState]: isNewNotes,
        });
    }

    displayComponent() {
        switch (this.state.activeComponent) {
            case 'notes':
                return !this.props.hideNotes && <Notes notesHeight={this.props?.height?(this.props?.height-120):380} isCustomHeight={this.props.isCustomHeight} hideVendorPortal={this.props.hideVendorPortal} notesource_integrator={this.props.notesource_integrator} source_id={this.props.source_id} exportable={!this.props.notesource_integrator} updateNewNotes={(isNewNotesState,isNewNotes)=>{this.setNewNotes(isNewNotesState,isNewNotes)}} isNewExternalNotes={this.state.isNewExternalNotes} isNewInternalNotes={this.state.isNewInternalNotes} />;
            case 'attachment':
                return <UploaderWithList disableAttachments={this.props?.disableAttachments} hideVendorPortal={this.props.hideVendorPortal} source_id={this.props.source_id} attachmentsource_integrator={this.props.attachmentsource_integrator} condition={this.props.condition} hideUploaderCondition={this.props.hideUploaderCondition} updateNewAttachments={(isNewAttachmentState,isNewAttachments)=>{this.setNewAttachments(isNewAttachmentState,isNewAttachments)}} isNewExternalAttachments={this.state.isNewExternalAttachments} isNewInternalAttachments={this.state.isNewInternalAttachments}/>;
            case 'action_history':
                return <ActionsHistory actionHistoryHeight={this.props?.height?(this.props?.height-190):380} actionHistoryLoadingState={this.props.actionHistoryLoadingState} setActionHistoryState={this.props.setActionHistoryState} showActionHistoryDropdown={this.props.showActionHistoryDropdown} openCurrentAssignees={this.props.openCurrentAssignees} showCanvas={this.props.openCanvas} historyArray={this.props.historyArray} />;
            default:
                break;
        }
    }

    render() {
        let heights = this.props.height;
        
        return (
            <div className='note_attachment_history_widget'>
                <div className='verticle_items'>
                    {!this.props.hideNotes && <span>Notes</span>}
                    {!this.props.hideAttachments && <span>Attachment</span>}
                    {!this.props.hideHistory && <span>Action History</span>}
                </div>
                <div className='note_attachment_history_widget_inner'>
                    <div className='note-attchement-style'>
                        <ul className={`menu_style_2 ${this.props.hideHistory ? 'menu_style_3' : ''}`}>
                            {!this.props.hideNotes && <li className={this.state.activeComponent === 'notes' ? 'active  d-flex align-items-center gap-1 justify-content-center' : 'd-flex align-items-center gap-1 justify-content-center'} onClick={e => this.setActiveComponent(e, 'notes')}>Notes {(this.state.isNewInternalNotes || this.state.isNewExternalNotes) && <div className='attachment-module-highlighter' style={{background:this.state.activeComponent === 'notes'?"#ffffff":"#FC5E24"}}></div>}</li>}
                            {!this.props.hideAttachments && <li className={this.state.activeComponent === 'attachment' ? 'active d-flex align-items-center gap-1 justify-content-center' : 'd-flex align-items-center gap-1 justify-content-center'} onClick={e => this.setActiveComponent(e, 'attachment')}>Attachment {(this.state.isNewInternalAttachments || this.state.isNewExternalAttachments) && <div className='attachment-module-highlighter' style={{background:this.state.activeComponent === 'attachment'?"#ffffff":"#FC5E24"}}></div>}</li>}
                            {!this.props.hideHistory && <li className={this.state.activeComponent === 'action_history' ? 'active' : ''} onClick={e => this.setActiveComponent(e, 'action_history')}>Action History</li>}
                        </ul>
                    </div>
                    <div
                        className='widget_contents note-tab-wraper'
                        style={{
                            transition: '0.6s',
                            maxHeight: `${heights?(heights-120):380}px`,
                            overflow: this.state?.activeComponent === 'attachment' ? 'scroll' : 'hidden'
                        }}
                    >
                        {this.displayComponent()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
    }
}

export default connect(mapStateToProps) (NoteAttachmentHistory);