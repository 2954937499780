import React, { useState, useEffect, Fragment, useRef, useCallback } from "react";
import { connect } from "react-redux";
import Gui_id_list from "../../inc/Gui_id_list";
import Input from "../Forms/Input";
import DropDownInput from "../../components/Forms/Dropdown";
import Button from "../inc/Button";
import Collapse from "../inc/Collapse";
import LinkBtn from "../inc/LinkBtn";
import SimpleLoading from "../Loading/SimpleLoading";
import NavigationHeder from "../Navigations/NavigationHeder";
import ApplySecurityRoles from "../SecurityRoles/ApplySecurityRoles";
import Tabs from "../Widgets/Tabs/Tabs";
import Checkbox from "../Forms/Checkbox";
import AgGrid from "../grid/ag/ag-grid";
import $ from "jquery";
import Alert from "../inc/Alert";
import Settings from "../../inc/Settings";
import Api from "../../inc/Api";
import { useParams } from "react-router-dom";
import Helper from "../../inc/Helper";
import {
  checkIfArrayIsEmpty,
  formatTime,
  getPopupMessageBasedOnStatus,
  is_valid_input,
} from "../../inc/Validation";
import MasterComponentWraper from "../Backend/MasterComponentWraper";
import InputTimePicker from "../Forms/InputTimePicker";
import SegmentsDistribution from "../Segments/SegmentsDistribution";
import AgGridNew from "../grid/ag/ag-grid-new";
import SimpleDropdown from "../grid/ag/cellEditor/SimpleDropdown";
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from "../grid/ag/commonFunctions/GridCommonFunctions";
import OverlayLoader from "../PurchaseRequisition/common/OverlayLoader";
import Dropdown from "../../components/Forms/Dropdown";

// Procurement Settings Screen for PO, RFQ, RFI, RFx, Edit Mode,

let default_quoation_expiry_days = null;
let currentSearchTerm = "";
let gridApiTeamHeader = null;
let gridApiNumberSequence = null;
let AddBtn ;
let transaction ;
let AddBtnSequence ;
let transactionSequence ;

const ParametersEdit = (props) => {
  const { id } = useParams();
  const parentEntitiesData = useRef([]);
  const parentCategoryData = useRef([]); 
  const [getSettings, setGetSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [entityLoading, setEntityLoading] = useState(false);
  const [nsLoading, setNsLoading] = useState(false); 
  const [teamHeaderList, setTeamHeaderList] = useState([]);
  const [entityArr, setEntityArr] = useState([]);
  const [deleteEntityArr, setDeleteEntityArr] = useState([]);
  const [entityNS, setEntityNS] = useState('');

  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);
  //Dropdowns State

  const [modeOfDelivery, setModeOfDelivery] = useState([]);
  const [deliveryItem, setDeliveryItem] = useState([]);
  const [taxGroup, setTaxGroup] = useState([]);
  const [itemTaxGroup, setItemTaxGroup] = useState([]);
  const [defaultQuoSecurityLevels, setDefaultQuoSecurityLevels] = useState([]);
  const [defaultQuoType, setDefaultQuoType] = useState([]);
  const [defaultCollabTeam, setDefaultCollabTeam] = useState([]);
  const [procurementCategory, setProcurementCategory] = useState([]);
  const [transactionDiscountGP, setTransactionDiscountGP] = useState([]);
  const [transactionRoundingGP, setTransactionRoundingGP] = useState([]);
  const [altItemGroup, setAltItemGroup] = useState([]);
  const [defaultVendorGroup, setDefaultVendorGroup] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState([]);
  const [defaultItemGroup, setDefaultItemGroup] = useState([]);
  const [document, setDocument] = useState([]);
  const [numberSequence, setNumberSequence] = useState([]);
  const [matchLevel, setMatchLevel] = useState([]);
  const [receiptAction, setReceiptAction] = useState([]);
  const [receiptNumber, setReceiptNumber] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [fiscalCalender,setFiscalCalendar]=useState([])
  const [vendorGroup,setVendorGroup]=useState([])
  const [vendorGroupPrePay,setVendorGroupPrePay]=useState([])
  const [defaultRfqStages,setDefaultRfqStages]=useState([])
  const [dynamicTeams,setDynamicTeams]=useState([])

  //End

  // API Calls Reduction
  let settingsCalled = false;  
  let TeamHeaderListCalled = false;
  let modeOfDeliveryCalled = false;
  let deliveryItemCalled = false;
  let taxGroupCalled = false;
  let itemTaxGroupCalled = false;
  let defaultQuotationSecurityLevel = false;
  let defaultQuotationTypeCalled = false;
  let procurementCategoryCalled = false;
  let transactionGroupCalled = false;
  let defaultCollabTeamCalled = false;
  let documentCalled = false;
  let numberSequenceCalled = false;
  let matchLevelCalled = false;
  let defaultVendorGroupCalled = false;
  let defaultCurrencyCalled = false;
  let receiptActionCalled = false;
  let receiptNumberCalled = false;
  let entityListCalled = false;
  let fiscalCalendarCalled = false
  let vendorGroupCalled = false
  let vendorGroupPrePaymentCalled = false
  let rfqStagesCalled = false
  //End

  // API Calls on-Renders/Loadings
  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  useEffect(() => {
    ModeOfDeliveryHandler();
    DeliveryItemHandler();
    TaxGroupHandler();
    DefaultQuotationSecurityLevelHandler();
    DefaultQuotationTypeHandler();
    DefaultCollabTeamAPI();
    ItemTaxItemGroupHandler(); 
    NumberSequenceHandler();
    DefaultVendorGroupAPI();
    DocumentHandler();
    MatchLevelHandler();
    ReceiptActionHandler();
    CheckProductReceiptNumberHandler();
    ProcurementCategoryAPI();
    getEntitiesList(); 
    TeamHeaderAPI();
    getProcureSettings();
    getTransactionGroups(); 
    DefaultCurrenciesAPI();
    FiscalCalendarAPI();
    VNGroupPrePaymentAPI()
    VNGroupAPI()
    DefaultRfqStages()
    DynamicTeams()
  }, []);
  
  // GET Calls

  const getProcureSettings = () => {
    if (settingsCalled) {
      return;
    }
    setLoading(true);
    let api = Api;
    settingsCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiProcurementSettingsUrl + `/procurementsetting/${id}`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_data = {
            ...res.data.data,
            default_quoation_expiry_times:
              res.data.data.default_quoation_expiry_times !== "None"
                ? formatTime(res.data.data.default_quoation_expiry_times)
                : null,
          };
          default_quoation_expiry_days =
            res.data.data.default_quoation_expiry_days;
          setGetSettings(UPDATED_data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }).catch((err) => {
        getPopupMessageBasedOnStatus(err)
        setLoading(false);
      })
  };
  
  const TeamHeaderAPI = () => {
    if (TeamHeaderListCalled) {
      return;
    }
    let api = Api;
    TeamHeaderListCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/teamheader", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let teamHeaderARR = [];
          res.data.data.forEach((item) => {
            teamHeaderARR.push({
              value: item.teamheader_id,
              label: item.name,
            });
          });
          setTeamHeaderList(teamHeaderARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  //End

  //Dropdowns Functions

  const getEntitiesList = () => {
    if (entityListCalled) {
      return;
    }
    let api = Api;
    setEntityLoading(true);
    entityListCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/get_entities", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.entity_id,
              label: `${element.name}`,
            });
          });
          setEntityLoading(false);
          setEntityList(UPDATED_STATE);
        }
      })
      .catch((err) => {
        setEntityLoading(false);
      });
  };

  const ModeOfDeliveryHandler = () => {
    if (modeOfDeliveryCalled) {
      return;
    }
    let api = Api;
    modeOfDeliveryCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + "/mode_of_delivery", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let modeOfDeliveryARR = [];
          res.data.data.forEach((item) => {
            modeOfDeliveryARR.push({
              value: item.mode_of_delivery_id,
              label: item.name,
            });
          });
          setModeOfDelivery(modeOfDeliveryARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DeliveryItemHandler = () => {
    if (deliveryItemCalled) {
      return;
    }
    let api = Api;
    deliveryItemCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + "/delivery_term", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let DeliveryItemARR = [];
          res.data.data.forEach((item) => {
            DeliveryItemARR.push({
              value: item.delivery_term_id,
              label: item.name,
            });
          });
          setDeliveryItem(DeliveryItemARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const TaxGroupHandler = () => {
    if (taxGroupCalled) {
      return;
    }
    let api = Api;
    taxGroupCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/taxgroup", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let taxGroupARR = [];
          res.data.data.forEach((item) => {
            taxGroupARR.push({
              value: item.taxgroup_id,
              label: item.name,
            });
          });
          setTaxGroup(taxGroupARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const ItemTaxItemGroupHandler = () => {
    if (itemTaxGroupCalled) {
      return;
    }
    let api = Api;
    itemTaxGroupCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/taxgroupitem", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let itemTaxGroups = [];
          res.data.data.forEach((item) => {
            itemTaxGroups.push({
              value: item.taxgroupitem_id,
              label: item.name,
            });
          });
          setItemTaxGroup(itemTaxGroups);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DefaultQuotationSecurityLevelHandler = () => {
    if (defaultQuotationSecurityLevel) {
      return;
    }
    let api = Api;
    defaultQuotationSecurityLevel = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/enum/RQ_securitylevel", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let QuoSecurityLevelsARR = [];
          res.data.data.forEach((item) => {
            QuoSecurityLevelsARR.push({
              value: item.id,
              label: item.name,
            });
          });
          setDefaultQuoSecurityLevels(QuoSecurityLevelsARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DefaultQuotationTypeHandler = () => {
    if (defaultQuotationTypeCalled) {
      return;
    }
    let api = Api;
    defaultQuotationTypeCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/enum/RQ_type", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let QuoTypeARR = [];
          res.data.data.forEach((item) => {
            QuoTypeARR.push({
              value: item.id,
              label: item.name,
            });
          });
          setDefaultQuoType(QuoTypeARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DefaultCollabTeamAPI = () => {
    if (defaultCollabTeamCalled) {
      return;
    }
    let api = Api;
    defaultCollabTeamCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/teamheader", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let DefaultCollabARR = [];
          res.data.data.forEach((item) => {
            DefaultCollabARR.push({
              value: item.teamheader_id,
              label: item.name,
            });
          });
          setDefaultCollabTeam(DefaultCollabARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const ProcurementCategoryAPI = () => {
    if (procurementCategoryCalled) {
      return;
    }
    let api = Api;
    procurementCategoryCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/categories", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let procurementARR = [];
          res.data.data.forEach((item) => {
            procurementARR.push({
              value: item.category_id,
              label: item.name,
              label: item.name,
            });
          });
          setProcurementCategory(procurementARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const getTransactionGroups = () => {
    if (transactionGroupCalled) {
      return;
    }
    let api = Api;
    transactionGroupCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiItemUrl + "/item_group", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let Item_Groups = [];
          res.data.data.forEach((item) => {
            Item_Groups.push({
              value: item.itemgroup_id,
              label: item.name,
            });
          });
          setTransactionDiscountGP(Item_Groups);
          setTransactionRoundingGP(Item_Groups);
          setAltItemGroup(Item_Groups);
          setDefaultItemGroup(Item_Groups);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DocumentHandler = () => {
    if (documentCalled) {
      return;
    }
    let api = Api;
    documentCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/source", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let documentsARR = [];
          res.data.data.forEach((item) => {
            documentsARR.push({
              value: item.source_id,
              label: item.source_name,
            });
          });
          setDocument(documentsARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const NumberSequenceHandler = () => {
    if (numberSequenceCalled) {
      return;
    }
    let api = Api;
    numberSequenceCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/numbersequence", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let numberSeqARR = [];
          res.data.data.forEach((item) => {
            numberSeqARR.push({
              value: item.sequencenumber_id,
              label: item.name,
            });
          });
          setNumberSequence(numberSeqARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DefaultVendorGroupAPI = () => {
    if (defaultVendorGroupCalled) {
      return;
    }
    let api = Api;
    defaultVendorGroupCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiVendorUrl + "/vendorgroup", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let DefaultVendorGroups = [];
          res.data.data.forEach((item) => {
            DefaultVendorGroups.push({
              value: item.vendorgroup_id,
              label: item.name,
            });
          });
          setDefaultVendorGroup(DefaultVendorGroups);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DefaultCurrenciesAPI = () => {
    if (defaultCurrencyCalled) {
      return;
    }
    let api = Api;
    defaultCurrencyCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/currency", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let DefaultCurrency = [];
          res.data.data.forEach((item) => {
            DefaultCurrency.push({
              value: item.currency_id,
              label: `${item.currency_code} - ${item.currency_name}`,
            });
          });
          setDefaultCurrency(DefaultCurrency);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const MatchLevelHandler = () => {
    if (matchLevelCalled) {
      return;
    }
    let api = Api;
    matchLevelCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/enum/matchlevel", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let matchLevelDATA = [];
          res.data.data.forEach((item) => {
            matchLevelDATA.push({
              value: item.id,
              label: item.name,
            });
          });
          setMatchLevel(matchLevelDATA);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const ReceiptActionHandler = () => {
    if (receiptActionCalled) {
      return;
    }
    let api = Api;
    receiptActionCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/enum/overreceiptaction", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let receiptActionARR = [];
          res.data.data.forEach((item) => {
            receiptActionARR.push({
              value: item.id,
              label: item.name,
            });
          });
          setReceiptAction(receiptActionARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const CheckProductReceiptNumberHandler = () => {
    if (receiptNumberCalled) {
      return;
    }
    let api = Api;
    receiptNumberCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/enum/checkproductreceiptnumber", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let receiptNumberARR = [];
          res.data.data.forEach((item) => {
            receiptNumberARR.push({
              value: item.id,
              label: item.name,
            });
          });
          setReceiptNumber(receiptNumberARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const DefaultRfqStages = () => {
    if(rfqStagesCalled){
      return;
    }
    let api = Api
    rfqStagesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/RFQStages',{}).then(function(res){
      if(res.data.status === "success"){
        let RfqStagesArr = []
        res.data.data.forEach(item => {
        RfqStagesArr.push({
          value: item.id,
          label: item.name
        })
        })
        setDefaultRfqStages(RfqStagesArr)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }


  const FiscalCalendarAPI = () => {
    if(fiscalCalendarCalled){
      return;
    }
    let api = Api
    fiscalCalendarCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/fiscalcalendar',{}).then(function(res){
      if(res.data.status === "success"){
        let FiscalArr = []
        res.data.data.forEach(item => {
        FiscalArr.push({
          value: item.fiscalcalendar_id,
          label: item.description
        })
        })
        setFiscalCalendar(FiscalArr)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  } 

  const VNGroupAPI = () => {
    if(vendorGroupCalled){
      return;
    }
    let api = Api
    vendorGroupCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{}).then(function(res){
      if(res.data.status==="success"){
        let VendorGroup = []
        res.data.data.forEach(item => {  
        VendorGroup.push({
          value: item.vendorgroup_id,
          label: item.name 
        })
        }) 
        setVendorGroup(VendorGroup)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const VNGroupPrePaymentAPI = () => {
    if(vendorGroupPrePaymentCalled){
      return;
    }
    let api = Api
    vendorGroupPrePaymentCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{ }).then(function(res){
      if(res.data.status==="success"){
        let vendorGroupPrePayment = []
        res.data.data.forEach(item => {  
        vendorGroupPrePayment.push({
          value: item.vendorgroup_id,  
          label: item.name  
        })
        }) 
        setVendorGroupPrePay(vendorGroupPrePayment)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  } 

  const DynamicTeams = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/teamheader?dynamic_team_header=true", {})
      .then(function (res) {
        if (res.data.status === "success") {
          let dynamicTeamARR = [];
          res.data.data.forEach((item) => {
            dynamicTeamARR.push({
              value: item.teamheader_id,
              label: item.name,
            });
          });
          setDynamicTeams(dynamicTeamARR);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  //End

  // mapping functions,

  const mapApiEntitiesItems = (item) => {
    return {
      id: item.entity_id || "null",
      text: item.name,
    };
  };

  const mapCategoryItems = (item) => {
    return {
      id: item.category_id,
      text: item.name,
    };
  };

  //--

  //STATES Dropdowns
  const NameHandler = (event) => {
    let name = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        name: name,
      };
    });
  };

  const DeliveryModeHandler = (event) => {
    let mode_devl = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        mode_of_delivery_id: mode_devl,
      };
    });
  };

  const DeliveryTermHandler = (event) => {
    let term_devr = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        delivery_term_id: term_devr,
      };
    });
  };

  const TaxGroupChangeHandler = (event) => {
    let tax_id = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        taxgroup_id: tax_id,
      };
    });
  };

  const TaxItemGroupHandler = (event) => {
    let taxCode = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        taxgroupitem_id: taxCode,
      };
    });
  };

  const DefaultQuoTypeHandler = (event) => {
    let defaultQuo = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_quoation_type: defaultQuo,
      };
    });
  };

  // const defaultQuoSecurityHandler = (event) => {
  //   let defaultQuoSecurity = event.target.value;
  //   setGetSettings((prevState) => {
  //     return {
  //       ...prevState,
  //       default_quoation_security_level: defaultQuoSecurity,
  //     };
  //   });
  // };

  const DefaultQuotationExpiryTimeHandler = (name, value) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_quoation_expiry_times: value,
      };
    });
  };

  const DefaultCategoryForComments = (event) => {
    let procure_comments_type = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_category_for_comments_type_for_integration: procure_comments_type
      };
    });
  }

  const ProcurementHandler = (event) => {
    let procure = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_procurement_cateory: procure,
      };
    });
  };

  const TransactionDiscountGPHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        transaction_discount_group: event.target.value,
      };
    });
  };

  const TransactionRoundingGPHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        transaction_rounding_group: event.target.value,
      };
    });
  };

  const AltItemGroupHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        alternate_item_comments_item_group: event.target.value,
      };
    });
  };

  const DefaultItemHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_itemgroup_for_miscellaneouscharges: event.target.value,
      };
    });
  };

  const DefaultEntityForVendorRegistrationHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_entity_for_vendor_registration: event.target.value,
      };
    });
  };

  const DefaultGroupForVendorRegistrationHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_group_for_vendor_registration: event.target.value,
      };
    });
  };

  const DefaultCurrency = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        currency_id: event.target.value,
      };
    });
  };

  const DefaultRfqStagesChange = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        rfq_stages: event.target.value,
      };
    });
  };

  const FiscalHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        fiscalcalendar_id: event.target.value,
      };
    });
  };

  const VendorGroupHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        vendorgroup_id: event.target.value,
      };
    });
  };

  const VendorGroupPrePayHandler = (event) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        vendorgroup_prepayment_id: event.target.value,
      };
    });
  };

  const BlindQuantity = (name, value) => {
    if (value == "on") {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          blind_receiving_of_Qty: true,
        };
      });
    } else {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          blind_receiving_of_Qty: false,
        };
      });
    }
  };

  const BlindItems = (name, value) => {
    if (value == "on") {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          blind_receiving_of_item: true,
        };
      });
    } else {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          blind_receiving_of_item: false,
        };
      });
    }
  };

  const DefaultSettings = (name, value) => {
    if (value == "on") {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          is_default: true,
        };
      });
    } else {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          is_default: false,
        };
      });
    }
  };

  const ActiveCharge = (name, value) => {
    if (value == "on") {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          activate_change_management: true,
        };
      });
    } else {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          activate_change_management: false,
        };
      });
    }
  };

  const AcceptUnderHandler = (name, value) => {
    if (value == "on") {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          accept_under_delivery: true,
        };
      });
    } else {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          accept_under_delivery: false,
        };
      });
    }
  };

  const AcceptOverHandler = (name, value) => {
    if (value == "on") {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          accept_over_delivery: true,
        };
      });
    } else {
      setGetSettings((prevState) => {
        return {
          ...prevState,
          accept_over_delivery: false,
        };
      });
    }
  };

  const OverReceiptAction = (event) => {
    let receiptAction = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        over_receipt_action: receiptAction,
      };
    });
  };

  const CheckProductNumberHandler = (event) => {
    let productNum = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        check_product_receipt_number: productNum,
      };
    });
  };

  const MatchLevel = (event) => {
    let matchLevel = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        match_level: matchLevel,
      };
    });
  };

  const onInputChangeHandler = (name, value) => {
    setGetSettings((prevState) => {
      return {
        ...prevState,
        [name]: value == "on",
      };
    });
  };

  const ExpiryDaysHandler = (event) => {
    let inputValue = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_quoation_expiry_days: inputValue,
      };
    });
  };

  const ExpiryOTPSecondsHandler = (event) => {
    let inputVal = event.target.value;
    if (isNaN(inputVal)) {
      Helper.alert("Please provide numeric value!", "error");
      return;
    }
    setGetSettings((prevState) => {
      return {
        ...prevState,
        otp_expiry_in_seconds: inputVal,
      };
    });
  };

  const DefaultRFQCollabTeam = (event) => {
    let RFQCollab = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        default_RFQ_collaboration_team: RFQCollab,
      };
    });
  };

  const DefaultDynamicTeamHandler = (event) => {
    let team_value = event.target.value;
    setGetSettings((prevState) => {
      return {
        ...prevState,
        [event.target.name]: team_value,
      };
    });
  };

  const CheckBoxHandler = (entityId, value) => {
    if (value === "off") {
      // Remove entityId from entityArr
      setEntityArr(
        entityArr.filter((id) => parseInt(entityId) !== parseInt(id))
      );

      // Add entityId to deleteEntityArr if it is present in getSettings.entities
      if (
        getSettings.entities.includes(parseInt(entityId)) &&
        !deleteEntityArr.includes(parseInt(entityId))
      ) {
        setDeleteEntityArr([...deleteEntityArr, parseInt(entityId)]);
      }
    } else {
      // Remove entityId from deleteEntityArr
      setDeleteEntityArr(
        deleteEntityArr.filter((id) => parseInt(entityId) !== parseInt(id))
      );

      // Add entityId to entityArr if not already present and not in getSettings.entities
      if (
        !deleteEntityArr.includes(parseInt(entityId)) &&
        !entityArr.includes(parseInt(entityId)) &&
        !getSettings.entities.includes(parseInt(entityId))
      ) {
        setEntityArr([...entityArr, parseInt(entityId)]);
      }
    }
  };

  const entityDropdownHandler = (event) => {
    setNsLoading(true);
    setEntityNS(event.target.value);
    setTimeout(() => {
      setNsLoading(false);
    }, 200);
  };

  const DeleteAssignedEntity = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    try {
      const res = await api
        .axios()
        .delete(Settings.apiProcurementSettingsUrl + `/numberingsequence/${element?.data?.record_id}`)
      const rowNode = gridApiNumberSequence?.getRowNode(element?.data?.record_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApiNumberSequence?.applyServerSideTransaction(transaction)
      }, 200);

      gridApiNumberSequence?.refreshServerSide({ purge: true })
      gridApiNumberSequence?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []); 

  const DeleteLinkedTeamHeader = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    try {
      const res = await api
        .axios()
        .delete(Settings.apiProcurementSettingsUrl + `/procurementteamheader/${id}/${element?.data?.teamheader_id}`)
      const rowNode = gridApiTeamHeader?.getRowNode(element?.data?.teamheader_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApiTeamHeader?.applyServerSideTransaction(transaction)
      }, 200);

      gridApiTeamHeader?.refreshServerSide({ purge: true })
      gridApiTeamHeader?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  const deleteEntitiesofSettings = () => {
    if (checkIfArrayIsEmpty(deleteEntityArr)) return;
    deleteEntityArr.forEach((element) => {
      let api = Api;
      api.setUserToken();
      api
        .axios()
        .delete(
          Settings.apiProcurementSettingsUrl +
            `/procurementsetting_entity/${id}/${element}`,
          {}
        )
        .then(function (res) {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res);
        });
    });
  };

  const addEntitiesToSettings = () => {
    if (checkIfArrayIsEmpty(entityArr)) return;
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .post(Settings.apiProcurementSettingsUrl + "/procurementsetting_entity", {
        procurementsetting_id: id,
        entity_ids: entityArr,
      })
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const onRowValueChangedNumberSequence = (params) => {
    if(params?.data?.record_id) {
      updateDataNS(params);
    } else {
      addNewDataNS(params);
    }
  }
 
  const addNewDataNS = async (event) => {
    try {
      let payload = {
        source_id: parseInt(event?.data?.source_id),
        sequence_id: parseInt(event?.data?.sequence_id),
        entity_id: entityNS ? entityNS : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiProcurementSettingsUrl + "/numberingsequence", payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiNumberSequence?.refreshServerSide({ purge: true });
          gridApiNumberSequence?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtnSequence) {
            gridApiNumberSequence?.applyServerSideTransaction(transactionSequence);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const updateDataNS = async (event) => {
    try {
      let payload = {
        source_id: parseInt(event?.data?.source_id),
        sequence_id: parseInt(event?.data?.sequence_id),
        entity_id: entityNS ? entityNS : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiProcurementSettingsUrl + `/numberingsequence/${event?.data?.record_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiNumberSequence?.refreshServerSide({ purge: true });
          gridApiNumberSequence?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  };

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const handleBtnNumberSequence = (value, transactionData) => {
    AddBtnSequence = value
    transactionSequence = transactionData
  } 

  const onRowValueChanged = (params) => {
    if(!params?.data?.tenant_id) {
      addNewDataTeamHeader(params)
    }
  }

  const addNewDataTeamHeader = async (event) => {
    try {
      let payload = {
        procurementsetting_id: id,
        teamheader_id: parseInt(event?.data?.teamheader_id),
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiProcurementSettingsUrl + "/procurementteamheader", payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiTeamHeader?.refreshServerSide({ purge: true });
          gridApiTeamHeader?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApiTeamHeader?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
 
  const DefaultNavs = [
    {
      title: Helper.getLabel(props.language, "default", "Default"),
      isActive: true,
    },
    { title: Helper.getLabel(props.language, "controls", "Controls") },
    {
      title: Helper.getLabel(props.language, "entity", "Entity"),
      forceLoadContents: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        "numbering_sequence",
        "Numbering Sequence"
      ),
      forceLoadContents: true,
    },
    {
      title: Helper.getLabel(props.language, "team_header", "Team Header"),
      forceLoadContents: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        "segment_distribution",
        "Segment Distribution"
      ),
      forceLoadContents: true,
    },
  ];
  
  const Columns_Headings = [
    {
      field: 'teamheader_id',
      headerName: Helper.getLabel(props.language, "team_header", "Team Header"),
      editable: (event) => !event?.data?.tenant_id,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: {
        values: teamHeaderList
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, teamHeaderList),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      },
    }
  ];

  const Columns_Headings_NS = [ 
    {
      field: 'source_id',
      headerName: Helper.getLabel(props.language, "document", "Document"),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: {
        values: document
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, document),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      },
    },
    {
      field: 'sequence_id',
      headerName: Helper.getLabel( props.language, "number_sequence", "Number Sequence" ),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: {
        values: numberSequence
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, numberSequence),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      },
    }
  ];

  const CreateDistributions = (distribution_source_id) => {
    if (!distribution_source_id) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(Settings.apiProcurementSettingsUrl + `/procurementsetting/${id}`, {
        distributionsource_id: distribution_source_id,
      })
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          getProcureSettings();
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  let saveButtonTimeout = null;
  const SaveHandler = () => {
    clearTimeout(saveButtonTimeout);

    saveButtonTimeout = setTimeout(function () {
      if (!getSettings.name) {
        Helper.alert("Name field missing", "error");
        return;
      }
      if (
        default_quoation_expiry_days != getSettings.default_quoation_expiry_days
      ) {
        if (!is_valid_input(getSettings.default_quoation_expiry_days)) {
          Helper.alert("Default Expiry Days Invalid!", "error");
          return;
        }
      } 
      deleteEntitiesofSettings();
      addEntitiesToSettings();
      let api = Api;
      api.setUserToken();
      api
        .axios()
        .put(Settings.apiProcurementSettingsUrl + `/procurementsetting/${id}`, {
          name: getSettings.name !== "" ? getSettings.name : null,
          currency_id: getSettings.currency_id ? getSettings.currency_id : "",
          mode_of_delivery_id:
            getSettings.mode_of_delivery_id == "0"
              ? 0
              : getSettings.mode_of_delivery_id ? parseInt(getSettings.mode_of_delivery_id) : "",
          delivery_term_id:
            getSettings.delivery_term_id == "0"
              ? 0
              : getSettings.delivery_term_id ? parseInt(getSettings.delivery_term_id) : "",
          taxgroup_id:
            getSettings.taxgroup_id == "0"
              ? 0
              : getSettings.taxgroup_id ? parseInt(getSettings.taxgroup_id) : "",
          taxgroupitem_id: getSettings.taxgroupitem_id ? parseInt(getSettings.taxgroupitem_id) : "",
          otp_expiry_in_seconds: getSettings.otp_expiry_in_seconds === "" ? null : parseInt(getSettings.otp_expiry_in_seconds),
          blind_receiving_of_qty: getSettings.blind_receiving_of_Qty,
          blind_receiving_of_item: getSettings.blind_receiving_of_item,
          activate_change_management: getSettings.activate_change_management,
          default_procurement_category:
            getSettings.default_procurement_cateory == "0"
              ? 0
              : getSettings.default_procurement_cateory ? parseInt(getSettings.default_procurement_cateory) : "",
          default_category_for_comments_type_for_integration: 
            getSettings.default_category_for_comments_type_for_integration == "0" 
            ? 0 
            : getSettings.default_category_for_comments_type_for_integration ? parseInt(getSettings.default_category_for_comments_type_for_integration) : "",
          accept_under_delivery: getSettings.accept_under_delivery,
          accept_over_delivery: getSettings.accept_over_delivery,
          check_product_receipt_number:
            getSettings.check_product_receipt_number == "0"
              ? 0
              : getSettings.check_product_receipt_number ? parseInt(getSettings.check_product_receipt_number) : "",
          over_receipt_action:
            getSettings.over_receipt_action == "0"
              ? 0
              : getSettings.over_receipt_action ? parseInt(getSettings.over_receipt_action) : "",
          match_level:
            getSettings.match_level == "0"
              ? 0
              : getSettings.match_level ? parseInt(getSettings.match_level) : "",
          allow_change_of_team_access_on_document:
            getSettings.allow_change_of_team_access_on_document,
          user_to_change_entity_on_po_lines:
            getSettings.user_to_change_entity_on_po_lines,
          allow_past_delivery_date_on_PO:
            getSettings.allow_past_delivery_date_on_PO,
          allow_past_document_date_on_PO:
            getSettings.allow_past_document_date_on_PO,
          allow_past_accounting_date_on_PO:
            getSettings.allow_past_accounting_date_on_PO,
          convert_PR_to_RFP_default: getSettings.convert_PR_to_RFP_default,
          convert_PR_to_PO_default: getSettings.convert_PR_to_PO_default,
          perform_QA_before_goods_receiving:
            getSettings.perform_QA_before_goods_receiving,
          allow_price_override_from_rate_card:
            getSettings.allow_price_override_from_rate_card,
          default_quoation_type: getSettings.default_quoation_type
            ? parseInt(getSettings.default_quoation_type)
            : "",
          allow_access_only_to_buyer_group:
            getSettings.allow_access_only_to_buyer_group,
          // default_quoation_security_level:
          //   getSettings.default_quoation_security_level
          //     ? parseInt(getSettings.default_quoation_security_level)
          //     : "",
          default_quoation_expiry_days: getSettings.default_quoation_expiry_days
            ? getSettings.default_quoation_expiry_days
            : null,
          default_quoation_expiry_times:
            getSettings.default_quoation_expiry_times
              ? JSON.stringify(getSettings.default_quoation_expiry_times)
              : null,
          require_full_quantity: getSettings.require_full_quantity,
          allow_distribute_header_discount_to_lines:
            getSettings.allow_distribute_header_discount_to_lines,
          allow_multiple_responses: getSettings.allow_multiple_responses,
          allow_multiple_active_response:
            getSettings.allow_multiple_active_response,
          allow_quote_withdrawal: getSettings.allow_quote_withdrawal,
          required_award_approval: getSettings.required_award_approval,
          allow_manual_close_before_the_close_date:
            getSettings.allow_manual_close_before_the_close_date,
          allow_manual_extend_when_negotiation_is_open:
            getSettings.allow_manual_extend_when_negotiation_is_open,
          allow_partial_awarding: getSettings.allow_partial_awarding,
          default_RFQ_collaboration_team:
            getSettings.default_RFQ_collaboration_team
              ? parseInt(getSettings.default_RFQ_collaboration_team)
              : "",
          restrict_to_invited_vendor: getSettings.restrict_to_invited_vendor,
          allow_vendor_to_select_lines_to_respond:
            getSettings.allow_vendor_to_select_lines_to_respond,
          display_PR_price_to_vendor: getSettings.display_PR_price_to_vendor,
          allow_alternate_lines_on_vendor_response:
            getSettings.allow_alternate_lines_on_vendor_response,
          display_scoring_criteria_to_vendor:
            getSettings.display_scoring_criteria_to_vendor,
          allow_manual_extend_when_bid_is_open:
            getSettings.allow_manual_extend_when_bid_is_open,
          transaction_discount_group: getSettings.transaction_discount_group
            ? parseInt(getSettings.transaction_discount_group)
            : "",
          transaction_rounding_group: getSettings.transaction_rounding_group
            ? parseInt(getSettings.transaction_rounding_group)
            : "",
          stop_vendor_portal_trade_license_expired:
            getSettings.stop_vendor_portal_trade_license_expired,
          show_related_category_on_vendor_portal:
            getSettings.show_related_category_on_vendor_portal,
          show_items_under_vendor_category:
            getSettings.show_items_under_vendor_category,
          alternate_item_comments_item_group: getSettings.alternate_item_comments_item_group ? parseInt(
            getSettings.alternate_item_comments_item_group
          ) : "",
          vendor_allowed_to_create_work_confirmation:
            getSettings.vendor_allowed_to_create_work_confirmation,
          back_date_invoice_allowed: getSettings.back_date_invoice_allowed,
          allow_goods_receiving_against_servicetype_item:
            getSettings.allow_goods_receiving_against_servicetype_item,
          price_include_tax: getSettings.price_include_tax,
          default_itemgroup_for_miscellaneous_charges: getSettings.default_itemgroup_for_miscellaneouscharges ? parseInt(
            getSettings.default_itemgroup_for_miscellaneouscharges
          ) : '',
          is_invoice_allowed_without_receiving:
            getSettings.is_invoice_allowed_without_receiving,
          allow_view_commericals_in_technical_evaluation:
            getSettings.allow_view_commericals_in_technical_evaluation,
          is_edit_allowed_by_buyer_after_rfq_expiry:
            getSettings.is_edit_allowed_by_buyer_after_rfq_expiry,
          is_sme_done_by_team_in_pr:
            getSettings?.is_sme_done_by_team_in_pr,
          is_disqualified_vendor_allowed_in_ce:
            getSettings?.is_disqualified_vendor_allowed_in_ce,
          auto_vendor_ranking_for_ce:
            getSettings?.auto_vendor_ranking_for_ce,
          vendor_allowed_to_create_goods_receiving:
            getSettings?.vendor_allowed_to_create_goods_receiving,
          is_direct_tender_enabled:
            getSettings?.is_direct_tender_enabled,
          default_entity_for_vendor_registration:
            getSettings.default_entity_for_vendor_registration
              ? parseInt(getSettings.default_entity_for_vendor_registration)
              : "",
          default_group_for_vendor_registration:
            getSettings.default_group_for_vendor_registration
              ? parseInt(getSettings.default_group_for_vendor_registration)
              : "",
          is_default: getSettings.is_default,
          fiscalcalendar_id:getSettings.fiscalcalendar_id
          ? parseInt(getSettings.fiscalcalendar_id)
          : "",
          vendorgroup_prepayment_id:getSettings.vendorgroup_prepayment_id
          ? parseInt(getSettings.vendorgroup_prepayment_id)
          : "",
          vendorgroup_id:getSettings.vendorgroup_id
          ? parseInt(getSettings.vendorgroup_id)
          : "",
          rfq_stages:getSettings.rfq_stages
          ? parseInt(getSettings.rfq_stages)
          : "",
          default_dynamic_te_team:getSettings.default_dynamic_te_team
          ? parseInt(getSettings.default_dynamic_te_team)
          : "",
          default_dynamic_ce_team:getSettings.default_dynamic_ce_team
          ? parseInt(getSettings.default_dynamic_ce_team)
          : ""
        })
        .then(function (res) {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
            setEntityArr([]);
            setDeleteEntityArr([]);
            getProcureSettings();
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res);
        });
    }, 200);
  };

  let security = props.security;
  let frontendIds = Gui_id_list;
  if (
    !security.canView(
      Gui_id_list.procure.procurement_settings
        .procurement_settings_edit_screen_save_button
    )
  ) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader isPopupInTab={true} /> : null }
      <div className={ 'container-fluid' }>
        <div ref={wrapperDiv}>
          <NavigationHeder
            hideMoreBtn={true}
            backUrl='/procurement-settings'
            title={Helper.getLabel(
              props.language,
              'parameters_edit',
              'Edit Procurement Settings'
            )}
          >
            <LinkBtn
              isActive={false}
              to='/procurement-settings'
              className='black-btn-style'
              title={Helper.getLabel(props.language, 'close', 'Close')}
            />
            <Button
              className='orange-btn-style'
              title={Helper.getLabel(props.language, 'history', 'History')}
            />
            <Button
              onClick={SaveHandler}
              isActive={false}
              className="rfq_save_btn"
              title={Helper.getLabel(props.language, 'save', 'Save')}
              isDisable={
                !security.canCreate(
                  frontendIds.procure.procurement_settings
                    .procurement_settings_edit_screen_save_button
                )
              }
            />
          </NavigationHeder> 
        </div>
        
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}> 
          { loading ? null : 
            <Collapse
              className='mb-4 default_params_tabs'
              open={true}
              title={Helper.getLabel(props.language, 'settings', 'Settings')}
            >
              <Tabs
                disableSticky={true}
                navs={DefaultNavs}
                showAll={false}
                scrollOffset='500'
              >
                <div className='container-fluid Default'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <Input
                        onChange={NameHandler}
                        required={true}
                        value={getSettings.name}
                        label={Helper.getLabel(props.language, 'name', 'Name')}
                        id='name_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DeliveryModeHandler}
                        value={getSettings.mode_of_delivery_id}
                        options={modeOfDelivery}
                        placeHolder='Select mode of delivery'
                        label={Helper.getLabel(
                          props.language,
                          'mode_of_delivery',
                          'Mode of delivery'
                        )}
                        id='mode_of_delivery_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DeliveryTermHandler}
                        value={getSettings.delivery_term_id}
                        options={deliveryItem}
                        placeHolder='Select delivery item'
                        label={Helper.getLabel(
                          props.language,
                          'delivery_item',
                          'Delivery item'
                        )}
                        id='delivery_item_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={TaxGroupChangeHandler}
                        value={getSettings.taxgroup_id}
                        options={taxGroup}
                        placeHolder='Select tax group'
                        label={Helper.getLabel(
                          props.language,
                          'tax_group',
                          'Tax group'
                        )}
                        id='tax_group_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={TaxItemGroupHandler}
                        value={getSettings.taxgroupitem_id}
                        options={itemTaxGroup}
                        placeHolder='Select tax group item'
                        label={Helper.getLabel(
                          props.language,
                          'tax_group_item',
                          'Tax group item'
                        )}
                        id='tax_group_item_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DefaultQuoTypeHandler}
                        value={getSettings.default_quoation_type}
                        options={defaultQuoType}
                        placeHolder='Select default quotation type'
                        label={Helper.getLabel(
                          props.language,
                          'default_quotation_type',
                          'Default quotation type'
                        )}
                        id='default_quotation_type_dropdown'
                      />
                    </div>
                    {/* <div className='col-lg-4'>
                      <DropDownInput
                        id='default_quoation_security_level'
                        options={defaultQuoSecurityLevels}
                        placeHolder='Select default quoation security level'
                        onChange={defaultQuoSecurityHandler}
                        value={getSettings.default_quoation_security_level}
                        label={Helper.getLabel(
                          props.language,
                          'default_quoation_security_level',
                          'Default quoation security level'
                        )}
                      />
                    </div> */}
                    <div className='col-lg-4'>
                      <InputTimePicker
                        placeHolder='Select default quotation expiry times'
                        label={Helper.getLabel(
                          props.language,
                          'default_quoation_expiry_times',
                          'Default quotation expiry times'
                        )}
                        onChange={DefaultQuotationExpiryTimeHandler}
                        value={getSettings.default_quoation_expiry_times}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select default RFQ collaboration team'
                        options={defaultCollabTeam}
                        onChange={DefaultRFQCollabTeam}
                        name='default_RFQ_collaboration_team'
                        id='default_RFQ_collaboration_team'
                        value={getSettings.default_RFQ_collaboration_team}
                        label={Helper.getLabel(
                          props.language,
                          'default_RFQ_collaboration_team',
                          'Default RFQ collaboration team'
                        )}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Input
                        inputId='default_quoation_expiry_days'
                        onChange={ExpiryDaysHandler}
                        value={getSettings.default_quoation_expiry_days}
                        label={Helper.getLabel(
                          props.language,
                          'default_quoation_expiry_days',
                          'Default quotation expiry days'
                        )}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Input
                        onChange={ExpiryOTPSecondsHandler}
                        value={getSettings.otp_expiry_in_seconds}
                        label={Helper.getLabel(
                          props.language,
                          'otp_expiry_in_days',
                          'Otp expiry in days'
                        )}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        apiUrl={Settings.apiUrl + '/categories'}
                        currentSearchTerm={currentSearchTerm}
                        mapFunction={mapCategoryItems}
                        allDataRef={parentCategoryData}
                        pagination={true}
                        placeHolder='Select default category for comments type for integration'
                        onChange={DefaultCategoryForComments}
                        value={getSettings.default_category_for_comments_type_for_integration}
                        options = {
                          getSettings?.default_category_for_comments_type_for_integration ? 
                            [{ value: getSettings?.default_category_for_comments_type_for_integration, label: getSettings?.default_category_for_comments_type_for_integration_name ?? parentCategoryData?.current?.find(item => parseInt(item.category_id) === parseInt(getSettings?.default_category_for_comments_type_for_integration))?.name }] 
                          : 
                          procurementCategory
                        }
                        label={Helper.getLabel(
                          props.language,
                          'default_category_for_comments_type_for_integration',
                          'Default category for comments type for integration'
                        )}
                        id='default_category_for_comments_type_for_integration'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        apiUrl={Settings.apiUrl + '/categories'}
                        currentSearchTerm={currentSearchTerm}
                        mapFunction={mapCategoryItems}
                        allDataRef={parentCategoryData}
                        pagination={true}
                        placeHolder='Select procurement category'
                        onChange={ProcurementHandler}
                        value={getSettings.default_procurement_cateory}
                        options={ 
                          getSettings.default_procurement_cateory
                          ? 
                            [{
                              value: getSettings.default_procurement_cateory,
                              label: getSettings?.default_procurement_category_name ?? parentCategoryData?.current?.find(item => parseInt(item.category_id) === parseInt(getSettings.default_procurement_cateory))?.name,
                            }]
                          : 
                            procurementCategory
                        } 
                        label={Helper.getLabel(
                          props.language,
                          'procurement_category',
                          'Procurement category'
                        )}
                        id='procurement_category_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select transaction discount group'
                        onChange={TransactionDiscountGPHandler}
                        value={getSettings.transaction_discount_group}
                        options={transactionDiscountGP}
                        label={Helper.getLabel(
                          props.language,
                          'transaction_discount_group',
                          'Transaction discount group'
                        )}
                        id='transaction_discount_group'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select transaction rounding group'
                        onChange={TransactionRoundingGPHandler}
                        value={getSettings.transaction_rounding_group}
                        options={transactionRoundingGP}
                        label={Helper.getLabel(
                          props.language,
                          'transaction_rounding_group',
                          'Transaction rounding group'
                        )}
                        id='transaction_rounding_group'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select alternate item comments item group'
                        onChange={AltItemGroupHandler}
                        value={getSettings.alternate_item_comments_item_group}
                        options={altItemGroup}
                        label={Helper.getLabel(
                          props.language,
                          'alternate_item_comments_item_group',
                          'Alternate item comments item group'
                        )}
                        id='alternate_item_comments_item_group'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DefaultItemHandler}
                        value={
                          getSettings.default_itemgroup_for_miscellaneouscharges
                        }
                        placeHolder='Select default item group for miscellaneous charges'
                        options={defaultItemGroup}
                        label={Helper.getLabel(
                          props.language,
                          'default_itemgroup_for_miscellaneouscharges',
                          'Default item group for miscellaneous charges'
                        )}
                        id='default_itemgroup_for_miscellaneouscharges'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Dropdown
                        id='default_entity_for_vendor_registration'
                        label={Helper.getLabel(
                          props.language,
                          'default_entity_for_vendor_registration',
                          'Default entity for vendor registration'
                        )}
                        options={ 
                          getSettings.default_entity_for_vendor_registration
                            ? 
                              [{
                                value: getSettings?.default_entity_for_vendor_registration,
                                label: getSettings?.default_entity_for_vendor_registration_name ?? parentEntitiesData?.current?.find(item => parseInt(item.entity_id) === parseInt(getSettings.default_entity_for_vendor_registration))?.name,
                              }]
                            : 
                            entityList
                        } 
                        apiUrl={Settings.apiUrl + '/get_entities'}
                        currentSearchTerm={currentSearchTerm}
                        mapFunction={mapApiEntitiesItems}
                        allDataRef={parentEntitiesData} 
                        pagination={true}
                        placeHolder={`${Helper.getLabel(
                          props.language,
                          'please_default_entity_for_vendor_registration',
                          'Select default entity for vendor registration'
                        )}`}
                        value={getSettings.default_entity_for_vendor_registration}
                        onChange={DefaultEntityForVendorRegistrationHandler}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DefaultGroupForVendorRegistrationHandler}
                        value={getSettings.default_group_for_vendor_registration}
                        options={defaultVendorGroup}
                        placeHolder='Select default group for vendor registration'
                        label={Helper.getLabel(
                          props.language,
                          'default_group_for_vendor_registration',
                          'Default group for vendor registration'
                        )}
                        id='default_group_for_vendor_registration'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DefaultCurrency}
                        value={getSettings.currency_id}
                        placeHolder='Select default currency'
                        options={defaultCurrency}
                        label={Helper.getLabel(
                          props.language,
                          'currency',
                          'Default currency'
                        )}
                        id='default_currency'
                      />
                    </div>
                    <div className="col-lg-4">
                      <DropDownInput
                        onChange={DefaultRfqStagesChange}
                        value={getSettings.rfq_stages}
                        placeHolder='Select default RFQ stage'
                        options={defaultRfqStages}
                        reRenderRequired={true}
                        label={Helper.getLabel(
                          props.language,
                          "default rfq stage",
                          "Default RFQ stage"
                        )}
                        id="default_rfq_stage"
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        id='fiscalcalendar_id'
                        placeHolder='Select fiscal calendar'
                        value={getSettings.fiscalcalendar_id}
                        onChange={FiscalHandler}
                        label={Helper.getLabel(
                          props.language,
                          'fiscal_calendar',
                          'Fiscal calendar'
                        )}
                        options={fiscalCalender}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        id='vendorgroup_id'
                        placeHolder='Select vendor group'
                        value={getSettings.vendorgroup_id}
                        onChange={VendorGroupHandler}
                        label={Helper.getLabel(
                          props.language,
                          'vendor_group',
                          'Vendor group'
                        )}
                        options={vendorGroup}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select vendor group pre payment'
                        id='vendorgroup_prepayment_id'
                        value={getSettings.vendorgroup_prepayment_id}
                        onChange={VendorGroupPrePayHandler}
                        label={Helper.getLabel(
                          props.language,
                          'vendorgroup_prepayment_id',
                          'Vendor group pre payment'
                        )}
                        options={vendorGroupPrePay}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={DefaultDynamicTeamHandler}
                        value={getSettings.default_dynamic_te_team}
                        options={dynamicTeams}
                        name="default_dynamic_te_team"
                        placeHolder='Select default dynamic technical evaluation team'
                        label={Helper.getLabel(
                          props.language,
                          'default_dynamic_technical_evaluation_team',
                          'Default dynamic technical evaluation team'
                        )}
                        id='default_dynamic_technical_evaluation_team'
                      />
                    </div>
                    <div className='col-lg-4'>
                    <DropDownInput
                      onChange={DefaultDynamicTeamHandler}
                      value={getSettings.default_dynamic_ce_team}
                      options={dynamicTeams}
                      name="default_dynamic_ce_team"
                      placeHolder='Select default dynamic commercial evaluation team'
                      label={Helper.getLabel(
                        props.language,
                        'default_dynamic_commercial_evaluation_team',
                        'Default dynamic commercial evaluation team'
                      )}
                      id='default_dynamic_commercial_evaluation_team'
                      />
                    </div>
                    <div className='col-lg-4 mt-lg-3'>
                      <Checkbox
                        onChange={DefaultSettings}
                        isChecked={getSettings.is_default}
                        type='checkbox'
                        label='isDefault'
                      />
                    </div>
                  </div>
                </div>

                <div className='container-fluid Controls'>
                  <div className='row mb-5 gy-4'>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            type='checkbox'
                            onChange={BlindQuantity}
                            isChecked={getSettings.blind_receiving_of_Qty}
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Blind receiving of quantity</h5>
                          <p>
                            Request managers to approve orders above a minimum
                            amount
                          </p>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={BlindItems}
                            isChecked={getSettings.blind_receiving_of_item}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Blind receiving of items</h5>
                          <p>
                            Request managers to approve orders above a minimum
                            amount
                          </p>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={ActiveCharge}
                            isChecked={getSettings.activate_change_management}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Active charge management</h5>
                          <p>
                            Request managers to approve orders above a minimum
                            amount
                          </p>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={AcceptUnderHandler}
                            isChecked={getSettings.accept_under_delivery}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Accept under delivery</h5>
                          <p>
                            Request managers to approve orders above a minimum
                            amount
                          </p>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={AcceptOverHandler}
                            isChecked={getSettings.accept_over_delivery}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Accept over delivery</h5>
                          <p>
                            Request managers to approve orders above a minimum
                            amount
                          </p>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='perform_QA_before_goods_receiving'
                            isChecked={
                              getSettings.perform_QA_before_goods_receiving
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Perform QA before goods receiving</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_distribute_header_discount_to_lines'
                            isChecked={
                              getSettings.allow_distribute_header_discount_to_lines
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow distribute header discount to lines</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_price_override_from_rate_card'
                            isChecked={
                              getSettings.allow_price_override_from_rate_card
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow price override from ratecard</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_access_only_to_buyer_group'
                            isChecked={
                              getSettings.allow_access_only_to_buyer_group
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow access only to buyer group</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_change_of_team_access_on_document'
                            isChecked={
                              getSettings.allow_change_of_team_access_on_document
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow change of team access on document</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='user_to_change_entity_on_po_lines'
                            isChecked={
                              getSettings.user_to_change_entity_on_po_lines
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>User to change entity on PO lines</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_past_delivery_date_on_PO'
                            isChecked={getSettings.allow_past_delivery_date_on_PO}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow past delivery date on PO</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_past_document_date_on_PO'
                            isChecked={getSettings.allow_past_document_date_on_PO}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow past document date on PO</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_past_accounting_date_on_PO'
                            isChecked={
                              getSettings.allow_past_accounting_date_on_PO
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow past accounting date on PO</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='convert_PR_to_RFP_default'
                            isChecked={getSettings.convert_PR_to_RFP_default}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Convert PR to RFQ default</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='convert_PR_to_PO_default'
                            isChecked={getSettings.convert_PR_to_PO_default}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Convert PR to PO default</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='require_full_quantity'
                            isChecked={getSettings.require_full_quantity}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Require full quantity</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_multiple_responses'
                            isChecked={getSettings.allow_multiple_responses}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow multiple responses</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_multiple_active_response'
                            isChecked={getSettings.allow_multiple_active_response}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow multiple active response</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_quote_withdrawal'
                            isChecked={getSettings.allow_quote_withdrawal}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow quote withdrawal</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='required_award_approval'
                            isChecked={getSettings.required_award_approval}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Required award approval</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_manual_close_before_the_close_date'
                            isChecked={
                              getSettings.allow_manual_close_before_the_close_date
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow manual close before the close date</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_manual_extend_when_negotiation_is_open'
                            isChecked={
                              getSettings.allow_manual_extend_when_negotiation_is_open
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow manual extend when negotiation is open</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_partial_awarding'
                            isChecked={getSettings.allow_partial_awarding}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow partial awarding</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='restrict_to_invited_vendor'
                            isChecked={getSettings.restrict_to_invited_vendor}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow vendor to request extension</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_vendor_to_select_lines_to_respond'
                            isChecked={
                              getSettings.allow_vendor_to_select_lines_to_respond
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow vendor to select lines to respond</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='display_PR_price_to_vendor'
                            isChecked={getSettings.display_PR_price_to_vendor}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Display PR price to vendor</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_alternate_lines_on_vendor_response'
                            isChecked={
                              getSettings.allow_alternate_lines_on_vendor_response
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow alternate lines on vendor response</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='display_scoring_criteria_to_vendor'
                            isChecked={
                              getSettings.display_scoring_criteria_to_vendor
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Display scoring criteria to vendor</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_manual_extend_when_bid_is_open'
                            isChecked={
                              getSettings.allow_manual_extend_when_bid_is_open
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow manual extend when bid is open</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='stop_vendor_portal_trade_license_expired'
                            isChecked={
                              getSettings.stop_vendor_portal_trade_license_expired
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Stop vendor portal trade license expired</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='show_related_category_on_vendor_portal'
                            isChecked={
                              getSettings.show_related_category_on_vendor_portal
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Show related category on vendor portal</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='show_items_under_vendor_category'
                            isChecked={
                              getSettings.show_items_under_vendor_category
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Show items under vendor category</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='vendor_allowed_to_create_work_confirmation'
                            isChecked={
                              getSettings.vendor_allowed_to_create_work_confirmation
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Vendor allowed to create work confirmation</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_goods_receiving_against_servicetype_item'
                            isChecked={
                              getSettings.allow_goods_receiving_against_servicetype_item
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow goods receiving against service type item</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='price_include_tax'
                            isChecked={getSettings.price_include_tax}
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Price includes tax</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='is_invoice_allowed_without_receiving'
                            isChecked={
                              getSettings.is_invoice_allowed_without_receiving
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Is invoice allowed without receiving</h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='allow_view_commericals_in_technical_evaluation'
                            isChecked={
                              getSettings.allow_view_commericals_in_technical_evaluation
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>
                            Allow view commericals values in technical evaluation
                          </h5>
                        </div>
                        <div className='col-1'>
                          <img className='img-fluid' />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='is_edit_allowed_by_buyer_after_rfq_expiry'
                            isChecked={
                              getSettings.is_edit_allowed_by_buyer_after_rfq_expiry
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Is edit allowed by buyer after RFQ expiry</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='is_sme_done_by_team_in_pr'
                            isChecked={
                              getSettings.is_sme_done_by_team_in_pr
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Is SME done by team in PR</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='is_disqualified_vendor_allowed_in_ce'
                            isChecked={
                              getSettings.is_disqualified_vendor_allowed_in_ce
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Is disqualified vendor allowed in CE</h5>
                        </div>
                      </div>
                    </div> 
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='is_direct_tender_enabled'
                            isChecked={
                              getSettings.is_direct_tender_enabled
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Is direct tender enabled</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='auto_vendor_ranking_for_ce'
                            isChecked={
                              getSettings.auto_vendor_ranking_for_ce
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Auto Vendor Ranking for CE</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='vendor_allowed_to_create_goods_receiving'
                            isChecked={
                              getSettings.vendor_allowed_to_create_goods_receiving
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Vendor allowed to create goods receiving</h5>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <div className='row d-flex align-items-center'>
                        <div className='col-1'>
                          <Checkbox
                            onChange={onInputChangeHandler}
                            name='back_date_invoice_allowed'
                            isChecked={
                              getSettings.back_date_invoice_allowed
                            }
                            type='checkbox'
                          />
                        </div>
                        <div className='col-10 border-controls'>
                          <h5>Allow back date invoice</h5>
                        </div>
                      </div>
                    </div> 
                  </div>
                  <div className='row mt-3'>
                    <div className='col-lg-4'>
                      <DropDownInput
                        onChange={OverReceiptAction}
                        placeHolder='Select over receipt action'
                        value={getSettings.over_receipt_action}
                        options={receiptAction}
                        label={Helper.getLabel(
                          props.language,
                          'over_receipt_action',
                          'Over receipt action'
                        )}
                        id='over_receipt_action_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select check product receipt number'
                        options={receiptNumber}
                        onChange={CheckProductNumberHandler}
                        value={getSettings.check_product_receipt_number}
                        label={Helper.getLabel(
                          props.language,
                          'check_product_receipt_number',
                          'Check product receipt number'
                        )}
                        id='check_product_receipt_number_dropdown'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <DropDownInput
                        placeHolder='Select match level'
                        options={matchLevel}
                        onChange={MatchLevel}
                        value={getSettings.match_level}
                        label={Helper.getLabel(
                          props.language,
                          'match_level',
                          'Match level'
                        )}
                        id='match_level_dropdown'
                      />
                    </div>
                  </div>
                </div>

                <div className='container-fluid entities'>
                  {entityLoading == undefined ? <SimpleLoading /> : ''}
                  <div className='row'>
                    {getSettings ? (
                      <Fragment>
                        {entityList.map((item) => {
                          return (
                            <div className='columns'>
                              {getSettings.entities == undefined ? (
                                <Checkbox
                                  onChange={CheckBoxHandler}
                                  name={item.value}
                                  isChecked={false}
                                  label={item.label + ' ' + item.value}
                                />
                              ) : (
                                <Checkbox
                                  onChange={CheckBoxHandler}
                                  name={item.value}
                                  isChecked={
                                    getSettings.entities.includes(item.value)
                                      ? true
                                      : false
                                  }
                                  label={item.label + ' ' + item.value}
                                />
                              )}
                            </div>
                          )
                        })}
                      </Fragment>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className='container-fluid Numbering_Sequence'> 
                  <Collapse
                    className='mb-4 number_sequence_grid'
                    open={true}
                    title={Helper.getLabel(
                      props.language,
                      'number_sequence',
                      'Number Sequence'
                    )}
                  >
                    <div className='row'>
                      <div className='col-4'>
                        <DropDownInput
                          id='entity_dropdown'
                          name='entities'
                          label={Helper.getLabel(
                            props.language,
                            'entity',
                            'Entity'
                          )}
                          options={entityList}
                          apiUrl={Settings.apiUrl + '/get_entities'}
                          currentSearchTerm={currentSearchTerm}
                          mapFunction={mapApiEntitiesItems}
                          allDataRef={parentEntitiesData} 
                          pagination={true}
                          placeHolder={`${Helper.getLabel(
                            props.language,
                            'please_select',
                            'Select entity'
                          )}`}
                          value={entityNS}
                          onChange={entityDropdownHandler}
                        />
                      </div>
                      <div className='col-12 ns_grid'> 
                        {nsLoading ? <SimpleLoading /> :
                          <AgGridNew
                            apiUrl={entityNS !== ''?Settings.apiProcurementSettingsUrl+`/numberingsequence?entity_id=${parseInt(entityNS)}`:Settings.apiProcurementSettingsUrl+`/numberingsequence`}
                            pagination={false}
                            columnDefs={Columns_Headings_NS}
                            onRowValueChanged={onRowValueChangedNumberSequence}
                            handleDeleteSelectedRows={DeleteAssignedEntity}
                            height={500}
                            onGridReady={(params) => gridApiNumberSequence = params?.api}
                            handleAddButton={handleBtnNumberSequence}
                            uniqueField={`record_id`}
                            gridId={'number_sequence_procurement_settings'}
                          />
                        }
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className='container-fluid team_header'> 
                  <Collapse
                    className='mb-4 team_header_grid'
                    open={true}
                    title={Helper.getLabel(props.language, 'team', 'Team')}
                  >
                    <div className='row'>
                      <div className='container-fluid'>
                        <div className='row ps-3 pe-3'>
                          <div className='col-12'> 
                            <AgGridNew
                              apiUrl={id?Settings.apiProcurementSettingsUrl + `/procurementteamheader/${id}`:null}
                              pagination={false}
                              columnDefs={Columns_Headings}
                              onRowValueChanged={onRowValueChanged}
                              handleDeleteSelectedRows={DeleteLinkedTeamHeader}
                              height={500}
                              onGridReady={(params) => gridApiTeamHeader = params?.api}
                              handleAddButton={handleBtn}
                              uniqueField={`teamheader_id`}
                              gridId={'team_header_procurement_settings'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className='container-fluid segments_distribution'>
                  <Collapse
                    className='mb-4 segment_distributions_procure_settings'
                    open={true}
                    title={Helper.getLabel(
                      props.language,
                      'segment_distribution',
                      'Segment Distribution'
                    )}
                  >
                    <SegmentsDistribution
                      id={'segments_distribution_procurement_settings'}
                      source_id={50}
                      integrator={id}
                      distribution_source_id={getSettings.distribution_source_id}
                      totalAmount={100}
                      disableFinancial={false}
                      onCreateFirstDistribution={CreateDistributions}
                    />
                  </Collapse>
                </div>
              </Tabs>
            </Collapse> 
          }
        </div>
      </div>
    </Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id:
    Gui_id_list.procure.procurement_settings
      .procurement_settings_edit_screen_save_button,
};

export default connect(mapStateToProps)(
  MasterComponentWraper(ApplySecurityRoles(ParametersEdit, SecurityOptions))
); 