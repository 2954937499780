import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import MasterComponentWraper from '../../../../Backend/MasterComponentWraper'; 
import { connect } from 'react-redux'; 
import Collapse from '../../../../inc/Collapse';
import Input from '../../../../Forms/Input';
import NavigationHeder from '../../../../Navigations/NavigationHeder';
import LinkBtn from '../../../../inc/LinkBtn';
import Button from '../../../../inc/Button';
import SimpleLoading from '../../../../Loading/SimpleLoading'; 
import Helper from '../../../../../inc/Helper'; 
import Api from '../../../../../inc/Api';
import Settings from '../../../../../inc/Settings';
import { useParams } from 'react-router-dom';
import { getPopupMessageBasedOnStatus } from '../../../../../inc/Validation';
import ColorPickerPopup from './ColorPickerPopup';
import AgGridNew from '../../../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../../../PurchaseRequisition/common/OverlayLoader';

/* Response line name is mandatory, and it should be around 20 length, response line rank should be integer */

let currentLineViewGridObj = null;  
let defaultComplianceHeaderObject = null; 
let AddBtn;
let transaction;

const DetailViewResponses = ({editMode=false, language}) => {
    const [complianceResponse, setComplianceResponse] = useState({
        compliance_name: null
    });
    
    const [colorPickerPopup, setColorPickerPopup] = useState({
        openPopup: false,
        color: null,
        node: null
    });

    const { id } = useParams();
    
    const wrapperDiv = useRef(null);
    const [height, setHeight] = useState(0);

    const [loading, setLoading] = useState(false); 

    // API Calls Functions/timeouts,
    let ComplianceHeaderFetch = false;  

    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, loading]);

    useEffect(() => {
        getComplianceHeaderById(); 
    }, [id]);

    const getComplianceHeaderById = () => {
        if (ComplianceHeaderFetch || (!editMode && !id)) return;

        setLoading(true);
        let api = Api
        ComplianceHeaderFetch = true;
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+`/complianceresponseheader/${id}`,{}).then(function(res){
            if(res.data.status==='success'){
                defaultComplianceHeaderObject = res.data.data.compliance_name
                setComplianceResponse(res.data.data)
                setLoading(false)
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
            setLoading(false)
        })
    }

    // modules
    const DetailViewReponseModuleWrapper = () => {
        return (
            <div className='detail_view_response_wrapper common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}> 
                {ResponsesInputFieldWrapper()}
                {LineViewResponsesWrapper()}
            </div>
        )
    };

    const ResponsesInputFieldWrapper = () => {
        const onInputChangeHandler = (e) => { 
            setComplianceResponse((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]:e.target.value
                }
            })
        };
 
        return ( 
            <Collapse className='mb-4' open={true} title={Helper.getLabel(language,'compliance',"Compliance")}>
                <div className='container-fluid'>
                    <div className='row'> 
                        <div className='col-lg-3'>
                            <Input required={true} onChange={onInputChangeHandler} value={complianceResponse.compliance_name} label={Helper.getLabel(language,'compliance_name',"Compliance Name")} name='compliance_name' />
                        </div>
                    </div>
                </div>
            </Collapse> 
        )
    };

    const LineViewResponsesWrapper = () => {
        const onColorCellClicked = (params) => {
            if(params.colDef.field === 'color') {
                if(!params?.data?.compliance_id) {
                    Helper.alert('Add a response line then add color!', 'error');
                    return;
                }
                setColorPickerPopup((prevState) => {
                    return {
                        ...prevState,
                        openPopup: true,
                        color: params?.data?.color,
                        node: params 
                    }
                })
            } 
        };

        const gridColHeaders = [
            {field:'name', filter: 'agTextColumnFilter', editable: true, minWidth: 230, headerName: Helper.getLabel(language,'name','Name*') }, 
            {field:'rank', filter: 'agNumberColumnFilter', editable: true, defaultValue: '0', minWidth: 230, headerName: Helper.getLabel(language,'rank','Rank') },
            {field:'comments_mandatory', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', cellRendererParams: { disabled: true }, editable: true, minWidth: 230,
                valueGetter: (params) => {
                    if(!params?.data?.comments_mandatory || !params?.data){
                        return false
                    }
                    else {
                        return params.data?.comments_mandatory
                    }
                }, headerName: Helper.getLabel(language,'comments_mandatory','Comments mandatory')
            }, 
            {field:'date_mandatory', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', cellRendererParams: { disabled: true }, editable: true, minWidth: 230,
                valueGetter: (params) => {
                    if(!params?.data?.date_mandatory || !params?.data){
                        return false
                    }
                    else {
                        return params.data?.date_mandatory
                    }
                }, headerName: Helper.getLabel(language,'date_mandatory','Date Mandatory')
            },
            {field:'color', filter: false, sortable: false, editable: false, cellRenderer: (params) => { return gridLinkValueFormat(params) }, minWidth: 230, headerName: Helper.getLabel(language,'color','Color') },
            {field:'comments', filter: 'agTextColumnFilter', editable: true, minWidth: 350, headerName: Helper.getLabel(language,'comments','Comments') }, 
        ];

        return (
            <div className='compliance_line_view'> 
                <Collapse open={true} className='grid_save_btn_hide' title={Helper.getLabel(language,'compliance_response_lines',"Compliance Response Lines")}>
                    <div className='container-fluid grid_compliance_line_view'>
                        <AgGridNew
                            apiUrl={id ? Settings.apiPurchaseRequisitionUrl+`/complianceresponselines/${id}` : null}
                            pagination={false}
                            onCellValueChanged={onColorCellValueChanged}
                            columnDefs={gridColHeaders}
                            onRowValueChanged={onRowValueChanged} 
                            onCellClicked={onColorCellClicked}
                            handleDeleteSelectedRows={DeleteResponseLine}
                            height={500}
                            onGridReady={(params) => currentLineViewGridObj = params?.api}
                            handleAddButton={handleBtn}
                            uniqueField={`compliance_id`}
                            gridId={'compliance-responses-setup-form'}
                        />
                    </div>
                </Collapse>
            </div>
        )
    };

    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    };

    const onRowValueChanged = async (event) => {
        const { compliance_id } = event?.data
        try {
            if (compliance_id) {
                await onUpdateCurrentResponseLine(event)
            } else {
                await onCreateNewResponseLine(event) 
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error) 
        }
    };

    const onColorCellValueChanged = useCallback(async (event) => {
        const { data, column, newValue } = event;
        if(!data?.compliance_id){
            return;
        }
        if(column?.colDef?.field==="color"){
            try {
                data[column.colDef.field] = newValue;
                await onRowValueChanged({ data });
            } catch (error) {
                getPopupMessageBasedOnStatus(error);
            }
        }
    }, [onRowValueChanged]);

    const DeleteResponseLine = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        
        let delete_compliance_response_id = element?.data?.compliance_id;
    
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl+`/complianceresponseline/${delete_compliance_response_id}`)
            const rowNode = currentLineViewGridObj?.getRowNode(delete_compliance_response_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element.data] }
                currentLineViewGridObj?.applyServerSideTransaction(transaction)
            }, 200);

            currentLineViewGridObj?.refreshServerSide({ purge: true });
            currentLineViewGridObj?.deselectAll();
            
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
     
    const onCreateNewResponseLine = async (event) => {
        try {
            let payload = {
                name: event?.data?.name,
                rank: event?.data?.rank,
                comments_mandatory: event?.data?.comments_mandatory ? true : false,
                date_mandatory: event?.data?.date_mandatory ? true : false,
                color: event?.data?.color,
                comments: event?.data?.comments
            };
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl+`/complianceresponselines/${id}`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    currentLineViewGridObj?.refreshServerSide({ purge: true });
                    currentLineViewGridObj?.deselectAll();
                }, 100);
                setTimeout(() => {
                    if (AddBtn) {
                        currentLineViewGridObj.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        } 
    };

    const onUpdateCurrentResponseLine = async (event) => {
        try {
            let payload = {
                name: event?.data?.name,
                rank: event?.data?.rank,
                comments_mandatory: event?.data?.comments_mandatory ? true : false,
                date_mandatory: event?.data?.date_mandatory ? true : false,
                color: event?.data?.color,
                comments: event?.data?.comments
            };

            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/complianceresponseline/${event?.data?.compliance_id}`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    currentLineViewGridObj?.refreshServerSide({ purge: true });
                    currentLineViewGridObj?.deselectAll();
                }, 100);
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        };
    };
   
    const updateComplianceResponseHeader = () => {
        if((defaultComplianceHeaderObject == complianceResponse.compliance_name)) return;

        setLoading(true);
        let api = Api 
        api.setUserToken()
        api.axios().put(Settings.apiPurchaseRequisitionUrl+`/complianceresponseheader/${id}`,{
            "compliance_name": complianceResponse.compliance_name
        }).then(function(res){
            if(res.data.status==='success'){
                Helper.alert(res.data.message);  
                getComplianceHeaderById();
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res); 
            getComplianceHeaderById();
        })
    };
  
    const SaveDetailViewComplianceResponses = () => {
        if(!complianceResponse.compliance_name){
            Helper.alert('Please provide complaince name!', 'error');
            return;
        }
   
        updateComplianceResponseHeader();  
    };

    return ( 
        <Fragment>
            { loading ? <OverlayLoader isPopupInTab={true} /> : null } 
            <div className='container-fluid'>
                <div ref={wrapperDiv}> 
                    <NavigationHeder hideMoreBtn={true} backUrl='/compliances-responses/listings' title={Helper.getLabel(language,`${editMode ? 'edit_compliance_response' : 'create_compliance_response'}`,`${editMode ? 'Edit Compliance Response' : 'Create Compliance Response'}`)}>
                        <LinkBtn isActive= {false} to="/compliances-responses/listings" className='black-btn-style' title={Helper.getLabel(language,'close',"Close")} /> 
                        <Button onClick={SaveDetailViewComplianceResponses} className="rfq_save_btn" title={Helper.getLabel(language,'save',"Save")}  /> 
                    </NavigationHeder> 
                </div>

                { DetailViewReponseModuleWrapper() }  
            </div>
            { colorPickerPopup?.openPopup && (
                <ColorPickerPopup
                    gridApi={currentLineViewGridObj}
                    onCellClickObject={colorPickerPopup?.node}
                    closePopupView={setColorPickerPopup}
                    color={colorPickerPopup?.color}
                />
            )}
        </Fragment>  
    );
};

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps)(
    MasterComponentWraper((DetailViewResponses))
);