export const Budget_Data = [
  {
    id: 1,
    document_number: 'BE-001',
    title: 'Data Center Migration',
    year: '2023',
    initiate_date: new Date('2023-02-15').toString(),
    department: 'Head Office',
    segmentvalue_id: 66,
    project: 'Data Center Migration',
    owner: 'Alice Johnson',
    description: 'Migrate data to a new data center for better performance.',
    total_budget_estimated: '1,500,000',
    key_objectives: 'Improve data access speed by 40%',
    status: 'Draft',
    status_id: 1,
  },
  {
    id: 2,
    document_number: 'BE-002',
    title: 'Website Support Services',
    year: '2022',
    initiate_date: new Date('2022-05-25').toString(),
    department: 'Training',
    segmentvalue_id: 71,
    project: 'Website Support Services',
    owner: 'David Miller',
    description: 'Maintain and support website services for e-commerce.',
    total_budget_estimated: '900,000',
    key_objectives: 'Ensure 99.9% uptime for the website.',
    status: 'Approved',
    status_id: 4,
  },
  {
    id: 3,
    document_number: 'BE-003',
    title: 'Office Upgrade',
    year: '2023',
    initiate_date: new Date('2023-07-01').toString(),
    department: 'Recruitment',
    segmentvalue_id: 69,
    project: 'Office Upgrade',
    owner: 'Emily Davis',
    description: 'Upgrade office infrastructure to support remote work.',
    total_budget_estimated: '2,000,000',
    key_objectives: 'Enable flexible working environments.',
    status: 'In-review',
    status_id: 3,
  },
  {
    id: 4,
    document_number: 'BE-004',
    title: 'HR Tech Implementation',
    year: '2024',
    initiate_date: new Date('2024-01-12').toString(),
    department: 'HR Technology',
    segmentvalue_id: 73,
    project: 'HR Tech Implementation',
    owner: 'Michael Lee',
    description: 'Implement a new HR management system to streamline processes.',
    total_budget_estimated: '1,200,000',
    key_objectives: 'Automate employee onboarding and payroll.',
    status: 'Draft',
    status_id: 1,
  },
  {
    id: 5,
    document_number: 'BE-005',
    title: 'Project Omega',
    year: '2023',
    initiate_date: new Date('2023-06-22').toString(),
    department: 'Head Office',
    segmentvalue_id: 66,
    project: 'Logistics Improvement',
    owner: 'Sophia Brown',
    description: 'Streamline logistics and reduce delivery time by 20%.',
    total_budget_estimated: '900,000',
    key_objectives: 'Optimize the supply chain and delivery network.',
    status: 'In-review',
    status_id: 3,
  },
  {
    id: 6,
    document_number: 'BE-006',
    title: 'Project Zeta',
    year: '2020',
    initiate_date: new Date('2020-03-19').toString(),
    department: 'HR Technology',
    segmentvalue_id: 73,
    project: 'Talent Acquisition',
    owner: 'James Wilson',
    description: 'Attract top talent for the company’s expansion plans.',
    total_budget_estimated: '300,000',
    key_objectives: 'Hire 50 new employees by the end of the year.',
    status: 'Approved',
    status_id: 4,
  },
  {
    id: 7,
    document_number: 'BE-007',
    title: 'Project Alpha',
    year: '2023',
    initiate_date: new Date('2023-08-10').toString(),
    department: 'Head Office',
    segmentvalue_id: 66,
    project: 'Budget Allocation',
    owner: 'Olivia Martinez',
    description: 'Review and reallocate departmental budgets for Q4.',
    total_budget_estimated: '750,000',
    key_objectives: 'Optimize spending for the end of the fiscal year.',
    status: 'Re-call',
    status_id: 2,
  },
]
