import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import ToggleSwitch from '../../Forms/ToggleSwitch'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'

const RFQBiddingProposal = (props) => {

    const gridLinkValueFormat = (params) => {
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }

    const GridColumnsHeadingProposalLines = [
        {field:'pr_number', headerName:Helper.getLabel(props.language,'pr_number',"PR Number"), cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat, minWidth: 200, },
        {field:'entity', headerName:Helper.getLabel(props.language,'entity',"Entity"), minWidth: 210,},
        {field:'site', headerName:Helper.getLabel(props.language,'site',"Site"), minWidth: 210, },
        {field:'wear_house', headerName:Helper.getLabel(props.language,'wear_house',"Wear house"), minWidth: 240,},
        {field:'requisition', headerName:Helper.getLabel(props.language,'requisition',"Requisition"), minWidth: 280,},
        {field:'qty_req', headerName:Helper.getLabel(props.language,'qty_req',"Qty Req"),minWidth: 200,},
        {field:'qty_rec', headerName:Helper.getLabel(props.language,'qty_rec',"Qty Rec"),minWidth: 200, },
        {field:'unit_price', headerName:Helper.getLabel(props.language,'unit_price',"Unit Price"),minWidth: 200, },
        {field:'date', headerName:Helper.getLabel(props.language,'date',"Date"), inputType: "datepicker",minWidth: 200, },
    ]

    const GridColumnsHeadingLines = [
        {field:'description', headerName:Helper.getLabel(props.language,'description',"Description"), minWidth: 250, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat,  },
        {field:'category', headerName:Helper.getLabel(props.language,'category',"Category"), minWidth: 130,},
        {field:'type', headerName:Helper.getLabel(props.language,'type',"Type"), minWidth: 170, },
        {field:'quantity', headerName:Helper.getLabel(props.language,'quantity',"Quantity"), minWidth: 150,},
        {field:'unit', headerName:Helper.getLabel(props.language,'unit',"Unit"), minWidth: 160,},
        {field:'unit_price', headerName:Helper.getLabel(props.language,'unit_price',"Unit Price"), minWidth: 160,},
        {field:'line_amount', headerName:Helper.getLabel(props.language,'line_amount',"Line Amount"), minWidth: 160,}, 
    ]
 
    const ToggleChangeHandler = (value) => {}

    const RenderGridProposalLabel = () => {
        return (
          <h2 className='grid_label_rfq'>Proposal Line</h2>
        )
    }
 
    const RenderGridLinesLabel = () => { 
        return ( 
            <h2 className='grid_label_rfq me-4'>Lines</h2>   
        )
    }

    const RenderGridCategoryLabel = () => {
        return (
            <button className='rfq_category_btn me-2'><span className=''><img className='img-fluid mb-1 me-1' src='/images/icons/category.svg' /></span><span className=''> Add Category</span></button> 
        )
    }
  
  return (
    <Fragment>
        <AgGrid id='proposal_line_grid' header={GridColumnsHeadingProposalLines} afterDeleteButtons={RenderGridProposalLabel} data={[]} hideSave={true} addBtnHide={true} hideDelete={true} />
        <ToggleSwitch
            margin={'mb-4'}
            onChange={ToggleChangeHandler} 
            title='Do you want to propose any alternate item/service then requested in this proposal?' 
        /> 
        <AgGrid id='lines_grid' labels={{btnDelete: "Remove", btnAdd: "Add Item", }} beforeSaveButton={RenderGridCategoryLabel} header={GridColumnsHeadingLines} beforeAddButton={RenderGridLinesLabel} data={[]} hideSave={true} addBtnHide={false} hideDelete={false} />
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}
  

export default connect(mapStateToProps) (RFQBiddingProposal) 