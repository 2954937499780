import React, { useState, useEffect } from 'react';
import './TenantForm.css';
import TenantFields from '../Tenant Fields/TenantFields';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { connect } from 'react-redux';
import SimpleLoading from '../../Loading/SimpleLoading';
import Alert from '../../inc/Alert';
import LinkBtn from '../../inc/LinkBtn';
import Button from '../../inc/Button';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Helper from '../../../inc/Helper';
import TextTranslations from '../../Popup/TextTranslation/TextTranslations';
import AddressList from '../Address/AddressList';
import Collapse from '../../inc/Collapse';
import ContactAddress from '../Address/ContactAddress';
import FormValidator from '../../../inc/FormValidator';
import RsWithRouter from '../../inc/RsWithRouter';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Form_gui_ids from '../../../inc/Form_gui_ids';

const TenantForm = (props) => {
  const [userTypes, setUserTypes] = useState([]);
  const [tenantFormData, setTenantFormData] = useState({
    isactive: false,
    enable: false,
  });
  const [tenant, setTenant] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [displaySearchResultPopup, setDisplaySearchResultPopup] = useState(false);
  const [isSarchingTenants, setIsSarchingTenants] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');
  const [validator, setValidator] = useState(null);
  
  useEffect(() => {
    validationConfigure();
  }, []);

  const validationConfigure = () => {
    const language = props.language;
    let fieldConfig = [
      { name: 'code', displayName: Helper.getLabel(language, 'code', 'Code'), types: ['Required'], max: 10 },
      { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'], max: 100 },
      { name: 'start_date', displayName: Helper.getLabel(language, 'start_date', 'Started Date'), types: ['Required'] },
      { name: 'end_date', displayName: Helper.getLabel(language, 'end_date', 'End Date'), types: ['Required'] },
      { name: 'first_name', displayName: Helper.getLabel(language, 'first_name', 'First name'), types: ['Required'] },
      { name: 'user_email', displayName: Helper.getLabel(language, 'user_email', 'Email'), types: ['Required', 'Email'] },
      { name: 'user_type', displayName: Helper.getLabel(language, 'user_type', 'User Type'), types: ['Required'] },
      { name: 'isactive', displayName: Helper.getLabel(language, 'active', 'Active'), types: ['Required'] },
    ];
    setValidator(new FormValidator(fieldConfig, language));
  };
 
  const onTanentSaveHandler = () => {
    let formData = tenantFormData;

    let validData = {
      ...formData,
    };
    if (!validator.isValid(validData)) {
      validator.displayMessage();
      return;
    }
    setIsSarchingTenants(false);
    setTenant({});
    setIsLoading(true);
    setMessage(null);
    setMessageType('fail');

    let api = Api;
    api.setUserToken();

    let tenantData = {
      code: formData.code,
      name: formData.name,
      isactive: formData.isactive === "true" ? true : false,
      start_date: Helper.getDateForDb(formData.start_date),
      end_date: Helper.getDateForDb(formData.end_date),
      first_name: formData.first_name,
      last_name: formData.last_name,
      middle_name: formData.middle_name,
      user_email: formData.user_email,
      enable: formData.isactive === "true" ? true : false,
      is_deleted: false,
      default_login_entity: formData.default_login_entity,
      SID: formData.SID,
      language_id: 1,
      domain_code: formData.domain_code,
      usertype: formData.user_type,
      createdby: props.auth.user.user_id,
      modifiedby: props.auth.user.user_id,
      address: null,
      contact: null,
    };

    api.axios().post(Settings.apiUrl + '/add_tenant', tenantData).then((res) => {
      if (res.data.Status === 'Success') {
        Helper.alert(res.data.Message);
        props.rs_router.navigate('/tenants');
      }
      setIsLoading(false);
      setMessage(null);
      setMessageType(res.data.Status);
    }).catch((e) => {
      setIsLoading(false);
      setMessage(e.response.data.message);
      setMessageType(e.response.data.Status);
    });
  };

  const onAddClickHandler = () => {
    setIsSarchingTenants(false);
    setTenant(null);
  };

  const onSearchClickHandler = () => {
    setDisplaySearchResultPopup(true);
    setIsSarchingTenants(true);
  };

  const onUserPopupClose = () => {
    setDisplaySearchResultPopup(false);
    setIsSarchingTenants(true);
  };

  const onPopupTenantSelectHandler = (tenantData) => {
    setDisplaySearchResultPopup(false);
    setTenant(tenantData);
  };

  const language = props.language;

  if (!props.user?.admin_user) {
    return <Alert type="danger" message={Helper.getLabel(language, 'no_access', 'No Access')} />;
  }

  return (
    <section className='tenant-form-section'>
      <div className='container-fluid'>
        <NavigationHeder backUrl="/tenants" title={Helper.getLabel(language, 'tenant', "Create New Tenant")}>
          <TextTranslations />
          <LinkBtn isActive={false} to="/tenants" title={Helper.getLabel(language, 'Close', "Close")} className="black-btn-style" />
          <Button onClick={onTanentSaveHandler} icon={"/images/icons/save-white.svg"} isActive={true} title={Helper.getLabel(language, 'save', "Save")} />
        </NavigationHeder>

        {isLoading ? <SimpleLoading width="50px" /> : ''}

        {message ? <Alert message={message} type={messageType === 'Success' ? 'success' : 'danger'} /> : ''}
        <div className='address_contact_section'>
          <TenantFields validator={validator} data={tenant ? tenant : {}} isSearchMode={isSarchingTenants} setFormData={setTenantFormData} />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(RsWithRouter(TenantForm));