import React, { Component } from 'react';
import Collapse from '../../inc/Collapse';
import Button from '../../inc/Button';
import FileUpload from '../../Widgets/FileUpload/FileUpload';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import SimpleLoading from '../../Loading/SimpleLoading';
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
class Documents extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading:false,
            files:[],
            selectedCategory:null,
            attachmentsTypes:[],
            attachmentsource_id:null,
            mandatoryAttachments:[]
        }
    }
    componentDidMount(){
        this.loadAttachmentTypes();
        this.loadFiles();
        this.loadAttachmentMandatory()
    }
    loadAttachmentTypes(){
        let api = Api;
        api.setUserToken();
        let that = this;
        this.setState({
            isLoading:true
        })
        let source_id = this.props.source_id
        api.axios().get( Settings.apiUrl + '/attachmenttype').then(function(res){
            if(res.data.status ==='success'){
                let attachmentsTypes = res.data.data.reverse().filter((item)=>item.source_id === source_id);
                let selectedCategory = !that.state.selectedCategory && attachmentsTypes.length >0 ? attachmentsTypes[0].attachmenttype_id : that.state.selectedCategory;
                that.setState({
                    isLoading:false,
                    attachmentsTypes:attachmentsTypes,
                    selectedCategory:selectedCategory
                })
            }else{
                that.setState({
                    isLoading:false
                })
            }
        }).catch(error=>{
            console.log(error)
        })
    }

    loadAttachmentMandatory() {
      let api = Api;
      api.setUserToken();
      api.axios()
        .get(Settings.apiVendorUrl + `/vendor_required_attachments/${this?.props?.data?.vendor_id}`)
        .then((res) => { 
          if (res.data.status === 'success') {
            this.setState({
              mandatoryAttachments: res.data.data
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
    loadFiles(){
        let that = this;
        let api = Api;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        let attachmentsource_integrator= this.props.attachmentsource_integrator;
        let source_id= this.props.source_id;
        let apiUrl = Settings.apiUrl + '/get_attachments/'+source_id+'/'+attachmentsource_integrator;
        api.axios().get(apiUrl).then(function(res){
            if(res.data.status==="success"){
                that.setState({
                    isLoading:false,
                    files: res.data.data,
                    attachmentsource_id:res.data?.data[0]?.attachmentsource_id,
                })
                if(res.data?.data?.length >= 1){
                  that.props.updateStep && that?.props?.updateStep(6,true)
                }else {
                  that.props.updateStep && that?.props?.updateStep(6,false)
                }
            }
        }).catch((err)=>{
          getPopupMessageBasedOnStatus(err)
           that.setState({
            isLoading:false,
           })
        })
    }

    fileDeleteApiCall(file){
      let api = Api;
      api.setUserToken();
      let that = this;
      api.axios().delete(Settings.apiUrl + '/attachment/'+file.attachment_id).then(function(res){
          if(res.data.status ==='success'){
              Helper.alert(res.data.message,"success")
              that.setState({
                  isLoading:false
              },function(){
                  that.loadFiles()
              })
          }     
      })
      .catch(error => {
        that.setState({
          isLoading:false
      })
        getPopupMessageBasedOnStatus(error)
      })
    }
  
    fileDeleteHandler(file){
      Helper.confirmMe(()=>{this.fileDeleteApiCall(file)}, Helper.getLabel(null, 'attachment_del', `This document can not be recovered once deleted.`),null,"Are you sure you want to delete?",450)
    }
    onFileUpload(file){
        this.loadFiles()
    }
    onGroupTabClick(e,categoryId){
        this.setState({
            selectedCategory:categoryId
        })
    }

    goStep(stepNumber){
      this.props.goStep(stepNumber)
    }

    downloadFile(file){
        let api = Api;
        api.setUserToken();
        let that = this;

        api.axios().get(Settings.apiUrl + '/get_attachment/'+file.attachment_id,{responseType:"blob"}).then(function(res){
            if(res.status === 200){
              const response = res
              const contentDisposition = response.headers['content-disposition']
              const fileName =
                contentDisposition?.split('filename=')[1] || 'dummy.jpg'
              const fileBlob = new Blob([response.data], {
                type: response.headers['content-type'],
              })
              const downloadLink = document.createElement('a')
              downloadLink.href = URL.createObjectURL(fileBlob)
              downloadLink.download = fileName
    
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
     })}

     onTemplateDownloadClick(id){
      if(!id){
          return;
      }
      let api = Api
      api.setUserToken()
      api
        .axios()
        .get(Settings.loginUrl + `/get_attachmenttype_doc/${id}`,{ responseType: 'blob' })
        .then(async function (res) {
          if (res.status === 200) {
            const response = res
            const contentDisposition = response.headers['content-disposition']
            const fileName =
              contentDisposition?.split('filename=')[1] || 'dummy.jpg'
            const fileBlob = new Blob([response.data], {
              type: response.headers['content-type'],
            })
            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(fileBlob)
            downloadLink.download = fileName
  
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res) 
        })
    }
    
    render() {
        let activeStep = this.props.activeStep;
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        let data = this.props.data;
        let allFiles = this.state.files;
        return (
          <div>
            <div className='row'>
              <div className='col-md-4'>
                <Collapse title='Please Select Document Type' id="d1" open={true} stopCollapsable={true} className="documents-collapse">
                  <ul className='documents_category_list'>
                    {this.state.attachmentsTypes.map((item) => {
                      return (
                        <li key={item.attachmenttype_id} className={item.attachmenttype_id == this.state.selectedCategory ? 'active d-flex gap-2 align-items-center' : 'd-flex gap-2 align-items-center'}>
                          <span
                            className='ct_name'
                            onClick={(e) => {
                              this.onGroupTabClick(e, item.attachmenttype_id)
                            }}
                          >
                            {item.name} {(item.mandatory || this.state.mandatoryAttachments?.includes(item.attachmenttype_id)) && <span className='text-danger'>*</span>}
                          </span>
                          {item?.is_template_based_attachment && (
                            <OverlayTrigger placement='top' overlay={<Tooltip id={`tooltip-download-template`}>Download Template</Tooltip>}  popperConfig={{ modifiers: [ { name: 'offset', options: { offset: [0, -8],}}]}}>
                              <button className='p-0 m-0 bg-transparent border-0' onClick={() => { this.onTemplateDownloadClick(item?.attachmenttype_id)  }}>
                                <img src='/images/icons/print.svg' alt='download-icon' height='28px' style={{ paddingTop: '12px' }} />
                              </button>
                            </OverlayTrigger>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </Collapse>
              </div>
              
              <div className='col-md-8'>
                <Collapse title='Upload Files' open={true} id="d2" stopCollapsable={true} className="documents-collapse">
                  <FileUpload
                    document_type={this.props.document_type}
                    source_id={this.props.source_id}
                    attachmentsource_integrator={
                      this.props.attachmentsource_integrator
                        ? this.props.attachmentsource_integrator
                        : data?.vendor_id
                        ? data.vendor_id
                        : null
                    }
                    attachmentsource_id={
                      this.props?.attachmentsource_id
                        ? this.props.attachmentsource_id
                        : data?.attachmentsource_id
                        ? data.attachmentsource_id
                        : null
                    }
                    attachmenttype_id={this.state.selectedCategory}
                    onFileUpload={this.onFileUpload.bind(this)}
                    userType={this.props.auth?.user?.usertype}
                  />
                </Collapse>
              </div>
            </div>
           {this.state.files?.length === 0 ? null : <div className='mt-4'>
              <ul className='documents_category_list'>
                {this.state.attachmentsTypes?.map((item) => {
                  let categoryFiles = allFiles.filter((file) => {
                    return file.attachmenttype_id == item.attachmenttype_id &&
                      file.attachmentsource_id ===
                        this.state?.attachmentsource_id
                      ? file
                      : null
                  })
                  return (
                    categoryFiles.length>0 && <li
                      key={item.attachmenttype_id}
                      className={
                        item.attachmenttype_id == this.state.selectedCategory
                          ? 'active'
                          : ''
                      }
                    >
                      <span className="document-file-name fw-bold" style={{color:"#2d74e0"}}>
                        {item.name}
                      </span>
                      <ul className='d-flex flex-column mt-3'>
                        {categoryFiles.map((file) => {
                          return (
                            <li key={item.attachmenttype_id}>
                              <div className={this.props.auth?.user?.usertype===0?'file_item document-internal-tile':'file_item document-external-tile'}>
                                <div className='d-flex gap-2 align-items-center justify-content-center'>
                                <img src='/images/icons/document-gray.svg' alt="document" height={35}/>
                                <div className='d-flex flex-column gap-1'>
                                <p className='mb-0 document-file-name download-file-style' onClick={()=>this.downloadFile(file)}>{file.filename}</p>
                                <p className='mb-0 document-file-name'>{DatepickerFunctions.convertDateTimeFromDataBase(file.createddatetime)} . {file?.attachmenttype_name}</p>
                                </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                <p className='mb-0 document-file-name'>{Number.parseFloat(file.filesize).toFixed(3)} Mb</p>
                                {((data?.document_status==="Draft") || (this?.props?.document_status===0)) &&<img
                                  src='/images/icons/delete.svg'
                                  onClick={(e) => {
                                    this.fileDeleteHandler(file)
                                  }}
                                  alt='Delete'
                                />}
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </div>}
            {this.props.document_type === 'invoice' ? null : (
              <>
                <div className='mt-4'></div>
                <div className='tabs_changer'>
                  {activeStep >= 2 ? (
                    <Button
                      className='light_gray btn_back'
                      title='Back'
                      onClick={(e) => {
                        this.state.files?.length > 0 ? 
                        this.props.updateStep(6,true) : this.props.updateStep(6,false) 
                        this.goStep(activeStep - 1)
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <Button
                    className='blue'
                    title='Next'
                    onClick={(e) => {
                      this.state.files?.length > 0 ? 
                      this.props.updateStep(6,true) : this.props.updateStep(6,false) 
                      this.goStep(activeStep + 1)}}
                  />
                </div>
              </>
            )}
          </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      appOptions: state.options,
      auth: state.auth,
      language: state.language,
    };
  };
  
export default connect(mapStateToProps)(Documents);
  
