import React from 'react'
import Popup from '../../../Popup/Popup'
import $ from 'jquery'
import { checkIfArrayIsEmpty } from '../../../../inc/Validation';

const TermsHTMLDisplayPopup = ({ content, detailedView=false, completeTemplateDetails=[], content_otherlanguage, heading_otherlanguage, templateTitle, ClosePopupView }) => {
 
    const onPopupCloseClickHandler = () => {
        $('.terms_html_content_display .rs_popup_close').trigger('click');
        ClosePopupView((prevState) => {
            return {
                ...prevState,
                openPopup: false
            }
        })
    };

    const TermsConditionsNormalView = () => {
        return (
            <> 
                <div className='row'>
                    <div className='col-6'> 
                        <div className='template_headings_for_content'>
                            <h2><span>{templateTitle}</span></h2> 
                        </div>
                    </div>
                    <div className='col-6'> 
                        <div className='template_headings_for_content' style={{direction: 'rtl'}}>
                            <h2><span>{heading_otherlanguage}</span></h2>
                        </div>
                    </div>
                </div>

                <div className='container-fluid p-0 m-0'>
                    {Array.isArray(content) ? 
                        content.map((item, index) => (
                            <div className='row' key={index}>
                                <div className='col-6'>
                                    <div className='content_wrapper_html'>  
                                        <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                                    </div>
                                </div>
                                <div className='col-6' style={{ direction: 'rtl' }}>
                                    <div className='content_wrapper_html'>
                                        <div dangerouslySetInnerHTML={{ __html: item?.content_otherlanguage }} />
                                    </div>
                                </div>
                            </div>
                        )) 
                        :
                        <div className='row'>
                            <div className='col-6'>
                                <div className='content_wrapper_html'>  
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            </div>
                            <div className='col-6' style={{ direction: 'rtl' }}>
                                <div className='content_wrapper_html'>  
                                    <div dangerouslySetInnerHTML={{ __html: content_otherlanguage }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    };

    const TermsConditionsDetailView = () => {
        return (
            <>
                {completeTemplateDetails?.map((item) => {
                    return (
                        <> 
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='template_headings_for_content'>
                                        <h2><span>{item?.heading}</span></h2>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='template_headings_for_content' style={{ direction: 'rtl' }}>
                                        <h2><span>{item?.heading_otherlanguage}</span></h2>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='container-fluid p-0 m-0'>
                                {!checkIfArrayIsEmpty(item?.content) &&
                                    item?.content?.map((element, index) => (
                                        <div className='row' key={index}>
                                            <div className='col-6'>
                                                <div className='content_wrapper_html'>
                                                    <div dangerouslySetInnerHTML={{ __html: element?.content }} />
                                                </div>
                                            </div>
                                            <div className='col-6' style={{ direction: 'rtl' }}>
                                                <div className='content_wrapper_html'>
                                                    <div dangerouslySetInnerHTML={{ __html: element?.content_otherlanguage }} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                })}
            </>
        )
    };

    return (
        <Popup className="terms_html_content_display" autoOpen={true} width="1200px" onClose={onPopupCloseClickHandler}>
            { detailedView && 
                <div className='row'>
                    <div className='col-12 text-center template_headings_for_content'>
                        <h2>Template: <span>{templateTitle}</span></h2>
                    </div>
                </div>
            }
            { !detailedView ? TermsConditionsNormalView() : TermsConditionsDetailView() }
        </Popup>
    )
}

export default TermsHTMLDisplayPopup;