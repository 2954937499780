import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import Popup from "../../Popup/Popup";
import Helper from "../../../inc/Helper";
import { Form } from "react-bootstrap";
import Button from "../../inc/Button";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import Dropdown from "../../Forms/Dropdown";
import RFQCommonApiCalls from "../RFQCommonApiCalls";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

const AddVendorModal = ({
  language,
  handleCloseModal,
  handleSave,
  isConverting,
  setSelectedDocType,
}) => {
  const commonApiCalls = new RFQCommonApiCalls();

  const vendorDataRef = useRef([]);
  const vendorSitesDataRef = useRef([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorSiteList, setVendorSiteList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVendorSite, setSelectedVendorSite] = useState({});

  useEffect(() => {
    const getAllVendors = () => {
      const api = Api;
      api.setUserToken();
      api
        .axios()
        .get(Settings.apiVendorUrl + "/get_active_vendors")
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === "success") {
            setVendorList(res?.data?.data?.map((item) => {
              return {
                value: item.vendor_id,
                label: item.name
              }
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          Helper.alert(error?.response?.data?.message, "error");
        });
    };

    getAllVendors();

    const getAllVendorSites = () => {
      let api = Api;
      api.setUserToken();
      api
        .axios()
        .get(Settings.apiVendorUrl + "/get_active_vendor_sites")
        .then(function (res) {
          if (res.data.status === "success") {
            setVendorSiteList(res.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
          Helper.alert(error?.response?.data?.message, "error");
        });
    };

    getAllVendorSites();
  }, []);

  const getVendorSitesByVendorId = (id) => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiVendorUrl + "/get_active_vendor_sites/" + id)
      .then(function (res) {
        if (res.data.status === "success") {
          let defaultSite = res?.data?.data?.find((item)=>item.is_default === true)
          setSelectedVendorSite(defaultSite?.vendorsite_id)
          setVendorSiteList(res.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const getVendorFromSite = async (siteId) => {
    const obj = vendorSiteList?.find(
      (item) => parseInt(item.vendorsite_id) === parseInt(siteId)
    );
    const vendor = await commonApiCalls.getVendorByVendorId(obj?.vendor_id);
    setVendorList([vendor]);
    document.getElementById("vendor_id").selectedIndex = 1;
  };

  const mapVendorData = (item) => {
    return {
      text: `${item.name}`,
      id: item.vendor_id
    };
  }

  const mapVendorSiteData = (item) => {
    return {
      text: `${item.name}`,
      id: item.vendorsite_id
    };
  }

  return (
    <>
    <Popup
      autoOpen={true}
      width="900px"
      className="rf_radio_form_modal"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setSelectedDocType(null);
        handleCloseModal();
      }}
    >
      {/* {isLoading || isConverting ? (
        <></>
      ) : ( */}
        <>
          <h5>
            {Helper.getLabel(
              language,
              "add_vendor_details_to_continue",
              "Add vendor details to continue"
            )}
          </h5>
          <Form className="addVendorModalForm">
            <div>
              <Dropdown
                label={`${Helper.getLabel(
                  language,
                  "vendor",
                  "Vendor"
                )}`}
                options={  
                  vendorList
                }
                apiUrl={Settings.apiVendorUrl+`/get_active_vendors`} 
                pagination={true}
                mapFunction={mapVendorData} 
                allDataRef={vendorDataRef}  
                placeHolder={`${Helper.getLabel(
                  language,
                  "select_vendor",
                  "Select vendor"
                )}`}
                id="vendor_id"
                onChange={(e) => {
                  setSelectedVendor(e.target.value);
                  getVendorSitesByVendorId(e.target.value);
                }}
                value={selectedVendor}
                reRenderRequired={true}
              />
            </div>

            <div>
              <Dropdown
                apiUrl={selectedVendor?Settings.apiVendorUrl+`/get_active_vendor_sites/${selectedVendor}`:null} 
                pagination={true}
                mapFunction={mapVendorSiteData} 
                allDataRef={vendorSitesDataRef}  
                label={`${Helper.getLabel(
                  language,
                  "select_vendor_site",
                  "Vendor site"
                )}`}
                options={
                  vendorSiteList?.map((item) => {
                    return {
                      value: item.vendorsite_id,
                      label: item.name,
                    };
                  }) ?? []
                }
                placeHolder={`${Helper.getLabel(
                  language,
                  "select_vendor_site",
                  "Select vendor site"
                )}`}
                id="vendor_site_id"
                onChange={(e) => {
                  setSelectedVendorSite(e.target.value);
                  if (selectedVendor === null) {
                    getVendorFromSite(e.target.value);
                  }
                }}
                value={selectedVendorSite}
                reRenderRequired={true}
              />
            </div>
          </Form>
          <div
            className="addDocumentNumber-body-buttons"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              isActive={false}
              className="black-btn-style"
              title={Helper.getLabel(language, "Close", "Close")}
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                handleCloseModal();
              }}
            />
            <Button
              className="solid_btn"
              isActive={true}
              title={Helper.getLabel(language, "save", "Save")}
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                handleSave(selectedVendor, selectedVendorSite);
              }}
            />
          </div>
        </>
      {/* )} */}
    </Popup>
    {isLoading || isConverting ? <OverlayLoader isLogoCenter={true} /> : null}
    </>
  );
};

export default AddVendorModal;
