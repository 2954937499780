import { createSlice } from '@reduxjs/toolkit';

const systemAPI = createSlice({
    name:'Grid Data From Dropdown',
    initialState: {
        burger_menu_logo: undefined,
        burger_menu_background_color: undefined,
        default_language_id: undefined,
        file_server_path: undefined,
        smtp_port_number: undefined,
        email_user_name: undefined,
        email_header_image: undefined,
        email_footer_image: undefined,
        attachment_size_limit_MB: 5,
        outgoing_mail_server: undefined,
        max_email_connection_time_sec: undefined,
        min_email_interval_connection_sec: undefined,
        max_email_message_size_KB: undefined,
        max_email_message_batch_size_KB: undefined,
        mailusernamepassword:undefined,
        mailuseSSL:undefined,
        mailuseTLS:undefined
    },
    reducers: {
        BurgerLogoChange(state, action) { 
            state.burger_menu_logo = action.payload.payload.burger_logo
            
        }, 
        BurgerLogoBackgroundChange(state, action) {
            state.burger_menu_background_color = action.payload.payload.burger_bg_color 
             
        },
        LanguageIdChange(state, action) {
            state.default_language_id = action.payload.payload.id
             
        },
        fileServerChange(state, action) { 
            state.file_server_path = action.payload.payload.file_path 
           
        }, 
        smtpPortChange(state, action) {
            state.smtp_port_number = action.payload.payload.smtp_num
           
        },
        emailUserNameChange(state, action) {
            state.email_user_name = action.payload.payload.email
             
        },
        emailHeaderImageChange(state, action) {
            state.email_header_image = action.payload.payload.email_header_image
           
        },
        emailFooterImageChange(state, action){
            state.email_footer_image = action.payload.payload.email_footer_image
            
        },
        attachmentSizeMB(state, action) {
            state.attachment_size_limit_MB = action.payload.payload.attachment_size
             
        },
        OutgoingMailServer(state, action) {
            state.outgoing_mail_server = action.payload.payload.outgoing_mail
            
        },
        MaxEmailConnectionSec(state, action) {
            state.max_email_connection_time_sec = action.payload.payload.max_email_connection_time_sec
            
        },
        MinEmailIntervalSec(state, action) {
            state.min_email_interval_connection_sec = action.payload.payload.min_email_interval_sec
             
        },
        MaxEmailMessageKB (state, action) {
            state.max_email_message_size_KB = action.payload.payload.max_email_message_size_KB
           
        },
        MaxEmailMessageBatchKB (state, action) {
            state.max_email_message_batch_size_KB = action.payload.payload.max_email_message_batch_KB
             
        }, 
        MailPasswordChange (state, action) {
            state.mailusernamepassword = action.payload.payload.mailusernamepassword
             
        },  
        MailUseSSLChange (state, action) {
            state.mailuseSSL = action.payload.payload.mailuseSSL
             
        }, 
        MailUseTLSChange (state, action) {
            state.mailuseTLS = action.payload.payload.mailuseTLS
             
        }, 
      
    }

});

 

export const systemActions = systemAPI.actions;
export default systemAPI;
