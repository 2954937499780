import React from 'react';
import EditGoodsReceivingNote from '../../components/GoodsReceiving/CreateNote/EditGoodsReceivingNote';
import Helper from '../../inc/Helper';
import Gui_id_list from '../../inc/Gui_id_list';
import { connect } from 'react-redux';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import MasterComonent from '../../components/Backend/MasterComonent';
import Alert from '../../components/inc/Alert';

const ContractGoodReceivingNoteEdit = ({security, auth}) => {
    const renderGuiList = () => { 
        if(auth?.user?.usertype === 0){
            return {
                screen: Gui_id_list.receiving.goods_receiving.goods_receiving_main,
                addBtn: Gui_id_list.receiving.goods_receiving.goods_receiving_main_create_button,
                deleteBtn: Gui_id_list.receiving.goods_receiving.goods_receiving_main_delete_button
            }
        } else {
            return ''
        }
    };

    if (renderGuiList() !== "" && !security.canView(renderGuiList()?.screen)) {
        return ( 
            <MasterComonent>
                <Alert 
                    message="You do not have any access to the following screen, please contact your administrator!" 
                    type="danger" 
                />
            </MasterComonent> 
        );
    };

    let addBtnDisable = renderGuiList() !== "" && !security.canCreate(renderGuiList()?.addBtn);
    let deleteBtnDisable = (renderGuiList() !== "" && !security.canDelete(renderGuiList()?.deleteBtn));

    return (
        <EditGoodsReceivingNote
            addBtnDisable={addBtnDisable}
            deleteBtnDisable={deleteBtnDisable}
            title={Helper.getLabel(null, 'Edit good receiving note', 'Edit good receiving note')}
            backUrl='/contract-order/good-receiving/notes'
        />
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const SecurityOptions = {
    gui_id: Gui_id_list.receiving.goods_receiving.goods_receiving_main
};

export default connect(mapStateToProps) (ApplySecurityRoles(ContractGoodReceivingNoteEdit, SecurityOptions));