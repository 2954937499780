import React from 'react';
import { Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import AddIcon from "../../../../assets/add-new.svg";
import ViewIcon from "../../../../assets/view.svg";
import LikeIcon from "../../../../assets/like.svg";
import Helper from '../../../../inc/Helper';

const VendorsButtons = ({language,rfqHeader,handleInviteVendor,handlePendingInvite,handleSuggestedVendor,isDisabled}) => {
    return (
      <div className="list_view_action_btns_grid">
        <Dropdown className="rfq-header-dropdown">
        <Dropdown.Toggle className="btn_more tab_menu_toggle_btn">
            <img src="/images/icons/more-vertical.svg" alt="More Options" className='rfq_droptown_toggle_img' />
          </Dropdown.Toggle>
          <Dropdown.Menu className='rfq_vendor_dropdown_menu'>
            <DropdownItem className='rfq_dropdown_item'><button onClick={isDisabled?'':handleInviteVendor} className={rfqHeader?.rfq_status===3?"ctl-dissable":""}>
              <span className="me-2">
                <img src={AddIcon} className="img-fluid" alt="invite a vendor" />
              </span>
              {Helper.getLabel(language, "invite_a_vendor", "Invite a vendor")}
            </button></DropdownItem>
            <DropdownItem className='rfq_dropdown_item'><button onClick={isDisabled?'':handleSuggestedVendor} className={rfqHeader?.rfq_status===3?"ctl-dissable":""}>
              <span className="me-2">
                <img src={ViewIcon} className="img-fluid" alt="suggested vendor" />
              </span>
              {Helper.getLabel(language, "suggested_vendor", "Suggested vendor")}
            </button></DropdownItem>
            <DropdownItem className='rfq_dropdown_item'><button onClick={isDisabled?'':handlePendingInvite} className={rfqHeader?.rfq_status===3?"ctl-dissable":""}>
              <span className="me-2">
                <img
                  src={LikeIcon}
                  className="img-fluid"
                  alt="Invited vendor history"
                />
              </span>
              {Helper.getLabel(
                language,
                "invited_vendor_history",
                "Invited vendor history"
              )}
            </button></DropdownItem>
          </Dropdown.Menu>
        </Dropdown>
        <div className="common-grid-responsive-btns">
          <button onClick={isDisabled?'':handleInviteVendor} className={isDisabled?"ctl-dissable":""}>
            <span className="me-2">
              <img src={AddIcon} className="img-fluid" alt="invite a vendor" />
            </span>
            {Helper.getLabel(language, "invite_a_vendor", "Invite a vendor")}
          </button>
          <button onClick={isDisabled?'':handleSuggestedVendor} className={isDisabled?"ctl-dissable":""}>
            <span className="me-2">
              <img src={ViewIcon} className="img-fluid" alt="suggested vendor" />
            </span>
            {Helper.getLabel(language, "suggested_vendor", "Suggested vendor")}
          </button>
          <button onClick={isDisabled?'':handlePendingInvite} className={isDisabled?"ctl-dissable":""}>
            <span className="me-2">
              <img
                src={LikeIcon}
                className="img-fluid"
                alt="Invited vendor history"
              />
            </span>
            {Helper.getLabel(
              language,
              "invited_vendor_history",
              "Invited vendor history"
            )}
          </button></div>
      </div>
    );
  };

export default VendorsButtons;
