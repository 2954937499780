import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    status:[]
}
const PoDocumentStatusReducer = ( state = defaultState, actions) =>{
    if(ActionTypes.SET_PO_DOCUMENT_STATUS === actions.type){
        state = {
            ...state,
            isLoaded:true,
            status:actions.payload
        }
    }
    return state;
}
export default PoDocumentStatusReducer;