import React, { Component } from 'react';
import Helper from '../../../../inc/Helper';
import FormValidator from '../../../../inc/FormValidator';
import Button from '../../../inc/Button';
import InputDatePicker from '../../../Forms/InputDatePicker';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import NewInputDatePicker from '../../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../../inc/DatepickerHelper';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';

/**

 */
class AmendmentDatePopupButton extends Component {
    constructor(props){
        super(props);
        this.validationConfigure()
        this.state = {
            isDropdownOpen:false,
            durations:{
                new_start_date:'',
                new_end_date:'',
            },
            amendmentDurations: []
        }
    }
    componentDidMount(){
        this.loadAmendmentDurations();
    }
    validationConfigure(){
        const language = null;
        let fieldConfig = [
            {name:'new_start_date',displayName:Helper.getLabel(language,'new_start_date_required','New start date'),types:['Required']},
            {name:'new_end_date',displayName:Helper.getLabel(language,'new_end_date_required','New end date'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    loadAmendmentDurations(){
        let {amendment_id} = this.props;
        if(!amendment_id){
            return;
        }
        let api= Api, that =this;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseOrderUrl+'/amendmentdurations/'+amendment_id).then(res=>{
            that.setState({
                amendmentDurations:res.data.data
            })
        }).catch(error=>{
            getPopupMessageBasedOnStatus(error)
        })
    }
    createDurations(data){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().post(Settings.apiPurchaseOrderUrl+'/amendmentduration',data).then(res=>{
            if(res.data.status=='success'){
                Helper.alert(res.data.message);
            }else{
                Helper.alert(res.data.message,'error');
            }
            that.loadAmendmentDurations();
            that.setState({
                isDropdownOpen:false,
                durations:{
                    new_start_date:'',
                    new_end_date:'',
                }
            })
        }).catch(error=>{
            getPopupMessageBasedOnStatus(error)
        })
    }
    updateDurations(data){
        if(!data.amendmentduration_id){
            Helper.alert('amendmentduration_id not found','error')
            return;
        }
        let api = Api;
        api.setUserToken();
        let that = this;
        let newData = {
            start_date:data.start_date,
            end_date: data.end_date
        }
        api.axios().put(Settings.apiPurchaseOrderUrl+'/amendmentduration/'+data.amendmentduration_id,newData).then(res=>{
            if(res.data.status=='success'){
                Helper.alert(res.data.message);
            }else{
                Helper.alert(res.data.message,'error');
            }
            that.loadAmendmentDurations();
            that.setState({
                isDropdownOpen:false,
                durations:{
                    new_start_date:'',
                    new_end_date:'',
                }
            })
        }).catch(error=>{
            getPopupMessageBasedOnStatus(error)
        })
    }
    
    onDateChangeHanlder( value,name){
        let durations = this.state.durations;
        this.setState({
            durations:{
                        ...durations,
                        [name]: value
                    }
            })
    }
    onCancleClickHandler(){
        this.setState({
            isDropdownOpen:false
        })
    }
    getAmendmentDuration(amendmentline_type,integrator){
        
        let output = null;
        this.state.amendmentDurations.forEach(duration => {
            if(duration.amendmentline_type == amendmentline_type && duration.integrator_id == integrator){
                output = duration;
            }
        })
        return output;
    }
    getIntegratorForDuriation(){
        if(this.props.integrator_id && typeof this.props.integrator_id == 'function'){
            return this.props.integrator_id()
        }
        return this.props.integrator_id;
    }
    onSaveClickHandler(){
        let durations = this.state.durations;
        if( !this.validator.isValid(durations)){
            this.validator.displayMessage()
            return;
        }
        if(this.props.onClickSave){
            this.props.onClickSave(durations)
        }
        let oldAmendDuration = this.getAmendmentDuration(this.props.amendmentline_type,this.getIntegratorForDuriation());
        let newData = {
            amendment_id:this.props.amendment_id,
            amendmentline_type: this.props.amendmentline_type ? this.props.amendmentline_type : 0,
            integrator_id:this.getIntegratorForDuriation(),
            start_date: DatepickerFunctions.convertDateForDataBase(durations.new_start_date),
            end_date: DatepickerFunctions.convertDateForDataBase(durations.new_end_date)
        }
        if(!oldAmendDuration){
            this.createDurations(newData)
        }else{
            let data = {
                ...oldAmendDuration,
                ...newData
            }
            this.updateDurations(data)
        }

        this.setState({
            isDropdownOpen:false,
            durations:{
                new_start_date:'',
                new_end_date:'',
            }
        })
    }
    canEdit(){
        return true;
    }
    toogleButtonClick(e){
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        })
    }
    displayOldDates(){
        let oldAmendDuration =  this.props.getExistingDates()
        return(
            <div className='d-flex gap-3' style={{width:'100%'}}>
                <div  style={{width:'50%'}}>
                    <InputDatePicker disable={true}  value={oldAmendDuration?.start_date} selectorName="new_start_date_po_exist" id="new_start_date_po_exist" name="new_start_date_po_exist" label={Helper.getLabel(null,'start_date','Start date')}  onChange={this.onDateChangeHanlder.bind(this)} />
                </div>
                <div  style={{width:'50%'}}>
                    <InputDatePicker disable={true}  value={oldAmendDuration?.end_date} selectorName="end_date_exist_pop" id="end_date_exist_pop" name="end_date_exist_pop" label={Helper.getLabel(null,'end_date','End date')}  onChange={this.onDateChangeHanlder.bind(this)} />
                </div>
            </div>
        )
    }
    render() {
        let durations = this.state.durations;
        let btnIcon = this.state.isDropdownOpen ? '/images/icons/datepicker-red.png':'/images/icons/datepicker.png';
        
        return (
            <div className={this.state.isDropdownOpen ? 'amend_dates_button_popup show' : 'amend_dates_button_popup'}>
                <Button isDisable={this.props.isDisable} className="light_gray pop_tgl_btn" title="Amend dates" icon={btnIcon} onClick={this.toogleButtonClick.bind(this)}/>
                <div className='rs_amend_popup' style={{left:0,right:'auto'}}>
                    <h3>{Helper.getLabel(null,'exist_date','Exist date')}</h3>
                    {
                        this.displayOldDates()
                    }
                    <h3>{Helper.getLabel(null,'amend_dates','Amend dates')}</h3>
                    <div className='d-flex gap-3'>
                        <div>
                            <NewInputDatePicker disable={!this.canEdit()} label ={Helper.getLabel(null,'new_start_date','New start date')} name ="new_start_date" id ="new_start_date_pop" value = {durations.new_start_date ? durations.new_start_date : null}  onChange ={this.onDateChangeHanlder.bind(this)} />
                        </div>
                        <div>
                            <NewInputDatePicker disable={!this.canEdit()} label ={Helper.getLabel(null,'new_end_date','New end date')} name ="new_end_date" id ="new_end_date_pop" value = {durations.new_end_date ? durations.new_end_date : null}  onChange ={this.onDateChangeHanlder.bind(this)} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-right mt-2' style={{gap:"10px",'justify-content':'right'}}>
                        <Button className="black-btn-style mr-2" onClick ={ this.onCancleClickHandler.bind(this)} title={Helper.getLabel(null,'close','Close')}  />
                        <Button className="blue btn_md" isDisable={false} title={Helper.getLabel(null,'save','Save')} onClick={ this.onSaveClickHandler.bind(this) } />
                    </div>
                </div>
            </div>
        );
    }
}

export default AmendmentDatePopupButton;