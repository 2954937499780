import React from 'react';

import {Doughnut} from "react-chartjs-2"


const TabInfoWidget = ({percentage, textColor, Graph, taskType,percentageData,taskCount,onTabClick}) => {

  const value = 100  - percentageData

  const data = {
    labels:["",""],
    datasets: [
      {
        data: [percentageData,value],
        backgroundColor: [textColor,"#EEEEEE"],
        borderWidth: 0,
      },
    ],
  };

  const  options={
    // responsive: true,
    cutout: '85%',
    maintainAspectRatio: false,
    aspectRatio: 1, 
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, 
      },
    },
  }

  return (
    <div className='user_feed_info_tab' onClick={onTabClick} style={{cursor:"pointer"}}>
      <div className='row flex-row d-flex align-items-center'>
        <div className='col-7'>
          <div className='feed_info_data ms-3 mt-2'>
            <h2 style={{color: textColor}}>{percentage}</h2>
            <p>{taskCount} {taskType}</p>
          </div>
        </div>
        <div className='col-5' style={{marginTop:"-8px"}}>
         <Doughnut data={data} width={100} height={50} options={options}  />
        </div>
      </div>
    </div>
  )
}
export default TabInfoWidget;