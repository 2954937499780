import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TopVendorsByTechnicalScoreBarGraph = ({ vendorsData }) => {
  const options = {
    indexAxis: "y",
    borderRadius: 30,
    barPercentage: 0.3,
    responsive: true,
    scales: {
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: vendorsData.map((vendor) => {
      return vendor.vendor_name;
    }),
    datasets: [
      {
        data: vendorsData.map((score) => {
          return score.score;
        }),
        backgroundColor: [
          "#77BA99",
          "#EF9737",
          "#96D1FA",
          "#A45E6E",
          "#FFD66B",
          "#5B93FF",
          "#FF8F6B",
        ],
      },
    ],
  };
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default TopVendorsByTechnicalScoreBarGraph;
