import React, { Fragment, useCallback, useEffect, useState } from 'react';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import { connect } from 'react-redux';
import PRRequestPolicyPopup from './PRRequestPolicyPopup';
import ButtonNew from '../../../inc/ButtonNew'; 
import NavigationHeder from '../../../Navigations/NavigationHeder';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import Settings from '../../../../inc/Settings';
import Api from '../../../../inc/Api';
import Helper from '../../../../inc/Helper';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import { getLabelFromDropdown, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Alert from '../../../inc/Alert';

let gridApi = null;

const PRRequestPolicyListings = ({language,security}) => {
    const [enumDetails, setEnumDetails] = useState([]);

    const [popupDetails, setPopupDetails] = useState({
        showPopup: false,
        id: null
    });

    let enumApiCalled = false;

    useEffect(() => { getPRExpressRequestDate() }, []);

    const getPRExpressRequestDate = () => {
        if (enumApiCalled) return;
        enumApiCalled = true;
 
        let api = Api;
        api.setUserToken();
        api
            .axios()
            .get(Settings.apiUrl+`/enum/express_request_date_to_picked`, {})
            .then(function (res) {
                if (res.data.status === "success") { 
                    setEnumDetails(res?.data?.data?.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.name
                        }
                    })); 
                }
            })
            .catch((e) => { 
                setEnumDetails([]);
            });
    };

    const DeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delPRRequestPolicy_ID = element?.data?.expressrequest_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl+`/express_request/${delPRRequestPolicy_ID}`)
            const rowNode = gridApi?.getRowNode(delPRRequestPolicy_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200)
            gridApi?.refreshServerSide({ purge: true });
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const CellHandler = (event) => {   
        if(event.colDef.field == 'name') { 
            setPopupDetails((prevState) => {
                return {
                    ...prevState,
                    showPopup: true,
                    id: event?.data?.expressrequest_id
                }
            })
        }
    }
 
    const OpenPopup = () => {
        setPopupDetails((prevState) => {
            return {
                ...prevState,
                showPopup: true,
                id: null
            }
        })
    }

    const gridColHeaders = [
        { field:'name', headerName:Helper.getLabel(language,'name',"Name"), cellRenderer: (params) => gridLinkValueFormat(params) },
        {
            field: 'which_date_to_be_picked_if_both_provided',
            headerName: 'Which date to pick if both provided',
            editable: false,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            cellEditorParams: { values: enumDetails },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, enumDetails),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label
            }
        },
        { field:'no_of_days_from_creation_and_deliverydate', headerName:Helper.getLabel(language,'no_of_days_from_creation_and_deliverydate',"No. of days from creation and delivery date")},
        { field:'no_of_days_from_creation_and_startdate', headerName:Helper.getLabel(language,'no_of_days_from_creation_and_startdate',"No. of days from creation and start date")}
    ];

    const reloadParentGrid = () => {
        gridApi?.refreshServerSide({ purge: true });
        gridApi?.deselectAll();
    };

    if(!security.canView(Gui_id_list.setupForms.pr_request_policy.pr_request_policy_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment>
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(language,'pr_request_policy',"PR Request Policy")}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.pr_request_policy.pr_request_policy_main_create_button)} onClick={OpenPopup} title={Helper.getLabel(language,'new',"New")} /> 
                </NavigationHeder> 
 
                <AgGridNew
                    apiUrl={Settings.apiPurchaseRequisitionUrl+'/express_request'}  
                    pagination={false}
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.pr_request_policy.pr_request_policy_main_delete_button)}
                    columnDefs={gridColHeaders} 
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'expressrequest_id'}
                    onCellClicked={CellHandler}
                    handleDeleteSelectedRows={DeleteHandler}
                    onGridReady={(params) => gridApi = params?.api} 
                    gridId={"rs_pr_request_list"}
                />
                {popupDetails?.showPopup && <PRRequestPolicyPopup enumDetails={enumDetails} pr_request_policy_id={popupDetails?.id} reloadParentGrid={reloadParentGrid} setShowCreateModal={setPopupDetails} />}
            </div> 
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { 
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.setupForms.pr_request_policy.pr_request_policy_main
};

export default connect(mapStateToProps) (MasterComponentWraper(( ApplySecurityRoles( PRRequestPolicyListings, SecurityOptions)))); 