import React, { useEffect, useState } from 'react'
import Popup from '../../../Popup/Popup'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import SimpleLoading from '../../../Loading/SimpleLoading'
import Checkbox from '../../../Forms/Checkbox' 
import DropDownInput from '../../../Forms/Dropdown'
import Helper from '../../../../inc/Helper'
import { connect } from 'react-redux'
import $, { isEmptyObject } from 'jquery';
import Button from '../../../inc/Button'

let teamEvaluationExist = false

const ViewTeamModule = ({reloadGrid, setTeamDetails, scoringmaster_id, templateName, modeRFQ = true, language}) => {
    const [teamEvaluation, setTeamEvaluation] = useState({})
    const [teamHeader, setTeamHeader] = useState([])

    const [loading, setLoading] = useState(true)

    let teamEvaluationCalled = false
    let teamHeaderCalled = false

    useEffect(() => {
        getTeamEvaluationById()
        getTeamHeaders()
    }, [])

    const getTeamEvaluationById = () => {
        if(teamEvaluationCalled) return;
             
        let api = Api
        setLoading(true)
        teamEvaluationCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+`/get_evaluationteams/${scoringmaster_id}${modeRFQ ? '?for_rfq=True' : ''}`,{}).then(function(res){
            if(res.data.status==="success"){
                setTeamEvaluation(res.data.data)
                setLoading(false) 
                teamEvaluationExist = true
            }
        }).catch((error) => { 
            setTeamEvaluation({})
            teamEvaluationExist = false
            setLoading(false) 
            Helper.alert('No team evaluation exist for this template', 'error')
        })
    }
    
    const getTeamHeaders = () => {
        if(teamHeaderCalled) return;

        let api = Api 
        teamHeaderCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/teamheader`,{}).then(function(res){
            if(res.data.status==="success"){
                let updated_data = []
                res.data.data.forEach((item) => {
                    updated_data.push({
                        value: item.teamheader_id,
                        label: item.name
                    })
                })
                setTeamHeader(updated_data) 
            } 
        }).catch((error) => {
            console.log(error)
        })
    }

    const TechnicalEvaulationTeam = (e) => {
        setTeamEvaluation((prevState) => {
            return {
                ...prevState,
                technical_evaluation_team:e.target.value
            }
        })
    }
    const FinancialEvaulationTeam = (e) => {
        setTeamEvaluation((prevState) => {
            return {
                ...prevState,
                financial_evaluation_team:e.target.value
            }
        })
    }
    const onInputCheckboxChangeHandler = (name, value) => {
        setTeamEvaluation((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        })
    }

    // MODULES :  
    const displayHeadLine = () => { return (<h1 className='rfq_head_line_txt'>View team evaluation - {templateName}</h1>) }

    const TeamModule = () => {
        return (
            <div className='row'> 
                <div className='col-lg-4'>
                    <DropDownInput options={teamHeader} id='technical_evaluation_team' label={Helper.getLabel(language,'technical_evaluation_team',"Technical evaluation team")} onChange={TechnicalEvaulationTeam} value={teamEvaluation.technical_evaluation_team} />
                </div>
                <div className='col-lg-4'>
                    <DropDownInput options={teamHeader} id='financial_evaluation_team' label={Helper.getLabel(language,'financial_evaluation_team',"Commercial evaluation team")} onChange={FinancialEvaulationTeam} value={teamEvaluation.financial_evaluation_team} />
                </div>
                <div className='col-lg-4'>
                    <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'request_to_perform_technical_evaluation',"Requester to perform technical evaluation")} name='request_to_perform_technical_evaluation' isChecked={teamEvaluation.request_to_perform_technical_evaluation} />
                </div>
                <div className='col-lg-4'>
                    <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'orderer_to_perform_technical_evaluation',"Orderer to perform technical evaluation")} name='orderer_to_perform_technical_evaluation' isChecked={teamEvaluation.orderer_to_perform_technical_evaluation} />
                </div>
                <div className='col-lg-4'>
                    <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'requester_to_perform_financial_evaluation',"Requester to perform commercial evaluation")} name='request_to_perform_financial_evaluation' isChecked={teamEvaluation.request_to_perform_financial_evaluation} />
                </div>
                <div className='col-lg-4'>
                    <Checkbox type={'checkbox'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'orderer_to_perform_financial_evaluation',"Orderer to perform commercial evaluation")} name='orderer_to_perform_financial_evaluation' isChecked={teamEvaluation.orderer_to_perform_financial_evaluation} />
                </div>
            </div>
        )
    }

    const displayTopLevelComponent = () => {
        return (
            <div className='row mb-3'>
                <div className='col-6'>{ displayHeadLine() }</div>
                <div className='col-6'> { actionBtns() } </div>
            </div>
        )
    }

    const actionBtns = () => {
        return (
            <div className='d-flex justify-content-end align-items-center'>
                <Button
                    isActive={false}  
                    title={Helper.getLabel(language, "close", "Close")}
                    onClick={onPopupCloseHandler}
                    className='me-2 black-btn-style'
                />
                <Button
                    isActive={false}  
                    title={Helper.getLabel(language, "reset", "Reset")}
                    onClick={ResetHandler}
                    className='me-2 red-btn-style'
                />
                <Button
                    isActive={false}  
                    title={Helper.getLabel(language, "save", "Save")}
                    onClick={SaveHandler}
                    className='blue-btn-style'
                /> 
            </div>
        )
    }

    // ---

    const onPopupCloseHandler = () => {
        setTeamDetails((prevState) => {
            return {
                ...prevState,
                showPopup: false
            }
        })
        $('html').removeClass('rs_popup_open'); 
        reloadGrid();
    }

    // Execution of APIs 
    const addTeamEvaluation = () => { 
        if(isEmptyObject(teamEvaluation)) return;
        let api = Api
        api.setUserToken()
        api.axios().post(Settings.apiPurchaseRequisitionUrl+`/evaluationteam${modeRFQ ? '?for_rfq=True' : ''}`,{
            "scoringmaster_id": scoringmaster_id,
            "evaluationteam_id":null,
            "request_to_perform_technical_evaluation": teamEvaluation.request_to_perform_technical_evaluation == 'on' ? true : (teamEvaluation.request_to_perform_technical_evaluation == true ? teamEvaluation.request_to_perform_technical_evaluation : false),
            "orderer_to_perform_technical_evaluation": teamEvaluation.orderer_to_perform_technical_evaluation == 'on' ? true : (teamEvaluation.orderer_to_perform_technical_evaluation == true ? teamEvaluation.orderer_to_perform_technical_evaluation : false),
            "requester_to_perform_financial_evaluation": teamEvaluation.request_to_perform_financial_evaluation == 'on' ? true : (teamEvaluation.request_to_perform_financial_evaluation == true ? teamEvaluation.request_to_perform_financial_evaluation : false),
            "orderer_to_perform_financial_evaluation": teamEvaluation.orderer_to_perform_financial_evaluation == 'on' ? true : (teamEvaluation.orderer_to_perform_financial_evaluation == true ? teamEvaluation.orderer_to_perform_financial_evaluation : false),
            "technical_evaluation_team": teamEvaluation.technical_evaluation_team ? parseInt(teamEvaluation.technical_evaluation_team) : null,
            "financial_evaluation_team": teamEvaluation.financial_evaluation_team ? parseInt(teamEvaluation.financial_evaluation_team) : null
        }).then(function(res){
            if(res.data.status==="success"){
                Helper.alert(res.data.message)
                getTeamEvaluationById()
            } 
        }).catch((error) => {
            Helper.alert(error.response.data.message, 'error') 
        })
    }
    
    const updateTeamEvaulation = () => {
        let api = Api
        api.setUserToken()
        api.axios().put(Settings.apiPurchaseRequisitionUrl+`/evaluationteam/${modeRFQ ? teamEvaluation.evaluationteamRFQ_id : teamEvaluation.evaluationteam_id}${modeRFQ ? '?for_rfq=True' : ''}`,{ 
            "request_to_perform_technical_evaluation": teamEvaluation.request_to_perform_technical_evaluation == 'on' ? true : (teamEvaluation.request_to_perform_technical_evaluation == true ? teamEvaluation.request_to_perform_technical_evaluation : false),
            "orderer_to_perform_technical_evaluation": teamEvaluation.orderer_to_perform_technical_evaluation == 'on' ? true : (teamEvaluation.orderer_to_perform_technical_evaluation == true ? teamEvaluation.orderer_to_perform_technical_evaluation : false),
            "requester_to_perform_financial_evaluation": teamEvaluation.request_to_perform_financial_evaluation == 'on' ? true : (teamEvaluation.request_to_perform_financial_evaluation == true ? teamEvaluation.request_to_perform_financial_evaluation : false),
            "orderer_to_perform_financial_evaluation": teamEvaluation.orderer_to_perform_financial_evaluation == 'on' ? true : (teamEvaluation.orderer_to_perform_financial_evaluation == true ? teamEvaluation.orderer_to_perform_financial_evaluation : false),
            "technical_evaluation_team": teamEvaluation.technical_evaluation_team ? parseInt(teamEvaluation.technical_evaluation_team) : null,
            "financial_evaluation_team": teamEvaluation.financial_evaluation_team ? parseInt(teamEvaluation.financial_evaluation_team) : null
        }).then(function(res){
            if(res.data.status==="success"){
                Helper.alert(res.data.message)
                getTeamEvaluationById() 
                 
            } 
        }).catch((error) => {
            Helper.alert(error.response.data.message, 'error') 
        })
    }

    const ResetHandler = () => {
        if(!teamEvaluationExist) {
            Helper.alert('No team evaluation exist to reset!', 'error')
            return;
        }
        let api = Api
        api.setUserToken()
        api.axios().delete(Settings.apiPurchaseRequisitionUrl+`/evaluationteam/${modeRFQ ? teamEvaluation.evaluationteamRFQ_id : teamEvaluation.evaluationteam_id}${modeRFQ ? '?for_rfq=True' : ''}`,{}).then(function(res){
            if(res.data.status==="success"){
                Helper.alert(res.data.message)
                getTeamEvaluationById() 
                 
            } 
        }).catch((error) => {
            Helper.alert(error.response.data.message, 'error') 
        })
    }

    const SaveHandler = () => { 
        if(isEmptyObject(teamEvaluation)){
            Helper.alert('Enter Data in fields', 'error')
            return;
        }

        {teamEvaluationExist ? updateTeamEvaulation() : addTeamEvaluation()}
        onPopupCloseHandler()
    }

    return (
        <div className='common_responsive_class_popup_component'>
            <Popup className="view_team_popup" autoOpen={true} onClose={onPopupCloseHandler}>
                {loading ? <SimpleLoading /> : <div className='container-fluid'> {displayTopLevelComponent()} {TeamModule()} </div>}
            </Popup>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) (ViewTeamModule);