import React from 'react'
import TaskTurnAroundChart from './TaskTurnAroundChart'
import ResponseChart from './ResponseChart'

const DashboardLastRowCharts = () => {
  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-sm-6">
          <ResponseChart />
        </div>
        <div className="col-sm-6">
          <TaskTurnAroundChart />
        </div>
      </div>
    </div>
  )
}

export default DashboardLastRowCharts
