import React from 'react'
import AgGridNew from '../grid/ag/ag-grid-new';
import NavigationHeder from '../Navigations/NavigationHeder'; 
import MasterComponentWraper from '../Backend/MasterComponentWraper';
import Settings from '../../inc/Settings';
import SimpleDropdown from '../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown } from '../grid/ag/commonFunctions/GridCommonFunctions';
import DatepickerFunctions from '../../inc/DatepickerHelper';

let gridApi = null;
const ApiMasterFormsListings = () => {
    const gridColumnHeaders = [
        {field:'api_name', filter: 'agTextColumnFilter', headerName:'API name', editable:false }, 
        {field:'api_url', headerName:'API URL', editable: false },
        {field:'api_method', headerName:'API method', editable: false },
        {field:'api_key', headerName:'API key', editable: false },
        {field:'api_secret', headerName:'API secret', editable: false },  
        {field:'api_master_id', headerName:'Api master', editable: false },
        {field:'timeout',  headerName:'Timeout', editable: false },
        {field:'retry_count', headerName:'Retry count', editable: false },
        {
            field: 'status',
            headerName: 'Status',
            editable: false, 
            isAllowZero:true,
            cellEditor: SimpleDropdown,
            minWidth:180,  
            cellEditorParams: {
              values: Settings.enumStatus
            },
            cellDataType:false,
            valueFormatter: (params) => getLabelFromDropdown(params)
        },
        {field:'priority', headerName:'Priority', editable: false },
        {field:'type', headerName:'Type', editable: false },
        {field:'created_datetime', valueFormatter: (params) => params.value ? DatepickerFunctions.convertDateTimeFromDataBase(params.value) : null, headerName:'Created date time', editable: false },
        {field:'created_by', headerName:'Created by', editable: false }, 
        {field:'modified_datetime', headerName:'Modified date time', valueFormatter: (params) => params.value ? DatepickerFunctions.convertDateTimeFromDataBase(params.value) : null, editable: false },
        {field:'modified_by', headerName:'Modified by', editable: false }, 
    ];

    return (
        <div className='container-fluid'>
            <NavigationHeder hideMoreBtn={true} title={'API Master'}></NavigationHeder>
                
            <AgGridNew
                apiUrl={Settings.apiIntegrationUrl+'/api-details/'} 
                pagination={false}
                columnDefs={gridColumnHeaders} 
                hideAddBtn={true}
                height={500}
                uniqueField={'api_master_id'}
                allBtnHide={true} 
                onGridReady={(params) => gridApi = params?.api} 
                gridId={"api_master_forms_listing_grid"}
            />
        </div> 
    )
}

export default (MasterComponentWraper(ApiMasterFormsListings));