import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    allStatus:[]
}
const LineSiteStatusReducer = ( state = defaultState, actions) =>{
    if(ActionTypes.SET_LINESITE_STATUS === actions.type){
        state = {
            ...state,
            isLoaded:true,
            allStatus:actions.payload
        }
    }
    return state;
}
export default LineSiteStatusReducer;