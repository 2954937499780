import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../inc/Api'; 
import Helper from '../../inc/Helper';
import LabelsGUI_ID from '../../inc/LabelsGUI_ID';
import Settings from '../../inc/Settings';
import Alert from '../inc/Alert';
import ButtonNew from '../inc/ButtonNew'; 
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder'; 
import AgGridNew from '../grid/ag/ag-grid-new';
import { getNewValue, gridLinkValueFormat, statusLabelValueFormat } from '../grid/ag/commonFunctions/GridCommonFunctions';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import DatepickerFunctions from '../../inc/DatepickerHelper';
import TenantFormPopup from './Tenant Form/TenantFormPopup';
 
const Tenants = ({language, user}) => {
    const [tenantsList, setTenantsList] = useState([]);
    
    const [isLoading, setIsLoading] = useState(true); 
    const [openTenantPopup, setOpenTenantPopup] = useState(false);

    const navigate = useNavigate();

    let tenantCalled = false;

    useEffect(() => { loadTenants() }, []);

    const loadTenants = () => {
        if (tenantCalled) return; 
        tenantCalled = true;

        const api = Api; 
        api.setUserToken();

        const formData = { code: null, name: null };

        api.axios().post(Settings.apiUrl + '/tenant', formData).then(res => {
            if (res?.data?.status === 'success') { 
                setTenantsList(res?.data?.data);
                setIsLoading(false);
            }
        }).catch((error) => {
            getPopupMessageBasedOnStatus(error);
            setIsLoading(false);
        })
    };

    let dataSource = {
        getRows: async function (params) {
            params?.success({
                rowData: tenantsList?.map((item) => ({
                    ...item,
                    gridId: `${Date.now()}_${getNewValue()}`
                })),
                rowCount: tenantsList?.length
            })
        }
    };

    const onGridReady = (params) => {
        if(params) { 
            setTimeout(()=>{ 
                params?.api?.setGridOption('serverSideDatasource', dataSource);
            }, 400)
        }
    };

    const onCellClicked = (event) => {
        if (event.colDef.field === 'code') {
            navigate('/tenant/edit/' + event?.data?.tenant_Id);
        }
    };

    if (!user?.admin_user) {
        return <Alert type="danger" message={Helper.getLabel(language, 'no_access', 'No Access')} />;
    };
 
    const gridColumnHeaders = [ 
        { field: 'code', headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.code, 'Code'), width: 150,  cellRenderer: (params) => gridLinkValueFormat(params)},
        { field: 'name', headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.name, 'Name'), width: 150 },
        { field: 'isactive',  filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.status, 'Status'), width: 150,  cellRenderer: (params) => statusLabelValueFormat(params) },
        { field: 'createdby',  headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.created_by, 'Created by'), width: 150, editable: false },
        { field: 'modifiedby', headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.modifiedby, 'Modified by'), width: 150, editable: false },
        { field: 'start_date', filter:'agDateColumnFilter', valueFormatter: (params) => params.value ? DatepickerFunctions.convertDateFromDataBase(params.value) : null, headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.start_date, 'Start date'), width: 150 },
        { field: 'end_date', filter:'agDateColumnFilter', valueFormatter: (params) => params.value ? DatepickerFunctions.convertDateFromDataBase(params.value) : null, headerName: Helper.getLabel(language, LabelsGUI_ID.tenant.end_date, 'End date'), width: 150 },
    ];

    return (
        <div className='tenents_archive'>
            <div className='container-fluid'>
                <NavigationHeder backUrl="/lynkaz-administration" title={Helper.getLabel(language, 'tenant', 'Tenant')} hideMoreBtn={true} >
                    {user?.admin_user ? <ButtonNew onClick={() => setOpenTenantPopup(true)} /> : ''}
                </NavigationHeder>
                
                {   isLoading ? 
                        <SimpleLoading /> 
                    : 
                    <AgGridNew
                        apiUrl={null} 
                        pagination={false}
                        onCellClicked={onCellClicked}
                        columnDefs={gridColumnHeaders} 
                        onGridReady={onGridReady}
                        height={500} 
                        allBtnHide={true}
                        gridId={"tenant_listings"}
                    />  
                }

                {openTenantPopup ? <TenantFormPopup reloadGrid={loadTenants} setShowCreateTenantModal={setOpenTenantPopup} /> : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language,
        user:state.auth.user
    }
};

export default connect (mapStateToProps) (Tenants);