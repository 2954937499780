import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../../Purchase Order/ExtPO.css'
import Helper from '../../../../inc/Helper'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import { useLocation, useNavigate } from 'react-router-dom'
import Settings from '../../../../inc/Settings'
import MasterComonent from "../../../Backend/MasterComonent";
import CommonApiCalls from '../../Purchase Order Screen/CommonApiCalls/CommonApiCalls'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import getPurchaseAgreementColumns from './PurchaseAgreementColumns'

let gridApi = null

const PurchaseAgreementList = (props) => {
  const location= useLocation();
  const [loading, setLoading] = useState(true)
  const [purchaseStatuses,setPurchaseStatuses]=useState([])
  const [documentStatuses,setDocumentStatuses]=useState([])

  const contractStatusCalls = new CommonApiCalls()

  const navigate = useNavigate()

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  
  const status = getSearchParam('status')
  
  const handleGridReady= (params) => {
    gridApi = params.api
  }

  //function to fetch data
  const getAsyncData = async () => {
    // setLoading(true)
    const purchase_statuses = await contractStatusCalls.getAllPurchaseOrderStatus()
    const document_statuses = await contractStatusCalls.getAllDocumentStatus()
    if (purchase_statuses && documentStatuses) {
      setPurchaseStatuses(purchase_statuses)
      setDocumentStatuses(document_statuses)
      setLoading(false)
    }
  }


  //function for on cell click
  const cellHandler = (event) => { 
    if (event.colDef.field === 'document_number') {
      navigate(
        `/purchase-agreement-details?&agreement_id=${event.data?.agreement_id}`,
      )
    }
    if (event.colDef.field === 'vendor_code') {
      navigate(
        `/edit-vendor/${event?.data?.vendor_id}` 
      )
    }
  }

  const Columns_Headings = getPurchaseAgreementColumns({
    language:props.language,
    purchaseStatuses:purchaseStatuses,
    documentStatuses:documentStatuses
  })

  useEffect(() => {
    getAsyncData()
  }, [])

  const typeTitle = () => {
    if(parseInt(status)===0){
      return "Draft"
    }else if(parseInt(status)===1){
      return "Confirmed"
    }
    else if(parseInt(status)===2){
      return "Under Review"
    } else {
      return "All"
    }
  }

  return (
    <div className="purchase_orders_listing_archive external-theme">
      <MasterComonent>
      <div className="container-fluid">
        <NavigationHeder title={`Purchase Agreements - ${typeTitle()}`}             
          backUrl={
            location.search === '' ? props.auth?.user?.usertype===1 && `/dashboard` : -1
          }
          hideMoreBtn={true}
        ></NavigationHeder>
        {loading ? (
          <SimpleLoading />
        ) : (
          <div> 
            <ExtHeaderLine
              title={Helper.getLabel(
                props.language,
                'purchase_agreements',
                `Purchase Agreements - ${typeTitle()}`,
              )}
            />
            <AgGridNew
              apiUrl={Settings.apiPurchaseOrderUrl + `/PA_header`}
              pagination={true}
              columnDefs={Columns_Headings}
              onGridReady={handleGridReady}
              isDisabledCheckbox={true}
              onCellClicked={cellHandler}
              uniqueField={'agreement_id'}
              hideAddBtn={true}
              height={500}
              hideDeleteBtn={true}
              gridId={`purchase-agreement-external-listing-page`}
            />
          </div>
        )}
      </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(PurchaseAgreementList)
