import React from 'react'
import { ProgressBar } from 'react-bootstrap';

const AttachmentLoader = ({isLoading,percentage,fileName}) => {
    if (!isLoading) {
        return null;
    }
    const percentageUpload = percentage;
  return (
    <div className='dotted-outer-div flex-column align-items-start mb-3'>
      <p className='uploading-title'>Wait, still uploading {fileName}</p>
      <ProgressBar now={Math.round(percentageUpload)} className='w-100'/>
    </div>
  )
}

export default AttachmentLoader

