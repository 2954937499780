import React, { Fragment, useEffect, useRef, useState } from 'react'
import TechnicalEvaluationMasterWraper from '../Team Evaluation/TechnicalEvaluationMasterWraper';
import CompliancesHeader from './CompliancesHeader';
import CriteriaSectionsCollapseWrapper from '../Team Evaluation/CriteriaSectionsCollapseWrapper';
import TeamSectionsWrapper from '../Team Evaluation/TeamSectionsWrapper';
import CompliancesSections from './CompliancesSections';
import { connect } from 'react-redux'; 
import CompliancesSummary from './CompliancesSummary';
import Api from '../../../../inc/Api';
import SimpleLoading from '../../../Loading/SimpleLoading';
import Settings from '../../../../inc/Settings';
import {  checkIfArrayIsEmpty, combineComplianceResponsesWithTemplateLines, findLongestArrayLength } from '../../../../inc/Validation';
import useDynamicRefs from '../Team Evaluation/useDynamicRefs';

/*

RFQ - Compliances Consolidated View:
rfq_id: *required

*/

const CompliancesConsolidatedView = ({rfq_id}) => { 
  const [loadingConsolidatedView, setLoadingConsolidatedView] = useState(false);

  const [RFQComplianceVendors, setRFQComplianceVendors] = useState([]);
  const [RFQComplianceTemplateWithResponseLines, setRFQComplianceTemplateWithResponseLines] = useState([]); 
  const [RFQTemplateLinesSummary, setRFQTemplateLinesSummary] = useState([]);
  const [RFQComplianceResponses, setRFQComplianceResponses] = useState([]);
  const [RFQComplianceResponseLines, setRFQComplianceResponseLines] = useState([]);
  const [RFQComplianceTemplateResponseLines, setRFQComplianceTemplateResponseLines] = useState([]);
 
  // for scrolling functionalities,
  const vendorScrollableRef = useRef(0);
  const complianceSummaryScrollableRef = useRef(0);
  const complianceTemplateLinesRefs = useDynamicRefs(RFQComplianceTemplateResponseLines.length); // custom hook for creating instances to implement the scroll functionality wisely
 
  let RFQVendorsCalled = false;
  let RFQComplianceTemplateLinesCalled = false;
  let RFQComplianceResponseCalled = false; 
 
  useEffect(() => {
    getRFQComplianceVendors(); 
    getComplianceTemplateLines();
  }, []);
  
  const getRFQComplianceVendors = () => { 
    if(RFQVendorsCalled || !rfq_id) return;
    RFQVendorsCalled = true;
    
    let api = Api
    api.setUserToken();
 
    api.axios().get(Settings.apiPurchaseRequisitionUrl+`/get_vendors_of_RFQ/${rfq_id}`,{}).then(function(res){
      if(res.data.status==='success'){  
        let RFQComplianceVendors = [];
        res.data.data.forEach((item) => {
          RFQComplianceVendors.push({
            ...item,
            vendor: item.vendor_name,
            id: item.vendor_id
          })
        });
        setRFQComplianceVendors(RFQComplianceVendors); 
      }
    }).catch((res) => {   
      setRFQComplianceVendors([]); 
    })
  };
  
  const getComplianceTemplateLines = async () => {
    if(RFQComplianceTemplateLinesCalled || !rfq_id) return;
    RFQComplianceTemplateLinesCalled = true;
    
    let api = Api;
    api.setUserToken();
 
    setLoadingConsolidatedView(true);
    api.axios().get(Settings.apiPurchaseRequisitionUrl+`/get_rfq_compliance_template_lines/${rfq_id}`,{}).then(async function(res){
      if(res.data.status==='success'){  
        setRFQComplianceTemplateResponseLines(res.data.data)
        let Complianceresponse = await getComplianceResponse();
        let RFQTemplateLinesUpdated = combineComplianceResponsesWithTemplateLines(res.data.data, Complianceresponse);
           
        setRFQComplianceTemplateWithResponseLines(RFQTemplateLinesUpdated)
        setLoadingConsolidatedView(false);
      }
    }).catch((res) => {    
      setRFQComplianceTemplateWithResponseLines([])
      setLoadingConsolidatedView(false);
      console.log(res)
    })
  };

  const getComplianceResponse = async () => {
    if (RFQComplianceResponseCalled || !rfq_id) return [];
    RFQComplianceResponseCalled = true;

    let api = Api;
    api.setUserToken();

    try {
      const res = await api.axios().get(Settings.apiPurchaseRequisitionUrl + `/get_compliance_response/${rfq_id}`, {});
      
      if (res.data.status === 'success') {
        let ResponseHeaderLines = await getComplianceResponseLinesByComplianceHeader(res?.data?.data[0]?.complianceheader_id);
       
        let ResponseHeaderLinesForSummary = [];
        ResponseHeaderLines.forEach((item) => {
          ResponseHeaderLinesForSummary.push({
            id: item.compliance_id,
            type: item.name
          })
        })

        setRFQComplianceResponses(res.data.data)
        setRFQTemplateLinesSummary([...ResponseHeaderLinesForSummary, { id:Date.now(), type: 'Total' }]);

        let Updated_Compliances = res.data.data.map((item) => {
          const matchingSubItem = ResponseHeaderLines.find((subItem) => item.compliance_id === subItem.compliance_id);

          if (matchingSubItem) {
            return {
              compliance_templateline_id: item.compliance_templateline_id,
              compliance_id: item.compliance_id,
              compliance_type: matchingSubItem?.name,
              color: item.color,
              comments: item.comments,
              rfq_vendorreply_id: item.rfq_vendorreply_id,
            };
          } else return { ...item };
        });

        return Updated_Compliances;
      } else { return [] }
    } catch (error) { return [] }
  };
  
  const getComplianceResponseLinesByComplianceHeader = async (complianceheader_id) => {
    if (!complianceheader_id) return [];

    let api = Api;
    api.setUserToken();

    try {
      const res = await api.axios().get(Settings.apiPurchaseRequisitionUrl + `/complianceresponselines/${complianceheader_id}`, {});

      if (res.data.status === 'success') { 
        setRFQComplianceResponseLines(res.data.data)
        return res.data.data
      }
      else return [];

    } catch (error) { return [] };
  };

  // Scroll handling Effects functions,
  const handleVendorRefs = () => {  
    complianceTemplateLinesRefs.forEach((ref) => {
      if (ref.current) { 
        ref.current.scrollLeft = vendorScrollableRef.current.scrollLeft;
      }
    }); 
    if(complianceSummaryScrollableRef.current && vendorScrollableRef.current){ 
      complianceSummaryScrollableRef.current.scrollLeft = vendorScrollableRef.current.scrollLeft 
    }
  };

  const handleScrollingVendorRefs = (index) => {   
    if (vendorScrollableRef.current && complianceSummaryScrollableRef.current && complianceTemplateLinesRefs[index].current) {  
      vendorScrollableRef.current.scrollLeft = complianceTemplateLinesRefs[index].current.scrollLeft; 
      complianceSummaryScrollableRef.current.scrollLeft = complianceTemplateLinesRefs[index].current.scrollLeft;
    } 
  }; 

  const handleScrollingForSummaryGrid = () => { 
    if (complianceSummaryScrollableRef.current && vendorScrollableRef.current){ 
      vendorScrollableRef.current.scrollLeft = complianceSummaryScrollableRef.current.scrollLeft 
    }
    complianceTemplateLinesRefs.forEach((ref) => {
      if (ref.current) { 
        ref.current.scrollLeft = vendorScrollableRef.current.scrollLeft;
      }
    });
  };
  // --

  const ComplianceConsolidatedViewWrapper = () => { 
    return (
      <div style={{marginBottom: '3rem'}}>
        {!checkIfArrayIsEmpty(RFQComplianceTemplateWithResponseLines) && !checkIfArrayIsEmpty(RFQComplianceVendors)
          ?
            <TechnicalEvaluationMasterWraper>
              <CompliancesHeader onScroll={handleVendorRefs} vendorRef={vendorScrollableRef} CompliancesVendorList={RFQComplianceVendors} /> 
              { findLongestArrayLength(RFQComplianceTemplateWithResponseLines, 'compliances') !== 0 ? RFQComplianceTemplateWithResponseLines.map((templateLinesObj, index) => {
                return (
                  <CriteriaSectionsCollapseWrapper  
                    title={templateLinesObj?.category} 
                    compliance={true} 
                    className={"evaluation-section"} 
                    open={true}
                  > 
                    <TeamSectionsWrapper
                      onScroll={() => handleScrollingVendorRefs(index)}
                      sectionRef={complianceTemplateLinesRefs[index]}
                      numberOfElements={RFQComplianceVendors?.length+1} 
                      compliance={true}
                    >
                      <CompliancesSections compliances={templateLinesObj?.compliances} RFQVendors={RFQComplianceVendors} complainceName={templateLinesObj.compliance_description ? templateLinesObj.compliance_description : '-'} />  
                    </TeamSectionsWrapper>
                  </CriteriaSectionsCollapseWrapper>
                )
              }) : <div className='text-center mt-5'>No Vendor Responses Found!</div>}
               
              {findLongestArrayLength(RFQComplianceTemplateWithResponseLines, 'compliances') !== 0 && 
                <CompliancesSummary
                  onSummaryGridScroll={handleScrollingForSummaryGrid}
                  summaryRef={complianceSummaryScrollableRef}
                  TotalLines={RFQComplianceTemplateWithResponseLines} 
                  RFQResponseLines={RFQComplianceResponseLines} 
                  RFQComplianceResponses={RFQComplianceResponses} 
                  RFQComplianceVendors={RFQComplianceVendors} 
                  NoOfObjectsToMap={RFQComplianceVendors?.length+1} 
                  ColumnsData={RFQTemplateLinesSummary} 
                />
              }
            </TechnicalEvaluationMasterWraper>
          : 
          <Fragment> No Vendors & Vendor Responses Found to be shown! </Fragment>
        }
      </div>
    )
  }

  return (
    <div className='compliances_consolidated_view_wrapper'> 
      {loadingConsolidatedView ? <SimpleLoading /> : ComplianceConsolidatedViewWrapper()} 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps) (CompliancesConsolidatedView);