import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import DropDownInput from '../../../components/Forms/Dropdown';
import Helper from '../../../inc/Helper'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { useNavigate } from 'react-router-dom';
import Collapse from '../../inc/Collapse';
import Tabs from '../../Widgets/Tabs/Tabs';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

// Create Mode Team Header Lines Access - Levels / Granted - SYSTEM

const TeamHeaderAccessLevelsCreate = (props) => {
  // Component Local State / Declarations -
  const [teamLinesAccessLevels, setTeamLinesAccessLevels] = useState({
    "teamline_id": null,
    "teamaccesslevel_id": null,
    "access_level": null
  })
  const navigate = useNavigate()
  // Dropdown Arrat 'type' State
  const [teamLinesData, setTeamLinesData] = useState([])
  const [teamAccessLevelData, setTeamAccessLevelData] = useState([])
  const [accessLevelsData, setAccessLevelsData] = useState([])
  // Component Loading Ability Handler
  const [loading, setLoading] = useState(false)
  // --

  // API Reduction Calls --

  let teamLinesCalled = false
  let teamAccessLevelCalled = false
  let accessLevelsCalled = false

  // ---

  // Tabs - 
  const DefaultNavs = [ 
    {title:Helper.getLabel(props.language,'teamlines_access','Team Lines Access Levels'), isActive: true, },
    {title:Helper.getLabel(props.language,'teamlines_access_source','Team Lines Access Levels (Source)'), isDisable: true},  
  ]

  //--

  // API Calls / Renderer Functions ---

  useEffect(() => {
    getTeamLinesData()
    getAccessLevelsData()
    getTeamAccessLevelsData()
  }, [])

  // ---

  // API Functions --

  const getTeamLinesData = () => {
    if(teamLinesCalled){
      return;
    }
    let api = Api
    teamLinesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/teamlines',{}).then(function(res){
    if(res.data.status==='success'){   
      let Updated_State = []
      res.data.data.forEach(element => {
        Updated_State.push({
          label: element.description,
          value: element.teamLine_id
        })
      });
      setTeamLinesData(Updated_State) 
    } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getAccessLevelsData = () => {
    if(accessLevelsCalled){
      return;
    }
    let api = Api
    accessLevelsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/lineaccess_level',{}).then(function(res){
    if(res.data.status==='success'){   
      let Updated_State = []
      res.data.data.forEach(element => {
        Updated_State.push({
          label: element.name,
          value: element.id
        })
      });
      setAccessLevelsData(Updated_State) 
    } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getTeamAccessLevelsData = () => {
    if(teamAccessLevelCalled){
      return;
    }
    let api = Api
    teamAccessLevelCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/access_levels',{}).then(function(res){
    if(res.data.status==='success'){   
      let Updated_State = []
      res.data.data.forEach(element => {
        Updated_State.push({
          label: element.description,
          value: element.teamaccess_level_id
        })
      });
      setTeamAccessLevelData(Updated_State) 
    } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  // ---

  // onChange Input Fields Handlers -

  const TeamLineHandler = (event) => {
    let teamLine = event.target.value
    setTeamLinesAccessLevels((prevState) => {
      return {
        ...prevState,
        teamline_id: teamLine
      }
    })
  }
  const TeamLineAccessLevelHandler = (event) => {
    let teamLineAccess = event.target.value
    setTeamLinesAccessLevels((prevState) => {
      return {
        ...prevState,
        teamaccesslevel_id: teamLineAccess
      }
    })
  }
  const AccessLevelHandler = (event) => {
    let accessLevel = event.target.value
    setTeamLinesAccessLevels((prevState) => {
      return {
        ...prevState,
        access_level: accessLevel
      }
    })
  }
  // ----

  // Module / Component Function ---
  
  const TeamLinesAccessLevelsModule = () => {
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <DropDownInput required={true} onChange={TeamLineHandler} value={teamLinesAccessLevels.teamline_id} options={teamLinesData} label={Helper.getLabel(props.language,'team_line_id',"Team Line")} id='team_line_id' />
        </div>
        <div className='col-lg-4'>
          <DropDownInput required={true} onChange={TeamLineAccessLevelHandler} value={teamLinesAccessLevels.teamaccesslevel_id} options={teamAccessLevelData} label={Helper.getLabel(props.language,'teamaccesslevel_id',"Team Access Level")} id='teamaccesslevel_id' />
        </div>
        <div className='col-lg-4'>
          <DropDownInput required={true} onChange={AccessLevelHandler} value={teamLinesAccessLevels.access_level} options={accessLevelsData} label={Helper.getLabel(props.language,'access_level',"Access Level")} id='access_level' />
        </div>
      </div>
    )
  }

  // ----

  // API Executions -----

  const SaveHandler = () => {
    if(!teamLinesAccessLevels.teamline_id){
      Helper.alert('Team Line Missing!', 'error')
      return;
    }
    if(!teamLinesAccessLevels.teamaccesslevel_id){
      Helper.alert('Team Access level Missing!', 'error')
      return;
    }
    if(!teamLinesAccessLevels.access_level){
      Helper.alert('Access Level Missing!', 'error')
      return;
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().post(Settings.apiUrl+`/teamlineaccesslevel`,{
      "teamline_id":  parseInt(teamLinesAccessLevels.teamline_id),
      "teamaccesslevel_id": parseInt(teamLinesAccessLevels.teamaccesslevel_id),
      "access_level": parseInt(teamLinesAccessLevels.access_level)
    }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        navigate('/teamlines-access-levels')
        setLoading(false)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoading(false)
    })
  }

  //----

  //Security System - Accessibility Screen -

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_create_screen)){
  return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  </Fragment>
  }

  // ----

  return (
    <div className='team_lines_access_levels_create_screen'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/teamlines-access-levels' title={Helper.getLabel(props.language,'team_header_line_access_level_create',"Team Header Line Access Level Create")}>
          <Button onClick={SaveHandler} isActive={true} icon='/images/icons/save-white.svg' isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_create_screen_save_button)} title={Helper.getLabel(props.language,'save','Save')} />
        </NavigationHeder>

        {loading ? <SimpleLoading /> : (
          <Collapse className='mb-4 default_params_tabs' open={true} title={Helper.getLabel(props.language,'team_lines_accessibility',"Team Lines Accessibility")}>
            <Tabs disableSticky={true} navs={DefaultNavs} showAll={false} scrollOffset= "500">
              <Fragment>{TeamLinesAccessLevelsModule()}</Fragment>
              <Fragment></Fragment>
            </Tabs>
          </Collapse>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header_access_levels.team_header_access_levels_create_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(TeamHeaderAccessLevelsCreate, SecurityOptions))))