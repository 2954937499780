import React, { Component } from "react";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import ShopResults from "../../components/PurchaseRequisition/ShopResults";

// "/purchase-requisition"
export default class ShopScreen extends Component {
  render() {
    return (
      <ShopLayout
        backUrl={-1}
        title="Shop"
        hideMoreBtn={true}
        hideNextBtn={true}
        isCartVisible={true}
        currentDocumentStatus={0}
      >
        <ShopResults />
      </ShopLayout>
    );
  }
}
