import Api from "../../../../inc/Api";
import Helper from "../../../../inc/Helper";
import Settings from "../../../../inc/Settings";
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from "../../../../inc/Validation";

/*
Common Advance Search Filter API calls file

*/

export default class ListingCommonCalls { 
    api;

    constructor() {
        this.api = Api;
        this.api.setUserToken();
    };

    async getDataFromSearchParams(apiUrl, searchOnDocument="") { 
        try {
            const res = await this.api.axios().get(apiUrl, {});

            if (res?.status === 200) {
                if (checkIfArrayIsEmpty(res?.data?.data)) {
                    Helper.alert('Search query yielded no results. Reloading with existing data!', 'error');
                    return [];
                }

                const mapDocumentStatus = (item, statusProp) => ({
                    ...item,
                    [statusProp]: item.document_status,
                });

                const mapVendorMaster = (item) => {
                    const updateCategories = !checkIfArrayIsEmpty(item?.category) ? item?.category?.map((val) => val?.name) : [];
                    return {
                        ...item,
                        category: updateCategories,
                        vendor_code: item.code,
                        vendor_name: item.name,
                        workflow_status: item.document_status?.replace(/_/g, ' ')
                    };
                };

                switch (searchOnDocument) {
                    case 'external_contract':
                        return res?.data?.data?.map((item) => mapDocumentStatus(item, 'co_document_status'));
                    case 'external_purchase_order':
                        return res?.data?.data?.map((item) => mapDocumentStatus(item, 'po_document_status'));
                    case 'goods_receiving':
                        return res?.data?.data?.map((item) => mapDocumentStatus(item, 'sh_document_status'));
                    case 'work_confirmations':    
                        return res?.data?.data?.map((item) => mapDocumentStatus(item, 'sh_document_status'));
                    case 'invoices':
                        return res?.data?.data?.map((item) => mapDocumentStatus(item, 'inv_document_status'));
                    case 'vendor_master':
                        return res?.data?.data?.map(mapVendorMaster);
                    default: 
                        return res?.data?.data;
                }
            }
            else {
                Helper.alert('Apologies, something went wrong from our side!', 'error'); 
                return [];
            };
        } catch (error) { 
            getPopupMessageBasedOnStatus(error);
            return [];
        }
    };

    async getFilterOptionsEnum() {
        try {
            const res = await this.api.axios().get(Settings.apiUrl + `/enum/filter_options`, {});
            
            if (res.status === 200) {
                let Updated_data = [];  
                
                res.data.data.forEach((item) => {
                    Updated_data.push({
                        value: item.id,
                        label: item.name
                    })
                });

                return Updated_data;
            }
            else {
                return [];
            };
        } catch (error) {  
            return [];
        }
    };

    async getDropdownsData(apiUrl, valueProp, labelProp) {
        try {
            const res = await this.api.axios().get(apiUrl, {});

            if (res.status === 200) { 
                return res.data.data.map(item => ({
                    value: item[valueProp],
                    label: item[labelProp]
                }));
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    }
}