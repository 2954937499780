import React, { useCallback, useEffect, useState } from "react";

import Helper from "../../../../inc/Helper";
import $ from 'jquery'

import AddIcon from "../../../../assets/add-new.svg";
import RFQCommonApiCalls from "../../RFQCommonApiCalls";
import SelectFromTeamsModal from "./SelectFromTeamsModal";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import { getPopupMessageBasedOnStatus, objectIsEmpty } from "../../../../inc/Validation";
import { getCollaborationTeamColumn } from "./ColumnsHeader/ColumnsHeader";
import AgGridNew from "../../../grid/ag/ag-grid-new";
import { getNewValue } from "../../../grid/ag/commonFunctions/GridCommonFunctions";
import CollaborationTeamPopup from "./CollaborationTeamPopup";

let gridObj = null
let AddBtn = false;
let transaction

const CollaborationTeamGrid = ({
  language,
  rfqHeader,
  employeesList,
  collaborationTeams,
  setCollaborationTeams,
  oldCollaborationTeams,
  getAsyncData,
  isCollaborationTeamsDisable
}) => {
  const commonApiCalls = new RFQCommonApiCalls();
  const [teamsList, setTeamsList] = useState([]);
  const [dynamicTeamsList, setDynamicTeamsList] = useState([]);
  const [showAddTeamPopup,setAddTeamPopup]=useState(false)
  const [showSelectFromTeamsModal, setShowSelectFromTeamsModal] =
    useState(false);
  const [accessLevel, setAccessLevel] = useState([]);
  const [teamsData,setTeamsData]=useState({})
  const [gridkey,setGridkey]=useState('')

  const handleEditable = (params) => {
    //commented for future
    // if(rfqHeader?.rfq_status===3){
    //   return false;
    // }else if(rfqHeader?.rfq_status===0){
    //   return true
    // }else{
    //   const currentId = params.data.employee_id; 
    //   const idExists = collaborationTeams.some(row => row.employee_id === currentId);
    //   return !idExists;
    // }
    return isCollaborationTeamsDisable
  }
  
  const Columns_Headings = getCollaborationTeamColumn({
    language:language,
    teamsList:teamsList,
    dynamicTeamsList:dynamicTeamsList,
    employeesList:employeesList,
    handleEditable:handleEditable,
    accessLevel:accessLevel,
  })

  useEffect(() => {
    getAllTeams();
    getAllDynamicTeams()
    getAccessLevels();
  }, []);

  // useEffect(() => {
  //   getAsyncData();
  // },[employeesList]);

  const handleCloseSelectFromTeamsModal = () => {
    setShowSelectFromTeamsModal(false);
  };

  const handleAddBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onTeamDelete  = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(`${Settings.loginUrl}/teamsourcelines/${element.data?.teamsourceline_id}`)
      const rowNode = gridObj?.api?.getRowNode(element.data?.teamsourceline_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
        setCollaborationTeams((prevTeamsData)=>prevTeamsData.filter((team)=>team.teamsourceline_id !== element?.data?.teamsourceline_id))
        setGridkey(Date.now())
      }, 200)
      if (index === lastIndex) {
        getAsyncData()
      }
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const onAddClick = () => {
    setAddTeamPopup(true)
  }

  const getAccessLevels = async () => {
    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(`${Settings.loginUrl}/enum/lineaccess_level`)
      .then((res) => {
        setAccessLevel(res?.data?.data);
      })
      .catch((error) => console.log("Error in getting Access Levels: ", error));
  };

  const getAllTeams = async () => {
    try {
      const teamsData = await commonApiCalls.getAllTeams();
      setTeamsList(teamsData);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllDynamicTeams = async () => {
    try {
      const dynamicTeamsData = await commonApiCalls.getDynamicTeams();
      setDynamicTeamsList(dynamicTeamsData);
    } catch (err) {
      console.log(err);
    }
  };

  const AfterDeleteButton = () => {
    return (
      <div className="list_view_action_btns_grid">
        <button
          onClick={isCollaborationTeamsDisable ? '':() => {
           setShowSelectFromTeamsModal(true);
          }}
          className={isCollaborationTeamsDisable?'ctl-dissable':"add_collaboration_team"}
        >
          <span>
            <img src={AddIcon} className="img-fluid" alt="add team" />
          </span>
          <span>
            {Helper.getLabel(language, "add_team", "Add team")}
          </span>
        </button>
      </div>
    );
  };

  const handleOnRowValueChanged = async (event) => {
    const addedMembers = collaborationTeams.filter(
      (team) =>
        !oldCollaborationTeams.some(
          (oldMember) => oldMember.teamsourceline_id === team.teamsourceline_id
        )
    );

    // find updated members
    const updatedMembers = collaborationTeams.filter((team) => {
      const oldMember = oldCollaborationTeams.find(
        (oldMember) => oldMember.teamsourceline_id === team.teamsourceline_id
      );  
      return oldMember && JSON.stringify(oldMember) !== JSON.stringify(team);
    });

    if (addedMembers?.length > 0) {
      for (let i = 0; i < addedMembers.length; i++) {
        addedMembers[i] = {
          ...addedMembers[i],
          source_id: rfqHeader?.source_id,
          sourceintegrator_id: rfqHeader?.RFQ_id,
          employee_id: parseInt(addedMembers[i].employee_id),
          access_level: parseInt(addedMembers[i].access_level),
          description:
            addedMembers[i].description === ""
              ? null
              : addedMembers[i].description,
          from_date:
            addedMembers[i].from_date === "" || addedMembers[i].from_date === null || addedMembers[i].from_date === undefined  ? null : DatepickerFunctions.convertDateForDataBase(new Date(addedMembers[i].from_date)),
          to_date:
            addedMembers[i].to_date === "" || addedMembers[i].to_date === null || addedMembers[i].to_date === undefined? null : DatepickerFunctions.convertDateForDataBase(new Date(addedMembers[i].to_date)),
          teamheader_id: addedMembers[i].teamheader_id || null,
          dynamicteamheader_ids: addedMembers[i]?.dynamicteamheader_ids || null
        };
      }
      try {
        const res = await commonApiCalls.addTeamSourceLine(addedMembers);
        if (res) {
          Helper.alert(res?.message, "success");
          gridObj?.api?.deselectAll()
          getAsyncData()
          setTimeout(()=>{
            setGridkey(Date.now())
          },400)
          popUpCloseTeams()
          setTimeout(() => {
            if (AddBtn) {
              gridObj?.api?.applyServerSideTransaction(transaction);
            }
          }, 1000);
        }
      } catch (error) {
        getPopupMessageBasedOnStatus(error)
        const uniqueCollaborationTeams = collaborationTeams.filter(
          (team, index, self) =>
            index === self.findIndex(
              (t) => t.employee_id === team.employee_id && t.teamsourceline_id
            )
        );
        setCollaborationTeams(uniqueCollaborationTeams)
        setGridkey(Date.now())
      }
    }

    if (updatedMembers?.length > 0) {
      for (const updatedMember of updatedMembers) {
        const oldMember = oldCollaborationTeams.find(
          (oldMember) =>
            oldMember.teamsourceline_id === updatedMember.teamsourceline_id
        )

        const existingDynamicTeamHeaderIds=Object.keys(oldMember?.dynamicteamheader_names || {});
        const oldData = existingDynamicTeamHeaderIds.map(item => parseInt(item));  

        const newDynamicTeamHeaderIds = updatedMember.dynamicteamheader_ids || [];
        const filteredDynamicTeamHeaderIds = newDynamicTeamHeaderIds.filter(id => !oldData?.includes(id));
        
        const payload = {}

        for (const key in updatedMember) {
          if (updatedMember.hasOwnProperty(key) &&
          key !== 'setRowDataGridRowId') {
            let value = updatedMember[key]
            if (value === "") {
              value = null
            }
            if (key === 'dynamicteamheader_ids') {
              value = filteredDynamicTeamHeaderIds; 
            }
            if (JSON.stringify(value) !== JSON.stringify(oldMember[key])) {
              payload[key] = value
            }
          }
        }
        let payload2 = {
          ...payload,

        }
        if(objectIsEmpty(payload)){
          return
        }
        try {
          const res = await commonApiCalls.updateTeamSourceLine(
            updatedMember?.teamsourceline_id,
            payload2
          )
          if (res) {
            Helper.alert(res?.message, 'success')
            getAsyncData()
            setTimeout(()=>{
              setGridkey(Date.now())
            },400)
            popUpCloseTeams()
            gridObj?.api?.deselectAll()
          }
        } catch (error) {
          getPopupMessageBasedOnStatus(error)
        }
      }
      // getAsyncData();
    }
  };

  let dataSource = {
    getRows: function (params) {
        params.success({
          rowData: collaborationTeams?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}`,
          })),
          rowCount: collaborationTeams?.length,
        })
    },
  }

  const handleCellClicked = (event) => {
    if(event?.colDef?.field==="employee_id"){
      setAddTeamPopup(true)
      setTeamsData(event?.data)
    }
  }

  const handleGridReady = (params) => {
    gridObj = params
    setTimeout(()=>{
      params.api.setGridOption('serverSideDatasource', dataSource)
    },200)
  }

  // useEffect(() => {
  //   handleOnRowValueChanged();
  // }, [collaborationTeams]);

  const popUpCloseTeams = () => {
      setAddTeamPopup(false)
      const filteredTeams = collaborationTeams.filter(team => team.teamsourceline_id);
      setCollaborationTeams(filteredTeams);
      setTeamsData({})
      $('html').removeClass('rs_popup_open')
  }

  return (
    <>
      <AgGridNew
       columnDefs={Columns_Headings}
        id="rfq_header_collaboration_team"
        key={gridkey}
        afterDeleteBtns={<AfterDeleteButton />}
        // onCellValueChanged={handleCellValueChange}
        handleDeleteSelectedRows={onTeamDelete}
        onGridReady={handleGridReady}
        isDataSourceRequire={false}
        uniqueField={"teamsourceline_id"}
        handleAddButton={handleAddBtn}
        isAddBtnDisable={isCollaborationTeamsDisable}
        isDeleteBtnDisable={isCollaborationTeamsDisable}
        onAddClick={onAddClick}
        onCellClicked={handleCellClicked}
      />
      {
        showAddTeamPopup && 
        <CollaborationTeamPopup 
        collaborationTeams={collaborationTeams}
        setCollaborationTeams={setCollaborationTeams}
        setTeamsData={setTeamsData}
        onSaveHandler={handleOnRowValueChanged}
        onPopupClose={popUpCloseTeams}
        employeesList={employeesList?.map((item) => {
          return {
            value: item.employee_id,
            label: item.displayname,
          };
        })}
        dynamicTeamsList={dynamicTeamsList?.map((item) => {
          return {
            value: item.teamheader_id,
            label: item.name,
          };
        })}
        accessLevel ={accessLevel?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })}
        teamData={teamsData}
        isDisabled={isCollaborationTeamsDisable}
        />
      }
      {showSelectFromTeamsModal ? (
        <SelectFromTeamsModal
          language={language}
          show={showSelectFromTeamsModal}
          handleCloseModal={handleCloseSelectFromTeamsModal}
          teamsList={teamsList}
          dynamicTeamsList={dynamicTeamsList}
          headerId={rfqHeader?.RFQ_id}
          employeesList={employeesList}
          collaborationTeams={collaborationTeams}
          setCollaborationTeams={setCollaborationTeams}
          setGridKey={setGridkey}
          onSaveHandler={handleOnRowValueChanged}
        />
      ) : null}
    </>
  );
};

export default CollaborationTeamGrid;
