import React, { useRef, useState, useEffect } from 'react'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import Helper from '../../../inc/Helper'
import InvoiceForm from './InvoiceForm'
import HeaderViewDelivery from '../../Purchase Orders Internal/Edit PO/HeaderViewDelivery'
import SegmentsDistribution from '../../Segments/SegmentsDistribution'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import Collapse from '../../inc/Collapse'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import { objectIsEmpty } from '../../../inc/Validation'

const InvoiceGeneralInformation = (props) => {
  const [scrollDitection, setScrollDitection] = useState('down')
  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [height, setHeight] = useState(0)
  const sectionName = 'invoice'
  const myDivRef = useRef()

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef?.current?.clientHeight,scrollDitection]);
  
  let navs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      data_section: 'one',
      active_status: 'active_tab',
    },
    {
      title: Helper.getLabel(props.language, 'delivery', 'Delivery'),
      data_section: 'two',
      active_status: '',
    },
  ]

  const newInvoiceNav = navs.filter(
    (item) =>
      item.title === Helper.getLabel(props.language, 'general', 'General')
  )

  if (props.user?.usertype === 0)
    {
      navs.push({
        title: Helper.getLabel(props.language, 'segment', 'Segment'),
        data_section: 'three',
        active_status: '',
      })
    }

  const onCreateFirstDistribution = (newDisSouceId) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(Settings.apiPurchaseOrderUrl + `/invoice_header/${props.location}`, {
        distributionsource_id: newDisSouceId,
      })
      .then((res) => {})
    props.setInvoiceHeader({
      ...props.invoiceHeader,
      distributionsource_id: newDisSouceId,
    })
  }

  const addComponentRight = () => {
    return (
      objectIsEmpty(props?.invoiceHeader) && !props?.invoiceHeader?.invoice_id ? <></> : <MasterSidebar>
        <div className="work-confirmation-sidebar">
          <NoteAttachmentHistory
            notesource_integrator={props?.id}
            height={height + 80}
            scrollDitection={scrollDitection}
            attachmentsource_integrator={props?.id}
            source_id={props?.invoiceHeader?.source_id || Settings?.source_id?.invoice}
            hideHistory={true}
            condition={props?.invoiceHeader?.document_status===2}
          />
        </div>
      </MasterSidebar>
    )
  }

  return (
    <div className={`scrollBarBlinkControl invoice-general-details ${ objectIsEmpty(props?.invoiceHeader) && !props?.invoiceHeader?.invoice_id ? 'invoice-new-details-screen' : ''}`}>
      <div ref={myDivRef}>
        <FixedNavigationHeder
          sectionName={sectionName}
          navs={!props.location ? newInvoiceNav : navs}
          scrollDitection={scrollDitection}
          selectedSupNav={selectedSupNav}
          addComponentTop={props.addComponentTop}
        />
      </div>
      <TabContentWraper
        height={height - 80}
        setScrollDitection={setScrollDitection}
        leftSideContent={
          <>
            <div
              className='lists_contant one'
              id={`${sectionName}General`}
              style={{ position: 'relative' }}
            >
              <div className='general-information-white'>
                <div className='rfq-bidding-information rounded'>
                  <div className='rfq-information-title '>
                    <h2 className='grid_label_rfq mb-0'>
                      {Helper.getLabel(
                        props.language,
                        'general_information',
                        'General Information'
                      )}
                    </h2>
                  </div>
                  <div className='col-sm-12 p-3 pt-4'>
                    <InvoiceForm
                      invoiceDate={props.invoiceDate}
                      setInvoiceDate={props.setInvoiceDate}
                      documentDate={props.documentDate}
                      setDocumentDate={props.setDocumentDate}
                      invoiceNumber={props.invoiceNumber}
                      setInvoiceNumber={props.setInvoiceNumber}
                      invoiceDescription={props.invoiceDescription}
                      setInvoiceDescription={props.setInvoiceDescription}
                      selectedDocument={props.selectedDocument}
                      setSelectedDocument={props.setSelectedDocument}
                      invoiceHeader={props.invoiceHeader}
                      setInvoiceHeader={props.setInvoiceHeader}
                      location={props?.location}
                      invoiceList={props?.invoicesList}
                      selectedValues={props?.selectedValues}
                      setSelectedValues={props.setSelectedValues}
                      userType={props.user?.usertype}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='lists_contant two mt-4'
              id={`${sectionName}Delivery`}
              style={{ position: 'relative' }}
            >
              {props.location && (
                <HeaderViewDelivery
                  isEditable={props.invoiceHeader?.document_status === 0}
                  state={props?.deliveryData}
                  setState={props?.setDeliveryData}
                  type="invoice"
                  reRenderRequired={true}
                />
              )}
            </div>
            {props.user?.usertype == 0 && props.location && (
              <div
                className='lists_contant three'
                id={`${sectionName}Segment`}
                style={{ position: 'relative' }}
              >
                <Collapse open={true} title="Segments">
                <SegmentsDistribution
                  isEditable={props.invoiceHeader?.document_status === 0}
                  id='invoice_header_segments'
                  disableFinancial={true}
                  distribution_source_id={
                    props?.invoiceHeader?.distributionsource_id
                  }
                  integrator={props.invoiceHeader?.invoice_id || '-'}
                  onCreateFirstDistribution={onCreateFirstDistribution}
                  source_id={Settings?.source_id?.invoice}
                  totalAmount={100}
                  accountingDate={props?.invoiceHeader?.accounting_date}
                />
                </Collapse>
              </div>
            )}
          </>
        }
        rightSideContent={addComponentRight}
      />
    </div>
  )
}

export default InvoiceGeneralInformation
