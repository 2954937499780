import React from 'react'
import { connect } from 'react-redux'
import MasterComonent from '../../Backend/MasterComonent'
import NavigationHeder from '../../Navigations/NavigationHeder'
import Helper from '../../../inc/Helper'
import LeftVersionsSideBar from '../../External Screens/RFQ Vendor Reply/Versions/LeftVersionsSideBar'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { useEffect } from 'react'




const LogsDetails = (props) => {

    const location = useLocation()
    const [versions,setVersions]=useState([])
    const [versionsData,setVersionsData]=useState([])
    
   //Functions for Url
   const getApiUrlForVersionsData = () => {
    let versionsApiUrl
    if(location.state?.logsFactor?.viewType==='1'){
      versionsApiUrl = Settings.apiPurchaseOrderUrl + `/header_h/${location?.state?.logsFactor?.header_id}/${location?.state?.sourceId}`
    }else if(location.state?.logsFactor?.viewType==='2'){
      if(location?.state?.logsFactor?.lineViewType==='2'){
        versionsApiUrl = Settings.apiPurchaseOrderUrl + `/billingrule_history/${location?.state?.logsFactor?.line_type_id}/${location?.state?.sourceId}`
      }else if(location?.state?.logsFactor?.lineViewType==='1'){
        versionsApiUrl = Settings.apiPurchaseOrderUrl + `/linesite_history/${location?.state?.logsFactor?.line_type_id}/${location?.state?.sourceId}`
      }else{
        versionsApiUrl = Settings.apiPurchaseOrderUrl + `/line_history/${location?.state?.logsFactor?.line_id}/${location?.state?.sourceId}`
      }
    }
    return versionsApiUrl
   }

   const getApiUrlForRevision = (version) => {
    let versionsUrl = getApiUrlForVersionsData()
    let revisionUrl = versionsUrl + `/${version}`
    return revisionUrl
   }

    //Api Calls
    const getVersionsData = () => {
      let api = Api
      api.setUserToken()
      api.axios().get(getApiUrlForVersionsData()).then(function(res){
        if(res.data.status==="success"){ 
          setVersions(res.data.data.reverse())
        }
      }) 
    }

    const getVersionData = (version) => {
      let api = Api
      api.setUserToken()
      api.axios().get(getApiUrlForRevision(version)).then(function(res){
        if(res.data.status==="success"){ 
          const rowData = Object.keys(res.data.data[0].new_values).map((key) => ({
            field: key,
            old_value: res.data.data[0].old_values[key],
            new_value: res.data.data[0].new_values[key],
          }));
  
          setVersionsData(rowData);
  
        }
      }) 
    }

    const CellHandler = (event) => {
      if (event?.colDef?.field === 'revision') {
       if(event.data?.revision===null || event?.data?.revision===undefined) return
       getVersionData(event?.data?.revision)
      }
    }
  

    useEffect(()=>{
      getVersionsData()
    },[location])

    const RenderGridVersionsLabel = () => {
    return (
      <h2 className="grid_label_rfq">
        {Helper.getLabel(props.language, "changed_fields", "Changed Fields")}
      </h2>
    );
    }; 


    const gridLinkValueFormat = (params) => {
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    };
   
    const fieldValueFormatter = (params) => {
      return `<div style="text-transform:capitalize;">${params?.value?.replace(/_/g, " ")}</div>`;
    };
 
      //columns heading/title data
      const versionTableHeader = [
        {
          field: "revision",
          headerName: Helper.getLabel(props.language, "revision", "Revision"),
          minWidth: 50,
          maxWidth:140,
          cellRenderer: CustomHtmlCell,
          editable:false,
          valueFormatter: gridLinkValueFormat,
        },
        {
          field: "modifieddatetime",
          headerName: Helper.getLabel(props.language, "change_of_date_time", "Change of date & time"),
          minWidth: 125,
          editable:false,
          inputType: "datepicker",
        },
      ];

      const fieldTableHeader = [
        {
          field: "field",
          headerName: Helper.getLabel(props.language, "field label", "Field Label"),
          minWidth: 105,
          cellRenderer: CustomHtmlCell,
          valueFormatter:fieldValueFormatter,
        },
        {
          field: "old_value",
          headerName: Helper.getLabel(props.language, "old_value", "Old Value"),
          minWidth: 125,
        },
        {
          field: "new_value",
          headerName: Helper.getLabel(props.language, "new_value", "New Value"),
          minWidth: 125,
        },
      ];

  return (
    <div className=''>
      <MasterComonent>
        <NavigationHeder
          backUrl={-1}
          title={`${Helper.getLabel(props.language, 'logs', 'Logs')}`}
        ></NavigationHeder>
        <div>
          <div className="rfq-versions-container">
            <LeftVersionsSideBar
              header={versionTableHeader}
              versions={versions}
              CellHandler={CellHandler}
              title={Helper.getLabel(props.language,"logs","Logs")}
            />
           <div style={{width:"100%"}}>
            <AgGrid header={fieldTableHeader} data={versionsData} allBtnHide={true} afterDeleteButtons={RenderGridVersionsLabel}></AgGrid>
           </div>
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(LogsDetails)
