import React from 'react'
import Dropdown from '../../../Forms/Dropdown'
import Helper from '../../../../inc/Helper'
import Input from '../../../Forms/Input'
import SimpleLoading from '../../../Loading/SimpleLoading'
import Settings from '../../../../inc/Settings'
import Api from '../../../../inc/Api'
import NewInputDatePicker from '../../../Forms/NewInputDatePicker'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'

const BiddingForm = ({
  language,
  rfqHeader,
  isLoading,
  setRfqHeader,
  currencyList,
  allFieldsAreZero,
  type,
  bidStatus,
  lineLevelStatus,
}) => {
  const handleDropdownChange = (e) => {
    const newCurrencyId = e.target.value
    const tempData = {
      currency_id: parseInt(newCurrencyId),
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/RFVendorreplyheader/${rfqHeader?.RFQ_vendorreply_id}`,
        tempData,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          setRfqHeader((prev) => {
            return { ...prev, ...tempData }
          })
        }
      })
      .catch(function (error) {
        getPopupMessageBasedOnStatus(error)
        setRfqHeader((prev) => {
          return { ...prev, currency_id:rfqHeader?.currency_id}
        })
      })
  }
  return (
    <div className="generalInformation">
      {isLoading ? (
        <SimpleLoading />
      ) : (
          <div className="generalInformation-basic rfq-general-bidding-form">
            <div className="generalInformation-basic-grid">
              <Input
                label={`${Helper.getLabel(language, 'document_no', 'Document number')}`}
                value={rfqHeader?.document_number}
                disable={true}
                onChange={(e) => {}}
              />
               <Input
                label={`${Helper.getLabel(language, 'rfq_document_no', 'RFQ document number')}`}
                value={rfqHeader?.RFQ_document_number}
                disable={true}
                onChange={(e) => {}}
              />
              <Dropdown
                label={`${Helper.getLabel(
                  language,
                  'bid_currency',
                  'Bid currency',
                )}`}
                id="rfq_currency"
                options={currencyList?.map((item) => {
                  return {
                    value: item.currency_id,
                    label: `${item.currency_code} - ${item.currency_name}`,
                  }
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_currency',
                  'Select currency',
                )}`}
                onChange={handleDropdownChange}
                value={
                  type === 'versions'
                    ? rfqHeader?.currency?.currency_id
                    : rfqHeader?.currency_id
                }
                reRenderRequired={true}
                isDisable={
                  rfqHeader?.interested_to_participate === false ||
                  rfqHeader?.interested_to_participate === null ||
                  allFieldsAreZero === true ||
                  type === 'versions' ||
                  bidStatus || rfqHeader?.onhold || rfqHeader?.RFQ_status === 2 || rfqHeader?.RFQ_status === 9
                  // || lineLevelStatus
                }
              />
                 <NewInputDatePicker
                label={`${Helper.getLabel(
                  language,
                  'expiration_date_time',
                  'Expiration date and time',
                )}`}
                timePicker={true}
                name="expiry_date_time"
                value={rfqHeader?.expiry_datetime}
                disable={true}
                onChange={(e) => {}}
              />
              <Input
                label={`${Helper.getLabel(language, 'title', 'RFQ title')}`}
                value={rfqHeader?.title}
                disable={true}
                onChange={(e) => {}}
              />
            </div>
            {rfqHeader?.interested_to_participate === false &&
            type !== 'versions' ? (
              <Input
                label={`${Helper.getLabel(language, 'comments', 'Comments')}`}
                value={rfqHeader?.comment_no_participation}
                onChange={(e) => {}}
                disable={true}
              />
            ) : (
              ''
            )}
          </div>
      )}
    </div>
  )
}

export default BiddingForm
