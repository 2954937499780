import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import FormValidator from "../../../../inc/FormValidator";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import Helper from "../../../../inc/Helper";
import $ from "jquery";
import FieldsRenderer from "../../../Purchase Orders Internal/Edit PO/FieldsRenderer";
import { getPopupMessageBasedOnStatus } from "../../../../inc/Validation";
import OverlayLoader from "../../../PurchaseRequisition/common/OverlayLoader";
import Popup from "../../../Popup/Popup";
import Collapse from "../../../inc/Collapse";
import { Form } from "react-bootstrap";
import Button from "../../../inc/Button";
import NavigationHeder from "../../../Navigations/NavigationHeder";
import { Link } from "react-router-dom";

let cancelTokenSource = axios.CancelToken.source();
const NonCompliancePolicyPopup = ({
  policy_id,
  reloadParentGrid,
  setShowCreateModal,
  language,
}) => {
  const [formData, setFormData] = useState({});

  const [apiCallBtn, setApiCallBtn] = useState(false);

  let formValidatory;

  const validationConfigure = () => {
    let formFieldConfig = [
      {
        name: "name",
        displayName: Helper.getLabel(language, "name", "Name"),
        types: ["Required"],
      },
    ];

    if (!formValidatory) {
      formValidatory = new FormValidator(formFieldConfig, language);
    }
  };

  validationConfigure();

  let nonCompliancePolicyObjCalled = false;

  useEffect(() => {
    if (!policy_id) {
      setFormData({});
      return;
    }
    getNonCompliancePolicyByID();
  }, []);

  const getNonCompliancePolicyByID = () => {
    if (nonCompliancePolicyObjCalled || !policy_id) return;
    nonCompliancePolicyObjCalled = true;

    setApiCallBtn(true);
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiUrl +
          `/non_compliance_policy/${policy_id}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setFormData(res?.data?.data);
          setApiCallBtn(false);
        }
      })
      .catch((e) => {
        setApiCallBtn(false);
        setFormData({});
      });
  };

  const fieldsArrayGeneral = [
    {
      className: "col-lg-4",
      required: true,
      maxLength: 50,
      disable: apiCallBtn,
      label: Helper.getLabel(language, "name", "Name"),
      inputType: "text",
      id: "name",
      name: "name",
    },
    {
      className: "col-lg-4",
      required: true, 
      disable: apiCallBtn,
      label: Helper.getLabel(language, "delivery_date_is_past_date", "Delivery date is past date"),
      inputType: "checkbox",
      id: "delivery_date_is_past_date",
      name: "delivery_date_is_past_date",
    },
    {
      className: "col-lg-4",
      required: true,
      disable: apiCallBtn,
      label: Helper.getLabel(language, "delivery_date_is_pr_creation_date", "Delivery date is PR creation date"),
      inputType: "checkbox",
      id: "delivery_date_is_pr_creation_date",
      name: "delivery_date_is_pr_creation_date",
    },
    {
      className: "col-lg-4",
      disable: apiCallBtn,
      label: Helper.getLabel(language, "start_date_is_past_date", "Start date is past date"),
      inputType: "checkbox",
      id: "start_date_is_past_date",
      name: "start_date_is_past_date",
    },
    {
      className: "col-lg-4",
      disable: apiCallBtn,
      label: Helper.getLabel(language, "start_date_is_pr_creation_date", "Start date is PR creation date"),
      inputType: "checkbox",
      id: "start_date_is_pr_creation_date",
      name: "start_date_is_pr_creation_date",
    },
    {
      className: "col-lg-4",
      disable: apiCallBtn,
      label: Helper.getLabel(language, "non_compliance_request_allowed_to_be_converted_into_rfq", "Non compliance request allowed to be converted into RFQ"),
      inputType: "checkbox",
      id: "non_compliance_request_allowed_to_be_converted_into_rfq",
      name: "non_compliance_request_allowed_to_be_converted_into_rfq",
    },
    {
      className: "col-lg-4",
      disable: apiCallBtn,
      label: Helper.getLabel(language, "active", "Active"),
      inputType: "checkbox",
      id: "active",
      name: "active",
    }
  ];

  const executePOSTAPI = async () => {
    try {
      let api = Api;
      api.setUserToken();

      const res = await api.axios().post(
        Settings.apiUrl + "/non_compliance_policy",
        {
          ...formData, 
          delivery_date_is_past_date: formData.delivery_date_is_past_date == "on" || formData.delivery_date_is_past_date == true,
          delivery_date_is_pr_creation_date: formData.delivery_date_is_pr_creation_date == "on" || formData.delivery_date_is_pr_creation_date == true,
          start_date_is_past_date: formData.start_date_is_past_date == "on" || formData.start_date_is_past_date == true,
          start_date_is_pr_creation_date: formData.start_date_is_pr_creation_date == 'on' || formData.start_date_is_pr_creation_date == true,
          non_compliance_request_allowed_to_be_converted_into_rfq: formData.non_compliance_request_allowed_to_be_converted_into_rfq == "on" || formData.non_compliance_request_allowed_to_be_converted_into_rfq == true,
          active: formData.active == 'on' || formData.active == true
        },
        { cancelToken: cancelTokenSource.token }
      );

      if (res.data.status === "success") {
        Helper.alert(res?.data?.message);
        reloadParentGrid();
        onPopupClose();
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const executePUTAPI = async () => {
    try {
      let api = Api;
      api.setUserToken();

      const res = await api.axios().put(
        Settings.apiUrl + `/non_compliance_policy/${policy_id}`,
        {
          ...formData, 
          delivery_date_is_past_date: formData.delivery_date_is_past_date == "on" || formData.delivery_date_is_past_date == true,
          delivery_date_is_pr_creation_date: formData.delivery_date_is_pr_creation_date == "on" || formData.delivery_date_is_pr_creation_date == true,
          start_date_is_past_date: formData.start_date_is_past_date == "on" || formData.start_date_is_past_date == true,
          start_date_is_pr_creation_date: formData.start_date_is_pr_creation_date == 'on' || formData.start_date_is_pr_creation_date == true,
          non_compliance_request_allowed_to_be_converted_into_rfq: formData.non_compliance_request_allowed_to_be_converted_into_rfq == "on" || formData.non_compliance_request_allowed_to_be_converted_into_rfq == true,
          active: formData.active == 'on' || formData.active == true
        },
        { cancelToken: cancelTokenSource.token }
      );

      if (res.data.status === "success") {
        Helper.alert(res?.data?.message);
        reloadParentGrid();
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const onPopupClose = () => {
    $("html").removeClass("rs_popup_open");
    setShowCreateModal(false);
  };

  const moreOptions = () => {
    return (
      <div>
        <ul className='actions_nav'>
          <li>
            <Link
              to={`/non-compliance-policy-history`}
            >
              View History
            </Link>
          </li> 
        </ul>
      </div>
    )
  }

  const handleSubmit = () => {
    if (!formValidatory.isValid({ ...formData })) {
      formValidatory.displayMessage();
      formValidatory.reset();
      return;
    }

    if (!policy_id) {
      Helper.createDebouncedAPIFunction(
        [executePOSTAPI],
        setApiCallBtn,
        cancelTokenSource
      )();
    } else {
      Helper.createDebouncedAPIFunction(
        [executePUTAPI],
        setApiCallBtn,
        cancelTokenSource
      )();
    }
  };

  return (
    <Fragment>
      {apiCallBtn ? <OverlayLoader /> : null}
      <Popup autoOpen={true} width="1000px" onClose={onPopupClose}>
        <> 
          <NavigationHeder moreOptions={moreOptions} hideBackButton={true} title={!policy_id ? "Create Non Compliance Policy" : "Edit Non Compliance Policy"} />

          <Collapse
            className="mt-4 mb-4"
            open={true}
            title={Helper.getLabel(
              language,
              "general_info",
              "General Information"
            )}
          >
            <Form>
              <div className="container-fluid">
                <FieldsRenderer
                  updateState={setFormData}
                  currentState={formData}
                  fieldsArray={fieldsArrayGeneral}
                />
              </div>
            </Form>
          </Collapse>
        </>

        <div
          className="addDocumentNumber-body-buttons"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            isActive={false}
            isDisable={apiCallBtn}
            className='black-btn-style'
            title={Helper.getLabel(language, "close", "Close")}
            onClick={onPopupClose}
          />

          <Button
            isDisable={apiCallBtn}
            className={policy_id ? "rfq_save_btn" : "solid_btn"}
            isActive={!policy_id}
            title={
              !policy_id
                ? Helper.getLabel(language, "create", "Create")
                : Helper.getLabel(language, "save", "Save")
            }
            onClick={handleSubmit}
          />
        </div>
      </Popup>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(NonCompliancePolicyPopup);