
class MasterCellEditor{
    addClass(selector,className){
        className.split(' ').forEach(clsName => {
            selector.classList.add(clsName)
        });
        /*
        const att = document.createAttribute("class");
        att.value = className;
        selector.setAttributeNode(att);
        */
    }
    removeClass(selector,className){
        selector.classList.remove(className)
    }
    addAttribute(selector,attribute,value){
        const att = document.createAttribute(attribute);
        att.value = value;
        selector.setAttributeNode(att);
    }
    removeSpaces(str) {
        return str ? str.replace(/\s/g, '') : str;
    }
}

export default MasterCellEditor;