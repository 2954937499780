import React, { Component } from 'react';
import MasterComonent from '../../components/Backend/MasterComonent';
import EditWarehouse from '../../components/Warehouse/form/EditWarehouse';
import NewWarehouse from '../../components/Warehouse/form/NewWarehouse';
import Warehouse from '../../components/Warehouse/Warehouse';

class WarehouseScreen extends Component {
    displayComponent = (props) =>{
        switch(props.pageName){
            case 'new':
                return <NewWarehouse/>
            case 'edit':
                return <EditWarehouse/>
            default:
                return <Warehouse/>
        }
    }
    render() {
        return (
            <div>
                <MasterComonent>
                    { this.displayComponent(this.props)}
                </MasterComonent>
            </div>
        );
    }
}

export default WarehouseScreen;