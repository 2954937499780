import React from 'react'
import CommonChartCard from './CommonChartCard'
import { Doughnut } from 'react-chartjs-2'
import CommonInnerCircle from '../../Employee Dashboard/EmployeeDasboardScreen/EmployeeInfoCharts/EmployeeTasksInfo/common/CommonInnerCircle'

import {Chart,ArcElement,Tooltip} from 'chart.js';

Chart.register(ArcElement,Tooltip)


//data for chart
export const data = {
  labels: ['Emily Johnson', 'Rajesh Kumar', 'Juanita Lopez','Wei Zhang','Youssef El-Mahdy'],
  datasets: [
    {
      data: [15, 30, 7, 9, 34],
      backgroundColor: ['#8C62FF', '#2DD4BF', '#FE964A',  '#FFD700', '#B57EDC'],
      borderColor: 'white',
      hoverBorderColor: 'white',
      borderWidth: 8,
      borderJoinStyle: 'round',
      borderRadius: 8,
    },
  ],
}

const ContractEmployeeChart = () => {
  //options for chart
  const options = {
    cutout: "75%",
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        direction: 'column',
        fullWidth: false,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: 'circle',
          pointRadius: 3,
          padding: 20,
        },
        align: 'start',
      },
      tooltip:{
        boxPadding:5,
      },
    },
  }
  const totalTasks = data.datasets[0].data.reduce((acc, num) => acc + num, 0)
  return (
    <div style={{ position: 'relative' }}>
      <CommonChartCard title="Employee-wise contract distribution">
        <Doughnut data={data} options={options}></Doughnut>
        <CommonInnerCircle totalTasks={totalTasks} />
      </CommonChartCard>
    </div>
  )
}

export default ContractEmployeeChart
