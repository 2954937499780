import React, { useState } from 'react';
import MasterComonent from '../Backend/MasterComonent';
import NavigationHeder from '../Navigations/NavigationHeder';
import Button from '../inc/Button';
import QuestionnaireForm from './QuestionnaireForm';
import Helper from '../../inc/Helper';
import QuestionData from './QuestionnaireData';

const TestQuestionnaire = (props) => {
const [testQuestionnaire,setTestQuestionnaire] = useState(false)
 const handleQuestionnaire = () => {
    setTestQuestionnaire(!testQuestionnaire)
 }
 const closeQuestionnaire = () => {
    setTestQuestionnaire(false)
 }
  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'Questionnaire', 'Questionnaire')}`} hideMoreBtn={true}>
            <Button title={`${Helper.getLabel(props.language, 'begin_questionnaire', 'Begin Questionnaire')}`} onClick={handleQuestionnaire}></Button>
        </NavigationHeder>
        {testQuestionnaire ? <QuestionnaireForm onQuestionnaireClose={closeQuestionnaire} QuestionData={QuestionData} /> : <></>}
      </MasterComonent>
    </div>
  );
}

export default TestQuestionnaire;
