import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import AgGrid from '../../grid/ag/ag-grid';
import Collapse from '../../inc/Collapse'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import LinkBtn from '../../inc/LinkBtn';
import SimpleLoading from '../../Loading/SimpleLoading';
import Button from '../../inc/Button';
import Input from '../../Forms/Input';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import FormValidator from '../../../inc/FormValidator';  
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useParams } from 'react-router-dom';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { CustomAPICallsRenderer } from '../../grid/ag/cellRender/CustomAPICallsRenderer';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

/* taxcodes_id -> is required field, the taxcode api call for its object, then value_percentage - value_exempted_percetange ( looping through ), Settings.apiUrl + '/taxcodes/' + codesId */

/*
let codesId = Helper.getOnlyInt(params.data.taxcodes_id, true);
if (codesId) {
    const api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiUrl + '/taxcodes/' + codesId).then(res => {
        let totalTaxValue = 0;
        res.data.data.codes_values.forEach(item => {
            totalTaxValue += Helper.getPositiveNumber(item.value_percentage) - Helper.getPositiveNumber(item.percentage_exempt_from_tax);
        });
        div.innerHTML = totalTaxValue;
    });
}
*/

let gridApi = null;
let AddBtn;
let transaction;

const EditTaxGroup = ({ language }) => {
    const { id } = useParams();

    const [gridKey, setGridKey] = useState(Date.now());
    const [taxGroup, setTaxGroup] = useState({}); 
    const [taxCodes, setTaxCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 

    const wrapperDiv = useRef();
    const [height, setHeight] = useState(0);

    let isTaxcodesLoaded = false;
    let isTaxGroupLoaded = false;

    let validator; 
  
    useEffect(() => { 
        Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
    }, [wrapperDiv?.current?.clientHeight, isLoading]);

    useEffect(() => {
        loadTaxGroup()
        loadTaxCodes()
    }, [id])

    const validationConfigure = () => {
        let fieldConfig = [
            { name: 'name', displayName: Helper.getLabel(language, 'name', 'Name'), types: ['Required'] }
        ];

        if(!validator) {
            validator = new FormValidator(fieldConfig, language)
        };
    };

    validationConfigure();

    const loadTaxGroup = () => { 
        if (isTaxGroupLoaded) return;
        isTaxGroupLoaded = true;

        const api = Api;
        api.setUserToken();
        setIsLoading(true);
        api.axios().get(Settings.apiUrl + '/taxgroup/' + id).then(res => {
            setIsLoading(false);
            setTaxGroup(res?.data?.data);
        }).catch((error) => {
            setIsLoading(false);
        })
    };

    const loadTaxCodes = () => {
        if (isTaxcodesLoaded) return;
        isTaxcodesLoaded = true;
 
        const api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/taxcodes').then(res => {
            if (res.data.status === 'success') {
                setTaxCodes(res?.data?.data?.map(taxCode => {
                    return {
                        label: taxCode.name,
                        value: taxCode.taxcodes_id
                    };
                }));
            } 
        });
    };

    const onInputHander = e => {
        setTaxGroup((prevState) => {
            return {
                ...prevState,
                name: e?.target?.value
            }
        })
    };

    const onSaveHandler = e => { 
        const validData = { ...taxGroup };
        if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        };
 
        const api = Api;
        api.setUserToken();  

        api.axios()
            .put(Settings.apiUrl + '/taxgroup/' + taxGroup.taxgroup_id, taxGroup)
            .then(res => {
                setIsLoading(false);  
                isTaxGroupLoaded = false;
                loadTaxGroup();
                Helper.alert(res.data.message);
            })
            .catch(res => {
                setIsLoading(false); 
                getPopupMessageBasedOnStatus(res);
            });
    };
  
    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const onRowValueChanged = (event) => {
        let apiPayload = {
            tax_codes_id: parseInt(event?.data?.taxcodes_id),
            exempted: event?.data?.exempted ? true : false,
            exempt_code: null,
            tax_group_id: taxGroup?.taxgroup_id
        };

        if (!event?.data?.isNew) {
            updateTaxGroupCode(event, apiPayload)
        } else {
            createTaxGroupCode(apiPayload)
        }
    }

    let dataSource = {
        getRows: async function (params) { 
            params?.success({
                rowData: taxGroup?.group_codes?.map((item) => ({
                    ...item,
                    gridId: `${Date.now()}_${getNewValue()}`
                })),
                rowCount: taxGroup?.group_codes?.length
            })
        },
    };

    const onTaxGroupGridReady = (params) => {
        if(params){
            gridApi = params?.api
            setTimeout(()=> { 
                params?.api?.setGridOption('serverSideDatasource', dataSource);
            }, 200)
        };
    };

    const createTaxGroupCode = async (payload) => {
        try { 
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiUrl + '/taxgroupcodes', payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    loadTaxGroup();
                    gridApi?.deselectAll();
                }, 100);

                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.applyServerSideTransaction(transaction);
                    }
                }, 1000);

                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };

    const updateTaxGroupCode = async (event, payload) => {
        try { 
            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiUrl+'/taxgroupcodes/'+event?.data?.taxgroup_id+'/'+event?.data?.taxcodes_id, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    loadTaxGroup();
                    gridApi?.deselectAll();
                }, 100);

                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };

    const GroupCodesDeleteHandler = useCallback(async (element, index, lastIndex) => { 
        let api = Api;
        api.setUserToken();

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + '/taxgroupcodes/'+element?.data?.taxgroup_id+'/'+element?.data?.taxcodes_id)
            const rowNode = gridApi?.getRowNode(element?.data?.taxcodes_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction) 
                setTaxGroup((prevTaxGroup) => ({
                    ...prevTaxGroup,
                    group_codes: prevTaxGroup?.group_codes?.filter(
                        (code) => code?.taxcodes_id !== element?.data?.taxcodes_id
                    ),
                }));
                setGridKey(Date.now())
            }, 200);

            if(index === lastIndex) {
                loadTaxGroup();
            }

            setTimeout(() => { 
                gridApi?.deselectAll();
            }, 100);
        
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
 
    /* Grid Column Headers */

    const gridColHeaders = [
        {
            field: 'taxcodes_id',
            headerName: Helper.getLabel(language, "tax_codes", 'Tax codes'),
            editable: (event) => event?.data?.isNew,
            cellEditor: SimpleDropdown,
            isAllowZero: true,
            minWidth:180, 
            cellEditorParams: {
              values: taxCodes
            },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, taxCodes),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label
            }
        }, 
        {   
            field: 'exempted', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
            editable: true, headerName: Helper.getLabel(language, "exempted", 'Exempted'), 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            cellRendererParams: { disabled: true },
            valueGetter: (params) => {
                if (!params?.data?.exempted || !params?.data){
                    return false
                }
                else {
                    return params.data?.exempted
                }
            }
        },
        // { field: 'exemptedcode', headerName: Helper.getLabel(language, "exempt_code", 'Exempt code'), inputType: 'dropdown', options: taxCodesList },
        { 
            field: 'percentage', 
            filter: false,
            sortable: false,
            cellRenderer: (params) => CustomAPICallsRenderer(params, Settings.apiUrl+'/taxcodes/'+params?.data?.taxcodes_id, '0'),
            headerName: Helper.getLabel(language, "percentage", 'Percentage'), 
            editable: false 
        }
    ];

    return (
        <Fragment> 
            { isLoading ? <OverlayLoader /> : null }
            <div className='container-fluid new_settlement_form taxcodes_page'>
                <div ref={wrapperDiv}>
                    <NavigationHeder hideMoreBtn={true} backUrl="/tax/group" title={Helper.getLabel(language, 'tax_codes', 'Tax Group')}>
                        <LinkBtn to="/tax/group" className='black-btn-style' isActive={false} title={Helper.getLabel(language, 'close', 'Close')} />
                        <Button isDisable={isLoading} onClick={e => onSaveHandler(e)} className="rfq_save_btn" title={Helper.getLabel(language, 'save', 'Save')} /> 
                    </NavigationHeder>
                </div>

                {isLoading ? null : (
                    <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
                        <Collapse title={Helper.getLabel(language, 'general', 'General')} open={true}>
                            <div className='rs_form_container'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Input name="name" hasError={validator.hasError('name')} value={taxGroup.name} label={Helper.getLabel(language, 'name', 'Name')} required={true} onChange={onInputHander} />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        
                        <Collapse title={Helper.getLabel(language, 'group_codes', 'Group Codes')} open={true}>
                            <AgGridNew
                                pagination={false}
                                columnDefs={gridColHeaders}
                                onRowValueChanged={onRowValueChanged}
                                key={gridKey}
                                handleDeleteSelectedRows={GroupCodesDeleteHandler}
                                height={500}
                                onGridReady={onTaxGroupGridReady}
                                handleAddButton={handleBtn}
                                uniqueField={`taxcodes_id`}
                                gridId={'taxgroup_grid'}
                            />
                        </Collapse>
                    </div>
                )} 
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language
    }
};

export default connect(mapStateToProps)((EditTaxGroup));