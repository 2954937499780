import React, { Component } from 'react';
import AmendmentRequest from '../../components/Purchase Orders Internal/AmendmentRequest/AmendmentRequest';

class CoAmendmentEditPage extends Component {
    render() {
        return (
            <AmendmentRequest
                backUrl = '/contract-order/amendment-request/all'
            />
        );
    }
}

export default CoAmendmentEditPage;