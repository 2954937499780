import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import CommonChartCard from "../ContractSecondRow/CommonChartCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ContractByItemGroup = () => {
    const options = {
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        barPercentage: 0.4,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      }

  const data = {
    labels: ['IT Services', 'Facilities Management', 'Human Resources','Legal Services','Marketing and Communications','Professional Consulting','Logistics and Supply Chain','Catering and Hospitality','Training and Development','Health and Safety'],
    datasets: [
      {
        data: [1578700,4879000,1459700,256900,57900,2489900,158790,346000,420000,157900],
        backgroundColor: [
          "#00B8D9",
          "#FFAB00"
        ],
      },
    ],
  };
  return (
      <CommonChartCard title='Contract value by Item Group'>
        <Bar options={options} data={data} />
      </CommonChartCard>
  )
};

export default ContractByItemGroup;
