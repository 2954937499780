import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Settings from '../../inc/Settings';
import AuthCheck from './AuthCheck';
import LoginForm from './Login/LoginForm';
import RegisterForm from './Register/RegisterForm';
import { connect } from 'react-redux';
class LoginRegisterTab extends Component {
    constructor(props){
        super(props); 
    }
    render() {
        let logo = '/images/login-reg-screen-logo.png';
        return (
            <div className='loginreg_contents'>
                <div className='login_regiser_box'>
                    <AuthCheck noAccessForLogin={this.props?.user?.admin_user?("/"+Settings.redirectAdminAfterLogin):("/"+Settings.redirectUrlAfterLogin)}/>
                    <Link to="/" className='login_logo'><img src={logo} alt="Lynkaz"/></Link>
                    <div className='lr_box_inner'>
                        <div className='log_reg_th'>
                            <Link to="/login" className={ this.props.tab === "login" ? 'active' : '' } >Log in </Link>
                            <Link to="/register" className={ this.props.tab === "register" ? 'active' : '' }> Register</Link>
                        </div>
                        { this.props.tab === "login" ?  <LoginForm/> : <RegisterForm/> }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user:state.auth.user
    }
}
 
export default connect(mapStateToProps) (LoginRegisterTab);