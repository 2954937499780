import React, { useEffect, useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import AgGrid from '../grid/ag/ag-grid'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Button from '../inc/Button'
import ParticipantsPopup from './ParticipantsPopup'
import $ from 'jquery'

const QuestionnaireIntegration = (props) => {
  const [questionnaireList, setQuestionnaireList] = useState([])
  const [participantsPopup,setParticipantsPopup]=useState(false)

  const onPopupOpen = () => {
    setParticipantsPopup(true)
  }

  const onPopupClose = () => {
    setParticipantsPopup(false)
    $('html').removeClass('rs_popup_open');
  }

  const getQuestionnaireList = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/questionnaire`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionnaireList(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onQuestionnaireDelete = (allRows) => {
    let api = Api
    api.setUserToken()
    allRows.forEach((element) => {
    //   api
    //     .axios()
    //     .delete(Settings.apiUrl + `/questionnaire/${element.questionnaire_id}`)
    //     .then(function (res) {
    //       if (res.data.status === 'success') {
    //         Helper.alert(res.data.message)
    //         getQuestionnaireList()
    //       }
    //     })
    //     .catch((res) => {
    //       getPopupMessageBasedOnStatus(res)
    //     })
    })
  }

  useEffect(() => {
    getQuestionnaireList()
  }, [])

  let allQuestionnaireList = questionnaireList.map((item=>{
    return {
        label:item.description,
        value:item.questionnaire_id
    }
  }))

  const Columns_Headings = [
    {
      field: 'questionnaire_id',
      minWidth: 280,
      inputType:'dropdown',
      options:allQuestionnaireList,
      headerName: Helper.getLabel(props.language, 'questionnaire', 'Questionnaire'),
      editable: true,
    },
    {
      field: 'operation',
      minWidth: 120,
      headerName: Helper.getLabel(props.language, 'operation', 'Operation'),
    },
    {
      field: 'status',
      minWidth: 350,
      headerName: Helper.getLabel(props.language, 'status', 'Status'),
      editable: false,
    },
  ]
 

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'questionnaire', 'Questionnaire')}`} hideMoreBtn={true}>
          <Button title={Helper.getLabel(props.language, 'view_parcticipants', 'View Participants')} isActive={true} onClick={onPopupOpen} />
        </NavigationHeder>
        <div>
          <AgGrid id='rfq-questionnaire-list' header={Columns_Headings} data={[]} hideSave={true} onDelete={onQuestionnaireDelete} viewOnly={true}/>
        </div>
        {participantsPopup && <ParticipantsPopup onPopupCloseClickHandler={onPopupClose} />}
      </MasterComonent>
    </div>
  )
}

export default QuestionnaireIntegration
