import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import store from '../../store';  

// use empty={true} when you required to show blank area on DatePicker field...
// here minDate, maxDate for set maximum and minimun date set into date AgPickerField...
const NewInputDatePicker = ({ label, name = '', id, value, empty=false, disable = false, onChange, timePicker = false, required=false, minDate, maxDate, timeOnly, portalId }) => {
    let userFormateOfDate = store.getState().dateFormate.formate;  
    
    return (
        <div className="form-group input-datepicker-lynkaz">
            <label>{label} {required ? <span style={{color:'red'}}>*</span> : ''}</label>
            <div className="rs_input_box_wraper">
                <DatePicker
                    id={id}
                    name={name}
                    className="form-control"
                    selected={value ? new Date(value) : empty ? null : new Date()}
                    onChange={(date) => onChange(date,name)}
                    dateFormat={timeOnly?'HH:mm:ss':`${userFormateOfDate ? userFormateOfDate : 'YYYY/MM/DD'} ${timePicker ? 'h:mm aa' : ''}`}
                    showTimeSelect={timePicker}
                    portalId={portalId}
                    disabled={disable}
                    autoComplete='off'
                    showTimeSelectOnly={timeOnly}
                    minDate={minDate?minDate:null}
                    maxDate={maxDate?maxDate:null}
                />
            </div>
        </div>
    )
};

export default NewInputDatePicker; 