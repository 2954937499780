import React from "react";

const SwitchViewDynamic = ({ viewComponent, setViewComponent, headerList,showImage }) => {
  const onChangeViewComponent = (title) => {
    setViewComponent(title);
  };

  return (
    <section className="view_display_components mt-2 mb-2">
      <div className="view_btns d-flex gap-4">
        {headerList.map((header, index) => {
          return (
            <button
              key={header + index}
              className={`view_btn_class ${
                viewComponent === header ? "view_btn_class_active" : ""
              }`}
              onClick={() => onChangeViewComponent(header)}
            >
              {header.toLowerCase().includes("line") && showImage === true ? (
                <span>
                  <img
                    className="img-fluid mb-1 me-2 img_class"
                    src={
                      viewComponent.toLowerCase().includes("list")
                        ? "/images/icons/list-bullets-colored.svg"
                        : "/images/icons/list-bullets.svg"
                    }
                    alt="list view"
                  />
                </span>
              ) : null}
              {header}
            </button>
          );
        })}
      </div>
    </section>
  );
};

export default SwitchViewDynamic;
