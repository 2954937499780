import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    segmentsValues:null,
    isSegmentValueLoaded:false
}
const SegmentsValuesReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_SEGMENTS_VALUES){
        state = {
            ...state,
            isSegmentValueLoaded:true,
            segmentsValues:actions.payload
        }
    }
    return state;
}
export default SegmentsValuesReducer;