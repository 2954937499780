import React, { useState,useEffect } from 'react'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import SimpleLoading from '../../Loading/SimpleLoading'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getInvoiceSummaryColumns } from './ColumnsHeadings'

let gridObj=null

const InvoiceSummary = (props) => {
  const [total,setTotal]=useState({})
  const [loading,setLoading]=useState(true)
  const [vendorAddress,setVendorAddress]=useState([])
  const [tenantAddress,setTenantAddress]=useState([])
  let stepCondition = 3
  const getTotal = () => {
    let api = Api
    api.setUserToken()
    if (!props.invoiceHeader || props.currentStep !== stepCondition) return
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/total/${props?.invoiceHeader?.source_id}/${props.invoiceHeader?.invoice_id}`,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          setTotal(res.data.data)
          setLoading(false)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const getFilterAddresses = (type,addressSourceId) => {
    let api = Api;
    api.setUserToken();
    if (!props.invoiceHeader || props.currentStep !== stepCondition || !addressSourceId) return;

    api
      .axios()
      .get(Settings.loginUrl + `/get_filter_addresses/${addressSourceId}?is_primary=${true}`)
      .then(function (res) {
        if (type === 'vendor') {
          setVendorAddress(res.data.data);
        } else if (type === 'tenant') {
          setTenantAddress(res.data.data);
        }
      })
      .catch((res) => {
        // getPopupMessageBasedOnStatus(res) 
      });
  };

  useEffect(()=>{
    getTotal()
    getFilterAddresses('vendor',props?.invoiceHeader?.vendor_addresssource_id)
    getFilterAddresses('tenant',props?.user?.tenant_addresssource_id)
  },[])


  const summaryGridReady = (gridApi) => {
    gridObj = gridApi
  }


  const GridColumnsHeadingLines = getInvoiceSummaryColumns({
      language:props.language
  })

  if(loading){
    return <SimpleLoading />
  }

  return (
   <div className='p-4 summary-grid-style'>
    {<div className='generalInformation-basic-grid mb-4'>
      <div>
        <p className='mb-1 invoice-summary-title'>Invoice Description</p>
        <p className='mb-0 invoice-summary-data'>{props.invoiceHeader?.description}</p>
      </div>
      <div>
        <p className='mb-1 invoice-summary-title'>Invoice Id</p>
        <p className='mb-0 invoice-summary-data'>{props.invoiceHeader?.reference_number}</p>
      </div>
      <div>
        <p className='mb-1 invoice-summary-title'>Invoice Date</p>
        <p className='mb-0 invoice-summary-data'>{DatepickerFunctions.convertDateFromDataBase(props.invoiceHeader?.invoice_date)}</p>
      </div>
      <div>
        <p className='mb-1 invoice-summary-title'>Invoice From</p>
        <p className='mb-1 invoice-summary-data'>{props.invoiceHeader?.vendor_name}</p>
        {vendorAddress[0] && <p className='mb-0 invoice-summary-data'>{vendorAddress[0]?.addressline_1},<br></br> {vendorAddress[0]?.city_code}, {vendorAddress[0]?.country_name}</p>}
      </div>
      <div>
        <p className='mb-1 invoice-summary-title'>Source Document Number</p>
        <p className='mb-0 invoice-summary-data'>{props.invoiceHeader?.PO_document_number}</p>
      </div>
      <div>
        <p className='mb-1 invoice-summary-title'>Invoice To</p>
        <p className='mb-1 invoice-summary-data'>{props.user?.tenant_name}</p>
        {tenantAddress[0] && <p className='mb-0 invoice-summary-data'>{tenantAddress[0]?.addressline_1},<br></br> {tenantAddress[0]?.city_code}, {tenantAddress[0]?.country_name}</p>}
      </div>
    </div>}
    <div
      className={
        props.userType === 0
          ? 'work_completion_line_grid_responsive line-grid-btns-common line-tabs summary-grid'
          : 'work_completion_line_grid work_completion_line_grid_responsive line-grid-btns-common line-tabs summary-grid' 
      }
    >
      <AgGridNew columnDefs={GridColumnsHeadingLines} apiUrl={Settings.apiPurchaseOrderUrl +`/lines/${props.invoiceHeader?.invoice_id}/${Settings?.source_id?.invoice}`} height="unset" hideAddBtn={true} hideDeleteBtn={true} domLayout='autoHeight' onGridReady={summaryGridReady} pagination={false} isDisabledCheckbox={true} hidePaginationFooter={true} />
      {/* <AgGrid header={GridColumnsHeadingLines} data={props.invoiceHeader?.purchaseorder_type===1?props.linesData:summaryLines} allBtnHide={true} hideFooter={true} hidePagination={true} onGridReady={summaryGridReady}  height={height} /> */}
    </div>
    {
       <div className='d-flex align-items-end justify-content-end flex-column gap-3 invoice-total-main mt-3'>
        <div className='d-flex total-invoice-tile'>
          <p>Line amount</p>
          <p>{Helper.formateCurrency(total?.line_amount)}</p>
        </div>
        <div className='d-flex total-invoice-tile'>
          <p>Line discount</p>
          <p>{Helper.formateCurrency(total?.line_discount)}</p>
        </div>
        <div className='d-flex total-invoice-tile'>
          <p>Tax</p>
          <p>{Helper.formateCurrency(total?.tax)}</p>
        </div>
        <div className='d-flex total-invoice-tile'>
          <p>Miscellaneous charges</p>
          <p>{Helper.formateCurrency(total?.miscellaneous_charges)}</p>
        </div>
        <div className='total-invoice-tile'>
        <div className='invoice-devider'></div>
        <div className='d-flex total-invoice-tile'>
          <p>Subtotal</p>
          <p>{Helper.formateCurrency(total?.subtotal)}</p>
        </div>
        <div className='invoice-devider'></div>
        </div>
        <div className='d-flex total-invoice-tile'>
          <p>Discount</p>
          <p>{Helper.formateCurrency(total?.discount)}</p>
        </div>
        <div className='d-flex total-invoice-tile'>
          <p>Round off</p>
          <p>{Helper.formateCurrency(total?.round_off)}</p>
        </div>
        <div className='d-flex total-invoice-tile'>
          <p className='fw-bold total-invoice-style'>Total</p>
          <p className='fw-bold total-invoice-style'>{Helper.formateCurrency(total?.total)}</p>
        </div>
      </div>
    }
   </div>
  )
}

export default InvoiceSummary
