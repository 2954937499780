import { Component } from "react";
import { Carousel, Modal, ModalBody } from "react-bootstrap";

class ImagePopupCarousel extends Component {
  render() {
    const { show, hide, children } = this.props;
    return (
      <Modal className="imagepopup" show={show} onHide={hide}>
        <ModalBody>
          <Carousel className="imagepopup-carousel" indicators={false}>
            {children?.map((child) => {
              return (
                <Carousel.Item
                  className="imagepopup-carousel-item"
                  key={child.key}
                >
                  {child}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </ModalBody>
      </Modal>
    );
  }
}

export default ImagePopupCarousel;
