import React from 'react'
import EmployeeInformationCard from './EmployeeInformationCard'

const EmployeeInformation = () => {
  return (
    // Employee Information Cards Row (Dummy Values For Now)
    <div className="row mt-4 employee-info-cards">
      <div className="col-sm-6 col-lg-3 mb-3">
        <EmployeeInformationCard
          title="Number of PR"
          information="65%"
          mainCardClass="pr-card-bg"
          totalValue={36}
          completedValue={32}
          progressValue={4}
        />
      </div>
      <div className="col-sm-6 col-lg-3 mb-3">
        <EmployeeInformationCard
          title="Number of Contracts"
          information="20%"
          mainCardClass="contracts-card-bg"
          totalValue={36}
          completedValue={32}
          progressValue={4}
        />
      </div>
      <div className="col-sm-6 col-lg-3">
        <EmployeeInformationCard
          title="Number of PO/RO"
          information="35%"
          mainCardClass="ro-card-bg"
          totalValue={36}
          completedValue={32}
          progressValue={4}
        />
      </div>
      <div className="col-sm-6 col-lg-3">
        <EmployeeInformationCard
          title="Number of Invoices"
          information="90%"
          mainCardClass="invoices-card-bg"
          totalValue={36}
          completedValue={32}
          progressValue={4}
        />
      </div>
    </div>
  )
}

export default EmployeeInformation
