import React, {useState, useEffect, Fragment, useMemo, useCallback} from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'; 
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import ButtonNew from '../../inc/ButtonNew';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getLabelFromDropdown, gridLinkValueFormat, statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import CreateEmployeeModal from '../Create Employee/CreateEmployeeModal';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import Alert from '../../inc/Alert';

let gridApi = null;
const EmployeeArchive = (props) => {
  const [positions, setPositions] = useState([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();

  let positionCalled = false

  useEffect(() => {
    get_position_dropdown_list()
  }, [])

  const get_position_dropdown_list = () => {
    if (positionCalled) {
      return;
    }
    let api = Api
    positionCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl + `/get_active_postion`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.position_id,
            label: element.name
          })
        })
        setPositions(UPDATED_STATE)
      }
    })
  }

  const CellHandler = (event) => {   
    if(event.colDef.field === 'code') { 
      navigate(`/edit-employee/${event.data.employee_id}`)
    }
  }
 
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delEmployee_ID = element?.data?.employee_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiOrgUrl+`/employee/${delEmployee_ID}`)
      const rowNode = gridApi?.getRowNode(delEmployee_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const processUserProfileImage = (image) => {
    const imageData = image?.slice(2,-1);
    const imageUrl = `data:image/${imageData};base64,${imageData}`;
  
    return imageUrl;
  };

  const displayLogo = (params) => {
    const userImage = processUserProfileImage(params.value);
    const imageUrl = params.value ? userImage : '/images/blank-profile.jpg'; 
    return ( 
      <div style={{
        width: '35px',
        height: '35px',
        background: '#fff', 
        borderRadius: '50%',
        boxSizing: 'border-box', 
        position: 'relative',
        margin: '0 10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${imageUrl})`
      }}>
      </div>
    );
  };

  const Columns_Headings = [
    {field:'image', sortable: false, filter: false, headerName:Helper.getLabel(props.language,'180020102',"Image"), editable: false, cellRenderer: displayLogo},
    {field:'code', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'180030102',"Code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
    {field:'display_name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'180050102',"Display name"), editable: false}, 
    {field:'first_name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'180040102',"First name"), editable: false},
    {field:'last_name', filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'180050102',"Last name"), editable: false},  
    {
      field:'position_id', 
      headerName: Helper.getLabel(props.language,'1805005',"Position"), 
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      cellEditorParams: {
        values: positions
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agTextColumnFilter',
      editable: false 
    },
    {field:'status', headerName: Helper.getLabel(props.language,'1804004',"Status"), filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, cellRenderer: (params) => statusLabelValueFormat(params), filter: 'agSetColumnFilter' }
  ]

  let security = props.security;
  let frontendIds= Gui_id_list; 
  if(!security.canView(Gui_id_list.setupForms.employee.employee_main)){ 
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(props.language,'180010102',"Employee")}>
          <ButtonNew onClick={() => setShowCreateModal(true)} title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.setupForms.employee.employee_main_create_button)} /> 
        </NavigationHeder>
 
        <AgGridNew
          apiUrl={`${Settings.apiOrgUrl}/employee`} 
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.employee.employee_main_delete_button)}
          uniqueField={'employee_id'}
          pagination={true}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api}
          gridId={"rs_employees_list"}
        /> 
      </div>
      {showCreateModal && <CreateEmployeeModal setShowCreateModal={setShowCreateModal} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
};

const SecurityOptions = {
  gui_id: Gui_id_list.setupForms.employee.employee_main
};

export default connect(mapStateToProps) (MasterComponentWraper(( ApplySecurityRoles( EmployeeArchive, SecurityOptions))));