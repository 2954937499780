const ContractQuestionnaireData = [
    {
        question: 'How well did the supplier adhere to the agreed-upon delivery timelines for solutions provided?',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      },
      {
        question: 'Rate the quality of the services and solutions delivered, specifically related to automated vision inspection or laser marking processes.',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      },
      {
        question: 'Did the supplier provide adequate support and after-sales service during the contract period?',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      },
      {
        question: 'How effective was the supplier in adapting to any changes or additional requirements during the contract term?',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      },
      {
        question: 'Rate the supplier’s transparency in communication and reporting throughout the project duration.',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      },
      {
        question: 'Did the supplier meet the compliance and regulatory standards as required in the contract?',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      },
      {
        question: 'How would you rate the overall value for money provided by the supplier?',
        inputType: 'rating',
        options: [1, 2, 3, 4, 5]
      }
]

export default ContractQuestionnaireData