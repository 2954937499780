import React, { useState, useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";
import ActionTypes from "../../inc/ActionsTypes";
import Api from "../../inc/Api";
import Helper from "../../inc/Helper";
import Settings from "../../inc/Settings";
import ButtonNew from "../inc/ButtonNew";
import RsWithRouter from "../inc/RsWithRouter"; 
import NavigationHeder from "../Navigations/NavigationHeder";
import NewSite from "./forms/NewSite";
import { getPopupMessageBasedOnStatus } from "../../inc/Validation";
import { useNavigate } from "react-router-dom";
import AgGridNew from "../grid/ag/ag-grid-new";
import { gridLinkValueFormat } from "../grid/ag/commonFunctions/GridCommonFunctions";
import Gui_id_list from "../../inc/Gui_id_list";
import ApplySecurityRoles from "../SecurityRoles/ApplySecurityRoles";
import Alert from "../inc/Alert";

let gridApi = null;

const Sites = ({ language, entity, setEntity, security }) => { 
  const [showCreatPopup, setShowCreatPopup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadEntity(); 
  }, []);
 
  const onCellClicked = (event) => {
    if (event.colDef.field === "code") {
      navigate("/site/edit/" + event?.data?.site_id);
    }
  };
 
  const showWarehouseCreateForm = () => {
    setShowCreatPopup(true);
  };

  const onCreateFormClose = () => {
    setShowCreatPopup(false);
  };

  const loadEntity = () => {
    Api.setUserToken();
    Api.axios()
      .get(Settings.apiUrl + "/get_entities")
      .then((res) => {
        if (res.data.status === "success") {
          setEntity(res.data.data);
        }
      });
  };

  const deleteSite = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    let delSite_ID = element?.data?.site_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/site/${delSite_ID}`)
      const rowNode = gridApi?.getRowNode(delSite_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert('Site deleted successfully', 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  const gridColHeaders = [
    {
      field: "code",
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(language, "code", "Code"),
      width: 150,
      cellRenderer: (params) => gridLinkValueFormat(params)
    },
    {
      field: "name",
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(language, "name", "Name"),
      width: 150,
    },
    {
      field: "entity_name",
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(language, "entity", "Entity"),
      width: 150,
    },
    {
      field: "createdby",
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(language, "created", "Created"),
    },
    {
      field: "modifiedby",
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(language, "modified", "Modified"),
    },
  ];
 
  if(!security.canView(Gui_id_list.setupForms.site.site_main)){ 
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className="warehouse_admin_page">
      <NavigationHeder
        backUrl="/setup"
        hideMoreBtn={true}
        title={Helper.getLabel(language, "sites", "Sites")}
      >
        <ButtonNew
          isDisable={!security.canCreate(Gui_id_list.setupForms.site.site_main_create_button)} 
          onClick={showWarehouseCreateForm} 
        />
      </NavigationHeder>
  
      {showCreatPopup && (
        <NewSite entityList={entity.entity} onPopupClose={onCreateFormClose} />
      )}
 
      <AgGridNew
        apiUrl={Settings.apiUrl+`/get_site`}  
        pagination={true}
        columnDefs={gridColHeaders} 
        hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.site.site_main_delete_button)}
        hideAddBtn={true}
        height={500}
        uniqueField={'site_id'}
        onCellClicked={onCellClicked}
        handleDeleteSelectedRows={deleteSite}
        onGridReady={(params) => gridApi = params.api} 
        gridId={"rs_sites_list"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    entity: state.entity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEntity: (entity) => {
      dispatch({ type: ActionTypes.SET_ENTITY, payload: entity });
    },
  };
};

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.site.site_main
};
 
export default connect(mapStateToProps, mapDispatchToProps) ((RsWithRouter(ApplySecurityRoles(Sites, SecurityOptions))));  