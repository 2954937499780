import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import ButtonNew from '../../inc/ButtonNew';
import Collapse from '../../inc/Collapse'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { useNavigate } from 'react-router-dom';
import TaxGroupModal from './TaxGroupModal';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';

let gridApi = null;

const TaxGroup = ({security}) => { 
    const [showCreateModal, setShowCreateModal] = useState(false);

    const navigate = useNavigate();

    const language = useSelector(state => state.language);

    const onCellClicked = (event) => {
        if (event.colDef.field === 'name') {
            navigate('/tax/edit-tax-group/' + event?.data?.taxgroup_id);
        }
    };

    const deleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delTaxGroup_ID = element?.data?.taxgroup_id;

        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/taxgroup/${delTaxGroup_ID}`)
            const rowNode = gridApi?.getRowNode(delTaxGroup_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.applyServerSideTransaction(transaction)
            }, 200);

            gridApi?.refreshServerSide({ purge: true })
            gridApi?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
 
    /* Grid Column Headers */

    const gridColumnHeaders = [
        { field: 'name', filter: 'agTextColumnFilter', cellRenderer: (params) => gridLinkValueFormat(params), editable: false, headerName: Helper.getLabel(language, "name", 'Name') },
    ];

    if(!security.canView(Gui_id_list.setupForms.tax_groups.tax_groups_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment> 
            <div className='tax_codes_page'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'tax_group', 'Tax Group')}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.tax_groups.tax_groups_main_create_button)} onClick={() => setShowCreateModal(true)} />
                </NavigationHeder>

                <Collapse open={true} title={Helper.getLabel(language, 'group', 'Group')}>
                    <AgGridNew
                        hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.tax_groups.tax_groups_main_delete_button)}
                        apiUrl={Settings.apiUrl + '/taxgroup'}  
                        pagination={false}
                        columnDefs={gridColumnHeaders} 
                        hideAddBtn={true}
                        height={500}
                        uniqueField={'taxgroup_id'}
                        onCellClicked={onCellClicked}
                        handleDeleteSelectedRows={deleteHandler}
                        onGridReady={(params) => gridApi = params?.api} 
                        gridId={"tax_group_grid"}
                    />
                </Collapse>
            </div>
            {showCreateModal && <TaxGroupModal setShowCreateModal={setShowCreateModal} />}
        </Fragment>
    );
};
 
const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.tax_groups.tax_groups_main
};

export default ((( ApplySecurityRoles( TaxGroup, SecurityOptions)))); 