import React from 'react';
import Helper from '../../inc/Helper';
import EditPO from '../../components/Purchase Orders Internal/Edit PO/EditPO';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../../components/inc/Alert';
import MasterComonent from '../../components/Backend/MasterComonent';

const ContractOrderEditPage = ({security}) => {
    if (!security.canView(Gui_id_list.procure.contract_orders.contract_orders_edit)) {
        return (
            <MasterComonent>
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>
        )
    }
    return (
        <EditPO
            security={security}
            title={Helper.getLabel(null, 'edit_contract_order', "Edit Contract Order")}
            lineTitle={Helper.getLabel(null, 'contract_order_lines', "Contract Order Lines")}
            backUrl='/contract-order/all'
            versionUrl='/contract-order/version'
            historyUrl='/contract-order/history'
            logsUrl='/contract-order/logs'
            goodReceivingUrl='/contract-order/good-receiving/notes'
            returnOrderUrl='/contract-order/return-order/all'
            amendmentRequestUrl='/contract-order/amendment-request/all'
            renewUrl="/contract-order/renew"
            hideStartDate={false}
            hideEndDate={false}
            hideBillingRule={false}
            source_id={14}
            visibleEndDate={true}
            visibleStartDate={true}
            type="contract"
        />
    );
};

const SecurityOptions = {
    gui_id: Gui_id_list.procure.contract_orders.contract_orders_edit
};

export default ApplySecurityRoles(ContractOrderEditPage, SecurityOptions); 