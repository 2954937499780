import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings'; 
import ButtonNew from '../inc/ButtonNew';
import RsWithRouter from '../inc/RsWithRouter'; 
import NavigationHeder from '../Navigations/NavigationHeder';
import NewWarehouse from './form/NewWarehouse';
import { useNavigate } from 'react-router-dom';
import AgGridNew from '../grid/ag/ag-grid-new';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import { gridLinkValueFormat, statusLabelValueFormat } from '../grid/ag/commonFunctions/GridCommonFunctions';
import Alert from '../inc/Alert';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';

let gridApi = null;

const Warehouse = ({ language, security, entity, sites, setEntity, setSite }) => {
    const [showCreatPopup, setShowCreatPopup] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        loadEntity();
        loadSites(); 
    }, []);

    const onCellClicked = event => {
        if (event.colDef.field === 'code') {
            navigate('/warehouse/edit/' + event?.data?.warehouse_id);
        }
    };
 
    const showWarehouseCreateForm = () => {
        setShowCreatPopup(true);
    };

    const onCreateFormClose = () => {
        setShowCreatPopup(false);
    };

    const loadEntity = () => {
        const searchKey = {
            page_number: null,
            page_size: null,
            search: null
        };
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/get_entities', searchKey)
            .then(res => {
                if (res.data.status === 'success') {
                    const dataList = res?.data?.data?.reverse();
                    setEntity(dataList);
                }
            });
    };

    const loadSites = () => {
        const searchKey = {
            page_number: null,
            page_size: null,
            search: null,
            entity_id: null
        };
        Api.setUserToken();
        Api.axios().get(Settings.apiUrl + '/get_site', searchKey)
            .then(res => {
                if (res.data.status === 'success') {
                    const dataList = res.data.data.reverse();
                    setSite(dataList);
                }
            });
    };

    const onDeleteWarehouseHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delWarehouse_ID = element?.data?.warehouse_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl+`/warehouse/${delWarehouse_ID}`)
            const rowNode = gridApi?.getRowNode(delWarehouse_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert('Warehouse deleted successfully', 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const gridColHeaders = [
        { field: 'code', filter:'agTextColumnFilter', headerName: Helper.getLabel(language, "code", 'Code'), width: 150, cellRenderer: (params) => gridLinkValueFormat(params)},
        { field: 'name', filter:'agTextColumnFilter', headerName: Helper.getLabel(language, "name", 'Name'), width: 150 },
        { field: 'entity_name', filter:'agTextColumnFilter', headerName: Helper.getLabel(language, "entity", 'Entity'), width: 150 },
        { field: 'isactive', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, cellRenderer: (params) => statusLabelValueFormat(params), headerName: Helper.getLabel(language, "status", 'Status'), width: 150 }
    ];

    if(!security.canView(Gui_id_list.setupForms.warehouse.warehouse_main)){ 
        return <Fragment>
          <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <div className='warehouse_admin_page'>
            <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'warehouses', 'Warehouses')}>
                <ButtonNew
                    isDisable={!security.canCreate(Gui_id_list.setupForms.warehouse.warehouse_main_create_button)}  
                    onClick={showWarehouseCreateForm} 
                />
            </NavigationHeder>

            {showCreatPopup && <NewWarehouse entityList={entity.entity} siteList={sites.sites} onPopupClose={onCreateFormClose} />}

            <AgGridNew
                apiUrl={Settings.apiUrl+`/get_warehouse`}  
                pagination={true}
                hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.warehouse.warehouse_main_delete_button)}
                columnDefs={gridColHeaders} 
                hideAddBtn={true}
                height={500}
                uniqueField={'warehouse_id'}
                onCellClicked={onCellClicked}
                handleDeleteSelectedRows={onDeleteWarehouseHandler}
                onGridReady={(params) => gridApi = params.api} 
                gridId={"warehouse_list"}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
        entity: state.entity,
        sites: state.sites
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEntity: (entity) => { dispatch({ type: ActionTypes.SET_ENTITY, payload: entity }) },
        setSite: (sites) => { dispatch({ type: ActionTypes.SET_SITES, payload: sites }) }
    };
};

const SecurityOptions = {
    gui_id:Gui_id_list.setupForms.warehouse.warehouse_main
};
 
export default connect(mapStateToProps, mapDispatchToProps) ((RsWithRouter(ApplySecurityRoles(Warehouse, SecurityOptions))));  