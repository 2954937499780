function CalculateDateDelta(endDateStr) {
  const endDate = new Date(endDateStr)
  const startDate = new Date()
  const deltaMilliseconds = endDate - startDate
  let days, hours, minutes, seconds;
   if (deltaMilliseconds <= 0) {
    days = 0;
    hours = 0;
    minutes = 0;
    seconds = 0;
  } else {
    const deltaSeconds = Math.floor(deltaMilliseconds / 1000);
    days = Math.floor(deltaSeconds / 86400);
    hours = Math.floor((deltaSeconds % 86400) / 3600);
    minutes = Math.floor((deltaSeconds % 3600) / 60);
    seconds = Math.floor(deltaSeconds % 60);
  }
  const dateDelta = {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }
  return dateDelta
}

export { CalculateDateDelta }