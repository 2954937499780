import React, { Component } from 'react';
import Popup from '../../../Popup/Popup';
import TaxTransaction from '../../../Tax/TaxTransactionView/TaxTransaction';
import Helper from '../../../../inc/Helper';

class TaxTransactionPopup extends Component {
    onPopupClose(){
        this.props.onTaxTransactionPopupClose();
    }
    render() {
        return (
            <Popup title={Helper.getLabel(null,'tax_transaction','Tax transaction')} onClose={ this.onPopupClose.bind(this)} autoOpen={true} width="1100px">
                <TaxTransaction source_id={this.props.source_id} line_id={this.props.line_id} integrator={this.props.integrator}/>
            </Popup>
        );
    }
}

export default TaxTransactionPopup;