import React from 'react'
import Popup from '../../Popup/Popup'
import AgGridNew from '../../grid/ag/ag-grid-new'
import Helper from '../../../inc/Helper'
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions'

const UploadCataloguePopUp = ({ handleUploadCatalogueModalClose }) => {
  //dummy data
  const Products_Data = [
    {
      product_name: 'Solar Panel 300W',
      product_code_sku: 'SP300W-001',
      product_description: 'High-efficiency 300W solar panel suitable for residential and commercial use.',
      brand_name: 'EcoEnergy',
      manufacturer: 'Green Solutions Inc.',
      model_number: 'GS-300SP',
      unit_price: '750.00',
    },
    {
      product_name: 'Wind Turbine 5kW',
      product_code_sku: 'WT5000-002',
      product_description: 'Compact and efficient 5kW wind turbine ideal for off-grid applications.',
      brand_name: 'WindPower Co.',
      manufacturer: 'Renewable Dynamics',
      model_number: 'RD-5000WT',
      unit_price: '12000.00',
    },
    {
      product_name: 'Battery Storage Unit 10kWh',
      product_code_sku: 'BSU10K-003',
      product_description: 'Lithium-ion battery storage unit with 10kWh capacity for backup power.',
      brand_name: 'StorageTech',
      manufacturer: 'FuturePower Ltd.',
      model_number: 'FP-10KBSU',
      unit_price: '8500.00',
    },
    {
      product_name: 'Electric Vehicle Charger 7kW',
      product_code_sku: 'EVC7KW-004',
      product_description: '7kW fast electric vehicle charger compatible with all EV models.',
      brand_name: 'ChargeMaster',
      manufacturer: 'ElectroWorks',
      model_number: 'EW-7KW',
      unit_price: '1500.00',
    },
    {
      product_name: 'Smart Meter',
      product_code_sku: 'SM-005',
      product_description: 'Advanced smart meter for real-time energy consumption monitoring.',
      brand_name: 'MeterPro',
      manufacturer: 'SmartGrid Technologies',
      model_number: 'SGT-100',
      unit_price: '250.00',
    }
  ];
  
  const Columns_Headings = [
    {
      field: 'product_name',
      headerName: Helper.getLabel(null, 'product_name', 'Product name'),
      editable: 'false',
      minWidth:200
    },
    {
      field: 'product_code_sku',
      headerName: Helper.getLabel(null, 'product_code_sku', 'Product Code/SKU'),
      editable: 'false',
      minWidth:160
    },
    {
      field: 'product_description',
      headerName: Helper.getLabel(null, 'product_description', 'Product description'),
      editable: 'false',
      minWidth:220
    },
    {
      field: 'brand_name',
      headerName: Helper.getLabel(null, 'brand_name', 'Brand name'),
      editable: 'false',
      minWidth:180
    },
    {
      field: 'manufacturer',
      headerName: Helper.getLabel(null, 'manufacturer', 'Manufacturer'),
      editable: 'false',
      minWidth:180
    },
    {
      field: 'model_number',
      headerName: Helper.getLabel(null, 'model_number', 'Model number'),
      editable: 'false',
      minWidth:180
    },
    {
      field: 'unit_price',
      headerName: Helper.getLabel(null, 'unit_price', 'Unit price'),
      editable: 'false',
      minWidth:160
    },
  ]

  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: Products_Data?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: Products_Data?.length
      })
    },
  };

  const onGridReady = (params) => {
    if(params){ 
      setTimeout(()=>{ 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200)
    };
  };

  const ExcelImportActionButton = () => {
    return (
        <>
            <div className='compliance_action_excel_btn vendor_action_excel_btn w-auto pt-1 me-1'>  
                <img className='img-fluid' src="/images/icons/excel-logo.svg" /> 
                <input style={{height:'36px'}} type="file" />
            </div>

            <div className='import_sample_template' style={{height:'36px'}}>
                <button style={{height:'36px'}}><img className='img-fluid' src="/images/icons/excel-logo.svg" />Export catalogue</button>
            </div>
        </>
    )
  }

  return (
    <Popup autoOpen={true} width='900px' onClose={handleUploadCatalogueModalClose}>
      <div>
        <h5 className='create_rfq_modal__title'>{'Upload Catalogue'}</h5>
        <div  className='mt-4'>
          <AgGridNew height={500} isDataSourceRequire={true} gridId={'product-catalogue-grid'} apiUrl={''} onGridReady={onGridReady} columnDefs={Columns_Headings} afterDeleteBtns={<ExcelImportActionButton />} />
        </div>
      </div>
    </Popup>
  )
}

export default UploadCataloguePopUp
