import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { gridIdActions } from '../../../store/grid-id';

const HierarchyItem = (props) => {
  const { item, onClick, isActive } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    onClick(item.id);
    dispatch(gridIdActions.VendorID({payload: item.id}))
  };

  return (
    <li className={`treeitem ${isActive ? 'treeview_sub_item_active' : ''}`} onClick={handleClick}>
      <p style={{ backgroundColor: `${props.user?.usertype === 1 ? '#FFF' : '#FFF'}` }}>{item.title}</p>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(HierarchyItem); 