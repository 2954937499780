import React, {useState, useEffect} from 'react';
import $ from 'jquery'
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import Helper from '../../../inc/Helper';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import { connect } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import SimpleLoading from '../../Loading/SimpleLoading';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { ValidateArray, ValidateData, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import FormValidator from '../../../inc/FormValidator';
import { useNavigate } from 'react-router-dom';
import ActionTypes from '../../../inc/ActionsTypes';

const CreateCOPopup = (props) => {
    
    //States
    let auth = props.auth.user;
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(() => {
      return {
        sites:false,
        //types:false,
        //document_status:false,
        //purchase_status:false,
        vendor_group:false,
        vendor:false,
        entity:false,
        currency:false,
        warehouse:false,
      }
    })
    const [sitesLoading, setSitesLoading] = useState(false)
    const [sitesCurrencyLoading, setSitesCurrencyLoading] = useState(false)
    const [createPO, setCreatePO] = useState({
        "vendor_id": null,
        "vendorsite_id": null,
        "vendorgroup_id": null,
        "purchase_status": null,
        "document_status": null,
        "accounting_date": Helper.formateDate(Date.now()),
        "description":null,
        "document_date": Helper.formateDate(Date.now()),
        "delivery_date": Helper.formateDate(Date.now()), 
        "entity_id": auth.Employee ? auth.Employee.Entity_id : '',
        "site_id": auth.Employee ?  auth.Employee.Site_id : '',
        "currency_id": auth.default_currency_id ? auth.default_currency_id : null,
        "document_number": null,
        "warehouse_id": auth.Employee ? auth.Employee.Warehouse_id : ''
    })

    //Fields Validation
    let validator ; 

    const validationConfigure = () => {
      let fieldConfig = [
          {name:'vendor_id', displayName:Helper.getLabel(props.language,'vendor_id','Vendor'),types:['Required']},
          {name:'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse_id','Warehouse'),types:['Required']},      
          // {name:'document_number', displayName:Helper.getLabel(props.language,'document_number','Document Number'),types:['Required']},  
          {name:'vendorgroup_id', displayName:Helper.getLabel(props.language,'vendorgroup_id','Vendor Group'),types:['Required']},  
          // {name:'purchase_status', displayName:Helper.getLabel(props.language,'purchase_status','Purchase Status'),types:['Required'] },
          // {name:'document_status', displayName:Helper.getLabel(props.language,'document_status','Document Status'),types:['Required']},  
          {name:'accounting_date', displayName:Helper.getLabel(props.language,'accounting_date','Accounting Date'),types:['Required'] }, 
          {name:'document_date', displayName:Helper.getLabel(props.language,'document_date','Document Date'),types:['Required'] },
          {name:'delivery_date', displayName:Helper.getLabel(props.language,'delivery_date','Delivery Date'),types:['Required']},   
          {name:'entity_id', displayName:Helper.getLabel(props.language,'entity','Entity'),types:['Required']}, 
          {name:'currency_id', displayName:Helper.getLabel(props.language,'currency_id','Currency '),types:['Required'] }, 
          {name:'site_id', displayName:Helper.getLabel(props.language,'site_id','Site '),types:['Required'] },
      ]
      if(!validator) {
        validator = new FormValidator(fieldConfig,props.language)
      }
    }
  
    validationConfigure()

    //End

    //Dropdowns States

    let type = props.poHeaderTypes.types.map( (item) => {
      return {
        label:item.name,
        value:item.id
      }
    })
    const [allVendors, setAllVendors] = useState([])
    const [vendor, setVendor] = useState([])
    const [vendorGroup, setVendorGroup] = useState([])
    const [vendorSite, setVendorSite] = useState([])
    const [vendorSiteAllData, setVendorSiteAllData] = useState([])
    const [purchaseStatus, setPurchaseStatus] = useState([])
    const [documentStatus, setDocumentStatus] = useState([]) 
    const [entity, setEntity] = useState([])
    const [sites, setSites] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [currencyList, setCurrencyList] = useState([])

    //End

    
    //Calls Reduction

    let typeCalled = false
    let vendorCalled = false
    let vendorGroupCalled = false
    let vendorSiteCalled = false
    let purchaseStatusCalled = false
    let documentStatusCalled = false 
    let entityCalled = false
    let sitesCalled = false
    let currencyCalled = false
    let vendorSiteCurrencyCalled = false
    let warehouseCalled = false
    let isUserDetailsCalled = false;

    //End

    
    //API RENDERS
    useEffect(() => {
      SitesAPI()
      TypeAPI()
      DocumentStatusAPI() 
      PurchaseStatusAPI()
      VendorGroupAPI()
      vendorAPI()
      EntityAPI()
      CurrencyAPI()
      WareHouseAPI()
      loadUserDetails()
      //VendorSiteAPI(null)
  }, [])

  const loadUserDetails =() => {
      if(isUserDetailsCalled){
        return;
      }
      isUserDetailsCalled = true;
      let api = Api;
      if(api.getUserToken()){
          api.setJsonHeader();
          api.setUserToken();
          api.axios().get(Settings.apiUrl+'/user_details',{}).then(function({data}){
              if(data && data.Status === "Success"){
                  props.setUser(data.data);
              }
          })
      }
  }

  //End

    // Dropdown API
    const TypeAPI = () => {
        if(typeCalled){
            return;
        }
        if(props.poHeaderTypes.isLoaded){
          return;
        }
        let api = Api
        typeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderType',{}).then(function(res){
          if(res.data.status==="success"){
            props.setPoTypes(res.data.data);
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }
  
    const vendorAPI = () => {
        if(vendorCalled){
            return;
        }
        let api = Api
        vendorCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+'/get_active_vendors',{}).then(function(res){
          if(res.data.status==="success"){
            let VENDORARR = []
            res.data.data.forEach(item => {  
            VENDORARR.push({
              value: item.vendor_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setAllVendors(res.data.data)
           setVendor(VENDORARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                vendor:true
               }
            )
           })
           
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const VendorGroupAPI = () => {
        if(vendorGroupCalled){
            return;
        }
        let api = Api
        vendorGroupCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{}).then(function(res){
          if(res.data.status==="success"){
            let GroupARR = []
            res.data.data.forEach(item => {  
            GroupARR.push({
              value: item.vendorgroup_id,
              label: item.name
            })
           }) 
           setVendorGroup(GroupARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                vendor_group:true
               }
            )
           })
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }
    const getVendorDefaultSiteFromVendorId = (rsVendorId) =>{
      let rsDefaultSite = '';
      allVendors.forEach(elementVendor => {
        if(parseInt(elementVendor.vendor_id) === parseInt(rsVendorId)){
          rsDefaultSite = elementVendor.defaultsite_id
        }
      });
      return rsDefaultSite;
    }
    const VendorSiteAPI = (id) => {
        if(vendorSiteCalled){
            return;
        }
        let api = Api
        vendorSiteCalled = true
        setSitesLoading(true)
        api.setUserToken()
        let url = Settings.apiVendorUrl+`/get_active_vendor_sites`;
        if(id){
          let rsDefaultSiteId = getVendorDefaultSiteFromVendorId(id)
          setCreatePO((prevState) => {
            return {
              ...prevState,
              vendorsite_id:rsDefaultSiteId
            }
          })
          if(rsDefaultSiteId){
            getVendorCurrentCurrencyAPI(rsDefaultSiteId)
          }
          url = Settings.apiVendorUrl+`/get_active_vendor_sites/${id}`;
        }
        api.axios().get(url,{}).then(function(res){
          if(res.data.status==="success"){
            let vendorSite = []
            res.data.data.forEach(item => {  
              vendorSite.push({
                value: item.vendorsite_id,
                label: item.name
              })
            }) 
            setVendorSiteAllData( res.data.data)
            setVendorSite(vendorSite)
            setSitesLoading(false)
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setSitesLoading(false)
      })
    }

    const getVendorCurrentCurrencyAPI = (id) => {
      if(vendorSiteCurrencyCalled){
          return;
      }
      let api = Api
      vendorSiteCurrencyCalled = true
      setSitesCurrencyLoading(true)
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+`/vendorsite/${id}`,{}).then(function(res){
        if(res.data.status==="success"){
          setSitesCurrencyLoading(false)
            setCreatePO((prevState) => {
              return {
                ...prevState,
                currency_id: res.data.data.currency_id
              }
            })
            setCreatePO((prevState) => {
              return {
                ...prevState,
                vendorgroup_id: res.data.data.vendor_group_id
              }
            })
        } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setSitesCurrencyLoading(false)
    })
  }

    const PurchaseStatusAPI = () => {
        if(purchaseStatusCalled){
            return;
        }
        let api = Api
        purchaseStatusCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderPurchaseStatus',{}).then(function(res){
          if(res.data.status==="success"){
            let PurchaseStatus = []
            res.data.data.forEach(item => {  
            PurchaseStatus.push({
              value: item.id,
              label: item.name
            })
           }) 
           setPurchaseStatus(PurchaseStatus)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                purchase_status:true
               }
            )
           })
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const DocumentStatusAPI = () => {
        if(documentStatusCalled){
            return;
        }
        let api = Api
        documentStatusCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderDocumentStatus',{}).then(function(res){
          if(res.data.status==="success"){
            let docsStatus = []
            res.data.data.forEach(item => {  
            docsStatus.push({
              value: item.id,
              label: item.name
            })
           }) 
           setDocumentStatus(docsStatus)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                document_status:true
               }
            )
           })
           
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const CurrencyAPI = () => {
        if(currencyCalled){
            return;
        }
        let api = Api
        currencyCalled = true 
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
          if(res.data.status==="success"){ 
            let CurrencyARR = []
            res.data.data.forEach(item => {  
                CurrencyARR.push({
                    value: item.currency_id,
                    label: `${item.currency_symbol} - ${item.currency_name}`
                })
           })
           setCurrencyList(CurrencyARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                currency:true
               }
            )
           })
        } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const EntityAPI = () => {
        if(entityCalled){
            return;
        }
        let api = Api
        entityCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl + '/get_entities').then(function(res){
          if(res.data.status==="success"){
            let EntityARR = []
            res.data.data.forEach(item => {  
            EntityARR.push({
              value: item.entity_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setEntity(EntityARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                entity:true
               }
            )
           })
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const SitesAPI = () => {
        if(sitesCalled){
            return;
        }
        let api = Api
        sitesCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/get_site',{
            "code":"",
            "name":""
        }).then(function(res){
          if(res.data.status==="success"){
            let SitesARR = []
            res.data.data.forEach(item => {  
            SitesARR.push({
              value: item.site_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setSites(SitesARR)
           setAllDataLoading((oldData) => {
            return(
              {
                ...oldData,
                sites:true
               }
            )
           })
           
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
      })
    }

    const WareHouseAPI = () => {
      if(warehouseCalled){
          return;
      }
      let api = Api
      warehouseCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/get_warehouse',{
          "code":"",
          "name":""
      }).then(function(res){
        if(res.data.status==="success"){
          let Warehouse = []
          res.data.data.forEach(item => {  
          Warehouse.push({
            value: item.warehouse_id,
            label: `${item.code} - ${item.name}`
          })
         }) 
         setWarehouse(Warehouse)
        } 
        setAllDataLoading((oldData) => {
          return(
            {
              ...oldData,
              warehouse:true
             }
          )
         })
        
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

    //End
  
    //Handlers
    
    const onPopupClose = () => { 
        $('html').removeClass('rs_popup_open');
        props.ClosePopup(false)
    }

    const ClosePopupHandler = () => {
        $('html').removeClass('rs_popup_open');
        props.ClosePopup(false)
    }

    const InputChangeHandler = (e) => {
      let poAditionalParams= {}
        if(e.target.name == "vendor_id"){

            VendorSiteAPI(e.target.value)
            poAditionalParams.vendorsite_id = getVendorDefaultSiteFromVendorId(e.target.value)
        }
        if(e.target.name == "vendorsite_id"){
            let rsVendorSite = null;
            vendorSiteAllData.forEach(venSite => {
              if(e.target.value == venSite.vendorsite_id){
                rsVendorSite = venSite;
              }
            });
            if(rsVendorSite){
              poAditionalParams.vendor_id = rsVendorSite.vendor_id;
            }
            getVendorCurrentCurrencyAPI(e.target.value)

        }

        setCreatePO((prevState) => {
            return {
                ...prevState,
                ...poAditionalParams,
                [e.target.name]:e.target.value
            }
        })
    }

    const DocumentDatePickerHandler = (e) => {
       setCreatePO((prevState) => {
            return {
                ...prevState,
                document_date: $('.document_date').val()
            }
       })
    }

    const AccountDatePickerHandler = (e) => {
        setCreatePO((prevState) => {
            return {
                ...prevState,
                accounting_date: $('.accounting_date').val()
            }
        })
    }

    const DeliveryDatePickerHandler = (e) => {
        setCreatePO((prevState) => {
            return {
                ...prevState,
                delivery_date: $('.delivery_date').val()
            }
        })
    }
    //End
    const getDefaultPaymentTermIdFromSiteId = (siteId) => {
      let paymentterm_id = null;
      vendorSiteAllData.forEach(venSite => {
        if(siteId == venSite.vendorsite_id){
          paymentterm_id = venSite.paymentterm_id;
        }
      });
      return paymentterm_id;
    }
    const CreatePurchaseOrder = () => {
        let validData = {
            ...createPO
          }
          if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        }  
        if(!ValidateData(vendorSite) && !createPO.vendorsite_id){
          Helper.alert('Vendor Site Field missing!', 'error')
          return;
        }
       setLoading(true)
       let api = Api 
        api.setUserToken()
        let rsNewPoData = { 
              "document_number": null,
              "delivery_address_id": null,
              "delivery_name": null,
              "attention_information": null,
              "mode_of_delivery_id": null,
              "delivery_term_id": null,
              "discount_amount": null,
              "discount_percentage": 0,
              "rounding_amount":null,
              "taxgroup_id": null,
              "taxcodes_id": null,
              "taxgroupitem_id": null,
              "priceinclude_tax": null,
              "requester_id": null,
              "orderer_id": null,
              "emailaddress": null,
              "buyergroup_id": null,
              "paymentterm_id": getDefaultPaymentTermIdFromSiteId(createPO.vendorsite_id),
              "distributionsource_id": null,
              "blindreceivingof_qty": null,
              "blindreceivingof_item": null,
              "externalreference": null,
              "attachmentsource_id": null,
              "notesource_id": null,
              "contactsource_id": null,
              "addresssource_id":null,
              "allow_access_only_to_buyer_group": null,
              "exchangecombination_id":null,
              "exchangerate_id":null,
              "type": !ValidateArray(type) ? type.find(id => id.label == "Contract").value : null,
              "vendor_id": parseInt(createPO.vendor_id),
              "vendorsite_id": parseInt(createPO.vendorsite_id),
              "vendorgroup_id": parseInt(createPO.vendorgroup_id),
              "purchase_status": !ValidateArray(purchaseStatus) ? purchaseStatus.find(id => id.label == "Open").value : null,
              "document_status": !ValidateArray(documentStatus) ? documentStatus.find(id => id.label == "Draft").value : null,
              "accounting_date": Helper.getDateForDb(createPO.accounting_date),
              "description": createPO.description,
              "document_date": Helper.getDateForDb(createPO.document_date),
              "delivery_date": Helper.getDateForDb(createPO.delivery_date), 
              "entity_id": parseInt(createPO.entity_id),
              "site_id": parseInt(createPO.site_id),
              "currency_id": parseInt(createPO.currency_id),
              "warehouse_id": parseInt(createPO.warehouse_id)
          }
        api.axios().post(Settings.apiPurchaseOrderUrl+'/PO_header',rsNewPoData).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            setLoading(false)
            navigate(`/edit-purchase-order/${res.data.PO_header_id}`)
            $('html').removeClass('rs_popup_open');
            props.ClosePopup(false)
          } 
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
    }
  console.log('allDataLoading',allDataLoading)
  console.log('auth.Employee',auth.Employee)
  const isAllDataLoaded = () => {
      let isAllDataLoaded = true;
      for (const objectKey in allDataLoading) {
            if(!allDataLoading[objectKey]){
              isAllDataLoaded = false;
            }
      }
      return isAllDataLoaded;
  }
  if(!isAllDataLoaded()){
    return <SimpleLoading/>
  }
  return (
    <div className='close-popup_po'>
        <Popup onClose={ onPopupClose} autoOpen={true} width="752px">
           <div className='container-fluid'>
               <div className='row'>
                   <div className='col-12'>
                       <h2 className='mb-4'>{Helper.getLabel(null,'create_contract_order','Create Contract Order')}</h2>
                   </div>
               </div>
           </div>
           {loading ? <SimpleLoading /> : ''}
           <Collapse className='mb-4 info_po_tabs' open={true} title={Helper.getLabel(props.language,'etfi',"Enter the following information")}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Input label={Helper.getLabel(props.language,'description',"Description")} value={createPO.description} onChange={InputChangeHandler} name='description' />
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='rs_po_type' label={Helper.getLabel(props.language,'type',"Type")} disable={true} options={type} value={1} name='type' onChange={InputChangeHandler} />
                        </div> 
                        <div className='col-lg-6'>
                            <DropDownInput id='vendor_id' required={true} label={Helper.getLabel(props.language,'vendor_code_name',"Vendor")} options={vendor} value={createPO.vendor_id} name='vendor_id' onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            {sitesLoading ? <SimpleLoading /> : ''}
                            <DropDownInput id='vendorsite_id' required={true} label={Helper.getLabel(props.language,'vendor_site',"Vendor Site")} options={vendorSite} value={createPO.vendorsite_id} name='vendorsite_id' onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            {sitesCurrencyLoading ? <SimpleLoading /> : ''}
                            <DropDownInput id='vendorgroup_id' required={true} label={Helper.getLabel(props.language,'vendor_group',"Vendor Group")} options={vendorGroup} value={createPO.vendorgroup_id} name='vendorgroup_id' onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            {sitesCurrencyLoading ? <SimpleLoading /> : ''}
                            <DropDownInput id='currency_id' required={true} label={Helper.getLabel(props.language,'currency_id',"Currency")} options={currencyList} value={createPO.currency_id} name='currency_id' onChange={InputChangeHandler} />
                        </div> 
                        <div className='col-lg-6'>
                            <InputDatePicker value={createPO.document_date} label={Helper.getLabel(props.language,'document_date',"Document Date")} name='document_date' inputClassName='document_date' required={true} onChange={DocumentDatePickerHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <InputDatePicker value={createPO.accounting_date} label={Helper.getLabel(props.language,'accounting_date',"Accounting Date")} name='accounting_date' inputClassName='accounting_date' required={true} onChange={AccountDatePickerHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <InputDatePicker value={createPO.delivery_date} label={Helper.getLabel(props.language,'delivery_date',"Delivery Date")} name='delivery_date' inputClassName='delivery_date' required={true} onChange={DeliveryDatePickerHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='entity_id' value={createPO.entity_id} label={Helper.getLabel(props.language,'entity_id',"Entity")} name='entity_id' required={true} options={entity} onChange={InputChangeHandler}/>
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='site_id' value={createPO.site_id} label={Helper.getLabel(props.language,'site_id',"Site")} name='site_id' required={true} options={sites} onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='warehouse_id' value={createPO.warehouse_id} label={Helper.getLabel(props.language,'warehouse_id',"Warehouse")} name='warehouse_id' required={true} options={warehouse} onChange={InputChangeHandler} />
                        </div>
                    </div>
                </div> 
           </Collapse>
           <div className='container-fluid'>
              <div className='row mt-3 mb-3'>
                <div className='col-12'>
                    <div className='action_btns_po d-flex justify-content-end align-items-center'>
                        <button className="black-btn-style me-3" onClick={ClosePopupHandler}>{Helper.getLabel(null,'Close','Close')}</button>
                        <button className='create_btn' onClick={CreatePurchaseOrder}>{Helper.getLabel(null,'create','Create')}</button>
                    </div>
                </div>  
              </div>
           </div>
        </Popup>
    </div>
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language,
      poHeaderTypes:state.poHederTypes
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setPoTypes:(types) => { dispatch({type:ActionTypes.SET_PO_HEADER_TYPES,payload:types})},
    setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
  }
}
const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders_create_screen
}


export default connect(mapStateToProps,mapDispatchToProps) (ApplySecurityRoles(CreateCOPopup, SecurityOptions))
