import React, { useEffect, useState } from 'react';
import MasterComonent from '../Backend/MasterComonent';
import NavigationHeder from '../Navigations/NavigationHeder';
import Helper from '../../inc/Helper';
import Dropdown from '../Forms/Dropdown';
import NotificationTile from './NotificationTile';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import QuestionnaireForm from '../Questionnaire/QuestionnaireForm';
import ContractQuestionnaireData from './ContractQuestionnaireData';

const Notifications = ({ isMasterComponenNotNeeded = false }) => {
  const [notifications, setNotifications] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords,setTotalRecords]=useState(10)
  const [staticNotification, setStaticNotification] = useState({
    acknowledge: false,
    createddatetime: "Mon, 04 Nov 2024 16:44:44 GMT",
    notification: {
      body: "Contract Expire Soon",
      title: "RFQ",
    },
    notification_id: 3699,
  });
  const [questionnaireTest,setQuestionnaireTest]=useState(false)
  const pageSize = 10;

  let notificationsCalled = false

  let filterOptions = [
    { value: 'all', label: 'Show All' },
    { value: true, label: 'Marked as Read' },
    { value: false, label: 'Marked as Unread' },
  ];

  const getAllNotifications = () => {
    if (pageNumber > Math.ceil(totalRecords / pageSize) || notificationsCalled) {
      return;
    }
    let url = Settings.apiUrl + `/notifications/${pageNumber}/${pageSize}`;

    if (selectedFilter !== 'all') {
      url += `?all_read=${selectedFilter}`;
    }

    let api = Api;
    api.setUserToken();

    notificationsCalled = true

    api
      .axios()
      .get(url)
      .then(function (res) {
        if (res.data.status === 'success') {
          setNotifications((prevNotifications) => [...prevNotifications, ...res.data.data]);
          setTotalRecords(res?.data?.total_records)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const handleMarkAllAsRead = () => {
    let api = Api;
    api.setUserToken();

    const allNotificationsIds = notifications
      .filter((item) => !item.acknowledge)
      .map((item) => item.notification_id);

    api
      .axios()
      .get(Settings.apiUrl + `/ack_notifications/${allNotificationsIds.join(',')}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success');

          let newNotificationsData = notifications.map((item) =>{
            return allNotificationsIds.includes(item.notification_id)
              ? { ...item, acknowledge: true }
              : item
          });

          setNotifications(newNotificationsData);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const handleDropdownChange = (event) => {
    let value = event.target.value;
    setSelectedFilter(value);
    setNotifications([])
    setPageNumber(1);
  };

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getAllNotifications();
  }, [selectedFilter, pageNumber]);


  const closeQuestionnaire = () => {
    setQuestionnaireTest(false)
  }

  const staticNotificationClickHandler = () => {
    setStaticNotification((prevNotification) => ({
      ...prevNotification,
      acknowledge: true,
    }));

    setQuestionnaireTest(true)
  };

  const locationData = window.location.href;
  const match = locationData?.match(/\/\/([a-zA-Z0-9-]+)\./);
  const subdomain = match ? match[1] : null;

  const renderContent = () => {
    return (
      <>
        <NavigationHeder 
          title={Helper.getLabel(null, 'notifications', 'Notifications')}
          hideMoreBtn={true}
        ></NavigationHeder>
        <div className={`notifications-main-div ${isMasterComponenNotNeeded ? 'bg-white' : ''}`}>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <button onClick={handleMarkAllAsRead} className='p-0 bg-transparent border-0' style={{ color: '#2D74E0' }}>
              Mark all as read
            </button>
            <div className='notification-dropdown'>
              <Dropdown id="notification-filter" value={selectedFilter} onChange={handleDropdownChange} options={filterOptions}/>
            </div>
          </div>
          <div className='mt-4'>
           {subdomain === 'gts-dev' && <NotificationTile
              key="static-notification"
              notification={staticNotification}
              handleOnNotificationClick={staticNotificationClickHandler}
            />}
            {notifications.length > 0 ? (
              notifications.map((item) => <NotificationTile key={item.notification_id} notification={item} notifications={notifications} setNotifications={setNotifications} />)
            ) : (
              <p>No Notifications are found yet.</p>
            )}
          </div>
          {
            questionnaireTest && <QuestionnaireForm  onQuestionnaireClose={closeQuestionnaire} QuestionData = {ContractQuestionnaireData} handleSubmit={closeQuestionnaire} info={'This information is about contract.'}/>
          }
        </div>
      </>
    )
  };

  return isMasterComponenNotNeeded ? <div className='join_to_vendor container-fluid'>{renderContent()}</div> : <MasterComonent>{renderContent()}</MasterComonent>
}

export default Notifications;
