import React from 'react';
import Helper from '../../../inc/Helper';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import Input from '../../Forms/Input';
import Settings from '../../../inc/Settings';
import LineViewComponent from '../Edit PO/ListView/LineViewComponent';
import Button from '../../inc/Button';
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget';

const CancelDocumentsPopup = (props) => {
    const onPopupClose = () => {
        if (props.onPopupClose) {
            props.onPopupClose();
        }
    };

    const onSaveClickHandler = () => {
       return Helper.confirmMe(props.onSubmitCancelDocumentStatus, Helper.getLabel(null, 'cancel_document', `Are you sure you want to cancel this contract?`))
    };

    const onCancleClickHandler = () => {
        return true;
    };

    let poObject = props.po;

    return (
        <Popup className="po_cancel_doc_popup" onClose={onPopupClose} autoOpen={true} width="1200px">
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <div>
                    <div className='popup_header mb-0'> <h3 className='fw-bold'>{Helper.getLabel(null, 'cancel_document', 'Cancel Document')}</h3></div>
                </div>
                <div>
                    <div className='d-flex justify-content-right' style={{ gap: "10px", 'justify-content': 'right' }}>
                      <TotalWidget source_id={props.source_id} integrator={poObject.purchase_order_id} />
                      <Button className="btn_md light_gray mr-2" onClick={onPopupClose} title={Helper.getLabel(null, 'close', 'Close')} />
                      <Button className="blue btn_md" isDisable={false} title={Helper.getLabel(null, 'submit', 'Submit')} onClick={onSaveClickHandler} />
                   </div>
                </div>
            </div>

            <Collapse className="mt-3 mb-3" open={true} title={Helper.getLabel(null, 'general_information', 'General Information')}>
                <div className='row'>
                    <div className='col-xs-12 col-md-4'>
                        <Input label={Helper.getLabel(null, 'document_number', 'Document number')} name="document_number" value={poObject.document_number} disable={true} />
                    </div>
                    <div className='col-xs-12 col-md-4'>
                        <Input label={Helper.getLabel(null, 'description', 'Description')} name="description" value={poObject.description} disable={true} />
                    </div>
                </div>
            </Collapse>
            <LineViewComponent
                lineGridId='amendment_line_grid'
                distributionGridId='amendment_line_distribution'
                defaultValues={{
                    requester_id: poObject.requester_id,
                    warehouse_id: poObject.warehouse_id,
                    delivery_date: poObject.delivery_date,
                    taxgroupitem_id: poObject.taxgroupitem_id,
                    taxgroup_id: poObject.taxgroup_id,
                    entity_id: poObject.entity_id,
                    site_id: poObject.site_id,
                    orderer_id: poObject.orderer_id
                }}
                source_id={props?.source_id?props?.source_id:Settings.source_id.purchase_order}
                line_source={props?.source_id?props?.source_id:Settings.source_id.purchase_order}
                isEditable={true}
                lineGridTitle={props?.lineGridTitle}
                header_id={poObject.purchase_order_id}
                purchase_status={poObject.purchase_status}
                document_status={poObject.document_status}
                delivery_date={poObject.delivery_date}
                master_save_button_id="btn_master_save"
                onbehalf_request={false}
                vendor_id={null}
                vendorsite_id={null}
            />
        </Popup>
    );
};

export default CancelDocumentsPopup;