const QuestionData = [
  {
    question: 'What is the size of your procurement team?',
    inputType: 'multiple choice',
    options: ['1-5', '6-10', '11-20', '21-50', '51+'],
  },
  {
    question: 'Which procurement software are you currently using?',
    inputType: 'multiple choice',
    options: ['SAP Ariba', 'Oracle Procurement Cloud', 'Coupa', 'Jaggaer', 'Other'],
  },
  {
    question: 'What is the primary reason for using procurement software?',
    inputType: 'multiple choice',
    options: ['Cost Reduction', 'Process Efficiency', 'Compliance', 'Supplier Management', 'Other'],
  },
  {
    question: 'How frequently do you update your procurement software?',
    inputType: 'multiple choice',
    options: ['Monthly', 'Quarterly', 'Annually', 'As Needed', 'Never'],
  },
  {
    question: 'What modules are you using in your procurement software?',
    inputType: 'checkbox',
    options: ['Supplier Management', 'Purchase Order Management', 'Invoice Management', 'Contract Management', 'Analytics'],
  },
  {
    question: 'How satisfied are you with the current procurement software?',
    inputType: 'rating',
    options: ['1', '2', '3', '4', '5'],
  },
  {
    question: 'What is your biggest challenge with the current procurement software?',
    inputType: 'multiple choice',
    options: ['User Interface', 'Integration with Other Systems', 'Customer Support', 'Functionality', 'Cost'],
  },
  {
    question: 'How important is mobile accessibility for your procurement software?',
    inputType: 'multiple choice',
    options: ['Very Important', 'Important', 'Neutral', 'Not Important', 'Not at All Important'],
  },
  {
    question: 'What is the annual budget for procurement software in your organization?',
    inputType: 'multiple choice',
    options: ['Under $10,000', '$10,000-$50,000', '$50,000-$100,000', '$100,000-$500,000', 'Over $500,000'],
  },
  {
    question: 'What features would you like to see in future updates of your procurement software?',
    inputType: 'text',
    options: [],
  },
]

export default QuestionData
