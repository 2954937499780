import axios from "axios";
import ActionTypes from "../inc/ActionsTypes";
import Api from "../inc/Api";
import Settings from "../inc/Settings";

const LoadTaxSettlementPeriods = (dispatch,oldData) => {
    if(oldData.isLoaded){
        return;
    }
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiUrl + '/taxSettlementPeriods').then(res =>{
        dispatch({
            type:ActionTypes.SET_SETTLEMENT_PERIODS,
            payload:res.data.data
        });
    })
}
export default LoadTaxSettlementPeriods;