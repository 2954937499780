import React from 'react';
import './ExternalUser.css';
import { connect } from 'react-redux'; 
import RightSection from './RightSection';

const ExtHome = () => {
  return (
    <section className="external_home_user">
      <div className="container-fluid ext_container">
        <div className="row">
          <div className="col-12 p-0">
            <RightSection />
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (((ExtHome)))