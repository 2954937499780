import moment from 'moment/moment'; 
import store from '../store';

let DatepickerFunctions = {
  //commented for emergency
  // convertDateForDataBase(currentDate) {
  //   return (moment.utc(currentDate).format('YYYY/MM/DD') === 'Invalid date' ? null :
  //     moment.utc(currentDate).format('YYYY/MM/DD'))
  // },
  convertDateForDataBase (currentDate)  {
    let userFormateOfDate = store?.getState()?.dateFormate?.formate.toUpperCase();
    const parsedDate = moment(currentDate, userFormateOfDate, true);
    if (parsedDate.isValid()) {
      return parsedDate.format('YYYY/MM/DD');
    } else {
      return null;
    }
  },
  convertDateFromDataBase(currentDate) {
    let userFormateOfDate = store?.getState()?.dateFormate?.formate?.toUpperCase();
    return (moment(currentDate).format(userFormateOfDate) === 'Invalid date' ? null :
      moment(currentDate).format(userFormateOfDate))
  }, 
  convertDateTimeForDataBase(currentDate) {
    return (moment.utc(currentDate).format('YYYY/MM/DD HH:mm:ss') === 'Invalid date' ? null :
      moment.utc(currentDate).format('YYYY/MM/DD HH:mm:ss'))
  },
  convertDateTimeFromDataBase(currentDate) {
    let userFormateOfDate = store.getState().dateFormate.formate.toUpperCase();
    return (moment(currentDate).format(`${userFormateOfDate} h:mm a`) === 'Invalid date' ? null :
      moment(currentDate).format(`${userFormateOfDate} h:mm a`))
  },
  formatDateYearOneDayMinusCurrentDate() {
    return this.convertDateFromDataBase(moment().clone().add(1, "years").subtract(1, "days"))
  },
  convertTimeFromDate(date){
    return moment(date).format('HH:mm:ss');
  },
  convertDesiredDateFormatForDataBase(inputDate) {
    let userFormateOfDate = store.getState().dateFormate.formate.toUpperCase();
    const parsedDate = moment(inputDate, userFormateOfDate, true);

    if (parsedDate.isValid()) return parsedDate.format('YYYY/MM/DD'); 
    else return null;
  },
  combineDateAndTime(time){
    if (!time) return null;
    const today = new Date();
    const [hours, minutes, seconds] = time.split(':');
    today.setHours(hours, minutes, seconds, 0);
     return today;
  },
  gridDateForDatabase(currentDate){
    return this.convertDateForDataBase(this.convertDateFromDataBase(currentDate))
  },
  checkIfInputStringIsDate(inputString) {
    const dateString = inputString.split(' ')[0];

    const dateFormats = [
      "dd/mm/yyyy",
      "dd-mm-yyyy",
      "yyyy-mm-dd",
      "yyyy/mm/dd",
      "mm-dd-yyyy",
      "mm/dd/yyyy"
    ];

    for (const format of dateFormats) {
      const regexString = format
        .replace(/dd/g, '(0[1-9]|[12][0-9]|3[01])')
        .replace(/mm/g, '(0[1-9]|1[0-2])')
        .replace(/yyyy/g, '\\d{4}');

      const regex = new RegExp(`^${regexString}$`);
      
      if (regex.test(dateString)) { 
        return true;
      }
    }
 
    return false;
  },
  convertPayloadDateTimeForDataBase(currentDate) {
    const parsedDate = moment(currentDate, 'YYYY/MM/DD h:mm a', true)
    if (parsedDate.isValid()) {
      return parsedDate.format('YYYY/MM/DD HH:mm:ss')
    } else {
      return null
    }
  },

};

export default DatepickerFunctions;