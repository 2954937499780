import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FieldsRenderer from './FieldsRenderer'

const DeliveryTab = (props) => { 

  //States
   const [deliveryTerms, setDeliveryTerms] = useState([])
   const [modeOfDelivery, setModeOfDelivery] = useState([])
   const [deliveryAddress, setDeliveryAddress] = useState([])
  //End

  //API Calls Renders
  useEffect(() => {
    DeliveryTerms()
    ModeOfDelivery()
  }, [])
  //End

  // API Reduction Call
  let deliveryterm_id = false
  let mode_of_delivery = false 
  let delivery_address = false 
  //End

  //API Calls

    const DeliveryTerms = () => {
      if(deliveryterm_id) {
        return;
      }
        let api = Api
        deliveryterm_id = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+'/delivery_term',{}).then(function(res){
        if(res.data.status==="success"){
          let deliveryTerm = []
          res.data.data.forEach(item => {  
          deliveryTerm.push({
            value: item.delivery_term_id,
            label: item.name
          })
        }) 
        setDeliveryTerms(deliveryTerm)
        } 
      }).catch((res) => { 
        console.log(res.response.data.message)
        // Helper.alert(res.response.data.message, 'error')
      })
    }

    const ModeOfDelivery = () => {
      if(mode_of_delivery) {
        return;
      }
        let api = Api
        mode_of_delivery = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+'/mode_of_delivery',{}).then(function(res){
        if(res.data.status==="success"){
          let ModeDelviery = []
          res.data.data.forEach(item => {  
          ModeDelviery.push({
            value: item.mode_of_delivery_id,
            label: item.name
          })
        }) 
        setModeOfDelivery(ModeDelviery)
        } 
      }).catch((res) => { 
        console.log(res.response.data.message)
        // Helper.alert(res.response.data.message, 'error')
      })
    }
  
    const DeliveryAddress = () => {
      if(delivery_address) {
        return;
      }
        let api = Api
        delivery_address = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+'/',{}).then(function(res){
        if(res.data.status==="success"){
          let deliveryAddress = []
          res.data.data.forEach(item => {  
          deliveryAddress.push({
            value: item.delivery_term_id,
            label: item.name
          })
        }) 
        setDeliveryAddress(deliveryAddress)
        } 
      }).catch((res) => { 
        console.log(res.response.data.message)
        // Helper.alert(res.response.data.message, 'error')
      })
    }

  //END
    
    let textBoxInputSettings = [ 
        {label:Helper.getLabel(props.language,'deliveryterm_id',"Delivery term"), options: deliveryTerms, inputType: "dropdown", id: 'delivery_term_id', name:'delivery_term_id' ,disable:!props.isEditable,reRenderRequired:props.reRenderRequired,placeHolder:Helper.getLabel(props.language,'select_delivery_term',"Select delivery term")},
        {label:Helper.getLabel(props.language,'mode_of_delivery',"Mode of delivery"),options: modeOfDelivery, inputType: "dropdown", id: 'mode_of_delivery_id', name:'mode_of_delivery_id',disable:!props.isEditable,reRenderRequired:props.reRenderRequired,placeHolder:Helper.getLabel(props.language,'select_mode_of_delivery',"Select mode of delivery")},
        {label:Helper.getLabel(props.language,'delivery_date',"Delivery date"), inputType: "datepicker_new", name:'delivery_date', inputClassName: 'delivery_date',disable:!props.isEditable,reRenderRequired:props.reRenderRequired},
        /*
        {label:Helper.getLabel(props.language,'delivery_address',"Delivery Address"), options: deliveryAddress, inputType: "dropdown", id: 'deliveryaddress_id', name:'deliveryaddress_id',disable:!props.isEditable},
        */
    ]
   if(props.type==="invoice"){
    textBoxInputSettings = textBoxInputSettings.filter((item)=>item.label !== Helper.getLabel(props.language,'delivery_date',"Delivery Date"))
   }
  return (
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (DeliveryTab) 