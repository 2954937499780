import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    logoUrl:'',
    tenant: {
        tenantData: {}
    },
}
const LogoUrlReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_LOGO_URL){
        state = {
            ...state,
            logoUrl:actions.url,
            tenant: {
                tenantData: actions.tenant
            }
        }
    }
    return state;
}
export default LogoUrlReducer;