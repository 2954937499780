import React, { Component } from 'react';
import Helper from '../../../../inc/Helper';
import Checkbox from '../../../Forms/Checkbox';
import Input from '../../../Forms/Input';
import DropDownInput from '../../../Forms/Dropdown';
import RsWithRouter from '../../../inc/RsWithRouter';
import { Link } from 'react-router-dom';

class DistributionAditionalInformation extends Component {
    constructor(props){
        super(props);
        this.activePoLineSite = null;
        this.setTimeOut = null;
        this.uniqueIdForTable = this.props.uniqueIdForTable;
        this.settings = this.props.settings;
        this.state = {
            data: {}
        }

    }
    componentDidMount(){
        this.activePoLineSite = this.props.activePoLineSite;
        this.setState({
            data:this.props.activePoLineSite ? this.props.activePoLineSite.data : {}
        })
    }
    onChangeHandler(e) {
        this.updateValue(e.target.name,e.target.value)
    }
    onDropdownChangeHandler(e,value) {
        console.log(e.target.value)
        this.updateValue(e.target.name,e.target.value)
    }
    onCheckboxChange(name,value){
        this.updateValue(name,value == 'on' ? true : false)
    }
    updateValue(newKey,newValue){
        let oldRowData = this.state.data;
        if(Object.keys(oldRowData).length <=0){
            Helper.alert("Please select ",'error')
            return;
        }
        let that = this;
        this.setState({
            data:{
                ...oldRowData,
                [newKey]: newValue
            }
        })
        clearTimeout(this.setTimeOut);
        this.setTimeOut = setTimeout(() => {
            let isChanged = that.activePoLineSite.setDataValue(newKey,newValue,'RowValueChangedEvent');
            if(isChanged){
                if(that.state.data[this.uniqueIdForTable]){
                    that.props.update(that.state.data)
                }else{
                    that.props.create(that.state.data)
                }
                that.props.grid.componentObj.setGridValueChanged(that.activePoLineSite.id, that.state.data);
            }
        }, 200);
    }

    onInputFocus(){
    let newData = this.state.data
       this.props.rs_router.navigate(`/purchase-requisition/summary?pr_view_id=${newData.requisition_id}`)
    }
    
    componentDidUpdate(prevProps){
        if(this.props.activePoLineSite && this.props.activePoLineSite.data[this.uniqueIdForTable] != prevProps.activePoLineSite.data[this.uniqueIdForTable]){
            this.activePoLineSite = this.props.activePoLineSite;
            this.setState({
                data:this.props.activePoLineSite.data
            })
        }
    }
    getField(fieldOptions){
        let language = null;
        let data = this.state.data;
        if(fieldOptions.inputType && fieldOptions.inputType == 'link'){
            return <div className={fieldOptions.wraperClass ? fieldOptions.wraperClass : 'col-xs-12 col-sm-4'}><p className='mb-1'>{fieldOptions.label}</p>{fieldOptions.disable === true?<p>{data[fieldOptions.name] || "-"}</p>:data[fieldOptions.name] === null || data[fieldOptions.name]===undefined ? "-":<Link to={`/purchase-requisition/summary?pr_view_id=${data.requisition_id}`} state={{isPrevPath:true}}>{data[fieldOptions.name]}</Link>}</div>
        } 
        if(fieldOptions.inputType && fieldOptions.inputType == 'checkbox'){
            return <div className={fieldOptions.wraperClass ? fieldOptions.wraperClass : 'col-xs-12 col-sm-4'}><Checkbox onChange={this.onCheckboxChange.bind(this)} isChecked={ fieldOptions.valueFormatter ? fieldOptions.valueFormatter(data[fieldOptions.name]) :  data[fieldOptions.name]  } label={fieldOptions.label} name={fieldOptions.name} value={data[fieldOptions.name]} disable = {fieldOptions.disable === true ? true : (!this.props.isEditable)}/></div>
        }
        if(fieldOptions.inputType && fieldOptions.inputType == 'dropdown'){
            return <div className={fieldOptions.wraperClass ? fieldOptions.wraperClass : 'col-xs-12 col-sm-4'}><DropDownInput id={fieldOptions.id} onChange={this.onDropdownChangeHandler.bind(this)} options={fieldOptions.options} label={fieldOptions.label} name={fieldOptions.name} value={data[fieldOptions.name]} disable = {fieldOptions.disable === true ? true : (!this.props.isEditable)} placeHolder={fieldOptions?.placeHolder}/></div>
        }
        return <div className={fieldOptions.wraperClass ? fieldOptions.wraperClass : 'col-xs-12 col-sm-4'}><Input onChange={fieldOptions?.name==="requisition_number"?"":this.onChangeHandler.bind(this)} onFocus={fieldOptions?.name==="requisition_number"?this.onInputFocus.bind(this):''} label={fieldOptions.label} name={fieldOptions.name} value={data[fieldOptions.name]} disable = {fieldOptions.disable === true ? true : (!this.props.isEditable)}/></div>
    }
    render() {
        let additionalFields = this.settings.getAdditionalFields();
        return (
            <div className='dt_addtional_info' style={{minHeight:'600px'}}>
                <div className='row' >
                    {
                        additionalFields.map( fieldOptions => {
                            return this.getField(fieldOptions)
                        })
                    }
                </div>
            </div>
            
        );
    }
}

export default RsWithRouter(DistributionAditionalInformation);