import React, { useEffect, useState } from 'react'
import LoginRegisterSidebar from './LoginRegisterSidebar'
import LoginRegisterTab from '../../components/Auth/LoginRegisterTab'
import Api from '../../inc/Api'
import { htmlContent2 } from './loginayc'
import Settings from '../../inc/Settings'
import { useNavigate } from 'react-router-dom'
import SimpleLoading from '../../components/Loading/SimpleLoading'
import Helper from '../../inc/Helper'
import FormValidator from '../../inc/FormValidator'
import AuthCheck from '../../components/Auth/AuthCheck'
import { connect } from 'react-redux'

let validator

const Register = ({user}) => {
  const [registerData,setRegisterData]=useState(null)
  const [loading,setLoading]=useState(false)

  const navigate = useNavigate()

  const validationConfigure = () => {
    let language = null
    let fieldConfig = [
        {name:'first_name',displayName:Helper.getLabel(language,'first_name','First Name'),types:['Required'],max:80 },
        {name:'email',displayName:Helper.getLabel(language,'email','Email'),types:['Required','Email'],max:80 },
        {name:'vendor_type',displayName:Helper.getLabel(language,'Vendor Type','Vendor Type'),types:['Required']},
    ]
    validator = new FormValidator(fieldConfig,language);
  }

  const onLoginBtnClick = () => {
    navigate("/login")
  }

  const onRegisterBtnClick = () => {
    navigate("/register")
  }

  const handleVendorTypeChange = (e,organizationInput) => {
    if (e.target.value === '1') {
        organizationInput.style.display = 'block';
    } else {
        organizationInput.style.display = 'none';
    }
 };


  useEffect(() => {
    const loginButton = document.getElementById('login-tab');
    
    const handleClick = (e) => {
      e.preventDefault();  
      onLoginBtnClick();
    };
  
    if (loginButton) {
      loginButton?.addEventListener('click', handleClick);
    }
  
    return () => {
      if (loginButton) {
        loginButton?.removeEventListener('click', handleClick);
      }
    };
  }, [onLoginBtnClick]);

  useEffect(() => {
    const registerButton = document.getElementById('register-tab');
    
    const handleClick = (e) => {
      e.preventDefault();  
      onRegisterBtnClick();
    };
  
    if (registerButton) {
      registerButton?.addEventListener('click', handleClick);
    }
  
    return () => {
      if (registerButton) {
        registerButton?.removeEventListener('click', handleClick);
      }
    };
  }, [onRegisterBtnClick]);

  useEffect(() => {
    const vendorType = document.getElementById('dynamic-register-vendor-type');
    const organizationInput = document.getElementById('dynamic-register-organization');
    
    const handleChange = (e) => {
      e.preventDefault();  
      handleVendorTypeChange(e,organizationInput)
    };
  
    if (vendorType) {
      vendorType?.addEventListener('change', handleChange);
    }
  
    return () => {
      if (vendorType) {
        vendorType?.removeEventListener('change', handleChange);
      }
    };

  }, [handleVendorTypeChange]);


  const getRegisterDetails = async () => {
    try {
      const api = Api;
      api.setUserToken();
      setLoading(true);
      let res
      res = await api.axios().get(Settings.loginUrl + `/register_page`);

      if (res?.headers['content-type'] === 'text/html; charset=utf-8') {
        setRegisterData(res.data);
        setLoading(false);
      }else if(res?.data?.status==='success' && res?.headers['content-type'] === 'application/json'){
        setRegisterData('')
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 404 && error?.response?.headers['content-type'] === 'application/json') {
        setRegisterData(htmlContent2);
        setLoading(false);
      }
    }
  };
  const handleErrorResponse = (error, email, registerButton) => {
    let customMessage = error.response.data.message;
    if (customMessage.includes('already exists')) {
        customMessage = `The username with email id ${email} is already exist`;
    }
    const errorDiv = document.getElementById('dynamic-register-message');
    if (errorDiv) {
        errorDiv.textContent = customMessage;
        errorDiv?.classList?.add('alert', 'alert-danger');
    }
    if(registerButton){
      registerButton.disabled = false
      registerButton.style.opacity=1
    }
  };

const clearFormFields = () => {
    document.getElementById('dynamic-register-first-name').value = '';
    document.getElementById('dynamic-register-last-name').value = '';
    document.getElementById('dynamic-register-organization-name').value = '';
    document.getElementById('dynamic-register-email').value = '';
    document.getElementById('dynamic-register-vendor-type').value = '';
    document.getElementById('termsCheckbox').checked = false;
};

  const registerHandler = async (e,registerButton) => {
    if (loading) {
        return;
    }
    let aggreeTc = document.getElementById('termsCheckbox')?.checked
    let firstName = document.getElementById('dynamic-register-first-name')?.value
    let lastName=document.getElementById('dynamic-register-last-name')?.value
    let organizationName=document.getElementById('dynamic-register-organization-name')?.value
    let email = document.getElementById('dynamic-register-email')?.value
    let vendorType = document.getElementById('dynamic-register-vendor-type')?.value

    validationConfigure()

    let data = {
      "first_name": firstName,
      "last_name": lastName,
      "middle_name": "",
      "enable": true,
      "SID": "",
      "network_domain": "",
      "network_alias": "",
      "language_id": 1,
      "vendor_type": parseInt(vendorType),
      "company_name": organizationName,
      "email": email
    }

    data.user_name = data.email;
    if(!Helper.getNullableValue(data.company_name) && data.vendor_type  == 2){
        data.company_name = data.first_name + ' ' +data.last_name ;
    }
    if(data.invitation_code===""){
      data.invitation_code=null
    }

    if (!aggreeTc) {
        Helper.alert(Helper.getLabel(null, 'please_accept_terms_condition', "Terms of use and Privacy Policy are required"), 'error');
        return;
    }

    if(!validator.isValid(data)){
      validator.displayMessage();
      validator.reset()
      return;
    }

    let api = Api;
    api.setUserToken();

   
    if(registerButton){
      registerButton.disabled = true
      registerButton.style.opacity=0.7
    }
    try {
        const res = await api.axios().post(`${Settings.apiUrl}/register_vendor`, data);
        if(res){
        setLoading(false);

        if(registerButton){
          registerButton.disabled = false
          registerButton.style.opacity=1
        }

        const errorDiv = document.getElementById('dynamic-register-message');
       if (errorDiv) {
        errorDiv.textContent = res.data.message;
        errorDiv?.classList?.add('alert', 'alert-success');
       }

       clearFormFields()

        setTimeout(() => {
            navigate("/login");
        }, 3500);
        }
    } catch (error) {
       handleErrorResponse(error,email,registerButton)
    }
};

useEffect(() => {
  const registerButton = document.getElementById('dynamic-register-submit');
  
  const handleClick = (e) => {
    e.preventDefault();  
    registerHandler(e,registerButton);
  };

  if (registerButton) {
     registerButton?.addEventListener('click', handleClick);
     document.addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        handleClick();
      }
    });
  }

  return () => {
    if (registerButton) {
      registerButton?.removeEventListener('click', handleClick);
    }
  };
}, [registerHandler]);

  useEffect(()=>{
    getRegisterDetails()
  },[])

  const renderLoginModule = () => {
    if (!loading && registerData !== '') {
      return (
         <>
          <AuthCheck noAccessForLogin={user?.admin_user?("/"+Settings.redirectAdminAfterLogin):"/"+Settings.redirectUrlAfterLogin}/>
          <div dangerouslySetInnerHTML={{ __html: registerData }} />
         </>
      )
    } else {
      return (
        <div className='login_register_page register_page'>
          <LoginRegisterSidebar/>
          <LoginRegisterTab tab="register"/>
        </div>
      )
    }
  }
  return loading ? <SimpleLoading /> : renderLoginModule()
}

const mapStateToProps = (state) => {
  return {
      user:state.auth.user
  }
}

export default connect(mapStateToProps) (Register);
