import React, { useRef, useState } from "react";
import "./TeamEvaluation.css";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import RFQAttachmentPopup from "../../RFQ Vendor Reply/RFQList/RFQAttachmentPopup"; 

/*
brief: This is the Evaluation Criteria Component for Team Evaluation - (RFQ) -> Component,

props; 

criteriaType : will be the sticky(fixed) column, STRING (D.T),
vendorsList : will the mapped, having the scroll transition (horizontal-scroll-bar) ARRAY (D.S),

vendorsList : [
  {
    id: STRING OR NUMERIC,
    vendor: STRING,
  }
]
*/

const EvaluationCriteriaHeader = ({
  criteriaType,
  vendorsList,
  setShowSelectedLinesModal,
  setVendor,
  vendorRef
}) => {
  const [selectedAttachment, setSelectedAttachment] = useState(null); 
  const [showAttachments, setShowAttachments] = useState(false);

  const getRfqAttachments = (attachmentSourceId) => {
    if (!attachmentSourceId) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + `/get_attachments/${Settings?.source_id?.RfqVendorReply}/${attachmentSourceId}?attachment_for=1`)
      .then(function (res) { 
        if (res.data.status === "success") { 
          setSelectedAttachment(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const executeAttachment = (vendor) => {
    setSelectedAttachment(null);
    setShowAttachments(true);
    getRfqAttachments(vendor?.reply_id);
  };

  const executeInfoBlue = (vendor) => {
    setVendor(vendor);
    setShowSelectedLinesModal(true);
  };
  
  const closeAttachmentModal = () => {
    setSelectedAttachment(null);
    setShowAttachments(false);
  };

  return (
    <div className='team_evaluation_criteria_header'>
      <div className='fixed_column'>{criteriaType}</div>
      <div className='scrollable_column' ref={vendorRef}>
        {vendorsList.map((item) => (
          <div className="child_div" key={item?.reply_id}>
            {item.vendor}
            <span style={{ position: "relative", display: "flex" }} className="ms-3">
              <div style={{ position: "relative" }}>
                <img
                  onClick={() => executeAttachment(item)}
                  className="img-fluid me-2"
                  src="/images/icons/attachment.svg"
                  alt=""
                />
                { item?.attachment_exists && 
                  <div
                    style={{
                      position: "absolute",
                      top: "1.5px",
                      right: "7px",
                      width: "7.5px",
                      height: "7.5px",
                      borderRadius: "50%",
                      backgroundColor: "#FC5E24",
                    }}
                  />
                }
              </div>
              <img
                onClick={() => executeInfoBlue(item)}
                className="img-fluid"
                src="/images/icons/info-blue.svg"
                alt=""
              />
            </span>
          </div>
        ))}
      </div>
      {showAttachments && (
        <RFQAttachmentPopup
          data={selectedAttachment}
          language=""
          closeModal={closeAttachmentModal}
          style={{
            position: "absolute",
            top: "52px", 
            left: "40%",
            zIndex: 9999
          }}
        />
      )}
    </div>
  );
};

export default EvaluationCriteriaHeader;