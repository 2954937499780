import React, { Component } from 'react';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import Input from '../../Forms/Input';
import Helper from '../../../inc/Helper';
import Dropdown from '../../Forms/Dropdown';
import Button from '../../inc/Button';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';

class GoodReceivingCreatePopup extends Component {
    constructor(props){
        super(props);
        this.isPOHeaderLoad = false;
        this.state = {
            isLoading:false,
            poData:{}
        }
    }
    componentDidMount(){
        this.loadPoHeader();
    }
    onClose(){
        if(this.props.onClose){
            this.props.onClose();
        }
    }
    onDropdownChangeHandler(e){}
    onSaveClickHandler(e){}
    onCloseBtnClick(e){}
    canPerformSave(e){
        return true;
    }
    loadPoHeader(){
        if(this.isPOHeaderLoad){
            return;
        }
        this.isPOHeaderLoad = true;
        let {poid} = this.props;
        if(!poid){
            return;
        }
        let api = Api, that =this;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_header/${poid}`,{}).then(function(res){
            that.setState({
                isLoading:false,
                poData:res.data.data
            })
            //that.loadVendorById(res.data.data.vendor_id)
            //that.loadVendorSite(res.data.data.vendorsite_id)
        }).catch((res) => {
            that.setState({
                isLoading:false,
                poData:{}
            })
        })
    }

    render() {
        if(this.state.isLoading){
            return (
                <Popup autoOpen={true} onClose={this.onClose.bind(this)} width="750px">
                    <div className='popup_header'>
                        <h3>{Helper.getLabel(null,'Create_Good_Receiving_Note','Create Good Receiving Note')}</h3>
                    </div>
                    <SimpleLoading/>
                </Popup>
            )
        }
        return (
            <Popup autoOpen={true} onClose={this.onClose.bind(this)} width="750px">
                <div className='popup_header'>
                    <h3>{Helper.getLabel(null,'Create_Good_Receiving_Note','Create Good Receiving Note')}</h3>
                </div>
                <Collapse title={'Please select the following information to create good receiving note'} open={true}>
                    <div className='row'>
                        <div className='col-xs-12 col-md-6'>
                            <Dropdown name="show_quantity_as_per" options={[]}  label={Helper.getLabel(null,'show_quantity_as_per','Show quantity as per')} onChange={this.onDropdownChangeHandler.bind(this)} id="show_quantity_as_per"/>
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <Dropdown name="filter_items_which_are_supposed" options={[]}  label={Helper.getLabel(null,'filter_items_which_are_supposed','Filter items which are supposed')} onChange={this.onDropdownChangeHandler.bind(this)} id="filter_items_which_are_supposed"/>
                        </div>
                    </div>
                </Collapse>
                <div className='d-flex justify-content-right mt-5' style={{gap:"10px",'justify-content':'right'}}>
                    <Button className="black-btn-style mr-2" onClick ={ this.onCloseBtnClick.bind(this)} title={Helper.getLabel(null,'Close','Close')} />
                    <Button className="blue btn_md" isDisable={!this.canPerformSave()} title={Helper.getLabel(null,'create','Create')} onClick={ this.onSaveClickHandler.bind(this) } />
                </div>
            </Popup>
        );
    }
}

export default GoodReceivingCreatePopup;