import React, { useEffect, useState } from 'react';
import LoginRegisterTab from '../../components/Auth/LoginRegisterTab';
import './login-register.css';
import LoginRegisterSidebar from './LoginRegisterSidebar';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import CompanyDomain from './CompanyDomain';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import Helper from '../../inc/Helper';
import SimpleLoading from '../../components/Loading/SimpleLoading';
import { requestPermission } from '../../services/Firebase/FirebaseServiceFunctions';
import ForgotPasswordPopup from './ForgotPasswordPopup';
import { htmlContent2 } from './loginayc';
import FormValidator from '../../inc/FormValidator';
import AuthCheck from '../../components/Auth/AuthCheck';

let validator

const Login = (props) => {
  const [loginData,setLoginData]=useState(null)
  const [loading,setLoading]=useState(false)
  const [forgotPasswordPopup,setForgotPasswordPopup] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const locationPath = window.location.href;
  const parsedUrl = new URL(locationPath);
  const hostnameParts = parsedUrl?.hostname?.split('.');
  const subdomain = hostnameParts[0];
  const extractedText = subdomain || null;

  const redirectToAfterLogin = (user) => {
    user?.first_time ? navigate('/change-password'):navigate('/' + Settings.redirectUrlAfterLogin);
  };

  const validationConfigure = () => {
    const language = null;
    let fieldConfig = [
        {name:'user_name',displayName:Helper.getLabel(language,'email','Email'),types:['Required','Email'],max:80 },
        {name:'password',displayName:Helper.getLabel(language,'password','Password'),types:['Required'],min:5,max:80 },
    ]
    validator = new FormValidator(fieldConfig,language);
  }

  const onLoginClickHandler = async (e,loginButton) => {
    e.preventDefault(); 

    const email = document.getElementById('dynamic-login-email').value?.trim();
    const password = document.getElementById('dynamic-login-password').value?.trim();

    const locationPath = window.location.href;
    const parsedUrl = new URL(locationPath);
    const hostnameParts = parsedUrl?.hostname?.split('.');
    const subdomain = hostnameParts[0] ? hostnameParts[0] : null;
    const token = await requestPermission(true);

    validationConfigure()

    let validData =    {
      user_name: email,
      password: password,
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset()
      return;
    } 

    let formData = {
        user_name: email,
        password: Helper.encryptPassword(password)?.trim(),
        web_fcm_token:token,
    };

    if(loginButton){
      loginButton.disabled = true
      loginButton.style.opacity=0.7
    }

    let api = Api;
    api.setJsonHeader();
    api.axios().post(Settings.apiUrl + '/login',formData).then(function({data}){
        if(data && data.status === "Success"){
            const aditionalData = data.data;
            //set user token to browser
            Helper.setCookie(Settings.userTokenKey,data.jwttoken,Settings.userTokenExpireDate);
            Helper.setCookie(Settings.dateFormateKey,aditionalData.tenant.date_format);
            Helper.setCookie(Settings.tenantSubdomain,subdomain)
            props.setDateFormate(aditionalData.tenant.date_format);
            api.setUserToken();
            if(data?.data?.first_time){
              navigate("/change-password")
            }
            api.axios().get(Settings.apiUrl+'/user_details',{}).then(function({data}){
                if(data && data.Status === "Success"){
                    props.setUser(data.data);
                    props.login(data.data);
                }
                if(loginButton){
                  loginButton.disabled = false
                  loginButton.style.opacity=1
                }
                setTimeout(()=>{redirectToAfterLogin(data.data)},200)
            })
        }
    }).catch((error)=>{
       Helper.alert(error?.response?.data?.message,"error")
       if(loginButton){
        loginButton.disabled = false
        loginButton.style.opacity=1
      }
    })

  };

  const onForgotPasswordClickHandler = () => {
    setForgotPasswordPopup(true)
  }

  const onLoginBtnClick = () => {
    navigate("/login")
  }

  const onRegisterBtnClick = () => {
    navigate("/register")
  }

  const handleClose = () => setForgotPasswordPopup(false);

  const getLogInDetails = async () => { 
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const tParam = urlParams.get('t');
      const api = Api;
      api.setUserToken();
      if (tParam && extractedText) { 
        Helper.setCookie(Settings.userTokenKey,tParam,Settings.userTokenExpireDate);
        navigate('/dashboard'); 
        return;
      }

      console.log('domain',extractedText)

      if(extractedText === 'admin'){ 
        setLoginData('');
        return;
      };

      setLoading(true);

      if (!extractedText || locationPath === 'https://app.lynkaz.com/login' || locationPath === 'https://appdev.lynkaz.com/login') {
        setLoading(false);
        return;
      };
      
      let res
      if(extractedText !== "admin" ||  locationPath !== 'https://app.lynkaz.com/login' || locationPath !== 'https://app.lynkaz.com'  || locationPath !== 'https://appdev.lynkaz.com/login' || locationPath !== 'https://appdev.lynkaz.com/login'){
        res = await api.axios().get(Settings.loginUrl + `/login_page`);
      }

      if (res?.headers['content-type'] === 'text/html; charset=utf-8') {
        setLoginData(res.data);
        setLoading(false);
      }else if(res?.data?.status==='success' && res?.headers['content-type'] === 'application/json'){
        setLoginData('')
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 404 && error?.response?.headers['content-type'] === 'application/json') {
        setLoginData(htmlContent2);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getLogInDetails();
    requestPermission()
  }, [location?.pathname]);

  useEffect(() => {
    const submitButton = document.getElementById('dynamic-login-submit');
  
    const handleClick = (e) => {
      e.preventDefault();  
      onLoginClickHandler(e,submitButton);
    };
  
    if (submitButton) {
      submitButton.addEventListener('click', handleClick);
    }
  
    return () => {
      if (submitButton) {
        submitButton.removeEventListener('click', handleClick);
      }
    };
  }, [onLoginClickHandler]);

  useEffect(() => {
    const forgotPasswordButton = document.getElementById('dynamic-forgot-password');
    
    const handleClick = (e) => {
      e.preventDefault();  
      onForgotPasswordClickHandler();
    };
  
    if (forgotPasswordButton) {
      forgotPasswordButton?.addEventListener('click', handleClick);
    }
  
    return () => {
      if (forgotPasswordButton) {
        forgotPasswordButton?.removeEventListener('click', handleClick);
      }
    };
  }, [onForgotPasswordClickHandler]);

  useEffect(() => {
    const loginButton = document.getElementById('login-tab');
    
    const handleClick = (e) => {
      e.preventDefault();  
      onLoginBtnClick();
    };
  
    if (loginButton) {
      loginButton?.addEventListener('click', handleClick);
    }
  
    return () => {
      if (loginButton) {
        loginButton?.removeEventListener('click', handleClick);
      }
    };
  }, [onLoginBtnClick]);

  useEffect(() => {
    const registerButton = document.getElementById('register-tab');
    
    const handleClick = (e) => {
      e.preventDefault();  
      onRegisterBtnClick();
    };
  
    if (registerButton) {
      registerButton?.addEventListener('click', handleClick);
    }
  
    return () => {
      if (registerButton) {
        registerButton?.removeEventListener('click', handleClick);
      }
    };
  }, [onRegisterBtnClick]);


  const renderLoginModule = () => {
    if(!loading && loginData !== ''){
      return (
        <>
          <AuthCheck noAccessForLogin={props?.auth?.user?.admin_user?("/"+Settings.redirectAdminAfterLogin):"/"+Settings.redirectUrlAfterLogin}/>
          <div dangerouslySetInnerHTML={{ __html: loginData }} />
          {forgotPasswordPopup && (<ForgotPasswordPopup forgotPasswordPopup={forgotPasswordPopup} handleClose={handleClose} language={props.language} />)}
        </>
      );
    }else{
      return <div className='login_register_page'>
          <LoginRegisterSidebar />
          <LoginRegisterTab tab='login' />
        </div>
    }
  }

  if(window.location.href==="https://app.lynkaz.com/login" || window.location.href==="https://app.lynkaz.com/" || window.location.href==="https://appdev.lynkaz.com/login" || window.location.href==="https://appdev.lynkaz.com/" || window.location.href==="http://localhost:3000/login" || window.location.href==="http://localhost:3000/" || !extractedText){
    return <CompanyDomain />
  }  
  return (
    loading ? <SimpleLoading /> : (
      renderLoginModule()
    )
  );

}

const mapStateToProps = (state) => {
    return {
        language:state.language,
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        //date_format
        setDateFormate:(formate) => { dispatch({type:ActionTypes.SET_DATE_FORMATE , payload:formate}) },
        login:(user) => { dispatch({type:ActionTypes.SET_LOGIN , payload:user}) },
        setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (Login);
