import React, { Fragment, useEffect, useState } from 'react'
import SimpleLoading from '../../../Loading/SimpleLoading';
import AgGridMemo from '../../../grid/ag/AgGridMemo';
import Collapse from '../../../inc/Collapse';
import { connect } from 'react-redux';
import Helper from '../../../../inc/Helper';
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell';
import Api from '../../../../inc/Api';
import DatepickerFunctions from '../../../../inc/DatepickerHelper';
import Settings from '../../../../inc/Settings';
import { customDateComparator, getPopupMessageBasedOnStatus, submergeComplianceResponses } from '../../../../inc/Validation';
import ViewDescriptionPopup from '../ViewDescriptionPopup';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import DateEditor from "../../../grid/ag/cellEditor/CustomDatePicker"; 
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown';

/*

LYNKAZ - External Compliance View:

rfq_id: required*
rfq_vendorreply_id: required*
disableEditing: (required, when Times Finishes on RFQ Bidding Form)

*/


let complainceExternalGridObj = null;

const ExternalComplianceView = (
    { language, rfq_id, rfq_vendorreply_id, disableEditing = true }
) => {
    const [compliances, setCompliances] = useState([]);
    const [complianceResponseHeaders, setComplianceResponseHeaders] = useState([]);
    const [complianceNames, setComplianceNames] = useState([]);
    const [complianceResponseHeadersList, setComplianceResponseHeadersList] = useState([]);
 
    const [loading, setLoading] = useState(true);

    let RFQComplianceTemplateCalled = false;
    let complianceResponseHeaderCalled = false;

    useEffect(() => { 
        getRFQComplianceTemplateLines()
        getAllComplianceResponseHeader() 
    }, []);

    // api calls functions,
    const getRFQComplianceTemplateLines = async () => {
        if(RFQComplianceTemplateCalled) return;
        RFQComplianceTemplateCalled = true;

        if(!rfq_id && !rfq_vendorreply_id) return;

        let api = Api
        api.setUserToken();

        setLoading(true) 
        api.axios().get(Settings.apiPurchaseRequisitionUrl+`/get_rfq_compliance_template_lines/${rfq_id}`,{}).then(async function(res){
            if(res.data.status==='success'){  
                let responseLinesComplianceHeader = await getComplianceResponseLinesByComplianceHeader(res.data.data[0]?.complianeheader_id);
                let rfqComplianceResponse = await getRFQComplianceResponse();

                let updated_array_response = submergeComplianceResponses(res.data.data, rfqComplianceResponse, responseLinesComplianceHeader);
                let Dropdown_data = [];

                responseLinesComplianceHeader.forEach((item) => {
                    Dropdown_data.push({
                        value: item.compliance_id,
                        label: item.name,
                    });
                });

                setComplianceResponseHeadersList(responseLinesComplianceHeader);
                setComplianceResponseHeaders(Dropdown_data);  
                setCompliances(updated_array_response);
                 
                setLoading(false);
            }
        }).catch((res) => {  
            setCompliances([]);
            setLoading(false);
        })
    };

    const getComplianceResponseLinesByComplianceHeader = async (complianceheader_id) => {
        if (!complianceheader_id) return [];
    
        let api = Api;
        api.setUserToken();
    
        try {
            const res = await api.axios().get(Settings.apiPurchaseRequisitionUrl + `/complianceresponselines/${complianceheader_id}`, {});
    
            if (res.data.status === 'success') return res.data.data;
            else return [];

        } catch (error) { return [] };
    };
    
    const getRFQComplianceResponse = async () => {
        if (!rfq_id || !rfq_vendorreply_id) return Promise.resolve([]); 
      
        let api = Api;
        api.setUserToken();
       
        return api.axios()
          .get(Settings.apiPurchaseRequisitionUrl + `/get_compliance_response/${rfq_id}?rfq_vendorreply_id=${rfq_vendorreply_id}`, {})
          .then(function (res) {
            if (res.data.status === 'success') return res.data.data; 
            else return []; 
        })
        .catch((res) => { return [] });
    };

    const getAllComplianceResponseHeader = () => {
        if(complianceResponseHeaderCalled) return;
  
        let api = Api
        complianceResponseHeaderCalled = true;
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+'/complianceresponseheader',{}).then(function(res){
            if(res.data.status==='success'){
                let Compliance_Response_Header = []
                res.data.data.forEach((item) => {
                    Compliance_Response_Header.push({
                        label: item.compliance_name,
                        value: item.complianceheader_id
                    })
                })
                setComplianceNames(Compliance_Response_Header); 
            } 
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res);
        })
    }

    // Grid api execution functions,
    const onCreateComplianceResponse = async (payload) => { 
        if (!payload || !rfq_id || !rfq_vendorreply_id || !payload.complianceheader_id || !payload.templateline_id) return;
    
        if(!payload.compliance_id) {
            Helper.alert('Compliance Applicable missing!', 'error');
            return;
        }

        try {
            let apiPayload = {
                rfq_id: rfq_id,
                complianceheader_id: payload.complianceheader_id,
                rfq_vendorreply_id: rfq_vendorreply_id,
                templateline_id: payload.templateline_id,
                compliance_id: payload.compliance_id,
                comments: payload.comments,
                compliance_date: (payload.compliance_date)
            };
 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl + `/compliance_response`, apiPayload);
            if (res.data.status === "success") { 
                Helper.alert(res?.data?.message);

                let foundCompliance = compliances?.find(compliance => compliance.compliance_templateline_id === payload.templateline_id);

                if (foundCompliance) { 
                    complainceExternalGridObj.api.forEachNode((node) => {
                        if(!node.data) return;

                        if(node.data.compliance_templateline_id === payload.templateline_id){
                            node.data.compliance_response_id = res.data.compliance_response_id
                        }
                    });

                    foundCompliance.compliance_response_id = res.data.compliance_response_id;
                        
                    setCompliances((prevCompliances) => {
                        return prevCompliances.map((compliance) =>
                            compliance.compliance_templateline_id === payload.templateline_id ? foundCompliance : compliance
                        );
                    });
                    getRFQComplianceTemplateLines();
                };
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }  
    };
    
    const onUpdateComplianceResponse = async ( comments, compliance_id, compliance_response_id, compliance_date ) => {
        if(!compliance_response_id) return;

        if(!comments && !compliance_date && !compliance_id){
            Helper.alert('Please provide either Comments, Compliance Applicable or Compliance Date!', 'error');
            return; 
        }

        try {
            let payload = {
                comments: comments,
                compliance_date: (compliance_date),
                compliance_id: compliance_id
            };

            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/compliance_response/${compliance_response_id}`, payload);
            if (res.data.status === "success") { 
                Helper.alert(res?.data?.message);
                getRFQComplianceTemplateLines();
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    };

    const onRowValueChangeAPICall = (params) => {
        let rowObject = params?.data;
        let apiPayload = {
            rfq_id: rfq_id,
            complianceheader_id: rowObject?.complianeheader_id,
            rfq_vendorreply_id: rfq_vendorreply_id,
            templateline_id: rowObject?.compliance_templateline_id,
            compliance_id: parseInt(rowObject?.compliance_id),
            comments: rowObject?.comments,
            compliance_date: DatepickerFunctions.gridDateForDatabase(rowObject?.compliance_date)
        }

        if(!apiPayload.comments && !apiPayload.compliance_date && !apiPayload.compliance_id) return;
        
        if(rowObject.date_mandatory && ( apiPayload.compliance_date === '' || apiPayload.compliance_date === null || !apiPayload.compliance_date )) {
            Helper.alert('Please provide date, its mandatory', 'error');
            return;
        }
        
        if(rowObject.comments_mandatory && !apiPayload.comments) {
            Helper.alert('Please provide comments, its mandatory', 'error');
            return;
        }
        
        if(!rowObject?.compliance_response_id) onCreateComplianceResponse(apiPayload)
        else onUpdateComplianceResponse(apiPayload.comments, apiPayload.compliance_id, rowObject?.compliance_response_id, apiPayload.compliance_date)
    };

    // module wrapper
    const ComplianceModuleWrapper = () => { 
        const gridValueDateFormat = (params) => {
            const borderColor = params?.data?.date_mandatory;  
          
            return <div style={{ display: 'flex', border: `1px solid ${borderColor ? '#E94335' : 'transparent'}`, alignItems: 'center', height: '40px'}}><div>{params.value ? (params.value) : '-'}</div></div>;
        }

        const gridValueDescriptionFormat = (params) => {
            const borderColor = params?.data?.comments_mandatory; 
            const borderStyle = `border: 1px solid ${borderColor ? '#E94335' : 'transparent'}`;
          
            return `<div style="display: flex; ${borderStyle}; align-items: center; height: 40px;"><div>${params.value ? params.value : '-'}</div></div>`;
        }
  
        const complianceDropdownColorEffect = (params) => {
            if(params.colDef.field == "compliance_id") {
                const cellValue = params.value === null || params.value === undefined ? ' ' : params.value;
                let complianceObj = complianceResponseHeadersList?.find(val => val.compliance_id == cellValue);
                
                return <div style={{color: complianceObj?.color ? complianceObj?.color : '#000'}}> {complianceObj ? complianceObj?.name : '-'} </div>;
            }
        }
  
        const onComplianceApplicableCellEditingStopped = (params) => {
            let currentComplianceVal = params.data?.compliance_id;
            let currentComplianceObj = complianceResponseHeadersList?.find(val => val.compliance_id == currentComplianceVal);
            
            params.node.setDataValue('date_mandatory', currentComplianceObj?.date_mandatory);
            params.node.setDataValue('comments_mandatory', currentComplianceObj?.comments_mandatory);

            params.api.refreshCells({ rowNodes: [params.node], columns: ['date_mandatory', 'comments_mandatory'] });
        }

        let dataSource = {
            getRows: async function (params) {
                params?.success({
                    rowData: compliances?.map((item) => ({
                        ...item,
                        gridId: `${Date.now()}_${getNewValue()}`
                    })),
                    rowCount: compliances?.length
                })
            },
        };

        const onComplainceGridReady = (params) => {
            if(params) {
                complainceExternalGridObj = params;
                setTimeout(()=>{ 
                    params?.api?.setGridOption('serverSideDatasource', dataSource);
                }, 200)
            }
        }
 
        const gridColumnHeadersCompliance = [
            { 
                field: 'complianeheader_id', 
                headerName: Helper.getLabel(language,'compliance_name','Name'),
                editable: false,
                cellEditor: SimpleDropdown,
                isAllowZero:true,
                minWidth: 300,
                cellEditorParams: { values: complianceNames },
                cellDataType:false,
                valueFormatter: (params)=> getLabelFromDropdown(params),
                filter:'agSetColumnFilter',
                filterParams: {
                    values: (params) => getOptions(params, complianceNames),
                    keyCreator: (params) => optionsKeyCreator(params, 'value'),
                    valueFormatter: params => params.value.label
                }
            },
            {field:'category', filter: 'agTextColumnFilter', editable: false, minWidth: 270, headerName: Helper.getLabel(language,'category','Category')}, 
            {field:'subcategory', filter: 'agTextColumnFilter', valueFormatter: (params) => params.value ? params.value : '-', editable: false, minWidth: 270, headerName: Helper.getLabel(language,'subcategory','Sub category')}, 
            {field:'compliance_description', filter: 'agTextColumnFilter', editable: false, minWidth: 230, headerName: Helper.getLabel(language,'description','Description')}, 
            { 
                field: 'comments_mandatory',
                filter: 'agSetColumnFilter',
                filterParams: { 
                    values: [true, false], 
                    keyCreator: (params) => params.value, 
                    valueFormatter: (params) => params.value ? 'true' : 'false' 
                }, 
                cellRenderer: 'agCheckboxCellRenderer', 
                cellEditor: 'agCheckboxCellEditor', 
                hide: true,
                cellRendererParams: { disabled: true },
                editable: false,
                minWidth: 270,
                valueGetter: (params) => {
                    if (!params?.data?.comments_mandatory || !params?.data){
                        return false
                    }
                    else {
                        return params.data?.comments_mandatory
                    }
                },
                headerName: Helper.getLabel(language,'comments_mandatory','Comments mandatory')
            },
            { 
                field: 'date_mandatory',
                filter: 'agSetColumnFilter',
                filterParams: { 
                    values: [true, false], 
                    keyCreator: (params) => params.value, 
                    valueFormatter: (params) => params.value ? 'true' : 'false' 
                }, 
                cellRenderer: 'agCheckboxCellRenderer', 
                cellEditor: 'agCheckboxCellEditor', 
                hide: true,
                cellRendererParams: { disabled: true },
                editable: false,
                minWidth: 270,
                valueGetter: (params) => {
                    if (!params?.data?.date_mandatory || !params?.data){
                        return false
                    }
                    else {
                        return params.data?.date_mandatory
                    }
                },
                headerName: Helper.getLabel(language,'date_mandatory','Date mandatory')
            },
            { 
                field: 'compliance_id', 
                headerName: Helper.getLabel(language,'compliance_id','Compliance applicable'),
                editable: disableEditing,
                cellEditor: SimpleDropdown,
                isAllowZero:true,
                minWidth: 300, 
                cellEditorParams: { values: complianceResponseHeaders },
                cellDataType:false,
                cellRenderer: complianceDropdownColorEffect,
                valueFormatter: (params)=> getLabelFromDropdown(params),
                filter:'agSetColumnFilter',
                filterParams: {
                    values: (params) => getOptions(params, complianceResponseHeaders),
                    keyCreator: (params) => optionsKeyCreator(params, 'value'),
                    valueFormatter: params => params.value.label
                }
            }, 
            {
                field: 'compliance_date',
                headerName: Helper.getLabel(language,'compliance_date','Compliance date'),
                filter:'agDateColumnFilter', 
                valueFormatter: (params) => { 
                    return params.value
                        ? DatepickerFunctions.convertDateFromDataBase(params.value)
                    : '-'
                },
                editable: disableEditing,
                cellEditor: DateEditor,
                cellEditorParams: { empty: true },
                minWidth: 270,
                comparator: customDateComparator
            },
            {field:'comments', filter: 'agTextColumnFilter', cellRenderer: CustomHtmlCell, valueFormatter: gridValueDescriptionFormat, editable: disableEditing,  minWidth: 270, headerName: Helper.getLabel(language,'compliance_comments','Compliance comments')} 
        ];

        return (
            <div className='external_compliance_view'>
                <Collapse className='grid_save_btn_hide' open={true} title={`Compliances`}>
                    <div className='container-fluid grid_compliance_external'>
                        <AgGridNew
                            hideAddBtn={true}
                            hideDeleteBtn={true}  
                            columnDefs={gridColumnHeadersCompliance}  
                            onRowValueChanged={onRowValueChangeAPICall}
                            onCellEditingStopped={onComplianceApplicableCellEditingStopped}
                            onGridReady={onComplainceGridReady} 
                            height={500}
                            gridId={'grid_compliance_external_view'} 
                            pagination={false}  
                        />
                    </div>
                </Collapse>
            </div>
        )
    };
    return ( <Fragment> {loading ? <div style={{height: '500px'}}> <SimpleLoading /> </div> : ComplianceModuleWrapper()} </Fragment> )
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language,
    };
};
  
export default connect(mapStateToProps)(ExternalComplianceView);