import React, { useState, useEffect } from "react";
import Helper from "../../../../inc/Helper";

import Collapse from "../../../inc/Collapse";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import Checkbox from "../../../Forms/Checkbox";
import TechnicalSelectedLinesModalPreferredColumn from "../TechnicalEvaluation/TechnicalSelectedLinesModalPreferredColumn";
import TechnicalSelectedLinesModalAwardToColumn from "../TechnicalEvaluation/TechnicalSelectedLinesModalAwardTo";
import GreenUpArrow from "../../../../assets/green-up-arrow.png";
import RedDownArrow from "../../../../assets/red-down-arrow.png.png";
import { objectIsEmpty } from "../../../../inc/Validation";
import SimpleLoading from "../../../Loading/SimpleLoading";

const TableDataInfo = ({key,condition,renderData}) => {
  return (
    <td key={key} style={{ paddingLeft: '30px'}}>
      <span className='me-1'>{condition ? renderData : '-'}</span>
    </td>
  )
}

const CommercialEvaluation = ({
  language,
  rfqHeader,
  vendors,
  setReasonForSelection,
  reasonForSelection,
  evaluationData,
  user,
  currencyList
}) => {
  const [publishedVendors, setPublishedVendors] = useState([]);
  const [scoreSummary, setScoreSummary] = useState([]);
  const [showLinesPopUpPreferred, setShowLinesPopUpPreferred] = useState(false);
  const [linesPopUpType,setLinesPopUpType]=useState('')
  const [showLinesPopUpAwardTo, setShowLinesPopUpAwardTo] = useState(false);
  const [isRecordNotFound, setIsRecordNotFound] = useState(false);
  const [vendorReplyId, setVendorReplyId] = useState();
  const [scorerReplyHeaderId, setScorerReplyHeaderId] = useState();
  const [loading,setLoading]=useState(false)

  const onTextAreaChange = (e) => {
    setReasonForSelection(e.target.value);
  };

  useEffect(() => {
    getPublishedVendors();
  }, []);

  useEffect(() => {
    getScoreSummary();
  }, [publishedVendors]);

  const getCurrencyFromId = (id) => {
    return currencyList?.filter((item) => item.currency_id == id)[0]?.currency_code
  }

  const getPublishedVendors = async () => {
    try {
      let api = Api;
      api.setUserToken();
      const response = await api
        .axios()
        .get(
          Settings.apiPurchaseRequisitionUrl +
          `/get_vendors_of_RFQ/${rfqHeader?.RFQ_id}`
        );

      if (response.data?.status === "success") {
        const publishedVendorsData = response?.data?.data?.filter(
          (vendor) => vendor.vendorreply_id !== null
        );
        setPublishedVendors(publishedVendorsData)
      } else {
        setPublishedVendors([])
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setPublishedVendors([])
    }
  };

  const getScoreSummary = async () => {
    try {
      let api = Api;
      api.setUserToken();
      let url =  Settings.apiPurchaseRequisitionUrl + `/score_summary/${rfqHeader?.RFQ_id}?evaluation_type=1`
      if(!user?.is_buyer){
        url += `&employee_id=${user?.employee_id}`
      }
      setLoading(true)
      const response = await api
        .axios()
        .get(url);

      if (response.data?.status === "success") {
        setScoreSummary(response.data.data);
        setLoading(false)
      } else {
        setIsRecordNotFound(true);
        setLoading(false)
      }
    } catch (error) {
      setIsRecordNotFound(true);
      setLoading(false)
    }
  };

  const handleLineViewPopUpPreferred = (lines,type) => {
    if (!lines) {
      setVendorReplyId(null);
      setScorerReplyHeaderId(null);
    }
    setVendorReplyId(lines.vendorreply_id);
    setScorerReplyHeaderId(lines.scorereplyheader_id);
    setTimeout(() => {
      setShowLinesPopUpPreferred(true);
      setLinesPopUpType(type)
    }, 200);
  };

  const handleLineViewPopUpAwardTo = (item) => {
    if (!item) {
      setVendorReplyId(null);
      setScorerReplyHeaderId(null);
    }
    setVendorReplyId(item.vendorreply_id);
    setScorerReplyHeaderId(item.scorereplyheader_id);
    setTimeout(() => {
      setShowLinesPopUpAwardTo(true);
    }, 300);
  };

  const getDisqualifyRows = () => {
    const rows = scoreSummary.map((item, itemId) => {
      const newRows = item.entries.filter((itemNew)=>itemNew.scoreRFQ_type===0)
      return newRows.map((entry) => {
        return {
          evaluator: entry.evaluator_name,
          disqualify: entry.disqualified,
          vendorreply_id: entry.rfq_vendorreply_id,
        };
      });
    });
    const resultArray = [];
    if (rows)

     rows.forEach((row) => {
       row.sort((a, b) => a.evaluator.localeCompare(b.evaluator));
      });
      // Iterate through the rows and organize the data
      rows[0]?.forEach((entry, index) => {
        const resultObj = {
          evaluator_name: entry.evaluator,
          disqualify: [],
          vendorreply_id: entry.rfq_vendorreply_id,
        };

        rows?.forEach((row) => {
          resultObj?.disqualify.push({
            [`disqualify`]: row[index]?.disqualify,
            [`vendorreply_id`]: row[index]?.vendorreply_id,
          });
        });

        resultArray.push(resultObj);
      });
      const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);

      resultArray.forEach((item) => {
       item.disqualify.sort((a, b) => {
        const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
        const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
        return indexA - indexB;
      });
    });

    return (
      <>
        {resultArray.map((item) => {
          return (
            <tr key={item.evaluator_name}>
              <td style={{ color: "#2D74E0" }}>
                Disqualify by {item.evaluator_name}
              </td>
              {item.disqualify.map((disqualify, id) => {
                return (
                  <td 
                    key={`itemdisqualify-${id + 1}`}
                    style={{
                      paddingLeft: "30px",
                    }}
                  >
                    <Checkbox
                      isChecked={disqualify.disqualify}
                      disableWithoutIcon={true}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  const getTotalScoreRows = () => {
    const rows = scoreSummary.map((item, itemId) => {
      const newRows = item.entries.filter((itemNew)=>itemNew.scoreRFQ_type===0)
      return newRows.map((entry) => {
        return {
          evaluator: entry.evaluator_name,
          total_score: entry.total_score,
          vendorreply_id: entry.rfq_vendorreply_id,
        };
      });
    });
    const resultArray = [];

    if (rows)

    rows.forEach((row) => {
      row.sort((a, b) => a.evaluator.localeCompare(b.evaluator));
    });
      // Iterate through the rows and organize the data
      rows[0]?.forEach((entry, index) => {
        const resultObj = {
          evaluator_name: entry.evaluator,
          scores: [],
        };

        rows?.forEach((row) => {
          resultObj.scores.push({
            [`total_score`]: row[index]?.total_score,
            [`vendorreply_id`]: row[index]?.vendorreply_id,
          });
        });

        resultArray.push(resultObj);
      });
      const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);

      resultArray.forEach((item) => {
       item.scores.sort((a, b) => {
        const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
        const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
        return indexA - indexB;
      });
      });

    return (
      <>
        {resultArray.map((item) => {
          return (
            <tr key={item?.evaluator_name}>
              <td style={{ color: "#2D74E0" }}>
                Total Score by {item.evaluator_name}
              </td>
              {item.scores.map((score, id) => {
                return (
                  <TableDataInfo key={`total_score${id + 1}`} condition={score?.total_score} renderData={score?.total_score?.toFixed(2)} />
                )
              })}
            </tr>
          );
        })}
      </>
    );
  };

const getFinalAverageRows = () => {
  const rows = scoreSummary?.map((entry) => {
    return {
      final_avg_score: entry.final_avg_score,
      vendorreply_id: entry.rfq_vendorreply_id,
    };
  });

  if (!rows) return null;

  // Sort the rows according to publishVendorsOrder
  const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);
  rows.sort((a, b) => {
    const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
    const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
    return indexA - indexB;
  });

  return (
    <tr>
      <td style={{ color: "#2D74E0" }}>Final Average Score</td>
      {rows.map((row) => (
        <TableDataInfo
          key={row.vendorreply_id}
          condition={row.final_avg_score}
          renderData={row.final_avg_score.toFixed(2)}
        />
      ))}
    </tr>
  );
};

const getBidValueRows = () => {
  const rows = scoreSummary?.map((entry) => {
    return {
      sum_line_amount: entry.sum_line_amount,
      vendorreply_id: entry.rfq_vendorreply_id,
    };
  });

  if (!rows) return null;

  // Sort the rows according to publishVendorsOrder
  const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);
  rows.sort((a, b) => {
    const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
    const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
    return indexA - indexB;
  });

  return (
    <tr>
      <td style={{ color: "#2D74E0" }}>Bid Value</td>
      {rows.map((row,itemId) => (
      <TableDataInfo
          key={`item-${row?.vendorreply_id}-${itemId + 1}`}
          condition={row?.sum_line_amount}
          renderData={Helper.formateCurrency(parseFloat(row.sum_line_amount).toFixed(2))}
        />
      ))}
    </tr>
  );
};

const getConvertedValueRows = () => {
  const rows = scoreSummary?.map((entry) => {
    return {
      converted_value: entry.converted_value,
      vendorreply_id: entry.rfq_vendorreply_id,
    };
  });

  if (!rows) return null;

  // Sort the rows according to publishVendorsOrder
  const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);
  rows.sort((a, b) => {
    const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
    const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
    return indexA - indexB;
  });

  return (
    <tr>
      <td style={{ color: "#2D74E0" }}>Converted Value</td>
      {rows.map((row,itemId) => (
        <TableDataInfo  key={`item-${row?.converted_value}-${itemId + 1}`} 
        condition={row?.converted_value} 
        renderData={Helper.formateCurrency(parseFloat(row.converted_value).toFixed(2))}/>
      ))}
    </tr>
  );
};

const getRankRows = () => {
  const rows = scoreSummary?.map((entry) => {
    return {
      ranking: entry.ranking,
      vendorreply_id: entry.rfq_vendorreply_id,
    };
  });

  if (!rows) return null;

  // Sort the rows according to publishVendorsOrder
  const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);
  rows.sort((a, b) => {
    const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
    const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
    return indexA - indexB;
  });

  return (
    
    <tr>
      <td style={{ color: "#2D74E0" }}>Ranking</td>
      {rows.map((row) => (
       <td
       key={`${row.ranking}${(row.id + 1)}`}
       style={{
         paddingLeft: "30px",
       }}
     >
         {row?.ranking ?
           <span className="me-1">
             <img
               src={(row.ranking > 5) ? GreenUpArrow : RedDownArrow}
               className="img-fluid"
               alt="arrow icons"
             />
           </span>
           : null}
         <span className="me-1">{row.ranking ?? "-"}</span>
     </td>
      ))}
    </tr>
  );
};



  const getLineRecommendationsVendorRows = () => {
    const rows = scoreSummary.map((item, itemId) => {
      const newRows = item.entries.filter((itemNew)=>itemNew.scoreRFQ_type===0)
      return newRows.map((entry) => {
        return {
          evaluator: entry.evaluator_name,
          total_score: entry.line_recommendations,
          scorereplyheader_id: entry.scorereplyheader_id,
          vendorreply_id: entry.rfq_vendorreply_id,
          line_recommendation: entry.line_recommendation,
        };
      });
    });

    const resultArray = [];

    if (rows)
     rows.forEach((row) => {
      row.sort((a, b) => a.evaluator.localeCompare(b.evaluator));
      });
      // Iterate through the rows and organize the data
      rows[0]?.forEach((entry, index) => {
        const resultObj = {
          evaluator_name: entry.evaluator,
          line_recommendations: [],
        };

        rows?.forEach((row) => {
          resultObj.line_recommendations.push({
            [`line_recommendation`]: row[index]?.line_recommendation,
            [`scorereplyheader_id`]: row[index]?.scorereplyheader_id,
            [`vendorreply_id`]: row[index]?.vendorreply_id,
          });
        });

        resultArray.push(resultObj);
      });

    const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);

    resultArray.forEach((item) => {
     item.line_recommendations.sort((a, b) => {
      const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
      const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
      return indexA - indexB;
    });
    });

    return (
      <>
        {resultArray.map((item) => {
          return (
            <tr key={item.evaluator_name}>
              <td style={{ color: "#2D74E0" }}>
                Recommended by {item.evaluator_name}
              </td>
              {item.line_recommendations.map((lines, id) => {
                return (
                  <td
                    key={`line_recommend${id + 1}`}
                    onClick={() => {
                      handleLineViewPopUpPreferred(lines,'recommended');
                    }}
                  >
                      <span className="me-1">
                        <img
                          src="/images/icons/lines-blue.svg"
                          className="img-fluid"
                          alt="Lines Icon"
                        />
                      </span>
                      <span className="me-1">{lines.line_recommendation}</span>
                      <span className="me-1"> Lines </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  }

  const getPreferredVendorRows = () => {
    const rows = scoreSummary.map((item, itemId) => {
      const newRows = item.entries.filter((itemNew)=>itemNew.scoreRFQ_type===1)
      return newRows.map((entry) => {
        return {
          evaluator: entry.evaluator_name,
          total_score: entry.line_recommendations,
          scorereplyheader_id: entry.scorereplyheader_id,
          vendorreply_id: entry.rfq_vendorreply_id,
          line_recommendation: entry.line_recommendation,
          preferred:entry?.preferred
        };
      });
    });

    const resultArray = [];

    if (rows)

    rows.forEach((row) => {
      row.sort((a, b) => a.evaluator.localeCompare(b.evaluator));
    });
      // Iterate through the rows and organize the data
      rows[0]?.forEach((entry, index) => {
        const resultObj = {
          evaluator_name: entry.evaluator,
          preferred: [],
        };

        rows?.forEach((row) => {
          resultObj.preferred.push({
            [`line_recommendation`]: row[index]?.line_recommendation,
            [`preferred`]:row[index]?.preferred,
            [`scorereplyheader_id`]: row[index]?.scorereplyheader_id,
            [`vendorreply_id`]: row[index]?.vendorreply_id,
          });
        });

        resultArray.push(resultObj);
      });
      const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);

      resultArray.forEach((item) => {
       item.preferred.sort((a, b) => {
        const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
        const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
        return indexA - indexB;
      });
      });

    return (
      <>
        {resultArray.map((item) => {
          return (
            <tr key={item.evaluator_name}>
              <td style={{ color: "#2D74E0" }}>
                Preferred by {item.evaluator_name}
              </td>
              {item.preferred.map((lines, id) => {
                return (
                  <td
                    key={`line_recommend${id + 1}`}
                    onClick={() => {
                      handleLineViewPopUpPreferred(lines,'preferred');
                    }}
                  >
                      <span className="me-1">
                        <img
                          src="/images/icons/lines-blue.svg"
                          className="img-fluid"
                          alt="Lines Icon"
                        />
                      </span>
                      <span className="me-1">{lines.preferred}</span>
                      <span className="me-1"> Lines </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  const getAwardToRow = () => {
    const rows = scoreSummary.map((item) => {
      const newRows = item.entries.filter((itemNew)=> itemNew.scoreRFQ_type===1 && itemNew.awarding)
      return newRows.map((entry) => {
        return {
          evaluator: entry.evaluator_name,
          total_score: entry.line_recommendations,
          scorereplyheader_id: entry.scorereplyheader_id,
          vendorreply_id: entry.rfq_vendorreply_id,
          award_to:entry.award_to
        };
      });
    });

    const resultArray = [];

    if (rows)
     rows.forEach((row) => {
      row.sort((a, b) => a.evaluator.localeCompare(b.evaluator));
      });
      // Iterate through the rows and organize the data
      rows[0]?.forEach((entry, index) => {
        const resultObj = {
          evaluator_name: entry.evaluator,
          award_to: [],
        };

        rows?.forEach((row) => {
          resultObj?.award_to?.push({
            [`award_to`]: row[index]?.award_to,
            [`scorereplyheader_id`]: row[index]?.scorereplyheader_id,
            [`vendorreply_id`]: row[index]?.vendorreply_id,
          });
        });

        resultArray.push(resultObj);
      });

      const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);

      resultArray.forEach((item) => {
       item.award_to.sort((a, b) => {
        const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
        const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
        return indexA - indexB;
      });
      });

    return (
      <>
        {resultArray?.map((item) => {
          return (
            <tr key={item.evaluator_name}>
              <td style={{ color: "#2D74E0" }}>
                Award By {item?.evaluator_name}
              </td>
              {item.award_to?.map((lines, id) => {
                return (
                  <td
                    key={`awardto${id + 1}`}
                    onClick={() => {
                      handleLineViewPopUpAwardTo(lines);
                    }}
                  >
                      <span className="me-1">
                        <img
                          src="/images/icons/lines-blue.svg"
                          className="img-fluid"
                          alt="Lines Icon"
                        />
                      </span>
                      <span className="me-1">{lines.award_to}</span>
                      <span className="me-1"> Lines </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  const getBidCurrencyVendorRows = () => {
    const rows = scoreSummary.map((item, itemId) => {
      const newRows = item.entries.filter((itemNew)=>itemNew.scoreRFQ_type===0)
      return newRows.map((entry) => {
        return {
          evaluator: entry.evaluator_name,
          total_score: entry.line_recommendations,
          scorereplyheader_id: entry.scorereplyheader_id,
          vendorreply_id: entry.rfq_vendorreply_id,
          currency_id: entry.currency_id
        };
      });
    });

    const resultArray = [];

    if (rows)

    rows.forEach((row) => {
      row.sort((a, b) => a.evaluator.localeCompare(b.evaluator));
    });
      rows[0]?.forEach((entry, index) => {
        const resultObj = {
          evaluator_name: entry.evaluator,
          currency_id: [],
        };

        rows?.forEach((row) => {
          resultObj?.currency_id?.push({
            [`currency_id`]: row[index]?.currency_id,
            [`scorereplyheader_id`]: row[index]?.scorereplyheader_id,
            [`vendorreply_id`]: row[index]?.vendorreply_id,
          });
        });

        resultArray.push(resultObj);
      });
      const publishVendorsOrder = publishedVendors.map((vendor) => vendor.vendorreply_id);

      resultArray.forEach((item) => {
       item.currency_id.sort((a, b) => {
        const indexA = publishVendorsOrder.indexOf(a.vendorreply_id);
        const indexB = publishVendorsOrder.indexOf(b.vendorreply_id);
        return indexA - indexB;
      });
      });

    return (
      <>
        {resultArray.map((item) => {
          return (
            <tr key={item.evaluator_name}>
              <td style={{ color: "#2D74E0" }}>
                Bid Currency
              </td>
              {item.currency_id.map((lines, id) => {
                return (
                  <td
                    key={`currency_id${id + 1}`}
                    style={{paddingLeft:'30px'}}
                  >
                      <span className="me-1">{getCurrencyFromId(lines.currency_id)}</span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  const getHeaderRow = () => {
    const columnHeadersData = publishedVendors?.filter((vendor) => {return scoreSummary.some((summary) => summary.rfq_vendorreply_id=== vendor.vendorreply_id)}).map((vendor) => {return vendor.vendor_name});

    return (
      <>
        {columnHeadersData.map((item) => {
          return <th key={item}> {item} </th>;
        })}
      </>
    );
  };

  return (
    <div>
      {loading ? <SimpleLoading /> : <Collapse
        className="mb-4"
        open={true}
        title={Helper.getLabel(
          language,
          "commercial_evaluation",
          "Commercial Evaluation"
        )}
      >
        {isRecordNotFound ? (
          <div>{rfqHeader?.rfq_status===6?'No Record Found':'Commercial Evaluation is not initiated yet !'}</div>
        ) : (
          <>
            <div className="common_scoring_summary_table_wrapper">
              <div className="common_scoring_summary_table">
                <div className="table-wrapper">
                  {scoreSummary && (
                    <table className="table-view">
                      <th> Line </th>
                      {getHeaderRow()}
                      {getDisqualifyRows()}
                      {getLineRecommendationsVendorRows()}
                      {getPreferredVendorRows()}
                      {getAwardToRow()}
                      {getTotalScoreRows()}
                      {getFinalAverageRows()}
                      {getRankRows()}
                      {getBidValueRows()}
                      {getBidCurrencyVendorRows()}
                      {getConvertedValueRows()}
                    </table>
                  )}
                </div>
              </div>
            </div>

            <div className="vendor_reason mt-4">
              <h2 className="vendor_selection_text">
                Reason <span className="asterik_sign" style={{color:"#D73535"}}>*</span>
              </h2>
              <textarea
                onChange={onTextAreaChange}
                value={reasonForSelection}
                name="reason_for_selection"
                id="reason_for_vendor_selection"
                disabled={  objectIsEmpty(evaluationData) || evaluationData?.submitted}
                className="descriptionTextarea"
                maxLength={80}
              />
            </div>
          </>
        )}
      </Collapse>}

      {showLinesPopUpPreferred && (
        <TechnicalSelectedLinesModalPreferredColumn
          rfqHeader={rfqHeader}
          language={language}
          setShowSelectedLinesModal={setShowLinesPopUpPreferred}
          vendors={vendors}
          vendorreplyId={vendorReplyId}
          scoreReplyHeaderId={scorerReplyHeaderId}
          type={linesPopUpType}
          evaluationData={evaluationData}
          getScoreSummary={getScoreSummary}
          user={user}
        />
      )}
      {showLinesPopUpAwardTo && (
        <TechnicalSelectedLinesModalAwardToColumn
          rfqHeader={rfqHeader}
          language={language}
          setShowSelectedLinesModal={setShowLinesPopUpAwardTo}
          vendors={vendors}
          vendorreplyId={vendorReplyId}
          scoreReplyHeaderId={scorerReplyHeaderId}
          getScoreSummary={getScoreSummary}
          evaluationData={evaluationData}
        />
      )}
    </div>
  );
};

export default CommercialEvaluation;
