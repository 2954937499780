import React, { Fragment } from 'react'
import { connect } from 'react-redux'

const RFQBiddingRequirement = (props) => {
  return (
    <Fragment>
        <div className='scope_of_work ps-3 pe-3'>
            <h2 className='border-bottom-h2'>Scope of Work</h2>
            <div className='request_for_quotation_content w-100 mt-2'></div>
        </div>
        <div className='material_not_inc ps-3 pe-3 mt-4'>
            <h2 className='border-bottom-h2'>Materials and Services not Included</h2>
            <div className='request_for_quotation_content w-100 mt-2'></div>
        </div>
        <div className='agreement_terms ps-3 pe-3 mt-4 mb-4'>
            <h2 className='border-bottom-h2'>Agreement Terms</h2>
            <div className='request_for_quotation_content w-100 mt-2'></div>
        </div>
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
  

export default connect(mapStateToProps) (RFQBiddingRequirement) 