import React, {useState, useEffect, Fragment, useRef} from 'react'; 
import Settings from '../../../inc/Settings'; 
import { connect, useSelector,useDispatch } from 'react-redux'; 
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import '../User Container/UserContainer.css'
import Button from '../../inc/Button';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import LinkBtn from '../../inc/LinkBtn'; 
import $ from 'jquery'
import Collapse from '../../inc/Collapse';
import DropDownInput from '../../Forms/Dropdown';
import Delete from '../../../assets/delete.svg'
import Plus from '../../../assets/add-new.svg'
import { gridIdActions } from '../../../store/grid-id';
import { useNavigate } from 'react-router-dom';
import Popup from '../../Popup/Popup';
import AgGrid from '../../grid/ag/ag-grid';
import Input from '../../Forms/Input'; 
import Treeview from '../../Treeview/Treeview';
import FormValidator from '../../../inc/FormValidator';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import Checkbox from '../../Forms/Checkbox';
import SimpleLoading from '../../Loading/SimpleLoading';
import { checkIfArrayIsEmpty, filterByReference, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';


// Lynkaz- User SYSTEM -
let defaultUserRoles = []
let currentSearchParam = '';
let gridObject;
 
const NewUser = ( props) => {
  // Local State Of Component / User ---
  // declaration of variables to hold data for usage --
  let item_id = null
  // Loader Component --
  const [loading, setLoading] = useState(false);
  const employeeAllData = useRef([]); 
  // Main User State, for holding data 
  const [user, setUser] = useState({
    first_name: null,
    last_name: null,
    middle_name: null,
    user_name: null,
    enable: true,
    is_deleted:false,
    SID: null,
    network_domain: null,
    network_alias: null,
    language_id: null,
    usertype: null,
    employee_id: null
  }) 
  // Navigation Hook, for redirecting user to list page after success --
  const navigate = useNavigate()
  // Dispatch Hook, for dispatching redux, functions --
  const dispatch = useDispatch()
  // Showing Roles List / Grid List Of All Roles ---
  const showRolesGrid = useSelector(state => state.gridIdFlow.rolesListShow)

  const wrapperDiv = useRef();
  const [height, setHeight] = useState(0);

  // Dropdown States --- 
  const [selectedRoles, setSelectedRoles] = useState([]) 
  const [languages, setLanguages] = useState([])
  const [userType, setUserType] = useState([])
  const [employees, setEmployees] = useState([])
 
  //API Reduction Called 
  let languagesListCalled = false 
  let userTypeCalled = false
  let employeeCalled = false
  //End
  
  // Component Level Renders / First Load (Component) ---

  useEffect(() => {  
    get_languages_list() 
    get_user_type_list()
    getEmployeeList()
  }, [])

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);
  // -----

  // Frontend Validation System ---
  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'user_name', displayName:Helper.getLabel(props.language,'user_name','User Name'),types:['Required']}, 
      {name:'first_name', displayName:Helper.getLabel(props.language,' first_name','First Name'),types:['Required']},  
      {name:'last_name', displayName:Helper.getLabel(props.language,'last_name','Last Name'),types:['Required']},
      // {name:'language_id', displayName:Helper.getLabel(props.language,'language','Language'),types:['Required']},
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()

  // -----
  
  // Dropdown API Calls ----
  
  const get_user_type_list = () => {
    if(userTypeCalled){
      return;
    }
    let api = Api
    userTypeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/usertype',{}).then(function(res){
      if(res.data.status==='success'){   
        let USERS_TYPE_API_LIST = []
        res.data.data.forEach(item => {  
          USERS_TYPE_API_LIST.push({
           value: item.id,
           label: item.name
         })
        })
        setUserType(USERS_TYPE_API_LIST)
      }
    }).catch((res) => { 
      console.log(res)
    })
  }

  const getEmployeeList = () => {
    if(employeeCalled) {
      return
    }
    let api = Api
    employeeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/employee_dropdown',{}).then(function(res){
      if(res.data.status==='success'){   
        let Employee_API_LIST = []
        res.data.data.forEach(item => {  
          Employee_API_LIST.push({
           value: item.employee_id,
           label: `${item.code} - ${item.displayname}`
         })
        })
        setEmployees(Employee_API_LIST)
      }
    }).catch((res) => { 
      console.log(res)
    })
  }

  const get_languages_list = () => {
    if(languagesListCalled){
      return;
    }
    let api = Api
    languagesListCalled = true
    api.setUserToken()
    api.axios().get(Settings.loginUrl+'/language',{}).then(function(res){
      if(res.data.status==='success'){   
        let Languages_API_LIST = []
        res.data.data.forEach(item => {  
          Languages_API_LIST.push({
           value: item.language_id,
           label: item.language
         })
        })
        setLanguages(Languages_API_LIST)
      } 
    }).catch((res) => { 
      console.log(res)
    })
  }
 
  // ----

   // mapping functions,

  const mapEmployeeValue = (obj) => { 
    return {  id: obj.employee_id , text: `${obj.code} - ${obj.displayname}`}
  }

  //--

  // Dropdown Handlers ------

  const onChangeUser = (event) => {
    let User = event.target.value
    setUser((prevState) => {
      return {...prevState, user_name: User}
    })
  }

  const LanguageHandler = (event) => {
    let lang = event.target.value
    setUser((prevState) => {
      return {...prevState, language_id: document.getElementById('language_id').value}
    })
  }

  const EmployeeHandler = (event) => {
    let emp = event.target.value
    setUser((prevState) => {
      return{
        ...prevState,
        employee_id: emp
      }
    })
  }
  
  const onChangefName = (event) => {
    let FirstName = event.target.value
    setUser((prevState) => {
      return {...prevState, first_name: FirstName}
    })
  }
  
  const CloseHandler = (event) => { 
    dispatch(gridIdActions.rolesGridShow({payload: false})) 
    $('html').removeClass('rs_popup_open');
  }

  const onChangeNetwork = (event) => {
    let NetworkDomain = event.target.value
    setUser((prevState) => {
      return {...prevState, network_domain: NetworkDomain}
    })
  }

  const onChangeMName = (event) => {
    let MiddleName = event.target.value
    setUser((prevState) => {
      return {...prevState, middle_name: MiddleName}
    })
  }

  const onChangelName = (event) => {
    let LastName = event.target.value
    setUser((prevState) => {
      return {...prevState, last_name: LastName}
    })
  }
  
  const onChangeEnable = (name, value) => { 
    if(value == "on"){
      setUser((prevState) => {
        return {...prevState, [name]: true}
      })
    }else {
      setUser((prevState) => {
        return {...prevState, [name]: false}
      })
    }
  }

  // ---

  // Grid Things ----

  const Columns_Headings_Roles = [
    {field:'name', headerName: 'Role Name'},
    {field:'description', headerName: 'Role Description'}, 
  ]
 
  const SelectNodeHandler = (data) => {
    let currentNode = data.api.getSelectedRows(); 
    if(currentNode.length <=0 || !currentNode[0].role_id){
      $('.rs_setvalue_btn').addClass('ctl-dissable');
      $('.visible_on_select_item').addClass('ctl-dissable');
    }else{
      $('.rs_setvalue_btn').removeClass('ctl-dissable');
      $('.visible_on_select_item').removeClass('ctl-dissable');
    } 
  }

  const Callbuttons = () => {
    return <Button title='Select' className='rs_setvalue_btn select_role_btn visible_on_select_item ctl-dissable' onClick={SelectedRolesList} />;
  }

  const AssignRoles = () => {  
    dispatch(gridIdActions.rolesGridShow({payload: true})) 
  }
 
  const DeleteRole = () => {
    if(item_id){ 
      let newData=  [];
      let removeRoleFromGridArray = []
      selectedRoles.forEach(item => {
        if(item_id.id != item.id){
          newData.push(item)
        }
      })
      defaultUserRoles.forEach((element) => {
        if(item_id.id!== element.role_id){
          removeRoleFromGridArray.push(element)
        }
      })
      defaultUserRoles = removeRoleFromGridArray
      setSelectedRoles(newData)
    } 
  }

  const onItemClicked = (itemId) => { 
    item_id = itemId
  }
  
  const checkRoles = (array, itemId) => {
    let found;
    array.forEach(element => {
      if(element.role_id !== itemId){
        found = true
      }else {
        found = false
      }
    })
    return found
  }

  const SelectedRolesList = () => { 
    let UPDATED_ROLES = [];
    let roleArray = [];
    let dataListFormPopup = []; 
    let HierarchyRoles = []; 
    dataListFormPopup = gridObject.api.getSelectedRows();
    dataListFormPopup.forEach((element) => {
      UPDATED_ROLES.push({ 
        "name": element.name,
        "role_id": element.role_id,
        "user_id": null
      })
    })
    for(let i=0; i < UPDATED_ROLES.length; i++){
      if(checkRoles(UPDATED_ROLES, i)){
        roleArray.push(UPDATED_ROLES[i])
      }
    } 
    roleArray.forEach(element => {
      HierarchyRoles.push({
        id: element.role_id,
        title:element.name,
        showChild:false,
        items:[]
      })
      defaultUserRoles.push({
        role_id:element.role_id
      })
    })
    if(checkIfArrayIsEmpty(HierarchyRoles)) return;  
    setSelectedRoles( [].concat(selectedRoles, HierarchyRoles) )   
    CloseHandler()
  }

  // ----

  const handleFetchData = (response) => {
    let newArr = filterByReference(response?.data?.data, defaultUserRoles);
    return {
      rowData: newArr
      ?.filter(val => val.role_id !== "63" && 
        (val.name.includes("Lynkaz -") || 
        val.name === "System User" || 
        val.name === "Workflow User" || 
        val.name === "Lynkaz Demo Role") 
      )
      ?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: newArr
      ?.filter(val => val.role_id !== "63" && 
        (val.name.includes("Lynkaz -") || 
        val.name === "System User" || 
        val.name === "Workflow User" || 
        val.name === "Lynkaz Demo Role")
      )?.length
    };
  };
 
  // Module / Component ----

  const UsersModule = () => {
    return (
      <Fragment>
        <Collapse title={Helper.getLabel(props.language,11217,'General')} open={true}> 
          <section className='users-fields'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row users-fields-form gy-0 mt-2 mb-2'>
                    <div className='col-lg-3 col-md-3'> 
                      <Input maxLength={80} label={Helper.getLabel(props.language,'user_USER_SETUP',"User email")} name='text' required={true} hasError={validator.hasError('user_name')} onChange={onChangeUser} value={user.user_name} />
                    </div>
                
                    <div className='col-lg-3 col-md-3'> 
                      <Input maxLength={255} label={Helper.getLabel(props.language,'network_domain_USER_SETUP',"Network domain")} type='text' onChange={onChangeNetwork} value={user.network_domain} />
                    </div>
                
                    <div className='col-lg-3 col-md-3'> 
                      <Input maxLength={50} label={Helper.getLabel(props.language,'first_name_USER_SETUP',"First name")} type='text' hasError={validator.hasError('first_name')} required={true}  onChange={onChangefName} value={user.first_name} />
                    </div>

                    <div className='col-lg-3 col-md-3'> 
                      <Input maxLength={50} label={Helper.getLabel(props.language,'middle_name_USER_SETUP',"Middle name")} type='text' onChange={onChangeMName} value={user.middle_name} />
                    </div> 
                  </div>
        
                  <div className='row users-fields-form gy-0 mb-2'>
                    <div className='col-lg-3 col-md-3'> 
                      <Input maxLength={50} label={Helper.getLabel(props.language,'last_name_USER_SETUP',"Last name")} type='text' required={true} hasError={validator.hasError('last_name')}  onChange={onChangelName} value={user.last_name} />
                    </div> 

                    <div className='col-lg-3 col-md-3 mt-auto'> 
                      <DropDownInput
                        apiUrl={Settings.apiOrgUrl + "/employee_dropdown"}
                        currentSearchTerm={currentSearchParam}
                        mapFunction={mapEmployeeValue}
                        allDataRef={employeeAllData}
                        label={Helper.getLabel(
                          props.language,
                          "employee_USER_SETUP",
                          "Employee"
                        )}
                        placeHolder={`${Helper.getLabel(
                          props.language,
                          'please_select',
                          'Select employee',
                          )}`
                        }
                        pagination={true}
                        options={employees}
                        onChange={EmployeeHandler}
                        value={user.employee_id}
                        id="employee_id"
                        name='employee_id'
                      /> 
                    </div>
              
                    <div className='col-lg-3 col-md-3 mt-auto'> 
                      <DropDownInput  placeHolder={'Select default language'} label={Helper.getLabel(props.language,'default_language_USER_SETUP',"Default language")} required={true} isDisable={true} options={languages} hasError={validator.hasError('language')} onChange={LanguageHandler} value={languages?.find(val => val.label == "English")?.value || null} id='language_id' />
                    </div>

                    <div className='col-lg-2 col-md-2 mt-4 d-flex gap-3'>
                      <Checkbox type='checkbox' hasError={validator.hasError('enable')} name="enable" className='me-4' onChange={onChangeEnable} isChecked={user.enable} label={Helper.getLabel(props.language,'enable_USER_SETUP',"Enable")} /> 
                      <Checkbox type='checkbox' hasError={validator.hasError('is_buyer')} name="is_buyer" className='me-4' onChange={onChangeEnable} isChecked={user.is_buyer} label={Helper.getLabel(props.language,'is_buyer',"is Buyer")} /> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Collapse> 
        <div className='mt-4'>
          {UsersRolesGridModule()}
        </div>
        {PopupGridWithRoles()}
      </Fragment>
    )
  }

  const UsersRolesGridModule = () => {
    return (
      <Collapse title='Users Roles' open={true}>
        <section className='user-roles-section'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 gy-2'>
                <div className='roles-buttons d-flex justify-content-start border-bottom'>
                  <button type='button' className='add-user-role' onClick={AssignRoles}><span className='me-1'><img src={Plus} alt="plus-icon" className='img-fluid mb-1 roles-icons' /></span> {Helper.getLabel(props.language,'assign_role_USER_SETUP',"Assign Role")}</button>
                  <button type='button' className='delete-role' onClick={DeleteRole}><span className='me-1'><img src={Delete} alt="delete-icon" className='img-fluid mb-1 roles-icons' /></span> {Helper.getLabel(props.language,'delete_role_USER_SETUP',"Delete Role")}</button>
                </div>
              </div>
              <div className='col-lg-4'> 
                <div className='user-treeview-hierarchy ps-4 pt-2'>
                  <Treeview items={selectedRoles} onClick={onItemClicked} /> 
                </div>
              </div>
            </div>
          </div>
        </section>
      </Collapse>
    )
  }

  const PopupGridWithRoles = () => {
    return (
      <Fragment>
        {showRolesGrid && 
          <div className='search_grid_height'> 
            <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
              <div className='rs_create_users_grid'> 
                <AgGridNew
                  apiUrl={Settings.apiBaseUrl+'/role'}  
                  pagination={false}
                  columnDefs={Columns_Headings_Roles}
                  fetchData={handleFetchData}
                  hideAddBtn={true}
                  hideDeleteBtn={true}
                  onGridReady={(params) => gridObject = params}
                  height={500}
                  afterDeleteBtns={<Callbuttons />}
                  onSelectionChanged={SelectNodeHandler}
                  gridId={"rs_popup_open_users_roles"}
                />
              </div>
            </Popup>
          </div>
        }
      </Fragment>
    )
  }

  // Execute API

  const SaveHandler = () => { 
    let validData = {
      ...user
    }
 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }
 
      setLoading(true)
      let api = Api
      api.setUserToken()   
      api.axios().post(Settings.apiUrl+'/user',{
        first_name: user.first_name,
        last_name: user.last_name,
        middle_name: user.middle_name,
        user_name: user.user_name,
        enable: user.enable,
        is_buyer:user.is_buyer,
        is_deleted:false,
        SID: user.SID,
        network_domain: user.network_domain !== "" ? user.network_domain : null,
        network_alias: user.network_alias,
        language_id: languages?.find(val => val.label == "English")?.value || null,
        usertype: userType ? userType.find(val => val.label == "Internal").value : null,
        employee_id: user.employee_id !== null ? parseInt(user.employee_id) : null
      }).then(function(res){
      if(res.data.status==='success'){ 
        let UPDATED_ROLES_IDS = []
        selectedRoles.forEach((element) => {
          element.user_id = res.data.id
          UPDATED_ROLES_IDS.push({
            "role_id": element.id,
            "user_id": res.data.User_id
          })
        })
        api.axios().post(Settings.apiBaseUrl+'/assign_role', UPDATED_ROLES_IDS).then(function(res){
        if(res.data.status==='success'){ 
          setLoading(false)
          defaultUserRoles = []
          navigate(-1)
          Helper.alert(`User Created & ${selectedRoles?.length === 0 ? 'basic role was assigned automatically!' : 'Roles assigned!'}`)
        }
      }).catch((res) => { 
        setLoading(false)
        getPopupMessageBasedOnStatus(res) 
      }) 
    }
    }).catch((res) => { 
      setLoading(false)
      getPopupMessageBasedOnStatus(res)
    })
  }
 
  // Security System ---

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.administrationForms.users.users_create_screen)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  }

  // -----

  return ( 
    <Fragment>
      { loading ? <OverlayLoader /> : null }
      <div className='container-fluid'>
        <div ref={wrapperDiv}> 
          <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(props.language,'new_user',"New User")}>
            <LinkBtn to={-1} className='black-btn-style' isActive= {false} title={Helper.getLabel(props.language,'close',"Close")} />
            <Button isDisable={!security.canCreate(frontendIds.administrationForms.users.users_create_screen_save_button)} isActive= {false} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} className="rfq_save_btn" />
          </NavigationHeder>
        </div>
 
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}> 
          { (UsersModule()) }
        </div>
      </div> 
    </Fragment>
  )
}

  const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.administrationForms.users.users_create_screen
  }
  
  
  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(NewUser, SecurityOptions))))