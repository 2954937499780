import React, { Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import ButtonNew from '../../inc/ButtonNew';
import RsDataLoader from '../../inc/RsDataLoader';
import RsWithRouter from '../../inc/RsWithRouter'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { useNavigate } from 'react-router-dom';
import TaxAuthorityModal from './TaxAuthorityModal';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../inc/Alert';

// dataLoaderProps.vendors -> vendors array

let gridApi = null;

const TaxAuthority = ({ language, security }) => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    const navigate = useNavigate(); 
 
    const onCellClicked = event => {
        if (event.colDef.field === 'code') {
            navigate('/tax/edit-tax-authority/' + event?.data?.taxauthority_id);
        }
    };

    const deleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delTax_ID = element?.data?.taxauthority_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/taxauthority/${delTax_ID}`)
            const rowNode = gridApi?.getRowNode(delTax_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi.applyServerSideTransaction(transaction)
            }, 200)
            gridApi.refreshServerSide({ purge: true })
            gridApi?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const gridColHeaders = [
        { field: 'code', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, 'code', 'Code'), cellRenderer: (params) => gridLinkValueFormat(params)},
        { field: 'name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, 'name', 'Name')},
        { field: 'vendor_name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(language, 'vendor', 'Vendor') }
    ];

    if(!security.canView(Gui_id_list.setupForms.tax_authority.tax_authority_main)){ 
        return <Fragment>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    return (
        <Fragment> 
            <div className='container-fluid'>
                <NavigationHeder hideMoreBtn={true} backUrl="/setup" title={Helper.getLabel(language, 'tax_authority', 'Authority')}>
                    <ButtonNew isDisable={!security.canCreate(Gui_id_list.setupForms.tax_authority.tax_authority_main_create_button)} onClick={() => setShowCreateModal(true)} />
                </NavigationHeder>

                <AgGridNew
                    apiUrl={Settings.apiUrl + '/taxauthority'}
                    pagination={true}
                    columnDefs={gridColHeaders}
                    hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.tax_authority.tax_authority_main_delete_button)}
                    hideAddBtn={true}
                    height={500}
                    uniqueField={'taxauthority_id'}
                    onCellClicked={onCellClicked}
                    handleDeleteSelectedRows={deleteHandler}
                    onGridReady={(params) => gridApi = params?.api }
                    gridId={"tax_grid"}
                />
            </div>
            {showCreateModal && <TaxAuthorityModal setShowCreateModal={setShowCreateModal} />}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        language: state.language
    };
};
 
const SecurityOptions = {
    gui_id: Gui_id_list.setupForms.tax_authority.tax_authority_main
};

export default connect(mapStateToProps) ((( ApplySecurityRoles( TaxAuthority, SecurityOptions)))); 