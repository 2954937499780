import Droppable from 'droppable';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';   

const ImageUpload = ({file, setImageFile}) => {  
  let droppable = null;
  let id = 'file_uploader_canvas'; 

  useEffect(() => { 
    let selector = document.getElementById(id)
    if(selector){ 
    if(!droppable){
      droppable = new Droppable({
        element: document.querySelector('#'+id)
      })
      droppable.setAcceptsMultipleFiles(false)
      droppable.onFilesDropped((files) => {
        if(files[0]){ 
          uploadFiles(files[0])
        }
      });
      } 
    }
  }, [])
 
  const ResetImagePath = () => {
    setImageFile((prevState) => {
      return {
        ...prevState,
        image: null
      }
    })
  }

  const uploadFiles = (files) => {
    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = () => {  
      setImageFile((prevState) => {
        return {
          ...prevState,
          image: reader.result
        }
      })
    }
  }  
  
  return (
    <section className='image_section_employee'>
      <div className='container-fluid'>
        <div className='row mb-4'>
          <div className='image_elements d-flex justify-content-between align-items-center' style={{borderBottom: '1px solid #DEE8F4'}}>
            <h2 className='ms-3 mt-2' style={{borderBottom: '3px solid #FC5E24', marginBottom: 0}}>Upload Image</h2>
            <button onClick={ResetImagePath}>
              <span><img src='/images/icons/edit-black.svg' className='img-fluid' /></span> 
            </button> 
          </div>
        </div>  
        <div style={{display: !file?.includes('blank-profile') && file !== null ? 'block' : 'none'}}>
          <div className='row d-flex justify-content-center align-items-center'>
            <div className={`image_uploader_wrapper`} style={{backgroundImage: `url("${file}")`}}></div> 
          </div>
        </div> 
        <div style={{display: file?.includes('blank-profile') || file === null ? 'block' : 'none'}} className='file_uploader mt-5' id={id}>
          <div className='file_uploader_canvas'>
            <img src="/images/icons/file-upload.svg" className='file_uploader_icon' alt="Upload File" />
            <div className='uploader_help_text'>
              <h3>Select a file or drag and drop here</h3>
              <p>JPG, PNG or PDF</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
} 
 
const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    language:state.language
  }
}

export default connect (mapStateToProps) (ImageUpload);