import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import { calculateSmallestLargestNumbersOnDigits, checkSegmentLength } from '../../../inc/Validation'; 
import Collapse from '../../inc/Collapse'; 
import SimpleLoading from '../../Loading/SimpleLoading';
import AgGridNew from '../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
  
const SegmentsModule = ({ segmentsGrid, setLoadingSegmentsGrid, setSegmentsGrid, loadingSegmentsGrid, language, setDisplayFormat, segmentList, setSegments}) => {
  const onCellValue = (event) => {   
    let updateSegmentsGrid = [...segmentsGrid];
    const segmentValueLength = event?.data?.segment_value?.length || 0;
 
    if (segmentValueLength > 15) { 
      Helper.alert('Segment value cannot exceed 15 digits in total', 'error');
      event.data.segment_value = ''; 
      event?.node?.setDataValue("segment_length", 0); 
      return;
    }
 
    const existingSegmentIndex = updateSegmentsGrid.findIndex(segment => segment?.segment_id === event?.data?.segment_id);
    if (existingSegmentIndex !== -1) { 
      updateSegmentsGrid[existingSegmentIndex] = event.data;
    } else { 
      updateSegmentsGrid.push(event.data);  
    }
  
    const checkSegmentsValidation = checkSegmentLength(updateSegmentsGrid);
    if (checkSegmentsValidation?.result) { 
      Helper.alert('Segment value cannot exceed 15 digits in total', 'error');
      event.data.segment_value = ''; 
      event?.node?.setDataValue("segment_length", 0); 
      return;
    }
  
    event?.node?.setDataValue("segment_length", segmentValueLength); 

    if (event.data.segment_type === '3') {
      const { smallest, largest } = calculateSmallestLargestNumbersOnDigits(segmentValueLength);
      
      setSegments((prevState) => {
        return {
          ...prevState,
          min_value: smallest,
          max_value: largest,
          next_value: smallest
        };
      });

      const regex = /^[#]+$/;
      if (!regex.test(event?.data?.segment_value)) {
        Helper.alert('Please provide # sign only', 'error');
        event.data.segment_value = '';
        return;
      }  
    }
  }

  const onRowValueChangedHandler = async (event) => {
    if (event?.data?.segment_id) {
      onUpdateCurrentSegments(event) 
    } else { 
      onAddNewSegment(event) 
    } 
  }
 
  const onAddNewSegment = (event) => { 
    setLoadingSegmentsGrid(true);

    setSegmentsGrid((prevState) => {
      return [...prevState, { ...event?.data, segment_id: Math.floor(Math.random() * 749) + 4 }]
    });

    setTimeout(() => {
      setLoadingSegmentsGrid(false)
    }, 200);
  }

  const onUpdateCurrentSegments = (event) => { 
    setLoadingSegmentsGrid(true);
 
    setSegmentsGrid((prevState) => {
      const existingIndex = segmentsGrid?.findIndex(item => item?.segment_id == event?.data?.segment_id);
      if (existingIndex !== -1) { 
        return segmentsGrid?.map((item, index) => index === existingIndex ? event?.data : item);
      } else { 
        return [...prevState, event.data];
      }
    });

    setTimeout(() => {
      setLoadingSegmentsGrid(false)
    }, 200);
  }
 
  const DeleteSegments = (event) => { 
    setLoadingSegmentsGrid(true); 

    let segmentsRecords = [...segmentsGrid];
    let segmentsIndex = segmentsRecords?.findIndex(val => val.segment_id === event?.data?.segment_id);
     
    if (segmentsIndex !== -1) {
      segmentsRecords.splice(segmentsIndex, 1);
    } 
   
    setSegmentsGrid(segmentsRecords);  

    setTimeout(() => {
      setLoadingSegmentsGrid(false); 
    }, 200);
  } 

  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: segmentsGrid?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: segmentsGrid?.length
      })
    },
  };

  const onSegmentsNumberSequenceReady = (params) => {
    if(params){
      setTimeout(()=>{ 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200)
    };
  };
 
  /* Grid Column Headers */

  const gridColumnHeaders = [
    {
      field: 'segment_type',
      headerName: Helper.getLabel(language,'segment','Segment'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      minWidth:180, 
      cellEditorParams: {
        values: segmentList 
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter: false,
      sortable: false
    },
    {field: 'segment_value', filter: false, sortable: false, editable: true, headerName: Helper.getLabel(language,'value','Value')},
    {field: 'segment_length', filter: false, sortable: false, headerName: Helper.getLabel(language,'length','Length'), editable: false}
  ];

  return (
    <Collapse open={true} title={Helper.getLabel(language,'number_seq-003','Segments')}>
      <div className='rs_new_number_sequence'> 
        {
          loadingSegmentsGrid ? <div style={{height: 500}}><SimpleLoading /></div> : 
          <AgGridNew 
            pagination={false}
            onRowValueChanged={onRowValueChangedHandler}
            onCellValueChanged={onCellValue}
            handleDeleteSelectedRows={DeleteSegments}
            onGridReady={onSegmentsNumberSequenceReady}
            columnDefs={gridColumnHeaders}
            height={500}
            uniqueField={'segment_id'}
            rowType="single"
            gridId={'rs_new_number_sequence'}
          />
        }
      </div>
    </Collapse>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (SegmentsModule);