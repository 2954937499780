import React, { Component } from "react";
import Success from "../../components/PurchaseRequisition/Checkout/Success";

// "/purchase-requisition/shop"
export default class SuccessScreen extends Component {
  render() {
    return (
      <Success
        backUrl={-1}
        title="Shop > Requisition Submitted Successfully"
        hideMoreBtn={true}
      />
    );
  }
}
