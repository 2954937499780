import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoaded:false,
    taxgroup:[]
}
const TaxGroupReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_TAXGROUP){
        state = {
            ...state,
            isLoaded:true,
            taxgroup:actions.payload
        }
    }
    return state;
}
export default TaxGroupReducer;