import React, { Fragment, useEffect, useState } from 'react';
import SimpleLoading from '../../../Loading/SimpleLoading';  
import { connect } from 'react-redux';
import TechConsolidationHeader from './TechConsolidationHeader';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { ArrayInitializerOfObjects, checkIfArrayIsEmpty, isEmptyObject, transformSplitArrayData, multipleUserScorings } from '../../../../inc/Validation';
import Helper from '../../../../inc/Helper';
 
const TechEvaluationConsolidated = (props) => {  
    const [technicalEvaluation, setTechnicalEvaluation] = useState({});  

    const [loading, setLoading] = useState(true);

    let techCalled = false; 

    useEffect(() => { getTechnicalEvaluationConsolidationData(props?.rfq_scores_data?.scorerfq_id) }, [props?.rfq_scores_data]);
     
    // API calls functions,

    const getTechnicalEvaluationConsolidationData = (scorerfq_id) => {
        if((techCalled && !props.rfq_id) || !scorerfq_id) {
            setLoading(false);
            return;
        }; 

        techCalled = true
        // reply_id send as of props
        let api = Api
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+`/get_consolidated_vendorscoring/${props.rfq_id}?scoreRFQ_id=${scorerfq_id}`,{}).then(async function(res){
            if(res.data.status==="success"){
                let VendorsList = []  
                let { criteriaArray, RFQ_id } = processObjectData(res.data.data);
                let criteriaList; try { criteriaList = await getRFQScoringCriteriasData(criteriaArray) } catch(error){ console.log(error) }
                
                let sectionArray = getUniqueScoringSectionIds(criteriaList);
                let sectionsList; try { sectionsList = await getRFQScoringSectionData(sectionArray) } catch(error) { console.log(error) } 
                let sectionsCriteriasList = getSectionsWithCriterias(sectionsList, criteriaList) // sections for 1st column 

                let vendors; try { vendors = await getRFQVendorData(props.rfq_id) } catch (error) { console.log(error) };
                if(!checkIfArrayIsEmpty(vendors)){ vendors.forEach((item) => VendorsList.push({ id: item.RFQvendor_id, vendor: item.vendor_name, reply_id: item.vendorreply_id }))};  

                let TopScorer = getHigherScoreVendors(res.data.data); // top scorer last column 
                let vendorScoring = processVendorScoring(res.data.data, VendorsList) // columns data
                let userScorings = getUserNameFromVendorsScoring(vendorScoring, VendorsList);
                
                let summaryRowData; { summaryRowData = await getRFQVendorsSummary(VendorsList, scorerfq_id, userScorings[0], ((criteriaList?.length * (userScorings[0]?.length - 1)) * 10)) };
                
                let sectionsNamesWithScorings = getSectionNamesWithScorings(vendorScoring, sectionsCriteriasList)
                let scoresWithSections = getCombinedScoresSections(TopScorer,sectionsCriteriasList);
  
                setLoading(false)
                
                setTechnicalEvaluation({
                    summaryRowData: summaryRowData,
                    sections: scoresWithSections,
                    vendorsList: VendorsList,
                    vendorScoring: sectionsNamesWithScorings, 
                    users: userScorings 
                })
            }
        }).catch((res) => {  
            setTechnicalEvaluation({});
            setLoading(false);
        })
    }

    const getRFQVendorsSummary = async (RFQVendors, scorerfq_id, evaluatorArray, totalPercentageSum=0) => {
        let api = Api;
        api.setUserToken();
        
        try {  
            const res = await api.axios().get(
                Settings.apiPurchaseRequisitionUrl +
                `/score_summary/${props?.rfq_id}?evaluation_type=${props?.evaluation_type}&scoreRFQ_id=${scorerfq_id}`,
                {}
            );
    
            if (res.data.status === 'success') {
                const { simplifiedObjects } = multipleUserScorings(res?.data?.data, RFQVendors, evaluatorArray, totalPercentageSum);
                return simplifiedObjects;
            }
        } catch (error) { 
            if (checkIfArrayIsEmpty(RFQVendors)) return [];
        
            const initializedObjects = ArrayInitializerOfObjects(RFQVendors); 
            return initializedObjects;
        }
    };

    const getRFQVendorData = (rfq_id) => {
        if (!rfq_id) return Promise.resolve();
      
        let api = Api;
        api.setUserToken();
      
        return new Promise((resolve, reject) => {
            api.axios()
            .get(Settings.apiPurchaseRequisitionUrl + `/get_vendors_of_RFQ/${rfq_id}`, {})
            .then(function (res) {
                if (res.data.status === "success") {  
                    resolve(res.data?.data?.filter(item => item.vendor_replystatus !== 1 || item.vendor_replystatus !== 8));
                } else {
                    reject(new Error(res.data.message));
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    };

    const getRFQScoringCriteriasData = async (criteriaArray) => {
        if (checkIfArrayIsEmpty(criteriaArray)) return []; 
      
        let api = Api;
        api.setUserToken();
      
        const fetchPromises = criteriaArray.map(async (id) => {
            return api.axios()
            .get(Settings.apiPurchaseRequisitionUrl + `/scoringcriteria/${id}?for_rfq=True`, {})
            .then(function (res) {
                if (res.data.status === "success") {
                    const { name, scoringsectiondetailrfq_id, scoringcriterialdetailrfq_id, comments_mandatory } = res.data.data;
                    return { name, scoringsectiondetailrfq_id, scoringcriterialdetailrfq_id, comments_mandatory };
                } else {
                    throw new Error(res.data.message);
                }
            })
            .catch((error) => {
                throw error;
            });
        });
      
        try {
            const resultArray = await Promise.all(fetchPromises);
            return resultArray;
        } catch (error) {
            console.error("Error fetching RFQ scoring criterias data:", error);
            return [];
        }
    };

    const getRFQScoringSectionData = async (idsArray) => {
        if (checkIfArrayIsEmpty(idsArray)) { return Promise.reject(new Error("Empty Array!")); }
      
        let api = Api;
        api.setUserToken(); 

        const fetchPromises = idsArray.map(async (scoringsectiondetailrfq_id) => {
            if (!scoringsectiondetailrfq_id) return null;
        
            try {
                const res = await api.axios().get(Settings.apiPurchaseRequisitionUrl + `/scoringsection/${scoringsectiondetailrfq_id}?for_rfq=True`,{});
        
                if (res.data.status === "success") {
                    const { name, scoringsectiondetailrfq_id } = res.data.data;
                    return { section_name: name, scoringsectiondetailrfq_id };
                } else { return null; }

            } catch (error) {
                console.error(`Error fetching data for scoringsectiondetailrfq_id ${scoringsectiondetailrfq_id}:`, error);
                return null;
            }
        });
      
        const resultArray = await Promise.all(fetchPromises);
        return resultArray;
    };

    // Data processing functions/modules

    const getUniqueScoringSectionIds = (dataArray) => { // extracting unique section_ids from array of objects
        return [...new Set(dataArray.map((item) => item.scoringsectiondetailrfq_id))]; 
    }

    const getSectionsWithCriterias = (sectionsArray, criteriasArray) => {
        const result = [];
  
        sectionsArray.forEach((section) => {
            const sectionName = section.section_name;
            const matchingCriterias = criteriasArray.filter(
                (criteria) => criteria.scoringsectiondetailrfq_id === section.scoringsectiondetailrfq_id
            );
      
            if (matchingCriterias.length > 0) {
                const criterias = matchingCriterias.map((criteria) => ({
                    criteria_detail_id: criteria.scoringcriterialdetailrfq_id,
                    criteria: criteria.name,
                    scoreVendorName: '',
                    commentsMandatory: criteria.comments_mandatory,
                }));
        
                result.push({
                    name: sectionName,
                    criterias,
                });
            }
        });
      
        return result;
    }

    const getHigherScoreVendors = (data) => {
        const result = [];
      
        for (const key in data) {
          const vendorEntries = data[key];
          
          if (vendorEntries.length > 0) {
            if (vendorEntries.length === 1) { 
              result.push({ id: parseInt(key), scoreVendorName: vendorEntries[0]?.evaluator_name });
            } else { 
              let maxScore = -Infinity;
              let maxScoreVendorName = "";
      
              for (const vendor of vendorEntries) {
                if (vendor.score > maxScore) {
                  maxScore = vendor?.score;
                  maxScoreVendorName = maxScore === 0 ? '-' : vendor?.evaluator_name;
                }  
              }
              
              result.push({ id: parseInt(key), scoreVendorName: maxScoreVendorName });
            }
          }
        } 
        return result;
    };

    const getCombinedScoresSections = (array1, array2) => {
        const idToVendorMap = {};
        array1.forEach(item => {
            idToVendorMap[item.id] = item.scoreVendorName;
        });

        const result = array2.map(section => ({
            ...section,
            criterias: section.criterias.map(criteria => ({
                ...criteria,
                scoreVendorName: idToVendorMap[criteria.criteria_detail_id] || criteria.scoreVendorName,
            })),
        }));

        return result;
    }

    const getUserNameFromVendorsScoring = (data, vendors) => { 
        const result = [];
      
        for (let i = 0; i < vendors.length; i++) {
            const allScoresArray = data[i]?.all_scores?.[0] || []; 
            
            const clonedScoresArray = [...allScoresArray]; 
            clonedScoresArray.push({ userName: 'emptyO', id: Date.now() }); // Push the additional object at the end of the cloned array
            result.push(clonedScoresArray); 
        }

        return result;
    };

    const getSectionNamesWithScorings = (array1, array2) => {
        const combinedArray = [];
        
            array1.forEach(item => {
                const matchedSection = array2.find(section => 
                    section.criterias.some(criteria => criteria.criteria_detail_id === item.criteria_id)
                );
        
                if (matchedSection) {
                    const newItem = { ...item, section_name: matchedSection.name };
                    combinedArray.push(newItem);
                }
            });
        
        return combinedArray;
    }
       
    const processObjectData = (data) => {
        const criteriaArray = Object.keys(data);
        let RFQ_id = null;
      
        for (const key of criteriaArray) {
            const scoresArray = data[key];
            const rfq_id = scoresArray[0].rfq_id;
            RFQ_id = rfq_id;
        }
      
        return { criteriaArray, RFQ_id };
    };

    const processVendorScoring = (object, vendors) => {
        let maxScoreCount = 0;
      
        // Find the maximum number of scoring entries among all vendors and criteria
        for (const key in object) {
            const vendorEntries = object[key];
            if (vendorEntries.length > maxScoreCount) { maxScoreCount = vendorEntries.length }
        }

        const result = [];
      
        // Process each criteria
        for (const key in object) {
            const vendorEntries = object[key];
            const all_scores = [];
            
            for (let i = 0; i < vendors.length; i++) {
                const subArray = [];
                const vendor_reply_id = vendors[i].reply_id;
        
                for (let j = 0; j < maxScoreCount; j++) {
                    const vendor = vendorEntries[j];
            
                    const matchingVendor = vendor_reply_id === vendor.rfq_vendorreply_id;
                    
                    if (matchingVendor) {
                        const scoreObj = {
                            id: vendor.scorecriteriadetail_RFQ_id, 
                            score: vendor.score ? vendor.score : '0',
                            userName: vendor.evaluator_name,
                            comments: vendor.comments,
                            reply_id: vendor.rfq_vendorreply_id,
                            evaluator_id: vendor?.evaluator_id
                        };
                        subArray.push(scoreObj);
                    }
                }
                all_scores.push(subArray);
            }
            result.push({ criteria_id: parseInt(key), all_scores });
        }
        return result;
    };

    // Modules,
    const TechConsolidated = () => { 
        return (
            <div className='technical_evaluation_consolidated'> 
                <TechConsolidationHeader rfq_scores_data={props?.rfq_scores_data} hideSidebarState={props?.hideSidebarState} screenHeight={props?.screenHeight} rfqHeader={props?.rfqHeader} technicalData={technicalEvaluation} /> 
            </div>
        )
    };

    return (
        <Fragment>
            {loading ? <SimpleLoading /> :  
                (
                    !isEmptyObject(props.rfq_scores_data) ?
                        <Fragment> {TechConsolidated()} </Fragment> 
                    :  <div className='text-center'>No Technical Evaluation Consolidated View Found!</div>
                )
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { 
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) ((TechEvaluationConsolidated));