import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    isLoading:false,
}
const IsMasterContentLoaderReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_IS_CONTENT_MASTER_LOADER){
        state = {
            ...state,
            isLoading:actions.payload,
        }
    }
    return state;
}
export default IsMasterContentLoaderReducer;