import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import '../../../../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
import datetimepicker from 'jquery-datetimepicker';
import Helper from '../../../../inc/Helper';
/**
 * minDateInputId : id of input box
 * maxDateInputId : id of input box
 * inputId : id of inputbox
 */
class DatePicker {
    init(params) {
      this.eInput = document.createElement('input');
      if(params.colDef.inputId){
        this.eInput.id = params.colDef.inputId;
      }
      if(params.value){
        if(Helper.isFrontendFormatedDate(params.value)){
          this.eInput.value = params.value;
        }else{
          this.eInput.value = Helper.convertDateFromDb(params.value);
        }
      }else{
        this.eInput.value = '';
      }
      this.eInput.classList.add('ag-date-picker-input');
      this.eInput.style.height = '100%';
      this.eInput.style.width = '100%';
      let datePickerOptions = {
              timepicker: false,
              format: Helper.getDatePickerFormate(),
              mask:false,
          }
      datePickerOptions.onShow =function( ct ){
        if(params.colDef.minDateInputId){
          let minDate = document.getElementById(params.colDef.minDateInputId);
          if(minDate){
            this.setOptions({
              //format:Helper.getDatePickerFormate(),
              minDate:minDate.value
            })
          }
        }
        if(params.colDef.maxDateInputId){
          let maxDate = document.getElementById(params.colDef.maxDateInputId);
          if(maxDate){
            this.setOptions({
              //format:Helper.getDatePickerFormate(),
              maxDate:maxDate.value
            })
          }
        }
        
      }
      
      $(this.eInput).datetimepicker(datePickerOptions);
      
    }
  
    // gets called once when grid ready to insert the element
    getGui() {
      return this.eInput;
    }
  
    // focus and select can be done after the gui is attached
    afterGuiAttached() {
      //this.eInput.focus();
      //this.eInput.select();
    }
    getValue() {
      if(this.eInput.value === 'undefined'){
        return '';
      }
      return this.eInput.value;
    }
    destroy() {

    }
    isPopup() {
      // and we could leave this method out also, false is the default
      return false;
    }
    focusIn(){
      this.eInput.focus();
    }
  
  }
  export default DatePicker;