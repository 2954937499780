import React, { useEffect, useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import ButtonNew from '../inc/ButtonNew'
import AgGrid from '../grid/ag/ag-grid'
import { useNavigate } from 'react-router-dom'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import CustomHtmlCell from '../grid/ag/cellRender/CustomHtmlCell'

const QuestionnaireList = (props) => {
  const [questionnaireList, setQuestionnaireList] = useState([])
  const navigate = useNavigate()

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link">${params.value ? params.value : "-"}</div>`;
  };

  const Columns_Headings = [
    {
      field: 'description',
      minWidth: 280,
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      editable: false,
      valueFormatter:gridLinkValueFormat,
      cellRenderer:CustomHtmlCell,     
    },
    {
      field: 'is_active',
      minWidth: 120,
      headerName: Helper.getLabel(props.language, 'active', 'Active'),
      inputType: 'checkbox',
      cellClass: 'check-cell',
    },
    {
      field: 'total_time',
      minWidth: 350,
      headerName: Helper.getLabel(props.language, 'total_time_to_complete_the_questionnaire', 'Total time to complete the questionnaire'),
      editable: false,
    },
    {
      field: 'allow_back',
      minWidth: 160,
      headerName: Helper.getLabel(props.language, 'allow_back', 'Allow Back'),
      inputType: 'checkbox',
      cellClass: 'check-cell',
    },
    {
      field: 'allow_next_without_complete_the_question',
      minWidth: 350,
      headerName: Helper.getLabel(props.language, 'allow_next_without_completing_the_question', 'Allow next without completing the question'),
      inputType: 'checkbox',
      cellClass: 'check-cell',
    },
    {
      field: 'overview_enabled',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'overview_visible', 'Overview visible'),
      inputType: 'checkbox',
      cellClass: 'check-cell',
    },
    {
      field: 'note_enabled',
      minWidth: 160,
      headerName: Helper.getLabel(props.language, 'notes_visible', 'Notes visible'),
      editable: false,
      inputType: 'checkbox',
      cellClass: 'check-cell',
    },
    {
      field: 'note',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'notes', 'Notes'),
      editable: false,
      valueFormatter:(params) => params.value ? params.value : "-"
    },
    {
      field: 'overview',
      minWidth: 200,
      headerName: Helper.getLabel(props.language, 'overview', 'Overview'),
      editable: false,
      valueFormatter:(params) => params.value ? params.value : "-"
    },
  ]
  const getQuestionnaireList = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/questionnaire`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionnaireList(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onCellClicked = (event) => {
    if(event?.colDef?.field === 'description'){
      navigate(`/edit-questionnaire/${event?.data?.questionnaire_id}`)
    }
  }

  const onQuestionnaireDelete = (allRows) => {
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiUrl+`/questionnaire/${element.questionnaire_id}`).then(function(res){
            if(res.data.status==="success"){
              Helper.alert(res.data.message)
              getQuestionnaireList()
            }
        }).catch((res) => {  
          getPopupMessageBasedOnStatus(res) 
        })
    })
}

  useEffect(() => {
    getQuestionnaireList()
  }, [])

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'questionnaire', 'Questionnaire')}`} hideMoreBtn={true}>
          <ButtonNew title={Helper.getLabel(props.language, 'new', 'New')} onClick={() => navigate('/new-questionnaire')} />
        </NavigationHeder>
        <div>
          <AgGrid id='rfq-questionnaire-list' header={Columns_Headings} data={questionnaireList} addBtnHide={true} hideSave={true} onDelete={onQuestionnaireDelete} viewOnly={true} onCellClicked={onCellClicked}/>
        </div>
      </MasterComonent>
    </div>
  )
}

export default QuestionnaireList
