import React, { useState, Fragment, useEffect } from 'react';
import Popup from '../../../Popup/Popup';
import $ from 'jquery';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import FormValidator from '../../../../inc/FormValidator';
import Collapse from '../../../inc/Collapse';  
import { checkValue, getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import Helper from '../../../../inc/Helper';
import { connect } from 'react-redux'; 
import GeneralTemplateModule from './GeneralTemplateModule';
import { useNavigate } from 'react-router-dom';
import Button from '../../../inc/Button';

const AddTemplatePopup = ({closePopup, masterComponentWrapperDiv=null, reloadScoringGrid, rfq_id, language, RFQModuleReq = true, callBackAPIExecution}) => { 
    const [createTemplate, setCreateTemplate] = useState({
      auto_disqualification_financial_score_prct: null,
      auto_disqualification_technical_score_prct: null,
      financial_score_weight: null,
      name: null, 
      show_evaluation_criteria_to_bidder: null,
      technical_score_weight: null
    })

    const navigate = useNavigate()

    const [scoringWeights, setScoringWeights] = useState(false)
    const [height, setHeight] = useState(0);

    let validator ; 

    const validationConfigure = () => {
        let fieldConfig = [ 
            {name:'name', displayName:Helper.getLabel(language,'name','Name'),types:['Required']},
            {name:'technical_score_weight', displayName:Helper.getLabel(language,'technical_score_weight','Technical score weight'),types:['Required', 'Number']},
            {name:'financial_score_weight', displayName:Helper.getLabel(language,'financial_score_weight','Financial score weight'),types:['Required', 'Number']}
        ]
        if(!validator) {
            validator = new FormValidator(fieldConfig, language)
        }
    }

    validationConfigure()

    useEffect(() => { 
        if(!RFQModuleReq){
            Helper.resizeWindowHandle(masterComponentWrapperDiv, setHeight, window) 
        } 
    }, [masterComponentWrapperDiv, masterComponentWrapperDiv?.current?.clientHeight]);
    
    const displayTopLevelComponent = () => {
        return (
            <div className='row mb-3'>
                <div className='col-6'>{ displayHeadLine() }</div>
                <div className='col-6'> { actionBtns() } </div>
            </div>
        )
    }

    const displayHeadLine = () => { return (<h1 className='rfq_head_line_txt'>This template refers to RFQ only</h1>) }

    const ScoringModule = () => {
        return (
            <Fragment>
                {GeneralModule()} 
            </Fragment>
        )
    }

    const GeneralModule = () => {
        return (
            <Collapse open={true} title={Helper.getLabel(language,'general_info',"General information")}>
                <GeneralTemplateModule setScoringWeights={setScoringWeights} currentState={createTemplate} updateState={setCreateTemplate} /> 
            </Collapse>
        )
    }

    const actionBtns = () => {
        return (
            <div className='d-flex justify-content-end align-items-center'> 
                <Button   
                    title={Helper.getLabel(language, "save_close", "Save & Close")}
                    onClick={SaveHandler}
                    className='blue-btn-style'
                />
            </div>
        )
    }
 
    const onPopupCloseHandler = () => {
        closePopup((prevState) => {
            return {
                ...prevState,
                addTemplatePopup: false
            }
        })
        $('html').removeClass('rs_popup_open');
    }
 
    const ScoringTemplateAPI = () => {
        if (scoringWeights) {
            Helper.alert('Distribute scoring weights about 100%', 'error');
            return;
        } 
    
        let api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiPurchaseRequisitionUrl + `/scoringMaster${RFQModuleReq ? '?for_rfq=True' : ''}`, {
            auto_disqualification_financial_score_prct: !checkValue(createTemplate.auto_disqualification_financial_score_prct) ? null : createTemplate.auto_disqualification_financial_score_prct,
            auto_disqualification_technical_score_prct: !checkValue(createTemplate.auto_disqualification_technical_score_prct) ? null : createTemplate.auto_disqualification_technical_score_prct,
            financial_score_weight: !checkValue(createTemplate.financial_score_weight) ? null : parseFloat(createTemplate.financial_score_weight),
            name: !checkValue(createTemplate.name) ? null : createTemplate.name, 
            technical_score_weight: !checkValue(createTemplate.technical_score_weight) ? null : parseFloat(createTemplate.technical_score_weight),
            show_evaluation_criteria_to_bidder: createTemplate.show_evaluation_criteria_to_bidder == 'on' ? true : false,
            scoringmaster_id: null,
            RFQ_id: RFQModuleReq ? rfq_id : null
        }).then(function(res) { 
            if ( res.data.status === "success") { 
                if (RFQModuleReq) onPopupCloseHandler();
                if(RFQModuleReq) reloadScoringGrid();
                Helper.alert('Scoring template added successfully');
                if (!RFQModuleReq) navigate('/scoring-template');
            } 
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
        })
    };
 
    const SaveHandler = (e) => {  
        let validData = {
            ...createTemplate
        }

        if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        }
         
        if (!/^\d*\.?\d*$/.test(createTemplate.technical_score_weight) && createTemplate.technical_score_weight !== null) {
            Helper.alert('Technical score weight invalid number', 'error');
            return;
        }

        if (!/^\d*\.?\d*$/.test(createTemplate.auto_disqualification_technical_score_prct) && createTemplate.auto_disqualification_technical_score_prct !== null) {
            Helper.alert('Auto disqualification technical score invalid number', 'error');
            return;
        }

        if (!/^\d*\.?\d*$/.test(createTemplate.financial_score_weight) && createTemplate.financial_score_weight !== null) {
            Helper.alert('Financial score weight invalid number', 'error');
            return;
        }

        if (!/^\d*\.?\d*$/.test(createTemplate.auto_disqualification_financial_score_prct) && createTemplate.auto_disqualification_financial_score_prct !== null) {
            Helper.alert('Auto disqualification financial score invalid number', 'error');
            return;
        }
 
        ScoringTemplateAPI() 
    }

    { !RFQModuleReq && callBackAPIExecution(SaveHandler) }
 
    const RFQModule = () => {
        return (
            <div className='common_responsive_class_popup_component'> 
                <Popup onClose={ onPopupCloseHandler } autoOpen={true} className='mb-5'>
                    { <div className='container-fluid'> { displayTopLevelComponent() } { ScoringModule() } </div> }
                </Popup>
            </div>
        )
    }

    const MasterModule = () => {
        return (
            <Fragment>
                { <div className='container-fluid common_scrollbar_styles' style={{ height: `${height-30}px`, overflowY: 'auto'}}> { ScoringModule() } </div> }
            </Fragment>
        )
    }

    return ( <Fragment> {RFQModuleReq ? RFQModule() : MasterModule()} </Fragment> )
}

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) (AddTemplatePopup)