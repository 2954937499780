import React, { Component } from 'react';

class UserDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            data:this.props.data,
        }
    }

    render() {
        let data = this.props;
        let vendorType = this.props.vendorTypes.find((item)=>item.id === data?.data?.vendor_type)
        
        return (
            <div className="user_details_call2action">
                {/* <h3 className="label_of_c2a">User Details</h3> */}
                <div className='row '>
                    <div className='col-xs-12 col-md-12'>
                        <div className="items">
                            <div className="item">
                                <div className="item_inner">
                                    <span>Organization/Person</span>
                                    <h3>{this.props?.data?.name}</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item_inner">
                                    <span>Organization Sector</span>
                                    <h3>{vendorType?.name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserDetails;