import React, { Fragment, useCallback } from 'react'; 
import { connect } from "react-redux";
import axios from 'axios';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';  
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new'; 
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import Api from '../../../inc/Api';

let gridApi = null;
let AddBtn;
let transaction;

const Language = (props) => {

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {  
    if (event?.data?.languange_Id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  const updateData = async (element) => { 
    try {
      let payload = {
        languagecode: element?.data?.languageCode,
        language: element?.data?.language,
        alignment: element?.data?.alignment === "" ? null : element?.data?.alignment
      };
 
      const api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiSystemUrl+`/language/${element?.data?.languange_Id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
  
  const addNewData = async (element) => {
    try {
      let payload = {
        languagecode: element?.data?.languageCode,
        language: element?.data?.language,
        alignment: element?.data?.alignment === "" ? null : element?.data?.alignment
      };

      const api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiSystemUrl+'/language', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }  
  }

  const DeleteHandler = useCallback(async (element) => {  
    try {
      const api = Api;
      api.setUserToken();
      const res = await 
        api.axios()
        .delete(Settings.apiSystemUrl+`/language/${element?.data?.languange_Id}`)
      const rowNode = gridApi?.getRowNode(element?.data?.languange_Id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200);

      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
   
  if(!props?.user?.admin_user){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };

  /* Grid Column Headers */

  const AlignmentOptions = [
    { value: 0, label: "Left" },
    { value: 1, label: "Right" }
  ];

  const gridColHeaders = [
    { field:'languageCode', editable: true, filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'code_md_lg','Code') },
    { field:'language', editable: true, filter: 'agTextColumnFilter', headerName:Helper.getLabel(props.language,'language_md_lg','Language') },
    {
      field: 'alignment',
      headerName: Helper.getLabel(props.language,'alignment_md_lg','Alignment'),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero:true, 
      cellEditorParams: {
        values: AlignmentOptions
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, AlignmentOptions),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    }
  ];

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} title='Language' /> 

      <AgGridNew
        apiUrl={Settings.apiSystemUrl+'/language'} 
        hideAddBtn={!props?.user?.admin_user}
        hideDeleteBtn={!props?.user?.admin_user}
        pagination={false}
        columnDefs={gridColHeaders}
        height={500}
        uniqueField={'languange_Id'} 
        handleAddButton={handleBtn}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={DeleteHandler}
        onGridReady={(params) => gridApi = params?.api} 
        gridId={"languages-grid"}
      />
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language,
    user:state.auth.user
  }
}
 
 
export default connect(mapStateToProps) (MasterComponentWraper((Language)))