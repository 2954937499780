import React, { useState, useEffect, Fragment } from 'react'; 
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper'; 
import {connect} from 'react-redux'; 
import { customDateComparator, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder';
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker";
import Button from '../../inc/Button'; 
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../inc/Alert';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import Api from '../../../inc/Api';
import InviteModal from '../../RequestForQuotation/RFQDetailForm/HeaderView/InviteModal';
import DatepickerFunctions from '../../../inc/DatepickerHelper'; 
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getDisplayNamesFromArray, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';

let gridApi = null;

const VendorsInvitationList = (props) => {
    const [countries, setCountries] = useState([]);
    const [openInviteModal, setOpenInviteModal] = useState(false);
  
    let getCountry = false;

    useEffect(() => { get_countries() }, []);
 
    const get_countries = () => {
        if(getCountry) return;

        let api = Api
        api.setUserToken()
        getCountry = true
        api.axios().get(Settings.apiUrl+'/country',{}).then(function(res){ 
            if(res.data.Status==="Success"){  
                let Updated_data = []
                res.data.Message.forEach((item) => {
                    Updated_data.push({
                        value: item.country_id,
                        label: `${item.country_short_name}`
                    })
                }) 
                setCountries(Updated_data) 
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
        })
    }

    const getVendorInvitationByInvitationId = (invitation_id) => {   
        if(!invitation_id) return;

        let api = Api
        api.setUserToken()

        api.axios().get(Settings.apiVendorUrl+`/vendorinvitation/${invitation_id}`,{}).then(function(res){
            if(res.data.status==="success"){
                revokeVendorInvitation(invitation_id, res.data?.data?.email_body);
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)
        })
    };

    const revokeVendorInvitation = (invitation_id, vendor_invitation_email_body) => {   
        if(!invitation_id) return;

        let api = Api
        api.setUserToken()

        api.axios().put(Settings.apiVendorUrl+`/vendorinvitation/${invitation_id}`,{
            email_body: vendor_invitation_email_body ? vendor_invitation_email_body : null
        }).then(function(res){
            if(res.data.status==="success"){
                Helper.alert(res.data.message);

                gridApi?.refreshServerSide({ purge: true })
                gridApi?.deselectAll();
            }
        }).catch((res) => {  
            getPopupMessageBasedOnStatus(res);
        })
    };

    const regenerateExpiryOTP = (invitation_id) => {   
        if(!invitation_id) return;

        let api = Api
        api.setUserToken()

        api.axios().get(Settings.apiVendorUrl+`/regenerate_otp/${invitation_id}`,{}).then(function(res){
            if(res.data.status==="success"){
                Helper.alert(res.data.message);

                gridApi?.refreshServerSide({ purge: true })
                gridApi?.deselectAll();
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)
        })
    };

    const onRevokeCellClicked = (event) => {
        let vendor_invitation_id = event?.data?.vendorinvitation_id;

        if(event.colDef.field === 'revoke') getVendorInvitationByInvitationId(vendor_invitation_id);
        if(event.colDef.field === 'regenerate_opt') regenerateExpiryOTP(vendor_invitation_id);
    };

    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.masterData.vendor_invitation_listings.vendor_invitation_listings_main)){
        return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

    /* Grid Column Headers */

    const gridColumnHeaders = [
        {field:'invitation_code', filter: 'agNumberColumnFilter', valueFormatter:(params) => params.value ? params.value : '-', headerName:'Invitation Code', editable: false, minWidth: 350},
        {field:'email', filter: 'agTextColumnFilter', valueFormatter:(params) => params.value ? params.value : '-', headerName:'Email', editable: false, minWidth: 350},
        {field:'country_id', filter: 'agTextColumnFilter', cellRenderer: (params) => getDisplayNamesFromArray(params, countries, 'value', 'label'),  filterParams: { values: (params) => getOptions(params, countries), keyCreator: (params) => optionsKeyCreator(params, 'value'), valueFormatter: params => params.value.label }, filter: 'agSetColumnFilter', headerName:'Country', minWidth: 350, editable: false}, 
        {field:'phone_number', filter: 'agNumberColumnFilter', valueFormatter:(params) => params.value ? params.value : '-', headerName:'Phone Number', editable: false, minWidth: 300},
        {field:'vendor_name', valueFormatter:(params) => params.value ? params.value : '-', headerName:'Vendor', editable: false, minWidth: 400 },
        {
            field: 'code_expiry_datetime',
            headerName: 'Code Expiry Date Time',
            filter:'agDateColumnFilter', 
            valueFormatter: (params) => { 
             return params.value
                ? DatepickerFunctions.convertDateTimeFromDataBase(params.value)
                : '-'
            },
            editable: false,
            cellEditor: DateEditor,
            minWidth: 220,
            comparator: customDateComparator
        },
        {field:'revoke', filter: false, sortable: false, headerName:'Revoke Invite', cellRenderer: () => <div className="rs_inactive_label">Revoke Invite</div>, editable: false, minWidth: 300},
        {field:'regenerate_opt', filter: false, sortable: false, headerName:'Regenerate OTP', cellRenderer: () => <div className="rs_inactive_label">Regenerate OTP</div>, editable: false, minWidth: 300},
    ];

    return ( 
        <Fragment>
            <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title='Vendors - Invitation List'>
                <Button isDisable={!security.canEdit(frontendIds.masterData.vendor_invitation_listings.vendor_invitation_listings_main_create_button)} isActive= {true} title='Invite Vendor' onClick={() => setOpenInviteModal(true)} />
            </NavigationHeder>  

            <AgGridNew
                apiUrl={Settings.apiVendorUrl+'/vendorinvitation'}
                pagination={false}
                columnDefs={gridColumnHeaders}
                onCellClicked={onRevokeCellClicked}
                hideDeleteBtn={true}
                hideAddBtn={true} 
                height={500}
                onGridReady={(params) => gridApi = params?.api}
                gridId={'rs_vendors_invitation_list'}
            />

            {openInviteModal && (
                <InviteModal
                    InvitationForMasterLevel={true}
                    gridApi={gridApi}
                    language={props?.language}
                    handleCloseModal={() => setOpenInviteModal(false)}
                />
            )}
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.masterData.vendors.vendors
}

export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(VendorsInvitationList, SecurityOptions)))