import React from 'react'

const Step = ({ number, name, isCurrentStep, isSaved }) => {
  return (
    <div
      className={`step ${isCurrentStep ? 'current-step' : ''} ${
        isSaved ? 'saved-step' : ''
      }`}
    >
      <div
        className={`circle ${isSaved ? 'saved-circle' : ''} ${
          isCurrentStep ? 'current-step-circle' : ''
        }`}
      >
        <p className='stepper-number mb-0'>{isSaved ? <img src="/images/icons/tick-white.svg" /> : number}</p>
      </div>
      <div
        className={`name ${isSaved ? 'saved-name' : ''} ${
          isCurrentStep ? 'current-step-name' : ''
        }`}
      >
        {name}
      </div>
    </div>
  )
}

export default Step
