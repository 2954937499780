// import React, { Component } from 'react';
// import Api from '../../inc/Api';
// import Helper from '../../inc/Helper';
// import Settings from '../../inc/Settings';
// import CriteriaDetails from '../External Screens/Request For Quotation/Scoring Module/CriteriaDetails';
// import AgGrid from '../grid/ag/ag-grid';
// import RsDataLoader from '../inc/RsDataLoader';
// import GridSegmentDistributionDetails from './GridSegmentDistributionDetails';
// import SegmentDistributionPopup from './popups/SegmentDistributionPopup';
// import Alert from '../inc/Alert';
// import DatepickerFunctions from '../../inc/DatepickerHelper';
// import AgGridNew from '../grid/ag/ag-grid-new';
// /**
//  * id: grid id
//  * source_id: required
//  * totalAmount: required
//  * integrator: required
//  * distribution_source_id: id | null
//  * disableFinancial={false} 
//  * onCreateFirstDistribution= function(distribution_source_id) 
//  */

// class SegmentsDistribution extends Component {
//     constructor(props){
//         super(props);
//         this.id = this.props.id ? this.props.id:'rs_segment_distribution_grid';
//         this.grid = null;
//         this.source_id = this.props.source_id;
//         this.totalAmount = this.props.totalAmount;
//         this.integrator = this.props.integrator;
//         this.state ={
//             isPopupOpen:false,
//             isLoading:false,
//             segmentsCombinations:[],
//             allCombinationDetails:[],
//             allSegmentsValues:[],
//             distribution_source_id: this.props.distribution_source_id ? this.props.distribution_source_id : null,
//             reRender:false,
//             allSegments:[]
//         }
//     }
//     componentDidMount(){
//         // this.loadSegmentsBySource();
//         this.loadSegments();
//         this.loadSegmentValues();
//     }
//     componentDidUpdate(prevProps){
//         let that = this;
//         this.source_id = this.props.source_id;
//         this.totalAmount = this.props.totalAmount;
//         this.integrator = this.props.integrator;

//         if(this.props.distribution_source_id != this.state.distribution_source_id){
//             this.setState({
//                 segmentsCombinations:[],
//                 distribution_source_id: this.props.distribution_source_id
//             },function(){
//                 this.grid.api?.refreshServerSide({purge:true})
//             })
//         }
//     }
//     // loadSegmentsBySource(){
//     //     if(!this.state.distribution_source_id){
//     //         return;
//     //     }
//     //     let that = this;
//     //     that.setState({
//     //         isLoading:true
//     //     })
//     //     let api = Api;
//     //     api.setUserToken();
        
//     //     api.axios().get(Settings.apiUrls.segments+'/distribution_detail_by_source/'+this.state.distribution_source_id).then( res => {
//     //         that.setState({
//     //             isLoading:false,
//     //             segmentsCombinations:res.data.data
//     //         })
//     //     })
//     // }
//     loadSegments(){
//         if(this.isSegmentsLoaded){
//             return;
//         }
//         this.isSegmentsLoaded = true;
//         let api = Api;
//         let that = this;
//         that.setState({
//             segmentsCombinations:[],
//             isLoading:true
//         })
//         api.setUserToken();
//         api.axios().get(Settings.apiUrls.segments + '/get_segments').then(res => {
//             let data = res.data.data.reverse();
//             that.setState({
//                 isLoading:false,
//                 allSegments:data
//             })
//         }).catch( error => {
//             that.setState({
//                 isLoading:false
//             })
//         })
//     }
//     loadSegmentValues(){
//         let api = Api;
//         api.setUserToken();
//         let that = this;
//         that.setState({
//             isLoading:true,
//         })
//         api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
//             let responseData = res.data.data.reverse();
//             that.setState({
//                 allSegmentsValues:responseData,
//                 isLoading:false,
//             })
//         }).catch(error =>{
//             that.setState({
//                 isLoading:false,
//             })
//         })
//     }
//     onGridReady(grid){
//         this.grid = grid;
//     }
//     onAddBtnClick(event){
//         this.setState({
//             isPopupOpen:true
//         })
//     }
//     onPopupClose(event){
//         this.setState({
//             isPopupOpen:false
//         })
//         this.grid?.api?.refreshServerSide({purge:true})
//         // this.loadSegmentsBySource();
//     }
//     loadCombinationDetails(){
//         let that = this;
//         that.setState({
//             isLoading:true
//         })
//         let api = Api;
//         api.setUserToken();
        
//         api.axios().get(Settings.apiUrls.segments+'/distribution_detail_by_source/'+this.source_id).then( res => {
//             that.setState({
//                 isLoading:false,
//                 allCombinationDetails:res.data.data
//             })
//         })
//     }
    
//     getSegmentValueFromValueId(valueId){
//         let output = {};
//         this.state.allSegmentsValues.map(item => {
//             if(item.segmentvalue_id ==valueId ){
//                 output = item;
//             }
//         })
//         return output;
//     }
//     onCreateFirstDistribution(distributionsource_id){
//         let that = this;
//         if(this.props.onCreateFirstDistribution){
//             this.props.onCreateFirstDistribution(distributionsource_id);
//             this.setState({
//                 reRender:!this.state.reRender,
//             })
//         }
//         this.setState({
//             distribution_source_id:distributionsource_id
//         }, function(){
//             this.grid?.api?.refreshServerSide({purge:true})
//             // that.loadSegmentsBySource();
//         })
//     }
    
//     getValueCodesString(valueIds){
//         if(!Helper.getNullableValue(valueIds)){
//             return "";
//         }
//         let valueIdAr = valueIds.split("|");
//         let allSegmentsValues = this.state.allSegmentsValues;
//         let output = '';
//         valueIdAr.forEach( valueId => {
//             allSegmentsValues.forEach(value =>{
//                 if(value.segmentvalue_id == valueId){
//                     if(output===''){
//                         output = value.code;
//                     }else{
//                         output += '-'+value.code;
//                     }
//                 }
                
//             })
//         })
//         return output;
//     }
//     render() {
//         if(!Helper.getOnlyNumber(this.source_id) || !Helper.getOnlyNumber(this.integrator) ){
//             return <Alert message="source_id & integrator props are required" />;
//         }
//         let language = this.props.dataLoaderProps.language;
//         let header = [
//             {field:'name',headerName:Helper.getLabel(language,'segments','Segments'),  width:150,cellRenderer: 'agGroupCellRenderer'},
//             {field:'percentage',headerName:Helper.getLabel(language,'percentage','Percentage'), valueFormatter: (params) => { return Helper.getOnlyNumber(params.value)}},
//             {field:'amount',headerName:Helper.getLabel(language,'amount','Amount'), valueFormatter: (params) => { return  Helper.formateCurrency(params.value) } , hide:this.props.disableFinancial ? true : false },
//             {field:'accounting_date',headerName:Helper.getLabel(language,'accounting_date','Accounting Date'),valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value) ,editable:false},
//             //{field:'date',headerName:'Date', inputType:'datepicker'},
//         ]
//         let masterSettings = {
//             detailRowAutoHeight: true,
//             masterDetail: true,
//             detailCellRenderer: GridSegmentDistributionDetails,
//             /*
//             detailCellRendererParams: {
//                 detailGridOptions: {
//                     rowSelection: 'single',
//                     columnDefs: [
//                         { field: 'name' , headerName: Helper.getLabel(null,'segments','Segments') },
//                         { field: 'duration' , headerName:Helper.getLabel(null,'value','Value')},
//                         { field: 'switchCode' , headerName:Helper.getLabel(null,'description','Description')}
//                     ],
//                     defaultColDef: {
//                         sortable: true,
//                         flex: 1,
//                     },
//                 }
//             }
//             */
//         }
//         let gridLabels = {
//             btnAdd:Helper.getLabel(language,'segment_distribution','Segment distribution'),
//             btnDelete:Helper.getLabel(language,'remove','Remove'),
//         }
//         let compbinationGridData = this.state.segmentsCombinations.map( item => {
//             return {
//                 ...item,
//                 //formated_combination:this.getValueCodesString(item.segmentcombination_value),
//                 //this.getValueCodesString
//                 name: this.getValueCodesString(item.segmentcombination_value),
//                 segmentcombinationvalue: item.segmentcombination_value
//             }
//         })
//         //console.log('compbinationGridData',compbinationGridData);
//         return (
//             <div className='segment_distribution_component'>
//                 { this.state.isPopupOpen ? <SegmentDistributionPopup disableFinancial ={ this.props.disableFinancial ? true : false}  totalAmount={this.totalAmount} source_id={this.source_id} integrator={this.props.integrator} distribution_source_id={this.state.distribution_source_id} language ={language} onPopupClose ={this.onPopupClose.bind(this)} onCreateFirstDistribution = { this.onCreateFirstDistribution.bind(this) }/> : '' }
//                 {/* <AgGrid readOnlyGrid={this.props.isEditable === false ? true : false } id={this.id} viewOnly={true}    deleteConfig={{apiUrl:Settings.apiUrls.segments + '/segment_distribution_detail',idName:'distributiondetail_id',that:this,reloadFuntion:this.loadSegmentsBySource.bind(this)}} onAddClick={this.onAddBtnClick.bind(this)} hideSave={true} labels={gridLabels} onGridReady={ this.onGridReady.bind(this) } data={compbinationGridData} header={header} settings={masterSettings}/> */}
//                 <AgGridNew 
//                   key={this.state.reRender?'rerender':'norerender'}
//                   isAddBtnDisable={!this.props.isEditable} 
//                   gridId={this.id} 
//                 //   deleteConfig={{apiUrl:Settings.apiUrls.segments + '/segment_distribution_detail',idName:'distributiondetail_id',that:this,reloadFuntion:this.loadSegmentsBySource.bind(this)}} 
//                   onAddClick={this.onAddBtnClick.bind(this)} 
//                   onGridReady={ this.onGridReady.bind(this) } 
//                   columnDefs={header} 
//                   masterSettings={masterSettings}
//                   apiUrl={Settings.apiUrls.segments + `/distribution_detail_by_source/${this.state.distribution_source_id}`}
//                   returnApiCondition={!this.state.distribution_source_id}
//                 />
//             </div>
//         );
//     }
// }

// export default RsDataLoader(SegmentsDistribution,{ segments:true,segmentsValues:true});

import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import CriteriaDetails from '../External Screens/Request For Quotation/Scoring Module/CriteriaDetails';
import AgGrid from '../grid/ag/ag-grid';
import RsDataLoader from '../inc/RsDataLoader';
import GridSegmentDistributionDetails from './GridSegmentDistributionDetails';
import SegmentDistributionPopup from './popups/SegmentDistributionPopup';
import Alert from '../inc/Alert';
import DatepickerFunctions from '../../inc/DatepickerHelper';
import AgGridNew from '../grid/ag/ag-grid-new';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';

let gridObj = null
let isSegmentsLoaded = false

const SegmentsDistribution = (props) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [segmentsCombinations, setSegmentsCombinations] = useState([]);
    const [allCombinationDetails, setAllCombinationDetails] = useState([]);
    const [allSegmentsValues, setAllSegmentsValues] = useState([]);
    const [distribution_source_id, setDistributionSourceId] = useState(props.distribution_source_id ? props.distribution_source_id : null);
    const [reRender, setReRender] = useState(false);
    const [allSegments, setAllSegments] = useState([]);

    let id = props.id ? props.id:'rs_segment_distribution_grid'

    useEffect(() => {
        loadSegments();
        loadSegmentValues();
    }, []);

    useEffect(() => {
        if (props.distribution_source_id !== distribution_source_id) {
            setSegmentsCombinations([]);
            setDistributionSourceId(props.distribution_source_id);
            if (gridObj) {
                gridObj?.api?.refreshServerSide({ purge: true });
            }
        }
    }, [distribution_source_id]);

    const loadSegments = () => {
        if (isSegmentsLoaded) {
            return;
        }
        isSegmentsLoaded = true;
        setIsLoading(true);
        Api.setUserToken();
        Api.axios().get(Settings.apiUrls.segments + '/get_segments')
            .then(res => {
                const data = res.data.data.reverse();
                setAllSegments(data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const loadSegmentValues = () => {
        Api.setUserToken();
        setIsLoading(true);
        Api.axios().get(Settings.apiUrls.segments + '/segmentvalue')
            .then(res => {
                const responseData = res.data.data.reverse();
                setAllSegmentsValues(responseData);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const onGridReady = (grid) => {
        gridObj = grid;
    };

    const onAddBtnClick = () => {
        setIsPopupOpen(true);
    };

    const onPopupClose = () => {
        setIsPopupOpen(false);
        if (gridObj) {
            gridObj?.api?.refreshServerSide({ purge: true });
        }
    };

    const onCreateFirstDistribution = (distributionsource_id) => {
        if (props.onCreateFirstDistribution) {
            props.onCreateFirstDistribution(distributionsource_id);
            setReRender(!reRender);
        }
        setDistributionSourceId(distributionsource_id);
        if (gridObj) {
          gridObj?.api?.refreshServerSide({ purge: true });
        }
    };

    const onSegmentPopupClose = (allSegments) => {
        if (props.onSegmentDistributionPopupClose) {
            props.onSegmentDistributionPopupClose(allSegments);
        }
    };

    const getValueCodesString = (valueIds) => {
        if (!Helper.getNullableValue(valueIds)) {
            return "";
        }
        const valueIdAr = valueIds.split("|");
        let output = '';
        valueIdAr.forEach(valueId => {
            allSegmentsValues?.forEach(value => {
                if (value.segmentvalue_id == valueId) {
                    output = output === '' ? value.code : output + '-' + value.code;
                }
            });
        });
        return output;
    };

    const getSegmentCode = (params) => {
        const segmentValues = params.data?.segmentcombination_value;
        if (!segmentValues) return '-'; 
        const delimiter = segmentValues.includes('|') ? '|' : '';
        if (!delimiter) {
            const segment = allSegmentsValues?.find((item) => item.segmentvalue_id === parseInt(segmentValues));
            return segment ? segment.code : '-';
        }
        const segmentIds = segmentValues.split(delimiter);
        const segmentCodes = segmentIds.map(id => {
            const segment = allSegmentsValues?.find((item) => item.segmentvalue_id === parseInt(id));
            return segment ? segment.code : '-';
        });
    
        return segmentCodes.join(' - ');
    };
   
    
  const onSegmentDelete  = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrls.segments + `/segment_distribution_detail/${element?.data?.distributiondetail_id}`)
      const rowNode = gridObj?.api?.getRowNode(element?.data?.distributiondetail_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
   }, [])

    if (!Helper.getOnlyNumber(props.source_id) || !Helper.getOnlyNumber(props.integrator)) {
        return <Alert message="source_id & integrator props are required" />;
    }

    const language = props.dataLoaderProps.language;
    const header = [
        { field: 'segment', headerName: Helper.getLabel(language, 'segments', 'Segments'), width: 150, cellRenderer: 'agGroupCellRenderer', valueFormatter:getSegmentCode },
        { field: 'percentage', headerName: Helper.getLabel(language, 'percentage', 'Percentage'), valueFormatter: (params) => Helper.getOnlyNumber(params.value) },
        { field: 'amount', headerName: Helper.getLabel(language, 'amount', 'Amount'), valueFormatter: (params) => Helper.formateCurrency(params.value), hide: props.disableFinancial ? true : false },
        { field: 'accounting_date', headerName: Helper.getLabel(language, 'accounting_date', 'Accounting date'), valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), editable: false, filter:'agDateColumnFilter' },
    ];
    const masterSettings = {
        detailRowAutoHeight: true,
        masterDetail: true,
        detailCellRenderer: GridSegmentDistributionDetails,
    };

    return (
        <div className='segment_distribution_component'>
            {isPopupOpen && <SegmentDistributionPopup
                disableFinancial={props.disableFinancial ? true : false}
                totalAmount={props.totalAmount}
                source_id={props.source_id}
                integrator={props.integrator}
                distribution_source_id={distribution_source_id ?? props.distribution_source_id}
                language={language}
                onPopupClose={onPopupClose}
                gridSegmentObj={gridObj}
                onCreateFirstDistribution={onCreateFirstDistribution}
                onSegmentPopupClose={onSegmentPopupClose}
                accountingDate={props.accountingDate}
            />}
            <AgGridNew
                key={reRender ? 'rerender' : 'norerender'}
                addLabel={'Segment distribution'}
                isAddBtnDisable={!props.isEditable}
                isDeleteBtnDisable={!props.isEditable}
                gridId={id}
                onAddClick={onAddBtnClick}
                onGridReady={onGridReady}
                columnDefs={header}
                masterSettings={masterSettings}
                apiUrl={`${Settings.apiUrls.segments}/distribution_detail_by_source/${distribution_source_id}`}
                handleDeleteSelectedRows={onSegmentDelete}
                uniqueField={'distributiondetail_id'}
                returnApiCondition={!distribution_source_id}
            />
        </div>
    );
};

export default RsDataLoader(SegmentsDistribution, { segments: true, segmentsValues: true });
