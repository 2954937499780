import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Helper from '../../../../inc/Helper';

/*
Props:

This is our WrapperCollapse Component For Team Evaluation / Technical / Financial -

*/
 
class CriteriaSectionsCollapseWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: !props.open
    };
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed
    }), () => {
      Helper.refreshAppScript();
    });
  };

  render() {
    const { id, type, className, title, children, compliance, weight, language } = this.props;
    const { isCollapsed } = this.state;

    let collapseClassName = `rs_collapse ${className}`;
    if (isCollapsed) {
      collapseClassName += ' collapsed';
    }

    return (
      <div className="criteria_section">
        <div id={id} className={collapseClassName} open={!isCollapsed}>
          <div className="rs_collaps_title" onClick={this.toggleCollapse}>
            <span className="cl_icon"></span>
            <p>
              <span>{!compliance ? Helper.getLabel(language, 'section', 'Section:') : Helper.getLabel(language, 'category', 'Category:')} </span> {title}
            </p>
            {!compliance && 
              <div className="rs_collaps_info">
                <h2>
                  <span>{Helper.getLabel(language, 'type', 'Type:')} </span> {type}
                </h2>
                <h2>
                  <span>{Helper.getLabel(language, 'weight', 'Weight:')} </span> {weight}
                </h2>
              </div>
            } 
          </div>
          <div className="rs_collapse_contents p-0 ps-0 mr-0">{children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language
  };
};

export default connect(mapStateToProps)(CriteriaSectionsCollapseWrapper);