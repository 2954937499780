import React from 'react'
import MasterComonent from '../../components/Backend/MasterComonent'
import InnerScreen from '../../components/Inner Screens/InnerScreen'
import { Link } from 'react-router-dom'

const LynkazAdminScreens = () => {
    return (
        <div className="modules_screen_lynkaz">
            <MasterComonent>
                <InnerScreen innerTitle="Administration">
                    <div className='col-lg-3 col-md-4 col-6 gy-4'> 
                        <div className='inner-page-header border-bottom'>
                            <h2>Tenant</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/tenants'>Tenant</Link> 
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-6 gy-4'> 
                        <div className='inner-page-header border-bottom'>
                            <h2>Currency</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/currency'>Currency</Link>
                        </div>
                    </div> 
                    
                    <div className="col-lg-3 col-md-4 col-6 gy-4">
                        <div className="inner-page-header border-bottom">
                            <h2>Security</h2>
                        </div>
                        <div className="inner-page-routes">
                            <Link to="/form">Forms</Link>
                            <Link to="/security-roles">Security Roles</Link>
                            <Link to="/privileges">Privileges</Link>
                            <Link to="/resources">Resources</Link>
                            <Link to="/form-resources">Forms Resources</Link>
                            <Link to="/module">Module</Link>
                            <Link to="/resources-access">Resources Access</Link>
                            <Link to='/previleges-assignment'>Previleges Assignment</Link>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-6 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Api Master</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/api-master-forms'>Api Master</Link>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-6 gy-4'>
                        <div className='inner-page-header border-bottom'>
                            <h2>Translation</h2>
                        </div>
                        <div className='inner-page-routes'>
                            <Link to='/lynkaz-administration'>Translation</Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-6 gy-4">
                        <div className="inner-page-header border-bottom">
                            <h2>Batch Jobs</h2>
                        </div>
                        <div className="inner-page-routes">
                            <Link to="/lynkaz-administration">Batch Jobs</Link>
                            <Link to="/lynkaz-administration">Batch Jobs History</Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-6 gy-4">
                        <div className="inner-page-header border-bottom">
                            <h2>Language</h2>
                        </div>
                        <div className="inner-page-routes">
                            <Link to="/language">Language</Link>
                        </div>
                    </div>  

                    <div className="col-lg-3 col-md-4 col-6 gy-4">
                        <div className="inner-page-header border-bottom">
                            <h2>Labels Form</h2>
                        </div>
                        <div className="inner-page-routes">
                            <Link to="/labels-form">Labels</Link>
                        </div>
                    </div> 
                </InnerScreen>
            </MasterComonent>
        </div>
    )
}

export default LynkazAdminScreens;