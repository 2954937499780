import React, { useState, useEffect, useRef } from "react"
import Tabs from "../../../Widgets/Tabs/Tabs";
import Dropdown from '../../../Forms/Dropdown'
import Helper from '../../../../inc/Helper'
import Input from '../../../Forms/Input'
import SimpleLoading from '../../../Loading/SimpleLoading'
import { useLocation } from 'react-router-dom'
import RFQCommonApiCalls from '../../RFQCommonApiCalls'
import NewInputDatePicker from '../../../Forms/NewInputDatePicker'
import CommonApiCalls from '../../../External Screens/Purchase Order Screen/CommonApiCalls/CommonApiCalls'
import GeneralAdditionalInformation from './GeneralAdditionalInformation'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import RFQBiddingRequirement from "../../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQBiddingRequirement";
import Settings from "../../../../inc/Settings";
import Checkbox from "../../../Forms/Checkbox";
import GeneralAuction from "./GeneralAuction";
import { getSubdomain } from "../../../../inc/Validation";

const GeneralInformationForm = ({
  language,
  rfqHeader,
  isLoading,
  setRfqHeader,
  updateRFQ,
  setUpdateRFQ,
  employeesList,
  currencyList,
  securityLevelList,
  quotationTypeList,
  paymentTermList,
  modeOfDeliveryList,
  deliveryTermList,
  targetDocumentsList,
  buyersList,
  rfqStatus,
  isDisabled,
  isDisableAllFields=false,
  user,
  teamData,
  rfqStages,
  taxGroupList,
  taxGroupItemList,
  numberSequenceData
}) => {
  const [sites, setSites] = useState([])
  const [warehouse, setWarehouse] = useState([])
  const [documentStatus, setDocumentStatus] = useState([])
  const [rfqHeaderKeyPressData, setRfqHeaderKeyPressData] = useState(null)

  let sitesWarehouseCall = false
  const location = useLocation()
  const subdomain = getSubdomain()
  const commonApiCalls = new CommonApiCalls()
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  const generalNavs = [
    { title: "Basic", isActive: true },
    { title: "Additional Information" },
    // { title: "Price and Discount" },
    { title: "Requirements" },
    {title: "Operational Controls"},
  ];
  if(subdomain==='gts-dev'){
    generalNavs.push({title: "Auction"})
  }
  const rfqCommonApiCalls = new RFQCommonApiCalls()

  const employeesData = useRef([])
  const mapEmployeesData = (item) => {
    return {
      id: item.employee_id,
      text: item.displayname,
    };
  }

  useEffect(() => {
    const getAsyncData = async () => {
      // here we check and store localStore data into variable...
      try {
        if (sitesWarehouseCall) {
          return
        }
        sitesWarehouseCall = true
        const sites = await rfqCommonApiCalls.getAllSites()
        const warehouse = await rfqCommonApiCalls.getAllWarehouses()

        setTimeout(() => {
          setSites(sites)
          setWarehouse(warehouse)
        }, 100)
      } catch (err) {
        console.log(err)
      }
    }

    getAsyncData()
    getDocumentStatusData()
  }, [])

  //function to fetch status
  const getDocumentStatusData = async () => {
    const document_statuses = await commonApiCalls.getAllDocumentStatus()
    if (document_statuses) setDocumentStatus(document_statuses)
  }

  // this is for document status
  const rfqDocumentStatus = (status) => {
    let matchStatus = documentStatus.filter((item) => {
      if (item.id === status) {
        return item?.name
      }
    })
    return matchStatus[0]?.name
  }
 
  const getSiteNameFromId = (id) => {
    return sites?.filter((item) => item.site_id == id)[0]?.site_id
  }

  const getWarehouseNameFromId = (id) => {
    return warehouse?.filter((item) => item.warehouse_id == id)[0]?.warehouse_id
  }

  const onInputCheckboxChangeHandler = (name, value) => {
    if (value === 'on') {
      setRfqHeader((prevState) => {
        return {
          ...prevState,
          price_include_tax: true,
        }
      })
      setUpdateRFQ((prevState) => {
        return {
          ...prevState,
          price_include_tax: true,
        }
      })
    } else {
      setRfqHeader((prevState) => {
        return {
          ...prevState,
          price_include_tax: false,
        }
      })
      setUpdateRFQ((prevState) => {
        return {
          ...prevState,
          price_include_tax: false,
        }
      })
    }
  }

  let paymentTermListOptions
  paymentTermList.forEach((item) => {
    paymentTermListOptions?.push({
      value: item.paymentterm_id,
      label: `${item.code} - ${item.name}`,
    })
  })

let targetDocumentsListOptions = []
  targetDocumentsList.forEach((item) => {
    targetDocumentsListOptions?.push({
      value: item.id,
      label: item.name,
    })
  })

  let rfqStagesList = []
  rfqStages.forEach((item)=>{
    rfqStagesList?.push({
      value:item.id,
      label:item.name,
    })
  })

  // here we filter site for Dropdown...
  const siteOption = (items) => {
    let siteFilterData = items.filter((item) => {
      return item.entity_id == rfqHeader?.entity_id
    })
    return siteFilterData.map((optionItem) => {
      return {
        value: optionItem.site_id,
        label: optionItem.name,
      }
    })
  }

  // here we filter site for Dropdown...
  const werehouseOption = (items) => {
    let warehouseFilterData = items.filter((item) => {
      return item.site_id == rfqHeader?.site_id
    })
    return rfqHeader?.warehouse_id ? [{
      value: rfqHeader.warehouse_id,
      label: rfqHeader.warehouse_name,
    }]:  warehouseFilterData.map((optionItem) => {
      return {
        value: optionItem.warehouse_id,
        label: optionItem.name,
      }
    })
  }

  const handleInputChange = (e) => {
    setRfqHeaderKeyPressData({
      ...rfqHeaderKeyPressData,
      [e.target.name]: e.target.value,
    })
    if (e.target.value === '') {
      setRfqHeader({ ...rfqHeader, [e.target.name]: e.target.value })
      setUpdateRFQ({ ...updateRFQ, [e.target.name]: e.target.value })
    }
  }

  const handleOnChange = (e) => {
    let value = e.target.value
    if(e.target.name==="target_document" || e.target.name==="quotation_type_id"){
       value=parseInt(e.target.value)
    }

    setRfqHeader(prev => {
      const updatedRfqHeader = { ...prev, [e.target.name]: value };
      if (e.target.name === "requester_id") {
        const requesterName = employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(value))?.displayname;
        updatedRfqHeader.requester_name = requesterName;
      }
      if (e.target.name === "orderer_id") {
        const ordererName = employeesData?.current?.find(item => parseInt(item.employee_id) === parseInt(value))?.displayname;
        updatedRfqHeader.orderer_name = ordererName;
      }
      return updatedRfqHeader;
    });
    setUpdateRFQ(prev => ({ ...prev, [e.target.name]: value }));
  }

  let biddingTypeOptions = [
    {
      value:1,
      label:"Normal Bidding"
    },
    {
      value:2,
      label:"Auction Bidding"
    },
  ]

  return (
    <div className='generalInformation'>
      {(isLoading || !rfqHeader) && !getSearchParam('new') ? (
        <SimpleLoading />
      ) : (
        <Tabs navs={generalNavs} showAll={false} disableSticky={true}>
          <div className='generalInformation-basic'>
            <div className='generalInformation-basic-grid'>
              <Input
                label={`${Helper.getLabel(
                  language,
                  'document_number',
                  'Document number'
                )}`} 
                name='document_number'
                value={rfqHeaderKeyPressData?.document_number || rfqHeader?.document_number}
                onChange={handleInputChange}
                onBlur={handleOnChange}
                disable={rfqHeader?.version >= 1 || numberSequenceData?.ns_type === 'continuous' || isDisableAllFields}
                required
              />

              <div className='generalInformation-oldCartItem-grid1-info'>
                <label>{Helper.getLabel(language, 'version', 'Version')}</label>
                <span>{rfqHeader?.version ?? '-'}</span>
              </div>

              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  'rfq_status',
                  'RFQ status'
                )}`}
                value={rfqStatus}
              />
              <InputPlaceholder
                label={`${Helper.getLabel(
                  language,
                  'document_status',
                  'Document status'
                )}`}
                value={rfqDocumentStatus(rfqHeader?.document_status)}
              />

              <Input
                label={`${Helper.getLabel(language, 'title', 'Title')}`}
                value={ rfqHeaderKeyPressData?.title || rfqHeader?.title }
                name='title'
                onChange={handleInputChange}
                onBlur={handleOnChange}
                disable={
                  rfqHeader?.rfq_status === 3 || (!user?.is_buyer && teamData?.access_level <= 1)
                }
                required
              />
               <Input
                label={`${Helper.getLabel(
                  language,
                  'description',
                  'Description'
                )}`}
                value={rfqHeaderKeyPressData?.description || rfqHeader?.description}
                name='description'
                onChange={handleInputChange}
                maxLength={255}
                onBlur={handleOnChange}
                disable={
                  rfqHeader?.rfq_status === 3 || (!user?.is_buyer && teamData?.access_level <= 1)
                }
              />
              <Dropdown
                label={`${Helper.getLabel(language, 'buyer', 'Buyer')}`}
                options={buyersList?.map((item) => {
                  return {
                    value: item.employee_id,
                    label: item.displayname,
                  }
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_buyer',
                  'Select buyer'
                )}`}
                id='rfq_general_buyer'
                name='buyer_id'
                onChange={handleOnChange}
                pagination={true} 
                apiUrl={Settings?.apiOrgUrl + `/employee_dropdown`} 
                allDataRef={employeesData}
                mapFunction={mapEmployeesData}
                value={rfqHeader?.buyer_id}
                disableClear={true}
                isDisable={
                  isDisabled
                }
              />
               <NewInputDatePicker
                label={`${Helper.getLabel(
                  language,
                  'start_date',
                  'Start date'
                )}`}
                id='start_date'
                name='start_date'
                onChange={(date) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    start_date:
                      DatepickerFunctions.convertDateForDataBase(date),
                  })
                  setRfqHeader({
                    ...rfqHeader,
                    start_date: date,
                  })
                }}
                value={rfqHeader?.start_date}
                minDate={new Date()}
                disable={ rfqHeader?.rfq_status === 3 || (!user?.is_buyer && teamData?.access_level <= 1)}
                empty={true}
              /> 
              <NewInputDatePicker
              label={`${Helper.getLabel(
                language,
                'end_date',
                'End date'
              )}`}
              id='end_date'
              name='end_date'
              onChange={(date) => {
                setUpdateRFQ({
                  ...updateRFQ,
                  end_date:
                    DatepickerFunctions.convertDateForDataBase(date),
                })
                setRfqHeader({
                  ...rfqHeader,
                  end_date: date,
                })
              }}
              value={rfqHeader?.end_date}
              minDate={new Date()}
              disable={ rfqHeader?.rfq_status === 3 || (!user?.is_buyer && teamData?.access_level <= 1)}
              empty={true}
              />
              <NewInputDatePicker
                label={`${Helper.getLabel(
                  language,
                  'delivery_date',
                  'Delivery date'
                )}`}
                id='delivery_date'
                name='delivery_date'
                onChange={(date) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    delivery_date:
                      DatepickerFunctions.convertDateForDataBase(date),
                  })
                  setRfqHeader({
                    ...rfqHeader,
                    delivery_date: date,
                  })
                }}
                value={rfqHeader?.delivery_date}
                minDate={new Date()}
                disable={ rfqHeader?.rfq_status === 3 || (!user?.is_buyer && teamData?.access_level <= 1) }
              />
              <NewInputDatePicker
                label={`${Helper.getLabel(
                  language,
                  'expiry_date_time',
                  'Expiry date time'
                )}`}
                id='expiry_datetime'
                name='expiry_date_time'
                onChange={(date) => {
                  setUpdateRFQ({
                    ...updateRFQ,
                    expiry_datetime:
                      DatepickerFunctions.convertDateTimeForDataBase(date),
                  })
                  setRfqHeader({
                    ...rfqHeader,
                    expiry_datetime: date,
                  })
                }}
                value={rfqHeader?.expiry_datetime}
                timePicker={true}
                disable={
                  (rfqHeader?.rfq_status !== 0 && rfqHeader?.rfq_status !== 9) || (!user?.is_buyer && teamData?.access_level <= 1)
                }
                required
                minDate={new Date()}
              />
              <NewInputDatePicker
                label={`${Helper.getLabel(
                  language,
                  'last_publish_date_time',
                  'Last publish date and time'
                )}`}
                id='last_publish_date_time'
                name='last_publish_date_time'
                empty={!rfqHeader?.publish_datetime}
                value={rfqHeader?.publish_datetime}
                timePicker={true}
                disable={true}
              />
              {/* Commented for future implementation */}
              {/* <Dropdown
                label={`${Helper.getLabel(
                  language,
                  'security_level',
                  'Security level'
                )}`}
                options={securityLevelList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  }
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_security_level',
                  'Select security level'
                )}`}
                id='rfq_general_security_level'
                name='security_level'
                onChange={handleOnChange}
                value={rfqHeader?.security_level}
                isDisable={
                  isDisabled
                }
                required
              /> */}
              <Dropdown
                label={`${Helper.getLabel(
                  language,
                  'quotation_type',
                  'Quotation type'
                )}`}
                options={quotationTypeList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  }
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_quotation_type',
                  'Select quotation type'
                )}`}
                id='rfq_general_quotation_type'
                name='quotation_type_id'
                onChange={handleOnChange}
                value={rfqHeader?.quotation_type_id}
                isDisable={
                  isDisabled || rfqHeader?.rfq_status === 1
                }
                required
              />
              {rfqHeader?.quotation_type_id != 0 &&
              rfqHeader?.quotation_type_id != null ? (
                <Input
                  label={`${Helper.getLabel(
                    language,
                    'unsealed_by',
                    'Unsealed by'
                  )}`}
                  placeHolder={`${Helper.getLabel(
                    language,
                    'unsealed_by',
                    'Unsealed by'
                  )}`}
                  name='unsealed_by'
                  onChange={handleOnChange}
                  id='unsealed_by'
                  disable={true}
                  value={rfqHeader?.unsealed_by_name}
                />
              ) : null}
              {rfqHeader?.quotation_type_id != 0 &&
              rfqHeader?.quotation_type_id != null ? (
                <NewInputDatePicker
                  label={`${Helper.getLabel(
                    language,
                    'unsealed_date_and_time',
                    'Unsealed date and time'
                  )}`}
                  id='unsealed_date_time'
                  name='unsealed_date_time'
                  onChange={(date) => {
                    setUpdateRFQ({
                      ...updateRFQ,
                      unsealed_datetime: date,
                    })
                    setRfqHeader({
                      ...rfqHeader,
                      unsealed_datetime: date,
                    })
                  }}
                  empty={true}
                  value={rfqHeader?.unsealed_datetime}
                  timePicker={true}
                  disable={true}
                />
              ) : null}
              <Input
                label={`${Helper.getLabel(
                  language,
                  'attention_information',
                  'Attention information'
                )}`}
                name='attention_information'
                maxLength={255}
                value={
                  rfqHeaderKeyPressData?.attention_information || rfqHeader?.attention_information
                }
                onChange={handleInputChange}
                onBlur={handleOnChange}
                disable={
                  rfqHeader?.rfq_status === 3 || (!user?.is_buyer && teamData?.access_level <= 1)
                }
              /> 
             <InputPlaceholder
                label={`${Helper.getLabel(language, 'entity', 'Entity')}`}
                value={rfqHeader?.entity_name}
                // disabledField={rfqHeader?.rfq_status === 1 ? true : false}
              />

              <Dropdown
                label={`${Helper.getLabel(language, 'site', 'Site')}`}
                options={siteOption(sites)}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_site',
                  'Select Site'
                )}`}
                id='rfq_general_select_site'
                name='site_id'
                required={true}
                onChange={handleOnChange}
                value={getSiteNameFromId(rfqHeader?.site_id)}
                reRenderRequired={true}
                disableClear={true}
                isDisable={
                  isDisabled
                }
              />

              <Dropdown
                label={`${Helper.getLabel(language, 'warehouse', 'Warehouse')}`}
                options={werehouseOption(warehouse)}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_warehouse',
                  'Select Warehouse'
                )}`}
                required={true}
                id='rfq_general_warehouse'
                name='warehouse_id'
                onChange={handleOnChange}
                value={getWarehouseNameFromId(rfqHeader?.warehouse_id)}
                reRenderRequired={true}
                disableClear={true}
                isDisable={
                  isDisabled
                }
              />
               <Dropdown
                label={`${Helper.getLabel(language, 'stages', 'Stages')}`}
                options={rfqStagesList}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_stages',
                  'Select stages'
                )}`}
                id='rfq_stages'
                name='rfq_stages'
                onChange={handleOnChange}
                reRenderRequired={true}
                value={rfqHeader?.rfq_stages_id}
                isDisable={isDisabled}
                disableClear={true}
              />
              <Dropdown
                label={`${Helper.getLabel(language, 'target_document', 'Target document type')}`}
                options={targetDocumentsListOptions}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_target_document',
                  'Select target document type'
                )}`}
                id='rfq_target_document'
                name='target_document'
                onChange={handleOnChange}
                value={rfqHeader?.target_document}
                isDisable={(!user?.is_buyer && teamData?.access_level !== 2) || isDisableAllFields || rfqHeader?.rfq_status===3 || rfqHeader?.onhold || rfqHeader?.rfq_status===2}
              />
              {subdomain === 'gts-dev' && <Dropdown
                label={`${Helper.getLabel(language, 'bidding_type', 'Bidding type')}`}
                options={biddingTypeOptions}
                placeHolder={`${Helper.getLabel(
                  language,
                  'select_bidding_type',
                  'Select bidding type'
                )}`}
                id='rfq_bidding_type'
                name='bidding_type'
                onChange={handleOnChange}
                value={rfqHeader?.bidding_type}
                isDisable={isDisabled}
              />}
            </div>
          </div>
          <GeneralAdditionalInformation
            rfqHeader={rfqHeader}
            handleOnChange={handleOnChange}
            language={language}
            rfqHeaderKeyPressData={rfqHeaderKeyPressData}
            handleInputChange={handleInputChange}
            deliveryTermList={deliveryTermList}
            employeesList={employeesList}
            modeOfDeliveryList={modeOfDeliveryList}
            currencyList={currencyList}
            paymentTermList={paymentTermList}
            isDisabled={isDisabled}
            taxGroupList={taxGroupList}
            taxGroupItemList={taxGroupItemList}
            employeesData={employeesData}
            mapEmployeesData={mapEmployeesData}
            onInputCheckboxChangeHandler={onInputCheckboxChangeHandler}
          />
          {/* <GeneralPriceAndDiscount
            rfqHeader={rfqHeader}
            rfqHeaderKeyPressData={rfqHeaderKeyPressData}
            handleInputChange={handleInputChange}
            language={language}
            handleOnChange={handleOnChange}
            onInputCheckboxChangeHandler={onInputCheckboxChangeHandler}
            paymentTermList={paymentTermList}
            isDisabled={isDisabled}
          /> */}
          <div className="bidding_requirement rfq-internal-requirements">
          <RFQBiddingRequirement
            scopeOfWork={rfqHeaderKeyPressData?.scope_of_work || rfqHeader?.scope_of_work}
            materialsAndServicesNotIncluded={
              rfqHeaderKeyPressData?.material_service_not_included || rfqHeader?.material_service_not_included
            }
            agreementTerms={rfqHeaderKeyPressData?.requirement_description || rfqHeader?.requirement_description}
            type="rfq"
            disable={isDisabled}
            onRequirementChange={handleInputChange}
            onRequirementBlur={handleOnChange}
          />
          </div>
          <div>
            <div className='row'>
              <div className="col-sm-3">
                <Checkbox
                  id='non_compliance_request'
                  type='checkbox'
                  name='non_compliance_request'
                  disableWithoutIcon={true}
                  label='Purchase requisition non compliance'
                  isChecked={rfqHeader?.non_compliance_request}
                />
              </div>
              <div className="col-sm-3">
                <Checkbox
                  id='direct_tender'
                  type='checkbox'
                  name='direct_tender'
                  disableWithoutIcon={true}
                  label='Direct tender'
                  isChecked={rfqHeader?.direct_tender}
                />
              </div>
            </div>
          </div>
          {subdomain==='gts-dev' && <GeneralAuction 
            rfqHeader={rfqHeader}
            setRfqHeader={setRfqHeader}
            setUpdateRFQ={setUpdateRFQ}
            updateRFQ={updateRFQ}
            handleOnChange={handleOnChange}
            language={language}
            rfqHeaderKeyPressData={rfqHeaderKeyPressData}
            handleInputChange={handleInputChange}
            isDisabled={true} 
          />}
        </Tabs>
      )}
    </div>
  )
}
const InputPlaceholder = ({
  label,
  value,
  disabledField = true,
  required = false,
}) => {
  return (
    <div className='RFQ_input_placeholder'>
      <label htmlFor='input'>
        {label} {required ? <span style={{ color: 'red' }}>*</span> : ''}
      </label>
      <input type='text' disabled={disabledField} value={value} />
    </div>
  )
}

export default GeneralInformationForm
