import React from 'react'


const CommonChartCard = ({ title, children }) => {
  return (
    <div className="chart-outer-div">
      <div className="row" style={{marginBottom:"1.5rem"}}>
        <div className="col-sm-12 col-lg-12">
          <h5 className="fw-bold">{title}</h5>
        </div>
      </div>
      {children}
    </div>
  )
}

export default CommonChartCard
