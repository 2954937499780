import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import AddressPurposeReducer from '../reducers/AddressPurposeReducer';
import AllSourceReducer from '../reducers/AllSourceReducer';
import AttachmentTypes from '../reducers/AttachmentsTypeReducer';
import AuthReducer from '../reducers/AuthReducer';
import ContactTypesReducer from '../reducers/ContactTypesReducer';
import DateFormateReducer from '../reducers/DateFormateReducer';
import EntityReducter from '../reducers/EntityReducer';
import LanguageReducer from '../reducers/LanguageReducer';
import Locations from '../reducers/Locations';
import NoteReducers from '../reducers/NoteReducers';
import OptionsReducer from '../reducers/OptionsReducer';
import PRShopReducers from '../reducers/PRShopReducer';
import SecurityRolesReducer from '../reducers/SecurityRolesReducer';
import SiteReducer from '../reducers/SiteReducer';
import TaxtSettlementPeriodsRedcer from '../reducers/TaxtSettlementPeriodsReducer';
import ThemeReducer from '../reducers/ThemeReducer';
import VendorReducer from '../reducers/VendorReducer';
import dataGrid from './data-grid';
import gridID from './grid-id';
import systemAPI from './system-params';
import userAuth from './user-auth';
import PurchaseStatusReducer from '../reducers/PurchaseStatusReducer';
import WarehouseReducer from '../reducers/WarehouseReducer';
import SegmentsReducer from '../reducers/SegmentsReducer';
import SegmentsValuesReducer from '../reducers/SegmentsValuesReducer';
import IsMasterContentLoaderReducer from '../reducers/IsMasterContentLoaderReducer';
import LineAccessLevelReducer from '../reducers/LineAccessLevelReducer';
import PoHeaderTypesReducer from '../reducers/PoHeaderTypesReducer';
import LineSiteStatusReducer from '../reducers/LineSiteStatusReducer';
import EmployeeReducer from '../reducers/EmployeeReducer';
import TaxGroupReducer from '../reducers/TaxGroupReducer';
import TaxcodesReducer from '../reducers/TaxcodesReducer';
import AmendmentNatureReducer from '../reducers/AmendmentNatureReducer';
import PoDocumentStatusReducer from '../reducers/PoDocumentStatusReducer';
import PRCartReducer from '../reducers/PrCartInfo';  
import LogoUrlReducer from '../reducers/LogoUrlReducer';

// import PRShopReducers from '../reducers/PRSHopReducer';

//Redux SAGA implemented for managing the data flow of the entire application.

const store = configureStore(
    {
        reducer: {
            authSlice : userAuth.reducer,
            gridSlice : dataGrid.reducer, 
            auth: AuthReducer,
            allSources: AllSourceReducer,
            language: LanguageReducer,
            options: OptionsReducer,
            locations: Locations,
            entity: EntityReducter,
            securityRoles: SecurityRolesReducer,
            attachmentTypes: AttachmentTypes,
            sites: SiteReducer,
            system: systemAPI.reducer,
            gridIdFlow: gridID.reducer,
            addressPurpose: AddressPurposeReducer,
            contactTypes: ContactTypesReducer,
            notes: NoteReducers,
            dateFormate: DateFormateReducer,
            theme: ThemeReducer,
            vendor: VendorReducer,
            prShop: PRShopReducers,
            TaxSmtPeriod: TaxtSettlementPeriodsRedcer,
            purchaseStatus:PurchaseStatusReducer,
            warehouse:WarehouseReducer,
            segments:SegmentsReducer,
            segmentsValues:SegmentsValuesReducer,
            isMasterContentLoader:IsMasterContentLoaderReducer,
            lineAccessLevel:LineAccessLevelReducer,
            poHederTypes:PoHeaderTypesReducer,
            lineSiteStatus:LineSiteStatusReducer,
            employee:EmployeeReducer,
            taxgroup:TaxGroupReducer,
            taxcodes:TaxcodesReducer,
            amendmentNature:AmendmentNatureReducer,
            poDocumentStatus:PoDocumentStatusReducer,
            prCart:PRCartReducer,
            url:LogoUrlReducer
            // More slices to manage different states
        },
        //middleware: [thunk],
    }
)

export default store;