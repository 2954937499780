// currently working on it.........
import React, {useState } from 'react'
import Popup from '../../Popup/Popup';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { Form } from 'react-bootstrap';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import { getExtensionHistoryColumns, getPublishHistoryColumns } from './HeaderView/ColumnsHeader/ColumnsHeader';

let gridObj = null

const PublishedHistoryModal = ({
  language,
  handleCloseModal,
  rfqHeader,
  rfq_id
}) => {
  const [selectedHistoryType,setSelectedHistoryType] = useState(null)
  const [loading,setLoading]=useState(false)
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  const toggleTextVisibility = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  const handleHistoryTypeChange = (value) => {
    setSelectedHistoryType(value)
    setLoading(true)
    setTimeout(()=>{ setLoading(false) },1200)
    gridObj?.api?.refreshServerSide({purge:true})
 }

  const handleGridReady = (params) => {
    gridObj = params
  }

  const handleFetchData = (response) => {
    let data =  Object.entries(response?.data?.data).map(([date, items]) => ({ date, items }));
    return {
      rowData:data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}` 
      })),
      rowCount: data?.length,
    }
  }

  const Columns_Headings1 = [
    {
      field: "date",
      headerName: Helper.getLabel(language, "publish_date", "Publish Date"),
      editable: false,
      cellRenderer:'agGroupCellRenderer',
      valueFormatter:(params)=> DatepickerFunctions.convertDateFromDataBase(params.value)
    },
  ]

  const Columns_HeadingsExtension1 = [
    {
      field: "date",
      headerName: Helper.getLabel(language, "modification_date", "Modification Date"),
      editable: false,
      cellRenderer:'agGroupCellRenderer',
      valueFormatter:(params)=> DatepickerFunctions.convertDateFromDataBase(params.value)
    },
  ]

  const Columns_Headings =  getPublishHistoryColumns({
    language:language
  })
  
  const Columns_Headings_Extensions = getExtensionHistoryColumns({
    language:language
  })

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: selectedHistoryType===0?Columns_Headings:Columns_Headings_Extensions,},

        getDetailRowData: function (params) {
            params.successCallback(params?.data?.items);
        }
    },
};

  let apiEndPoint = selectedHistoryType===0? `/publish_history/${rfq_id}`: `/extensions_history/${rfq_id}`

  return (
    <Popup
      autoOpen={true}
      width="1100px"
      onClose={() => {
        handleCloseModal();
      }}
    >
      <>
        <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, "publish_history", "RFQ Publish History")}</h5>
        <div className='rfq-publish-history-header mt-3'>
          <h6 className="heading-field mb-0 w-25">
            {Helper.getLabel(
              language,
              "rfq_description",
              "RFQ Description"
            )}
          </h6>
          <h6 className='mb-0 float-end' style={{wordBreak:'break-word',cursor:'pointer'}} onClick={toggleTextVisibility}>
            {isFullTextVisible ? rfqHeader?.title : rfqHeader?.title?.substring(0, 45) + '...'}
          </h6>
        </div>
        <div>
          <h6 className='mt-3'>Please choose option</h6>
          <div className='d-flex mt-3 gap-3'>
            <Form.Check
              type='radio'
              label="Publish History"
              value={selectedHistoryType}
              checked={selectedHistoryType===0}
              onChange={()=>{handleHistoryTypeChange(0)}}
            />
             <Form.Check
              type='radio'
              label="Extension History"
              value={selectedHistoryType}
              checked={selectedHistoryType === 1}
              onChange={()=>{handleHistoryTypeChange(1)}}
            />
          </div>
        </div>
       {selectedHistoryType===null?<h6 className='mt-3'>No option has been chosen yet.</h6>:
         <div className='mt-4'>
          {loading ? <SimpleLoading /> :
          <AgGridNew 
            gridId="published_history_grid"
            columnDefs={selectedHistoryType===0?Columns_Headings1:Columns_HeadingsExtension1}
            apiUrl={Settings.apiPurchaseRequisitionUrl+ apiEndPoint}
            height={500}
            hideAddBtn={true}
            hideDeleteBtn={true}
            rowType="single"
            masterSettings={masterSettings}
            onGridReady={handleGridReady}
            fetchData={handleFetchData}
          />
          }
          
        </div>}
      </>
    </Popup>
  )
}

export default PublishedHistoryModal