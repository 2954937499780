import React, { Fragment } from 'react'
import TabInfoWidget from '../TabInfoWidget'

const CommonTabCards = ({getPercentage,totalCount,dataDraft,dataConfirmed,onTabClickStatus1,onTabClickStatus2,onTabClickStatus3,onTabClickStatus4,isUnderReview,isPaid,dataUnderReview,dataPaid,secondCardStatus,firstCardStatus,thirdCardStatus,fourthCardStatus}) => {
  return (
    <Fragment>
      <div className='col-3'>
        <TabInfoWidget
          percentage={dataDraft===0?'0%':`${parseInt(
            getPercentage(
              dataDraft,
              totalCount
            )
          )}%`}
          textColor={'#FF4697'}
          taskType={firstCardStatus?firstCardStatus:'Draft'}
          taskCount={dataDraft}
          percentageData={dataDraft === 0 ? 0 :  getPercentage(
            dataDraft,
            totalCount,
          )}
          onTabClick={onTabClickStatus1}
        />
      </div>
      <div className='col-3' style={{ marginLeft: '16px' }}>
        <TabInfoWidget
          percentage={dataConfirmed === 0 ? "0%"  :`${parseInt(
            getPercentage(
              dataConfirmed,
              totalCount,
            )
          )}%`}
          textColor={'#00CE7D'}
          taskType={secondCardStatus?secondCardStatus:"Confirmed"}
          taskCount={dataConfirmed}
          percentageData={dataConfirmed === 0 ? 0  :getPercentage(
            dataConfirmed,
            totalCount
          )}
          onTabClick={onTabClickStatus2}
        />
      </div>
      {isUnderReview && 
       <div className='col-3' style={{ marginLeft: '16px' }}>
        <TabInfoWidget
          percentage={dataConfirmed === 0 ? "0%"  :`${parseInt(
            getPercentage(
              dataUnderReview,
              totalCount,
            )
          )}%`}
          textColor={'#5E9BFC'}
          taskType={thirdCardStatus?thirdCardStatus:'Under Review'}
          taskCount={dataUnderReview}
          percentageData={dataUnderReview === 0 ? 0  :getPercentage(
            dataUnderReview,
            totalCount
          )}
          onTabClick={onTabClickStatus3}
        />
       </div>
      }
      {isPaid && 
       <div className='col-3' style={{ marginLeft: '16px' }}>
        <TabInfoWidget
          percentage={dataPaid === 0 ? "0%"  :`${parseInt(
            getPercentage(
              dataPaid,
              totalCount,
            )
          )}%`}
          textColor={'#FDBB1F'}
          taskType={fourthCardStatus?fourthCardStatus:'Paid'}
          taskCount={dataPaid}
          percentageData={dataPaid === 0 ? 0  :getPercentage(
            dataPaid,
            totalCount
          )}
          onTabClick={onTabClickStatus4}
        />
       </div>
      }
    </Fragment>
  )
}

export default CommonTabCards
