import React, { useCallback } from "react";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import $ from "jquery";
import Settings from "../../../inc/Settings";
import Popup from "../../Popup/Popup";
import { connect } from "react-redux";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import AgGridNew from "../../grid/ag/ag-grid-new";

let gridApi = null;
let AddBtn;
let transaction;

/* Barcode 13 characters validation is removed, when grid validation feature adds, re-add it */

const Barcodes = ({ item_id, isDisable=false, setOpenBarcodePopup, language }) => {

  const Editable = (event) => {
    if (!item_id && !event.data.barcode) {
      return true;
    }
    if (item_id && !event.data.item_id) {
      return true;
    } 
    return false;
  };
   
  const CloseHandler = () => {
    setOpenBarcodePopup(false);
    $(".item_barcodes .rs_popup_close").trigger("click");
  };
 
  const onRowValueChanged = (event) => {
    if(item_id){
      addItemBarcode(event)
    }
  };

  const addItemBarcode = async (event) => { 
    try {
      let payload = {
        item_id: item_id,
        barcode: event?.data?.barcode
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiItemUrl + "/itembarcode", payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert('Barcode Added Successfully!');
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const handleBtn = (value,transactionData) => {
    AddBtn = value
    transaction = transactionData
  };

  const handleDeleteSelectedRows = useCallback(async (element) => {
    let api = Api
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl + `/barcode/${item_id}/${encodeURIComponent(element?.data?.barcode)}`)
      const rowNode = gridApi?.getRowNode(element?.data?.gridId)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction) 
      }, 200)
      gridApi?.refreshServerSide({ purge: true });
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  /* Grid Column Headers */

  const gridColsHeader = [
    {
      field: "barcode", 
      headerName: Helper.getLabel(language, "barcode", "Barcode"),
      editable: Editable,
      filter: 'agTextColumnFilter'
    }
  ];
  return ( 
    <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}> 
      <AgGridNew
        apiUrl={Settings.apiItemUrl+`/get_barcodes/${item_id}`}
        pagination={false}
        columnDefs={gridColsHeader}
        onRowValueChanged={onRowValueChanged}
        handleDeleteSelectedRows={handleDeleteSelectedRows}
        onGridReady={(params) => gridApi = params?.api}
        handleAddButton={handleBtn}
        isAddBtnDisable={isDisable}
        isDeleteBtnDisable={isDisable}
        uniqueField={`item_id`}
        gridId={'rs_popup_open_barcodes_roles'}
      /> 
    </Popup> 
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(Barcodes); 