import axios from 'axios';
import React, { Fragment,useState } from 'react';
import $ from 'jquery'; 
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { connect } from 'react-redux';
import Button from '../../inc/Button';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Helper from '../../../inc/Helper';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

let cancelTokenSource = axios.CancelToken.source();
const VendorContactTypeModal = ({setShowCreateModal, contactTypeData, setContactTypeData, reloadParentGrid, language}) => {  
    const [apiCallBtn, setApiCallBtn] = useState(false);
     
    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [  
            {name:'description', displayName:Helper.getLabel(language,'description','Description'),types:['Required']}
        ];
      
        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    const fieldsArrayGeneral = [
        {className:'col-lg-4', required: true, maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'description',"Description"), inputType: "text", id:'description', name:'description'},
        {className:'col-lg-4', required: false, maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'minimum_count',"Minimum count"), inputType: "number", id:'minimum_count', name:'minimum_count'},
        {className:'col-lg-4', required: false, maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'is_active',"Is active"), inputType: "checkbox", id:'is_active', name:'is_active'},
        {className:'col-lg-4', required: false, maxLength: 255, disable: apiCallBtn, label: Helper.getLabel(language,'is_mandatory',"Mandatory"), inputType: "checkbox", id:'is_mandatory', name:'is_mandatory'},
    ];

    let payload = {
        description:contactTypeData?.description,
        is_active:contactTypeData?.is_active === 'on' || contactTypeData?.is_active=== true ? true : false,
        minimum_count:contactTypeData?.minimum_count,
        is_mandatory:contactTypeData?.is_mandatory === 'on' || contactTypeData?.is_mandatory === true ? true : false
    }
 
    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().post(Settings.apiVendorUrl + '/vendorcontacttype', payload,
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
                onPopupClose(); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };
 
    const executePUTAPI = async () => {
        console.log(contactTypeData,payload)
        try { 
            let api = Api;
            api.setUserToken();
        
            const res = await api.axios().put(Settings.apiVendorUrl+`/vendorcontacttype/${contactTypeData?.contacttype_id}`, payload,
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                reloadParentGrid();
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    };

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...contactTypeData})){
            formValidatory.displayMessage();
            formValidatory.reset();
            return;
        };
     
        if(!contactTypeData?.contacttype_id){
            Helper.createDebouncedAPIFunction( 
                [executePOSTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        } else {
            Helper.createDebouncedAPIFunction( 
                [executePUTAPI], 
                setApiCallBtn,
                cancelTokenSource
            )();
        }
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">{!contactTypeData?.contacttype_id?'Create Vendor Contact Type':'Edit Vendor Contact Type'}</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general_info",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setContactTypeData} currentState={contactTypeData} fieldsArray={fieldsArrayGeneral} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        isDisable={apiCallBtn}
                        className='black-btn-style'
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}                        
                        className={contactTypeData?.contacttype_id?"rfq_save_btn":"solid_btn" } 
                        isActive={!contactTypeData?.contacttype_id}
                        title={!contactTypeData?.contacttype_id?Helper.getLabel(language, "create", "Create"):Helper.getLabel(language, "save", "Save")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (VendorContactTypeModal);