import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../inc/Api';
import FormValidator from '../../inc/FormValidator';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Input from '../Forms/Input';
import RsDataLoader from '../inc/RsDataLoader';
import SimpleLoading from '../Loading/SimpleLoading';
import AddressList from '../Tenant/Address/AddressList';
import ActionTypes from '../../inc/ActionsTypes';

class AccountBasicInformation extends Component {
    constructor(props){
        super(props);
        this.validationConfigure()
        this.state = {
            employee_called: false,
            user_called: false, 
            isLoading:false,
            userData:{
              first_name:'/images/blank-profile.jpg',
              last_name:'',
              middle_name: '', 
              image:null
            }
        }
    }
 
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [ 
            {name:'first_name',displayName:Helper.getLabel(language,'first_name','First Name'),types:['Required']},
            {name:'last_name',displayName:Helper.getLabel(language,'last_name','Last Name'),types:['Required']},
            {name:'user_name',displayName:Helper.getLabel(language,'user_name','User Name'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }

    componentDidMount(){
        const imageData = this.props?.dataLoaderProps?.auth?.user?.Image?.slice(2,-1)
        const imageUrl = `data:image/${this.props?.dataLoaderProps?.auth?.user?.Image_type};base64,${imageData}`;
            
            this.getUserDetails(
                this.props.dataLoaderProps.auth.user.first_name,
                this.props.dataLoaderProps.auth.user.last_name, 
                this.props.dataLoaderProps.auth.user.user_name,
                this.props.dataLoaderProps.auth.user.middle_name,
                imageUrl,
                this.props.dataLoaderProps.auth.user.usertype===1?this?.props?.vendor?.vendor?.vendor_id:this.props.dataLoaderProps.auth.user.employee_id,
                this.props.dataLoaderProps.auth.user.usertype
            );    
        if(this.props.setFormObj && typeof this.props.setFormObj ==='function'){
            this.props.setFormObj(this)
        } 
    }  
    onInputChangeHanlder(e){
        this.setState((prevState) => ({
            userData: {
              ...prevState.userData,
              [e.target.name]: e.target.value
            }
        }));
    }
    profileSelectHandler(e,fileData){
        this.setState((prevState) => ({
            userData: {
              ...prevState.userData,
              image:fileData.fileData
            }
        }));
    } 
    getUserDetails(first_name,last_name, user_name, middle_name, user_image,id,user_type){
        if(this.state.user_called){
            return
        } 
        this.setState({
            isLoading:true,
            employee_called: true
        })  
        let api = Api;
        api.setUserToken(); 
        let that = this; 
        let data = {
            first_name:first_name,
            last_name:last_name,
            user_name:user_name,
            image:user_image,
            middle_name:middle_name,
            id:id,
            addressSourceId:null,
        }
        if(user_type===0){
        api.axios().get(Settings.apiOrgUrl+'/employee/'+id).then(res => {  
            that.setState({ 
                userData: {
                    ...data, 
                    addressSourceId: res?.data?.data?.addresssource_id,
                },
                isLoading:false
            }) 
        }).catch(e => {
            that.setState({
                isLoading:false
            })
            Helper.alert(e.response.data.message, 'error')
        })
      }else {
        this.setState((prevState) => ({
            userData: {
              ...data,
              addressSourceId:this?.props?.vendor?.vendor?.addresssource_id,
            },
            isLoading:false
        }));
      }
    } 
    AddressCreate(data){ 
        if(this.state.userData.addresssource_id !== null){
            return;
        }
        this.setState({
            userData:{
                addressSourceId:data.address_source_id,
            }
        })
    }
   
    onSaveHandler(e){
        let userData = this.state.userData; 
        let user_id = this.props.dataLoaderProps.auth.user.user_id
        let validData = {
            ...userData
        } 
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage() 
            this.validator.reset() 
            return;
        }
        this.setState({
            isLoading:true
        }) 
        let api = Api;
        api.setUserToken(); 
        
        let that = this;
        
        let apiPayload = {
            first_name:Helper.getNullableValue(userData?.first_name),
            middle_name:userData?.middle_name?userData?.middle_name:"",
            last_name:Helper.getNullableValue(userData?.last_name),
            image: userData?.image === '/images/blank-profile.jpg' ? null : (userData?.image.startsWith("https://sgp1.digitaloceanspaces.com/lynkazbucket") ? null : userData?.image),
        }
        api.axios().put(Settings.apiUrl+'/user/'+user_id, apiPayload).then(res => { 
            Helper.alert("Profile updated successfully");
            that.props.setUserImage(userData?.image);
            that.setState({ isLoading:false })
        }).catch(e => {
            that.setState({ isLoading:false })
            Helper.alert(e.response.data.message, 'error')
        })
    }
    render() {
        let language = this.props.dataLoaderProps.language;
        let userData = this.state.userData; 
        if(this.state.isLoading){
            return <SimpleLoading />
        }
        return (
            <div className='account_basic_information'>
                <h2>Basic Information</h2>
                <div className='user_icon_box'> 
                   <div className='icon_round' style={{backgroundImage: `url(${userData?.image})`}}></div>
                <label>
                    <img className='file_selector' src={'/images/icons/camera-round-blue.svg'} alt="" />
                    <Input inputType="file" name="profile_picture" onChange = { this.profileSelectHandler.bind(this)} />
                </label>
                </div>
                <div className='row'> 
                    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'><Input required={true} name="first_name" value={userData?.first_name } label={Helper.getLabel(language,'first_name','First Name')}  onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'><Input name="middle_name" value={userData?.middle_name } label={Helper.getLabel(language,'middle_name','Middle Name')}  onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'><Input required={true} name="last_name" value={userData?.last_name } label={Helper.getLabel(language,'last_name','Last Name')} onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'><Input required={true} name="user_name" value={userData?.user_name } disable={true} label={Helper.getLabel(language,'email_address','Email Address')} onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                </div>
                <div className='row'>
                    {this.state.isLoading ? <SimpleLoading /> : 
                        <AddressList onAddressCreate={this.AddressCreate.bind(this)} addressSourc_id = {userData?.addressSourceId} address_source_integrator={userData?.id} source_id ={this.props.dataLoaderProps?.auth?.user?.usertype===1?Settings?.source_id?.vendor:18} /> 
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      vendor:state.vendor,
      language:state.language,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
        setUserImage:(userImage) => { dispatch({type:ActionTypes.SET_USER_IMAGE , payload:userImage}) },
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (RsDataLoader(AccountBasicInformation,{}))