import React, { Fragment } from "react";
import MasterComponentWraper from "../../../Backend/MasterComponentWraper";
import { connect } from "react-redux";
import NavigationHeder from "../../../Navigations/NavigationHeder";
import Helper from "../../../../inc/Helper";
import AgGridNew from "../../../grid/ag/ag-grid-new";
import Settings from "../../../../inc/Settings";
import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import { customDateComparator } from "../../../../inc/Validation";

const NonCompliancePolicyHistory = ({ language }) => {
  const gridColHeaders = [
    {
      field: "name",
      headerName: Helper.getLabel(language, "name", "Name"),
      minWidth: 250
    },
    {
      field: "delivery_date_is_past_date",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        keyCreator: (params) => params.value,
        valueFormatter: (params) => (params.value ? "true" : "false"),
      },
      editable: false,
      headerName: "Delivery date is past date",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if (!params?.data?.delivery_date_is_past_date || !params?.data) {
          return false;
        } else {
          return params.data?.delivery_date_is_past_date;
        }
      },
      minWidth: 250
    },
    {
      field: "delivery_date_is_pr_creation_date",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        keyCreator: (params) => params.value,
        valueFormatter: (params) => (params.value ? "true" : "false"),
      },
      editable: false,
      headerName: "Delivery date is PR creation date",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if (!params?.data?.delivery_date_is_pr_creation_date || !params?.data) {
          return false;
        } else {
          return params.data?.delivery_date_is_pr_creation_date;
        }
      },
      minWidth: 250
    },
    {
      field: "start_date_is_past_date",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        keyCreator: (params) => params.value,
        valueFormatter: (params) => (params.value ? "true" : "false"),
      },
      editable: false,
      headerName: "Start date is past date",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if (!params?.data?.start_date_is_past_date || !params?.data) {
          return false;
        } else {
          return params.data?.start_date_is_past_date;
        }
      },
      minWidth: 250
    },
    {
      field: "start_date_is_pr_creation_date",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        keyCreator: (params) => params.value,
        valueFormatter: (params) => (params.value ? "true" : "false"),
      },
      editable: false,
      headerName: "Start date is PR creation date",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if (!params?.data?.start_date_is_pr_creation_date || !params?.data) {
          return false;
        } else {
          return params.data?.start_date_is_pr_creation_date;
        }
      },
      minWidth: 250
    },
    {
      field: "non_compliance_request_allowed_to_be_converted_into_rfq",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        keyCreator: (params) => params.value,
        valueFormatter: (params) => (params.value ? "true" : "false"),
      },
      editable: false,
      headerName: "Non compliance request allowed to be converted into RFQ",
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if (
          !params?.data
            ?.non_compliance_request_allowed_to_be_converted_into_rfq ||
          !params?.data
        ) {
          return false;
        } else {
          return params.data
            ?.non_compliance_request_allowed_to_be_converted_into_rfq;
        }
      },
      minWidth: 250
    },
    {
      field:'active', filter: 'agSetColumnFilter', filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'true' : 'false' }, 
      editable: false, headerName: 'Active', 
      cellRenderer: 'agCheckboxCellRenderer',
      minWidth: 250,
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      valueGetter: (params) => {
        if(!params?.data?.active || !params?.data){
          return false
        }
        else {
          return params.data?.active
        }
      }
    },
    {field:'revision', filter: 'agTextColumnFilter', editable:false, headerName:'Revision', minWidth: 250},
    {field:'createdby_name', filter: 'agTextColumnFilter', editable:false, headerName:'Created by', minWidth: 250},
    {
      field: 'createddatetime',
      headerName: "Created date time",
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value)
          : '-'
      },
      editable: false, 
      comparator: customDateComparator,
      minWidth: 250
    },
    {field:'modifiedby_name', filter: 'agTextColumnFilter', editable:false, headerName:'Modified by',minWidth: 250},
    {
      field: 'modifieddatetime',
      headerName: "Modified date time",
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateTimeFromDataBase(params?.value)
          : '-'
      },
      editable: false, 
      comparator: customDateComparator,
      minWidth: 250
    }
  ];
  return (
    <Fragment>
      <div className="container-fluid">
        <NavigationHeder
          hideMoreBtn={true}
          backUrl="/non-compliance-policies"
          title={Helper.getLabel(
            language,
            "non_compliance_policy_history",
            "Non Compliance Policy History"
          )}
        />

        <AgGridNew
          apiUrl={Settings.apiUrl + "/non_compliance_policy"}
          pagination={false}
          columnDefs={gridColHeaders}
          hideAddBtn={true}
          hideDeleteBtn={true}
          height={500}
          gridId={"rs_non_compliance_policy_history_list"}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(
  MasterComponentWraper(NonCompliancePolicyHistory)
); 