const ActionTypes = {
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  SET_LOGOUT: "SET_LOGOUT",
  SET_LOGIN: "SET_LOGIN",
  SET_USER: "SET_USER",
  RESET_USER: "RESET_USER",
  SET_LABELS: "SET_LABELS",
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE",
  SET_ALL_SOURCE: "SET_ALL_SOURCE",
  SET_OPTIONS: "SET_OPTIONS",
  SET_COUNTRY: "SET_COUNTRY",
  SET_STATE: "SET_STATE",
  SET_CITY: "SET_CITY",
  SET_ENTITY: "SET_ENTITY",
  SET_SITES: "SET_SITES",
  SET_ACCESS_LEVELS: "SET_ACCESS_LEVELS",
  RESET_ACCESS_LEVELS: "RESET_ACCESS_LEVELS",
  SET_ATTACHMENT_TYPES: "SET_ATTACHMENT_TYPES",
  SET_ALL_PURPOSE: "SET_ALL_PURPOSE",
  SET_ALL_CONTACT_TYPE: "SET_ALL_CONTACT_TYPE",
  SET_NOTES: "SET_EXPORTABLE_NOTES",
  CLEAR_NOTES: "CLEAR_NOTES",
  SET_DATE_FORMATE: "SET_DATE_FORMATE",
  SET_THEME_SETTINGS: "SET_THEME_SETTINGS",
  SET_USER_VENDOR: "SET_USER_VENDOR",
  CHANGE_CART_COUNT: "CHANGE_CART_COUNT",
  CHANGE_WISHLIST_COUNT: "CHANGE_WISHLIST_COUNT",
  UPDATE_WISHLIST_IDS: "UPDATE_WISHLIST_IDS",
  SHOW_EDITNEWCART_MODAL: "SHOW_EDITOLDCART_MODAL",
  CLOSE_EDITNEWCART_MODAL: "CLOSE_EDITOLDCART_MODAL",
  SHOW_EDITOLDCART_MODAL: "SHOW_EDITOLDCART_MODAL",
  CLOSE_EDITOLDCART_MODAL: "CLOSE_EDITOLDCART_MODAL",
  UPDATE_ACTIVRCARTITEM: "UPDATE_ACTIVRCARTITEM",
  UPDATE_CHECKOUT_LINE_IDS: "UPDATE_CHECKOUT_LINE_IDS",
  TOGGLE_DIDCARTUPDATE: "TOGGLE_DIDCARTUPDATE",
  SET_SETTLEMENT_PERIODS: "SET_SETTLEMENT_PERIODS",
  UPDATE_TOTAL_PR_AMOUNT: "UPDATE_TOTAL_PR_AMOUNT",
  SET_PURCHASE_STATUS: "SET_PURCHASE_STATUS",
  UPDATE_VENDOR_CHANGED: "UPDATE_VENDOR_CHANGED",
  SHOW_APPLY_VENDOR_MODAL: "SHOW_APPLY_VENDOR_MODAL",
  CLOSE_APPLY_VENDOR_MODAL: "CLOSE_APPLY_VENDOR_MODAL",
  SET_WAREHOUSE: "SET_WAREHOUSE",
  SET_SEGMENTS: "SET_SEGMENTS",
  SET_SEGMENTS_VALUES: "SET_SEGMENTS_VALUES",
  SET_IS_CONTENT_MASTER_LOADER: "SET_IS_CONTENT_MASTER_LOADER",
  SET_LINE_ACCESS_LEVEL: "SET_LINE_ACCESS_LEVEL",
  SET_PO_HEADER_TYPES: "SET_PO_HEADER_TYPES",
  SET_LINESITE_STATUS:'SET_LINESITE_STATUS',
  SET_EMPLOYEE_LIST:'SET_EMPLOYEE_LIST',
  SET_TAXGROUP:'SET_TAXGROUP',
  SET_TAXCODES:'SET_TAXCODES',
  SET_AMENDMENT_NATURE:'SET_AMENDMENT_NATURE',
  SET_PO_DOCUMENT_STATUS:'SET_PO_DOCUMENT_STATUS',
  SET_USER_IMAGE: 'SET_USER_IMAGE',
  SET_PR_ID:'SET_PR_ID',
  RESET_PR_ID:'RESET_PR_ID',
  RESET_EDITOLDCART_MODAL:'RESET_EDITOLDCART_MODAL',
  SET_LOGO_URL:'SET_LOGO_URL'
};
export default ActionTypes;
