import React, { Fragment } from 'react';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { Dropdown } from 'react-bootstrap';

/*
Vertical 3dots Component for ag-grid:

accepts:
[
    {
        id: 1,
        icon: '/images/',
        name: 'Add criteria',
        disableBeforeClick: false,
        onClick: myOnClick,
    }
]
*/

const GridResponsiveDropdownMenu = ({dropdownItems = [] }) => { 
    const ResponsiveBreakdownMobile = () => {
        return (
            <Dropdown className={'rfq-header-dropdown'}>
                <Dropdown.Toggle className="btn_more tab_menu_toggle_btn">
                    <img src="/images/icons/more-vertical.svg" alt="More Options" className='rfq_droptown_toggle_img' />
                </Dropdown.Toggle>

                <Dropdown.Menu className='rfq_scoring_dropdown_menu'>
                    {dropdownItems?.map((dropdown) => {
                        return (
                            <DropdownItem className='rfq_dropdown_item'>
                                <button disabled={dropdown.disableBeforeClick} onClick={dropdown?.onClick} className={`rs_h_controller rs_setvalue_btn_crt visible_on_select_item_crt ${dropdown.disableBeforeClick ? 'ctl-dissable' : ''}`}>
                                    <img className='img-fluid' src={dropdown?.icon} />
                                    <span>{dropdown?.name}</span>
                                </button>
                            </DropdownItem>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown> 
        );
    };

    return (<Fragment> {ResponsiveBreakdownMobile()} </Fragment>);
};
export default GridResponsiveDropdownMenu;