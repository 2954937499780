import React, { useEffect, useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import Collapse from '../inc/Collapse'
import Input from '../Forms/Input'
import Button from '../inc/Button'
import AgGrid from '../grid/ag/ag-grid'
import { useLocation } from 'react-router-dom'
import Api from '../../inc/Api'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Settings from '../../inc/Settings'
import AgGridMemo from '../grid/ag/AgGridMemo'
import { Form } from 'react-bootstrap'

let forceRender = 1

const AnswerDetails = (props) => {
  const [answerList, setAnswerList] = useState([])
  const [answerData, setAnswerData] = useState({})

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('answergroup_id')

  const getAllAnswers = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/getanswersbygroup/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setAnswerList(res.data?.data)
          forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onAddClick = () => {
    const element = document.getElementById('answer-description-field')
    setAnswerData({})
    if (element) {
      element.focus()
    }
  }

  const onSelectionChanged = (event) => {
    let selectedRows = event?.api?.getSelectedRows()
    if (selectedRows?.length > 0) {
      setAnswerData({
        ...selectedRows[0],
      })
    } else {
      setAnswerData({})
    }
  }

  const onAnswerDelete = () => {
    if (!answerData?.answer_id) {
      return
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .delete(Settings.apiUrl + `/answer/${answerData?.answer_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message)
          getAllAnswers()
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onSaveClickHandler = () => {
    let payload = {
      ...answerData,
      correct_answer:answerData?.correct_answer === 'yes' ? true : false,
    }
    let addPayload = {
      ...answerData,
      answergroup_id: id,
      correct_answer:answerData?.correct_answer === 'yes' ? true : false
    }
    if (answerData?.answer_id) {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .put(Settings.apiUrl + `/answer/${answerData?.answer_id}`, payload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            getAllAnswers()
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    } else {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .post(Settings.apiUrl + '/answer', addPayload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            setAnswerData({
              ...answerData,
              answer_id:res?.data?.questiongroup_id
            })
            getAllAnswers()
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    }
  }

  const handleAnswerDataChange = (event, type) => {
    let value = type === 'checkbox' ? event?.target?.checked : event.target.value
    setAnswerData({
      ...answerData,
      [event?.target?.name]: value,
    })
  }

  useEffect(() => {
    getAllAnswers()
  }, [])

  const Columns_Headings = [
    {
      field: 'answer',
      headerName: Helper.getLabel(props.language, 'Answer', 'answer'),
      editable: true,
    },
  ]
  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'answers', 'Answers')}`} hideMoreBtn={true}>
          <Button title={Helper.getLabel(props.language, 'delete', 'Delete')} onClick={onAnswerDelete}></Button>
          <Button title={Helper.getLabel(props.language, 'save', 'Save')} icon='/images/icons/save-white.svg' isActive={true} onClick={onSaveClickHandler} />
        </NavigationHeder>
        <div>
          <div className='rfq-versions-container'>
            <div className='rfq-versions-left-sidebar rfq-question-detail-grid'>
              <AgGridMemo
                hideDelete={true}
                header={Columns_Headings}
                data={answerList}
                addBtnHide={false}
                forceRender={forceRender}
                hideSave={true}
                hideFooter={true}
                onAddClick={onAddClick}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
            <div style={{ width: '100%' }}>
              <div>
                <Collapse open={true} title={Helper.getLabel(props.language, 'answer', 'Answer')}>
                  <div className='question-details-form'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <Input
                          label={`${Helper.getLabel(props.language, 'answer', 'Answer')}`}
                          inputId='answer-description-field'
                          value={answerData?.answer}
                          name='answer'
                          onChange={(event) => {
                            handleAnswerDataChange(event)
                          }}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Input
                          label={`${Helper.getLabel(props.language, 'sequence', 'Sequence')}`}
                          name='sequence'
                          value={answerData?.sequence}
                          onChange={(event) => {
                            handleAnswerDataChange(event)
                          }}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <Input
                          label={`${Helper.getLabel(props.language, 'points', 'Points')}`}
                          name='point'
                          value={answerData?.point}
                          onChange={(event) => {
                            handleAnswerDataChange(event)
                          }}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Form.Check
                          label={
                            <div className='d-flex flex-column mx-2'>
                              <span>Correct answer</span>
                            </div>
                          }
                          name='is_active'
                          value={answerData?.correct_answer}
                          checked={answerData?.correct_answer}
                          onChange={(event) => handleAnswerDataChange(event, 'checkbox')}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

export default AnswerDetails
