import React, { useEffect } from 'react'
import Helper from '../../../../inc/Helper';
import Checkbox from '../../../Forms/Checkbox';
import Input from '../../../Forms/Input';
import { connect } from 'react-redux';

const GeneralTemplateModule = ({currentState, updateState, setScoringWeights, language}) => {

    const onInputChangeHandler = (e) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        }) 
    }
 
    const onInputCheckboxChangeHandler = (name, value) => {  
        updateState((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        })
    }
    
    const TechnicalScoringHandler = (e) => {
        let value = e.target.value;
         
        if (/^(\d+(\.\d*)?|\.\d+)$/.test(value) || value === '') {
          updateState((prevState) => {
            return {
              ...prevState,
              technical_score_weight: value
            };
          }); 
          setScoringWeights(true)
        } 
    };

    const FinancialScoringHandler = (e) => {
        let value = e.target.value;
       
        if (/^(\d+(\.\d*)?|\.\d+)$/.test(value) || value === '') {
          updateState((prevState) => {
            return {
              ...prevState,
              financial_score_weight: value
            };
          }); 
          setScoringWeights(true)
        } 
    };

    let validationTimer = null;

    const validateInput = () => { 
        if (currentState.technical_score_weight === null && currentState.financial_score_weight === null) return;

        clearTimeout(validationTimer);
        validationTimer = setTimeout(() => {
            let technicalWeight = currentState.technical_score_weight;
            let financialWeight = currentState.financial_score_weight;
            let totalWeight = parseFloat(technicalWeight) + parseFloat(financialWeight);
            
            if (totalWeight !== 100) {
                Helper.alert('Technical & Financial weights must be distributed about 100%', 'error')
                setScoringWeights(true)
                return;
            }
            setScoringWeights(false) 
        }, 1000);
    };

    useEffect(() => {
        validateInput();
      
        return () => {
          clearTimeout(validationTimer);
        };
    }, [currentState.technical_score_weight, currentState.financial_score_weight]);

    return (
        <div className='scoring_module_general_module'>
            <div className='row'>
                <div className='col-lg-4'>
                    <Input type='text' id={'name'} required={true} name={'name'} label={Helper.getLabel(language,'name',"Name")} onChange={onInputChangeHandler} value={currentState.name} />
                </div>
                
                <div className='col-lg-4'>
                    <Input type='number' id={'technical_score_weight'} required={true} name={'technical_score_weight'} label={Helper.getLabel(language,'technical_score_weight',"Technical score weight")} onChange={TechnicalScoringHandler} value={currentState.technical_score_weight} />
                </div>

                <div className='col-lg-4'>
                    <Input type='number' id={'financial_score_weight'} required={true} name={'financial_score_weight'} label={Helper.getLabel(language,'financial_score_weight',"Financial score weight")} onChange={FinancialScoringHandler} value={currentState.financial_score_weight} />
                </div>

                <div className='col-lg-4'>
                    <Checkbox id={'show_evaluation_criteria_to_bidder'} type={'checkbox'} name={'show_evaluation_criteria_to_bidder'} onChange={onInputCheckboxChangeHandler} label={Helper.getLabel(language,'show_evaluation_criteria_to_bidder',"Show evaluation criteria to bidder")} isChecked={currentState.show_evaluation_criteria_to_bidder} /> 
                </div>

                <div className='col-lg-4'>
                    <Input type='number' id={'auto_disqualification_technical_score_prct'} name={'auto_disqualification_technical_score_prct'} label={Helper.getLabel(language,'auto_disqualification_technical',"Auto disqualification technical score%")} onChange={onInputChangeHandler} value={currentState.auto_disqualification_technical_score_prct} />
                </div>

                <div className='col-lg-4'>
                    <Input type='number' id={'auto_disqualification_financial_score_prct'} name={'auto_disqualification_financial_score_prct'} label={Helper.getLabel(language,'auto_disqualification_financial_score_prct',"Auto disqualification financial score%")} onChange={onInputChangeHandler} value={currentState.auto_disqualification_financial_score_prct} />
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return { 
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

export default connect(mapStateToProps) (GeneralTemplateModule);