import React, { Component } from "react";
import AddRequisitionDetails from "../../components/PurchaseRequisition/Checkout/AddRequisitionDetails";

// "/purchase-requisition/shop"
export default class AddRequisitionDetailsScreen extends Component {
  render() {
    return (
      <AddRequisitionDetails
        backUrl={"/purchase-requisition"}
        title="Shop > Add Requisition Details"
        hideMoreBtn={true}
      />
    );
  }
}