import React,{Fragment,useState,useEffect} from "react"
import Helper from "../../inc/Helper"
import Api from "../../inc/Api"
import Settings from "../../inc/Settings"
import TabsCommon from "../Widgets/Tabs/Tabs"
import CustomHtmlCell from "../grid/ag/cellRender/CustomHtmlCell"
import TaxTransactionPopup from "../Purchase Orders Internal/Edit PO/ListView/TaxTransactionPopup"
import { Tab,Tabs } from "react-bootstrap"
import BillingRuleView from "../Purchase Orders Internal/Edit PO/ListView/BillingRuleView"
import AgGridMemo from "../grid/ag/AgGridMemo"
import SimpleLoading from "../Loading/SimpleLoading"
import { getPopupMessageBasedOnStatus } from "../../inc/Validation"

let gridObj = null

const ChargesDetails = (props) => {
   let api = Api;
   const [categoriesOptions,setCategoriesOptions]=useState([])
   const [isLoading,setIsLoading]=useState(false)
   const [reloadGrid,setReloadGrid]=useState(false)
   const [miscChargesData,setMiscChargesData]=useState([])
   const [taxGroup,setTaxGroup]=useState([])
   const [taxGroupItems,setItemTaxGroup]=useState([])
   const [lineIdForTax,setLineTaxId]=useState(null)
   const [showTaxPopUp,setShowTaxPopUP]=useState(false)
   const [activeLine,setActiveLine]=useState(null)
   const [employeeList,setEmployeeList]=useState([])
   const [unitList,setUnitList]=useState([])
   

   useEffect(()=>{
    loadAllCategories()
    loadAllMiscChargesData()
    loadTaxGroup()
    loadItemTaxItemGroup()
    loadEmployee()
    loadUnits()
   },[])

   const onGridReady = (miscGridObj) => {
     gridObj = miscGridObj
   }

   useEffect(()=>{
    console.log(activeLine)
   },[activeLine])

   const loadAllCategories = ()=>{
    api.setUserToken()
    setIsLoading(true)
    api.axios().get(Settings.apiUrl+`/categories`).then(function(res){
    if(res.data.status==="success"){ 
      const updatedCategoriesOptions = res.data?.data.map(item => ({
        value: item.category_id,
        label: `${item.code} - ${item.name}`, 
      }));
      setCategoriesOptions(updatedCategoriesOptions);
      setIsLoading(false)
    }
    }).catch((res) => { 
        
        getPopupMessageBasedOnStatus(res)  
        setIsLoading(false)
    })
   }

   const loadTaxGroup = () => { 
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
      if(res.data.status==="success"){
        let taxGroupARR = []
        res.data.data.forEach(item => {  
          taxGroupARR.push({
            value: item.taxgroup_id,
            label: item.name
          })
        }) 
        setTaxGroup(taxGroupARR)
      } 
    }).catch((res) => { 
      
        getPopupMessageBasedOnStatus(res) 
    })
  }

  const loadItemTaxItemGroup = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/taxgroupitem',{}).then(function(res){
      if(res.data.status==="success"){
        let itemTaxGroups = []
        res.data.data.forEach(item => {  
          itemTaxGroups.push({
            value: item.taxgroupitem_id,
            label: item.name
          })
        }) 
        setItemTaxGroup(itemTaxGroups)
      } 
    }).catch((res) => { 
      
        getPopupMessageBasedOnStatus(res) 
    })
  }

  const loadEmployee = () => {
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
       setEmployeeList(res.data?.data)
    }).catch((res) => { 
        console.log(res.response.data.message)
    })
  }

  const loadUnits = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
        setUnitList(res.data.data)
    }).catch((res) => { 
        
        getPopupMessageBasedOnStatus(res)  
    })
   }

   const loadAllMiscChargesData = () => {
    api.setUserToken()
    setReloadGrid(true)
    api.axios().get(Settings.apiPurchaseOrderUrl+`/lines/${props.id}/${props.source_id}?miscellaneous_lines=True`).then(function(res){
    if(res.data.status==="success"){ 
       setMiscChargesData(res.data?.data)
       setReloadGrid(false)
    }
    }).catch((res) => { 
        
        getPopupMessageBasedOnStatus(res)  
        setReloadGrid(false)
    })
   }

   const onCategoryClick = (e) => {
    gridObj?.componentObj.addNewButtonHandler(e,{line_type:'1'})
    // return;
   }

   const onCommentAddHandler = (e) => {
     gridObj?.componentObj.addNewButtonHandler(e,{line_type:'2'})
    //  return;
   }

   const taxTransactionViewCell = (params) => {
    if (params.data.line_id) {
      return `<div class="rs_inactive_label">View tax</div>`
    }
    return ''
  }

  const onCellClicked = (event) => {
    if(event.colDef.field === 'view_tax_tranasction'){
        setLineTaxId(event.data?.line_id)
        setShowTaxPopUP(true)
     }
  }

  const onTaxTransactionPopupClose = () => {
    setLineTaxId(null)
    setShowTaxPopUP(false)
  }

   const beforeDeleteBtns = () => {
    return   <div className="list_view_action_btns_grid d-flex">
    <button className="d-flex align-items-center" onClick={(e)=>{onCategoryClick(e)}}>
      <span className="me-2 lh-1">
        <img src='/images/icons/category.svg' className="img-fluid" alt="add_category" />
      </span>
      {Helper.getLabel(props.language, "add_category", "Add Category")}
    </button>
    <button className="d-flex align-items-center" onClick={(e)=>{onCommentAddHandler(e)}}>
      <span className="me-2 lh-1">
        <img src="/images/icons/comment.svg" className="img-fluid" alt="add_comment" />
      </span>
      {Helper.getLabel(props.language, "comments", "Add Comments")}
    </button>
    </div>
   }

   const handlePutRequest = (api,putRequestData,event)=> {
    api
    .axios()
    .put(
      Settings.apiPurchaseOrderUrl + `/line/${event.data?.line_id}/${props?.source_id}`,
      putRequestData,
    )
    .then(function (res) {
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success')
        setMiscChargesData((prevLines) =>
          prevLines.map((line) =>
            line.line_id === event.data?.line_id
              ? { ...line, ...putRequestData } // Update the line with new data
              : line
          )
        )
      }
    })
    .catch((res) => {
      
        getPopupMessageBasedOnStatus(res) 
    })
  }

   const onRowValueChanged = (event) => {
    const data = {
      category_id:parseInt(event.data.lines_type)===1 || parseInt(event.data.line_type) === 1?parseInt(event.data.category_id):null,
      unit_price:parseInt(event.data.unit_price)||0,
      line_amount:parseInt(event.data.ordered_qty||1)*parseInt(event.data.unit_price||0),
      line_type:parseInt(event.data.line_type),
      ordered_qty:parseInt(event.data.ordered_qty),
      comments:parseInt(event.data.lines_type)===2 || parseInt(event.data.line_type) === 2?event.data.comment:null,
      taxgroup_id:parseInt(event.data.taxgroup_id),
      taxgroupitem_id:parseInt(event.data.taxgroupitem_id),
      source_id:parseInt(props.source_id),
      header_id:parseInt(props.id),
      miscellaneous_charge:true,
    }
    const putRequestData = {
        category_id:parseInt(event.data.line_type)===1||parseInt(event.data.lines_type)===1?parseInt(event.data.category_id):null,
        unit_price:parseFloat(event.data.unit_price),
        line_amount:parseFloat(event.data.ordered_qty)*parseFloat(event.data.unit_price),
        line_type:parseInt(event.data.line_type),
        ordered_qty:parseFloat(event.data.ordered_qty),
        comments:parseInt(event.data.line_type)===2||parseInt(event.data.lines_type)===2?event.data.comment:null,
        taxgroup_id:parseInt(event.data.taxgroup_id),
        taxgroupitem_id:parseInt(event.data.taxgroupitem_id),
    }
    let api = Api
    api.setUserToken()
    if (!props.id) return
    if (
      event.data?.line_id
    ) {
      // PUT request
      handlePutRequest(api,putRequestData,event)
    } else {
      const postData = data
      api
        .axios()
        .post(Settings.apiPurchaseOrderUrl + `/lines`,postData)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data?.message, 'success')
            loadAllMiscChargesData()
          }
        })
        .catch((res) => {
          
        getPopupMessageBasedOnStatus(res) 
        })
    }
  }

  const onRowDelete = (data) => {
    let api = Api
    api.setUserToken()
    if (!props.id) return
    data.forEach((item) => {
      api
        .axios()
        .delete(
          Settings.apiPurchaseOrderUrl +
            `/line/${item.line_id}/${props.source_id}`,
        )
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data?.message, 'success')
            setMiscChargesData(prevLines => prevLines.filter(line => line.line_id !== item.line_id))
          }
        })
        .catch((res) => {
          
        getPopupMessageBasedOnStatus(res) 
        })
    })
  }

  const onSelectionChanged = (event ) => {
    let activeSelectedPOLine =event.api.getSelectedNodes();
    if(activeSelectedPOLine.length <=0){
       setActiveLine(null)
    }else{
       setActiveLine(activeSelectedPOLine[0])
    }
}


const onInputChange = (event,params) => {
  let line_amount_new 
  let quantity = params.data?.ordered_qty
  let unit_price = event.target.value
  line_amount_new = unit_price * quantity ;
  params.node.setDataValue('line_amount', line_amount_new);
}

const hasSelected = () => {
  if (!activeLine) {
    return false
  }
  if (Object.keys(activeLine).length >= 1) {
    if (!activeLine?.data?.line_id) {
      return false
    }
    return true
  }

  return false
}

    const defaultNav = [
      {
        title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
        isActive: true,
      },
    ]
  
    const Misc_Charges_Column_Header = [
      {
        field: 'category_id',
        headerName: Helper.getLabel(props.language, 'category', 'Category'),
        minWidth: 200,
        inputType:"dropdown",
        inputId:"category-dropdown",
        options:categoriesOptions,
        editable:(params)=>parseInt(params.data?.line_type)===parseInt(2)||parseInt(params.data?.lines_type)===parseInt(2)?false:true,
      },
      {
        field: 'comment',
        headerName: Helper.getLabel(props.language, 'comment', 'Comment'),
        minWidth: 300,
        editable:(params)=>parseInt(params?.data?.line_type)===parseInt(1)||params.data?.lines_type===parseInt(1)? false : true,
      },
      {
        field: 'ordered_qty',
        headerName: Helper.getLabel(props.language, 'qty', 'Quantity'),
        minWidth: 180,
        defaultValue:1,
        editable:false,
      },
      {
        field: 'unit_price',
        headerName: Helper.getLabel(props.language, 'unit_price', 'Unit Price'),
        minWidth: 200,
        defaultValue:"0",
        inputType:"number",
        onChange:onInputChange,
      },
       {
        field: 'line_amount',
        headerName: Helper.getLabel(props.language, 'line_amount', 'Line Amount'),
        minWidth: 200,
        defaultValue:"0",
        editable:false
        // valueFormatter:(params)=>(params?.data?.quantity || 1 * params?.data?.unit_price||0)
      },
      {
        field: 'taxgroup_id',
        headerName: Helper.getLabel(
          props.language,
          'tax_group',
          'Tax Group',
        ),
        minWidth: 200,
        inputType:"dropdown",
        inputId:"tax_dropdown",
        options:taxGroup
      },
      {
        field: 'taxgroupitem_id',
        headerName: Helper.getLabel(
          props.language,
          'tax_group_items',
          'Tax Group Items',
        ),
        minWidth: 200,
        inputType:"dropdown",
        inputId:"taxgroup_item_dropdown",
        options:taxGroupItems
      },
      {
        field: 'view_tax_tranasction',
        minWidth: 150,
        headerName: Helper.getLabel(props.language, 'tax', 'Tax'),
        editable: false,
        defaultValue:" ",
        cellRenderer: CustomHtmlCell,
        valueFormatter:taxTransactionViewCell
      },
    ]

    let employeeListNew = employeeList.map( itemObj => {
      return {
          value: itemObj.employee_id,
          label: `${itemObj.code} - ${itemObj.display_name}`
      }
    });

    let units = unitList.map( itemObj => {
      return {
          value: itemObj.unit_id,
          label:`${itemObj.code} - ${itemObj.description}`
      }
   });
  
    return (
      <TabsCommon
        navs={defaultNav}
        showAll={true}
        scrollOffset='300'
        disableSticky={false}
        addComponentTop={props.addComponentTop}
        addComponentRight={props.addComponentRight}
      >
        <Fragment>
          <div className='misc-grid'>
            {isLoading || reloadGrid ? <SimpleLoading />:<AgGridMemo
              header={Misc_Charges_Column_Header}
              data={miscChargesData}
              addBtnHide={true}
              hideSave={true}
              onDelete={onRowDelete}
              beforeDeleteButton={beforeDeleteBtns}
              onGridReady={onGridReady}
              onRowValueChanged={onRowValueChanged}
              onCellClicked={onCellClicked}
              onSelectionChanged={onSelectionChanged}
            />}
            <div className='line-view-tabs'>
              <Tabs defaultActiveKey={'billingrule'} className='mb-3 mt-4'>
                  <Tab
                    eventKey='billingrule'
                    title='Billing Rule'
                    disabled={!hasSelected()}
                  >
                    {hasSelected() && (
                      <BillingRuleView
                        isEditable={props.editable}
                        employeeList={employeeListNew}
                        misc_charges={true}
                        ownerDefaultValue={
                          activeLine
                            ? activeLine?.requester_id
                            : 0
                        }
                        line_id={
                          activeLine
                            ? activeLine?.data.line_id
                            : null
                        }
                        item_id={
                         activeLine
                            ? activeLine?.data.item_id
                            : null
                        }
                        units={units}
                        grid={gridObj}
                        defaultQuantity={
                          activeLine
                            ? activeLine?.data.ordered_qty
                            : 0
                        }
                        defaultUnitPrice={
                          activeLine
                            ? activeLine?.data?.line_amount /
                              activeLine?.data?.ordered_qty
                            : 0
                        }
                        defaultUnit={
                          activeLine
                            ? activeLine?.data.unit
                            : 0
                        }
                        defaultAmount={
                          activeLine
                            ? activeLine?.data.line_amount
                            : 0
                        }
                        source_id={props.source_id}
                        master_save_button_id={props.master_save_button_id}
                        line_type={
                          activeLine
                            ? activeLine?.data.line_type
                            : ''
                        }
                        version={props.version ? props.version : null}
                        viewType={
                          props.viewType ? props.viewType : 'default'
                        }
                        amendment_id={
                           props.amendment_id
                            ? props.amendment_id
                            : null
                        }
                        line_source={props.source_id}
                      />
                    )}
                  </Tab>
              </Tabs>
            </div>
            {showTaxPopUp && lineIdForTax && (
              <TaxTransactionPopup
                onTaxTransactionPopupClose={onTaxTransactionPopupClose}
                line_id={lineIdForTax}
                source_id={props.source_id}
                integrator={props.id}
              />
            )}
          </div>
        </Fragment>
      </TabsCommon>
    )
  }
  

  export default ChargesDetails