import React, { useEffect, useState } from 'react';
import './InnerScreen.css';

const InnerScreen = ({ innerTitle, children }) => {
  const [componentHeight, setComponentHeight] = useState(0);  

  const updateComponentHeight = () => {
    setComponentHeight(window?.innerHeight - 56 - 81);
  };
  
  useEffect(() => { 
    updateComponentHeight();
 
    window.addEventListener('resize', updateComponentHeight);
 
    return () => {
      window.removeEventListener('resize', updateComponentHeight);
    };
  }, []);

  return (
    <div className='inner-page-section'>
      <div className='container-fluid'>
        <div className='row gy-2'>
          <div className='col-12 border-bottom'>
            <h1>{innerTitle}</h1>
          </div>
          <div className='col-12'>
            <div className='container-fluid inner-page-container common_scrollbar_styles' 
                 style={{ height: `${componentHeight}px`, overflowY: 'auto', overflowX: 'hidden' }}>
              <div className='row'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerScreen; 