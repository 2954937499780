import React, { Component, createRef } from "react";
import { Modal } from "react-bootstrap";

import { connect } from "react-redux";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import DropDownInput from "../../Forms/Dropdown";
import Input from "../../Forms/Input";
import OverlayLoader from "../common/OverlayLoader";
import FormValidator from "../../../inc/FormValidator";
import Button from "../../inc/Button";

class AddCategoryFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCategoriesLoading: false,
      currentSearchTerm: '',
      isItemGroupsLoading: false,
      isUnitsLoading: false,
      isSubmitting: false,
      itemType: null,
      comment: null,
      itemGroups: [],
      selectedItemGroup_comment: null,
      categories: [],
      selectedCategory: null,
      quantity_comment: 1,
      quantity_category: 1,
      unitOfMeasure: [],
      selectedUnitOfMeasure_comment: null,
      selectedUnitOfMeasure_category: null,
      unitPrice_category: 0,
      unitPrice_comment: 0,
      lineTypesList: [],
    };

    this.categoryDataRef = createRef()
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    // this.validationConfigure()
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchItemGroups();
    this.fetchUnits();
    this.getLineTypesList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.itemType !== this.state.itemType) {
      this.validationConfigure();
    }
  }

  validationConfigure(){
    const language = this.props.language;
    let fieldConfig = [
      {name:'ordered_qty',displayName:Helper.getLabel(language,'quantity','Quantity'),types:['Required'] },
      {name:'unit',displayName:Helper.getLabel(language,'unit_of_measure','Unit of measure'),types:['Required'] }
    ]
    if(this.state.itemType===1){
      fieldConfig.push({name:'category_id',displayName:Helper.getLabel(language,'category','Category'),types:['Required'] })
    }

    if(this.state.itemType===2){
      fieldConfig.push({name:'comments',displayName:Helper.getLabel(language,'comment','Comment'),types:['Required'] })
      fieldConfig.push({name:'itemgroup_id',displayName:Helper.getLabel(language,'item_group','Item group'),types:['Required'] })
    }
       
    this.validator = new FormValidator(fieldConfig,language);
  }

  fetchCategories() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isCategoriesLoading: true });
    api
      .axios()
      .get(Settings.loginUrl + "/child_categories")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isCategoriesLoading: false,
            categories: res.data.data,
          });
        }
      })
      .catch((error) => {
        that.setState({ isCategoriesLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  mapCategoryItems(item){
    return {
        id:item.category_id,
        text: item.name
    }
  }

  fetchItemGroups() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isItemGroupsLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + "/item_group")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isItemGroupsLoading: false,
            itemGroups: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isItemGroupsLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  fetchUnits() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isUnitsLoading: true });
    api
      .axios()
      .get(Settings.loginUrl + "/unit")
      .then(function (res) {
        that.setState({
          isUnitsLoading: false,
          unitOfMeasure: [...res.data.data],
        });
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isUnitsLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getLineTypesList() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            lineTypesList: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  checkAddToCartDisable() {
    return !(this.state.itemType === 1 || this.state.itemType === 2)
  }

  handleFormSubmit(event) {
    event.preventDefault();

    let data = null;
    if (this.state.itemType === 1) {
      data = {
        line_type: this.state.itemType,
        header_id:this.props.prId,
        source_id:Settings.source_id.purchase_requisition,
        category_id: this.state.selectedCategory * 1,
        ordered_qty: parseInt(this.state.quantity_category),
        unit: this.state.selectedUnitOfMeasure_category * 1,
        unit_price: this.state.unitPrice_category===null ? 0: parseInt(this.state.unitPrice_category) || 0,
        line_amount:
          (parseFloat(this.state.unitPrice_category) *
          parseFloat(this.state.quantity_category)) || 0,
      };
    } else if (this.state.itemType === 2) {

      data = {
        line_type: this.state.itemType,
        header_id:this.props.prId,
        source_id:Settings.source_id.purchase_requisition,
        comments: this.state.comment,
        itemgroup_id: this.state.selectedItemGroup_comment ?? null,
        ordered_qty: parseInt(this.state.quantity_comment) * 1,
        unit: this.state.selectedUnitOfMeasure_comment * 1,
        unit_price: this.state.unitPrice_comment === null? 0: parseInt(this.state.unitPrice_comment) * 1 || 0,
        line_amount:
          (parseFloat(this.state.unitPrice_comment) *
          parseFloat(this.state.quantity_comment)) || 0,
      };
    }
    if (data) {
      let api = Api;
      api.setUserToken();
      let that = this;
      this.setState({ isSubmitting: true });
      if(!this.validator.isValid(data)){
        this.validator.displayMessage(this);
        this.setState({ isSubmitting: false });
        return;
      } 
      api
        .axios()
        .post(Settings.apiPurchaseOrderUrl + "/lines", data)
        .then(function (res) {
          if (res.data.status === "success") {
            that.setState({ isSubmitting: false,
              itemType: null,
              comment: null,
              selectedItemGroup_comment: null,
              selectedCategory: null,
              quantity_comment: 1,
              quantity_category: 1,
              selectedUnitOfMeasure_comment: null,
              selectedUnitOfMeasure_category: null,
              unitPrice_category: 0,
              unitPrice_comment: 0,
             });
            that.props.hide();
            that.props.incrementCartCount();
            Helper.alert(res.data.message, "success");
          }
        })
        .catch((error) => {
          console.log(error);
          that.setState({ isSubmitting: false });
          Helper.alert(error?.response?.data?.message, "error");
        });
    }
  }

  handleCancel(event) {
    event.preventDefault();
    this.setState({
      itemType: null,
      comment: null,
      selectedItemGroup_comment: null,
      selectedCategory: null,
      quantity_comment: 1,
      quantity_category: 1,
      selectedUnitOfMeasure_comment: null,
      selectedUnitOfMeasure_category: null,
      unitPrice_category: 0,
      unitPrice_comment: 0,
     });
    this.props.hide();
  }

  render() {
    const { visibility, hide } = this.props;
    return (
      <Modal
        show={visibility}
        onHide={hide}
        className="pr-addcategory pr-addcategory-modal"
        enforceFocus={false}
      >
        <Modal.Body className="pr-addcategory-modal-body">
          <form
            className="pr-addcategory-form"
            onSubmit={(e) => this.handleFormSubmit(e)}
          >
            <div className="rs_collapse">
              <div className="rs_collaps_title" open={true}>
                <p>
                  {Helper.getLabel(
                    this.props.language,
                    "general_information",
                    "General Information"
                  )}
                </p>
              </div>
              <DropDownInput
                className="pr-addcategory-form-dropdowninput"
                label={`${Helper.getLabel(
                  this.props.language,
                  "item_type",
                  "Item type"
                )}`}
                options={this.state.lineTypesList
                  ?.filter((obj) => obj.name !== "Item")
                  ?.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                placeHolder="Select item type"
                id="item_type_id"
                value={this.state.itemType}
                onChange={(e) =>
                  this.setState({ itemType: parseFloat(e.target.value) })
                }
                required={true}
              />
              {this.state.itemType === 1 ? (
                <>
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    apiUrl={Settings.apiUrl + '/child_categories'}
                    currentSearchTerm={this?.state?.currentSearchTerm}
                    mapFunction={this.mapCategoryItems}
                    pagination={true}
                    allDataRef={this?.categoryDataRef} 
                    label={`${Helper.getLabel(
                      this.props.language,
                      "category",
                      "Category"
                    )}`}
                    options={this.state?.categories?.map((item) => {
                      return {
                        value: item.category_id,
                        label: item.name,
                      };
                    })}
                    placeHolder="Select category"
                    id="item_type2_id"
                    name="category_id"
                    onChange={(e) => {
                      this.setState({ selectedCategory: e.target.value });
                    }}
                    value={this.state.selectedCategory}
                    reRenderRequired={true}
                    required={true}
                  />
                  {/* <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "item_group",
                      "Item group"
                    )}`}
                    options={this.state.itemGroups.map((item) => {
                      return {
                        value: item.itemgroup_id,
                        label: item.name,
                      };
                    })}
                    placeHolder="Select item type"
                    id="item_group_id"
                    onChange={(e) => {
                      this.setState({
                        selectedItemGroup_category: e.target.value,
                      });
                    }}
                    value={this.state.selectedItemGroup_category}
                    required={true}
                  /> */}
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "quantity",
                      "Quantity"
                    )}`}
                    placeHolder="Enter quantity"
                    name="ordered_qty"
                    value={this.state.quantity_category}
                    onChange={(e) => {
                      if (e.target.value === '0'){
                        return
                      }
                        this.setState({ quantity_category: e.target.value });
                    }}
                    required={true}
                  />
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "unit_of_measure",
                      "Unit of measure"
                    )}`}
                    options={this.state.unitOfMeasure.map((item) => {
                      return {
                        value: item.unit_id,
                        label: item.description,
                      };
                    })}
                    placeHolder="Select unit of measure"
                    id="unit_of_measure_id"
                    name="unit"
                    onChange={(e) => {
                      this.setState({
                        selectedUnitOfMeasure_category: e.target.value,
                      });
                    }}
                    value={this.state.selectedUnitOfMeasure_category}
                    required={true}
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "unit_price",
                      "Unit Price"
                    )}`}
                    placeHolder="Enter unit price"
                    value={this.state.unitPrice_category}
                    onChange={(e) => {
                        this.setState({ unitPrice_category: e.target.value });
                    }}
                    required={false}
                  />
                </>
              ) : null}
              {this.state.itemType === 2 ? (
                <>
                  <Input
                    inputClassName="pr-addcategory-form-textarea"
                    inputType="textarea"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "comment",
                      "Comment"
                    )}`}
                    name="comments"
                    maxLength={255}
                    placeHolder="Enter your comment"
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    value={this.state.comment}
                    required={true}
                  />
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={Helper.getLabel(
                      this.props.language,
                      "item_group",
                      "Item group"
                    )}
                    options={this.state.itemGroups.map((item) => {
                      return {
                        value: item.itemgroup_id,
                        label: item.name,
                      };
                    })}
                    placeHolder="Select item group"
                    id="item_group_id"
                    onChange={(e) => {
                      this.setState({
                        selectedItemGroup_comment: e.target.value,
                      });
                    }}
                    value={this.state.selectedItemGroup_comment}
                    required
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "quantity",
                      "Quantity"
                    )}`}
                    placeHolder="Enter quantity"
                    value={this.state.quantity_comment}
                    onChange={(e) => {
                      if (e.target.value === '0'){
                        return
                      }
                        this.setState({ quantity_comment: e.target.value });
                    }}
                    required={true}
                  />
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "unit_of_measure",
                      "Unit of measure"
                    )}`}
                    options={this.state.unitOfMeasure.map((item) => {
                      return {
                        value: item.unit_id,
                        label: item.description,
                      };
                    })}
                    placeHolder="Select unit of measure"
                    id="unit_of_measure_id"
                    onChange={(e) => {
                      this.setState({
                        selectedUnitOfMeasure_comment: e.target.value,
                      });
                    }}
                    value={this.state.selectedUnitOfMeasure_comment}
                    required={true}
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={`${Helper.getLabel(
                      this.props.language,
                      "unit_price",
                      "Unit Price"
                    )}`}
                    placeHolder="Enter unit price"
                    value={this.state.unitPrice_comment}
                    onChange={(e) => {
                        this.setState({ unitPrice_comment: e.target.value });
                    }}
                    required={false}
                  />
                </>
              ) : null}
            </div>
            <div className="pr-addcategory-form-action-btns">
              <button
                className="pr-primary-btn pr-primary-btn-outline"
                onClick={this.handleCancel}
              >
                {Helper.getLabel(this.props.language, "close", "Close")}
              </button>
              <button
                className="pr-primary-btn pr-primary-btn-solid"
                type="submit"
                disabled={
                  this.checkAddToCartDisable() || this.state.isSubmitting
                }
              >
                {Helper.getLabel(
                  this.props.language,
                  "add_to_cart",
                  "Add to Cart"
                )}
              </button>
            </div>
            {this.state.isCategoriesLoading ||
            this.state.isItemGroupsLoading ||
            this.state.isUnitsLoading ||
            this.state.isSubmitting ? (
              <OverlayLoader height={40} isHeightPercentage={true} />
            ) : null}
          </form>{" "}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(AddCategoryFormModal);
