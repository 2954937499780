import React, { useEffect, useState } from 'react'
import $ from 'jquery'

import Popup from '../../../Popup/Popup'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Dropdown from '../../../Forms/Dropdown'
import Collapse from '../../../inc/Collapse'
import RFQCommonApiCalls from '../../RFQCommonApiCalls'
import SimpleLoading from '../../../Loading/SimpleLoading'
import { getTeamsColumns } from './ColumnsHeader/ColumnsHeader'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions'

let gridObj = null

const SelectFromTeamsModal = ({ language, handleCloseModal, teamsList, employeesList, collaborationTeams, setCollaborationTeams, setGridKey, onSaveHandler }) => {
  const commonApiCalls = new RFQCommonApiCalls()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectableMembers, setSelectableMembers] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])

  let newAddItems = {
    PR_view_access: false,
    RFP_view_access: false,
    perform_RFQ_technical_evaluation: false,
    perform_RFQ_financial_evaluation: false,
    allowed_to_award: false,
    allowed_to_unsealed_technical_proposal: false,
    allowed_to_unsealed_commercial_proposal: false,
  }

  const Columns_Headings = getTeamsColumns({
    language: language,
    teamsList: teamsList,
    employeesList: employeesList,
  })

  const handleTeamSelection = (event) => {
    let membersAll = event?.api?.getSelectedRows()
    setSelectedMembers(event.api.getSelectedRows())
    const newMembers = [...collaborationTeams]
    membersAll?.forEach((member) => {
      if (!newMembers.find((team) => team.employee_id === member.employee_id && team.teamheader_id === member.teamheader_id)) {
        const updatedMember = {
          ...member,
          PR_view_access: member?.PR_view_access,
          RFP_view_access: member?.RFP_view_access,
          allowed_to_award: member?.allowed_to_award,
          allowed_to_unsealed_commercial_proposal: member?.allowed_to_unsealed_commercial_proposal,
          allowed_to_unsealed_technical_proposal: member?.allowed_to_unsealed_technical_proposal,
          perform_RFQ_financial_evaluation: member?.perform_RFQ_financial_evaluation,
          perform_RFQ_technical_evaluation: member?.perform_RFQ_technical_evaluation,
        }
        newMembers.push(updatedMember)
      }
    })
    setCollaborationTeams(newMembers)
  }

  const handleSelectClick = () => {
    $('html').removeClass('rs_popup_open')
    setGridKey(Date.now())
    handleCloseModal()
    onSaveHandler()
  }

  let dataSource = {
    getRows: async function (params) {
      setTimeout(() => {
        params.success({
          rowData: selectableMembers?.map((item) => ({
            ...item,
            gridId: `${Date.now()}_${getNewValue()}`,
          })),
          rowCount: selectableMembers?.length,
        })
      }, 300)
    },
  }

  const handleGridReady = (params) => {
    gridObj = params
    params.api.setGridOption('serverSideDatasource', dataSource)
  }

  useEffect(() => {
    const getAsyncData = async () => {
      try {
        if (selectedTeam && selectedTeam !== '') {
          setIsLoading(true)
          const teamsData = await commonApiCalls.getTeamlinesByHeaderId(selectedTeam)
          if (teamsData) {
            setIsLoading(false)
            setSelectableMembers(teamsData)
          }
        }
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    }
    getAsyncData()
  }, [selectedTeam])

  useEffect(() => {}, [selectableMembers])

  return (
    <Popup
      autoOpen={true}
      width='900px'
      className='rf_radio_form_modal'
      onClose={() => {
        $('html').removeClass('rs_popup_open')
        handleCloseModal()
      }}
    >
      <div className='d-flex justify-content-between align-items-start mb-2'>
        <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'pick_a_team', 'Pick a team')}</h5>
        <div className='addDocumentNumber-body-buttons' style={{ justifyContent: 'flex-end' }}>
          <Button
            isActive={false}
            className="black-btn-style"
            title={Helper.getLabel(language, 'Close', 'Close')}
            onClick={() => {
              $('html').removeClass('rs_popup_open')
              handleCloseModal()
            }}
          />
          <Button className='solid_btn' isActive={true} title={Helper.getLabel(language, 'select', 'Select')} onClick={handleSelectClick} isDisable={selectedMembers?.length === 0} />
        </div>
      </div>
      <div>
        <Dropdown
          label={`${Helper.getLabel(language, 'team', 'Team')}`}
          options={teamsList?.map((item) => {
            return {
              value: item.teamheader_id,
              label: item.name,
            }
          })}
          reRenderRequired={true}
          placeHolder={`${Helper.getLabel(language, 'select_team', 'Select team')}`}
          id='rfq_general_add_team_modal_select'
          onChange={(e) => {
            if (e.target.value === '') {
              setSelectedTeam(null)
            }
            setSelectedTeam(e.target.value)
          }}
          value={selectedTeam}
        />
      </div>
      {selectedTeam ? (
        <Collapse title={Helper.getLabel(language, 'select_members_to_add', 'Select members to add')} open={true} className='mb-5'>
          {isLoading ? (
            <SimpleLoading />
          ) : (
            // <AgGridMemo
            //   id="rfq_header_collaboration_team_modal"
            //   header={Columns_Headings}
            //   data={selectableMembers}
            //   hideSave={true}
            //   addBtnHide={true}
            //   hideDelete={true}
            //   onSelectionChanged={handleTeamSelection}
            //   viewOnly={true}
            // />
            <AgGridNew
              columnDefs={Columns_Headings}
              hideAddBtn={true}
              hideDeleteBtn={true}
              isDataSourceRequire={false}
              onGridReady={handleGridReady}
              gridId='rfq_header_collaboration_team_modal'
              newAddItems={newAddItems}
              onSelectionChanged={handleTeamSelection}
            />
          )}
        </Collapse>
      ) : null}
    </Popup>
  )
}

export default SelectFromTeamsModal
