import React, { Component } from 'react';
import MasterComonent from '../../components/Backend/MasterComonent';
import Tenants from '../../components/Tenant/Tenants';

class TenantsArchive extends Component {
    render() {
        return (
            <MasterComonent>
                <Tenants/>
            </MasterComonent>
        );
    }
}

export default TenantsArchive;