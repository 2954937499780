import React from 'react';
import Form from 'react-bootstrap/Form';

const InputColorPicker = ({value, label, required, onChange, id, name}) => {
    return (
        <div className="input-colorpicker-lynkaz"> 
            <label>{label} {required ? <span style={{color:'red'}}>*</span> : ''}</label>
            <div className='input-color-wrapper d-flex justify-content-start align-items-center flex-row'> 
                <input style={{fontSize: '14px'}} disabled value={value} />
                <Form.Control
                    type='color'
                    name={name}
                    id={id}
                    title='Your Color'
                    value={value}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    style={{ border: '0'  }}
                /> 
            </div>
        </div>
    )
}

export default InputColorPicker;