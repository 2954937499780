import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Helper from '../../../inc/Helper';
import Collapse from '../../inc/Collapse';
import Input from '../../Forms/Input';
import Settings from '../../../inc/Settings';
import Button from '../../inc/Button';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import NavigationHeder from '../../Navigations/NavigationHeder';
import PoTopBlueShortDetails from '../Widget/PoTopBlueShortDetails';
import Api from '../../../inc/Api';
import SimpleLoading from '../../Loading/SimpleLoading';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation';

function RenewOrderEdit(props) {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [renewData, setRenewData] = useState({});
    const [updatingRenew, setUpdatingRenew] = useState(false);
    const [po, setPo] = useState(null);
    const [renewOrderData, setRenewOrderData] = useState({});

    const navigate = useNavigate()

    useEffect(() => {
        loadPurchasesOrder(id);
        loadRenewOrders(id);
    }, [id]);

    const loadRenewOrders = (id) => {
        if(objectIsEmpty(renewOrderData)) return
        let api = Api;
        api.setUserToken();
        api.axios()
            .get(Settings.apiPurchaseOrderUrl + `/get_po_autorenewal/${id}`)
            .then((res) => {
                let data = renewData;
                setRenewData({
                    ...data,
                    renewal_before_days: res.data?.data?.renewal_before_days,
                });
                setRenewOrderData(res.data.data);
            })
            .catch((error) => {
                // Handle error
            });
    };

    const loadPurchasesOrder = (poId) => {
        if (!poId) {
            return;
        }
        let api = Api;
        api.setUserToken();
        setIsLoading(true);
        api.axios()
            .get(Settings.apiPurchaseOrderUrl + `/PO_header/${poId}`)
            .then((res) => {
                setIsLoading(false);
                setPo(res.data.data);
            })
            .catch((error) => {
                Helper.alert(error.response.data.message, 'error');
                setPo(null);
                setIsLoading(false);
            });
    };

    const onChangeHandler = (event) => {
        setRenewData({
            ...renewData,
            [event.target.name]: event.target.value,
        });
    };

    const onDateChangeHanlder = (date, name) => {
        let data = renewData;
        setRenewData({
            ...data,
            [name]: date,
        });
    };

    const saveHandler = (event) => {
        let data = renewData;
        let postData = {
            purchaseorder_id: parseInt(id),
            renewal_before_days: parseInt(data.renewal_before_days),
        };
        let api = Api;
        api.setUserToken();
        api.axios()
            .post(Settings.apiPurchaseOrderUrl + `/autorenewal`, postData)
            .then((res) => {
                if (res.data.status === 'success') {
                    Helper.alert(res.data?.message, 'success');
                    loadRenewOrders(id);
                }
            })
            .catch((res) => {
                getPopupMessageBasedOnStatus(res) 
            });
    };

    const renewOrderDeleteHandler = (event) => {
        let api = Api;
        api.setUserToken();
        api.axios()
            .delete(Settings.apiPurchaseOrderUrl + `/autorenewal/${renewOrderData?.po_autorenewal_id}`)
            .then((res) => {
                if (res.data.status === 'success') {
                    Helper.alert(res.data?.message, 'success');
                    // You can use the navigation hook here to go back
                    navigate(-1)
                }
            })
            .catch((res) => {
                getPopupMessageBasedOnStatus(res) 
            });
    };

    if (isLoading) {
        return <SimpleLoading />;
    }

    let lng = null;

    if (!po) {
        return <SimpleLoading />;
    }

    return (
        <div className='amandment_request_single_page'>
            <NavigationHeder
                backUrl={props.backUrl ? props.backUrl + '/' + po?.purchase_order_id  :'' }
                title={Helper.getLabel(lng, 'renew_edit', objectIsEmpty(renewOrderData) ? 'Renew' : 'Renew edit')}
                hideMoreBtn={true}
            >
               <Button id='btn_master_save' isDisable={objectIsEmpty(renewOrderData)} title={Helper.getLabel(null, 'delete', 'Delete')} onClick={renewOrderDeleteHandler} icon='/images/icons/delete.svg' />
               <Button id='btn_master_save' className='rfq_save_btn' isDisable={updatingRenew} isActive={false} title={Helper.getLabel(null, 'save', 'Save')} onClick={saveHandler} />
            </NavigationHeder>
            <PoTopBlueShortDetails poid={po?.purchase_order_id} />
            <Collapse className='mt-3 mb-3' open={true} title={Helper.getLabel(null, 'general_information', 'General Information')}>
                <div className='rs_form_container'>
                    <div className='row renew-form'>
                        <div className='col-xs-12 col-md-6'>
                            <Input label={Helper.getLabel(null, 'contract_no', 'Contract No.')} name='contract_no' value={po?.document_number} disable={true} onChange={onChangeHandler} />
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <Input label={Helper.getLabel(null, 'description', 'Description')} name='description' value={po?.description} disable={true} onChange={onChangeHandler} />
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <NewInputDatePicker disable={true} empty={true} value={po?.start_date} id='start_date' name='start_date' label={Helper.getLabel(null, 'start_date', 'Start date')} onChange={onDateChangeHanlder} />
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <NewInputDatePicker disable={true} empty={true} value={po?.end_date} id='end_date' name='end_date' label={Helper.getLabel(null, 'end_date', 'End date')} onChange={onDateChangeHanlder} />
                        </div>
                        <div className='col-xs-12 col-md-12'>
                            <h3 className='inline_title_1'>{Helper.getLabel(null, 'renewl_trigger_date', 'Renewal trigger date')}</h3>
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <NewInputDatePicker disable={false} empty={true} value={renewData?.renewed_end_date} id='renewed_date' name='renewed_end_date' label={Helper.getLabel(null, 'renewed_start_date', 'Renewed date')} onChange={onDateChangeHanlder} />
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <Input disable={false} value={renewData?.renewal_before_days} id='renew_days' name='renewal_before_days' label={Helper.getLabel(null, 'renew_days', 'Renew Before Days')} onChange={onChangeHandler} />
                        </div>
                        <div className='col-xs-12 col-md-6'></div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default MasterComponentWraper(RenewOrderEdit);
