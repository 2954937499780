import React from "react";
import { calculateSubArrayTotals, createSummaryGridArray, replaceLastArray } from "../../../../inc/Validation";

const CompliancesSummary = ({ 
    ColumnsData, 
    RFQResponseLines, 
    TotalLines, 
    RFQComplianceVendors, 
    summaryRef,
    onSummaryGridScroll,
    RFQComplianceResponses, 
    NoOfObjectsToMap
}) => {

    let ComplianceSummaryArray = createSummaryGridArray(
        ColumnsData?.length, 
        RFQComplianceVendors, 
        RFQComplianceResponses, 
        RFQResponseLines, 
        TotalLines?.length
    );

    let CalculatedTotalRowGrid = calculateSubArrayTotals(ComplianceSummaryArray, TotalLines?.length, RFQComplianceVendors);
 
    let ComplianceSummaryGridRowData = replaceLastArray(ComplianceSummaryArray, CalculatedTotalRowGrid);

    const VerticalTableColumns = () => {
        return (
            <table className="table-scores">
                <thead>
                    <tr>
                        <th>
                            <div className="vertical_col_label_summary">Summary</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {ColumnsData.map((item) => {
                        return (
                            <tr id={item.id}>
                                <td>
                                    <div className="vertical_col_label">{item.type}</div>
                                </td>
                            </tr>
                        )
                    })} 
                </tbody>
            </table>
        )
    };
  
    const renderHeaderColumns = () => (
        <thead>
            <tr>
                {Array.from({ length:NoOfObjectsToMap }, (el, index) => (
                    <th key={index}>
                        <div className="horizontal_col_labels">
                            <div className="d-flex justify-content-around align-items-center">
                                <div>Total</div>
                                <div>Percentage</div>
                            </div>
                        </div>
                    </th>
                ))} 
            </tr>
        </thead>
    );

    const renderDataRows = () => (
        <tbody> 
           {Array.from({ length: ComplianceSummaryGridRowData?.length }, (item, rowIndex) => (
                <tr key={rowIndex}>
                    {ComplianceSummaryGridRowData[rowIndex].map((cell, cellIndex) => (
                        <td key={cellIndex}>
                            <div className="score-values">
                                <div> {cell.total}</div>
                                <div> {cell.percentage}</div>
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );

    return (
        <div className="compliance_summary_grid"  >
            <div className="wrapper">
                {VerticalTableColumns()}
                <div className="table-data-wrapper" onScroll={onSummaryGridScroll} ref={summaryRef}>
                    <table className="table-data">
                        {renderHeaderColumns()}
                        {renderDataRows()}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CompliancesSummary;