import React from "react";
import MasterComonent from "../../components/Backend/MasterComonent";
import RFQHeaderList from "../../components/RequestForQuotation/RFQDetailForm/RFQHeaderList";

const RFQHeaderListScreen = () => {
  return (
    <MasterComonent>
      <RFQHeaderList />
    </MasterComonent>
  );
};

export default RFQHeaderListScreen;
