import React, { Component } from "react";
import Popup from "../Popup";
import $ from 'jquery';
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import Input from "../../Forms/Input";
import DropDownInput from "../../Forms/Dropdown";
import Button from "../../inc/Button";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
/*
source_id = {} source_integrator ={} translationsource_id ="" original_text ="" product_number ={} onFirstTranslationCreate ={}
source_id : app source id (Static)
source_integrator : Unique id of form
translationsource_id: Old translator id if translated before
original_text: Orinial Text
product_number: Form Code or Number
default_language: Form Default Language
onFirstTranslationCreate(translationsouce_id): first category create
*/
class TextTranslationPopup extends Component {
    constructor(props){
        super(props);
        this.state = {
            source_id: this.props.source_id,
            translationsource_integrator: this.props.source_integrator,
            translationsource_id: this.props.translationsource_id,
            original_text: this.props.original_text,
            product_number: this.props.product_number,
            default_language: this.props.default_language ? this.props.default_language  : 'en-us',
            translated_text:'',
            translation_id:null,
            isLoading:false,
            selectedLanguage:null,
            selectedLanguageObj:null,
            translated_text_in_all_lang:[]
        }
    }
    componentDidMount(){
        this.loadTranslation();
    }
    getSelectedLanguageId(){
        if(this.state.selectedLanguage){
            return parseInt(this.state.selectedLanguage);
        }
        return document.getElementById('language_select').value;
    }
    updateTranslation(){
        let that = this;
        this.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let data = {
            language_id: this.getSelectedLanguageId(),
            translated_text: this.state.translated_text
        }
        api.axios().put(Settings.apiUrl + '/translation/'+this.state.translation_id,data).then(function(res){
            if(res.data.status==='success'){
                that.setState({
                    translationsource_id:res.data.translationsource_id
                })
                Helper.alert(res.data.message)
            }
            that.setState({
                isLoading:false
            })
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
            that.setState({
                isLoading:false
            })
        })
    }
    createTranslationFromSourceIntegrator(){
        let that = this;
        this.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let data = {
            source_id: parseInt(this.state.source_id) ,
            translationsource_integrator: parseInt(this.state.translationsource_integrator),
            language_id: this.getSelectedLanguageId(),
            translated_text: this.state.translated_text
        }
        api.axios().post(Settings.apiUrl + '/translationsource',data).then(function(res){
            if(res.data.status==='success'){
                that.setState({
                    translationsource_id:res.data.translationsource_id
                })
                if(that.props.onFirstTranslationCreate && typeof that.props.onFirstTranslationCreate ==='function'){
                    that.props.onFirstTranslationCreate(res.data.translationsource_id)
                }
                Helper.alert(res.data.message)
            }
            that.setState({
                isLoading:false
            })
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
            that.setState({
                isLoading:false
            })
        })
    }
    saveFromTranslationSourceId(){
        let that = this;
        this.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let data = {
            translationsource_id: this.state.translationsource_id,
            language_id: this.getSelectedLanguageId(),
            translated_text: this.state.translated_text
        }
        api.axios().post(Settings.apiUrl + '/translation',data).then(function(res){
            if(res.data.status==='success'){
                Helper.alert(res.data.message)
            }
            that.setState({
                isLoading:false
            })
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
            that.setState({
                isLoading:false
            })
        })
    }
    loadTranslation(){
        let that = this;
        this.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let data = {
            source_id: parseInt(this.state.source_id) ,
            translationsource_integrator: parseInt(this.state.translationsource_integrator),
        }
        api.axios().get(Settings.apiUrl + '/translation/'+data.source_id+'/'+data.translationsource_integrator).then(function(res){
            if(res.data.status==='success'){
                if(res.data.data.length>=1 && !that.state.translationsource_id){
                    that.setState({
                        isLoading:false,
                        translationsource_id:res.data.data[0].translation_source_id,
                        translated_text_in_all_lang:res.data.data
                    })
                }else{
                    that.setState({
                        isLoading:false,
                        translated_text_in_all_lang:res.data.data
                    })
                }
                that.setSelectedLangTranslatedText();
            }else{
                that.setState({
                    isLoading:false,
                })
            }
            
        }).catch(error => {
            console.log(error.response.data.message,'error')
            that.setState({
                isLoading:false
            })
        })
    }
    setSelectedLangTranslatedText(){
        let selectedLanguageId = this.getSelectedLanguageId();
        let that = this;
        that.setState({
            translation_id:null,
            translated_text:""
        })
        this.state.translated_text_in_all_lang.forEach(translatedText => {
            if( parseInt(translatedText.language_id) === parseInt(selectedLanguageId)  ){
                that.setState({
                    translation_id:translatedText.translation_id,
                    translated_text:translatedText.translated_text
                })
            }
        })
    }
    popupCloseHandler(e){
        $('html').removeClass('rs_popup_open');
        this.props.onClose(e)
    }
    onLanguageChangeHandler(e){
        let selectedLanguageObj = null;
        this.props.language.allLanguage.forEach(language => {
            if( parseInt(language.languange_Id) === parseInt( e.target.value)){
                selectedLanguageObj = language;
            }
        });
        this.setState({
            selectedLanguageObj:selectedLanguageObj,
            selectedLanguage:e.target.value
        },() => {
            this.loadTranslation();
        } )
    }
    onChangeTranslatedText(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    onSave(e){
        if(this.state.translated_text == ''){
            Helper.alert('Translated text filed required','error')
            return;
        }
        if(this.state.translation_id){
            this.updateTranslation();
        }
        else if(this.state.translationsource_id){
            this.saveFromTranslationSourceId();
        }else{
            this.createTranslationFromSourceIntegrator();
        }
    }
    render() {
        let language = this.props.language;
        let languageList = language.allLanguage.map( item => {  return { label:item.language, value:item.languange_Id } })
        let translatedTextAlign = ( this.state.selectedLanguageObj && this.state.selectedLanguageObj.alignment === 1 ) ? "text-right" : 'text-left';
        return(
            <Popup autoOpen={true} width="920px" onClose  = { this.popupCloseHandler.bind(this)}>
                <div className="text_translation_popup">
                    <div className="tt_header">
                        {Helper.getLabel(language,'text_translation','Text translation')}
                        {this.state.isLoading ? <div style={{width:'100px'}}><SimpleLoading/></div> : <Button onClick={ e => this.onSave(e)} isActive={true} icon="/images/icons/save-white.svg" title={Helper.getLabel(language,'save','Save')} /> }
                    </div>
                    <h3>{Helper.getLabel(language,'tt_label_2','Provide translation for the orginal text')}</h3>
                    <p>{Helper.getLabel(language,'default_language','Default language')}: en-us</p>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <DropDownInput onChange={ this.onLanguageChangeHandler.bind(this)} id ="language_select" className="language_select" labelAlign="left" name="language" label={Helper.getLabel(language,'language','Language')} options={languageList} />
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <Input disable={true} value = {this.state.product_number} labelAlign="left" name="product_number" label={Helper.getLabel(language,'product_number','Product number:')} />
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-xs-12 col-sm-6">
                            <h4>{Helper.getLabel(language,'orignal_text','Orignal text:')}</h4>
                            <Input className="tt_textarea_box" disable={true} value = {this.state.original_text}  inputType="textarea" name="descripion" />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <h4>{Helper.getLabel(language,'translated_text','Translated Text:')}</h4>
                            <Input className="tt_textarea_box" inputClassName={translatedTextAlign}  onChange = {e => this.onChangeTranslatedText(e) }  value={ this.state.translated_text}  inputType="textarea" name="translated_text" />
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
export default connect (mapStateToProps) (TextTranslationPopup);
