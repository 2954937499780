import React, { Fragment, useEffect, useRef, useState } from 'react'
import '../PO.css'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles' 
import MasterComonent from '../../Backend/MasterComonent'
import NavigationHeder from '../../Navigations/NavigationHeder'
import LinkBtn from '../../inc/LinkBtn'
import SegmentsDistribution from '../../Segments/SegmentsDistribution'
import Button from '../../inc/Button'
import Tabs from '../../Widgets/Tabs/Tabs'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Collapse from '../../inc/Collapse' 
import Helper from '../../../inc/Helper' 
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import OrderStatus from '../../Widgets/OrderStatus/OrderStatus'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Alert from '../../inc/Alert'
import { Link, useNavigate, useParams } from 'react-router-dom' 
import Api from '../../../inc/Api'
import SimpleLoading from '../../Loading/SimpleLoading'
import Settings from '../../../inc/Settings'
import { ValidateArray, ValidateData, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import FormValidator from '../../../inc/FormValidator' 
import ListViewTabs from './ListViewTabs'
import ListViewGrid from './ListViewGrid'
import SwitchView from '../../../inc/SwitchView'
import HeaderViewGeneral from './HeaderViewGeneral'
import HeaderViewDelivery from './HeaderViewDelivery'
import HeaderViewPriceDiscount from './HeaderViewPriceDiscount'
import $ from 'jquery'
import LineViewComponent from './ListView/LineViewComponent'
import LoadPurchaseStatus from '../../../actions/LoadPurchaseStatus'
import OrderSidebarWidget from './OrderSidebarWidget'
import ActionTypes from '../../../inc/ActionsTypes'
import Team from '../Team/Team'
import PoTeamAccess from '../../SecurityRoles/PoTeamAccess'
import AddressList from '../../Tenant/Address/AddressList'
import ContactAddress from '../../Tenant/Address/ContactAddress'
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget'
import TaxTransactionPopup from './ListView/TaxTransactionPopup'
import CancelDocumentsPopup from '../PoPopups/CancelDocumentsPopup'
import PoHelper from '../PoHelper'
import RsDataLoader from '../../inc/RsDataLoader'

let isDisable = true
let defaultGridData = null
let DefStateData  = null; 
let contactGridObj = null;
let dataFromGridAndInput = []

const EditCO = (props) => {
  const navigate = useNavigate();
  const {id} = useParams()
  const hasPoChanged = useRef(false);
  //States Component - Wide
    const [isVisibleCancelDocumentPopup, setIsVisibleCancelDocumentPopup] = useState(false);
    const [isShowTransactionPopup, setIsShowTransactionPopup] = useState(false);
    const [isNoPOFound, setIsNoPOFound] = useState(false);
    const [purchaseStatusLabel, setPurchaseStatusLabel] = useState("");
    //const [poTeamAccess, setPoTeamAccess] = useState({access_level:0});
    const poTeamAccess = props.poAccessForTeam;
    const [isPoEditable, setIsPoEditable] = useState(false) 
    const [viewComponent, setViewComponent] = useState("Header View") 
    const [loading, setLoading] = useState(true)
    const [poLinesLoading, setPOLinesLoading] = useState(false)
    const [orderStatusProps, setOrderStatusProps] = useState({})
    
 
    // const [dataFromGridAndInput, setDataFromGridAndInput] = useState([])
    let editPoFromDb = useRef(null);
    const [editPO, setEditPO] = useState({}) 
    const [listViewTabsObj, setListViewTabsObj] = useState({})
    const [listViewTabsGridObj, setListViewTabsGridObj] = useState({})
    const [lineSiteId, setLineSiteId] = useState(null)
    const [editPOLinesGrid, setEditPOLinesGrid] = useState([]) 
    //const [previousPurchaseOrderLinesData, setPreviousPurchaseOrderLinesData] = useState(Object.freeze([]))
    const [taxCode, setTaxCode] = useState([])
    const [taxGroup, setTaxGroup] = useState([]) 
    const [requester, setRequester] = useState([])
    const [ordered, setOrdered] = useState([])
    const [vendorsDropdown, setVendorsDropdown] = useState([])
    const [vendors, setVendors] = useState([])
    const [unit, setUnit] = useState([])
    const [type, setType] = useState([])
    const [sites, setSites] = useState([])
    const [entities, setEntities] = useState([])

    let purchase_order_source_id = Settings.source_id.purchase_order;
  //End

  //Fields Validation

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [
        {name:'vendor_id', displayName:Helper.getLabel(props.language,'vendor_id','Vendor'),types:['Required']},
        {name:'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse_id','Warehouse'),types:['Required']},       
        {name:'accounting_date', displayName:Helper.getLabel(props.language,'accounting_date','Accounting Date'),types:['Required'] }, 
        {name:'document_date', displayName:Helper.getLabel(props.language,'document_date','Document Date'),types:['Required'] }, 
        {name:'entity_id', displayName:Helper.getLabel(props.language,'entity','Entity'),types:['Required']}, 
        {name:'currency_id', displayName:Helper.getLabel(props.language,'currency_id','Currency '),types:['Required'] }, 
        {name:'site_id', displayName:Helper.getLabel(props.language,'site_id','Site '),types:['Required'] },
        {name:'requester_id', displayName:Helper.getLabel(props.language,'requester','Requester'), custom:(formData,field) =>{
          if( (Helper.getNullableValue(formData.requester_id) || Helper.getNullableValue(formData.orderer_id) ) && formData.requester_id == formData.orderer_id){
            return {
              type:false,
              message:"Requester and Orderer can't be same"
            }
          }
        } },
        {name:'discount_amount', displayName:Helper.getLabel(props.language,'discount_amount','Discount amount '), minValue:0 },
        {name:'discount_percentage', displayName:Helper.getLabel(props.language,'discount_percentage','Discount percentage '),maxValue:100 ,minValue:0 },
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()

 
  const updateSidebarOrderState = (dataArg,poHeaderData) => {
            let lineNetAmount = 0, lineDiscountAmount =0,lineDiscount = 0,discount=0,exchangeRate = Helper.getOnlyNumber(poHeaderData.exchange_rate), roundOff = Helper.getOnlyNumber(poHeaderData.rounding_amount);
            dataArg.forEach(lineItem => {
                lineNetAmount += Helper.getOnlyNumber (lineItem.ordered_qty) * Helper.getOnlyNumber(lineItem.unit_price );
                  lineDiscountAmount += Helper.getOnlyNumber(lineItem.line_amount)
            });
            let discount_percentage = 0;
            if(Helper.getOnlyNumber(poHeaderData.discount_percentage) > 0){
              discount_percentage = ( Helper.getOnlyNumber(poHeaderData.discount_percentage) / 100 ) * lineDiscountAmount;
            }
            lineDiscount = lineNetAmount - lineDiscountAmount; 
            discount = Helper.getOnlyNumber(poHeaderData.discount_amount) + discount_percentage
            setOrderStatusProps( (data) => {
                return(
                    {
                        ...data,
                        //exchange_rate:exchangeRate,
                        discount:discount,
                        round_off:roundOff,
                        line_amount:lineNetAmount,
                        line_discount:lineDiscount
                    }
                )
            })
        }
    
    useEffect(function(){
      updateSidebarOrderState(editPOLinesGrid,editPO);
      //Helper.alert(hasPoChanged.current)
      hasPoChanged.current = true;
      if(editPO.purchase_order_id && editPoFromDb.current  != null){

        if(editPoFromDb.current.warehouse_id != editPO.warehouse_id){
          Helper.confirmMe(() => {
            updateLineOrderData('warehouse', Helper.getOnlyInt(editPO.warehouse_id,true))
          },"Warehouse will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.delivery_date != editPO.delivery_date){
          Helper.confirmMe(() => {
            updateLineOrderData('delivery_date', Helper.getDateForDb(editPO.delivery_date))
          },"Delivery date will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.taxgroupitem_id != editPO.taxgroupitem_id){
          Helper.confirmMe(() => {
            updateLineOrderData('taxgroupitem_id',Helper.getOnlyInt(editPO.taxgroupitem_id,true))
          },"Taxgroup item will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.taxgroup_id != editPO.taxgroup_id ){
          Helper.confirmMe(() => {
            updateLineOrderData('taxgroup_id',Helper.getOnlyInt(editPO.taxgroup_id,true) )
          },"Tax group will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.requester_id != editPO.requester_id){
          Helper.confirmMe(() => {
            updateLineOrderData('requester_id',Helper.getOnlyInt(editPO.requester_id,true) )
          },"Requester will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.orderer_id != editPO.orderer_id){
          Helper.confirmMe(() => {
            updateLineOrderData('orderer_id',Helper.getOnlyInt(editPO.orderer_id,true))
          },"Orderer will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.entity_id != editPO.entity_id){
          Helper.confirmMe(() => {
            updateLineOrderData('entity_id',Helper.getOnlyInt(editPO.entity_id,true))
          },"Entity will be updated",null,"Do you want to update on line view?")
        }
        if(editPoFromDb.current.site_id != editPO.site_id){
          Helper.confirmMe(() => {
            updateLineOrderData('site_id',Helper.getOnlyInt(editPO.site_id,true))
          },"Site will be updated",null,"Do you want to update on line view?")
        }
        editPoFromDb.current = editPO;
      }
    },[editPO])
  // line update default data
  const updateLineOrderData = (key,value) =>{
    let poPutData = {
        linenumber: null,
        document_status: null,
        line_type: null,
        item_id: null,
        barcode: null,
        category_id: null,
        description: null,
        comments:null,
        itemgroup_id:null,
        ordered_qty: null,
        unit: null,
        unit_price: null,
        discount_amount: null,
        discount_percentage: null,
        line_amount: null,
        taxgroup_id: null,
        taxcodes_id: null,
        external_reference: null,
        ordered_id: null,
        requester_id: null,
        advance_payment: null,
        advance: null,
        recoupment_rate: null,
        retainage_rate: null,
        max_retainage_amount: null,
        match_level: null,
        onbehalf_request: null,
        distributionsource_id: null,
        requirement_description:null,
        scope_of_work:null,
        material_service_not_included:null,
        [key] : Helper.getNullableValue(value)
    }
    let api = Api;
    api.setUserToken();
    editPOLinesGrid.forEach( lineObj => {
      api.axios().put(Settings.apiPurchaseOrderUrl+`/line/${lineObj.line_id}/${Settings.source_id.purchase_order}`,poPutData).then(res => {
        Helper.alert(res.data.message)
      }).catch(error=>{
        getPopupMessageBasedOnStatus(error)
      })
    })
  }
  //End
 
  let navs = [
    {title:Helper.getLabel(props.language,'general','General'), isActive: true, },
    {title:Helper.getLabel(props.language,'delivery','Delivery')}, 
    {title:Helper.getLabel(props.language,'price_discount','Price & Discount')},
    {title:Helper.getLabel(props.language,'segment','Segment')},   
    {title:Helper.getLabel(props.language,'address','Address')},   
    {title:Helper.getLabel(props.language,'contact','Contact')},   
    {title:Helper.getLabel(props.language,'team','Team')},   
  ];
  const getVendorFromId = (vendorId) =>{
    let singleVendor =  {name:'',vendor_id:'',code:''};
    vendors.forEach(vn => {
       if(vn.vendor_id == vendorId){
        singleVendor = vn;
       }
    });
    return singleVendor;
  }
  const addComponentTop = () => {
    let items = [
      {title:Helper.getLabel(props.language,'document_number',"Document number"), subtitle: editPO.document_number ? editPO.document_number : ''},
      {title:Helper.getLabel(props.language,'document_type',"Document Type"), subtitle:  !ValidateData(type) ? (type.find(id => id.label == "Purchase Order") === undefined ? '' : type.find(id => id.label == "Purchase Order").label) : '' },
      {title:Helper.getLabel(props.language,'vendor_id',"Vendor Code"), subtitle: editPO.vendor_id ? getVendorFromId(editPO.vendor_id).code : ''},
      {title:Helper.getLabel(props.language,'vendorname',"Vendor Name"), subtitle: editPO.vendor_id ? getVendorFromId(editPO.vendor_id).name : '' },
    ]
    let currentpurchaseStatus = props.purchaseStatus.allStatus.find( item => {
      return item.id == editPO.purchase_status ? item : false
    })
    if(currentpurchaseStatus){
      setPurchaseStatusLabel(currentpurchaseStatus.name)
    }
    let statusColor = currentpurchaseStatus ? getPoColorCode(currentpurchaseStatus.id) : {};
    let tags = [
        {title:currentpurchaseStatus ? currentpurchaseStatus.name : null, style:{backgroundColor:statusColor.bgColor,color:statusColor.textColor}},
    ]
 
    return(
      <Fragment>
        <BlueCallToAction items={items} tags={tags}/> 
        <SwitchView hasPoChanged={hasPoChanged} poSaveHandler = {SaveHandler} setObjectClear={setListViewTabsObj} setViewComponent={setViewComponent} viewComponent={viewComponent} />
      </Fragment>
    )
  }
  
  const addComponentRight = () => {
    return(
    <MasterSidebar>
      <NoteAttachmentHistory  source_id ={purchase_order_source_id} notesource_integrator= {id}  attachmentsource_integrator ={id}/> 
    </MasterSidebar>
    )
    
  }
 const LoadPurchaseStatus = () => {
  props.LoadPurchaseStatus(props.purchaseStatus)
 }
  //API Calls Renderer
   useEffect(() => {
      getPurchaseOrders((arg) => {

        getPurchaseOrdersLines(arg)
      })
      
      TypeAPI()
      VendorsAPI() 
      TaxCodeAPI()
      TaxGroupAPI() 
      SitesAPI()
      EntityAPI()
      RequesterAPI()
      OrderedAPI()
      getUnitData()
      LoadPurchaseStatus()
      setOrderStatusProps({
            currency:"",
            exchange_rate:0,
            discount:0,
            round_off:0,
            line_amount:0,
            line_discount:0,
            tax:0
      })
    
    document.title = "Edit Contract Order";
    
   }, [])
 
 
  //End

  //API Reduction Calls
    let taxCodeCalled = false
    let taxGroupCalled = false 
    let POCalled = false
    let POLinesCalled = false
    let typeCalled = false
    let vendorsCalled = false
    let RequesterCalled = false
    let entityCalled = false
    let sitesCalled = false
    let OrderedCalled = false
    let unitCalled = false
  //End
  const setIsPoEditableConditions = (PoData) =>{
    let result = true;
    if(
        PoData.purchase_status == Settings.po_purchase_status.confirmed.id ||
        PoData.purchase_status == Settings.po_purchase_status.close.id ||
        PoData.purchase_status == Settings.po_purchase_status.freeze.id
      ){
      result =false;
    }
    
    
    if(poTeamAccess && poTeamAccess.access_level == 1){
      result = false;
    }
    setIsPoEditable(result)
  }
  //API Calls

  const getPurchaseOrders = (PoCallBackFn =null) => {
    if(POCalled){
      return;
    }
    setLoading(true)
    let api = Api
    POCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_header/${id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setIsNoPOFound(false)
        setLoading(false)
        setEditPO(res.data.data)
        editPoFromDb.current = res.data.data;
        setIsPoEditableConditions(res.data.data)
        if(res.data.data.document_number == null){
          isDisable = false
        }
        if(PoCallBackFn && typeof PoCallBackFn == 'function'){
          PoCallBackFn(res.data.data)
        }
      } 
    }).catch((res) => { 
      setLoading(false)
      setIsNoPOFound(true)
    })
  }
  
  const getPurchaseOrdersLines = (argData) => {
    if(POLinesCalled){
      return;
    } 
    setPOLinesLoading(true)
    let api = Api
    POLinesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/lines/${id}/${Settings.source_id.purchase_order}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setEditPOLinesGrid(res.data.data.reverse())
        updateSidebarOrderState(res.data.data.reverse(),argData);
        setPOLinesLoading(false)
        //setPreviousPurchaseOrderLinesData(Object.freeze(res.data.data))
        if(defaultGridData === null){
            defaultGridData = res.data.data   
        } 
        if(!DefStateData){ 
          DefStateData = {};
          res.data.data.forEach((element) => {
            DefStateData[element.line_id] = element 
          })
        }
          
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res)  
      setPOLinesLoading(false)
      // console.log(res.response.data.message)
    })
  }
  
  const RequesterAPI = () => {
    if(RequesterCalled) {
      return;
    }
      let api = Api
      RequesterCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let Requester = []
        res.data.data.forEach(item => {  
        Requester.push({
          value: item.employee_id,
          label: `${item.code} - ${item.display_name}`
        })
      }) 
      setRequester(Requester)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const OrderedAPI = () => {
    if(OrderedCalled) {
      return;
    }
      let api = Api
      OrderedCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let OrderedARR = []
        res.data.data.forEach(item => {  
        OrderedARR.push({
          value: item.employee_id,
          label: `${item.code} - ${item.display_name}`
        })
      }) 
      setOrdered(OrderedARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const getUnitData = () => {
    if(unitCalled){
        return;
    } 
    let api = Api
    unitCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
    if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
            UPDATED_STATE.push({
                value: element.unit_id,
                label:`${element.code} - ${element.description}`
            })
        })
        setUnit(UPDATED_STATE)
    }
    }).catch((res) => { 
        getPopupMessageBasedOnStatus(res)  
    })
}
 
  const TaxCodeAPI = () => {
    if(taxCodeCalled) {
      return;
    }
      let api = Api
      taxCodeCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/taxgroupitem',{}).then(function(res){
      if(res.data.status==="success"){
        let TaxCode = []
        res.data.data.forEach(item => {  
        TaxCode.push({
          value: item.taxgroupitem_id,
          label:  item.name
        })
      }) 
      setTaxCode(TaxCode)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const TaxGroupAPI = () => {
    if(taxGroupCalled) {
      return;
    }
      let api = Api
      taxGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
      if(res.data.status==="success"){
        let TaxGroup = []
        res.data.data.forEach(item => {  
        TaxGroup.push({
          value: item.taxgroup_id,
          label: item.name
        })
      }) 
      setTaxGroup(TaxGroup)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const VendorsAPI = () => {
    if(vendorsCalled) {
      return;
    }
      let api = Api
      vendorsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
      if(res.data.status==="success"){
        let VendorsDropdownARR = []
        res.data.data.forEach(item => {  
        VendorsDropdownARR.push({
          value: item.vendor_id,
          label: `${item.code} - ${item.name}`
        })
      }) 
      setVendorsDropdown(VendorsDropdownARR)
      setVendors(res.data.data)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // getPopupMessageBasedOnStatus(res) 
    })
  }

  const EntityAPI = () => {
    if(entityCalled){
        return;
    }
    let api = Api
    entityCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + '/get_entities').then(function(res){
      if(res.data.status==="success"){
        let EntityARR = []
        res.data.data.forEach(item => {  
        EntityARR.push({
          value: item.entity_id,
          label: `${item.code} - ${item.name}`
        })
       }) 
       setEntities(EntityARR)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const SitesAPI = () => {
    if(sitesCalled){
        return;
    }
    let api = Api
    sitesCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+'/get_site',{
      "code":"",
      "name":""
    }).then(function(res){
      if(res.data.status==="success"){
        let SitesARR = []
        res.data.data.forEach(item => {  
        SitesARR.push({
          value: item.site_id,
          label: `${item.code} - ${item.name}`
        })
      }) 
      setSites(SitesARR)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const TypeAPI = () => {
    if(typeCalled){
        return;
    }
    let api = Api
    typeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/POHeaderType',{}).then(function(res){
      if(res.data.status==="success"){
        let TypeARR = []
        res.data.data.forEach(item => {  
        TypeARR.push({
          value: item.id,
          label: item.name
        })
       }) 
       setType(TypeARR) 
      } 
  }).catch((res) => { 
    getPopupMessageBasedOnStatus(res) 
  })
}

  //End

 // Header View Tab
  const HeaderViewSegment = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'segment','Segment')}>
        <SegmentsDistribution  isEditable={isPoEditable} id="purchase_order_header_segments" disableFinancial={true} onCreateFirstDistribution={onCreateFirstDistribution} distribution_source_id={editPO.distributionsource_id} integrator={id} source_id ={purchase_order_source_id} totalAmount = {100} />
      </Collapse>  
    )
  }
 
  //End

  //End

  //API Executions UPDATE Requests

  const UpdatePurchaseOrderHeader = (isShowResponseMessage = true) => {
    setLoading(true)
    let api = Api 
    api.setUserToken()
    api.axios().put(Settings.apiPurchaseOrderUrl+`/PO_header/${id}`,{ 
        "type": !ValidateArray(type) ? type.find(id => id.label == "Purchase Order").value : null,
        "description": editPO.description !== "" ? editPO.description : null,
        "vendor_id": editPO.vendor_id ? parseInt(editPO.vendor_id) : null,
        "vendorsite_id": editPO.vendorsite_id ? parseInt(editPO.vendorsite_id) : null,
        "vendorgroup_id": editPO.vendorgroup_id ? parseInt(editPO.vendorgroup_id) : null,
        "purchase_status": null,//editPO.purchase_status,
        "document_status": null,// editPO.document_status,
        "accounting_date": Helper.getDateForDb(editPO.accounting_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(editPO.accounting_date) : null,
        "document_date": Helper.getDateForDb(editPO.document_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(editPO.document_date) : null,
        "delivery_date": Helper.getDateForDb(editPO.delivery_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(editPO.delivery_date) : null,
        "period_id": editPO.period_id ? parseInt(editPO.period_id) : null,
        "delivery_address_id": editPO.delivery_address_id,
        "delivery_name": editPO.delivery_name !== "" ? editPO.delivery_name : null,
        "attention_information": editPO.attention_information !== "" ? editPO.attention_information : null,
        "mode_of_delivery_id": editPO.mode_of_delivery_id ? parseInt(editPO.mode_of_delivery_id) : null,
        "delivery_term_id": editPO.delivery_term_id ? parseInt(editPO.delivery_term_id) : null,
        "discount_amount": editPO.discount_amount !== "" ? editPO.discount_amount : null,
        "discount_percentage": editPO.discount_percentage !== "" ? editPO.discount_percentage : null,
        "rounding_amount": editPO.rounding_amount !== "" ? editPO.rounding_amount : null,
        "taxgroup_id": editPO.taxgroup_id ? parseInt(editPO.taxgroup_id) : null,
        "taxgroupitem_id": editPO.taxgroupitem_id ? parseInt(editPO.taxgroupitem_id) : null,
        "priceinclude_tax": editPO.priceinclude_tax == "on" ? (editPO.priceinclude_tax == "on" ? true : false) : editPO.priceinclude_tax,
        "requester_id": editPO.requester_id ? parseInt(editPO.requester_id) : null,
        "orderer_id": editPO.orderer_id ? parseInt(editPO.orderer_id) : null,
        "currency_id": editPO.currency_id ? parseInt(editPO.currency_id) : null,
        "emailaddress": editPO.emailaddress !== "" ? editPO.emailaddress : null,
        "warehouse_id": editPO.warehouse_id ? parseInt(editPO.warehouse_id) : null,
        "entity_id": editPO.entity_id ? parseInt(editPO.entity_id) : null,
        "site_id": editPO.site_id ? parseInt(editPO.site_id) : null,
        "buyergroup_id": editPO.buyergroup_id ? parseInt(editPO.buyergroup_id) : null,
        "paymentterm_id": editPO.paymentterm_id ? parseInt(editPO.paymentterm_id) : null,
        "distributionsource_id": editPO.distributionsource_id ,
        "blindreceivingof_qty": editPO.blindreceivingofqty == "on" ? (editPO.blindreceivingofqty == "on" ? true : false) : editPO.blindreceivingofqty,
        "blindreceivingof_item": null,
        "source_id": null,
        "externalreference": editPO.externalreference !== "" ? editPO.externalreference : null,
        "attachmentsource_id": null,
        "notesource_id": null,
        "document_number": editPO.document_number !== "" ? editPO.document_number : null,
        "contactsource_id": null,
        "addresssource_id":null,
        "allow_access_only_to_buyer_group": editPO.allowaccesstobuyergroup == "on" ? (editPO.allowaccesstobuyergroup == "on" ? true : false) : editPO.allowaccesstobuyergroup
    }).then(function(res){
    if(res.data.status==="success"){
      if(isShowResponseMessage){
        Helper.alert(res.data.message)
      }
      setLoading(false)
      getPurchaseOrders()
      if(editPO.document_number !== null){
        isDisable = true
      }
    }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoading(false)
      getPurchaseOrders()
    }) 
  }
  const updateOrderStatus = (newOrderStatus) => {
    let api = Api;
    api.setUserToken();
    let newPoData = {
      purchase_status: newOrderStatus
    }
    props.setMasterContentLoader(true)
    api.axios().put(Settings.apiPurchaseOrderUrl+'/PO_header/'+id,newPoData).then(res => {
      Helper.alert(res.data.message)
      POCalled = false;
      props.setMasterContentLoader(false)
      getPurchaseOrders()
    }).catch(error => {
      getPopupMessageBasedOnStatus(error);
      props.setMasterContentLoader(false)
    })
  }
  const updateOrderDocumentStatus = (document_status) => {
    let api = Api;
    api.setUserToken();
    let newPoData = {
      document_status: document_status
    }
    props.setMasterContentLoader(true)
    api.axios().put(Settings.apiPurchaseOrderUrl+'/PO_header/'+id,newPoData).then(res => {
      Helper.alert(res.data.message)
      POCalled = false;
      props.setMasterContentLoader(false)
      getPurchaseOrders()
    }).catch(error => {
      getPopupMessageBasedOnStatus(error);
      props.setMasterContentLoader(false)
    })
  }
  const onAddressCreateHandler = (addressResponse) => {
    console.log(addressResponse,'addressResponse')
    let api = Api;
    api.setUserToken();
    api.axios().put(Settings.apiPurchaseOrderUrl+'/PO_header/'+id,{addresssource_id:addressResponse.address_source_id}).then(res => {
    })
    setEditPO({
      ...editPO,
      addresssource_id:addressResponse.address_source_id
    })
  }
  const onContactCreate = (contactResponse) => {
    console.log(contactResponse,'contactResponse')
    let api = Api;
    api.setUserToken();
    api.axios().put(Settings.apiPurchaseOrderUrl+'/PO_header/'+id,{contactsource_id:contactResponse.contact_source_id}).then(res => {
    })
    setEditPO({
      ...editPO,
      contactsource_id:contactResponse.contact_source_id
    })
  }
  //END
  const getPoColorCode = (statusId) => {
    let output = {
      bgColor:'#fff',
      textColor:'#000'
    }
    let poStatusWithColor = Settings.po_purchase_status;
    for (const key in poStatusWithColor) {
      if (Object.hasOwnProperty.call(poStatusWithColor, key)) {
        const poSt = poStatusWithColor[key];
        if(statusId == poSt.id){
          output = poSt;
        }
      }
    }
    return output;
  }
  const poDeleteHandler= () =>{
    Helper.confirmMe(function(){
      let delPO_ID = editPO.purchase_order_id;
      setLoading(true);
      let api = Api;
      api.setUserToken();
      api.axios().delete(Settings.apiPurchaseOrderUrl+'/PO_header/'+delPO_ID).then(res=>{
        Helper.alert(res.data.message);
        navigate('/purchase-orders');
      }).catch(error => {
        setLoading(false)
      })
    },Helper.getLabel(null,'po_del_msg','Purchase order will be deleted'))
    
  }
  const canPoActionButtonActive = (poActionKey,poActionId=null) => {
    let poHelper = new PoHelper(editPO);
    return poHelper.canPoActionButtonActive(poActionKey,poActionId,poTeamAccess)
  }
  const showTaxTransaction =() =>{
    setIsShowTransactionPopup(true)
  }
  
  const afterCloseTaxPopup =() =>{
    setIsShowTransactionPopup(false)
  }
  
  const afterCloseCancelDoumentPopup =() =>{
    setIsVisibleCancelDocumentPopup(false)
  }
  const showCancelDocumentPopup =() =>{
    setIsVisibleCancelDocumentPopup(true)
  }
  const getDocumentStatusLabel =() =>{
    let output = editPO.document_status;
    if(editPO.document_status){
      props.dataLoaderProps.documentStatus.status.forEach(dcStatus => {
        if(editPO.document_status == dcStatus.id){
          output = dcStatus.name;
        }
      });
    }
    
    return output;
  }
  const navigationMoreOptions = () => {
    
    let PoPS = Settings.po_purchase_status;
    return(
      <ul className='actions_nav'>
        {  canPoActionButtonActive('confirmed') ?<li onClick={ e => { updateOrderDocumentStatus(1) }}>{Helper.getLabel(props.language,'confirmed',"Confirmed")}</li>:<li className='disable'>{Helper.getLabel(props.language,'confirmed',"Confirmed")}</li> }
        {  canPoActionButtonActive('edit') ?<li onClick={ e => { updateOrderDocumentStatus(0) }}  >{Helper.getLabel(props.language,'edit',"Edit")}</li>: <li className='disable'>{Helper.getLabel(props.language,'edit',"Edit")}</li> }
        {  canPoActionButtonActive('close') ?<li onClick={ e => { updateOrderStatus(PoPS.close.id) }} >{Helper.getLabel(props.language,'close',"Close")}</li>: <li className='disable'>{Helper.getLabel(props.language,'close',"Close")}</li> }
        {  canPoActionButtonActive('reopen') ?<li onClick={ e => { updateOrderStatus(PoPS.reopen.id) }} >{Helper.getLabel(props.language,'reopen',"Reopen")}</li>: <li className='disable'>{Helper.getLabel(props.language,'reopen',"Reopen")}</li> }
        {  canPoActionButtonActive('freeze') ?<li onClick={ e => { updateOrderStatus(PoPS.freeze.id) }}  >{Helper.getLabel(props.language,'freeze',"Freeze")}</li>: <li className='disable'>{Helper.getLabel(props.language,'freeze',"Freeze")}</li> }
        {  canPoActionButtonActive('hold') ?<li onClick={ e => { updateOrderStatus(PoPS.hold.id) }} >{Helper.getLabel(props.language,'hold',"Hold")}</li> : <li className='disable'>{Helper.getLabel(props.language,'hold',"Hold")}</li> }
        {  canPoActionButtonActive('withdraw_freeze') ?<li onClick={ e => { updateOrderStatus(PoPS.withdrawFreeze.id) }} >{Helper.getLabel(props.language,'withdraw_freeze',"Withdraw Freeze")}</li> : <li className='disable'>{Helper.getLabel(props.language,'withdraw_freeze',"Withdraw Freeze")}</li> }
        {  canPoActionButtonActive('renew') ?<li onClick={ e => {   }} >{Helper.getLabel(props.language,'renew',"Renew")}</li> : <li className='disable'>{Helper.getLabel(props.language,'renew',"Renew")}</li> }
        <li className="nav_divider"><Link to={"/purchase-order-version/"+id}>{Helper.getLabel(null,'view_version','View Version')}</Link></li>
        <li><Link to={"/purchase-order-history/"+id}>{Helper.getLabel(null,'view_history','View History')}</Link></li>
        {  canPoActionButtonActive('invoice') ? <li><Link to={"/invoice/"+id}>{Helper.getLabel(null,'invoice','Invoice')}</Link></li> : <li className='disable'>{Helper.getLabel(null,'invoice','Invoice')}</li>}
        <li onClick={ e => { showTaxTransaction() }}>{Helper.getLabel(props.language,'view_tax',"View tax")}</li>
        {  canPoActionButtonActive('view_change_logs') ? <li ><Link to={"/po-change-logs/"+id}>{Helper.getLabel(null,'view_change_logs','View Change Logs')}</Link></li> : <li className='disable'>{Helper.getLabel(null,'view_change_logs','View Change Logs')}</li>}
        {  canPoActionButtonActive('goods_receiving_note') ? <li><Link to={"/good-receiving/notes/"+id}>{Helper.getLabel(null,'goods_receiving_note','Goods Receiving Note')}</Link></li> : <li className='disable'>{Helper.getLabel(null,'goods_receiving_note','Goods Receiving Note')}</li>}
        {  canPoActionButtonActive('return_order') ?  <li><Link to={"/return-order/all/"+id}>{Helper.getLabel(null,'return_order','Return Order')}</Link></li> :  <li className='disable'>{Helper.getLabel(null,'return_order','Return Order')}</li>}
        <li><Link to={"/amendment-request/all/"+id}>{Helper.getLabel(null,'amendment_request','Amendment request')}</Link></li>
        {  canPoActionButtonActive('goods_receiving_note') ? <li ><Link to={"/work-confirmation-list?&purchaseorder_id="+id}>{Helper.getLabel(null,'work_confirmation','Work Confirmation')}</Link></li> :  <li className='disable'>{Helper.getLabel(null,'work_confirmation','Work Confirmation')}</li>}
        {  canPoActionButtonActive('cancel_document') ? <li onClick={ e => {showCancelDocumentPopup()}}>{Helper.getLabel(null,'cancel_document','Cancel Document')}</li> :  <li className='disable'>{Helper.getLabel(null,'cancel_document','Cancel Document')}</li>}
        { canPoActionButtonActive('delete') ? <li onClick={ e => { poDeleteHandler() }}  className='nav_divider'>{Helper.getLabel(props.language,'delete',"Delete")}</li> : <li   className='nav_divider disable'>{Helper.getLabel(props.language,'delete',"Delete")}</li>} 
      </ul>
    )
  }
  let saveButtonTimeout = null;
  const SaveHandler = (isShowResponseMessage = true) => {
    if(!isPoEditable){
      return;
    }
    
    clearTimeout(saveButtonTimeout);
    saveButtonTimeout = setTimeout(function(){
      let validData = {
        ...editPO
      }
  
      if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
      }
  
   
      if(editPO.requester_id && editPO.orderer_id && editPO.requester_id === editPO.orderer_id ){
        Helper.alert('Orderer & Requester Cant Be Same!', 'error')
        return;
      } 
      
  
      $('#lnk_tb_rsListViewGridDistribution .rs_grid_btn_save').trigger('click');  
      //$('.po_line_grid_collapse .rs_grid_btn_save').trigger('click');  
      $('.purchase_orders_lines_list_view_grid .rs_grid_btn_save').trigger('click');  
      $('.purchase_orders_lines_list_view_billing_rule_grid .rs_grid_btn_save').trigger('click');  
      $('.purchase_orders_lines_list_view_distribution_general_grid .rs_grid_btn_save').trigger('click'); 
      $('.po_contact_list .rs_grid_btn_save').trigger('click');
      UpdatePurchaseOrderHeader(isShowResponseMessage)
      defaultGridData = editPOLinesGrid

    },200)
    
  }
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }
  const onCreateFirstDistribution = (newDisSouceId) =>{
    let api = Api;
    api.setUserToken();
    api.axios().put(Settings.apiPurchaseOrderUrl+'/PO_header/'+id,{distributionsource_id:newDisSouceId}).then(res => {
      
    })
    setEditPO({
      ...editPO,
      distributionsource_id:newDisSouceId
    })
    
  } 
  if(isNoPOFound){
    return(<div className='mt-4'><Alert type="danger" message={Helper.getLabel(null,'record_not_found',"Record not found")}/></div>)
  }
  
  return (
    <>
    <div className=''>
      <NavigationHeder moreOptions={navigationMoreOptions} title={ `<span style="color:#000;">${Helper.getLabel(props.language,'edit_po',"Edit Contract Order")} #</span> ${id ? id : ''}` }>
        <Button title={"Print"} icon="/images/icons/print.svg"/>
        <TotalWidget source_id={purchase_order_source_id} integrator={editPO.purchase_order_id}/>
        <Button id="btn_master_save" isDisable={!security.canCreate(frontendIds.procure.purchase_orders.purchase_orders_edit_screen_save_button) || !isPoEditable } isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
    </div>
    {loading ? <SimpleLoading /> : ''} 
    { isShowTransactionPopup ? <TaxTransactionPopup onTaxTransactionPopupClose = { afterCloseTaxPopup } line_id={null} source_id={purchase_order_source_id} integrator={editPO.purchase_order_id}/> : '' }
    { isVisibleCancelDocumentPopup ? <CancelDocumentsPopup onPopupClose = { afterCloseCancelDoumentPopup } po={editPO}/> : '' }
    <section className={viewComponent === 'Header View' ? 'header_view_class' : 'list_view_class'}>
      <Tabs navs={navs} tabContantsId='purchase-orders-tabs'  showAll={true} scrollOffset="330" addComponentTop = {addComponentTop} addComponentRight={addComponentRight} >
        {
          viewComponent == "Header View" ? 
          <HeaderViewGeneral purchase_status_label={purchaseStatusLabel} document_status_label = {getDocumentStatusLabel()} isEditable={isPoEditable} updateOrderStatusWidget={setOrderStatusProps} sites={sites} entities={entities} state={editPO} setState={setEditPO} requesterAPI={requester} orderedAPI={ordered} taxCode={taxCode} isDisable={isDisable} vendorsDropdown={vendorsDropdown} taxGroup={taxGroup} /> 
          :
          <>
          <LineViewComponent 
            defaultValues ={{
              requester_id:editPO.requester_id,
              warehouse_id:editPO.warehouse_id,
              delivery_date:editPO.delivery_date,
              taxgroupitem_id:editPO.taxgroupitem_id,
              taxgroup_id:editPO.taxgroup_id,
              entity_id:editPO.entity_id,
              site_id:editPO.site_id,
              orderer_id:editPO.orderer_id
            }}
            source_id={Settings.source_id.purchase_order} 
            line_source={Settings.source_id.purchase_order} 
            isEditable={isPoEditable} 
            lineGridTitle = {Helper.getLabel(null,'purchase_order_lines',"Purchase Order Lines")}
            header_id={editPO.purchase_order_id}
            purchase_status={editPO.purchase_status}
            document_status={editPO.document_status}
            delivery_date={editPO.delivery_date}
            master_save_button_id = "btn_master_save"
            onbehalf_request =  {false}
            vendor_id = {editPO.vendor_id}
            vendorsite_id = {editPO.vendorsite_id}
            setOrderStatusProps={setOrderStatusProps}
            
            // recommended =  {true}
            // recommended_editable =  {true}
            // preferred={true}
            // preferred_editable={true}
            // award_to={true}
            // award_to_editable={true}
            //lineColumsOrder ={['comments']}
            //proposed_quantity = {true}
            //lines_attachment = {true}

            //lineColumsOrder ={['comments']}
            //proposed_quantity = {true}
            //lines_attachment = {true}
            />
          </> 
        
        } 
        {viewComponent == "Header View" ? <HeaderViewDelivery  isEditable={isPoEditable} state={editPO} setState={setEditPO} /> : <div></div>} 
        {viewComponent == "Header View" ? <HeaderViewPriceDiscount  isEditable={isPoEditable} sites={sites} entities={entities} state={editPO} setState={setEditPO} /> : <div></div>}
        {viewComponent == "Header View" ? HeaderViewSegment() : <div> </div>}
        {viewComponent == "Header View" ? editPO.purchase_order_id ? <AddressList canEdit={ isPoEditable } onAddressCreate = { onAddressCreateHandler  } addressSourc_id = {editPO.addresssource_id} address_source_integrator={editPO.purchase_order_id} source_id ={Settings.source_id.purchase_order}/> : <div><SimpleLoading/></div> : <div> </div>}
        {viewComponent == "Header View" ? <Collapse className="mt-4 po_contact_list grid_save_btn_hide" title={ Helper.getLabel(null,'contact','Contact') } open={true}> {editPO.purchase_order_id ? <ContactAddress onGridReady={ (ctGrid) => { contactGridObj = ctGrid} } canEdit={isPoEditable} onContactCreate = { onContactCreate } contactsource_id ={editPO.contactsource_id} source_id = {Settings.source_id.purchase_order} address_id = {null} contactsource_integrator={editPO.purchase_order_id} /> : <SimpleLoading  />  }  </Collapse> : <div> </div>}
        {viewComponent == "Header View" ? <Team isEditable={isPoEditable} source_id ={purchase_order_source_id} integrator={id}/> : <div> </div>}
      </Tabs>
    </section>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
      isMasterContentLoader:state.isMasterContentLoader.isLoading,
      appOptions:state.options,
      auth:state.auth,
      language:state.language,
      purchaseStatus:state.purchaseStatus
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen
}
const setDispatchToProps = (dispatch) => {
  return {
    setMasterContentLoader: (value) => { dispatch({type:ActionTypes.SET_IS_CONTENT_MASTER_LOADER,payload:value})  },
    LoadPurchaseStatus: (oldData) => { LoadPurchaseStatus(dispatch,oldData)}
  }
}
export default connect(mapStateToProps,setDispatchToProps) ( MasterComponentWraper(ApplySecurityRoles(PoTeamAccess( RsDataLoader(EditCO,{poDocumentStatus:true}) ), SecurityOptions)) )

