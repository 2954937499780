import React, { Component } from 'react';
import Popup from '../../../Popup/Popup';
import Helper from '../../../../inc/Helper';
import $ from "jquery";
import Collapse from '../../../inc/Collapse';
import AgGrid from '../../../grid/ag/ag-grid';
import AdvancedDropdown from '../../../grid/ag/cellEditor/AdvancedDropdown';
import Settings from '../../../../inc/Settings';
import RsDataLoader from '../../../inc/RsDataLoader';
import Dropdown from '../../../Forms/Dropdown';
import Api from '../../../../inc/Api';
import AgGridMemo from '../../../grid/ag/AgGridMemo';
import AmendmentDatePopupButton from './AmendmentDatePopupButton';
import Button from '../../../inc/Button';
import PopupHeader from '../../../Popup/PopupHeader';
import BlueCallToAction from '../../../Widgets/BlueCallToAction/BlueCallToAction';
import WarehouseCellRenderer from '../../../grid/ag/cellRender/WarehouseCellRenderer';
import SiteCellRenderer from '../../../grid/ag/cellRender/SiteCellRenderer';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
/**
 * closePopup = function
 * amendment_id
 */
class AmendmentSitePopup extends Component {
    constructor(props){
        super(props);
        this.forceRender = 1;
        this.grid = null;
        this.saveTimeout = null;
        this.state = {
            isLoading:false,
            lineObj:{},
            selectedNode:null,
            PoLinesite:[]
        }

    }
    componentDidMount(){
        this.loadPoLinesite();
        this.loadLineById(this.getLineId());
    }
    loadLineById(lineId){
        let api = Api, that = this;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        api.axios().get(Settings.apiPurchaseOrderUrl+`/line/${lineId}/${this.props.line_source}`).then(res => {
            that.setState({
                isLoading:false,
                lineObj:res.data.data
            })
        })
    }
    onClose(){
        if(this.props.onClose){
            this.props.onClose();
        }
        $('html').removeClass('rs_popup_open');
    }
    canEditReceivedQty(prarams){
        return true;
    }
    getLineId(){
        return this.props.po_line_id ? this.props.po_line_id : this.props.line_id;
    }
    loadPoLinesite(){
        if(!this.props.po_line_id){
            this.forceRender++;
            this.setState({
                PoLinesite:[],
                isLoading:false
            })
            return;
        }
        let that = this;
        let api = Api;
        api.setUserToken();
        that.setState({
            PoLinesite:[],
            isLoading:true
        })
        let apiUrl = Settings.apiPurchaseOrderUrl +'/linesites/' +this.props.po_line_id+'/'+this.props.source_id;
        api.axios().get(apiUrl).then(res => {
            that.forceRender++;
            let poLineSiteData = res.data.data;
            that.setState({
                PoLinesite:poLineSiteData,
                isLoading:false
            })
        }).catch(error =>{
            that.forceRender++;
            that.setState({
                PoLinesite:[],
                isLoading:false
            })
        })
    }
    getSiteFromId(id){
        let output = null;
        this.props.dataLoaderProps.sites.sites.forEach( item => {
            if(id == item.site_id){
                output = item;
            }
        })
        return output;
        
    }
    getWarehouseFromId(params){
        return {}
    }
    onAmendantDateSaveClick(data){
        let selectedNode = this.state.selectedNode;
        if(!selectedNode){
            Helper.alert(Helper.getLabel(null,'please_select_row','Please select row'),'error')
            return;
        }
        selectedNode.setDataValue('new_start_date', data?.new_start_date);
        selectedNode.setDataValue('new_end_date',data?.new_end_date);
        this.grid.componentObj.setGridValueChanged(selectedNode.id,selectedNode.data)
    }
    getIntegratorForDuration(){
        if(!this.state.selectedNode){
            return null;
        }
        return this.state.selectedNode.data.linesite_id;
    }
    getExistingDates(){
        if(!this.state.selectedNode){
            return null;
        }
        return {
            start_date:this.state.selectedNode.data.start_date,
            end_date:this.state.selectedNode.data.end_date,
        }
    }
    addButtonsAfterDeleteButon(){
        return(
            <>
                <AmendmentDatePopupButton getExistingDates={this.getExistingDates.bind(this)} amendmentline_type = {2} integrator_id={this.getIntegratorForDuration.bind(this)} amendment_id={this.props.amendment_id} onClickSave={this.onAmendantDateSaveClick.bind(this)}/>
            </>
        )
    }
    onSaveBtnHanlder(event){
        if(this.grid){
            clearTimeout(this.saveTimeout)
            let that = this;
            this.saveTimeout = setTimeout(function(){
                that.grid.componentObj.saveBtnClickHandler(event);
            },100)
        }
    }
    onSelectionChanged(event){
        let selectedNodes = event.api.getSelectedNodes();
        if(selectedNodes.length <=0){
            this.setState({
                selectedNode:null
            })
            
        }else{
            this.setState({
                selectedNode:selectedNodes[0]
            })
        }
    }
    onGridSaveHandler(data){
        let api = Api;
        let that = this;
        let amendment_id = this.props.amendment_id;
        if(!amendment_id){
            return;
        }
        api.setUserToken();
        let siteData = data.map( item => {
            return {
                entity_id: Helper.getNullableValue(item?.entity_id),
                external_reference:  Helper.getNullableValue(item?.external_reference),
                line_amount:  Helper.getNullableValue(item?.line_amount),
                line_id:  Helper.getNullableValue(item?.line_id),
                linesite_id:  Helper.getNullableValue(item?.linesite_id),
                ordered_qty:  Helper.getNullableValue(item?.decrease_quantity),
                over_delivery_tolearance:  Helper.getNullableValue(item?.over_delivery_tolearance),
                overreceipt_action:  Helper.getNullableValue(item?.overreceipt_action),
                price_include_tax:  Helper.getNullableValue(item?.price_include_tax),
                requester_id:  Helper.getNullableValue(item?.requester_id),
                requisition_id:  Helper.getNullableValue(item?.requisition_id),
                site_id:  Helper.getNullableValue(item?.site_id),
                taxgroup_id:  Helper.getNullableValue(item?.taxgroup_id),
                taxgroupitem_id:  Helper.getNullableValue(item?.taxgroupitem_id),
                under_delivery_tolerance:  Helper.getNullableValue(item?.under_delivery_tolerance),
                warehouse_id:  Helper.getNullableValue(item?.warehouse_id),

                amendment_id: amendment_id,
                amendment_nature: 1, // Decrease ammendment nature,
                delivery_date: Helper.getDateForDb(item.delivery_date),
                end_date: Helper.getDateForDb(item.end_date),
                start_date: Helper.getDateForDb(item.start_date),
            }
        })
        let newData = {
            "amendment_id":amendment_id,
            "amendment_nature":1,
            "sites":siteData
        }
        api.axios().post(Settings.apiPurchaseOrderUrl + '/amendmentdetails',newData).then(res =>{
            Helper.alert(res.data.message);
            var event = new CustomEvent("rs_amendment_created", { "api_response": res });
            document.dispatchEvent(event);
            $('.rs_popup_close').trigger('click');

        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })
    }
    onDecreaseQuantityHandler(event,params){
        let newAmount = params.data.line_amount;
        let unitPrice = Helper.getOnlyNumber( this.state.lineObj.unit_price );
        newAmount = (event.target.value * unitPrice)
        params.node.setDataValue('line_amount', newAmount);
    }
    onRowEditingStopped(params){
        let unitPrice = Helper.getOnlyNumber( this.state.lineObj.unit_price );
        let newAmount = ( Helper.getOnlyNumber(params.data.decrease_quantity) * unitPrice)
        params.node.setDataValue('line_amount', newAmount);
    }
    render() {
        let PoLinesite =this.state.PoLinesite;
        let language = null;
        let entitiesOptions = this.props.dataLoaderProps.entity.entity.map( item => {
            return {
                label:`${item.name} | ${item.code}`,
                value:item.entity_id
            }
        });
        let linestatusOptions = this.props.dataLoaderProps.lineSiteStatus.allStatus.map( (siteStatus) => {
            return ({
                label:siteStatus.name,
                value:siteStatus.id
            })
        })
        let  distributionValidations={
            entity_id:{
                types:['Required']
            },
            site_id:{
                types:['Required']
            },
            warehouse_id:{
                types:['Required']
            },
            ordered_qty:{
                types:['Required']
            },
            decrease_quantity:{
                types:[]
            },
            delivery_date:{
                //types:['Required']
            },
            start_date:{
                //types:['Required']
            },
            end_date:{
                //types:['Required']
            },
            received_qty:{
                //types:['Required']
            }
        }
        let header =[
            {field:'entity_id',inputId:'emnd_entity_id',editable:false,minWidth: 180,validationRule: distributionValidations.entity_id, headerName:Helper.getLabel(language,'entity',"Entity"), options: entitiesOptions, inputType: "dropdown"},
            {
                field:'site_id',minWidth: 180,validationRule: distributionValidations.site_id, headerName:Helper.getLabel(language,'site',"Site"), 
                //options: sitesOptions, inputType: "dropdown"
                cellRenderer : SiteCellRenderer,
                inputId:'emnd_site_id',
                editable:false,
                cellEditor:AdvancedDropdown,
                //cellEditorPopup: true,
                cellEditorParams:{
                    lynSettings: {
                        dependentSelector:['emnd_entity_id'],
                        apiUrl:Settings.apiUrl +  '/get_site_entity',
                        apiRequestMethod:'get',
                        options: (apiResponse) => {
                            let output = apiResponse.data.data.map( item => {
                                return {
                                    label: `${item.name}`,
                                    value: item.site_id
                                }
                            });
                            return output;
                        }
                    }
                }
            },
            {
                field:'warehouse_id',minWidth: 180,validationRule: distributionValidations.warehouse_id, headerName:Helper.getLabel(language,'warehouse',"Warehouse"),
                cellRenderer : WarehouseCellRenderer,
                inputId:'emnd_warehouse_id',
                cellEditor:AdvancedDropdown,
                cellEditorParams:{
                    lynSettings: {
                        dependentSelector:['emnd_site_id'],
                        resetDependentSelector:['emnd_entity_id'],
                        apiUrl:Settings.apiUrl +  '/get_warehouse_site',
                        apiRequestMethod:'get',
                        options: (apiResponse) => {
                            let output = apiResponse.data.data.map( item => {
                                return {
                                    label: `${item.name}`,
                                    value: item.warehouse_id
                                }
                            });
                            return output;
                        }
                    }
                },
                editable:false
            },
            {field:'ordered_qty',editable:false,minWidth:200,inputType:'number',validationRule: distributionValidations.ordered_qty, headerName:Helper.getLabel(language,'quantity',"Quantity")},
            {field:'decrease_quantity',autoFocus:true,minWidth:200,inputType:'number',onlyPositive:true,maxValue:(value,param)=> { return param.data.remaining_qty },minValue:'0',validationRule: distributionValidations.decrease_quantity, onChange:this.onDecreaseQuantityHandler.bind(this), headerName:Helper.getLabel(language,'decrease_quantity',"Decrease Quantity")},
            {field:'remaining_qty',editable:false,minWidth:250,inputType:'number', headerName:Helper.getLabel(language,'remaining_quantity',"Remaining Quantity")},
            {field:'line_amount', inputType:'number', editable:false,minWidth:150,headerName:Helper.getLabel(language,'amount',"Amount")},
            {field:'start_date',minWidth:200,editable:false,validationRule: distributionValidations.start_date, headerName:Helper.getLabel(language,'start_date',"Start Date"), inputType: "datepicker"},
            {field:'end_date',minWidth:200,editable:false,validationRule: distributionValidations.end_date, headerName:Helper.getLabel(language,'end_date',"End Date"), inputType: "datepicker"},
            {field:'delivery_date',editable:false,minWidth:200,validationRule: distributionValidations.delivery_date, headerName:Helper.getLabel(language,'delivery_date',"Delivery Date"), name: "delivery_date_po_lines",defaultValue:Helper.formateDate(this.props.delivery_date), inputType: "datepicker"},
            {field:'line_status',editable:false,headerName:Helper.getLabel(language,'status',"Status"),defaultValue:1, inputType:"dropdown", options:linestatusOptions},
            {field:'new_start_date',editable:false,headerName:Helper.getLabel(language,'new_start_date',"New start date"),hide:true, minWidth:200},
            {field:'new_end_date',editable:false,headerName:Helper.getLabel(language,'new_end_date',"New end date"),hide:true, minWidth:200}
        ]
        let lineObj = this.state.lineObj;
        let blugBoxInfo = [
            {title:Helper.getLabel(null,'line_no','Line no'), subtitle: lineObj.line_number },
            {title:Helper.getLabel(null,'quantity','Quantity'), subtitle: Helper.formateCurrency(lineObj.ordered_qty) },
            {title:Helper.getLabel(null,'unit_price','Unit Price'), subtitle: Helper.formateCurrency(lineObj.unit_price) },
            {title:Helper.getLabel(null,'line_amount','Line Amount'), subtitle: Helper.formateCurrency(lineObj.line_amount) },
        ]
        return (
            <Popup autoOpen={true} onClose={this.onClose.bind(this)} width="1100px">
                <PopupHeader>
                    <div>
                        {Helper.getLabel(null,'amendment_line_site',"Amendment line site")}
                    </div>
                    <div>

                        <Button title={Helper.getLabel(null,"save","Save am")} className="blue" icon="/images/icons/save-white.svg"  onClick={ this.onSaveBtnHanlder.bind(this)}/>
                    </div>
                </PopupHeader>
                <div className='mb-3 rfq-internal-blue'>
                    <BlueCallToAction items = {blugBoxInfo}/>
                </div>
                <Collapse open={true} title={Helper.getLabel(null,'amendment','Amendment')}>
                    <AgGridMemo
                        afterDeleteButtons = { this.addButtonsAfterDeleteButon.bind(this)}
                        addBtnHide={true} 
                        hideSave={true} 
                        hideDelete={true}  
                        forceRender = { this.forceRender } 
                        data={PoLinesite} 
                        header={header}
                        onRowEditingStopped = {this.onRowEditingStopped.bind(this)}
                        settings = {
                            {
                                singleClickEdit:false
                            }
                        }
                        singleRowSelect = {true}
                        onGridReady={(grid) => { this.grid = grid}}
                        onSave={this.onGridSaveHandler.bind(this)}
                        onSelectionChanged = { this.onSelectionChanged.bind(this)} 
                    />
                </Collapse>
            </Popup>
        );
    }
}

export default RsDataLoader(AmendmentSitePopup,{entity:true,sites:true,warehouse:true,lineSiteStatus:true});