import React, { Fragment, useCallback, useState, } from 'react' 
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew' 
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import VendorContactTypeModal from './VendorContactTypeModal'


let gridApi = null;
const VendorContactTypes = (props) => { 
  const [contactTypeData,setContactTypeData] = useState({})
  const [showCreateModal, setShowCreateModal] = useState(false);
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'description') {
      setContactTypeData(event?.data);
      setShowCreateModal(true);
    }
  }
  
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delVendorContactType_ID = element?.data?.contacttype_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/vendorcontacttype/${delVendorContactType_ID}`)
      const rowNode = gridApi?.getRowNode(delVendorContactType_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const reloadGrid = () => {
    gridApi?.refreshServerSide({ purge: true });
    gridApi?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setContactTypeData({})
  };

  const Columns_Headings = [
    { field:'description', headerName: Helper.getLabel(props.language,'description',"Description"), editable: false, cellRenderer: gridLinkValueFormat },
    { field:'minimum_count', headerName: Helper.getLabel(props.language,'minimum_count',"Minimum count"), editable: false },
    { field:'is_mandatory', headerName: Helper.getLabel(props.language,'is_mandatory',"Is mandatory"),filter: 'agSetColumnFilter', filterParams:{ values: [true, false], keyCreator: (params) => params.value,  valueFormatter: (params) => params.value ? 'true' : 'false'}, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellRendererParams: { disabled: true },cellEditor: 'agCheckboxCellEditor', },
    { field:'is_active', headerName: Helper.getLabel(props.language,'is_active',"Is active"),filter: 'agSetColumnFilter', filterParams:{ values: [true, false], keyCreator: (params) => params.value,  valueFormatter: (params) => params.value ? 'true' : 'false'}, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellRendererParams: { disabled: true },cellEditor: 'agCheckboxCellEditor', },
  ];

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData?.vendor_contact_types.vendors_contact_type_main)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />}

  return ( 
    <div className='vendors_reason_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(props.language,'vendors_contact_type',"Vendors Contact Types")}>
          <ButtonNew onClick={onClickNewButton} title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData?.vendor_contact_types.vendors_contact_type_main_create_button)} /> 
        </NavigationHeder>  

        <AgGridNew
          apiUrl={Settings.apiVendorUrl+'/vendorcontacttype'} 
          hideDeleteBtn={!security.canDelete(frontendIds.masterData?.vendor_contact_types.vendors_contact_type_main_delete_button)}
          uniqueField={'contacttype_id'}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api}
          gridId={"rs_vendors_contact_type_list"}
        />  
      </div>
      {showCreateModal && <VendorContactTypeModal reloadParentGrid={reloadGrid} contactTypeData={contactTypeData} setContactTypeData={setContactTypeData} setShowCreateModal={setShowCreateModal} />}
    </div> 
  )
};
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_contact_types.vendors_contact_type_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(VendorContactTypes, SecurityOptions))))