import React, { Fragment, useCallback, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../inc/Api';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'; 
import Alert from '../../../inc/Alert';
import ButtonNew from '../../../inc/ButtonNew'; 
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import ScoringTemplateModal from './ScoringTemplateModal';

let gridApi = null;
const ScoringTemplate = (props) => {
  const [showPopupModal, setShowPopupModal] = useState(false);

  const navigate = useNavigate();
  
  const CellHandler = (event) => {   
    if(event.colDef.field == 'name') { 
      navigate(`/edit-scoring-template/${event.data.scoringmaster_id}`)
    }
  }
  
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delScoring_ID = element?.data?.scoringmaster_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl+`/scoringMaster/${delScoring_ID}`)
      const rowNode = gridApi?.getRowNode(delScoring_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])
  
  const Columns_Headings = [
    {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), cellRenderer: (params) => gridLinkValueFormat(params), editable: false},
    {field:'technical_score_weight', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'technical_score_weight',"Technical score weight"), editable: false},
    {field:'auto_disqualification_technical_score_prct', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'auto_disqualification_technical',"Auto disqualification technical"), editable: false},
    {field:'financial_score_weight', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'financial_score_weight',"Financial score weight"), editable: false},
    {field:'auto_disqualification_financial_score_prct', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(props.language,'auto_disqualification_financial',"Auto disqualification financial"), editable: false}, 
    {field:'show_evaluation_criteria_to_bidder', headerName:Helper.getLabel(props.language,'show_evaluation_criteria_to_bidder',"Show evaluation criteria to bidder"), cellRenderer: 'agCheckboxCellRenderer',
    cellEditor: 'agCheckboxCellEditor',
    filter: 'agSetColumnFilter', filterParams: { values: [true, false] },
    valueGetter:(params)=>{
       if(params?.data === null || params?.data === undefined || objectIsEmpty(params?.data)){
        return false
      }
      else{
        return params.data?.show_evaluation_criteria_to_bidder
      }
    }, editable: false}, 
  ]

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.scoring_template.scoring_template)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className='scoring_template_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(props.language,'scoring_templates',"Scoring Templates")}>
          <ButtonNew onClick={() => setShowPopupModal(true)} title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.scoring_template.scoring_template_main_create_button)} /> 
        </NavigationHeder> 
 
        <AgGridNew
          apiUrl={Settings.apiPurchaseRequisitionUrl+'/scoringMaster'} 
          hideDeleteBtn={!security.canDelete(frontendIds.procure.request_for_quotation.control_forms.scoring_template.scoring_template_main_delete_button)}
          uniqueField={'scoringmaster_id'}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api}
          gridId={"rs_scoring_templates_list"}
        /> 
      </div>
      {showPopupModal && <ScoringTemplateModal setShowCreateModal={setShowPopupModal} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.request_for_quotation.control_forms.scoring_template.scoring_template
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ScoringTemplate, SecurityOptions))))