import React, { useEffect, useState } from 'react';
import RFQBiddingRequirement from '../../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQBiddingRequirement';
import Helper from '../../../../inc/Helper';
import SimpleLoading from '../../../Loading/SimpleLoading';

let setTimeOut = null

const RequirementTabs = ({isEditable,activePOLine,updateData,grid,updatedData}) => {
const [data,setData]=useState({})
const [loading,setLoading]=useState(false)

    useEffect(() => {
        if (activePOLine?.data?.line_id) {
            setLoading(true)
            setTimeout(()=>{
                setLoading(false)
            },300)
            setData(activePOLine.data);
        }
    }, [activePOLine?.data?.line_id]);

const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if oldRowData is empty
    let oldRowData = data;
    if (Object.keys(oldRowData).length <= 0) {
        Helper.alert("Please select Purchase Order Lines", 'error');
        return;
    }

    setData((prevState) => ({
        ...prevState,
        [name]: value
    }));


    clearTimeout(setTimeOut);
    setTimeOut = setTimeout(() => {
        let isChanged = activePOLine.setDataValue(name, value, 'RowValueChangedEvent');
        if (isChanged) {
            updateData({
                ...updatedData,
                [name]: value
            }, oldRowData?.line_id, false);
            grid.componentObj.setGridValueChanged(activePOLine?.id, {
                ...data,
                [name]: value
            });
        }
    }, 200);
};

if(loading){
    return <SimpleLoading />
}

  return (
    <div className='rfq-internal-requirements bidding_requirement'>
    <RFQBiddingRequirement 
       type="rfq" 
       scopeOfWork={data?.scope_of_work}
       materialsAndServicesNotIncluded={
         data?.material_service_not_included
       }
       agreementTerms={data?.requirement_description}
       disable={!isEditable}
       onRequirementChange={handleInputChange}
       onRequirementBlur={handleInputChange}
    />
    </div>
  );
}

export default RequirementTabs;

