import React, { Fragment, useState } from 'react';
import SimpleLoading from '../../../Loading/SimpleLoading';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ButtonNew from '../../../inc/ButtonNew';
import Gui_id_list from '../../../../inc/Gui_id_list';
import { connect } from 'react-redux';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Helper from '../../../../inc/Helper';
import Alert from '../../../inc/Alert';
import AgGrid from '../../../grid/ag/ag-grid';
import Tabs from '../../../Widgets/Tabs/Tabs';
import FieldsRenderer from '../../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Collapse from '../../../inc/Collapse';

const Awarding = (props) => {
  const [awardingPolicy, setAwardingPolicy] = useState({})

  const [awardingNames, setAwardingNames] = useState([])
  const [loading, setLoading] = useState(false)

  // modules,
  const AwardingPolicyProcess = () => {
    const Columns_Headings = [
      {field:'name', headerName:Helper.getLabel(props.language,'names',"Names"), editable: false},
    ]

    const defaultNavs = [
      {title:Helper.getLabel(props.language,'header','Header'), isActive: true, },
      {title:Helper.getLabel(props.language,'line','Line')}
    ]

    return (
      <div className='row'>
        <div className='col-3'>
          <div className='awarding_grid'>
            <AgGrid dissableCheckBox={true} hideHeaderRightContents={true} hideFooter={true} data={awardingNames} id="rs_awarding_names_grid" allBtnHide={true} header={Columns_Headings} />
          </div>
        </div>
        <div className='col-9'>
          <Tabs navs={defaultNavs} tabContantsId='bidding-count-tabs' showAll={true} scrollOffset="300">
            <div className='mb-3'>{AwardingHeader()}</div>
            <div>{AwardingLine()}</div>
          </Tabs>
        </div>
      </div>
    )
  }

  const AwardingHeader = () => {
    const textBoxInputSettingsHeader = [
      {label:Helper.getLabel(props.language,'name',"Name"), inputType: "text", name:'name', id:'name'},
      {label:Helper.getLabel(props.language,'type',"Type"), inputType: "dropdown", options: [], name:'type', id:'type'},
      {label:Helper.getLabel(props.language,'active',"Active"), inputType: "checkbox", name:'active', id:'active'}
    ]
    return ( 
      <Collapse open={true} title={Helper.getLabel(props.language,'header',"Header")}>
        <FieldsRenderer updateState={setAwardingPolicy} currentState={awardingPolicy} fieldsArray={textBoxInputSettingsHeader} />
      </Collapse>
    )
  }

  const AwardingLine = () => {
    const textBoxInputSettingsLine = [
      {label:Helper.getLabel(props.language,'sequence',"Sequence"), options: [], inputType: "dropdown", id: 'sequence', name:'sequence'},
      {label:Helper.getLabel(props.language,'stage_name',"Stage Name"), options: [], inputType: "dropdown", name:'stage_name', id: 'stage_name'}, 
      {label:Helper.getLabel(props.language,'from_amount_threshold',"From amount threshold"), inputType: "text", name:'from_amount_threshold', id: 'from_amount_threshold'},
      {label:Helper.getLabel(props.language,'to_amount_threshold',"To amount threshold"), inputType: "text", name:'to_amount_threshold', id: 'to_amount_threshold'},
      {label:Helper.getLabel(props.language,'to',"To"), options: [], inputType: "dropdown", name:'to', id: 'to'}, 
      {label:Helper.getLabel(props.language,'create_date_time',"Created Date & Time"), inputType: "datepicker", name:'create_date_time', id: 'create_date_time'},
      {label:Helper.getLabel(props.language,'by',"By"), options: [], inputType: "dropdown", name:'by', id: 'by'}, 
      {label:Helper.getLabel(props.language,'team_name',"Team Name"), inputType: "text", name:'team_name', id: 'team_name'},
      {label:Helper.getLabel(props.language,'comments',"Comments"), inputType: "textarea", name:'comments', id: 'comments'}
    ]
    return ( 
      <Collapse open={true} title={Helper.getLabel(props.language,'line',"Line")}>
        <div className='line_manager_cn mb-2 d-flex justify-content-between w-100'><div className='line_manager_chld'><h3>Line manager approval</h3></div> <div className='line_manager_chld'><button><img src='/images/icons/delete.svg' className='img-fluid' /></button></div></div>
          <FieldsRenderer updateState={setAwardingPolicy} currentState={awardingPolicy} fieldsArray={textBoxInputSettingsLine} />
        <div className='new_line_creation mt-2'><div className='another_line'><button><img className='img-fluid' src='/images/icons/plus.svg' /> Add another line</button></div></div>
      </Collapse>
    )
  }

  const SaveHandler = () => { }

  // Screen Accessibility -
   
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.awarding_process_policy.awarding_process_policy_create_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  // ------

  return (
    <div className='rfq_awarding_process_policy'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/request' title={Helper.getLabel(props.language,'rfq_awarding_policy_process', "RFQ Awarding Policy and Process")} >
          <ButtonNew isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.awarding_process_policy.awarding_process_policy_create_screen_save_button)} isActive= {true} onClick={SaveHandler} />
        </NavigationHeder>
      </div>

      {loading ? <SimpleLoading /> : AwardingPolicyProcess()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.request_for_quotation.control_forms.awarding_process_policy.awarding_process_policy_create_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(Awarding, SecurityOptions))))