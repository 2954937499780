import React, { Component } from 'react';
import Helper from '../../../inc/Helper';
import DropDownInput from '../../Forms/Dropdown';
import Button from '../../inc/Button';
import Popup from '../../Popup/Popup';

class SegmentsRange extends Component {
    render() {
        let language = this.props.language;
        let fromOptions = [{label:'0001', value:'0001'},{label:'0002', value:'0002'},{label:'0003', value:'0003'},{label:'0004', value:'0004'}];
        let toOptions = fromOptions;
        return (
            <Popup className="seg_popup" autoOpen={true} width="750px">
                <h2 className='seg_p_title'>Add segment value child range</h2>
                <form id="segment_range_form" style={{minHeight:'200px'}}>
                    <table className='table seg_p_table'>
                        <thead>
                            <tr>
                                <th style={{width:'180px'}}>{Helper.getLabel(language,'from','From')}</th>
                                <th style={{width:'180px'}}>{Helper.getLabel(language,'to','to')}</th>
                                <th>{Helper.getLabel(language,'include','Include')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='active'>
                                <td><DropDownInput name="from[]" options={fromOptions} /></td>
                                <td><DropDownInput name="to[]" options={toOptions} /></td>
                                <td><span className='label_only'>Child values only</span></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div className='seg_p_footer'>
                    <Button className="black-btn-style mr-2" title="Close" />
                    <Button className="blue btn_md" title="Add" />
                </div>
            </Popup>
        );
    }
}

export default SegmentsRange;