 import { useEffect, useState } from "react";
import Api from "../../../../inc/Api";
 
export const CustomAPICallsRenderer = (params, apiUrl='', defaultValue='') => {
    const [value, setValue] = useState(0);
 
    useEffect(() => { fetchDataFromApi() }, []);
 
    async function fetchDataFromApi() {
        if (params?.data?.taxcodes_id && apiUrl) {
            try {
                const res = await Api.axios().get(apiUrl);
 
                let totalTaxValue = 0;
                res?.data?.data?.codes_values?.forEach(item => {
                    let valuePercentage = parseFloat(item?.value_percentage ?? 0);
                    let exemptPercentage = parseFloat(item?.percentage_exempt_from_tax ?? 0);
                    totalTaxValue += valuePercentage - exemptPercentage;
                });
 
                setValue(totalTaxValue);
            } catch (error) {
                setValue(0);
            }
        }
    }
 
    return !value ? defaultValue : value;
};