import React, { Component } from 'react';
import Popup from '../../Popup/Popup';
import $ from 'jquery';
import Helper from '../../../inc/Helper';
import Button from '../../inc/Button';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import Input from '../../Forms/Input';
import AgGridMemo from '../../grid/ag/AgGridMemo';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
class SegmentDistributionPopup extends Component {
    constructor(props){
        super(props);
        this.grid = null;
        this.forceRenderValuesGrid = false;
        this.fRSegmentCmGrid = false;
        this.distributionGrid = null;
        this.isSegmentsLoaded = false;
        this.totalAmount = this.props.totalAmount;
        this.onInputDateChangeHandler = this.onInputDateChangeHandler.bind(this);
        this.source_id = this.props.source_id;
        this.distrbutionintegrator_id =  this.props.integrator;
        this.distribution_source_id = this.props.distribution_source_id;
        this.scSetTimeOut = null;
        this.isEdit=false;
        this.state = {
            isLoading:false,
            isLoadingDetails:false,
            selectedNode:null,
            allSegments:[],
            allSegmentsValues:[],
            data:[],
            allCombinationDetails:[],
            dataDistribution:[],
            segmentCombination:this.getBlankSegmentCombination()
        }
    }
    componentDidMount(){
        this.loadSegments();
        this.loadSegmentValues();
        clearTimeout(this.scSetTimeOut)
        this.scSetTimeOut = setTimeout(() => {
            this.loadSegmentCombinations();
        }, 100);
    }
    getTodayDate(){
        let dateObject = new Date(); 
        return `${dateObject.getFullYear() }/${ ( dateObject.getMonth() + 1 ) }/${dateObject.getDate()}`;
    }
    loadSegmentCombinations(){
        this.loadCombinationDetails();
        /*
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments+'/segmentcombinationvalue').then( res => {
            let responseData = res.data.data.reverse();
            let data = responseData.map( item=>{
                return {
                    ...item,
                    name: item.segmentcombinationvalue
                }
            })
            that.fRSegmentCmGrid = !that.fRSegmentCmGrid;
            that.setState({
                isLoading:false,
                data:data
            })
            that.loadCombinationDetails();
        })
        */
    }
    loadSegments(){
        if(this.isSegmentsLoaded){
            return;
        }
        this.isSegmentsLoaded = true;
        let api = Api;
        let that = this;
        that.setState({
            isLoading:true,
            gridData:[]
        })
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/get_segments').then(res => {
            let data = res.data.data.reverse();
            that.fRSegmentCmGrid = !that.fRSegmentCmGrid;
            that.setState({
                isLoading:false,
                allSegments:data
            })
        }).catch( error => {
            that.setState({
                isLoading:false
            })
        })
    }
    loadSegmentValues(){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoading:true,
        })
        api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
            let responseData = res.data.data.reverse();
            that.fRSegmentCmGrid = !that.fRSegmentCmGrid;
            that.setState({
                allSegmentsValues:responseData,
                isLoading:false,
            })
        }).catch(error =>{
            that.setState({
                isLoading:false,
            })
        })
    }
    loadCombinationDetails(){
        if(!this.distribution_source_id){
            return;
        }
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments+'/distribution_detail_by_source/'+this.distribution_source_id).then( res => {
            that.fRSegmentCmGrid = !that.fRSegmentCmGrid;
            that.setState({
                isLoading:false,
                allCombinationDetails:res.data.data.reverse()
            })
        })
    }
    getSegmentValues(setmentId){
        return this.state.allSegmentsValues.filter(item => {
            return item.segment_id == setmentId ? item.segmentvalue_id : null
        })
    }
    getValueIdForGridRow(combinationNameArray,segmentId){
        let output = '';
        let valuseArray = this.getSegmentValues(segmentId);
        if(Array.isArray(combinationNameArray)){
            combinationNameArray.forEach(id => {
                valuseArray.forEach(segmentValue => {
                    if(segmentValue.segmentvalue_id == id){
                        output = id;
                        return output;
                    }
                });
            })
        }
        return output;
    }
    loadSegmentDistributionDetails(combinationId){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoadingDetails:true,
        })
        let segmentCombination = that.state.segmentCombination;
        let segmentCombinationNameArray= segmentCombination.name.split('|')
        let dataDistribution = [];
        
        this.state.allSegments.forEach(item => {
            let tempRow = {
                segments:item.segment_id,
                value: this.getValueIdForGridRow(segmentCombinationNameArray,item.segment_id),
                description:""
            };
            dataDistribution.push(tempRow)
        })
        api.axios().get(Settings.apiUrls.segments +'/distribution_detail_by_combination/'+combinationId).then(res => {
            
            let data = res.data.data;
            if(data.length >= 1){
                /*
                segmentCombination = {
                    ...segmentCombination,
                    ...data[0],
                    distribution_detail_id:data[0].distributiondetail_id,
                    distribution_source_id:data[0].distributionsource_id,
                }
                */
            }
            
            that.setState({
                dataDistribution:dataDistribution,
                segmentCombination:segmentCombination,
                isLoadingDetails:false
            }) 
        }).catch( error => {
            that.setState({
                dataDistribution:dataDistribution,
                segmentCombination:{},
                isLoadingDetails:false
               }) 
        })
    }
    onPopupCloseHandler(e){
        if(this.props.onPopupClose && typeof this.props.onPopupClose ==='function'){
            this.props.onPopupClose();
            if(this.props.onSegmentPopupClose && typeof this.props.onSegmentPopupClose ==='function' && this.isEdit){
                this.props.onSegmentPopupClose(this.state.allCombinationDetails)
            }
        }
        $('html').removeClass('rs_popup_open');
    }
    onGridReady(grid){
        this.grid = grid;
    }
    onDistributionGridReady(grid){
        this.distributionGrid = grid;
    }
    onDistributionChange(event){
        let data = Helper.getDataFromGrid(event);
        let combinationName = "";
        
        data.forEach( item => {
            if( Helper.getNullableValue(item.value) ){
                if(Helper.getNullableValue(combinationName) ){
                    combinationName += '|'+item.value;
                }else{
                    combinationName = item.value;
                }
            }
        })
        let segmentCombination = this.state.segmentCombination; 
        this.setState({
            segmentCombination:{
                ...segmentCombination,
                name:combinationName
            }
        })
    }
    
    segmentValueSetter(params){
        let gridData = Helper.getDataFromGrid(params);
        let isExitInGrid = false;
        gridData.forEach(data =>{
            if(params.newValue == data.segment){
                isExitInGrid = true;
            }
        })
        if(isExitInGrid){
            Helper.alert("Segment already in use",'error');
            return false;
        }
        params.data.segment = params.newValue;
        return true;
    }
    getBlankSegmentCombination(){
        return {name:'',percentage:'',amount:'',accounting_date: this?.props?.accountingDate ? this.props.accountingDate : '',distribution_source_id:null,distribution_detail_id:null};
    }
    onSelectionChanged(event){
        if(event.api.getSelectedNodes().length <=0 || event.api.getSelectedNodes().length >=2){
            this.resetSegmentCombinationForm()
            this.setState({
                segmentCombination:this.getBlankSegmentCombination(),
                dataDistribution:[],
                selectedNode:null
            })
            return;
        }
        let selectedRow = event.api.getSelectedNodes()[0];
        if(this.state.selectedNode && this.state.selectedNode.rowIndex === selectedRow.rowIndex){
            return;
        }
        let that = this;

        this.setState({
            segmentCombination:{
                ...selectedRow.data
            },
            selectedNode:selectedRow
        }, function(){
            that.loadSegmentDistributionDetails(that.state.segmentCombination.segmentcombination_id)
        })
    }
    /*
    componentDidUpdate(){
        let selectedNode = this.state.selectedNode;
        if(selectedNode){
            this.grid.api.forEachNode((node) =>{
                    if(node.rowIndex === selectedNode.rowIndex){
                        node.setSelected(true)
                    }
                }
            );
        }
        
    }
    */
    onSaveClickHandler(){
        let that = this;
        let segmentCombination = this.state.segmentCombination;
        //let amount = parseFloat(segmentCombination.amount);
        //amount = amount ? amount : 0;
        let percentage = parseFloat(segmentCombination.percentage);
        percentage = percentage ?  percentage : 0;
        let segmentComData = segmentCombination.name.replaceAll('-','|');
        if(segmentCombination.segmentcombination_id && segmentCombination.distributiondetail_id){
            this.updateSegmentDistributionDetails(segmentCombination.distributiondetail_id,{
                amount:that.totalAmount,
                percentage:percentage,
                accounting_date: DatepickerFunctions.convertDateForDataBase(segmentCombination.accounting_date)
            })
        }else if(!segmentCombination.segmentcombination_id){
            this.createSegmentCombination(segmentComData,function(res){
                if(that.distribution_source_id){
                    that.createSegmentDistributionDetailsWithSourceId({
                        distribution_source_id: that.distribution_source_id,
                        segment_combination_id: res.data.segmentcombination_id,
                        accounting_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(segmentCombination.accounting_date)),
                        percentage: percentage,
                        //amount: amount
                        amount:that.totalAmount,
                    })
                }else{
                    that.createSegmentDistributionDetails([{
                        segment_combination_id: res.data.segmentcombination_id,
                        accounting_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(segmentCombination.accounting_date)),
                        percentage: percentage,
                        //amount: amount
                    }])
                }
            })

        }else{
            console.log("Can't take action",segmentCombination)
        }
        
    }
    createSegmentDistributionDetails(details){
        let that = this;
        let api = Api;
        api.setUserToken();
        let newData = {
            source_id: this.source_id,
            distributionintegrator_id: this.distrbutionintegrator_id,
            financial: this.props.disableFinancial ? false : true,
            amount:this.totalAmount,
            distribution_details:details
        }
        api.axios().post(Settings.apiUrls.segments + '/segmentdistribution',newData).then(res=>{
            that.distribution_source_id = res.data.distributionsource_id;
            Helper.alert(res.data.message)
            that.loadSegmentCombinations();
            //that.forceRenderValuesGrid = !that.forceRenderValuesGrid;
            that.resetSegmentCombinationForm()
            if(that.props.onCreateFirstDistribution){
                that.props.onCreateFirstDistribution(res.data.distributionsource_id)
                that.isEdit = true
            }
        }).catch( error => {
            getPopupMessageBasedOnStatus(error)
            let segmentCombination = that.state.segmentCombination;
            
            if(segmentCombination.segmentcombination_id){
                that.setState({
                    segmentCombination:{
                        ...segmentCombination,
                        segmentcombination_id:null
                    }
                })
                that.deleteCombinationById(segmentCombination.segmentcombination_id)
            }
        })
    }
    createSegmentDistributionDetailsWithSourceId(details){
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiUrls.segments + '/segment_distribution_detail',details).then(res=>{
            Helper.alert(res.data.message)
            that.loadSegmentCombinations();
            that.isEdit=true
            //that.forceRenderValuesGrid = !that.forceRenderValuesGrid;
            that.resetSegmentCombinationForm()
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
            let segmentCombination = that.state.segmentCombination;
            if(segmentCombination.segmentcombination_id){
                that.setState({
                    segmentCombination:{
                        ...segmentCombination,
                        segmentcombination_id:null
                    }
                })
                that.deleteCombinationById(segmentCombination.segmentcombination_id)
            }
        })
    }

    createSegmentCombination(data,afterCreateCombination =null){
        let segmentCombination = this.state.segmentCombination;
        if(segmentCombination.segmentcombination_id){
            return;
        }
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiUrls.segments + '/segmentcombinationvalue',{segmentcombination_value:data}).then(res=>{
            that.setState({
                segmentCombination:{
                    ...segmentCombination,
                    segmentcombination_id:res.data.segmentcombination_id
                }
            })
            that.isEdit = true
            if(afterCreateCombination){
                afterCreateCombination(res)
            }
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })
    }
    updateSegmentDistributionDetails(detailsId, details){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().put(Settings.apiUrls.segments+'/segment_distribution_detail/'+detailsId,details).then(res=>{
            Helper.alert(res.data.message)
            that.isEdit = true
            that.loadSegmentCombinations()
            that.resetSegmentCombinationForm()
        })
    }
    onCloseBtnClick(e){
        $('.rs_popup_close').trigger('click');
    }
    onInputChnageHandler(e){
        let segmentCombination = this.state.segmentCombination;
        let name = e.target.name;
        let percentage = 0;
        let amount = 0;
        let targetValue = e.target.value;
        let amountDistributed = 0, percentageDistributed = 0;
        this.state.allCombinationDetails.forEach( distibutedComb => {
            amountDistributed += Helper.getOnlyNumber(distibutedComb.amount);
            percentageDistributed += Helper.getOnlyNumber(distibutedComb.percentage);
        })
        if(this.state.selectedNode){
            percentageDistributed = percentageDistributed - Helper.getOnlyNumber(this.state.selectedNode.data.percentage);
            amountDistributed = amountDistributed - Helper.getOnlyNumber(this.state.selectedNode.data.amount);
            if(percentageDistributed < 0){
                percentageDistributed = 0
            }
            if(amountDistributed < 0){
                amountDistributed = 0
            }
        }
        if( parseFloat(targetValue) <=0 ){
            percentage =  0;
            amount = 0;
        }else{
            if(name =='amount'){
                if(Helper.getOnlyNumber(targetValue)> ( this.totalAmount - amountDistributed) ){
                    targetValue = ( this.totalAmount - amountDistributed);
                }
                percentage =  ( targetValue * 100 ) / this.totalAmount;
                amount = targetValue;

            }else if(name =='percentage'){
                if(Helper.getOnlyNumber(targetValue)> ( 100 - percentageDistributed)){
                    targetValue = ( 100 - percentageDistributed);
                }
                amount = ( targetValue / 100 ) * this.totalAmount;
                percentage = targetValue;
            }
        }
        
        if(amount < 0 || percentage < 0){
            percentage = 0;
            amount = 0;
        }
        this.setState({
            segmentCombination:{
                ...segmentCombination,
                percentage: percentage,
                amount: amount
            }
        })
        
    }
    onInputDateChangeHandler(value) {
        let segmentCombination = this.state.segmentCombination;
        this.setState({
            segmentCombination:{
                ...segmentCombination,
                accounting_date: value
            }
        })
    }
    getValueCodesString(valueIds){
        if(!Helper.getNullableValue(valueIds)){
            return "";
        }
        let valueIdAr = valueIds.split("|");
        let allSegmentsValues = this.state.allSegmentsValues;
        let output = '';
        valueIdAr.forEach( valueId => {
            allSegmentsValues?.forEach(value =>{
                if(value.segmentvalue_id == valueId){
                    if(output===''){
                        output = value.code;
                    }else{
                        output += '-'+value.code;
                    }
                }
                
            })
        })
        return output;
    }
    getCombinationNameForVisitor(params){
        return this.getValueCodesString(params.data.name)
    }
    
    performSaveAction(e){
        let dataDistribution = [];
        let selectedNodes = this.grid.api.getSelectedNodes();
        let noOfApiReq  = selectedNodes.length;
        this.state.allSegments.forEach(item => {
            dataDistribution.push({
                segments:item.segment_id,
                value:"",
                description:""
            })
        })
        this.fRSegmentCmGrid = !this.fRSegmentCmGrid;
        this.forceRenderValuesGrid = !this.forceRenderValuesGrid;
        this.setState({
            dataDistribution:dataDistribution,
            segmentCombination:{name:'',percentage:'',amount:'',accounting_date:this.props.accountingDate?this.props.accountingDate: ''},
            selectedNode:null
        })
        let that = this;
        if(noOfApiReq >=1){
            setTimeout(function(){
                that.fRSegmentCmGrid = !that.fRSegmentCmGrid;
                that.forceRenderValuesGrid = !that.forceRenderValuesGrid;
                that.setState({
                    dataDistribution:dataDistribution,
                    segmentCombination:{name:'',percentage:'',amount:'',accounting_date: ''},
                    selectedNode:null
                })
            },150)
        }
    }
    
    onSegmentCombinationAdd(e){
        if(this.canPerformSave()){
            Helper.confirmMe(this.performSaveAction.bind(this),Helper.getLabel(null,'msg_confirm_add_segment',"Would you like to leave?"),null,Helper.getLabel(null,'you_have_not_saved',"You have not saved"))
            return;
        }
        this.performSaveAction(e)
        
    }
    resetSegmentCombinationForm(){
        this.forceRenderValuesGrid = !this.forceRenderValuesGrid;
        this.setState({
            dataDistribution:[],
            segmentCombination:{name:'',percentage:'',amount:'', accounting_date: ''},
            selectedNode:null
        })
    }
    onSegmentCombinationDelHandle(e){
        if(!this.grid){
            return;
        }
        //this.grid.componentObj.delteBtnHandler(e)
        let that = this;
        this.resetSegmentCombinationForm();
        let api = Api;
        api.setUserToken();
        let selectedNodes = this.grid.api.getSelectedNodes();
        let noOfApiReq  = selectedNodes.length;
        if(noOfApiReq <=0){
            return;
        }
        selectedNodes.forEach(nodeItem => {
            api.axios().delete(Settings.apiUrls.segments + '/segment_distribution_detail/'+nodeItem.data.distributiondetail_id).then(res=>{
                that.fRSegmentCmGrid = !that.fRSegmentCmGrid;
                --noOfApiReq;
                if(noOfApiReq<=0){
                    Helper.alert(res.data.message)
                    that.loadSegmentCombinations();
                }
            })
        }).catch(errors => {
            --noOfApiReq;
            if(noOfApiReq<=0){
                that.loadSegmentCombinations();
            }
        })
        
        /*
        if(!segmentCombination.segmentcombination_id){
            return '';
        }
        if(segmentCombination.distribution_detail_id){
            this.deleteSegCompDtlById(segmentCombination.distribution_detail_id,function(){
                that.deleteCombinationById(segmentCombination.segmentcombination_id,function(){
                    Helper.alert("Success");
                })
            })
        }else{
            this.deleteCombinationById(segmentCombination.segmentcombination_id,function(){
                Helper.alert("Success");
            })
        }
        this.setState({
            dataDistribution:[],
            segmentCombination:this.getBlankSegmentCombination(),
            selectedNode:null
        })
        */
        
    }
    deleteCombinationById(id,afterCallBack = null){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().delete(Settings.apiUrls.segments + '/segmentcombination/'+id).then(res=>{
            if(afterCallBack){
                afterCallBack(res);
            }
            that.loadSegmentCombinations();
        })
    }
    deleteSegCompDtlById(id,afterCallBack = null){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().delete(Settings.apiUrls.segments + '/segment_distribution_detail/'+id).then(res=>{
            if(afterCallBack){
                afterCallBack(res);
            }
            that.loadSegmentCombinations();
        })
    }
    getSegmentsDropdownOptions(){
        let options = [];
        let allSegments = this.state.allSegments;
        allSegments.forEach( segment => {
            options.push({
                label: segment.name,
                value:segment.segment_id
            })
        })
        
        return options;
    }
    valueOptionsFilter(options,params){
        let output = options.filter( item => {
            return item.segment_id == params.data.segments ? item : null
        })
        return output;
    }
    getSegmentValueFromValueId(valueId){
        let output = {};
        this.state.allSegmentsValues.map(item => {
            if(item.segmentvalue_id ==valueId ){
                output = item;
            }
        })
        return output;
    }
    getSegmentValueDropdownList(arg){
        let options = this.state.allSegmentsValues.map((item)=> {
            return {
                label: item.code+ ' | '+item.name,
                segment_id:item.segment_id,
                value: item.segmentvalue_id
            }
        })
        return options;
    }
    formateSegmentValueDropdownValue(prarams){
        let segmentValue = this.getSegmentValueFromValueId(prarams.value);
        if(segmentValue.code)
            return segmentValue.code;
        return prarams.value;
    }
    getDesctionFromRow(params){
        let description = '';
        let allSegmentsValues = this.state.allSegmentsValues;
        allSegmentsValues?.forEach( segmentValue => {
            if(segmentValue.segmentvalue_id == params.data.value){
                description = segmentValue.name;
            }
        })
        return description;
    }
    formHederContents(){
        let segmentCombination = this.state.segmentCombination;
        return(
            <div className='d-flex segment_distributions_labels'>
                <div className='item' style={{width:'100%'}}><span>{Helper.getLabel(null,'segments_combination','Combination')} : </span>{ this.getValueCodesString(segmentCombination.name) }</div>
                <div className='item'><span>{Helper.getLabel(null,'accounting_date','Accounting Date')} : </span> <NewInputDatePicker value={segmentCombination.accounting_date} empty={true} onChange={this.onInputDateChangeHandler} id='accounting_date' name="accounting_date" /></div>
                <div className='item'><span>{Helper.getLabel(null,'percentage','Percentage')} : </span> <Input name="percentage" inputType="number" value={segmentCombination.percentage} onChange={ (e) => this.onInputChnageHandler(e) }/></div>
                {
                    this.props.disableFinancial ? '' : 
                    <div className='item'><span>{Helper.getLabel(null,'amount','Amount')}  : </span><Input name="amount"  inputType="number"  value={segmentCombination.amount} onChange={ (e) => this.onInputChnageHandler(e) }/></div>
                }
            </div>
        )
    }
    beforePopupClose(){
        let isValid = true;
        let percentageDistributed = 0;
        this.state.allCombinationDetails.forEach( distibutedComb => {
            percentageDistributed += Helper.getOnlyNumber(distibutedComb.percentage);
        })
        if(percentageDistributed>0 && percentageDistributed<100){
            isValid = false;
            Helper.alert(Helper.getLabel(null,12002,'Distribution should be 100%'),'error')
        }
        return isValid;
    }
    canPerformSave(){
        let segmentCombination = this.state.segmentCombination;
        if(segmentCombination.name == '' || segmentCombination.percentage == ''){
            return false;
        }
        return true;
    }
    canPerformRemove(){
        if(!this.grid){
            return false;
        }
        let selectedNodes = this.grid.api.getSelectedNodes();
        if(selectedNodes.length >=1){
            return true;
        }
        return false;
    }
    render() {
        let language = this.props.language;
        let segmentCombination = this.state.segmentCombination;
        let header = [
            {field:'formated_combination',headerName:Helper.getLabel(language,'combination','Combination'),filter:false},
            {field:'name',hide:true},
            {field:'segmentcombinationvalue',hide:true},
            {field:'segmentcombination_id',hide:true},
            {field:'percentage',headerName:Helper.getLabel(language,'percentage','Percentage'), valueFormatter: (params) => { return Helper.getOnlyNumber(params.value,true) },filter:false},
            {field:'amount',headerName:Helper.getLabel(language,'amount','Amount'), valueFormatter: (params) => { return Helper.formateCurrency(params.value,true)} , hide:this.props.disableFinancial ? true : false,filter:false,},
        ]
        let headerDistribution = [
            {field:'segments',headerName:Helper.getLabel(language,'segments','Segments'), editable:false, valueSetter : this.segmentValueSetter.bind(this) ,inputType:'dropdown',options:this.getSegmentsDropdownOptions() },
            {field:'value', defaultValue:'', headerName:Helper.getLabel(language,'value','Value'),inputType:'dropdown',valueFormatter: this.formateSegmentValueDropdownValue.bind(this),options:this.getSegmentValueDropdownList(),optionsFilter: this.valueOptionsFilter.bind(this), editable: segmentCombination.segmentcombination_id ? false: true},
            {field:'description',headerName:Helper.getLabel(language,'description','Description'),valueFormatter: this.getDesctionFromRow.bind(this) ,editable:false}
        ]
        let dataDistribution = this.state.dataDistribution;
        let compbinationGridData = this.state.allCombinationDetails.map( item => {
            return {
                ...item,
                formated_combination:this.getValueCodesString(item.segmentcombination_value),
                //this.getValueCodesString
                name: item.segmentcombination_value
            }
        })
        let compGridSettings = {
            singleClickEdit:true,
        } 
        return (
            <Popup beforePopupClose={ this.beforePopupClose.bind(this)} className="segment_distribution_popup" onClose={ this.onPopupCloseHandler.bind(this)} autoOpen={true} width="1400px">
                <div className='popup_header'>
                    <h2 className='seg_p_title'>{Helper.getLabel(language,'segment_distribution','Segment distribution')}</h2>
                    <div className='d-flex gap-3 align-items-center'>
                    {
                        this.props.disableFinancial ? '' : 
                        <div className='percentage_grand_total'><strong> {Helper.getLabel(null,'total_amount','Total amount')} :</strong>{Helper.formateCurrency(this.totalAmount,true)}</div>
                    }
                    <div className='d-flex justify-content-right' style={{gap:"10px",'justify-content':'right'}}>
                       <Button onClick ={ this.onCloseBtnClick.bind(this)} className="black-btn-style mr-2" title={Helper.getLabel(language,'close','Close')} />
                       <Button className="blue btn_md" isDisable={!this.canPerformSave()} title={Helper.getLabel(language,'save','Save')} onClick={ this.onSaveClickHandler.bind(this) } />
                    </div>
                    </div>
                </div>
                <div className='rs_button_box'>
                    {checkIfArrayIsEmpty(this.state.allSegments) && <div style={{color:"#2D74E0",marginBottom:"0.2rem"}}>Segments are still loading. You cannot add segment combinations until all segments are fully loaded.</div>}
                    <Button className="btn_md" onClick={ this.onSegmentCombinationAdd.bind(this)} title={Helper.getLabel(language,'add','Add')} icon="/images/icons/add-new.svg" isDisable={checkIfArrayIsEmpty(this.state.allSegments)} />
                    <Button className= {"btn_md "+ ( this.canPerformRemove() ? '':'disable')} onClick={ this.onSegmentCombinationDelHandle.bind(this)} title={Helper.getLabel(language,'delete','Delete')} icon="/images/icons/delete.svg" />
                </div>
                <div className='row'>
                    <div className='col-sm-4 segdpopup_grid_wraper'>
                        <AgGridMemo height="509px" forceRender={this.fRSegmentCmGrid} ppp={compbinationGridData.length}  viewOnly={true}  onSelectionChanged={this.onSelectionChanged.bind(this)}  id="segdpopup_grid" hideFooter={true} singleRowSelect={true}  allBtnHide={true} onGridReady={ this.onGridReady.bind(this) } data={compbinationGridData} header={header}/>
                    </div>
                    <div className='col-sm-8 rs_right_grid'>
                        <div className='rs_segm_com_frm'>
                            {this.formHederContents()}
                            {this.state.isLoadingDetails ? <SimpleLoading /> : <AgGridMemo settings={ compGridSettings } forceRender={this.forceRenderValuesGrid} dissableCheckBox={true} addBtnHide={true} hideDelete={true}  id="seg_distribution_grid"  deleteConfig={{apiUrl:Settings.apiUrls.segments + '/segment_distribution_detail',idName:'distributiondetail_id',that:this}} onGridReady={ this.onDistributionGridReady.bind(this)} onRowValueChanged={this.onDistributionChange.bind(this)} ppp={10000} hideSave={true}  data={dataDistribution} hideFooter={true} hideHeaderRightContents={true} header={headerDistribution}/> }
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default SegmentDistributionPopup;