import React, { Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings' 
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'  
import Alert from '../../inc/Alert' 
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGridNew from '../../grid/ag/ag-grid-new'

let gridApi = null;
const Brand = (props) => { 
  let AddBtn;
  let transaction;

  const updateBrand = async (event) =>{  
    try {
      let payload = {
        name: event?.data?.name
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiItemUrl+`/itembrand/${event?.data?.itembrand_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const addNewBrand = async (event) => { 
    try {
      let payload = {
        name: event?.data?.name
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiItemUrl+'/item_brand', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    let delBrand_ID = element?.data?.itembrand_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/itembrand/${delBrand_ID}`)
      const rowNode = gridApi?.getRowNode(delBrand_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }
 
  const onRowValueChanged = (event) => {
    if (event?.data?.itembrand_id) {
      updateBrand(event)
    } else {
      addNewBrand(event)
    }
  }

  const ITBrandValidator = (params) => {
    if( params.newValue.length > 255 ){
      Helper.alert('Name limit exceeded!', 'error') 
      return false
    }
    params.data.name = params.newValue
    return true
  }

  let Columns_Headings = [ 
    {field:'name', editable:true, valueSetter: ITBrandValidator, headerName: Helper.getLabel(props.language, 'name', 'Name') } 
  ]
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item_brand.item_brand_main)){
    return <Fragment> 
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(props.language, 'brand', 'Brand')}></NavigationHeder>
      <div className='rs_brand_grid'>
        <AgGridNew
          apiUrl={Settings.apiItemUrl+'/item_brand'}
          pagination={false}
          columnDefs={Columns_Headings}
          onRowValueChanged={onRowValueChanged}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.item_master_modules.item_brand.item_brand_main_delete_button)}
          hideAddBtn={!security.canCreate(frontendIds.masterData.item_master_modules.item_brand.item_brand_main_create_button)}
          handleDeleteSelectedRows={DeleteHandler}
          height={500}
          onGridReady={(params) => gridApi = params?.api}
          handleAddButton={handleBtn}
          uniqueField={`itembrand_id`}
          gridId={'brand-master-data'}
        />
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.item_master_modules.item_brand.item_brand_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Brand, SecurityOptions)))