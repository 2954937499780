import React, { Component } from 'react';
import Helper from '../../inc/Helper';

class LoginRegisterSidebar extends Component {
    render() {
        let lgBanner = '/images/login_register_banner.png';
        return (
            <div className='login_register_sidebar'>
                <div className='logreg_sidebar_contents'>
                    <h1 className='logreg_title'>{Helper.getLabel(null,'welcome_to_lynkaz','Welcome to Lynkaz')}</h1>
                    <div className='logreg_banner'>
                        <img src={lgBanner} alt="Welcome to Lynkaz" />
                    </div>
                    
                </div>
                <ul className='logreg_footer_menu'>
                    <li><a href='#'>&copy; Lynkaz</a></li>
                    <li><span className='circle'></span></li>
                    <li><a href='#'>Contact  Us</a></li>
                    <li><span className='circle'></span></li>
                    <li><a href='#'>Terms of use</a></li>
                    <li><span className='circle'></span></li>
                    <li><a href='#'>Privacy Policy</a></li>
                </ul>
            </div>
        );
    }
}

export default LoginRegisterSidebar;