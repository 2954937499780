import { Component } from "react";
import { Alert } from "react-bootstrap";

import DeleteGrey from "../../../assets/delete-grey.svg";

class CheckDeleteActionBar extends Component {
  render() {
    const {
      icon,
      title,
      itemCount,
      toggleSelectAll,
      selectAll,
      deleteAll,
      disable,
      viewOnly,
    } = this.props;

    return (
      <Alert className="pr-wishlist-actionbar">
        <div>
          {!viewOnly ? (
            <input
              className="pr-wishlist-checkbox"
              type="checkbox"
              onChange={toggleSelectAll}
              checked={selectAll}
            />
          ) : null}
          <div>
            <img src={icon} alt="wishlist" />
            <span className="pr-wishlist-actionbar-highlight">{title}</span> (
            {itemCount} {itemCount > 1 ? "items" : "item"})
          </div>
        </div>
        {!viewOnly ? (
          <img
            className={`pr-wishlist-actionbar-delete ${
              disable ? "pr-wishlist-actionbar-deleteDisable" : ""
            }`}
            src={DeleteGrey}
            alt="delete"
            width={20}
            onClick={disable ? null : () => deleteAll()}
          />
        ) : null}
      </Alert>
    );
  }
}

export default CheckDeleteActionBar;
