import React, { useEffect, useState } from 'react'
import Popup from '../../Popup/Popup'
import $ from 'jquery'
import AgGrid from '../../grid/ag/ag-grid'
import Helper from '../../../inc/Helper'
import Button from '../../inc/Button'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import { calculateDateDifference } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { getExtensionRequestsColumns } from './HeaderView/ColumnsHeader/ColumnsHeader'

let gridObj = null

const ExtensionRequests = ({ rfq_id, language, handleCloseModal, setRefreshAfterPublish, usertype }) => {
  const [actions, setActions] = useState([])

  const handleExtensionsActions = (type, params) => {
    const matchingAction = actions.find((action) => action.name.toLowerCase() === type)
    const data = {
      action: null,
      extensionrequest_id: params?.data?.extensionrequest_id,
    }
    if (matchingAction) {
      const { id } = matchingAction
      data.action = id
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(Settings.apiPurchaseRequisitionUrl + `/action_on_extensionrequest`, data)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          gridObj?.api?.refreshServerSide({ purge: true })
          handleCloseModal()
          if (type !== 'recalled') {
            gridObj?.api?.refreshServerSide({ purge: true })
            setRefreshAfterPublish(true)
            setTimeout(() => {
              setRefreshAfterPublish(false)
            }, 100)
          }
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const handleAccept = (params) => {
    handleExtensionsActions('accepted', params)
  }

  const handleDeclined = (params) => {
    handleExtensionsActions('declined', params)
  }

  const handleRecalled = (params) => {
    handleExtensionsActions('recalled', params)
  }

  const getExpiryDateDifferences = (params) => {
    const difference = calculateDateDifference(params.data?.createddatetime, params.data?.extended_datetime)
    return `${difference?.days} days ${difference?.hours} hours`
  }

  const getAllActions = () => {
    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/enum/RFvendorreply_extensionrequest_status`)
      .then((res) => {
        setActions(res?.data.data)
      })
      .catch((error) => {
        console.log('Error in getting actions: ', error)
      })
  }

  useEffect(() => {
    getAllActions()
  }, [])

  const handleGridReady = (params) => {
    gridObj = params
  }

  const Columns_Headings = getExtensionRequestsColumns({
    language: language,
    getExpiryDateDifferences: getExpiryDateDifferences,
    handleAccept: handleAccept,
    handleRecalled: handleRecalled,
    handleDeclined: handleDeclined,
    usertype: usertype,
    actions: actions,
  })

  return (
    <Popup
      autoOpen={true}
      width='1100px'
      onClose={() => {
        $('html').removeClass('rs_popup_open')
        handleCloseModal()
      }}
    >
      <>
        <div className='d-flex justify-content-between mb-4'>
        <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'extension_requests', 'Extension Requests')}</h5>
        <div className='addDocumentNumber-body-buttons' style={{ justifyContent: 'flex-end', gap: '16px' }}>
          <Button
            isActive={false}
            className='black-btn-style'
            title={Helper.getLabel(language, 'close', 'Close')}
            onClick={() => {
              $('html').removeClass('rs_popup_open')
              handleCloseModal()
            }}
          />
        </div>
        </div>
        <div className='mb-4'>
          <AgGridNew
            gridId='extension_history_grid'
            apiUrl={Settings.apiPurchaseRequisitionUrl + `/extension_requests/${rfq_id}`}
            columnDefs={Columns_Headings}
            height={500}
            hideAddBtn={true}
            hideDeleteBtn={true}
            onGridReady={handleGridReady}
          />
        </div>
      </>
    </Popup>
  )
}

export default ExtensionRequests
