import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import AgGrid from '../../grid/ag/ag-grid';
import Collapse from '../../inc/Collapse';

const SegmentsVersion = ({data, language}) => {
  return (
    <Collapse className='segments_po_version' open={true} title={Helper.getLabel(language,'segments',"Segments")}>
      <div className='container-fluid segments_po_version'>
        <AgGrid hideFooter={true} data={data} id="rs_segments_po_version" allBtnHide={true} header={[]} />
      </div>
    </Collapse>
  )
}

 
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
  
export default connect(mapStateToProps) (SegmentsVersion) 