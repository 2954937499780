import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import { Form } from 'react-bootstrap';
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';
import Helper from '../../../inc/Helper';
import $ from 'jquery';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import FormValidator from '../../../inc/FormValidator';
import { useNavigate } from 'react-router-dom';
import Button from '../../inc/Button';

let cancelTokenSource = axios.CancelToken.source();
const TaxAuthorityModal = ({setShowCreateModal, language}) => {
    const [vendors, setVendors] = useState([]);
    const vendorDataRef = useRef([]);

    const [formData, setFormData] = useState({}); 

    const [apiCallBtn, setApiCallBtn] = useState(false);

    const navigate = useNavigate();

    let formValidatory;   

    const validationConfigure = () => {
        let formFieldConfig = [ 
            { name:'code', displayName: Helper.getLabel(language,'code','Code'), types:['Required'] },
            { name:'name', displayName: Helper.getLabel(language,'name','Name'), types:['Required'] },
            { name:'vendor_id', displayName: Helper.getLabel(language,'vendor','Vendor'), types:['Required'] }
        ];

        if(!formValidatory) {
            formValidatory = new FormValidator(formFieldConfig, language)
        }; 
    };

    validationConfigure();

    let vendorObjCalled = false; 

    useEffect(() => { getVendorsData() }, []);
   
    const getVendorsData = () => {
        if (vendorObjCalled) return;
        vendorObjCalled = true;

        let api = Api; 
        api.setUserToken();
        api
        .axios()
        .get(Settings.apiVendorUrl + '/get_active_vendors', {})
        .then(function (res) {
            if (res.data.status === "success") { 
                setVendors(res?.data?.data?.map((item) => {
                    return {
                        value: item.vendor_id,
                        label:`${item.code} - ${item.name}`,
                    }
                }));
            }
        });
    };
 
    const mapVendorDataRef = (item) => {
        return {
            text: `${item.code} - ${item.name}`,
            id: item.vendor_id
        };
    };

    const fieldsArrayTaxAuthority = [ 
        {className:'col-lg-4', required: true, maxLength: 10, disable: apiCallBtn, label: Helper.getLabel(language,'code',"Code"), inputType: "text", id:'code', name:'code'}, 
        {className:'col-lg-4', required: true, maxLength: 80, disable: apiCallBtn, label: Helper.getLabel(language,'name',"Name"), inputType: "text", id:'name', name:'name'}, 
        {className:'col-lg-4', required: true, pagination: true, apiUrl: Settings.apiVendorUrl + '/get_active_vendors', allDataRef: vendorDataRef, mapFunction: mapVendorDataRef, placeHolder: 'Select vendor', disable: apiCallBtn, inputType: "dropdown", label: Helper.getLabel(language,'vendor',"Vendor"), options: vendors, id:'vendor_id', name:'vendor_id'}
    ];

    const executePOSTAPI = async () => {
        try { 
            let api = Api;
            api.setUserToken();

            const res = await api.axios().post(Settings.apiUrl + '/taxauthority', {
                ...formData,
                address: { addresses: [] },
                contact: { contacts: [] }
            },
            { cancelToken: cancelTokenSource.token });

            if (res.data.status === "success") {
                Helper.alert(res?.data?.message); 
                onPopupClose();
                navigate(`/tax/edit-tax-authority/${res?.data?.tax_authority_id}`); 
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error); 
        }
    };

    const onPopupClose = () => {
        $("html").removeClass("rs_popup_open");
        setShowCreateModal(false);
    }

    const handleSubmit = () => { 
        if(!formValidatory.isValid({...formData})){
            formValidatory.displayMessage();
            formValidatory.reset(); 
            return;
        };

        Helper.createDebouncedAPIFunction( 
            [executePOSTAPI], 
            setApiCallBtn,
            cancelTokenSource
        )();
    };

    return (
        <Fragment> 
            { apiCallBtn ? <OverlayLoader /> : null }
            <Popup
                autoOpen={true} width="900px"
                onClose={onPopupClose} 
            >
                <>
                    <h5 className="create_rfq_modal__title">Create Tax Authority</h5>

                    <Collapse
                        className="mt-4 mb-4" open={true}
                        title={Helper.getLabel(
                            language,
                            "general",
                            "General Information"
                        )}
                    >
                        <Form>
                            <div className='container-fluid'>
                                <FieldsRenderer updateState={setFormData} currentState={formData} fieldsArray={fieldsArrayTaxAuthority} />
                            </div>
                        </Form> 
                    </Collapse>
                </>

                <div className="addDocumentNumber-body-buttons" style={{ justifyContent: "flex-end" }}>
                    <Button
                        isActive={false} 
                        className='black-btn-style'
                        isDisable={apiCallBtn}
                        title={Helper.getLabel(language, "close", "Close")}
                        onClick={onPopupClose}
                    />

                    <Button
                        isDisable={apiCallBtn}
                        className="solid_btn" isActive={true}
                        title={Helper.getLabel(language, "create", "Create")}
                        onClick={handleSubmit}
                    />
                </div>
            </Popup>
        </Fragment>
    )
}
 
const mapStateToProps = (state) => {
    return {
        appOptions: state.options,
        auth: state.auth,
        language: state.language,
    };
};

export default connect(mapStateToProps) (TaxAuthorityModal);  