import React, {useState, useEffect, Fragment, useRef} from 'react'
import '../Numbers.css' 
import { connect } from 'react-redux'
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import LinkBtn from '../../inc/LinkBtn'
import Checkbox from '../../Forms/Checkbox'
import Helper from '../../../inc/Helper' 
import NavigationHeder from '../../Navigations/NavigationHeder'
import Button from '../../inc/Button'
import Api from '../../../inc/Api' 
import Settings from '../../../inc/Settings'  
import Collapse from '../../inc/Collapse'
import { useNavigate } from 'react-router-dom' 
import FormValidator from '../../../inc/FormValidator'
import Input from '../../Forms/Input'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, isWithinRange, segmentNumberSequenceFormat, validateArrayHavingAtleastOneObj, validateArrayHavingMoreThanObj } from '../../../inc/Validation'
import SegmentsModule from './SegmentsModule' 
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

/* Validations -> Segment Value -> should have # not any other character */

const NewNumber = (props) => {
  const [segments, setSegments] = useState({
    number_sequence_code: '',
    name: null, 
    in_use: null,
    continuous: null,
    manual: null,
    sequence_format: null,
    min_value: null,
    max_value: null,
    next_value: null
  }) 
  const [segmentsGrid, setSegmentsGrid] = useState([]);
  const [manualChecked, setManualChecked] = useState(false)
  const [continuousChecked, setContinuousChecked] = useState(false) 
  const [displayFormat, setDisplayFormat] = useState('')
 
  const [segmentList, setSegmentList] = useState([])

  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight]);
 
  const navigate = useNavigate() 
 
  const [loading, setLoading] = useState(false);
  const [loadingSegmentsGrid, setLoadingSegmentsGrid] = useState(false);
     
  let seqTypeCalled = false 
 
  useEffect(() => { 
    getSequenceType()  
  }, []) 
  
  useEffect(() => {
    setDisplayFormat(segmentNumberSequenceFormat(segmentsGrid) || '');
  }, [segmentsGrid]);
   
  let validator;

  const validationConfigure = () => {
    let fieldConfig = [
      {name:'number_sequence_code', displayName:Helper.getLabel(props.language,'code','Code'),types:['Required'] },
      {name:'name', displayName:Helper.getLabel(props.language,'name','Name'),types:['Required'] },   
      {name:'min_value', displayName:Helper.getLabel(props.language, 'smallest_value', 'Smallest Value'), types:['Required', 'Number']},
      {name:'max_value', displayName:Helper.getLabel(props.language, 'max_value', 'Largest Value'), types:['Required', 'Number']},
      {name:'next_value', displayName:Helper.getLabel(props.language, 'next_value', 'Next Value'), types:['Required', 'Number']}, 
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()
  
  const getSequenceType = () => {
    if(seqTypeCalled){
      return;
    }
    let api = Api
    seqTypeCalled =true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/numbersequenceSegmentType',{}).then(function(res){
      if(res.data.status==='success'){   
        let Sequence_Types_List_API = []
        res.data.data.forEach(item => {  
          Sequence_Types_List_API.push({
            value: item.id,
            label: item.name
          })
        })
        setSegmentList(Sequence_Types_List_API) 
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }
  
  const CodeChangeHandler = (event) => {
    let Code = event.target.value
    setSegments((prevState) => {
      return {...prevState, number_sequence_code: Code}
    })
  }

  const NameChangeHandler = (event) => {
    let Name = event.target.value
    setSegments((prevState) => {
      return {...prevState, name: Name}
    })
  }
 
  const InUseChangeHandler = (name, value) => {
    if(value == "on") {
      setSegments((prevState) => {
        return {...prevState, in_use: true}
      })
    }else {
      setSegments((prevState) => {
        return {...prevState, in_use: false}
      })
    }
  }

  const ContinousChangeHandler = () => {
    setContinuousChecked(true);
    setManualChecked(false);
  }

  const ManualChangeHandler = () => { 
    setManualChecked(true);
    setContinuousChecked(false);
  }

  const SmallestChangeHandler = (event) => {
    let Smallest = event.target.value 
    setSegments((prevState) => {
      return {...prevState, min_value: Smallest}
    }) 
    setSegments((prevState) => {
      return {...prevState, next_value: Smallest}
    }) 
  }

  const LargestChangeHandler = (event) => {
    let Largest = event.target.value
    setSegments((prevState) => {
      return {...prevState, max_value: Largest}
    })
  }

  const NextChangeHandler = (event) => {
    let Next = event.target.value
    setSegments((prevState) => {
      return {...prevState, next_value: Next}
    })
  } 
  
  const NumberingSequenceModule = () => {
    return (
      <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
        {IdentificationModule()} 
        <SegmentsModule
          setLoadingSegmentsGrid={setLoadingSegmentsGrid}
          loadingSegmentsGrid={loadingSegmentsGrid} 
          segmentsGrid={segmentsGrid}
          setSegmentsGrid={setSegmentsGrid}
          segmentList={segmentList}
          setDisplayFormat={setDisplayFormat}
          setSegments={setSegments}
        />
        {GeneralModule()}
      </div>
    );
  }; 
  
  const IdentificationModule = () => {
    return (
      <Collapse open={true} title={Helper.getLabel(props.language,'number_seq-001','Identification')}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='row gy-0 mt-2 mb-2'>
                <div className='col-lg-2 col-md-2'> 
                  <Input maxLength={10} label={Helper.getLabel(props.language,'code_NS_ADMIN','Code')} type='text' onChange={CodeChangeHandler} value={segments.number_sequence_code} required={true}   />
                </div>

                <div className='col-lg-3 col-md-3'> 
                  <Input maxLength={50} type='text' label={Helper.getLabel(props.language,'name_NS_ADMIN','Name')} onChange={NameChangeHandler} value={segments.name} required={true} />
                </div>
              </div>
            </div>
          </div>
        </div> 
      </Collapse>
    )
  }
  
  const GeneralModule = () => {
    return (
      <Collapse open={true} title={Helper.getLabel(props.language,11217,'General')}>
        <div className='container-fluid'>
          <div className='row mb-4'>
            <div className='col-lg-3 col-md-3'> 
              <Input label={Helper.getLabel(props.language,'format_NS_ADMIN','Format')} type='text' className='format' disable={true} value={displayFormat} /> 
            </div>  
          </div> 

          <div className='row p-0 mb-4'>
            <div className='col-lg-2 col-md-2'>
              <Checkbox type='checkbox' onChange={InUseChangeHandler} isChecked={segments.in_use} label={Helper.getLabel(props.language,'in-use_NS_ADMIN','In Use')} />
            </div>

            <div className='col-lg-2 col-md-2'>
              <Checkbox type='checkbox' onChange={ContinousChangeHandler} isChecked={continuousChecked} label={Helper.getLabel(props.language,'continues_NS_ADMIN','Continues')} /> 
            </div>

            <div className='col-lg-2 col-md-2'>
              <Checkbox type='checkbox' onChange={ManualChangeHandler} isChecked={manualChecked} label={Helper.getLabel(props.language,'manual_NS_ADMIN','Manual')} />
            </div>
          </div> 

          <div className='row'> 
            <div className='col-lg-3 col-md-3'> 
              <Input required={true} label={Helper.getLabel(props.language,'smallest_NS_ADMIN','Smallest')} type='text' onChange={SmallestChangeHandler} value={segments.min_value}  />
            </div>

            <div className='col-lg-3 col-md-3'>
              <Input required={true} label={Helper.getLabel(props.language,'largest_NS_ADMIN','Largest')} type='text' onChange={LargestChangeHandler} value={segments.max_value} />
            </div>  

            <div className='col-lg-3 col-md-3'> 
              <Input type='text' required={true} label={Helper.getLabel(props.language,'next_NS_ADMIN','Next')} onChange={NextChangeHandler} value={segments.next_value} />
            </div>  
          </div>
        </div>
      </Collapse>
    )
  }
  
  const SaveHandler = () => {   
    let validData = {
      ...segments
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }
  
    if(parseInt(segments.min_value) > parseInt(segments.max_value)){
      Helper.alert('Smallest Number cant be greater than Largest Number!', 'error')
      return;
    } 

    if(parseInt(segments.next_value) < parseInt(segments.min_value)){
      Helper.alert('Next Number cant be smaller than Smallest Number!', 'error')
      return;
    } 

    if(parseInt(segments.next_value) > parseInt(segments.max_value)){
      Helper.alert('Next Number cant be greater than Largest Number!', 'error')
      return;
    } 

    if(segments.in_use) { 
      if(!continuousChecked && !manualChecked) {
        Helper.alert('Please provide either manual or continuous!', 'error')
        return;
      }
    }

    if(checkIfArrayIsEmpty(segmentsGrid)){
      Helper.alert('Segments Missing!', 'error')
      return;
    }

    if(!validateArrayHavingAtleastOneObj(segmentsGrid)){
      Helper.alert('Please Enter Number Segment Type!', 'error')
      return;
    }

    if(!validateArrayHavingMoreThanObj(segmentsGrid)){
      Helper.alert('You cannot add Number inside segments more than 1', 'error')
      return;
    }
     
    setLoading(true)
    let api = Api
    api.setUserToken()  
    api.axios().post(Settings.apiUrl+'/numbersequence',{
      "number_sequence_code": segments.number_sequence_code !== "" ? segments.number_sequence_code: null,
      "name": segments.name !== "" ? segments.name : null,
      "segments": segmentsGrid,
      "in_use": segments.in_use === null ? false : segments.in_use,
      "continuous": continuousChecked,
      "manual": manualChecked,
      "sequence_format": displayFormat !== '' ? displayFormat : null,
      "min_value": segments.min_value,
      "max_value":  segments.max_value,
      "next_value": segments.next_value
    }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        setLoading(false)
        navigate('/number-sequence')
      }
    }).catch((res) => { 
      setLoading(false)
      getPopupMessageBasedOnStatus(res) 
    })
  }

  // Security Maintenance / Screen Rights / Accessibilities ---
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.number_sequence.number_sequence_create_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return ( 
    <Fragment>
      { loading ? <OverlayLoader isPopupInTab={true} /> : null }
      <div className = { 'container-fluid' }>
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} title='Create New Number Sequence'>
            <LinkBtn isActive= {false} className='black-btn-style' to="/number-sequence" title={Helper.getLabel(props.language,'close','Close')} />
            <Button isDisable={!security.canCreate(frontendIds.setupForms.number_sequence.number_sequence_create_screen_save_button)} isActive= {false} title={Helper.getLabel(props.language,'save','Save')} onClick={SaveHandler} className="rfq_save_btn" />
          </NavigationHeder> 
        </div>

        { NumberingSequenceModule()}
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.number_sequence.number_sequence_create_screen
}

export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(NewNumber, SecurityOptions)))